import { useMemo } from 'react';
import { useTranslation } from '../../../services/translation';
import { FormConfigTypes } from '../../../types/FormConfig';
import { makeGetErrorAndHelperText } from '../../../utils';
import { textFieldProps } from '../../../utils/materialHelpers';

export const useFormConfig = ({ props, classes }: FormConfigTypes) => {
  const { t } = useTranslation();
  const getErrorAndHelperText = makeGetErrorAndHelperText(t);

  const {
    first_name,
    last_name,
    document,
    email,
    phone_number,
    buyerDepartment,
    buyerFloor,
    buyerProvince,
    buyerStreet,
    buyerStreetNumber,
    buyerExtraAddress,
    buyerZip,
    buyerCity,
    street,
    street_number,
    zip,
    floor,
    department,
    extra_address,
    city,
    province
  } = props;

  const errorDocument = getErrorAndHelperText(document);
  const errorPhoneNumber = getErrorAndHelperText(phone_number);
  const errorStreetNumber = getErrorAndHelperText(street_number);
  const errorFloor = getErrorAndHelperText(floor);
  const errorDepartment = getErrorAndHelperText(department);
  const errorEmail = getErrorAndHelperText(email);
  const errorFirstName = getErrorAndHelperText(first_name);
  const errorLastName = getErrorAndHelperText(last_name);
  const errorBuyerStreetNumber = getErrorAndHelperText(buyerStreetNumber);
  const errorBuyerFloor = getErrorAndHelperText(buyerFloor);
  const errorBuyerDepartment = getErrorAndHelperText(buyerDepartment);
  const errorBuyerCity = getErrorAndHelperText(buyerCity);
  const errorCity = getErrorAndHelperText(city);
  const errorProvince = getErrorAndHelperText(province);
  const errorBuyerProvince = getErrorAndHelperText(buyerProvince);

  const formFieldsBuyer = [
    {
      field: first_name,
      errorField: errorFirstName,
      class: classes && classes.halfTextFieldLeft,
      ...textFieldProps(t('First Name'))
    },
    {
      field: last_name,
      errorField: errorLastName,
      class: classes && classes.halfTextField,
      ...textFieldProps(t('Last Name'))
    },
    {
      field: document,
      errorField: errorDocument,
      class: classes && classes.textField,
      ...textFieldProps(t('Document'))
    },
    {
      field: email,
      errorField: errorEmail,
      class: classes && classes.textField,
      ...textFieldProps(t('Email'))
    },
    {
      field: phone_number,
      errorField: errorPhoneNumber,
      class: classes && classes.textField,
      ...textFieldProps(t('Phone Number'))
    }
  ];

  const formFieldsShipment = [
    {
      field: zip,
      errorField: null,
      class: classes && classes.textField,
      customeValid: true,
      ...textFieldProps(t('Zip'))
    },
    {
      field: street,
      errorField: null,
      class: classes && classes.textField,
      ...textFieldProps(t('Street'))
    },
    {
      field: street_number,
      errorField: errorStreetNumber,
      class: classes && classes.textField,
      ...textFieldProps(t('Street Number'))
    },
    {
      field: floor,
      errorField: errorFloor,
      class: classes && classes.halfTextFieldLeft,
      ...textFieldProps(t('Floor'))
    },
    {
      field: department,
      errorField: errorDepartment,
      class: classes && classes.halfTextField,
      ...textFieldProps(t('Department'))
    },
    {
      field: extra_address,
      errorField: null,
      class: classes && classes.textField,
      ...textFieldProps(t('Extra Address'))
    }
  ];

  const formFieldsBuyerAddress = [
    {
      field: buyerZip,
      errorField: null,
      class: classes && classes.textField,
      customeValid: true,
      ...textFieldProps(t('Zip'))
    },
    {
      field: buyerStreet,
      errorField: null,
      class: classes && classes.textField,
      ...textFieldProps(t('Street'))
    },
    {
      field: buyerStreetNumber,
      errorField: errorBuyerStreetNumber,
      class: classes && classes.textField,
      ...textFieldProps(t('Street number'))
    },
    {
      field: buyerFloor,
      errorField: errorBuyerFloor,
      class: classes && classes.halfTextFieldLeft,
      ...textFieldProps(t('Floor'))
    },
    {
      field: buyerDepartment,
      errorField: errorBuyerDepartment,
      class: classes && classes.halfTextField,
      ...textFieldProps(t('Department'))
    },
    {
      field: buyerExtraAddress,
      errorField: null,
      class: classes && classes.textField,
      ...textFieldProps(t('Extra Address'))
    }
  ];

  const errorBuyerAddress = useMemo(
    () => errorBuyerDepartment.error || errorBuyerFloor.error || errorBuyerStreetNumber.error || errorBuyerCity.error || errorBuyerProvince.error,
    [errorBuyerDepartment, errorBuyerFloor, errorBuyerStreetNumber, errorBuyerCity, errorBuyerProvince]
  );

  const errorForm = Object.values(props).filter((value) => getErrorAndHelperText(value).error);

  return {
    formFieldsBuyer,
    formFieldsBuyerAddress,
    formFieldsShipment,
    errorBuyerCity,
    errorCity,
    errorForm,
    errorBuyerAddress,
    errorProvince,
    errorBuyerProvince
  };
};
