import { GoalFieldsEnabled } from '../../interfaces/business';
import { makeBusinessEntity } from '../../lib/libby/generator/makeBusinessEntity';

const DAO_NAME = 'ster_goal_field_enabled';

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch }
} = makeBusinessEntity<GoalFieldsEnabled>(DAO_NAME);

// hooks
export const useGoalFieldsEnabledFetchById = fetchById;
export const useGoalFieldsEnabledLibbyCall = libbyCall;
export const useGoalFieldsEnabledDAO = libbyDAO;
export const useGoalFieldsEnabledLibbyFetch = libbyFetch;

// hooks
export const useGoalFieldsEnabledUpdateFetchById = fetchById;
export const useGoalFieldsEnabledUpdateLibbyCall = libbyCall;
export const useGoalFieldsEnabledUpdateDAO = libbyDAO;
export const useGoalFieldsEnabledUpdateLibbyFetch = libbyFetch;
