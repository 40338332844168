import React from 'react';
import { TextFieldProps } from '@material-ui/core/TextField';
import { GeneralSelect } from './GeneralSelect';
import { ValidatorType, makeValidator } from '../utils';

const data = [
  {
    id: 1,
    value: ';'
  },
  {
    id: 2,
    value: ','
  }
];

export const SelectDelimiter = ({ inputProps = {}, optionAll = false }: { inputProps?: TextFieldProps; optionAll?: boolean }) => (
  <GeneralSelect working={false} label="Delimiter" optionAll={optionAll} data={data} inputProps={inputProps} />
);

export const inputName = 'select_name';

export const formInitialValues = {
  [inputName]: ''
};

export const validate = makeValidator([{ key: inputName, validators: [ValidatorType.REQUIRED] }]);
