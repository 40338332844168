import { LibbyFetchDAO } from './LibbyFetchDAO';

export class OrderCancelReasonDAO extends LibbyFetchDAO {
  constructor() {
    super('order_cancel_reason', 'order_cancel_reason_id');
  }

  getAll(params: Array<number>) {
    const query = this.query();
    params.forEach((item) => {
      query.notEquals('order_cancel_reason_id', item);
    });
    return query.limit(0, 10000).run();
  }
}
