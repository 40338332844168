import { Grid } from '@material-ui/core';
import React from 'react';
import { ButtonComponent } from 'components/Button';
import { ScreenAligned } from 'components/ScreenAligned/ScreenAligned';
import { useTranslation } from 'services/translation';
import { ButtonDetailsType } from 'types/Button';
import InfoTable from 'routes/components/InfoTable';
import LoadingData from 'routes/components/LoadingData';
import { useProcessListLogic } from '../hook/useProcessListLogic';
import { LibbyObject } from 'types';

export const StockProcessList = ({ libby /* title */ }: { libby: LibbyObject; title: string }) => {
  const { t } = useTranslation();
  const { rows, working, buttons, titleShow, columns } = useProcessListLogic({ libby });
  return (
    <ScreenAligned
      title={titleShow}
      additionalTitle={
        <>
          {buttons.map((dataButton: ButtonDetailsType) => (
            <ButtonComponent
              key={dataButton.id}
              title={dataButton.title}
              className={dataButton.className}
              variant={dataButton.variant}
              color={dataButton.color}
              onClick={dataButton.onClick}
              type={dataButton.type}
              disabled={dataButton.disabled}
              loading={dataButton.loading}
            />
          ))}
        </>
      }
    >
      <Grid container direction="row" justify="center" alignItems="center">
        <InfoTable columns={columns} rows={rows} onBottomScroll={() => {}} onSortChange={() => {}} direction={'asc'} rowIdKey="goal_id" md={12} sm={12} xs={12} />
        {(working || !!rows.length) && <LoadingData label={`${t('Loaded marketplaces')}`} working={working} cant={rows.length} />}
      </Grid>
    </ScreenAligned>
  );
};
