import { LibbyFetchDAO } from './LibbyFetchDAO';

export class StockProductsLogsDAO extends LibbyFetchDAO {
  constructor() {
    super('ster_product_stock_logs', 'product_stock_log_id');
  }

  getAll() {
    return this.query().limit(0, 10000).run();
  }
}
