import { Button, Grid } from '@material-ui/core';
import React, { useMemo } from 'react';
import { makeStyles } from '@material-ui/styles';
import { useTranslation } from '../../../services/translation';
import { formatDate } from '../../../utils';
import { SendColumnActions } from '../routes/DispatchesList/components/SendColumnActions';
import { useIsReadOnly } from '../utils/isReadOnly';
import { columnsInquiry, columnsNotInquiry } from '../utils/columns';
import { ORDER_STATE, DISPATCH_STATE } from '../../../const';
import { useBreadcrumbs } from '../../../hooks';
import { Dispatch_item, Dispatch } from '../../../interfaces/business';
import { AnyObject } from '../../../types';

const useStyles = makeStyles({
  buttonLargeWidth: {
    fontFamily: '"Oswald"',
    padding: '5px 22px',
    minWidth: 100
  }
});

export const useDispatchMode = (data: AnyObject, onCreateDispatch: (k?: Dispatch) => void, handleSendModal: (k?: Dispatch) => void, actionsDispatch: (dispatch: Dispatch, idName: string, type: string) => void) => {
  const { t } = useTranslation();
  const isReadOnly = useIsReadOnly();
  const classes = useStyles();

  const screenProps = useMemo(
    () =>
      !isReadOnly
        ? {
            title: 'Dispatches',
            additionalTitle: (
              <Grid item>
                <Button className={classes.buttonLargeWidth} type="reset" variant="contained" onClick={() => onCreateDispatch()} color="primary">
                  {t('Create')}
                </Button>
              </Grid>
            )
          }
        : { title: 'Dispatches Inquiry' },
    [isReadOnly, onCreateDispatch, classes, t]
  );

  const titleShow = useBreadcrumbs(screenProps.title);

  const title = useMemo(() => {
    if (isReadOnly) {
      titleShow[0].href = '/dispatches/inquiry';
    }
    return titleShow;
  }, [titleShow, isReadOnly]);

  const rows = useMemo(
    () =>
      data.map((dispatch: Dispatch) => {
        const updated_by = dispatch?.updated_by ? dispatch.updated_by : dispatch.created_by;

        const { items } = dispatch;
        const searchOrder = items.filter((item: Dispatch_item) => item.order.state.order_state_id === ORDER_STATE.OUT_FOR_DELIVERY.toString());

        return {
          id: dispatch?.dispatch_id || '',
          dispatch_id: dispatch?.dispatch_id,
          total_amount_dispatch: dispatch?.items.reduce((previousValue, currentValue) => previousValue + Number(currentValue.order.amount), 0).toString(),
          created_at: formatDate(dispatch?.created_at, true, false),
          name: dispatch?.name,
          dispatch_zone: dispatch?.dispatch_zone?.name,
          state: t(dispatch?.dispatch_state?.name),
          number_delivered: `${dispatch?.dispatch_state?.dispatch_state_id === DISPATCH_STATE.CANCELLED ? '0' : dispatch?.items?.length - searchOrder.length}/${dispatch?.items?.length}`,
          courier: dispatch?.courier_service?.courier?.name,
          courier_service: dispatch?.courier_service?.name,
          number_items: dispatch?.items?.length,
          delivery_date: formatDate(dispatch?.delivery_date, false),
          updated_by: updated_by?.username,
          updated_at: dispatch?.updated_at ? formatDate(dispatch?.updated_at, true, false) : '',
          send: <SendColumnActions dispatch={dispatch} handleSendModal={handleSendModal} actionsDispatch={actionsDispatch} isReadOnly={isReadOnly} />
        };
      }),
    [data, t, isReadOnly, actionsDispatch, handleSendModal]
  );

  return {
    rows,
    screenProps,
    columns: isReadOnly ? columnsInquiry : columnsNotInquiry,
    title,
    isReadOnly
  };
};
