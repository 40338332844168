import { formatMoney } from '../../../utils';
import { columnsPriority } from '../../Dispatches/routes/DispatchOrderList/components/DispatchOrderListTable';

export const columnsAddOrder = [
  {
    id: 'id',
    label: 'Order ID',
    width: '160px',
    style: {
      minWidth: '100px'
    },
    orderById: 'dispatch.items.order.order_id'
  },
  {
    id: 'so_number',
    label: 'SO Number',
    width: '160px',
    style: {
      minWidth: '100px'
    },
    orderById: 'dispatch.items.order.so_number'
  },
  {
    id: 'buyer',
    label: 'Buyer',
    width: '160px',
    style: {
      minWidth: '150px'
    },
    orderById: 'dispatch.items.order.buyer.first_name'
  },
  {
    id: 'total',
    label: 'Total Purchase',
    width: '150px',
    style: {
      minWidth: '80px'
    },
    orderById: 'dispatch.items.order.total',
    render: (value: any) => formatMoney(value?.total) || '-'
  },
  {
    id: 'items',
    label: 'Items',
    width: '160px',
    style: {
      minWidth: '100px'
    },
    orderById: 'dispatch.items.order.number_items'
  },
  {
    id: 'state',
    label: 'State',
    width: '160px',
    style: {
      minWidth: '100px'
    },
    orderById: 'dispatch.items.order.state'
  },
  {
    id: 'created_at',
    label: 'Purchase Date',
    width: '160px',
    style: {
      minWidth: '100px'
    },
    orderById: 'dispatch.items.order.created_at'
  },
  columnsPriority
];
