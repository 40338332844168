import { LibbyFetchDAO } from '../LibbyFetchDAO';

export class OrderShipmentAddressLogsDAO extends LibbyFetchDAO {
  constructor() {
    super('ster_order_shipment_address_logs', 'order_shipment_address_log_id');
  }

  getAll() {
    return this.query().limit(0, 10000).run();
  }

  getAllByOrderId(id: number) {
    return this.query().equals('order.order_id', id).run();
  }
}
