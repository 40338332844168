import React from 'react';
import { DatabaseConnector } from '@phinxlab/libby-rest-web';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import { LibbyObject } from '../../types';
import { MessageTemplateList } from './routes/MessageTemplateList';

interface TemplateRawType {
  libby: LibbyObject;
}

export const TemplatesRaw = ({ libby }: TemplateRawType) => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${path}`}>
        <MessageTemplateList title="Message Templates" libby={libby} />
      </Route>
    </Switch>
  );
};

export const MessagesTemplates = DatabaseConnector(TemplatesRaw)('ster_message_template', 'ster_message_template_update');
