import { GoalMarketplace } from '../../interfaces/business';
import { makeBusinessEntity } from '../../lib/libby/generator/makeBusinessEntity';

const DAO_NAME = 'ster_goal_marketplace';

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch }
} = makeBusinessEntity<GoalMarketplace>(DAO_NAME);

// hooks
export const useGoalMarketplaceFetchById = fetchById;
export const useGoalMarketplaceLibbyCall = libbyCall;
export const useGoalMarketplaceDAO = libbyDAO;
export const useGoalMarketplaceLibbyFetch = libbyFetch;

// hooks
export const useGoalMarketplaceUpdateFetchById = fetchById;
export const useGoalMarketplaceUpdateLibbyCall = libbyCall;
export const useGoalMarketplaceUpdateDAO = libbyDAO;
export const useGoalMarketplaceUpdateLibbyFetch = libbyFetch;
