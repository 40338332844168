import { LibbyFetchDAO } from '../LibbyFetchDAO';

export class ProcessConfigDAO extends LibbyFetchDAO {
  constructor() {
    super('ster_process_config', 'ster_config_id');
  }

  getAll() {
    return this.query().limit(0, 10000).run();
  }
}
