import React from 'react';
import { useTranslation } from '../../../../../services/translation';
import InfoTable from '../../../../components/InfoTable';
import LoadingData from '../../../../components/LoadingData';
import { HeadCellsType, RowsType } from '../../../types';

type BudgetTableProps = {
  rows: RowsType[];
  columns: Array<HeadCellsType>;
  working: boolean;
};

export const BudgetCommentTable = ({ columns, rows, working }: BudgetTableProps) => {
  const { t } = useTranslation();

  return (
    <>
      <InfoTable columns={columns} rowIdKey="order_comment_id" rows={rows} />
      <LoadingData label={`${t('Loaded registers')}`} working={working} cant={rows?.length} />
    </>
  );
};
