import { Marketplace } from '../../interfaces/business';
import { makeBusinessEntity } from '../../lib/libby/generator/makeBusinessEntity';

const DAO_NAME = 'ster_marketplace_sla_unassigned';

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch }
} = makeBusinessEntity<Marketplace>(DAO_NAME);

// hooks
export const useMarketplaceSlaUnassignedFetchById = fetchById;
export const useMarketplaceSlaUnassignedLibbyCall = libbyCall;
export const useMarketplaceSlaUnassignedDAO = libbyDAO;
export const useMarketplaceSlaUnassignedLibbyFetch = libbyFetch;
