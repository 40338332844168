import { LayoutType } from 'recharts';
import { NumberFormat } from '../util/format';

// eslint-disable-next-line max-len
export const CHART_COLORS = [
  '#3366cc',
  '#dc3912',
  '#ff9900',
  '#109618',
  '#990099',
  '#0099c6',
  '#dd4477',
  '#66aa00',
  '#b82e2e',
  '#316395',
  '#994499',
  '#22aa99',
  '#aaaa11',
  '#6633cc',
  '#e67300',
  '#8b0707',
  '#651067',
  '#329262',
  '#5574a6',
  '#3b3eac',
  '#b77322',
  '#16d620',
  '#b91383',
  '#f4359e',
  '#9c5935',
  '#a9c413',
  '#2a778d',
  '#668d1c',
  '#bea413',
  '#0c5922',
  '#743411'
];

export const CHART_LIGHT_COLORS = [
  '#38a6ff',
  '#dc5012',
  '#ffac00',
  '#10b918',
  '#996899',
  '#00b2c6',
  '#dd6a77',
  '#66bf00',
  '#b8512e',
  '#317b95',
  '#995b99',
  '#22aa99',
  '#aaaa11',
  '#6633cc',
  '#e67300',
  '#8b0707',
  '#651067',
  '#329262',
  '#5574a6',
  '#3b3eac',
  '#b77322',
  '#16d620',
  '#b91383',
  '#f4359e',
  '#9c5935',
  '#a9c413',
  '#2a778d',
  '#668d1c',
  '#bea413',
  '#0c5922',
  '#743411'
];

export type ChartData = {
  key: string;
  label: string;
  value: number;
  color?: string;
};

export type ChartType = 'Pie' | 'Bar';

export type ChartDataFormat = NumberFormat;

export type ChartGridSize = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;

export type ChartProps = {
  data: Array<ChartData>;
  type: ChartType;
  height: number;
  key: string;
  value: string;
  format: ChartDataFormat;
  xs?: ChartGridSize;
  sm?: ChartGridSize;
  md?: ChartGridSize;
  lg?: ChartGridSize;
  xl?: ChartGridSize;
  working?: boolean;
  layout?: LayoutType;
  verticalAlign?: 'top' | 'middle' | 'bottom';
  outerRadius?: string;
  cx?: string;
  cy?: string;
  wrapperStyle?: object;
};
