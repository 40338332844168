import { LibbyFetchDAO } from '../LibbyFetchDAO';

export class MessageDAO extends LibbyFetchDAO {
  constructor() {
    super('ster_message', 'message_id');
  }

  getAll() {
    return this.query().limit(0, 10000).run();
  }
}
