import { LibbyFetchDAO } from './LibbyFetchDAO';

export class ImportationTypeDAO extends LibbyFetchDAO {
  constructor() {
    super('public_importation_type', 'importation_type_id');
  }

  getAll() {
    return this.query().limit(0, 10000).run();
  }
}
