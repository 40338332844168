import React, { useMemo, useState, useEffect, useCallback, useRef } from 'react';
import { DatabaseConnector } from '@phinxlab/libby-rest-web';
import { TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { makeStyles } from '@material-ui/styles';
import { AnyObject, LibbyObject } from '../../types/types';
import { useLibbyCall } from '../../hooks';
import useDebounce from '../../hooks/useDebounce';
import { City } from '../../interfaces/business';
import { useTranslation } from '../../services/translation';
import LoadingData from '../../routes/components/LoadingData';

type SelectImportTypeProps = {
  inputProps: AnyObject;
  optionAll?: boolean;
  libby: LibbyObject;
  disabled?: boolean;
  cityId: string;
  name: string;
  form: any;
  id?: string;
  cityInputRef?: React.MutableRefObject<any>;
  copiedCity: string;
  setCopiedCity: (val: null | string) => void;
};

interface CityValue {
  id: number;
  value: string;
}

const useStyles = makeStyles(() => ({
  textFieldAutocomplete: {
    '& .MuiFormHelperText-root': {
      color: '#FF5179'
    }
  }
}));

export const AutocompleteCityRaw = ({ libby, inputProps, id, cityInputRef, copiedCity, setCopiedCity }: SelectImportTypeProps) => {
  const timeout = useRef<NodeJS.Timeout>();
  const [search, setSearch] = useState(inputProps.value);
  const [province, setProvince] = useState(inputProps.province);
  const searchDebunce = useDebounce(search, 600);
  const paramsVar = useMemo(() => [inputProps.province, searchDebunce], [inputProps.province, searchDebunce]);
  const classes = useStyles();

  const { t } = useTranslation();

  const { data: cities, recall } = useLibbyCall(libby, {
    daoName: 'ster_city',
    methodName: 'getAllByStateName',
    params: paramsVar,
    noAutoCall: true
  });

  const allCities = useMemo(() => (cities.length ? cities.map((city: City) => ({ id: city.city_id, value: city.name })) : []), [cities]);

  useEffect(() => {
    if (!province) setProvince(inputProps.province);
  }, [inputProps.province, province]);

  const city = allCities && allCities.find((cityInner: CityValue) => cityInner.value === inputProps.value);

  useEffect(() => {
    if (paramsVar[0]?.length > 0) recall?.(...paramsVar);
  }, [paramsVar, recall]);

  useEffect(() => {
    setSearch(inputProps.value);
  }, [inputProps.value]);

  const provinceChange = useMemo(() => province === inputProps.province, [inputProps.province, province]);

  const forceCopy = useCallback(() => {
    if (!copiedCity) return null;
    const cityIndex = allCities.findIndex(({ value }: CityValue) => value === inputProps.value);
    if (cityIndex < 0) return null;
    timeout.current = setTimeout(() => {
      // @ts-ignore
      document.getElementById(`shipment-address-option-${cityIndex}`)?.click();
      setCopiedCity(null);
      clearTimeout(timeout.current as NodeJS.Timeout);
    }, 200);
    return null;
  }, [allCities, timeout, copiedCity, setCopiedCity, inputProps.value]);

  const defaultValue = useMemo(() => (copiedCity ? { id: '-1', value: copiedCity } : city), [city, copiedCity]);

  return (
    <>
      {city || !inputProps.value || !provinceChange ? (
        <Autocomplete
          style={inputProps.classes ? undefined : { display: 'inline-block', width: '100%' }}
          id={id || 'tags-outlined'}
          options={allCities}
          getOptionLabel={(option: { id: string; value: string }) => option.value}
          filterSelectedOptions
          renderInput={(params: any) => {
            if (params.inputProps.value) params.inputProps.value = inputProps.value;
            return (
              <form noValidate>
                <TextField
                  required
                  {...params}
                  className={inputProps.classes || classes.textFieldAutocomplete}
                  label={t('City')}
                  placeholder=""
                  helperText={inputProps.error.error && `${t(inputProps.error?.helperText || '')} *`}
                  onBlur={inputProps.onBlur}
                  inputRef={cityInputRef}
                  InputLabelProps={inputProps.InputLabelProps ? { ...inputProps.InputLabelProps } : {}}
                />
              </form>
            );
          }}
          onChange={(e, newValue) => {
            inputProps.onChange(newValue && newValue.value.toString());
          }}
          noOptionsText="sin opciones"
          onOpen={forceCopy}
          defaultValue={defaultValue}
        />
      ) : (
        <LoadingData working />
      )}
    </>
  );
};

export const AutocompleteCity = DatabaseConnector(AutocompleteCityRaw)('ster_city');
