import React from 'react';
import { Grid } from '@material-ui/core';
import { DatabaseConnector } from '@phinxlab/libby-rest-web';
import { ButtonComponent } from '../../../../components/Button';
import { ScreenAligned } from '../../../../components/ScreenAligned/ScreenAligned';
import { useTranslation } from '../../../../services/translation';
import InfoTable from '../../../components/InfoTable';
import LoadingData from '../../../components/LoadingData';
import { useReportingGoalSaleSettingPeriodsLogic } from './hooks/useReportingGoalSalesSettingPeriods';
import { LibbyObject } from '../../../../types';

type ReportingObjectSaleSettingPeriodRawProps = {
  libby: LibbyObject;
};

export const ReportingObjectSaleSettingPeriodRaw = ({ libby }: ReportingObjectSaleSettingPeriodRawProps) => {
  const { columns, rows, working, titleShow, buttonDetails, direction, handleRequestSort, orderBy } = useReportingGoalSaleSettingPeriodsLogic({ libby });
  const { t } = useTranslation();
  return (
    <ScreenAligned
      title={titleShow}
      additionalTitle={
        <>
          <Grid container>
            {buttonDetails.map((dataButton) => (
              <ButtonComponent
                key={dataButton.id}
                title={dataButton.title}
                className={dataButton.className}
                variant={dataButton.variant}
                color={dataButton.color}
                onClick={dataButton.onClick}
                type={dataButton.type}
                disabled={dataButton.disabled}
                loading={dataButton.loading}
              />
            ))}
          </Grid>
        </>
      }
    >
      <Grid container direction="row" justify="center" alignItems="center">
        <InfoTable columns={columns} rows={rows} onSortChange={handleRequestSort} orderBy={orderBy} direction={direction} rowIdKey="goal_id" md={12} sm={12} xs={12} />
        <LoadingData label={`${t('Loaded goals')}`} working={working} cant={rows.length} />
      </Grid>
    </ScreenAligned>
  );
};

export const ReportingObjectSaleSettingPeriod = DatabaseConnector(ReportingObjectSaleSettingPeriodRaw)('ster_goal_field_enabled');
