import { Box, Grid, Switch } from '@material-ui/core';
import React from 'react';
import { DatabaseConnector } from '@phinxlab/libby-rest-web';
import { ScreenAligned } from '../../../../../components/ScreenAligned/ScreenAligned';
import { SearchFilters } from './components/SearchFilters';
import { ResportingLogisticJustifyLogic } from './hook/ResportingLogisticJustifyLogic';
import { ButtonComponent } from '../../../../../components/Button';
import InfoTable from '../../../../components/InfoTable';
import { useTranslation } from '../../../../../services/translation';
import LoadingData from '../../../../components/LoadingData';
import { reportingLogisticJustifyColumns as columns } from './components/ReportingLogisticJustifyColumns';
import { LibbyObject } from '../../../../../types';

interface JustifiedListType {
  libby: LibbyObject;
}

export const ReportingLogisticJustifyListRaw = ({ libby }: JustifiedListType) => {
  const { t } = useTranslation();
  const { titleShow, rows, fetchMore, working, orderBy, direction, handleRequestSort, refreshFilter, setResetFilter, stateRefresh, handleChange, buttons } = ResportingLogisticJustifyLogic({ libby });

  return (
    <ScreenAligned
      title={titleShow}
      additionalTitle={
        <>
          <Grid container>
            <Box display="flex" justifyContent="center" alignItems="center">
              <Switch checked={stateRefresh} onChange={handleChange} name="checkedA" color="primary" inputProps={{ 'aria-label': 'secondary checkbox' }} />
            </Box>
            {buttons.map((dataButton) => (
              <ButtonComponent
                key={dataButton.id}
                title={dataButton.title}
                className={dataButton.className}
                variant={dataButton.variant}
                color={dataButton.color}
                onClick={dataButton.onClick}
                type={dataButton.type}
                disabled={dataButton.disabled}
                loading={dataButton.loading}
              />
            ))}
          </Grid>
        </>
      }
    >
      <Grid container direction="row" justify="center" alignItems="center">
        <SearchFilters onFilterChange={setResetFilter} filter={refreshFilter} />
        <InfoTable columns={columns} orderBy={orderBy} rows={rows} onBottomScroll={fetchMore} onSortChange={handleRequestSort} direction={direction} rowIdKey="non_working_day_id" md={12} sm={12} xs={12} />
        {(working || !!rows.length) && <LoadingData label={`${t('Loaded non-working days')}`} working={working} cant={rows.length} />}
      </Grid>
    </ScreenAligned>
  );
};

export const ReportingLogisticJustifyList = DatabaseConnector(ReportingLogisticJustifyListRaw)('ster_reporting_logistic_justify', 'ster_reporting_logistic_justify_logs');
