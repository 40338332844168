import React from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import { ReportingMlList } from './routes';

export const ReportingMl = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${path}`}>
        <ReportingMlList />
      </Route>
    </Switch>
  );
};
