import { LibbyFetchDAO } from '../LibbyFetchDAO';

export class AlertUpdatedDAO extends LibbyFetchDAO {
  constructor() {
    super('ster_alert_updated', 'alert_id');
  }

  getAll() {
    return this.query().limit(0, 10000).run();
  }
}
