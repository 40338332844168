import React, { useEffect, useMemo } from 'react';
import { TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { DatabaseConnector } from '@phinxlab/libby-rest-web';
import { Moment } from 'moment';
import { orderBy, toLower } from 'lodash';
import { AnyObject, LibbyObject } from '../../types/types';
import { Marketplace } from '../../interfaces/business';
import { useLibbyCall } from '../../hooks';
import { useTranslation } from '../../services/translation';

type SelectMarketplaceGoalProps = {
  libby: LibbyObject;
  from?: Moment;
  inputProps: AnyObject;
  optionAll?: boolean;
  [k: string]: any;
  disabled?: boolean;
  marketplace?: boolean;
  label: string;
  methodName?: string;
  editForm?: boolean;
};

export const SelectMarketplaceGoalRaw = ({ libby, inputProps = {}, disabled = false, label, from, editForm = false }: SelectMarketplaceGoalProps) => {
  const { t } = useTranslation();
  const {
    data: dataAll,
    working,
    recall
  } = useLibbyCall(libby, {
    daoName: !editForm ? 'ster_goal_field_enabled' : 'ster_marketplace',
    methodName: !editForm ? 'getAllGoalFieldEnabledByType' : 'getAll',
    ...(!editForm && { params: [{ type: 'Marketplace', start_date: `${from?.date(1).format('YYYY-MM-DD')} 00:00:00` }] }),
    noAutoCall: !editForm
  });

  useEffect(() => {
    if (from) {
      recall?.({ type: 'Marketplace', start_date: `${from?.date(1).format('YYYY-MM-DD')} 00:00:00` });
    }
  }, [from, recall]);

  const data = useMemo(() => {
    const dataMarketplaces = dataAll as Marketplace[];
    return dataMarketplaces?.length ? dataMarketplaces.map((marketplace) => ({ id: marketplace.marketplace_id, value: marketplace.name })) : [];
  }, [dataAll]);

  return (
    <Autocomplete
      disabled={disabled || working}
      style={{ display: 'inline-block', width: '100%' }}
      multiple
      options={orderBy(data, [(item) => toLower(item.value)], 'asc')}
      getOptionLabel={(option: { id: string; value: string }) => option.value}
      filterSelectedOptions
      getOptionSelected={(option: any, _value: any) => option.id === _value.id}
      value={inputProps.value || []}
      onChange={(e, newValue) => inputProps.onChange(newValue)}
      renderInput={(params) => <TextField {...params} variant="standard" label={label} placeholder="" error={inputProps.error?.error} helperText={inputProps.error?.error ? `${t(inputProps.error?.helperText || '')}` : ''} />}
    />
  );
};

export const SelectMarketplaceGoal = DatabaseConnector(SelectMarketplaceGoalRaw)('ster_goal_field_enabled', 'ster_marketplace');
