import React, { useCallback, MouseEventHandler } from 'react';
import { toUpper } from 'ramda';
import { DatabaseConnector } from '@phinxlab/libby-rest-web';
import Drawer from '@material-ui/core/Drawer';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import LogoutIcon from '@material-ui/icons/ExitToApp';
import CreditCardIcon from '@material-ui/icons/CreditCard';
// import DashboardIcon from '@material-ui/icons/Dashboard';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import Avatar from '@material-ui/core/Avatar';
import Collapse from '@material-ui/core/Collapse';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import DesktopWindowsOutlinedIcon from '@material-ui/icons/DesktopWindowsOutlined';
import StoreMallDirectoryOutlinedIcon from '@material-ui/icons/StoreMallDirectoryOutlined';
import ShoppingBasket from '@material-ui/icons/ShoppingBasket';
import { makeStyles } from '@material-ui/styles';
import { createStyles, Theme } from '@material-ui/core/styles';
import { pink } from '@material-ui/core/colors';
import LocalShippingOutlinedIcon from '@material-ui/icons/LocalShippingOutlined';
import MenuDrawerItem from './components/MenuDrawerItem';
import { useGlobalContext } from '../../hooks';
import { groupType, userInfoType } from '../../types';
import { useTranslation } from '../../services/translation';
import { authorizeMenu } from '../../utils';
import { ImportationsTargets, MENU } from '../../const';
import { Retool_account_menu } from '../../interfaces/business/retool';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      maxWidth: 360,
      backgroundColor: theme.palette.background.paper
    },
    nested2: {
      padding: theme.spacing(0)
    },
    marginSub: {
      margin: '0px',
      marginLeft: '17%'
    },
    nested: {
      paddingLeft: theme.spacing(2)
    },
    list: {
      width: 280
    },
    toolbar: {
      ...theme.mixins.toolbar,
      display: 'flex',
      alignItems: 'center',
      padding: theme.spacing(2)
    },
    nameText: {
      marginTop: theme.spacing(2)
    },
    profile: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      minHeight: 'fit-content',
      paddingTop: '1em',
      paddingBottom: '1em'
    },
    title: {
      textAlign: 'center'
    },

    avatar: {
      color: theme.palette.getContrastText(pink[500]),
      backgroundColor: pink[500],
      marginRight: 20,
      width: 45,
      height: 45
    },
    userContainer: {
      padding: 10,
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'flex-start',
      marginLeft: 16
    },
    paddingSubMenu: { padding: '0px 16px' },
    subMenu: {
      display: 'flex',
      flexDirection: 'column'
    }
  })
);

type MenuDrawerProps = {
  libby: any;
};

const MenuDrawerRaw = ({ libby }: MenuDrawerProps) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [open, setOpen] = React.useState<string[]>([]);

  const {
    isOpen,
    set,
    userInfo,
    isLogged
  }: {
    isOpen: boolean;
    set: Function;
    userInfo: userInfoType;
    isLogged: boolean;
  } = useGlobalContext();

  // Handlers
  const onCloseDrawer: MouseEventHandler = useCallback(() => {
    setOpen([]);
    set(false);
  }, [set]);

  const { username = '', groups = [], retool }: { username?: string; groups?: groupType[]; retool?: Retool_account_menu } = userInfo || {};
  const retool_menus = retool?.menus;

  const getInitials = `${(username && username.length && username[0].charAt(0)) || ''}${(username && username.length && username[1].trim().charAt(0)) || ''}`;
  const handleClick = (value: string | undefined) => {
    setOpen((prev: any[]) => {
      const copy = [...prev];
      const result = copy.indexOf(value);

      if (result === -1) {
        copy.push(value);
      } else {
        copy.splice(result, 1);
      }
      return copy;
    });
  };

  return (
    <Drawer open={isOpen} onClose={onCloseDrawer}>
      <div role="presentation">
        <div className={classes.list}>
          <div className={classes.toolbar}>
            <Typography variant="h6">{t('Menu')}</Typography>
          </div>
          <Divider />
          {isLogged && (
            <div className={classes.userContainer}>
              <Avatar className={classes.avatar}>{toUpper(getInitials)}</Avatar>
              <div className={classes.profile}>
                <Typography variant="h5">{username}</Typography>
                <Typography variant="h6">{groups.length > 0 && t(groups[0].name)}</Typography>
              </div>
            </div>
          )}
          <Divider />
          {(authorizeMenu(userInfo, MENU.ORDER) ||
            authorizeMenu(userInfo, MENU.INBOX) ||
            authorizeMenu(userInfo, MENU.IMPORTS) ||
            authorizeMenu(userInfo, MENU.CANAL_OFF_LINE) ||
            authorizeMenu(userInfo, MENU.BUDGET) ||
            authorizeMenu(userInfo, MENU.LIQUIDATIONS)) && (
            <>
              <ListItem button onClick={() => handleClick('order')}>
                <ListItemIcon>
                  <CreditCardIcon />
                </ListItemIcon>
                <ListItemText primary={t('Orders')} />
                {open.includes('order') ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
              <Collapse in={open.includes('order')} timeout="auto" unmountOnExit>
                <List component="div" disablePadding className={classes.marginSub}>
                  {authorizeMenu(userInfo, MENU.ORDER) && (
                    <ListItem className={classes.nested} onClick={onCloseDrawer}>
                      <MenuDrawerItem className={classes.title} label="Orders" path="/orders" />
                    </ListItem>
                  )}
                  {authorizeMenu(userInfo, MENU.INBOX) && (
                    <ListItem className={classes.nested} onClick={onCloseDrawer}>
                      <MenuDrawerItem className={classes.title} label="Inbox" path="/inbox-orders" />
                    </ListItem>
                  )}
                  {authorizeMenu(userInfo, MENU.IMPORTS) && (
                    <ListItem className={classes.nested} onClick={onCloseDrawer}>
                      <MenuDrawerItem className={classes.title} label="Massive Uploads" path={`/imports/${ImportationsTargets.INBOX_ORDER}`} />
                    </ListItem>
                  )}
                  {authorizeMenu(userInfo, MENU.CANAL_OFF_LINE) && (
                    <ListItem className={classes.nested} onClick={onCloseDrawer}>
                      <MenuDrawerItem className={classes.title} label="Canal off line" path="/canal-off-line" />
                    </ListItem>
                  )}
                  {authorizeMenu(userInfo, MENU.BUDGET) && (
                    <ListItem className={classes.nested} onClick={onCloseDrawer}>
                      <MenuDrawerItem className={classes.title} label="Budget" path="/budget" />
                    </ListItem>
                  )}
                  {authorizeMenu(userInfo, MENU.LIQUIDATIONS) && (
                    <ListItem className={classes.nested} onClick={onCloseDrawer}>
                      <MenuDrawerItem label="Liquidations" className={classes.title} path="/liquidations" />
                    </ListItem>
                  )}
                </List>
              </Collapse>
            </>
          )}

          {(authorizeMenu(userInfo, MENU.SHIPPING) ||
            authorizeMenu(userInfo, MENU.DISPATCH) ||
            authorizeMenu(userInfo, MENU.DISPATCH_INQUIRY) ||
            authorizeMenu(userInfo, MENU.COLLECTS) ||
            authorizeMenu(userInfo, MENU.IMPORTS) ||
            authorizeMenu(userInfo, MENU.STORE_PICKUP) ||
            authorizeMenu(userInfo, MENU.PICK_CONTROL) ||
            authorizeMenu(userInfo, MENU.CLIENT_WITH_TURNS) ||
            authorizeMenu(userInfo, MENU.RECEIPTS)) && (
            <>
              <ListItem button onClick={() => handleClick('logistics')}>
                <ListItemIcon>
                  <LocalShippingOutlinedIcon />
                </ListItemIcon>
                <ListItemText primary={t('Logistics')} />
                {open.includes('logistics') ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
              <Collapse in={open.includes('logistics')} timeout="auto" unmountOnExit>
                <List component="div" disablePadding className={classes.marginSub}>
                  {authorizeMenu(userInfo, MENU.SHIPPING) && (
                    <ListItem className={classes.nested} onClick={onCloseDrawer}>
                      <MenuDrawerItem className={classes.title} label="Shipping" path="/tag-manager" />
                    </ListItem>
                  )}
                  {authorizeMenu(userInfo, MENU.DISPATCH) && (
                    <ListItem className={classes.nested} onClick={onCloseDrawer}>
                      <MenuDrawerItem className={classes.title} label="Dispatches" path="/dispatches" />
                    </ListItem>
                  )}
                  {authorizeMenu(userInfo, MENU.DISPATCH_INQUIRY) && (
                    <ListItem className={classes.nested} onClick={onCloseDrawer}>
                      <MenuDrawerItem className={classes.title} label="Dispatches Inquiry" path="/dispatches/inquiry" />
                    </ListItem>
                  )}
                  {authorizeMenu(userInfo, MENU.COLLECTS) && (
                    <ListItem className={classes.nested} onClick={onCloseDrawer}>
                      <MenuDrawerItem className={classes.title} label="Collects" path="/collect" />
                    </ListItem>
                  )}
                  {authorizeMenu(userInfo, MENU.IMPORTS) && (
                    <ListItem className={classes.nested} onClick={onCloseDrawer}>
                      <MenuDrawerItem className={classes.title} label="Massive Uploads" path={`/imports/${ImportationsTargets.INBOX_COURIER_EVENT}`} />
                    </ListItem>
                  )}
                  {authorizeMenu(userInfo, MENU.STORE_PICKUP) && (
                    <ListItem className={classes.nested} onClick={onCloseDrawer}>
                      <MenuDrawerItem className={classes.title} label="Store Pickup" path="/store-pickup" />
                    </ListItem>
                  )}
                  {authorizeMenu(userInfo, MENU.PICK_CONTROL) && (
                    <ListItem className={classes.nested} onClick={onCloseDrawer}>
                      <MenuDrawerItem className={classes.title} label="Pick Control" path="/pick-control" />
                    </ListItem>
                  )}
                  {authorizeMenu(userInfo, MENU.CLIENT_WITH_TURNS) && (
                    <ListItem className={classes.nested} onClick={onCloseDrawer}>
                      <MenuDrawerItem className={classes.title} label="Clients with turns" path="/clients-turns" />
                    </ListItem>
                  )}
                  {authorizeMenu(userInfo, MENU.RECEIPTS) && (
                    <ListItem onClick={onCloseDrawer}>
                      <MenuDrawerItem className={classes.nested} label="Recibos" path="/receipts" />
                    </ListItem>
                  )}
                </List>
              </Collapse>
            </>
          )}

          {authorizeMenu(userInfo, MENU.CATALOG_PRODUCTS) && (
            <>
              <ListItem button onClick={() => handleClick('catalog')}>
                <ListItemIcon>
                  <ShoppingBasket />
                </ListItemIcon>
                <ListItemText primary={t('Catalog')} />
                {open.includes('catalog') ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
              <Collapse in={open.includes('catalog')} timeout="auto" unmountOnExit>
                <List component="div" disablePadding className={classes.marginSub}>
                  {authorizeMenu(userInfo, MENU.CATALOG_PRODUCTS) && (
                    <ListItem className={classes.nested} onClick={onCloseDrawer}>
                      <MenuDrawerItem className={classes.title} label="Products" path="/products" />
                    </ListItem>
                  )}
                  {authorizeMenu(userInfo, MENU.CATALOG_PRODUCTS) && (
                    <ListItem className={classes.nested} onClick={onCloseDrawer}>
                      <MenuDrawerItem className={classes.title} label="Categories" path="/categories" />
                    </ListItem>
                  )}
                  {authorizeMenu(userInfo, MENU.CATALOG_PRODUCTS) && (
                    <ListItem className={classes.nested} onClick={onCloseDrawer}>
                      <MenuDrawerItem className={classes.title} label="Brands" path="/brands" />
                    </ListItem>
                  )}
                </List>
              </Collapse>
            </>
          )}

          {(authorizeMenu(userInfo, MENU.IMPORTS) ||
            authorizeMenu(userInfo, MENU.PROVINCES) ||
            authorizeMenu(userInfo, MENU.LOCALITIES) ||
            authorizeMenu(userInfo, MENU.PROCESS_MONITOR) ||
            authorizeMenu(userInfo, MENU.ALERT) ||
            authorizeMenu(userInfo, MENU.CARRIERS)) && (
            <>
              <ListItem button onClick={() => handleClick('system')}>
                <ListItemIcon>
                  <DesktopWindowsOutlinedIcon />
                </ListItemIcon>
                <ListItemText primary={t('System')} />
                {open.includes('system') ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
              <Collapse in={open.includes('system')} timeout="auto" unmountOnExit>
                <List component="div" disablePadding className={classes.marginSub}>
                  {authorizeMenu(userInfo, MENU.PROVINCES) && authorizeMenu(userInfo, MENU.LOCALITIES) && (
                    <li>
                      <ListItem button onClick={() => handleClick('cities')}>
                        <ListItemText primary={t('Cities')} className={classes.paddingSubMenu} />
                        {open.includes('cities') ? <ExpandLess /> : <ExpandMore />}
                      </ListItem>
                      <Collapse in={open.includes('cities')} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding className={classes.marginSub}>
                          {authorizeMenu(userInfo, MENU.PROVINCES) && (
                            <ListItem className={classes.nested} onClick={onCloseDrawer}>
                              <MenuDrawerItem className={classes.title} label="Provinces" path="/states" />
                            </ListItem>
                          )}
                          {authorizeMenu(userInfo, MENU.LOCALITIES) && (
                            <ListItem className={classes.nested} onClick={onCloseDrawer}>
                              <MenuDrawerItem className={classes.title} label="Localities" path="/cities" />
                            </ListItem>
                          )}
                        </List>
                      </Collapse>
                    </li>
                  )}

                  {authorizeMenu(userInfo, MENU.COURIERS) && (
                    <ListItem className={classes.nested} onClick={onCloseDrawer}>
                      <MenuDrawerItem className={classes.title} label="Courier" path="/couriers" />
                    </ListItem>
                  )}

                  {authorizeMenu(userInfo, MENU.PROCESS_MONITOR) && (
                    <ListItem className={classes.nested} onClick={onCloseDrawer}>
                      <MenuDrawerItem className={classes.title} label="Process monitor" path="/process_monitor" />
                    </ListItem>
                  )}
                  {(authorizeMenu(userInfo, MENU.MESSAGES) || authorizeMenu(userInfo, MENU.TEMPLATE) || authorizeMenu(userInfo, MENU.MESSAGE_CONFIG) || authorizeMenu(userInfo, MENU.MESSAGE_CONFIG_ALERT)) && (
                    <li>
                      <ListItem button onClick={() => handleClick('messages')}>
                        <ListItemText primary={t('Messages')} className={classes.paddingSubMenu} />
                        {open.includes('messages') ? <ExpandLess /> : <ExpandMore />}
                      </ListItem>
                      <Collapse in={open.includes('messages')} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding className={classes.marginSub}>
                          {authorizeMenu(userInfo, MENU.MESSAGES) && (
                            <ListItem className={classes.nested} onClick={onCloseDrawer}>
                              <MenuDrawerItem className={classes.title} label="Messages list" path="/messages/messages" />
                            </ListItem>
                          )}
                          {authorizeMenu(userInfo, MENU.TEMPLATE) && (
                            <ListItem className={classes.nested} onClick={onCloseDrawer}>
                              <MenuDrawerItem className={classes.title} label="Templates" path="/messages/templates" />
                            </ListItem>
                          )}
                          {authorizeMenu(userInfo, MENU.MESSAGE_CONFIG) && (
                            <ListItem className={classes.nested} onClick={onCloseDrawer}>
                              <MenuDrawerItem className={classes.title} label="Configuration" path="/messages/configuration" />
                            </ListItem>
                          )}
                          {authorizeMenu(userInfo, MENU.MESSAGE_CONFIG_ALERT) && (
                            <ListItem className={classes.nested} onClick={onCloseDrawer}>
                              <MenuDrawerItem className={classes.title} label="Alert settings" path="/messages/configuration_alert" />
                            </ListItem>
                          )}
                        </List>
                      </Collapse>
                    </li>
                  )}
                  {authorizeMenu(userInfo, MENU.ALERT) && (
                    <ListItem className={classes.nested} onClick={onCloseDrawer}>
                      <MenuDrawerItem className={classes.title} label="Alerts" path="/system/alerts" />
                    </ListItem>
                  )}

                  {authorizeMenu(userInfo, MENU.CARRIERS) && (
                    <ListItem className={classes.nested} onClick={onCloseDrawer}>
                      <MenuDrawerItem className={classes.title} label="Carriers" path="/carriers" />
                    </ListItem>
                  )}
                </List>
              </Collapse>
            </>
          )}

          {(authorizeMenu(userInfo, MENU.ERP_ORDERS) ||
            authorizeMenu(userInfo, MENU.REPORTING) ||
            authorizeMenu(userInfo, MENU.REPORTING_SALES_TRANSPORT) ||
            authorizeMenu(userInfo, MENU.REPORTING_SALES) ||
            authorizeMenu(userInfo, MENU.REPORTING_SALES_TAXATION) ||
            authorizeMenu(userInfo, MENU.REPORTING_SALES_ALL_STATES) ||
            authorizeMenu(userInfo, MENU.REPORTING_OBJECT_SALES) ||
            authorizeMenu(userInfo, MENU.REPORTING_LOGISTIC) ||
            authorizeMenu(userInfo, MENU.AGING_REPORT) ||
            authorizeMenu(userInfo, MENU.REPORT_PENDING_ANDREANI_INCOME) ||
            authorizeMenu(userInfo, MENU.REPORTING_ML) ||
            authorizeMenu(userInfo, MENU.REPORTING_DASHBOARD) ||
            authorizeMenu(userInfo, MENU.REPORT_B2B_STOCK)) && (
            <>
              <ListItem button onClick={() => handleClick('reports')}>
                <ListItemIcon>
                  <DesktopWindowsOutlinedIcon />
                </ListItemIcon>
                <ListItemText primary={t('Reports')} />
                {open.includes('reports') ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
              <Collapse in={open.includes('reports')} timeout="auto" unmountOnExit>
                <List component="div" disablePadding className={classes.marginSub}>
                  {authorizeMenu(userInfo, MENU.REPORTING_DASHBOARD) && (
                    <ListItem className={`${classes.nested} ${classes.subMenu}`}>
                      <ListItem button onClick={() => handleClick('quicksight reports')}>
                        <ListItemText primary={t('Quicksight reports')} />
                        {open.includes('quicksight reports') ? <ExpandLess /> : <ExpandMore />}
                      </ListItem>
                      <Collapse in={open.includes('quicksight reports')} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                          <ListItem className={classes.nested} onClick={onCloseDrawer}>
                            <MenuDrawerItem className={classes.title} label="Purchases" path="/reports-quicksight/purchases" />
                          </ListItem>
                          <ListItem className={classes.nested} onClick={onCloseDrawer}>
                            <MenuDrawerItem className={classes.title} label="Sales" path="/reports-quicksight/sales" />
                          </ListItem>
                          <ListItem className={classes.nested} onClick={onCloseDrawer}>
                            <MenuDrawerItem className={classes.title} label="Stock" path="/reports-quicksight/stock" />
                          </ListItem>
                        </List>
                      </Collapse>
                    </ListItem>
                  )}
                  {authorizeMenu(userInfo, MENU.REPORTING_DASHBOARD) && (
                    <ListItem className={classes.nested} onClick={onCloseDrawer}>
                      <MenuDrawerItem className={classes.title} label="Reporting dashboard" path="/reporting-dashboard" />
                    </ListItem>
                  )}
                  {authorizeMenu(userInfo, MENU.ERP_ORDERS) && (
                    <ListItem className={classes.nested} onClick={onCloseDrawer}>
                      <MenuDrawerItem className={classes.title} label="ERP Orders" path="/erp-orders" />
                    </ListItem>
                  )}
                  {authorizeMenu(userInfo, MENU.REPORTING) && (
                    <ListItem className={classes.nested} onClick={onCloseDrawer}>
                      <MenuDrawerItem label="Orders by state" className={classes.title} path="/reporting" />
                    </ListItem>
                  )}
                  {authorizeMenu(userInfo, MENU.REPORTING_SALES_TRANSPORT) && (
                    <ListItem className={classes.nested} onClick={onCloseDrawer}>
                      <MenuDrawerItem label="Orders by transport" className={classes.title} path="/reporting-transport" />
                    </ListItem>
                  )}
                  {authorizeMenu(userInfo, MENU.REPORTING_SALES) && (
                    <ListItem className={classes.nested} onClick={onCloseDrawer}>
                      <MenuDrawerItem label="Sales" className={classes.title} path="/reporting-sales" />
                    </ListItem>
                  )}
                  {authorizeMenu(userInfo, MENU.REPORTING_SALES_TAXATION) && (
                    <ListItem className={classes.nested} onClick={onCloseDrawer}>
                      <MenuDrawerItem label="Sales taxation" className={classes.title} path="/reporting-sale-taxation" />
                    </ListItem>
                  )}
                  {authorizeMenu(userInfo, MENU.REPORTING_SALES_ALL_STATES) && (
                    <ListItem className={classes.nested} onClick={onCloseDrawer}>
                      <MenuDrawerItem label="Orders" className={classes.title} path="/reporting-orders" />
                    </ListItem>
                  )}
                  {authorizeMenu(userInfo, MENU.REPORTING_OBJECT_SALES) && (
                    <ListItem className={classes.nested} onClick={onCloseDrawer}>
                      <MenuDrawerItem label="Sales by goals" className={classes.title} path="/reporting-goal-sales" />
                    </ListItem>
                  )}
                  {authorizeMenu(userInfo, MENU.REPORTING_OBJETIVE_OFFLINE_SALE) && (
                    <ListItem className={classes.nested} onClick={onCloseDrawer}>
                      <MenuDrawerItem label="Offline sales by goals" className={classes.title} path="/reporting-goal-offline-sales" />
                    </ListItem>
                  )}
                  {authorizeMenu(userInfo, MENU.REPORTING_LOGISTIC) && (
                    <ListItem className={classes.nested} onClick={onCloseDrawer}>
                      <MenuDrawerItem label="Delivery control" className={classes.title} path="/reporting-logistic" />
                    </ListItem>
                  )}
                  {authorizeMenu(userInfo, MENU.AGING_REPORT) && (
                    <ListItem className={classes.nested} onClick={onCloseDrawer}>
                      <MenuDrawerItem label="Aging report" className={classes.title} path="/aging-report" />
                    </ListItem>
                  )}
                  {authorizeMenu(userInfo, MENU.REPORT_PENDING_ANDREANI_INCOME) && (
                    <ListItem className={classes.nested} onClick={onCloseDrawer}>
                      <MenuDrawerItem className={classes.title} label="Report pending andreani income" path="/report_pending_andreani_income" />
                    </ListItem>
                  )}
                  {authorizeMenu(userInfo, MENU.REPORTING_ANDREANI_TRACKING) && (
                    <ListItem className={classes.nested} onClick={onCloseDrawer}>
                      <MenuDrawerItem className={classes.title} label="Seguimiento Andreani" path="/reporting-andreani-tracking" />
                    </ListItem>
                  )}
                  {authorizeMenu(userInfo, MENU.REPORTING_ML) && (
                    <ListItem className={classes.nested} onClick={onCloseDrawer}>
                      <MenuDrawerItem label="Mercadolibre" className={classes.title} path="/reporting-ml" />
                    </ListItem>
                  )}
                  {authorizeMenu(userInfo, MENU.REPORT_B2B_STOCK) && (
                    <ListItem className={classes.nested} onClick={onCloseDrawer}>
                      <MenuDrawerItem label="Report B2B Stock" className={classes.title} path="/report-b2b-stock" />
                    </ListItem>
                  )}
                  {authorizeMenu(userInfo, MENU.STOCK_DAYS_REPORT) && (
                    <ListItem className={classes.nested} onClick={onCloseDrawer}>
                      <MenuDrawerItem label="Stock days report" className={classes.title} path="/report-stock-days" />
                    </ListItem>
                  )}
                  {authorizeMenu(userInfo, MENU.CHARGEBACK_REPORT) && (
                    <ListItem className={classes.nested} onClick={onCloseDrawer}>
                      <MenuDrawerItem label="Chargeback report" className={classes.title} path="/report-chargeback" />
                    </ListItem>
                  )}
                </List>
              </Collapse>
            </>
          )}
          {(authorizeMenu(userInfo, MENU.STOCK) || authorizeMenu(userInfo, MENU.FRAUD_ADMINISTRATOR) || authorizeMenu(userInfo, MENU.STOCK_LOGS) || !!retool_menus?.length) && (
            <>
              <ListItem button onClick={() => handleClick('ecommerce')}>
                <ListItemIcon>
                  <StoreMallDirectoryOutlinedIcon />
                </ListItemIcon>
                <ListItemText primary={t('Ecommerce')} />
                {open.includes('ecommerce') ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
              <Collapse in={open.includes('ecommerce')} timeout="auto" unmountOnExit>
                {(authorizeMenu(userInfo, MENU.STOCK) || authorizeMenu(userInfo, MENU.STOCK_LOGS)) && (
                  <List component="div" disablePadding className={classes.marginSub}>
                    <ListItem button onClick={() => handleClick('stock')}>
                      <ListItemText primary={t('Stock')} className={classes.paddingSubMenu} />
                      {open.includes('stock') ? <ExpandLess /> : <ExpandMore />}
                    </ListItem>
                    <Collapse in={open.includes('stock')} timeout="auto" unmountOnExit>
                      <List component="div" disablePadding className={classes.marginSub}>
                        {authorizeMenu(userInfo, MENU.STOCK) && (
                          <ListItem className={classes.nested} onClick={onCloseDrawer}>
                            <MenuDrawerItem className={classes.title} label="Stock Administrator" path="/stock" />
                          </ListItem>
                        )}
                      </List>
                      <List component="div" disablePadding className={classes.marginSub}>
                        {authorizeMenu(userInfo, MENU.STOCK_LOGS) && (
                          <ListItem className={classes.nested} onClick={onCloseDrawer}>
                            <MenuDrawerItem className={classes.title} label="Stock Movements" path="/stock-logs" />
                          </ListItem>
                        )}
                      </List>
                    </Collapse>
                  </List>
                )}

                <List component="div" disablePadding className={classes.marginSub}>
                  {authorizeMenu(userInfo, MENU.FRAUD_ADMINISTRATOR) && (
                    <ListItem className={classes.nested} onClick={onCloseDrawer}>
                      <MenuDrawerItem className={classes.title} label="Fraud Administrator" path="/fraud-administrator" />
                    </ListItem>
                  )}
                </List>

                {retool_menus?.map(({ name, menus }) => (
                  <>
                    <List component="div" disablePadding className={classes.marginSub}>
                      {menus.length === 1 ? (
                        <ListItem className={classes.nested} onClick={onCloseDrawer}>
                          <MenuDrawerItem className={classes.title} label={menus[0].name} path={menus[0].path} />
                        </ListItem>
                      ) : (
                        <>
                          <ListItem button onClick={() => handleClick(name)}>
                            <ListItemText primary={t(name)} className={classes.paddingSubMenu} />
                            {open.includes(name) ? <ExpandLess /> : <ExpandMore />}
                          </ListItem>

                          <Collapse in={open.includes(name)} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding className={classes.marginSub}>
                              {menus?.map((menuList) => (
                                <ListItem className={classes.nested} onClick={onCloseDrawer}>
                                  <MenuDrawerItem className={classes.title} label={menuList.name} path={menuList.path} />
                                </ListItem>
                              ))}
                            </List>
                          </Collapse>
                        </>
                      )}
                    </List>
                  </>
                ))}
              </Collapse>
            </>
          )}
          {authorizeMenu(userInfo, MENU.ACCOUNTS) && (
            <>
              <ListItem className={classes.nested2} onClick={onCloseDrawer}>
                <MenuDrawerItem className={classes.title} label="Accounts" icon={<AccountCircleIcon />} path="/accounts" />
              </ListItem>
            </>
          )}
          <Divider />
          {isLogged && (
            <ListItem className={classes.nested2} onClick={onCloseDrawer}>
              <MenuDrawerItem
                label="Logout"
                icon={<LogoutIcon />}
                onItemClick={() => {
                  libby.session.logout();
                }}
              />
            </ListItem>
          )}
        </div>
      </div>
    </Drawer>
  );
};

export const MenuDrawer = DatabaseConnector(MenuDrawerRaw)();
