import { Product_stock_error_sync } from 'interfaces/business';
import { makeBusinessEntity } from '../../lib/libby/generator/makeBusinessEntity';

const DAO_NAME = 'product_stock_error_sync';

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch }
} = makeBusinessEntity<Product_stock_error_sync>(DAO_NAME);

// hooks
export const useProductStockSyncErrorFetchById = fetchById;
export const useProductStockSyncErrorLibbyCall = libbyCall;
export const useProductStockSyncErrorDAO = libbyDAO;
export const useProductStockSyncErrorLibbyFetch = libbyFetch;
