import React from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import { ReportingSalesList } from '../ReportingSales/routes';

export const ReportingOrders = () => {
  const match = useRouteMatch();

  return (
    <Switch>
      <Route exact path={`${match.path}`}>
        <ReportingSalesList typeReporting="Orders" daoName="ster_order_reporting_orders" daoNameHistory="ster_order_reporting_orders_history_marketplace" />
      </Route>
    </Switch>
  );
};
