import React from 'react';
import { useHistory } from 'react-router-dom';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import DeleteIcon from '@material-ui/icons/Delete';
import PermIdentityIcon from '@material-ui/icons/PermIdentity';
import RestoreFromTrashIcon from '@material-ui/icons/RestoreFromTrash';
import { MakeCell } from '../../../../components/MakeCell';
import { useTranslation } from '../../../../../services/translation';
import { AnyObject } from '../../../../../types/types';
import { Account } from '../../../../../interfaces/business';

type MakeRowsProps = {
  data: AnyObject;
  match: AnyObject;
};

export const MakeRows = ({ data = [], match }: MakeRowsProps) => {
  const { t } = useTranslation();
  const history = useHistory();

  return data.map((account: Account) => {
    const accountLabel = <MakeCell onClick={() => history.push(`${match.path}/${account.account_id}/1`)} label={account.username} icon={VpnKeyIcon} />;
    const roleLabel: JSX.Element = <MakeCell onClick={() => history.push(`${match.path}/${account.account_id}/2`)} label={t(account.role.role)} icon={PermIdentityIcon} />;

    const statusLabel = (
      <MakeCell onClick={() => history.push(`${match.path}/${account.account_id}/3`)} label={account.account_enabled ? t('Enabled') : t('Disabled')} icon={account.account_enabled ? DeleteIcon : RestoreFromTrashIcon} />
    );

    return {
      id: account.account_id,
      account_id: account.account_id,
      username: accountLabel,
      role: roleLabel,
      account_enabled: account.account_enabled,
      status: statusLabel
    };
  });
};
