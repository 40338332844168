import React from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import { MENU, MENU_ACTIONS } from '../../../const';
import { useMenuAction } from '../../../hooks/useMenuActions';
import { ReportingLogisticList } from './routes';
import { ReportingLogisticJustifyList } from './routes/ReportingLogisticJustifyList';
import { ReportingLogisticNonWorkingDayList } from './routes/ReportingLogisticNonWorkingDayList';
import { ReportingLogisticSlaList } from './routes/ReportingLogisticSlaList';
import { ReportingDeliverOrdersList } from './routes/ReportingDeliverOrders';

export const ReportingLogistic = () => {
  const match = useRouteMatch();

  const { validateMenuActions } = useMenuAction(MENU.REPORTING_LOGISTIC);

  return (
    <Switch>
      <Route exact path={`${match.path}`}>
        <ReportingLogisticList />
      </Route>
      {validateMenuActions(MENU_ACTIONS.SETTINGS.toString()) && (
        <>
          <Route exact path={`${match.path}/sla`}>
            <ReportingLogisticSlaList />
          </Route>
          <Route exact path={`${match.path}/non-working-day`}>
            <ReportingLogisticNonWorkingDayList />
          </Route>
          <Route exact path={`${match.path}/justify`}>
            <ReportingLogisticJustifyList />
          </Route>
          <Route exact path={`${match.path}/deliver`}>
            <ReportingDeliverOrdersList />
          </Route>
        </>
      )}
    </Switch>
  );
};
