import { LibbyFetchDAO } from '../LibbyFetchDAO';

export class GoalMarketplaceEnabledDAO extends LibbyFetchDAO {
  constructor() {
    super('ster_goal_marketplace_enabled', 'marketplace_id');
  }

  getAllGoalMarketplaceEnabled() {
    return this.query().limit(0, 10000).run();
  }
}
