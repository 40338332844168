import React from 'react';
import Button from '@material-ui/core/Button';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from '../../../../../services/translation';
import { Account } from '../../../../../interfaces/business';

const useStyles = makeStyles(() => ({
  buttonsContainer: {
    margin: '16px 0 8px 0',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    '& button': {
      marginLeft: '10px'
    }
  }
}));

type ActionButtonsProps = {
  account: Account | null;
  duplicate: boolean;
  working: boolean;
  disabledSave: boolean;
  onSearchChange: () => void;
  onCancelClick: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
};

export const ActionButtons = ({ account, duplicate, working, disabledSave, onSearchChange, onCancelClick }: ActionButtonsProps) => {
  const { t } = useTranslation();
  const history = useHistory();
  const classes = useStyles();

  return (
    <div className={classes.buttonsContainer}>
      {!account && !working && !duplicate && (
        <Button
          variant="contained"
          onClick={(e) => {
            e.preventDefault();
            onSearchChange();
          }}
          color="primary"
          type="submit"
        >
          {t('Check Availability')}
        </Button>
      )}

      {duplicate && (
        <Button
          variant="contained"
          onClick={() => {
            history.push('/accounts', {
              initSearch: account?.username,
              account_id: account?.account_id
            });
          }}
          color="primary"
          type="button"
          disabled={working}
        >
          {t('Search User')}
        </Button>
      )}

      {!duplicate && !!account && (
        <Button variant="contained" color="primary" type="submit" disabled={working || disabledSave}>
          {t('Save')}
        </Button>
      )}

      {(!!account || duplicate) && (
        <Button variant="contained" href="" onClick={onCancelClick}>
          {t('Cancel')}
        </Button>
      )}
    </div>
  );
};
