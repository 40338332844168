import { makeStyles } from '@material-ui/styles';

export const useModalFormStyles = makeStyles(() => ({
  content: {
    '& > div:nth-child(n+1)': {
      marginTop: '15px'
    },
    '& label': {
      color: '#999999'
    },
    '& input': {
      color: '#333333'
    },
    '& textarea': {
      color: '#333333'
    },
    '& .MuiSelect-root.MuiSelect-select.MuiSelect-selectMenu.MuiInputBase-input.MuiInput-input': {
      color: '#333333'
    }
  },
  title: {
    overflow: 'visible',
    '& > h3': {
      fontFamily: '"Oswald"'
    }
  }
}));
