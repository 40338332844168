import React, { FC, useCallback, useEffect, useState } from 'react';
import { Button, Grid, IconButton, MenuItem, TextField, Theme, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import { Add } from '@material-ui/icons';
import { useTranslation } from '../../../../../../../../services/translation';

import { useBudgetContext } from '../../../../context';
import { useStyles } from '../../../../const';
import { ModalBillingAddressEdit } from './Modals';

export const BillingAddress: FC<any> = () => {
  const [openEditModal, setOpenEditModal] = useState(false);
  const { t } = useTranslation();
  const { selectedClient, addBillingAddressOptions, provinces, selectBillingAddress, billingAddress, setSelectedProvinces, selectedProvince } = useBudgetContext();
  const classes = useStyles();
  const { billingAddresses, billingAddressOptions, selectedBillingAddress } = billingAddress;
  const theme = useTheme<Theme>();
  const matches = useMediaQuery(theme.breakpoints.up(430));

  const selectBilling = useCallback(
    (value) => {
      selectBillingAddress(value);
    },
    [selectBillingAddress]
  );

  useEffect(() => {
    if (provinces.length) {
      // @ts-ignore
      addBillingAddressOptions(selectedClient, provinces);
    }
  }, [selectedClient, provinces, addBillingAddressOptions]);

  useEffect(() => {
    // @ts-ignore
    if (!selectedProvince?.state_id && billingAddresses?.length) setSelectedProvinces(provinces?.find((province: any) => province.state_id === billingAddresses[billingAddresses.length - 1].city?.state?.state_id?.toString()));
  }, [provinces, billingAddress, billingAddresses, setSelectedProvinces, selectedProvince]);

  return selectedClient.data ? (
    <Grid container style={{ marginBottom: '10px', display: 'flex', justifyContent: 'space-between' }}>
      {selectedBillingAddress === '' && (
        <Grid xs={12}>
          <Typography variant="h1" className={classes.title}>{`${t('Billing Address')}`}</Typography>
        </Grid>
      )}
      <Grid container xs={selectedBillingAddress === '' ? 12 : 8}>
        <TextField
          placeholder={t('Billing Addresses')}
          label={selectedBillingAddress !== '' ? t('Billing Addresses') : null}
          select
          variant="standard"
          value={selectedBillingAddress}
          onChange={(value: any) => selectBilling(value.target.value)}
          InputLabelProps={{ shrink: true }}
          style={{ width: '100%', maxWidth: '500px' }}
          disabled={billingAddressOptions?.length === 0}
        >
          {billingAddressOptions?.map((item: any) => (
            <MenuItem key={item.id} value={item.id}>
              {item.value}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
      {selectedBillingAddress !== '' &&
        (matches ? (
          <Grid container xs={4} alignItems="center" justify="center">
            <Button style={{ padding: '1px', width: '90%', maxWidth: '150px' }} type="button" variant="contained" color="primary" onClick={() => setOpenEditModal(true)}>
              {t('Add')}
            </Button>
          </Grid>
        ) : (
          <IconButton>
            <Add style={{ width: '40px', height: '40px' }} color="primary" onClick={() => setOpenEditModal(true)} />
          </IconButton>
        ))}
      <ModalBillingAddressEdit open={openEditModal} onClose={() => setOpenEditModal(false)} />
    </Grid>
  ) : null;
};
