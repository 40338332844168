import React from 'react';
import SearchIcon from '@material-ui/icons/Search';
import { Grid, TextField } from '@material-ui/core';
import { AnyObject } from '../../../types';
import { useTranslation } from '../../../services/translation';
import { makeFilter } from '../utils/MakeFilter';
import { FilterBar } from '../../../components/FilterBar';
import { gridBreakpoints, gridBreakpointsFields } from '../../../const';
import { useInitFilterDebounced, useInitFilter, useInitFilterArray } from '../../OrderReport/routes/OrderReportList/hook';
import { GeneralSelect } from '../../../components/GeneralSelect';
import { SelectStockMarketplaces } from './SelectStockMarketplaces';

type SearchFiltersProps = {
  onFilterChange: (makeFilter: object) => void;
  filter: AnyObject;
  resetFilter: () => void;
};

const SearchFiltersRaw = ({ onFilterChange, filter, resetFilter }: SearchFiltersProps) => {
  const { t } = useTranslation();

  const { search: sku, handleChange: handleChangeSku } = useInitFilterDebounced({
    initFetch: filter.sku,
    init: '',
    onFilterChange,
    id: 'sku',
    makeFilter
  });

  const { search, handleChange } = useInitFilterDebounced({
    initFetch: filter.search,
    init: '',
    onFilterChange,
    id: 'search',
    makeFilter
  });

  const { search: name, handleChange: handleChangeName } = useInitFilterDebounced({
    initFetch: filter.name,
    init: '',
    onFilterChange,
    id: 'name',
    makeFilter
  });

  const { search: syncStock, handleChangeFilter: handleChangeSyncStock } = useInitFilter({
    initFetch: filter.syncStock,
    init: '0',
    onFilterChange,
    id: 'syncStock',
    makeFilter
  });

  const { search: minorStock, handleChangeFilter: handleChangeMinorStock } = useInitFilter({
    initFetch: filter.minorStock,
    init: '0',
    onFilterChange,
    id: 'minorStock',
    makeFilter
  });

  const { search: marketplace, handleChangeFilter: handleChangeMarketplace } = useInitFilterArray({
    initFetch: filter.marketplace,
    onFilterChange,
    id: 'marketplace',
    makeFilter
  });

  const { search: odooStock, handleChangeFilter: handleChangeOdooStock } = useInitFilter({
    initFetch: filter.odooStock,
    init: '0',
    onFilterChange,
    id: 'odooStock',
    makeFilter
  });

  return (
    <FilterBar
      defaultFilter={
        <Grid item {...gridBreakpoints}>
          <TextField
            id="standard-search"
            placeholder={t('Search')}
            type="search"
            value={search}
            onChange={handleChange}
            fullWidth
            InputLabelProps={{
              shrink: true
            }}
            InputProps={{
              endAdornment: <SearchIcon />
            }}
          />
        </Grid>
      }
      handleReset={resetFilter}
    >
      <Grid item {...gridBreakpointsFields}>
        <TextField
          id="sku"
          label={t('Sku')}
          type="search"
          value={sku}
          onChange={handleChangeSku}
          fullWidth
          InputLabelProps={{
            shrink: true
          }}
        />
      </Grid>
      <Grid item {...gridBreakpointsFields}>
        <TextField
          id="name"
          label={t('Product Name')}
          type="search"
          value={name}
          onChange={handleChangeName}
          fullWidth
          InputLabelProps={{
            shrink: true
          }}
        />
      </Grid>
      <Grid item {...gridBreakpointsFields}>
        <GeneralSelect
          label={t('Odoo Synchronization')}
          optionAll
          working={false}
          data={[
            { id: 1, value: 'Si' },
            { id: 2, value: 'No' }
          ]}
          inputProps={{
            value: syncStock,
            onChange: handleChangeSyncStock
          }}
        />
      </Grid>
      <Grid item {...gridBreakpointsFields}>
        <GeneralSelect
          label={t('Stock below Minimum')}
          optionAll
          working={false}
          data={[
            { id: 1, value: 'Si' },
            { id: 2, value: 'No' }
          ]}
          inputProps={{
            value: minorStock,
            onChange: handleChangeMinorStock
          }}
        />
      </Grid>
      <Grid item {...gridBreakpointsFields}>
        <SelectStockMarketplaces inputProps={{ value: marketplace, onChange: handleChangeMarketplace }} />
      </Grid>
      <Grid item {...gridBreakpointsFields}>
        <GeneralSelect
          label={t('Con stock en Odoo')}
          optionAll
          working={false}
          data={[
            { id: 1, value: 'Si' },
            { id: 2, value: 'No' }
          ]}
          inputProps={{
            value: odooStock,
            onChange: handleChangeOdooStock
          }}
        />
      </Grid>
    </FilterBar>
  );
};

export const SearchFilters = React.memo(SearchFiltersRaw);
