import React, { FC, useCallback, useMemo, useState } from 'react';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { IconButton, makeStyles, TextField, Theme, useMediaQuery, useTheme } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { v4 as uuid } from 'uuid';
import { Add } from '@material-ui/icons';
import InfoTable, { Column } from '../../../../../components/InfoTable';
import { AddMethodModal } from './components';
import { useBudgetContext } from '../../context';
import { PaymentMethodItem } from '../../context/BudgetContext/types';
import { LeftAndRight } from '../../../../../../components/LeftAndRight';
import { useTranslation } from '../../../../../../services/translation';

const useStyles = makeStyles({
  title: {
    fontFamily: '"Oswald"',
    verticalAlign: 'baseline',
    textTransform: 'uppercase',
    fontWeight: 200,
    fontSize: '20px',
    color: '#4B4B4B',
    textAlign: 'start'
  }
});

export interface PaymentMethodsProps {}

export const PaymentMethods: FC<PaymentMethodsProps> = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const theme = useTheme<Theme>();
  const matches = useMediaQuery(theme.breakpoints.up(430));

  const { paymentMethods, addPaymentMethod, editPaymentMethod, removePaymentMethod } = useBudgetContext();

  const [openModal, setOpenModal] = useState(false);

  const onDeliveryEdit = useCallback(
    (method: PaymentMethodItem, value: number) => {
      const days = value < 0 ? 0 : value;
      const newMethod: PaymentMethodItem = {
        ...method,
        deliverDays: days
      };
      editPaymentMethod(newMethod);
    },
    [editPaymentMethod]
  );

  const onPaymentDaysEdit = useCallback(
    (method: PaymentMethodItem, value: number) => {
      const days = value < 0 ? 0 : value;
      const newMethod: PaymentMethodItem = {
        ...method,
        paymentDays: days
      };
      editPaymentMethod(newMethod);
    },
    [editPaymentMethod]
  );

  const onDaysEdit = useCallback(
    (method: PaymentMethodItem, value: number) => {
      const days = value < 0 ? 0 : value;
      const newMethod: PaymentMethodItem = {
        ...method,
        paymentMethod: {
          ...method.paymentMethod,
          PaymentMethod: {
            ...method.paymentMethod.PaymentMethod,
            days: days
          }
        }
      };
      editPaymentMethod(newMethod);
    },
    [editPaymentMethod]
  );

  const columns = useMemo<Array<Column>>(
    () => [
      {
        id: 'name',
        label: 'Payment Method',
        noSort: true
      },
      {
        id: 'days',
        label: 'Days',
        noSort: true,
        render: (row: PaymentMethodItem) =>
          row.plazo !== 'Personalizado' ? (
            row.paymentMethod.PaymentMethod.days
          ) : (
            <TextField
              required
              variant="standard"
              type="number"
              value={row.paymentMethod.PaymentMethod.days}
              onChange={(event) => {
                onDaysEdit(row, +event.target.value);
              }}
              InputLabelProps={{ shrink: true }}
            />
          ),
        style: {
          maxWidth: '60px'
        }
      },
      {
        id: 'currency',
        label: 'Currency',
        noSort: true,
        render: (row: PaymentMethodItem) => row.Currency?.name,
        style: {
          maxWidth: '60px'
        }
      },
      {
        id: 'paidInAdvance',
        label: 'Paid in Advance',
        noSort: true,
        render: (row: PaymentMethodItem) => (row?.paidInAdvance ? t('Yes') : 'No'),
        style: {
          maxWidth: '70px'
        }
      },
      {
        id: 'deliverDays',
        label: 'Delivery Days',
        noSort: true,
        render: (row: PaymentMethodItem) => (
          <TextField
            required
            variant="standard"
            type="number"
            value={row.deliverDays}
            onChange={(event) => {
              onDeliveryEdit(row, +event.target.value);
            }}
            InputLabelProps={{ shrink: true }}
          />
        ),
        style: {
          maxWidth: '70px'
        }
      },
      {
        id: 'paymentDays',
        label: 'Deferred Payment Days',
        noSort: true,
        render: (row: PaymentMethodItem) => (
          <TextField
            required
            variant="standard"
            type="number"
            value={row.paymentDays}
            onChange={(event) => {
              onPaymentDaysEdit(row, +event.target.value);
            }}
            InputLabelProps={{ shrink: true }}
          />
        ),
        style: {
          maxWidth: '80px'
        }
      },
      {
        id: 'actions',
        label: '',
        noSort: true,
        render: (row: PaymentMethodItem) => (
          <Button style={{ width: '30px' }} color="primary" type="button" onClick={() => removePaymentMethod(row)}>
            <DeleteIcon />
          </Button>
        ),
        style: {
          maxWidth: '30px'
        }
      }
    ],
    [removePaymentMethod, onDeliveryEdit, onPaymentDaysEdit, onDaysEdit, t]
  );

  const onConfirm = useCallback(
    (method: Omit<PaymentMethodItem, 'code'>) => {
      addPaymentMethod({ ...method, code: uuid() });
      setOpenModal(false);
    },
    [addPaymentMethod]
  );

  return (
    <>
      <LeftAndRight
        left={
          <Typography variant="h1" className={classes.title}>
            {t('Payment Methods')}
          </Typography>
        }
        right={
          matches ? (
            <div style={{ textAlign: 'right', padding: 8 }}>
              <Button type="button" variant="contained" color="primary" onClick={() => setOpenModal(true)}>
                {t('Add')}
              </Button>
            </div>
          ) : (
            <IconButton onClick={() => setOpenModal(true)}>
              <Add style={{ width: '40px', height: '40px' }} color="primary" />
            </IconButton>
          )
        }
        rightProps={{ style: { display: 'flex', flexDirection: 'column', alignItems: 'flex-end' } }}
        leftProps={{ style: { display: 'flex', flexDirection: 'column', justifyContent: 'center' } }}
      />
      <InfoTable columns={columns} rows={paymentMethods} rowIdKey="id" height="auto" tableContainerStyles={{ minHeight: '140px' }} />
      <AddMethodModal open={openModal} onCancel={() => setOpenModal(false)} onConfirm={onConfirm} />
    </>
  );
};
