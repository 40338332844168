import { FeaturesGroupTable } from '../components/FeaturesGroupTable';
import { FeaturesTable } from '../components/FeaturesTable';
import { columnsFeaturesGroup, columnsFeatures } from 'models/products/columns';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import useProductFeaturesLogic from '../hook/useProductFeaturesLogic';
import { Button } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import { LibbyObject } from 'types';
import { useCallback, useState } from 'react';
import { AnyObject } from 'types';
import { FeatureGroupsLibbyProps } from '../ProductDetail';

type FeaturesDetailProps = { libby: LibbyObject; featuresLibbyValues: FeatureGroupsLibbyProps; id: number | string };

export const FeaturesDetail = ({ libby, featuresLibbyValues, id }: FeaturesDetailProps) => {
  const theme = useTheme();
  const [selectedGroup, setSelectedGroup] = useState<AnyObject | null>(null);
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const { featureGroupButtons, featurepButtons, featureData, featureGroupData, featuresMainPageButtons } = useProductFeaturesLogic({ selectedGroup, featuresLibbyValues, libby, id });
  const onFeatureGroupClick = useCallback((row: any) => {
    setSelectedGroup(row);
  }, []);
  return (
    <>
      <Box sx={{ width: '100%', display: 'flex', flexDirection: isSmallScreen ? 'column' : 'row', justifyContent: 'flex-end', marginBottom: '30px' }}>
        {featuresMainPageButtons.map(({ title, onClick, variant, id: idMap }) => (
          <Button key={idMap} onClick={onClick} variant={variant} color="primary" style={{ ...(isSmallScreen ? { marginBottom: '5px', width: '100%' } : { marginRight: '10px', width: 'auto' }) }}>
            {title}
          </Button>
        ))}
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={12} lg={6}>
          <FeaturesGroupTable columns={columnsFeaturesGroup} rows={featureGroupData} buttons={featureGroupButtons} onRowClick={onFeatureGroupClick} />
        </Grid>
        <Grid item xs={12} lg={6}>
          <FeaturesTable columns={columnsFeatures} rows={featureData} buttons={featurepButtons} />
        </Grid>
      </Grid>
    </>
  );
};
