import React from 'react';
import { ScreenAligned } from '../../../components/ScreenAligned/ScreenAligned';
import { useTranslation } from '../../../services/translation';
import { LibbyObject } from '../../../types';
import InfoTable from '../../components/InfoTable';
import LoadingData from '../../components/LoadingData';
import { SearchFilters } from '../components/SearchFilters';
import { useStockLogsLogic } from '../hooks';
import { stockLogsColumns } from '../utils';

interface StockLogsListTypes {
  libby: LibbyObject;
  title: string;
}

export const StockLogsList = ({ title, libby }: StockLogsListTypes) => {
  const { working, data, rows, orderBy, direction, handleRequestSort, fetchMore, filter, setFilter } = useStockLogsLogic({ libby });
  const { t } = useTranslation();

  return (
    <ScreenAligned title={title}>
      <SearchFilters onFilterChange={setFilter} filter={filter} />
      <InfoTable onBottomScroll={fetchMore} columns={stockLogsColumns} rows={rows} data={data} onSortChange={handleRequestSort} orderBy={orderBy} direction={direction} />
      <LoadingData label={`${t('Loaded registers')}`} working={working} cant={rows.length} />
    </ScreenAligned>
  );
};
