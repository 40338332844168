import { useState, useCallback } from 'react';

let debounce: NodeJS.Timeout;
export const useDebounceState = (value: any, delay: number) => {
  // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = useState(value);

  const setValue = useCallback(
    (_value) => {
      if (debounce) {
        clearTimeout(debounce);
      }
      debounce = setTimeout(() => setDebouncedValue(_value), delay);
    },
    [delay]
  );

  return [debouncedValue, setValue];
};
