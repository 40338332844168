import { useCallback } from 'react';
import { API_URL } from '../../../config';
import { Order } from '../../../interfaces/business';
import { CREATE_PICKING_DISPATCH_DATE } from '../../../const';

export const usePickingDispatch = () => {
  const createPickingDispatchDateOdoo = useCallback(async (orders: Order[]) => {
    orders.map(async (order) => {
      await fetch(`${API_URL}/${CREATE_PICKING_DISPATCH_DATE}`, {
        method: 'POST',
        body: JSON.stringify({ order_id: order.order_id }),
        headers: { 'Content-Type': 'application/json' }
      });
    });
  }, []);

  return {
    createPickingDispatchDateOdoo
  };
};
