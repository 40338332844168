import React, { useCallback } from 'react';
import { Autocomplete } from '@material-ui/lab';
import { TextField } from '@material-ui/core';
import { AnyObject } from '../../../types';
import { useTranslation } from '../../../services/translation';
import { OrderStateTurn } from 'interfaces/business/general/OrderStateTurn';

interface WithEntityAutocompleteProps {
  autoCompleteProps?: any;
  multiple?: boolean;
  value: string;
  onChange: (newValue: string) => void;
  filter?: AnyObject;
  textFieldProps?: AnyObject;
}

const dummyFilter = {};

export const TurnAutocomplete = ({ multiple = false, autoCompleteProps, value, onChange, textFieldProps = dummyFilter }: WithEntityAutocompleteProps) => {
  const { t } = useTranslation();

  const onChangeAutoComplete = useCallback(
    (event, newValue) => {
      onChange(newValue);
    },
    [onChange]
  );

  const options: OrderStateTurn[] = [{ name: t('Yes'), order_state_turn_id: '1' }];

  return (
    <Autocomplete
      multiple={multiple}
      options={options}
      loading={false}
      onChange={onChangeAutoComplete}
      value={value || (multiple ? [] : undefined)}
      renderInput={(params) => {
        const { startAdornment } = params.InputProps;
        if (startAdornment && Array.isArray(startAdornment) && startAdornment.length > 1) {
          params.InputProps.startAdornment = <span>{startAdornment.length} Seleccionados</span>;
        }
        return <TextField {...params} variant="outlined" {...textFieldProps} />;
      }}
      getOptionLabel={(option: OrderStateTurn) => option.name}
      {...(autoCompleteProps || {})}
    />
  );
};
