import { LibbyFetchDAO } from '../LibbyFetchDAO';

export class BrandDAO extends LibbyFetchDAO {
  constructor() {
    super('ster_brand', 'brand_id');
  }

  getAllBrands() {
    return this.query().limit(0, 10000).run();
  }
}
