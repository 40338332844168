export const white: string = '#FFFFFF';

type Grey = {
  50: string;
  100: string;
  200: string;
  300: string;
  400: string;
  500: string;
  600: string;
  700: string;
  800: string;
  900: string;
};

export const grey: Grey = {
  50: '#FAFAFA',
  100: '#F5F5F5',
  200: '#EEEEEE',
  300: '#E0E0E0',
  400: '#BDBDBD',
  500: '#949499',
  600: '#757575',
  700: '#616161',
  800: '#424242',
  900: '#212121'
};

export const black: string = '#000000';

export const mainlight: string = '#FFEEEF';

export const blue: string = '#0767DB';

export const green: string = '#45B880';

export const orange: string = '#FFB822';

export const red: string = '#ED4740';

export const lightGrey: string = '#a6a4a4';

export const greyText: string = '#999999';

type Background = {
  buttonDisabled: string;
};

export const background: Background = {
  buttonDisabled: '#C4C2C4'
};

type Primary = {
  main: string;
  light: string;
  dark: string;
  white: string;
};

export const primary: Primary = {
  main: blue,
  light: '#F6F9FD',
  dark: '#0B48A0',
  white: '#ffffff'
};
