import { makeValidator, ValidatorRule, ValidatorType } from '../../../utils';

export interface SterConfigCommentData {
  comment: string;
}

export const formInitialValues = {
  comment: ''
};

const validations: ValidatorRule[] = [{ key: 'comment', validators: [ValidatorType.REQUIRED] }];

export const validate = makeValidator(validations);

export const getValidate = (keys: Array<string>) => makeValidator(keys.length > 0 ? validations.filter((val) => keys.includes(val.key)) : validations);
