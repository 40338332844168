import React, { useEffect, useMemo, useState } from 'react';
import { DatabaseConnector } from '@phinxlab/libby-rest-web';
import { makeStyles } from '@material-ui/styles';
import { Box, IconButton, Menu, MenuItem, Theme, Tooltip } from '@material-ui/core';
import { CheckCircle, Error, PriorityHigh, Warning } from '@material-ui/icons';
import { useLibbyCall } from '../../hooks';
import { LibbyObject } from '../../types';
import { useTranslation } from '../../services/translation';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    '& .MuiBadge-badge': {}
  },
  customPaper: {
    overflow: 'visible',
    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
    marginTop: theme.spacing(1.5),
    '& .MuiAvatar-root': {
      width: 32,
      height: 32,
      marginLeft: -0.5,
      marginRight: theme.spacing(1)
    },
    '&:before': {
      content: '""',
      display: 'block',
      position: 'absolute',
      top: 0,
      right: 14,
      width: 10,
      height: 10,
      backgroundColor: theme.palette.background.paper,
      transform: 'translateY(-50%) rotate(45deg)',
      zIndex: 0
    }
  },
  notificationContainer: {
    overflow: 'scroll',
    scrollbarWidth: 'none',
    '&::-webkit-scrollbar': {
      display: 'none'
    }
  },
  notificationCard: {
    display: 'flex',
    maxWidth: '317px',
    whiteSpace: 'normal',
    margin: '10px',
    borderRadius: '4px',
    padding: '15px',
    paddingLeft: '0px',
    boxShadow: 'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px'
  }
}));

const ProcessConfigAlertRaw = ({ libby }: { libby: LibbyObject }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const paramsFetchSter = useMemo(() => ({ daoName: 'ster_process_config', methodName: 'getAll' }), []);
  const { data: dataSter, recall } = useLibbyCall(libby, paramsFetchSter);

  useEffect(() => {
    setInterval(() => {
      if (recall) {
        recall();
      }
    }, 30000);
  }, [recall]);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      {dataSter.length ? (
        <Box style={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
          <Tooltip title={t('Ster processes')}>
            <IconButton onClick={handleClick} size="small" style={{ marginLeft: 2 }} aria-controls={open ? 'ster-processes' : undefined} aria-haspopup="true" aria-expanded={open ? 'true' : undefined}>
              {dataSter.length >= 1 || dataSter.length <= 3 ? <Warning style={{ width: 32, height: 32, color: '#FFB200' }} /> : <Error style={{ width: 32, height: 32, color: '#DF0000' }} />}
            </IconButton>
          </Tooltip>
        </Box>
      ) : (
        <CheckCircle style={{ width: 32, height: 32, color: '#18B33E' }} />
      )}
      <Menu
        anchorEl={anchorEl}
        id="ster-processes"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          className: classes.customPaper
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        style={{ top: '45px' }}
        disableScrollLock
      >
        <Box maxHeight={500} className={classes.notificationContainer}>
          {dataSter.map((process: any) => {
            return (
              <MenuItem onClick={handleClose} className={classes.notificationCard}>
                <Box display="flex" alignItems="center" justifyContent="center">
                  <PriorityHigh style={{ width: 84, height: 32, color: '#FFB306' }} />
                </Box>
                <Box display="flex" flexDirection="column" alignContent="start">
                  <strong>{process.name}</strong>
                  <span>{process.description}</span>
                </Box>
              </MenuItem>
            );
          })}
        </Box>
      </Menu>
    </>
  );
};

export const ProcessConfigAlert = DatabaseConnector(ProcessConfigAlertRaw)('ster_process_config');
