import React from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import { ReportingAndreaniTrackingList } from './components/ReportingAndreaniTrackingList';
import { useReportingAndreaniTracking } from './hook';

export const ReportingAdreaniTracking = () => {
  const { path } = useRouteMatch();

  const { title, working, rows, fetchMore, orderBy, direction, handleRequestSort, filter, setFilter } = useReportingAndreaniTracking();

  return (
    <Switch>
      <Route exact path={path}>
        <ReportingAndreaniTrackingList title={title} working={working} rows={rows} fetchMore={fetchMore} orderBy={orderBy} direction={direction} onSortChange={handleRequestSort} filter={filter} setFilter={setFilter} />
      </Route>
    </Switch>
  );
};
