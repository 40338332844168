import React, { useMemo } from 'react';
import { DatabaseConnector } from '@phinxlab/libby-rest-web';
import { Box, capitalize } from '@material-ui/core';
import { useLibbyFetch } from '../../../../../hooks';
import { LibbyObject } from '../../../../../types';
import { useTableSortConfig } from '../../../../../commons/hooks/useTableSortConfig';
import InfoTable from '../../../../components/InfoTable';
import { RowsType } from '../../../../../utils/tables';
import { Order_reporting_group_by_marketplace_courier } from '../../../../../interfaces/business/orders/Order_reporting_group_by_marketplace_courier';

export interface ReportingGroupByMarketplaceCourierRawProps {
  libby: LibbyObject;
  setFilter: (makeFilter: object) => void;
}

export interface ReportingGroupByMarketplaceCourierData {
  cant_logisticacriticos: string;
  courier_id: string;
  marketplace_id: string;
  mc: string;
}

const columns = [
  {
    id: 'marketplace_id',
    label: 'Marketplace',
    orderById: 'marketplace.name',
    render: ({ marketplace_id }: ReportingGroupByMarketplaceCourierData, item: any, t: (str: string) => string) => <Box whiteSpace="nowrap">{t(capitalize(marketplace_id))}</Box>
  },
  {
    id: 'courier_id',
    label: 'Courier',
    orderById: 'courier.name',
    render: ({ courier_id }: ReportingGroupByMarketplaceCourierData, item: any, t: (str: string) => string) => <Box whiteSpace="nowrap">{t(capitalize(courier_id))}</Box>
  },
  {
    id: 'cant_logisticacriticos',
    label: 'Critical amount of logistics',
    orderById: 'cant_logisticacriticos',
    style: {
      whiteSpace: 'nowrap'
    }
  }
];

export const ReportingGroupByMarketplaceCourierRaw = ({ libby }: ReportingGroupByMarketplaceCourierRawProps) => {
  const { direction, orderBy, onSortChange } = useTableSortConfig('marketplace.marketplace_id');

  const paramsFetch = useMemo(
    () => ({
      daoName: 'ster_order_report_group',
      direction,
      orderBy
    }),
    [direction, orderBy]
  );

  const { data, working } = useLibbyFetch(libby, paramsFetch);

  const rowsReportingGroupByMarketplaceCourier: RowsType[] = useMemo(() => {
    const dataReporting = data as Order_reporting_group_by_marketplace_courier[];

    return dataReporting.reduce((allReporting: RowsType[], { marketplace: { name }, courier: { name: nameCourier }, cant_logisticacriticos }: Order_reporting_group_by_marketplace_courier) => {
      allReporting.push({
        marketplace_id: name,
        courier_id: nameCourier,
        cant_logisticacriticos,
        id: nameCourier
      });

      return allReporting;
    }, []);
  }, [data]);

  return (
    <>
      <InfoTable columns={columns} rows={rowsReportingGroupByMarketplaceCourier} onSortChange={onSortChange} orderBy={orderBy} direction={direction} working={working} height="auto" padding={false} />
    </>
  );
};

export const ReportingGroupByMarketplaceCourier = DatabaseConnector(ReportingGroupByMarketplaceCourierRaw)('ster_order_report_group');
