import React, { useMemo, useCallback } from 'react';
import { Order_reporting_ml_all } from '../../../interfaces/business';
import { MenuItemComponentType, MenuActions } from '../../../components/MenuActions';
import CustomModal from '../../../services/customFormDialog';
import { ShowDetails } from '../components/ShowDetails';
import useMenuArrayData from './useMenuArray';
import { useTranslation } from '../../../services/translation';

interface dataTypes {
  data_urbantech_ml: Order_reporting_ml_all | undefined;
  data_novatech_ml: Order_reporting_ml_all | undefined;
  data_tienda_oficial_ml: Order_reporting_ml_all | undefined;
}

interface useRowsTypes {
  working: {
    working_tienda_oficial_ml: boolean;
    working_novatech_ml: boolean;
    working_urbantech_ml: boolean;
  };
  data: dataTypes;
}

const ShowDetailsDialog = CustomModal(ShowDetails);

export default function useRows({ working, data }: useRowsTypes) {
  const { data_urbantech_ml, data_novatech_ml, data_tienda_oficial_ml } = data;
  const { working_novatech_ml, working_urbantech_ml, working_tienda_oficial_ml } = working;
  const { handleDataMenuArray } = useMenuArrayData();
  const { t } = useTranslation();

  const menuArray = useCallback(
    (dataInner: any, indicator: string, search: boolean = false, distinct: boolean = false): MenuItemComponentType[] => {
      if (indicator === 'indicator-7' || indicator === 'indicator-8' || indicator === 'indicator-9') {
        const menu: MenuItemComponentType[] = [
          {
            title: 'Details',
            onClick: () => {
              ShowDetailsDialog.show({
                title: dataInner.name,
                rows: dataInner.rows,
                label: dataInner.label,
                distinct,
                search
              });
            },
            disabled: false,
            loading: working_novatech_ml || working_urbantech_ml || working_tienda_oficial_ml
          }
        ];
        return menu;
      }
      return [];
    },
    [working_novatech_ml, working_urbantech_ml, working_tienda_oficial_ml]
  );

  const rows = useMemo(() => {
    const dataDummy: any = { name: '', rows: { data_urbantech_ml: undefined, data_novatech_ml: undefined, data_tienda_oficial_ml: undefined }, label: '' };
    const distinct = true;
    const search = true;
    return [
      {
        actions: <MenuActions menuArray={menuArray(dataDummy, 'indicator-1')} />,
        id: 'indicator-1',
        name: 'Number of total claims per account',
        quantity_urbantech_meli: data_urbantech_ml?.claims,
        quantity_novatech_meli: data_novatech_ml?.claims,
        quantity_tienda_oficial_ml: data_tienda_oficial_ml?.claims,
        working_tienda_oficial_ml,
        working_novatech_ml,
        working_urbantech_ml
      },
      {
        actions: <MenuActions menuArray={menuArray(dataDummy, 'indicator-2')} />,
        id: 'indicator-2',
        name: 'Number of claims affecting reputation',
        quantity_urbantech_meli: data_urbantech_ml?.claims_dispute,
        quantity_novatech_meli: data_novatech_ml?.claims_dispute,
        quantity_tienda_oficial_ml: data_tienda_oficial_ml?.claims_dispute,
        working_tienda_oficial_ml,
        working_novatech_ml,
        working_urbantech_ml
      },
      {
        actions: <MenuActions menuArray={menuArray(dataDummy, 'indicator-3')} />,
        id: 'indicator-3',
        name: 'Total sales per account',
        quantity_urbantech_meli: data_urbantech_ml?.sales_paid,
        quantity_novatech_meli: data_novatech_ml?.sales_paid,
        quantity_tienda_oficial_ml: data_tienda_oficial_ml?.sales_paid,
        working_tienda_oficial_ml,
        working_novatech_ml,
        working_urbantech_ml
      },
      {
        actions: <MenuActions menuArray={menuArray(dataDummy, 'indicator-4')} />,
        id: 'indicator-4',
        name: 'Sales cancelled by the buyer',
        quantity_urbantech_meli: data_urbantech_ml?.sales_buyer,
        quantity_novatech_meli: data_novatech_ml?.sales_buyer,
        quantity_tienda_oficial_ml: data_tienda_oficial_ml?.sales_buyer,
        working_tienda_oficial_ml,
        working_novatech_ml,
        working_urbantech_ml
      },
      {
        actions: <MenuActions menuArray={menuArray(dataDummy, 'indicator-5')} />,
        id: 'indicator-5',
        name: 'Sales cancelled by the seller',
        quantity_urbantech_meli: data_urbantech_ml?.sales_seller,
        quantity_novatech_meli: data_novatech_ml?.sales_seller,
        quantity_tienda_oficial_ml: data_tienda_oficial_ml?.sales_seller,
        working_tienda_oficial_ml,
        working_novatech_ml,
        working_urbantech_ml
      },
      {
        actions: <MenuActions menuArray={menuArray(dataDummy, 'indicator-6')} />,
        id: 'indicator-6',
        name: 'Sales cancelled by mercadolibre',
        quantity_urbantech_meli: data_urbantech_ml?.sales_meli,
        quantity_novatech_meli: data_novatech_ml?.sales_meli,
        quantity_tienda_oficial_ml: data_tienda_oficial_ml?.sales_meli,
        working_tienda_oficial_ml,
        working_novatech_ml,
        working_urbantech_ml
      },
      {
        actions: <MenuActions menuArray={menuArray(handleDataMenuArray('indicator-7', data, t('Number of Sales by Product')), 'indicator-7', search, distinct)} />,
        id: 'indicator-7',
        name: 'Sales by Sku',
        quantity_urbantech_meli: data_urbantech_ml?.sales_by_product.total,
        quantity_novatech_meli: data_novatech_ml?.sales_by_product.total,
        quantity_tienda_oficial_ml: data_tienda_oficial_ml?.sales_by_product.total,
        working_tienda_oficial_ml,
        working_novatech_ml,
        working_urbantech_ml
      },
      {
        actions: <MenuActions menuArray={menuArray(handleDataMenuArray('indicator-8', data, t('Number of Orders by Couriers')), 'indicator-8')} />,
        id: 'indicator-8',
        name: 'Sales by Courier',
        quantity_urbantech_meli: data_urbantech_ml?.couriers.total,
        quantity_novatech_meli: data_novatech_ml?.couriers.total,
        quantity_tienda_oficial_ml: data_tienda_oficial_ml?.couriers.total,
        working_tienda_oficial_ml,
        working_novatech_ml,
        working_urbantech_ml
      },
      {
        actions: <MenuActions menuArray={menuArray(handleDataMenuArray('indicator-9', data, t('Number of Orders and Products')), 'indicator-9')} />,
        id: 'indicator-9',
        name: 'Sales by Orders and Items',
        quantity_urbantech_meli: data_urbantech_ml?.skus_and_orders.total,
        quantity_novatech_meli: data_novatech_ml?.skus_and_orders.total,
        quantity_tienda_oficial_ml: data_tienda_oficial_ml?.skus_and_orders.total,
        working_tienda_oficial_ml,
        working_novatech_ml,
        working_urbantech_ml
      }
    ];
  }, [data_urbantech_ml, data_novatech_ml, data_tienda_oficial_ml, working_tienda_oficial_ml, working_novatech_ml, working_urbantech_ml, menuArray, handleDataMenuArray, data, t]);

  return { rows };
}
