import React, { useState, useCallback, useMemo } from 'react';
import { DatabaseConnector } from '@phinxlab/libby-rest-web';
import { useSnackbar } from 'notistack';
import { AnyObject } from '../../../../../types/types';
import { PaginatedTable } from '../../../../../components/PaginatedTable';
import { Order_item } from '../../../../../interfaces/business/orders/Order_item';
import { IconButton } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CustomModal from '../../../../../services/customFormDialog';
import { FillProductAttributesModal } from './FillProductsAttributesModal';

const FillProductsAttributesModalForm = CustomModal(FillProductAttributesModal);

const styleItemRow = {
  whiteSpace: 'nowrap',
  width: '50px'
};

export const ItemsWithoutProductTableRaw = ({ libby, items = [], updateDataWithProducts, redirect }: AnyObject) => {
  const [success, setSuccess] = useState<any>();
  const { enqueueSnackbar } = useSnackbar();
  const attributesFormModal = useCallback(
    async (value: Order_item) => {
      try {
        await FillProductsAttributesModalForm.show({
          title: "Please insert package quantity, measurements and product's size",
          libby,
          rowData: value,
          updateDataWithProducts,
          redirect,
          items,
          setSuccess,
          enqueueSnackbar
        });
      } catch (error: any) {
        // nothing
      }
    },
    [libby, updateDataWithProducts, redirect, items, setSuccess, enqueueSnackbar]
  );

  const itemsColumns = useMemo(
    () => [
      {
        id: 'name',
        label: 'Name/Description',
        render: (value: Order_item) => value?.name || '--',
        style: styleItemRow
      },
      {
        id: 'sku',
        label: 'Sku',
        render: (value: Order_item) => value?.sku || ''
      },
      {
        id: 'action',
        label: 'Action',
        render: (valueId: Order_item) => (
          <IconButton color="primary" type="submit" onClick={() => attributesFormModal(valueId)} disabled={success?.[valueId.order_item_id]}>
            {!success?.[valueId.order_item_id] ? <EditIcon /> : <CheckCircleIcon />}
          </IconButton>
        )
      }
    ],
    [attributesFormModal, success]
  );

  return <PaginatedTable headCells={itemsColumns} rows={items} rowIdKey="order_item_id" />;
};

export const ItemsWithoutProductTable = DatabaseConnector(ItemsWithoutProductTableRaw)('ster_order_item_product');
