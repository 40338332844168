import React, { useCallback, useEffect } from 'react';
import { Autocomplete } from '@material-ui/lab';
import { TextField } from '@material-ui/core';
import _ from 'lodash';
import { makeEntityLibbyCall } from '../hooks';
import { AnyObject } from '../../../types';
import { useTranslation } from '../../../services/translation';

export type OptionLabelKeyFunction = <T>(option: T) => string | JSX.Element;

export interface WithEntityAutocompleteProps<T> {
  autoCompleteProps?: any;
  multiple?: boolean;
  value: undefined | T | T[];
  onChange: (newValue: T | T[]) => void;
  filter?: AnyObject;
  textFieldProps?: AnyObject;
  optionLabelKey?: string | OptionLabelKeyFunction;
  direction?: 'asc' | 'desc';
  orderBy?: string;
}

export interface WithEntityAutocompleteOptions {
  optionLabelKey: string | OptionLabelKeyFunction;
}

interface Option {
  name: string;
}

type WithEntityAutocompleteReturn = <T>(daoName: string, options: WithEntityAutocompleteOptions) => (props: WithEntityAutocompleteProps<T>) => JSX.Element;

const dummyFilter = {};
// TODO: add loading
export const withEntityAutocomplete: WithEntityAutocompleteReturn = (daoName: string, { optionLabelKey }) => {
  const useHook = makeEntityLibbyCall(daoName);

  return <T,>({ direction = 'asc', orderBy = '', multiple = false, autoCompleteProps, value, onChange, filter = dummyFilter, textFieldProps = dummyFilter, optionLabelKey: optionLabelKeyProps }: WithEntityAutocompleteProps<T>) => {
    const optionLabelKeyToUse = optionLabelKeyProps || optionLabelKey;
    const { t } = useTranslation();
    // const classes = useStyles();

    // TODO: improve this
    const { working, data, recall } = useHook({
      methodName: 'fetch',
      params: [],
      noAutoCall: true
    });

    useEffect(() => {
      if (recall) {
        const params = { filter, limit: 20000, direction, orderBy };
        recall(params);
      }
    }, [filter, direction, orderBy, recall]);

    const onChangeAutoComplete = useCallback(
      (event, newValue) => {
        onChange(newValue);
      },
      [onChange]
    );

    const getOptionLabel = useCallback((option) => (typeof optionLabelKeyToUse !== 'string' ? optionLabelKeyToUse(option) : _.get(option, optionLabelKeyToUse)), [optionLabelKeyToUse]);

    const options: Option[] = Array.isArray(data) ? data.map((option: Option): Option => ({ ...option, name: t(option.name) })) : [];

    return (
      <Autocomplete
        // classes={classes}
        multiple={multiple}
        options={options}
        loading={working}
        onChange={onChangeAutoComplete}
        value={value || (multiple ? [] : undefined)}
        renderInput={(params) => {
          const { startAdornment } = params.InputProps;
          if (startAdornment && Array.isArray(startAdornment) && startAdornment.length > 1) {
            params.InputProps.startAdornment = <span>{startAdornment.length} Seleccionados</span>;
          }
          return <TextField {...params} variant="outlined" {...textFieldProps} />;
        }}
        getOptionLabel={getOptionLabel}
        {...(autoCompleteProps || {})}
      />
    );
  };
};
