import { Moment } from 'moment';
import { Filter } from '../../../../../types';
import { AnyObject } from '../../../../../types/types';
import { endDateFormatUtc, startDateFormatUtc } from '../../../../../utils';

type MakeFilterProps = {
  search?: string;
  marketplace?: string;
  source?: string;
  shipment?: string;
  typeCourierSearch?: string;
  typeOrderCourierSearch?: string;
  typeOrderCourierMarketplaceSearch?: string;
  orderStatus?: string;
  startDate?: Moment | null;
  endDate?: Moment | null;
  ownerId?: string;
  orderId?: string;
  canalOffLine?: string[];
  orderStateStorePickup?: string[];
  courierServiceStorePickup?: string[];
  courierService?: number[];
  paidInAdvance?: string;
  brand?: string;
  category?: string;
  productSearch?: string;
};

export const makeFilter = ({
  search,
  ownerId,
  marketplace,
  source,
  shipment,
  orderStatus,
  startDate,
  endDate,
  typeCourierSearch,
  typeOrderCourierSearch,
  typeOrderCourierMarketplaceSearch,
  orderId,
  canalOffLine,
  orderStateStorePickup,
  courierServiceStorePickup,
  courierService,
  paidInAdvance,
  brand,
  category,
  productSearch
}: MakeFilterProps) => {
  const filter: Filter = {};

  if (search) {
    const valueSearch = search.split(' ').reduce((accumulator: AnyObject, item, index) => {
      accumulator[index] = [
        {
          path: 'order_id',
          value: item,
          method: 'includes'
        },
        {
          path: 'buyer.first_name',
          value: search,
          method: 'includes'
        },
        {
          path: 'so_number',
          value: item,
          method: 'includes'
        },
        {
          path: 'buyer.first_name',
          value: item,
          method: 'includes'
        },
        {
          path: 'buyer.last_name',
          value: item,
          method: 'includes'
        },
        {
          path: 'buyer.phone_number',
          value: item,
          method: 'includes'
        },
        {
          path: 'buyer.document',
          value: item,
          method: 'includes'
        },
        {
          path: 'shipment.service.courier.name',
          value: item,
          method: 'includes'
        },
        {
          path: 'shipment.service.name',
          value: item,
          method: 'includes'
        },
        {
          path: 'state.name',
          value: item,
          method: 'includes'
        },
        {
          path: 'source.marketplace.name',
          value: item,
          method: 'includes'
        },
        {
          path: 'owner_id',
          value: item,
          method: 'includes'
        }
      ];

      const numberSearch = parseInt(item, 10);
      if (numberSearch) {
        accumulator[index].concat([
          {
            path: 'amount',
            value: numberSearch,
            method: 'includes'
          },
          {
            path: 'order_id',
            value: numberSearch,
            method: 'includes'
          }
        ]);
      }
      return accumulator;
    }, {});
    filter.search = [...valueSearch[0]];
  }
  if (productSearch) {
    const valueSearch = productSearch.split(' ').reduce((accumulator: AnyObject, item, index) => {
      accumulator[index] = [
        {
          path: 'sku',
          value: item,
          method: 'includes'
        },
        {
          path: 'name',
          value: productSearch,
          method: 'includes'
        }
      ];
      return accumulator;
    }, {});

    filter.productSearch = [...valueSearch[0]];
  }

  if (orderStatus && orderStatus !== '0') {
    filter.orderStatus = [
      {
        path: 'state.order_state_id',
        value: orderStatus
      }
    ];
  }

  if (orderId) {
    filter.orderId = [
      {
        path: 'order_id',
        value: orderId,
        method: 'includes'
      }
    ];
  }

  if (ownerId) {
    filter.ownerId = [
      {
        path: 'owner_id',
        value: ownerId,
        method: 'includes'
      }
    ];
  }

  if (source && source !== '0') {
    filter.source = [
      {
        path: 'shipment.service.courier.courier_id',
        value: source
      }
    ];
  }

  if (typeCourierSearch && typeCourierSearch !== '0') {
    filter.typeCourierSearch = [
      {
        path: 'courier.courier_id',
        value: typeCourierSearch
      }
    ];
  }

  if (typeOrderCourierSearch && typeOrderCourierSearch !== '0') {
    filter.typeOrderCourierSearch = [
      {
        path: 'marketplace.marketplace_id',
        value: typeOrderCourierSearch
      }
    ];
  }

  if (typeOrderCourierMarketplaceSearch && typeOrderCourierMarketplaceSearch !== '0') {
    filter.typeOrderCourierMarketplaceSearch = [
      {
        path: 'marketplace.marketplace_id',
        value: typeOrderCourierMarketplaceSearch
      }
    ];
  }

  if (shipment && shipment !== '0') {
    filter.shipment = [
      {
        path: 'shipment.service.courier_service_id',
        value: shipment
      }
    ];
  }

  if (marketplace && marketplace !== '0') {
    filter.marketplace = [
      {
        path: 'source.marketplace.marketplace_id',
        value: marketplace
      }
    ];
  }

  if (startDate) {
    filter.startDate = [
      {
        path: 'created_at',
        value: startDateFormatUtc(startDate),
        method: 'higherOrEquals'
      }
    ];
  }
  if (endDate) {
    filter.endDate = [
      {
        path: 'created_at',
        value: endDateFormatUtc(endDate),
        method: 'lowerOrEquals'
      }
    ];
  }

  if (canalOffLine?.length) {
    filter.canalOffLine = canalOffLine.map((offLine) => ({
      path: 'source.order_source_type.order_source_type_id',
      value: offLine
    }));
  }
  if (paidInAdvance && paidInAdvance !== '0') {
    filter.paidInAdvance = [
      {
        path: 'paid_in_advance',
        value: paidInAdvance,
        method: 'equals'
      }
    ];
  }
  if (brand && brand !== '0') {
    filter.brand = [
      {
        path: 'brand.brand_id',
        value: brand,
        method: 'equals'
      }
    ];
  }
  if (category && category !== '0') {
    filter.category = [
      {
        path: 'category.category_id',
        value: category,
        method: 'equals'
      }
    ];
  }

  if (orderStateStorePickup?.length) {
    filter.orderStateStorePickup = orderStateStorePickup.map((state) => ({
      path: 'state.order_state_id',
      value: state
    }));
  }

  if (courierServiceStorePickup?.length) {
    filter.courierServiceStorePickup = courierServiceStorePickup.map((serviceCourier) => ({
      path: 'shipment.service.courier.courier_id',
      value: serviceCourier
    }));
  }

  if (courierService?.length) {
    filter.courierService = courierService.map((serviceCourier) => ({
      path: 'shipment.service.courier_service_id',
      value: serviceCourier
    }));
  }

  return filter;
};
