import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Theme, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { useTranslation } from '../../../../../../../../../services/translation';
import { useBudgetContext } from '../../../../../context';
import { textFieldProps } from '../../../../../../../../../utils';
import { useStyles } from '../../../../../const';
import { AutocompleteCityEdit } from '../../../../../../../../../components/AutocompleteCityEdit';
import { IAddress, IProvince } from '../../../../../context/BudgetContext/types';

interface IModalBillingAddressEdit {
  open: boolean;
  onClose: () => void;
}

export const ModalBillingAddressEdit = ({ open = false, onClose }: IModalBillingAddressEdit) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const theme = useTheme<Theme>();
  const matches = useMediaQuery(theme.breakpoints.up(430));
  const cityInputRef = useRef<HTMLInputElement | null>(null);

  const { provinces, setSelectedProvinces, selectedProvince, billingAddress, pushBillingAddressOptions, editBillingAddress } = useBudgetContext();

  const [editedBillingCity, setEditedBillingCity] = useState(billingAddress.selectedBillingCity);
  const [billingCityId, setBillingCityId] = useState<string>();
  const [editedBillingState, setEditedBillingState] = useState(billingAddress?.selectedBillingState);
  const [editedBillingStreet, setEditedBillingStreet] = useState(billingAddress?.selectedBillingStreet);
  const [editedBillingStreetNumber, setEditedBillingStreetNumber] = useState(billingAddress?.selectedBillingStreetNumber);
  const [editedBillingZipCode, setEditedBillingZipCode] = useState(billingAddress?.selectedBillingZipCode);
  const [editedBillingFloor, setEditedBillingFloor] = useState(billingAddress?.selectedBillingFloor);
  const [editedBillingDepartment, setEditedBillingDepartment] = useState(billingAddress?.selectedBillingDepartment);

  const handleEditing = useCallback(() => {
    const province = selectedProvince as IProvince;
    const address: IAddress = {
      street: editedBillingStreet,
      street_number: editedBillingStreetNumber,
      city: { city_id: billingCityId, name: editedBillingCity, state: province.name },
      zip: editedBillingZipCode,
      floor: editedBillingFloor,
      department: editedBillingDepartment
    };
    editBillingAddress({
      editedBillingCity,
      editedBillingState,
      editedBillingStreet,
      editedBillingStreetNumber,
      editedBillingZipCode,
      editedBillingFloor,
      editedBillingDepartment
    });
    pushBillingAddressOptions(province, address, provinces);
    onClose();
  }, [
    billingCityId,
    editBillingAddress,
    editedBillingCity,
    editedBillingDepartment,
    editedBillingFloor,
    editedBillingState,
    editedBillingStreet,
    editedBillingStreetNumber,
    editedBillingZipCode,
    onClose,
    provinces,
    pushBillingAddressOptions,
    selectedProvince
  ]);

  useEffect(() => {
    setEditedBillingCity(billingAddress?.selectedBillingCity);
    setEditedBillingState(billingAddress?.selectedBillingState);
    setEditedBillingStreet(billingAddress?.selectedBillingStreet);
    setEditedBillingStreetNumber(billingAddress?.selectedBillingStreetNumber);
    setEditedBillingZipCode(billingAddress?.selectedBillingZipCode);
    setEditedBillingFloor(billingAddress?.selectedBillingFloor);
    setEditedBillingDepartment(billingAddress?.selectedBillingDepartment);
  }, [billingAddress]);

  return (
    <Dialog open={open} aria-labelledby="form-dialog-title" fullWidth maxWidth="md">
      <DialogTitle id="form-dialog-title">
        <Typography variant="h1" className={classes.title}>{`${t('Add')}`}</Typography>
      </DialogTitle>
      <DialogContent>
        <div style={{ margin: '10px' }}>
          <Autocomplete
            id="province-search"
            options={provinces}
            // @ts-ignore
            getOptionLabel={(option) => option?.name}
            onChange={(e, value) => {
              setSelectedProvinces(value);
            }}
            // @ts-ignore
            value={selectedProvince}
            filterSelectedOptions
            renderInput={(params) => <TextField {...params} className={classes.textFieldAutocomplete} placeholder={t('Province')} />}
          />
        </div>
        <div style={{ margin: '10px' }}>
          <AutocompleteCityEdit
            className={classes.inputs}
            cityInputRef={cityInputRef}
            inputProps={{
              // @ts-ignore
              // province: parseInt(editedBillingState?.state_id),
              // province: editedBillingState?.state_id,
              province: selectedProvince?.state_id,
              // @ts-ignore
              // value: editedBillingCity,
              // @ts-ignore
              onChange: (city: { id: string; value: stirng }) => {
                setBillingCityId(city.id);
                setEditedBillingCity(city.value);
              },

              InputLabelProps: { shrink: true },
              required: true,
              error: {
                error: provinces.find((item: any) => item.name === 'city')?.error || false,
                helperText: t(provinces.find((item: any) => item.name === 'city')?.text || '').replace('$$$$', t('City'))
              }
            }}
          />
        </div>

        <TextField
          {...textFieldProps(`${t('Street')}`)}
          required
          variant="standard"
          value={editedBillingStreet}
          onChange={(event: any) => {
            setEditedBillingStreet(event.target.value);
          }}
          InputLabelProps={{ shrink: true }}
          className={classes.inputs}
          style={matches ? undefined : { maxWidth: '100%', width: '100%', paddingRight: '18px' }}
        />
        <TextField
          {...textFieldProps(`${t('Street Number')}`)}
          required
          variant="standard"
          value={editedBillingStreetNumber}
          onChange={(event: any) => setEditedBillingStreetNumber(event.target.value)}
          InputLabelProps={{ shrink: true }}
          type="number"
          className={classes.inputs}
          style={matches ? undefined : { maxWidth: '100%', width: '100%', paddingRight: '18px' }}
        />
        <TextField
          {...textFieldProps(`${t('Floor')}`)}
          required
          variant="standard"
          value={editedBillingFloor}
          onChange={(event: any) => setEditedBillingFloor(event.target.value)}
          InputLabelProps={{ shrink: true }}
          className={classes.inputs}
          style={matches ? undefined : { maxWidth: '100%', width: '100%', paddingRight: '18px' }}
        />
        <TextField
          {...textFieldProps(`${t('Department')}`)}
          required
          variant="standard"
          value={editedBillingDepartment}
          onChange={(event: any) => setEditedBillingDepartment(event.target.value)}
          InputLabelProps={{ shrink: true }}
          className={classes.inputs}
          style={matches ? undefined : { maxWidth: '100%', width: '100%', paddingRight: '18px' }}
        />
        <TextField
          {...textFieldProps(`${t('Zip')}`)}
          required
          variant="standard"
          value={editedBillingZipCode}
          onChange={(event: any) => setEditedBillingZipCode(event.target.value)}
          InputLabelProps={{ shrink: true }}
          type="number"
          className={classes.inputs}
          style={matches ? undefined : { maxWidth: '100%', width: '100%', paddingRight: '18px' }}
        />
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={() => handleEditing()} autoFocus>
          {`${t('Add')}`}
        </Button>
        <Button color="secondary" onClick={() => onClose()} autoFocus>
          {`${t('Cancel')}`}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
