import React from 'react';
import { GeneralSelect } from '../../components/GeneralSelect';
import { InputProps } from '../../types/types';

type SelectHourTypeProps = {
  inputProps: InputProps;
  optionAll?: boolean;
  [k: string]: any;
  sort?: boolean | 'asc' | 'desc';
  disabled?: boolean;
};
const data = [
  {
    id: 0,
    value: 'AM'
  },
  {
    id: 1,
    value: 'PM'
  }
];
export const SelectHourAmpM = ({ inputProps, optionAll = false, sort = 'asc', disabled = false, ...styles }: SelectHourTypeProps) => {
  return <GeneralSelect disabled={disabled} optionAll={optionAll} sort={sort} working={false} label="Time system" data={data} inputProps={inputProps} {...styles} />;
};
