import { Order } from '../../../interfaces/business';
import { makeBusinessEntity } from '../../../lib/libby/generator/makeBusinessEntity';

const DAO_NAME = 'ster_reporting_deliver_order';
const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch }
} = makeBusinessEntity<Order>(DAO_NAME);

// hooks
export const useReporingDeliverOrderFetchById = fetchById;
export const useReporingDeliverOrderLibbyCall = libbyCall;
export const useReporingDeliverOrderDAO = libbyDAO;
export const useReporingDeliverOrderLibbyFetch = libbyFetch;
