import { Company } from '../interfaces/business/marketplace/Company';
import { makeBusinessEntity, PartialBusinessEntityOptions } from '../lib/libby/generator/makeBusinessEntity';

import { businessOptions } from './businessOptions';

const DAO_NAME = 'ster_company';

const defaultOptions: PartialBusinessEntityOptions<any> = {
  hoc: {
    withEntityAutocomplete: {
      optionLabelKey: 'name'
    },
    withAccordionSelector: {
      idKey: 'company_id',
      labelKey: 'name'
    },
    withSelectedChips: {
      idKey: 'company_id',
      labelKey: 'name'
    }
  }
};

const optionsToUse = !businessOptions.Company
  ? defaultOptions
  : {
      ...defaultOptions,
      ...businessOptions.Company,
      hoc: { ...defaultOptions.hoc, ...businessOptions.Company.hoc }
    };

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch },
  hoc: { entityAutocomplete, renderPropCall, renderPropFetchById, simpleSelect, accordionSelector, selectedChips }
} = makeBusinessEntity<Company>(DAO_NAME, optionsToUse);

// hooks
export const useCompanyFetchById = fetchById;
export const useCompanyLibbyCall = libbyCall;
export const useCompanyDAO = libbyDAO;
export const useCompanyLibbyFetch = libbyFetch;

// components/hoc
export const CompanyAutocomplete = entityAutocomplete;
export const CompanyCall = renderPropCall;
export const CompanyById = renderPropFetchById;
export const CompanySelect = simpleSelect;
export const CompanyAccordionSelector = accordionSelector;
export const CompanySelectedChips = selectedChips;
