import React, { ReactNode, useRef } from 'react';
import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useContainerDimensions } from '../../hooks/useContainerDimensions';
import { useTranslation } from '../../services/translation';

const useStyles = makeStyles({
  title: {
    textAlign: 'center'
  },
  legend: {
    textAlign: 'left'
  }
});

export const ContainerChildrenLoading = ({ children, width }: { children: ReactNode; width: number }) => (
  <Box minWidth={width} m={1} flex="1" display="flex" alignItems="center" flexDirection="column">
    {children}
  </Box>
);

export const LoadingProgress = ({ progress, all, success, error }: { progress: number; all: number; success: number; error: number }) => {
  const componentRef = useRef<HTMLInputElement>(null);
  const { width } = useContainerDimensions(componentRef);
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <div ref={componentRef}>
      <Box display="flex" alignItems="center" flexDirection="column">
        <ContainerChildrenLoading width={width}>
          <CircularProgress size={24} />
        </ContainerChildrenLoading>
        <Box minWidth={width} m={1} flex="1" flexDirection="row" display="flex" alignItems="center">
          <Box minWidth={width - 35} m={1} flex="1">
            <LinearProgress variant="determinate" value={progress} />
          </Box>
          <ContainerChildrenLoading width={35}>
            <Typography variant="body2" color="textSecondary" className={classes.title}>
              {`${Math.round(progress)}%`}
            </Typography>
          </ContainerChildrenLoading>
        </Box>

        <Box flex="1" width={width} flexDirection="column" display="flex">
          <Typography variant="body2" color="textSecondary" className={classes.legend}>
            {`${t('Success')}: ${success}`}
          </Typography>
          <Typography variant="body2" color="textSecondary" className={classes.legend}>
            {`${t('Failure')}: ${error}`}
          </Typography>
        </Box>

        <ContainerChildrenLoading width={width}>
          <Typography variant="body2" color="textSecondary" className={classes.title}>
            {`${success + error} ${t('of')} ${all}`}
          </Typography>
        </ContainerChildrenLoading>
      </Box>
    </div>
  );
};
