import React, { useMemo } from 'react';
import { DatabaseConnector } from '@phinxlab/libby-rest-web';
import { useLibbyFetch } from '../../../../../../hooks';
import { AnyObject } from '../../../../../../types';
import { GeneralSelect } from '../../../../../../components/GeneralSelect';

type SelectImportTypeProps = {
  inputProps: AnyObject;
  optionAll?: boolean;
  libby: AnyObject;
  [k: string]: any;
};

const paramsFetch = {
  daoName: 'ster_justified_orders_types'
};

export const SelectJustifiedOrdersTypesRaw = ({ libby, inputProps = {}, optionAll = false, ...styles }: SelectImportTypeProps) => {
  const { data: dataRaw, working }: { data: AnyObject; working: boolean } = useLibbyFetch(libby, paramsFetch);

  const data = useMemo(
    () =>
      dataRaw.map(({ justified_orders_types_id, name }: AnyObject) => ({
        id: justified_orders_types_id,
        value: name
      })),
    [dataRaw]
  );

  const capitalizeFirstLetter = (string: string) => string.charAt(0).toUpperCase() + string.slice(1);

  data.sort((a: any, b: any) => (capitalizeFirstLetter(a.value) > capitalizeFirstLetter(b.value) ? 1 : capitalizeFirstLetter(b.value) > capitalizeFirstLetter(a.value) ? -1 : 0));

  return <GeneralSelect working={working} label="Types" optionAll={optionAll} data={data} inputProps={inputProps} {...styles} />;
};

export const SelectJustifiedOrdersTypes = React.memo(DatabaseConnector(SelectJustifiedOrdersTypesRaw)('ster_justified_orders_types'));
