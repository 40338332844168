import React, { useMemo, useCallback, MouseEvent } from 'react';
import { useSnackbar } from 'notistack';
import { useHistory } from 'react-router-dom';
import { useLibbyFetch, OptionsLibbyFetch } from '../../../hooks/useLibbyFetch';
import { AnyObject, LibbyObject } from '../../../types';
import { RowsType } from '../../../utils/tables';
import { columns } from '../utils/columns';
import { validate as validateSterConfigComment, formInitialValues } from '../utils/model';
import { MenuActions, MenuItemComponentType } from '../../../components/MenuActions';
import { SterConfigComment } from '../routes/SterConfigList/components';
import CustomModal from '../../../services/customFormDialog';
import { useTranslation } from '../../../services/translation';

const SterConfigCommentModalService = CustomModal(SterConfigComment);

type useDispatchesLogicProps = {
  libby: LibbyObject;
  paramsFetch: OptionsLibbyFetch;
};

type sterConfigType = {
  active?: boolean;
  frequency?: number;
  lastState?: string;
  last_execution?: string;
  metadata?: AnyObject;
  name?: string;
  ster_config_id: string;
  success?: string | null;
  comment?: string;
  description?: string;
};

export const useSterConfigLogic = ({ libby, paramsFetch }: useDispatchesLogicProps) => {
  const { data, working, fetchMore, updateData } = useLibbyFetch(libby, paramsFetch);
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const history = useHistory();

  const fetchMoreMemo = useCallback(() => {
    if (!working) {
      fetchMore();
    }
  }, [working, fetchMore]);

  const switchProcess = useCallback(
    async (sterConfig: sterConfigType) => {
      try {
        const formValues = (await SterConfigCommentModalService.show({ confirmText: t('Confirm'), cancelText: t('Cancel'), title: t('Confirm'), validate: validateSterConfigComment, formInitialValues })) as any;
        const updatedSterConnfig = await libby.ster_ster_config.save({ ...sterConfig, comment: formValues.comment, active: !sterConfig.active });
        updateData(updatedSterConnfig, 'ster_config_id');
        enqueueSnackbar(t('Saved changes'), { variant: 'success' });
      } catch (error: any) {
        if (error !== false) {
          enqueueSnackbar(t('Something is wrong'), { variant: 'error' });
        }
      }
    },
    [libby, updateData, t, enqueueSnackbar]
  );

  const menuArray = useCallback(
    (sterConfig: sterConfigType) => {
      const menu: MenuItemComponentType[] = [
        {
          title: sterConfig.active ? 'Disable' : 'Enable',
          onClick: (e: MouseEvent) => {
            e.stopPropagation();
            switchProcess(sterConfig);
          },
          disabled: false
        },
        {
          title: 'Logs',
          onClick: (e: MouseEvent) => {
            e.stopPropagation();
            history.push(`/process_monitor/logs/${sterConfig.ster_config_id}`);
          },
          disabled: false
        }
      ];
      return menu;
    },
    [switchProcess, history]
  );

  const rows: RowsType[] = useMemo(() => {
    const dataSterConfig = data as sterConfigType[];

    return dataSterConfig.reduce((allSterConfigRows: RowsType[], { active, frequency, lastState, last_execution, metadata, name, ster_config_id, success, comment, description }: sterConfigType) => {
      allSterConfigRows.push({
        id: ster_config_id,
        active,
        frequency,
        lastState,
        last_execution,
        metadata: JSON.stringify(metadata, null, 2),
        name,
        ster_config_id,
        success,
        comment,
        description,
        options: <MenuActions menuArray={menuArray({ active, frequency, lastState, last_execution, metadata, name, ster_config_id, success, comment, description })} />
      });

      return allSterConfigRows;
    }, []);
  }, [data, menuArray]);

  return {
    working,
    data,
    rows,
    columns,
    fetchMoreMemo
  };
};
