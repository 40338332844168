import { useCallback, useState } from 'react';
import { ThemeProvider } from '@material-ui/styles';
import { Dialog, DialogActions, DialogContent, Typography, Button, CircularProgress } from '@material-ui/core';
import { useField, useForm } from 'react-final-form-hooks';
import { ResetForm } from '../../../../../components/ResetForm';
import { FormProps } from '../../../../../types';
import { useModalFormStyles } from '../../../../../theme';
import { SelectProduct } from 'routes/components/SelectProduct';

export const CopyFeaturesForm = ({
  open = false,
  onCancel = () => {},
  onConfirm = () => {},
  title = 'Confirmation Dialog',
  cancelText = 'Cancel',
  confirmText = 'confirmText',
  customTheme = null,
  validate,
  formInitialValues = {},
  libby,
  id
}: FormProps) => {
  const styles = useModalFormStyles();
  const [loading, setLoading] = useState<boolean>(false);
  const onSubmit = useCallback(
    async ({ product }) => {
      try {
        setLoading(true);
        const copyFeaturesResponse = await libby.ster_product_copy_features.save({ product_id_from: product.value, product_id_to: id });
        onConfirm(copyFeaturesResponse);
      } catch (err) {
        console.error(err);
      } finally {
        setLoading(false);
      }
    },
    [onConfirm, libby, id, setLoading]
  );

  const { form, handleSubmit } = useForm({
    initialValues: formInitialValues,
    onSubmit,
    validate
  });
  const product = useField('product', form);

  const dialog = (
    <Dialog open={open} onClose={onCancel} fullWidth aria-labelledby="form-dialog-title">
      <DialogContent className={styles.title}>
        <Typography variant="h3">{title}</Typography>
      </DialogContent>
      <form noValidate onSubmit={handleSubmit}>
        <DialogContent className={styles.content}>
          <SelectProduct sort="asc" inputProps={{ value: product.input.value, onChange: product.input.onChange }} canalOffLine={false} variant={'outlined'} multiple={false} autocomplete={true} />
        </DialogContent>
        <DialogActions>
          <ResetForm form={form} open={open} />
          <Button
            onClick={() => {
              onCancel();
            }}
          >
            {cancelText}
          </Button>
          <Button color="primary" type="submit">
            {loading ? <CircularProgress size={24} /> : confirmText}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );

  return !customTheme ? dialog : <ThemeProvider theme={customTheme}>{dialog}</ThemeProvider>;
};
