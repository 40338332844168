import React from 'react';
import { DatabaseConnector } from '@phinxlab/libby-rest-web';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import { Grid, Button, useMediaQuery } from '@material-ui/core';
import { Theme, makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import { ScreenAligned } from '../../../../components/ScreenAligned/ScreenAligned';
import { LibbyObject } from '../../../../types/types';
import { TitleBreadcrumbs } from '../../../../interfaces';
import { useBreadcrumbs } from '../../../../hooks';
import { Collect } from '../../../../interfaces/business/dispatch/Collect';
import { useCollectsDetail } from '../../hook/useCollectsDetail';
import { OrderDetailData } from './components/OrderDetailData';
import { useTranslation } from '../../../../services/translation';
import { OrderItems } from '../../../Dispatches/routes/DispatchDetail/components/OrderItems';
import { fonts } from '../../../../theme/fonts';
import { percentageComplete } from '../../../../utils';
import { ACCOUNT_ROLE, CollectStatusPercentage, CollectItemState } from '../../../../const';
import { background, white } from '../../../../theme/colors';
import { MobileCollectDetailsHeader } from './components/MobileComponents/MobileCollectDetailsHeader';
import { MobileCollectDetailsItemList } from './components/MobileComponents/MobileCollectDetailsItemList';
import { DeleteCollects } from '../../components/DeleteCollects';
import { OrderAddButton } from './components/OrderAdd';
import { CollectState } from '../../../../const/CollectState';
import { LoadingTable } from '../../../../components/LoadingTable';
import { DialogLoading } from 'components/DialogLoading';

type ICollectDetail = {
  libby: LibbyObject;
  handleUpdateCollectList: (Collect: Collect, id: string) => void;
  title: TitleBreadcrumbs[];
  rolAdminLogistics: Boolean;
};

const useStyles = makeStyles((theme: Theme) => ({
  buttonLargeWidth: {
    fontFamily: fonts.Oswald,
    padding: '5px 22px',
    minWidth: 100
  },
  buttonSendDispatch: {
    fontFamily: fonts.Oswald,
    padding: '5px 22px',
    minWidth: 100,
    background: background.buttonDisabled,
    color: white,
    marginLeft: 15
  },
  msg: {
    textAlign: 'center'
  },
  mobileItemList: {
    marginTop: 10
  },
  [theme.breakpoints.down('xs')]: {
    boxSmHidden: {
      display: 'none'
    }
  },
  [theme.breakpoints.up('sm')]: {
    boxSmShow: {
      display: 'none'
    },
    mobileItemList: {
      display: 'none'
    }
  },
  closeCollect: {
    position: 'fixed',
    bottom: 0,
    left: 0,
    width: '100%',
    padding: 10,
    backgroundColor: theme.palette.common.white
  },
  closeCollectButton: {
    fontFamily: fonts.Oswald,
    width: '100%'
  }
}));

export const CollectDetailRaw = ({ libby, handleUpdateCollectList, title, rolAdminLogistics }: ICollectDetail) => {
  const { t } = useTranslation();
  const { id, dispatchId } = useParams<{ id: string; dispatchId: string }>();
  const { state } = useLocation<{ goBack: TitleBreadcrumbs[] }>();
  const classes = useStyles();
  const history = useHistory();
  const isDownLg = useMediaQuery<Theme>((theme) => theme.breakpoints.down(900));
  const userInfo = libby.session.user;
  const {
    data: dataDetails,
    working,
    rows,
    columnsDetails,
    checked,
    deleteItemsInOrdersItemsModal,
    msg,
    itemProductAddOrRemove,
    onCloseCollect,
    orderStateState,
    handleOnOpenCollectModal
  } = useCollectsDetail({
    libby,
    id,
    handleUpdateCollectList
  });

  const orders = dataDetails?.items?.flatMap((order: any) => order) || [];
  const rest = orders.filter((item) => item.collectItemState.collect_item_state_id === CollectItemState.COLLECTED).length || 0;
  const complete = orders ? percentageComplete(rest, orders.length) : '0';

  const isCollectCollected = dataDetails?.collectState?.collect_state_id === CollectState.COLLECTING && complete === 100;
  const couldCloseCollect = complete === CollectStatusPercentage.GREEN;
  const buttonColor = couldCloseCollect ? 'primary' : 'default';

  const titleShow = useBreadcrumbs(`${dataDetails?.dispatch?.name ? `${dataDetails.dispatch.name} | ` : ''} ${t('Collect')}`, state && state?.goBack?.length ? state.goBack : title, `#${id}`);

  const addOrdersStep = () => {
    history.push({
      pathname: dispatchId ? `/dispatches/detail/${dispatchId}/collects/detail/${id}/add-orders` : `/collect/add-orders/${id}`,
      state: {
        goBack: state?.goBack
      }
    });
  };

  return (
    <ScreenAligned
      title={titleShow}
      additionalTitle={
        <>
          {dataDetails?.cart?.cart_id && (
            <OrderAddButton
              onCloseCollect={isCollectCollected && onCloseCollect}
              disabledAdd={dataDetails?.collectState?.collect_state_id === CollectState.COMPLETE}
              addOrdersStep={addOrdersStep}
              workingData={libby.working}
              cart_id={dataDetails?.cart?.cart_id}
            />
          )}
        </>
      }
    >
      {working && <LoadingTable />}
      {userInfo?.groups[0]?.id === ACCOUNT_ROLE.LOGISTICS || userInfo?.groups[0]?.id === ACCOUNT_ROLE.ADMINISTRATOR || userInfo?.id === String(dataDetails?.account?.account_id) ? (
        <>
          {!isDownLg ? (
            <Box my={3}>
              <OrderDetailData working={working} data={dataDetails} readOnlyData={false} onEditCollect={rolAdminLogistics && handleOnOpenCollectModal} />
            </Box>
          ) : (
            <Box>
              <MobileCollectDetailsHeader id={id} data={dataDetails} loading={libby.working} />
            </Box>
          )}
          {libby.working && (
            <>
              <LoadingTable />
              <h3 className={classes.msg}>{t(msg)}</h3>
            </>
          )}
          {!isDownLg ? (
            <>
              <OrderItems rows={rows} columns={columnsDetails} working={libby.working}>
                <DeleteCollects checked={checked?.all} deleteItemsInOrdersItemsModal={deleteItemsInOrdersItemsModal} />
              </OrderItems>
            </>
          ) : (
            <>
              <MobileCollectDetailsItemList rows={rows} loading={libby.working} assignedUser={String(dataDetails?.account.account_id)} itemProductAddOrRemove={itemProductAddOrRemove} />
              {isCollectCollected && dataDetails && (
                <div className={classes.mobileItemList}>
                  <Grid className={classes.closeCollect}>
                    <Button className={classes.closeCollectButton} color={buttonColor} variant="contained" type="button" onClick={() => onCloseCollect(true)}>
                      {t('Close collect')}
                    </Button>
                  </Grid>
                </div>
              )}
            </>
          )}
        </>
      ) : (
        !working && (
          <Grid container justify="center">
            <h2 style={{ textAlign: 'center' }}>{t("You haven't permissions to see this collect.")}</h2>
          </Grid>
        )
      )}
      {orderStateState.loading && <DialogLoading title={t('Updating')} content={`${t('We are updating the orders')}: ${orderStateState.count} ${t('of')} ${orderStateState.totalCount}`} />}
    </ScreenAligned>
  );
};

export const CollectDetail = DatabaseConnector(CollectDetailRaw)(
  'ster_dispatch_collect_item',
  'ster_dispatch_item',
  'ster_dispatch_collect_close',
  'ster_dispatch_collect_details',
  'ster_dispatch_collect',
  'ster_collect_item_product',
  'ster_order_table'
);
