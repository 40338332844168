import React, { FC } from 'react';
import { Grid, Paper } from '@material-ui/core';
import { BillingAddress, Client, ShippingAddress } from './components';
import { AdvanceSale } from '../../../BudgetNew/components';
import { useStyles } from '../../const';

export interface HeaderProps {}

export const Header: FC<HeaderProps> = () => {
  const classes = useStyles();

  return (
    <Grid item xs={12}>
      <Paper className={classes.paper}>
        <Client />
        <BillingAddress />
        <AdvanceSale />
        <ShippingAddress />
      </Paper>
    </Grid>
  );
};
