import React from 'react';
import { Box, capitalize } from '@material-ui/core';

export const columns = [
  {
    id: 'ster_config_log_id',
    label: 'Log ID',
    style: {
      width: '20%',
      whiteSpace: 'nowrap'
    },
    orderById: 'ster_config_log_id'
  },
  {
    id: 'name',
    label: 'Name',
    style: {
      width: '20%',
      whiteSpace: 'nowrap'
    }
  },
  {
    id: 'active',
    label: 'State',
    style: {
      width: '20%',
      whiteSpace: 'nowrap'
    },
    render: ({ active }: { active: boolean }, item: any, t: (str: string) => string) => <Box whiteSpace="nowrap">{t(capitalize(String(active ? t('Enabled') : t('Disabled')) ?? ''))}</Box>
  },
  {
    id: 'comment',
    label: 'Comment',
    style: {
      width: '20%',
      whiteSpace: 'nowrap'
    }
  },
  {
    id: 'updated_at',
    label: 'Date',
    style: {
      width: '20%',
      whiteSpace: 'nowrap'
    }
  },
  {
    id: 'updated_by',
    label: 'Updated by',
    style: {
      width: '20%',
      whiteSpace: 'nowrap'
    }
  }
];
