import { LibbyFetchDAO } from '../LibbyFetchDAO';

export class MessageFromDAO extends LibbyFetchDAO {
  constructor() {
    super('ster_message_from', 'message_from_id');
  }

  getAll() {
    return this.query().limit(0, 10000).run();
  }
}
