import React from 'react';
import { RecipentsShow } from '../../../Messages/Components';
import { MessageConfigType } from '../../types/types';

export const columnsMessageConfigForm = [
  {
    id: 'options',
    label: '',
    width: '40px',
    style: {
      minWidth: '30px'
    },
    noSort: true
  },
  {
    id: 'id',
    label: 'Message Configuration ID',
    width: '60px',
    style: {
      minWidth: '80px'
    },
    noSort: true
  },
  {
    id: 'marketplace',
    label: 'Marketplace',
    width: '180px',
    style: {
      minWidth: '180px'
    },
    orderById: 'marketplace.name'
  },
  {
    id: 'state',
    label: 'Order State',
    width: '180px',
    style: {
      minWidth: '180px'
    },
    orderById: 'state.name'
  },
  {
    id: 'template',
    label: 'Template',
    width: '180px',
    style: {
      minWidth: '180px'
    },
    orderById: 'template.name'
  },
  {
    id: 'order_document_type',
    label: 'Order Document Type',
    width: '180px',
    style: {
      minWidth: '180px'
    },
    orderById: 'order_document_type.name'
  },

  {
    id: 'send_to',
    label: 'Send to',
    width: '180px',
    style: {
      minWidth: '180px'
    },
    render: ({ send_to, message_config_id }: MessageConfigType) => (send_to?.length ? <RecipentsShow message_to={send_to} message_id={message_config_id} /> : ''),
    orderById: 'send_to'
  },
  {
    id: 'send_to_client',
    label: 'Send to client',
    width: '180px',
    style: {
      minWidth: '180px'
    },
    orderById: 'send_to_client',
    render: ({ send_to_client }: MessageConfigType, item: any, t: (str: string) => string) => t(send_to_client ? 'Enabled' : 'Disabled')
  }
];
