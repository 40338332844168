import React from 'react';
import { Button, Grid, makeStyles, Typography } from '@material-ui/core';
import { ScreenAligned } from '../../../../components/ScreenAligned/ScreenAligned';
import { useTranslation } from '../../../../services/translation';
import { AnyObject } from '../../../../types';
import LoadingData from '../../../components/LoadingData';
import { SearchFilters } from './components';
import { SalesPaper, StockPaper, CostPaper, FinancialsPaper, DebtorsPaper, ProvidersPaper, MarginsPaper } from './components/Papers';
import { useReportingDashboardLogic } from './hook/useReportingDashboard';

interface ReportingTransportListTypes {
  title: string;
}

const useStyles = makeStyles((theme: AnyObject) => ({
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    marginBottom: '10px'
  },
  title: {
    fontFamily: '"Oswald"',
    verticalAlign: 'baseline',
    textTransform: 'uppercase',
    color: '#4B4B4B',
    textAlign: 'start'
  }
}));

export const ReportingDashboardList = ({ title }: ReportingTransportListTypes) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const { working, date, screenData, onFilterChange, onRefresh, rowsProductsSales, rowsCustomersSales, rowsCategoryStock } = useReportingDashboardLogic();

  return (
    <ScreenAligned
      title={title}
      additionalTitle={
        <Button color="primary" variant="contained" onClick={onRefresh}>
          {t('Refresh')}
        </Button>
      }
    >
      <Grid container xs={12} sm={12} md={12} lg={12} justify="flex-end">
        <SearchFilters onFilterChange={onFilterChange} date={date} />
      </Grid>
      {!working ? (
        <Grid container spacing={1} justify="center">
          {screenData ? (
            <>
              {screenData?.sales && Object.keys(screenData?.sales).length > 0 && <SalesPaper values={screenData?.sales} classes={classes} rowsProductsSales={rowsProductsSales} rowsCustomersSales={rowsCustomersSales} />}
              {screenData?.company_cost && Object.keys(screenData?.company_cost).length > 0 && <CostPaper values={screenData?.company_cost} classes={classes} />}
              {screenData?.financials && Object.keys(screenData?.financials).length > 0 && <FinancialsPaper values={screenData?.financials} classes={classes} />}
              {screenData?.margins && Object.keys(screenData?.margins).length > 0 && <MarginsPaper values={screenData?.margins} classes={classes} />}
              {screenData?.stock && Object.keys(screenData?.stock).length > 0 && <StockPaper values={screenData?.stock} classes={classes} rowsCategoryStock={rowsCategoryStock} />}
              <Grid item xs={12} sm={12} md={10} lg={4}>
                {screenData?.debtors && Object.keys(screenData?.debtors).length > 0 && <DebtorsPaper values={screenData?.debtors} classes={classes} />}
                {screenData?.providers && Object.keys(screenData?.providers).length > 0 && <ProvidersPaper values={screenData?.providers} classes={classes} />}
              </Grid>
            </>
          ) : (
            <Grid container xs={12} justify="space-between" alignItems="center">
              <Typography variant="h4" className={classes.title}>
                {t('No Data')}
              </Typography>
            </Grid>
          )}
        </Grid>
      ) : (
        <LoadingData working={working} />
      )}
    </ScreenAligned>
  );
};
