import React, { FC, useEffect, useMemo, useState } from 'react';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import { makeStyles } from '@material-ui/styles';
import { TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useTranslation } from '../../../../../../../../services/translation';
import { PaymentMethodItem } from '../../../../context/BudgetContext/types';
import { ICurrency } from '../../../../context/BudgetContext/types/ICurrency';
import { usePaymentMethodCurrencyLibbyCall } from '../../../../../../../../business/payment/PaymentMethodCurrency';
import { usePaymentCurrencyLibbyFetch } from '../../../../../../../../business/payment/PaymentCurrency';
import { useBudgetContext } from '../../../../context';
import { Checkbox, FormControlLabel } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  modalTitle: {
    '& h2.MuiTypography-h6': {
      textTransform: 'uppercase',
      fontSize: '24px !important',
      fontWeight: 700
    }
  },
  modalContent: {
    fontSize: 16
  },
  buttonText: {
    '& button': {
      fontSize: 16
    }
  },

  root: {
    '& .MuiPaper-root': {
      borderRadius: '8px'
    }
  }
}));

export interface AddMethodModalProps {
  open: boolean;
  onCancel: () => void;
  onConfirm: (method: Omit<PaymentMethodItem, 'code'>) => void;
}
const initialValues = {
  Currency: null,
  paymentMethod: null,
  paidInAdvance: false
};
export const AddMethodModal: FC<AddMethodModalProps> = ({ open, onCancel, onConfirm }: AddMethodModalProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [selected, setSelected] = useState<Omit<PaymentMethodItem, 'code'> | any>(initialValues);
  const { paymentMethods } = useBudgetContext();
  const { data: paymentMethodCurrency, recall } = usePaymentMethodCurrencyLibbyCall({
    methodName: 'getByCurrency',
    params: [selected?.Currency?.currency_id]
  });
  const { data: paymentCurrency } = usePaymentCurrencyLibbyFetch();
  const paymentCurrencyGroup = useMemo<Array<ICurrency>>(
    () =>
      paymentCurrency?.map<ICurrency>((currency) => ({
        currency_id: currency.currency_id,
        name: currency.name,
        currency_symbol: currency.currency_symbol,
        odooCurrency: currency.odooCurrency
      })) || [],
    [paymentCurrency]
  );
  const paymentMethodsCurrencyGroup = useMemo<Array<Omit<PaymentMethodItem, 'code'>>>(
    () =>
      //@ts-ignore
      paymentMethodCurrency
        //@ts-ignore
        ?.map<Omit<PaymentMethodItem, 'code'>>((paymentMethod) => ({
          id: paymentMethod.OdooPaymentMethod[0].odoo_payment_method_id || '',
          plazo: paymentMethod.PaymentMethod.name,
          name: paymentMethod.PaymentMethod.PaymentType.name,
          paymentDays: 0,
          days: paymentMethod.PaymentMethod.name === 'Personalizado' ? '0' : paymentMethod.PaymentMethod.PaymentType.name,
          deliverDays: 0,
          //@ts-ignore
          paymentMethod
        })) || [],
    [paymentMethodCurrency]
  );

  useEffect(() => {}, [paymentMethodsCurrencyGroup]);

  useEffect(() => {
    if (!open) {
      setSelected(initialValues);
    }
  }, [open]);

  useEffect(() => {
    recall(selected?.Currency?.currency_id);
  }, [recall, selected?.Currency?.currency_id]);

  useEffect(() => {
    if (paymentMethods.length > 0) {
      // @ts-ignore
      setSelected((prev: any) => ({ ...prev, Currency: paymentMethods[0].Currency }));
    }
    recall(selected?.Currency?.currency_id);
  }, [recall, selected?.Currency?.currency_id, paymentMethods]);

  return (
    <Dialog open={open} onClose={onCancel} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" className={classes.root}>
      <DialogTitle className={classes.modalTitle} id="alert-dialog-title">
        {t('Add Payment Method')}
      </DialogTitle>
      <DialogContent>
        <DialogContentText className={classes.modalContent} id="alert-dialog-description">
          <Autocomplete
            disabled={paymentCurrencyGroup.length === 0 || paymentMethods.length > 0}
            value={selected?.Currency}
            //@ts-ignore
            onChange={(event, value) => setSelected((prev) => ({ ...prev, Currency: value }))}
            options={paymentCurrencyGroup}
            groupBy={(option) => option?.name}
            getOptionLabel={(option) => option?.name}
            renderInput={(params) => <TextField {...params} label={`${t('Currency')}`} variant="standard" />}
            filterSelectedOptions
          />
          <Autocomplete
            disabled={paymentMethodsCurrencyGroup.length === 0}
            value={selected}
            //@ts-ignore
            onChange={(event, value) => setSelected((prev) => ({ ...prev, ...value }))}
            options={paymentMethodsCurrencyGroup}
            groupBy={(option) => option.name}
            getOptionLabel={(option) => option.plazo}
            renderInput={(params) => <TextField {...params} label={`${t('Method')}`} variant="standard" />}
            filterSelectedOptions
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={selected!.paidInAdvance}
                onChange={() => {
                  setSelected((prev: any) => ({
                    ...prev,
                    paidInAdvance: !selected!.paidInAdvance
                  }));
                }}
              />
            }
            label={`${t('Is it paid in advance')}?`}
          />
        </DialogContentText>
      </DialogContent>
      <DialogActions className={classes.buttonText}>
        <Button onClick={onCancel} color="primary">
          {t('Cancel')}
        </Button>
        <Button onClick={() => onConfirm(selected as PaymentMethodItem)} color="primary" autoFocus disabled={!selected?.paymentMethod}>
          {t('Add')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
