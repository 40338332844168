import React, { useCallback, useMemo } from 'react';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { useSnackbar } from 'notistack';
import { useFilerParamsId, useLibbyFetch } from '../../../hooks';
import { LibbyObject, RowsType } from '../../../types';
import CustomModal from '../../../services/customFormDialog';
import { CategoryForm } from '../components/CategoryForm';
import { useTranslation } from '../../../services/translation';
import { formInitialValues, validate, columns } from '../utils';
import { MakeCell } from '../../components/MakeCell';
import { ButtonDetailsType } from 'types/Button';
import { CategoriesBulkLoadForm } from '../routes/CategoryList/components/CategoriesBulkLoadForm';
import { ModalTitle } from 'routes/Collection/common';
import ConfirmDialog from 'components/ConfirmDialog';
import Box from '@material-ui/core/Box';

type CategoryFormType = {
  id?: string;
  name: string;
};
type BulkLoadFormResult = {
  message: string;
};

interface useCourierLogicProps {
  libby: LibbyObject;
}

const CategoryFormModal = CustomModal(CategoryForm);
const CategoryBulkLoadFormModal = CustomModal(CategoriesBulkLoadForm);
const ConfirmModal = CustomModal(ConfirmDialog);

export const useCategoryLogic = ({ libby }: useCourierLogicProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const { orderBy, direction, paramsFetch, handleRequestSort, setFilter, filter } = useFilerParamsId({
    orderInit: 'category_id',
    daoName: 'ster_category'
  });

  const { data, working, fetchMore, reFetch } = useLibbyFetch(libby, paramsFetch);
  // FIX ALL EDITING AND CREATING CATEGORIES, COULDN'T FINISH IT BECAUSE OF DATABASE CONSTRAINT ERROR NOT ALLOWING NULL CATEGORY_ID
  const openCategoryForm = useCallback(
    async (event: any, currentCategory?: RowsType) => {
      try {
        const formData = (await CategoryFormModal.show({
          confirmText: t(currentCategory ? 'Update' : 'Create'),
          cancelText: t('Cancel'),
          title: `${t(currentCategory ? 'Update' : 'Create')} ${t('Category')}`,
          validate,
          formInitialValues: currentCategory ? { name: currentCategory.name } : formInitialValues
        })) as CategoryFormType;
        if (!formData) return;
        if (currentCategory?.category_id) {
          await libby.ster_category.save({ ...formData, category_id: currentCategory.category_id });
        } else {
          await libby.ster_category.save(formData);
        }
        reFetch();
        enqueueSnackbar(t('Category saved'), { variant: 'success' });
      } catch (error: any) {
        if (error !== false) {
          enqueueSnackbar(t('Something is wrong'), { variant: 'error' });
        }
      }
    },
    [t, libby, enqueueSnackbar, reFetch]
  );

  const handleOpenCatergoryBulkLoad = useCallback(async () => {
    try {
      const featuresBulkLoadResult = (await CategoryBulkLoadFormModal.show({
        confirmText: t('Send'),
        cancelText: t('Cancel'),
        title: t('Features Bulk Load'),
        libby
      })) as BulkLoadFormResult;
      if (featuresBulkLoadResult) {
        enqueueSnackbar(t(featuresBulkLoadResult.message), { variant: 'success' });
        reFetch();
      }
    } catch (err) {
      //nothing
    }
  }, [t, enqueueSnackbar, libby, reFetch]);

  const handleOpenDeleteCategory = useCallback(
    async (e: MouseEvent, row: any) => {
      try {
        const isDeleted = await ConfirmModal.show({
          title: <ModalTitle title="Warning" error={true} />,
          content: `${t('Are you sure you want to delete the $$$$ Category?').replace('$$$$', row.name)}`,
          confirmText: t('Accept'),
          oneButton: true
        });
        if (isDeleted) {
          await libby.ster_category.remove({ ...row, category_id: row.category_id });
          reFetch();
          enqueueSnackbar(t('Category deleted successfully'), { variant: 'success' });
        }
      } catch (err) {
        console.log(err);
      }
    },
    [t, enqueueSnackbar, reFetch, libby]
  );

  const rows: RowsType[] = useMemo(() => {
    const dataCategory = data as any[];

    return dataCategory.map((category: any) => ({
      ...category,
      id: category.category_id,
      actions: (
        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
          <MakeCell label="" icon={EditIcon} onClick={(e) => openCategoryForm(e, category)} />
          <MakeCell label="" icon={DeleteIcon} onClick={(e) => handleOpenDeleteCategory(e, category)} />
        </Box>
      )
    }));
  }, [data, openCategoryForm, handleOpenDeleteCategory]);

  const buttons: ButtonDetailsType[] = useMemo(
    () => [
      {
        id: 'CreateCategory',
        onClick: (e) => openCategoryForm(e),
        title: t('Create Category'),
        variant: 'contained'
      },
      {
        id: 'BulkLoad',
        onClick: handleOpenCatergoryBulkLoad,
        title: t('Bulk'),
        variant: 'contained'
      }
    ],
    [t, openCategoryForm, handleOpenCatergoryBulkLoad]
  );

  return {
    rows,
    columns,
    working,
    fetchMore,
    handleRequestSort,
    orderBy,
    direction,
    setFilter,
    filter,
    buttons
  };
};
