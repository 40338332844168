import { Column } from '../../../../components/InfoTable';

export const columnsReportingGoalSaleSettingFieldProducts: Array<Column> = [
  {
    id: 'actions',
    label: '',
    noSort: true,
    width: 30
  },
  {
    id: 'sku',
    label: 'Sku'
  },
  {
    id: 'delete',
    label: 'Actions',
    noSort: true,
    style: {
      whiteSpace: 'nowrap'
    }
  }
];
