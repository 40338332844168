import { useLibbyCall } from '../../../../../hooks/useLibbyCall';
import { LibbyObject } from '../../../../../types';

export type useSterConfigIdOptions = {
  daoName: string;
  id?: string | number;
};

export const useSterConfigId = (libby: LibbyObject, { daoName }: useSterConfigIdOptions) => {
  const { data, working, recall } = useLibbyCall(libby, {
    daoName,
    methodName: 'getSterConfigId'
  });

  return { data, working, recall };
};
