import React, { useMemo } from 'react';
import { GeneralSelect } from '../../components/GeneralSelect';
import { InputProps } from '../../types/types';

type SelectHourTypeProps = {
  inputProps: InputProps;
  optionAll?: boolean;
  [k: string]: any;
  sort?: boolean | 'asc' | 'desc';
  disabled?: boolean;
};

export const SelectHour = ({ inputProps, optionAll = false, sort = 'asc', disabled = false, ...styles }: SelectHourTypeProps) => {
  const data = useMemo(() => {
    const dataHoursAll = [];
    for (let index = 1; index <= 12; index++) {
      dataHoursAll.push({
        id: index,
        value: index < 10 ? `0${index}` : index
      });
    }
    return dataHoursAll;
  }, []);

  return <GeneralSelect disabled={disabled} optionAll={optionAll} sort={sort} working={false} label="Hour" data={data} inputProps={inputProps} {...styles} />;
};
