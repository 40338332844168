import { LibbyFetchDAO } from '../LibbyFetchDAO';

export class UploadDocumentsGenerateDAO extends LibbyFetchDAO {
  constructor() {
    super('ster_upload_documents_generate', 'document_upload_id');
  }

  getAll() {
    return this.query().limit(0, 10000).run();
  }
}
