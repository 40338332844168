import React, { useState, useCallback, KeyboardEvent, useRef, useLayoutEffect } from 'react';
import { DatabaseConnector, useLibby } from '@phinxlab/libby-rest-web';
import { Grid, Typography, TextField, IconButton } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { useSnackbar } from 'notistack';
import CropFreeIcon from '@material-ui/icons/CropFree';
import { AnyObject, LibbyObject } from '../../../../../types';
import { CollectState, DISPATCH_ITEM_STATE } from '../../../../../const';
import { CollectItemState } from '../../../../../const/CollectItemState';
import { useTranslation } from '../../../../../services/translation';
import CustomModal from '../../../../../services/customFormDialog';
import ConfirmDialog from '../../../../../components/ConfirmDialog';
import { ModalTitle, LabelProduct } from '../../../common';
import { getClassNames } from '../../../utils/getClassNames';
import { useStyles } from './CollectProductStyle';
import { LoadingTable } from '../../../../../components/LoadingTable';
import { Collect_item, Collect_item_product } from '../../../../../interfaces/business';
import { reducerQuantity } from '../../../utils/quantity';
import { replaceNonNumericCharacters } from '../../../../../utils';
import { ScannerDialog } from '../../../../../components/ScannerDialog';

export interface Data_Product {
  inItemsProducts: boolean;
  product: AnyObject;
  collectItem: Collect_item;
}

type CollectProductProps = {
  libby: LibbyObject;
  data: Data_Product;
  onNextCard: (a: number, b: number) => void;
  actualCard: number;
  carouselLength: number;
  id: string;
  updateData: (collectItemProduct: Collect_item_product) => void;
};

const ConfirmModal = CustomModal(ConfirmDialog);
const ScannerModal = CustomModal(ScannerDialog);

const CollectProductRaw = ({ libby, data, onNextCard, actualCard, carouselLength, id, updateData }: CollectProductProps) => {
  const { t } = useTranslation();
  const [productPicked, setProductPicked] = useState<boolean>(false);
  const [cartPicked, setCartPicked] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(false);
  const classes = useStyles();
  const productInput = useRef<HTMLInputElement>(null);
  const cartInput = useRef<HTMLInputElement>(null);
  const { enqueueSnackbar } = useSnackbar();
  const collectItemState: string = data.collectItem.collectItemState.collect_item_state_id;
  const collectItemStateConst: string = CollectItemState.COLLECTED;
  const orderItem = data.product.order_item_id;

  const collectItemId = data?.collectItem?.collect_item_id;
  const [serialNumber, setSerialNumber] = useState<string>();
  const { libby: collectItemProductDAO } = useLibby(['ster_collect_item_product']);

  const { skuText, textCart, productLabel, progressIcon, progressIconCart, checkGreyCircle, checkGreyCircleCart, borderedBox } = getClassNames({
    productPicked,
    cartPicked,
    collectItemState,
    collectItemStateConst,
    classes
  });

  const focusProductInput = () => {
    const inputProduct = productInput.current;
    if (inputProduct) {
      inputProduct.focus();
    }
  };

  const focusCartInput = () => {
    const inputCart = cartInput.current;
    if (inputCart) {
      inputCart.focus();
    }
  };

  useLayoutEffect(() => {
    if (!productPicked) {
      focusProductInput();
    }
    if (!cartPicked && productPicked) {
      focusCartInput();
    }
  });

  const onProcessCollectItem = useCallback(
    async (orderItemId: string, saveCollectItemId: string) => {
      setLoading(true);
      try {
        const productsWithQuantity = data.collectItem?.dispatch_item?.order ? data.collectItem.dispatch_item.order.items.reduce(reducerQuantity, 0) : 1;
        let collect_item_state = {};

        if (data?.collectItem.items_product.length + 1 === productsWithQuantity) {
          collect_item_state = {
            collectItemState: {
              collect_item_state_id: CollectItemState.COLLECTED
            }
          };
        }

        const saveCollectItemProduct = {
          order_item: {
            order_item_id: orderItemId
          },
          collect_item: {
            collect_item_id: saveCollectItemId,
            ...collect_item_state,
            dispatch_item: {
              ...data?.collectItem.dispatch_item,
              dispatch_item_state: {
                dispatch_item_state_id: DISPATCH_ITEM_STATE.COLLECTING
              }
            },
            collect: {
              collect_id: id,
              collectState: { collect_state_id: CollectState.COLLECTING }
            }
          },
          serial_number: serialNumber
        };

        const collectItemProduct = await collectItemProductDAO.ster_collect_item_product.aspect('limit_updated_by').save({ ...saveCollectItemProduct });
        updateData(collectItemProduct);

        if (collectItemProduct) {
          setLoading(false);
          enqueueSnackbar(t('Product Collected'), { variant: 'success' });
          onNextCard(actualCard, carouselLength);
        }
      } catch (error: any) {
        enqueueSnackbar(t(`Error${error}`), { variant: 'error' });
      }
    },
    [data, id, serialNumber, collectItemProductDAO.ster_collect_item_product, updateData, enqueueSnackbar, t, onNextCard, actualCard, carouselLength]
  );

  const onProccessProduct = useCallback(
    async (value: string) => {
      try {
        setProductPicked(true);
        const valueToSend = value.trim().replace(/\t/g, '\\t').replace(/\n/g, '\\n');
        const checkProductSerial = await libby.ster_collect_serial_number
          .query()
          .equals('sku', data.product.sku)
          .and()
          .equals('serial_number', valueToSend)
          .and()
          .equals('order_id', data.collectItem.dispatch_item.order.order_id)
          .run();

        if (checkProductSerial.data) {
          setCartPicked(false);
          enqueueSnackbar(t('Product Picked'), { variant: 'success' });
          setSerialNumber(valueToSend);
        } else {
          setProductPicked(false);
          await ConfirmModal.show({
            title: <ModalTitle title="Incorrect product" error />,
            content: `${t('The scanned product does not match the requested one. Check and rescan.')}`,
            confirmText: t('Accept'),
            oneButton: true
          });
        }
      } catch (error: any) {
        if (error) {
          enqueueSnackbar(`${t('Error picking the Product')}: ${error.message}`, { variant: 'error' });
        }
      }
    },
    [libby.ster_collect_serial_number, data.collectItem.dispatch_item.order.order_id, enqueueSnackbar, t, data.product.sku]
  );

  const onProcessCart = useCallback(
    async (value: string) => {
      try {
        setCartPicked(true);
        const [checkCartSerial] = await libby.ster_dispatch_collect_details.aspect('search_cart_stock').query().equals('collect_id', id).equals('items.cartBox.cart_box_id', value).run();

        if (checkCartSerial) {
          onProcessCollectItem(orderItem, collectItemId);

          enqueueSnackbar(t('Cart Box Picked'), { variant: 'success' });
        } else {
          setCartPicked(false);
          await ConfirmModal.show({
            title: <ModalTitle title="Incorrect cart box" error />,
            content: `${t('The scanned cart box does not match the one requested. Check and rescan.')}`,
            confirmText: t('Accept'),
            oneButton: true
          });
        }
      } catch (error: any) {
        if (error) {
          enqueueSnackbar(t('Error picking the Cart Box'), {
            variant: 'error'
          });
        }
      }
    },
    [libby.ster_dispatch_collect_details, id, enqueueSnackbar, t, onProcessCollectItem, orderItem, collectItemId]
  );

  const validateButton = (event: KeyboardEvent<HTMLInputElement>) => event.key === 'Enter';

  const onPickUpProduct = useCallback((event: KeyboardEvent<HTMLInputElement>) => validateButton(event) && onProccessProduct(event.currentTarget.value), [onProccessProduct]);

  const onPickUpCart = useCallback((event: KeyboardEvent<HTMLInputElement>) => validateButton(event) && event.currentTarget.value.length && onProcessCart(event.currentTarget.value), [onProcessCart]);

  const handleScanner = useCallback(
    async (type: string) => {
      const rest: any = await ScannerModal.show({
        title: 'Scanner',
        typeScanner: type
      });

      if (rest) {
        switch (type) {
          case 'product':
            if (productInput.current !== null) {
              productInput.current.value = rest;
              onProccessProduct(rest);
            }
            break;
          case 'cart':
            if (cartInput.current !== null) {
              cartInput.current.value = replaceNonNumericCharacters(rest);
              onPickUpCart(rest);
            }
            break;
          default:
            break;
        }
      }
    },
    [onPickUpCart, onProccessProduct]
  );

  return (
    <>
      <Grid className={classes.cardBox}>
        <Grid>
          <Grid className={classes.collectProductInfoBox}>
            <Grid className={borderedBox}>
              <div className={checkGreyCircle}>
                <CheckCircleIcon className={progressIcon} />
              </div>
              <div>
                <LabelProduct data={data} labelStyle={productLabel} skuAndSerialBoxStyle={classes.skuAndSerial} skuStyle={skuText} />
                <TextField
                  disabled={data.inItemsProducts || productPicked}
                  className={classes.textField}
                  label=""
                  variant="filled"
                  size="small"
                  id={`productSerial-${data.product.product_id || ''}`}
                  name={`productSerial-${data.product.product_id || ''}`}
                  InputProps={{
                    inputRef: productInput,
                    onKeyDown: (event: React.KeyboardEvent<HTMLInputElement>) => {
                      onPickUpProduct(event);
                    }
                  }}
                />
                <IconButton aria-label="close" disabled={data.inItemsProducts || productPicked} onClick={() => handleScanner('product')}>
                  <CropFreeIcon color={data.inItemsProducts || productPicked ? 'inherit' : 'primary'} />
                </IconButton>
              </div>
            </Grid>
            <Grid className={classes.cartBox}>
              <div className={checkGreyCircleCart}>
                <CheckCircleIcon className={progressIconCart} />
              </div>
              <div className={classes.cartBoxText}>
                <label htmlFor={`cartSerial-${data.product.product_id || ''}`} className={textCart}>
                  <Typography variant="h6" color="initial">
                    {`${t('Pick and place it in box')} ${data.collectItem.cartBox.name}`}
                  </Typography>
                </label>
                <TextField
                  disabled={data.inItemsProducts || cartPicked}
                  className={classes.textField}
                  label=""
                  variant="filled"
                  size="small"
                  id={`cartSerial-${data.product.product_id || ''}`}
                  name={`cartSerial-${data.product.product_id || ''}`}
                  onChange={(event) => {
                    const { value } = event.target;
                    event.target.value = replaceNonNumericCharacters(value);
                    return event;
                  }}
                  InputProps={{
                    inputRef: cartInput,
                    onKeyDown: (event: React.KeyboardEvent<HTMLInputElement>) => onPickUpCart(event)
                  }}
                />
                <IconButton aria-label="close" disabled={data.inItemsProducts || cartPicked} onClick={() => handleScanner('cart')}>
                  <CropFreeIcon color={data.inItemsProducts || cartPicked ? 'inherit' : 'primary'} />
                </IconButton>
              </div>
            </Grid>
          </Grid>
          <Grid />
        </Grid>
        {data.inItemsProducts && (
          <Grid className={classes.collected}>
            <div className={classes.collectCircle}>
              <CheckCircleIcon className={classes.itemProductChecked} />
            </div>
          </Grid>
        )}
      </Grid>
      {loading && (
        <Grid className={classes.collected}>
          <LoadingTable />
        </Grid>
      )}
    </>
  );
};

export const CollectProduct = DatabaseConnector(CollectProductRaw)('ster_dispatch_collect_details', 'ster_dispatch_collect_item', 'ster_collect_item_product', 'ster_collect_item_product_search', 'ster_collect_serial_number');
