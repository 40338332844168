import React, { ReactElement, useCallback, useMemo, MouseEvent } from 'react';
import { RowsAlert } from '../../types/types';
import { Alert } from '../../../../interfaces/business';
import { MenuActions, MenuItemComponentType } from '../../../../components/MenuActions';

interface dataRowsType extends Alert {
  id: string;
  options: ReactElement<any, any>;
}

export const useRowsAlertForm = ({ alert, openAlertEdit, openAlertEditHour }: RowsAlert) => {
  const menuArray = useCallback(
    (dataAlert: Alert): MenuItemComponentType[] => {
      const menu: MenuItemComponentType[] = [
        {
          title: 'Edit email to',
          onClick: (e: MouseEvent) => {
            e.stopPropagation();
            openAlertEdit(dataAlert);
          },
          disabled: false
        },
        {
          title: 'Edit hour',
          onClick: (e: MouseEvent) => {
            e.stopPropagation();
            openAlertEditHour(dataAlert);
          },
          disabled: false
        }
      ];
      return menu.filter((menuItems) => menuItems.disabled === false);
    },
    [openAlertEdit, openAlertEditHour]
  );

  const rows = useMemo(() => {
    return alert.reduce((allDataRows: dataRowsType[], dataAlert: Alert) => {
      const { alert_id } = dataAlert;
      const copyAllDataRows = [...allDataRows];
      copyAllDataRows.push({
        ...dataAlert,
        options: <MenuActions menuArray={menuArray(dataAlert)} />,
        id: alert_id
      });

      return copyAllDataRows;
    }, []);
  }, [alert, menuArray]);

  return { rows };
};
