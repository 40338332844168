import React from 'react';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { SelectTemplate } from '../../components/SelectTemplate';
import { MessageConfigAlertFormTypes } from '../types/types';
import { useTranslation } from '../../../services/translation';
import { SelectAlert } from '../../components/SelectAlert';

const useStyles = makeStyles({
  field: {
    marginTop: '20px'
  }
});

export const MessageConfigAlertForm = ({ alert, template, errorAlert, errorTemplate }: MessageConfigAlertFormTypes) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <>
      <Grid className={classes.field}>
        <SelectAlert inputProps={{ value: alert.input.value, onChange: alert.input.onChange, ...errorAlert }} not_in_config_alert />
      </Grid>

      <Grid className={classes.field}>
        <SelectTemplate inputProps={{ value: template.input.value, onChange: template.input.onChange, ...errorTemplate }} title={t('Template')} />
      </Grid>
    </>
  );
};
