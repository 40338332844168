export enum ACCOUNT_ROLE {
  NOT_ROLE = '1',
  ADMINISTRATOR = '2',
  MANAGEMENT = '3',
  LOGISTICS_OPERATOR = '4',
  DRIVER = '5',
  AGENT = '7',
  FINANCE = '8',
  LOGISTICS = '9',
  HEAD_MANAGEMENT = '10',
  SERVICES_MANAGER = '11',
  ADMINISTRATOR_MANAGEMENT = '13',
  SALESMAN = '14',
  SALESCOORDINATOR = '15',
  EXTERNALDRIVER = '16',
  TALAVERA_DRIVER = '17'
}
