import React from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import { ChargebackReportList } from './routes';
import { ChargebackMarketplaces } from './routes/ChargebackMarketplaces';
import { DatabaseConnector } from '@phinxlab/libby-rest-web';
import { LibbyObject } from 'types';
import { useLocation } from 'react-router-dom';
import { useBreadcrumbs } from 'hooks';
import { TitleBreadcrumbs } from 'interfaces';
import { useTranslation } from 'services/translation';

export const ChargebackReportRaw = ({ libby }: { libby: LibbyObject }) => {
  const { t } = useTranslation();
  const { state } = useLocation<{ goBack: TitleBreadcrumbs[] }>();
  const match = useRouteMatch();
  const title = useBreadcrumbs(t('Chargeback Report'), state && state?.goBack?.length ? state.goBack : undefined);
  return (
    <Switch>
      <Route exact path={`${match.path}`}>
        <ChargebackReportList path={match.path} />
      </Route>
      <Route exact path={`${match.path}/marketplaces`}>
        <ChargebackMarketplaces libby={libby} title={title} />
      </Route>
    </Switch>
  );
};

export const ChargebackReport = DatabaseConnector(ChargebackReportRaw)('ster_marketplace_chargeback');
