import React from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import { StockDaysReportList } from './routes';

export const StockDaysReport = () => {
  const match = useRouteMatch();

  return (
    <Switch>
      <Route exact path={`${match.path}`}>
        <StockDaysReportList />
      </Route>
    </Switch>
  );
};
