import React from 'react';
import { TextFieldProps } from '@material-ui/core';
import { GeneralSelect } from '../../components/GeneralSelect';

interface DataType {
  id: number;
  value: string;
}

type SelectImportTypeProps = {
  title: string;
  inputProps: TextFieldProps;
  optionAll?: boolean;
  [k: string]: any;
  sort?: boolean | 'asc' | 'desc';
  data: DataType[];
};

export type OrderStateType = {
  order_state_id: string | number;
  name: string;
};

export const SelectOrderStateInbox = ({ data, inputProps = {}, optionAll = false, title, sort, ...restProps }: SelectImportTypeProps) => {
  return <GeneralSelect working={false} label={title} optionAll={optionAll} data={data} inputProps={inputProps} {...restProps} sort={sort} />;
};
