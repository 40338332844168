import React, { ReactNode } from 'react';
import Grid from '@material-ui/core/Grid';
import { AnyObject } from '../../types/types';

type LeftAndRightProps = {
  children?: ReactNode;
  left?: ReactNode;
  right?: ReactNode;
  className?: string;
  leftProps?: AnyObject;
  rightProps?: AnyObject;
  [k: string]: any;
};

const dummy = {};

export const LeftAndRight = ({ children = null, left = null, right = null, className = '', leftProps = dummy, rightProps = dummy, ...rest }: LeftAndRightProps) => {
  const childrenArray = React.Children.toArray(children);
  const leftPart = !children || !childrenArray[0] ? left : childrenArray[0];
  const rightPart = !children || !childrenArray[1] ? right : childrenArray[1];
  return (
    <Grid container className={className} wrap="wrap" justify="space-between" {...rest}>
      {!!leftPart && (
        <Grid item xs={12} {...leftProps} sm={!rightPart ? 12 : 6}>
          {leftPart}
        </Grid>
      )}
      {!!rightPart && (
        <Grid item xs={12} {...rightProps} sm={!leftPart ? 12 : 6}>
          {rightPart}
        </Grid>
      )}
    </Grid>
  );
};
