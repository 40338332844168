import React from 'react';
import { Box, Button, Card, Grid, Link, Theme, Typography, useMediaQuery } from '@material-ui/core';
import { useTranslation } from '../../../../../services/translation';
import { format } from '../../../../../util';

interface KpiData {
  name: string;
  value: number | string;
  title?: string;
  url?: string;
  dollar?: boolean;
}

export const KpiComponent = ({ name, value, title, url, dollar = false }: KpiData) => {
  const { t } = useTranslation();
  const isMd = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));

  const handleColor = (type: string) => {
    if (type.includes('nova')) {
      return '#456CFF';
    }
    if (type.includes('lean')) {
      return '#FFD86B';
    }
    if (type.includes('athuel')) {
      return '#38FF56';
    }
    return '#FF5179';
  };

  return (
    <Box display="flex" width="100%">
      <Grid container xs={12}>
        {title && (
          <Grid container xs={12} justify="space-between" alignItems="center">
            <Typography variant="h5">{t(title)}</Typography>
            {url && (
              <Button color="primary">
                <Link target="_blank" href={url}>
                  {t('see more')}
                </Link>
              </Button>
            )}
          </Grid>
        )}
        <Grid container xs={12} direction="column">
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Box my={isMd ? 2 : 1} mx={isMd ? 2 : 1}>
              <Card style={{ display: 'flex' }}>
                <Box minHeight="100%" width="10px" bgcolor={handleColor(name)} />
                <Box display="flex" flexDirection="row" p={isMd ? 3 : 1} alignItems="center" width="100%">
                  <Box display="flex" flexDirection="column" width="100%">
                    <Box height="100%" minWidth="80%" mb={1}>
                      <Typography variant={isMd ? 'subtitle2' : 'body2'} color="primary" align="left">
                        {t(name.replace('_', ' ').replace('_', ' ')).toUpperCase()}
                      </Typography>
                    </Box>
                    <Box height="100%" width="100%">
                      <Typography variant={isMd ? 'h3' : 'h5'} color="primary" align="right">
                        {typeof value === 'number' ? (dollar ? `U$S ${format(value, 'Decimal', t)}` : format(value, 'ARS', t)) : value}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Card>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};
