import { LibbyFetchDAO, FilterSubGroup } from '../LibbyFetchDAO';

interface FetchParams {
  filter?: FilterSubGroup;
  orderBy: any;
  offset: number;
  limit: number;
}

export class FilterDAO extends LibbyFetchDAO {
  constructor() {
    super('filter', 'filter_id');
  }

  getAll() {
    return this.query().limit(0, 10000).run();
  }

  getByUserFilter(account_id: string, screen_id: number) {
    return this.query().groupStart().equals('account_id', account_id).and().equals('preset', true).and().equals('screen', screen_id).groupEnd().limit(0, 10000).run();
  }

  getByName(name: string) {
    return this.query().equals('name', name).run();
  }

  fetch({ filter, orderBy, offset = 0, limit = 20 }: Partial<FetchParams> = {}) {
    let query = this.applyFiltersSubGroups(this.query(), filter).limit(offset, limit);
    if (orderBy) {
      query = query.orderBy(orderBy);
    }
    return query.run();
  }
}
