import { makeBusinessEntity } from '../../../lib/libby/generator/makeBusinessEntity';
import { OperationComposition } from '../../../interfaces/business';

const DAO_NAME = 'operationcomposition';

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch }
} = makeBusinessEntity<OperationComposition>(DAO_NAME);

// hooks
export const useOperationCompositionFetchById = fetchById;
export const useOperationCompositionLibbyCall = libbyCall;
export const useOperationCompositionDAO = libbyDAO;
export const useOperationCompositionLibbyFetch = libbyFetch;
