import { GoalDate } from '../../interfaces/business/goal/GoalDate';
import { makeBusinessEntity } from '../../lib/libby/generator/makeBusinessEntity';

const DAO_NAME = 'ster_list_date';

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch }
} = makeBusinessEntity<GoalDate>(DAO_NAME);

// hooks
export const useGoalListDateFetchById = fetchById;
export const useGoalListDateLibbyCall = libbyCall;
export const useGoalListDateDAO = libbyDAO;
export const useGoalListDateLibbyFetch = libbyFetch;
