import React, { useCallback, useMemo, useState } from 'react';
import moment from 'moment';

import { useLocation } from 'react-router-dom';
import { useReportingDeliveryControlLibbyFetch } from '../../../../../../business/orders/order/ReportingDeliveryControl';
import { useBreadcrumbs, useFilerParamsId, useGlobalContext } from '../../../../../../hooks';
import { useInterval } from '../../../../../../hooks/useIntervalPage';
import { TitleBreadcrumbs } from '../../../../../../interfaces';
import { useTranslation } from '../../../../../../services/translation';
import { ButtonDetailsType } from '../../../../../../types/Button';
import { makeFilter } from '../../ReportingLogisticList/utils/makeFilter';
import { LibbyObject, RowsType, userInfoType } from '../../../../../../types';
import { Order_reporting_delivery_control_reporting } from '../../../../../../interfaces/business';
import { MenuActions, MenuItemComponentType } from '../../../../../../components/MenuActions';
import CustomModal from '../../../../../../services/customFormDialog';
import { ReportingLogisticJustifyModal } from '../components/ReportingLogisticJustifyModal';
import { ResportingLogisticJustifyModalLogs } from '../components/ResportingLogisticJustifyModalLogs';

const ShowJustifyDialog = CustomModal(ReportingLogisticJustifyModal);
const ShowJustifyDialogLogs = CustomModal(ResportingLogisticJustifyModalLogs);

interface JustifiedListType {
  libby: LibbyObject;
}

export const ResportingLogisticJustifyLogic = ({ libby }: JustifiedListType) => {
  const { state } = useLocation<{ goBack: TitleBreadcrumbs[] }>();
  const { t } = useTranslation();
  const { userInfo }: { userInfo: userInfoType } = useGlobalContext();
  const monthsInital = 1;

  const title = useBreadcrumbs('Justify');
  const titleShow = useBreadcrumbs('Justify', state && state?.goBack?.length ? state.goBack : title);
  const [refreshFilter, setResetFilter] = useState<any>(
    makeFilter({
      startDate: moment().startOf('month').subtract(monthsInital, 'months'),
      endDate: moment().endOf('month').subtract(monthsInital, 'months'),
      effectiveness: 2
    })
  );

  const { orderBy, direction, paramsFetch, handleRequestSort, setFilter } = useFilerParamsId({
    orderInit: 't_waiting_preparation',
    daoName: 'ster_reporting_delivery_control',
    init: makeFilter({
      startDate: moment().startOf('month').subtract(monthsInital, 'months'),
      endDate: moment().endOf('month').subtract(monthsInital, 'months'),
      effectiveness: 2
    })
  });

  const [stateRefresh, setState] = useState(false);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setState(event.target.checked);
  };

  const refresh = useCallback(() => {
    setFilter(refreshFilter);
  }, [refreshFilter, setFilter]);

  const { data: allData, fetchMore, working, reFetch } = useReportingDeliveryControlLibbyFetch(paramsFetch);

  const delay = useMemo(() => (stateRefresh ? 60000 : null), [stateRefresh]);

  const { time, resetTime } = useInterval(refresh, delay);

  const OnChangeApplyFilters = useCallback(() => {
    if (stateRefresh) {
      resetTime();
    } else {
      refresh();
    }
  }, [refresh, stateRefresh, resetTime]);

  const buttons: ButtonDetailsType[] = useMemo(
    () => [
      {
        id: 'dispatchDetailAddOrder',
        onClick: OnChangeApplyFilters,
        title: `${t('Refresh')} ${stateRefresh ? `${time} ${t('Seconds').toLowerCase()})` : ''}`,
        disabled: working,
        loading: stateRefresh ? working : false
      }
    ],
    [t, OnChangeApplyFilters, time, working, stateRefresh]
  );

  const menuArray = useCallback(
    (data: Order_reporting_delivery_control_reporting): MenuItemComponentType[] => {
      const menu: MenuItemComponentType[] = [
        {
          title: 'Edit',
          onClick: (e: any) => {
            e.stopPropagation();
            ShowJustifyDialog.show({
              title: `${t('Justify Order id')} ${data.order_id}`,
              data,
              cancelText: t('Cancel'),
              confirmText: t('Save'),
              updateText: t('Update'),
              deleteText: t('Delete'),
              libby,
              userId: userInfo?.id,
              reFetch
            });
          },
          disabled: false
        },
        {
          title: 'Logs',
          onClick: (e: any) => {
            e.stopPropagation();
            ShowJustifyDialogLogs.show({
              title: `${t('Logs justification order id')} ${data.order_id}`,
              orderId: data.order_id,
              cancelText: t('Leave'),
              libby
            });
          },
          disabled: false
        }
      ];
      return menu.filter((menuItems) => menuItems.disabled === false);
    },
    [t, libby, reFetch, userInfo]
  );

  const rows: RowsType[] = useMemo(() => {
    const dataOrder = allData as Order_reporting_delivery_control_reporting[];

    return dataOrder.length
      ? dataOrder.map((dataReporting: Order_reporting_delivery_control_reporting) => ({
          id: dataReporting.order_id,
          actions: <MenuActions menuArray={menuArray(dataReporting)} />,
          ...dataReporting
        }))
      : [];
  }, [allData, menuArray]);

  return {
    titleShow,
    rows,
    fetchMore,
    working,
    orderBy,
    direction,
    handleRequestSort,
    refreshFilter,
    setResetFilter,
    stateRefresh,
    handleChange,
    buttons
  };
};
