import { LibbyFetchDAO } from './LibbyFetchDAO';

export class ERPOrderDAO extends LibbyFetchDAO {
  constructor() {
    super('ster_erp_order', 'so_id');
  }

  getAll() {
    return this.query().limit(0, 10000).run();
  }
}
