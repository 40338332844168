import { LibbyFetchDAO } from '../LibbyFetchDAO';

export class ProductDAO extends LibbyFetchDAO {
  constructor() {
    super('ster_product', 'product_id');
  }

  getAll() {
    return this.query().limit(0, 10000).run();
  }

  getProducts() {
    return this.query().limit(10000).run();
  }
}
