import { translate } from '../../../../utils';

export const discountMap = [
  {
    key: 'coupon_discount_name',
    label: 'Coupon',
    formatter: (value: any) => translate(value)
  },
  {
    key: 'coupon_discount',
    label: 'Value',
    formatter: (value: any) => translate(value)
  },
  {
    key: 'coupon_discount_id',
    label: 'Coupon Id',
    formatter: (value: any) => translate(value)
  },
  {
    key: 'coupon_payment_id',
    label: 'Discount Id',
    formatter: (value: any) => translate(value)
  }
];
