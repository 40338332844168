import React from 'react';
import { Theme, useMediaQuery } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { ScreenAligned } from '../../../components/ScreenAligned/ScreenAligned';
import { useMessageTemplatesLogic } from '../hooks/useMessageTemplatesLogic';
import { useTranslation } from '../../../services/translation';
import { LibbyObject } from '../../../types';
import { TableColumns } from './utils/TableColumns';
import InfoTable from '../../components/InfoTable';
import { TableRows } from './utils/TableRows';
import LoadingData from '../../components/LoadingData';
import { SearchFilters } from '../components/SearchFilter';

interface MessageTemplateListType {
  title: string;
  libby: LibbyObject;
}

export const MessageTemplateList = ({ title, libby }: MessageTemplateListType) => {
  const { t } = useTranslation();
  const { createTemplateDialog, editMessageTemplate, deleteMessageTemplate, showMessageDialog, handleRequestSort, orderBy, direction, templates, working, setFilter, filter } = useMessageTemplatesLogic({ libby });
  const isMobile = useMediaQuery<Theme>((theme) => theme.breakpoints.down(450));
  const isDownSm = useMediaQuery<Theme>((theme) => theme.breakpoints.down('sm'));
  const { rows } = TableRows({
    templates,
    showMessageDialog,
    deleteMessageTemplate,
    editMessageTemplate,
    isDownSm,
    isMobile
  });

  return (
    <ScreenAligned
      title={title}
      additionalTitle={
        <Button color="primary" variant="outlined" onClick={() => createTemplateDialog()}>
          {t('Create')}
        </Button>
      }
    >
      <SearchFilters onFilterChange={setFilter} filter={filter} />
      <InfoTable columns={TableColumns} rows={rows} onSortChange={handleRequestSort} orderBy={orderBy} direction={direction} />
      <LoadingData label={`${t('Number of')} ${t('Message Templates').toLocaleLowerCase()}`} working={working} cant={rows.length} />
    </ScreenAligned>
  );
};
