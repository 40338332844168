import React, { useMemo } from 'react';
import { TextFieldProps } from '@material-ui/core/TextField';
import { GeneralSelect } from '../GeneralSelect';

type SelectTrimesterProps = {
  title?: string;
  optionAll?: boolean;
  inputProps: TextFieldProps;
  [k: string]: any;
  sort?: boolean | 'asc' | 'desc';
  quarterDisabled?: number[];
};
const trimesters = [
  {
    id: 1,
    value: '1st quarter'
  },
  {
    id: 2,
    value: '2nd quarter'
  },
  {
    id: 3,
    value: '3rd quarter'
  },
  {
    id: 4,
    value: '4th quarter'
  }
];

export const SelectTrimester = ({ inputProps = {}, optionAll = false, title = 'Quarter', sort, quarterDisabled, ...restProps }: SelectTrimesterProps) => {
  const trimestersEnabled = useMemo(() => (quarterDisabled?.length ? trimesters.filter((trimester) => !quarterDisabled?.includes(trimester.id)) : trimesters), [quarterDisabled]);
  return (
    <GeneralSelect
      label={title}
      optionAll={optionAll}
      data={trimestersEnabled}
      inputProps={trimestersEnabled.length === 0 && inputProps?.value !== '0' ? { ...inputProps, value: '0' } : inputProps}
      working={false}
      {...restProps}
      sort={sort}
    />
  );
};
