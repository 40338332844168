import { makeBusinessEntity } from '../../lib/libby/generator/makeBusinessEntity';
import { PaymentCurrencyDAO } from '../../platform/libby/dao/Payment';

const DAO_NAME = 'paymentmethodcurrency';

const {
  hook: { libbyFetch, libbyCall }
} = makeBusinessEntity<PaymentCurrencyDAO>(DAO_NAME);

// hooks
export const usePaymentMethodCurrencyLibbyFetch = libbyFetch;
export const usePaymentMethodCurrencyLibbyCall = libbyCall;
