import React, { useCallback, useReducer, useState } from 'react';
import { flatten } from 'lodash';
import { useSnackbar } from 'notistack';
import axios from 'axios';
import { ExportDialogComponent } from '../../../components/ExportDialog';
import { DISPATCH_ITEM_STATE, DISPATCH_ITEM_STATE_NAME, GET_COLLET_ITEM_PRODUCT, GET_SHIPPING_REPORT, ORDER_DOCUMENT_SOURCE, ORDER_DOCUMENT_TYPE, ORDER_STATE } from '../../../const';
import { Dispatch, Dispatch_item, Order_document, Order_item, Product } from '../../../interfaces/business';
import { AnyObject, LibbyObject } from '../../../types';
import CustomModal from '../../../services/customFormDialog';
import { DocumentsTable, documentsColumns } from '../../components/DocumentsTable';
import { filterDispatchItemsByState } from '../utils/filter';
import { generatorUrlBarcode } from '../../../utils';
import { OddoOrder, sendDataToOddo } from '../utils/oddo';
import { useTranslation } from '../../../services/translation';
import { OrderWithUrl } from '../../../interfaces';
import ConfirmDialog from '../../../components/ConfirmDialog';
import { ModalTitle } from '../../Collection/common';
import { TagMangerViewPdf } from '../../TagManager/routes/TagManagerViewPdf';
import { addProducts } from '../../TagManager/functions/AddProducts';
import { TokenManager } from '../../../platform/libby/TokenManager';
import { API_URL } from '../../../config';

interface Checked {
  all: Dispatch_item[] | AnyObject;
}

interface prepareOrderHook {
  checked: Checked;
  dispatchData: Dispatch;
  checkIfDispatchIsReady: (dispatch: Dispatch) => void;
  setNewData: (dispatch: Dispatch) => void;
  setOrderItems: (items: Dispatch_item[]) => void;
}

interface IOrderState {
  loading: boolean;
  count: number;
  totalCount: number;
}

enum ActionType {
  UpdateInit = 'UPDATE_INIT',
  UpdateProcess = 'UPDATE_PROCESS',
  UpdateSuccess = 'UPDATE_SUCCESS',
  UpdateFailure = 'UPDATE_FAILURE'
}

type Action = { type: ActionType.UpdateInit; payload: number } | { type: ActionType.UpdateProcess; payload: number } | { type: ActionType.UpdateSuccess } | { type: ActionType.UpdateFailure };

const orderStateReducer = (state: IOrderState, action: Action): IOrderState => {
  switch (action.type) {
    case ActionType.UpdateInit:
      return { ...state, loading: true, totalCount: action.payload };
    case ActionType.UpdateProcess:
      return { ...state, loading: true, count: action.payload };
    case ActionType.UpdateSuccess:
      return { ...state, loading: false };
    case ActionType.UpdateFailure:
      return { ...state, loading: false };
    default:
      return state;
  }
};

const initialState: IOrderState = {
  loading: false,
  count: 0,
  totalCount: 0
};

const SearchDialogModal = CustomModal(ExportDialogComponent);
const ConfirmModal = CustomModal(ConfirmDialog);

export const usePrepareOrder = (libby: LibbyObject, { checked, dispatchData, checkIfDispatchIsReady, setNewData, setOrderItems }: prepareOrderHook) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [tagView, setTagView] = useState(<></>);
  const [showModalTagManagerView, setShowModalTagManagerView] = useState(false);
  const [preparingOrder, setPreparingOrder] = useState(false);
  const [loading, setLoading] = useState(false);
  const [orderStateState, dispatch] = useReducer(orderStateReducer, initialState);

  const showReminderPrint = useCallback(async () => {
    await ConfirmModal.show({
      title: <ModalTitle title="Reminder" />,
      content: `${t('Please print documents and put them in its correct ubication')}`,
      confirmText: t('Accept'),
      oneButton: true
    });
  }, [t]);

  const showReminderCheck = useCallback(
    async ({ items }: { items: number }) => {
      if (items === checked.all.length) return;
      await ConfirmModal.show({
        title: <ModalTitle title="Reminder" />,
        content: `${t('From $fromItems items this will just take $toItems').replace('$fromItems', checked.all.length).replace('$toItems', items.toString())}`,
        confirmText: t('Accept'),
        oneButton: true
      });
    },
    [t, checked]
  );

  /*
  it is very possible that data inside checked.all is unupdated so
  this function returns me the data updated. the right approach is update "checked.all" every time new data comes from API
  I tried to do that but  there is a lot of code that I don't understand and I don't have enough time
  */
  const giveMeCheckDataUpdated = useCallback(
    () =>
      checked.all.map((item: Dispatch_item) => {
        const updatedItem = dispatchData.items.find(({ dispatch_item_id }: Dispatch_item) => dispatch_item_id === item.dispatch_item_id);
        return updatedItem || item;
      }),
    [checked, dispatchData]
  );

  const updateOrderState = async () => {
    const ordersChecked = giveMeCheckDataUpdated();

    try {
      for (let i = 0; i < ordersChecked.length; i++) {
        const { order } = ordersChecked[i];

        const { state } = await libby.ster_order_table.save({
          ...order,
          state: {
            order_state_id: +order.state.order_state_id === ORDER_STATE.WAITING_FOR_PREPARATION ? ORDER_STATE.READY_FOR_DELIVERY : ORDER_STATE.WAITING_FOR_PREPARATION
          }
        });
        ordersChecked[i].order.state = state;
        ordersChecked[i].dispatch_item_state = {
          dispatch_item_state_id: DISPATCH_ITEM_STATE.ASSIGNED,
          name: 'Assigned'
        };
        await libby.ster_dispatch_item.aspect('collect_item_update_state').save(ordersChecked[i]);
      }

      ordersChecked.forEach((item: any) => {
        const itemOld = dispatchData.items.findIndex((itemD) => itemD.order.order_id === item.order.order_id);
        if (itemOld) dispatchData.items[itemOld].order.state = item.order.state;
      });

      setOrderItems([...dispatchData.items]);
    } catch (err: any) {
      enqueueSnackbar(err.toString(), { variant: 'error' });
      return [];
    }
  };

  const getDocuments = useCallback(
    async (data: { order_id: number }[]) => {
      try {
        const documents = await Promise.all(data.map(({ order_id }: { order_id: number }) => libby.ster_order_document.getAllByOrderId(order_id)));
        const allDocuments = flatten(documents);
        for (let i = 0; i < data.length; i++) {
          const remito = allDocuments.find(
            ({ order, source: { order_document_source_id }, type: { order_document_type_id } }) =>
              data[i].order_id.toString() === order.order_id && order_document_type_id === ORDER_DOCUMENT_TYPE.REMITO && order_document_source_id === ORDER_DOCUMENT_SOURCE.ERP.toString()
          );
          // means we dont have remito or we alredy created it (if url looks different means odoo sent us a document and we need to replace it)
          if (!remito || !remito?.url?.includes('documents.ster.phinxlab.com')) {
            try {
              /* eslint-disable-next-line */
              const response = await fetch(`${API_URL}/${GET_SHIPPING_REPORT.replace(':orderid', data[i].order_id.toString())}`, {
                method: 'GET'
              });
              /* eslint-disable-next-line */
              const json = await response.json();
              if (json.order_document_id) {
                const index = allDocuments.findIndex(
                  ({ order, source: { order_document_source_id }, type: { order_document_type_id } }) =>
                    data[i].order_id.toString() === order.order_id && order_document_type_id === ORDER_DOCUMENT_TYPE.REMITO && order_document_source_id === ORDER_DOCUMENT_SOURCE.ERP.toString()
                );
                if (index > 0) {
                  allDocuments.splice(index, 1);
                }

                allDocuments.push(json);
              }
            } catch (e: any) {
              console.log('error getting document');
            }
          }
        }
        return allDocuments.filter((document) => document);
      } catch (err: any) {
        enqueueSnackbar(err.toString(), { variant: 'error' });
        return [];
      }
    },
    [libby, enqueueSnackbar]
  );

  const onPrepareOrder = useCallback(async () => {
    if (preparingOrder) {
      enqueueSnackbar(t('Some orders are being prepared right now, please wait'), { variant: 'info' });
      return;
    }
    setPreparingOrder(true);
    enqueueSnackbar(t('Preparing orders, please wait'), {
      variant: 'info'
    });
    const dispatchItemsToPrepare = filterDispatchItemsByState({
      dispatchItems: giveMeCheckDataUpdated(),
      orderState: ORDER_STATE.WAITING_FOR_PREPARATION.toString(),
      dispatchItemState: DISPATCH_ITEM_STATE.PREPARING_PACKAGE
    });
    await showReminderCheck({ items: dispatchItemsToPrepare.length });
    const dataForOddo: OddoOrder[] = [];
    /* eslint-disable no-await-in-loop */
    for (let i = 0; i < dispatchItemsToPrepare.length; i++) {
      const dispatchItem = dispatchItemsToPrepare[i];
      const {
        order: { order_id, so_number: so, items }
      } = dispatchItem;
      const serial_numbers = [];
      /* eslint-disable no-await-in-loop */
      for (let j = 0; j < items.length; j++) {
        const { sku, order_item_id } = items[j];

        let startTime = performance.now();
        const response = await axios.get(`${API_URL}/${GET_COLLET_ITEM_PRODUCT}/${order_item_id}`, {
          headers: {
            'x-chino-token': await TokenManager.create().retrieve()
          }
        });
        const serialNumbers = response.data;
        let endTime = performance.now();
        console.log(`Execution time serial number: ${endTime - startTime} milliseconds.`);

        serial_numbers.push({
          SKU: sku,
          SN: serialNumbers ? serialNumbers.map((item: { serial_number: string }) => item.serial_number) : []
        });
      }

      dataForOddo.push({
        order_id: Number(order_id),
        so: so || '',
        serial_numbers
      });
      // Todo: Remove console.log
      console.log('dataForOddo', dataForOddo);
    }

    try {
      await sendDataToOddo({ orders: dataForOddo });
      dispatch({ type: ActionType.UpdateInit, payload: dispatchItemsToPrepare.length });
      let countOrderUpdated = 0;
      for await (const item of dispatchItemsToPrepare) {
        await libby.ster_order_table.save({
          ...item.order,
          state: {
            order_state_id: ORDER_STATE.READY_FOR_DELIVERY
          }
        });
        dispatch({ type: ActionType.UpdateProcess, payload: countOrderUpdated++ });
      }
      enqueueSnackbar(t('Saved changes'), { variant: 'success' });
    } catch (err: any) {
      dispatch({ type: ActionType.UpdateFailure });
      if (Array.isArray(err)) {
        const [error] = err;
        enqueueSnackbar(error?.error || error.toString(), {
          variant: 'error'
        });
        return;
      }
      enqueueSnackbar(err.toString(), { variant: 'error' });
    } finally {
      dispatch({ type: ActionType.UpdateSuccess });
      setPreparingOrder(false);
    }
  }, [libby, enqueueSnackbar, t, giveMeCheckDataUpdated, showReminderCheck, preparingOrder]);

  // TODO: cada vez que presionan este boton le estoy pegando a la base, tengo que cachear data
  const handleOpenInvoiceList = useCallback(async () => {
    const ordersReadyForDeliveryPreparing = filterDispatchItemsByState({
      dispatchItems: giveMeCheckDataUpdated(),
      orderState: ORDER_STATE.READY_FOR_DELIVERY.toString(),
      dispatchItemState: DISPATCH_ITEM_STATE.PREPARING_PACKAGE
    }).map(({ order: { order_id } }: Dispatch_item) => ({
      order_id: Number(order_id)
    }));
    const ordersReadyForDeliveryAssigned = filterDispatchItemsByState({
      dispatchItems: giveMeCheckDataUpdated(),
      orderState: ORDER_STATE.READY_FOR_DELIVERY.toString(),
      dispatchItemState: DISPATCH_ITEM_STATE.ASSIGNED
    }).map(({ order: { order_id } }: Dispatch_item) => ({
      order_id: Number(order_id)
    }));
    const ordersReadyForDelivery = [...ordersReadyForDeliveryPreparing, ...ordersReadyForDeliveryAssigned];

    await showReminderCheck({ items: ordersReadyForDelivery.length });
    setLoading(true);
    const allDocuments = await getDocuments(ordersReadyForDelivery);
    setLoading(false);
    await showReminderPrint();
    const invoicesAndRemitos = allDocuments.filter(({ type: { order_document_type_id } }: Order_document) => order_document_type_id === ORDER_DOCUMENT_TYPE.INVOICE || order_document_type_id === ORDER_DOCUMENT_TYPE.REMITO);
    try {
      await SearchDialogModal.show({
        title: 'Documents',
        id: 'order_document_id',
        properties: ['documentNumber', 'type.name'],
        label: 'Document',
        data: invoicesAndRemitos,
        maxWidth: 'md',
        render: () => (
          <DocumentsTable
            documents={invoicesAndRemitos}
            columns={[
              {
                id: 'orderId',
                label: 'Order',
                render: (row: any) => row?.order?.order_id || ''
              },
              ...documentsColumns
            ]}
          />
        )
      });
    } catch (error: any) {
      // nothing
    }
  }, [getDocuments, showReminderPrint, giveMeCheckDataUpdated, showReminderCheck, setLoading]);

  // TODO: cada vez que presionan este boton le estoy pegando a la base, tengo que cachear data

  const handleOpenLabels = useCallback(async () => {
    const ordersReadyForDeliveryPreparing = filterDispatchItemsByState({
      dispatchItems: giveMeCheckDataUpdated(),
      orderState: ORDER_STATE.READY_FOR_DELIVERY.toString(),
      dispatchItemState: DISPATCH_ITEM_STATE.PREPARING_PACKAGE
    }).map(({ order: { order_id } }: Dispatch_item) => order_id);
    const ordersReadyForDeliveryAssigned = filterDispatchItemsByState({
      dispatchItems: giveMeCheckDataUpdated(),
      orderState: ORDER_STATE.READY_FOR_DELIVERY.toString(),
      dispatchItemState: DISPATCH_ITEM_STATE.ASSIGNED
    }).map(({ order: { order_id } }: Dispatch_item) => order_id);
    const ordersReadyForDelivery = [...ordersReadyForDeliveryPreparing, ...ordersReadyForDeliveryAssigned];

    const allOrderID = ordersReadyForDelivery.reduce((accumOrder: string[], order_id: string) => {
      accumOrder.push(order_id);
      return accumOrder;
    }, []);

    const response = await libby.ster_order_so.fetchAllById(allOrderID);

    const allResponse = flatten(response) as OrderWithUrl[];

    const ordersSource = allResponse.map((item: OrderWithUrl) => ({
      ...item,
      urlBarcode: generatorUrlBarcode(item.order_id)
    }));

    showReminderCheck({ items: ordersSource.length });
    await showReminderPrint();
    const component = <TagMangerViewPdf dataChecked={{ all: ordersSource }} resetCheck={() => {}} title={[]} savePrint={() => {}} disableScreenType />;
    setShowModalTagManagerView(true);
    setTagView(component);
  }, [libby, showReminderPrint, giveMeCheckDataUpdated, showReminderCheck]);

  const updateDataWithProducts = useCallback(
    (product: Product) => {
      const resultDatas = dispatchData.items.filter(({ order }: Dispatch_item) => order.items.filter((item: Order_item) => item.sku === product.sku).length > 0);

      resultDatas.forEach((itemUpdate: Dispatch_item) => {
        const newDispatchItem = { ...itemUpdate };
        const items = [...dispatchData.items];
        const orderItems = newDispatchItem.order.items;

        const itemModify = newDispatchItem.order.items.findIndex((item: Order_item) => item.sku === product.sku);

        orderItems[itemModify] = { ...orderItems[itemModify], product };

        const searchItemId = dispatchData.items.findIndex((dispatch_item: Dispatch_item) => dispatch_item.dispatch_item_id === itemUpdate.dispatch_item_id);

        items[searchItemId] = {
          ...items[searchItemId],
          order: {
            ...items[searchItemId].order,
            items: orderItems
          }
        };

        setNewData({
          ...dispatchData,
          items
        });
        setOrderItems(dispatchData.items || []);
      });
    },
    [dispatchData, setNewData, setOrderItems]
  );

  const handleOpenLabelsModalProducts = useCallback(() => {
    addProducts({
      orders: checked.all.map(({ order }: Dispatch_item) => order),
      updateDataWithProducts,
      redirectPrint: handleOpenLabels
    });
  }, [checked, handleOpenLabels, updateDataWithProducts]);

  const onCompletePackage = useCallback(async () => {
    const dispatchItemsPreparing: Dispatch_item[] = filterDispatchItemsByState({
      dispatchItems: giveMeCheckDataUpdated(),
      orderState: ORDER_STATE.READY_FOR_DELIVERY.toString(),
      dispatchItemState: DISPATCH_ITEM_STATE.PREPARING_PACKAGE
    });
    const dispatchItemsAssigned: Dispatch_item[] = filterDispatchItemsByState({
      dispatchItems: giveMeCheckDataUpdated(),
      orderState: ORDER_STATE.READY_FOR_DELIVERY.toString(),
      dispatchItemState: DISPATCH_ITEM_STATE.ASSIGNED
    });
    const dispatchItems: Dispatch_item[] = [...dispatchItemsPreparing, ...dispatchItemsAssigned];
    showReminderCheck({ items: dispatchItems.length });
    const updatedDispatchItems = dispatchItems.map((item: Dispatch_item) => ({
      ...item,
      dispatch_item_state: {
        dispatch_item_state_id: DISPATCH_ITEM_STATE.PREPARED
      }
    }));
    const itemsDontUpdated = dispatchData.items.filter((item) => {
      return (
        updatedDispatchItems.filter((itemUpdated) => {
          return item.dispatch_item_id === itemUpdated.dispatch_item_id;
        }).length === 0
      );
    });

    const itemsUpdated: Dispatch_item[] = [];
    for (let i = 0; i < updatedDispatchItems.length; i++) {
      const updatedDispatchItem = updatedDispatchItems[i];
      //@ts-ignore
      delete updatedDispatchItem.order.shipment;
      const itemUpdated = await libby.ster_dispatch_item.aspect('collect_item_update_state').save(updatedDispatchItems[i]);
      if (itemUpdated)
        itemsUpdated.push({
          ...updatedDispatchItem,
          dispatch_item_state: {
            dispatch_item_state_id: DISPATCH_ITEM_STATE.PREPARED,
            name: DISPATCH_ITEM_STATE_NAME[DISPATCH_ITEM_STATE.PREPARED]
          }
        });
    }

    try {
      const dispatchUpdated: Dispatch = { ...dispatchData, items: [...itemsDontUpdated, ...itemsUpdated] };
      setNewData(dispatchUpdated);
      setOrderItems(dispatchUpdated.items || []);
      checkIfDispatchIsReady(dispatchUpdated);
    } catch (err: any) {
      enqueueSnackbar(err.toString(), { variant: 'error' });
    }
  }, [giveMeCheckDataUpdated, showReminderCheck, libby, dispatchData, setNewData, setOrderItems, checkIfDispatchIsReady, enqueueSnackbar]);

  return {
    onPrepareOrder,
    handleOpenInvoiceList,
    handleOpenLabels,
    onCompletePackage,
    tagView,
    setTagView,
    showModalTagManagerView,
    setShowModalTagManagerView,
    checkIfDispatchIsReady,
    handleOpenLabelsModalProducts,
    loading,
    orderStateState,
    updateOrderState
  };
};
