import { API_URL } from '../config';
import { Order } from '../types';
import { CREATE_PRESUPUESTO_B2B } from '../const';

interface OrderData extends Order {
  approved_id?: string | null;
  confirmedByClient: boolean;
  operation: { operation_id: number; Order_id: number | string | undefined };
  hasStock?: Boolean;
}

export const useCreateOdooOrder = () => {
  const createOrderInOdoo = async (order: OrderData) => {
    const response = await fetch(`${API_URL}/${CREATE_PRESUPUESTO_B2B}`, {
      method: 'POST',
      body: JSON.stringify(order),
      headers: { 'Content-Type': 'application/json' }
    });
    const json = await response.json();
    if (json?.result?.status !== 200) {
      throw new Error('order could not be crated on Odoo');
    }

    return json?.result?.orders && json?.result?.orders[0]?.name_odoo_so;
  };

  return { createOrderInOdoo };
};
