import { /* React, */ useCallback, useMemo, useState } from 'react';
import { useSnackbar } from 'notistack';
import { useLibbyFetchById } from '../../../hooks';
import { LibbyObject, RowsType } from '../../../types';
import { useTranslation } from '../../../services/translation';
import { ButtonDetailsType } from 'types/Button';
import { useBreadcrumbs } from '../../../hooks';
import { useLocation } from 'react-router';
import { TitleBreadcrumbs } from 'interfaces';
import { Column } from 'routes/components/InfoTable';
import { useCheckAll } from '../../../hooks';
import ConfirmDialog from '../../../components/ConfirmDialog';
import CustomModal from '../../../services/customFormDialog';
import { Box, Typography } from '@material-ui/core';
import { orderBy } from 'lodash';
import moment from 'moment';

interface useStockLogicProps {
  libby: LibbyObject;
}

const ConfirmModal = CustomModal(ConfirmDialog);

export const useProcessListLogic = ({ libby }: useStockLogicProps) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [loading] = useState(false);
  const manualStockProcessID = '144';
  const { working, data, recall } = useLibbyFetchById(libby, { daoName: 'ster_ster_config', id: manualStockProcessID });

  const { state } = useLocation<{ goBack: TitleBreadcrumbs[] }>();
  const title = useBreadcrumbs(t('Stock process sync'));
  const titleShow = useBreadcrumbs(t('Stock process sync'), state && state?.goBack?.length ? state.goBack : title);

  const {
    checked: { all },
    columnAll,
    handleCheckId,
    resetCheck
  } = useCheckAll(data?.metadata, 'name');

  const columnsProcessApi: Array<Column> = [
    columnAll,
    {
      id: 'actions',
      label: '',
      noSort: true,
      width: 30
    },
    {
      id: 'name',
      label: 'Name',
      style: {
        whiteSpace: 'nowrap'
      },
      orderById: 'name',
      noSort: true
    },
    {
      id: 'last_global_excecution',
      label: 'Last global excecution',
      style: {
        whiteSpace: 'nowrap'
      },
      noSort: true
    },
    {
      id: 'last_sync',
      label: 'Last manual excecution',
      style: {
        whiteSpace: 'nowrap'
      },
      noSort: true
    },
    {
      id: 'active',
      label: 'Active',
      noSort: true
    },
    {
      id: 'last_state',
      label: 'State',
      noSort: true
    }
  ];

  const rows: RowsType[] = useMemo(() => {
    const dataApis = Array.isArray(data?.metadata) ? data.metadata : [];

    return orderBy(
      dataApis.map((api: any) => {
        return {
          select_order_id: handleCheckId(api.name),
          ...api,
          last_global_excecution: data.last_execution ? moment(data.last_execution).format('DD/MM/YYYY-HH:mm') : t('None'),
          active: api.active ? t('Yes') : t('No'),
          last_sync: api.last_sync ? moment(api.last_sync).format('DD/MM/YYYY-HH:mm') : t('None')
        };
      }),
      ['name'],
      ['asc']
    );
  }, [data, handleCheckId, t]);

  const openSyncStockModal = useCallback(async () => {
    try {
      const validation = await ConfirmModal.show({
        title: t('Synchronize Stock'),
        content: (
          <Box style={{ display: 'flex', flexDirection: 'column' }}>
            <Typography variant="h4">{t("You're about to sync the following API's with it's corresponding markets")}:</Typography>
            <Typography style={{ marginBottom: '10px', width: '100%', display: 'flex', justifyContent: 'center' }} variant="subtitle1">{`(${t('This may take some time')})`}</Typography>
            {all.map((api) => (
              <Typography style={{ marginBottom: '5px' }} variant="h5" key={api.name}>
                {api.name}
              </Typography>
            ))}
          </Box>
        ),
        confirmText: t('Yes'),
        cancelText: t('No'),
        oneButton: false
      });

      if (validation) {
        const nonSelectedApis = data?.metadata.filter((api: any) => all.every((selected) => selected.name !== api.name));
        const updatedSelectedApis = all.map((api) => ({
          ...api,
          last_state: 'pending',
          must_sync: true
        }));
        const newMetadata = [...nonSelectedApis, ...updatedSelectedApis];
        await libby.ster_ster_config.save({ ...data, metadata: newMetadata });
        resetCheck();
        recall?.();
        enqueueSnackbar(t("The selected stock api's are now being processed in the background"), { variant: 'success' });
      }
    } catch (error: any) {
      enqueueSnackbar(`${t('There was an error while trying to sync the selected processes')}`, {
        variant: 'error'
      });
      return false;
    }
  }, [t, all, resetCheck, data, recall, enqueueSnackbar, libby?.ster_ster_config]);

  const buttons: ButtonDetailsType[] = useMemo(
    () => [
      {
        id: 'SynchronizeButton',
        onClick: () => openSyncStockModal(),
        title: t('Synchronize'),
        variant: 'outlined',
        disabled: working || !all.length
      }
    ],
    [t, openSyncStockModal, all.length, working]
  );

  return { rows, working: loading, buttons, titleShow, columns: columnsProcessApi };
};
