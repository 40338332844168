import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { TextField } from '@material-ui/core';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import { useSnackbar } from 'notistack';
import CircularProgress from '@material-ui/core/CircularProgress';
import { CustomError } from 'utils';
import { Product } from '../../../../../../../../interfaces/business';
import { useLibbyCall } from '../../../../../../../../lib/libby';
import { useTranslation } from '../../../../../../../../services/translation';
import { useBudgetContext } from '../../../../context';
import { PaymentMethodItem, ProductInfo } from '../../../../context/BudgetContext/types';
import { getOodoPrice } from '../../../../utils';
import { getNetIncome } from '../../../../utils/getNetIncome';

const useStyles = makeStyles(() => ({
  modalTitle: {
    '& h2.MuiTypography-h6': {
      textTransform: 'uppercase',
      fontSize: '24px !important',
      fontWeight: 700
    }
  },
  modalContent: {
    fontSize: 16
  },
  buttonText: {
    '& button': {
      fontSize: 16
    }
  },
  textFieldAutocomplete: {
    '& .MuiFormHelperText-root': {
      color: '#FF5179'
    }
  },
  root: {
    '& .MuiPaper-root': {
      borderRadius: '8px'
    }
  }
}));

export interface ProductOption extends ProductInfo {
  nameSKU: string;
}

export interface AddProductModalProps {
  open: boolean;
  onCancel: () => void;
  onConfirm: (product: ProductOption, method: PaymentMethodItem) => void;
}

export const AddProductModal: FC<AddProductModalProps> = ({ open, onCancel, onConfirm }: AddProductModalProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const { paymentMethods, selectedClient, advanceSale } = useBudgetContext();

  const { data: products = [], working } = useLibbyCall({ daoName: 'ster_product', methodName: 'getAll' });
  // @ts-ignore
  const options = useMemo(() => products.map<ProductOption>((product: Product) => ({ ...product, nameSKU: `${product.sku} - ${product.name}`, odoo: null })), [products]);

  const [selected, setSelected] = useState<ProductOption | null>(null);
  const [selectedMethod, setSelectedMethod] = useState<PaymentMethodItem | null>(null);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (!open) {
      setSelectedMethod(null);
      setSelected(null);
    }
  }, [open]);

  const onAddClick = useCallback(async () => {
    if (selectedMethod && selected) {
      setLoading(true);
      try {
        const oodoPrice = await getOodoPrice(selected.sku, selectedMethod, selectedClient.tier?.id, advanceSale.isAdvanceSale);
        selected.odoo = !oodoPrice
          ? null
          : {
              price: {
                company: oodoPrice.company,
                list: 0,
                payment_method: oodoPrice.payment_method,
                price: oodoPrice.price,
                odoo_margin: oodoPrice.margen,
                // net_income: +((oodoPrice.margen / 100) * oodoPrice.price).toFixed(2), //TODO: formula vieja
                // net_income: +(oodoPrice.price - oodoPrice.price / (1 + oodoPrice.margen / 100)).toFixed(2) // TODO: formula de MARIANO consultar en caso de cambio
                net_income: getNetIncome(oodoPrice.price, oodoPrice.margen)
              }
            };

        onConfirm(selected as ProductOption, selectedMethod as PaymentMethodItem);
      } catch (error) {
        const message = error instanceof CustomError ? error.message : 'There was an error getting the product';
        enqueueSnackbar(t(message), {
          variant: 'error'
        });
      } finally {
        setLoading(false);
      }
    }
  }, [enqueueSnackbar, onConfirm, selected, selectedClient.tier, selectedMethod, t, advanceSale.isAdvanceSale]);

  return (
    <Dialog fullWidth maxWidth="md" open={open} onClose={onCancel} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" className={classes.root}>
      <DialogTitle className={classes.modalTitle} id="alert-dialog-title">
        {t('Add Product')}
      </DialogTitle>
      <DialogContent>
        <DialogContentText className={classes.modalContent} id="alert-dialog-description">
          <Autocomplete<ProductOption>
            loading={working}
            style={{ display: 'inline-block', width: '100%' }}
            id="budget-product-search"
            options={options}
            autoSelect
            getOptionLabel={(option: ProductOption) => option.nameSKU}
            filterSelectedOptions
            renderInput={(params) => (
              <form noValidate>
                <TextField {...params} className={classes.textFieldAutocomplete} label={t('Product')} placeholder="" />
              </form>
            )}
            onChange={(e, newValue) => {
              setSelected(newValue);
            }}
            noOptionsText="sin opciones"
          />

          <Autocomplete<PaymentMethodItem>
            disabled={paymentMethods.length === 0}
            style={{ display: 'inline-block', width: '100%' }}
            value={selectedMethod}
            onChange={(event, value) => setSelectedMethod(value)}
            options={paymentMethods}
            groupBy={(option) => option.name}
            getOptionLabel={(option) => `${option.plazo}(${option.paymentMethod.PaymentMethod.days})`}
            renderInput={(params) => <TextField {...params} label={`${t('Method')}`} variant="standard" />}
            filterSelectedOptions
          />
        </DialogContentText>
      </DialogContent>
      <DialogActions className={classes.buttonText}>
        <Button onClick={onCancel} color="primary" disabled={loading}>
          {t('Cancel')}
        </Button>
        <Button onClick={onAddClick} color="primary" autoFocus disabled={!selected || !selectedMethod || loading}>
          {!loading ? t('Add') : <CircularProgress />}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
