import { LibbyFetchDAO } from '../LibbyFetchDAO';

export class CompanyDAO extends LibbyFetchDAO {
  constructor() {
    super('ster_company', 'company_id');
  }

  getAllCompany() {
    return this.query().limit(0, 10000).run();
  }
}
