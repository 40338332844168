import React, { useEffect, useMemo, useState, useCallback } from 'react';
import { Box, Card, CardContent, Checkbox, Theme, Typography, useMediaQuery } from '@material-ui/core';
import { CartesianGrid, Legend, LegendPayload, LegendProps, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import moment from 'moment';
import { useLibbyCall } from '../../../../../hooks';
import { useTranslation } from '../../../../../services/translation';
import { LibbyObject } from '../../../../../types';
import { CHART_COLORS } from '../../../../../chart/types';
import { MakeCellChildren } from '../../../../components/MakeCell';
import { ChartEmpty } from '../../../../../components/ChartEmpty';
import LoadingData from '../../../../components/LoadingData';
import { FilterBarSelection } from '../../../../Reporting/routes/ReportingList/FilterBar';
import { endDateFormat, startDateFormat } from '../../../../../utils';

interface HistoryMarketplaceProps {
  libby: LibbyObject;
  filter: FilterBarSelection;
  daoName: string;
}

type AllMarketplaceType = {
  id: string;
  name: string;
};

interface RenderCusomizedLegendProps extends LegendProps {
  onChange: (selectMarketplace: AllMarketplaceType) => void;
  selectedMarketplace: AllMarketplaceType[];
}

const RenderCusomizedLegend = ({ payload, onChange, selectedMarketplace }: RenderCusomizedLegendProps) => (
  <Box flexWrap="wrap" display="flex" justifyContent="center">
    {payload &&
      payload.map((entry: LegendPayload) => (
        <Box flexDirection="rows" display="flex">
          <MakeCellChildren label={entry.value}>
            <Checkbox
              checked={selectedMarketplace.findIndex((selectMarketplace: AllMarketplaceType) => selectMarketplace.id === entry.id) > -1}
              onChange={() => {
                onChange({ id: entry.id, name: entry.value });
              }}
              inputProps={{
                'aria-labelledby': 'transfer-list-item-all-label'
              }}
              style={{ color: entry.color }}
            />
          </MakeCellChildren>
        </Box>
      ))}
  </Box>
);

const filterInit: FilterBarSelection = {
  marketplaces: [],
  from: startDateFormat(moment().subtract(30, 'days')).toDate(),
  to: endDateFormat(moment()).toDate(),
  courier: []
};

type AllMarketplaceDataType = {
  id: string;
  name: string;
};

type HistoryMarketplaceType = {
  countofsource_marketplace_name: string;
  createat: string;
  source_marketplace_id: string;
  source_marketplace_name: string;
  sumofamount: string;
};

type AllMarketplaceWithIdType = {
  [marketplace: string]: number;
};

type DataGroupByDateType = {
  day: string;
  [marketplace: string]: any;
};

export const HistoryMarketplace = ({ libby, filter, daoName }: HistoryMarketplaceProps) => {
  const {
    data = [],
    working = true,
    recall
  } = useLibbyCall(libby, {
    daoName,
    methodName: 'getByDate',
    params: [filterInit],
    noAutoCall: true
  });

  useEffect(() => {
    recall?.({
      ...filterInit,
      canal: filter.canal,
      company: filter.company
    });
  }, [filter.canal, recall, filter.company]);

  const { t } = useTranslation();

  const allMarketplaceWitId = useMemo(
    () =>
      data.reduce((allMarketplaceData: AllMarketplaceDataType[], dataMarketplace: HistoryMarketplaceType) => {
        const copyAllMarketplace = [...allMarketplaceData];
        const indexCopyAllMarketplace = copyAllMarketplace.findIndex((accumulatorAllMarketplace) => accumulatorAllMarketplace.id === dataMarketplace.source_marketplace_id);

        if (indexCopyAllMarketplace === -1) {
          copyAllMarketplace.push({
            id: dataMarketplace.source_marketplace_id,
            name: dataMarketplace.source_marketplace_name
          });
        }

        return copyAllMarketplace;
      }, []),
    [data]
  );

  const [selectedMarketplace, setSelectMarketplace] = useState<AllMarketplaceType[]>([]);

  useEffect(() => {
    setSelectMarketplace(allMarketplaceWitId);
  }, [allMarketplaceWitId]);

  const allMarketplace = useMemo(
    () =>
      allMarketplaceWitId.reduce((allMarketplaceData: AllMarketplaceWithIdType, dataMarketplace: AllMarketplaceDataType) => {
        const indexSelectedMarketplace = selectedMarketplace.findIndex((accumulatorSelectedMarketplace) => accumulatorSelectedMarketplace.id === dataMarketplace.id);
        if (indexSelectedMarketplace > -1) {
          return {
            ...allMarketplaceData,
            [dataMarketplace.name]: 0
          };
        }
        return allMarketplaceData;
      }, {}),
    [allMarketplaceWitId, selectedMarketplace]
  );

  const onChangeLegendMarketplace = useCallback((selectMarketplaceUpdate: AllMarketplaceType) => {
    setSelectMarketplace((prevSelectMarketplace: AllMarketplaceType[]) => {
      const copySelectMarketplace = [...prevSelectMarketplace];

      const indexSelectMarketplace = copySelectMarketplace.findIndex((accumulatorSelectMarketplace: AllMarketplaceType) => accumulatorSelectMarketplace.id === selectMarketplaceUpdate.id);

      if (indexSelectMarketplace > -1) {
        if (copySelectMarketplace.length > 1) {
          copySelectMarketplace.splice(indexSelectMarketplace, 1);
        }
      } else {
        copySelectMarketplace.push(selectMarketplaceUpdate);
      }

      return copySelectMarketplace;
    });
  }, []);

  const dataGroupByDate = useMemo(
    () =>
      data.reduce((accumulatorDataGroup: DataGroupByDateType[], currentValue: HistoryMarketplaceType) => {
        const copyAccumulator = [...accumulatorDataGroup];

        const indexAccumulator = copyAccumulator.findIndex((accumulator: DataGroupByDateType) => accumulator.day === currentValue.createat);

        const indexSelectedMarketplace = selectedMarketplace.findIndex((accumulatorSelectedMarketplace) => accumulatorSelectedMarketplace.id === currentValue.source_marketplace_id);

        if (indexSelectedMarketplace > -1) {
          if (indexAccumulator > -1) {
            copyAccumulator[indexAccumulator] = {
              ...copyAccumulator[indexAccumulator],

              [currentValue.source_marketplace_name]: parseInt(currentValue.sumofamount, 10)
            };
          } else {
            copyAccumulator.push({
              day: currentValue.createat,
              ...allMarketplace,
              [currentValue.source_marketplace_name]: parseInt(currentValue.sumofamount, 10)
            });
          }
        }

        return copyAccumulator;
      }, []),
    [data, allMarketplace, selectedMarketplace]
  );

  const isDownXs = useMediaQuery<Theme>((theme) => theme.breakpoints.down('xs')); // 400

  const isDownMd = useMediaQuery<Theme>((theme) => theme.breakpoints.down('md'));

  return (
    <Box my={4} width="100%" height="100%">
      <Card>
        <CardContent>
          <Typography variant="h5">
            {t('Historic by Marketplace')} ({t('Last 30 days')})
          </Typography>
          <Box display="flex" justifyContent="center" alignItems="center" py={2}>
            {!working ? (
              dataGroupByDate.length ? (
                <ResponsiveContainer width="100%" height="100%" minHeight={isDownXs ? 400 : isDownMd ? 300 : 200}>
                  <LineChart
                    width={500}
                    height={300}
                    margin={{
                      top: 10,
                      right: 30,
                      left: 20,
                      bottom: 0
                    }}
                    data={dataGroupByDate}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="day" />
                    <YAxis type="number" />
                    <Tooltip />
                    <Legend
                      payload={allMarketplaceWitId.map((nameMarketplace: { id: string; name: string }) => ({
                        id: nameMarketplace.id,
                        type: 'square',
                        value: `${nameMarketplace.name} `,
                        color: CHART_COLORS[parseInt(nameMarketplace.id, 10)]
                      }))}
                      content={<RenderCusomizedLegend onChange={onChangeLegendMarketplace} selectedMarketplace={selectedMarketplace} />}
                    />

                    {allMarketplaceWitId.map((nameMarketplace: AllMarketplaceDataType) => (
                      <Line type="linear" dataKey={nameMarketplace.name} stroke={CHART_COLORS[parseInt(nameMarketplace.id, 10)]} />
                    ))}
                  </LineChart>
                </ResponsiveContainer>
              ) : (
                <ChartEmpty />
              )
            ) : (
              <LoadingData working={working} />
            )}
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
};
