import { Moment } from 'moment';
import { subFilter } from '../../../types';

export type MakeFilterProps = {
  courierService?: string | [object];
  deliveryDate?: Moment | null;
  states?: Array<number>;
  search?: [object] | string;
  status?: subFilter[];
  statusInit?: subFilter[];
};

type Filter =
  | MakeFilterProps
  | {
      courierService?: [object] | string;
      deliveryDate?: [object] | null;
      search?: [object] | string;
      status?: [object] | null;
      statusInit?: [object] | null;
    };

export const makeFilter = ({ courierService, deliveryDate, search, status, statusInit }: MakeFilterProps) => {
  const filter: Filter = {};

  if (search && search !== '') {
    filter.search = [
      {
        path: 'items.order.so_number',
        value: search,
        method: 'includes'
      }
    ];
  }

  if (courierService && courierService !== '0') {
    filter.courierService = [
      {
        path: 'courier_service.courier_service_id',
        value: courierService
      }
    ];
  }

  if (deliveryDate) {
    filter.deliveryDate = [
      {
        path: 'delivery_date',
        value: deliveryDate.set({ second: 59, millisecond: 999 }).toISOString(),
        method: 'equals'
      }
    ];
  }

  if (status?.length && !statusInit?.length) {
    filter.status = status?.map((statusValue) => ({
      path: 'dispatch_state.dispatch_state_id',
      value: statusValue?.value
    }));
    filter.statusInit = [];
  } else {
    filter.statusInit = statusInit?.map((statusValue) => ({
      path: 'dispatch_state.dispatch_state_id',
      value: statusValue?.value
    }));
    filter.status = [];
  }

  return filter;
};
