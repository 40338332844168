import React, { useCallback, useEffect } from 'react';
import { useField, useForm, FormConfig } from 'react-final-form-hooks';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { ThemeProvider } from '@material-ui/styles';
import { useTranslation } from '../../../../../services/translation';
import { AnyObject } from '../../../../../types/types';
import { makeGetErrorAndHelperText, textFieldProps } from '../../../../../utils/materialHelpers';
import { replaceNonNumericCharacters } from '../../../../../utils';

const dummyFunc = () => {};

interface ClientFormDialogType extends FormConfig<any> {
  open: boolean;
  onCancel: any;
  onConfirm: Function;
  title: string;
  content: React.ReactNode;
  labelContent: string;
  cancelText: string;
  confirmText: string;
  customTheme: string | null;
  inputType: string;
  formInitialValues: AnyObject;
}

export const ClientFormDialog = ({
  open = false,
  onCancel = dummyFunc,
  onConfirm = dummyFunc,
  title = 'Confirmation Dialog',
  cancelText = 'Cancel',
  confirmText = 'confirmText',
  customTheme = null,
  formInitialValues = {}
}: ClientFormDialogType) => {
  const { t } = useTranslation();
  const getErrorAndHelperText = makeGetErrorAndHelperText(t);

  const onSubmit = useCallback(
    async ({ client_document_id, document }) => {
      onConfirm({
        client_document_id,
        document
      });
    },
    [onConfirm]
  );

  const { form, handleSubmit, submitError } = useForm({
    initialValues: formInitialValues,
    onSubmit
  });

  const inputDocument = useField('document', form);

  useEffect(() => {
    if (!open) {
      // execute after submit form
      setTimeout(() => form.reset(), 0);
    }
  }, [open, form]);

  const dialog = (
    <Dialog open={open} onClose={onCancel} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">{title}</DialogTitle>
      <form noValidate onSubmit={handleSubmit}>
        <DialogContent>
          {formInitialValues.client_document_id && <TextField autoFocus {...textFieldProps(t('Document ID'))} value={formInitialValues.client_document_id} disabled />}

          <TextField
            {...textFieldProps(t('Document'))}
            {...inputDocument.input}
            {...getErrorAndHelperText(inputDocument)}
            onChange={(event) => {
              const { value } = event.target;
              event.target.value = replaceNonNumericCharacters(value);
              inputDocument.input.onChange(event);
              return event;
            }}
            type="text"
          />

          {submitError && (
            <Typography style={{ display: 'block' }} variant="overline" color="error">
              {t(submitError)}
            </Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={onCancel}>{cancelText}</Button>
          <Button color="primary" type="submit">
            {confirmText}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );

  return !customTheme ? dialog : <ThemeProvider theme={customTheme}>{dialog}</ThemeProvider>;
};
