import React, { useCallback, useMemo, useState } from 'react';
import { Checkbox } from '@material-ui/core';
import { useLocation } from 'react-router-dom';
import { makeFilter } from '../utils';
import { useReporingDeliverOrderLibbyFetch } from '../../../../../../business/orders/order/ReportingDeliverOrder';
import { useBreadcrumbs } from '../../../../../../hooks';
import { useTranslation } from '../../../../../../services/translation';
import { RowsType } from '../../../../../../types';
import { formatDate } from '../../../../../../utils';
import { TitleBreadcrumbs } from '../../../../../../interfaces';

export const useReportingDeliverOrders = () => {
  const { t } = useTranslation();
  const [ordersToUpdate, setOrdersToUpdate] = useState<string[]>([]);
  const { state } = useLocation<{ goBack: TitleBreadcrumbs[] }>();
  const titleR = useBreadcrumbs('Deliver Orders');
  const title = useBreadcrumbs('Deliver Orders', state && state?.goBack?.length ? state.goBack : titleR);

  const [filter, setFilter] = useState(
    makeFilter({
      startDate: null,
      endDate: null
    })
  );
  const [orderBy, setOrderBy] = useState('order_id');
  const [direction, setDirection] = useState<'asc' | 'desc'>('desc');

  const paramsFetch = useMemo(
    () => ({
      filter,
      orderBy,
      direction
    }),
    [filter, orderBy, direction]
  );

  const handleRequestSort = useCallback((newOrderBy: string, newDirection: 'asc' | 'desc') => {
    setOrderBy(newOrderBy);
    setDirection(newDirection);
  }, []);

  const { data, working, fetchMore, reFetch } = useReporingDeliverOrderLibbyFetch(paramsFetch);

  const handleChangeOrdersToUpdate = useCallback(
    (orderId: string) => {
      const orderValidate = ordersToUpdate.find((order_id: string) => order_id === orderId);

      if (orderValidate) setOrdersToUpdate(ordersToUpdate.filter((order_id) => order_id !== orderId));
      else setOrdersToUpdate([...ordersToUpdate, orderId]);
    },
    [ordersToUpdate, setOrdersToUpdate]
  );

  const rows: RowsType[] = useMemo(
    () =>
      data
        ? data.map((order: any) => ({
            ...order,
            checked: <Checkbox onClick={() => handleChangeOrdersToUpdate(order.order_id)} />,
            created_at: formatDate(order.created_at, true),
            updated_at: formatDate(order.created_at, true),
            update_by_username: t(order.update_by_username)
          }))
        : [],
    [data, t, handleChangeOrdersToUpdate]
  );

  return {
    title,
    working,
    rows,
    fetchMore,
    orderBy,
    direction,
    handleRequestSort,
    filter,
    setFilter,
    ordersToUpdate,
    reFetch
  };
};
