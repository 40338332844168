import React, { useState } from 'react';
import moment from 'moment';
import { Grid, TextField } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import { useTranslation } from '../../../../../services/translation';
import { Filter } from '../../../../../types';
import { useInitFilter, useInitFilterDebounced } from '../../../../OrderReport/routes/OrderReportList/hook';
import { gridBreakpointsFields } from '../../../../../const';
import { FilterBar } from '../../../../../components/FilterBar';
import { makeFilter } from '../../../utils';
import { SelectMpMl } from '../../../../../components/SelectMlMp';
import { DateClose } from '../../../../../components/DateClose';

type SearchFiltersProps = {
  onFilterChange: (makeFilter: object) => void;
  filter: Filter;
  resetFilter: () => void;
};

const SearchFiltersRaw = ({ onFilterChange, filter, resetFilter }: SearchFiltersProps) => {
  const { t } = useTranslation();

  const [symbolsArr] = useState(['e', 'E', '+', '-', '.', ',', '`+']);

  const { search: order_liquidation_group_id, handleChange: handleChangeOrderLiquidationGroupId } = useInitFilterDebounced({
    initFetch: filter.order_liquidation_group_id,
    init: '',
    onFilterChange,
    id: 'order_liquidation_group_id',
    makeFilter
  });

  const { search: marketplace, handleChangeFilter: handleChangeMarketplace } = useInitFilter({
    initFetch: filter.marketplace,
    init: '',
    onFilterChange,
    id: 'marketplace',
    makeFilter
  });

  const { search: start_date, handleChangeFilterString: setStartDate } = useInitFilter({
    initFetch: filter.start_date,
    onFilterChange,
    init: null,
    id: 'start_date',
    date: true,
    makeFilter
  });

  const { search: end_date, handleChangeFilterString: setEndDate } = useInitFilter({
    initFetch: filter.end_date,
    onFilterChange,
    id: 'end_date',
    date: true,
    init: null,
    makeFilter
  });

  return (
    <FilterBar
      defaultFilter={
        <Grid item {...gridBreakpointsFields}>
          <TextField
            id="standard-search"
            placeholder={t('Group ID')}
            type="text"
            value={order_liquidation_group_id}
            onChange={handleChangeOrderLiquidationGroupId}
            fullWidth
            InputLabelProps={{
              shrink: true
            }}
            InputProps={{
              endAdornment: <SearchIcon />
            }}
            onKeyDown={(e) => symbolsArr.includes(e.key) && e.preventDefault()}
          />
        </Grid>
      }
      handleReset={resetFilter}
    >
      <Grid item {...gridBreakpointsFields}>
        <SelectMpMl
          inputProps={{
            value: marketplace,
            onChange: handleChangeMarketplace
          }}
        />
      </Grid>
      <Grid item {...gridBreakpointsFields}>
        <DateClose name="Start Date" value={moment.isMoment(start_date) ? start_date : null} onChange={setStartDate} />
      </Grid>
      <Grid item {...gridBreakpointsFields}>
        <DateClose name="End Date" value={moment.isMoment(end_date) ? end_date : null} minValue={moment.isMoment(start_date) ? start_date : null} onChange={setEndDate} />
      </Grid>
    </FilterBar>
  );
};

export const SearchFilters = React.memo(SearchFiltersRaw);
