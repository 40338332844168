import React, { useState, useMemo } from 'react';
import { DatabaseConnector } from '@phinxlab/libby-rest-web';
import { SearchFilters } from './components/SearchFilters';
import LoadingData from '../../../components/LoadingData';
import { useLibbyFetch } from '../../../../hooks';
import { useTranslation } from '../../../../services/translation';
import { AnyObject } from '../../../../types/types';
import InfoTable from '../../../components/InfoTable';
import { useMakeStateTable } from './hooks/useMakeStateTable';
import { ScreenAligned } from '../../../../components/ScreenAligned/ScreenAligned';

type StatesListProps = {
  libby: AnyObject;
};

const StatesListRaw = ({ libby }: StatesListProps) => {
  const { t } = useTranslation();
  const [direction, setDirection] = React.useState<'asc' | 'desc'>('asc');
  const [orderBy, setOrderBy] = React.useState('state_id');

  const [filter, setFilter] = useState({});

  const handleRequestSort = (newOrderBy: string, newDirection: 'asc' | 'desc') => {
    setOrderBy(newOrderBy);
    setDirection(newDirection);
  };

  const paramsFetch = useMemo(
    () => ({
      daoName: 'ster_state',
      filter,
      orderBy,
      direction
    }),
    [filter, orderBy, direction]
  );

  const { data, working, reFetch, fetchMore } = useLibbyFetch(libby, paramsFetch);

  const { columns, rows } = useMakeStateTable(data, libby, reFetch);

  return (
    <ScreenAligned title="Provinces">
      <SearchFilters onFilterChange={setFilter} libby={libby} working={working} />
      <InfoTable columns={columns} rows={rows} onBottomScroll={fetchMore} onSortChange={handleRequestSort} orderBy={orderBy} direction={direction} rowIdKey="state_id" />
      <LoadingData label={`${t('Loaded registers')}`} working={working} cant={rows.length} />
    </ScreenAligned>
  );
};

export const StatesList = DatabaseConnector(StatesListRaw)('ster_state');
