import React, { useState, useCallback } from 'react';
import { Paper } from '@material-ui/core';
import Carousel from 'react-material-ui-carousel';
import { makeStyles } from '@material-ui/core/styles';
import { CollectProduct, Data_Product } from '../routes/CollectStarted/components/CollectProduct';
import { Collect_item_product, Collect_item } from '../../../interfaces/business';

type CarouselProductType = {
  products: Data_Product[];
  id: string;
  updateData: (collectItemProduct: Collect_item_product, newItem: Collect_item) => void;
};

const useStyles = makeStyles(() => ({
  paperCard: {
    padding: 10,
    minWidth: 300
  },
  carouselProducts: {
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'row'
  }
}));

export const CarouselProduct = ({ products, updateData, id }: CarouselProductType) => {
  const searchInitActualCard = useCallback(() => {
    const initActualCard = products.findIndex((productActualCart: Data_Product) => !productActualCart.inItemsProducts);
    return initActualCard > -1 ? initActualCard : products.length - 1;
  }, [products]);

  const [actualCard, setActualCard] = useState<number>(searchInitActualCard());

  const carouselLength = products.length;
  const classes = useStyles();
  const onNextCard = useCallback(
    (actual: number, length: number) => {
      if (actual === length) {
        setActualCard(0);
      }
      setActualCard(actual + 1);
    },
    [setActualCard]
  );
  return (
    <Carousel animation="slide" swipe autoPlay={false} indicators={false} index={actualCard}>
      {products.map((product: Data_Product) => (
        <Paper elevation={0} key={product.product.product_id}>
          <CollectProduct data={product} className={classes.paperCard} allProduct={products} onNextCard={onNextCard} actualCard={actualCard} carouselLength={carouselLength} updateData={updateData} id={id} />
        </Paper>
      ))}
    </Carousel>
  );
};
