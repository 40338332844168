import { LibbyFetchDAO } from './LibbyFetchDAO';

export class OrderBuyerDAO extends LibbyFetchDAO {
  constructor() {
    super('ster_order_buyer', 'order_buyer_id');
  }

  getAll() {
    return this.query().limit(0, 10000).run();
  }
}
