export const ReportObjetiveSalesColumns = {
  product_category: {
    id: 'product_category',
    label: 'Product Category',
    style: {
      whiteSpace: 'nowrap'
    },
    noSort: true
  },
  sku: {
    id: 'sku',
    label: 'Product',
    style: {
      whiteSpace: 'nowrap',
      minWidth: '150px'
    },
    noSort: true
  },
  description: {
    id: 'description',
    label: 'Description',
    style: {
      whiteSpace: 'nowrap',
      minWidth: '150px'
    },
    noSort: true
  },
  quantity: {
    id: 'quantity',
    label: 'Quantity',
    style: {
      whiteSpace: 'nowrap',
      minWidth: '120px'
    },
    noSort: true
  },
  amount: {
    id: 'amount',
    label: 'Amount',
    style: {
      whiteSpace: 'nowrap',
      minWidth: '120px'
    },
    noSort: true
  }
};

export const ReportObjetiveSalesWeeksInformationColumns = {
  name: {
    id: 'name',
    label: 'Week Number',
    style: {
      whiteSpace: 'nowrap'
    },
    orderById: 'name'
  },
  quantity_objective: {
    id: 'quantity_objective',
    label: 'Objective quantity',
    style: {
      whiteSpace: 'nowrap',
      minWidth: '150px'
    },
    orderById: 'quantity_objective'
  },
  quantity_sale: {
    id: 'quantity_sale',
    label: 'Sale quantity',
    style: {
      whiteSpace: 'nowrap',
      minWidth: '120px'
    },
    orderById: 'quantity_sale'
  },
  amount_objective: {
    id: 'amount_objective',
    label: 'Objective amount',
    style: {
      whiteSpace: 'nowrap',
      minWidth: '120px'
    },
    orderById: 'amount_objective'
  },
  amount_sale: {
    id: 'amount_sale',
    label: 'Sale amount',
    style: {
      whiteSpace: 'nowrap',
      minWidth: '120px'
    },
    orderById: 'amount_sale'
  }
};
