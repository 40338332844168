import { Backdrop, CircularProgress, Dialog, IconButton } from '@material-ui/core';
import { Print } from '@material-ui/icons';
import React, { useState } from 'react';
import { useSnackbar } from 'notistack';

const dialogStyle = {
  style: {
    backgroundColor: 'transparent'
  }
};

export const PreviewPdfModal = ({ url }: { url: string }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleClickOpen = () => {
    setLoading(true);
    setOpen(true);
  };

  const handleClose = (error: boolean) => {
    setLoading(false);
    setOpen(false);

    if (error)
      enqueueSnackbar('Error al cargar el archivo', {
        variant: 'error'
      });
  };

  const onLoad = () => setLoading(false);
  const onError = () => handleClose(true);

  return (
    <>
      <IconButton color="primary" onClick={handleClickOpen}>
        <Print />
      </IconButton>

      <Backdrop open={loading} style={{ zIndex: 999 }}>
        <CircularProgress color="primary" />
      </Backdrop>

      <Dialog open={open} onClose={() => handleClose(false)} fullWidth maxWidth="lg" PaperProps={dialogStyle} style={{ visibility: loading ? 'hidden' : 'visible' }}>
        <iframe src={url} style={{ height: '70vh', border: 'none' }} title="PDF Preview" onLoad={onLoad} onError={onError} />
      </Dialog>
    </>
  );
};
