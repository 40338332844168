import React from 'react';
import { formatMoney } from '../../utils';
import { AnyObject } from '../../types/types';
import { PaginatedTable } from '../../components/PaginatedTable';

const itemsColumns = [
  {
    id: 'name',
    label: 'Name'
  },
  {
    id: 'sku',
    label: 'Sku',
    render: (item: any) => item?.sku || ''
  },
  {
    id: 'quantity',
    label: 'Quantity'
  },
  {
    id: 'unit_price',
    label: 'Price',
    render: (item: any) => formatMoney(item.unit_price)
  },
  {
    id: 'subtotal',
    label: 'Subtotal',
    render: (item: any) => formatMoney(item.subtotal)
  }
];

export const ItemsTable = ({ items = [] }: AnyObject) => <PaginatedTable headCells={itemsColumns} rows={items} rowIdKey="order_item_id" />;
