import { Order_reporting_delivery_control_group } from '../../../interfaces/business';
import { makeBusinessEntity } from '../../../lib/libby/generator/makeBusinessEntity';

const DAO_NAME = 'ster_reporting_delivery_control_group';

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch }
} = makeBusinessEntity<Order_reporting_delivery_control_group>(DAO_NAME);

// hooks
export const useReportingDeliveryControlGroupFetchById = fetchById;
export const useReportingDeliveryControlGroupLibbyCall = libbyCall;
export const useReportingDeliveryControlGroupDAO = libbyDAO;
export const useReportingDeliveryControlGroupLibbyFetch = libbyFetch;
