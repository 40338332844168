import React, { useEffect, useState } from 'react';
import { FormConfig } from 'react-final-form-hooks';
import { ThemeProvider, makeStyles } from '@material-ui/styles';
import { Box, Dialog, DialogContent, TextField } from '@material-ui/core';
import { InformationSection } from '../InformationSection';
import { useTranslation } from '../../services/translation';
import { AnyObject } from '../../types/types';
import { isSearchIncludedInValue } from '../../utils';

const dummyFunc = () => {};
const useStyles = makeStyles({
  root: {
    '& .MuiDialogContent-root': {
      overflowY: 'unset !important'
    },
    '& .MuiTextField-root': {
      width: '100%'
    }
  }
});

interface SearchDialogType extends FormConfig<any> {
  open: boolean;
  onCancel: any;
  label: string;
  title: string;
  customTheme: string | null;
  renderItem: React.ElementType;
  data: AnyObject;
  properties: Array<string>;
  disabledLabel?: boolean;
}

export const SearchDialog = ({
  open = false,
  onCancel = dummyFunc,
  label = 'Search',
  title = 'Confirmation Dialog',
  customTheme = null,
  renderItem: RenderItem = () => <></>,
  data = [],
  properties,
  disabledLabel = false
}: SearchDialogType) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [dataFound, setDataFound] = useState(data);

  useEffect(() => {
    if (open) {
      setDataFound(data);
    }
  }, [open, data]);

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    const search = event.target.value;
    if (search === '') {
      setDataFound(data);
    }
    const filteredItems = data.filter((values: AnyObject) => isSearchIncludedInValue(search, values, properties));
    setDataFound(filteredItems);
  };

  const dialog = (
    <Dialog className={classes.root} open={open} onClose={onCancel} fullWidth maxWidth="md" aria-labelledby="form-dialog-title">
      <DialogContent>
        <InformationSection title={t(title)} noPadding>
          {!disabledLabel && (
            <Box pb={3}>
              <TextField autoFocus label={t(label)} defaultValue="" onChange={onChange} variant="outlined" type="text" />
            </Box>
          )}

          <RenderItem data={dataFound} />
        </InformationSection>
      </DialogContent>
    </Dialog>
  );

  return !customTheme ? dialog : <ThemeProvider theme={customTheme}>{dialog}</ThemeProvider>;
};
