import { Box, capitalize } from '@material-ui/core';
import { OrderStateColors } from '../../const/OrderState';
import { OrderType } from '../../types';

export const ReceiptsColumns = [
  {
    id: 'actions',
    label: 'Attach receipt',
    style: {
      textAlign: 'center'
    },
    noSort: true
  },
  {
    id: 'order_id',
    label: 'ID Order',
    style: {
      whiteSpace: 'nowrap',
      minWidth: '150px'
    },
    orderById: 'order_id'
  },
  {
    id: 'order_state',
    label: 'State',
    style: {
      textAlign: 'center'
    },
    render: ({ order_state_id, order_state_name }: OrderType, item: any, t: (str: string) => string) => (
      <Box color={OrderStateColors[order_state_id ?? '']} whiteSpace="nowrap">
        {t(capitalize(order_state_name ?? ''))}
      </Box>
    ),
    orderById: 'state.name'
  },
  {
    id: 'owner_id',
    label: 'Owner ID',
    style: {
      whiteSpace: 'nowrap',
      minWidth: '150px'
    },
    orderById: 'owner_id'
  },
  {
    id: 'marketplace',
    label: 'Marketplace',
    style: {
      textAlign: 'center'
    },
    orderById: 'source.marketplace.name'
  },
  {
    id: 'buyer',
    label: 'Buyer',
    style: {
      whiteSpace: 'nowrap',
      minWidth: '150px'
    },
    orderById: 'buyer.first_name'
  },
  {
    id: 'business',
    label: 'Business',
    orderById: 'business.name'
  },
  {
    id: 'document',
    label: 'DNI',
    orderById: 'buyer.document'
  },
  {
    id: 'phone_number',
    label: 'Phone',
    orderById: 'buyer.phone_number'
  },
  {
    id: 'courier',
    label: 'Courier',
    render: ({ courier }: OrderType, item: any, t: (str: string) => string) => (
      <Box whiteSpace="nowrap" textAlign="center">
        {t(capitalize(courier ?? ''))}
      </Box>
    ),
    orderById: 'shipment.service.courier.name'
  },
  {
    id: 'zip_code',
    label: 'Zip Code',
    style: {
      whiteSpace: 'nowrap'
    },
    orderById: 'buyer.zip'
  },
  {
    id: 'created_at',
    label: 'Origin date',
    style: {
      whiteSpace: 'nowrap',
      minWidth: '150px'
    },
    noSort: true
  },
  {
    id: 'so_number',
    label: 'Number so',
    style: {
      whiteSpace: 'nowrap'
    },
    orderById: 'so_number'
  }
];
