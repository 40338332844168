import React from 'react';
import { Button } from '@material-ui/core';
import { DatabaseConnector } from '@phinxlab/libby-rest-web';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { CarriersList } from './routes';
import { LibbyObject } from '../../types';
import { useCarriersLogic } from './hook';
import { useTranslation } from '../../services/translation';

const CarriersRaw = ({ libby }: { libby: LibbyObject }) => {
  const match = useRouteMatch();
  const { t } = useTranslation();
  const { rows, columns, working, fetchMore, handleRequestSort, orderBy, direction, setFilter, filter, openCarrierForm } = useCarriersLogic({
    libby
  });

  return (
    <Switch>
      <Route path={`${match.path}`}>
        <CarriersList
          title="Carriers"
          rows={rows}
          columns={columns}
          working={working}
          fetchMore={fetchMore}
          onSortChange={handleRequestSort}
          orderBy={orderBy}
          direction={direction}
          setFilter={setFilter}
          filter={filter}
          additionalTitle={
            <Button color="primary" type="submit" variant="contained" onClick={() => openCarrierForm()}>
              {`${t('Create')} ${t('Carrier')}`}
            </Button>
          }
        />
      </Route>
    </Switch>
  );
};

export const Carriers = DatabaseConnector(CarriersRaw)('ster_transportation_company', 'ster_state', 'ster_city');
