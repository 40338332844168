import { ReactNode } from 'react';

export type RowsType = {
  id: string;
  color_row?: string;
  [k: string]: any;
};

export type OnSortChange = (orderBy: string, direction: 'asc' | 'desc') => void;

export const descendingComparator = (a: RowsType, b: RowsType, orderBy: string) => {
  const elementA = a[orderBy]?.props?.children || a[orderBy];
  const elementB = b[orderBy]?.props?.children || b[orderBy];

  if (elementB < elementA) {
    return -1;
  }
  if (elementB > elementA) {
    return 1;
  }
  return elementA - elementB;
};

export const getComparator = (order: 'asc' | 'desc' = 'asc', orderBy: string = 'id') => (order === 'desc' ? (a: any, b: any) => descendingComparator(a, b, orderBy) : (a: any, b: any) => -descendingComparator(a, b, orderBy));

export const stableSort = (array: Array<RowsType>, comparator: (a: any, b: any) => number) => {
  const stabilizedThis = array.map((el: RowsType, index: number) => [el, index]);
  stabilizedThis.sort((a: (number | RowsType)[], b: (number | RowsType)[]) => {
    const order = comparator(a[0], b[0]);
    return order;
  });
  return stabilizedThis.map((el: Array<RowsType | number>) => el[0]);
};

export type ActionsFooterTable = {
  icon?: NonNullable<ReactNode>;
  id: string;
};
