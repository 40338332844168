import React from 'react';
import { DatabaseConnector } from '@phinxlab/libby-rest-web';
import { useParams } from 'react-router-dom';
import { useLibbyFetchById, useBreadcrumbs } from '../../../../hooks';
import { ScreenAligned } from '../../../../components/ScreenAligned/ScreenAligned';
import { InboxOrderDetailData } from './components/InboxOrderDetailData';
import { LibbyObject } from '../../../../types/types';
import { TitleBreadcrumbs } from '../../../../interfaces';

type InboxOrderDetailProps = {
  libby: LibbyObject;
  reFetch: () => void;
  title: TitleBreadcrumbs[];
};

const InboxOrderDetailRaw = ({ libby, reFetch, title }: InboxOrderDetailProps) => {
  const { id } = useParams<{ id: string }>();
  const { working, data, recall } = useLibbyFetchById(libby, {
    daoName: 'ster_inbox_order',
    id,
    aspect: 'list_inbox_order'
  });
  const titleNew = useBreadcrumbs('Inbox Order', title, `#${id}`);

  return (
    <ScreenAligned title={titleNew} working={working} showData={!!data}>
      <InboxOrderDetailData libby={libby} data={data} recall={recall} reFetch={reFetch} />
    </ScreenAligned>
  );
};

export const InboxOrderDetail = DatabaseConnector(InboxOrderDetailRaw)('ster_inbox_order');
