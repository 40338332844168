import { useMemo, useState } from 'react';
import { CompanyMarketplaceAutocomplete } from '../../../../business/CompanyMarketplace';
import { CourierAutocomplete } from '../../../../business/Courier';
import { StateAutocomplete } from '../../../../business/State';
import { Courier, Marketplace } from '../../../../interfaces/business';
import { OrderState } from '../../../../interfaces/business/general/OrderState';
import { OrderStateTurn } from '../../../../interfaces/business/general/OrderStateTurn';
import { Company } from '../../../../interfaces/business/marketplace/Company';
import { Company_marketplace } from '../../../../interfaces/business/marketplace/CompanyMarketplace';
import { FilterBarSelection } from '../../../../routes/Reporting/routes/ReportingList/FilterBar';
import { Filter } from '../../../../types';
import { DataGenerateAutocompleteType } from '../../../../utils/generateAutocomplete';
import { RowsImput } from '../../types/Rowsimput';
import { DateInput } from '../DateInput';
import { TurnAutocomplete } from '../TurnAutocomplete';
import { COURIER } from 'const';

export interface useRowsProps {
  filter: FilterBarSelection;
  working: boolean;
  filterMarketplaces: Filter;
  t: (str: string) => string;
  prepareToChange: <T, K>(value: T, name: string, setAditional?: Partial<K>, trasformDate?: 'end' | 'start') => void;
}

export const useReportingOrderByStateFilterRows = ({ filter, working, filterMarketplaces, t, prepareToChange }: useRowsProps): RowsImput[] => {
  const [disableTurn, setDisableTurn] = useState<boolean>(false);

  return useMemo(
    () => [
      {
        id_row: 1,
        container: true,
        direction: 'row',
        justify: 'space-between',
        alignItems: 'flex-end',
        spacing: 2,
        imputs: [
          {
            id: 2,
            md: 3,
            sm: 6,
            xs: 12,
            value: filter.marketplaces.map(({ id, value }, index) => ({ company_marketplace_product_id: index, company: {} as Company, marketplace: { marketplace_id: id, name: value } as Marketplace })),
            multiple: true,
            autoCompleteProps: { disabled: working, getOptionSelected: (option: Company_marketplace, _value: Company_marketplace) => option.marketplace.marketplace_id === _value.marketplace.marketplace_id },
            textFieldProps: { label: t('All marketplace') },
            filter: filterMarketplaces,
            direction: 'asc',
            orderBy: 'marketplace.name',
            onChange: (value: Company_marketplace[]) => {
              const transform = value.map(({ marketplace: { marketplace_id, name } }) => ({ id: marketplace_id, value: name }));
              prepareToChange<DataGenerateAutocompleteType[] | Date, FilterBarSelection>(transform, 'marketplaces');
            },
            component: CompanyMarketplaceAutocomplete
          },
          {
            id: 3,
            md: 3,
            sm: 6,
            xs: 12,
            value: filter.courier?.map(({ id, value }) => ({ courier_id: id, name: value })),
            multiple: true,
            autoCompleteProps: { disabled: working, getOptionSelected: (option: Courier, _value: Courier) => option.courier_id === _value.courier_id },
            textFieldProps: { label: t('All the courier') },
            onChange: (value: Courier[]) => {
              const isDisableTurn = value.length > 0 && !value?.find((courier: Courier) => courier.courier_id === COURIER.TRANSPORTE);
              setDisableTurn(isDisableTurn);
              const transform = value.map(({ courier_id, name }) => ({ id: courier_id, value: name }));
              prepareToChange<DataGenerateAutocompleteType[] | Date, FilterBarSelection>(transform, 'courier');
            },
            component: CourierAutocomplete
          },
          {
            id: 4,
            md: 3,
            sm: 6,
            xs: 12,
            value: filter.turn?.map(({ id, value }) => ({ order_state_turn_id: id, name: t(value) })),
            multiple: true,
            autoCompleteProps: { disabled: disableTurn },
            textFieldProps: { label: t('Pending of turn') },
            onChange: (value: OrderStateTurn[]) => {
              const transform = value.map(({ order_state_turn_id, name }) => ({ id: order_state_turn_id, value: t(name) }));
              prepareToChange<DataGenerateAutocompleteType[] | Date, FilterBarSelection>(transform, 'turn');
            },
            component: TurnAutocomplete
          },
          {
            id: 5,
            md: 3,
            sm: 6,
            xs: 12,
            value: filter.state?.map(({ id, value }) => ({ order_state_id: id, name: t(value) })),
            multiple: true,
            autoCompleteProps: { disabled: working, getOptionSelected: (option: OrderState, _value: OrderState) => option.order_state_id === _value.order_state_id },
            textFieldProps: { label: t('All states') },
            onChange: (value: OrderState[]) => {
              const transform = value.map(({ order_state_id, name }) => ({ id: order_state_id, value: t(name) }));
              prepareToChange<DataGenerateAutocompleteType[] | Date, FilterBarSelection>(transform, 'state');
            },
            component: StateAutocomplete
          }
        ]
      },
      {
        id_row: 2,
        container: true,
        direction: 'row',
        alignItems: 'flex-end',
        spacing: 2,
        imputs: [
          {
            id: 1,
            md: 3,
            sm: 6,
            xs: 6,
            value: filter.from,
            label: t('From'),
            disabled: working,
            onChange: (value: Date) => {
              prepareToChange<Date, FilterBarSelection>(value, 'from');
            },
            component: DateInput
          },
          {
            id: 2,
            md: 3,
            sm: 6,
            xs: 6,
            value: filter.to,
            label: t('To'),
            disabled: working,
            onChange: (value: Date) => {
              prepareToChange<Date, FilterBarSelection>(value, 'to', {}, 'end');
            },
            component: DateInput
          }
        ]
      }
    ],
    [working, filter, filterMarketplaces, t, prepareToChange, disableTurn]
  );
};
