import React from 'react';
import Grid from '@material-ui/core/Grid';
import { AnyObject } from '../../../../../types/types';
import { useTranslation } from '../../../../../services/translation';
import { InformationSection } from '../../../../../components/InformationSection';
import { makeMapLabelValues } from '../../../../utils/mappers';
import { Matches } from './Matches';
import { gridBreakpointsWithTablet } from '../../../../../const/gridBreakpoints';

const cityInformationMap = [
  {
    key: 'city_id',
    label: 'City ID'
  },
  {
    key: 'name',
    label: 'City Name'
  },
  {
    key: 'zip',
    label: 'Zip'
  },
  {
    key: 'list',
    label: 'Zip List'
  }
];

const stateInformationMap = [
  {
    key: 'state_id',
    label: 'State ID'
  },
  {
    key: 'name',
    label: 'State Name'
  }
];

export const CityDetailData = ({ data }: AnyObject) => {
  const { t } = useTranslation();
  const mapperLabelValues = makeMapLabelValues(t);
  const basicMapper = mapperLabelValues(cityInformationMap);
  const stateMapper = mapperLabelValues(stateInformationMap);

  return (
    <Grid container spacing={1} justify="space-between">
      <InformationSection title={t('City Information')} containerProps={gridBreakpointsWithTablet} noPadding>
        {basicMapper(data)}
      </InformationSection>
      <InformationSection title={t('State Information')} containerProps={gridBreakpointsWithTablet} noPadding>
        {stateMapper(data?.state)}
      </InformationSection>
      <InformationSection title={t('City Matches')} containerProps={gridBreakpointsWithTablet} noPadding>
        <Matches data={data} />
      </InformationSection>
      <InformationSection title={t('State Matches')} containerProps={gridBreakpointsWithTablet} noPadding>
        <Matches data={data?.state} />
      </InformationSection>
    </Grid>
  );
};
