import ConfirmDialog from '../../components/ConfirmDialog';
import asService from '../../hoc/asService';

const options = {
  activeProp: 'open',
  resolveProp: 'onConfirm',
  rejectProp: 'onCancel',
  forceResolveOnReject: true,
  resolveClose: 'moveBack',
  resolveErrorClose: 'closeErrorModal',
  rejectValue: false,
  resolveValue: true
};
export default asService(options)(ConfirmDialog);
