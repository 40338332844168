import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useProductStockSyncErrorLibbyFetch } from 'business/product/ProductStockSyncError';
import { ScreenAligned } from 'components/ScreenAligned/ScreenAligned';
import { useDebounceState } from 'hooks';
import { useTranslation } from 'services/translation';
import { makeFilter, columns } from './utils';
import InfoTable from 'routes/components/InfoTable';
import { RowsType } from 'types';
import { gridBreakpoints } from 'const';
import { Grid, TextField } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import { FilterBar } from 'components/FilterBar';
import LoadingData from 'routes/components/LoadingData';
import { SelectMarketPlace } from 'routes/components/SelectMarketPlace';

export const ProductStockSyncError = () => {
  const { t } = useTranslation();
  const [filter, setFilter] = useState(makeFilter({}));
  const [orderBy, setOrderBy] = useState('last_sync');
  const [direction, setDirection] = useState<'asc' | 'desc'>('asc');
  const [search, setSearch] = useState<string>('');
  const [filters, setFilters] = useDebounceState({ search }, 500);

  const paramsFetch = useMemo(
    () => ({
      filter,
      orderBy,
      direction
    }),
    [filter, orderBy, direction]
  );

  const handleRequestSort = useCallback((newOrderBy: string, newDirection: 'asc' | 'desc') => {
    setOrderBy(newOrderBy);
    setDirection(newDirection);
  }, []);

  useEffect(() => {
    setFilter(makeFilter(filters));
  }, [setFilter, filters]);

  const { data, working, fetchMore } = useProductStockSyncErrorLibbyFetch(paramsFetch);

  const rows: RowsType[] = useMemo(
    () =>
      data
        ? data?.map((error) => {
            return {
              id: error.sku,
              ...error
            };
          })
        : [],
    [data]
  );

  const handleReset = useCallback(() => {
    setSearch('');
    setFilter(makeFilter({}));
  }, [setFilter]);

  const handleChangeSearch = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setSearch(event.target.value);
    setFilters({
      ...filters,
      search: event.target.value
    });
  };

  const titleToShow = [
    {
      name: 'stock',
      additionalName: '',
      href: '/stock'
    },
    {
      name: 'Errors',
      additionalName: '',
      href: '/product-stock-sync-error'
    }
  ];

  return (
    <ScreenAligned title={titleToShow}>
      <FilterBar
        defaultFilter={
          <Grid item {...gridBreakpoints}>
            <TextField
              id="standard-search"
              placeholder={t('Search')}
              type="search"
              value={search}
              onChange={handleChangeSearch}
              InputLabelProps={{
                shrink: true
              }}
              fullWidth
              InputProps={{
                endAdornment: <SearchIcon />
              }}
              style={{ marginBottom: '40px' }}
            />
            <SelectMarketPlace inputProps={{ onChange: (event: React.ChangeEvent<HTMLInputElement>) => setFilters({ ...filters, marketplaceId: event.target.value }) }} />
          </Grid>
        }
        handleReset={handleReset}
      />
      <InfoTable columns={columns} rows={rows} onBottomScroll={fetchMore} orderBy={orderBy} direction={direction} onSortChange={handleRequestSort} />
      <LoadingData label={`${t('Loaded registers')}`} working={working} cant={rows.length} />
    </ScreenAligned>
  );
};
