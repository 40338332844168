import { LibbyFetchDAO } from './LibbyFetchDAO';

export class DispatchCartEnabledDAO extends LibbyFetchDAO {
  constructor() {
    super('ster_dispatch_cart_enabled', 'cart_id');
  }

  getAll() {
    return this.query().orderBy('name', 'asc').limit(0, 10000).run();
  }

  async getByCart(capacity: number) {
    let ls = this.query();
    if (capacity > 0) {
      ls = ls.equals('capacity', -1).or().higherOrEquals('capacity', capacity);
    }
    ls = await ls.run();

    return ls;
  }
}
