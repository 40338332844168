import React, { useCallback, useMemo } from 'react';
import { DatabaseConnector } from '@phinxlab/libby-rest-web';
import { TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { GeneralSelect } from '../../components/GeneralSelect';
import { AnyObject, LibbyObject } from '../../types/types';
import { useLibbyCall } from '../../hooks';
import { Order_source } from '../../interfaces/business';
import { ORDER_SOURCE_CANAL_OF_LINE_ENABLED } from '../../const/canalOffLine';
import { useTranslation } from '../../services/translation';
import { DataGenerateSelectType } from '../../utils/generateSelect';

type SelectMarketPlaceTypeProps = {
  inputProps: AnyObject;
  optionAll?: boolean;
  [k: string]: any;
  libby: LibbyObject;
  canalOffLine: boolean;
  sort?: boolean | 'asc' | 'desc';
  disabled?: boolean;
  multiple: boolean;
};

export const SelectMarketPlaceRaw = ({ multiple = false, disabled, libby, inputProps = {}, optionAll = false, canalOffLine, sort, ...styles }: SelectMarketPlaceTypeProps) => {
  const { t } = useTranslation();
  const { data: dataOrderSource, working = true } = useLibbyCall(libby, {
    daoName: 'ster_order_source',
    methodName: canalOffLine ? 'getAllByOrderSourceType' : 'getAll',
    params: canalOffLine ? [ORDER_SOURCE_CANAL_OF_LINE_ENABLED] : []
  });

  const data = useMemo(
    () =>
      dataOrderSource.map(({ marketplace: { marketplace_id, name } }: Order_source) => ({
        id: marketplace_id,
        value: name
      })),
    [dataOrderSource]
  );

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const onChangeAutoComplete = useCallback(
    (event, newValue: DataGenerateSelectType[]) => {
      inputProps.onChange(
        newValue.map((marketplace: DataGenerateSelectType) => ({
          value: marketplace.id,
          path: ''
        }))
      );
    },
    [inputProps]
  );

  return (
    <>
      {multiple ? (
        <Autocomplete
          disabled={disabled}
          style={{ display: 'inline-block', width: '100%' }}
          multiple
          id="tags-outlined"
          options={data}
          getOptionLabel={(option: { id: string; value: string }) => option.value}
          filterSelectedOptions
          onChange={onChangeAutoComplete}
          renderInput={(params) => <TextField {...params} variant="outlined" label={t('All marketplace')} placeholder="" />}
        />
      ) : (
        <GeneralSelect disabled={disabled} working={working} label="Marketplace" optionAll={optionAll} data={data} inputProps={inputProps} {...styles} />
      )}
    </>
  );
};

export const SelectMarketPlace = DatabaseConnector(SelectMarketPlaceRaw)('ster_order_source');
