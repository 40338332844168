import React from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Grid, TextField, Typography } from '@material-ui/core';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { AccordionType } from '../types/types';

import { SelectProvince } from '../../SelectProvince';
import { AutocompleteCity } from '../../AutocompleteCity';
import { FormFieldsBuyerTypes } from '../../../types/FormConfig';
import { useTranslation } from '../../../services/translation';

export const AccordionBuyerAddress = ({ classes, errorBuyerAddress, verificateZipBuyer, buyerProvince, buyerCity, errorBuyerProvince, errorBuyerCity, formFieldsBuyerAddress }: AccordionType) => {
  const { t } = useTranslation();

  return (
    <Grid className={classes.rootAccordion}>
      <Accordion className={classes.accordion}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
          <Typography className={errorBuyerAddress || !verificateZipBuyer ? classes.headingAccordionError : classes.headingAccordion}>{`${t('Buyer Address')} ${errorBuyerAddress || !verificateZipBuyer ? '*' : ''}`}</Typography>
        </AccordionSummary>
        <AccordionDetails className={classes.rootDetailsAccordion}>
          <Grid xs={12}>
            <SelectProvince
              name="buyerProvince"
              inputProps={{
                onChange: buyerProvince.input.onChange,
                value: buyerProvince.input.value,
                classes: classes.textFieldSelect,
                error: errorBuyerProvince,
                onBlur: buyerProvince.input.onBlur
              }}
            />
            <AutocompleteCity
              name="buyerCity"
              inputProps={{
                province: buyerProvince.input.value,
                onChange: buyerCity.input.onChange,
                value: buyerCity.input.value,
                error: errorBuyerCity,
                onBlur: buyerCity.input.onBlur
              }}
            />
            {formFieldsBuyerAddress.map(({ field, errorField, label, customeValid, ...props }: FormFieldsBuyerTypes) => (
              <>
                <TextField
                  key={props.name}
                  margin="normal"
                  InputLabelProps={{ shrink: true }}
                  value={field.input.value}
                  onChange={field.input.onChange}
                  className={props.class}
                  label={t(label)}
                  onBlur={field.input.onBlur}
                  name={props.name}
                  {...errorField}
                />
                {customeValid ? (
                  verificateZipBuyer ? (
                    ''
                  ) : (
                    <Typography className={classes.error} color="primary">
                      {`${t('The value do not match the city zip codes')} *`}
                    </Typography>
                  )
                ) : (
                  ''
                )}
              </>
            ))}
          </Grid>
        </AccordionDetails>
      </Accordion>
    </Grid>
  );
};
