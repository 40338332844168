import React, { useCallback } from 'react';
import { Switch, Route, useRouteMatch, useHistory } from 'react-router-dom';
import { DatabaseConnector } from '@phinxlab/libby-rest-web';
import { useBreadcrumbs, useFilerParamsId } from '../../hooks';
import { useStorePickupTableLogic } from '../Orders/routes/OrdersList/hook/useStorePickupTableLogic';
import { LibbyObject } from '../../types';
import { OrderStateLogs } from '../Orders/routes/OrderStateLogs';
import { makeFilterPickup } from '../Orders/routes/OrdersList/utils/makeFilterPickup';
import { OrderDetail } from '../Orders/routes/OrderDetail';
import { StorePickupList } from './StorePickupList/StorePickupList';
import { ORDER_STATE_STORE_PICKUP, COURIER_SERVICE_STORE_PICKUP } from '../../const/storePickup';

export const StorePickupRouter = ({ libby }: LibbyObject) => {
  const match = useRouteMatch();
  const title = useBreadcrumbs('Store Pickup');
  const history = useHistory();

  const filterInit = makeFilterPickup({ orderStateStorePickup: ORDER_STATE_STORE_PICKUP, courierServiceStorePickup: COURIER_SERVICE_STORE_PICKUP });

  const detailsRedirection = useCallback((order_id: string) => history.push(`orders/detail/${order_id}`), [history]);

  const { filter, orderBy, direction, paramsFetch, setFilter, handleRequestSort, resetFilter } = useFilerParamsId({
    orderInit: 'order_id',
    daoName: 'ster_order',
    aspect: 'list_order_so_order',
    init: filterInit
  });

  const { rows, columns, working, fetchMore, allButtonsAction } = useStorePickupTableLogic({
    libby,
    paramsFetch,
    detailsRedirection,
    columnCheckEnabled: true,
    actions: false,
    path: match.path,
    deliveryDate: true
  });

  return (
    <Switch>
      <Route path={`${match.path}/logs/:id`}>
        <OrderStateLogs title={title} />
      </Route>
      <Route path={`${match.path}/detail/:id`}>
        <OrderDetail title={title} />
      </Route>
      <Route path={`${match.path}`}>
        <StorePickupList
          title={title}
          detailUrl={`${match.path}/detail`}
          setFilter={setFilter}
          rows={rows}
          columns={columns}
          working={working}
          filter={filter}
          fetchMore={fetchMore}
          onSortChange={handleRequestSort}
          orderBy={orderBy}
          direction={direction}
          allButtonsAction={allButtonsAction}
          resetFilter={resetFilter}
        />
      </Route>
    </Switch>
  );
};

export const StorePickup = DatabaseConnector(StorePickupRouter)('ster_order', 'ster_order_table', 'ster_order_document_generate', 'ster_order_so_with_delivery_date');
