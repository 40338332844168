import { useCallback, useMemo } from 'react';
import { uniqBy } from 'lodash';
import moment from 'moment';
import { useLibbyFetchById } from '../../../../../hooks';
import { LibbyObject, Order } from '../../../../../types';
import { Product } from '../../../../../interfaces/business';
import { API_URL } from '../../../../../config';
import { companyMarketplace, GET_LOCATION_STOCK, LOCATION_ID_BY_COMPANY } from '../../../../../const';
import { OrderItemType } from './useSaveOrderLogic';

interface UseEditOrderProps {
  libby: LibbyObject;
  orderId: string;
}

export const useEditOrder = ({ libby, orderId }: UseEditOrderProps) => {
  const { data } = useLibbyFetchById(libby, {
    daoName: 'ster_order_table',
    id: orderId
  });

  const getFormValues = useCallback((order: Order) => {
    const { buyer, shipment } = order;
    const formValues = {
      first_name: buyer?.first_name,
      last_name: buyer?.last_name,
      document: buyer?.document,
      document_type: buyer?.document_type?.document_type_id,
      phone_number: buyer?.phone_number,
      email: buyer?.email,
      billingAddressState: buyer?.city?.state.state_id,
      billingAddressCity: buyer?.city?.name,
      billingAddressStreet: buyer?.street,
      billingAddressFloor: buyer?.floor,
      billingAddressDepartment: buyer?.department,
      billingAddressStreetNumber: buyer?.street_number,
      billingAddressZip: buyer?.zip,
      shipmentAddressState: shipment?.address?.city?.state?.state_id,
      shipmentAddressCity: shipment?.address?.city?.name,
      shipmentAddressStreet: shipment?.address?.street,
      shipmentAddressStreetNumber: shipment?.address?.street_number,
      shipmentAddressZip: shipment?.address?.zip,
      shipment_type: order.shipment?.service?.courier_service_id,
      payment_method: order?.orderB2Bs ? order?.orderB2Bs[0].payment_method_id : 0,
      custom_term: order?.orderB2Bs ? order?.orderB2Bs[0].custom_term : '',
      qty_deferred_payment: order?.orderB2Bs ? order?.orderB2Bs[0].qty_deferred_payment : 0,
      qty_delivery_days: order?.orderB2Bs ? order?.orderB2Bs[0].qty_delivery_days : 0,
      transportation_company: shipment?.transportation_company?.transportation_company_id || '',
      advance_sale_date: order?.orderB2Bs ? (order.orderB2Bs[0].advanceSaleDate ? moment(order.orderB2Bs[0].advanceSaleDate, 'YYYY-MM-DD') : null) : null,
      advance_sale: order.orderB2Bs && order.orderB2Bs[0].advanceSale
    };
    return formValues;
  }, []);

  const getItems = useCallback(
    async (order: Order) => {
      const itemsBySku = uniqBy(order.items, 'sku');
      let products: Product[] = [];
      for (let i = 0; i < itemsBySku?.length; i++) {
        /* eslint-disable-next-line */
        const [product] = await libby.ster_product.query().equals('sku', itemsBySku[i].sku).run();
        products.push(product);
      }
      const company = companyMarketplace.find((e) => e.order_source_id === Number(order.source?.order_source_id));
      const body = {
        locations_id: [LOCATION_ID_BY_COMPANY[company?.company_id || 0]],
        company_id: company?.company_id,
        skus: itemsBySku.map((e) => e.sku)
      };

      try {
        const response = await fetch(`${API_URL}/${GET_LOCATION_STOCK}`, {
          method: 'POST',
          body: JSON.stringify(body),
          headers: { 'Content-Type': 'application/json' }
        });

        const json = await response.json();
        const stock = json?.result?.message?.stock || [];

        products = products.map((p) => ({
          ...p,
          stock: stock.find((e: any) => e.product_sku === p.sku)?.qty || 0,
          company: company?.company_id,
          company_name: company?.name
        }));
      } catch (e: any) {
        console.log('e', e);
        return [];
      }

      const orderItems =
        order.items?.map((item) => ({
          ...item,
          product: products.find((pr) => pr.sku === item.sku)
        })) || [];

      return orderItems as OrderItemType[];
    },
    [libby.ster_product]
  );

  const formValues = useMemo(() => data && getFormValues(data), [getFormValues, data]);

  const formItems = useMemo(() => data && getItems(data), [data, getItems]);

  return { previousOrderData: data, formValues, formItems };
};
