import React from 'react';
import { Box, capitalize } from '@material-ui/core';
import { OrderStateColors } from '../../const/OrderState';
import { OrderType } from '../../types';

export const B2BOrderColumns = {
  actions: {
    id: 'actions',
    label: '',
    noSort: true
  },
  orderId: {
    id: 'order_id',
    label: 'ID Order',
    style: {
      whiteSpace: 'nowrap',
      minWidth: '150px'
    }
  },
  orderState: {
    id: 'order_state',
    label: 'State',
    style: {
      textAlign: 'center'
    },
    render: ({ order_state_id, order_state_name }: OrderType, item: any, t: (str: string) => string) => (
      <Box color={OrderStateColors[order_state_id ?? '']} whiteSpace="nowrap">
        {t(capitalize(order_state_name ?? ''))}
      </Box>
    ),
    orderById: 'state.name'
  },
  buyer: {
    id: 'buyer',
    label: 'Buyer',
    style: {
      whiteSpace: 'nowrap',
      minWidth: '150px'
    },
    orderById: 'buyer.first_name'
  },
  complete_name: {
    id: 'complete_name',
    label: 'Business',
    orderById: 'complete_name'
  },
  marketplace: {
    id: 'marketplace',
    label: 'Marketplace',
    style: {
      whiteSpace: 'nowrap'
    },
    orderById: 'marketplace'
  },
  currency: {
    id: 'currency',
    label: 'Currency',
    style: {
      whiteSpace: 'nowrap'
    },
    orderById: 'currency_id'
  },
  document: {
    id: 'document',
    label: 'DNI',
    orderById: 'buyer.document'
  },
  documents: {
    id: 'documents',
    label: 'Number of Documents',
    style: {
      whiteSpace: 'nowrap'
    },
    noSort: true
  },
  items: {
    id: 'items',
    label: 'Number of Items',
    style: {
      whiteSpace: 'nowrap'
    },
    orderById: 'number_items'
  },
  total: {
    id: 'total',
    label: 'Total',
    orderById: 'total'
  },
  updatedBy: {
    id: 'updated_by',
    label: 'Updated by',
    style: {
      whiteSpace: 'nowrap',
      minWidth: '150px'
    },
    noSort: true
  },
  created_at: {
    id: 'created_at',
    label: 'Origin date',
    style: {
      whiteSpace: 'nowrap',
      minWidth: '150px'
    },
    noSort: true
  },
  detail: {
    id: 'detail',
    label: 'Detail',
    noSort: true
  },
  logs: {
    id: 'logs',
    label: 'Logs',
    noSort: true
  },
  soNumber: {
    id: 'so_number',
    label: 'Number so',
    style: {
      whiteSpace: 'nowrap'
    },
    orderById: 'so_number'
  },
  message: {
    id: 'message',
    label: 'Message',
    noSort: true
  }
};
