import React, { useMemo, useCallback, useEffect, useRef, useState } from 'react';
import { Autocomplete } from '@material-ui/lab';
import { TextField, Grid, Paper, Typography } from '@material-ui/core';
import { useForm, useField } from 'react-final-form-hooks';
import { DatabaseConnector } from '@phinxlab/libby-rest-web';
import { useSnackbar } from 'notistack';
import { useStyles } from '../const';
import { useLibbyCall } from '../../../../../hooks';
import { useTranslation } from '../../../../../services/translation';
import { LibbyObject } from '../../../../../types';
import CustomModal from '../../../../../services/customFormDialog';
import { ProductSelection } from './ProductSelection';
import { Product } from '../../../../../interfaces/business';
import { API_URL } from '../../../../../config';
import { Company } from '../../../../../interfaces/business/marketplace/Company';
import { GET_LOCATION_STOCK, GET_PRICE_LIST_B2B, LOCATION_ID_BY_COMPANY } from '../../../../../const';
import { BudgetItems, BudgetItemsProps } from './BudgetItems';

interface ProductSearchProps extends BudgetItemsProps {
  libby: LibbyObject;
  onProductAdded: (product: Product) => void;
  isEdit: boolean;
}

interface OdooPriceList {
  company: Number;
  list: Number;
  price: Number;
}

const ProductSelectionModal = CustomModal(ProductSelection);

const ProductSearchRaw = ({ libby, onProductAdded, items, removeItem, editItem, isEdit }: ProductSearchProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const { data: products, working } = useLibbyCall(libby, { daoName: 'ster_product', methodName: 'getAll' });
  const { data: companies } = useLibbyCall(libby, { daoName: 'ster_company', methodName: 'getAllCompany' });
  const autoC = useRef(null);
  const searchInputValue = useRef<string>('');

  const { form } = useForm({
    initialValues: { search: '' },
    onSubmit: () => {}
  });
  const { t } = useTranslation();

  const search = useField('search', form);

  const options = useMemo(() => products.map((product: Product) => ({ id: product.sku, value: `${product.sku} - ${product.name}` })), [products]);

  const verifyProductWithOdoo = useCallback(
    async (sku: string) => {
      const body = {
        sku,
        lists: [168, 170, 171],
        payment_methods: [114], // EFECTIVO
        channel: 'offline'
      };
      let prices = [];
      try {
        setLoading(true);
        const response = await fetch(`${API_URL}/${GET_PRICE_LIST_B2B}`, {
          method: 'POST',
          body: JSON.stringify(body),
          headers: { 'Content-Type': 'application/json' }
        });
        const json = await response.json();
        prices = json?.result?.message?.prices || [];
      } catch {
        enqueueSnackbar(t('Something wrong with Odoo'), { variant: 'error' });
      } finally {
        setLoading(false);
      }

      try {
        for (let i = 0; i < prices?.length; i++) {
          const locationStockBody = {
            locations_id: [LOCATION_ID_BY_COMPANY[prices[i].company]],
            company_id: prices[i].company,
            skus: [sku]
          };
          /* eslint-disable-next-line no-await-in-loop */
          const response = await fetch(`${API_URL}/${GET_LOCATION_STOCK}`, {
            method: 'POST',
            body: JSON.stringify(locationStockBody),
            headers: { 'Content-Type': 'application/json' }
          });
          /* eslint-disable-next-line no-await-in-loop */
          const json = await response.json();
          prices[i].stock = json?.result?.message?.stock[0]?.qty || 0;
        }
      } catch {
        enqueueSnackbar(t('Something wrong with Stock'), { variant: 'error' });
      } finally {
        setLoading(false);
      }
      if (prices?.length !== 0) {
        const product = products.find((pr: Product) => pr.sku === sku);
        const productWithPriceByCompany = prices.map((price: OdooPriceList) => ({
          ...product,
          ...price,
          company_name: companies.find((companyItem: Company) => Number(companyItem.company_id) === price.company)?.name
        }));

        await ProductSelectionModal.show({
          products: productWithPriceByCompany,
          onRowClick: (pr: Product) => {
            onProductAdded(pr);
            try {
              // @ts-ignore
              const ele = autoC?.current?.getElementsByClassName('MuiAutocomplete-clearIndicator')[0];
              searchInputValue.current = '';
              search.input.onChange('');
              if (ele) ele.click();
            } catch {
              enqueueSnackbar(t('Something wrong with User Interface'), { variant: 'error' });
            }
          }
        });
      } else enqueueSnackbar(t('This product does not match with the company you selected'), { variant: 'error' });
    },
    [products, enqueueSnackbar, t, companies, onProductAdded, search.input]
  );

  useEffect(() => {
    if (search.input.value && search.input.value !== searchInputValue.current) {
      searchInputValue.current = search.input.value;
      verifyProductWithOdoo(search.input.value);
    }
  }, [search.input.value, verifyProductWithOdoo]);

  return (
    <Grid item xs={12}>
      <Paper className={classes.paper}>
        <Grid item container>
          <Grid xs={12}>
            <Typography variant="h1" className={classes.title}>{`${t('Products')}`}</Typography>
          </Grid>
        </Grid>
        <Autocomplete
          loading={loading || working}
          style={{ display: 'inline-block', width: '100%' }}
          id="budget-product-search"
          options={options}
          autoSelect
          getOptionLabel={(option: { id: string; value: string }) => option.value}
          filterSelectedOptions
          renderInput={(params) => (
            <form noValidate>
              <TextField {...params} className={classes.textFieldAutocomplete} label={t('Product')} placeholder="" onBlur={search.input.onBlur} />
            </form>
          )}
          onChange={(e, newValue) => {
            search.input.onChange(newValue && newValue.id.toString());
          }}
          noOptionsText="sin opciones"
          clearOnBlur
          ref={autoC}
        />
        <BudgetItems items={items} removeItem={removeItem} editItem={editItem} isEdit={isEdit} />
      </Paper>
    </Grid>
  );
};

export const ProductSearch = DatabaseConnector(ProductSearchRaw)('ster_product', 'ster_company');
