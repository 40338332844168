import React from 'react';
import { Grid } from '@material-ui/core';
import { BackButton } from '../BackButton';
import { LoadingModal } from '../LoadingModal';
import { Screen } from '../Screen/Screen';
import { ScreenTitle } from '../ScreenTitle';
import { TitleBreadcrumbs } from '../../interfaces';

type ScreenAlignedProps = {
  title: TitleBreadcrumbs[] | string;
  working?: Boolean;
  backTo?: string;
  showData?: Boolean;
  children: React.ReactNode;
  additionalTitle?: React.ReactElement | null;
  style?: React.CSSProperties;
};

export const ScreenAligned = React.memo(({ title, working = false, showData = true, children, additionalTitle = null, backTo, style }: ScreenAlignedProps) => (
  <Screen style={style}>
    {working ? (
      <LoadingModal />
    ) : (
      showData && (
        <>
          <Grid container wrap="nowrap" alignItems="center" justify="flex-start">
            {!!backTo && <BackButton to={backTo} />}
            <ScreenTitle title={title}>
              <Grid container spacing={1}>
                {additionalTitle}
              </Grid>
            </ScreenTitle>
          </Grid>
          {children}
        </>
      )
    )}
  </Screen>
));
