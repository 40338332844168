import React from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import { MENU, MENU_ACTIONS } from '../../const';
import { useMenuAction } from '../../hooks/useMenuActions';
import { ReportingObjectiveSalesList, ReportingObjectiveSalesSettingList, ReportingObjectSaleSettingFields } from './routes';
import { ReportingObjectSaleSettingFieldsAdd } from './routes/ReportingObjectSaleSettingFieldsAdd';
import { ReportingObjectSaleSettingPeriod } from './routes/ReportingObjectSaleSettingPeriod';

export const ReportingObjectSales = () => {
  const match = useRouteMatch();
  const { validateMenuActions } = useMenuAction(MENU.REPORTING_OBJECT_SALES);
  return (
    <Switch>
      <Route exact path={`${match.path}`}>
        <ReportingObjectiveSalesList />
      </Route>
      {validateMenuActions(MENU_ACTIONS.SETTING.toString()) && (
        <>
          <Route exact path={`${match.path}/setting`}>
            <ReportingObjectiveSalesSettingList />
          </Route>

          <Route exact path={`${match.path}/setting/fields/:goal_id`}>
            <ReportingObjectSaleSettingFields />
          </Route>
          <Route exact path={`${match.path}/setting/fields/:goal_id/add`}>
            <ReportingObjectSaleSettingFieldsAdd />
          </Route>
          <Route exact path={`${match.path}/setting/period/:goal_id`}>
            <ReportingObjectSaleSettingPeriod />
          </Route>
        </>
      )}
    </Switch>
  );
};
