export const stockLogsColumns = [
  {
    id: 'id',
    label: 'Sku',
    orderById: 'sku'
  },
  {
    id: 'quantity',
    label: 'Quantity',
    orderById: 'quantity'
  },
  {
    id: 'marketplace',
    label: 'Marketplace',
    orderById: 'marketplace.name'
  },
  {
    id: 'updated_by',
    label: 'Updated By',
    orderById: 'updated_by.username'
  },
  {
    id: 'updated_at',
    label: 'Updated At',
    orderById: 'updated_at'
  }
];
