import React, { useCallback, useState, useEffect } from 'react';
import { useForm, FormConfig, useField } from 'react-final-form-hooks';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Typography } from '@material-ui/core';
import { ThemeProvider, makeStyles } from '@material-ui/styles';
import { useTranslation } from '../../../services/translation';
import { makeGetErrorAndHelperText, textFieldProps } from '../../../utils/materialHelpers';
import { formEditStock, ProductStockNew } from '../../../interfaces/business';
import { AccordionMarket } from './AccordionMarket';

const useStyles = makeStyles({
  title: {
    fontFamily: 'Oswald'
  },
  error: {
    fontSize: '12px'
  },
  inputs: {
    marginTop: '5px',
    width: '48%'
  },
  inputsContainer: {
    marginTop: '15px'
  },
  switches: {
    width: '100%'
  }
});

interface StockEditNewDialogProps extends FormConfig<any> {
  open: boolean;
  onCancel: any;
  onConfirm: Function;
  title: string;
  content: React.ReactNode;
  labelContent: string;
  cancelText: string;
  confirmText: string;
  customTheme: string | null;
  inputType: string;
  formInitialValues: formEditStock;
  dataInner: ProductStockNew;
  reset?: boolean;
}

export const StockEditNewDialog = ({ dataInner, open = false, onCancel = () => {}, onConfirm = () => {}, title, cancelText, confirmText, customTheme = null, formInitialValues }: StockEditNewDialogProps) => {
  const { t } = useTranslation();
  const getErrorAndHelperText = makeGetErrorAndHelperText(t);
  const classes = useStyles();
  const [cancel, setCancel] = useState(false);
  const [errorSecurityStock, setErrorSecurityStock] = useState(false);

  const findDifferences = useCallback(
    (submitValues: any) => {
      const result: any = { marketplaces: [] };

      if (formInitialValues.base !== submitValues.base) result.base = submitValues.base;
      if (formInitialValues.min !== submitValues.min) result.min = submitValues.min;

      const marketKeys = Object.keys(formInitialValues.marketplaces[0]);

      for (let i = 0; i < formInitialValues.marketplaces.length; i++) {
        const marketInitialValues: any = formInitialValues.marketplaces[i];
        const resultMarket: any = {
          marketplace_id: marketInitialValues.marketplace_id,
          marketplace_name: marketInitialValues.marketplace_name
        };

        for (let j = 0; j < marketKeys.length; j++) {
          const key = marketKeys[j];

          if (marketInitialValues[key] !== submitValues.marketplaces[i][key]) resultMarket[key] = submitValues.marketplaces[i][key];
        }

        if (Object.keys(resultMarket).length > 2) result.marketplaces.push(resultMarket);
      }

      return result;
    },
    [formInitialValues]
  );

  const onSubmit = useCallback(
    async (object) => {
      onConfirm(findDifferences(object));
    },
    [findDifferences, onConfirm]
  );

  const { form, handleSubmit, values } = useForm({
    initialValues: formInitialValues,
    onSubmit
  });

  const security_base = useField('base', form);
  const security_min = useField('min', form);

  useEffect(() => {
    if (parseInt(security_min.input.value, 10) >= parseInt(security_base.input.value, 10)) setErrorSecurityStock(true);
    else setErrorSecurityStock(false);
  }, [security_base.input.value, security_min.input.value]);

  const dialog = (
    <Dialog open={open} onClose={onCancel} aria-labelledby="form-dialog-title" fullWidth maxWidth="md">
      <DialogTitle id="form-dialog-title">
        <Typography className={classes.title} variant="h3">
          {title}
        </Typography>
      </DialogTitle>
      <form onSubmit={handleSubmit}>
        <DialogContent>
          <Typography variant="body1">
            <strong>{`${t('Product Name')}:`}</strong>
          </Typography>
          <Typography variant="body1">{dataInner?.name}</Typography>
          <br />
          <Typography variant="body1">
            <strong>{`${t('Sku')}:`}</strong>
          </Typography>
          <Typography>{dataInner?.sku}</Typography>
          <br />
          <Box display="flex" justifyContent="space-between">
            <Typography variant="body1">
              <strong>{'Odoo stock: '}</strong>
              {dataInner?.odoo_stock}
            </Typography>
            <Typography variant="body1">
              <strong>{'Reserva: '}</strong>
              {dataInner?.reserve}
            </Typography>
            <Typography variant="body1">
              <strong>{'Ultima Sincronización: '}</strong>
              {dataInner?.last_sync}
            </Typography>
          </Box>
          <Box className={classes.inputsContainer}>
            <Typography variant="h6">
              <strong>{`${t('Security Stock')}: `}</strong>
            </Typography>
            <Box display="flex" justifyContent="space-between">
              <TextField
                {...textFieldProps(`${t('Base Stock')}`)}
                onChange={(event) => security_base.input.onChange(Number(event.target.value))}
                value={security_base.input.value}
                variant="standard"
                type="number"
                InputLabelProps={{
                  shrink: true
                }}
                InputProps={{ inputProps: { min: 0 } }}
                onBlur={security_base.input.onBlur}
                {...getErrorAndHelperText(security_base)}
                className={classes.inputs}
                error={errorSecurityStock || getErrorAndHelperText(security_base).error}
                helperText={errorSecurityStock ? t('The “Base Stock” cannot be equal to or less than the “Minimum Stock”.') : getErrorAndHelperText(security_base).error ? getErrorAndHelperText(security_base).helperText : ''}
              />
              <TextField
                {...textFieldProps(`${t('Minimum Stock')}`)}
                onChange={(event) => security_min.input.onChange(Number(event.target.value))}
                value={security_min.input.value}
                variant="standard"
                type="number"
                InputLabelProps={{
                  shrink: true
                }}
                InputProps={{ inputProps: { min: 0 } }}
                onBlur={security_min.input.onBlur}
                className={classes.inputs}
                error={errorSecurityStock || getErrorAndHelperText(security_min).error}
                helperText={errorSecurityStock ? t('The “Minimum Stock” cannot be equal to or higher than the “Base Stock”.') : getErrorAndHelperText(security_min).error ? getErrorAndHelperText(security_min).helperText : ''}
              />
            </Box>
          </Box>
          <Box mb={1}>
            <Typography variant="h6">
              <strong>{'Marketplaces: '}</strong>
            </Typography>
          </Box>

          {formInitialValues &&
            formInitialValues.marketplaces.map((marketplace: any, index: number) => (
              <AccordionMarket title={marketplace.marketplace_name} form={form} values={values?.marketplaces ? values?.marketplaces[index] : formInitialValues.marketplaces[index]} odooStock={dataInner.odoo_stock} index={index} />
            ))}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              onCancel();
              setCancel(!cancel);
            }}
          >
            {cancelText}
          </Button>

          <Button color="primary" type="submit" autoFocus>
            {confirmText}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );

  return !customTheme ? dialog : <ThemeProvider theme={customTheme}>{dialog}</ThemeProvider>;
};
