import { FieldRenderProps } from 'react-final-form-hooks';
import { TextFieldProps } from '@material-ui/core';
import { TranslationFunction } from '../services/translation/service';

export const makeGetErrorAndHelperText =
  (t: TranslationFunction = (str: string) => str) =>
  (field: FieldRenderProps) => {
    const helperText = field.meta.touched && field.meta.error && t(field.meta.error);
    const error = !!helperText;
    if (!error) {
      return { error };
    }
    return { error, helperText };
  };

export const textFieldProps = (label: string): TextFieldProps => ({
  id: label,
  label,
  fullWidth: true,
  variant: 'outlined',
  margin: 'normal'
});
