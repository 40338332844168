import React from 'react';
import { DatabaseConnector } from '@phinxlab/libby-rest-web';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import { ReportingB2BStockList } from './routes';

const ReportB2BStockRaw = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${path}`}>
        <ReportingB2BStockList />
      </Route>
    </Switch>
  );
};

export const ReportB2BStock = DatabaseConnector(ReportB2BStockRaw)('ster_report_b2b_stock');
