import React, { useCallback, useMemo, MouseEvent } from 'react';
import { useSnackbar } from 'notistack';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'services/translation';
import { useBreadcrumbs, useFilerParamsId } from 'hooks';
import { MarketplaceChargeback } from 'interfaces/business';
import { RowsType } from 'utils/tables';
import CustomModal from '../../../../../services/customFormDialog';
import { MenuActions, MenuItemComponentType } from '../../../../../components/MenuActions';
import ConfirmDialog from '../../../../../components/ConfirmDialog';
import { ButtonDetailsType } from '../../../../../types/Button';
import { TitleBreadcrumbs } from '../../../../../interfaces';
import { useMarketplaceChargebackLibbyFetch } from '../../../../../business/marketplace';
import { LibbyObject } from 'types';

const ConfirmModal = CustomModal(ConfirmDialog);
export const useReportingChargebackMarketplaceLogic = ({ libby }: { libby: LibbyObject }) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const { orderBy, direction, paramsFetch, handleRequestSort, setFilter, filter } = useFilerParamsId({
    orderInit: 'marketplace.name',
    daoName: 'ster_marketplace_chargeback'
  });

  const { data: allData, fetchMore, working, updateData } = useMarketplaceChargebackLibbyFetch(paramsFetch);

  const { state } = useLocation<{ goBack: TitleBreadcrumbs[] }>();

  const title = useBreadcrumbs(t('Settings Chargeback Marketplaces'));
  const titleShow = useBreadcrumbs(t('Settings Chargeback Marketplaces'), state && state?.goBack?.length ? state.goBack : title);

  const handleOpenSyncMarketplace = useCallback(
    async (marketplace_chargeback: MarketplaceChargeback) => {
      try {
        const validation = await ConfirmModal.show({
          title: t(`${marketplace_chargeback?.active ? 'Disable' : 'Enable'} Marketplace`),
          content: t(`Are you sure that you want to ${marketplace_chargeback?.active ? 'disable' : 'enable'} this marketplace?`),
          confirmText: t('Yes'),
          cancelText: t('No')
        });
        if (validation) {
          const updateResponse = await libby.ster_marketplace_chargeback.save({ ...marketplace_chargeback, active: !marketplace_chargeback.active });
          updateData(updateResponse, 'chargeback_marketplace_id');
          enqueueSnackbar(t(`Marketplace ${marketplace_chargeback?.active ? 'disabled' : 'enabled'} successfully`), { variant: 'success' });
        }
      } catch (err) {
        console.log(err, 'err');
        if (err !== false) {
          enqueueSnackbar(t(`There was a problem while trying to ${marketplace_chargeback?.active ? 'disable' : 'enable'} this marketplace`), { variant: 'error' });
        }
      }
    },
    [libby, t, enqueueSnackbar, updateData]
  );

  const menuArray = useCallback(
    (marketplace_chargeback: MarketplaceChargeback): MenuItemComponentType[] => {
      const menu: MenuItemComponentType[] = [
        {
          title: marketplace_chargeback.active ? t('Disable') : t('Enable'),
          onClick: (e: MouseEvent) => {
            e.stopPropagation();
            handleOpenSyncMarketplace(marketplace_chargeback);
          },
          disabled: false,
          color: marketplace_chargeback.active ? 'error' : 'inherit'
        }
      ];
      return menu.filter((menuItems) => menuItems.disabled === false);
    },
    [handleOpenSyncMarketplace, t]
  );
  const rows: RowsType[] = useMemo(() => {
    const dataOrder = allData as MarketplaceChargeback[];
    return dataOrder.map((marketplace_chargeback: MarketplaceChargeback) => {
      const {
        chargeback_marketplace_id,
        active,
        marketplace: { name: marketplace_name }
      } = marketplace_chargeback;
      return {
        id: chargeback_marketplace_id,
        ...marketplace_chargeback,
        active,
        marketplace: marketplace_name,
        actions: <MenuActions menuArray={menuArray(marketplace_chargeback)} />
      };
    });
  }, [allData, menuArray]);

  const buttons: ButtonDetailsType[] = useMemo(() => [], []);

  return {
    working,
    direction,
    orderBy,
    handleRequestSort,
    setFilter,
    filter,
    rows,
    fetchMore,
    buttons,
    titleShow
  };
};
