import React, { useMemo } from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import { DatabaseConnector } from '@phinxlab/libby-rest-web';
import Moment from 'moment';
import { LibbyObject } from '../../types';
import { OrderDetail } from '../Orders/routes/OrderDetail';
import { OrderReportList } from './routes';
import { useOrderReportLogic } from './routes/OrderReportList/hook/useOrderReport';
import { useLibbyFetch } from '../../hooks';
import { ReportAreaTime } from '../../types/ReportAreaTime';
import { OrderState } from '../../const';
import { useTranslation } from '../../services/translation';
import { DataFilterDownloadInterface, DataFilterDownloadInterfaceSpanish } from '../../interfaces/business/general/DataFilterDownload';

const paramsFetch = {
  daoName: 'ster_order_area_time'
};

export const OrderReportRaw = ({ libby }: LibbyObject) => {
  const { path } = useRouteMatch();

  const { searchFilter, rows, working, fetchMore, handleRequestSort, filter, title, orderBy, direction, setFilter, pathDetails } = useOrderReportLogic({ libby, path });

  const { data: data_area_time, working: working_area_time } = useLibbyFetch(libby, paramsFetch);

  const { t } = useTranslation();

  const handleDataCSV = useMemo(() => {
    const dataFilter: DataFilterDownloadInterface[] | DataFilterDownloadInterfaceSpanish[] = [];
    rows.forEach((value) => {
      const singleState = OrderState[value.state];
      const originDate = Moment(value.created_at).format('DD-MM-YYYY HH:mm');
      const DaysPerArea = t('Days_per_area');
      if (DaysPerArea === 'Days_per_area') {
        const newArray: DataFilterDownloadInterface = {
          Id_Orden: value.order_id,
          So_Number: value.so_number,
          State: t(singleState),
          Area: t(value.meta_state),
          Days_per_area: value.days_meta_state,
          Total_days: value.total_time,
          Marketplace: value.marketplace,
          Customer: value.buyer,
          Total_Price: value.total_price,
          Origin_Date: originDate,
          Courier: value.courier
        };
        dataFilter.push(newArray);
      }
      if (DaysPerArea === 'Dias_por_area') {
        const newArraySpanish: DataFilterDownloadInterfaceSpanish = {
          Orden_id: value.order_id,
          Numero_SO: value.so_number,
          Estado: t(singleState),
          Area: t(value.meta_state),
          Dias_por_area: value.days_meta_state,
          Dias_totales: value.total_time,
          Mercado: value.marketplace,
          Cliente: value.buyer,
          Precio_total: value.total_price,
          Fecha_de_origen: originDate,
          Mensajeria: value.courier
        };
        dataFilter.push(newArraySpanish);
      }
    });
    return dataFilter;
  }, [rows, t]);

  return (
    <Switch>
      <Route exact path={`${path}/orders/detail/:id`}>
        <OrderDetail title={title} />
      </Route>

      <Route exact path={`${path}`}>
        <OrderReportList
          data_area_time={data_area_time as ReportAreaTime[]}
          working_area_time={!!working_area_time}
          title={title}
          rows={rows}
          filter={filter}
          working={!!working}
          searchFilter={searchFilter}
          setFilter={setFilter}
          fetchMore={fetchMore}
          orderBy={orderBy}
          direction={direction}
          handleRequestSort={handleRequestSort}
          pathDetails={pathDetails}
          download
          data={handleDataCSV}
        />
      </Route>
    </Switch>
  );
};

export const OrderReport = DatabaseConnector(OrderReportRaw)('ster_order_report', 'ster_order', 'ster_order_area_time');
