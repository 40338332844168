import React, { useMemo } from 'react';
import { DatabaseConnector } from '@phinxlab/libby-rest-web';
import { useLibbyCall } from '../../../../../hooks';
import { LibbyObject } from '../../../../../types';
import { LoadingTable } from '../../../../../components/LoadingTable';

type SelectImportTypeProps = {
  order_id: string;
  libby: LibbyObject;
};

export const DomicileTableRaw = ({ order_id, libby }: SelectImportTypeProps) => {
  const { data, working = true } = useLibbyCall(libby, {
    daoName: 'ster_order',
    methodName: 'getCityByOrderId',
    params: [order_id]
  });

  const dataBuyer = useMemo(() => data?.[0]?.buyer, [data]);

  return <div>{working || !dataBuyer ? <LoadingTable /> : `${dataBuyer.street} ${dataBuyer.street_number}, ${dataBuyer.city.name}, ${dataBuyer.city.state.name}`}</div>;
};

export const DomicileTable = React.memo(DatabaseConnector(DomicileTableRaw)('ster_order'));
