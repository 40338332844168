import { useTranslation } from 'services/translation';
import { FormConfigTypesProduct } from './types';
import { makeGetErrorAndHelperText } from 'utils';
import { textFieldProps } from 'utils';

export const useFormConfig = ({ props, classes, userPermissions, type }: FormConfigTypesProduct) => {
  const { t } = useTranslation();
  const getErrorAndHelperText = makeGetErrorAndHelperText(t);

  const { name, sku, description, width, height, weight, depth, price, vat } = props;

  const errorName = getErrorAndHelperText(name);
  const errorSKU = getErrorAndHelperText(sku);
  const errorDescription = getErrorAndHelperText(description);
  const errorWidth = getErrorAndHelperText(width);
  const errorHeight = getErrorAndHelperText(height);
  const errorWeight = getErrorAndHelperText(weight);
  const errorDepth = getErrorAndHelperText(depth);
  const errorPrice = getErrorAndHelperText(price);
  const errorVat = getErrorAndHelperText(vat);

  const formFieldsProduct = [
    {
      field: name,
      errorField: errorName,
      class: classes && classes.halfTextFieldLeft,
      multiline: false,
      ...textFieldProps(t('Name')),
      disabled: userPermissions.full_permission ? false : type === 'create' ? !userPermissions.high_permission : !userPermissions.edit_permission
    },
    {
      field: sku,
      errorField: errorSKU,
      class: classes && classes.halfTextField,
      multiline: false,
      ...textFieldProps(t('SKU')),
      disabled: type === 'create' ? (userPermissions.high_permission || userPermissions.full_permission ? false : true) : !userPermissions.full_permission
    },
    {
      field: description,
      errorField: errorDescription,
      class: classes && classes.textField,
      multiline: true,
      ...textFieldProps(t('Description')),
      disabled: userPermissions.full_permission ? false : type === 'create' ? !userPermissions.high_permission : !userPermissions.edit_permission
    },
    {
      field: price,
      errorField: errorPrice,
      class: classes && classes.halfTextFieldLeft,
      multiline: false,
      ...textFieldProps(t('Price')),
      disabled: userPermissions.full_permission ? false : type === 'create' ? !userPermissions.high_permission : !userPermissions.edit_permission
    },
    {
      field: vat,
      errorField: errorVat,
      class: classes && classes.halfTextField,
      multiline: false,
      ...textFieldProps(t('Vat')),
      disabled: userPermissions.full_permission ? false : type === 'create' ? !userPermissions.high_permission : !userPermissions.edit_permission
    }
  ];

  const formFieldsAttributes = [
    {
      field: width,
      errorField: errorWidth,
      class: classes && classes.halfTextFieldLeft,
      multiline: false,
      ...textFieldProps(t('Width')),
      disabled: userPermissions.full_permission ? false : type === 'create' ? !userPermissions.high_permission : !userPermissions.edit_permission
    },
    {
      field: height,
      errorField: errorHeight,
      class: classes && classes.halfTextField,
      multiline: false,
      ...textFieldProps(t('Height')),
      disabled: userPermissions.full_permission ? false : type === 'create' ? !userPermissions.high_permission : !userPermissions.edit_permission
    },
    {
      field: weight,
      errorField: errorWeight,
      class: classes && classes.halfTextFieldLeft,
      multiline: false,
      ...textFieldProps(t('Weight')),
      disabled: userPermissions.full_permission ? false : type === 'create' ? !userPermissions.high_permission : !userPermissions.edit_permission
    },
    {
      field: depth,
      errorField: errorDepth,
      class: classes && classes.halfTextField,
      multiline: false,
      ...textFieldProps(t('Depth')),
      disabled: userPermissions.full_permission ? false : type === 'create' ? !userPermissions.high_permission : !userPermissions.edit_permission
    }
  ];

  const errorForm = Object.values(props).filter((value) => getErrorAndHelperText(value).error);

  return {
    formFieldsProduct,
    formFieldsAttributes,
    errorForm
  };
};
