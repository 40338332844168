import { makeValidator, ValidatorType, ValidatorRule } from '../../../utils';

export const formInitialValues = {
  name: '',
  courier: '',
  courier_service: '',
  observation: '',
  dispatch_zone: '',
  delivery_date: null
};

const validations: ValidatorRule[] = [
  { key: 'name', validators: [ValidatorType.REQUIRED] },
  { key: 'courier', validators: [ValidatorType.REQUIRED] },
  { key: 'courier_service', validators: [ValidatorType.REQUIRED] },
  { key: 'dispatch_zone', validators: [ValidatorType.REQUIRED] },
  { key: 'delivery_date', validators: [ValidatorType.REQUIRED] }
];

export const validate = makeValidator(validations);

export const getValidate = (keys: Array<string>) => makeValidator(keys.length > 0 ? validations.filter((val) => keys.includes(val.key)) : validations);
