import { Courier } from '../interfaces/business';
import { makeBusinessEntity, PartialBusinessEntityOptions } from '../lib/libby/generator/makeBusinessEntity';

import { businessOptions } from './businessOptions';

const DAO_NAME = 'courier_order';

const defaultOptions: PartialBusinessEntityOptions<any> = {
  hoc: {
    withEntityAutocomplete: {
      optionLabelKey: 'name'
    },
    withAccordionSelector: {
      idKey: 'courier_id',
      labelKey: 'name'
    },
    withSelectedChips: {
      idKey: 'courier_id',
      labelKey: 'name'
    }
  }
};

const optionsToUse = !businessOptions.Courier
  ? defaultOptions
  : {
      ...defaultOptions,
      ...businessOptions.Courier,
      hoc: { ...defaultOptions.hoc, ...businessOptions.Courier.hoc }
    };

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch },
  hoc: { entityAutocomplete, renderPropCall, renderPropFetchById, simpleSelect, accordionSelector, selectedChips }
} = makeBusinessEntity<Courier>(DAO_NAME, optionsToUse);

// hooks
export const useCourierFetchById = fetchById;
export const useCourierLibbyCall = libbyCall;
export const useCourierDAO = libbyDAO;
export const useCourierLibbyFetch = libbyFetch;

// components/hoc
export const CourierAutocomplete = entityAutocomplete;
export const CourierCall = renderPropCall;
export const CourierById = renderPropFetchById;
export const CourierSelect = simpleSelect;
export const CourierAccordionSelector = accordionSelector;
export const CourierSelectedChips = selectedChips;
