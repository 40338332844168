import { Moment } from 'moment';
import { Filter, subFilter } from '../../../../../../types';
import { formatDate } from '../../../../../../utils';

type MakeFilterProps = {
  search?: string;
  startDate?: Moment | null;
  endDate?: Moment | null;
  goal_types?: subFilter[];
  goal_types_value?: subFilter[];
};

export const makeFilter = ({ search, startDate, endDate, goal_types, goal_types_value }: MakeFilterProps) => {
  const filter: Filter = {};
  if (search) {
    const searchArray = search.split(' ').reduce((accumulator: Filter, item, index) => {
      accumulator[index] = [
        {
          path: 'name',
          value: search,
          method: 'includes'
        }
      ];

      return accumulator;
    }, {});
    filter.search = [...searchArray[0]];
  }

  if (startDate) {
    filter.startDate = [
      {
        path: 'start_date',
        value: formatDate(startDate.toDate(), true, false, 'YYYY-MM-DD HH:mm:ss')
      }
    ];
  }
  if (endDate) {
    filter.endDate = [
      {
        path: 'end_date',
        value: formatDate(endDate.toDate(), true, false, 'YYYY-MM-DD HH:mm:ss')
      }
    ];
  }

  if (goal_types?.length) {
    filter.goal_types = goal_types?.map((goal_type: subFilter) => ({
      path: 'goal_type.goal_type_id',
      value: goal_type?.value
    }));
  }
  if (goal_types_value?.length) {
    filter.goal_types_value = goal_types_value?.map((goal_type_value: subFilter) => ({
      path: 'goal_type_value.goal_type_value_id',
      value: goal_type_value?.value
    }));
  }
  return filter;
};
