import { AnyObject } from '../../../types/types';

type MakeFilterProps = {
  search?: string;
  sku?: string | null;
  name?: string | null;
  syncStock?: string | null;
  minorStock?: string | null;
  odooStock?: string | null;
  marketplace?: [object] | null;
};

export const makeFilter = ({ search, sku, name, syncStock, minorStock, odooStock, marketplace }: MakeFilterProps) => {
  const filter: AnyObject = {};

  if (search) {
    const valueSearch = search.split(' ').reduce((accumulator: AnyObject, item, index) => {
      accumulator[index] = [
        {
          path: 'name',
          value: search,
          method: 'includes'
        },
        {
          path: 'sku',
          value: search,
          method: 'includes'
        }
      ];
      return accumulator;
    }, {});
    filter.search = [...valueSearch[0]];
  }

  if (name) {
    filter.name = [
      {
        path: 'name',
        value: name,
        method: 'includes'
      }
    ];
  }
  if (sku) {
    filter.sku = [
      {
        path: 'sku',
        value: sku,
        method: 'includes'
      }
    ];
  }
  if (syncStock) {
    filter.syncStock = [
      {
        path: 'syncStock',
        value: syncStock,
        method: 'equals'
      }
    ];
  }
  if (minorStock) {
    filter.minorStock = [
      {
        path: 'minorStock',
        value: minorStock,
        method: 'equals'
      }
    ];
  }
  if (odooStock) {
    filter.odooStock = [
      {
        path: 'odooStock',
        value: odooStock,
        method: 'equals'
      }
    ];
  }

  if (marketplace?.length) {
    filter.marketplace = marketplace?.map((marketplaceValue: any) => ({
      path: 'marketplace',
      value: marketplaceValue?.value
    }));
  }

  return filter;
};
