import React, { useEffect, useMemo } from 'react';
import { GeneralSelect } from '../../components/GeneralSelect';
import { AnyObject } from '../../types/types';
import { Dispatch_zone } from '../../interfaces/business';
import { useDispatchZoneEnabledLibbyCall } from '../../business';
import { DataGenerateSelectType } from '../../utils/generateSelect';

type SelectDispatchZoneProps = {
  inputProps: AnyObject;
  optionAll?: boolean;
  [k: string]: any;
  initName?: string;
  typeCourierSearch?: string;
  initId: string | undefined;
};

export const SelectDispatchZone = ({ typeCourierSearch, inputProps = {}, optionAll = false, initName, initId, ...styles }: SelectDispatchZoneProps) => {
  const {
    data: dataRaw = [],
    working = true,
    recall
  } = useDispatchZoneEnabledLibbyCall({
    methodName: 'getAllDispatchZoneEnableWithCourier',
    noAutoCall: true
  });
  const disabled = useMemo(() => typeof typeCourierSearch !== 'undefined' && (typeCourierSearch === '0' || !typeCourierSearch.length), [typeCourierSearch]);
  useEffect(() => {
    if (!disabled) recall?.({ typeCourierSearch });
  }, [recall, typeCourierSearch, disabled]);

  const dataSelect: DataGenerateSelectType[] = useMemo(() => {
    const allDataRaw = dataRaw as Dispatch_zone[];
    const dataInitSelect: DataGenerateSelectType[] = initId
      ? [
          {
            id: initId,
            value: initName
          }
        ]
      : [];

    return allDataRaw
      .filter(({ dispatch_zone_id }) => dispatch_zone_id !== initId)
      .reduce((allData, { dispatch_zone_id, name }) => {
        const copyAllData = [...allData];
        copyAllData.push({
          id: parseInt(dispatch_zone_id, 10),
          value: name
        });
        return copyAllData;
      }, dataInitSelect);
  }, [dataRaw, initName, initId]);

  return <GeneralSelect working={working} label="Dispatch Zone" placeholder="Assign Dispatch Zone" optionAll={optionAll} data={dataSelect} inputProps={inputProps} {...styles} />;
};
