import { LibbyFetchDAO } from '../LibbyFetchDAO';

export class MessageStateDAO extends LibbyFetchDAO {
  constructor() {
    super('ster_message_state', 'message_state_id');
  }

  getAll() {
    return this.query().limit(0, 10000).run();
  }
}
