import { Box, Theme, Tooltip, Typography, useMediaQuery } from '@material-ui/core';
import React from 'react';
import { format } from '../../../../../util';
import { Column } from '../../../../components/InfoTable';
import { useStyles } from '../../../../ReportingSales/routes/ReportingSalesList/styles/ReportingSalesType';

const emptyName = ['Discounts', 'Shipping cost', 'Sub total', 'Vat'];

const textBold = [...emptyName, 'Total'];

type TypeColumn = {
  name: string;
  total: number;
  count: number;
  id: string;
};

export const ColumnsProductsTaxation = () => {
  const isMd = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));
  const classes = useStyles();
  const columns: Array<Column> = [
    {
      id: 'name',
      label: 'Products',
      style: {
        whiteSpace: 'nowrap'
      },
      render: (value: TypeColumn, item: TypeColumn, tr: (str: string) => string) => (
        <Tooltip title={`${tr(value.name)}`}>
          <Box maxWidth={isMd ? 400 : 100} minWidth={100}>
            <Typography noWrap variant="body1" color="textSecondary" align={textBold.includes(value.name) ? 'right' : 'left'} className={textBold.includes(value.name) ? classes.bold : ''}>
              {tr(value.name)}
            </Typography>
          </Box>
        </Tooltip>
      ),
      noSort: true
    },
    {
      id: 'count',
      label: 'Count',
      render: (value: TypeColumn) => {
        const text = emptyName.includes(value.name) ? '' : format(value.count, 'Integer');
        return textBold.includes(value.name) ? <b>{text}</b> : text;
      },
      align: 'right',
      noSort: true
    },
    {
      id: 'total',
      label: 'Total',
      render: (value: TypeColumn, item: TypeColumn, tr: (str: string) => string) => (textBold.includes(value.name) ? <b>{format(value.total, 'ARS', tr)}</b> : format(value.total, 'ARS', tr)),
      align: 'right'
    }
  ];

  const columnsProducts: Array<Column> = [
    {
      id: 'name',
      label: 'Products',
      style: {
        whiteSpace: 'nowrap'
      },
      render: (value: TypeColumn, item: TypeColumn, tr: (str: string) => string) => (
        <Tooltip title={`${tr(value.name)}`}>
          <Box maxWidth={isMd ? 400 : 100} minWidth={100}>
            <Typography noWrap variant="body1" color="textSecondary" align={textBold.includes(value.name) ? 'right' : 'left'} className={textBold.includes(value.name) ? classes.bold : ''}>
              {tr(value.name)}
            </Typography>
          </Box>
        </Tooltip>
      ),
      noSort: true
    },
    {
      id: 'count',
      label: 'Count',
      render: (value: TypeColumn) => {
        const text = emptyName.includes(value.name) ? '' : format(value.count, 'Integer');
        return textBold.includes(value.name) ? <b>{text}</b> : text;
      },
      align: 'right',
      noSort: true
    },
    {
      id: 'total',
      label: 'Total',
      render: (value: TypeColumn, item: TypeColumn, tr: (str: string) => string) => (textBold.includes(value.name) ? <b>{format(value.total, 'ARS', tr)}</b> : format(value.total, 'ARS', tr)),
      align: 'right'
    }
  ];

  return { columns, columnsProducts };
};
