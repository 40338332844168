import { AnyObject } from '../../../types/types';

type MakeFilterProps = {
  search?: string;
  state?: string | null;
  marketplace?: string | null;
  month?: string | null;
  sku?: string | null;
};
export const makeFilter = ({ search, state, marketplace, month, sku }: MakeFilterProps) => {
  const filter: AnyObject = {};

  if (search) {
    const valueSearch = search.split(' ').reduce((accumulator: AnyObject, item, index) => {
      accumulator[index] = [
        {
          path: 'search',
          value: item,
          method: 'includes'
        }
      ];
      return accumulator;
    }, {});
    filter.search = [...valueSearch[0]];
  }

  if (state && state !== '0') {
    filter.state = [
      {
        path: 'order_state_id',
        value: parseInt(state, 10),
        method: 'includes'
      }
    ];
  }
  if (marketplace) {
    filter.marketplace = [
      {
        path: 'marketplace',
        value: marketplace,
        method: 'includes'
      }
    ];
  }
  if (month) {
    filter.month = [
      {
        path: 'month',
        value: month,
        method: 'includes'
      }
    ];
  }
  if (sku) {
    filter.sku = [
      {
        path: 'sku',
        value: sku,
        method: 'includes'
      }
    ];
  }
  return filter;
};
