import { AnyObject } from '../../../types/types';

export type MakeFilterProps = {
  search?: string;
  marketplaceId?: string | number;
};

export const makeFilter = ({ search, marketplaceId }: MakeFilterProps) => {
  let filter: AnyObject = {};

  if (search) {
    filter = search.split(' ').reduce((accumulator: AnyObject, item, index) => {
      accumulator[index] = [
        {
          path: 'sku',
          value: search,
          method: 'includes'
        },
        {
          path: 'name',
          value: item,
          method: 'includes'
        }
      ];

      return accumulator;
    }, {});
  }

  if (marketplaceId) {
    if (filter[0])
      filter[0].push({
        path: 'marketplaceId',
        value: marketplaceId,
        method: 'includes'
      });
    else
      filter[0] = [
        {
          path: 'marketplaceId',
          value: marketplaceId,
          method: 'includes'
        }
      ];
  }

  return filter;
};
