import React from 'react';
import { DatabaseConnector } from '@phinxlab/libby-rest-web';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
// import { LibbyObject } from '../../types';
import { ReportingDashboardList } from './routes';

// interface ReportingTransportTypes {
//   libby: LibbyObject;
// }

const ReportingDashboardRaw = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${path}`}>
        <ReportingDashboardList title="Reporting dashboard" />
      </Route>
    </Switch>
  );
};

export const ReportingDashboard = DatabaseConnector(ReportingDashboardRaw)('ster_reporting_dashboard');
