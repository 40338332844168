import React from 'react';
import Grid from '@material-ui/core/Grid';
import { IconButton, Tooltip } from '@material-ui/core';
import Zoom from '@material-ui/core/Zoom';
import { Create } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';
import { Dispatch } from '../../../types/Dispatch';
import { InformationSection } from '../../../../../components/InformationSection';
import { makeMapLabelValues } from '../../../../utils/mappers';
import { useTranslation } from '../../../../../services/translation';
import { formatDate, translate, formatMoney } from '../../../../../utils';
import LoadingData from '../../../../components/LoadingData';
import { greyText } from '../../../../../theme/colors';
import { Dispatch_state } from '../../../../../interfaces/business';

const useStyles = makeStyles({
  gridItem: {
    '& h5': {
      color: greyText,
      fontSize: 12
    },
    '& p': {
      fontSize: 16
    }
  },
  editButton: {
    position: 'relative',
    top: '-5px'
  }
});

export interface DispatchDetailDataType {
  onEditDispatch?: Function;
  working?: boolean;
  data: Dispatch;
  readOnlyData: boolean;
  updatedDataDetail?: (dispatch: Dispatch) => void;
}

const basicInformationMap = [
  {
    key: 'courier',
    label: 'Courier',
    formatter: (value: any, data: any) => data?.courier_service?.courier?.name || '-'
  },
  {
    key: 'courier_service',
    label: 'Service',
    formatter: (value: any) => value?.name || '-'
  },
  {
    key: 'delivery_date',
    label: 'Delivery date',
    formatter: (value: any) => formatDate(value, false) || '-'
  },
  {
    key: 'dispatch_zone',
    label: 'Dispatch Zone',
    formatter: (value: any) => value?.name || '-'
  },
  {
    key: 'number_items',
    label: 'Assigned Orders',
    formatter: (value: any) => value || '-'
  },
  {
    key: 'observation',
    label: 'Observations',
    formatter: (value: any) => value || '-'
  },
  {
    key: 'total_amount_dispatch',
    label: 'Total amount of dispatch',
    formatter: (value: any) => formatMoney(value) || '-'
  },
  {
    key: 'state_turn',
    label: 'Turn',
    formatter: (value: any, data: any) => translate(!!data?.state_turn ? 'Yes' : 'No')
  },
  {
    key: 'cot',
    label: 'COT',
    formatter: (value: any, data: any) => translate(!!data?.cot ? 'Yes' : 'No')
  }
];

const basicInformationMapInquiry = [
  ...basicInformationMap,
  {
    key: 'dispatch_state',
    label: 'State',
    formatter: (value: Dispatch_state) => translate(value.name) || '-'
  }
];

export const DispatchDetailData = ({ data, working, onEditDispatch, updatedDataDetail, readOnlyData }: DispatchDetailDataType) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const mapperLabelValues = makeMapLabelValues(t, 'row', false, {
    lg: 2,
    md: 2,
    sm: 6,
    style: { padding: '10px' }
  });
  const basicMapper = mapperLabelValues(onEditDispatch ? basicInformationMap : basicInformationMapInquiry);

  return (
    <InformationSection title="" whiteBackground minimumPadding detailHeader>
      <Grid container justify="space-between" alignItems="flex-start" wrap="nowrap">
        <Grid className={classes.gridItem} container justifyContent="flex-start" spacing={1}>
          {working ? <LoadingData working={working} /> : <>{Object.entries(data).length > 0 && basicMapper(data)}</>}
        </Grid>

        {onEditDispatch && !readOnlyData && (
          <Tooltip title={t('Edit')} TransitionComponent={Zoom} placement="top" arrow>
            <IconButton className={classes.editButton} onClick={() => onEditDispatch({ ...data }, updatedDataDetail)}>
              <Create color="action" />
            </IconButton>
          </Tooltip>
        )}
      </Grid>
    </InformationSection>
  );
};
