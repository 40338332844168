import React, { FC, useMemo } from 'react';
import { FieldRenderProps } from 'react-final-form-hooks';
import Box from '@material-ui/core/Box';
import { SelectYear } from '../../../../../components/SelectYear';
import { SelectMLPeriod } from '../../../../../components/SelectMLPeriod';
import { gridBreakpointsFields } from '../../../../../const';

interface MLPeriodsProps {
  yearInput: FieldRenderProps<any, string>;
  mlPeriodInput: FieldRenderProps<any, string>;
  marketplaceId: string;
}

export const MLPeriods: FC<MLPeriodsProps> = ({ yearInput, mlPeriodInput, marketplaceId }) => {
  const mlPeriodsFilter = useMemo<{ marketplace: string; year: string }>(
    () => ({ marketplace: marketplaceId?.toString()?.includes('mercadopago') ? marketplaceId?.toString()?.split('-')[1] : marketplaceId.toString(), year: yearInput.input.value }),
    [yearInput.input.value, marketplaceId]
  );

  return (
    <>
      <Box {...gridBreakpointsFields} style={{ width: '45%' }}>
        <SelectYear inputProps={yearInput.input} />
      </Box>
      <Box {...gridBreakpointsFields} style={{ width: '45%' }}>
        <SelectMLPeriod inputProps={mlPeriodInput.input} filter={mlPeriodsFilter} />
      </Box>
    </>
  );
};
