export type NumberFormat = 'Decimal' | 'ARS' | 'USD' | 'Integer';

export interface NumberFormatConfig {
  style?: string;
  currency?: string;
  currencyDisplay?: string;
  maximumFractionDigits: number;
  minimumFractionDigits: number;
  parser: (value: string, radix?: number) => number;
}

const NUMBER_FORMAT_VALUES: Record<NumberFormat, NumberFormatConfig> = {
  ARS: {
    style: 'currency',
    currency: 'ARS',
    currencyDisplay: 'symbol',
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
    parser: parseFloat
  },
  USD: {
    style: 'currency',
    currency: 'USD',
    currencyDisplay: 'symbol',
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
    parser: parseFloat
  },
  Decimal: {
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
    parser: parseFloat
  },
  Integer: {
    maximumFractionDigits: 0,
    minimumFractionDigits: 0,
    parser: parseInt
  }
};

export const format = (value: any, numberFormat: NumberFormat, traduction?: (str: string) => string) => {
  const { parser, ...rest } = NUMBER_FORMAT_VALUES[numberFormat];
  return numberFormat === 'USD'
    ? parser(value)
        .toLocaleString(traduction ? traduction('en-US') : 'en-US', rest)
        .replace('$', 'U$D ')
    : parser(value).toLocaleString(traduction ? traduction('en-US') : 'en-US', rest);
};
