import { useEffect, useState, useCallback } from 'react';
import { subFilter, Filter } from '../../../../../types';

interface UseInitFiler {
  initFetch: subFilter[];
  onFilterChange: (makeFilter: object) => void;
  id: string;
  makeFilter: any;
}

export const useInitFilterArray = ({ initFetch, onFilterChange, id, makeFilter }: UseInitFiler) => {
  const [filter, setFilter] = useState<subFilter[]>([]);

  useEffect(() => {
    const initCompare = initFetch || [];
    if (JSON.stringify(filter) !== JSON.stringify(initCompare)) {
      setFilter(initFetch);
    }
  }, [filter, initFetch]);

  const handleChangeFilter = useCallback(
    (value: any) => {
      const valueFilter = makeFilter({ [id]: value });
      onFilterChange((prev: Filter) => {
        const copy = { ...prev };
        delete copy[id];
        return { ...copy, ...valueFilter };
      });
    },
    [onFilterChange, id, makeFilter]
  );

  return { search: filter, handleChangeFilter };
};
