import React, { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { useField, useForm, FormConfig } from 'react-final-form-hooks';
import { ValidationErrors } from 'final-form';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Typography } from '@material-ui/core';
import moment, { Moment } from 'moment';
import { SnackbarProvider } from 'notistack';
import { ThemeProvider, makeStyles } from '@material-ui/styles';
import { makeGetErrorAndHelperText } from '../../../../../utils/materialHelpers';
import { gridBreakpointsFields, MARKETPLACE_BILLING } from '../../../../../const';
import { useTranslation } from '../../../../../services/translation';
import { SelectMpMl } from '../../../../../components/SelectMlMp';
import { DateClose } from '../../../../../components/DateClose';
import { FileInput } from './FileInput';
import { LibbyObject } from '../../../../../types';
import { textFieldProps } from '../../../../../utils';
import { MLPeriods } from './MLPeriods';
import { isMLMarketplace } from '../../../utils';
import { LoadingScreen } from 'components/LoadingScreen';
import { useBillings } from '../../../hook/useBillings';
import { FinalLiquidationDialog } from './FinalLiquidationDialog';
import customModal from '../../../../../services/customFormDialog';

const useStyles = makeStyles({
  title: {
    fontFamily: 'Oswald'
  },
  error: {
    fontSize: '12px'
  },
  inputsContainer: {
    marginTop: '15px'
  }
});

interface FormInitialValues {
  marketplace: string;
  startDate: Moment | null;
  endDate: Moment | null;
  reference: string;
}

interface NewLiquidationDialogTypes extends FormConfig<any> {
  open: boolean;
  onCancel: any;
  onConfirm: Function;
  title: string;
  content: React.ReactNode;
  labelContent: string;
  cancelText: string;
  confirmText: string;
  customTheme: string | null;
  inputType: string;
  formInitialValues: FormInitialValues;
  formPreviousValues: FormInitialValues;
  validate?: (values: any) => ValidationErrors | Promise<ValidationErrors> | undefined;
  libby: LibbyObject;
}

const importationInitialValue = { importationText: '', delimiter: '' };
const LiquidationFinalDialog = customModal(FinalLiquidationDialog);

export const NewLiquidationDialog = ({
  open = false,
  onCancel = () => {},
  onConfirm = () => {},
  title,
  cancelText,
  confirmText,
  customTheme = null,
  formInitialValues,
  formPreviousValues,
  validate,
  libby
}: NewLiquidationDialogTypes) => {
  const { t } = useTranslation();
  const getErrorAndHelperText = makeGetErrorAndHelperText(t);
  const classes = useStyles();
  const [cancel, setCancel] = useState(false);
  const [importation, setImportation] = useState(importationInitialValue);
  const [errorImportation, setErrorImportation] = useState(false);
  const [mercadopago, setMercadopago] = useState(false);
  const [loading, setLoading] = useState(false);
  const [fetchLoading, setFetchLoading] = useState<number>(0);
  const [loadingPercentage, setLoadingPercentage] = useState<number>(0);
  const { fetchAllBillings } = useBillings(setFetchLoading, setLoadingPercentage);
  /*   const { enqueueSnackbar } = useSnackbar(); */

  const validationimportText = useCallback(
    async (marketplace) => {
      let validation = true;

      if (!mercadopago && MARKETPLACE_BILLING.find((market) => market.marketplace_id === marketplace && !market.noFile) && !importation.importationText) {
        validation = false;
        setErrorImportation(true);
      } else setErrorImportation(false);

      return validation;
    },
    [importation, mercadopago]
  );

  const onSubmit = useCallback(
    async (props) => {
      try {
        const { marketplace, startDate, endDate, reference, mlPeriod, cancellations, penalties, refund } = props;
        setLoading(true);
        if (await validationimportText(marketplace)) {
          const marketplace_type = MARKETPLACE_BILLING.find((m) => m.marketplace_id === marketplace)?.marketplace_type;
          const marketplace_id = marketplace.includes('mercadopago') ? marketplace.split('-')[1] : marketplace;
          const period: {
            expiration_date: string;
            date_from: string;
            date_to: string;
          } = {
            expiration_date: '',
            date_from: '',
            date_to: ''
          };
          if (mlPeriod) {
            const [from, to, expiration_date] = mlPeriod.split(',');
            period.date_from = from;
            period.date_to = to;
            period.expiration_date = expiration_date;
          }

          const billings = await fetchAllBillings(marketplace_id, marketplace_type, period.expiration_date);

          const liquidation = {
            marketplace_id,
            date_start: startDate,
            date_end: endDate,
            importation,
            reference,
            marketplace_type: marketplace_type || '',
            period,
            cancellations: +cancellations || 0,
            penalties: +penalties || 0,
            refund: +refund || 0,
            billings
          };
          const data = await libby.ster_new_order_liquidation.save(liquidation);
          if (data) {
            setLoading(false);
            const finalLiquidationConfirm: any = await LiquidationFinalDialog.show({
              title: t('Verify Liquidation'),
              confirmText: t('Confirm'),
              cancelText: t('Cancel'),
              confirm: { data, liquidation },
              validate,
              libby
            });
            onConfirm(finalLiquidationConfirm);
          }
        }
      } catch (e: any) {
        console.log(e);
        if (!e.movingBack) {
          onCancel();
          setCancel(!cancel);
        }
      } finally {
        setLoading(false);
      }
    },
    [validationimportText, fetchAllBillings, importation, libby, t, validate, onConfirm, onCancel, cancel]
  );

  const { form, handleSubmit } = useForm({
    initialValues: formInitialValues,
    onSubmit,
    validate
  });
  const marketplace = useField('marketplace', form);
  const startDate = useField('startDate', form);
  const endDate = useField('endDate', form);
  const reference = useField('reference', form);
  const year = useField('year', form);
  const mlPeriod = useField('mlPeriod', form);

  const cancellations = useField('cancellations', form);
  const penalties = useField('penalties', form);
  const refund = useField('refund', form);

  const isMLMkt = isMLMarketplace(marketplace.input.value.toString());

  const errors = useMemo(() => {
    return getErrorAndHelperText(marketplace).error || getErrorAndHelperText(startDate).error || getErrorAndHelperText(endDate).error || getErrorAndHelperText(reference).error;
  }, [getErrorAndHelperText, marketplace, startDate, endDate, reference]);

  useEffect(() => {
    if (MARKETPLACE_BILLING.find((market) => market.marketplace_id === marketplace.input.value)) {
      if (importation.importationText) setErrorImportation(false);
    }
  }, [marketplace, importation]);

  const resetForm = useCallback(() => {
    form.reset(formInitialValues);
  }, [form, formInitialValues]);

  useEffect(() => {
    setTimeout(() => {
      if (formPreviousValues === null) form.reset(formPreviousValues);
      else form.reset(formInitialValues);
      setImportation(importationInitialValue);
      setErrorImportation(false);
    }, 0);
  }, [form, formInitialValues, formPreviousValues]);

  useEffect(() => {
    if (mlPeriod.input.value) {
      const [from, to] = mlPeriod.input.value.split(',');
      form.change('startDate', moment(from));
      form.change('endDate', moment(to));
    } else {
      form.change('startDate', null);
      form.change('endDate', null);
      form.change('reference', '');
      form.change('year', null);
    }
  }, [mlPeriod.input.value, marketplace.input.value, form]);

  const dialog = (
    <>
      <Dialog open={open} aria-labelledby="form-dialog-title" fullWidth maxWidth="md">
        <DialogTitle id="form-dialog-title">
          <Typography className={classes.title} variant="h3">
            {title}
          </Typography>
        </DialogTitle>
        <form onSubmit={handleSubmit}>
          <DialogContent>
            <Box item {...gridBreakpointsFields} style={{ marginBottom: '10px' }}>
              <SelectMpMl
                inputProps={{
                  value: marketplace.input.value,
                  onChange: (e: ChangeEvent<HTMLInputElement>) => {
                    setMercadopago(e.target.value.includes('mercadopago'));
                    form.change('startDate', null);
                    form.change('endDate', null);
                    form.change('reference', '');
                    form.change('mlPeriod', null);
                    marketplace.input.onChange(e.target.value);
                  },
                  ...getErrorAndHelperText(marketplace)
                }}
              />
            </Box>
            {isMLMkt && (
              <Box display="flex" justifyContent="space-between" style={{ marginBottom: '10px' }}>
                <MLPeriods yearInput={year} mlPeriodInput={mlPeriod} marketplaceId={marketplace.input.value} />
              </Box>
            )}
            <Box display="flex" justifyContent="space-between">
              <Box {...gridBreakpointsFields} style={{ width: '45%' }}>
                <DateClose name="Start of Period" value={moment.isMoment(startDate.input.value) ? startDate.input.value : null} onChange={startDate.input.onChange} {...getErrorAndHelperText(startDate)} disabled={isMLMkt} />
              </Box>
              <Box {...gridBreakpointsFields} style={{ width: '45%' }}>
                <DateClose
                  name="End of Period"
                  value={moment.isMoment(endDate.input.value) ? endDate.input.value : null}
                  minValue={moment.isMoment(startDate.input.value) ? startDate.input.value : null}
                  onChange={endDate.input.onChange}
                  {...getErrorAndHelperText(endDate)}
                  disabled={isMLMkt}
                />
              </Box>
            </Box>
            <Box {...gridBreakpointsFields} style={{ width: '35%' }}>
              <TextField {...textFieldProps(`${t('Reference')}`)} value={reference.input.value} onChange={reference.input.onChange} type="string" variant="standard" {...getErrorAndHelperText(reference)} />
            </Box>
            {!mercadopago && MARKETPLACE_BILLING.find((market) => market.marketplace_id === marketplace.input.value && !market.noFile) && (
              <FileInput marketplace={marketplace.input.value} onUpdateFiles={setImportation} error={errorImportation} />
            )}

            <Box display="flex" justifyContent="space-between">
              <Box {...gridBreakpointsFields} style={{ width: '30%' }}>
                <TextField {...textFieldProps(`${t('Cancellations')}`)} value={cancellations.input.value} onChange={cancellations.input.onChange} type="number" variant="standard" {...getErrorAndHelperText(cancellations)} />
              </Box>
              <Box {...gridBreakpointsFields} style={{ width: '30%' }}>
                <TextField {...textFieldProps(`${t('Penalties')}`)} value={penalties.input.value} onChange={penalties.input.onChange} type="number" variant="standard" {...getErrorAndHelperText(penalties)} />
              </Box>
              <Box {...gridBreakpointsFields} style={{ width: '30%' }}>
                <TextField {...textFieldProps(`${t('Refunds')}`)} value={refund.input.value} onChange={refund.input.onChange} type="number" variant="standard" {...getErrorAndHelperText(refund)} />
              </Box>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                onCancel();
                setCancel(!cancel);
                resetForm();
              }}
            >
              {cancelText}
            </Button>
            {errors || errorImportation ? (
              <Button disabled>{confirmText}</Button>
            ) : (
              <Button color="primary" type="submit" autoFocus disabled={loading}>
                {!loading ? confirmText : `${t('Loading')}...`}
              </Button>
            )}
          </DialogActions>
        </form>
      </Dialog>
      {loading && <LoadingScreen total_loaded={fetchLoading} percentage={loadingPercentage} />}
    </>
  );

  return !customTheme ? (
    dialog
  ) : (
    <ThemeProvider theme={customTheme}>
      <SnackbarProvider maxSnack={3}>{dialog}</SnackbarProvider>
    </ThemeProvider>
  );
};
