import React, { useContext } from 'react';
import { CircularProgress, Grid, Paper, TextField, Typography } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import SearchIcon from '@material-ui/icons/Search';
import { AnyObject } from '../../../../../types';
import { makeGetErrorAndHelperText, textFieldProps } from '../../../../../utils/materialHelpers';
import { GeneralSelect } from '../../../../../components/GeneralSelect';
import { documentTypeOptions, useStyles } from '../const';
import { useTranslation } from '../../../../../services/translation';
import { BudgetFormContext } from '../hook/BudgetFormContext';

export const ClientInformation = () => {
  const { t } = useTranslation();
  const { budgetEditProps, loadingSearch, setClientSearch, handleClient, clientSearch, budgetUsers, first_name, last_name, email, phone_number, document_type, document, client } = useContext(BudgetFormContext);
  const classes = useStyles();

  const getErrorAndHelperText = makeGetErrorAndHelperText(t);

  return (
    <Grid item xs={12}>
      <Paper className={classes.paper}>
        <Grid item container>
          <Grid xs={12}>
            <Typography variant="h1" className={classes.title}>{`${t('Client')}`}</Typography>
          </Grid>
          <Grid item xs={6}>
            <Autocomplete
              disabled={!!budgetEditProps}
              onChange={(event, value) => value && handleClient(value)}
              onInputChange={(event, value) => {
                setClientSearch(value);
              }}
              options={loadingSearch ? [{ ...budgetUsers[0], last_name: clientSearch }] : budgetUsers}
              getOptionLabel={(item: AnyObject) => `${item.first_name} ${item.last_name} ${item.document}`}
              renderOption={(item) =>
                loadingSearch ? (
                  <div className={classes.loadingAutocomplete}>
                    <CircularProgress size={24} />
                  </div>
                ) : (
                  <div>
                    <div>{`${item.first_name} ${item.last_name}`}</div>
                    <span>DNI: {item.document}</span>
                  </div>
                )
              }
              popupIcon={<SearchIcon />}
              style={{ width: 450, margin: '8px' }}
              renderInput={(params) =>
                loadingSearch ? (
                  <TextField {...params} label="Buscar" variant="standard" disabled>
                    <CircularProgress size={24} />
                  </TextField>
                ) : (
                  <TextField {...params} label="Buscar" variant="standard" />
                )
              }
            />
          </Grid>
          <Grid item xs={12} className={classes.containerInputs}>
            <TextField
              {...textFieldProps(`${t('First Name')}`)}
              {...getErrorAndHelperText(first_name)}
              value={first_name.input.value}
              required
              variant="standard"
              onChange={first_name.input.onChange}
              InputProps={{ readOnly: !!client?.first_name?.trim() }}
              InputLabelProps={{ shrink: true }}
              className={classes.inputs}
            />
            <TextField
              {...textFieldProps(`${t('Last Name')}`)}
              variant="standard"
              value={last_name.input.value}
              onChange={last_name.input.onChange}
              InputProps={{ readOnly: !!client?.last_name?.trim() }}
              InputLabelProps={{ shrink: true }}
              className={classes.inputs}
            />
            <Grid item style={{ margin: '8px', width: '30ch' }}>
              <GeneralSelect
                label={`${t('Document Type')}`}
                optionAll={false}
                data={documentTypeOptions}
                working={false}
                inputProps={{
                  onChange: document_type.input.onChange,
                  value: document_type.input.value || '',
                  ...getErrorAndHelperText(document_type),
                  style: { textAlign: 'left' }
                }}
                inputPropsTextFiel={{ readOnly: !!client?.document_type?.name?.trim() }}
              />
            </Grid>
            <TextField
              {...textFieldProps(`${t('Document')}`)}
              {...getErrorAndHelperText(document)}
              required
              variant="standard"
              value={document.input.value}
              onChange={document.input.onChange}
              InputProps={{ readOnly: !!client?.document?.trim() }}
              InputLabelProps={{ shrink: true }}
              className={classes.inputs}
            />
            <TextField
              {...textFieldProps(`${t('Phone Number')}`)}
              {...getErrorAndHelperText(phone_number)}
              required
              variant="standard"
              value={phone_number.input.value}
              onChange={phone_number.input.onChange}
              InputProps={{ readOnly: !!client?.phone_number?.trim() }}
              InputLabelProps={{ shrink: true }}
              className={classes.inputs}
            />
            <TextField
              {...textFieldProps(`${t('Email')}`)}
              {...getErrorAndHelperText(email)}
              required
              variant="standard"
              value={email.input.value}
              onChange={email.input.onChange}
              InputProps={{ readOnly: !!client?.email?.trim() }}
              InputLabelProps={{ shrink: true }}
              className={classes.inputs}
            />
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
};
