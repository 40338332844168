import React, { useMemo } from 'react';
import { DatabaseConnector } from '@phinxlab/libby-rest-web';
import { GeneralSelect } from '../../../components/GeneralSelect';
import { useLibbyCall } from '../../../hooks';
import { Message_state } from '../../../interfaces/business/message';
import { SelectTypeProps } from '../../../interfaces/select';

export const SelectMessageStateRaw = ({ libby, inputProps, ...styles }: SelectTypeProps) => {
  const { data: dataRaw, working = true } = useLibbyCall(libby, {
    daoName: 'ster_message_state',
    methodName: 'getAll'
  });

  const data = useMemo(
    () =>
      dataRaw.map(({ message_state_id, name }: Message_state) => ({
        id: message_state_id,
        value: name
      })),
    [dataRaw]
  );

  return <GeneralSelect working={working} label="State" optionAll data={data} inputProps={inputProps} {...styles} />;
};

export const SelectMessageState = React.memo(DatabaseConnector(SelectMessageStateRaw)('ster_message_state'));
