import React, { ReactNode, useCallback } from 'react';
import SearchIcon from '@material-ui/icons/Search';
import 'moment/locale/es';
import moment from 'moment';
import { Grid, TextField } from '@material-ui/core';

import { FilterBar } from '../../../../../components/FilterBar';
import { gridBreakpoints } from '../../../../../const/gridBreakpoints';
import { useTranslation } from '../../../../../services/translation';
import { makeFilter } from '../utils/makeFilter';
import { SelectOrderState } from '../../../../components/SelectOrderState';
import { SelectCreationAreaStatus } from './SelectCreationAreaStatus';
import { SelectArea } from './SelectArea';
import { Filter } from '../../../../../types';
import { replaceNonNumericCharactersComma } from '../../../../../functions';
import { DateClose } from '../../../../../components/DateClose';
import { useInitFilter, useInitFilterArray, useInitFilterDebounced } from '../hook';
import { SelectOrderCourier } from '../../../../components/SelectOrderCourier';
import { SelectAllMarketPlace } from '../../../../components/AllMarketplace';

type SearchFiltersProps = {
  onFilterChange: (makeFilter: object) => void;
  initFetch: Filter;
};

type SearchFilterItemProps = {
  children: ReactNode;
};

const SearchFilterItem = ({ children = null, ...props }: SearchFilterItemProps) => (
  <Grid item {...gridBreakpoints} {...props}>
    {children}
  </Grid>
);

const SearchFiltersRaw = ({ onFilterChange, initFetch }: SearchFiltersProps) => {
  const { t } = useTranslation();

  const { search, handleChange } = useInitFilterDebounced({
    initFetch: initFetch?.search,
    init: '',
    onFilterChange,
    id: 'search',
    makeFilter
  });

  const { search: customer, handleChange: handleChangeCustomer } = useInitFilterDebounced({
    initFetch: initFetch?.customer,
    init: '',
    onFilterChange,
    id: 'customer',
    makeFilter
  });

  const { search: orderId, handleChange: handleChangeOrderId } = useInitFilterDebounced({
    initFetch: initFetch.orderId,
    init: '',
    onFilterChange,
    id: 'orderId',
    makeFilter,
    join: true
  });

  const handleChangeOrderIdValidate = useCallback(
    (event) => {
      event.target.value = replaceNonNumericCharactersComma(event.target.value);
      handleChangeOrderId(event);
    },
    [handleChangeOrderId]
  );

  const { search: orderStatus, handleChangeFilter: handleChangeOrderStatus } = useInitFilter({
    initFetch: initFetch.orderStatus,
    init: '0',
    onFilterChange,
    id: 'orderStatus',
    makeFilter
  });

  const { search: area, handleChangeFilter: handleChangeArea } = useInitFilter({
    initFetch: initFetch.area,
    init: '0',
    onFilterChange,
    id: 'area',
    makeFilter
  });

  const { search: creationStatus, handleChangeFilter: handleChangeCreationStatus } = useInitFilter({
    initFetch: initFetch.creationStatus,
    init: '0',
    onFilterChange,
    id: 'creationStatus',
    makeFilter
  });

  const { search: areaStatus, handleChangeFilter: handleChangeAreaStatus } = useInitFilter({
    initFetch: initFetch.areaStatus,
    init: '0',
    onFilterChange,
    id: 'areaStatus',
    makeFilter
  });

  const { search: courier, handleChangeFilter: handleChangeCourier } = useInitFilter({
    initFetch: initFetch.courier,
    init: '0',
    onFilterChange,
    id: 'courier',
    makeFilter
  });

  const { search: startDate, handleChangeFilterString: setStartDate } = useInitFilter({
    initFetch: initFetch.startDate,
    onFilterChange,
    init: null,
    id: 'startDate',
    date: true,
    makeFilter
  });

  const { search: endDate, handleChangeFilterString: setEndDate } = useInitFilter({
    initFetch: initFetch.endDate,
    onFilterChange,
    id: 'endDate',
    date: true,
    init: null,
    makeFilter
  });

  const { search: marketplace, handleChangeFilter: setMarketplace } = useInitFilterArray({
    initFetch: initFetch.marketplace,
    onFilterChange,
    id: 'marketplace',
    makeFilter
  });
  const handleChangeMarketPlace = useCallback(
    (value: any) => {
      setMarketplace(value);
    },
    [setMarketplace]
  );

  const handleReset = useCallback(() => {
    onFilterChange(makeFilter({}));
  }, [onFilterChange]);

  return (
    <FilterBar
      initFetch={initFetch}
      defaultFilter={
        <SearchFilterItem>
          <TextField
            id="standard-search"
            placeholder={t('Search')}
            type="search"
            value={search}
            onChange={handleChange}
            fullWidth
            InputLabelProps={{
              shrink: true
            }}
            InputProps={{
              endAdornment: <SearchIcon />
            }}
          />
        </SearchFilterItem>
      }
      handleReset={handleReset}
    >
      <SearchFilterItem>
        <SelectArea inputProps={{ value: area, onChange: handleChangeArea }} optionAll sort="asc" />
      </SearchFilterItem>
      <SearchFilterItem>
        <SelectOrderState title="State" inputProps={{ value: orderStatus, onChange: handleChangeOrderStatus }} optionAll sort="asc" />
      </SearchFilterItem>

      <SearchFilterItem>
        <SelectCreationAreaStatus
          title="Status since creation"
          inputProps={{
            value: creationStatus,
            onChange: handleChangeCreationStatus
          }}
          optionAll
          sort="asc"
        />
      </SearchFilterItem>

      <SearchFilterItem>
        <SelectOrderCourier inputProps={{ value: courier, onChange: handleChangeCourier }} optionAll sort="asc" />
      </SearchFilterItem>

      <SearchFilterItem>
        <SelectCreationAreaStatus title="Status by area" inputProps={{ value: areaStatus, onChange: handleChangeAreaStatus }} optionAll sort="asc" />
      </SearchFilterItem>

      <SearchFilterItem>
        <TextField
          id="customer-search"
          label={t('Customer')}
          value={customer}
          type="text"
          onChange={handleChangeCustomer}
          fullWidth
          InputLabelProps={{
            shrink: true
          }}
        />
      </SearchFilterItem>

      <SearchFilterItem>
        <DateClose name="Start Date" value={moment.isMoment(startDate) ? startDate : null} onChange={setStartDate} />
      </SearchFilterItem>
      <SearchFilterItem>
        <DateClose name="End Date" value={moment.isMoment(endDate) ? endDate : null} minValue={moment.isMoment(startDate) ? startDate : null} onChange={setEndDate} />
      </SearchFilterItem>

      <SearchFilterItem>
        <TextField
          id="generic-search"
          label={t('ID Order')}
          value={orderId}
          type="text"
          onChange={handleChangeOrderIdValidate}
          fullWidth
          InputLabelProps={{
            shrink: true
          }}
        />
      </SearchFilterItem>

      <Grid item xs={12} sm={6}>
        <SelectAllMarketPlace onChange={handleChangeMarketPlace} allMarketplace={marketplace} />
      </Grid>
    </FilterBar>
  );
};

export const SearchFilters = React.memo(SearchFiltersRaw);
