import { Brand } from '../../interfaces/business';
import { makeBusinessEntity, PartialBusinessEntityOptions } from '../../lib/libby/generator/makeBusinessEntity';

import { businessOptions } from '../businessOptions';

const DAO_NAME = 'ster_brand';

const defaultOptions: PartialBusinessEntityOptions<any> = {
  hoc: {
    withEntityAutocomplete: {
      optionLabelKey: 'name'
    },
    withAccordionSelector: {
      idKey: 'brand_id',
      labelKey: 'name'
    },
    withSelectedChips: {
      idKey: 'brand_id',
      labelKey: 'name'
    }
  }
};

const optionsToUse = !businessOptions.Brand
  ? defaultOptions
  : {
      ...defaultOptions,
      ...businessOptions.Brand,
      hoc: { ...defaultOptions.hoc, ...businessOptions.Brand.hoc }
    };

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch },
  hoc: { entityAutocomplete, renderPropCall, renderPropFetchById, simpleSelect, accordionSelector, selectedChips }
} = makeBusinessEntity<Brand>(DAO_NAME, optionsToUse);

// hooks
export const useBrandFetchById = fetchById;
export const useBrandLibbyCall = libbyCall;
export const useBrandDAO = libbyDAO;
export const useBrandLibbyFetch = libbyFetch;

// components/hoc
export const BrandAutocomplete = entityAutocomplete;
export const BrandCall = renderPropCall;
export const BrandById = renderPropFetchById;
export const BrandSelect = simpleSelect;
export const BrandAccordionSelector = accordionSelector;
export const BrandSelectedChips = selectedChips;
