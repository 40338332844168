import { makeValidator, ValidatorType, ValidatorRule } from '../../../utils';

export const formInitialValues = {
  name: '',
  street: '',
  street_number: '',
  phone_number: ''
};

const validations: ValidatorRule[] = [
  { key: 'name', validators: [ValidatorType.REQUIRED] },
  { key: 'street', validators: [ValidatorType.REQUIRED] },
  { key: 'street_number', validators: [ValidatorType.REQUIRED] },
  { key: 'phone_number', validators: [ValidatorType.REQUIRED] }
];

export const validate = makeValidator(validations);

export const getValidate = (keys: Array<string>) => makeValidator(keys.length > 0 ? validations.filter((val) => keys.includes(val.key)) : validations);
