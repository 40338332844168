import { makeValidator, ValidatorType, ValidatorRule } from '../../../../../utils';

export const featuresInitialValuesForm = {
  feature_group_id: '',
  feature_name: '',
  value: '',
  order: '',
  searchable: false
};

const validations: ValidatorRule[] = [
  { key: 'feature_name', validators: [ValidatorType.REQUIRED] },
  { key: 'value', validators: [ValidatorType.REQUIRED] },
  { key: 'order', validators: [ValidatorType.REQUIRED, ValidatorType.NUMBER] }
];

export const validate = makeValidator(validations);

export const getValidate = (keys: Array<string>) => makeValidator(keys.length > 0 ? validations.filter((val) => keys.includes(val.key)) : validations);
