import React from 'react';
import { Rectangle, TooltipPayload } from 'recharts';
import { useStyles } from '../../theme/styles/useStyleChart';

interface CustomizedCursorProps {
  x: number;
  y: number;
  width: number;
  height: number;
  payload: TooltipPayload[];
  onChange?: (payload: any) => void;
}

export const CustomizedCursor = (props: any) => {
  const { x, y, width, height, payload, onChange }: CustomizedCursorProps = props;
  const className = useStyles();
  return (
    <Rectangle
      fill="#cecece"
      className={className.cursor}
      onClick={() => {
        if (onChange) {
          onChange(payload[0].payload);
        }
      }}
      stroke="#cecece"
      x={x}
      y={y}
      width={width}
      height={height}
    />
  );
};
