import { makeBusinessEntity } from '../../../lib/libby/generator/makeBusinessEntity';

interface VerifyOrder {
  owner_id: string;
}

const DAO_NAME = 'ster_verify_order';

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch }
} = makeBusinessEntity<VerifyOrder>(DAO_NAME);

// hooks
export const useVerifyOrdersFetchById = fetchById;
export const useVerifyOrdersLibbyCall = libbyCall;
export const useVerifyOrdersDAO = libbyDAO;
export const useVerifyOrdersLibbyFetch = libbyFetch;
