import React from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { ScreenAligned } from '../../../../components/ScreenAligned/ScreenAligned';
import { useTranslation } from '../../../../services/translation';
import { BudgetCommentTable } from './components';
import { useBreadcrumbs } from '../../../../hooks';
import { TitleBreadcrumbs } from '../../../../interfaces';
import { useOrderB2BCommentLibbyCall } from '../../../../business';
import { columnsComments } from './utils';
import { useOrderCommentLogic } from './hook/useOrderCommentLogic';
import { LibbyObject } from '../../../../types';
import { RowsType } from '../../types';

interface BudgetDetailsProps {
  libby: LibbyObject;
  title: TitleBreadcrumbs[];
}

const useStyles = makeStyles(() => ({
  parent: {
    overflowX: 'hidden'
  },
  container: {
    position: 'relative',
    marginRight: '118px',
    left: '50%',
    top: '50%',
    textAlign: 'center'
  },
  btn: {
    border: 'none',
    margin: 20,
    width: 120,
    height: 35,
    borderRadius: 6,
    textTransform: 'uppercase',
    boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
    cursor: 'pointer',
    color: '#fff',
    backgroundSize: '200%',
    transition: '0.4s',
    '&:hover': {
      backgroundPosition: 'right'
    }
  }
}));

export const BudgetCommentList = ({ libby, title }: BudgetDetailsProps) => {
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();
  const classes = useStyles();

  const { data, working, recall } = useOrderB2BCommentLibbyCall({ methodName: 'getAllByOrderId', params: [id] });

  const { onCreateComment } = useOrderCommentLogic({ libby, id, recall });

  const { state } = useLocation<{ goBack: TitleBreadcrumbs[] }>();
  const titleDetail = useBreadcrumbs('Order', [...title, { name: 'Order', additionalName: `#${id}`, href: `/budget/detail/${id}` }]);
  const titleShow = useBreadcrumbs('Comments', state && state?.goBack?.length ? state.goBack : titleDetail);

  return (
    <div className={classes.parent}>
      <ScreenAligned
        title={titleShow}
        additionalTitle={
          <Button color="primary" type="submit" variant="contained" onClick={onCreateComment}>
            {t('Add Comment')}
          </Button>
        }
      >
        <BudgetCommentTable columns={columnsComments} rows={data as RowsType[]} working={working} />
      </ScreenAligned>
    </div>
  );
};
