export const columns = [
  {
    id: 'options',
    label: '',
    width: '40px',
    style: {
      minWidth: '30px'
    },
    noSort: true
  },
  {
    id: 'order_id',
    label: 'Order id',
    orderById: 'order_id'
  },
  {
    id: 'owner_id',
    label: 'Owner id',
    style: {
      whiteSpace: 'nowrap'
    },
    orderById: 'owner_id'
  },
  {
    id: 'so',
    label: 'Number so',
    orderById: 'so',
    style: {
      minWidth: '200px',
      whiteSpace: 'nowrap'
    }
  },
  {
    id: 'courier',
    label: 'Courier',
    style: {
      minWidth: '150px'
    },
    orderById: 'courier'
  },
  {
    id: 'courier_state',
    label: 'Courier state',
    style: {
      minWidth: '150px'
    },
    orderById: 'courier_state'
  },
  {
    id: 'dispatch_date',
    label: 'Dispatch date',
    style: {
      whiteSpace: 'nowrap'
    },
    orderById: 'dispatch_date'
  },
  {
    id: 'detail',
    label: 'Detail',
    noSort: true
  }
];
