import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { FormConfig } from 'react-final-form-hooks';
import { ThemeProvider } from '@material-ui/styles';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Box, Dialog, DialogContent, TextField, DialogTitle, Button, Typography } from '@material-ui/core';
import { useTranslation } from 'services/translation';
import { textFieldProps } from 'utils/materialHelpers';
import { Order_item } from 'interfaces/business';
import { AnyObject, LibbyObject } from 'types';
import { SnackbarProvider } from 'notistack';
import { PRODUCT_TYPE } from 'const';
import { CircularProgress } from '@material-ui/core';

const dummyFunc = () => {};
const useStyles = makeStyles((theme: Theme) => ({
  root: {
    '& .MuiDialogContent-root': {
      overflowY: 'unset !important',
      minHeight: '350px'
    },
    '& .MuiTextField-root': {
      width: '100%'
    }
  },
  mainBox: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column' as 'column'
  },
  packageQuantityBox: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row' as 'row',
    alignItems: 'center',
    justifyContent: 'flex-start'
  },
  packageQuantityButton: {
    height: 'auto'
  },
  textFieldBox: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    gap: theme.spacing(2),
    marginTop: '20px'
  },
  buttonContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    gap: theme.spacing(2)
  }
}));

type AttributesValues = { value: string; error: string };

type AttributesType = {
  width: AttributesValues;
  height: AttributesValues;
  depth: AttributesValues;
  weight: AttributesValues;
};
interface FillProductsAttributesModalProps extends FormConfig<any> {
  open: boolean;
  onCancel: () => void;
  onConfirm: (data: any) => void;
  label: string;
  title: string;
  customTheme: string | null;
  renderItem: React.ElementType;
  data: AnyObject;
  properties: Array<string>;
  disabledLabel?: boolean;
  rowData: Order_item;
  updateDataWithProducts: (row: any, rowlist: any) => void;
  redirect: () => void;
  items: any[];
  setSuccess: React.Dispatch<any>;
  libby: LibbyObject;
  enqueueSnackbar: any;
}

type packageQuantityType = {
  error: string;
  validQuantity: number;
};

const packageQuantityInitialValue = { error: '', validQuantity: 1 };

export const FillProductAttributesModal = ({
  open = false,
  onCancel = dummyFunc,
  onConfirm = dummyFunc,
  title = 'Confirmation Dialog',
  customTheme = null,
  items,
  redirect,
  updateDataWithProducts,
  setSuccess,
  libby,
  rowData,
  enqueueSnackbar
}: FillProductsAttributesModalProps) => {
  const [packageQuantityInput, setPackageQuantityInput] = useState<string>('1');
  const [packageQuantity, setPackageQuantity] = useState<packageQuantityType>(packageQuantityInitialValue);
  const [inputs, setInputs] = useState<AttributesType[]>([]);
  const classes = useStyles();
  const { t } = useTranslation();
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    const { validQuantity } = packageQuantity;

    if (validQuantity > inputs.length) {
      setInputs((prevInputs) => [
        ...prevInputs,
        ...Array(validQuantity - prevInputs.length).fill({
          width: { value: '', error: '' },
          depth: { value: '', error: '' },
          height: { value: '', error: '' },
          weight: { value: '', error: '' }
        })
      ]);
    } else if (validQuantity < inputs.length) {
      setInputs((prevInputs) => prevInputs.slice(0, validQuantity));
    }
  }, [packageQuantity, inputs.length]);

  const handleSubmit = useCallback(async () => {
    try {
      setLoading(true);
      const secondaryAttributes =
        inputs.length > 1 ? inputs.slice(1).map((att) => ({ depth: parseInt(att.depth.value, 10), width: parseInt(att.width.value), height: parseInt(att.height.value), weight: parseInt(att.weight.value) })) : [];
      const data = await libby.ster_order_item_product.save({
        ...rowData,
        product: {
          product_id: rowData.product?.product_id || null,
          name: rowData.name,
          description: rowData.name,
          sku: rowData.sku,
          packages_quantity: packageQuantity.validQuantity,
          attributes: {
            ...(!!rowData.product?.attributes ? { ...rowData.product?.attributes } : null),
            physical: {
              width: parseInt(inputs[0].width.value, 10),
              height: parseInt(inputs[0].height.value, 10),
              depth: parseInt(inputs[0].depth.value, 10),
              weight: parseInt(inputs[0].weight.value, 10)
            },
            secondary_packages: secondaryAttributes
          },
          price: parseInt(rowData.unit_price, 10),
          tags: {
            tags: 'accesorio'
          },
          productType: {
            productTypeId: PRODUCT_TYPE.BNA
          }
        }
      });

      updateDataWithProducts(data.product, items);
      setSuccess((prevItems: any) => {
        const arr = { ...prevItems };
        arr[rowData.order_item_id] = true;
        if (Object.keys(arr)?.length === items.length) {
          redirect();
        }
        return arr;
      });
      enqueueSnackbar(t('Updated sku $$$ product').replace('$$$', rowData.sku), {
        variant: 'success'
      });
      clearInputs();
      onConfirm(data);
    } catch (error: any) {
      enqueueSnackbar(t('Something is wrong'), { variant: 'error' });
    } finally {
      setLoading(false);
    }
  }, [redirect, updateDataWithProducts, setSuccess, inputs, items, enqueueSnackbar, t, rowData, libby, onConfirm, packageQuantity.validQuantity]);

  const handleDinamicInputChange = (index: number, name: keyof AttributesType, value: string) => {
    let errorMessage = '';

    if (value === '') {
      errorMessage = 'Value cannot be empty';
    } else if (!/^\d+$/.test(value)) {
      errorMessage = 'Value must be numeric';
    }

    setInputs((prevInputs) => prevInputs.map((input, i) => (i === index ? { ...input, [name]: { value, error: errorMessage } } : input)));
  };

  const handlePackageQuantityChange = (e: any) => {
    setPackageQuantityInput(e.target.value);
  };

  const onSelectPackageQuantity = useCallback(() => {
    if (!/^\d+$/.test(packageQuantityInput)) setPackageQuantity((prevState) => ({ ...prevState, error: t('Numeric value only') }));
    else if (Number(packageQuantityInput) > 5) setPackageQuantity((prevState) => ({ ...prevState, error: t('Max value should be 5') }));
    else if (Number(packageQuantityInput) < 1) setPackageQuantity((prevState) => ({ ...prevState, error: t('Min value should be 1') }));
    else setPackageQuantity((prevState) => ({ ...prevState, validQuantity: Number(packageQuantityInput), error: '' }));
  }, [setPackageQuantity, packageQuantityInput, t]);

  const isButtonDisabled = useMemo(() => {
    return inputs.some((input) => ['width', 'depth', 'height', 'weight'].some((key) => input[key as keyof AttributesType].value === '' || !!input[key as keyof AttributesType].error));
  }, [inputs]);

  const clearInputs = () => {
    setInputs([]);
    setPackageQuantity(packageQuantityInitialValue);
    setPackageQuantityInput('1');
  };

  const dialog = (
    <SnackbarProvider>
      <Dialog
        className={classes.root}
        open={open}
        onClose={() => {
          clearInputs();
          onCancel();
        }}
        fullWidth
        maxWidth="md"
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <Box className={classes.mainBox}>
            <Box className={classes.packageQuantityBox}>
              <TextField
                {...textFieldProps(`${t('Package quantity')}`)}
                error={!!packageQuantity.error}
                helperText={!!packageQuantity.error ? packageQuantity.error : ''}
                variant="standard"
                value={packageQuantityInput}
                onChange={handlePackageQuantityChange}
                InputLabelProps={{ shrink: true }}
                fullWidth={false}
              />
              <Button className={classes.packageQuantityButton} onClick={onSelectPackageQuantity} variant="outlined" color="primary">
                {t('Select')}
              </Button>
            </Box>
            <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', minHeight: '300px', overflow: 'auto' }}>
              <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                <Typography>{t('Bulto')}</Typography>
                <Typography>{t('Width (cm)')}</Typography>
                <Typography>{t('Height (cm)')}</Typography>
                <Typography>{t('Depth (cm)')}</Typography>
                <Typography>{t('Weight (grms)')}</Typography>
              </Box>
              {inputs.map((input, index) => (
                <Box key={index} className={classes.textFieldBox}>
                  <TextField disabled={true} variant="standard" value={index + 1} InputLabelProps={{ shrink: true }} />
                  <TextField
                    error={!!input.width.error}
                    helperText={!!input.width.error ? input.width.error : ''}
                    variant="standard"
                    value={input.width.value}
                    name="width"
                    onChange={(e) => handleDinamicInputChange(index, 'width', e.target.value)}
                    InputLabelProps={{ shrink: true }}
                  />
                  <TextField
                    error={!!input.height.error}
                    helperText={!!input.height.error ? input.height.error : ''}
                    variant="standard"
                    name="height"
                    value={input.height.value}
                    onChange={(e) => handleDinamicInputChange(index, 'height', e.target.value)}
                    InputLabelProps={{ shrink: true }}
                  />
                  <TextField
                    error={!!input.depth.error}
                    helperText={!!input.depth.error ? input.depth.error : ''}
                    variant="standard"
                    name="depth"
                    value={input.depth.value}
                    onChange={(e) => handleDinamicInputChange(index, 'depth', e.target.value)}
                    InputLabelProps={{ shrink: true }}
                  />
                  <TextField
                    error={!!input.weight.error}
                    helperText={!!input.weight.error ? input.weight.error : ''}
                    variant="standard"
                    name="weight"
                    value={input.weight.value}
                    onChange={(e) => handleDinamicInputChange(index, 'weight', e.target.value)}
                    InputLabelProps={{ shrink: true }}
                  />
                </Box>
              ))}
            </Box>
            <Box className={classes.buttonContainer}>
              <Button onClick={handleSubmit} variant="contained" color="primary" disabled={isButtonDisabled || loading}>
                {loading ? <CircularProgress size={24} color="primary" /> : t('Save')}
              </Button>
              <Button
                variant="outlined"
                onClick={() => {
                  clearInputs();
                  onCancel();
                }}
              >
                {t('Cancel')}
              </Button>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
    </SnackbarProvider>
  );

  return !customTheme ? dialog : <ThemeProvider theme={customTheme}>{dialog}</ThemeProvider>;
};
