import React, { useMemo } from 'react';
import { DatabaseConnector } from '@phinxlab/libby-rest-web';
import { GeneralSelect } from '../../../components/GeneralSelect';
import { Message_from } from '../../../interfaces/business/message/Message_from';
import { useLibbyFetch } from '../../../hooks';
import { DataGenerateSelectType } from '../../../utils/generateSelect';
import { InputProps, LibbyObject } from '../../../types';

type SelectImportTypeProps = {
  inputProps: InputProps;
  optionAll?: boolean;
  libby: LibbyObject;
  [k: string]: any;
  field: any;
  title: string;
  sort?: boolean | 'asc' | 'desc';
};

const paramsFetch = {
  daoName: 'ster_message_from'
};

export const SelectMessageFromRaw = ({ field, libby, inputProps, optionAll = false, title, sort, ...styles }: SelectImportTypeProps) => {
  const { data: dataRaw, working } = useLibbyFetch(libby, paramsFetch);

  const data: DataGenerateSelectType[] = useMemo(() => {
    const dataMessageFrom = dataRaw as Message_from[];
    const dataGenerateSelect = dataMessageFrom.map(({ message_from_id, email }: Message_from) => ({
      id: parseInt(message_from_id, 10),
      value: email
    }));
    return dataGenerateSelect;
  }, [dataRaw]);

  return <GeneralSelect field={field} working={working} label={title} optionAll={optionAll} data={data} inputProps={inputProps} {...styles} sort={sort} />;
};

export const SelectMessageFrom = React.memo(DatabaseConnector(SelectMessageFromRaw)('ster_message_from'));
