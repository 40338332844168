import { makeStyles } from '@material-ui/styles';
import { PaymentMethodType } from '../../../const';
import { AnyObject } from '../../../../../types';
import { DOCUMENT_TYPE } from '../../../../../const';

export const useStyles = makeStyles((theme: AnyObject) => ({
  search: {
    width: '15vw'
  },
  container: {
    position: 'relative',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    textAlign: 'center'
  },
  btn: {
    border: 'none',
    margin: 20,
    width: 120,
    height: 30,
    borderRadius: 6,
    textTransform: 'uppercase',
    boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
    cursor: 'pointer',
    color: '#fff',
    backgroundSize: '200%',
    transition: '0.4s',
    '&:hover': {
      backgroundPosition: 'right'
    }
  },
  btn1: {
    backgroundImage: 'linear-gradient(45deg, #FFC312, #EE5A24, #00A8FF)'
  },
  btn2: {
    backgroundImage: 'linear-gradient(45deg, #e74c3c, #8e44ad, #f1c40f)'
  },
  root: {
    '& .MuiAutocomplete-popupIndicatorOpen': {
      transform: 'rotate(0deg)!important'
    }
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    marginBottom: '10px'
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120
  },
  containerInputs: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  inputs: {
    margin: theme.spacing(1),
    width: '30ch'
  },
  inputsShipment: {
    margin: theme.spacing(1),
    width: '45ch'
  },
  errorForm: {
    color: '#FF5179',
    display: 'flex',
    justifyContent: 'flex-end'
  },
  error: {
    fontSize: '12px'
  },
  provinceCityInput: {
    '& label': {
      color: '#999999'
    },
    '& input': {
      color: 'black'
    },
    '& .MuiFormHelperText-root': {
      color: '#FF5179'
    },
    width: '30ch',
    margin: theme.spacing(1)
  },
  provinceCityInputShipment: {
    '& label': {
      color: '#999999'
    },
    '& input': {
      color: 'black'
    },
    '& .MuiFormHelperText-root': {
      color: '#FF5179'
    },
    width: '45ch',
    margin: theme.spacing(1)
  },
  title: {
    fontFamily: '"Oswald"',
    verticalAlign: 'baseline',
    textTransform: 'uppercase',
    fontWeight: 200,
    fontSize: '15px',
    color: '#4B4B4B',
    textAlign: 'start'
  },
  textFieldAutocomplete: {
    '& .MuiFormHelperText-root': {
      color: '#FF5179'
    }
  },
  mgtp: {
    marginTop: 7
  },
  loadingAutocomplete: {
    width: '100%',
    textAlign: 'center'
  }
}));

export const initFormBudget = {
  first_name: '',
  last_name: '',
  document: '',
  document_type: '',
  phone_number: '',
  email: '',
  billingAddressState: '',
  billingAddressCity: '',
  billingAddressStreet: '',
  billingAddressStreetNumber: '',
  billingAddressFloor: '',
  billingAddressDepartment: '',
  billingAddressZip: '',
  shipmentAddressState: '',
  shipmentAddressCity: '',
  shipmentAddressStreet: '',
  shipmentAddressStreetNumber: '',
  shipmentAddressZip: '',
  shipment_type: '',
  payment_method: '',
  custom_term: '',
  qty_deferred_payment: '',
  transportation_company: '',
  advance_sale_date: ''
};

export interface PaymentMethodGroupType extends PaymentMethodType {
  group: string;
}

export const documentTypeOptions = [
  { id: DOCUMENT_TYPE.DNI.toString(), value: 'DNI' },
  { id: DOCUMENT_TYPE.CUIT.toString(), value: 'CUIT' },
  { id: DOCUMENT_TYPE.CUIL.toString(), value: 'CUIL' }
];
