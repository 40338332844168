import React, { useMemo } from 'react';
import { DatabaseConnector } from '@phinxlab/libby-rest-web';
import { GeneralSelect } from '../../components/GeneralSelect';
import { InputProps, LibbyObject } from '../../types/types';
import { useLibbyCall } from '../../hooks';
import { Alert } from '../../interfaces/business';

type SelectAlertTypeProps = {
  inputProps: InputProps;
  optionAll?: boolean;
  [k: string]: any;
  libby: LibbyObject;
  sort?: boolean | 'asc' | 'desc';
  not_in_config_alert?: boolean;
};

export const SelectAlertRaw = ({ libby, inputProps, optionAll = false, sort = 'asc', not_in_config_alert = false, ...styles }: SelectAlertTypeProps) => {
  const { data: dataAlert, working = true } = useLibbyCall(libby, {
    daoName: 'ster_alert',
    methodName: 'getAll'
  });

  const data = useMemo(() => {
    let dataAlertAll: Alert[] = dataAlert as Alert[];

    if (not_in_config_alert) {
      dataAlertAll = dataAlertAll.filter(({ message_config_alert }: Alert) => !message_config_alert.length);
    }
    if (inputProps.value) {
      const validateTemplate = dataAlertAll.find(({ alert_id }: Alert) => alert_id === inputProps.value);

      if (!validateTemplate) {
        const searchTemplate = dataAlert.find(({ alert_id }: Alert) => alert_id === inputProps.value);
        if (searchTemplate) {
          dataAlertAll.push(searchTemplate);
        }
      }
    }
    return dataAlertAll.map(({ alert_id, name }: Alert) => ({
      id: parseInt(alert_id, 10),
      value: name
    }));
  }, [dataAlert, not_in_config_alert, inputProps.value]);

  return <GeneralSelect sort={sort} working={working} label="Alert" optionAll={optionAll} data={data} inputProps={inputProps} {...styles} msgEmptyData="No alerts available" msgEmptyEnable />;
};

export const SelectAlert = DatabaseConnector(SelectAlertRaw)('ster_alert');
