import React, { FC } from 'react';
import { useParams } from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Theme, useMediaQuery, useTheme } from '@material-ui/core';
import { TitleBreadcrumbs } from '../../../../interfaces';
import { ScreenAligned } from '../../../../components/ScreenAligned/ScreenAligned';
import { Footer, Header, PaymentMethods, ProductTable } from './components';
import { BudgetContextProvider } from './context';
import { useBudgetInitialValues } from './context/BudgetContext/hooks';

export interface BudgetNewV2Props {
  title: TitleBreadcrumbs[];
}

export const BudgetNewV2: FC<BudgetNewV2Props> = ({ title }: BudgetNewV2Props) => {
  const { id } = useParams<{ id: string | undefined }>();
  const initialValues = useBudgetInitialValues(id);
  const theme = useTheme<Theme>();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return !initialValues ? (
    <div style={{ height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <CircularProgress />
    </div>
  ) : (
    <BudgetContextProvider initialValues={initialValues}>
      <ScreenAligned title={title} additionalTitle={null} showData style={isMobile ? { padding: '20px' } : undefined}>
        <Header />
        <PaymentMethods />
        <ProductTable />
        <Footer />
      </ScreenAligned>
    </BudgetContextProvider>
  );
};
