import { Message } from '../interfaces/business/message';
import { makeBusinessEntity } from '../lib/libby/generator/makeBusinessEntity';

const DAO_NAME = 'ster_message';

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch }
} = makeBusinessEntity<Message>(DAO_NAME);

// hooks
export const useMessageFetchById = fetchById;
export const useMessageLibbyCall = libbyCall;
export const useMessageDAO = libbyDAO;
export const useMessageLibbyFetch = libbyFetch;
