import React, { useCallback, useState } from 'react';
import { FormConfig } from 'react-final-form-hooks';
import { ValidationErrors } from 'final-form';
import { Backdrop, Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@material-ui/core';
import moment, { Moment } from 'moment';
import { SnackbarProvider } from 'notistack';
import { ThemeProvider, makeStyles } from '@material-ui/styles';
import { gridBreakpointsFields, MARKETPLACE_BILLING } from '../../../../../const';
import { useTranslation } from '../../../../../services/translation';
import { AnyObject, LibbyObject } from '../../../../../types';
import InfoTable from '../../../../components/InfoTable';
import { useComparativeTableLogic } from '../../../hook';
import { format } from '../../../../../util';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { LiquidationDialogError } from './LiquidationDialogError';
import customModal from '../../../../../services/customFormDialog';
import { getLibbyError } from 'utils';

const useStyles = makeStyles({
  title: {
    fontFamily: 'Oswald'
  },
  error: {
    fontSize: '12px'
  },
  inputsContainer: {
    marginTop: '15px'
  },
  headersWithIcon: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  }
});

interface FormInitialValues {
  marketplace: string;
  startDate: Moment | null;
  endDate: Moment | null;
  reference: string;
}

interface FinalLiquidationDialogTypes extends FormConfig<any> {
  open: boolean;
  onCancel: any;
  moveBack: Function;
  onConfirm: Function;
  title: string;
  content: React.ReactNode;
  labelContent: string;
  cancelText: string;
  confirmText: string;
  customTheme: string | null;
  inputType: string;
  formInitialValues: FormInitialValues;
  validate?: (values: any) => ValidationErrors | Promise<ValidationErrors> | undefined;
  libby: LibbyObject;
  confirm: AnyObject | any;
}
const FinalDialogErrorModal = customModal(LiquidationDialogError);
export const FinalLiquidationDialog = ({ open = false, onCancel = () => {}, moveBack = () => {}, onConfirm = () => {}, title, cancelText, confirmText, customTheme = null, libby, confirm }: FinalLiquidationDialogTypes) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [cancel, setCancel] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);

  const { columnsProcessed, rowsProcessed, rowsUnprocessed, columnsUnprocessed, marketExpenses, generalTotal, shippingTotal } = useComparativeTableLogic(confirm?.data, t);
  const onSubmit = useCallback(async () => {
    try {
      setLoading(true);
      const data = await libby.ster_new_order_liquidation_confirm.save({
        ...confirm?.data,
        liquidation: confirm.liquidation
      });
      if (data) {
        onConfirm(data);
      }
    } catch (e: any) {
      setLoading(false);
      const libbyError = getLibbyError({ libby, daoName: 'ster_new_order_liquidation_confirm' });
      await FinalDialogErrorModal.show({
        errormsg: libbyError,
        title: t('Error when trying to Liquidate'),
        confirmText: t('Confirm'),
        cancelText: t('Cancel'),
        libby
      });
    }
    setLoading(false);
  }, [libby, onConfirm, confirm, t]);

  const dialog = (
    <Dialog open={open} aria-labelledby="form-dialog-title" fullWidth maxWidth="md">
      <Backdrop open={loading} style={{ zIndex: 999 }}>
        <CircularProgress style={{ color: '#FF5179' }} />
      </Backdrop>
      <DialogTitle id="form-dialog-title">
        <Box className={classes.headersWithIcon}>
          <Typography className={classes.title} variant="h3">
            {title}
          </Typography>
          <IconButton
            onClick={() => {
              moveBack();
            }}
          >
            <ArrowBackIcon color="primary" />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Box item {...gridBreakpointsFields} style={{ marginBottom: '10px' }}>
          <Typography variant="h5">Marketplace: {MARKETPLACE_BILLING.find((market) => market.marketplace_id === confirm?.liquidation?.marketplace_id)?.name}</Typography>
        </Box>
        <Box display="flex" justifyContent="space-between" marginBottom={1}>
          <Box {...gridBreakpointsFields} style={{ width: '45%' }}>
            <Typography variant="h5">
              {t('Start of Period')}: {moment(confirm?.liquidation?.date_start).format('DD-MM-YYYY')}
            </Typography>
          </Box>
          <Box {...gridBreakpointsFields} style={{ width: '45%' }}>
            <Typography variant="h5">
              {t('End of Period')}: {moment(confirm?.liquidation?.date_end).format('DD-MM-YYYY')}
            </Typography>
          </Box>
        </Box>
        {confirm?.liquidation?.reference && (
          <Box {...gridBreakpointsFields} style={{ width: '90%', marginBottom: '10px' }}>
            <Typography variant="h5">
              {t('Reference')}: {confirm?.liquidation?.reference}
            </Typography>
          </Box>
        )}
        {rowsProcessed && rowsProcessed?.length > 0 ? (
          <>
            <InfoTable columns={columnsProcessed} rows={rowsProcessed} onSortChange={() => {}} direction="asc" height={320} />
            <Box display="flex" justifyContent="flex-end" marginTop={2}>
              <Box style={{ width: '300px' }}>
                <Box display="flex" justifyContent="space-between">
                  <Typography variant="h5">{t('Sub Total')}:</Typography>
                  <Typography variant="h5">{format(confirm?.data.details.total_accumulated, 'ARS', t)}</Typography>
                </Box>
                <Box display="flex" justifyContent="space-between">
                  <Typography variant="h5">{t('Refunds')}:</Typography>
                  <Typography variant="h5">{format(confirm?.data.details.refund, 'ARS', t)}</Typography>
                </Box>
                <Box display="flex" justifyContent="space-between">
                  <Typography variant="h5">{t('Shipping')}:</Typography>
                  <Typography variant="h5">{format(shippingTotal, 'ARS', t)}</Typography>
                </Box>
                <Box display="flex" justifyContent="space-between">
                  <Typography variant="h5">{t('Market expenses')}:</Typography>
                  <Typography variant="h5">{format(marketExpenses, 'ARS', t)}</Typography>
                </Box>
                <Box display="flex" justifyContent="space-between">
                  <Typography variant="h5">{t('Cancellations')}:</Typography>
                  <Typography variant="h5">-{format(confirm?.data.details.cancellations, 'ARS', t)}</Typography>
                </Box>
                <Box display="flex" justifyContent="space-between">
                  <Typography variant="h5">{t('Penalties')}:</Typography>
                  <Typography variant="h5">-{format(confirm?.data.details.penalties, 'ARS', t)}</Typography>
                </Box>
                <Box display="flex" justifyContent="space-between">
                  <Typography variant="h4" style={{ fontWeight: 600 }}>
                    {t('Total')}:
                  </Typography>
                  <Typography variant="h4" style={{ fontWeight: 600 }}>
                    {format(generalTotal, 'ARS', t)}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </>
        ) : (
          confirm?.liquidation?.importation?.importationText !== '' && (
            <Box {...gridBreakpointsFields} style={{ width: '90%', marginBottom: '10px', color: 'red' }}>
              <Typography variant="h5">{t('No matches were found for the orders in the imported file')}</Typography>
            </Box>
          )
        )}
        {rowsUnprocessed && rowsUnprocessed?.length > 0 && (
          <>
            <Box {...gridBreakpointsFields} style={{ width: '90%', marginBottom: '10px', marginTop: '10px', color: 'red' }}>
              <Typography variant="h4">{t('There are Unprocessed Orders')}</Typography>
              <Typography variant="h5" style={{ marginTop: '10px' }}>
                {t('Unprocessed Orders Total')}: {rowsUnprocessed.length}{' '}
              </Typography>
            </Box>
            <InfoTable columns={columnsUnprocessed} rows={rowsUnprocessed} onSortChange={() => {}} direction="asc" height={200} />
            <Box display="flex" justifyContent="flex-end" marginTop={2}>
              <Box style={{ width: '300px' }}>
                <Box display="flex" justifyContent="space-between">
                  <Typography variant="h4" style={{ fontWeight: 600 }}>
                    {t('Unprocessed Total')}:
                  </Typography>
                  <Typography variant="h4" style={{ fontWeight: 600 }}>
                    {format(confirm?.data.details.unprocessed_total, 'ARS', t)}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            onCancel();
            setCancel(!cancel);
          }}
        >
          {cancelText}
        </Button>
        <Button color="primary" autoFocus onClick={onSubmit}>
          {confirmText}
        </Button>
      </DialogActions>
    </Dialog>
  );

  return !customTheme ? (
    dialog
  ) : (
    <ThemeProvider theme={customTheme}>
      <SnackbarProvider maxSnack={3}>{dialog}</SnackbarProvider>
    </ThemeProvider>
  );
};
