import { LibbyFetchDAO } from '../LibbyFetchDAO';

export class OrderReportByTransportDAO extends LibbyFetchDAO {
  constructor() {
    super('ster_reporting_by_transport', 'order_id');
  }

  getAll() {
    return this.query().limit(0, 20000).run();
  }
}
