import React from 'react';
import { AnyObject } from '../../../../../types/types';
import { PaginatedTable } from '../../../../../components/PaginatedTable';

const itemsColumns = [
  {
    id: 'id',
    label: 'Id'
  },
  {
    id: 'msg',
    label: 'Mensaje'
  },
  {
    id: 'courier',
    label: 'Correo'
  }
];

export const FailurePdfTable = ({ items = [] }: AnyObject) => <PaginatedTable headCells={itemsColumns} rows={items} rowIdKey="order_id" />;
