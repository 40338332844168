import { makeValidator, ValidatorType, ValidatorRule } from '../../../utils';

export const formInitialValues = {
  alert: '',
  template: ''
};

const validations: ValidatorRule[] = [
  { key: 'alert', validators: [ValidatorType.SELECT] },
  { key: 'template', validators: [ValidatorType.SELECT] }
];

export const validate = makeValidator(validations);

export const getValidate = (keys: Array<string>) => makeValidator(keys.length > 0 ? validations.filter((val) => keys.includes(val.key)) : validations);
