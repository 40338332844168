import { LibbyFetchDAO } from '../LibbyFetchDAO';

export class PaymentMethodOdooDAO extends LibbyFetchDAO {
  constructor() {
    super('paymentmethododoo', 'paymentMethodOdooId');
  }

  getAll() {
    return this.query().limit(0, 10000).run();
  }
}
