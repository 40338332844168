import { OrderDocument } from '../interfaces/order';
import { makeBusinessEntity, PartialBusinessEntityOptions } from '../lib/libby/generator/makeBusinessEntity';

import { businessOptions } from './businessOptions';

const DAO_NAME = 'ster_shipping_tags_update';

const defaultOptions: PartialBusinessEntityOptions<any> = {
  hoc: {
    withEntityAutocomplete: {
      optionLabelKey: 'name'
    },
    withAccordionSelector: {
      idKey: 'order_document_id',
      labelKey: 'name'
    },
    withSelectedChips: {
      idKey: 'order_document_id',
      labelKey: 'name'
    }
  }
};

const optionsToUse = !businessOptions.ShippingTagsUpdate
  ? defaultOptions
  : {
      ...defaultOptions,
      ...businessOptions.ShippingTagsUpdate,
      hoc: { ...defaultOptions.hoc, ...businessOptions.ShippingTagsUpdate.hoc }
    };

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch },
  hoc: { entityAutocomplete, renderPropCall, renderPropFetchById, simpleSelect, accordionSelector, selectedChips }
} = makeBusinessEntity<OrderDocument>(DAO_NAME, optionsToUse);

// hooks
export const useShippingTagsUpdateFetchById = fetchById;
export const useShippingTagsUpdateLibbyCall = libbyCall;
export const useShippingTagsUpdateDAO = libbyDAO;
export const useShippingTagsUpdateLibbyFetch = libbyFetch;

// components/hoc
export const ShippingTagsUpdateAutocomplete = entityAutocomplete;
export const ShippingTagsUpdateCall = renderPropCall;
export const ShippingTagsUpdateById = renderPropFetchById;
export const ShippingTagsUpdateSelect = simpleSelect;
export const ShippingTagsUpdateAccordionSelector = accordionSelector;
export const ShippingTagsUpdateSelectedChips = selectedChips;
