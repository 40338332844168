import { LibbyFetchDAO } from './LibbyFetchDAO';

export class OrdersSoPrintTagDAO extends LibbyFetchDAO {
  constructor() {
    super('ster_order_print_tags', 'order_id');
  }

  getAll() {
    return this.query().limit(0, 10000).run();
  }

  getAllByOrderId(id: number) {
    if (!id) {
      return null;
    }
    return this.aspect('list_order_so_print_pdf').query().equals('order_id', id).run();
  }
}
