import React from 'react';
import { SnackbarProvider } from 'notistack';
import { SearchDialog } from '../../../components/SearchDialog';
import CustomModal from '../../../services/customFormDialog';
import { ItemsWithoutProductTable } from '../routes/TagMangerList/components/ItemsWithoutProduct';
import { MARKETPLACE, COURIER } from '../../../const';
import { Order_item, Product, Order } from '../../../interfaces/business';

const SearchDialogModal = CustomModal(SearchDialog);

type modalProductType = {
  items: Order_item[];
  updateDataWithProducts: (product: Product, id?: string) => void;
  redirectPrint: () => void;
};

const modalProduct = async ({ items, updateDataWithProducts, redirectPrint }: modalProductType) => {
  try {
    await SearchDialogModal.show({
      title: 'To generate the label please enter the measurements and weight of the product',
      id: 'order_item_id',
      properties: ['name', 'sku'],
      label: 'Name/Sku',
      data: items,
      renderItem: (dataItems: { data: Order_item }) => (
        <SnackbarProvider>
          <ItemsWithoutProductTable
            items={dataItems.data}
            updateDataWithProducts={updateDataWithProducts}
            redirect={() => {
              SearchDialogModal.handleCancel();
              redirectPrint();
            }}
          />
        </SnackbarProvider>
      )
    });
  } catch (error: any) {
    // nothing
  }
};

type addProductsType = {
  orders: Order[];
  updateDataWithProducts: (product: Product, id?: string | undefined) => void;
  redirectPrint: () => void;
};

export const addProducts = async ({ orders, updateDataWithProducts, redirectPrint }: addProductsType) => {
  const itemsProduct: any[] = [];

  orders.forEach((dataPrint: Order) => {
    let validate = false;
    if (dataPrint.shipment?.service?.courier.courier_id === COURIER.ANDREANI && dataPrint.source.marketplace.marketplace_id !== MARKETPLACE.TARJETA_NARANJA) {
      dataPrint.items.forEach(({ product, unit_price, subtotal }: Order_item, index: number) => {
        if (
          (!product || product?.attributes?.physical?.width === 0 || product?.attributes?.physical?.height === 0 || product?.attributes?.physical?.depth === 0 || product?.attributes?.physical?.weight === 0) &&
          (parseFloat(unit_price) > 0 || (parseFloat(unit_price) === 0 && parseFloat(subtotal) > 0))
        ) {
          itemsProduct.push({ ...dataPrint, item: dataPrint.items[index] });
          validate = true;
        }
      });
    }

    return validate;
  });

  if (itemsProduct?.length) {
    const newItems = itemsProduct.reduce((accOrderItems: Order_item[], item: any) => {
      if (!accOrderItems.find((accNewOrderItems: Order_item) => accNewOrderItems.sku === item.item.sku)) {
        accOrderItems.push(item.item);
      }
      return accOrderItems;
    }, []);

    modalProduct({
      items: newItems,
      updateDataWithProducts,
      redirectPrint
    });
  } else {
    redirectPrint();
  }
};
