import { AnyObject } from '../types/types';

export enum REPORTING_AREA {
  LIQUIDATION = 'Liquidation',
  LOGISTICS = 'Logistics',
  SALE = 'Sale'
}

export const ReportingArea: AnyObject = {
  1: 'Liquidation',
  2: 'Logistics',
  3: 'Sale'
};
