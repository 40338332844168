import { Box } from '@material-ui/core';
import React from 'react';
import { formatDate } from '../../../utils';
import { RowMessage } from '../../MessageTemplate/components/RowMessage';
import { RecipentsShow } from '../Components';
import { RowsMessageType } from '../types';

interface MessageToType {
  message_to: {
    recipents: string;
    message_id: string;
  };
}
interface MessageType {
  message: {
    showMessageDialog: (message: string, isDownSm: boolean, isMobile: boolean) => void;
    messageSent: string;
    isDownSm: boolean;
    isMobile: boolean;
  };
}

export const columnsMessage = [
  {
    id: 'actions',
    label: '',
    noSort: true
  },
  {
    id: 'id',
    label: 'Message ID',
    orderById: 'message_id'
  },
  {
    id: 'source_id',
    label: 'Source ID'
  },
  {
    id: 'message_subject',
    label: 'Subject',
    orderById: 'template.message_subject'
  },
  {
    id: 'message_to',
    label: 'Recipient',
    render: ({ message_to }: MessageToType) => <RecipentsShow message_to={message_to.recipents} message_id={message_to.message_id} />
  },
  {
    id: 'message_cc',
    label: 'CC',
    render: ({ message_cc, id }: any) => {
      return message_cc?.length ? <RecipentsShow message_to={message_cc} message_id={id} /> : '';
    }
  },
  {
    id: 'created_at',
    label: 'Created At',
    render: ({ created_at }: RowsMessageType) => <Box>{formatDate(created_at, true, false)}</Box>
  },
  {
    id: 'updated_at',
    label: 'Last Update',
    render: ({ updated_at }: RowsMessageType) => <Box>{formatDate(updated_at, true, false)}</Box>
  },
  {
    id: 'message_source',
    label: 'Source',
    orderById: 'message_source.name',
    render: ({ message_source }: RowsMessageType, item: RowsMessageType, t: (str: string) => string) => t(message_source)
  },
  {
    id: 'message_reason',
    label: 'Reason',
    orderById: 'message_reason.name',
    render: ({ message_reason }: RowsMessageType, item: RowsMessageType, t: (str: string) => string) => t(message_reason)
  },
  {
    id: 'message_status',
    label: 'Status',
    render: ({ message_status }: RowsMessageType, item: RowsMessageType, t: (str: string) => string) => t(message_status),
    orderById: 'message_state.name'
  },
  {
    id: 'resend',
    label: 'Resend',
    render: ({ resend }: RowsMessageType, item: RowsMessageType, t: (str: string) => string) => t(resend),
    noSort: true
  },
  {
    id: 'result',
    label: 'Result'
  },
  {
    id: 'message',
    label: 'Message',
    render: ({ message }: MessageType) => <RowMessage showMessageDialog={message.showMessageDialog} message={message.messageSent} isDownSm={message.isDownSm} isMobile={message.isMobile} />
  }
];
