import React from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import { ReportingSalesList } from '../ReportingSales/routes';
import { ColumnsProductsTaxation } from './routes/ReportingSalesTaxation/utils/column';

export const ReportingSalesTaxation = () => {
  const match = useRouteMatch();
  const { columns, columnsProducts } = ColumnsProductsTaxation();
  return (
    <Switch>
      <Route exact path={`${match.path}`}>
        <ReportingSalesList typeReporting="Sales taxation" daoName="ster_order_reporting_sales_taxation" daoNameHistory="ster_order_history_marketplace" columnsAux={columns} columnsProductAux={columnsProducts} vatEnabled />
      </Route>
    </Switch>
  );
};
