import React, { RefObject, useMemo, useState } from 'react';
import { FormControl, Grid, Typography, IconButton, InputAdornment, InputLabel, OutlinedInput } from '@material-ui/core';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { useStyles } from './styles';
import { useValidateLogic } from './useValidateLogic';
import { textFieldProps } from '../../utils/materialHelpers';
import { AnyObject } from '../../types';
import { useTranslation } from '../../services/translation';

interface ValidatePasswordType {
  value: string;
  genericInput: AnyObject;
  ref?: RefObject<HTMLHeadingElement>;
  disabled?: boolean;
}

export const ValidatePassword = ({ value, genericInput, ref, disabled = false }: ValidatePasswordType) => {
  const classes = useStyles();
  const [showPassword, setShowPassword] = useState(false);

  const { atLeastEight, upperCase, lowerCase, oneNumber, special } = useValidateLogic(value);

  const errors1 = useMemo(() => [atLeastEight, upperCase, lowerCase], [atLeastEight, upperCase, lowerCase]);
  const errors2 = useMemo(() => [oneNumber, special], [oneNumber, special]);

  const totalErrors = useMemo(() => [...errors1, ...errors2], [errors1, errors2]);

  const count = useMemo(() => {
    let countInner = 5;
    totalErrors.forEach((item) => {
      if (item.error) {
        countInner += 1;
      } else {
        countInner -= 1;
      }
    });
    return countInner;
  }, [totalErrors]);

  const { t } = useTranslation();

  const widthValidateBar = useMemo(() => (count === 10 ? '0%' : count === 8 ? '25%' : count === 6 ? '50%' : count >= 2 ? '75%' : '100%'), [count]);
  const textValidate = useMemo(() => (count === 10 ? '' : count === 8 ? t('Bad') : count === 6 ? t('Medium ') : count >= 2 ? t('Almost ready') : t('Excellent')), [count, t]);
  const colorValidateBar = useMemo(() => (count === 10 ? 'red' : count === 8 ? 'red' : count === 6 ? 'rgb(243 243 57)' : count === 4 ? '#56b561' : '#56b561'), [count]);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event: any) => {
    event.preventDefault();
  };

  return (
    <>
      <FormControl fullWidth variant="outlined">
        <InputLabel htmlFor="outlined-adornment-password" disabled={disabled}>
          Password
        </InputLabel>
        <OutlinedInput
          {...textFieldProps(t('Password'))}
          {...genericInput.input}
          type={showPassword ? 'text' : 'password'}
          inputRef={ref}
          disabled={disabled}
          endAdornment={
            <InputAdornment position="end">
              <IconButton aria-label="toggle password visibility" onClick={handleClickShowPassword} onMouseDown={handleMouseDownPassword} edge="end">
                {showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          }
        />
      </FormControl>
      <Grid className={classes.validateBar}>
        <Grid className={classes.validateBarInner} style={{ width: widthValidateBar, backgroundColor: colorValidateBar }} />
      </Grid>
      <Grid className={classes.textValidate}>
        <Typography style={{ color: count === 6 ? '#dcd700' : colorValidateBar }}>{textValidate}</Typography>
      </Grid>
      <Grid className={classes.root} style={{ marginTop: count === 10 ? '10px' : '' }}>
        <Grid xs={6}>
          {errors1.map((item) => (
            <Typography className={item.error ? classes.textError : classes.textSuccess}>{`· ${t(item.text ? item.text : '')}`}</Typography>
          ))}
        </Grid>
        <Grid xs={6}>
          {errors2.map((item) => (
            <Typography className={item.error ? classes.textError : classes.textSuccess}>{`· ${t(item.text ? item.text : '')}`}</Typography>
          ))}
        </Grid>
      </Grid>
    </>
  );
};
