import { makeValidator, ValidatorType, ValidatorRule } from '../../../../../utils';

export const featuresCopyInitialValuesForm = {
  product_id: ''
};

const validations: ValidatorRule[] = [{ key: 'product_id', validators: [ValidatorType.REQUIRED] }];

export const validate = makeValidator(validations);

export const getValidate = (keys: Array<string>) => makeValidator(keys.length > 0 ? validations.filter((val) => keys.includes(val.key)) : validations);
