import { makeBusinessEntity } from '../lib/libby/generator/makeBusinessEntity';
import { MessageConfigType } from '../routes/MessageConfig/types/types';

const DAO_NAME = 'ster_message_config';

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch }
} = makeBusinessEntity<MessageConfigType>(DAO_NAME);

// hooks
export const useMessageConfigFetchById = fetchById;
export const useMessageConfigLibbyCall = libbyCall;
export const useMessageConfigDAO = libbyDAO;
export const useMessageConfigLibbyFetch = libbyFetch;
