import { LibbyFetchDAO } from '../LibbyFetchDAO';

export class ShippingTagsUpdateDAO extends LibbyFetchDAO {
  constructor() {
    super('ster_shipping_tags_update', 'order_id');
  }

  regeneratePDF(order_id: string) {
    return this.query().equals('order_id', order_id).run();
  }
}
