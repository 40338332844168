import { useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useSterConfigLogLibbyFetch } from '../../../../../business';
import { useFilerParamsId } from '../../../../../hooks';
import { makeFilter } from '../utils';
import { RowsType } from '../../../../../utils/tables';
import { formatDate } from '../../../../../utils';

export const useSterConfigLogsLogic = () => {
  const params = useParams<{ id: string }>();
  const filterInit = useMemo(() => makeFilter({ ster_config_id: params?.id }), [params]);
  const { paramsFetch, direction, orderBy, handleRequestSort } = useFilerParamsId({
    orderInit: 'updated_at',
    daoName: 'ster_config_logs',
    init: filterInit,
    directionInit: 'desc'
  });

  const { data: sterConfigLogs, working, fetchMore } = useSterConfigLogLibbyFetch(paramsFetch);

  const rows: RowsType[] = useMemo(
    () =>
      sterConfigLogs?.map((item) => ({
        ...item,
        id: item.ster_config_log_id,
        updated_by: item.updated_by.username,
        name: item.ster_config.name,
        date: formatDate(item.updated_at, true, false)
      })) || [],
    [sterConfigLogs]
  );

  const fetchMoreMemo = useCallback(() => {
    if (!working) {
      fetchMore();
    }
  }, [working, fetchMore]);

  return { rows, working, fetchMoreMemo, direction, orderBy, handleRequestSort };
};
