import { Moment } from 'moment';
import { Filter } from '../../../types';
import { endDateFormatUtc, startDateFormatUtc } from '../../../utils';

type MakeFilterProps = {
  startDate?: Moment | null;
  endDate?: Moment | null;
  marketplace_id: string;
};

export const makeFilter = ({ startDate, endDate, marketplace_id }: MakeFilterProps) => {
  const filter: Filter = {};
  if (marketplace_id) {
    filter.startDate = [
      {
        path: 'marketplace_id',
        value: marketplace_id
      }
    ];
  }
  if (startDate) {
    filter.startDate = [
      {
        path: 'created_at',
        value: startDateFormatUtc(startDate),
        method: 'higherOrEquals'
      }
    ];
  }
  if (endDate) {
    filter.endDate = [
      {
        path: 'created_at',
        value: endDateFormatUtc(endDate),
        method: 'lowerOrEquals'
      }
    ];
  }

  return filter;
};
