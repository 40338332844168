import { LibbyFetchDAO } from '../LibbyFetchDAO';

export class CategoryDAO extends LibbyFetchDAO {
  constructor() {
    super('ster_category', 'category_id');
  }

  getAllCategory() {
    return this.query().limit(0, 10000).run();
  }
}
