import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { ClientsList } from './routers/ClientsList';

export const ClientsTurns = () => {
  const match = useRouteMatch();

  return (
    <Switch>
      <Route path={`${match.path}`}>
        <ClientsList />
      </Route>
    </Switch>
  );
};
