import React, { useMemo, useState } from 'react';
import { useReactTable, getCoreRowModel, flexRender } from '@tanstack/react-table';
import { Box, Checkbox, FormControlLabel } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { useTranslation } from '../../../../../../services/translation';
import { format } from '../../../../../../util';
import './index.css';
import { ChartEmpty } from '../../../../../../components/ChartEmpty';
import LoadingData from '../../../../../components/LoadingData';

type Data = {
  name: string;
  [k: string]: any;
  goal: number;
  shareGoal: number;
  real: number;
  percentageComplied: number;
  difference: number;
  projection: number;
  percentageCompliedProjection: number;
};

type GoalTableProps = {
  [k: string]: any;
  working: boolean;
  data: Data[];
  aditionalColumns: { id: string; value: string }[];
  monetary?: boolean;
};

const useStyles = makeStyles(() => ({
  rowStyle: {
    '& > tr:nth-child(odd)': {
      backgroundColor: '#dae2f2'
    }
  }
}));

const GoalTable = ({ working, data, aditionalColumns, monetary = true }: GoalTableProps) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [columnVisibility, setColumnVisibility] = useState<any>({});
  const valueType = monetary ? 'ARS' : 'Integer';

  const defaultColumns = useMemo(
    () => [
      {
        header: t(monetary ? 'Billing' : 'Units').toUpperCase(),
        columns: [
          {
            header: t('Name'),
            accessorKey: 'name',
            cell: (info: any) => <div style={{ textAlign: 'left' }}>{info.getValue()}</div>
          },
          {
            header: t('Goal'),
            accessorKey: 'goal',
            cell: (info: any) => format(info.getValue(), valueType, t)
          },
          {
            header: t('Share'),
            accessorKey: 'shareGoal',
            cell: (info: any) => <div style={{ textAlign: 'center' }}>{`${info.getValue().toFixed(2)}%`}</div>,
            size: 90
          },
          {
            header: t('Real'),
            accessorKey: 'real',
            cell: (info: any) => format(info.getValue(), valueType, t)
          },
          {
            header: t('% Complied'),
            accessorKey: 'percentageComplied',
            cell: (info: any) => <div style={{ textAlign: 'center' }}>{`${info.getValue().toFixed(2)}%`}</div>,
            size: 100
          },
          {
            header: t('Difference'),
            accessorKey: 'difference',
            cell: (info: any) => {
              const cellStyle: any = {};
              const value = info.getValue();
              if (value < 0) {
                cellStyle.color = 'red';
              }
              return <div style={{ ...cellStyle }}>{format(value, valueType, t)}</div>;
            }
          },
          {
            header: t('Projection'),
            accessorKey: 'projection',
            cell: (info: any) => format(info.getValue(), valueType, t)
          },
          {
            header: t('% Projection Complied'),
            accessorKey: 'percentageCompliedProjection',
            cell: (info: any) => <div style={{ textAlign: 'center' }}>{`${info.getValue().toFixed(2)}%`}</div>,
            size: 115
          }
        ]
      }
    ],
    [monetary, t, valueType]
  );

  const columnsMonths = useMemo(
    () =>
      aditionalColumns.map((value) => ({
        header: t(value.value),
        accessorKey: value.id,
        cell: (info: any) => format(info.getValue(), valueType, t)
      })),
    [aditionalColumns, t, valueType]
  );

  const columns: typeof defaultColumns = useMemo(() => {
    const updatedColumns = [...defaultColumns[0].columns];
    updatedColumns.splice(1, 0, ...columnsMonths);
    return [{ ...defaultColumns[0], columns: updatedColumns }];
  }, [defaultColumns, columnsMonths]);

  const table = useReactTable({
    data,
    columns,
    state: {
      columnVisibility
    },
    onColumnVisibilityChange: setColumnVisibility,
    columnResizeMode: 'onChange',
    getCoreRowModel: getCoreRowModel()
  });

  const AditionalStyles = (columnId: string, value: any, rowId: number) => {
    const styles: any = {};
    switch (columnId) {
      case 'name': {
        styles.borderRight = '2px solid #333';
        break;
      }
      case 'goal': {
        styles.borderLeft = '2px solid #333';
        break;
      }
      case 'shareGoal': {
        styles.borderRight = '2px solid #333';
        break;
      }
      case 'real': {
        styles.backgroundColor = '#e3f0db';
        break;
      }
      case 'percentageComplied': {
        styles.backgroundColor = '#e3f0db';
        break;
      }
      case 'difference': {
        styles.backgroundColor = '#d1cfcf';
        styles.borderRight = '2px solid #333';
        styles.borderLeft = '2px solid #333';
        break;
      }
      case 'percentageCompliedProjection': {
        if (value < 80) {
          styles.backgroundColor = '#f8696b';
        } else if (value < 100) {
          styles.backgroundColor = '#e6e584';
        } else {
          styles.backgroundColor = '#63bf7c';
        }
        break;
      }
    }
    if (rowId === data.length - 1) {
      styles.borderTop = '2px solid #333';
      if (columnId !== 'percentageCompliedProjection') styles.backgroundColor = '#f0f0f0';
    }
    return styles;
  };

  const onClickMonths = () => {
    if (!Object.keys(columnVisibility).length) {
      const obj: any = {};
      aditionalColumns.map((month) => {
        obj[month.id] = false;
      });
      setColumnVisibility(obj);
    } else setColumnVisibility({});
  };

  return (
    <>
      {!working ? (
        data.length ? (
          <>
            <FormControlLabel control={<Checkbox checked={!Object.keys(columnVisibility).length} onChange={onClickMonths} color="primary" />} label={t('Months')} />
            <Box component="div" py={2} style={{ overflow: 'auto' }}>
              <Box width={table.getCenterTotalSize()}>
                <table
                  {...{
                    style: {
                      width: table.getCenterTotalSize()
                    },
                    className: 'divTable'
                  }}
                >
                  <thead>
                    {table.getHeaderGroups().map((headerGroup) => (
                      <tr key={headerGroup.id} className="tr">
                        {headerGroup.headers.map((header) => (
                          <th
                            {...{
                              key: header.id,
                              colSpan: header.colSpan,
                              style: {
                                width: header.getSize()
                              },
                              className: 'th'
                            }}
                          >
                            {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                            <div
                              {...{
                                onMouseDown: header.getResizeHandler(),
                                onTouchStart: header.getResizeHandler(),
                                className: `resizer ${header.column.getIsResizing() ? 'isResizing' : ''}`
                              }}
                            />
                          </th>
                        ))}
                      </tr>
                    ))}
                  </thead>
                  <tbody className={classes.rowStyle}>
                    {table.getRowModel().rows.map((row) => (
                      <tr key={row.id}>
                        {row.getVisibleCells().map((cell) => {
                          const styles = AditionalStyles(cell.column.id, cell.getValue(), cell.row.index);
                          return (
                            <td
                              {...{
                                key: cell.id,
                                style: {
                                  width: cell.column.getSize(),
                                  ...styles
                                },
                                className: 'td'
                              }}
                            >
                              {flexRender(cell.column.columnDef.cell, cell.getContext())}
                            </td>
                          );
                        })}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </Box>
            </Box>
          </>
        ) : (
          <ChartEmpty />
        )
      ) : (
        <LoadingData working={working} />
      )}
    </>
  );
};
export default GoalTable;
