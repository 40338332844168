import React, { useCallback, useMemo } from 'react';
import { DatabaseConnector } from '@phinxlab/libby-rest-web';
import { TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { GeneralSelect } from '../../components/GeneralSelect';
import { AnyObject, LibbyObject } from '../../types/types';
import { useLibbyCall } from '../../hooks';
import { useTranslation } from '../../services/translation';
import { DataGenerateSelectType } from '../../utils/generateSelect';

type TextFieldVariant = 'filled' | 'standard' | 'outlined';
type SelectMarketPlaceTypeProps = {
  inputProps: AnyObject;
  optionAll?: boolean;
  [k: string]: any;
  libby: LibbyObject;
  canalOffLine: boolean;
  sort?: boolean | 'asc' | 'desc';
  disabled?: boolean;
  multiple: boolean;
  variant?: TextFieldVariant;
};

export const SelectBrandProductRaw = ({ multiple = false, disabled, libby, inputProps = {}, optionAll = false, canalOffLine, sort, variant = 'standard', ...styles }: SelectMarketPlaceTypeProps) => {
  const { t } = useTranslation();
  const { data: dataBrands, working = true } = useLibbyCall(libby, {
    daoName: 'ster_brand',
    methodName: 'getAllBrands',
    params: []
  });

  const data = useMemo(
    () =>
      dataBrands.map(({ brand_id, name }: any) => ({
        id: brand_id,
        value: name
      })),
    [dataBrands]
  );

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const onChangeAutoComplete = useCallback(
    (event, newValue: DataGenerateSelectType[]) => {
      inputProps.onChange(
        newValue.map((marketplace: DataGenerateSelectType) => ({
          value: marketplace.id,
          path: ''
        }))
      );
    },
    [inputProps]
  );

  return (
    <>
      {multiple ? (
        <Autocomplete
          disabled={disabled}
          style={{ display: 'inline-block', width: '100%' }}
          multiple
          id="tags-outlined"
          options={data}
          getOptionLabel={(option: { id: string; value: string }) => option.value}
          filterSelectedOptions
          onChange={onChangeAutoComplete}
          renderInput={(params: any) => <TextField {...params} variant="outlined" label={t('All Brands')} placeholder="" />}
        />
      ) : (
        <GeneralSelect disabled={disabled} working={working} label="Brand" optionAll={optionAll} data={data} inputProps={inputProps} {...styles} variant={variant} />
      )}
    </>
  );
};

export const SelectBrandProduct = DatabaseConnector(SelectBrandProductRaw)('ster_brand');
