import React from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import { StatesList } from './routes/StatesList';

export const States = () => {
  const match = useRouteMatch();
  return (
    <Switch>
      <Route path={`${match.path}`}>
        <StatesList />
      </Route>
    </Switch>
  );
};
