import { LibbyFetchDAO } from './LibbyFetchDAO';

export class CitiesDAO extends LibbyFetchDAO {
  constructor() {
    super('ster_city', 'city_id');
  }

  getAll() {
    return this.query().limit(0, 10000).run();
  }

  getAllByOrderId(id: number) {
    return this.query().equals('city.city_id', id).run();
  }

  getAllByCityName(id: number) {
    return this.query().includes('name', id).run();
  }

  getByCityName(name: string) {
    return this.query().includes('name', name).run();
  }

  getAllByStateName(state: String, name: string) {
    return this.query()
      .orderBy('name', 'asc')
      .equals('state.name', state)
      .or()
      .equals('state.state_id', state)
      .and()
      .includes('name', name || '')
      .run();
  }
}
