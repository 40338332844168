import { LibbyFetchDAO } from '../LibbyFetchDAO';

export class OrderB2BCommentDAO extends LibbyFetchDAO {
  constructor() {
    super('ster_order_comment', 'order_b2b_comment_id');
  }

  getAllByOrderId(id: number) {
    return this.query().equals('order.order_id', id).orderBy('created_at', 'desc').run();
  }
}
