import { FilterBarSelectionReportingMl } from '../../../../routes/ReportingMl/type';
import { LibbyFetchDAO } from '../LibbyFetchDAO';

export class OrderReportMlDAO extends LibbyFetchDAO {
  constructor() {
    super('ster_reporting_ml', 'order_id');
  }

  getAll() {
    return this.query().limit(0, 20000).run();
  }

  async getByDateOrderReportingMl(filter: FilterBarSelectionReportingMl, marketplace_id: string) {
    let ls = this.query();
    // FIXME QUERY BUILDER HAVE TO DECIDE IF HAVE TO AUTOMATICALLY INSERT AN AND
    let needsAnd: boolean = false;
    if (filter?.startDate || filter?.endDate) {
      const startDate = filter?.startDate?.[0].value as string;
      const endDate = filter?.endDate?.[0].value as string;
      const fromUtc = filter?.startDate && startDate;
      const toUtc = filter?.endDate && endDate;
      if (needsAnd) ls = ls.and();

      ls = ls.groupStart().higherOrEquals('created_at', fromUtc).lowerOrEquals('created_at', toUtc).groupEnd();
      needsAnd = true;
    }
    if (marketplace_id) {
      if (needsAnd) ls = ls.and();

      ls = ls.equals('marketplace_id', marketplace_id);

      needsAnd = true;
    }

    return ls.run();
  }
}
