import React, { useCallback, useMemo } from 'react';
import { DatabaseConnector } from '@phinxlab/libby-rest-web';
import { TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { GeneralSelect } from '../../components/GeneralSelect';
import { AnyObject, LibbyObject } from '../../types/types';
import { useLibbyCall } from '../../hooks';
import { useTranslation } from '../../services/translation';

type TextFieldVariant = 'filled' | 'standard' | 'outlined';
type SelectMarketPlaceTypeProps = {
  inputProps: AnyObject;
  optionAll?: boolean;
  [k: string]: any;
  libby: LibbyObject;
  sort?: boolean | 'asc' | 'desc';
  disabled?: boolean;
  multiple?: boolean;
  variant?: TextFieldVariant;
  autocomplete?: boolean;
};

export const SelectProductRaw = ({ disabled, libby, inputProps = {}, autocomplete = false, optionAll = false, sort, variant = 'standard', ...styles }: SelectMarketPlaceTypeProps) => {
  const { t } = useTranslation();
  const { data: productsData, working = true } = useLibbyCall(libby, {
    daoName: 'ster_product',
    methodName: 'getAll',
    params: []
  });
  const data = useMemo(
    () =>
      productsData
        ? productsData.map(({ product_id, sku }: { product_id: string | number; sku: string }) => ({
            id: product_id,
            value: sku || ''
          }))
        : [],
    [productsData]
  );

  /*   const onChangeAutoCompleteMultiple = useCallback(
    (event, newValue: DataGenerateSelectType[]) => {
      inputProps.onChange(
        newValue.map((value: DataGenerateSelectType) => ({
          value: value.id,
          path: ''
        }))
      );
    },
    [inputProps]
  ); */

  const onChangeAutoComplete = useCallback(
    (event, newValue) => {
      inputProps.onChange(newValue ? { value: newValue.id, path: '' } : null);
    },
    [inputProps]
  );

  return (
    <>
      {autocomplete ? (
        <Autocomplete
          disabled={disabled}
          style={{ display: 'inline-block', width: '100%' }}
          multiple={false}
          id="tags-outlined"
          options={data}
          getOptionLabel={(option: { id: string; value: string }) => option.value}
          filterSelectedOptions
          onChange={onChangeAutoComplete}
          renderInput={(params: any) => <TextField {...params} variant="outlined" label={t('All Products')} placeholder="" />}
        />
      ) : (
        <GeneralSelect disabled={disabled} working={working} label="Product" optionAll={optionAll} data={data} inputProps={inputProps} {...styles} variant={variant} />
      )}
    </>
  );
};

export const SelectProduct = DatabaseConnector(SelectProductRaw)('ster_product');
