import React from 'react';
import CancelIcon from '@material-ui/icons/Cancel';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { useStyles } from './ModalTitleStyle';
import { useTranslation } from '../../../services/translation';

interface ModalTitleProps {
  title: string;
  error?: boolean;
}

export const ModalTitle = ({ title, error = false }: ModalTitleProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <div className={classes.modalTitle}>
      <span className={classes.titleIcon}>
        <>{error ? <CancelIcon className={classes.iconStyleError} /> : <CheckCircleIcon className={classes.iconStyleSuccess} />}</>
      </span>
      <span className={classes.titleText}>{t(title)}</span>
    </div>
  );
};
