import { useCallback, useState } from 'react';
import { useSnackbar } from 'notistack';
import confirmDialog from '../../../../services/confirmDialog';
import { useTranslation } from '../../../../services/translation';
import { ORDER_DOCUMENT_SOURCE, ORDER_DOCUMENT_TYPE, ORDER_STATE, UPDATE_ORDER_CONFIRMED_CLIENT } from '../../../../const';
import { AnyObject, userInfoType } from '../../../../types';
import { useCreateOdooOrder, useGlobalContext } from '../../../../hooks';
import { API_URL } from '../../../../config';
import CustomModal from '../../../../services/customFormDialog';
import { ConfirmBudgetDialog } from '../../../Budget/components/ConfirmBudgetDialog';
import { getMargenPercentage, getOodoPrice } from '../../../Budget/routes/BudgetNewV2/utils';
import { PaymentMethodItem } from '../../../Budget/routes/BudgetNewV2/context/BudgetContext/types';
import { getPercentage } from '../../../Budget/routes/BudgetNewV2/utils/getPercentage';

interface UseOrderDetailDataReturn {
  onAcceptBudget: () => void;
  approveBudget: () => void;
  approving: boolean;
}

interface UseOrderDetailDataProps {
  data: AnyObject;
  recall: ((...newParams: any[]) => Promise<any>) | undefined;
  onStateChange: (stateId: number) => void;
  operationData: AnyObject;
}

const ConfirmBudgetModal = CustomModal(ConfirmBudgetDialog);

export const useBudget = ({ data, recall, onStateChange, operationData }: UseOrderDetailDataProps): UseOrderDetailDataReturn => {
  const { t } = useTranslation();
  const { createOrderInOdoo } = useCreateOdooOrder();
  const [approving, setApproving] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const {
    userInfo
  }: {
    userInfo: userInfoType;
  } = useGlobalContext();

  const getOdooPrices = async (products: any[], payment_method: PaymentMethodItem, tier: number, advanceSale: boolean) => {
    const odooPrices: any[] = [];
    console.log('useBudget:getOdooPrices', payment_method);
    for (const product of products) {
      const price = await getOodoPrice(product.sku, payment_method, tier, advanceSale);
      odooPrices.push({
        // @ts-ignore
        // discountPercentage: product.unit_price > price.price ? 0 : (100 * (price.price - product.unit_price)) / net_income,
        discountPercentage: Math.trunc(price?.price) !== Math.trunc(product.unit_price) ? getPercentage(price?.price, product.unit_price) : 0,
        discountPercentageMAX: getMargenPercentage(),
        ...product
      });
    }
    return odooPrices;
  };

  const onAcceptBudget = useCallback(async () => {
    const document = data.documents?.find(
      (a: any) => a.source.order_document_source_id?.toString() === ORDER_DOCUMENT_SOURCE.ERP.toString() && a.type.order_document_type_id?.toString() === ORDER_DOCUMENT_TYPE.PRESUPUESTO.toString()
    );
    const so = document?.documentNumber;
    const confirmedAction = await confirmDialog.show({
      title: t('Budget Confirmation'),
      content: t('Are you sure to confirm the budget?'),
      confirmText: `${t('Confirm')}!`,
      cancelText: t('No')
    });
    if (!confirmedAction) return;

    const body = {
      orders: [so],
      confirmed_client: true
    };

    try {
      const response = await fetch(`${API_URL}/${UPDATE_ORDER_CONFIRMED_CLIENT}`, {
        method: 'POST',
        body: JSON.stringify(body),
        headers: { 'Content-Type': 'application/json' }
      });
      const json = await response.json();

      if (json?.result?.status === 200) {
        enqueueSnackbar(t('Order has been confirmed successfully'), { variant: 'success' });
      } else {
        throw json.result.message;
      }

      onStateChange(ORDER_STATE.BUDGET);
    } catch (e: any) {
      enqueueSnackbar(e.toString(), { variant: 'error' });
    }
  }, [t, data, enqueueSnackbar, onStateChange]);

  /*
  const verifyProductWithOdoo = useCallback(async (sku: string, company: number, paymentMethodId: string) => {
    const body = {
      sku,
      lists: [168, 170, 171],
      payment_methods: [Number(paymentMethodId)],
      channel: 'offline'
    };
    let price: AnyObject = {};
    try {
      // setLoading(true);
      const response = await fetch(`${API_URL}/${GET_PRICE_LIST_B2B}`, {
        method: 'POST',
        body: JSON.stringify(body),
        headers: { 'Content-Type': 'application/json' }
      });
      const json = await response.json();
      price = json?.result?.message?.prices[0];
    } catch (e) {
      console.log('eee', e);
    }

    try {
      const locationStockBody = {
        locations_id: [LOCATION_ID_BY_COMPANY[company]],
        company_id: company,
        skus: [sku]
      };
      /!* eslint-disable-next-line no-await-in-loop *!/
      const response = await fetch(`${API_URL}/${UPDATE_ORDER_CONFIRMED_CLIENT}`, {
        method: 'POST',
        body: JSON.stringify(locationStockBody),
        headers: { 'Content-Type': 'application/json' }
      });
      /!* eslint-disable-next-line no-await-in-loop *!/
      const json = await response.json();
      price.stock = json?.result?.message?.stock[0]?.qty || 0;
    } catch (e) {
      console.log('eee', e);
    }

    return price;
  }, []);
*/

  const approveBudget = useCallback(async () => {
    try {
      setApproving(true);

      const confirmedClientBudget = (await ConfirmBudgetModal.show({
        title: t('Confirm Budget'),
        initialValuesForm: {
          confirmed: ''
        }
      })) as { confirmed: boolean };

      const hasStock = false;
      /* TODO: esto se buscaba anteriormente usando la funcion verifyProductWithOdoo */
      /* TODO: SIMON, SE DEBE PASAR AQUI TAMBIEN EL ODOO CURRENCY */
      console.log('useBudget:data.orderB2Bs[0]', data);
      // @ts-ignore
      const paymentMethod: PaymentMethodItem = {
        // @ts-ignore
        Currency: data.currency,
        // @ts-ignore
        paymentMethod: {
          PaymentMethod: {
            days: data.orderB2Bs[0].custom_term
          },
          OdooPaymentMethod: [
            {
              odoo_payment_method_id: data.orderB2Bs[0].payment_method_id
            }
          ]
        },
        days: data.orderB2Bs[0].custom_term,
        deliverDays: data.orderB2Bs[0].qty_delivery_days,
        id: data.orderB2Bs[0].payment_method_id,
        paymentDays: data.orderB2Bs[0].qty_deferred_payment,
        plazo: ''
      };
      const discount = await getOdooPrices(data.items, paymentMethod, data.orderB2Bs[0].tier_id, data.orderB2Bs[0].advanceSale);

      data.items = discount;

      const so = await createOrderInOdoo({
        ...data,
        ...discount,
        approved_id: userInfo?.id,
        confirmedByClient: confirmedClientBudget.confirmed,
        operation: { operation_id: operationData.operation_id.toString(), Order_id: data.order_id },
        hasStock
      });
      enqueueSnackbar(t('Order has been approved successfully'), { variant: 'success' });
      enqueueSnackbar(t(`${so}`), { variant: 'success' });
      if (recall) {
        recall();
      }
      /* } */
    } catch (e: any) {
      enqueueSnackbar(e.toString(), { variant: 'error' });
    } finally {
      setApproving(false);
    }
  }, [data, enqueueSnackbar, t, createOrderInOdoo, recall, userInfo, operationData]);

  return { onAcceptBudget, approveBudget, approving };
};
