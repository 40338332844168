import { Moment } from 'moment';
import { AnyObject } from '../../../../../types/types';
import { endDateFormatUtc, startDateFormatUtc } from '../../../../../utils';

type MakeFilterProps = {
  startDate: Moment | null;
  endDate: Moment | null;
  status?: string;
  ownerId?: string;
  marketplace?: string;
  inboxOrderStateId?: string;
  reference?: string;
};

export const makeFilter = ({ inboxOrderStateId, startDate, endDate, status, ownerId, marketplace, reference }: MakeFilterProps) => {
  const filter: AnyObject = {};

  if (inboxOrderStateId) {
    filter.inboxOrderStateId = [
      {
        path: 'inbox_order_id',
        value: parseInt(inboxOrderStateId, 10)
      }
    ];
  }
  if (startDate) {
    filter.startDate = [
      {
        path: 'updated_at',
        value: startDateFormatUtc(startDate),
        method: 'higherOrEquals'
      }
    ];
  }
  if (endDate) {
    filter.endDate = [
      {
        path: 'updated_at',
        value: endDateFormatUtc(endDate),
        method: 'lowerOrEquals'
      }
    ];
  }

  if (status && status !== '0') {
    filter.status = [
      {
        path: 'state.inbox_order_state_id',
        value: status
      }
    ];
  }

  if (marketplace && marketplace !== '0') {
    filter.marketplace = [
      {
        path: 'marketplace.marketplace_id',
        value: marketplace
      }
    ];
  }

  if (ownerId) {
    filter.ownerId = [
      {
        path: 'owner_id',
        value: ownerId,
        method: 'includes'
      }
    ];
  }
  if (reference) {
    filter.reference = [
      {
        path: 'reference',
        value: reference,
        method: 'includes'
      }
    ];
  }

  return filter;
};
