import React, { useState, useEffect } from 'react';
import { AnyObject } from '../../types/types';
import { isSearchIncludedInValue } from '../../utils';
import { LoadingTable } from '../LoadingTable';
import { SearchDialogContent } from '../SearchDialog/SearchDialogContent';

interface SearchDialogSearchDataType {
  label: string;
  working: boolean | undefined;
  renderItem: React.ElementType;
  dataProp: AnyObject;
  properties: Array<string>;
}

export const SearchDialogSearchData = ({ label = 'Search', working = true, renderItem: RenderItem = () => <></>, dataProp = [], properties }: SearchDialogSearchDataType) => {
  const [dataFound, setDataFound] = useState(dataProp);
  const [search, setsearch] = useState('');

  useEffect(() => {
    if (dataProp.length !== dataFound.length && dataFound.length === 0 && search.length === 0) {
      setDataFound(dataProp);
    }
  }, [dataProp, dataFound, search]);

  const onChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    event.preventDefault();
    const searchEvent = event.target.value;
    setsearch(event.target.value);
    const filteredItems = dataProp.filter((values: AnyObject) => isSearchIncludedInValue(searchEvent, values, properties));
    setDataFound(filteredItems);
  };

  const dialog = (
    <>
      {!working ? (
        <SearchDialogContent title="" label={label} onChange={onChange}>
          <RenderItem data={dataFound} />
        </SearchDialogContent>
      ) : (
        <LoadingTable />
      )}
    </>
  );

  return dialog;
};
