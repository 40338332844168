import React from 'react';
import { DatabaseConnector } from '@phinxlab/libby-rest-web';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import { PickControlList, PickControlDetail } from './routes';
import { useBreadcrumbs } from '../../hooks';
import { LibbyObject } from '../../types';

const PickControlRaw = ({ libby }: LibbyObject) => {
  const { path } = useRouteMatch();
  const title = useBreadcrumbs('Pick Control');

  return (
    <Switch>
      <Route exact path={`${path}`}>
        <PickControlList libby={libby} title={title} />
      </Route>
      <Route path={`${path}/detail/:id/:type?`}>
        <PickControlDetail libby={libby} title={title} />
      </Route>
    </Switch>
  );
};

export const PickControl = DatabaseConnector(PickControlRaw)('ster_dispatch_collect_details', 'ster_collect_item_product', 'ster_order_document', 'ster_order_so');
