import { Order_reporting_andreani_tracking } from '../../../interfaces/business/orders';
import { makeBusinessEntity } from '../../../lib/libby/generator/makeBusinessEntity';

const DAO_NAME = 'ster_order_reporting_adreani_tracking';

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch }
} = makeBusinessEntity<Order_reporting_andreani_tracking>(DAO_NAME);

// hooks
export const useOrderReportingAndreaniTrackingFetchById = fetchById;
export const useOrderReportingAndreaniTrackingLibbyCall = libbyCall;
export const useOrderReportingAndreaniTrackingDAO = libbyDAO;
export const useOrderReportingAndreaniTrackingLibbyFetch = libbyFetch;
