import React, { useEffect, useState } from 'react';
import SearchIcon from '@material-ui/icons/Search';
import { Grid, TextField } from '@material-ui/core';
import { DatePicker } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import moment from 'moment';
import { AnyObject } from '../../../types';
import { useTranslation } from '../../../services/translation';
import { makeFilter } from '../utils';
import { FilterBar } from '../../../components/FilterBar';
import { gridBreakpoints, gridBreakpointsFields, STATES_AFTER_OUT_FOR_DELIVERY } from '../../../const';
import useDebounce from '../../../hooks/useDebounce';
import { SelectOrderState } from '../../components/SelectOrderState';
import { SelectMarketPlace } from '../../components/SelectMarketPlace';

type SearchFiltersProps = {
  onFilterChange: (makeFilter: object) => void;
  filter: AnyObject;
};

const SearchFiltersRaw = ({ onFilterChange, filter }: SearchFiltersProps) => {
  const { t } = useTranslation();

  const [search, setSearch] = useState<string>(filter.search && filter.search.length > 0 ? filter.search[0].value : '');
  const [sku, setSku] = useState<string>(filter.importId && filter.importId.length > 0 ? filter.importId[0].value : '');
  const [marketplace, setMarketplace] = useState<string>(filter.importId && filter.importId.length > 0 ? filter.importId[0].value : '0');
  const [month, setMonth] = useState<string>(filter.importId && filter.importId.length > 0 ? filter.importId[0].value : moment().subtract(1, 'months').date(1).format('YYYY-MM-DD HH:mm'));
  const [state, setState] = useState<string>(filter.importId && filter.importId.length > 0 ? filter.importId[0].value : '0');
  const searchDebounced = useDebounce(search, 500);

  useEffect(() => {
    onFilterChange(
      makeFilter({
        search: searchDebounced,
        marketplace,
        state,
        month,
        sku
      })
    );
  }, [onFilterChange, state, marketplace, searchDebounced, month, sku]);
  const handleReset = () => {
    setState('');
    setSearch('');
    setMarketplace('');
    setSku('');
    setMonth(moment().subtract(1, 'months').date(1).format('YYYY-MM-DD HH:mm'));
    onFilterChange(makeFilter({}));
  };
  const handleChangeState = (event: React.ChangeEvent<HTMLInputElement>) => {
    setState(event.target.value);
  };
  const handleChangeMarketplace = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMarketplace(event.target.value);
  };
  const handleChangeSku = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSku(event.target.value);
  };

  return (
    <FilterBar
      defaultFilter={
        <Grid item {...gridBreakpoints}>
          <TextField
            id="standard-search"
            placeholder={t('Search')}
            type="search"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            fullWidth
            InputLabelProps={{
              shrink: true
            }}
            InputProps={{
              endAdornment: <SearchIcon />
            }}
          />
        </Grid>
      }
      handleReset={handleReset}
    >
      <Grid item {...gridBreakpointsFields}>
        <DatePicker
          value={month}
          onChange={(date: MaterialUiPickersDate) => setMonth(moment(date).format())}
          views={['year', 'month']}
          fullWidth
          id="month"
          label="Month"
          InputLabelProps={{
            shrink: true
          }}
        />
      </Grid>
      <Grid item {...gridBreakpointsFields}>
        <SelectOrderState optionAll sort="asc" title="State" inputProps={{ value: state, onChange: handleChangeState }} l listStateInit={STATES_AFTER_OUT_FOR_DELIVERY} />
      </Grid>
      <Grid item {...gridBreakpointsFields}>
        <SelectMarketPlace optionAll sort="asc" title="State" inputProps={{ value: marketplace, onChange: handleChangeMarketplace }} />
      </Grid>
      <Grid item {...gridBreakpointsFields}>
        <TextField
          id="sku"
          label={t('Sku')}
          type="search"
          value={sku}
          onChange={handleChangeSku}
          fullWidth
          InputLabelProps={{
            shrink: true
          }}
        />
      </Grid>
    </FilterBar>
  );
};

export const SearchFilters = React.memo(SearchFiltersRaw);
