import React, { useMemo } from 'react';
import { useTranslation } from '../../../../../services/translation';
import { CollectMobile } from '../../CollectList/components/CollectMobile';

type CollectAddMobileProps = {
  row: any;
};

export const CollectAddMobile = ({ row }: CollectAddMobileProps) => {
  const { t } = useTranslation();

  const data = useMemo(
    () =>
      row
        ? [
            [
              {
                title: row.buyer
              }
            ],
            [
              {
                title: `${row.order_id} ${' '} ${row.so_number}`,
                titleSize: true,
                colorType: true
              },
              {
                title: `${row.date_change}`,
                titleSize: true,
                xs: 4,
                align: 'right',
                justify: 'flex-end'
              }
            ],
            [
              {
                title: `$${row.total} | ${row.number_items} ${t('items')}`
              }
            ]
          ]
        : [],
    [row, t]
  );

  return <CollectMobile datas={data} select={row.select_order_id_state} />;
};
