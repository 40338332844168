import React, { KeyboardEvent, MouseEventHandler, useCallback, useEffect, useRef, useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import { Box, Button, ButtonGroup, Grid, IconButton, TextField } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import { ThemeProvider, makeStyles } from '@material-ui/styles';
import DialogContentText from '@material-ui/core/DialogContentText';
import QrReader from 'react-qr-reader';
import { useTranslation } from '../../services/translation';
import { BarCodeScanner } from './BarCodeScanner';

const useStyles = makeStyles(() => ({
  modalTitle: {
    '& h2.MuiTypography-h6': {
      textTransform: 'uppercase',
      fontSize: '16px !important',
      fontWeight: 600
    }
  },
  modalContent: {
    fontSize: 16
  },
  buttonText: {
    '& button': {
      fontSize: 16
    }
  },
  textField: {
    marginTop: '5px',
    width: '100%'
  },
  root: {
    '& .MuiPaper-root': {
      borderRadius: '8px'
    }
  }
}));

type ConfirmDialogProps = {
  title: React.ReactNode;
  open: boolean;
  onCancel: MouseEventHandler;
  onConfirm: any;
  customTheme: { [k: string]: any } | null;
  typeScanner: string;
  serialNumberInput: boolean;
};

const dummyFunc = () => {};

export const ScannerDialog = ({ title, open = false, onCancel = dummyFunc, onConfirm = dummyFunc, customTheme = null, typeScanner, serialNumberInput = false }: ConfirmDialogProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [barCodeScanner, setBarCodeScanner] = useState(true);
  const productInput = useRef<HTMLInputElement>(null);

  const handleScanQR = async (scanData: any) => {
    if (scanData && scanData !== '') {
      onConfirm(scanData);
    }
  };

  useEffect(() => {
    if (typeScanner && typeScanner !== 'product') setBarCodeScanner(false);
  }, [typeScanner]);

  const validateButton = (event: KeyboardEvent<HTMLInputElement>) => event.key === 'Enter' && event.currentTarget.value.length;

  const onPickUpProduct = useCallback((event: KeyboardEvent<HTMLInputElement>) => validateButton(event) && onConfirm(event.currentTarget.value), [onConfirm]);

  const dialog = (
    <Dialog open={open} onClose={onCancel} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" className={classes.root}>
      <DialogTitle className={classes.modalTitle} id="alert-dialog-title">
        <Grid container justify="space-between" alignItems="center">
          {title}
          {onCancel && (
            <>
              <IconButton aria-label="close" onClick={onCancel}>
                <CloseIcon />
              </IconButton>
            </>
          )}
        </Grid>
      </DialogTitle>
      <DialogContent>
        <DialogContentText className={classes.modalContent} id="alert-dialog-description">
          {!serialNumberInput && (
            <Box display="flex" justifyContent="center" mb={1}>
              <ButtonGroup variant="contained">
                <Button color={barCodeScanner ? 'primary' : 'default'} onClick={() => setBarCodeScanner(true)}>
                  {t('Bar code')}
                </Button>
                <Button color={!barCodeScanner ? 'primary' : 'default'} onClick={() => setBarCodeScanner(false)}>
                  {t('QR Code')}
                </Button>
              </ButtonGroup>
            </Box>
          )}
          {!serialNumberInput ? barCodeScanner ? <BarCodeScanner setCode={onConfirm} /> : <QrReader onError={(err: any) => console.error(err)} onScan={handleScanQR} style={{ width: '100%' }} /> : null}
          {serialNumberInput && (
            <TextField
              className={classes.textField}
              variant="standard"
              // size="medium"
              InputProps={{
                inputRef: productInput,
                onKeyDown: (event: React.KeyboardEvent<HTMLInputElement>) => {
                  onPickUpProduct(event);
                }
              }}
            />
          )}
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
  return !customTheme ? dialog : <ThemeProvider theme={customTheme}>{dialog}</ThemeProvider>;
};
