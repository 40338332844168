import { Order_source_type } from '../interfaces/business/orders/Order_source_type';
import { makeBusinessEntity, PartialBusinessEntityOptions } from '../lib/libby/generator/makeBusinessEntity';

import { businessOptions } from './businessOptions';

const DAO_NAME = 'ster_order_source_type';

const defaultOptions: PartialBusinessEntityOptions<any> = {
  hoc: {
    withEntityAutocomplete: {
      optionLabelKey: 'name'
    },
    withAccordionSelector: {
      idKey: 'order_source_type_id',
      labelKey: 'name'
    },
    withSelectedChips: {
      idKey: 'order_source_type_id',
      labelKey: 'name'
    }
  }
};

const optionsToUse = !businessOptions.OrderSourceType
  ? defaultOptions
  : {
      ...defaultOptions,
      ...businessOptions.OrderSourceType,
      hoc: { ...defaultOptions.hoc, ...businessOptions.OrderSourceType.hoc }
    };

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch },
  hoc: { entityAutocomplete, renderPropCall, renderPropFetchById, simpleSelect, accordionSelector, selectedChips }
} = makeBusinessEntity<Order_source_type>(DAO_NAME, optionsToUse);

// hooks
export const useOrderSourceTypeFetchById = fetchById;
export const useOrderSourceTypeLibbyCall = libbyCall;
export const useOrderSourceTypeDAO = libbyDAO;
export const useOrderSourceTypeLibbyFetch = libbyFetch;

// components/hoc
export const OrderSourceTypeAutocomplete = entityAutocomplete;
export const OrderSourceTypeCall = renderPropCall;
export const OrderSourceTypeById = renderPropFetchById;
export const OrderSourceTypeSelect = simpleSelect;
export const OrderSourceTypeAccordionSelector = accordionSelector;
export const OrderSourceTypeSelectedChips = selectedChips;
