import { LibbyFetchDAO } from './LibbyFetchDAO';

export class InboxOrdersDAO extends LibbyFetchDAO {
  constructor() {
    super('ster_inbox_order', 'inbox_order_id');
  }

  getAll() {
    return this.query('list_inbox_order').limit(0, 10000).run();
  }
}
