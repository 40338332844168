import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Typography } from '@material-ui/core';
import { useTranslation } from '../../../../../../../../../services/translation';
import { useStyles } from '../../../../../const';
import { IBudgetClient } from '../Types';

interface IModalMissingTier {
  open: boolean;
  client: IBudgetClient;
  onClose: () => void;
}

export const ModalMissingTier = ({ open = false, client, onClose }: IModalMissingTier) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Dialog open={open} aria-labelledby="form-dialog-title" fullWidth maxWidth="md">
      <DialogTitle id="alert-dialog-title">
        <Typography variant="h1" className={classes.title}>{`${t('Missing Tier')}`}</Typography>
      </DialogTitle>
      <DialogContent>
        <Grid container xs={12} style={{ marginLeft: '10px', marginBottom: '10px', marginTop: '10px', justifyContent: 'flex-start' }}>
          <Grid item xs={12} direction="row" style={{ display: 'flex', justifyContent: 'flex-start' }}>
            <Typography variant="body1" style={{ marginLeft: '5px' }}>
              El cliente {client?.first_name} no posee configurado un tier. Por favor, configurar dicha información para avanzar con la carga.
            </Typography>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button color="secondary" onClick={() => onClose()} autoFocus>
          {`${t('Accept')}`}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
