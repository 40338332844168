import { useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import { useReportingDashboardLibbyCall } from '../../../../../business/orders/order/ReportingDashboard';
import { ReportingTypes } from '../types';
import { useTranslation } from '../../../../../services/translation';
import { AnyObject, RowsType } from '../../../../../types';
import { format } from '../../../../../util';

export const useReportingDashboardLogic = () => {
  const { t } = useTranslation();

  const [date, setDate] = useState(moment().format('YYYY-MM-DD'));

  const { data, working, recall } = useReportingDashboardLibbyCall({ methodName: 'getByDate', params: [date] });

  const screenData: ReportingTypes = useMemo(() => {
    const dataObj = data as ReportingTypes;
    return dataObj;
  }, [data]);

  const onFilterChange = (newDate: any) => {
    setDate(moment(newDate._d).format('YYYY-MM-DD'));
  };

  const onRefresh = () => {
    setDate(moment().format('YYYY-MM-DD'));
  };

  useEffect(() => {
    if (date) {
      recall(date);
    }
  }, [date, recall]);

  const rowsProductsSales: Array<RowsType> = useMemo(() => {
    return screenData?.sales?.products?.values?.map((item: AnyObject) => ({ id: item.name, amount: format(item.amount, 'ARS', t), name: item.name, ranking: item.ranking, quantity: item.quantity }));
  }, [screenData, t]);

  const rowsCustomersSales: Array<RowsType> = useMemo(() => {
    return screenData?.sales?.customers
      ?.sort((a, b) => b.amount_month - a.amount_month)
      .map((item: AnyObject) => ({ id: item.name, amount_month: format(item.amount_month, 'ARS', t), name: item.name, hostoric_amount: format(item.hostoric_amount, 'ARS', t) }));
  }, [screenData, t]);

  const rowsCategoryStock: Array<RowsType> = useMemo(() => {
    return screenData?.stock?.category?.values?.sort((a, b) => b.value - a.value).map((item: AnyObject) => ({ id: item.name, value: format(item.value, 'ARS', t), name: item.name }));
  }, [screenData, t]);

  return {
    working,
    recall,
    date,
    setDate,
    screenData,
    onFilterChange,
    onRefresh,
    rowsProductsSales,
    rowsCustomersSales,
    rowsCategoryStock
  };
};
