import { format } from '../../util';
export const chargebackCityColumns = [
  {
    id: 'city_id',
    label: 'City ID',
    noSort: true,
    style: {
      whiteSpace: 'nowrap',
      minWidth: '60px'
    }
  },
  {
    id: 'name',
    label: 'Name',
    noSort: true,
    style: {
      whiteSpace: 'nowrap',
      minWidth: '100px'
    }
  },
  {
    id: 'chargeback_amount_positive',
    label: 'Positive Chargeback Amount',
    style: {
      whiteSpace: 'nowrap',
      width: '50px'
    },
    render: (value: any, item: any, tr: (str: string) => string) => {
      return format(value.chargeback_amount_positive, 'ARS', tr);
    }
  },
  {
    id: 'chargeback_amount_negative',
    label: 'Negative Chargeback Amount',
    style: {
      whiteSpace: 'nowrap',
      width: '50px'
    },
    render: (value: any, item: any, tr: (str: string) => string) => {
      return format(value.chargeback_amount_negative, 'ARS', tr);
    }
  },
  {
    id: 'chargeback_amount_total',
    label: 'Total amount',
    style: {
      whiteSpace: 'nowrap',
      width: '50px'
    },
    render: (value: any, item: any, tr: (str: string) => string) => {
      return format(value.chargeback_amount_total, 'ARS', tr);
    }
  },
  {
    id: 'quantity_positive',
    label: 'Total Positive Chargeback',
    style: {
      whiteSpace: 'nowrap',
      width: '25px'
    }
  },
  {
    id: 'quantity_negative',
    label: 'Total Negative Chargeback',
    style: {
      whiteSpace: 'nowrap',
      minWidth: '25pxx'
    }
  },
  {
    id: 'quantity_total',
    label: 'Total Orders',
    style: {
      whiteSpace: 'nowrap',
      width: '25px'
    }
  }
];

export const chargebackStreetColumns = [
  {
    id: 'address',
    label: 'Address',
    noSort: true,
    style: {
      whiteSpace: 'nowrap',
      minWidth: '100px'
    }
  },
  {
    id: 'name',
    label: 'City',
    noSort: true,
    style: {
      whiteSpace: 'nowrap',
      minWidth: '100px'
    }
  },
  {
    id: 'chargeback_amount_positive',
    label: 'Positive Chargeback Amount',
    style: {
      whiteSpace: 'nowrap',
      width: '50px'
    },
    render: (value: any, item: any, tr: (str: string) => string) => {
      return format(value.chargeback_amount_positive, 'ARS', tr);
    }
  },
  {
    id: 'chargeback_amount_negative',
    label: 'Negative Chargeback Amount',
    style: {
      whiteSpace: 'nowrap',
      width: '50px'
    },
    render: (value: any, item: any, tr: (str: string) => string) => {
      return format(value.chargeback_amount_negative, 'ARS', tr);
    }
  },
  {
    id: 'chargeback_amount_total',
    label: 'Total amount',
    style: {
      whiteSpace: 'nowrap',
      width: '50px'
    },
    render: (value: any, item: any, tr: (str: string) => string) => {
      return format(value.chargeback_amount_total, 'ARS', tr);
    }
  },
  {
    id: 'quantity_positive',
    label: 'Total Positive Chargeback',
    style: {
      whiteSpace: 'nowrap',
      width: '25px'
    }
  },
  {
    id: 'quantity_negative',
    label: 'Total Negative Chargeback',
    style: {
      whiteSpace: 'nowrap',
      width: '25px'
    }
  },
  {
    id: 'quantity_total',
    label: 'Total Orders',
    style: {
      whiteSpace: 'nowrap',
      width: '25px'
    }
  }
];

export const chargebackProductColumns = [
  {
    id: 'product_id',
    label: 'Product ID',
    noSort: true,
    style: {
      whiteSpace: 'nowrap',
      width: '50px'
    }
  },
  {
    id: 'sku',
    label: 'SKU',
    noSort: true,
    style: {
      whiteSpace: 'nowrap',
      minWidth: '100px'
    }
  },
  {
    id: 'chargeback_amount_positive',
    label: 'Positive Chargeback Amount',
    style: {
      whiteSpace: 'nowrap',
      width: '50px'
    },
    render: (value: any, item: any, tr: (str: string) => string) => {
      return format(value.chargeback_amount_positive, 'ARS', tr);
    }
  },
  {
    id: 'chargeback_amount_negative',
    label: 'Negative Chargeback Amount',
    style: {
      whiteSpace: 'nowrap',
      width: '50px'
    },
    render: (value: any, item: any, tr: (str: string) => string) => {
      return format(value.chargeback_amount_negative, 'ARS', tr);
    }
  },
  {
    id: 'chargeback_amount_total',
    label: 'Total amount',
    style: {
      whiteSpace: 'nowrap',
      width: '50px'
    },
    render: (value: any, item: any, tr: (str: string) => string) => {
      return format(value.chargeback_amount_total, 'ARS', tr);
    }
  },
  {
    id: 'quantity_positive',
    label: 'Total Positive Chargeback',
    style: {
      whiteSpace: 'nowrap',
      width: '25px'
    }
  },
  {
    id: 'quantity_negative',
    label: 'Total Negative Chargeback',
    style: {
      whiteSpace: 'nowrap',
      minWidth: '25pxx'
    }
  },
  {
    id: 'quantity_total',
    label: 'Total Orders',
    style: {
      whiteSpace: 'nowrap',
      width: '25px'
    }
  }
];
