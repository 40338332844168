import { LibbyFetchDAO } from '../LibbyFetchDAO';

export class OrderReportingDashboardDAO extends LibbyFetchDAO {
  constructor() {
    super('ster_reporting_dashboard', 'order_id');
  }

  getAll() {
    return this.query().limit(0, 10000).run();
  }

  getByDate(date: string) {
    return this.query().equals('date', date).run();
  }
}
