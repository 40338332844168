import { makeValidator, ValidatorRule, ValidatorType } from '../../../utils';

export interface SterConfigCommentData {
  comment: string;
}

const validations: ValidatorRule[] = [
  { key: 'qty_deferred_payment', validators: [ValidatorType.REQUIRED, ValidatorType.NUMBER] },
  { key: 'qty_delivery_days', validators: [ValidatorType.REQUIRED, ValidatorType.NUMBER] }
];

export const validate = makeValidator(validations);
