import { LibbyFetchDAO } from '../LibbyFetchDAO';

export class MessageConfigDAO extends LibbyFetchDAO {
  constructor() {
    super('ster_message_config', 'message_config_id');
  }

  getAll() {
    return this.query().limit(0, 10000).run();
  }

  getConfigByID(id: string) {
    return this.query().equals('message_config_id', id).run();
  }
}
