import React from 'react';
import { ProductTable } from './components/ProductTable';
import { ScreenAligned } from '../../../../components/ScreenAligned/ScreenAligned';
import { AnyObject } from '../../../../types/types';
import { TitleBreadcrumbs } from '../../../../interfaces';
import { useHistory } from 'react-router';
import { Button, Box } from '@material-ui/core';
import { useTranslation } from 'services/translation';
import { UserProductPermissions } from '../../../../types/types';

type HeadCellsType = {
  id: string;
  label: string;
  translate?: boolean;
  render?: (row: any, item: any, t: (str: string) => string) => React.ReactNode;
  [k: string]: any;
};

type RowsType = {
  id: string;
  [k: string]: any;
};

export interface OrderListProps {
  pathUrl: string;
  setFilter: (makeFilter: object) => void;
  filter: AnyObject;
  working: boolean;
  rows: Array<RowsType>;
  columns: Array<HeadCellsType>;
  fetchMore: () => void;
  reFetch?: () => void;
  onSortChange?: (orderBy: string, direction: 'asc' | 'desc') => void;
  orderBy?: string;
  direction?: 'asc' | 'desc';
  title: TitleBreadcrumbs[];
  allButtonsActions?: React.ReactElement;
  onRowClick?: (row: RowsType) => void;
  canalOffLine?: boolean;
  resetFilter: () => void;
  userPermissions: UserProductPermissions;
}

export const ProductList = ({ setFilter, resetFilter, rows, columns, working, filter, fetchMore, reFetch, onSortChange, orderBy, direction, title, onRowClick, canalOffLine = false, pathUrl, userPermissions }: OrderListProps) => {
  const history = useHistory();
  const { t } = useTranslation();
  return (
    <ScreenAligned title={title}>
      {(userPermissions.full_permission || userPermissions.high_permission) && (
        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
          <Button variant="contained" color="primary" onClick={() => history.push(`${pathUrl}/create`)}>
            {t('New Product')}
          </Button>
        </Box>
      )}
      <ProductTable
        canalOffLine={canalOffLine}
        columns={columns}
        rows={rows}
        working={working}
        filter={filter}
        setFilter={setFilter}
        fetchMore={fetchMore}
        onSortChange={onSortChange}
        orderBy={orderBy}
        direction={direction}
        onRowClick={onRowClick}
        reFetch={reFetch}
        resetFilter={resetFilter}
      />
    </ScreenAligned>
  );
};
