import { makeBusinessEntity } from '../../../lib/libby/generator/makeBusinessEntity';

interface GenerateOrderDocumentURL {
  fileURL: string;
}

const DAO_NAME = 'ster_generate_order_document_url';

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch }
} = makeBusinessEntity<GenerateOrderDocumentURL>(DAO_NAME);

// hooks
export const useGenerateOrderDocumentURLFetchById = fetchById;
export const useGenerateOrderDocumentURLLibbyCall = libbyCall;
export const useGenerateOrderDocumentURLDAO = libbyDAO;
export const useGenerateOrderDocumentURLLibbyFetch = libbyFetch;
