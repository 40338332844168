import { Button, Grid } from '@material-ui/core';
import React, { useCallback } from 'react';
import { SnackbarProvider, useSnackbar } from 'notistack';
import { ScreenAligned } from '../../../../components/ScreenAligned/ScreenAligned';
import { useTranslation } from '../../../../services/translation';
import InfoTable from '../../../components/InfoTable';
import LoadingData from '../../../components/LoadingData';
import customModal from '../../../../services/customFormDialog';
import { SearchFilters, NewLiquidationDialog } from './components';
import { validate } from '../../utils';
import { LibbyObject, RowsType } from '../../../../types';
import { TitleBreadcrumbs } from '../../../../interfaces';

interface ListProps {
  rows: RowsType[];
  columns: any;
  libby: LibbyObject;
  working: boolean;
  fetchMore: () => void;
  reFetch: () => void;
  filter: any;
  setFilter: any;
  resetFilter: () => void;
  title: TitleBreadcrumbs[];
}

const LiquidationDialog = customModal(NewLiquidationDialog);

export const LiquidationsList = ({ columns, filter, resetFilter, setFilter, libby, rows, working, reFetch, fetchMore, title }: ListProps) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const onNewLiquidation = useCallback(async () => {
    try {
      const formInitialValues = {
        marketplace: '',
        startDate: null,
        endDate: null,
        year: '',
        reference: '',
        cancellations: 0,
        penalties: 0,
        refund: 0
      };
      const confirm: any = await LiquidationDialog.show({
        title: t('New Liquidation'),
        confirmText: t('Process'),
        cancelText: t('Cancel'),
        formInitialValues,
        validate,
        libby
      });
      if (confirm) {
        reFetch();
        enqueueSnackbar(`${t('Orders processed:')} ${confirm.ordersProcessed}`, {
          variant: 'success'
        });
        enqueueSnackbar(`${t('Unprocessed Orders:')} ${confirm.unprocessedOrders}`, {
          variant: 'error'
        });
      }
    } catch (error) {
      console.log('error', error);
    }
  }, [t, libby, reFetch, enqueueSnackbar]);

  return (
    <SnackbarProvider>
      <ScreenAligned
        title={title}
        additionalTitle={
          <Button color="primary" type="submit" variant="contained" onClick={onNewLiquidation}>
            {t('Liquidation')}
          </Button>
        }
      >
        <SearchFilters onFilterChange={setFilter} filter={filter} resetFilter={resetFilter} />
        <Grid container direction="row" justify="center" alignItems="center">
          <InfoTable columns={columns} rows={rows} onBottomScroll={fetchMore} onSortChange={() => {}} direction="asc" rowIdKey="name" />
          <LoadingData label={`${t('Loaded liquidations')}`} working={working} cant={rows.length} />
        </Grid>
      </ScreenAligned>
    </SnackbarProvider>
  );
};
