import { Filter } from '../../../types';

type MakeFilterProps = {
  search?: string;
};

export const makeFilter = ({ search }: MakeFilterProps) => {
  const filter: Filter = {};
  if (search) {
    const searchArray = search.split(' ').reduce((accumulator: Filter, item, index) => {
      accumulator[index] = [
        {
          path: 'name',
          value: search,
          method: 'includes'
        },
        {
          path: 'email_title',
          value: item,
          method: 'includes'
        },
        {
          path: 'email_to',
          value: item,
          method: 'includes'
        }
      ];

      const numberSearch = parseInt(item, 10);
      if (numberSearch) {
        accumulator[index].push({
          path: 'collect_id',
          value: numberSearch
        });
        accumulator[index].push({
          path: 'cart.cart_id',
          value: numberSearch
        });
        accumulator[index].push({
          path: 'dispatch.dispatch_id',
          value: numberSearch
        });
      }
      return accumulator;
    }, {});
    filter.search = [...searchArray[0]];
  }

  return filter;
};
