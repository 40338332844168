import React, { useCallback } from 'react';
import { useField, useForm } from 'react-final-form-hooks';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { ThemeProvider } from '@material-ui/styles';
import moment from 'moment';
import { FormProps } from '../../../../../../types';
import { useTranslation } from '../../../../../../services/translation';
import { makeGetErrorAndHelperText, textFieldProps } from '../../../../../../utils';
import { ResetForm } from '../../../../../../components/ResetForm';
import { useModalFormStyles } from '../../../../../../theme';
import { DateCloseWitNonWorkingDay } from '../../../../../components/DateCloseWitNonWorkingDay';

export type CreateNonWorkingDayType = {
  description: string;
  day_date: Date;
};

export const AddNonWorkingDayForm = ({
  open = false,
  onCancel = () => {},
  onConfirm = () => {},
  title = 'Confirmation Dialog',
  cancelText = 'Cancel',
  confirmText = 'confirmText',
  customTheme = null,
  validate,
  formInitialValues = {}
}: FormProps) => {
  const { t } = useTranslation();
  const styles = useModalFormStyles();
  const getErrorAndHelperText = makeGetErrorAndHelperText(t);

  const onSubmit = useCallback(
    async ({ description, day_date }: CreateNonWorkingDayType) => {
      onConfirm({
        description,
        day_date
      });
    },
    [onConfirm]
  );

  const { form, handleSubmit, submitError } = useForm({
    initialValues: formInitialValues,
    onSubmit,
    validate
  });

  const description = useField('description', form);
  const day_date = useField('day_date', form);

  const dialog = (
    <Dialog open={open} onClose={onCancel} fullWidth aria-labelledby="form-dialog-title">
      <DialogContent className={styles.title}>
        <Typography variant="h3">{title}</Typography>
      </DialogContent>
      <form noValidate onSubmit={handleSubmit}>
        <DialogContent className={styles.content}>
          <DateCloseWitNonWorkingDay
            dayEnabled={formInitialValues.day_date}
            name="Day date"
            value={day_date.input.value ? moment(day_date.input.value).utc() : null}
            onChange={day_date.input.onChange}
            disableFuture={false}
            {...getErrorAndHelperText(day_date)}
          />
          <TextField {...textFieldProps(t('Description'))} {...description.input} {...getErrorAndHelperText(description)} variant="standard" onChange={description.input.onChange} type="text" />

          {submitError && (
            <Typography style={{ display: 'block' }} variant="overline" color="error">
              {t(submitError)}
            </Typography>
          )}
        </DialogContent>
        <DialogActions>
          <ResetForm form={form} open={open} />
          <Button
            onClick={() => {
              onCancel();
            }}
          >
            {cancelText}
          </Button>
          <Button color="primary" type="submit">
            {confirmText}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );

  return !customTheme ? dialog : <ThemeProvider theme={customTheme}>{dialog}</ThemeProvider>;
};
