import { useEffect, useState } from 'react';
import { useLibbyCall } from '../../../../../../../lib/libby';
import { ISelectedTransport } from '../types/ISelectedTransport';
import { ITransportCompany } from '../types';
import { BudgetInitialValues } from '../types/BudgetContextValue';

export const useTransport = (initialValues: BudgetInitialValues) => {
  const [selectedTransport, setSelectedTransport] = useState<ISelectedTransport>(initialValues.selectedTransport);
  const [transportationCompanyOptions, setTransportationCompanyOptions] = useState<[]>([]);
  const [selectedTransportCompany, setSelectedTransportCompany] = useState<ITransportCompany>(
    initialValues.selectedTransportCompany || {
      transportation_company_id: null,
      name: '',
      street: '',
      street_number: null,
      phone_number: '',
      floor: null,
      department: null,
      zip: null,
      city: null
    }
  );

  const {
    data: transportationCompany = [],
    working: fetchingTransportationCompany,
    recall: recallTransportation
  } = useLibbyCall({
    daoName: 'ster_transportation_company_search',
    methodName: 'getTransportationByName',
    params: []
  });

  useEffect(() => {
    if (!fetchingTransportationCompany) {
      // @ts-ignore
      setTransportationCompanyOptions(transportationCompany);
    }
  }, [fetchingTransportationCompany, transportationCompany]);

  return {
    selectedTransport,
    setSelectedTransport,
    transportationCompanyOptions,
    selectedTransportCompany,
    setSelectedTransportCompany,
    fetchingTransportationCompany,
    setTransportationCompanyOptions,
    recallTransportation
  };
};
