import React, { MouseEventHandler } from 'react';
import { Grid } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { MakeCell } from '../../../routes/components/MakeCell';

interface ActionTableDeleteProps {
  onClick: MouseEventHandler;
}

export const ActionTableDelete = ({ onClick }: ActionTableDeleteProps) => (
  <Grid container justify="center">
    <MakeCell label="" icon={DeleteIcon} onClick={onClick} />
  </Grid>
);
