import { LibbyFetchDAO } from './LibbyFetchDAO';

export class OrderTotalTimeDAO extends LibbyFetchDAO {
  constructor() {
    super('ster_order_total_time', 'id');
  }

  getAll() {
    return this.query().limit(0, 10000).run();
  }
}
