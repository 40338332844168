import { AnyObject } from '../types/types';

export const importationsStatus: AnyObject = {
  1: 'Pending',
  2: 'Pending Processing',
  3: 'Processing',
  4: 'Pending approbation',
  5: 'Accepted',
  6: 'Rejected',
  7: 'Failure'
};

export const IMPORTATION_STATUS_NAME = {
  PENDING: 1,
  PENDING_PROCESSING: 2,
  PROCESSING: 3,
  PENDING_APPROBATION: 4,
  ACCEPTED: 5,
  REJECTED: 6,
  FAILURE: 7
};
