import React from 'react';
import { DatePicker, MuiPickersUtilsProvider, DatePickerProps } from '@material-ui/pickers';
import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core/styles';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import { Grid, IconButton } from '@material-ui/core';
import moment, { Moment } from 'moment';
import MomentUtils from '@date-io/moment';
import ClearIcon from '@material-ui/icons/Clear';
import { useTranslation, useLanguage } from '../../services/translation';

const useStyles = makeStyles((theme: Theme) => ({
  dateBox: {
    position: 'relative'
  },
  calendarIcon: {
    position: 'absolute',
    top: 10,
    right: 5,
    color: theme.palette.grey[500]
  }
}));

export interface DateCloseType extends DatePickerProps {
  minValue?: Moment | null;
}

export const DateClose = ({ name, value, onChange, minValue, disableFuture = true, views, shouldDisableDate, ...inputProps }: DateCloseType) => {
  const { t } = useTranslation();
  const { getLanguage } = useLanguage();
  const classes = useStyles();
  const language = getLanguage();
  const xs = value ? 11 : 12;
  return (
    <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils} locale={language}>
      <Grid container justify="center" alignItems="center" direction="row">
        <Grid className={classes.dateBox} item xs={xs}>
          <DatePicker
            views={views || undefined}
            autoOk
            disableFuture={disableFuture}
            label={t(name ?? '')}
            value={value}
            onChange={onChange}
            minDate={minValue ? moment(minValue) : undefined}
            shouldDisableDate={shouldDisableDate}
            minDateMessage={t('Date should not be before minimal date')}
            fullWidth
            variant="inline"
            InputLabelProps={{
              shrink: true
            }}
            {...inputProps}
          />
          <CalendarTodayIcon className={classes.calendarIcon} />
        </Grid>
        {value && (
          <Grid item xs={1}>
            <IconButton edge="end" size="small" disabled={!value} onClick={() => onChange(null)}>
              <ClearIcon />
            </IconButton>
          </Grid>
        )}
      </Grid>
    </MuiPickersUtilsProvider>
  );
};
