import React, { useMemo, useState, useCallback } from 'react';

import { OpenInNew } from '@material-ui/icons';
import { IconButton } from '@material-ui/core';
import AssignmentIcon from '@material-ui/icons/Assignment';
import { useBreadcrumbs } from '../../../hooks';
import { useOrderReportingAndreaniTrackingLibbyFetch } from '../../../business/orders/order/ReportingAndreaniTracking';
import { RowsType } from '../../../utils/tables';
import { useTranslation } from '../../../services/translation';
import { makeFilter } from '../utils/makeFilter';
import { formatDate, openInNewTab } from '../../../utils';
import { MakeCell } from '../../components/MakeCell';

export const useReportingAndreaniTracking = () => {
  const title = useBreadcrumbs('Seguimiento Andreani');
  const { t } = useTranslation();

  const [filter, setFilter] = useState(
    makeFilter({
      startDate: null,
      endDate: null
    })
  );
  const [orderBy, setOrderBy] = useState('update_at');
  const [direction, setDirection] = useState<'asc' | 'desc'>('asc');

  const paramsFetch = useMemo(
    () => ({
      filter,
      orderBy,
      direction
    }),
    [filter, orderBy, direction]
  );

  const handleRequestSort = useCallback((newOrderBy: string, newDirection: 'asc' | 'desc') => {
    setOrderBy(newOrderBy);
    setDirection(newDirection);
  }, []);

  const { data, working, fetchMore } = useOrderReportingAndreaniTrackingLibbyFetch(paramsFetch);

  const rows: RowsType[] = useMemo(
    () =>
      data
        ? data.map((order: any) => ({
            ...order,
            shipment_state_name: t(order.shipment_state_name),
            order_state_name: t(order.order_state_name),
            update_at: formatDate(order.update_at, false),
            andreani_detail: (
              <IconButton
                aria-label="andreani detail"
                onClick={() => openInNewTab(`https://www.andreani.com/#!/informacionEnvio/${order.shipment_tracking}`)}
                style={{
                  marginLeft: 'auto',
                  marginRight: 'auto'
                }}
              >
                <OpenInNew />
              </IconButton>
            ),
            detail: (
              <MakeCell
                label=""
                icon={AssignmentIcon}
                path={`orders/detail/${order?.order_id}`}
                onClick={(e) => {
                  e.stopPropagation();
                }}
              />
            )
          }))
        : [],
    [data, t]
  );

  return {
    title,
    working,
    rows,
    fetchMore,
    orderBy,
    direction,
    handleRequestSort,
    filter,
    setFilter
  };
};
