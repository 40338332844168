import React, { ReactElement, useMemo } from 'react';
import { MessageConfigType, RowsMessageConfig } from '../../types/types';
import { RowsOptions } from '../../components/RowsOptions';
import { useTranslation } from '../../../../services/translation';

interface dataRowsType {
  id: string;
  options: ReactElement<any, any>;
  marketplace: string;
  state: string;
  order_document_type: string;
  template: string;
  send_to: string;
  send_to_client: boolean;
}

export const useRowsMessageConfigForm = ({ messageConfig, deleteMessageConfig, openMessageConfigEdit }: RowsMessageConfig) => {
  const { t } = useTranslation();

  const rows = useMemo(() => {
    return messageConfig.reduce((allDataRows: dataRowsType[], dataMessageConfig: MessageConfigType) => {
      const {
        message_config_id,
        marketplace: { name: nameMarketplace },
        state: { name: nameState },
        template: { name: nameTemplate },
        order_document_type,
        send_to,
        send_to_client
      } = dataMessageConfig;
      const copyAllDataRows = [...allDataRows];

      const nameDocument = order_document_type?.name;

      copyAllDataRows.push({
        options: <RowsOptions deleteMessageConfig={deleteMessageConfig} openMessageConfigEdit={openMessageConfigEdit} message_config={dataMessageConfig} />,
        id: message_config_id,
        marketplace: nameMarketplace,
        state: t(nameState),
        template: t(nameTemplate),
        order_document_type: nameDocument ? t(nameDocument) : 'N/A',
        send_to,
        send_to_client
      });

      return copyAllDataRows;
    }, []);
  }, [messageConfig, t, deleteMessageConfig, openMessageConfigEdit]);

  return { rows };
};
