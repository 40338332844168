import React from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Button, Grid, Link, Paper, Typography } from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import { useTranslation } from '../../../../../../services/translation';
import { AnyObject } from '../../../../../../types';
import { KpiComponent } from '../KpiComponent';
import { ProvidersTypes } from '../../types';

export const ProvidersPaper = ({ values, classes }: { values: ProvidersTypes; classes: AnyObject }) => {
  const { t } = useTranslation();

  const { url, ...kpi } = values;

  return (
    <Grid item xs={12} sm={12} md={12} lg={12}>
      <Paper className={classes.paper}>
        <Grid container xs={12} justify="space-between" alignItems="center">
          <Typography variant="h4" className={classes.title}>
            {t('Providers')}
          </Typography>
          {url && (
            <Button color="primary">
              <Link target="_blank" href={url}>
                {t('see more')}
              </Link>
            </Button>
          )}
        </Grid>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMore />} aria-controls="panel4bh-content" id="panel3">
            <Grid container xs={12}>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <KpiComponent name="Total" value={kpi.current + kpi.overdue} dollar />
              </Grid>
            </Grid>
          </AccordionSummary>
          <AccordionDetails>
            {kpi && (
              <Grid container xs={12}>
                {Object.entries(kpi).map(([key, value]: [string, string | number]) => (
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <KpiComponent name={key} value={value} dollar />
                  </Grid>
                ))}
              </Grid>
            )}
          </AccordionDetails>
        </Accordion>
      </Paper>
    </Grid>
  );
};
