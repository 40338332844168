import { useMemo, useCallback } from 'react';
import { ButtonDetailsType } from 'types/Button';
import { useTranslation } from 'services/translation';
import CustomModal from '../../../../../services/customFormDialog';
import { FeatureGroupForm as FeatureGroupFormModal } from '../components/FeatureGroupForm';
import { CopyFeaturesForm as CopyFeaturesFormModal } from '../components/CopyFeaturesForm';
import { featureGroupInitialValuesForm, validate } from '../models/featureGroup';
import { FeatureForm as FeatureFormModal } from '../components/FeatureForm';
import { featuresInitialValuesForm, validate as featureValidate } from '../models/features';
import { featuresCopyInitialValuesForm, validate as featureCopyValidate } from '../models/featuresCopy';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { MakeCell } from 'routes/components/MakeCell';
import Box from '@material-ui/core/Box';
import ConfirmDialog from 'components/ConfirmDialog';
import { ModalTitle } from 'routes/Collection/common';
import { AnyObject, LibbyObject } from 'types';
import { FeatureGroupsLibbyProps } from '../ProductDetail';
import { useSnackbar } from 'notistack';
import { FeaturesPreview } from '../components/FeaturesPreview';
import { FeaturesBulkLoadForm } from '../components/FeaturesBulkLoadForm';

const FeatureGroupForm = CustomModal(FeatureGroupFormModal);
const FeatureForm = CustomModal(FeatureFormModal);
const CopyFeaturesForm = CustomModal(CopyFeaturesFormModal);
const ConfirmModal = CustomModal(ConfirmDialog);
const FeaturesPreviewModal = CustomModal(FeaturesPreview);
const FeaturesBulkLoad = CustomModal(FeaturesBulkLoadForm);

type FeatureCopyFormResult = {
  message: string;
  total_groups: number;
  total_features: number;
};
type FeatureGroupFormResults = {
  id?: string | number;
  name: string | number;
  order: string | number;
  product_id: number;
};
type FeatureFormResults = {
  id?: string | number;
  feature_name: string | number;
  value: string | number;
  searchable: boolean;
  order: number;
};

type ProductsFeatureHookProps = { selectedGroup: AnyObject | null; featuresLibbyValues: FeatureGroupsLibbyProps; libby: LibbyObject; id: number | string };

const useProductFeaturesLogic = ({ selectedGroup, featuresLibbyValues, libby, id }: ProductsFeatureHookProps) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const handleOpenCopyFeaturesModal = useCallback(async () => {
    try {
      const copyFeaturesFormData = (await CopyFeaturesForm.show({
        confirmText: t('Copy'),
        cancelText: t('Cancel'),
        title: t('Copy features by SKU'),
        featureCopyValidate,
        formInitialValues: featuresCopyInitialValuesForm,
        libby,
        id
      })) as FeatureCopyFormResult;
      if (copyFeaturesFormData) {
        featuresLibbyValues.reFetch();
        enqueueSnackbar(t(copyFeaturesFormData.message), { variant: 'success' });
      }
    } catch (err) {
      //nothing
    }
  }, [t, enqueueSnackbar, libby, featuresLibbyValues, id]);

  const handleOpenFeaturesBulkLoad = useCallback(async () => {
    try {
      const featuresBulkLoadResult = (await FeaturesBulkLoad.show({
        confirmText: t('Send'),
        cancelText: t('Cancel'),
        title: t('Features Bulk Load'),
        libby,
        id
      })) as FeatureCopyFormResult;
      if (featuresBulkLoadResult) {
        featuresLibbyValues.reFetch();
        enqueueSnackbar(t(featuresBulkLoadResult.message), { variant: 'success' });
      }
    } catch (err) {
      //nothing
    }
  }, [t, enqueueSnackbar, libby, featuresLibbyValues, id]);

  const handleOpenFeaturesPreviewModal = useCallback(async () => {
    try {
      await FeaturesPreviewModal.show({
        cancelText: t('Close'),
        title: t('Features Preview'),
        features: featuresLibbyValues.features
      });
    } catch (err) {
      //nothing
    }
  }, [t, featuresLibbyValues]);

  const handleOpenFeatureGroupEdit = useCallback(
    async (e: MouseEvent, row: any) => {
      try {
        const editedFormResults = (await FeatureGroupForm.show({
          confirmText: t('Edit'),
          cancelText: t('Cancel'),
          title: t('Edit Feature Group'),
          validate,
          formInitialValues: {
            name: row.name,
            order: row.order
          }
        })) as FeatureGroupFormResults;
        if (editedFormResults) {
          const editedLibbyResult = await libby.ster_product_feature_group.save({ feature_group_id: row.feature_group_id, name: editedFormResults.name, order: editedFormResults.order });
          featuresLibbyValues.updateData(editedLibbyResult, 'feature_group_id');
          enqueueSnackbar(t('Feature group edited'), { variant: 'success' });
        }
      } catch (err) {
        console.log(err);
        enqueueSnackbar(t('Something went wrong'), { variant: 'error' });
      }
    },
    [t, enqueueSnackbar, libby, featuresLibbyValues]
  );

  const handleOpenEditFeature = useCallback(
    async (e: MouseEvent, row: any) => {
      try {
        const editedResultsForm = (await FeatureForm.show({
          confirmText: t('Edit'),
          cancelText: t('Cancel'),
          title: t('Edit Feature'),
          validate: featureValidate,
          formInitialValues: {
            feature_name: row.name,
            value: row.value,
            order: row.order,
            searchable: row.searchable
          }
        })) as FeatureFormResults;
        if (editedResultsForm) {
          await libby.ster_product_feature.save({ ...row, name: editedResultsForm.feature_name, value: editedResultsForm.value, searchable: editedResultsForm.searchable, order: editedResultsForm.order });
          featuresLibbyValues.reFetch();
          enqueueSnackbar(t('Feature edited successfully'), { variant: 'success' });
        }
      } catch (err) {
        console.log(err);
      }
    },
    [t, libby, enqueueSnackbar, featuresLibbyValues]
  );

  const handleOpenDeleteFeatureGroup = useCallback(
    async (e: MouseEvent, row: any) => {
      try {
        const isDeleted = await ConfirmModal.show({
          title: <ModalTitle title="Warning" error={true} />,
          content: `${t('Are you sure you want to delete $$$$ group? It will also delete all the features linked to it').replace('$$$$', row.name)}`,
          confirmText: t('Accept'),
          oneButton: true
        });
        if (isDeleted) {
          await libby.ster_product_feature_group.remove({ feature_group_id: row.feature_group_id, features: row.features });
          featuresLibbyValues.removeData(row.feature_group_id, 'feature_group_id');
          enqueueSnackbar(t('Feature group deleted successfully'), { variant: 'success' });
        }
      } catch (err) {
        console.log(err);
      }
    },
    [t, libby, enqueueSnackbar, featuresLibbyValues]
  );
  const handleOpenDeleteFeature = useCallback(
    async (e: MouseEvent, row: any) => {
      try {
        const isDeleted = await ConfirmModal.show({
          title: <ModalTitle title="Warning" error={true} />,
          content: `${t('Are you sure you want to delete $$$$ feature').replace('$$$$', row.name)}?`,
          confirmText: t('Accept'),
          oneButton: true
        });
        if (isDeleted) {
          await libby.ster_product_feature.remove({ feature_id: row.feature_id });
          featuresLibbyValues.reFetch();
          enqueueSnackbar(t('Feature deleted successfully'), { variant: 'success' });
        }
      } catch (err) {
        console.log(err);
      }
    },
    [t, libby, enqueueSnackbar, featuresLibbyValues]
  );

  const mocked_group_features = useMemo(
    () =>
      featuresLibbyValues.features.map((row: any) => {
        return {
          ...row,
          product_id: row.product.product_id,
          order: row.order,
          action: (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <MakeCell label="" icon={EditIcon} onClick={(e) => handleOpenFeatureGroupEdit(e, row)} />
              <MakeCell label="" icon={DeleteIcon} onClick={(e) => handleOpenDeleteFeatureGroup(e, row)} />
            </Box>
          )
        };
      }),
    [featuresLibbyValues, handleOpenFeatureGroupEdit, handleOpenDeleteFeatureGroup]
  );
  const mocked_features = useMemo(() => {
    const foundGroup = mocked_group_features.find((group: any) => group.feature_group_id === selectedGroup?.feature_group_id);
    if (!foundGroup) return [];
    else {
      return foundGroup.features.map((feat: any) => {
        return {
          ...feat,
          feature_group_id: foundGroup.feature_group_id,
          searchable: feat.searchable === true ? 'true' : 'false',
          action: (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <MakeCell label="" icon={EditIcon} onClick={(e) => handleOpenEditFeature(e, feat)} />
              <MakeCell label="" icon={DeleteIcon} onClick={(e) => handleOpenDeleteFeature(e, feat)} />
            </Box>
          )
        };
      });
    }
  }, [mocked_group_features, selectedGroup, handleOpenEditFeature, handleOpenDeleteFeature]);

  const handleOpenAddFeatureGroup = useCallback(async () => {
    try {
      const createdResults: FeatureGroupFormResults = (await FeatureGroupForm.show({
        confirmText: t('Create'),
        cancelText: t('Cancel'),
        title: t('Create Feature Group'),
        validate,
        formInitialValues: featureGroupInitialValuesForm
      })) as FeatureGroupFormResults;
      if (createdResults) {
        const createdGroup = await libby.ster_product_feature_group.save({ name: createdResults.name, product: { product_id: id }, order: createdResults.order });
        featuresLibbyValues.addCreate(createdGroup);
        enqueueSnackbar(t('Feature group created'), { variant: 'success' });
      }
    } catch (err) {
      console.log(err);
      //nothing
    }
  }, [t, libby, featuresLibbyValues, enqueueSnackbar, id]);

  const handleOpenAddFeature = useCallback(async () => {
    try {
      const createdResults = (await FeatureForm.show({
        confirmText: t('Create'),
        cancelText: t('Cancel'),
        title: t('Create Feature'),
        validate: featureValidate,
        formInitialValues: featuresInitialValuesForm
      })) as FeatureFormResults;
      if (createdResults) {
        await libby.ster_product_feature.save({
          name: createdResults.feature_name,
          value: createdResults.value,
          featureGroup: { feature_group_id: selectedGroup?.feature_group_id },
          order: createdResults.order,
          searchable: createdResults.searchable
        });
        featuresLibbyValues.reFetch();
        enqueueSnackbar(t('Feature created successfully'), { variant: 'success' });
      }
    } catch (err) {
      console.log(err);
    }
  }, [t, enqueueSnackbar, selectedGroup, featuresLibbyValues, libby]);

  const featureGroupButtons: ButtonDetailsType[] = useMemo(
    () => [
      {
        id: 'AddFeatureGroup',
        onClick: handleOpenAddFeatureGroup,
        title: t('Add Group'),
        variant: 'contained'
      }
    ],
    [t, handleOpenAddFeatureGroup]
  );
  const featurepButtons: ButtonDetailsType[] = useMemo(
    () => [
      {
        id: 'AddFeature',
        onClick: handleOpenAddFeature,
        title: t('Add Feature'),
        variant: 'contained',
        disabled: !!!selectedGroup
      }
    ],
    [t, handleOpenAddFeature, selectedGroup]
  );

  const featuresMainPageButtons: ButtonDetailsType[] = useMemo(
    () => [
      {
        id: 'Preview',
        onClick: handleOpenFeaturesPreviewModal,
        title: t('Preview'),
        variant: 'contained'
      },
      {
        id: 'Copy',
        onClick: handleOpenCopyFeaturesModal,
        title: t('Copy'),
        variant: 'contained'
      },
      {
        id: 'BulkLoad',
        onClick: handleOpenFeaturesBulkLoad,
        title: t('Bulk'),
        variant: 'contained'
      }
    ],
    [t, handleOpenFeaturesPreviewModal, handleOpenCopyFeaturesModal, handleOpenFeaturesBulkLoad]
  );

  return {
    featureGroupButtons,
    featurepButtons,
    featureGroupData: mocked_group_features,
    featureData: mocked_features,
    featuresMainPageButtons
  };
};

export default useProductFeaturesLogic;
