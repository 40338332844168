import { ORDER_STATE } from './OrderState';

export const AllOrderCancel = [
  ORDER_STATE.CANCELLED,
  ORDER_STATE.RETURNING_TO_CANCEL,
  ORDER_STATE.WAITING_TO_CANCEL,
  ORDER_STATE.RETURNED,
  ORDER_STATE.RETURNED_COMPLETED,
  ORDER_STATE.WAITING_FOR_RETURN,
  ORDER_STATE.CANCELLATION_FOR_FRAUD
];
export const AllOrderAwaitingCancelling = [ORDER_STATE.RETURNING_TO_CANCEL, ORDER_STATE.WAITING_TO_CANCEL, ORDER_STATE.WAITING_FOR_RETURN, ORDER_STATE.CANCELLATION_FOR_FRAUD];

export const DelivereOrders = [ORDER_STATE.RETAIN, ORDER_STATE.OUT_FOR_DELIVERY, ORDER_STATE.DELIVERED, ORDER_STATE.READY_TO_RESEND, ORDER_STATE.PAID, ORDER_STATE.COLLECTED, ORDER_STATE.BILL_INFORMED, ORDER_STATE.TRACKER_INFORMED];
