// TODO: Refactorizar
import React, { useCallback, useRef } from 'react';
import ClearIcon from '@material-ui/icons/Clear';
import { makeStyles } from '@material-ui/styles';
import { Button } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { useTranslation } from '../../../../../services/translation';
import { Navbar } from '../../../../../components/Navbar';
import { AnyObject, LibbyObject } from '../../../../../types';
import { LoadingTable } from '../../../../../components/LoadingTable';
import { useContainerDimensions } from '../../../../../hooks/useContainerDimensions';
import { BackButton } from '../../../../../components/BackButton';

const useStyles = makeStyles((theme: AnyObject) => ({
  navbarBox: {
    position: 'fixed',
    height: '5.5rem',
    display: 'flex',
    justifyContent: 'center'
  },
  clearIcon: {
    fill: theme.palette.common.black,
    fontSize: 36,
    cursor: 'pointer'
  },
  title: {
    fontFamily: 'Lato',
    fontSize: 30,
    '@media (max-width: 550px)': {
      fontSize: 20
    },
    textTransform: 'uppercase',
    cursor: 'default',
    color: theme.palette.common.black
  },
  contentTitle: {
    display: 'flex',
    alignItems: 'center'
  },
  actionButton: {
    marginRight: 0,
    marginLeft: 15,
    padding: '5px 20px',
    fontFamily: '"Oswald"'
  }
}));

type NavbarDispatchProps = {
  libby: LibbyObject;
  checked: {
    all: AnyObject;
  };
  resetCheck?: () => void;
  addOrdersHandle: () => void;
  buttonEnabled?: boolean;
};

export const NavbarDispatch = ({ libby, checked, resetCheck, addOrdersHandle, buttonEnabled = false }: NavbarDispatchProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const componentRef = useRef<HTMLInputElement>(null);
  const { width } = useContainerDimensions(componentRef);

  const left = (
    <Grid container direction="row" justify="center" alignItems="center">
      <BackButton color="default" />
      {checked.all.length > 0 && <ClearIcon className={classes.clearIcon} onClick={resetCheck} />}
    </Grid>
  );

  const title = (
    <div className={classes.contentTitle}>
      <h2 role="presentation" className={classes.title}>
        {`${checked.all.length} ${t(width >= 540 ? 'Selected Orders' : 'Orders')}`}
      </h2>
    </div>
  );

  const right = useCallback(
    () => (
      <>
        <Button onClick={addOrdersHandle} disabled={!!buttonEnabled || (!checked?.all?.length && !buttonEnabled) || libby.working} className={classes.actionButton} color="primary">
          {libby.working ? <LoadingTable /> : t('Add')}
        </Button>
      </>
    ),
    [buttonEnabled, t, addOrdersHandle, checked, classes, libby.working]
  );

  return (
    <div ref={componentRef}>
      <Navbar className={classes.navbarBox} left={left} title={title} right={right()} />
    </div>
  );
};
