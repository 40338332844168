import React, { useMemo, useState, useCallback, useEffect } from 'react';
import { DatabaseConnector } from '@phinxlab/libby-rest-web';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { AnyObject, LibbyObject } from '../../../../types/types';
import { makeFilter } from '../../../Orders/routes/OrdersList/utils/makeFilter';
import { DispatchOrderListTable } from './components/DispatchOrderListTable';
import { useDispatchesDetail } from '../../hook';
import { useOrderStateEnabled } from './hook/useOrderStateEnabled';
import { Filter } from '../../../../types';
import { API_URL } from 'config';
import { GET_ORDER_TO_DISPATCH, GET_CUSTOM_DISPATCH } from 'const';
import { TokenManager } from 'platform/libby/TokenManager';

type DispatchOrderListProps = {
  libby: LibbyObject;
  path: string;
  reFetch: () => void;
};

const DispatchOrderListRaw = ({ libby, path, reFetch }: DispatchOrderListProps) => {
  //@ts-ignore
  const { id, courierService } = useParams();
  const [direction, setDirection] = useState<'asc' | 'desc'>('asc');
  const [orderBy, setOrderBy] = useState('order_id');
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<any[] | undefined>([]);
  const [isMoreData, setIsMoreData] = useState(true);
  const [debounce, setDebounce] = useState<NodeJS.Timeout | null>(null);
  const [offset, setOffset] = useState(0);
  const [orderByAux, setOrderByAux] = useState('priority');
  const [directionAux, setDirectionAux] = useState<'asc' | 'desc'>('desc');
  const orderStatusInit = useOrderStateEnabled();
  const limit = 20;

  const { addItemInDispatch } = useDispatchesDetail({
    libby,
    id,
    reFetch
  });

  const handleRequestSort = (newOrderBy: string, newDirection: 'asc' | 'desc') => {
    if (newOrderBy !== 'priority') {
      setOrderBy(newOrderBy);
      setDirection(newDirection);
    }

    setOrderByAux(newOrderBy);
    setDirectionAux(newDirection);
  };

  const [filter, setFilter] = useState(
    makeFilter({
      orderStatus: orderStatusInit,
      courierService: [parseInt(courierService, 10)]
    })
  );

  const filtersParam = useMemo(() => {
    let filters = '';
    const keys = Object.keys(filter);
    keys.forEach((key, i) => {
      const uniqueFilterArray = Array.from(new Map(filter[key].map((obj) => [JSON.stringify(obj), obj])).values());

      filters +=
        uniqueFilterArray
          .map((item) => {
            return !filters.includes(item.path) ? `${key}.${item.path}=${item.value}` : '';
          })
          .join('&') + `${keys.length - 1 > i ? '&' : ''}`;
    });

    keys.forEach((key) => {
      if (keys.includes('startDate') || keys.includes('endDate')) {
        filters += `&${key}=${filter[key][0].value}`;
      }
    });

    return filters;
  }, [filter]);

  const onChangeFilter = useCallback(
    (value: Filter) => {
      if (JSON.stringify(filter) !== JSON.stringify(value)) {
        setFilter(value);
      }
    },
    [setFilter, filter]
  );

  const getOrders = useCallback(
    async (stateTurn?: string) => {
      let startTime = performance.now();
      setIsLoading(true);
      setIsMoreData(true);
      // TODO: Change endpoint call from axios to libby
      let baseUrl = `${API_URL}/${GET_ORDER_TO_DISPATCH}`;

      let queryParams = `limit=${limit}&offset=${offset}&orderBy=${orderBy}&direction=${direction}&${filtersParam}`;
      if (stateTurn) {
        queryParams += `&withTurn=${stateTurn}`;
      }

      const response = await axios.get(`${baseUrl}?${queryParams}`, {
        headers: {
          'x-chino-token': await TokenManager.create().retrieve()
        }
      });
      let endTime = performance.now();
      console.log(`Execution time get orders: ${endTime - startTime} milliseconds.`);
      setIsLoading(false);

      if (response.data.length !== 0) {
        setData((prev) => (prev ? [...prev, ...response.data] : [...response.data]));
      } else if (offset > 20) {
        setIsMoreData(false);
      }
    },
    [direction, filtersParam, offset, orderBy]
  );

  useEffect(() => {
    const fetch = async () => {
      console.log('courierService', courierService);
      if (courierService === '11') {
        const response = await axios.get(`${API_URL}/${GET_CUSTOM_DISPATCH}?dispatchId=${id}`, {
          headers: {
            'x-chino-token': await TokenManager.create().retrieve()
          }
        });
        await getOrders(response.data?.state_turn?.toString());
      } else {
        await getOrders();
      }
    };
    fetch();
  }, [courierService, getOrders, id]);

  useEffect(() => {
    setData([]);
    setOffset(0);
  }, [orderBy, direction, filter]);

  const fetchMore = useCallback(() => {
    if (!isMoreData) {
      return;
    }
    if (debounce) {
      clearTimeout(debounce);
    }

    const timeoutID = setTimeout(() => {
      setOffset((prevOffset) => prevOffset + limit);
    }, 100);
    setDebounce(timeoutID);
  }, [debounce, isMoreData]);

  const dataUpdate = useMemo(() => {
    return data?.map((dataAdd: AnyObject) => ({
      ...dataAdd,
      priority: Math.floor(Math.random() * (3 + 1 - 1) + 1)
    }));
  }, [data]);

  return (
    <DispatchOrderListTable
      id={id}
      courierService={courierService}
      data={dataUpdate}
      path={path}
      filter={filter}
      onFilterChange={onChangeFilter}
      working={isLoading}
      fetchMore={fetchMore}
      onSortChange={handleRequestSort}
      orderBy={orderByAux}
      direction={directionAux}
      addItem={addItemInDispatch}
      reFetch={reFetch}
    />
  );
};

export const DispatchOrderList = DatabaseConnector(DispatchOrderListRaw)(
  'ster_order_for_dispatch',
  'ster_dispatch_details',
  'ster_dispatch_update',
  'ster_dispatch_details_update',
  'ster_dispatch_upload_documents_manifest',
  'ster_validate_order_item'
);
