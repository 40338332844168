import { useCallback, useState } from 'react';
import { ProductData } from '../types';
import { BudgetContextValue, BudgetInitialValues } from '../types/BudgetContextValue';

export const useProducts = (initialValues: BudgetInitialValues) => {
  const [products, setProducts] = useState<ProductData>(initialValues.products);
  const [validatingProduct, setValidatingProduct] = useState<boolean>(false);

  const addProductRow = useCallback<BudgetContextValue['addProductRow']>(
    (product, row) => {
      let index = products.findIndex((item) => item.product.product_id === product.product_id);
      const newProducts: ProductData = [...products];
      if (index === -1) {
        // agregamos el producto en caso de que no exista
        newProducts.push({
          product,
          rows: []
        });
        // aqui el index es el ultimo agregado
        index = newProducts.length - 1;
      }
      if (!newProducts[index].rows.find((item) => item.paymentMethod.code === row.paymentMethod.code)) {
        newProducts[index].rows = [...newProducts[index].rows, row];
        setProducts(newProducts);
      }
    },
    [products, setProducts]
  );

  const editProductRow = useCallback<BudgetContextValue['editProductRow']>(
    (product, rowIndex, row) => {
      const index = products.findIndex((item) => item.product.product_id === product.product_id);
      if (index === -1) {
        throw new Error('product not found');
      }
      if (!products[index].rows[rowIndex]) {
        throw new Error('row not found');
      }
      const newProducts = [...products];
      newProducts[index].rows[rowIndex] = row;
      setProducts(newProducts);
    },
    [products, setProducts]
  );

  const removeProductRow = useCallback<BudgetContextValue['removeProductRow']>(
    (product, rowIndex) => {
      const index = products.findIndex((item) => item.product.product_id === product.product_id);
      if (index === -1) {
        throw new Error('product not found');
      }
      if (!products[index].rows[rowIndex]) {
        throw new Error('row not found');
      }
      let newProducts = [...products];
      newProducts[index].rows = newProducts[index].rows.filter((item, _index) => _index !== rowIndex);
      if (newProducts[index].rows.length === 0) {
        newProducts = newProducts.filter((item, _index) => _index !== index);
      }
      setProducts(newProducts);
    },
    [products, setProducts]
  );

  const cleanProducts = useCallback(async () => {
    setProducts([]);
    setTimeout(() => {
      return 'cleaned';
    }, 1000);
  }, [setProducts]);

  return {
    products,
    addProductRow,
    editProductRow,
    removeProductRow,
    cleanProducts,
    setValidatingProduct,
    validatingProduct
  };
};
