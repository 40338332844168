import { makeValidator, ValidatorType, ValidatorRule } from '../../../../../utils';

export interface ReceivingConfirmationData {
  rma?: boolean;
  location: number;
}

export const formInitialValues = {
  location: ''
};

const validations: ValidatorRule[] = [{ key: 'location', validators: [ValidatorType.REQUIRED] }];

export const validate = makeValidator(validations);

export const getValidate = (keys: Array<string>) => makeValidator(keys.length > 0 ? validations.filter((val) => keys.includes(val.key)) : validations);
