import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  containInfo: {
    paddingTop: '15px'
  },
  EditButtonDiv: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '10px'
  },
  Space: {
    marginRight: 12
  },
  formFields: {
    padding: '10px',
    width: '100%'
  },
  loading: {
    marginRight: '30px',
    marginTop: '5px'
  }
});
