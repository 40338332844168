import React from 'react';
import { Grid } from '@material-ui/core';
import moment from 'moment';
import { DateClose } from '../../../../components/DateClose';
import { Filter } from '../../../../types';
import { useInitFilter } from '../../../OrderReport/routes/OrderReportList/hook';
import { gridBreakpointsFields } from '../../../../const';
import { FilterBar } from '../../../../components/FilterBar';
import { makeFilter } from '../../utils';

type SearchFiltersProps = {
  onFilterChange: (makeFilter: object) => void;
  filter: Filter;
};

const SearchFiltersRaw = ({ onFilterChange, filter }: SearchFiltersProps) => {
  const { search: startDate, handleChangeFilterString: setStartDate } = useInitFilter({
    initFetch: filter.startDate,
    onFilterChange,
    init: null,
    id: 'startDate',
    date: true,
    makeFilter
  });

  const { search: endDate, handleChangeFilterString: setEndDate } = useInitFilter({
    initFetch: filter.endDate,
    onFilterChange,
    id: 'endDate',
    date: true,
    init: null,
    makeFilter
  });

  return (
    <FilterBar
      defaultFilter={
        <Grid container spacing={4}>
          <Grid item {...gridBreakpointsFields}>
            <DateClose name="Start Date" value={moment.isMoment(startDate) ? startDate : null} onChange={setStartDate} />
          </Grid>
          <Grid item {...gridBreakpointsFields}>
            <DateClose name="End Date" value={moment.isMoment(endDate) ? endDate : null} minValue={moment.isMoment(startDate) ? startDate : null} onChange={setEndDate} />
          </Grid>
        </Grid>
      }
    />
  );
};

export const SearchFilters = React.memo(SearchFiltersRaw);
