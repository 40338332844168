import { LibbyFetchDAO } from '../LibbyFetchDAO';

export class AccountRetoolDAO extends LibbyFetchDAO {
  constructor() {
    super('ster_account_retool', 'account_id');
  }

  getAll() {
    return this.query().limit(0, 10000).run();
  }
}
