import { Box, Grid, Switch } from '@material-ui/core';
import React from 'react';
import { ButtonComponent } from '../../../components/Button';
import { ScreenAligned } from '../../../components/ScreenAligned/ScreenAligned';
import { ButtonDetailsType } from '../../../types/Button';
import InfoTable from '../../components/InfoTable';
import { useReportingMlLogic } from '../hook/useReportingMl';
import { columns } from '../utils/columns';
import { SearchFilters } from './components/SearchFilters';

export const ReportingMlList = () => {
  const { rows, working, setFilter, filter, stateRefresh, handleChange, buttons } = useReportingMlLogic();

  return (
    <ScreenAligned
      title="Mercadolibre"
      additionalTitle={
        <>
          <Box display="flex" justifyContent="center" alignItems="center">
            <Switch checked={stateRefresh} onChange={handleChange} name="checkedA" color="primary" inputProps={{ 'aria-label': 'secondary checkbox' }} />
          </Box>
          {buttons.map((dataButton: ButtonDetailsType) => (
            <ButtonComponent
              key={dataButton.id}
              title={dataButton.title}
              className={dataButton.className}
              variant={dataButton.variant}
              color={dataButton.color}
              onClick={dataButton.onClick}
              type={dataButton.type}
              disabled={dataButton.disabled}
              loading={dataButton.loading}
            />
          ))}
        </>
      }
    >
      <SearchFilters onFilterChange={setFilter} filter={filter} />
      <Grid container direction="row" justify="center" alignItems="center">
        <InfoTable columns={columns} rows={rows} onBottomScroll={() => {}} onSortChange={() => {}} direction="asc" rowIdKey="name" height="auto" md={12} sm={12} xs={12} working={working} />
      </Grid>
    </ScreenAligned>
  );
};
