import { Objective_period } from '../interfaces/business';
import { makeBusinessEntity } from '../lib/libby/generator/makeBusinessEntity';

const DAO_NAME = 'objective_period';

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch }
} = makeBusinessEntity<Objective_period>(DAO_NAME);

// hooks
export const useObjectivePeriodFetchById = fetchById;
export const useObjectivePeriodLibbyCall = libbyCall;
export const useObjectivePeriodDAO = libbyDAO;
export const useObjectivePeriodLibbyFetch = libbyFetch;
