export const TableColumns = [
  {
    id: 'actions',
    label: 'Actions',
    noSort: true
  },
  {
    id: 'id',
    label: 'ID Template',
    style: {
      whiteSpace: 'nowrap',
      minWidth: '60px'
    },
    orderById: 'message_template_id'
  },
  {
    id: 'name',
    label: 'Name',
    style: {
      minWidth: '100px'
    },
    orderById: 'name'
  },
  {
    id: 'message_subject',
    label: 'Subject',
    style: {
      minWidth: '100px'
    },
    orderById: 'message_subject'
  },
  {
    id: 'message_from',
    label: 'From',
    noSort: true,
    style: {
      minWidth: '100px'
    }
  },
  {
    id: 'message',
    label: 'Message',
    noSort: true,
    style: {
      minWidth: '100px'
    }
  }
];
