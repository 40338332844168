import React, { useMemo } from 'react';
import { RowsMessageConfigAlert } from '../../types/types';
import { RowsOptions } from '../../components/RowsOptions';
import { useTranslation } from '../../../../services/translation';
import { RowsType } from '../../../../types';
import { Message_config_alert_template } from '../../../../interfaces/business/message/Message_config_alert_with_template';

export const useRowsMessageConfigForm = ({ messageConfig, deleteMessageConfig, openMessageConfigEdit }: RowsMessageConfigAlert) => {
  const { t } = useTranslation();

  const rows = useMemo(() => {
    return messageConfig.reduce((allDataRows: RowsType[], dataMessageConfig: Message_config_alert_template) => {
      const {
        message_config_alert_id,
        alert: { name: nameAlert, alert_id },
        template: { name: nameTemplate }
      } = dataMessageConfig;
      const copyAllDataRows = [...allDataRows];

      copyAllDataRows.push({
        options: <RowsOptions deleteMessageConfig={deleteMessageConfig} openMessageConfigEdit={openMessageConfigEdit} message_config={dataMessageConfig} />,
        id: message_config_alert_id,
        template: t(nameTemplate),
        alert: nameAlert,
        alert_id
      });

      return copyAllDataRows;
    }, []);
  }, [messageConfig, t, deleteMessageConfig, openMessageConfigEdit]);

  return { rows };
};
