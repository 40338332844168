import React, { useCallback, useEffect, useState, useMemo } from 'react';
import Dialog from '@material-ui/core/Dialog';
import { ThemeProvider } from '@material-ui/styles';
import { Button, DialogActions, DialogContentText, Grid } from '@material-ui/core';
import { useTranslation } from '../../../../services/translation';
import { InformationSection } from '../../../InformationSection';
import { Filter } from '../../../../interfaces/business';
import { NewFilter, FilterManagerDialogProps } from '../../types';
import { useFilterDAO } from '../../../../business/Filter';
import useDebounce from '../../../../hooks/useDebounce';

const dummyFunc = () => {};

const dataFilterInit: NewFilter = {
  name: '',
  private: false,
  preset: false,
  campaign: false
};

export const FilterManagerDialogComponent = <T,>({
  open = false,
  onCancel = dummyFunc,
  render,
  onGoBack = dummyFunc,
  customTheme = null,
  title,
  maxWidth = 'xs',
  cancelText = 'No',
  confirmText = 'Yes',
  onConfirm,
  goBackText = 'Return',
  props
}: FilterManagerDialogProps<T>) => {
  const { t } = useTranslation();
  const filterDAO = useFilterDAO();
  const [newFilter, setNewFilter] = useState<NewFilter>(dataFilterInit);
  const [showTable, setShowTable] = useState(false);
  const [validName, setValidName] = useState(false);
  const {
    toSave,
    newValue,
    filter: { name, private: privateF, preset, filter_id, campaign: campaignF }
  } = props;

  useEffect(() => {
    if (toSave) {
      setShowTable(false);
    } else {
      setShowTable(true);
    }
  }, [toSave]);

  useEffect(() => {
    if (newValue) {
      setNewFilter(dataFilterInit);
    } else {
      setNewFilter({ ...dataFilterInit, name, private: privateF, preset, filter_id, campaign: campaignF });
    }
  }, [newValue, name, privateF, preset, filter_id, campaignF]);

  const nameDebounced = useDebounce(newFilter.name, 700);

  const validateFilterByName = useCallback(async () => {
    if (!nameDebounced) return;
    const result: Filter[] = await filterDAO.getByName(nameDebounced);
    setValidName(!result.length);
  }, [filterDAO, nameDebounced, setValidName]);

  useEffect(() => {
    validateFilterByName();
  }, [validateFilterByName]);

  const onSubmit = useCallback(() => {
    onConfirm({ ...newFilter });
  }, [onConfirm, newFilter]);

  const onCancelModal = useCallback(() => {
    onCancel();
  }, [onCancel]);

  const onGoBackModal = useCallback(() => {
    onGoBack();
  }, [onGoBack]);

  const onChecboxchange = useCallback(
    (e: any) => {
      setNewFilter({ ...newFilter, [e.name]: e.checked });
    },
    [newFilter]
  );

  const onNameChange = useCallback(
    (value: string) => {
      setNewFilter({ ...newFilter, name: value });
    },
    [newFilter]
  );

  const onEdit = useCallback((value: Filter<T>) => {
    const { name: nameEdit, preset: presetEdit, private: priv, filter_id: filter_idEdit, campaign } = value;
    setNewFilter({ name: nameEdit, preset: presetEdit, private: priv, filter_id: filter_idEdit, campaign });
  }, []);

  const validateName = useMemo(() => (!newValue ? (newFilter.name === name ? true : validName) : validName), [newValue, validName, name, newFilter.name]);

  const propsComponent = { ...props, newFilter, showTable, validName: validateName, setShowTable, onChecboxchange, onNameChange, onEdit };
  const Comp = render;
  const dialog = (
    <Grid container>
      <Dialog open={open} onClose={onCancelModal} fullWidth maxWidth={maxWidth} aria-labelledby="form-dialog-title" aria-describedby="alert-dialog-description">
        <InformationSection title={t(title)} noPaddingChildren>
          <DialogContentText>{Comp && <Comp {...propsComponent} />}</DialogContentText>
        </InformationSection>
        <DialogActions>
          <Button onClick={onCancelModal}>{cancelText}</Button>
          <Button onClick={onGoBackModal}>{goBackText}</Button>
          {!showTable && (
            <Button color="primary" onClick={onSubmit} disabled={newFilter.name === '' || !validateName}>
              {t(confirmText)}
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </Grid>
  );

  return !customTheme ? dialog : <ThemeProvider theme={customTheme}>{dialog}</ThemeProvider>;
};
