import { makeValidator, ValidatorRule, ValidatorType } from '../../../../../../utils';

export const formReportingLogisticSlaInitialValues = {
  marketplace: '',
  sla_operative: 0,
  sla_delivered: 0
};

const validations: ValidatorRule[] = [
  { key: 'marketplace', validators: [ValidatorType.REQUIRED] },
  { key: 'sla_operative', validators: [ValidatorType.REQUIRED] },
  { key: 'sla_delivered', validators: [ValidatorType.REQUIRED] }
];

export const validateReportingLogisticSlaAdd = makeValidator(validations);
