import { LibbyFetchDAO } from './LibbyFetchDAO';

export class DispatchUploadDocumentsManifestDAO extends LibbyFetchDAO {
  constructor() {
    super('ster_dispatch_upload_documents_manifest', 'upload_documents_manifest_id');
  }

  getAll() {
    return this.query().limit(0, 10000).run();
  }
}
