import { makeBusinessEntity } from '../../lib/libby/generator/makeBusinessEntity';
import { PaymentCurrencyDAO } from '../../platform/libby/dao/Payment';

const DAO_NAME = 'paymentcurrency';

const {
  hook: { libbyFetch }
} = makeBusinessEntity<PaymentCurrencyDAO>(DAO_NAME);

// hooks
export const usePaymentCurrencyLibbyFetch = libbyFetch;
