import React, { useCallback, useRef, useState } from 'react';
import { Grid, IconButton, TextField, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import PublishIcon from '@material-ui/icons/Publish';
import ClearOutlinedIcon from '@material-ui/icons/ClearOutlined';
import { AnyObject } from '../../../types';
import { useTranslation } from '../../../services/translation';

const useStyles = makeStyles({
  rootRadio: {
    display: 'flex',
    justifyContent: 'space-around',
    width: '100%'
  },
  rootMessage: {
    dislpay: 'flex',
    flexDirection: 'column'
  },
  textField: {
    marginBottom: '20px'
  },
  inputField: {
    display: 'none'
  },
  label: {
    display: 'flex',
    height: '40px',
    width: '100%',
    justifyContent: 'flex-end'
  },
  inputText: {
    marginLeft: '20px'
  },
  actions: {
    display: 'flex'
  }
});

interface MessageFieldType {
  errorMessage: {
    error: boolean;
    helperText?: string;
  };
  inputProps: AnyObject;
}
let fileReader: AnyObject;

export const MessageField = ({ errorMessage, inputProps }: MessageFieldType) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [fileName, setFileName] = useState('');
  const fileRef = useRef<HTMLInputElement | null>(null);

  const handleFileChosen = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const target = e.target as HTMLInputElement;
      const fileInner: File = (target.files as FileList)[0];
      fileReader = new FileReader();
      fileReader.fileName = fileInner && fileInner.name;
      fileReader.onload = () => {
        inputProps.onChange(fileReader.result);
      };
      if (fileInner) {
        fileReader.readAsText(fileInner);
        fileReader.onload();
        setFileName(fileReader.fileName);
      }
    },
    [inputProps]
  );

  return (
    <Grid>
      <Grid className={classes.rootMessage}>
        <TextField id="Message" label={t('Message')} fullWidth multiline rows={17} margin="normal" value={inputProps.value} onChange={inputProps.onChange} {...errorMessage} />
        <Grid className={classes.actions}>
          <IconButton
            onClick={() => {
              inputProps.onChange('');
              if (fileRef.current) {
                fileRef.current.value = '';
              }
              setFileName('');
            }}
          >
            <ClearOutlinedIcon />
          </IconButton>
          <label htmlFor="input" className={classes.label}>
            <IconButton component="span">
              <PublishIcon />
              <input ref={fileRef} id="input" type="file" accept=".txt,.html" className={classes.inputField} onChange={handleFileChosen} />
              <Typography className={classes.inputText}>{fileName || t('Upload File')}</Typography>
            </IconButton>
          </label>
        </Grid>
      </Grid>
    </Grid>
  );
};
