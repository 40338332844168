type MuiSvgIconType = { [k: string]: any };

const MuiSvgIcon: MuiSvgIconType = {
  root: {
    fontSize: 22
  },
  fontSizeLarge: {
    fontSize: 32
  }
};

export default MuiSvgIcon;
