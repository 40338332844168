import React from 'react';
import { DatabaseConnector } from '@phinxlab/libby-rest-web';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { CategoryList } from './routes';
import { LibbyObject } from '../../types';
import { useCategoryLogic } from './hook/useCategoryLogic';

const CategoriesRaw = ({ libby }: { libby: LibbyObject }) => {
  const match = useRouteMatch();
  const { rows, columns, working, fetchMore, handleRequestSort, orderBy, direction, setFilter, filter, buttons } = useCategoryLogic({
    libby
  });

  return (
    <Switch>
      <Route path={`${match.path}`}>
        <CategoryList
          title="Categories"
          rows={rows}
          columns={columns}
          working={working}
          fetchMore={fetchMore}
          onSortChange={handleRequestSort}
          orderBy={orderBy}
          direction={direction}
          setFilter={setFilter}
          filter={filter}
          buttons={buttons}
        />
      </Route>
    </Switch>
  );
};

export const Categories = DatabaseConnector(CategoriesRaw)('ster_category', 'ster_product_bulk_categories');
