import { useCallback, useState } from 'react';
import { cloneDeep, groupBy } from 'lodash';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { LibbyObject, Order, userInfoType } from '../../../../../types';
import { Marketplace_product, Product } from '../../../../../interfaces/business';
import { companyMarketplace, ORDER_STATE, BUDGET_SHIPMENT_TYPE } from '../../../../../const';
import { Product_component } from '../../../../../interfaces/business/product/Product_component';
import { useGlobalContext } from '../../../../../hooks';
import { shipmentTypeIdOdoo } from '../../../const';
import { useTranslation } from '../../../../../services/translation';

export type OrderItemType = {
  order_item_id: string | null;
  owner_item_id: string;
  name: string;
  sku: string;
  quantity: number;
  unit_price: string;
  subtotal: string;
  marketplace_product_?: Marketplace_product;
  product?: Product;
  products_c?: Product_component[];
};

export interface UseSaveOrderLogicProps {
  libby: LibbyObject;
  previousOrderData: Order;
  isCopy?: boolean;
}

export interface MethosProductsType {
  payment_method_id: number;
  payment_method_name: string;
  payment_method_quantity: number;
  paid_in_advance?: boolean;
  product_list: OrderItemType[];
  custom?: number;
  order_id?: string;
}

export const useSaveOrderLogic = ({ libby, previousOrderData, isCopy }: UseSaveOrderLogicProps) => {
  const history = useHistory();
  const [orderItems, setOrderItems] = useState<OrderItemType[]>([]);
  const [methodsProducts, setMethodsProducts] = useState<MethosProductsType[]>([]);
  const [saving, setSaving] = useState(false);
  const {
    userInfo
  }: {
    userInfo: userInfoType;
  } = useGlobalContext();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const addItem = useCallback(
    (product: Product) => {
      setOrderItems((prev) => {
        const skuIndex = prev.findIndex((orderItem) => orderItem.sku === product.sku);
        const newState = cloneDeep(prev);
        if (skuIndex >= 0) {
          newState[skuIndex] = {
            ...newState[skuIndex],
            quantity: Number(product.quantity || 1),
            unit_price: product.price.toString(),
            subtotal: ((newState[skuIndex].quantity + Number(product.quantity || 1)) * Number(product.price)).toString()
          };
        } else {
          const orderItem = {
            order_item_id: null,
            owner_item_id: `budget - ${product.sku} - ${new Date().getTime()}`,
            name: product.name,
            sku: product.sku,
            quantity: Number(product.quantity || 1),
            unit_price: product.price.toString(),
            subtotal: (Number(product.quantity || 1) * Number(product.price)).toString(),
            product
          };
          newState.push(orderItem);
        }
        return newState;
      });
    },
    [setOrderItems]
  );

  const removeItem = useCallback(
    (orderItem: OrderItemType) => {
      setOrderItems((prev) => {
        let newState = cloneDeep(prev);
        const skuIndex = prev.findIndex((item) => item.sku === orderItem.sku);
        if (skuIndex < 0) return prev;

        if (newState[skuIndex].quantity === 1) {
          newState = newState.filter((_, i) => i !== skuIndex);
        } else {
          newState[skuIndex] = { ...newState[skuIndex], quantity: newState[skuIndex].quantity - 1 };
        }

        return newState;
      });
    },
    [setOrderItems]
  );

  const saveOrder = useCallback(
    async (order: Order) => {
      const orderResponse = await libby.ster_order_table.save({ ...order, items: [] });

      const items = order?.items || [];
      for (let i = 0; i < items?.length; i++) {
        const item = {
          ...items[i],
          order: orderResponse,
          order_item_id: isCopy ? null : items[i].order_item_id,
          owner_item_id: isCopy ? `budget - ${items[i].sku} - ${new Date().getTime()}` : items[i].owner_item_id
        };
        /* eslint-disable-next-line */
        const itemResponse = await libby.ster_order_item.save({ ...item });
        orderResponse.items.push(itemResponse);
      }
      return orderResponse;
    },
    [libby, isCopy]
  );

  const onSave = useCallback(
    async (param: any) => {
      try {
        const [city] = await libby?.ster_city
          .query()
          .equals('name', param.billingAddressCity || 'default')
          .equals('state.state_id', param.billingAddressState)
          .run();
        setSaving(true);
        const [orderState] = await libby.order_state.query().equals('order_state_id', ORDER_STATE.DRAFT).run();

        const orderBuyer = await libby.ster_order_buyer.save({
          ...previousOrderData?.buyer,
          order_buyer_id: previousOrderData?.buyer?.order_buyer_id || null,
          last_name: param.last_name,
          first_name: param.first_name,
          email: param.email,
          phone_number: param.phone_number,
          street: param.billingAddressStreet === '' || param.billingAddressStreet === 'null' ? null : param.billingAddressStreet,
          street_number: param.billingAddressStreetNumber === '' || param.billingAddressStreetNumber === 'null' ? null : param.billingAddressStreetNumber,
          floor: param.billingAddressFloor === '' || param.billingAddressFloor === 'null' ? null : param.billingAddressFloor,
          department: param.billingAddressDepartment === '' || param.billingAddressDepartment === 'null' ? null : param.billingAddressDepartment,
          zip: param.billingAddressZip === '' || param.billingAddressZip === 'null' ? null : param.billingAddressZip,
          extra_address: '',
          document: param.document,
          comments: '',
          document_type: { document_type_id: param.document_type },
          city
        });

        const [cityShipment] = await libby?.ster_city
          .query()
          .equals('name', param.shipment_type !== BUDGET_SHIPMENT_TYPE.RETIRO_EN_PLANTA.toString() ? param.shipmentAddressCity : 'default')
          .equals('state.state_id', param.shipment_type !== BUDGET_SHIPMENT_TYPE.RETIRO_EN_PLANTA.toString() ? param.shipmentAddressState : 0)
          .run();

        const orderShipmentAddress = {
          ...previousOrderData?.shipment?.address,
          order_shipment_address_id: previousOrderData?.shipment?.address?.order_shipment_address_id || null,
          street:
            param.shipment_type !== BUDGET_SHIPMENT_TYPE.RETIRO_EN_PLANTA.toString() && param.shipmentAddressStreet && param.shipmentAddressStreet !== '' && param.shipmentAddressStreet !== 'null'
              ? param.shipmentAddressStreet
              : null,
          street_number:
            param.shipment_type !== BUDGET_SHIPMENT_TYPE.RETIRO_EN_PLANTA.toString() && param.shipmentAddressStreetNumber && param.shipmentAddressStreetNumber !== '' && param.shipmentAddressStreetNumber !== 'null'
              ? Number(param.shipmentAddressStreetNumber)
              : null,
          floor:
            param.shipment_type !== BUDGET_SHIPMENT_TYPE.RETIRO_EN_PLANTA.toString() && param.shipmentAddressFloor && param.shipmentAddressFloor !== '' && param.shipmentAddressFloor !== 'null' ? param.shipmentAddressFloor : null,
          department:
            param.shipment_type !== BUDGET_SHIPMENT_TYPE.RETIRO_EN_PLANTA.toString() && param.shipmentAddressDepartment && param.shipmentAddressDepartment !== '' && param.shipmentAddressDepartment !== 'null'
              ? param.shipmentAddressDepartment
              : null,
          extra_address: null,
          comments:
            param.shipment_type !== BUDGET_SHIPMENT_TYPE.RETIRO_EN_PLANTA.toString() && param.shipmentAddressComments && param.shipmentAddressComments !== '' && param.shipmentAddressComments !== 'null'
              ? param.shipmentAddressComments
              : null,
          zip: param.shipment_type !== BUDGET_SHIPMENT_TYPE.RETIRO_EN_PLANTA.toString() && param.shipmentAddressZip && param.shipmentAddressZip !== '' && param.shipmentAddressZip !== 'null' ? Number(param.shipmentAddressZip) : null,
          city: cityShipment
        };

        const orderShipmentReceiver = {
          ...previousOrderData?.shipment?.receiver,
          order_shipment_receiver_id: previousOrderData?.shipment?.receiver?.order_shipment_receiver_id || null,
          first_name: orderBuyer.first_name,
          last_name: orderBuyer.last_name,
          email: orderBuyer.email,
          phone_number: orderBuyer.phone_number,
          document: orderBuyer.document,
          extra_address: null,
          comments: null,
          zip: param.shipment_type !== BUDGET_SHIPMENT_TYPE.RETIRO_EN_PLANTA.toString() && param.shipmentAddressZip ? Number(param.shipmentAddressZip) : null,
          city: cityShipment,
          document_type: orderBuyer.document_type
        };

        // let rs;
        // let rsb2b;
        const ordersB2B: any[] = [];

        if (!previousOrderData || isCopy) {
          /* eslint-disable-next-line */
          const operation = await libby.operation.save();
          methodsProducts.map(async (methodProduct, idx) => {
            const baseOrder = {
              order_id: null,
              owner_id: `budget - ${new Date().getTime()}`,
              tax: '0',
              shipping_cost: '0',
              updated_at: new Date(),
              returned_reason: '',
              print: false,
              created_at: new Date(),
              metadata: {},
              buyer: orderBuyer,
              state: orderState,
              updated_by: { account_id: userInfo?.id, username: userInfo?.userName }
            };

            const groupedByCompany = groupBy(methodProduct.product_list, 'product.company');
            const keys = Object.keys(groupedByCompany);
            for (let i = 0; i < keys?.length; i++) {
              const company = companyMarketplace.find((e) => e.company_id === Number(keys[i]));

              const shipmentTypeOdoo = shipmentTypeIdOdoo.find((e) => e.id.toString() === param.shipment_type.toString());
              const shipmentType = shipmentTypeOdoo?.group.find((e) => e.companyId.toString() === company?.company_id.toString());

              const orderShipment = {
                order_shipment_id: null,
                tracking: '',
                type: shipmentType?.type,
                updated_at: new Date(),
                deliver_estimate: new Date(),
                service: { courier_service_id: param.shipment_type },
                receiver: { ...orderShipmentReceiver },
                address: { ...orderShipmentAddress },
                state: { order_shipment_state_id: '3', name: 'preparation' },
                ...(param.shipment_type === BUDGET_SHIPMENT_TYPE.TRANSPORTE.toString() && { transportation_company: { transportation_company_id: param.transportation_company } })
              };

              /* eslint-disable-next-line */
              const osh = await libby.ster_order_shipment.save({ ...orderShipment });

              /* eslint-disable-next-line */
              const [source] = await libby.ster_order_source.query().equals('order_source_id', company?.order_source_id).run();

              const order = {
                ...baseOrder,
                shipment: osh,
                source,
                items: groupedByCompany[keys[i]],
                amount: groupedByCompany[keys[i]].reduce((prev, current) => (current.quantity > 1 ? prev + Number(current.unit_price) * current.quantity : prev + Number(current.unit_price)), 0).toString(),
                total: groupedByCompany[keys[i]].reduce((prev, current) => (current.quantity > 1 ? prev + Number(current.unit_price) * current.quantity : prev + Number(current.unit_price)), 0).toString()
              };

              if (!param.payment_method) {
                // eslint-disable-next-line no-continue
                continue;
              }

              /* eslint-disable-next-line */
              let rs = await saveOrder(order as unknown as Order);
              // eslint-disable-next-line no-await-in-loop
              const rsb2b = await libby.ster_order_b2b.save({
                order: { ...rs },
                createdByAccount: rs.updated_by,
                payment_method_id: methodProduct.payment_method_id,
                price_list_id: company?.id_lista,
                ...(methodProduct.custom && !Number.isNaN(+methodProduct.custom) && { custom_term: methodProduct.custom }),
                qty_deferred_payment: isCopy ? null : param.qty_deferred_payment,
                qty_delivery_days: isCopy ? null : param.qty_delivery_days,
                advanceSaleDate: param.advance_sale_date?.format('YYYY-MM-DD'),
                advanceSale: !!param.advance_sale_date
              });
              // eslint-disable-next-line no-await-in-loop
              await libby.operationcomposition.save({
                operation: { ...operation },
                percent: Number(methodProduct.payment_method_quantity),
                paymentMethodOdoo: methodProduct.payment_method_id
              });
              // TODO change custum api to chino sdk in ster-new-server
              // eslint-disable-next-line no-await-in-loop
              await libby.operationorder.save({
                operation: operation.operationId,
                orderB2B: rsb2b.orderB2bId
              });
              enqueueSnackbar(t('The order $$$$ successfully saved').replace('$$$$', rs.order_id), {
                variant: 'success'
              });
              ordersB2B.push(rs.order_id);
              if (idx === methodsProducts?.length - 1) {
                setSaving(false);
                history.push('/budget');
              }
            }
          });
        } else {
          const company = companyMarketplace.find((e) => e.company_id === (orderItems[0]?.product as Product & { company: number })?.company);
          const shipmentTypeOdoo = shipmentTypeIdOdoo.find((e) => e.id.toString() === param.shipment_type.toString());
          const shipmentType = shipmentTypeOdoo?.group.find((e) => e.companyId.toString() === company?.company_id.toString());

          const orderShipment = {
            ...previousOrderData.shipment,
            order_shipment_id: previousOrderData.shipment?.order_shipment_id,
            tracking: '',
            type: shipmentType?.type,
            updated_at: new Date(),
            deliver_estimate: new Date(),
            service: { courier_service_id: param.shipment_type },
            receiver: { ...orderShipmentReceiver },
            address: { ...orderShipmentAddress },
            state: { order_shipment_state_id: '3', name: 'preparation' },
            transportation_company: { transportation_company_id: param.shipment_type === BUDGET_SHIPMENT_TYPE.TRANSPORTE.toString() ? param.transportation_company : null }
          };

          if (previousOrderData?.orderB2Bs && Number(param.payment_method[0].id) !== Number(previousOrderData.orderB2Bs[0].payment_method_id)) {
            const editOperation = await libby.operationorder.query().equals('id', previousOrderData.orderB2Bs[0].orderB2bId).run();
            const editOperationComposition = await libby.operationcomposition
              .query()
              .equals('paymentMethodOdoo', Number(previousOrderData.orderB2Bs[0].payment_method_id))
              .and()
              .equals('operation', editOperation[0]?.operation_id)
              .run();
            if (editOperationComposition[0]?.operationCompositionId)
              await libby.operationcomposition.save({
                operationCompositionId: editOperationComposition[0].operationCompositionId,
                paymentMethodOdoo: param.payment_method[0].id
              });
          }

          const osh = await libby.ster_order_shipment.save({ ...orderShipment });

          const order = {
            ...previousOrderData,
            amount: orderItems.reduce((prev, current) => (current.quantity > 1 ? prev + Number(current.unit_price) * current.quantity : prev + Number(current.unit_price)), 0).toString(),
            total: orderItems.reduce((prev, current) => (current.quantity > 1 ? prev + Number(current.unit_price) * current.quantity : prev + Number(current.unit_price)), 0).toString(),
            buyer: orderBuyer,
            shipment: osh,
            items: orderItems,
            state: orderState
          };
          await saveOrder(order as unknown as Order);

          if (previousOrderData?.orderB2Bs) {
            await libby.ster_order_b2b.save({
              orderB2bId: previousOrderData?.orderB2Bs[0].orderB2bId,
              payment_method_id: param.payment_method[0].id,
              price_list_id: company?.id_lista,
              ...(!Number.isNaN(+param.custom_term) && { custom_term: param.custom_term }),
              qty_deferred_payment: param.qty_deferred_payment,
              qty_delivery_days: param.qty_delivery_days,
              advanceSaleDate: param.advance_sale_date?.format('YYYY-MM-DD') || null,
              advanceSale: !!param.advance_sale_date
            });
          }
          enqueueSnackbar(t('The order $$$$ successfully saved').replace('$$$$', previousOrderData?.order_id || ''), {
            variant: 'success'
          });
          setSaving(false);
          history.push('/budget');
        }
      } catch {
        enqueueSnackbar(t('Somethings Wrong With Order Creation'), { variant: 'error' });
        setSaving(false);
      }
    },
    [orderItems, libby, setSaving, previousOrderData, userInfo, enqueueSnackbar, t, saveOrder, isCopy, methodsProducts, history]
  );

  const editItem = useCallback(
    (orderItem: OrderItemType) => {
      setOrderItems((prev) => {
        const skuIndex = prev.findIndex((item) => item.sku === orderItem.sku);
        const newState = cloneDeep(prev);
        if (skuIndex >= 0) {
          newState[skuIndex] = orderItem;
        }

        return newState;
      });
    },
    [setOrderItems]
  );

  return { addItem, removeItem, orderItems, onSave, saving, editItem, setOrderItems, methodsProducts, setMethodsProducts };
};
