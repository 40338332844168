import { DatabaseConnector } from '@phinxlab/libby-rest-web';
import React from 'react';
import { LibbyObject } from '../../types';
import { StockLogsList } from './routes';

interface StockRawType {
  libby: LibbyObject;
}

const StockLogsRaw = ({ libby }: StockRawType) => {
  return <StockLogsList title="Stock Logs" libby={libby} />;
};

export const StockLogs = DatabaseConnector(StockLogsRaw)('ster_product_stock_logs');
