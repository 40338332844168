import Moment from 'moment';
import { LibbyFetchDAO } from '../LibbyFetchDAO';
import { FilterBarSelection } from '../../../../routes/Reporting/routes/ReportingList/FilterBar';

export class OrdersSoOrderDAO extends LibbyFetchDAO {
  // eslint-disable-next-line @typescript-eslint/no-useless-constructor
  constructor(name: string, pk: string) {
    super(name, pk);
  }

  getAll() {
    return this.aspect('list_order_so_order').query().limit(0, 10000).run();
  }

  async getByState(filter: FilterBarSelection = { marketplaces: [], courier: [] }) {
    let ls = this.aspect('list_order_so_order').query();
    // FIXME QUERY BUILDER HAVE TO DECIDE IF HAVE TO AUTOMATICALLY INSERT AN AND
    let needsAnd: boolean = false;

    if (filter.courier.length > 0) {
      if (needsAnd) ls = ls.and();
      ls = ls.groupStart();
      let first: boolean = true;
      filter.courier.forEach((courier) => {
        if (!first) ls = ls.or();
        ls = ls.equals('shipment.service.courier.courier_id', courier.id);
        first = false;
      });
      ls = ls.groupEnd();
      needsAnd = true;
    }

    if (filter.marketplaces.length > 0) {
      if (needsAnd) ls = ls.and();
      ls = ls.groupStart();
      let first: boolean = true;
      filter.marketplaces.forEach((marketplace) => {
        if (!first) ls = ls.or();
        ls = ls.equals('source.marketplace.marketplace_id', marketplace.marketplace_id);
        first = false;
      });
      ls = ls.groupEnd();
      needsAnd = true;
    }
    if (filter.from && filter.to) {
      if (needsAnd) ls = ls.and();
      ls = ls.groupStart().higherOrEquals('updated_at', Moment(filter.from).format()).lowerOrEquals('updated_at', Moment(filter.to).format()).groupEnd();
    }
    ls = await ls.aggregate().count('state.name', 'name').sum('amount', 'amount').groupBy('state.name').run();

    return ls;
  }

  getCityByOrderId(id: number) {
    return this.aspect('list_order_with_city').query().equals('order_id', id).run();
  }
}
