import React, { useState, useEffect, useCallback } from 'react';
import SearchIcon from '@material-ui/icons/Search';
import { Grid, TextField } from '@material-ui/core';
import moment, { Moment } from 'moment';
import { MakeFilter } from '../../../utils/MakeFilter';
import { FilterBar } from '../../../../../components/FilterBar';
import { DateClose } from '../../../../../components/DateClose';
import { gridBreakpoints } from '../../../../../const/gridBreakpoints';
import useDebounce from '../../../../../hooks/useDebounce';
import { useTranslation } from '../../../../../services/translation';
import { AnyObject } from '../../../../../types/types';

type SearchFiltersProps = {
  onFilterChange: (makeFilter: object) => void;
  filter: AnyObject;
};

const SearchFiltersRaw = ({ onFilterChange, filter }: SearchFiltersProps) => {
  const { t } = useTranslation();
  const initialDate = null;
  const [search, setSearch] = useState<string>(filter?.['0']?.[0]?.value || '');
  const [invoiceDate, setInvoiceDate] = useState<Moment | null>(filter?.invoiceDate?.length > 0 ? moment(filter.invoiceDate[0].value) : initialDate);
  const [shipmentDate, setShipmentDate] = useState<Moment | null>(filter?.shipmentDate?.length > 0 ? moment(filter.shipmentDate[0].value) : initialDate);
  const searchDebounced = useDebounce(search, 500);

  const handleReset = useCallback(() => {
    setSearch('');
    setInvoiceDate(initialDate);
    setShipmentDate(initialDate);
    onFilterChange(MakeFilter({}));
  }, [onFilterChange]);

  useEffect(() => {
    onFilterChange(
      MakeFilter({
        search: searchDebounced,
        shipmentDate,
        invoiceDate
      })
    );
  }, [onFilterChange, searchDebounced, invoiceDate, shipmentDate]);

  return (
    <FilterBar
      defaultFilter={
        <Grid item {...gridBreakpoints}>
          <TextField
            id="standard-search"
            placeholder={t('Search')}
            type="search"
            value={search}
            onChange={(event) => setSearch(event.target.value)}
            fullWidth
            InputLabelProps={{
              shrink: true
            }}
            InputProps={{
              endAdornment: <SearchIcon />
            }}
          />
        </Grid>
      }
      handleReset={handleReset}
    >
      <Grid item {...gridBreakpoints}>
        <DateClose name="Invoice Date" value={invoiceDate} onChange={setInvoiceDate} />
      </Grid>
      <Grid item {...gridBreakpoints}>
        <DateClose name="Shipment Date" value={shipmentDate} onChange={setShipmentDate} />
      </Grid>
    </FilterBar>
  );
};

export const SearchFilters = React.memo(SearchFiltersRaw);
