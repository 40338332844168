import { useLogsByOrderId } from '../../../../../hooks';
import { LibbyObject } from '../../../../../types';

interface useOrderLogsProps {
  libby: LibbyObject;
  id: number;
}

export const useOrderLogs = ({ libby, id }: useOrderLogsProps) => {
  const { working, data } = useLogsByOrderId(libby, {
    daoName: 'ster_order_logs',
    id
  });

  return {
    data,
    working
  };
};
export const useOrderBuyerLogs = ({ libby, id }: useOrderLogsProps) => {
  const { working: workingBuyer, data: dataBuyer } = useLogsByOrderId(libby, {
    daoName: 'ster_order_buyer_logs',
    id
  });

  return {
    workingBuyer,
    dataBuyer
  };
};
export const useOrderShipmentAddressLogs = ({ libby, id }: useOrderLogsProps) => {
  const { working: workingShipment, data: dataShipment } = useLogsByOrderId(libby, {
    daoName: 'ster_order_shipment_address_logs',
    id
  });

  return {
    workingShipment,
    dataShipment
  };
};
