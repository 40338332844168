import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Moment } from 'moment';
import { useField, useForm } from 'react-final-form-hooks';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';
import { ThemeProvider } from '@material-ui/styles';
import { Grid, Switch } from '@material-ui/core';
import { useModalFormStyles } from '../../../theme';
import { useTranslation } from '../../../services/translation';
import { FormProps } from '../../../types';
import { ResetForm } from '../../../components/ResetForm';
import { SelectHour } from '../../components/SelectHours';
import { SelectHourAmpM } from '../../components/SelectHoursAmPm';
import { convertFormatToHours24 } from '../../../utils/frecuendyHours';

export type CreateDispatchType = {
  courier: number;
  courier_service: number;
  dispatch_zone: number;
  observation: string;
  delivery_date: Moment;
  name: string;
};

export type EmailHourFormConfirmType = {
  hour: number | null;
};

export const EmailHourForm = ({
  open = false,
  onCancel = () => {},
  onConfirm = () => {},
  title = 'Confirmation Dialog',
  cancelText = 'Cancel',
  confirmText = 'confirmText',
  customTheme = null,
  validate,
  formInitialValues = {}
}: FormProps) => {
  const { t } = useTranslation();
  const styles = useModalFormStyles();

  const initState = useMemo(() => typeof formInitialValues.hour === 'number', [formInitialValues.hour]);

  const [state, setState] = useState(initState);

  useEffect(() => {
    setState(initState);
  }, [initState]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setState(event.target.checked);
  };

  const onSubmit = useCallback(
    async ({ hour, hourAmPm }) => {
      const result: EmailHourFormConfirmType = {
        hour: state ? convertFormatToHours24(hour.toString() === '0' ? 0 : parseInt(hour, 10), hourAmPm.toString() === '0') : null
      };

      onConfirm(result);
    },
    [onConfirm, state]
  );

  const { form, handleSubmit, submitError } = useForm({
    initialValues: formInitialValues,
    onSubmit,
    validate
  });
  const hour = useField('hour', form);
  const hourAmPm = useField('hourAmPm', form);

  const dialog = (
    <Dialog open={open} onClose={onCancel} fullWidth aria-labelledby="form-dialog-title">
      <DialogContent className={styles.title}>
        <Grid xs={12} spacing={1} container justify="center" alignItems="center" direction="row">
          <Grid item xs={8}>
            <Typography variant="h3">{t(title)}</Typography>
          </Grid>
          <Grid item xs={4}>
            <Grid xs={12} spacing={1} container justify="center" alignItems="center" direction="row">
              <Grid item xs={6}>
                <Switch checked={state} onChange={handleChange} name="checkedA" color="primary" inputProps={{ 'aria-label': 'secondary checkbox' }} />
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body2">{t(state ? 'Enabled' : 'Disabled')}</Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
      <form noValidate onSubmit={handleSubmit}>
        <DialogContent className={styles.content}>
          <Grid xs={12} spacing={1} container justify="center" alignItems="center" direction="row">
            <Grid item xs={8}>
              <SelectHour
                disabled={!state}
                inputProps={{
                  onChange: hour.input.onChange,
                  value: state ? hour.input.value : undefined
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <SelectHourAmpM
                disabled={!state}
                inputProps={{
                  onChange: hourAmPm.input.onChange,
                  value: state ? hourAmPm.input.value : undefined
                }}
              />
            </Grid>
          </Grid>
          {submitError && (
            <Typography style={{ display: 'block' }} variant="overline" color="error">
              {t(submitError)}
            </Typography>
          )}
        </DialogContent>
        <DialogActions>
          <ResetForm form={form} open={open} />
          <Button
            onClick={() => {
              onCancel();
            }}
          >
            {t(cancelText)}
          </Button>
          <Button color="primary" type="submit">
            {t(confirmText)}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );

  return !customTheme ? dialog : <ThemeProvider theme={customTheme}>{dialog}</ThemeProvider>;
};
