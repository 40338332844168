import React from 'react';
import { makeStyles } from '@material-ui/styles';
import Grid from '@material-ui/core/Grid';
import Zoom from '@material-ui/core/Zoom';
import { Create } from '@material-ui/icons';
import { IconButton, Tooltip } from '@material-ui/core';
import { InformationSection } from '../InformationSection';
import LoadingData from '../../routes/components/LoadingData';
import { useTranslation } from '../../services/translation';
import { greyText } from '../../theme/colors';

const useStyles = makeStyles({
  gridItem: {
    '& h5': {
      color: greyText,
      fontSize: 12
    },
    '& p': {
      fontSize: 16
    }
  },
  editButton: {
    position: 'relative',
    top: '-20px'
  }
});

export const DetailsData = ({ working, data, onEdit, readOnlyData, basicMapper }: { working?: boolean; data: any; onEdit?: Function; readOnlyData: boolean; basicMapper: Function }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <InformationSection title="" whiteBackground minimumPadding detailHeader>
      <Grid container justify="space-between" alignItems="flex-start" wrap="nowrap">
        <Grid className={classes.gridItem} container justify="space-between" spacing={1}>
          {working ? <LoadingData working={working} /> : <>{data && Object.entries(data).length > 0 && basicMapper(data)}</>}
        </Grid>

        {onEdit && !readOnlyData && (
          <Tooltip title={t('Edit')} TransitionComponent={Zoom} placement="top" arrow>
            <IconButton className={classes.editButton} onClick={() => onEdit({ ...data })}>
              <Create color="action" />
            </IconButton>
          </Tooltip>
        )}
      </Grid>
    </InformationSection>
  );
};
