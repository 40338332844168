import { useCallback, useMemo, useState } from 'react';
import { useSnackbar } from 'notistack';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { SerialNumberDialog } from '../components';
import { LibbyObject, RowsType } from '../../../../../types';
import { Collect } from '../../../../../interfaces/business';
import { ButtonDetailsType } from '../../../../../types/Button';
import { pickControlColumns } from '../utils/PickControlColumns';
import CustomModal from '../../../../../services/customFormDialog';
import { useTranslation } from '../../../../../services/translation';
import { OptionsLibbyFetch, useLibbyFetch } from '../../../../../hooks';
import { ScannerDialog } from '../../../../../components/ScannerDialog';

type usePickControlProps = {
  libby: LibbyObject;
  paramsFetch: OptionsLibbyFetch;
};

const ScannerModal = CustomModal(ScannerDialog);
const SerialNumberModal = CustomModal(SerialNumberDialog);

export const usePickControlLogic = ({ libby, paramsFetch }: usePickControlProps) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { path } = useRouteMatch();
  const { enqueueSnackbar } = useSnackbar();
  const [serchingSerialNumber, setSerchingSerialNumber] = useState<boolean>(false);

  const { data, working, fetchMore, reFetch } = useLibbyFetch(libby, paramsFetch);

  const rows: RowsType[] = useMemo(() => {
    const dataCollect = data as Collect[];

    return dataCollect.map((collect: Collect) => {
      return {
        ...collect,
        id: collect.collect_id,
        collectState: t(collect.collectState.name)
      };
    });
  }, [data, t]);

  const goToCollect = useCallback(
    (collect_id: string) => {
      history.push(`${path}/detail/${collect_id}`);
    },
    [history, path]
  );

  const handleScanner = useCallback(
    async (type: string) => {
      const rest: any = await ScannerModal.show({
        title: type === 'product' ? t('Search Serial Number') : t('Search Cart'),
        typeScanner: type,
        serialNumberInput: true
      });

      if (rest) {
        switch (type) {
          case 'product': {
            try {
              setSerchingSerialNumber(true);
              const dataSerialNumber = await libby.ster_collect_item_product.query().equals('serial_number', rest).orderBy('collect_item_product_id', 'desc').limit(0, 1).run();

              if (dataSerialNumber.length) {
                setSerchingSerialNumber(false);
                await SerialNumberModal.show({
                  title: t('Serial number information'),
                  collectItem: dataSerialNumber[0],
                  goToCollect
                });
              } else {
                enqueueSnackbar(`${t('Invalid Serial Number')}`, { variant: 'error' });
              }
            } catch (error) {
              console.log(error);
            } finally {
              setSerchingSerialNumber(false);
            }
            break;
          }
          case 'cart':
            history.push(`${path}/detail/${rest}/cart`);
            break;
          default:
            break;
        }
      }
    },
    [enqueueSnackbar, goToCollect, history, libby.ster_collect_item_product, path, t]
  );

  const buttons: ButtonDetailsType[] = useMemo(
    () => [
      {
        id: 'validateCart',
        onClick: () => handleScanner('cart'),
        title: t('Validate cart'),
        variant: 'contained'
      },
      {
        id: 'validateSerialNumber',
        onClick: () => handleScanner('product'),
        title: t('Validate Serial Number'),
        variant: 'contained'
      }
    ],
    [handleScanner, t]
  );

  const onRowClick = (row: LibbyObject) => {
    goToCollect(row.collect_id);
  };

  return {
    working,
    rows,
    fetchMore,
    reFetch,
    columns: pickControlColumns,
    buttons,
    onRowClick,
    serchingSerialNumber
  };
};
