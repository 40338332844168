export const pickControlColumns = [
  {
    id: 'collect_id',
    label: 'Collect ID',
    orderById: 'collect_id'
  },
  {
    id: 'dispatch.dispatch_id',
    label: 'Dispatch ID',
    orderById: 'dispatch.dispatch_id',
    render: (row: any) => row.dispatch.dispatch_id
  },
  {
    id: 'cart.cart_id',
    label: 'Cart ID',
    orderById: 'cart.cart_id',
    render: (row: any) => row.cart.cart_id
  },
  {
    id: 'collectState.name',
    label: 'State',
    orderById: 'collectState.name',
    render: ({ collectState }: { collectState: string }) => collectState
  },
  {
    id: 'account.username',
    label: 'User assigned',
    orderById: 'account.username',
    render: (row: any) => row.account.username
  }
];
