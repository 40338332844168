import { LibbyFetchDAO } from './LibbyFetchDAO';

export class ObjectivePeriodDAO extends LibbyFetchDAO {
  constructor() {
    super('objective_period', 'objective_period_id');
  }

  getAll() {
    return this.query().run();
  }
}
