import React, { useCallback, useMemo } from 'react';
import { ThemeProvider } from '@material-ui/styles';
import { Dialog, DialogActions, DialogContent, TextField, Typography, Button } from '@material-ui/core';
import { useField, useForm } from 'react-final-form-hooks';
import { useTranslation } from '../../../services/translation';
import { makeGetErrorAndHelperText, textFieldProps } from '../../../utils/materialHelpers';
import { ResetForm } from '../../../components/ResetForm';
import { FormProps } from '../../../types';
import { useModalFormStyles } from '../../../theme';
import { AutocompleteCity } from '../../../components/AutocompleteCity';
import { GeneralSelect } from '../../../components/GeneralSelect';

export const CarrierFormDialog = ({
  open = false,
  onCancel = () => {},
  onConfirm = () => {},
  title = 'Confirmation Dialog',
  cancelText = 'Cancel',
  confirmText = 'confirmText',
  customTheme = null,
  validate,
  formInitialValues = {},
  states = [],
  fetchingStates = false
}: FormProps) => {
  const { t } = useTranslation();
  const styles = useModalFormStyles();
  const getErrorAndHelperText = makeGetErrorAndHelperText(t);

  const onSubmit = useCallback(
    async ({ name, street, street_number, phone_number, city, department, floor, zip, state }) => onConfirm({ name, street, street_number, phone_number, city, department, floor, zip, state }),
    [onConfirm]
  );

  const stateOptions = useMemo(() => states.map((s: any) => ({ id: s.state_id, value: s.name })), [states]);

  const { form, handleSubmit, submitError } = useForm({
    initialValues: {
      ...formInitialValues,
      state: stateOptions.find((state: any) => formInitialValues.city?.state?.name === state.value)?.id || undefined,
      city: formInitialValues.city?.name || undefined
    },
    onSubmit,
    validate
  });
  const name = useField('name', form);
  const transportistState = useField('state', form);
  const transportistCity = useField('city', form);
  const street = useField('street', form);
  const street_number = useField('street_number', form);
  const floor = useField('floor', form);
  const department = useField('department', form);
  const zip = useField('zip', form);
  const phone_number = useField('phone_number', form);

  const dialog = (
    <Dialog open={open} onClose={onCancel} fullWidth aria-labelledby="form-dialog-title">
      <DialogContent className={styles.title}>
        <Typography variant="h3">{title}</Typography>
      </DialogContent>
      <form noValidate onSubmit={handleSubmit}>
        <DialogContent className={styles.content}>
          <TextField
            {...textFieldProps(`${t('Carrier')} ${t('Name')}`)}
            onChange={name.input.onChange}
            value={name.input.value}
            variant="standard"
            InputLabelProps={{
              shrink: true,
              ...getErrorAndHelperText(name),
              required: true
            }}
          />
          <GeneralSelect
            required
            label={`${t('Province')}`}
            optionAll={false}
            data={stateOptions}
            working={fetchingStates}
            inputProps={{
              onChange: transportistState.input.onChange,
              value: transportistState.input.value,
              ...getErrorAndHelperText(transportistState),
              required: true,
              style: { textAlign: 'left' }
            }}
          />
          <AutocompleteCity
            name="city"
            inputProps={{
              province: transportistState.input.value,
              onChange: transportistCity.input.onChange,
              value: transportistCity.input.value,
              error: { ...getErrorAndHelperText(transportistCity) },
              onBlur: transportistCity.input.onBlur,
              InputLabelProps: { shrink: true },
              required: true
            }}
          />
          <TextField
            {...textFieldProps(`${t('Street')}`)}
            onChange={street.input.onChange}
            value={street.input.value}
            variant="standard"
            InputLabelProps={{
              shrink: true,
              ...getErrorAndHelperText(street),
              required: true
            }}
          />
          <TextField
            {...textFieldProps(`${t('Street Number')}`)}
            onChange={street_number.input.onChange}
            value={street_number.input.value}
            variant="standard"
            InputLabelProps={{
              shrink: true,
              ...getErrorAndHelperText(street_number),
              required: true
            }}
          />
          <TextField
            {...textFieldProps(`${t('Floor')}`)}
            onChange={floor.input.onChange}
            value={floor.input.value}
            variant="standard"
            InputLabelProps={{
              shrink: true,
              ...getErrorAndHelperText(floor),
              required: false
            }}
          />
          <TextField
            {...textFieldProps(`${t('Department')}`)}
            onChange={department.input.onChange}
            value={department.input.value}
            variant="standard"
            InputLabelProps={{
              shrink: true,
              ...getErrorAndHelperText(department),
              required: false
            }}
          />
          <TextField
            {...textFieldProps(`${t('Zip')}`)}
            onChange={zip.input.onChange}
            value={zip.input.value}
            variant="standard"
            InputLabelProps={{
              shrink: true,
              ...getErrorAndHelperText(zip),
              required: true
            }}
          />
          <TextField
            {...textFieldProps(`${t('Phone Number')}`)}
            onChange={phone_number.input.onChange}
            value={phone_number.input.value}
            variant="standard"
            InputLabelProps={{
              shrink: true,
              ...getErrorAndHelperText(phone_number),
              required: true
            }}
          />
          {submitError && (
            <Typography style={{ display: 'block' }} variant="overline" color="error">
              {t(submitError)}
            </Typography>
          )}
        </DialogContent>
        <DialogActions>
          <ResetForm form={form} open={open} />
          <Button
            onClick={() => {
              onCancel();
            }}
          >
            {cancelText}
          </Button>
          <Button color="primary" type="submit">
            {confirmText}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );

  return !customTheme ? dialog : <ThemeProvider theme={customTheme}>{dialog}</ThemeProvider>;
};
