import React from 'react';
import { Box, capitalize } from '@material-ui/core';
import { OrderStateColors } from '../../const/OrderState';
import { OrderType } from '../../types';
import { ORDER_STATE } from '../../const/OrderState';

export const CommonOrderColumns = {
  actions: {
    id: 'actions',
    label: '',
    noSort: true
  },
  orderId: {
    id: 'order_id',
    label: 'ID Order',
    style: {
      whiteSpace: 'nowrap',
      minWidth: '150px'
    }
  },
  orderState: {
    id: 'order_state',
    label: 'State',
    style: {
      textAlign: 'center'
    },
    render: ({ order_state_id, order_state_name, chargeback }: OrderType, item: any, t: (str: string) => string) => {
      const isFraudWithChargeback = chargeback && order_state_id && order_state_id.toString() === ORDER_STATE.FRAUD.toString();
      return (
        <Box color={OrderStateColors[isFraudWithChargeback ? 9 : order_state_id ?? '']} whiteSpace="nowrap">
          {t(capitalize(order_state_name ?? ''))}
        </Box>
      );
    },
    orderById: 'state.name'
  },
  ownerId: {
    id: 'owner_id',
    label: 'Owner ID',
    style: {
      whiteSpace: 'nowrap',
      minWidth: '150px'
    },
    orderById: 'owner_id'
  },
  paid_in_advance: {
    id: 'paid_in_advance',
    label: 'Paid in advance',
    style: {
      whiteSpace: 'nowrap',
      minWidth: '100px'
    },
    render: ({ paid_in_advance }: { paid_in_advance: string }, item: any, t: (str: string) => string) => (
      <Box color={OrderStateColors[paid_in_advance !== null ? (paid_in_advance ? 9 : 10) : 3]} maxWidth={'100px'} whiteSpace="nowrap" style={{ textAlign: 'center' }}>
        {t(capitalize(paid_in_advance !== null ? (paid_in_advance ? 'Yes' : 'No') : 'Undefined'))}
      </Box>
    ),
    orderById: 'paid_in_advance'
  },
  advance_sale: {
    id: 'advance_sale',
    label: 'Advance sale',
    style: {
      whiteSpace: 'nowrap',
      minWidth: '100px'
    },
    render: ({ advance_sale }: { advance_sale: string }, item: any, t: (str: string) => string) => (
      <Box maxWidth={'100px'} whiteSpace="nowrap" style={{ textAlign: 'center' }}>
        {t(capitalize(advance_sale !== null ? (advance_sale ? 'Yes' : 'No') : 'Undefined'))}
      </Box>
    ),
    noSort: true
  },
  marketplace: {
    id: 'marketplace',
    label: 'Marketplace',
    style: {
      textAlign: 'center'
    },
    orderById: 'source.marketplace.name'
  },
  buyer: {
    id: 'buyer',
    label: 'Buyer',
    style: {
      whiteSpace: 'nowrap',
      minWidth: '150px'
    },
    orderById: 'buyer.first_name'
  },
  business: {
    id: 'business',
    label: 'Business',
    orderById: 'business.name'
  },
  document: {
    id: 'document',
    label: 'DNI',
    orderById: 'buyer.document'
  },
  phoneNumber: {
    id: 'phone_number',
    label: 'Phone',
    orderById: 'buyer.phone_number'
  },
  documents: {
    id: 'documents',
    label: 'Number of Documents',
    style: {
      whiteSpace: 'nowrap'
    },
    noSort: true
  },
  items: {
    id: 'items',
    label: 'Number of Items',
    style: {
      whiteSpace: 'nowrap'
    },
    orderById: 'number_items'
  },
  courier: {
    id: 'courier',
    label: 'Courier',
    render: ({ courier }: OrderType, item: any, t: (str: string) => string) => (
      <Box whiteSpace="nowrap" textAlign="center">
        {t(capitalize(courier ?? ''))}
      </Box>
    ),
    orderById: 'shipment.service.courier.name'
  },
  service: {
    id: 'service',
    label: 'Service',
    render: ({ service }: OrderType, item: any, t: (str: string) => string) => t(capitalize(service ?? '')),
    style: {
      whiteSpace: 'nowrap',
      minWidth: '150px'
    },
    orderById: 'shipment.service.name'
  },
  total: {
    id: 'total',
    label: 'Total',
    orderById: 'total'
  },
  updatedBy: {
    id: 'updated_by',
    label: 'Updated by',
    style: {
      whiteSpace: 'nowrap',
      minWidth: '150px'
    },
    noSort: true
  },
  date: {
    id: 'date',
    label: 'Change date',
    style: {
      whiteSpace: 'nowrap',
      minWidth: '150px'
    },
    orderById: 'updated_at'
  },
  created_at: {
    id: 'created_at',
    label: 'Origin date',
    style: {
      whiteSpace: 'nowrap',
      minWidth: '150px'
    },
    noSort: true
  },
  detail: {
    id: 'detail',
    label: 'Detail',
    noSort: true
  },
  logs: {
    id: 'logs',
    label: 'Logs',
    noSort: true
  },
  soNumber: {
    id: 'so_number',
    label: 'Number so',
    style: {
      whiteSpace: 'nowrap'
    },
    orderById: 'so_number'
  },
  zipCode: {
    id: 'zip_code',
    label: 'Zip Code',
    style: {
      whiteSpace: 'nowrap'
    },
    orderById: 'buyer.zip'
  },
  domicile: {
    id: 'domicile',
    label: 'Domicile',
    noSort: true
  },
  statePrinting: {
    id: 'state_printing',
    label: 'Printing status',
    style: {
      whiteSpace: 'nowrap'
    },
    noSort: true,
    render: ({ state_printing }: OrderType, item: any, t: (str: string) => string) => <Box textAlign="center">{t(state_printing ?? '')}</Box>
  },
  returnedReason: {
    id: 'returned_reason',
    label: 'Order Comment',
    style: {
      whiteSpace: 'nowrap'
    }
  },
  message: {
    id: 'message',
    label: 'Message',
    noSort: true
  }
};
