import React from 'react';
import { Box, Card } from '@material-ui/core';
import { useTranslation } from '../../services/translation';
import { CustomTooltipType } from './interfaces/CustomToolTip';
import { capitalizeFirstLetter } from '../../utils/letter';

export const CustomTooltip = (prop: any) => {
  const { active, payload, dataFormatPayload, dataIgnorePayload = [] }: CustomTooltipType = prop;
  const { t } = useTranslation();

  if (active && payload?.length) {
    return (
      <Card>
        <Box bgcolor="white" p={2}>
          {payload?.[0] &&
            Object.entries(payload[0].payload).length &&
            Object.entries(payload[0].payload)
              .filter((text) => !dataIgnorePayload.includes(text[0]))
              .map((dataPayload: any) => {
                const formatPayload = dataFormatPayload?.find((dataFormat) => dataFormat.name === dataPayload[0]);
                return <p className="label">{`${t(capitalizeFirstLetter(dataPayload[0]))}: ${formatPayload?.format ? formatPayload.format(dataPayload[1]) : dataPayload[1]}`}</p>;
              })}
        </Box>
      </Card>
    );
  }
  return null;
};
