import { makeValidator, ValidatorType, ValidatorRule } from '../../../../utils';

export const formInitialValues = {
  name: '',
  state_id: '',
  matches: '',
  enabled: true
};

const validations: ValidatorRule[] = [
  { key: 'name', validators: [ValidatorType.REQUIRED] },
  { key: 'state_id', validators: [ValidatorType.REQUIRED] },
  { key: 'zip', validators: [ValidatorType.REQUIRED] },
  { key: 'list', validators: [ValidatorType.REQUIRED] },
  { key: 'matches', validators: [ValidatorType.REQUIRED] }
];

export const validate = makeValidator(validations);

export const getValidate = (keys: Array<string>) => makeValidator(keys.length > 0 ? validations.filter((val) => keys.includes(val.key)) : validations);
