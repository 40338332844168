import { makeStyles } from '@material-ui/styles';
import { Button, Collapse, Dialog, DialogActions, DialogContent, Typography } from '@material-ui/core';
import { useTranslation } from '../../../services/translation';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import { MouseEventHandler, useState } from 'react';
import { JsonEditor } from 'components/JsonEditor';

const useStyles = makeStyles({
  root: {
    '& .MuiDialogContent-root': {
      overflowY: 'unset !important'
    },
    '& .MuiTextField-root': {
      width: '100%'
    }
  }
});

const dummyFunc = () => {};

export const ProductErrorModalDialog = ({ open = false, title, data, onCancel = dummyFunc }: { open: boolean; title: string; data: any; onCancel: MouseEventHandler }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Dialog open={open} onClose={onCancel} aria-labelledby="form-dialog-title" className={classes.root} maxWidth="md">
      <DialogContent>
        <Typography variant="h6">{title}</Typography>
        {data && data.map((error: any) => <MarketCollapse market={error.marketplace_name} error={error.error} />)}
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel} style={{ backgroundColor: '#FF5179', color: '#FFFFFF' }} fullWidth>
          {t('Close')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const MarketCollapse = ({ market, error }: { market: string; error: any }) => {
  const [open, setOpen] = useState(false);

  return (
    <div style={{ marginTop: '20px' }}>
      <Button fullWidth onClick={() => setOpen(!open)} style={{ justifyContent: 'flex-start' }}>
        {open ? <ExpandLess style={{ color: '#FF5179' }} /> : <ExpandMore style={{ color: '#FF5179' }} />}
        {market}
      </Button>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <JsonEditor key={`json-editable-${market}`} value={error} readOnly={true} />
      </Collapse>
    </div>
  );
};
