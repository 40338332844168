import { Libby, Database, RESTSessionStrategy, RESTStoreStrategy } from '@phinxlab/libby-rest-web';
import { OrderAreaTimeDAO } from './dao/OrderAreaTimeDAO';
import { OrderTotalTimeDAO } from './dao/OrderTotalTimeDAO';
import { OrderReportDetailDAO } from './dao/OrderReportDetailDAO';
/* eslint-disable class-methods-use-this */
import { TokenManager } from './TokenManager';
import { SessionChangeEmitter } from './session';
import { API_URL } from '../../config';
import {
  AccountRoleDAO,
  AdminGenerateReportDAO,
  AdminAccountDAO,
  SterConfigDAO,
  DispatchCollectDAO,
  DispatchCartDAO,
  DispatchCollectDetailsDAO,
  DispatchCollectItemDAO,
  DispatchCollectAddOrder,
  OrderReportDetailGroupDAO,
  InboxOrdersSearchImportationDAO,
  DispatchCartEnabledDAO,
  DispatchCartBoxDAO,
  SterProcessMonitorAlertDAO,
  DispatchCollectCloseDAO,
  DispatchZoneEnabledDAO,
  DispatchCollectState,
  DispatchCartWithCollectDAO,
  CollectItemProductSearchDAO,
  DispatchStateDAO,
  ProductStockDAO,
  DispatchUpdateDAO,
  DispatchDetailsUpdateDAO,
  DispatchDetailsDAO,
  DispatchItemSendDAO,
  DispatchSendDAO,
  InboxOrderCustomDAO,
  DispatchUploadDocumentsManifestDAO,
  // DispatchManifestDAO,
  ShippingTagsCustomDAO,
  ShippingTagsUpdateDAO,
  HistoryByMarketplaceDAO,
  OrderItemsBySkuDAO,
  OrderBuyerLogsDAO,
  OrderShipmentAddressLogsDAO,
  OrderCancelDAO,
  MessageDAO,
  MessageSourceDAO,
  MessageStateDAO,
  MessageTransportDAO,
  MessageTypeDAO,
  MessageReasonDAO,
  MessageUpdatedDAO,
  UploadDocumentsGenerateDAO,
  OrderDocumentGenerateDAO,
  StockProductsUpdateDAO,
  AccountRetoolDAO,
  CompanyDAO,
  CompanyMarketplaceDAO,
  OrderSourceTypeDAO,
  AlertDAO,
  OrdersDAO,
  OrdersSoOrderWithDeliveryDateDAO,
  FilterDAO,
  GoalDAO,
  GoalTypeDAO,
  GoalProductDAO,
  GoalMarketplaceDAO,
  GoalTypeUpdateDAO,
  GoalProductUpdateDAO,
  GoalMarketplaceUpdateDAO,
  GoalProductEnabledDAO,
  GoalMarketplaceEnabledDAO,
  OrderCancelReasonDAO,
  SterConfigLogDAO,
  GoalListDateDAO,
  GoalPeriodDAO,
  GoalPeriodUpdateDAO,
  GoalFieldEnabledDAO,
  OrderReportingOrdersDAO,
  OrderReportingOrdersHistoryByMarketplaceDAO,
  MarketPlaceSlaDAO,
  MarketPlaceSlaUpdateDAO,
  MarketPlaceSlaUnassignedDAO,
  OrderReportingDeliveryControlDAO,
  OrderReportingDeliveryControlGroupDAO,
  OrderB2BVwDAO,
  NonWorkingDayDAO,
  NonWorkingDayUpdateDAO,
  GoalUserDAO,
  GoalUserUpdateDAO,
  OrderLiquidationDAO,
  NewOrderLiquidationDAO,
  OrderReportingLogisticJustifyLogsDAO,
  OrderReportingLogisticJustifyDAO,
  GoalRangeTypeDAO,
  YearWeekDAO,
  ObjectivePeriodDAO,
  OrderLiquidationGroupDAO,
  TransportationCompanyDAO,
  OrderB2BCommentDAO,
  OrderReportingDashboardDAO,
  CollectSerialNumberDAO,
  CategoryDAO,
  OrderReportingAndreaniTrackingDAO,
  TransportationCompanySearchDAO,
  AvailableMLPeriodsDAO,
  OperationDAO,
  OperationCompositionDAO,
  OperationOrderDAO,
  BudgetDAO,
  StockMarketplaceDAO,
  ClientsDAO,
  OrderStateMarketplaceDAO,
  BrandDAO,
  CategoryBulkLoadDAO,
  MarketplaceChargebackDAO,
  MarketplaceFraudDAO,
  ChargebackReportDAO,
  ChargebackReportCityDAO,
  ChargebackReportStreetDAO,
  ChargebackReportProductDAO
} from './dao';

import { ImportationDAO } from './dao/ImportationDAO';
import { ImportationTypeDAO } from './dao/ImportationTypeDAO';
import { ImportationStatusDAO } from './dao/ImportationStatusDAO';
import { ERPOrderDAO } from './dao/ERPOrderDAO';
import { InboxOrdersDAO } from './dao/InboxOrdersDAO';
import { LogisticsServiceDAO } from './dao/LogisticsServiceDAO';
import { CitiesDAO } from './dao/CitiesDAO';
import { StateDAO } from './dao/StateDAO';

import { OrderCourierDAO } from './dao/OrderCourierDAO';
import { OrderSourceDAO } from './dao/OrderSourceDAO';
import { OrderLogsDAO } from './dao/OrderLogsDAO';
import { OrderStateDAO } from './dao/OrderStateDAO';
import { MarketPlaceDAO } from './dao/MarketPlaceDAO';
import { OrderShipmentDAO } from './dao/OrderShipmentDAO';
import { OrdersSoDAO } from './dao/OrdersSoDAO';
import { OrdersPrintOrangeStoreDAO } from './dao/OrdersPrintOrangeStoreDAO';
import { DispatchZoneDAO } from './dao/DispatchZoneDAO';
import { DispatchListDAO } from './dao/DispatchListDAO';
import { DispatchItemDAO } from './dao/DispatchItemDao';
import { CollectItemProductDAO } from './dao/CollectItemProductDAO';
import { OrderDocumentDAO } from './dao/OrderDocumentDAO';
import { OrderForDispatchDAO } from './dao/OrderForDispatchDAO';
import { OrderPrintDAO } from './dao/OrderPrintDao';
import { OrdersSoPrintTagDAO } from './dao/OrdersSoPrintTagDAO';
import { OrdersTableDAO } from './dao/OrdersTableDAO';
import { OrderItemDAO } from './dao/OrderItemDao';
import { OrderReportingPendingAndreaniIncomeDAO } from './dao/OrderReportingPendingAndreaniIncomeDAO';
import { DispatchToSaveDAO } from './dao/DistpatchToSaveDAO';
import { OrderItemWithProductDAO } from './dao/OrderItemWithProductDAO';
import { OrdersTableUpdateDAO } from './dao/OrdersTableUpdateDAO';
import { StockProductsDAO } from './dao/StockProductsDAO';
import { MessageConfigUpdateDAO, MessageConfigDAO, MessageFromDAO, MessageTemplateDAO, MessageTemplateUpdateDAO, MessageConfigAlertDAO, MessageConfigAlertUpdateDAO } from './dao/Messages';

import { OrderDocumentTypeDAO } from './dao/OrderDocumentTypeDAO';
import { StockProductsLogsDAO } from './dao/StockProductLogsDAO';
import { AlertUpdatedDAO } from './dao/Public/AlertUpdatedDAO';
import { OrderReportingObjectiveSalesDAO } from './dao/Order/OrderReportingObjectiveSalesDAO';
import { GoalUpdateDAO } from './dao/Goal/GoalUpdateDAO';
import { GoalTypeValueDAO } from './dao/Goal/GoalTypeValue';
import {
  OrderLiberateDAO,
  OrderReportByTransportDAO,
  OrderReportMlDAO,
  OrderReportingSalesTaxationAllDAO,
  OrderReportingSalesAllDAO,
  OrderB2BDAO,
  OrderReportB2BStockDAO,
  JustifiedOrdersTypesDAO,
  GenerateOrderDocumentUrlDAO,
  ProductFeaturesCopyDAO,
  ProductFeaturesBulkDAO,
  ValidateOrderItemDAO
} from './dao/Order';
import { ProductDAO } from './dao/Product/ProductDAO';
import { FeatureDAO } from './dao/Product/FeatureDAO';
import { FeatureGroupDAO } from './dao/Product/FeatureGroupDAO';
import { OrderBuyerDAO } from './dao/OrderBuyerDAO';
import { NewOrderLiquidationConfirmDAO } from './dao/Order/NewOrderLiquidationConfirmDAO';
import { BuyerOdooDAO } from './dao/BuyerOdooDAO';
import { ReportingDeliverOrderDAO } from './dao/Order/ReportingDeliverOrderDAO';
import { PaymentCurrencyDAO, PaymentMethodOdooDAO } from './dao/Payment';
import { VerifyOrdersDAO } from './dao/Order/VerifyOrdersDAO';
import { ProcessConfigDAO } from './dao/Process/ProcessConfigDAO';
import { PaymentMethodCurrencyDAO } from './dao/Payment/PaymentMethodCurrencyDAO';
import { ProductStockSyncErrorDAO } from './dao/Product/ProductStockSyncErrorDAO';
import { BrandBulkLoadDAO, StockDaysReportDAO } from './dao';

class DatabaseStoreRaw {
  chinoTokenEnvironmet: string;

  url: string;

  constructor() {
    this.chinoTokenEnvironmet = 'SterApp';
    // TODO: implement
    this.url = API_URL;
  }

  addSession(): void {
    const Session = RESTSessionStrategy.init(this.url);
    Session.setTokenManager(TokenManager.create());
    Database.addSessionStrategy(Session);
    Session.addSessionListener({
      // TODO: remove any type
      newSessionState: (state: any) => {
        const { user } = Session;
        const isLogged: boolean = !!user && user.isGuest === false;
        SessionChangeEmitter.emitChange({ isLogged, user, state });
      }
    });
    Database.addSessionStrategy(Session);
  }

  register(): void {
    Database.register(new RESTStoreStrategy('ster_product', '/api/product/product', 'product_id'), ProductDAO);
    Database.register(new RESTStoreStrategy('ster_product_feature_group', '/api/product/featureGroup', 'feature_group_id'), FeatureGroupDAO);
    Database.register(new RESTStoreStrategy('ster_product_feature', '/api/product/feature', 'feature_id'), FeatureDAO);
    Database.register(new RESTStoreStrategy('ster_product_copy_features', '/api/custom/product/features/copy', ''), ProductFeaturesCopyDAO);
    Database.register(new RESTStoreStrategy('ster_product_bulk_features', '/api/custom/product/features/bulk', ''), ProductFeaturesBulkDAO);
    Database.register(new RESTStoreStrategy('admin_account_role', '/api/ster/accountrole', 'account_role_id'), AccountRoleDAO);
    Database.register(new RESTStoreStrategy('admin_generate_report', '/admin/generate_report', 'generate_report_id'), AdminGenerateReportDAO);
    Database.register(new RESTStoreStrategy('admin_account', '/api/ster/account', 'account_id'), AdminAccountDAO);
    // TODO: CHECK DIFFERENCE BTW PUBLIC/ADM
    Database.register(new RESTStoreStrategy('app_importation', '/api/ster/importation', 'importation_id'), ImportationDAO);
    Database.register(new RESTStoreStrategy('public_importation_type', '/api/ster/importationtype', 'importation_type_id'), ImportationTypeDAO);
    Database.register(new RESTStoreStrategy('public_importation_status', '/api/ster/importationstatus', 'importation_status_id'), ImportationStatusDAO);
    Database.register(new RESTStoreStrategy('ster_order_total_time', '/api/orders/ordertotaltime', 'id'), OrderTotalTimeDAO);
    Database.register(new RESTStoreStrategy('ster_order_report', '/api/orders/orderreportdetail', 'order_id'), OrderReportDetailDAO);
    Database.register(new RESTStoreStrategy('ster_order_area_time', '/api/orders/orderareatime', 'id'), OrderAreaTimeDAO);
    Database.register(new RESTStoreStrategy('ster_order_for_dispatch', '/api/orders/orderfordispatch', 'order_id'), OrderForDispatchDAO);
    Database.register(new RESTStoreStrategy('ster_order', '/api/orders/ordersoorder', 'order_id'), OrdersDAO);
    Database.register(new RESTStoreStrategy('ster_order_table', '/api/orders/order', 'order_id'), OrdersTableDAO);
    Database.register(new RESTStoreStrategy('order_state_marketplace', '/api/custom/order/marketplace_states', 'order_id'), OrderStateMarketplaceDAO);

    Database.register(new RESTStoreStrategy('ster_order_so', '/api/orders-so/ordersoprint', 'order_id'), OrdersSoDAO);
    Database.register(new RESTStoreStrategy('ster_inbox_order', '/api/inbox/inboxorder', 'inbox_order_id'), InboxOrdersDAO);
    Database.register(new RESTStoreStrategy('courier_service', '/api/courier/courierservice', 'courier_service_id'), LogisticsServiceDAO);

    Database.register(new RESTStoreStrategy('ster_city', '/api/general/city', 'city_id'), CitiesDAO);
    Database.register(new RESTStoreStrategy('ster_state', '/api/general/state', 'state_id'), StateDAO);
    Database.register(new RESTStoreStrategy('ster_client', '/api/general/clientdocumentcustom', 'client_document_id'), ClientsDAO);
    Database.register(new RESTStoreStrategy('courier_order', '/api/courier/courier', 'courier_id'), OrderCourierDAO);
    Database.register(new RESTStoreStrategy('ster_transportation_company', '/api/courier/transportationcompany', 'transportation_company_id'), TransportationCompanyDAO);
    Database.register(new RESTStoreStrategy('ster_transportation_company_search', '/api/courier/transportationcompanysearch', 'transportation_company_id'), TransportationCompanySearchDAO);
    Database.register(new RESTStoreStrategy('ster_order_source', '/api/orders/ordersource', 'order_source_id'), OrderSourceDAO);
    Database.register(new RESTStoreStrategy('ster_order_logs', '/api/orders/orderlogs', 'order_log_id'), OrderLogsDAO);
    Database.register(new RESTStoreStrategy('ster_order_document', '/api/orders/orderdocument', 'order_document_id'), OrderDocumentDAO);
    Database.register(new RESTStoreStrategy('ster_print_orange_store', '/api/orders/print/orange-store/ordersoprint', 'order_id'), OrdersPrintOrangeStoreDAO);
    Database.register(new RESTStoreStrategy('ster_order_shipment', '/api/orders/ordershipment', 'order_shipment_id'), OrderShipmentDAO);
    Database.register(new RESTStoreStrategy('ster_marketplace', '/api/marketplace/marketplace', 'marketplace_id'), MarketPlaceDAO);
    Database.register(new RESTStoreStrategy('order_state', '/api/orders/orderstate', 'order_state_id'), OrderStateDAO);
    Database.register(new RESTStoreStrategy('ster_stock_days_report', '/api/custom/stockdaysreport', 'product_id'), StockDaysReportDAO);
    Database.register(new RESTStoreStrategy('ster_chargeback_report', '/api/custom/chargebackreport', 'marketplace_id'), ChargebackReportDAO);
    Database.register(new RESTStoreStrategy('ster_chargeback_report_city', '/api/custom/chargebackreport/cities', 'city_id'), ChargebackReportCityDAO);
    Database.register(new RESTStoreStrategy('ster_chargeback_report_street', '/api/custom/chargebackreport/streets', 'address'), ChargebackReportStreetDAO);
    Database.register(new RESTStoreStrategy('ster_chargeback_report_product', '/api/custom/chargebackreport/products', 'product_id'), ChargebackReportProductDAO);
    // Order Liquidation
    Database.register(new RESTStoreStrategy('ster_order_liquidation', '/api/orders/orderliquidation', 'order_liquidation_id'), OrderLiquidationDAO);
    Database.register(new RESTStoreStrategy('ster_order_liquidation_group', '/api/orders/orderliquidationgroup', 'order_liquidation_group_id'), OrderLiquidationGroupDAO);
    Database.register(new RESTStoreStrategy('ster_new_order_liquidation', '/api/custom/liquidation', ''), NewOrderLiquidationDAO);
    Database.register(new RESTStoreStrategy('ster_new_order_liquidation_confirm', '/api/custom/liquidation/confirm', ''), NewOrderLiquidationConfirmDAO);

    Database.register(new RESTStoreStrategy('ster_erp_order', '/api/orders/erporder', 'so_id'), ERPOrderDAO);

    Database.register(new RESTStoreStrategy('ster_dispatch_details', '/api/dispatch/dispatchdetails', 'dispatch_id'), DispatchDetailsDAO);

    Database.register(new RESTStoreStrategy('ster_dispatch_list', '/api/dispatch/dispatchlist', 'dispatch_id'), DispatchListDAO);

    Database.register(new RESTStoreStrategy('ster_dispatch_zone', '/api/dispatch/dispatchzone', 'dispatch_zone_id'), DispatchZoneDAO);

    Database.register(new RESTStoreStrategy('ster_dispatch_zone_enabled', '/api/dispatch/dispatchzoneenabled', 'dispatch_zone_id'), DispatchZoneEnabledDAO);

    Database.register(new RESTStoreStrategy('ster_dispatch_item', '/api/dispatch/dispatchitem', 'dispatch_item_id'), DispatchItemDAO);

    Database.register(new RESTStoreStrategy('ster_ster_config', '/api/ster/sterconfig', 'ster_config_id'), SterConfigDAO);

    Database.register(new RESTStoreStrategy('ster_order_document', '/api/orders/orderdocument', 'order_document_id'), OrderDocumentDAO);

    Database.register(new RESTStoreStrategy('ster_order_print', '/api/orders/printorderso', 'order_id'), OrderPrintDAO);

    Database.register(new RESTStoreStrategy('ster_order_print_tags', '/api/orders/printordersoprintpdf', 'order_id'), OrdersSoPrintTagDAO);

    Database.register(new RESTStoreStrategy('ster_order_item', '/api/orders/orderitem', 'order_item_id'), OrderItemDAO);

    Database.register(new RESTStoreStrategy('ster_reporting_pending_andreani_income', '/api/orders/reportingpendingandreaniincome', 'order_id'), OrderReportingPendingAndreaniIncomeDAO);

    Database.register(new RESTStoreStrategy('ster_dispatch', '/api/dispatch/dispatch', 'dispatch_id'), DispatchToSaveDAO);

    Database.register(new RESTStoreStrategy('ster_dispatch_collect', '/api/dispatch/collect', 'collect_id'), DispatchCollectDAO);

    Database.register(new RESTStoreStrategy('ster_dispatch_collect_close', '/api/dispatch/collect', 'collect_id'), DispatchCollectCloseDAO);

    Database.register(new RESTStoreStrategy('ster_dispatch_cart', '/api/dispatch/cart', 'cart_id'), DispatchCartDAO);

    Database.register(new RESTStoreStrategy('ster_dispatch_collect_details', '/api/dispatch/collectdetails', 'collect_id'), DispatchCollectDetailsDAO);

    Database.register(new RESTStoreStrategy('ster_dispatch_collect_item', '/api/dispatch/collectitem', 'collect_item_id'), DispatchCollectItemDAO);

    Database.register(new RESTStoreStrategy('ster_dispatch_collect_add_order', '/api/dispatch/dispatchcollectaddorder', 'collect_id'), DispatchCollectAddOrder);

    Database.register(new RESTStoreStrategy('ster_order_item_product', '/api/orders/orderitemsocustom', 'order_id'), OrderItemWithProductDAO);

    Database.register(new RESTStoreStrategy('ster_order_report_group', '/api/orders/orderreportdetailgroup', 'mc'), OrderReportDetailGroupDAO);

    Database.register(new RESTStoreStrategy('ster_inbox_order_search_importation', '/api/custom/inboxorder/test/search-importation/', 'reference'), InboxOrdersSearchImportationDAO);

    Database.register(new RESTStoreStrategy('ster_collect_item_product', '/api/dispatch/collectitemproduct', 'collect_item_product_id'), CollectItemProductDAO);

    Database.register(new RESTStoreStrategy('ster_dispatch_cart_enabled', '/api/dispatch/cartenabled', 'cart_id'), DispatchCartEnabledDAO);

    Database.register(new RESTStoreStrategy('ster_dispatch_cart_box', '/api/dispatch/cartboxitemscollect', 'cart_box_id'), DispatchCartBoxDAO);

    Database.register(new RESTStoreStrategy('ster_process_monitor_alert', '/api/ster/processmonitoralert', 'ster_config_id'), SterProcessMonitorAlertDAO);

    Database.register(new RESTStoreStrategy('ster_dispatch_collect_state', '/api/dispatch/collectstate', 'collect_state_id'), DispatchCollectState);

    Database.register(new RESTStoreStrategy('ster_dispatch_cart_with_collect', '/api/dispatch/cartwithcollect', 'cart_id'), DispatchCartWithCollectDAO);

    Database.register(new RESTStoreStrategy('ster_collect_item_product_search', '/api/dispatch/collectitemproduct', 'collect_item_product_id'), CollectItemProductSearchDAO);

    Database.register(new RESTStoreStrategy('ster_dispatch_state', '/api/dispatch/dispatchstate', 'dispatch_state_id'), DispatchStateDAO);
    Database.register(new RESTStoreStrategy('ster_product_stock', '/api/product/stock', 'stock_id'), ProductStockDAO);

    Database.register(new RESTStoreStrategy('ster_dispatch_update', '/api/dispatch/dispatch', 'dispatch_id'), DispatchUpdateDAO);

    Database.register(new RESTStoreStrategy('ster_dispatch_details_update', '/api/dispatch/dispatchdetails', 'dispatch_id'), DispatchDetailsUpdateDAO);

    Database.register(new RESTStoreStrategy('ster_dispatch_item_send', '/api/custom/dispatch/dispatch_item_send', 'dispatch_id'), DispatchItemSendDAO);

    Database.register(new RESTStoreStrategy('ster_inbox_order_custom', '/api/custom/inboxorder', 'inbox_order_id'), InboxOrderCustomDAO);

    Database.register(new RESTStoreStrategy('ster_dispatch_send', '/api/custom/dispatch/dispatch_send', 'dispatch_id'), DispatchSendDAO);

    /* Database.register(
      new RESTStoreStrategy(
        'ster_dispatch_manifest',
        '/api/custom/public/dispatch/dispatch_manifest',
        'dispatch_id',
      ),
      DispatchManifestDAO,
    );

*/
    Database.register(new RESTStoreStrategy('ster_order_table_update', '/api/orders/order', 'order_id'), OrdersTableUpdateDAO);

    Database.register(new RESTStoreStrategy('ster_order_history_marketplace', '/api/custom/order/history_marketplace/:order_id?', 'order_id'), HistoryByMarketplaceDAO);

    Database.register(new RESTStoreStrategy('ster_order_items_sku', '/api/custom/order/items_by_sku/:order_id?', 'order_id'), OrderItemsBySkuDAO);

    Database.register(new RESTStoreStrategy('ster_dispatch_upload_documents_manifest', '/api/dispatch/dispatchuploaddocumentsmanifest', 'upload_documents_manifest_id'), DispatchUploadDocumentsManifestDAO);

    Database.register(new RESTStoreStrategy('ster_shipping_tags_custom', '/api/custom/shipping/tags', 'inbox_order_id'), ShippingTagsCustomDAO);

    Database.register(new RESTStoreStrategy('ster_shipping_tags_update', '/api/custom/shipping/tags/reg', 'order_id'), ShippingTagsUpdateDAO);

    Database.register(new RESTStoreStrategy('ster_message_config', '/api/ster/messageconfig', 'message_config_id'), MessageConfigDAO);

    Database.register(new RESTStoreStrategy('ster_message', '/api/ster/message', 'message_id'), MessageDAO);

    Database.register(new RESTStoreStrategy('ster_message_updated', '/api/ster/message', 'message_id'), MessageUpdatedDAO);

    Database.register(new RESTStoreStrategy('ster_message_reason', '/api/ster/messagereason', 'message_reason_id'), MessageReasonDAO);

    Database.register(new RESTStoreStrategy('ster_message_source', '/api/ster/messagesource', 'message_source_id'), MessageSourceDAO);

    Database.register(new RESTStoreStrategy('ster_order_buyer_logs', '/api/orders/orderbuyerlogs', 'order_buyer_log_id'), OrderBuyerLogsDAO);
    Database.register(new RESTStoreStrategy('ster_order_buyer', '/api/orders/orderbuyer', 'order_buyer_id'), OrderBuyerDAO);

    Database.register(new RESTStoreStrategy('ster_message_state', '/api/ster/messagestate', 'message_state_id'), MessageStateDAO);

    Database.register(new RESTStoreStrategy('ster_message_template', '/api/ster/custom/messagetemplate', 'message_template_id'), MessageTemplateDAO);

    Database.register(new RESTStoreStrategy('ster_order_shipment_address_logs', '/api/orders/ordershipmentaddresslogs', 'order_shipment_address_log_id'), OrderShipmentAddressLogsDAO);

    Database.register(new RESTStoreStrategy('ster_message_transport', '/api/ster/messagetransport', 'message_transport_id'), MessageTransportDAO);

    Database.register(new RESTStoreStrategy('ster_order_document_type', '/api/orders/orderdocumenttype', 'order_document_type_id'), OrderDocumentTypeDAO);

    Database.register(new RESTStoreStrategy('ster_order_cancelled', '/public/order/cancel_order', 'order_id'), OrderCancelDAO);
    Database.register(new RESTStoreStrategy(OrderDocumentTypeDAO, 'ster_message_type', '/api/ster/messagetype', 'message_type_id'), MessageTypeDAO);
    Database.register(
      new RESTStoreStrategy(
        'ster_upload_documents_generate',

        '/api/custom/documents/upload_documents/generate',

        'document_upload_id'
      ),

      UploadDocumentsGenerateDAO
    );

    Database.register(new RESTStoreStrategy('ster_upload_documents_generate', '/api/custom/documents/upload_documents/generate', 'document_upload_id'), UploadDocumentsGenerateDAO);

    Database.register(new RESTStoreStrategy('ster_order_document_generate', '/api/custom/orders/orderdocument/generate', 'order_id'), OrderDocumentGenerateDAO);
    Database.register(new RESTStoreStrategy('ster_product_stock_odoo', '/api/custom/product/stock', 'sku'), StockProductsDAO);
    Database.register(new RESTStoreStrategy('ster_product_stock_odoo_update', '/api/custom/product/stock', 'product_stock_id'), StockProductsUpdateDAO);

    Database.register(new RESTStoreStrategy('ster_message_type', '/api/ster/messagetype', 'message_type_id'), MessageTypeDAO);

    Database.register(new RESTStoreStrategy('ster_order_cancelled', '/public/order/cancel_order', 'order_id'), OrderCancelDAO);

    Database.register(new RESTStoreStrategy('ster_message_from', '/api/ster/messagefrom', 'message_from_id'), MessageFromDAO);

    Database.register(new RESTStoreStrategy('ster_message_config_update', '/api/ster/messageconfig', 'message_config_id'), MessageConfigUpdateDAO);
    Database.register(new RESTStoreStrategy('ster_message_template_update', '/api/ster/custom/messagetemplate', 'message_template_id'), MessageTemplateUpdateDAO);
    Database.register(new RESTStoreStrategy('ster_product_stock_logs', '/api/product/productstocklogs', 'product_stock_log_id'), StockProductsLogsDAO);

    Database.register(new RESTStoreStrategy('ster_account_retool', '/api/custom/account-retool', 'account_id'), AccountRetoolDAO);

    Database.register(new RESTStoreStrategy('ster_company', '/api/marketplace/company', 'company_id'), CompanyDAO);

    Database.register(new RESTStoreStrategy('ster_category', '/api/product/category', 'company_id'), CategoryDAO);
    Database.register(new RESTStoreStrategy('ster_product_bulk_categories', '/api/custom/product/category/bulk', 'company_id'), CategoryBulkLoadDAO);

    Database.register(new RESTStoreStrategy('ster_company_marketplace', '/api/marketplace/companymarketplace', 'company_marketplace_id'), CompanyMarketplaceDAO);

    Database.register(new RESTStoreStrategy('ster_order_reporting_sales', '/api/custom/order/reporting/sales/all', 'order_id'), OrderReportingSalesAllDAO);

    Database.register(new RESTStoreStrategy('ster_order_source_type', '/api/orders/ordersourcetype', 'order_source_type_id'), OrderSourceTypeDAO);

    Database.register(new RESTStoreStrategy('ster_alert', '/api/public/alert', 'alert_id'), AlertDAO);

    Database.register(new RESTStoreStrategy('ster_message_config_alert', '/api/ster/messageconfigalert', 'message_config_alert_id'), MessageConfigAlertDAO);

    Database.register(new RESTStoreStrategy('ster_message_config_alert_update', '/api/ster/messageconfigalert', 'message_config_alert_id'), MessageConfigAlertUpdateDAO);

    Database.register(new RESTStoreStrategy('ster_order_so_with_delivery_date', '/api/orders/ordersoorderwithdeliverydate', 'order_id'), OrdersSoOrderWithDeliveryDateDAO);

    Database.register(new RESTStoreStrategy('ster_alert_updated', '/api/public/alert', 'alert_id'), AlertUpdatedDAO);

    Database.register(new RESTStoreStrategy('filter', '/api/public/filter', 'filter_id'), FilterDAO);

    Database.register(new RESTStoreStrategy('ster_reporting_objective_sales', '/api/custom/product/reporting_objective_sales', 'order_id'), OrderReportingObjectiveSalesDAO);

    Database.register(new RESTStoreStrategy('ster_reporting_logistic_justify', '/api/reporting/justifiedorders', 'justified_orders_id'), OrderReportingLogisticJustifyDAO);

    Database.register(new RESTStoreStrategy('ster_reporting_logistic_justify_logs', '/api/reporting/justifiedorderslogs', 'justified_orders_logs_id'), OrderReportingLogisticJustifyLogsDAO);

    Database.register(new RESTStoreStrategy('ster_justified_orders_types', '/api/reporting/justifiedorderstypes', 'justified_orders_types_id'), JustifiedOrdersTypesDAO);

    Database.register(new RESTStoreStrategy('ster_goal_type', '/api/goal/goaltype', 'goal_type_id'), GoalTypeDAO);

    Database.register(new RESTStoreStrategy('ster_goal_type_update', '/api/goal/goaltype', 'goal_type_id'), GoalTypeUpdateDAO);

    Database.register(new RESTStoreStrategy('ster_goal_type_value', '/api/goal/goaltypevalue', 'goal_type_value_id'), GoalTypeValueDAO);

    Database.register(new RESTStoreStrategy('ster_goal_range_type', '/api/goal/goalrangetype', 'goal_range_type_id'), GoalRangeTypeDAO);

    Database.register(new RESTStoreStrategy('ster_goal_product', '/api/goal/goalproduct', 'goal_product_id'), GoalProductDAO);

    Database.register(new RESTStoreStrategy('ster_goal_product_update', '/api/goal/goalproduct', 'goal_product_id'), GoalProductUpdateDAO);

    Database.register(new RESTStoreStrategy('ster_goal', '/api/goal/goal', 'goal_id'), GoalDAO);

    Database.register(new RESTStoreStrategy('ster_goal_update', '/api/goal/goal', 'goal_id'), GoalUpdateDAO);
    Database.register(new RESTStoreStrategy('ster_goal_period', '/api/goal/goalperiod', 'goal_period_id'), GoalPeriodDAO);

    Database.register(new RESTStoreStrategy('ster_goal_period_update', '/api/goal/goalperiod', 'goal_period_id'), GoalPeriodUpdateDAO);

    Database.register(new RESTStoreStrategy('ster_goal_marketplace', '/api/goal/goalmarketplace', 'goal_marketplace_id'), GoalMarketplaceDAO);

    Database.register(new RESTStoreStrategy('ster_goal_marketplace_update', '/api/goal/goalmarketplace', 'goal_marketplace_id'), GoalMarketplaceUpdateDAO);

    Database.register(new RESTStoreStrategy('ster_goal_user', '/api/goal/goaluser', 'goal_user_id'), GoalUserDAO);

    Database.register(new RESTStoreStrategy('ster_goal_user_update', '/api/goal/goaluser', 'goal_user_id'), GoalUserUpdateDAO);

    Database.register(new RESTStoreStrategy('ster_list_date', '/api/custom/goal/list_date', 'goal_id'), GoalListDateDAO);

    Database.register(new RESTStoreStrategy('ster_goal_field_enabled', '/api/custom/goal/fields_enabled', 'goal_id'), GoalFieldEnabledDAO);

    Database.register(new RESTStoreStrategy('ster_goal_product_enabled', '/api/custom/goal/product_enabled', 'goal_id'), GoalProductEnabledDAO);
    Database.register(new RESTStoreStrategy('order_cancel_reason', '/api/orders/ordercancelreason', 'order_cancel_reason_id'), OrderCancelReasonDAO);

    Database.register(new RESTStoreStrategy('ster_config_logs', '/api/public/sterconfiglogs', 'ster_config_log_id'), SterConfigLogDAO);

    Database.register(new RESTStoreStrategy('ster_goal_marketplace_enabled', '/api/goal/goalmarketplaceenabled', 'marketplace_id'), GoalMarketplaceEnabledDAO);

    Database.register(new RESTStoreStrategy('ster_goal_product_enabled', '/api/goal/goalproductenabled', 'goal_product_enabled_id'), GoalProductEnabledDAO);

    Database.register(new RESTStoreStrategy('ster_order_reporting_orders', '/api/custom/order/reporting/orders/all', 'order_id'), OrderReportingOrdersDAO);

    Database.register(new RESTStoreStrategy('ster_order_reporting_orders_history_marketplace', '/api/custom/order/reporting/orders/history_marketplace/all/:order_id?', 'order_id'), OrderReportingOrdersHistoryByMarketplaceDAO);

    Database.register(new RESTStoreStrategy('ster_marketplace_sla', '/api/marketplace/marketplacesla', 'marketplace_sla_id'), MarketPlaceSlaDAO);

    Database.register(new RESTStoreStrategy('ster_marketplace_sla_update', '/api/marketplace/marketplacesla', 'marketplace_sla_id'), MarketPlaceSlaUpdateDAO);

    Database.register(new RESTStoreStrategy('ster_marketplace_sla_unassigned', '/api/custom/marketplace/marketplace_sla_unassigned', 'marketplace_sla_id'), MarketPlaceSlaUnassignedDAO);

    Database.register(new RESTStoreStrategy('ster_marketplace_chargeback', '/api/marketplace/marketplacechargeback', 'chargeback_marketplace_id'), MarketplaceChargebackDAO);
    Database.register(new RESTStoreStrategy('ster_marketplace_fraud', '/api/marketplace/marketplacefraud', 'marketplace_fraud_id'), MarketplaceFraudDAO);

    Database.register(new RESTStoreStrategy('ster_reporting_delivery_control', '/api/custom/order/reporting/delivery_control', 'order_id'), OrderReportingDeliveryControlDAO);

    Database.register(new RESTStoreStrategy('ster_reporting_delivery_control_group', '/api/custom/order/reporting/delivery_control_group', 'order_id'), OrderReportingDeliveryControlGroupDAO);
    Database.register(new RESTStoreStrategy('ster_reporting_by_transport', '/api/custom/product/reporting_by_transport', 'order_id'), OrderReportByTransportDAO);
    Database.register(new RESTStoreStrategy('ster_reporting_ml', '/api/custom/order/reporting/mercado_libre', 'order_id'), OrderReportMlDAO);

    Database.register(new RESTStoreStrategy('ster_order_liberate', '/api/custom/order/liberate', 'order_id'), OrderLiberateDAO);

    Database.register(new RESTStoreStrategy('ster_non_working_day', '/api/public/nonworkingday', 'non_working_day_id'), NonWorkingDayDAO);

    Database.register(new RESTStoreStrategy('ster_non_working_day_update', '/api/public/nonworkingday', 'non_working_day_id'), NonWorkingDayUpdateDAO);

    Database.register(new RESTStoreStrategy('ster_order_reporting_sales_taxation', '/api/custom/order/reporting/sales_taxation/all', 'order_id'), OrderReportingSalesTaxationAllDAO);

    Database.register(new RESTStoreStrategy('ster_order_b2b', '/api/orders/orderb2b', 'order_b2b_id'), OrderB2BDAO);
    Database.register(new RESTStoreStrategy('ster_order_comment', '/api/orders/ordercomment', 'order_b2b_comment_id'), OrderB2BCommentDAO);
    Database.register(new RESTStoreStrategy('ster_vw_order_b2b', '/api/orders/ordersoorderwithdeliverydateandb2b', 'order_id'), OrderB2BVwDAO);

    Database.register(new RESTStoreStrategy('ster_buyer_odoo', '/api/custom/odoo/buyer_odoo', 'client'), BuyerOdooDAO);

    Database.register(new RESTStoreStrategy('ster_year_week', '/api/general/yearweek', 'year_week_id'), YearWeekDAO);

    Database.register(new RESTStoreStrategy('objective_period', '/api/custom/objective/periods', 'objective_period_id'), ObjectivePeriodDAO);

    Database.register(new RESTStoreStrategy('ster_reporting_dashboard', '/api/custom/order/reporting/dashboard', 'order_id'), OrderReportingDashboardDAO);

    Database.register(new RESTStoreStrategy('ster_collect_serial_number', '/api/custom/collect/serial_number', 'collect_id'), CollectSerialNumberDAO);

    Database.register(new RESTStoreStrategy('ster_report_b2b_stock', '/api/custom/order/reporting/b2bstock', 'order_id'), OrderReportB2BStockDAO);

    Database.register(new RESTStoreStrategy('ster_order_reporting_adreani_tracking', '/api/orders/orderreportingandreanitracking', 'order_id'), OrderReportingAndreaniTrackingDAO);

    Database.register(new RESTStoreStrategy('available_ml_periods', '/api/custom/liquidation/available-ml-periods', 'available_ml_periods_id'), AvailableMLPeriodsDAO);

    Database.register(new RESTStoreStrategy('ster_reporting_deliver_order', '/api/orders/reportingdeliverorder', 'order_id'), ReportingDeliverOrderDAO);

    Database.register(new RESTStoreStrategy('paymentmethododoo', '/api/payment/paymentmethododoo', 'paymentMethodOdooId'), PaymentMethodOdooDAO);

    Database.register(new RESTStoreStrategy('paymentcurrency', '/api/general/currency', 'currency_id'), PaymentCurrencyDAO);

    Database.register(new RESTStoreStrategy('paymentmethodcurrency', '/api/payment/paymentmethodcurrency', 'payment_method_currency_id'), PaymentMethodCurrencyDAO);

    Database.register(new RESTStoreStrategy('operation', '/api/orders/operation', 'operationId'), OperationDAO);

    Database.register(new RESTStoreStrategy('operationcomposition', '/api/orders/operationcomposition', 'operationCompositionId'), OperationCompositionDAO);

    Database.register(new RESTStoreStrategy('operationorder', '/api/orders/operationorder/', 'operationOrderId'), OperationOrderDAO);

    Database.register(new RESTStoreStrategy('ster_verify_order', '/api/custom/order/reporting/orders/verify_orders', 'owner_id'), VerifyOrdersDAO);
    Database.register(new RESTStoreStrategy('operationorder', '/api/custom/order/operationorder', 'operationOrderId'), OperationOrderDAO);
    Database.register(new RESTStoreStrategy('ster_process_config', '/api/custom/processconfig', 'ster_config_id'), ProcessConfigDAO);
    Database.register(new RESTStoreStrategy('budget', '/api/custom/orders/budget', 'budgetId'), BudgetDAO);
    Database.register(new RESTStoreStrategy('stock_marketplace', '/api/custom/product/stock_marketplace', 'marketplace_id'), StockMarketplaceDAO);
    Database.register(new RESTStoreStrategy('ster_generate_order_document_url', '/api/custom/order/generateorderdocumenturl', ''), GenerateOrderDocumentUrlDAO);
    Database.register(new RESTStoreStrategy('ster_brand', '/api/product/brand', 'brand_id'), BrandDAO);
    Database.register(new RESTStoreStrategy('ster_product_bulk_brand', '/api/custom/product/brand/bulk', 'brand_id'), BrandBulkLoadDAO);
    Database.register(new RESTStoreStrategy('product_stock_error_sync', '/api/custom/product/stock-sync-error', 'sku'), ProductStockSyncErrorDAO);
    Database.register(new RESTStoreStrategy('ster_validate_order_item', '/api/custom/orders/validate_order_item_sku', 'order_id'), ValidateOrderItemDAO);
  }

  init(): void {
    Libby.App = this.chinoTokenEnvironmet;
    this.addSession();
    this.register();
  }
}

export const DatabaseStore = new DatabaseStoreRaw();
