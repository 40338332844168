import { useCallback } from 'react';
import { Order_reporting_ml_all } from '../../../interfaces/business';
import { useTranslation } from '../../../services/translation';

export default function useMenuArrayData() {
  const { t } = useTranslation();

  interface dataTypes {
    data_urbantech_ml: Order_reporting_ml_all | undefined;
    data_novatech_ml: Order_reporting_ml_all | undefined;
    data_tienda_oficial_ml: Order_reporting_ml_all | undefined;
  }

  const handleDataMenuArray = useCallback(
    (indicator: string, data: dataTypes, name: string) => {
      const { data_urbantech_ml, data_novatech_ml, data_tienda_oficial_ml } = data;

      switch (indicator) {
        case 'indicator-7':
          return {
            name: t(name) || '',
            rows: {
              data_urbantech_ml: data_urbantech_ml?.sales_by_product.results,
              data_novatech_ml: data_novatech_ml?.sales_by_product.results,
              data_tienda_oficial_ml: data_tienda_oficial_ml?.sales_by_product.results
            },
            label: 'Skus'
          };
        case 'indicator-8':
          return {
            name: t(name) || '',
            rows: {
              data_urbantech_ml: data_urbantech_ml?.couriers.results,
              data_novatech_ml: data_novatech_ml?.couriers.results,
              data_tienda_oficial_ml: data_tienda_oficial_ml?.couriers.results
            },
            label: t('Courier')
          };
        case 'indicator-9':
          return {
            name: t(name) || '',
            rows: {
              data_urbantech_ml: data_urbantech_ml?.skus_and_orders.results,
              data_novatech_ml: data_novatech_ml?.skus_and_orders.results,
              data_tienda_oficial_ml: data_tienda_oficial_ml?.skus_and_orders.results
            },
            label: t('Items and Orders')
          };
        default:
          return {
            name: t('Error please provide an indicator'),
            rows: { data_urbantech_ml: undefined, data_novatech_ml: undefined, data_tienda_oficial_ml: undefined },
            label: ''
          };
      }
    },
    [t]
  );
  return { handleDataMenuArray };
}
