import asService from '../../hoc/asService';

const options = {
  activeProp: 'open',
  resolveProp: 'onConfirm',
  rejectProp: 'onCancel',
  resolveClose: 'moveBack',
  resolveErrorClose: 'closeErrorModal',
  forceResolveOnReject: false,
  rejectValue: false
};
//@ts-ignore
export default asService(options);
