import React from 'react';
import { Box, capitalize } from '@material-ui/core';
import { formatDate } from '../../utils';
import { ReportOrder } from '../../types';
import { ReportingCreationAreaStatusColors } from '../../const/ReportingCreationAreaStatus';

export const ReportOrderColumns = {
  marketplace: {
    id: 'marketplace',
    label: 'Marketplace',
    style: {
      whiteSpace: 'nowrap'
    },
    orderById: 'marketplace'
  },
  totalTime: {
    id: 'total_time',
    label: 'Total days',
    style: {
      whiteSpace: 'nowrap'
    },
    render: ({ total_time, total_alert }: ReportOrder) => (
      <Box color={ReportingCreationAreaStatusColors[total_alert ?? '']} whiteSpace="nowrap" style={{ fontWeight: 'bold' }}>
        {total_time ?? ''}
      </Box>
    )
  },
  buyer: {
    id: 'buyer',
    label: 'Customer',
    style: {
      whiteSpace: 'nowrap',
      minWidth: '150px'
    },
    orderById: 'buyer.first_name'
  },
  business: {
    id: 'business',
    label: 'Business',
    orderById: 'business'
  },
  state: {
    id: 'state',
    label: 'State',
    style: {
      whiteSpace: 'nowrap'
    },
    render: ({ state }: { state: string }, item: any, t: (str: string) => string) => <Box whiteSpace="nowrap">{t(capitalize(state))}</Box>,
    orderById: 'order_state'
  },
  daysMetaState: {
    id: 'days_meta_state',
    label: 'Days per area',
    style: {
      whiteSpace: 'nowrap'
    },
    render: ({ meta_state_alert, days_meta_state }: ReportOrder) => (
      <Box color={ReportingCreationAreaStatusColors[meta_state_alert ?? '']} whiteSpace="nowrap" style={{ fontWeight: 'bold' }}>
        {days_meta_state ?? ''}
      </Box>
    ),
    orderById: 'days_meta_state'
  },
  totalPrice: {
    id: 'total_price',
    label: 'Total Price',
    style: {
      whiteSpace: 'nowrap'
    },
    orderById: 'total_price'
  },
  created_at: {
    id: 'created_at',
    label: 'Origin date',
    style: {
      whiteSpace: 'nowrap'
    },
    orderById: 'created_at',
    render: ({ created_at }: ReportOrder) => <Box>{formatDate(created_at, true, false)}</Box>
  },
  StateMeta: {
    id: 'meta_state',
    label: 'Area',
    style: {
      whiteSpace: 'nowrap'
    },
    orderById: 'meta_state',
    render: ({ meta_state }: ReportOrder, item: any, t: (str: string) => string) => <Box>{meta_state?.length && t(meta_state)}</Box>
  }
};
