import { Moment } from 'moment';
import { Filter } from '../../../types';
import { endDateFormatUtc, startDateFormatUtc } from '../../../utils';

type MakeFilterProps = {
  search?: string;
  startDate?: Moment;
  endDate?: Moment;
  message_source?: string;
  message_reason?: string;
  message_state?: string;
  source_id?: string;
};

export const makeFilter = ({ search, startDate, endDate, message_source, message_reason, message_state, source_id }: MakeFilterProps) => {
  const filter: Filter = {};
  if (search) {
    const searchArray = search.split(' ').reduce((accumulator: Filter, item, index) => {
      accumulator[index] = [
        {
          path: 'message_to',
          value: search,
          method: 'includes'
        },
        {
          path: 'account.username',
          value: item,
          method: 'includes'
        },
        {
          path: 'source_id',
          value: item,
          method: 'includes'
        }
      ];

      const numberSearch = parseInt(item, 10);
      if (numberSearch) {
        accumulator[index].push({
          path: 'message_id',
          value: numberSearch
        });
      }
      return accumulator;
    }, {});
    filter.search = [...searchArray[0]];
  }

  if (message_source && message_source !== '0') {
    filter.message_source = [
      {
        path: 'message_source.message_source_id',
        value: message_source
      }
    ];
  }

  if (message_reason && message_reason !== '0') {
    filter.message_reason = [
      {
        path: 'message_reason.message_reason_id',
        value: message_reason
      }
    ];
  }

  if (message_state && message_state !== '0') {
    filter.message_state = [
      {
        path: 'message_state.message_state_id',
        value: message_state
      }
    ];
  }

  if (source_id && source_id !== '') {
    filter.source_id = [
      {
        path: 'source_id',
        value: source_id
      }
    ];
  }

  if (startDate) {
    filter.startDate = [
      {
        path: 'created_at',
        value: startDateFormatUtc(startDate),
        method: 'higherOrEquals'
      }
    ];
  }
  if (endDate) {
    filter.endDate = [
      {
        path: 'created_at',
        value: endDateFormatUtc(endDate),
        method: 'lowerOrEquals'
      }
    ];
  }

  return filter;
};
