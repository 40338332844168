import { Box, makeStyles } from '@material-ui/core';
import React, { useState } from 'react';
import { LoadingModal } from '../../../components/LoadingModal';
import { useGlobalContext } from '../../../hooks';
import { userInfoType } from '../../../types';

type retoolProps = {
  url: string;
};

export const useStyles = makeStyles(() => ({
  pageDisabled: {
    width: '0%',
    height: '0rem'
  },
  pageEnabled: {
    height: '100vh',
    width: '100%',
    webkitScrollbar: {
      display: 'none'
    }
  }
}));

export const Retool = ({ url }: retoolProps) => {
  const {
    userInfo
  }: {
    userInfo: userInfoType;
  } = useGlobalContext();
  const [loading, setLoading] = useState(true);
  const classes = useStyles();

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const sku = urlParams.get('sku');

  return (
    <>
      {loading && (
        <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column" width="100%" height="90vh">
          <LoadingModal />
        </Box>
      )}
      <iframe
        title="el title"
        className={!loading ? classes.pageEnabled : classes.pageDisabled}
        frameBorder="none"
        src={`${url}&token=${userInfo?.retool?.token}${sku ? `&sku=${sku}` : ''}`}
        onLoad={() => {
          setLoading(false);
        }}
      />
    </>
  );
};
