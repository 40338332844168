import React, { useCallback, useEffect, useState } from 'react';
import { DatabaseConnector } from '@phinxlab/libby-rest-web';
import { useParams, useLocation } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { AnyObject, LibbyObject } from '../../../../types/types';
import { useLibbyFetchById, useBreadcrumbs } from '../../../../hooks';
import { OrderDetailData } from '../../../components/OrderDetailData/OrderDetailData';
import { useTranslation } from '../../../../services/translation';
import { OrderNeedDeliveryNotification } from './components';
import { ScreenAligned } from '../../../../components/ScreenAligned/ScreenAligned';
import { TitleBreadcrumbs } from '../../../../interfaces';

type OrderDetailProps = {
  libby: LibbyObject;
  title: TitleBreadcrumbs[];
};

const OrderDetailRaw = ({ libby, title }: OrderDetailProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const { id } = useParams<{ id: string }>();

  const { state } = useLocation<{ goBack: TitleBreadcrumbs[] }>();
  const { working, data, recall } = useLibbyFetchById(libby, {
    daoName: 'ster_order_table',
    id
  });
  const [operationData, setOperationData] = useState<AnyObject>();

  const callOperation = useCallback(
    async (idB2b) => {
      const operation = await libby.operationorder.query().equals('id', idB2b).run();
      setOperationData(operation[0]);
    },
    [libby.operationorder]
  );

  useEffect(() => {
    if (data?.orderB2Bs[0]?.orderB2bId) {
      callOperation(data?.orderB2Bs[0]?.orderB2bId);
    }
  }, [data, callOperation]);

  const { t } = useTranslation();

  const titleShow = useBreadcrumbs('Order', state && state?.goBack?.length ? state.goBack : title, `#${id}`);

  const onStateChange = useCallback(
    async (stateId: number) => {
      try {
        enqueueSnackbar(t('Updating'), { variant: 'info' });
        await libby.ster_order_table.save({
          ...data,
          state: { order_state_id: stateId }
        });
        enqueueSnackbar(t('Status changed'), { variant: 'success' });
      } catch (e: any) {
        enqueueSnackbar(`${t('Something is wrong')}, ${t('Please try again later')}`, { variant: 'error' });
      } finally {
        if (recall) recall();
      }
    },
    [recall, libby, data, t, enqueueSnackbar]
  );

  return (
    <ScreenAligned title={titleShow} additionalTitle={<OrderNeedDeliveryNotification data={data} onStateClick={onStateChange} />} working={working} showData={!!data}>
      <OrderDetailData data={data} operationData={operationData} onStateChange={onStateChange} recall={recall} />
    </ScreenAligned>
  );
};

export const OrderDetail = DatabaseConnector(OrderDetailRaw)('ster_order_table', 'ster_city', 'ster_state', 'operationorder');
