import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSnackbar } from 'notistack';
import { useHistory, useLocation, useParams, useRouteMatch } from 'react-router-dom';
import { useTranslation } from '../../../../../services/translation';
import { useGoalFetchById, useGoalMarketplaceUpdateDAO, useGoalProductUpdateDAO, useGoalUserUpdateDAO } from '../../../../../business/goal';
import { useBreadcrumbs } from '../../../../../hooks';
import { Goal, GoalMarketplaceNoGoal, GoalProductNoGoal, GoalUserNoGoal } from '../../../../../interfaces/business';
import { RowsType } from '../../../../../utils/tables';
import CustomModal from '../../../../../services/customFormDialog';
import ConfirmDialog from '../../../../../components/ConfirmDialog';
import { ButtonDetailsType } from '../../../../../types/Button';
import { columnsReportingGoalSaleSettingFieldProducts } from '../utils/columnsReportingGoalSaleSettingProducts';
import { columnsReportingGoalSaleSettingFieldMarketplaces } from '../utils/columnsReportingGoalSaleSettingMarketplace';
import { columnsReportingGoalSaleSettingUser } from '../utils/columnsReportingGoalSaleSettingUser';
import { ActionTableDelete } from '../../../../../components/ActionTable';
import { TitleBreadcrumbs } from '../../../../../interfaces';

const ConfirmModal = CustomModal(ConfirmDialog);
export const useReportingGoalSaleSettingFieldsLogic = () => {
  const { goal_id } = useParams<{ goal_id: string }>();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { data, working } = useGoalFetchById(goal_id);
  const history = useHistory();
  const match = useRouteMatch();
  const [dataGoal, setDataGoal] = useState<Goal>();

  useEffect(() => {
    setDataGoal(data);
  }, [data]);

  const goalProductDAO = useGoalProductUpdateDAO();
  const goalMarketplaceDAO = useGoalMarketplaceUpdateDAO();
  const goalUserDAO = useGoalUserUpdateDAO();
  const { state } = useLocation<{ goBack: TitleBreadcrumbs[] }>();

  const title = useBreadcrumbs(`Goal field ${goal_id}`);
  const titleShow = useBreadcrumbs('Goal field', state && state?.goBack?.length ? state.goBack : title, goal_id);

  const buttonDetails = useMemo((): ButtonDetailsType[] => {
    return [
      {
        id: 'printDispatchNote',
        onClick: () =>
          history.push({
            pathname: `${match.url}/add`,
            state: {
              goBack: titleShow
            }
          }),
        title: 'Add',
        type: 'submit',
        variant: 'contained'
      }
    ];
  }, [history, match.url, titleShow]);

  const removeGoalMarketplace = useCallback((goal_marketplace_id_remove) => {
    setDataGoal((oldDataGoal) => {
      const copyOldDataGoal = { ...oldDataGoal } as Goal;
      const idRemove = copyOldDataGoal.goal_marketplace?.findIndex(({ goal_marketplace_id }: GoalMarketplaceNoGoal) => goal_marketplace_id === goal_marketplace_id_remove);
      if (idRemove > -1) copyOldDataGoal.goal_marketplace.splice(idRemove, 1);
      return copyOldDataGoal;
    });
  }, []);

  const removeGoalProduct = useCallback((goal_product_id_remove) => {
    setDataGoal((oldDataGoal) => {
      const copyOldDataGoal = { ...oldDataGoal } as Goal;
      const idRemove = copyOldDataGoal.goal_product?.findIndex(({ goal_product_id }: GoalProductNoGoal) => goal_product_id === goal_product_id_remove);
      if (idRemove > -1) copyOldDataGoal.goal_product.splice(idRemove, 1);
      return copyOldDataGoal;
    });
  }, []);

  const removeGoalUser = useCallback((goal_user_id_remove) => {
    setDataGoal((oldDataGoal) => {
      const copyOldDataGoal = { ...oldDataGoal } as Goal;
      const idRemove = copyOldDataGoal.goal_user?.findIndex(({ goal_user_id }: GoalUserNoGoal) => goal_user_id === goal_user_id_remove);
      if (idRemove > -1) copyOldDataGoal.goal_user.splice(idRemove, 1);
      return copyOldDataGoal;
    });
  }, []);

  const handleCancelModalProduct = useCallback(
    async (goalProduct: GoalProductNoGoal) => {
      try {
        const cancel = await ConfirmModal.show({
          title: t('Delete goal'),
          content: `${t('You are about to remove the product $$$$ from the goal, do you want to continue?')}`.replace('$$$$', String(goalProduct.sku)),
          confirmText: t('Confirm'),
          cancelText: t('Cancel')
        });
        if (cancel) {
          await goalProductDAO.remove({
            ...goalProduct
          });
          removeGoalProduct(goalProduct.goal_product_id);
          enqueueSnackbar(t('the product $$$$ from the goal deleted').replace('$$$$', goalProduct.sku), { variant: 'success' });
        }
      } catch (error) {
        if (error) {
          enqueueSnackbar(`${t('Failed to delete')}: ${error}`, {
            variant: 'info'
          });
        }
      }
    },
    [enqueueSnackbar, goalProductDAO, removeGoalProduct, t]
  );
  const rowsGoalProduct: RowsType[] = useMemo(() => {
    const dataOrder = dataGoal?.goal_product || ([] as GoalProductNoGoal[]);

    return dataOrder.map((goalProduct: GoalProductNoGoal) => {
      const { sku, goal_product_id } = goalProduct;
      return {
        id: goal_product_id || '',
        goal_product_id,
        sku,
        delete: <ActionTableDelete onClick={() => handleCancelModalProduct(goalProduct)} />
      };
    });
  }, [dataGoal, handleCancelModalProduct]);

  const handleCancelModalMarketplace = useCallback(
    async (goalMarketplace: GoalMarketplaceNoGoal) => {
      try {
        const cancel = await ConfirmModal.show({
          title: t('Delete goal'),
          content: `${t('You are about to remove the marketplace $$$$ from the goal, do you want to continue?')}`.replace('$$$$', String(goalMarketplace.marketplace.name)),
          confirmText: t('Confirm'),
          cancelText: t('Cancel')
        });
        if (cancel) {
          await goalMarketplaceDAO.remove({
            ...goalMarketplace
          });

          removeGoalMarketplace(goalMarketplace.goal_marketplace_id);

          enqueueSnackbar(t('The marketplace $$$$ from the goal deleted successfully').replace('$$$$', String(goalMarketplace.marketplace.name)), { variant: 'success' });
        }
      } catch (error) {
        if (error) {
          enqueueSnackbar(`${t('Failed to delete')}: ${error}`, {
            variant: 'info'
          });
        }
      }
    },
    [enqueueSnackbar, goalMarketplaceDAO, removeGoalMarketplace, t]
  );
  const rowsGoalMarketplace: RowsType[] = useMemo(() => {
    const dataOrder = dataGoal?.goal_marketplace || ([] as GoalMarketplaceNoGoal[]);
    return dataOrder.map((goalMarketplace: GoalMarketplaceNoGoal) => {
      const {
        marketplace: { name },
        goal_marketplace_id
      } = goalMarketplace;
      return {
        id: goal_marketplace_id || '',
        goal_marketplace_id,
        name,
        delete: <ActionTableDelete onClick={() => handleCancelModalMarketplace(goalMarketplace)} />
      };
    });
  }, [dataGoal, handleCancelModalMarketplace]);

  const handleCancelModalUser = useCallback(
    async (goalUser: GoalUserNoGoal) => {
      try {
        const cancel = await ConfirmModal.show({
          title: t('Delete goal'),
          content: `${t('You are about to remove the user $$$$ from the goal, do you want to continue?')}`.replace('$$$$', String(goalUser.account.username)),
          confirmText: t('Confirm'),
          cancelText: t('Cancel')
        });
        if (cancel) {
          await goalUserDAO.remove({
            ...goalUser
          });

          removeGoalUser(goalUser.goal_user_id);

          enqueueSnackbar(t('The user $$$$ from the goal deleted successfully').replace('$$$$', String(goalUser.account.username)), { variant: 'success' });
        }
      } catch (error) {
        if (error) {
          enqueueSnackbar(`${t('Failed to delete')}: ${error}`, {
            variant: 'info'
          });
        }
      }
    },
    [enqueueSnackbar, goalUserDAO, removeGoalUser, t]
  );

  const rowsGoalUser: RowsType[] = useMemo(() => {
    const dataOrder = dataGoal?.goal_user || ([] as GoalUserNoGoal[]);
    return dataOrder.map((goalUser: GoalUserNoGoal) => {
      const { account_id, username } = goalUser.account;
      return {
        id: goalUser.goal_user_id || '',
        account_id,
        username,
        delete: <ActionTableDelete onClick={() => handleCancelModalUser(goalUser)} />
      };
    });
  }, [dataGoal, handleCancelModalUser]);

  const rowsGoalFields = useMemo(() => {
    return rowsGoalMarketplace.length ? rowsGoalMarketplace : rowsGoalProduct.length ? rowsGoalProduct : rowsGoalUser.length ? rowsGoalUser : undefined;
  }, [rowsGoalMarketplace, rowsGoalProduct, rowsGoalUser]);

  const columns = useMemo(
    () =>
      dataGoal?.goal_marketplace.length
        ? columnsReportingGoalSaleSettingFieldMarketplaces
        : dataGoal?.goal_product.length
        ? columnsReportingGoalSaleSettingFieldProducts
        : dataGoal?.goal_user.length
        ? columnsReportingGoalSaleSettingUser
        : undefined,
    [dataGoal]
  );

  return { data, working, titleShow, buttonDetails, rowsGoalFields, columns };
};
