import { Order_reporting_b2b_stock } from '../../../interfaces/business';
import { makeBusinessEntity } from '../../../lib/libby/generator/makeBusinessEntity';

const DAO_NAME = 'ster_report_b2b_stock';

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch }
} = makeBusinessEntity<Order_reporting_b2b_stock>(DAO_NAME);

// hooks
export const useReportB2BStockFetchById = fetchById;
export const useReportB2BStockDAO = libbyDAO;
export const useReportB2BStockLibbyFetch = libbyFetch;
export const useReportB2BStockLibbyCall = libbyCall;
