import React, { useEffect, useMemo } from 'react';
import { DatabaseConnector } from '@phinxlab/libby-rest-web';
import { Switch, Route, useRouteMatch, useHistory, useLocation } from 'react-router-dom';
import { DispatchOrderList } from './routes/DispatchOrderList';
import { useDispatchesLogic, useDispatchMode } from './hook';
import { LibbyObject, userInfoType } from '../../types';

import { Collection } from '../Collection';
import { authorizeMenu } from '../../utils';

import { useGlobalContext } from '../../hooks';
import { MENU } from '../../const';
import { DispatchDetail } from './routes/DispatchDetail';
import { DispatchesList } from './routes/DispatchesList';

type DispatchesProps = {
  libby: LibbyObject;
};

export const DispatchesRaw = ({ libby }: DispatchesProps) => {
  const match = useRouteMatch();
  const location = useLocation();

  const { data, handleOnChangeFilter, working, handleOnOpenDispatchModal, updateData, fetchMore, orderBy, direction, handleRequestSort, handleSendModal, reFetch, filter, resetFilter, actionsDispatch } = useDispatchesLogic({
    libby,
    isDetailView: location.pathname.includes('detail')
  });

  const { columns, rows, screenProps, title, isReadOnly } = useDispatchMode(data, handleOnOpenDispatchModal, handleSendModal, actionsDispatch);

  const {
    userInfo
  }: {
    userInfo: userInfoType;
  } = useGlobalContext();

  const history = useHistory();

  const dispatchRoute = authorizeMenu(userInfo, MENU.DISPATCH);
  const dispatchInquiryRoute = authorizeMenu(userInfo, MENU.DISPATCH_INQUIRY);
  const { pathname } = useLocation();

  useEffect(() => {
    if (pathname === '/dispatches' && !dispatchRoute) {
      history.push(dispatchInquiryRoute ? '/dispatches/inquiry' : '/');
    }
  }, [pathname, dispatchRoute, dispatchInquiryRoute, history]);

  const pathDetails = useMemo(() => {
    const allPathDetails = [];

    if (dispatchRoute) {
      allPathDetails.push(`${match.path}/detail/:dispatch_id`);
    }
    if (dispatchInquiryRoute) {
      allPathDetails.push(`${match.path}/inquiry/detail/:dispatch_id`);
    }

    return allPathDetails;
  }, [dispatchRoute, dispatchInquiryRoute, match]);

  const pathList = useMemo(() => {
    const allPathList = [];

    if (dispatchRoute) {
      allPathList.push(`${match.path}`);
    }
    if (dispatchInquiryRoute) {
      allPathList.push(`${match.path}/inquiry`);
    }

    return allPathList;
  }, [dispatchRoute, dispatchInquiryRoute, match]);

  return (
    <Switch>
      <Route exact path={[`${match.path}/detail/:dispatchId/collects`, `${match.path}/detail/:dispatchId/collects/detail/:id`, `${match.path}/detail/:dispatchId/collects/detail/:id/add-orders`]}>
        <Collection libby={libby} titleProp={title} />
      </Route>
      <Route exact path={pathDetails}>
        <DispatchDetail title={title} path={match.path} onEditDispatch={handleOnOpenDispatchModal} handleSendModal={handleSendModal} handleUpdateDispatchList={updateData} reFetch={reFetch} resetFilter={resetFilter} />
      </Route>
      <Route exact path={pathList}>
        <DispatchesList
          filter={filter}
          title={title}
          rows={rows}
          columns={columns}
          working={working}
          fetchMore={fetchMore}
          direction={direction}
          orderBy={orderBy}
          onFilterChange={handleOnChangeFilter}
          onSortChange={handleRequestSort}
          resetFilter={resetFilter}
          additionalTitle={screenProps.additionalTitle || null}
          isReadOnly={isReadOnly}
        />
      </Route>
      {dispatchRoute && (
        <Route exact path={`${match.path}/add-orders/:id/:courierService`}>
          <DispatchOrderList updateData={updateData} path={match.path} reFetch={reFetch} />
        </Route>
      )}
    </Switch>
  );
};

export const Dispatches = DatabaseConnector(DispatchesRaw)(
  'ster_order',
  'ster_dispatch_list',
  'ster_dispatch',
  'ster_dispatch_zone',
  'ster_order_table',
  'ster_dispatch_details',
  'ster_dispatch_update',
  'ster_dispatch_details_update',
  'ster_dispatch_send',
  'ster_dispatch_collect_close'
);
