import moment from 'moment';
import { FilterBarSelectionReportingObjectiveOfflineSales, FilterBarSelectionReportingObjectiveSales } from '../../../../routes/ReportingObjectSales/routes/ReportingObjectSalesList/type';
import { endDateFormat, formatDate, startDateFormat } from '../../../../utils';
import { DataGenerateAutocompleteType } from '../../../../utils/generateAutocomplete';
import { LibbyFetchDAO } from '../LibbyFetchDAO';

export class OrderReportingObjectiveSalesDAO extends LibbyFetchDAO {
  constructor() {
    super('ster_reporting_objective_sales', 'order_id');
  }

  getAll() {
    return this.query().limit(0, 10000).run();
  }

  async getByDateOrderReportingObjectiveSales(filter: FilterBarSelectionReportingObjectiveSales = { canal: [], category: [], eightyPercent: 'no' }) {
    let ls = this.query();

    // FIXME QUERY BUILDER HAVE TO DECIDE IF HAVE TO AUTOMATICALLY INSERT AN AND
    let needsAnd: boolean = false;

    if (filter.from && filter.to) {
      const fromUtc = startDateFormat(filter.from);
      const toUtc = endDateFormat(filter.to);
      if (needsAnd) ls = ls.and();

      ls = ls
        .groupStart()
        .equals('start_date', formatDate(filter.from.toDate(), true, false, 'YYYY-MM-DD HH:mm:ss'))
        .equals('end_date', formatDate(filter.to.toDate(), true, false, 'YYYY-MM-DD HH:mm:ss'))
        .higherOrEquals('created_at', fromUtc)
        .lowerOrEquals('created_at', toUtc)
        .groupEnd();
      needsAnd = true;
    }

    if (filter.category && filter.category.length) {
      if (needsAnd) ls = ls.and();
      ls = ls.groupStart();
      let first: boolean = true;
      filter.category.forEach(({ id }: DataGenerateAutocompleteType) => {
        if (!first) ls = ls.or();
        ls = ls.equals('goal_id', id);
        first = false;
      });
      ls = ls.groupEnd();
      needsAnd = true;
    }

    if (filter.canal && filter.canal.length) {
      if (needsAnd) ls = ls.and();
      ls = ls.groupStart();
      let first: boolean = true;
      filter?.canal.forEach(({ id }: DataGenerateAutocompleteType) => {
        if (!first) ls = ls.or();
        ls = ls.equals('goal_id', id);
        first = false;
      });
      ls = ls.groupEnd();
    }

    if (filter.eightyPercent) {
      if (needsAnd) ls = ls.and();
      ls = ls.groupStart().equals('eighty_percent', filter.eightyPercent).groupEnd();
      needsAnd = true;
    }

    if (filter.sale_type) {
      if (needsAnd) ls = ls.and();
      ls = ls.groupStart().equals('sale_type', filter.sale_type).groupEnd();
      needsAnd = true;
    }

    if (filter.goal_range_type) {
      if (needsAnd) ls = ls.and();
      ls = ls.groupStart().equals('goal_range_type', filter.goal_range_type).groupEnd();
      needsAnd = true;
    }

    if (filter.account_id) {
      if (needsAnd) ls = ls.and();
      ls = ls.groupStart().equals('account_id', filter.account_id).groupEnd();
      needsAnd = true;
    }

    return ls.run();
  }

  async getByDateOrderReportingObjectiveOfflineSales(filter: FilterBarSelectionReportingObjectiveOfflineSales = { canal: [], category: [] }) {
    let ls = this.query();

    // FIXME QUERY BUILDER HAVE TO DECIDE IF HAVE TO AUTOMATICALLY INSERT AN AND
    let needsAnd: boolean = false;

    if (filter.from && filter.to) {
      const fromUtc = startDateFormat(moment(filter.from));
      const toUtc = endDateFormat(moment(filter.to));
      if (needsAnd) ls = ls.and();

      ls = ls.groupStart().equals('start_date', `${filter.from} 00:00:00`).equals('end_date', `${filter.to} 23:59:59`).higherOrEquals('created_at', fromUtc).lowerOrEquals('created_at', toUtc).groupEnd();
      needsAnd = true;
    }

    if (filter.category && filter.category.length) {
      if (needsAnd) ls = ls.and();
      ls = ls.groupStart();
      let first: boolean = true;
      filter.category.forEach(({ id }: DataGenerateAutocompleteType) => {
        if (!first) ls = ls.or();
        ls = ls.equals('items.product.category.category_id', id);
        first = false;
      });
      ls = ls.groupEnd();
      needsAnd = true;
    }

    if (filter?.canal && filter?.canal?.length) {
      if (needsAnd) ls = ls.and();
      ls = ls.groupStart();
      let first: boolean = true;
      filter?.canal.forEach(({ id }: DataGenerateAutocompleteType) => {
        if (!first) ls = ls.or();
        ls = ls.equals('source.marketplace.canal.canal_id', id);
        first = false;
      });
      ls = ls.groupEnd();
    }

    if (filter.sale_type) {
      if (needsAnd) ls = ls.and();
      ls = ls.groupStart().equals('sale_type', filter.sale_type).groupEnd();
      needsAnd = true;
    }

    if (filter.goal_range_type) {
      if (needsAnd) ls = ls.and();
      ls = ls.groupStart().equals('goal_range_type', filter.goal_range_type).groupEnd();
      needsAnd = true;
    }

    if (filter.account_id) {
      if (needsAnd) ls = ls.and();
      ls = ls.groupStart().equals('account_id', filter.account_id).groupEnd();
      needsAnd = true;
    }

    return ls.run();
  }
}
