import React from 'react';
import Button from '@material-ui/core/Button';
import { useTranslation } from '../../../services/translation';
import { LibbyObject } from '../../../types';
import { ScreenAligned } from '../../../components/ScreenAligned/ScreenAligned';
import { useMessageConfig } from '../hooks/MessageConfigLogic';
import InfoTable from '../../components/InfoTable';
import { columnsMessageConfigForm } from './utils/ColumnsMessageConfigForm';
import { useRowsMessageConfigForm } from './hooks/useRowsMessageConfigForm';
import LoadingData from '../../components/LoadingData';

interface MessMessageConfigListType {
  libby: LibbyObject;
}

export const MessageConfigList = ({ libby }: MessMessageConfigListType) => {
  const { t } = useTranslation();
  const { messageConfig, deleteMessageConfig, openMessageConfigEdit, onConfirmCreate, handleRequestSort, orderBy, direction, working, fetchMore } = useMessageConfig({ libby });
  const { rows } = useRowsMessageConfigForm({ messageConfig, deleteMessageConfig, openMessageConfigEdit });
  return (
    <>
      <ScreenAligned
        title={t('Message configuration')}
        additionalTitle={
          <Button color="primary" variant="outlined" onClick={() => onConfirmCreate()}>
            {t('Create')}
          </Button>
        }
      >
        <InfoTable columns={columnsMessageConfigForm} rows={rows} onBottomScroll={fetchMore} onSortChange={handleRequestSort} orderBy={orderBy} direction={direction} />
        <LoadingData label={`${t('Number of')} ${t('Message configuration').toLocaleLowerCase()}`} working={working} cant={rows.length} />
      </ScreenAligned>
    </>
  );
};
