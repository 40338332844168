import { useMemo } from 'react';
import { CompanyMarketplaceAutocomplete } from '../../../../business/CompanyMarketplace';
import { Brand, Marketplace } from '../../../../interfaces/business';
import { Company } from '../../../../interfaces/business/marketplace/Company';
import { Company_marketplace } from '../../../../interfaces/business/marketplace/CompanyMarketplace';
import { FilterBarSelection } from '../../../../routes/Reporting/routes/ReportingList/FilterBar';
import { Filter } from '../../../../types';
import { DataGenerateAutocompleteType } from '../../../../utils/generateAutocomplete';
import { RowsImput } from '../../types/Rowsimput';
import { DateInput } from '../DateInput';
import { BrandAutocomplete } from '../../../../business/product';
import { CategoryAutocomplete } from '../../../../business/Category';
import { Category } from '../../../../interfaces/business/product/Category';
import { SelectConfirmation } from 'components/SelectConfirmation';

export interface useRowsProps {
  filter: FilterBarSelection;
  working: boolean;
  filterMarketplaces: Filter;
  t: (str: string) => string;
  prepareToChange: <T, K>(value: T, name: string, setAditional?: Partial<K>, trasformDate?: 'end' | 'start') => void;
}
const currentDate = new Date();

const pastDate = new Date(currentDate);
pastDate.setFullYear(currentDate.getFullYear() - 1);

const minDate = pastDate.toISOString().split('T')[0];
const maxDate = new Date().toISOString().split('T')[0];
export const useStockDaysReportRows = ({ filter, working, filterMarketplaces, t, prepareToChange }: useRowsProps): RowsImput[] => {
  return useMemo(
    () => [
      {
        id_row: 1,
        container: true,
        direction: 'row',
        justify: 'space-between',
        alignItems: 'flex-end',
        spacing: 2,
        imputs: [
          {
            id: 1,
            md: 3,
            sm: 6,
            xs: 12,
            value: filter.marketplaces.map(({ id, value }, index) => ({ company_marketplace_product_id: index, company: {} as Company, marketplace: { marketplace_id: id, name: value } as Marketplace })),
            multiple: true,
            autoCompleteProps: { disabled: working, getOptionSelected: (option: Company_marketplace, _value: Company_marketplace) => option.marketplace.marketplace_id === _value.marketplace.marketplace_id },
            textFieldProps: { label: t('All marketplace') },
            filter: filterMarketplaces,
            direction: 'asc',
            orderBy: 'marketplace.name',
            onChange: (value: Company_marketplace[]) => {
              const transform = value.map(({ marketplace: { marketplace_id, name } }) => ({ id: marketplace_id, value: name }));
              prepareToChange<DataGenerateAutocompleteType[] | Date, FilterBarSelection>(transform, 'marketplaces');
            },
            component: CompanyMarketplaceAutocomplete
          },
          {
            id: 3,
            md: 3,
            sm: 6,
            xs: 12,
            value: filter.category?.map(({ id, value }) => ({ category_id: id, name: value })),
            multiple: true,
            autoCompleteProps: { disabled: working, getOptionSelected: (option: Category, _value: Category) => option.category_id === _value.category_id },
            textFieldProps: { label: t('All Categories') },
            onChange: (value: Category[]) => {
              const transform = value.map(({ category_id, name }) => ({ id: category_id, value: name }));
              prepareToChange<DataGenerateAutocompleteType[] | Date, FilterBarSelection>(transform, 'category');
            },
            component: CategoryAutocomplete
          },
          {
            id: 4,
            md: 3,
            sm: 6,
            xs: 12,
            value: filter.brand?.map(({ id, value }) => ({ brand_id: id, name: value })),
            multiple: true,
            autoCompleteProps: {
              disabled: working,
              getOptionSelected: (option: Brand, _value: Brand) => option.brand_id === _value.brand_id
            },
            textFieldProps: { label: t('All Brands') },
            onChange: (value: Brand[]) => {
              const transform = value.map(({ brand_id, name }) => ({ id: brand_id, value: name }));
              prepareToChange<DataGenerateAutocompleteType[] | Date, FilterBarSelection>(transform, 'brand');
            },
            component: BrandAutocomplete
          },
          {
            id: 1,
            md: 3,
            sm: 6,
            xs: 6,
            value: filter.stock,
            label: t('Stock'),
            disabled: working,
            onChange: (value: any) => {
              const parsedValue = value.target.value === 'yes' ? 'true' : 'false';
              prepareToChange<string, FilterBarSelection>(parsedValue, 'stock');
            },
            component: SelectConfirmation
          }
        ]
      },
      {
        id_row: 2,
        container: true,
        direction: 'row',
        alignItems: 'flex-end',
        spacing: 2,
        imputs: [
          {
            id: 1,
            md: 3,
            sm: 6,
            xs: 6,
            value: filter.from,
            label: t('From'),
            disabled: working,
            onChange: (value: Date) => {
              prepareToChange<Date, FilterBarSelection>(value, 'from');
            },
            component: DateInput,
            minDate,
            maxDate
          },
          {
            id: 2,
            md: 3,
            sm: 6,
            xs: 6,
            value: filter.to,
            label: t('To'),
            disabled: working,
            onChange: (value: Date) => {
              prepareToChange<Date, FilterBarSelection>(value, 'to', {}, 'end');
            },
            component: DateInput,
            minDate,
            maxDate
          }
        ]
      }
    ],
    [working, filter, filterMarketplaces, t, prepareToChange]
  );
};
