import { AnyObject } from '../types/types';
import { success, warning, critical } from '../routes/OrderReport/routes/OrderReportList/utils/colors';

export const Priority: AnyObject = {
  1: 'Low',
  2: 'Medium',
  3: 'High'
};

export const PriorityColors: AnyObject = {
  1: success,
  2: warning,
  3: critical
};
