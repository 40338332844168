import { useState, useMemo, useCallback, useEffect } from 'react';

export type useFilerParamsIdOptions = {
  orderInit: string;
  daoName: string;
  aspect?: string;
  init?: any;
  initAuxiliary?: any;
  directionInit?: 'asc' | 'desc';
};

export const useFilerParamsId = ({ orderInit, daoName, aspect = '', init = {}, initAuxiliary = {}, directionInit = 'desc' }: useFilerParamsIdOptions) => {
  const initEnabled = useMemo(() => (Object.keys(initAuxiliary).length ? initAuxiliary : init), [init, initAuxiliary]);

  const [initOld, setInitOld] = useState(initEnabled);
  const [filter, setFilter] = useState(initOld);
  const [filterSave, setfilterSave] = useState(filter);

  useEffect(() => {
    setFilter(initOld);
  }, [initOld]);

  useEffect(() => {
    if (JSON.stringify(initEnabled) !== JSON.stringify(initOld)) {
      setInitOld(initEnabled);
    }
  }, [initOld, initEnabled]);

  useEffect(() => {
    if (JSON.stringify(filterSave) !== JSON.stringify(filter)) {
      setfilterSave({ ...filter });
    }
  }, [filterSave, filter]);

  const [orderBy, setOrderBy] = useState(orderInit);
  const [direction, setDirection] = useState<'asc' | 'desc'>(directionInit);

  const paramsFetch = useMemo(
    () => ({
      daoName,
      filter,
      direction,
      orderBy,
      aspect
    }),
    [filter, direction, orderBy, daoName, aspect]
  );

  const handleRequestSort = useCallback((newOrderBy: string, newDirection: 'asc' | 'desc') => {
    setOrderBy(newOrderBy);
    setDirection(newDirection);
  }, []);

  const resetFilter = useCallback(() => {
    setFilter(init);
  }, [init]);

  return {
    filter: filterSave,
    orderBy,
    direction,
    paramsFetch,
    setFilter,
    handleRequestSort,
    resetFilter
  };
};
