export const UPDATE_ORDER_CONFIRMED_CLIENT = 'public/odoo/update_order_confirmed_client';
export const GET_LOCATION_STOCK = 'public/odoo/ster_get_location_stock';
export const GET_PRICE_LIST_B2B = 'public/odoo/ster_get_pricelist_b2b';
export const BUYER_ODOO_BILLING_INFO = 'public/odoo/buyer_odoo_billing_info/:document';
export const GET_CLIENT_DOCUMENT = 'api/custom/public/client_document/:document';
export const CREATE_PRESUPUESTO_B2B = 'public/odoo/ster_create_presupuesto_b2b';
export const REMITIR = 'public/odoo/remitir';
export const CANCEL_ORDER = 'public/odoo/cancel_order';
export const GET_SHIPPING_REPORT = 'public/odoo/get_shipping_report/:orderid';
export const CREATE_PICKING_DISPATCH_DATE = 'public/odoo/picking_dispatch_date';
