import React, { useEffect } from 'react';
import { TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { Moment } from 'moment';
import { AnyObject } from '../../types/types';
import { GeneralSelect } from '../GeneralSelect';
import { useTranslation } from '../../services/translation';
import { useDataCallGoal } from '../../hooks/useDataCallGoal';
import { GOAL_TYPE_VALUE } from 'const';

type SelectImportTypeProps = {
  multiple: boolean;
  from?: Moment;
  to?: Moment;
  inputProps: AnyObject;
  optionAll?: boolean;
  [k: string]: any;
  disabled?: boolean;
  sort?: boolean | 'asc' | 'desc';
  marketplace?: boolean;
  label: string;
  methodName?: string;
};

export const SelectGoal = ({ methodName, multiple = false, inputProps = {}, optionAll = false, disabled = false, sort, marketplace = false, label, from, to, ...styles }: SelectImportTypeProps) => {
  const { t } = useTranslation();
  const { data, working, recall } = useDataCallGoal({ methodName });

  useEffect(() => {
    if (from && to) {
      recall?.({ from, to, typeValue: marketplace ? GOAL_TYPE_VALUE.MONETARY : GOAL_TYPE_VALUE.QUANTITY });
    }
  }, [from, to, recall, marketplace]);

  return (
    <>
      {multiple ? (
        <Autocomplete
          disabled={disabled}
          style={{ display: 'inline-block', width: '100%' }}
          multiple
          id="tags-outlined"
          options={data}
          getOptionLabel={(option: { id: string; value: string }) => option.value}
          filterSelectedOptions
          onChange={inputProps.onChange}
          renderInput={(params) => <TextField {...params} variant="outlined" label={t(`All goals by ${label}`)} placeholder="" />}
          value={inputProps.value || []}
        />
      ) : (
        <GeneralSelect disabled={disabled} working={working} label={`Goal ${label}`} optionAll={optionAll} data={data} inputProps={inputProps} {...styles} sort={sort} />
      )}
    </>
  );
};
