class SessionChangeEmitterRaw {
  subscriptions: Function[];

  constructor() {
    this.subscriptions = [];
  }

  subscribe(fn: Function): () => void {
    this.subscriptions.push(fn);

    return () => this.unsubscribe(fn);
  }

  unsubscribe(fn: Function): void {
    this.subscriptions = this.subscriptions.filter((item) => item !== fn);
  }

  emitChange<F extends any[]>(...args: F): void {
    this.subscriptions.forEach((func) => func(...args));
  }
}

export const SessionChangeEmitter = new SessionChangeEmitterRaw();
