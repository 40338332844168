import moment from 'moment';
import { GoalPeriod } from '../../../../../interfaces/business/goal/GoalPeriod';
import { Column } from '../../../../components/InfoTable';

export const columnsReportingGoalSaleSettingFieldPeriods: Array<Column> = [
  {
    id: 'actions',
    label: '',
    noSort: true,
    width: 30
  },
  {
    id: 'goal_period_id',
    label: 'Goal period id',
    render: ({ goal_period_id }: GoalPeriod) => goal_period_id
  },
  {
    id: 'Quarter',
    label: 'Quarter',
    render: ({ start_date }: GoalPeriod) => `${moment(start_date).utc().quarter()}-${moment(start_date).utc().format('YYYY')}`,
    orderById: 'start_date'
  },
  {
    id: 'value',
    label: 'Value'
  }
];
