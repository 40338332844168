import { LibbyFetchDAO } from '../LibbyFetchDAO';

export class MessageSourceDAO extends LibbyFetchDAO {
  constructor() {
    super('ster_message_source', 'message_source_id');
  }

  getAll() {
    return this.query().limit(0, 10000).run();
  }
}
