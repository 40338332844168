import { FilterBarSelection } from '../../routes/Reporting/routes/ReportingList/FilterBar';
import { ReportingOrderByStateFilter } from './components/ReportingOrderByStateFilter/ReportingOrderByStateFilter';
import { ReportingSalesFilter } from './components/ReportingSales/ReportingSalesFilter';
import { StockDaysReportFilter } from './components/StockDaysReport/StockDaysReportFilter';
import { makeFilterBar } from './generator/makeFilterBar';
import { ScreenComponent } from './types';
import { ChargebackReportFilter } from './components/ChargebackReport/ChargebackReportFilter';

export enum SCREENS {
  REPORTING_SALES = 1,
  REPORTING_ORDER_BY_STATE = 2,
  REPORTING_STOCK_DAYS = 3,
  REPORTING_CHARGEBACK = 4
}

export const SCREEN_COMPONENT: { [k: number]: ScreenComponent<any> } = {
  [SCREENS.REPORTING_SALES]: { screen_id: String(SCREENS.REPORTING_SALES), render: ReportingSalesFilter },
  [SCREENS.REPORTING_ORDER_BY_STATE]: { screen_id: String(SCREENS.REPORTING_ORDER_BY_STATE), render: ReportingOrderByStateFilter },
  [SCREENS.REPORTING_STOCK_DAYS]: { screen_id: String(SCREENS.REPORTING_STOCK_DAYS), render: StockDaysReportFilter },
  [SCREENS.REPORTING_CHARGEBACK]: { screen_id: String(SCREENS.REPORTING_CHARGEBACK), render: ChargebackReportFilter }
};

export const FilterReportinSales = makeFilterBar<FilterBarSelection>(SCREEN_COMPONENT[SCREENS.REPORTING_SALES]);
export const FilterReportingOrderByState = makeFilterBar<FilterBarSelection>(SCREEN_COMPONENT[SCREENS.REPORTING_ORDER_BY_STATE]);
export const FilterStockDaysReport = makeFilterBar<FilterBarSelection>(SCREEN_COMPONENT[SCREENS.REPORTING_STOCK_DAYS]);
export const FilterChargebackReport = makeFilterBar<FilterBarSelection>(SCREEN_COMPONENT[SCREENS.REPORTING_CHARGEBACK]);
