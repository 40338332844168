import moment from 'moment';
import { useEffect, useState, useMemo } from 'react';
import { useReportB2BStockLibbyCall } from '../../../business';
import { RowsType } from '../../../types';

export interface InitialValuesFilter {
  startDate: string;
  endDate: string;
}

export const useReportB2BStock = () => {
  const initialValues: InitialValuesFilter = {
    startDate: moment().subtract(1, 'months').format('YYYY-MM-DD'),
    endDate: moment().format('YYYY-MM-DD')
  };

  const [startDate, setStartDate] = useState(initialValues.startDate);
  const [endDate, setEndDate] = useState(initialValues.endDate);

  const initFilter = useMemo(
    () => ({
      startDate,
      endDate
    }),
    [endDate, startDate]
  );

  const { data, working, recall } = useReportB2BStockLibbyCall({ methodName: 'getByDate', params: [initFilter] });

  const onFilterChange = (param: any, type: string) => {
    switch (type) {
      case 'startDate':
        setStartDate(moment(param._d).format('YYYY-MM-DD'));
        break;
      case 'endDate':
        setEndDate(moment(param._d).format('YYYY-MM-DD'));
        break;
      default:
        console.log('noting');
    }
  };

  const onRefresh = () => {
    setStartDate(initialValues.startDate);
    setEndDate(initialValues.endDate);
  };

  useEffect(() => {
    if (initFilter) {
      recall(initFilter);
    }
  }, [initFilter, recall]);

  const dataRows = data as RowsType[];
  const rows = dataRows?.map((row) => ({
    id: row.sku,
    sku: row.sku,
    quantity: row.quantity,
    name: row.name
  }));

  return {
    rows,
    working,
    onFilterChange,
    onRefresh,
    initFilter
  };
};
