export const buyerDirection = (
  street: string | null,
  street_number: string | null,
  cityName: string | null,
  floor: string | null,
  department: string | null,
  extra_address: string | null,
  comments: string | null,
  cityState: string | null
) => `${street || ''} ${street_number || ''}, ${cityName || ''}, ${department?.length && department !== '0' ? `${floor && floor > '0' ? `piso ${floor}` : ''} dpto ${department},` : ''}
     ${extra_address?.length ? `${extra_address},` : ''}
      ${comments?.length ? `${comments},` : ''}${cityState || ''}`;

export const buyerMap = [
  {
    key: 'first_name',
    label: 'First Name'
  },
  {
    key: 'last_name',
    label: 'Last Name'
  },
  {
    key: 'document_type',
    label: 'Document Type',
    formatter: (value: any) => value?.name
  },
  {
    key: 'document',
    label: 'Document'
  },
  {
    key: 'email',
    label: 'Email'
  },
  {
    key: 'phone_number',
    label: 'Phone Number'
  },
  {
    key: 'address',
    label: 'Address',
    formatter: (value: any, data: any) => buyerDirection(data?.street, data?.street_number, data?.city?.name, data?.floor, data?.department, data?.extra_address, data?.comments, data?.city?.state?.name)
  },
  {
    key: 'zip',
    label: 'Zip Code'
  }
];
