import { Grid } from '@material-ui/core';
import React from 'react';
import { ButtonComponent } from '../../../../components/Button';
import { ScreenAligned } from '../../../../components/ScreenAligned/ScreenAligned';
import { useTranslation } from '../../../../services/translation';
import { ButtonDetailsType } from '../../../../types/Button';
import InfoTable from '../../../components/InfoTable';
import LoadingData from '../../../components/LoadingData';
import { SearchFilters } from './components/SearchFilters';
import { useReportingGoalSaleSettingLogic } from './hook/useReportingGoalSalesSettingList';
import { columnsReportingGoalSaleSettingProducts } from './utils/columnsReportingGoalSaleSettingProducts';

export const ReportingObjectiveSalesSettingList = () => {
  const { t } = useTranslation();

  const { rowsGoal, working, handleRequestSort, direction, fetchMore, buttons, filter, setFilter, titleShow } = useReportingGoalSaleSettingLogic();

  return (
    <ScreenAligned
      title={titleShow}
      additionalTitle={
        <>
          {buttons.map((dataButton: ButtonDetailsType) => (
            <ButtonComponent
              key={dataButton.id}
              title={dataButton.title}
              className={dataButton.className}
              variant={dataButton.variant}
              color={dataButton.color}
              onClick={dataButton.onClick}
              type={dataButton.type}
              disabled={dataButton.disabled}
              loading={dataButton.loading}
            />
          ))}
        </>
      }
    >
      <Grid container direction="row" justify="center" alignItems="center">
        <SearchFilters filter={filter} onFilterChange={setFilter} />
        <InfoTable columns={columnsReportingGoalSaleSettingProducts} rows={rowsGoal} onBottomScroll={fetchMore} onSortChange={handleRequestSort} direction={direction} rowIdKey="goal_id" md={12} sm={12} xs={12} />
        {(working || !!rowsGoal.length) && <LoadingData label={`${t('Loaded goals')}`} working={working} cant={rowsGoal.length} />}
      </Grid>
    </ScreenAligned>
  );
};
