import { Column } from '../../../../components/InfoTable';

export const columnsReportingGoalSaleSettingFieldMarketplaces: Array<Column> = [
  {
    id: 'actions',
    label: '',
    noSort: true,
    width: 30
  },
  {
    id: 'name',
    label: 'Name',
    style: {
      whiteSpace: 'nowrap'
    }
  },
  {
    id: 'delete',
    label: 'Actions',
    noSort: true,
    style: {
      whiteSpace: 'nowrap'
    }
  }
];
