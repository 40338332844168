import { LibbyFetchDAO } from '../LibbyFetchDAO';

export class DispatchUpdateDAO extends LibbyFetchDAO {
  constructor() {
    super('ster_dispatch_update', 'dispatch_id');
  }

  getAll() {
    return this.query().limit(0, 10000).run();
  }
}
