import { useMemo } from 'react';
import { FilterBarSelection } from '../../../../routes/Reporting/routes/ReportingList/FilterBar';
import { Filter } from '../../../../types';
import { RowsImput } from '../../types/Rowsimput';
import { DateInput } from '../DateInput';
import { SelectConfirmation } from 'components/SelectConfirmation';
import { SelectType } from '../SelectType/SelectType';

export interface useRowsProps {
  filter: FilterBarSelection;
  working: boolean;
  filterMarketplaces: Filter;
  t: (str: string) => string;
  prepareToChange: <T, K>(value: T, name: string, setAditional?: Partial<K>, trasformDate?: 'end' | 'start') => void;
}
const currentDate = new Date();

const pastDate = new Date(currentDate);
pastDate.setFullYear(currentDate.getFullYear() - 1);

const maxDate = new Date().toISOString().split('T')[0];
export const useChargebackReportRows = ({ filter, working, t, prepareToChange }: useRowsProps): RowsImput[] => {
  return useMemo(
    () => [
      {
        id_row: 1,
        container: true,
        direction: 'row',
        justify: 'space-between',
        alignItems: 'flex-end',
        spacing: 2,
        imputs: [
          {
            id: 1,
            md: 3,
            sm: 6,
            xs: 12,
            value: filter.chargeback,
            label: t('Chargeback'),
            disabled: working,
            onChange: (value: any) => {
              prepareToChange<string, FilterBarSelection>(value.target.value, 'chargeback');
            },
            component: SelectConfirmation,
            optionAll: true
          },
          {
            id: 2,
            md: 3,
            sm: 6,
            xs: 12,
            value: filter.view,
            label: t('View'),
            disabled: working,
            onChange: (value: any) => {
              prepareToChange<string, FilterBarSelection>(value.target.value, 'view');
            },
            component: SelectType
          },
          {
            id: 3,
            md: 3,
            sm: 6,
            xs: 12,
            value: filter.from,
            label: t('From'),
            disabled: working,
            onChange: (value: Date) => {
              prepareToChange<Date, FilterBarSelection>(value, 'from');
            },
            component: DateInput,
            maxDate,
            views: ['year', 'month']
          },
          {
            id: 4,
            md: 3,
            sm: 6,
            xs: 12,
            value: filter.to,
            label: t('To'),
            disabled: working,
            onChange: (value: Date) => {
              prepareToChange<Date, FilterBarSelection>(value, 'to', {}, 'end');
            },
            component: DateInput,
            maxDate,
            views: ['year', 'month']
          }
        ]
      }
      /* {
        id_row: 2,
        container: true,
        direction: 'row',
        alignItems: 'flex-end',
        spacing: 2,
        imputs: [
          {
            id: 1,
            md: 3,
            sm: 6,
            xs: 6,
            value: filter.from,
            label: t('From'),
            disabled: working,
            onChange: (value: Date) => {
              prepareToChange<Date, FilterBarSelection>(value, 'from');
            },
            component: DateInput,
            minDate,
            maxDate
          },
          {
            id: 2,
            md: 3,
            sm: 6,
            xs: 6,
            value: filter.to,
            label: t('To'),
            disabled: working,
            onChange: (value: Date) => {
              prepareToChange<Date, FilterBarSelection>(value, 'to', {}, 'end');
            },
            component: DateInput,
            minDate,
            maxDate
          }
        ]
      } */
    ],
    [working, filter, t, prepareToChange]
  );
};
