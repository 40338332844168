import { LibbyFetchDAO } from '../LibbyFetchDAO';

export class MessageReasonDAO extends LibbyFetchDAO {
  constructor() {
    super('ster_message_reason', 'message_reason_id');
  }

  getAll() {
    return this.query().limit(0, 10000).run();
  }
}
