import React, { useCallback } from 'react';
import { useSnackbar } from 'notistack';
import CustomModal from '../../services/customFormDialog';
import { LibbyObject } from '../../types';
import { SearchDialog } from '../SearchDialog';
import { ProcessTable } from './ProcessTable';

const SearchDialogModal = CustomModal(SearchDialog);

export const useAlertModal = ({ libby }: { libby: LibbyObject }) => {
  const { enqueueSnackbar } = useSnackbar();

  const showModal = useCallback(async (processList) => {
    try {
      await SearchDialogModal.show({
        title: 'Warning: The following processes should be checked as soon as possible.',
        id: 'ster_config_id',
        properties: ['name'],
        label: 'Name process',
        data: processList,
        renderItem: ({ data }: any) => <ProcessTable items={data} />
      });
    } catch (error) {
      // nothing
    }
  }, []);

  const validateAlert = useCallback(async () => {
    try {
      const sterData = await libby.ster_ster_config.query().equals('lastState', 'failure').run();
      if (sterData.length) {
        await showModal(sterData);
      }
    } catch (error) {
      enqueueSnackbar('Error consulting process monitor alert.', {
        variant: 'error'
      });
    }
  }, [libby.ster_ster_config, showModal, enqueueSnackbar]);

  return { validateAlert };
};
