import React, { useCallback, useMemo, useState } from 'react';
import { flatten } from 'lodash';
import { useSnackbar } from 'notistack';
import PrintIcon from '@material-ui/icons/Print';
import AssignmentIcon from '@material-ui/icons/Assignment';
import { LibbyObject, RowsType } from '../../../../../types';
import { OptionsLibbyFetch, useLibbyFetch } from '../../../../../hooks';
import { DISPATCH_ITEM_STATE, GET_SHIPPING_REPORT, ORDER_DOCUMENT_SOURCE, ORDER_DOCUMENT_TYPE, ORDER_STATE } from '../../../../../const';
import { Order_document, Collect, Collect_item } from '../../../../../interfaces/business';
import { pickControlProductsColumns } from '../utils/PickControlColumns';

import { MakeCell } from '../../../../components/MakeCell';
import { OrderWithUrl } from '../../../../../interfaces';
import { generatorUrlBarcode } from '../../../../../utils';
import { TagMangerViewPdf } from '../../../../TagManager/routes/TagManagerViewPdf/TagManagerViewPdf';
import { useTranslation } from '../../../../../services/translation';
import CustomModal from '../../../../../services/customFormDialog';
import ConfirmDialog from '../../../../../components/ConfirmDialog';
import { ModalTitle } from '../../../../Collection/common';
import { API_URL } from '../../../../../config';
import { ExportDialogComponent } from '../../../../../components/ExportDialog';
import { documentsColumns, DocumentsTable } from '../../../../components/DocumentsTable';

type usePickControlProps = {
  libby: LibbyObject;
  paramsFetchCollect: OptionsLibbyFetch;
  paramsFetchCart: OptionsLibbyFetch;
};

const SearchDialogModal = CustomModal(ExportDialogComponent);
const ConfirmModal = CustomModal(ConfirmDialog);

export const usePickControlDetailLogic = ({ libby, paramsFetchCollect, paramsFetchCart }: usePickControlProps) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { data: dataCart, working: workingCart } = useLibbyFetch(libby, paramsFetchCart);
  const { data, working } = useLibbyFetch(libby, paramsFetchCollect);
  const [tagView, setTagView] = useState(<></>);
  const [showModalTagManagerView, setShowModalTagManagerView] = useState(false);
  const [loadingDocuments, setLoadingDocuments] = useState(false);

  const showReminderPrint = useCallback(async () => {
    await ConfirmModal.show({
      title: <ModalTitle title="Reminder" />,
      content: `${t('Please print documents and put them in its correct ubication')}`,
      confirmText: t('Accept'),
      oneButton: true
    });
  }, [t]);

  const handleOpenLabels = useCallback(
    async (orderId: string) => {
      const response = await libby.ster_order_so.fetchAllById([orderId]);
      const allResponse = flatten(response) as OrderWithUrl[];
      const ordersSource = allResponse.map((item: OrderWithUrl) => ({
        ...item,
        urlBarcode: generatorUrlBarcode(item.order_id)
      }));
      await showReminderPrint();
      const component = <TagMangerViewPdf dataChecked={{ all: ordersSource }} resetCheck={() => {}} title={[]} savePrint={() => {}} disableScreenType />;
      setShowModalTagManagerView(true);
      setTagView(component);
    },
    [libby, showReminderPrint]
  );

  const getDocuments = useCallback(
    async (dataOrder: { order_id: number }[]) => {
      try {
        const documents = await Promise.all(dataOrder.map(({ order_id }: { order_id: number }) => libby.ster_order_document.getAllByOrderId(order_id)));
        const allDocuments = flatten(documents);
        for (let i = 0; i < dataOrder.length; i++) {
          const remito = allDocuments.find(
            ({ order, source: { order_document_source_id }, type: { order_document_type_id } }) =>
              dataOrder[i].order_id.toString() === order.order_id && order_document_type_id === ORDER_DOCUMENT_TYPE.REMITO && order_document_source_id === ORDER_DOCUMENT_SOURCE.ERP.toString()
          );
          // means we dont have remito or we alredy created it (if url looks different means odoo sent us a document and we need to replace it)
          if (!remito || !remito?.url?.includes('documents.ster.phinxlab.com')) {
            try {
              /* eslint-disable-next-line */
              const response = await fetch(`${API_URL}/${GET_SHIPPING_REPORT.replace(':orderid', dataOrder[i].order_id.toString())}`, {
                method: 'GET'
              });
              /* eslint-disable-next-line */
              const json = await response.json();
              if (json.order_document_id) {
                const index = allDocuments.findIndex(
                  ({ order, source: { order_document_source_id }, type: { order_document_type_id } }) =>
                    dataOrder[i].order_id.toString() === order.order_id && order_document_type_id === ORDER_DOCUMENT_TYPE.REMITO && order_document_source_id === ORDER_DOCUMENT_SOURCE.ERP.toString()
                );
                if (index > 0) {
                  allDocuments[index] = '';
                }

                allDocuments.push(json);
              }
            } catch (e) {
              console.log('error getting document');
            }
          }
        }
        return allDocuments.filter((document) => document);
      } catch (err) {
        console.error(err);
        enqueueSnackbar(t('There was an error loading the documents'), { variant: 'error' });
        return [];
      }
    },
    [libby, enqueueSnackbar, t]
  );

  const handleOpenInvoiceList = useCallback(
    async (orderId: string) => {
      setLoadingDocuments(true);
      const allDocuments = await getDocuments([{ order_id: parseInt(orderId, 10) }]);
      setLoadingDocuments(false);
      await showReminderPrint();
      const invoicesAndRemitos = allDocuments.filter(({ type: { order_document_type_id } }: Order_document) => order_document_type_id === ORDER_DOCUMENT_TYPE.INVOICE || order_document_type_id === ORDER_DOCUMENT_TYPE.REMITO);
      try {
        await SearchDialogModal.show({
          title: 'Documents',
          id: 'order_document_id',
          properties: ['documentNumber', 'type.name'],
          label: 'Document',
          data: invoicesAndRemitos,
          maxWidth: 'md',
          render: () => (
            <DocumentsTable
              documents={invoicesAndRemitos}
              columns={[
                {
                  id: 'orderId',
                  label: 'Order',
                  render: (row: any) => row?.order?.order_id || ''
                },
                ...documentsColumns
              ]}
            />
          )
        });
      } catch (error) {
        console.log(error);
        enqueueSnackbar(t('There was an error loading the documents'), { variant: 'error' });
      }
    },
    [getDocuments, showReminderPrint, setLoadingDocuments, enqueueSnackbar, t]
  );

  const checkCollectItems = useCallback(
    (collect_item: Collect_item) =>
      (collect_item.dispatch_item.dispatch_item_state.dispatch_item_state_id === DISPATCH_ITEM_STATE.PREPARING_PACKAGE || collect_item.dispatch_item.dispatch_item_state.dispatch_item_state_id === DISPATCH_ITEM_STATE.ASSIGNED) &&
      collect_item.dispatch_item.order.state.order_state_id !== ORDER_STATE.WAITING_FOR_PREPARATION.toString(),
    []
  );

  const rows: RowsType[] = useMemo(() => {
    const dataCollect = (dataCart[0] as Collect) || (data[0] as Collect);

    const rowsUpdate: RowsType[] = [];
    dataCollect?.items?.map((collect_item: Collect_item) => {
      collect_item?.items_product?.map((items_product: any) => {
        rowsUpdate.push({
          id: collect_item.dispatch_item.order.order_id,
          so_number: collect_item.dispatch_item.order.so_number,
          order_id: collect_item.dispatch_item.order.order_id,
          cart_box_ix: collect_item.cartBox.cart_box_id,
          SKU: items_product.order_item.sku,
          product_name: items_product.order_item.name,
          serial_number: items_product.serial_number,
          actions: (
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <MakeCell
                label=""
                icon={PrintIcon}
                onClick={(e) => {
                  e.stopPropagation();
                  handleOpenLabels(collect_item.dispatch_item.order.order_id);
                }}
                disabled={!checkCollectItems(collect_item)}
              />
              <MakeCell
                label=""
                icon={AssignmentIcon}
                onClick={(e) => {
                  e.stopPropagation();
                  handleOpenInvoiceList(collect_item.dispatch_item.order.order_id);
                }}
                disabled={!checkCollectItems(collect_item)}
              />
            </div>
          )
        });
      });
    });

    return rowsUpdate;
  }, [data, dataCart, handleOpenInvoiceList, handleOpenLabels, checkCollectItems]);

  const collect: Collect = useMemo(() => {
    const dataCollect = (dataCart[0] as Collect) || (data[0] as Collect);
    return dataCollect;
  }, [dataCart, data]);

  return {
    collect,
    working: workingCart || working,
    rows,
    columns: pickControlProductsColumns,
    isCart: !!dataCart,
    tagView,
    setTagView,
    showModalTagManagerView,
    setShowModalTagManagerView,
    loadingDocuments
  };
};
