import React, { useCallback, useMemo } from 'react';
import { DatabaseConnector } from '@phinxlab/libby-rest-web';
import { TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { AnyObject, LibbyObject } from '../../types';
import { useLibbyCall } from '../../hooks';
import { GeneralSelect } from '../GeneralSelect';
import { DataGenerateSelectType } from '../../utils/generateSelect';
import { useTranslation } from '../../services/translation';

type SelectDispatchProps = {
  inputProps: AnyObject;
  [k: string]: any;
  libby: LibbyObject;
  label?: string;
  sort?: boolean | 'asc' | 'desc';
  multiple?: boolean;
  disabled?: boolean;
};

export const SelectOrderSourceTypeRaw = ({ disabled = false, libby, inputProps, label = 'Source type', sort, multiple = false, ...styles }: SelectDispatchProps) => {
  const { t } = useTranslation();
  const { data: dataRaw, working = true } = useLibbyCall(libby, {
    daoName: 'ster_order_source_type',
    methodName: 'getAll'
  });

  const data = useMemo(
    () =>
      dataRaw.map(({ order_source_type_id, name }: AnyObject) => ({
        id: order_source_type_id.toString(),
        value: name
      })),
    [dataRaw]
  );
  const onChangeAutoComplete = useCallback(
    (event, newValue: DataGenerateSelectType[]) => {
      inputProps.onChange(
        newValue.map((marketplace: DataGenerateSelectType) => ({
          value: marketplace.id,
          path: ''
        }))
      );
    },
    [inputProps]
  );

  return (
    <>
      {multiple && inputProps.valueArray ? (
        <Autocomplete
          disabled={disabled}
          style={{ display: 'inline-block', width: '100%' }}
          multiple
          id="tags-outlined"
          options={data}
          getOptionLabel={(option: { id: string; value: string }) => option.value}
          filterSelectedOptions
          onChange={onChangeAutoComplete}
          renderInput={(params) => <TextField {...params} variant="outlined" label={t('All source types')} placeholder="" />}
        />
      ) : (
        <GeneralSelect optionAll working={working} label={label} data={data} inputProps={{ ...inputProps }} {...styles} sort={sort} />
      )}
    </>
  );
};

export const SelectOrderSourceType = DatabaseConnector(SelectOrderSourceTypeRaw)('ster_order_source_type');
