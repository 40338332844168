import { makeValidator, ValidatorRule, ValidatorType } from '../../../../../utils';

export const formReportingGoalInitialValues = {
  name: '',
  value: '',
  start_date: '',
  end_date: '',
  goal_type: '',
  goal_type_value: ''
};

const validations: ValidatorRule[] = [
  { key: 'name', validators: [ValidatorType.REQUIRED] },
  { key: 'value', validators: [ValidatorType.REQUIRED] },
  { key: 'trimester_date', validators: [ValidatorType.REQUIRED] },
  { key: 'year', validators: [ValidatorType.REQUIRED] },
  { key: 'goal_type', validators: [ValidatorType.REQUIRED] },
  { key: 'goal_type_value', validators: [ValidatorType.REQUIRED] }
];

export const validateReportingGoalAdd = makeValidator(validations);
