export const SHIPMENT_TYPE = [
  { id: '11', value: 'Transporte' },
  { id: '14', value: 'Retiro en planta' },
  { id: '2', value: 'Andreani' }
];

export enum SHIPMENT_TYPE_IDS {
  TRANSPORTE = 11,
  RETIRO_EN_PLANTA = 14,
  ANDREANI = 2
}

export const shipmentTypeIdOdoo = [
  {
    id: SHIPMENT_TYPE_IDS.ANDREANI,
    group: [
      { companyId: 1, id: 6, type: 'ENVIO002' },
      { companyId: 2, id: 106, type: '[Delivery_007]' },
      { companyId: 3, id: 206, type: 'Delivery_007 Ath' }
    ]
  },
  {
    id: SHIPMENT_TYPE_IDS.RETIRO_EN_PLANTA,
    group: [
      { companyId: 1, id: 1, type: 'Delivery_007' },
      { companyId: 2, id: 101, type: '[Delivery_007]' },
      { companyId: 3, id: 201, type: 'Delivery_007 Ath' }
    ]
  },
  {
    id: SHIPMENT_TYPE_IDS.TRANSPORTE,
    group: [
      { companyId: 1, id: 5, type: 'Delivery_007' },
      { companyId: 2, id: 105, type: '[Delivery_007]' },
      { companyId: 3, id: 205, type: 'Delivery_007 Ath' }
    ]
  }
];
