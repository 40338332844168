import { useCallback, useState } from 'react';
import { ThemeProvider } from '@material-ui/styles';
import { Dialog, DialogActions, DialogContent, Typography, Button, CircularProgress } from '@material-ui/core';
import { useForm } from 'react-final-form-hooks';
import { ResetForm } from '../../../../../components/ResetForm';
import { FormProps } from '../../../../../types';
import { useModalFormStyles } from '../../../../../theme';
import { FileInput } from './FileInput';
import { SnackbarProvider } from 'notistack';

const importationInitialValue = { importationText: '', delimiter: '' };

export const BrandBulkLoadForm = ({
  open = false,
  onCancel = () => {},
  onConfirm = () => {},
  title = 'Confirmation Dialog',
  cancelText = 'Cancel',
  confirmText = 'confirmText',
  customTheme = null,
  validate,
  formInitialValues = {},
  libby
}: FormProps) => {
  const styles = useModalFormStyles();
  const [loading, setLoading] = useState<boolean>(false);
  const [importation, setImportation] = useState(importationInitialValue);
  const [errorImportation, setErrorImportation] = useState(false);

  const validationimportText = useCallback(async () => {
    let validation = true;
    if (!importation.importationText) {
      validation = false;
      setErrorImportation(true);
    } else setErrorImportation(false);

    return validation;
  }, [importation]);

  const onSubmit = useCallback(async () => {
    try {
      if (await validationimportText()) {
        setLoading(true);
        const copyFeaturesResponse = await libby.ster_product_bulk_brand.save({ bulk: importation });
        onConfirm(copyFeaturesResponse);
      }
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  }, [importation, onConfirm, libby, setLoading, validationimportText]);

  const { form, handleSubmit } = useForm({
    initialValues: formInitialValues,
    onSubmit,
    validate
  });

  const dialog = (
    <Dialog open={open} onClose={onCancel} fullWidth aria-labelledby="form-dialog-title">
      <DialogContent className={styles.title}>
        <Typography variant="h3">{title}</Typography>
      </DialogContent>
      <form noValidate onSubmit={handleSubmit}>
        <DialogContent className={styles.content}>
          <FileInput onUpdateFiles={setImportation} error={errorImportation} />
        </DialogContent>
        <DialogActions>
          <ResetForm form={form} open={open} />
          <Button
            onClick={() => {
              onCancel();
            }}
          >
            {cancelText}
          </Button>
          <Button color="primary" type="submit">
            {loading ? <CircularProgress size={24} /> : confirmText}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );

  return !customTheme ? (
    dialog
  ) : (
    <ThemeProvider theme={customTheme}>
      <SnackbarProvider maxSnack={3}>{dialog}</SnackbarProvider>
    </ThemeProvider>
  );
};
