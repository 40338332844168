import React from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/styles';
import { ERPOrder } from '../../../types/ERPOrder';
import { makeMapLabelValues } from '../../../../utils/mappers';
import { formatDate } from '../../../../../utils';
import { useTranslation } from '../../../../../services/translation';
import { InformationSection } from '../../../../../components/InformationSection';
import { gridBreakpointsWithTablet } from '../../../../../const/gridBreakpoints';

const useStyles = makeStyles({
  containInfo: {
    paddingTop: '15px'
  }
});

type ERPOrderDetailDataProps = {
  data: ERPOrder;
  working?: boolean;
};

export const ERPOrderDetailData = ({ data, working }: ERPOrderDetailDataProps) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const basicInformationMap = [
    {
      key: 'so_name',
      label: 'SO Name'
    },
    {
      key: 'sales_channel_name',
      label: 'Sales Channel Name'
    },
    {
      key: 'sales_channel_number',
      label: 'Marketplace ID'
    },
    {
      key: 'state',
      label: 'State',
      formatter: (value: string) => t(value)
    },
    {
      key: 'sales_team',
      label: 'Sales Team'
    },
    {
      key: 'credit_note_number',
      label: 'Credit Note Number'
    },
    {
      key: 'id_ml',
      label: 'ID ML'
    }
  ];

  const clientMap = [
    {
      key: 'client_name',
      label: 'Client Name'
    },
    {
      key: 'client_phone',
      label: 'Client Phone'
    },
    {
      key: 'client_movil',
      label: 'Client Mobile'
    },
    {
      key: 'client_mail',
      label: 'Client Email'
    },
    {
      key: 'client_address',
      label: 'Client Address'
    }
  ];

  const invoiceMap = [
    {
      key: 'invoice_number',
      label: 'Invoice Number'
    },
    {
      key: 'invoice_date',
      label: 'Invoice Date',
      formatter: (value: Date) => formatDate(value)
    }
  ];

  const shipmentMap = [
    {
      key: 'shipment_tracking',
      label: 'Shipment Tracking'
    },
    {
      key: 'shipment_date',
      label: 'Shipment Date',
      formatter: (value: Date) => formatDate(value)
    }
  ];

  const mapperLabelValues = makeMapLabelValues(t);
  const basicMapper = mapperLabelValues(basicInformationMap);
  const clientMapper = mapperLabelValues(clientMap);
  const invoiceMapper = mapperLabelValues(invoiceMap);
  const shipmentMapper = mapperLabelValues(shipmentMap);

  return (
    <>
      <Grid className={classes.containInfo}>
        <Grid container spacing={1} justify="space-between">
          {!working && data && (
            <InformationSection title={t('Basic Information')} containerProps={gridBreakpointsWithTablet} noPadding>
              {basicMapper(data)}
            </InformationSection>
          )}
          {data?.client_name && (
            <InformationSection title={t('Client')} containerProps={gridBreakpointsWithTablet} noPadding>
              {clientMapper(data)}
            </InformationSection>
          )}
          {data?.invoice_number && (
            <InformationSection title={t('Invoice')} containerProps={gridBreakpointsWithTablet} noPadding>
              {invoiceMapper(data)}
            </InformationSection>
          )}
          {data?.shipment_tracking && (
            <InformationSection title={t('Shipment')} containerProps={gridBreakpointsWithTablet} noPadding>
              {shipmentMapper(data)}
            </InformationSection>
          )}
        </Grid>
      </Grid>
    </>
  );
};
