import { Order_reporting_delivery_control_reporting } from '../../../interfaces/business';
import { makeBusinessEntity } from '../../../lib/libby/generator/makeBusinessEntity';

const DAO_NAME = 'ster_reporting_delivery_control';

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch }
} = makeBusinessEntity<Order_reporting_delivery_control_reporting>(DAO_NAME);

// hooks
export const useReportingDeliveryControlFetchById = fetchById;
export const useReportingDeliveryControlLibbyCall = libbyCall;
export const useReportingDeliveryControlDAO = libbyDAO;
export const useReportingDeliveryControlLibbyFetch = libbyFetch;
