import { makeBusinessEntity } from '../../lib/libby/generator/makeBusinessEntity';
import { PaymentMethodOdoo } from '../../interfaces/business';

const DAO_NAME = 'paymentmethododoo';

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch }
} = makeBusinessEntity<PaymentMethodOdoo>(DAO_NAME);

// hooks
export const usePaymentMethodOdooFetchById = fetchById;
export const usePaymentMethodOdooLibbyCall = libbyCall;
export const usePaymentMethodOdooDAO = libbyDAO;
export const usePaymentMethodOdooLibbyFetch = libbyFetch;
