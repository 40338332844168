export function replaceNonNumericCharacters(number: string) {
  return number.replace(/[^0-9]/g, '');
}

export function replaceNonNumericCharactersComma(number: string) {
  if ((number.length === 1 || number.slice(-1) === number.slice(-2, -1)) && number.slice(-1) === ',') {
    return number.slice(0, -1);
  }
  return number.replace(/[^0-9,]/g, '');
}
