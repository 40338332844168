import React, { useCallback } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import { useForm, FormConfig, useField } from 'react-final-form-hooks';
import { ValidationErrors } from 'final-form';
import { ThemeProvider, makeStyles } from '@material-ui/styles';
import { Box, DialogTitle, TextField } from '@material-ui/core';
import { useTranslation } from '../../../services/translation';
import { makeGetErrorAndHelperText } from '../../../utils/materialHelpers';
import { MessageField } from './MessageField';
import { TemplatesFieldsTypes } from '../types/types';
import { ResetForm } from '../../../components/ResetForm';
import { SelectMessageFrom } from './SelectMessageFrom';

const useStyles = makeStyles({
  root: {
    '& .MuiDialog-paperWidthSm': {
      width: '500px',
      maxWidth: '450px'
    },
    '& .MuiDialogContent-root': {
      padding: '0px 24px 8px 24px'
    }
  },
  textField: {
    marginBottom: '20px'
  },
  title: {
    overflow: 'visible',
    '& > h3': {
      fontFamily: '"Oswald"'
    }
  },
  rootEdit: {
    display: 'flex',
    justifyContent: 'space-around',
    width: '100%'
  },
  rootCancelEdit: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%'
  }
});

const dummyFunc = () => {};

interface FormDialogType extends FormConfig<any> {
  open: boolean;
  onCancel: () => void;
  onConfirm: Function;
  title: string;
  cancelText: string;
  confirmText: string;
  customTheme: string | null;
  validate: (values: any) => ValidationErrors | Promise<ValidationErrors> | undefined;
  formInitialValues: { [name: string]: string };
}

export const MessageTemplateFormDialog = ({
  open = false,
  onCancel = dummyFunc,
  onConfirm = dummyFunc,
  title = 'Confirmation Dialog',
  cancelText = 'Cancel',
  confirmText = 'Save',
  customTheme = null,
  validate,
  formInitialValues = {}
}: FormDialogType) => {
  const { t } = useTranslation();
  const getErrorAndHelperText = makeGetErrorAndHelperText(t);
  const classes = useStyles();

  const onSubmit = useCallback(
    async ({ message, name, from, message_subject }: TemplatesFieldsTypes) => {
      onConfirm({
        message,
        name,
        from,
        message_subject
      });
    },
    [onConfirm]
  );

  const { form, handleSubmit } = useForm({
    initialValues: formInitialValues,
    onSubmit,
    validate
  });

  const message = useField('message', form);
  const name = useField('name', form);
  const from = useField('from', form);
  const message_subject = useField('message_subject', form);

  const dialog = (
    <Dialog className={classes.root} open={open} onClose={onCancel} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">{title}</DialogTitle>
      <form noValidate onSubmit={handleSubmit}>
        <DialogContent>
          <TextField id="Name" label={t('Name')} fullWidth margin="normal" value={name.input.value} onChange={name.input.onChange} {...getErrorAndHelperText(name)} />
          <TextField id="Name" label={t('Subject')} fullWidth margin="normal" value={message_subject.input.value} onChange={message_subject.input.onChange} {...getErrorAndHelperText(message_subject)} />
          <Box pt={3}>
            <SelectMessageFrom
              title="Sender email"
              field={from}
              inputProps={{
                value: from.input.value,
                onChange: from.input.onChange
              }}
            />
          </Box>

          <MessageField
            errorMessage={getErrorAndHelperText(message)}
            inputProps={{
              value: message.input.value,
              onChange: message.input.onChange,
              form,
              ...getErrorAndHelperText(message)
            }}
          />
        </DialogContent>
        <DialogActions>
          <ResetForm form={form} open={open} />
          <Button
            color="primary"
            onClick={() => {
              onCancel();
            }}
          >
            {cancelText}
          </Button>
          <Button color="primary" type="submit">
            {confirmText}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );

  return !customTheme ? dialog : <ThemeProvider theme={customTheme}>{dialog}</ThemeProvider>;
};
