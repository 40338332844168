import React, { useCallback, useEffect, useState } from 'react';
import moment, { Moment } from 'moment';
import 'moment/locale/es';
import { Grid, TextField } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import { DatabaseConnector } from '@phinxlab/libby-rest-web';
import { useTranslation } from '../../../../../services/translation';
import { makeFilter } from '../../../utils/makeFilter';
import { LibbyObject, AnyObject } from '../../../../../types/types';
import { DateClose } from '../../../../../components/DateClose';
import { FilterBar } from '../../../../../components/FilterBar';
import { SelectMarketPlace } from '../../../../components/SelectMarketPlace';
import { SelectOrderSource } from '../../../../Orders/routes/OrdersList/components/SelectOrderSource';
import { SelectOrderShipment } from '../../../../components/SelectOrderShipment';
import { SelectOrderCourier } from '../../../../components/SelectOrderCourier';
import { SelectPrintOrderStatus } from './SelectPrintOrderStatus';
import useDebounce from '../../../../../hooks/useDebounce';
import { gridBreakpoints, gridBreakpointsFields } from '../../../../../const/gridBreakpoints';
import { SelectOrderState } from '../../../../components/SelectOrderState';
import { useOrderState } from '../../../../Orders/routes/OrdersList/hook/useOrderState';
import { replaceNonNumericCharacters } from '../../../../../functions';
import { ORDER_STATE } from '../../../../../const';

const initialStartDate = null;
const initialEndDate = null;

type SearchFiltersProps = {
  onFilterChange: (makeFilter: object) => void;
  initFetch: AnyObject;
  libby: LibbyObject;
};

const initData: number[] = [
  Number(ORDER_STATE.READY_FOR_DELIVERY),
  Number(ORDER_STATE.OUT_FOR_DELIVERY),
  Number(ORDER_STATE.DELIVERED),
  Number(ORDER_STATE.READY_TO_RESEND),
  Number(ORDER_STATE.RETURNED),
  Number(ORDER_STATE.RETURNING_TO_CANCEL),
  Number(ORDER_STATE.WAITING_FOR_RETURN)
];

const SearchFiltersRaw = ({ onFilterChange, initFetch, libby }: SearchFiltersProps) => {
  const { t } = useTranslation();

  const [symbolsArr] = useState(['e', 'E', '+', '-', '.', ',', '`+']);

  const { data: dataStatusPrint } = useOrderState(libby, initData);

  const [search, setSearch] = useState<string>(initFetch?.['0']?.[0]?.value || '');
  const [orderId, setOrderId] = useState<string>(initFetch?.order_id?.length > 0 ? initFetch.order_id[0].value : '');
  const [startDate, setStartDate] = useState<Moment | null>(initFetch?.startDate?.length > 0 ? moment(initFetch.startDate[0].value) : initialStartDate);
  const [marketplace, setMarketplace] = useState<string>(initFetch?.marketplace?.[0].value || '0');
  const [orderMarketPlaceSource, setOrderMarketPlaceSource] = useState<string>(initFetch?.orderMarketPlaceSource?.[0].value || '0');
  const [source, setSource] = useState<string>(initFetch?.source?.[0].value || '0');
  const [shipment, setShipment] = useState<string>(initFetch?.shipment?.[0].value || '0');
  const [endDate, setEndDate] = useState<Moment | null>(initFetch?.endDate?.length > 0 ? moment(initFetch.endDate[0].value) : initialEndDate);

  const [orderStatus, setOrderStatus] = useState<string>(initFetch?.orderStatus?.length > 0 ? initFetch.orderStatus[0].value : '0');

  const [orderPrintState, setOrderPrintState] = useState<string>(initFetch?.orderPrintState?.[0].value || '0');

  const searchDebounced = useDebounce(search, 500);
  const orderIdDebounced = useDebounce(orderId, 500);

  useEffect(() => {
    if (onFilterChange) {
      onFilterChange(
        makeFilter({
          orderId: orderIdDebounced,
          search: searchDebounced,
          orderStatus,
          orderPrintState,
          startDate,
          endDate,
          marketplace,
          orderMarketPlaceSource,
          source,
          shipment,
          dataStatusPrint
        })
      );
    }
  }, [orderIdDebounced, orderStatus, orderPrintState, onFilterChange, searchDebounced, startDate, endDate, marketplace, orderMarketPlaceSource, source, shipment, dataStatusPrint]);

  const handleChangeType = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setOrderStatus(event.target.value);
  }, []);

  const handleChangeMarketPlace = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setMarketplace(event.target.value);
  }, []);

  const handleChangeOrderSource = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setOrderMarketPlaceSource(event.target.value);
  }, []);

  const handleChangeSource = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setSource(event.target.value);
    setShipment('0');
  }, []);

  const handleChangeShipment = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setShipment(event.target.value);
    setOrderMarketPlaceSource('0');
  }, []);

  const handleChangeOrderPrintStatus = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setOrderPrintState(event.target.value);
  }, []);

  const handleReset = useCallback(() => {
    setOrderPrintState('0');
    setSearch('');
    setOrderId('');
    setOrderMarketPlaceSource('0');
    setShipment('0');
    setStartDate(initialStartDate);
    setMarketplace('0');
    setSource('0');
    setOrderStatus('0');
    setEndDate(initialEndDate);
    onFilterChange(makeFilter({}));
  }, [onFilterChange]);

  return (
    <FilterBar
      spacing={6}
      defaultFilter={
        <Grid item {...gridBreakpoints}>
          <TextField
            id="standard-search"
            placeholder={t('Search')}
            type="search"
            value={search}
            onChange={(event) => setSearch(event.target.value)}
            fullWidth
            InputLabelProps={{
              shrink: true
            }}
            InputProps={{
              endAdornment: <SearchIcon />
            }}
          />
        </Grid>
      }
      handleReset={handleReset}
    >
      <Grid item {...gridBreakpointsFields}>
        <TextField
          id="generic-search"
          label={t('ID Order')}
          value={orderId}
          type="text"
          onChange={(event) => setOrderId(replaceNonNumericCharacters(event.target.value))}
          fullWidth
          onKeyDown={(e) => symbolsArr.includes(e.key) && e.preventDefault()}
          InputLabelProps={{
            shrink: true
          }}
        />
      </Grid>
      <Grid item {...gridBreakpointsFields}>
        <SelectMarketPlace inputProps={{ value: marketplace, onChange: handleChangeMarketPlace }} optionAll sort="asc" />
      </Grid>
      <Grid item {...gridBreakpointsFields}>
        <SelectOrderSource
          typeOrderCourierSearch={marketplace.toString()}
          inputProps={{
            value: orderMarketPlaceSource,
            onChange: handleChangeOrderSource
          }}
          optionAll
          sort="asc"
        />
      </Grid>
      <Grid item {...gridBreakpointsFields}>
        <SelectOrderCourier
          inputProps={{
            value: source,
            onChange: handleChangeSource
          }}
          optionAll
          sort="asc"
        />
      </Grid>
      <Grid item {...gridBreakpointsFields}>
        <SelectOrderShipment typeCourierSearch={source.toString()} inputProps={{ value: shipment, onChange: handleChangeShipment }} optionAll sort="asc" />
      </Grid>
      <Grid item {...gridBreakpointsFields}>
        <SelectOrderState title="State" listStateInit={initData} inputProps={{ value: orderStatus, onChange: handleChangeType }} optionAll sort="asc" />
      </Grid>

      <Grid item {...gridBreakpointsFields}>
        <SelectPrintOrderStatus
          title="Printing status"
          inputProps={{
            value: orderPrintState,
            onChange: handleChangeOrderPrintStatus
          }}
        />
      </Grid>
      <Grid item {...gridBreakpointsFields}>
        <DateClose name="Start Date" value={startDate} onChange={setStartDate} />
      </Grid>
      <Grid item {...gridBreakpointsFields}>
        <DateClose name="End Date" value={endDate} minValue={startDate} onChange={setEndDate} />
      </Grid>
    </FilterBar>
  );
};

export const SearchFiltersMemo = React.memo(SearchFiltersRaw);
export const SearchFilters = DatabaseConnector(SearchFiltersMemo)('order_state');
