import React, { useContext } from 'react';
import { AccountProviderContext } from '../../../../context/AccountProvider';
import { GeneralSelect } from '../../../../components/GeneralSelect';
import { AnyObject } from '../../../../types/types';

type SelectRoleProps = {
  inputProps?: AnyObject;
  optionAll?: boolean;
  accountInfo?: AnyObject | null;
  disabled?: boolean;
};

export const SelectRole = ({ inputProps = {}, optionAll = false, accountInfo = null, disabled = false }: SelectRoleProps) => {
  let accountProvider: AnyObject = useContext(AccountProviderContext);
  if (accountInfo) {
    accountProvider = accountInfo;
  }

  const data = accountProvider.accountTypes.map(({ account_role_id, role }: { account_role_id: number; role: string }) => ({
    id: account_role_id,
    value: role
  }));

  return <GeneralSelect working={accountProvider.working} label="Role" optionAll={optionAll} data={data} inputProps={inputProps} disabled={disabled} />;
};
