import React from 'react';
import { Box, Grid, Switch } from '@material-ui/core';
import { useTranslation } from '../../../../../services/translation';
import { ScreenAligned } from '../../../../../components/ScreenAligned/ScreenAligned';
import { useReportingLogisticList } from './hook/useReportingLogisticList';
import { ButtonComponent } from '../../../../../components/Button';
import InfoTable from '../../../../components/InfoTable';
import LoadingData from '../../../../components/LoadingData';
import { columnsReportingDeliveryControl as columns } from './utils';
import { columnsReportingDeliveryControlGroup } from './utils/columnsGroup';
import { SectionTitle } from '../../../../../components/SectionTitle/index.ts';
import { SearchFilters } from './components/SearchFilters';
import KpiComponent from '../../../../components/Kpi';

export const ReportingLogisticList = () => {
  const { t } = useTranslation();

  const { stateRefresh, handleChange, title, buttons, rows, working, refreshFilter, setRefreshFilter, fetchMore, direction, handleRequestSort, rowsGroup, workingReportingDeliveryControlGroup, allKpi, quantityAllData } =
    useReportingLogisticList();

  return (
    <ScreenAligned
      title={title}
      additionalTitle={
        <>
          <Grid container>
            <Box display="flex" justifyContent="center" alignItems="center">
              <Switch checked={stateRefresh} onChange={handleChange} name="checkedA" color="primary" inputProps={{ 'aria-label': 'secondary checkbox' }} />
            </Box>
            {buttons.map((dataButton) => (
              <ButtonComponent
                key={dataButton.id}
                title={dataButton.title}
                className={dataButton.className}
                variant={dataButton.variant}
                color={dataButton.color}
                onClick={dataButton.onClick}
                type={dataButton.type}
                disabled={dataButton.disabled}
                loading={dataButton.loading}
              />
            ))}
          </Grid>
        </>
      }
    >
      <SearchFilters onFilterChange={setRefreshFilter} filter={refreshFilter} />
      <KpiComponent allKpi={allKpi} justify="flex-start" />
      <Grid container direction="row" justify="center" alignItems="center">
        <InfoTable columns={columns} rows={rows} onBottomScroll={fetchMore} onSortChange={handleRequestSort} direction={direction} rowIdKey="goal_id" md={12} sm={12} xs={12} />
        <LoadingData label={`${t('Loaded delivery control')}`} working={working} cant={rows.length} cantTotal={quantityAllData.quantity} />
      </Grid>

      {rowsGroup.map(({ title: titleGroup, data }) => (
        <SectionTitle title={titleGroup}>
          <InfoTable columns={columnsReportingDeliveryControlGroup} rows={data} onBottomScroll={() => {}} onSortChange={handleRequestSort} direction={direction} rowIdKey="goal_id" md={12} sm={12} xs={12} />
          <LoadingData label={`${t(`Loaded ${titleGroup}`)}`} working={workingReportingDeliveryControlGroup} cant={data.length} />
        </SectionTitle>
      ))}
    </ScreenAligned>
  );
};
