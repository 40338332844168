import { Column } from '../../../components/InfoTable';

export const columnProductSales: Column[] = [
  { id: 'ranking', label: 'Ranking', noSort: true },
  { id: 'name', label: 'Name', noSort: true },
  { id: 'amount', label: 'Amount', noSort: true, align: 'right' },
  { id: 'quantity', label: 'Quantity', noSort: true, align: 'right' }
];

export const columnCustomersSales: Column[] = [
  { id: 'name', label: 'Name', noSort: true },
  { id: 'amount_month', label: 'Amount Month', noSort: true, align: 'right' },
  { id: 'hostoric_amount', label: 'Historic Amount', noSort: true, align: 'right' }
];

export const columnCategoryStock: Column[] = [
  { id: 'name', label: 'Name', noSort: true },
  { id: 'value', label: 'Value', noSort: true, align: 'right' }
];
