import React, { useMemo } from 'react';
import { DatabaseConnector } from '@phinxlab/libby-rest-web';
import { AnyObject, LibbyObject } from '../../../../../types/types';
import { GeneralSelect } from '../../../../../components/GeneralSelect';
import { useLibbyFetch } from '../../../../../hooks';
import { makeFilter } from '../utils/makeFilter';

type SelectImportTypeProps = {
  typeOrderCourierSearch: string;
  inputProps: AnyObject;
  optionAll?: boolean;
  libby: LibbyObject;
  [k: string]: any;
  sort?: boolean | 'asc' | 'desc';
};

export const SelectOrderSourceRaw = ({ typeOrderCourierSearch, libby, sort, inputProps = {}, optionAll = false, ...styles }: SelectImportTypeProps) => {
  const filter = useMemo(() => makeFilter({ typeOrderCourierSearch }), [typeOrderCourierSearch]);
  const paramsFetch = useMemo(
    () => ({
      daoName: 'ster_order_source',
      filter
    }),
    [filter]
  );

  const { data: dataRaw, working }: { data: AnyObject; working: boolean } = useLibbyFetch(libby, paramsFetch);

  const disabled = useMemo(() => typeOrderCourierSearch === '0', [typeOrderCourierSearch]);

  const data = useMemo(
    () =>
      dataRaw.map(({ order_source_id, name }: AnyObject) => ({
        id: order_source_id,
        value: name
      })),
    [dataRaw]
  );

  return <GeneralSelect sort={sort} disabled={disabled} data={data} working={working} label="Source" optionAll={optionAll} inputProps={inputProps} {...styles} />;
};

export const SelectOrderSource = React.memo(DatabaseConnector(SelectOrderSourceRaw)('ster_order_source'));
