import { Grid } from '@material-ui/core';
import React from 'react';
import { ButtonComponent } from 'components/Button';
import { ScreenAligned } from 'components/ScreenAligned/ScreenAligned';
import { useTranslation } from 'services/translation';
import { ButtonDetailsType } from 'types/Button';
import InfoTable from 'routes/components/InfoTable';
import LoadingData from 'routes/components/LoadingData';
import { SearchFilters } from './components/SearchFilters';
import { useFraudAdministratorLogic } from './hook/useFraudAdministratorLogic';
import { columnsMarketplaceFraud as columns } from './utils/columnsMarketplaceFraud';
import { LibbyObject } from 'types';
import { TitleBreadcrumbs } from 'interfaces';
import { useBreadcrumbs } from 'hooks';
import { useLocation } from 'react-router';

export const FraudMarketplaceList = ({ libby, title }: { libby: LibbyObject; title: TitleBreadcrumbs[] }) => {
  const { t } = useTranslation();
  const { state } = useLocation<{ goBack: TitleBreadcrumbs[] }>();
  const titleShow = useBreadcrumbs(t('Fraud Rules Administrator'), state && state?.goBack?.length ? state.goBack : title);

  const { rows, working, handleRequestSort, direction, fetchMore, buttons, filter, setFilter } = useFraudAdministratorLogic({ libby });
  return (
    <ScreenAligned
      title={titleShow}
      additionalTitle={
        <>
          {buttons.map((dataButton: ButtonDetailsType) => (
            <ButtonComponent
              key={dataButton.id}
              title={dataButton.title}
              className={dataButton.className}
              variant={dataButton.variant}
              color={dataButton.color}
              onClick={dataButton.onClick}
              type={dataButton.type}
              disabled={dataButton.disabled}
              loading={dataButton.loading}
            />
          ))}
        </>
      }
    >
      <Grid container direction="row" justify="center" alignItems="center">
        <SearchFilters filter={filter} onFilterChange={setFilter} />
        <InfoTable columns={columns} rows={rows} onBottomScroll={fetchMore} onSortChange={handleRequestSort} direction={direction} rowIdKey="goal_id" md={12} sm={12} xs={12} />
        {(working || !!rows.length) && <LoadingData label={`${t('Loaded marketplaces')}`} working={working} cant={rows.length} />}
      </Grid>
    </ScreenAligned>
  );
};
