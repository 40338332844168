import { makeBusinessEntity } from '../../lib/libby/generator/makeBusinessEntity';
import { MarketplaceChargeback } from '../../interfaces/business';

const DAO_NAME = 'ster_marketplace_chargeback';

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch }
} = makeBusinessEntity<MarketplaceChargeback>(DAO_NAME);

// hooks
export const useMarketplaceChargebackFetchById = fetchById;
export const useMarketplaceChargebackLibbyCall = libbyCall;
export const useMarketplaceChargebackDAO = libbyDAO;
export const useMarketplaceChargebackLibbyFetch = libbyFetch;
