import { Moment } from 'moment';
import { AnyObject } from '../../../types';
import { endDateFormatUtc, startDateFormatUtc } from '../../../utils';

type MakeFilterProps = {
  search?: string;
  marketplace?: string;
  source?: string;
  shipment?: string;
  typeCourierSearch?: string;
  typeOrderCourierSearch?: string;
  typeOrderCourierMarketplaceSearch?: string;
  orderStatus?: string;
  startDate?: Moment | null;
  endDate?: Moment | null;
  ownerId?: string;
  orderId?: string;
  sku?: string;
};

export const makeFilter = ({ search, ownerId, marketplace, source, shipment, orderStatus, startDate, endDate, typeCourierSearch, typeOrderCourierSearch, typeOrderCourierMarketplaceSearch, orderId, sku }: MakeFilterProps) => {
  let filter: AnyObject = {};

  if (search) {
    filter = search.split(' ').reduce((accumulator: AnyObject, item, index) => {
      accumulator[index] = [
        {
          path: 'order_id',
          value: item,
          method: 'includes'
        },
        {
          path: 'buyer.first_name',
          value: search,
          method: 'includes'
        },
        {
          path: 'so_number',
          value: item,
          method: 'includes'
        },
        {
          path: 'buyer.first_name',
          value: item,
          method: 'includes'
        },
        {
          path: 'buyer.last_name',
          value: item,
          method: 'includes'
        },
        {
          path: 'buyer.phone_number',
          value: item,
          method: 'includes'
        },
        {
          path: 'buyer.document',
          value: item,
          method: 'includes'
        },
        {
          path: 'shipment.service.courier.name',
          value: item,
          method: 'includes'
        },
        {
          path: 'shipment.service.name',
          value: item,
          method: 'includes'
        },
        {
          path: 'state.name',
          value: item,
          method: 'includes'
        },
        {
          path: 'source.marketplace.name',
          value: item,
          method: 'includes'
        },
        {
          path: 'owner_id',
          value: item,
          method: 'includes'
        }
      ];

      const numberSearch = parseInt(item, 10);
      if (numberSearch) {
        accumulator[index].concat([
          {
            path: 'amount',
            value: numberSearch
          },
          {
            path: 'order_id',
            value: numberSearch
          }
        ]);
      }
      return accumulator;
    }, {});
  }

  if (orderStatus && orderStatus !== '0') {
    filter.orderStatus = [
      {
        path: 'state.order_state_id',
        value: orderStatus
      }
    ];
  }

  if (orderId) {
    filter.order_id = [
      {
        path: 'order_id',
        value: orderId,
        method: 'includes'
      }
    ];
  }

  if (sku) {
    filter.sku = [
      {
        path: 'items.sku',
        value: sku,
        method: 'includes'
      }
    ];
  }

  if (ownerId) {
    filter.owner_id = [
      {
        path: 'owner_id',
        value: ownerId,
        method: 'includes'
      }
    ];
  }

  if (source && source !== '0') {
    filter.source = [
      {
        path: 'shipment.service.courier.courier_id',
        value: source
      }
    ];
  }

  if (typeCourierSearch && typeCourierSearch !== '0') {
    filter.typeCourierSearch = [
      {
        path: 'courier.courier_id',
        value: typeCourierSearch
      }
    ];
  }

  if (typeOrderCourierSearch && typeOrderCourierSearch !== '0') {
    filter.typeOrderCourierSearch = [
      {
        path: 'marketplace.marketplace_id',
        value: typeOrderCourierSearch
      }
    ];
  }

  if (typeOrderCourierMarketplaceSearch && typeOrderCourierMarketplaceSearch !== '0') {
    filter.typeOrderCourierMarketplaceSearch = [
      {
        path: 'marketplace.marketplace_id',
        value: typeOrderCourierMarketplaceSearch
      }
    ];
  }

  if (shipment && shipment !== '0') {
    filter.shipment = [
      {
        path: 'shipment.service.courier_service_id',
        value: shipment
      }
    ];
  }

  if (marketplace && marketplace !== '0') {
    filter.marketplace = [
      {
        path: 'source.marketplace.marketplace_id',
        value: marketplace
      }
    ];
  }

  if (startDate) {
    filter.startDate = [
      {
        path: 'created_at',
        value: startDateFormatUtc(startDate),
        method: 'higherOrEquals'
      }
    ];
  }
  if (endDate) {
    filter.endDate = [
      {
        path: 'created_at',
        value: endDateFormatUtc(endDate),
        method: 'lowerOrEquals'
      }
    ];
  }
  return filter;
};
