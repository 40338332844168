import React, { useCallback, useEffect, useState } from 'react';
import { useField, useForm, FormConfig } from 'react-final-form-hooks';
import { ValidationErrors } from 'final-form';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';
import { ThemeProvider, makeStyles } from '@material-ui/styles';
import { SelectDispatch } from './SelectDispatch';
import { SelectDispatchCart } from './SelectCart';
import { SelectAccount } from './SelectAccount';
import { Collect } from '../types/Collect';
import { useTranslation } from '../../../services/translation';
import { makeGetErrorAndHelperText } from '../../../utils/materialHelpers';

const useStyles = makeStyles(() => ({
  content: {
    '& > div:nth-child(n+1)': {
      marginTop: '15px'
    }
  },
  title: {
    overflow: 'visible'
  }
}));

interface ZipFormDialogType extends FormConfig<any> {
  open: boolean;
  onCancel: any;
  onConfirm: Function;
  title: string;
  cancelText: string;
  confirmText: string;
  customTheme: string | null;
  validate: (values: any) => ValidationErrors | Promise<ValidationErrors> | undefined;
  formInitialValues: object;
  rolAdminLogistics: boolean;
  validateDispatch: boolean;
  data: Collect;
}

export const CollectionCreateForm = ({
  open = false,
  onCancel = () => {},
  onConfirm = () => {},
  title = 'Confirmation Dialog',
  cancelText = 'Cancel',
  confirmText = 'confirmText',
  customTheme = null,
  validate,
  formInitialValues = {},
  rolAdminLogistics = false,
  validateDispatch,
  data
}: ZipFormDialogType) => {
  const { t } = useTranslation();
  const styles = useStyles();
  const getErrorAndHelperText = makeGetErrorAndHelperText(t);

  const onSubmit = useCallback(
    async ({ dispatch_id, cart_id, account_id }) => {
      onConfirm({
        dispatch_id,
        cart_id,
        account_id
      });
    },
    [onConfirm]
  );
  const [cartBoxAll, setcartBoxAll] = useState([]);

  const validateCartBoxForm = useCallback(
    (values: any) => {
      if (values.cart_id) {
        const validateCartBoxData: any = cartBoxAll.find((cart: any) => cart?.cart_id === values.cart_id);
        if (validateCartBoxData?.items?.length < values.items?.length && validateCartBoxData.capacity > -1) {
          return {
            cart_id: 'You cannot select the number of orders greater than the number of available items in the cart.'
          };
        }
      }
      return {};
    },
    [cartBoxAll]
  );

  const validateGlobal = useCallback(
    (values) => {
      if (validate) {
        const valueValidation = validate(values);
        if (valueValidation && Object.keys(valueValidation).length) {
          return valueValidation;
        }
      }
      return validateCartBoxForm(values);
    },
    [validate, validateCartBoxForm]
  );

  const { form, handleSubmit } = useForm({
    initialValues: formInitialValues,
    onSubmit,
    validate: (values) => validateGlobal(values)
  });

  const cart_id = useField('cart_id', form);
  const dispatch_id = useField('dispatch_id', form);
  const account_id = useField('account_id', form);

  useEffect(() => {
    setTimeout(() => {
      form.reset(formInitialValues);
    }, 0);
  }, [form, formInitialValues]);

  const validateCartBox = useCallback((cartBoxAllData: any) => {
    setcartBoxAll(cartBoxAllData);
  }, []);

  const dialog = (
    <Dialog open={open} onClose={onCancel} fullWidth aria-labelledby="form-dialog-title">
      <DialogContent className={styles.title}>
        <Typography variant="h3">{title}</Typography>
      </DialogContent>
      <form noValidate onSubmit={handleSubmit}>
        <DialogContent className={styles.content}>
          {!validateDispatch && (
            <SelectDispatch
              inputProps={{
                onChange: dispatch_id.input.onChange,
                value: dispatch_id.input.value || '',
                ...getErrorAndHelperText(dispatch_id)
              }}
              disabled={!!data?.dispatch?.dispatch_id}
              valueInit={data?.dispatch}
            />
          )}
          <SelectDispatchCart
            inputProps={{
              onChange: cart_id.input.onChange,
              value: cart_id.input.value || '',
              ...getErrorAndHelperText(cart_id)
            }}
            disabled={!!data?.cart?.cart_id}
            validateCartBox={validateCartBox}
            valueInit={data?.cart}
            capacitySearch={data?.items ? data?.items.length : 0}
          />
          {rolAdminLogistics && (
            <SelectAccount
              label="Assigned to"
              inputProps={{
                onChange: account_id.input.onChange,
                value: account_id.input.value || '',
                ...getErrorAndHelperText(account_id)
              }}
              valueInit={data?.account}
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={onCancel}>{cancelText}</Button>
          <Button color="primary" type="submit">
            {confirmText}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );

  return !customTheme ? dialog : <ThemeProvider theme={customTheme}>{dialog}</ThemeProvider>;
};
