import { useMemo } from 'react';
import { Column } from '../../../components/InfoTable';
import { useTranslation } from '../../../../services/translation';
import { Box, Tooltip } from '@material-ui/core';
import { format } from 'util';

export default function StockDaysReportListConst() {
  const { t } = useTranslation();

  const columns = useMemo<Array<Column>>(
    () => [
      {
        id: 'sku',
        label: t('Product SKU'),
        style: {
          whiteSpace: 'nowrap',
          width: '150px'
        },
        noSort: true
      },
      {
        id: 'name',
        label: t('Name'),
        style: {
          whiteSpace: 'nowrap',
          width: '150px'
        },
        noSort: true,
        render: ({ name }: any) => {
          return (
            <Tooltip title={name}>
              <Box>{name.slice(0, 20)}</Box>
            </Tooltip>
          );
        }
      },
      {
        id: 'current_stock',
        label: t('Current Stock')
      },
      {
        id: 'units_sold',
        label: t('Units Sold')
      },
      {
        id: 'daily_sales',
        label: t('Daily Sales')
      },
      {
        id: 'stock_days',
        label: t('Days with stock')
      },
      {
        id: 'days_of_stock',
        label: t('Days of stock')
      },
      {
        id: 'valued_stock',
        label: t('Valued Stock'),
        noSort: true
      },
      {
        id: 'total_sold',
        label: t('Total sold'),
        render: ({ total_sold }: any) => {
          return format(total_sold, 'ARS', t);
        }
      }
    ],
    // eslint-disable-next-line
    [t]
  );

  return {
    columns
  };
}
