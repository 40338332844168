import React, { useState, useRef } from 'react';
import { makeStyles } from '@material-ui/styles';
import Box from '@material-ui/core/Box';
import { Typography } from '@material-ui/core';
import { useTranslation } from 'services/translation';
import PublishIcon from '@material-ui/icons/Publish';
import ImagePreview from './ImagePreview';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles(() => ({
  dropzone: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    /*     backgroundColor: '#ffffff', */
    width: '100%',
    borderRadius: '10px',
    border: '1px dashed',
    borderWidth: '3px',
    borderColor: '#5b9bfb',
    minHeight: '300px',
    transition: 'background-color 0.3s ease',
    '&:hover': {
      backgroundColor: '#dbe9ff'
    },
    cursor: 'pointer'
  },
  mergedTextBox: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: '5px'
  },
  publishIcon: {
    marginRight: '2px'
  },
  selectText: {
    fontSize: '20px',
    color: '#5b9bfb'
  },
  dropzoneDescription: {
    fontSize: '16px',
    textAlign: 'center',
    color: 'gray',
    width: '50%',
    marginBottom: '5px'
  },
  commonText: {
    fontSize: '20px'
  }
}));

const DragUpload = () => {
  const [files, setFiles] = useState<FileList | null>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const classes = useStyles();
  const { t } = useTranslation();

  const handleClearImages = () => {
    setFiles(null);
  };

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
  };

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setFiles(event.dataTransfer.files);
  };

  const handleUpload = () => {
    const formData = new FormData();
    if (files) {
      Array.from(files).forEach((file) => formData.append('Files', file));
      console.log(formData);
      // fetch(
      //   "link", {
      //     method: "POST",
      //     body: formData
      //   }
      // )
    }
  };
  if (files)
    return (
      <Box className="uploads">
        <ImagePreview fileList={files} handleClearImages={handleClearImages} />
        <div className="actions">
          <Button variant="contained" color="primary" onClick={handleUpload}>
            {t('Save')}
          </Button>
        </div>
      </Box>
    );

  return (
    <>
      <Box onClick={() => inputRef.current?.click()} className={classes.dropzone} onDragOver={handleDragOver} onDrop={handleDrop}>
        <Box className={classes.mergedTextBox}>
          <PublishIcon className={classes.publishIcon} />
          <Typography className={classes.selectText}>{t('Select')}</Typography>
          <Typography className={classes.commonText} style={{ marginLeft: '2px' }}>
            {t('or drag the files here')}
          </Typography>
        </Box>
        <Typography className={classes.dropzoneDescription}>{t('Upload your image in JPG, JPEG, PNG, or WEBP format, with a resolution of up to 500 pixels on each side and a maximum size of 10 MB')}</Typography>

        <input type="file" multiple onChange={(event) => setFiles(event.target.files)} hidden accept="image/png, image/jpeg" ref={inputRef} />
      </Box>
    </>
  );
};

export default DragUpload;
