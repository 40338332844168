import { makeBusinessEntity } from '../../../lib/libby/generator/makeBusinessEntity';
import { OperationOrder } from '../../../interfaces/business';

const DAO_NAME = 'operationorder';

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch }
} = makeBusinessEntity<OperationOrder>(DAO_NAME);

// hooks
export const useOperationOrderFetchById = fetchById;
export const useOperationOrderLibbyCall = libbyCall;
export const useOperationOrderDAO = libbyDAO;
export const useOperationOrderLibbyFetch = libbyFetch;
