import React from 'react';
import { ScreenAligned } from '../../../../components/ScreenAligned/ScreenAligned';
import { useTranslation } from '../../../../services/translation';
import { Filter } from '../../../../types';
import { OnSortChange, RowsType } from '../../../../utils/tables';
import InfoTable, { Column } from '../../../components/InfoTable';
import LoadingData from '../../../components/LoadingData';
import { SearchFilters } from './components/SearchFilters';

interface MessagesEmailSendProps {
  rows: RowsType[];
  columns: Column[];
  fetchMore: () => void;
  working: boolean;
  orderBy: string;
  filter: Filter;
  setFilter: (filter: object) => void;
  resetFilter: () => void;
  direction: 'asc' | 'desc';
  handleRequestSort: OnSortChange;
  title?: string;
}

export const MessagesEmailSend = ({ rows, columns, fetchMore, working, orderBy, filter, setFilter, resetFilter, direction, handleRequestSort, title = 'Messages' }: MessagesEmailSendProps) => {
  const { t } = useTranslation();
  return (
    <ScreenAligned title={title}>
      <SearchFilters onFilterChange={setFilter} initFetch={filter} resetFilter={resetFilter} />
      <InfoTable columns={columns} rows={rows} rowIdKey="message_id" onBottomScroll={fetchMore} orderBy={orderBy} direction={direction} onSortChange={handleRequestSort} />

      <LoadingData label={`${t('Loaded registers')}`} working={working} cant={rows.length} />
    </ScreenAligned>
  );
};
