import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { AccountErrorMessages } from './AccountErrorMessages';
import { ActionButtons } from './ActionButtons';
import { InputsForm } from './InputsForm';
import LoadingData from '../../../../components/LoadingData';
import { AnyObject, LibbyObject } from '../../../../../types/types';
import { Account } from '../../../../../interfaces/business';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    maxWidth: 400,
    marginTop: theme.spacing(1)
  }
}));

type AccountCreateFormProps = {
  libby: LibbyObject;
  account: Account | null;
  setAccountCreated: (data: Account | null) => void;
  handleSubmit: Function;
  onSearchChange: () => void;
  username: AnyObject;
  name: AnyObject;
  last_name: AnyObject;
  email: AnyObject;
  password: AnyObject;
  account_role?: AnyObject;
  submitError: string;
  duplicateAccount: boolean;
  isSearching: boolean;
  onCancelClick: (data: object) => void;
  disabledSave: boolean;
  duplicateAccountEmailData?: boolean;
  loadingAccountEmailData: boolean;
};

export const AccountCreateForm = ({
  libby,
  account,
  duplicateAccountEmailData = false,
  setAccountCreated,
  handleSubmit,
  onSearchChange,
  username,
  name,
  last_name,
  password,
  email,
  account_role,
  submitError,
  duplicateAccount,
  isSearching,
  onCancelClick,
  disabledSave,
  loadingAccountEmailData
}: AccountCreateFormProps) => {
  const classes = useStyles();
  return (
    <form
      noValidate
      className={classes.form}
      onSubmit={async (event) => {
        const accountSaved = await handleSubmit(event);
        if (accountSaved) {
          // we use setTimeout because we must set the account after libby has saved the user
          setTimeout(() => {
            setAccountCreated(accountSaved);
          }, 0);
        }
      }}
    >
      <InputsForm
        account={account}
        account_role={account_role}
        password={password}
        username={username}
        name={name}
        last_name={last_name}
        email={email}
        duplicateAccount={duplicateAccount}
        duplicateAccountEmailData={duplicateAccountEmailData}
        loadingAccountEmailData={loadingAccountEmailData}
      />
      <AccountErrorMessages account={account} duplicateAccount={duplicateAccount} submitError={submitError} />
      <ActionButtons account={account} duplicate={duplicateAccount} working={libby.working || isSearching} onSearchChange={onSearchChange} onCancelClick={onCancelClick} disabledSave={disabledSave} />
      <LoadingData working={libby.working || isSearching} />
    </form>
  );
};
