import React, { useContext, useEffect } from 'react';
import { Box, Button, Grid, Input, Paper, Slider, TextField, Typography } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import _ from 'lodash';
import { useStyles } from '../const';
import { useTranslation } from '../../../../../services/translation';
import { BudgetFormContext } from '../hook/BudgetFormContext';
import { PaginatedTable } from '../../../../../components/PaginatedTable';
import { RowsType } from '../../../types';
import { AnyObject } from '../../../../../types';

export const PaymentMethod = ({ handleChangePaymentMethod, reloadPrices, isEdit }: { handleChangePaymentMethod: any; reloadPrices: any; isEdit: boolean }) => {
  const { payment_method, paymentMethodsGroup, loadingSearch, custom_term } = useContext(BudgetFormContext);
  const { t } = useTranslation();
  const classes = useStyles();

  const [valueMethods, setValueMethods] = React.useState<Array<AnyObject>>([]);

  useEffect(() => {
    if (payment_method?.input?.value?.length > valueMethods.length) setValueMethods((prev) => _.unionBy(prev, payment_method?.input?.value, 'id'));
    if (payment_method?.input?.value?.length < valueMethods.length) setValueMethods((prev) => _.intersectionBy(prev, payment_method?.input?.value, 'id'));
  }, [payment_method, valueMethods]);

  const handleSliderChange = (newValue: number | number[], id: any) => {
    setValueMethods((prevData) => {
      const newData = prevData.map((item) => (item.id === id ? { ...item, percentil: newValue } : item));
      return newData;
    });
  };

  const handleInputChange = (event: any, id: any) => {
    event.stopPropagation();
    const { value } = event.target;
    setValueMethods((prevData) => {
      const newData = prevData.map((item) => (item.id === id ? { ...item, percentil: value === '' ? 0 : Number(value) } : item));
      return newData;
    });
  };

  const handleInputCustomChange = (event: any, id: any) => {
    event.stopPropagation();
    const { value } = event.target;
    setValueMethods((prevData) => {
      const newData = prevData.map((item) => (item.id === id ? { ...item, custom: value === '' ? 0 : Number(value) } : item));
      return newData;
    });
  };

  const handleBlur = (id: any) => {
    if (valueMethods?.find((item) => item.id === id)?.percentil < 0) {
      handleSliderChange(0, id);
    } else if (valueMethods?.find((item) => item.id === id)?.percentil > 100) {
      handleSliderChange(100, id);
    }
  };

  const validation = () => {
    return payment_method?.input?.value?.length === 0 || valueMethods?.reduce((acc, act) => acc + act.percentil, 0) !== 100 || valueMethods?.some((item) => item.percentil < 1);
  };

  const columnsPaymentMethods = [
    {
      id: 'id',
      label: 'Payment Method',
      render: (row: any) => {
        return (
          <Typography>
            {row.name} {row.plazo}
          </Typography>
        );
      }
    },
    {
      id: 'percentage',
      label: 'Percentage',
      render: (row: any) => {
        return (
          <Box style={{ width: 300 }}>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs>
                <Slider value={isEdit ? 100 : valueMethods?.find((item) => item.id === row.id)?.percentil || 0} onChange={(e, v) => handleSliderChange(v, row.id)} aria-labelledby="input-slider" disabled={isEdit} />
              </Grid>
              <Grid item style={{ maxWidth: 70 }}>
                <Input
                  disabled={isEdit}
                  value={isEdit ? 100 : valueMethods?.find((item) => item.id === row.id)?.percentil}
                  onChange={(e) => handleInputChange(e, row.id)}
                  onBlur={handleBlur}
                  inputProps={{
                    step: 10,
                    min: 0,
                    max: 100,
                    type: 'number',
                    'aria-labelledby': 'input-slider'
                  }}
                />
              </Grid>
            </Grid>
          </Box>
        );
      }
    },
    {
      id: 'personalize',
      label: 'Personalize Value',
      render: (row: any) => {
        return (
          <Box style={{ width: 250 }}>
            {row.plazo === 'Personalizado' && (
              <Grid container spacing={2} alignItems="center">
                <Grid item>
                  <Input
                    required
                    value={isEdit ? custom_term.input.value : valueMethods?.find((item) => item.id === row.id)?.custom || 0}
                    onChange={isEdit ? custom_term.input.onChange : (e) => handleInputCustomChange(e, row.id)}
                    inputProps={{
                      step: 10,
                      min: 0,
                      type: 'number',
                      'aria-labelledby': 'input-slider'
                    }}
                  />
                </Grid>
              </Grid>
            )}
          </Box>
        );
      }
    }
  ];

  return (
    <Grid item xs={12}>
      <Paper className={classes.paper}>
        <Grid item container>
          <Grid xs={12}>
            <Typography variant="h1" className={classes.title}>{`${t('Payment Method')}`}</Typography>
          </Grid>
          <Grid item xs={12} style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
            <Grid xs={12} style={{ maxWidth: 450, marginRight: '15px', alignSelf: 'flex-start' }}>
              <Autocomplete
                disabled={paymentMethodsGroup.length === 0}
                loading={loadingSearch}
                multiple={!isEdit}
                onChange={(event, value) => handleChangePaymentMethod({ target: value })}
                options={paymentMethodsGroup}
                groupBy={(option) => option.name}
                getOptionLabel={(option) => option.plazo}
                style={{ width: 450, margin: '8px' }}
                renderInput={(params) => <TextField {...params} label={`${t('Method')}`} variant="standard" />}
                filterSelectedOptions
                disableClearable={isEdit}
              />
            </Grid>
            <PaginatedTable headCells={columnsPaymentMethods} rows={(payment_method.input.value as RowsType[]) || []} pagination={false} />
            {!isEdit && (
              <Grid>
                <Button color="primary" type="button" style={{ marginRight: 23 }} onClick={() => reloadPrices(valueMethods)} disabled={validation()}>
                  {t('Reload Prices')}
                </Button>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
};
