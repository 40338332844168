import { useCallback, useEffect, useMemo } from 'react';
import { useRouteMatch, useHistory } from 'react-router-dom';
import { DatabaseConnector } from '@phinxlab/libby-rest-web';
import { useBreadcrumbs, useFilerParamsId } from '../../hooks';
import { OrdersList } from '../Orders/routes/OrdersList';
import { LibbyObject } from '../../types/types';
import { useOrderTableLogic } from '../Orders/routes/OrdersList/hook/useOrderTableLogic';
import { makeFilter } from '../Orders/routes/OrdersList/utils/makeFilter';
import { COURIER_SERVICE } from '../../const/CourierService';
import { MENU_ACTIONS, ORDER_STATE } from '../../const';
import { ReceiptsColumns } from '../../models/orders/ReceiptsColumns';
import { useTranslation } from 'services/translation';
import { useStateOrder } from 'routes/Orders/routes/OrdersList/hook/useStateOrder';
import { Order } from '../../interfaces/business';
import { IconButton } from '@material-ui/core';
import { CloudUploadRounded } from '@material-ui/icons';
import { useMenuAction } from 'hooks/useMenuActions';

const filterInit = makeFilter({
  courierService: [COURIER_SERVICE.TALAVERA_1, COURIER_SERVICE.TALAVERA_2, COURIER_SERVICE.TALAVERA_3],
  orderStatus: `${ORDER_STATE.OUT_FOR_DELIVERY}`
});

export const ReceiptsRaw = ({ libby }: LibbyObject) => {
  const match = useRouteMatch();
  const title = useBreadcrumbs('Receipts');
  const { validateAction } = useMenuAction();
  const history = useHistory();
  const { t } = useTranslation();
  const detailsRedirection = useCallback((order_id: string) => history.push(`orders/detail/${order_id}`), [history]);
  const { filter, orderBy, direction, paramsFetch, setFilter, handleRequestSort, resetFilter } = useFilerParamsId({
    orderInit: 'order_id',
    daoName: 'ster_order_so_with_delivery_date',
    aspect: 'list_order_so_order',
    init: validateAction(MENU_ACTIONS.SLCT_COURIER_FILTER_TALAVERA_DRIVER) ? filterInit : {}
  });
  const { rows, working, fetchMore } = useOrderTableLogic({
    libby,
    paramsFetch,
    detailsRedirection,
    columnCheckEnabled: true,
    actions: false,
    path: match.path,
    deliveryDate: true
  });
  const { onChangeUpdateStateAllWithRefer } = useStateOrder({
    libby
  });
  const columns = useMemo(() => ReceiptsColumns, []);

  useEffect(() => {
    columns[0].render = (item: any) => (
      <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
        <IconButton
          color="primary"
          onClick={() =>
            onChangeUpdateStateAllWithRefer({
              title: t('Do you want to upload receipts?'),
              data: [item as Order],
              replaceDocument: true
            })
          }
        >
          <CloudUploadRounded />
        </IconButton>
      </div>
    );
  }, [columns, onChangeUpdateStateAllWithRefer, t]);

  return (
    <OrdersList
      title={title}
      detailUrl={`${match.path}/detail`}
      setFilter={setFilter}
      rows={rows}
      columns={ReceiptsColumns}
      working={working}
      filter={filter}
      fetchMore={fetchMore}
      onSortChange={handleRequestSort}
      orderBy={orderBy}
      direction={direction}
      resetFilter={resetFilter}
    />
  );
};

export const Receipts = DatabaseConnector(ReceiptsRaw)('ster_order', 'ster_order_table', 'ster_order_document_generate', 'ster_order_so_with_delivery_date');
