import React, { useMemo } from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Grid, Link, Paper, Typography } from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import { useTranslation } from '../../../../../../services/translation';
import { AnyObject, RowsType } from '../../../../../../types';
import { KpiComponent } from '../KpiComponent';
import InfoTable from '../../../../../components/InfoTable';
import { SalesTypes } from '../../types';
import { columnCustomersSales, columnProductSales } from '../../ReportingDashboardListConst';

interface SalesPaperInterface {
  values: SalesTypes;
  classes: AnyObject;
  rowsProductsSales: Array<RowsType>;
  rowsCustomersSales: Array<RowsType>;
}

export const SalesPaper = ({ values, classes, rowsProductsSales, rowsCustomersSales }: SalesPaperInterface) => {
  const { t } = useTranslation();

  const { url: urlAvg, ...kpiAvg } = values?.avg_sales;
  const { url: urlObj, ...kpiObj } = values?.objective;

  const { avg_sales, total_sales, day_sales } = useMemo(() => {
    const avg_total_filter = Object.fromEntries(Object.entries(kpiAvg).filter(([key]) => key.includes('total')));
    const avg_sales_filter = Object.fromEntries(Object.entries(kpiAvg).filter(([key]) => key.includes('avg')));
    const day_sales_filter = Object.fromEntries(Object.entries(kpiAvg).filter(([key]) => key.includes('day')));
    return {
      avg_sales: avg_sales_filter,
      total_sales: avg_total_filter,
      day_sales: day_sales_filter
    };
  }, [kpiAvg]);

  return (
    <Grid item xs={12} sm={12} md={10} lg={12}>
      <Paper className={classes.paper}>
        <Grid container xs={12} justify="space-between" alignItems="center" style={{ marginBottom: 15 }}>
          <Typography variant="h4" className={classes.title}>
            {t('Sales')}
          </Typography>
        </Grid>

        <Grid container xs={12} justify="space-between" alignItems="flex-start" spacing={3} style={{ marginBottom: 15 }}>
          {total_sales && Object.keys(total_sales).length > 0 && (
            <Grid item xs={12} lg={Object.values(day_sales)?.length > 0 ? 4 : 6}>
              <Accordion>
                <AccordionSummary expandIcon={<ExpandMore />} aria-controls="panel4bh-content" id="panel1">
                  <Grid container xs={12}>
                    <Grid container xs={12} justify="space-between" alignItems="center">
                      <Typography variant="h5">{t('Total sales of the month')}</Typography>
                      {urlAvg && (
                        <Button color="primary">
                          <Link target="_blank" href={urlAvg}>
                            {t('see more')}
                          </Link>
                        </Button>
                      )}
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <KpiComponent name="Total" value={Object.values(total_sales).reduce((acc, act) => acc + act)} />
                    </Grid>
                  </Grid>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container xs={12} sm={12} md={12} lg={12} spacing={1} justify="space-between" alignItems="center">
                    {total_sales && Object.keys(total_sales).length > 0 && (
                      <Grid container xs={12}>
                        {Object.entries(total_sales).map(([key, value]: [string, string | number]) => (
                          <Grid item xs={12} sm={12} md={12} lg={12}>
                            <KpiComponent name={key.replace('total', '').trim()} value={value} />
                          </Grid>
                        ))}
                      </Grid>
                    )}
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </Grid>
          )}
          <Grid item xs={12} lg={Object.values(day_sales)?.length > 0 ? 4 : 6}>
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMore />} aria-controls="panel4bh-content" id="panel2">
                <Grid container xs={12}>
                  <Grid container xs={12} justify="space-between" alignItems="center">
                    <Typography variant="h5">{t('Average daily sales for the month')}</Typography>
                    {urlAvg && (
                      <Button color="primary">
                        <Link target="_blank" href={urlAvg}>
                          {t('see more')}
                        </Link>
                      </Button>
                    )}
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <KpiComponent name="Total" value={Object.values(avg_sales).reduce((acc, act) => acc + act)} />
                  </Grid>
                </Grid>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container xs={12} sm={12} md={12} lg={12} spacing={1} justify="space-between" alignItems="center">
                  {avg_sales && Object.keys(avg_sales).length > 0 && (
                    <Grid container xs={12}>
                      {Object.entries(avg_sales).map(([key, value]: [string, string | number]) => (
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                          <KpiComponent name={key.replace('avg', '').trim()} value={value} />
                        </Grid>
                      ))}
                    </Grid>
                  )}
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Grid>
          {Object.values(day_sales)?.length > 0 && (
            <Grid item xs={12} lg={day_sales ? 4 : 6}>
              <Accordion>
                <AccordionSummary expandIcon={<ExpandMore />} aria-controls="panel4bh-content" id="panel5">
                  <Grid container xs={12}>
                    <Grid container xs={12} justify="space-between" alignItems="center">
                      <Typography variant="h5">{t('Sales of the day')}</Typography>
                      {urlAvg && (
                        <Button color="primary">
                          <Link target="_blank" href={urlAvg}>
                            {t('see more')}
                          </Link>
                        </Button>
                      )}
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <KpiComponent name="Total" value={Object.values(day_sales).reduce((acc, act) => acc + act)} />
                    </Grid>
                  </Grid>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container xs={12} sm={12} md={12} lg={12} spacing={1} justify="space-between" alignItems="center">
                    {day_sales && Object.keys(day_sales).length > 0 && (
                      <Grid container xs={12}>
                        {Object.entries(day_sales).map(([key, value]: [string, string | number]) => (
                          <Grid item xs={12} sm={12} md={12} lg={12}>
                            <KpiComponent name={key.replace('day', '').trim()} value={value} />
                          </Grid>
                        ))}
                      </Grid>
                    )}
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </Grid>
          )}

          <Grid container xs={12} sm={12} md={12} lg={12} justify="space-between" alignItems="flex-start" style={{ marginTop: 15 }}>
            {kpiObj && (
              <Grid container xs={12}>
                <Grid container xs={12} md={12} justify="space-between" alignItems="center" style={{ marginLeft: '16px' }}>
                  <Typography variant="h5">{t('Offline Sales by Objectives')}</Typography>
                  {urlObj && (
                    <Button color="primary">
                      <Link target="_blank" href={urlObj}>
                        {t('see more')}
                      </Link>
                    </Button>
                  )}
                </Grid>
                {Object.entries(kpiObj).map(([key, value]: [string, string | number]) => (
                  <Grid item xs={12} sm={12} md={12} lg={4}>
                    <KpiComponent name={key} value={value} />
                  </Grid>
                ))}
              </Grid>
            )}
          </Grid>
        </Grid>

        <Grid container xs={12} sm={12} md={12} lg={12} spacing={3} justify="space-between" alignItems="flex-start">
          {rowsProductsSales?.length > 0 && (
            <Grid item xs={12} sm={12} md={12} lg={rowsCustomersSales?.length > 0 ? 6 : 12}>
              <Box mb={2}>
                <Grid container xs={12} justify="space-between" alignItems="center">
                  <Typography variant="h5" style={{ padding: '10px 8px' }}>
                    {t('Top 20 Products')}
                  </Typography>
                  {values.products.url && (
                    <Button color="primary">
                      <Link target="_blank" href={values.products.url}>
                        {t('see more')}
                      </Link>
                    </Button>
                  )}
                </Grid>
              </Box>
              <InfoTable padding={false} height="auto" columns={columnProductSales} rows={rowsProductsSales} />
            </Grid>
          )}
          {rowsCustomersSales?.length > 0 && (
            <Grid item xs={12} sm={12} md={12} lg={rowsProductsSales?.length > 0 ? 6 : 12}>
              <Box mb={2}>
                <Grid container xs={12} justify="space-between" alignItems="center">
                  <Typography variant="h5" style={{ padding: '10px 8px' }}>
                    {t('Top 20 Customers')}
                  </Typography>
                </Grid>
              </Box>
              <InfoTable padding={false} height="auto" columns={columnCustomersSales} rows={rowsCustomersSales} />
            </Grid>
          )}
        </Grid>
      </Paper>
    </Grid>
  );
};
