import { LibbyFetchDAO } from './LibbyFetchDAO';

export class StateDAO extends LibbyFetchDAO {
  constructor() {
    super('ster_state', 'state_id');
  }

  getAll() {
    return (
      this.query()
        // .orderBy('name', 'asc')
        .limit(0, 10000)
        .run()
    );
  }

  getStateByID(id: string) {
    return this.query().equals('marketplace_id', id).run();
  }
}
