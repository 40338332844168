import { useCallback, useState } from 'react';
import { useSnackbar } from 'notistack';
import { useFilerParamsId } from '../../../hooks';
import confirmDialog from '../../../services/confirmDialog';
import { useTranslation } from '../../../services/translation';
import { MessageConfigType, useMessageConfigType } from '../types/types';
import { FormDialog } from '../components/DialogEditMessageConfig';
import CustomModal from '../../../services/customFormDialog';
import { formInitialValues, validate } from '../model/model';
import { useMessageConfigLibbyFetch } from '../../../business';
import { makeFilter } from '../utils';

const EditDialogModal = CustomModal(FormDialog);

const filterInit = makeFilter({ active: true });

export const useMessageConfig = ({ libby }: useMessageConfigType) => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const [isValidNewMessage, setIsValidNewMessage] = useState(false);

  const { orderBy, direction, paramsFetch, handleRequestSort } = useFilerParamsId({
    orderInit: 'message_config_id',
    daoName: 'ster_message_config',
    aspect: 'message_config_list',
    init: filterInit
  });
  const { data: messageConfig, working, addCreate, removeData, updateData, fetchMore } = useMessageConfigLibbyFetch(paramsFetch);

  const onConfirmCreate = useCallback(async () => {
    const confirm: any = await EditDialogModal.show({
      title: t('Create Message Configuration'),
      confirmText: `${t('Save')}`,
      cancelText: t('Cancel'),
      formInitialValues,
      validate,
      isValidNewMessage
    });
    if (confirm) {
      const { orderState, marketplace, template, documentType, sendTo, sendToClient } = confirm;
      try {
        setIsValidNewMessage(!isValidNewMessage);

        const newMessageConfigSaved = await libby.ster_message_config_update.aspect('message_config_list').save({
          state: orderState,
          marketplace,
          template,
          order_document_type: documentType !== '0' ? documentType : null,
          send_to: sendTo,
          send_to_client: sendToClient
        });

        addCreate(newMessageConfigSaved);
        enqueueSnackbar(`${t('Successfully created message configuration')} ${newMessageConfigSaved.message_config_id}`, { variant: 'success' });
      } catch (e) {
        enqueueSnackbar(`${t('Error creating message configuration')}`, {
          variant: 'error'
        });
      }
    }
  }, [addCreate, enqueueSnackbar, libby, t, isValidNewMessage, setIsValidNewMessage]);

  const deleteMessageConfig = useCallback(
    async (message_config_id: string) => {
      const confirm = await confirmDialog.show({
        title: t('Delete Message Configuration'),
        content: t('Are you sure of perform this action?'),
        confirmText: `${t('Delete')}`,
        cancelText: t('Cancel')
      });
      if (confirm) {
        try {
          await libby.ster_message_config_update.aspect('message_config_list').save({ message_config_id, active: false });
          removeData(message_config_id, 'message_config_id');
          enqueueSnackbar(t('Message configuration deleted'), { variant: 'success' });
        } catch (e) {
          enqueueSnackbar(`${t('Error deleting message configuration')}`, {
            variant: 'error'
          });
        }
      }
    },
    [enqueueSnackbar, removeData, t, libby]
  );

  const openMessageConfigEdit = useCallback(
    async ({ state: { order_state_id }, marketplace: { marketplace_id }, template: { message_template_id }, order_document_type, message_config_id, send_to, send_to_client }: MessageConfigType) => {
      const formInitialValuesEdit = {
        orderState: order_state_id,
        marketplace: marketplace_id,
        template: message_template_id,
        documentType: order_document_type?.order_document_type_id || '0',
        send_to,
        send_to_client
      };
      try {
        const confirmEdit: any = await EditDialogModal.show({
          title: 'Edit Message Configuration',
          confirmText: 'Save',
          formInitialValues: formInitialValuesEdit
        });
        if (confirmEdit) {
          const { orderState, marketplace, template, documentType, sendTo, sendToClient } = confirmEdit;
          const newMessageConfig: MessageConfigType = await libby.ster_message_config_update.aspect('message_config_list').save({
            message_config_id,
            state: { order_state_id: orderState },
            marketplace: {
              marketplace_id: marketplace
            },
            template: {
              message_template_id: template
            },
            order_document_type:
              documentType !== '0'
                ? {
                    order_document_type_id: documentType
                  }
                : null,
            send_to: sendTo,
            send_to_client: sendToClient
          });
          updateData(newMessageConfig, 'message_config_id');
          enqueueSnackbar(`${t('Message configuration $$$$ saved').replace('$$$$', message_config_id)}`, { variant: 'success' });
        }
      } catch (e: any) {
        if (e)
          enqueueSnackbar(`${t('Error deleting message configuration')}: ${e.message}`, {
            variant: 'error'
          });
      }
    },
    [libby, enqueueSnackbar, t, updateData]
  );

  return {
    onConfirmCreate,
    messageConfig: messageConfig as MessageConfigType[],
    working,
    deleteMessageConfig,
    openMessageConfigEdit,
    handleRequestSort,
    orderBy,
    direction,
    fetchMore
  };
};
