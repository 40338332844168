import moment from 'moment';
import { formatDate } from '.';

export const convertMinsToHrsMins = (frequency: number) => {
  let hours: number | string = Math.trunc(frequency / 60);
  let minutes: number | string = frequency % 60;

  hours = hours < 10 ? `0${hours}` : hours;
  minutes = minutes < 10 ? `0${minutes}` : minutes;
  return `${hours}:${minutes}`;
};

export const convertFormatToHours24 = (hour: number, am: boolean) => {
  return !am ? (hour === 12 ? hour : hour + 12) : hour === 12 ? 0 : hour;
};

export const convertFormatToHours = (hour: number | null) => {
  if (hour === null) return undefined;
  return hour > 12 ? hour - 12 : hour === 0 ? 12 : hour;
};

export const convertFormatToHoursTimeSystem = (hour: number | null, typeNumber: boolean = false) => {
  if (hour === null) return undefined;
  return hour < 12 ? (!typeNumber ? 'AM' : 0) : !typeNumber ? 'PM' : 1;
};

export const getHourLocal = (hour: string | null, format: string = 'HH:mm A') => {
  if (hour === null) return null;

  return formatDate(new Date(`${moment().format('YYYY-MM-DD')}T${hour}.000Z`), true, false, format);
};

export const minutesToString = (minute: number) => {
  const hour = Math.floor(minute / 60);
  const hourNew = hour < 10 ? `0${hour}` : hour.toString();
  const m = minute % 60;
  const minuteNew = m < 10 ? `0${m}` : m.toString();

  return `${hourNew}:${minuteNew}:00`;
};
