import { Moment } from 'moment';
import { Filter } from '../../../../../../types';
import { formatDate } from '../../../../../../utils';

type MakeFilterProps = {
  search?: string;
  dayDate?: Moment | null;
};

export const makeFilter = ({ search, dayDate }: MakeFilterProps) => {
  const filter: Filter = {};
  if (search) {
    const searchArray = search.split(' ').reduce((accumulator: Filter, item, index) => {
      accumulator[index] = [
        {
          path: 'description',
          value: search,
          method: 'includes'
        }
      ];

      return accumulator;
    }, {});
    filter.search = [...searchArray[0]];
  }

  if (dayDate) {
    filter.dayDate = [
      {
        path: 'day_date',
        value: formatDate(dayDate.toDate(), true, false, 'YYYY-MM-DD')
      }
    ];
  }

  return filter;
};
