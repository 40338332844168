import { Moment } from 'moment';
import { AnyObject } from '../../../../../types/types';
import { endDateFormatUtc, startDateFormatUtc } from '../../../../../utils';

type MakeFilterProps = {
  importId?: string;
  importStatus?: string;
  importType?: string;
  startDate?: Moment | null;
  endDate?: Moment | null;
};

export const makeFilter = ({ importId, importType, importStatus, startDate, endDate }: MakeFilterProps) => {
  const filter: AnyObject = {};

  if (importId) {
    filter.importId = [
      {
        path: 'importation_id',
        value: importId
      }
    ];
  }

  if (importType && importType !== '0') {
    filter.importType = [
      {
        path: 'importation_type.importation_type_id',
        value: importType
      }
    ];
  }

  if (importStatus && importStatus !== '0') {
    filter.importStatus = [
      {
        path: 'importation_status.importation_status_id',
        value: importStatus
      }
    ];
  }

  if (startDate) {
    filter.startDate = [
      {
        path: 'creation_date',
        value: startDateFormatUtc(startDate),
        method: 'higherOrEquals'
      }
    ];
  }
  if (endDate) {
    filter.endDate = [
      {
        path: 'creation_date',
        value: endDateFormatUtc(endDate),
        method: 'lowerOrEquals'
      }
    ];
  }
  return filter;
};
