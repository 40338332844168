import React, { ReactNode } from 'react';
import { StyledAppBar as AppBar } from '../StyledAppBar';
import { Toolbar } from '../Toolbar';

type NavbarProps = {
  left?: ReactNode;
  title?: ReactNode;
  right?: ReactNode;
  className?: string;
};

export const Navbar = ({ left = null, title = null, right = null, className = '' }: NavbarProps) => (
  <AppBar className={className} position="sticky">
    <Toolbar left={left} title={title} right={right} />
  </AppBar>
);
