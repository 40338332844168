import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import { ThemeProvider, makeStyles } from '@material-ui/styles';
import { Box, Button, CircularProgress, DialogActions, DialogTitle } from '@material-ui/core';
import { LibbyObject, RowsType } from '../../../../../../types';
import InfoTable from '../../../../../components/InfoTable';
import { reportingLogisticJustifyLogsColumns as columns } from './ReportingLogisticJustifyLogsColumns';
import { useTranslation } from '../../../../../../services/translation';

const useStyles = makeStyles({
  root: {
    '& .MuiDialog-paperWidthSm': {
      width: '100%',
      maxWidth: '600px'
    },
    '& .MuiDialogContent-root': {
      padding: '0px 24px 8px 24px'
    }
  },
  textField: {
    marginBottom: '20px'
  },
  title: {
    overflow: 'visible',
    '& > h3': {
      fontFamily: '"Oswald"'
    }
  },
  rootEdit: {
    display: 'flex',
    justifyContent: 'space-around',
    width: '100%'
  },
  rootCancelEdit: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%'
  }
});

interface ShowDetailsTypes {
  open: boolean;
  onCancel: () => void;
  title: string;
  customTheme: string | null;
  orderId: string;
  cancelText: string;
  libby: LibbyObject;
}

interface JustifiedLogsType {
  justification: string;
  justified_orders_id: string;
  justified_orders_logs_id: string;
  operation: string;
  order_id: string;
  updated_at: Date;
  updated_by: string;
}

const dummyFunc = () => {};

export const ResportingLogisticJustifyModalLogs = ({ open = false, onCancel = dummyFunc, title, customTheme = null, orderId, cancelText, libby }: ShowDetailsTypes) => {
  const classes = useStyles();
  const [working, setWorking] = useState(false);
  const [dataJustificationLog, setDataJustificationLog] = useState<JustifiedLogsType[] | undefined>();

  const getJustificationLogs = useCallback(async (libbyProp, dataProp) => {
    if (!libbyProp) {
      return;
    }
    if (dataProp) {
      setWorking(true);
      try {
        const res = await libbyProp.ster_reporting_logistic_justify_logs.query().equals('order_id', parseInt(dataProp, 10)).run();
        res.sort((a: JustifiedLogsType, b: JustifiedLogsType) => {
          if (b.updated_at < a.updated_at) return -1;
          if (b.updated_at > a.updated_at) return 1;
          return '';
        });
        setDataJustificationLog(res);
        setWorking(false);
      } catch (e: any) {
        setWorking(false);
      }
    }
  }, []);

  const { t } = useTranslation();

  useEffect(() => {
    getJustificationLogs(libby, orderId);
  }, [libby, getJustificationLogs, orderId]);

  const handleOperation = (op: string) => {
    let res = '';
    if (op === 'Insert' || op === 'Update') {
      res = 'Justified';
    } else {
      res = 'Unjustified';
    }
    return res;
  };

  const rows: RowsType[] = useMemo(() => {
    return dataJustificationLog?.length
      ? dataJustificationLog.map((dataLog: JustifiedLogsType) => ({
          id: dataLog.justified_orders_logs_id,
          operation: t(handleOperation(dataLog.operation)),
          justification: dataLog.operation === 'Delete' ? '' : dataLog.justification,
          updated_by: dataLog.updated_by,
          updated_at: dataLog.updated_at
        }))
      : [];
  }, [dataJustificationLog, t]);

  const dialog = (
    <Dialog className={classes.root} open={open} onClose={onCancel} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">{title}</DialogTitle>
      <Box style={{ padding: '20px' }}>
        {working ? (
          <Box display="flex" justifyContent="center" alignItems="center" height="300px">
            <CircularProgress size={25} />
          </Box>
        ) : (
          <InfoTable direction="desc" columns={columns} orderBy="updated_at" rows={rows} md={12} sm={12} xs={12} />
        )}
      </Box>
      <DialogActions>
        <Button
          onClick={() => {
            onCancel();
          }}
        >
          {cancelText}
        </Button>
        <Button
          onClick={() => {
            getJustificationLogs(libby, orderId);
          }}
          color="primary"
          type="submit"
        >
          {t('Refresh')}
        </Button>
      </DialogActions>
    </Dialog>
  );

  return !customTheme ? dialog : <ThemeProvider theme={customTheme}>{dialog}</ThemeProvider>;
};
