export const columns = [
  {
    id: 'transportation_company_id',
    label: 'Carrier id',
    orderById: 'transportation_company_id'
  },
  {
    id: 'name',
    label: 'Name'
  },
  {
    id: 'city',
    label: 'City',
    noSort: true
  },
  {
    id: 'address',
    label: 'Address',
    noSort: true
  },
  {
    id: 'phone_number',
    label: 'Phone Number',
    noSort: true
  },
  {
    id: 'edit',
    label: 'Edit',
    maxWidth: 200,
    noSort: true
  }
];
