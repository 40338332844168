import { Column } from 'routes/components/InfoTable';
import { MarketplaceChargeback } from 'interfaces/business';

export const columnsMarketplaceFraud: Array<Column> = [
  {
    id: 'actions',
    label: '',
    noSort: true,
    width: 30
  },
  {
    id: 'marketplace_fraud_id',
    label: 'Marketplace Fraud ID',
    style: {
      whiteSpace: 'nowrap'
    },
    orderById: 'marketplace_fraud_id'
  },
  {
    id: 'marketplace',
    label: 'Marketplace',
    style: {
      whiteSpace: 'nowrap'
    },
    orderById: 'marketplace.name'
  },
  {
    id: 'configuration',
    label: 'Configuration',
    style: {
      whiteSpace: 'nowrap'
    }
  },
  {
    id: 'active',
    label: 'Active',
    render: (marketplace: MarketplaceChargeback) => <div>{marketplace.active ? 'Sí' : 'No'}</div>,
    noSort: true
  }
];
