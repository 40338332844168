import React from 'react';
import { Box, capitalize } from '@material-ui/core';
import { formatDate } from '../../../utils';

export const columns = [
  {
    id: 'options',
    label: '',
    width: '40px',
    style: {
      minWidth: '30px'
    },
    noSort: true
  },
  {
    id: 'name',
    label: 'Name',
    style: {
      width: '20%',
      whiteSpace: 'nowrap'
    },
    orderById: 'name'
  },
  {
    id: 'frequency',
    label: 'Frequency',
    style: {
      width: '5%',
      whiteSpace: 'nowrap'
    },
    orderById: 'frequency'
  },
  {
    id: 'metadata',
    label: 'Metadata',
    style: {
      width: '30%',
      whiteSpace: 'nowrap'
    },
    noSort: true,
    render: ({ metadata }: { metadata: object }) => <Box>{JSON.stringify(metadata)}</Box>
  },
  {
    id: 'last_execution',
    label: 'Last execution',
    style: {
      width: '20%',
      whiteSpace: 'nowrap'
    },
    render: ({ last_execution }: { last_execution: Date }) => <Box>{formatDate(last_execution ?? '', true, false)}</Box>,
    orderById: 'last_execution'
  },
  {
    id: 'active',
    label: 'State',
    style: {
      width: '10%'
    },
    render: ({ active }: { active: boolean }, item: any, t: (str: string) => string) => <Box whiteSpace="nowrap">{t(capitalize(String(active ? t('Enabled') : t('Disabled')) ?? ''))}</Box>,
    orderById: 'active'
  },
  {
    id: 'lastState',
    label: 'last states',
    style: {
      width: '10%',
      whiteSpace: 'nowrap'
    },
    orderById: 'lastState',
    render: ({ lastState }: { lastState: string }, item: any, t: (str: string) => string) => (
      <Box color={lastState === 'crashed' ? 'red' : ''} whiteSpace="nowrap">
        {t(capitalize(lastState ?? ''))}
      </Box>
    )
  },
  {
    id: 'description',
    label: 'Description',
    style: {
      width: '20%',
      whiteSpace: 'nowrap'
    }
  },
  {
    id: 'comment',
    label: 'Comments',
    style: {
      width: '20%',
      whiteSpace: 'nowrap'
    }
  }
];
