import React from 'react';
import { useParams } from 'react-router-dom';
import { ScreenAligned } from '../../../../components/ScreenAligned/ScreenAligned';
import { useSterConfigLogsLogic } from './hook';
import { TitleBreadcrumbs } from '../../../../interfaces';
import InfoTable from '../../../components/InfoTable';
import { columns } from './utils';
import LoadingData from '../../../components/LoadingData';
import { useTranslation } from '../../../../services/translation';
import { useBreadcrumbs } from '../../../../hooks';

interface SterConfigLogsListProps {
  title: TitleBreadcrumbs[];
}

export const SterConfigLogsList = ({ title }: SterConfigLogsListProps) => {
  const { t } = useTranslation();
  const { working, rows, fetchMoreMemo, direction, orderBy, handleRequestSort } = useSterConfigLogsLogic();
  const params = useParams<{ id: string }>();
  const titleShow = useBreadcrumbs('Process monitor log', title, `#${params?.id}`);

  return (
    <ScreenAligned title={titleShow}>
      <InfoTable columns={columns} rows={rows} rowIdKey="ster_config_log_id" onSortChange={handleRequestSort} onBottomScroll={fetchMoreMemo} orderBy={orderBy} direction={direction} />

      <LoadingData label={`${t('Process quantity')}`} working={working} cant={rows.length} />
    </ScreenAligned>
  );
};
