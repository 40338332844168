import { GoalPeriod } from '../../interfaces/business/goal/GoalPeriod';
import { makeBusinessEntity } from '../../lib/libby/generator/makeBusinessEntity';

const DAO_NAME = 'ster_goal_period';

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch }
} = makeBusinessEntity<GoalPeriod>(DAO_NAME);

// hooks
export const useGoalPeriodFetchById = fetchById;
export const useGoalPeriodLibbyCall = libbyCall;
export const useGoalPeriodDAO = libbyDAO;
export const useGoalPeriodLibbyFetch = libbyFetch;
