import { useCallback, useEffect, useMemo, useState } from 'react';
import { useField, useForm } from 'react-final-form-hooks';
import { useSnackbar } from 'notistack';
import { formInitialValues, initAccount, validate } from '../utils';
import { useTranslation } from '../../../../../services/translation';
import { LibbyObject } from '../../../../../types/types';
import { useLibbyCall } from '../../../../../hooks';
import { Account } from '../../../../../interfaces/business';

export const useAddAccountForm = ({ libby, addCreate }: { libby: LibbyObject; addCreate: (data: object) => void }) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [account, setAccount] = useState<Account | null>(null);
  const [isSearching, setIsSearching] = useState(false);
  const [duplicateAccount, setDuplicateAccount] = useState(false);

  const {
    data: [dataAccountEmail],
    working = true,
    recall
  } = useLibbyCall(libby, {
    daoName: 'admin_account',
    methodName: 'getAccountEmail',
    noAutoCall: true
  });

  const onSubmit = useCallback(
    async (values) => {
      try {
        const updatedAccount = {
          ...values,
          role: {
            account_role_id: values.account_role
          }
        };

        const newAccount = await libby.admin_account.save({
          account_id: null,
          creation_date: new Date(),
          account_enabled: true,
          ...updatedAccount
        });
        addCreate(newAccount);
        enqueueSnackbar(t('Account saved'), { variant: 'success' });
        return {
          account_id: String(newAccount.account_id)
        };
      } catch (e: any) {
        enqueueSnackbar(t('Something is wrong'), { variant: 'error' });
        return null;
      }
    },
    [libby, enqueueSnackbar, addCreate, t]
  );

  const { form, errors, handleSubmit, submitting, submitError } = useForm({
    initialValues: formInitialValues,
    onSubmit,
    validate
  });
  const username = useField('username', form);
  const name = useField('name', form);
  const last_name = useField('last_name', form);
  const password = useField('password', form);
  const account_role = useField('account_role', form);
  const email = useField('email', form);
  /* const getErrorAndHelperText = makeGetErrorAndHelperText(t);

  
   */

  const validateEmail = useMemo(() => email.input.value.length && !email?.meta?.error?.length, [email]);

  useEffect(() => {
    if (dataAccountEmail) {
      setAccount(dataAccountEmail);
    }
  }, [dataAccountEmail]);

  useEffect(() => {
    if (validateEmail) {
      recall?.([email.input.value.toLowerCase()]);
    }
  }, [validateEmail, email.input.value, recall]);

  const loadingAccountEmailData = useMemo(() => validateEmail && working, [validateEmail, working]);

  const onSearchChange = useCallback(async () => {
    const { value } = username.input;
    if (value) {
      setIsSearching(true);
      const [fetchedAccount] = await libby.admin_account.query().equals('username', value.toLowerCase()).run();
      if (fetchedAccount) {
        setDuplicateAccount(true);
        setAccount(fetchedAccount);
      } else {
        setDuplicateAccount(false);
        setAccount(initAccount);
      }
      setIsSearching(false);
    }
  }, [username, libby]);

  useEffect(() => {
    if (!account) {
      setTimeout(() => {
        form.reset(formInitialValues);
        form.resetFieldState('username');
        form.resetFieldState('password');
        form.resetFieldState('email');
        form.resetFieldState('account_role');
        form.resetFieldState('name');
        form.resetFieldState('last_name');
      }, 0);
    }
  }, [form, account]);

  const onCancelClick = useCallback(() => {
    setAccount(null);
    setDuplicateAccount(false);
  }, [setDuplicateAccount, setAccount]);

  return {
    account,
    setAccount,
    handleSubmit,
    onSearchChange,
    username,
    password,
    name,
    last_name,
    email,
    account_role,
    submitting,
    submitError,
    duplicateAccount,
    setDuplicateAccount,
    isSearching,
    errors,
    duplicateAccountEmailData: !!dataAccountEmail,
    loadingAccountEmailData,
    onCancelClick
  };
};
