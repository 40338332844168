import React, { useState } from 'react';
import SearchIcon from '@material-ui/icons/Search';
import 'moment/locale/es';
import { Grid, TextField } from '@material-ui/core';
import moment from 'moment';
import { makeFilter } from '../../../utils';
import { SelectOrderStateB2B } from '../../../../components/SelectOrderStateB2B';
import { SelectMarketPlace } from '../../../../components/SelectMarketPlace';
import { SelectBusiness } from '../../../../components/SelectBusiness';
import { DateClose } from '../../../../../components/DateClose';
import { FilterBar } from '../../../../../components/FilterBar';
import { gridBreakpoints, gridBreakpointsFields } from '../../../../../const/gridBreakpoints';
import { useTranslation } from '../../../../../services/translation';
import { useInitFilter, useInitFilterDebounced } from '../../../../OrderReport/routes/OrderReportList/hook';
import { SearchFiltersProps } from '../../../types';

const SearchFiltersRaw = ({ onFilterChange, initFetch, canalOffLine = false, resetFilter }: SearchFiltersProps) => {
  const { t } = useTranslation();

  const [symbolsArr] = useState(['e', 'E', '+', '-', '.', ',', '`+']);

  const { search, handleChange } = useInitFilterDebounced({
    initFetch: initFetch.search,
    init: '',
    onFilterChange,
    id: 'search',
    makeFilter
  });

  const { search: orderId, handleChange: handleChangeOrderId } = useInitFilterDebounced({
    initFetch: initFetch.orderId,
    init: '',
    onFilterChange,
    id: 'orderId',
    makeFilter
  });

  const { search: ownerId, handleChange: handleChangeOwnerId } = useInitFilterDebounced({
    initFetch: initFetch.ownerId,
    init: '',
    onFilterChange,
    id: 'ownerId',
    makeFilter
  });

  const { search: marketplace, handleChangeFilter: handleChangeMarketplace } = useInitFilter({
    initFetch: initFetch.marketplace,
    init: '0',
    onFilterChange,
    id: 'marketplace',
    makeFilter
  });

  const { search: orderStatus, handleChangeFilter: handleChangeOrderState } = useInitFilter({
    initFetch: initFetch.orderStatus,
    init: '0',
    onFilterChange,
    id: 'orderStatus',
    makeFilter
  });

  const { search: startDate, handleChangeFilterString: setStartDate } = useInitFilter({
    initFetch: initFetch.startDate,
    onFilterChange,
    init: null,
    id: 'startDate',
    date: true,
    makeFilter
  });

  const { search: account_id, handleChangeFilter: handleChangeAccountId } = useInitFilter({
    initFetch: initFetch.account_id,
    init: '0',
    onFilterChange,
    id: 'account_id',
    makeFilter
  });

  const { search: endDate, handleChangeFilterString: setEndDate } = useInitFilter({
    initFetch: initFetch.endDate,
    onFilterChange,
    id: 'endDate',
    date: true,
    init: null,
    makeFilter
  });

  return (
    <FilterBar
      defaultFilter={
        <Grid item {...gridBreakpoints}>
          <TextField
            id="standard-search"
            placeholder={t('Search')}
            type="search"
            value={search}
            onChange={handleChange}
            fullWidth
            InputLabelProps={{
              shrink: true
            }}
            InputProps={{
              endAdornment: <SearchIcon />
            }}
          />
        </Grid>
      }
      handleReset={resetFilter}
    >
      <Grid item {...gridBreakpointsFields}>
        <TextField
          id="generic-search"
          label={t('ID Order')}
          value={orderId}
          type="text"
          onChange={handleChangeOrderId}
          fullWidth
          onKeyDown={(e) => symbolsArr.includes(e.key) && e.preventDefault()}
          InputLabelProps={{
            shrink: true
          }}
        />
      </Grid>
      <Grid item {...gridBreakpointsFields}>
        <SelectOrderStateB2B sort="asc" title="State" inputProps={{ value: orderStatus, onChange: handleChangeOrderState }} optionAll />
      </Grid>
      <Grid item {...gridBreakpointsFields}>
        <SelectMarketPlace sort="asc" inputProps={{ value: marketplace, onChange: handleChangeMarketplace }} canalOffLine={canalOffLine} optionAll />
      </Grid>
      <Grid item {...gridBreakpointsFields}>
        <TextField
          id="owner-search"
          label={t('Owner ID')}
          type="search"
          value={ownerId}
          onChange={handleChangeOwnerId}
          fullWidth
          InputLabelProps={{
            shrink: true
          }}
        />
      </Grid>
      <Grid item {...gridBreakpointsFields}>
        <SelectBusiness sort="asc" inputProps={{ value: account_id, onChange: handleChangeAccountId }} optionAll />
      </Grid>
      <Grid item {...gridBreakpointsFields}>
        <DateClose name="Start Date" value={moment.isMoment(startDate) ? startDate : null} onChange={setStartDate} />
      </Grid>
      <Grid item {...gridBreakpointsFields}>
        <DateClose name="End Date" value={moment.isMoment(endDate) ? endDate : null} minValue={moment.isMoment(startDate) ? startDate : null} onChange={setEndDate} />
      </Grid>
    </FilterBar>
  );
};

export const SearchFilters = React.memo(SearchFiltersRaw);
