import { Moment } from 'moment';
import { Filter } from '../../../../../../types';
import { formatDate } from '../../../../../../utils';

type MakeFilterProps = {
  search?: string;
  startDate?: Moment | null;
  endDate?: Moment | null;
  effectiveness?: number;
  marketplace?: any[];
  marketplaces?: any[];
  company?: any[];
  courier?: any[];
  source?: any[];
  canal?: any[];
  state?: any[];
  turn?: any[];
  from?: Date;
  to?: Date;
};

export const makeFilter = ({ search, startDate, endDate, effectiveness, marketplace, company, courier, canal, state, turn, source, marketplaces, from, to }: MakeFilterProps) => {
  const filter: Filter = {};
  if (search) {
    const searchArray = search.split(' ').reduce((accumulator: Filter, item, index) => {
      accumulator[index] = [
        {
          path: 'name',
          value: search,
          method: 'includes'
        }
      ];

      return accumulator;
    }, {});
    filter.search = [...searchArray[0]];
  }

  if (startDate) {
    filter.startDate = [
      {
        path: 'start_date',
        value: formatDate(startDate.toDate(), true, false, 'YYYY-MM-DD HH:mm:ss')
      }
    ];
  }
  if (endDate) {
    filter.endDate = [
      {
        path: 'end_date',
        value: formatDate(endDate.toDate(), true, false, 'YYYY-MM-DD HH:mm:ss')
      }
    ];
  }
  if (from) {
    filter.from = [
      {
        path: 'created_at',
        value: formatDate(from, true, false, 'YYYY-MM-DDTHH:mm:ss.sssZ'),
        method: 'higherOrEquals'
      }
    ];
  }
  if (to) {
    filter.to = [
      {
        path: 'created_at',
        value: formatDate(to, true, false, 'YYYY-MM-DDTHH:mm:ss.sssZ'),
        method: 'lowerOrEquals'
      }
    ];
  }
  if (effectiveness) {
    filter.effectiveness = [
      {
        path: 'effectiveness',
        value: effectiveness
      }
    ];
  }

  if (marketplace?.length) {
    filter.marketplace = marketplace?.map((marketplaceValue) => ({
      path: 'marketplace_id',
      value: marketplaceValue?.value
    }));
  }

  if (company?.length) {
    filter.company = company?.map((companyValue) => ({
      path: 'company_id',
      value: companyValue?.value
    }));
  }

  if (courier?.length) {
    filter.courier = courier?.map((courierValue) => ({
      path: 'courier_id',
      value: courierValue?.value
    }));
  }

  if (canal?.length) {
    filter.canal = canal?.map((canalValue) => ({
      path: 'canal_id',
      value: canalValue?.value
    }));
  }

  if (state?.length) {
    filter.state = state?.map((stateValue) => ({
      path: 'order_state_id',
      value: stateValue?.id
    }));
  }

  if (turn?.length) {
    filter.turn = turn?.map((turnValue) => ({
      path: 'orderB2Bs.order_state_turn_id',
      value: turnValue?.id
    }));
  }

  if (source?.length) {
    filter.source = source?.map((sourceValue) => ({
      path: 'shipment.service.courier.courier_id',
      value: sourceValue?.id
    }));
  }

  if (marketplaces?.length) {
    filter.marketplaces = marketplaces?.map((marketplacesValue) => ({
      path: 'source.marketplace.marketplace_id',
      value: marketplacesValue?.id
    }));
  }

  return filter;
};
