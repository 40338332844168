import { useState } from 'react';
import { DatabaseConnector } from '@phinxlab/libby-rest-web';
import { Grid, Typography } from '@material-ui/core';
import moment from 'moment';
import { Column } from '../../../components/InfoTable';
import { useTranslation } from '../../../../services/translation';
import { LibbyObject } from '../../../../types/types';
import { FilterBarSelection } from '../../../Reporting/routes/ReportingList/FilterBar';
import { endDateFormat, startDateFormat } from '../../../../utils';
import { ScreenAligned } from '../../../../components/ScreenAligned/ScreenAligned';
import { ButtonDetailsType } from '../../../../types/Button';
import { ButtonComponent } from '../../../../components/Button';
import { FilterChargebackReport, SCREENS } from '../../../../components/FilterManager';
import { Filter } from '../../../../interfaces/business';
import ChargebackReportListLogic from './hooks/ChargebackReportListLogic';
import LoadingData from 'routes/components/LoadingData';
import ChargebackMainTable from './components/ChargebackMainTable';
import InfoTable from '../../../components/InfoTable';
import KpiComponent from 'routes/components/Kpi';

export type ChargebackReportProps = {
  vatEnabled?: boolean;
  libby: LibbyObject;
  typeReporting: 'Chargeback report' | 'Orders';
  daoNameHistory: string;
  daoName: string;
  columnsAux: Array<Column>;
  columnsProductAux: Array<Column>;
  path: string;
};

export const filterInit: FilterBarSelection = {
  marketplaces: [],
  courier: [],
  from: startDateFormat(moment().subtract('6', 'months')).toDate(),
  to: endDateFormat(moment()).toDate(),
  chargeback: '0',
  view: 'monetary'
};

export const initFilter: Filter<FilterBarSelection> = {
  name: '',
  private: false,
  preset: false,
  account: { username: '', account_id: '-1' },
  screen: { screen_id: SCREENS.REPORTING_CHARGEBACK.toString(), name: '' },
  last_update: new Date(),
  metadata: filterInit,
  campaign: false
};

const ChargebackReportListRaw = ({ libby, typeReporting = 'Chargeback report', path }: ChargebackReportProps) => {
  const { t } = useTranslation();

  const [filter, setFilter] = useState<Filter<FilterBarSelection>>(initFilter);
  const {
    buttons,
    monthsChargeback,
    workingData,
    mainTableData,
    cityColumns,
    cityRows,
    productColumns,
    productRows,
    streetColumns,
    streetRows,
    handleRequestCityTableSort,
    handleRequestStreetTableSort,
    handleRequestProductTableSort,
    cityTableDirection,
    cityTableOrderBy,
    productTableDirection,
    productTableOrderBy,
    streetTableDirection,
    streetTableOrderBy,
    allKpi
  } = ChargebackReportListLogic({
    filter,
    setFilter,
    path,
    libby
  });

  return (
    <ScreenAligned
      title={typeReporting}
      additionalTitle={
        <>
          {buttons.map((dataButton: ButtonDetailsType) => (
            <ButtonComponent
              key={dataButton.id}
              title={dataButton.title}
              className={dataButton.className}
              variant={dataButton.variant}
              color={dataButton.color}
              onClick={dataButton.onClick}
              type={dataButton.type}
              disabled={dataButton.disabled}
              loading={dataButton.loading}
            />
          ))}
        </>
      }
    >
      <Grid container direction="row" justify="center" alignItems="center">
        <FilterChargebackReport initialFilter={initFilter} filter={filter} onFilter={setFilter} initExpanded working={workingData} withSaved />
        <KpiComponent allKpi={allKpi} />
        <ChargebackMainTable months={monthsChargeback} data={mainTableData} filters={filter.metadata} />
        <LoadingData label={`${t('Loaded registers')}`} working={workingData} cant={mainTableData.length} />
      </Grid>
      <Grid container direction="row" alignItems="flex-start" spacing={2} sm={12}>
        <Grid item direction="row" md={12} sm={12} xs={12} alignItems="center" spacing={4}>
          <Typography style={{ width: '100%', display: 'flex', justifyContent: 'center', marginBottom: '15px' }} color="primary" variant="h4">
            {t('Chargeback by City')}
          </Typography>
          <InfoTable padding={false} columns={cityColumns} rows={cityRows} onSortChange={handleRequestCityTableSort} direction={cityTableDirection} orderBy={cityTableOrderBy} rowIdKey="city_id" working={workingData} />
        </Grid>
        <Grid item direction="row" md={12} sm={12} xs={12} alignItems="center" spacing={4}>
          <Typography style={{ width: '100%', display: 'flex', justifyContent: 'center', marginBottom: '15px' }} color="primary" variant="h4">
            {t('Chargeback by Address')}
          </Typography>
          <InfoTable padding={false} columns={streetColumns} rows={streetRows} onSortChange={handleRequestStreetTableSort} direction={streetTableDirection} orderBy={streetTableOrderBy} rowIdKey="address" working={workingData} />
        </Grid>
        <Grid item direction="row" md={12} sm={12} xs={12} alignItems="center" spacing={4}>
          <Typography style={{ width: '100%', display: 'flex', justifyContent: 'center', marginBottom: '15px' }} color="primary" variant="h4">
            {t('Chargeback by Product')}
          </Typography>
          <InfoTable
            padding={false}
            columns={productColumns}
            rows={productRows}
            onSortChange={handleRequestProductTableSort}
            direction={productTableDirection}
            orderBy={productTableOrderBy}
            rowIdKey="product_id"
            working={workingData}
          />
        </Grid>
      </Grid>
    </ScreenAligned>
  );
};

export const ChargebackReportList = DatabaseConnector(ChargebackReportListRaw)('ster_chargeback_report', 'ster_chargeback_report_city', 'ster_chargeback_report_street', 'ster_chargeback_report_product');
