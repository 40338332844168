import React, { useCallback, useMemo } from 'react';
import { AnyObject } from 'final-form';
import { TextField } from '@material-ui/core';
import { useStockMarketplaceLibbyFetch } from '../../../business/product';
import { useTranslation } from '../../../services/translation';
import { Autocomplete } from '@material-ui/lab';

export const SelectStockMarketplaces = ({ inputProps, label = 'Marketplaces' }: { inputProps: AnyObject; label?: string }) => {
  const { t } = useTranslation();
  const { data, working } = useStockMarketplaceLibbyFetch();

  const dataFormatted = useMemo(() => {
    return data?.length
      ? data.map(({ marketplace_id, name }) => ({
          id: marketplace_id.toString(),
          value: t(name)
        }))
      : [];
  }, [data, t]);

  const onChangeAutoComplete = useCallback(
    (event, newValue: any[]) => {
      inputProps.onChange(
        newValue.map((item: any) => ({
          value: item.id,
          path: ''
        }))
      );
    },
    [inputProps]
  );

  const autocompleteValue = useMemo(
    () =>
      inputProps.value?.length && dataFormatted.length
        ? inputProps.value.map((marketplace: { path: string; value: string; method: string }) => {
            const searchMarketplace = dataFormatted.find((marketplaceFind) => marketplaceFind.id === marketplace.value);
            return searchMarketplace;
          })
        : [],
    [inputProps.value, dataFormatted]
  );

  return (
    <Autocomplete
      disabled={working || inputProps.disabled}
      style={{ display: 'inline-block', width: '100%' }}
      multiple
      options={dataFormatted}
      getOptionLabel={(option: { id: string; value: string }) => option.value}
      filterSelectedOptions
      getOptionSelected={(option: any, _value: any) => option.id === _value.id}
      value={autocompleteValue}
      onChange={onChangeAutoComplete}
      renderInput={(params) => <TextField {...params} variant="standard" label={t(label)} placeholder="" />}
    />
  );
};
