import { LibbyFetchDAO } from '../LibbyFetchDAO';

export class NonWorkingDayUpdateDAO extends LibbyFetchDAO {
  constructor() {
    super('ster_non_working_day_update', 'non_working_day_id');
  }

  getAll() {
    return this.query().limit(0, 10000).run();
  }

  getNonWorkingDayUpdateByID(id: string) {
    return this.query().equals('non_working_day_id', id).run();
  }
}
