import React, { useContext } from 'react';
import { Grid, MenuItem, Paper, TextField, Typography } from '@material-ui/core';
import { makeGetErrorAndHelperText, textFieldProps } from '../../../../../utils/materialHelpers';
import { GeneralSelect } from '../../../../../components/GeneralSelect';
import { AutocompleteCity } from '../../../../../components/AutocompleteCity';
import { useStyles } from '../const';
import { BudgetFormContext } from '../hook/BudgetFormContext';
import { useTranslation } from '../../../../../services/translation';

export const BillingAddress = () => {
  const { t } = useTranslation();
  const {
    billingAddressesOptions,
    billingAddress,
    handleChangeAddress,
    stateOptions,
    fetchingStates,
    billingAddressState,
    billingAddressCity,
    billingAddressFloor,
    billingAddressStreetNumber,
    billingAddressDepartment,
    billingAddressZip,
    billingAddressStreet
  } = useContext(BudgetFormContext);

  const classes = useStyles();

  const getErrorAndHelperText = makeGetErrorAndHelperText(t);

  return (
    <Grid item xs={12}>
      <Paper className={classes.paper}>
        <Grid item container>
          <Grid xs={12}>
            <Typography variant="h1" className={classes.title}>{`${t('Billing Address')}`}</Typography>
          </Grid>
          <Grid item xs={3}>
            <TextField
              {...textFieldProps(`${t('Old Billing Addresses')}`)}
              select
              variant="standard"
              value={billingAddress}
              onChange={(e: any) => handleChangeAddress(e, 'Billing')}
              InputLabelProps={{ shrink: true }}
              style={{ width: 450, margin: '8px' }}
              disabled={billingAddressesOptions?.length === 0}
            >
              {billingAddressesOptions.map((item) => (
                <MenuItem key={item.id} value={item.value}>
                  {item.value}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12} className={classes.containerInputs}>
            <Grid item xs={3} style={{ margin: '8px', maxWidth: '30ch' }}>
              <GeneralSelect
                required
                label={`${t('Province')}`}
                optionAll={false}
                data={stateOptions}
                working={!!fetchingStates}
                inputProps={{
                  onChange: billingAddressState.input.onChange,
                  value: billingAddressState.input.value,
                  ...getErrorAndHelperText(billingAddressState),
                  required: true,
                  style: { textAlign: 'left' }
                }}
              />
              <AutocompleteCity
                name="city"
                inputProps={{
                  province: billingAddressState.input.value,
                  onChange: billingAddressCity.input.onChange,
                  value: billingAddressCity.input.value === '' ? null : billingAddressCity.input.value,
                  classes: classes.provinceCityInput,
                  error: { ...getErrorAndHelperText(billingAddressCity) },
                  onBlur: billingAddressCity.input.onBlur,
                  InputLabelProps: { shrink: true },
                  required: true
                }}
              />
              <TextField
                {...textFieldProps(`${t('Street')}`)}
                {...getErrorAndHelperText(billingAddressStreet)}
                required
                variant="standard"
                value={billingAddressStreet.input.value}
                onChange={billingAddressStreet.input.onChange}
                InputLabelProps={{ shrink: true }}
                className={classes.inputs}
              />
              <TextField
                {...textFieldProps(`${t('Street Number')}`)}
                {...getErrorAndHelperText(billingAddressStreetNumber)}
                required
                variant="standard"
                value={billingAddressStreetNumber.input.value}
                onChange={billingAddressStreetNumber.input.onChange}
                InputLabelProps={{ shrink: true }}
                className={classes.inputs}
                type="number"
              />
              <TextField
                {...textFieldProps(`${t('Floor')}`)}
                {...getErrorAndHelperText(billingAddressFloor)}
                required
                variant="standard"
                value={billingAddressFloor.input.value}
                onChange={billingAddressFloor.input.onChange}
                InputLabelProps={{ shrink: true }}
                className={classes.inputs}
              />
              <TextField
                {...textFieldProps(`${t('Department')}`)}
                {...getErrorAndHelperText(billingAddressDepartment)}
                required
                variant="standard"
                value={billingAddressDepartment.input.value}
                onChange={billingAddressDepartment.input.onChange}
                InputLabelProps={{ shrink: true }}
                className={classes.inputs}
              />
              <TextField
                {...textFieldProps(`${t('Zip')}`)}
                {...getErrorAndHelperText(billingAddressZip)}
                required
                variant="standard"
                value={billingAddressZip.input.value}
                onChange={billingAddressZip.input.onChange}
                InputLabelProps={{ shrink: true }}
                className={classes.inputs}
                type="number"
              />
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
};
