export const columns = [
  {
    id: 'checked',
    label: 'Entregar',
    orderById: ' ',
    style: {
      whiteSpace: 'nowrap',
      width: '70px'
    },
    noSort: true
  },
  {
    id: 'order_id',
    label: 'Order id',
    style: {
      width: '150px',
      whiteSpace: 'nowrap'
    },
    orderBy: 'order_id'
  },
  {
    id: 'owner_id',
    label: 'Owner id',
    style: {
      width: '300px',
      whiteSpace: 'nowrap'
    },
    noSort: true
  },
  {
    id: 'so_number',
    label: 'Number so',
    style: {
      minWidth: '80px',
      whiteSpace: 'nowrap'
    },
    noSort: true
  },
  {
    id: 'marketplace',
    label: 'Marketplace',
    style: {
      width: '150px'
    },
    noSort: true
  },
  {
    id: 'courier',
    label: 'Courier',
    style: {
      width: '150px'
    },
    noSort: true
  },
  {
    id: 'created_at',
    label: 'Fecha de creacion',
    style: {
      minWidth: '150px'
    },
    noSort: 'true'
  },
  {
    id: 'updated_at',
    label: 'Ultima actualizacion',
    style: {
      minWidth: '150px',
      whiteSpace: 'nowrap'
    },
    orderBy: 'updated_at'
  },
  {
    id: 'update_by_username',
    label: 'Actualizado por',
    style: {
      minWidth: '150px'
    },
    noSort: 'true'
  }
];
