import moment from 'moment';
import React, { useMemo } from 'react';
import { useNonWorkingDayLibbyCall } from '../../business/public/NonWorkingDay';
import { DateClose, DateCloseType } from '../../components/DateClose';
import { Non_working_day } from '../../interfaces/business';

interface DateCloseWitNonWorkingDayProps extends DateCloseType {
  dayEnabled: string;
}

export const DateCloseWitNonWorkingDay = ({ dayEnabled, name, value, onChange, minValue, disableFuture = true, views, ...inputProps }: DateCloseWitNonWorkingDayProps) => {
  const { data: dataAllNonWorkingDate } = useNonWorkingDayLibbyCall({
    methodName: 'getAll'
  });

  const dayDateDisabled = useMemo(() => {
    const copyDataAllNonWorkingDate = (dataAllNonWorkingDate || []) as Non_working_day[];
    const dataAllNonWorkingDateDisabled = copyDataAllNonWorkingDate.filter(({ day_date }: Non_working_day) => day_date.toString() !== dayEnabled);
    return dataAllNonWorkingDateDisabled.map(({ day_date }: Non_working_day) => day_date.toString());
  }, [dataAllNonWorkingDate, dayEnabled]);

  return (
    <DateClose name={name} shouldDisableDate={(date) => dayDateDisabled.includes(moment(date).format('YYYY-MM-DD'))} minDate={minValue} views={views} value={value} onChange={onChange} disableFuture={disableFuture} {...inputProps} />
  );
};
