import { translate } from '../../../../utils';

export const paymentMethodMap = [
  {
    key: 'card_emiter_id',
    label: 'Emitter ID',
    formatter: (value: any) => translate(value)
  },
  {
    key: 'card_id',
    label: 'Card ID',
    formatter: (value: any) => translate(value)
  },
  {
    key: 'emitter_name',
    label: 'Emitter name',
    formatter: (value: any) => translate(value)
  },
  {
    key: 'card_name',
    label: 'Card name',
    formatter: (value: any) => translate(value)
  },
  {
    key: 'installments',
    label: 'Installments',
    formatter: (value: any) => translate(value)
  },
  {
    key: 'campaigns',
    label: 'Campaign IDs',
    formatter: (value: any) => (value?.length ? value.join(', ') : translate('None'))
  }
];
