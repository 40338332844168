import React, { useState, useEffect, useCallback } from 'react';
import SearchIcon from '@material-ui/icons/Search';
import { Grid, TextField } from '@material-ui/core';
import moment, { Moment } from 'moment';
import { makeFilter } from '../../../utils/makeFilter';
import { FilterBar } from '../../../../../components/FilterBar';
import { DateClose } from '../../../../../components/DateClose';
import { gridBreakpoints } from '../../../../../const/gridBreakpoints';
import useDebounce from '../../../../../hooks/useDebounce';
import { useTranslation } from '../../../../../services/translation';
import { AnyObject } from '../../../../../types/types';

type SearchFiltersProps = {
  onFilterChange: (makeFilter: object) => void;
  filter: AnyObject;
};

const SearchFiltersRaw = ({ onFilterChange, filter }: SearchFiltersProps) => {
  const { t } = useTranslation();
  const initialDate = null;

  const [search, setSearch] = useState<string>('');

  const [startDate, setStartDate] = useState<Moment | null>(filter?.startDate?.length > 0 ? moment(filter.startDate[0].value) : initialDate);

  const [endDate, setEndDate] = useState<Moment | null>(filter?.endDate?.length > 0 ? moment(filter.endDate[0].value) : initialDate);

  const searchDebounced = useDebounce(search, 500);

  const handleReset = useCallback(() => {
    setSearch('');
    setStartDate(initialDate);
    setEndDate(initialDate);
    onFilterChange(makeFilter({}));
  }, [onFilterChange]);

  useEffect(() => {
    onFilterChange(
      makeFilter({
        search: searchDebounced,
        endDate,
        startDate
      })
    );
  }, [onFilterChange, searchDebounced, startDate, endDate]);

  return (
    <FilterBar
      defaultFilter={
        <Grid item {...gridBreakpoints}>
          <TextField
            id="standard-search"
            placeholder={t('Search')}
            type="search"
            value={search}
            onChange={(event) => setSearch(event.target.value)}
            fullWidth
            InputLabelProps={{
              shrink: true
            }}
            InputProps={{
              endAdornment: <SearchIcon />
            }}
          />
        </Grid>
      }
      handleReset={handleReset}
    >
      <Grid item {...gridBreakpoints}>
        <DateClose name="Dispatch date from" value={startDate} onChange={setStartDate} />
      </Grid>
      <Grid item {...gridBreakpoints}>
        <DateClose name="Dispatch Date to" value={endDate} onChange={setEndDate} />
      </Grid>
    </FilterBar>
  );
};

export const SearchFilters = React.memo(SearchFiltersRaw);
