import React from 'react';
import { Cell, Legend, Pie, PieChart, ResponsiveContainer, Tooltip } from 'recharts';
import { Grid } from '@material-ui/core';
import { ChartTooltip } from './ChartTooltip';
import { CHART_COLORS, ChartProps } from './types';
import { useTranslation } from '../services/translation';
import LoadingData from '../routes/components/LoadingData';
import { ChartEmpty } from '../components/ChartEmpty';
import { useStyles } from '../theme/styles/useStyleChart';

interface ChartPieProps extends ChartProps {
  onChange?: (data: any) => void;
}
/*
; */

export const ChartPie = ({
  data,
  height,
  key,
  value,
  format,
  xs,
  sm,
  md,
  lg,
  xl,
  working = false,
  layout = 'horizontal',
  verticalAlign = 'bottom',
  onChange,
  outerRadius = '80%',
  cx = '50%',
  cy = '50%',
  wrapperStyle = {}
}: ChartPieProps) => {
  const { t } = useTranslation();

  const className = useStyles();

  return (
    <Grid
      item
      xs={xs || 12}
      {...{
        sm,
        md,
        lg,
        xl
      }}
    >
      {!working ? (
        data.length ? (
          <ResponsiveContainer height={height} width="100%">
            <PieChart>
              <Pie data={data} dataKey={value} nameKey={key} fill="#8884d8" legendType="circle" outerRadius={outerRadius} cx={cx} cy={cy}>
                {/* outerRadius="95%" cx="32%" cy="50%" */}
                {data.map((dataKey, index) => (
                  <Cell
                    onClick={() => {
                      if (onChange) {
                        onChange(dataKey);
                      }
                    }}
                    className={className.cursor}
                    key={dataKey.value}
                    fill={dataKey?.color ?? CHART_COLORS[index % CHART_COLORS.length]}
                  />
                ))}
              </Pie>
              <Tooltip content={<ChartTooltip format={format} />} cursor={{ strokeDasharray: '3 3' }} />
              <Legend layout={layout} verticalAlign={verticalAlign} wrapperStyle={wrapperStyle} formatter={(label) => t(label)} />
            </PieChart>
          </ResponsiveContainer>
        ) : (
          <ChartEmpty />
        )
      ) : (
        <LoadingData working={working} />
      )}
    </Grid>
  );
};
