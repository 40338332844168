import { LibbyFetchDAO } from '../LibbyFetchDAO';

export class GoalListDateDAO extends LibbyFetchDAO {
  constructor() {
    super('ster_list_date', 'product_id');
  }

  getAllListDate({ quarter }: { quarter: boolean }) {
    return this.query().equals('quarter', quarter).limit(0, 10000).run();
  }
}
