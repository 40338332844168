import { OrderLiquidationGroup } from '../../../interfaces/business';
import { makeBusinessEntity } from '../../../lib/libby/generator/makeBusinessEntity';

const DAO_NAME = 'ster_order_liquidation_group';

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch }
} = makeBusinessEntity<OrderLiquidationGroup>(DAO_NAME);

// hooks
export const useOrderLiquidationGroupFetchById = fetchById;
export const useOrderLiquidationGroupLibbyCall = libbyCall;
export const useOrderLiquidationGroupDAO = libbyDAO;
export const useOrderLiquidationGroupLibbyFetch = libbyFetch;
