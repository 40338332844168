import { API_URL } from '../../../config';
import { CANCEL_ORDER } from '../../../const';

export enum CANCEL_TYPE {
  normal = 22, // Hacer NC y hacer retorno directo
  toReturn = 15, // Mover internamente la mercaderia al deposito indicado y hacer RNA si se indica
  manualReturn = 24 // Hacer NC y hacer retorno al deposito indicado en el canel
}

export interface BodyCancel {
  id_order: string;
  type: CANCEL_TYPE;
  create_rma?: boolean;
  location_id?: number;
}

export const cancelOrderInOdoo = async (data: BodyCancel) => {
  const response = await fetch(`${API_URL}/${CANCEL_ORDER}`, {
    method: 'POST',
    body: JSON.stringify(data),
    headers: { 'Content-Type': 'application/json' }
  });
  const body = await response.json();
  const {
    result: { status, message }
  } = body;
  // TODO: leaving this here to test
  console.log('odoo message', message);
  if (status === 400) throw new Error('order could not be cancelled in odoo');
  return body;
};
