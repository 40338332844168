import { Filter } from '../../../types/Filter';

type MakeFilterProps = {
  active?: boolean;
};

export const makeFilter = ({ active }: MakeFilterProps) => {
  const filter: Filter = {};

  if (typeof active === 'boolean') {
    filter.active = [
      {
        path: 'active',
        value: active
      }
    ];
  }

  return filter;
};
