import { LibbyFetchDAO } from '../LibbyFetchDAO';

export class GoalRangeTypeDAO extends LibbyFetchDAO {
  constructor() {
    super('ster_goal_range_type', 'goal_range_type_id');
  }

  getAllGoalType() {
    return this.query().limit(0, 10000).run();
  }
}
