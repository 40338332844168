import { CommonProdutColumns, FeaturesGroupColumns, FeaturesColumns } from './CommonProductColumns';

export const columnsProduct = [
  CommonProdutColumns.name,
  CommonProdutColumns.description,
  CommonProdutColumns.sku,
  CommonProdutColumns.category,
  CommonProdutColumns.brand,
  CommonProdutColumns.attributes,
  CommonProdutColumns.price,
  CommonProdutColumns.serial_number,
  CommonProdutColumns.active,
  CommonProdutColumns.edit,
  CommonProdutColumns.manage
];

export const columnsFeaturesGroup = [FeaturesGroupColumns.feature_group_id, FeaturesGroupColumns.name, FeaturesGroupColumns.product_id, FeaturesGroupColumns.order, FeaturesGroupColumns.action];

export const columnsFeatures = [FeaturesColumns.feature_group_id, FeaturesColumns.name, FeaturesColumns.value, FeaturesColumns.searchable, FeaturesColumns.order, FeaturesColumns.action];
