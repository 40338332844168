import React, { useCallback, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { DatabaseConnector } from '@phinxlab/libby-rest-web';
import { Grid } from '@material-ui/core';
import { Data_Product } from './components/CollectProduct';
import { ScreenAligned } from '../../../../components/ScreenAligned/ScreenAligned';
import { LibbyObject } from '../../../../types';
import { percentageComplete, getCircleColor } from '../../../../utils';
import { ACCOUNT_ROLE, CollectItemState, CollectStatusPercentage } from '../../../../const';
import { useCollectStarted } from '../../hook';
import { NavbarMobileCollect } from '../../common';
import { useTranslation } from '../../../../services/translation';
import { Order_item } from '../../../../interfaces/business/orders/Order_item';
import { Collect_item_product, Collect, Collect_item } from '../../../../interfaces/business';
import { CarouselProduct } from '../../components/CarouselProduct';
import { useCollectClose } from '../../hook/useCollectClose';
import { Product_component } from '../../../../interfaces/business/product/Product_component';
import { DialogLoading } from 'components/DialogLoading';

type CollectStartedProps = {
  libby: LibbyObject;
  updateData: (Collect: Collect, id: string) => void;
};

const CollectStartedRaw = ({ libby, updateData }: CollectStartedProps) => {
  const { id } = useParams<{ id: string }>();
  const { t } = useTranslation();
  const userInfo = libby.session.user;
  const {
    data,
    working,
    updateData: updateDataDetails
  } = useCollectStarted({
    libby,
    id,
    updateData
  });

  const { onCloseCollect, orderStateState, closeCollect } = useCollectClose({
    updateData: updateDataDetails,
    libby,
    data
  });

  const addProducts = useCallback((order_item: Order_item, quantity: number, item: Collect_item, items_product: Collect_item_product[]) => {
    const allProducts = [];
    for (let index = 0; index < quantity; index++) {
      const enabled = items_product.filter((item_product: Collect_item_product) => item_product.order_item.order_item_id === order_item.order_item_id);
      allProducts.push({
        product: order_item,
        collectItem: item,
        inItemsProducts: !!enabled[index]
      });
    }
    return allProducts;
  }, []);

  const products = useMemo(() => {
    let productsScan: Data_Product[] = [];

    if (data?.items?.length) {
      data.items.forEach((item: Collect_item) => {
        const copyItem = { ...item };
        item.dispatch_item.order.items.forEach((order_item: Order_item) => {
          let newProducts: Data_Product[] = [];

          if (order_item?.products_c?.length) {
            newProducts = order_item?.products_c.reduce((newProductComponent: Data_Product[], product_component: Product_component) => {
              const newProductsComponent = addProducts(
                {
                  ...order_item,
                  sku: product_component.component.sku,
                  product: product_component.component
                },
                product_component.quantity,
                item,
                copyItem.items_product
              );
              const quantityEnableNewProducts = newProductsComponent.filter((newProduct: Data_Product) => newProduct.inItemsProducts).length;

              if (quantityEnableNewProducts > 0) {
                for (let index = 0; index < quantityEnableNewProducts; index++) {
                  const allItemProductEnabled = [...copyItem.items_product];
                  const enabled = copyItem.items_product.findIndex((item_product: Collect_item_product) => item_product.order_item.order_item_id === order_item.order_item_id);
                  allItemProductEnabled.splice(enabled, 1);
                  copyItem.items_product = allItemProductEnabled;
                }
              }

              return [...newProductComponent, ...newProductsComponent];
            }, []);
          } else {
            newProducts = addProducts(order_item, order_item.quantity, item, copyItem.items_product);
          }
          productsScan = [...productsScan, ...newProducts];
        });
      });
    }
    return productsScan;
  }, [data, addProducts]);

  const orders = data?.items?.flatMap((order: Collect_item) => order) || [];

  const rest = orders.filter((item: Collect_item) => item.collectItemState.collect_item_state_id === CollectItemState.COLLECTED).length || 0;
  const complete = orders ? percentageComplete(rest, orders.length) : '0';

  useEffect(() => {
    if (complete === CollectStatusPercentage.GREEN && !closeCollect) {
      onCloseCollect();
    }
  }, [complete, onCloseCollect, closeCollect]);

  const updateDataCollectStart = useCallback(
    (collectItemProduct: Collect_item_product) => {
      updateDataDetails((prev: Collect) => {
        const copy = { ...prev };
        const copyItemUpdate = copy.items.findIndex((item: Collect_item) => item.collect_item_id === collectItemProduct.collect_item.collect_item_id);

        const copyItemsProduct = [...copy.items[copyItemUpdate].items_product];

        copy.items[copyItemUpdate].collectItemState = collectItemProduct.collect_item.collectItemState;

        copyItemsProduct.push(collectItemProduct);
        copy.items[copyItemUpdate].collectItemState = collectItemProduct.collect_item.collectItemState;

        copy.items[copyItemUpdate].items_product = copyItemsProduct;

        return copy;
      });
    },
    [updateDataDetails]
  );

  return (
    <>
      <NavbarMobileCollect id={id} complete={complete} getCircleColor={getCircleColor} />
      <ScreenAligned title="" working={working}>
        {userInfo?.groups[0]?.id === ACCOUNT_ROLE.LOGISTICS || userInfo?.groups[0]?.id === ACCOUNT_ROLE.ADMINISTRATOR || userInfo?.id === String(data?.account?.account_id) ? (
          <Grid>
            <CarouselProduct id={id} products={products} updateData={updateDataCollectStart} />
          </Grid>
        ) : (
          <Grid container justify="center">
            <h2 style={{ textAlign: 'center' }}>{t("You haven't permissions to see this collect.")}</h2>
          </Grid>
        )}
      </ScreenAligned>
      {orderStateState.loading && <DialogLoading title={t('Updating')} content={`${t('We are updating the orders')}: ${orderStateState.count} ${t('of')} ${orderStateState.totalCount}`} />}
    </>
  );
};

export const CollectStarted = DatabaseConnector(CollectStartedRaw)('ster_dispatch_collect_details', 'ster_collect_item_product', 'ster_dispatch_collect_close', 'ster_order_table');
