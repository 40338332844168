import React, { useCallback, useMemo, useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { ThemeProvider } from '@material-ui/styles';
import Button from '@material-ui/core/Button';
import { Accordion, AccordionDetails, AccordionSummary, Box, Grid, Paper, TextField, useMediaQuery } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { cloneDeep } from 'lodash';
import theme from 'theme';
import { useTranslation } from '../../services/translation';
import { useStyles } from './style/style';
import { PaginatedTable } from '../PaginatedTable';
import { MethosProductsType, OrderItemType } from '../../routes/Budget/routes/BudgetNew/hook/useSaveOrderLogic';
import { RowsType } from '../../routes/Budget/types';
import { formatMoney } from '../../utils';
import { AnyObject } from '../../types';
import OrderRowMobile from './OrderRowMobile';

export interface PreviewNewBudgetType {
  title: string;
  methodsProducts: MethosProductsType[];
  open?: boolean;
  editUnitPrice?: boolean;
  customTheme?: any;
  onConfirm: (listOrderIds?: (string | undefined)[]) => void;
  setMethodsProducts?: (param: any) => void;
}

export const PreviewNewBudgetDialog = ({ title = '', methodsProducts = [], open = false, customTheme = null, onConfirm = () => {}, setMethodsProducts = () => {}, editUnitPrice = true }: PreviewNewBudgetType) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [buttonText, setButtonText] = useState('Accept');
  const matches = useMediaQuery(theme.breakpoints.up(430));

  const listOrderIds = useMemo(() => {
    return methodsProducts.map((method: MethosProductsType) => method?.order_id);
  }, [methodsProducts]);

  const handleChange = useCallback(
    (event: any, row: any) => {
      event.stopPropagation();
      const { value } = event.target;
      setMethodsProducts((prevData: any) => {
        const paymentMethodIndex = prevData.findIndex((orderItem: AnyObject) => orderItem.payment_method_id === row.method);
        const newState = cloneDeep(prevData);
        if (paymentMethodIndex >= 0) {
          newState[paymentMethodIndex] = {
            ...newState[paymentMethodIndex],
            product_list: newState[paymentMethodIndex].product_list.map((item: any) =>
              item.sku === row.sku
                ? {
                    ...item,
                    unit_price: value.toString(),
                    subtotal: (Number(row.quantity) * Number(value)).toString()
                  }
                : item
            )
          };
        }
        return newState;
      });
      setButtonText('Save Price Change');
    },
    [setMethodsProducts]
  );

  const columnsProducts = [
    {
      id: 'sku',
      label: 'SKU'
    },
    {
      id: 'name',
      label: 'Name'
    },
    {
      id: 'quantity',
      label: 'Quantity'
    },
    {
      id: 'unit_price',
      label: 'Unit Price',
      render: (row: any) => {
        return editUnitPrice ? <TextField onChange={(e) => handleChange(e, row)} defaultValue={row.unit_price} color="primary" name={row.product_id} type="number" /> : row.unit_price;
      }
    },
    {
      id: 'subtotal',
      label: 'Subtotal'
    },
    {
      id: 'stock',
      label: 'Stock',
      render: (row: any) => row.product?.stock || 0
    }
  ];

  const rows = (method: MethosProductsType) => {
    return method.product_list.map((item: OrderItemType, index) => {
      return {
        ...item,
        id: item.order_item_id || index.toString(),
        method: method.payment_method_id,
        orderId: method?.order_id
      };
    }) as RowsType[];
  };

  const dialog = matches ? (
    <Dialog open={open} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" className={classes.root}>
      <DialogTitle className={classes.modalTitle} id="alert-dialog-title">
        <Typography className={classes.title} variant="h3">
          {t(title || '')}
        </Typography>
      </DialogTitle>
      <DialogContent>
        {methodsProducts.map((method: MethosProductsType) => {
          let subtotal = 0;
          method.product_list.map((orderItem) => {
            subtotal += +orderItem.subtotal;
            return orderItem;
          });
          return (
            <Accordion>
              <AccordionSummary>
                <DialogContentText className={classes.modalContent} id="alert-dialog-description">
                  <Box>{method.payment_method_name}</Box>
                  {method?.order_id && (
                    <Box>
                      {t('Order id')}: {method?.order_id}
                    </Box>
                  )}
                </DialogContentText>
              </AccordionSummary>
              <AccordionDetails>
                <Grid>
                  <PaginatedTable headCells={columnsProducts} rows={rows(method)} rowIdKey="sku" />
                  <Grid container spacing={3} justify="flex-end">
                    <Grid item xs={4}>
                      <Paper className={classes.paper}>
                        <Grid container spacing={3} justify="space-between">
                          <Grid item xs={4}>
                            Subtotal:
                          </Grid>
                          <Grid item xs={4}>
                            {formatMoney(subtotal.toString())}
                          </Grid>
                        </Grid>
                      </Paper>
                    </Grid>
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
          );
        })}
        <Grid xs={12} />
      </DialogContent>
      <DialogActions className={classes.buttonText}>
        <Button
          onClick={() => {
            setButtonText('Accept');
            onConfirm(listOrderIds);
          }}
          color="primary"
        >
          {t(buttonText)}
        </Button>
      </DialogActions>
    </Dialog>
  ) : (
    <Dialog open={open} className={classes.papperMobile}>
      <DialogTitle id="alert-dialog-title">
        <Typography variant="h3">{t(title || '')}</Typography>
      </DialogTitle>
      <DialogContent style={{ width: '100%', padding: '5px', margin: '0px' }}>
        {methodsProducts.map((method: MethosProductsType) => {
          let subtotal = 0;
          method.product_list.map((orderItem) => {
            subtotal += +orderItem.subtotal;
            return orderItem;
          });
          return <OrderRowMobile method={method} subtotal={subtotal} />;
        })}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            setButtonText('Accept');
            onConfirm(listOrderIds);
          }}
          color="primary"
        >
          {t(buttonText)}
        </Button>
      </DialogActions>
    </Dialog>
  );
  return !customTheme ? dialog : <ThemeProvider theme={customTheme}>{dialog}</ThemeProvider>;
};
