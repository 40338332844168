import { LibbyObject } from '../types';

type getsLibbyParams = {
  libby: LibbyObject;
  daoName: string;
};

export const getLibbyError = ({ libby, daoName }: getsLibbyParams): string | undefined => {
  let error;

  if (!libby[daoName]) {
    return `No DAO found with name ${daoName}`;
  }

  if (!!libby[daoName].errors?._default?.rest) {
    const { message } = JSON.parse(libby[daoName].errors._default?.rest?.data);
    error = message;
  } else if (!!libby[daoName].errors?.undefined?.rest) {
    const { message } = JSON.parse(libby[daoName].errors.undefined?.rest?.data);
    error = message;
  }

  return error;
};
