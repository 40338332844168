import { makeValidator, ValidatorType, ValidatorRule } from '../../../utils';

export const formInitialValues = {
  orderState: '',
  marketplace: '',
  template: '',
  documentType: '0',
  send_to_client: false,
  send_to: ''
};

const validations: ValidatorRule[] = [
  { key: 'orderState', validators: [ValidatorType.SELECT] },
  { key: 'marketplace', validators: [ValidatorType.SELECT] },
  { key: 'template', validators: [ValidatorType.SELECT] }
  // { key: 'documentType', validators: [ValidatorType.SELECT] },
];

export const validate = makeValidator(validations);

export const getValidate = (keys: Array<string>) => makeValidator(keys.length > 0 ? validations.filter((val) => keys.includes(val.key)) : validations);
