import React, { useMemo } from 'react';
import { GeneralSelect } from 'components/GeneralSelect';
import { useTranslation } from 'services/translation';
import { AnyObject } from 'types';

type TextFieldVariant = 'filled' | 'standard' | 'outlined';
interface SelectConfirmationProps {
  multiple?: boolean;
  inputProps: AnyObject;
  optionAll?: boolean;
  variant?: TextFieldVariant;
  onChange?: (value: any) => void;

  [k: string]: any;

  disabled?: boolean;
  sort?: boolean | 'asc' | 'desc';
  label?: string;
}

const optionsData = [
  { id: 'monetary', value: 'Monetary' },
  { id: 'units', value: 'Units' }
];

export const SelectType = ({ inputProps = {}, optionAll = false, disabled = false, sort, label, variant = 'standard', onChange, ...styles }: SelectConfirmationProps) => {
  const { t } = useTranslation();

  const options = useMemo(() => optionsData.map((option) => ({ ...option, value: t(option.value) })), [t]);

  return (
    <>
      <GeneralSelect disabled={disabled} working={false} label={t(label || '')} optionAll={optionAll} data={options} inputProps={onChange ? { ...inputProps, onChange } : inputProps} {...styles} sort={sort} variant={variant} />
    </>
  );
};
