import { useLibbyCall } from './useLibbyCall';

export interface LibbyFetchByIdOptions {
  daoName: string;
  id: string | number;
  aspect?: string;
}

export interface LibbyFetchByIdReturn<T> {
  data?: T;
  working: boolean;
  error?: string;
}

export const useLibbyFetchById = <T>({ daoName, id, aspect }: LibbyFetchByIdOptions): LibbyFetchByIdReturn<T> => {
  const {
    data: [item] = [],
    working,
    error
  } = useLibbyCall<T[]>({
    daoName,
    methodName: 'fetchById',
    params: [id],
    aspect
  });

  return { data: item, working, error };
};
