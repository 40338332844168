import React from 'react';
import { Box } from '@material-ui/core';
import ListAltIcon from '@material-ui/icons/ListAlt';
import AssignmentIcon from '@material-ui/icons/Assignment';
import EmailIcon from '@material-ui/icons/Email';
import { MakeCell } from '../../routes/components/MakeCell';
import { formatDate, formatDecimal, formatMoney } from '../../utils';
import { ReportOrder } from '../../types';
import { OrderPrintState } from '../../const/OrderPrintingState';
import { Order } from '../../interfaces/business';
import { MESSAGE_SOURCE } from '../../const';

export const useOrderToRows =
  (path: string) =>
  ({ order_id, owner_id, source, total = '0', buyer, updated_at, shipment, updated_by, created_at, so_number, print, state, paid_in_advance, advance_sale }: Order | ReportOrder) => ({
    id: order_id ?? '',
    order_id: formatDecimal(order_id ?? ''),
    total: (
      <Box textAlign="right" fontWeight="fontWeightBold">
        {formatMoney(total)}
      </Box>
    ),
    owner_id,
    marketplace: <Box textAlign="center">{source?.marketplace?.name}</Box>,
    order_state_id: state?.order_state_id,
    order_state_name: state?.name,
    state: source?.name,
    document: buyer?.document ?? '',
    buyer: `${buyer?.first_name} ${buyer?.last_name}`,
    phone_number: buyer?.phone_number,
    so_number,
    zip_code: buyer?.zip,
    state_printing: OrderPrintState[print ? 1 : 2],
    service: shipment?.service?.name,
    courier: shipment?.service?.courier?.name,
    date: `${updated_at ? formatDate(updated_at, true, false) : ''}`,
    updated_by: (
      <Box textAlign="center" fontWeight="fontWeightBold">
        {updated_by?.username}
      </Box>
    ),
    created_at: `${created_at ? formatDate(created_at, true, false) : ''}`,
    detail: (
      <MakeCell
        label=""
        icon={AssignmentIcon}
        path={`${path}/detail/${order_id}`}
        onClick={(e) => {
          e.stopPropagation();
        }}
      />
    ),
    logs: (
      <MakeCell
        label=""
        icon={ListAltIcon}
        path={`${path}/logs/${order_id}`}
        onClick={(e) => {
          e.stopPropagation();
        }}
      />
    ),
    message: (
      <MakeCell
        label=""
        icon={EmailIcon}
        path="/messages/messages"
        search={`?source_id=${order_id}&message_source=${MESSAGE_SOURCE.ORDER}`}
        onClick={(e) => {
          e.stopPropagation();
        }}
      />
    ),
    paid_in_advance: paid_in_advance,
    advance_sale: advance_sale
  });
