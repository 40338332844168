export enum CollectStatusColors {
  RED = '#F45724',
  YELLOW = '#FFC324',
  GREEN = '#00DE59'
}

export enum CollectStatusPercentage {
  RED = 33,
  GREEN = 100
}
