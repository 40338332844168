import React, { useMemo, useRef, useState } from 'react';
import { Button, Grid } from '@material-ui/core';
import { AnyObject } from '../../../../types/types';
import { useTranslation } from '../../../../services/translation';
import { useContainerDimensions } from '../../../../hooks/useContainerDimensions';
import { ScreenAligned } from '../../../../components/ScreenAligned/ScreenAligned';
import LoadingData from '../../../components/LoadingData';
import InfoTable from '../../../components/InfoTable';
import { SearchFilters } from './components/SearchFilters';
import { useMakeClientTable } from './hooks/useMakeClientTable';

export const ClientsList = () => {
  const { t } = useTranslation();
  const componentRef = useRef<HTMLInputElement>(null);
  const { width } = useContainerDimensions(componentRef);
  const [direction, setDirection] = React.useState<'asc' | 'desc'>('asc');
  //   const mobileList = useMemo(() => width < 600, [width]);
  const [orderBy, setOrderBy] = useState('client_document_id');

  const [filter, setFilter] = useState<AnyObject>({});
  const [resetFilter, setResetFilter] = useState<boolean>(false);

  const handleRequestSort = (newOrderBy: string, newDirection: 'asc' | 'desc') => {
    setOrderBy(newOrderBy);
    setDirection(newDirection);
  };

  const paramsFetch = useMemo(
    () => ({
      daoName: 'ster_client',
      filter,
      orderBy,
      direction
    }),
    [filter, orderBy, direction]
  );
  const { columns, rows, working, fetchMore, handleOpenClientModal } = useMakeClientTable(paramsFetch);

  return (
    <ScreenAligned
      title="Clients with turns"
      additionalTitle={
        <Grid container justify="flex-end">
          <Button type="reset" variant="contained" onClick={() => handleOpenClientModal()} color="primary">
            {t('Create')}
          </Button>
        </Grid>
      }
    >
      <div ref={componentRef}>
        <SearchFilters resetFilter={resetFilter} setResetFilter={setResetFilter} onFilterChange={setFilter} working={working} />
        {rows && rows.length > 0 && width > 0 ? (
          <>
            <InfoTable columns={columns} rows={rows} onBottomScroll={fetchMore} rowIdKey="client_document_id" onSortChange={handleRequestSort} orderBy={orderBy} direction={direction} />
          </>
        ) : (
          !working && (
            <Grid container justify="center">
              <h2>{t('No documents pending')}</h2>
            </Grid>
          )
        )}

        {(working || !!rows?.length) && <LoadingData label={`${t('Loaded registers')}`} working={working} cant={rows?.length} />}
      </div>
    </ScreenAligned>
  );
};
