import { useMemo, useCallback } from 'react';
import { Column } from '../../../components/InfoTable';
import { useTranslation } from '../../../../services/translation';
import { LibbyObject } from '../../../../types/types';
import { ButtonDetailsType } from '../../../../types/Button';
import { filterInit, initFilter } from './StockDaysReportList';

export type ReportingSalesListProps = { vatEnabled?: boolean; libby: LibbyObject; typeReporting: 'Sales' | 'Orders'; daoNameHistory: string; daoName: string; columnsAux: Array<Column>; columnsProductAux: Array<Column> };

type StockDaysReportListLogicProps = {
  recall: ((...newParams: any[]) => Promise<any>) | undefined;
  filter: any;
  setFilter: any;
  workingData: boolean;
};

export default function StockDaysReportListLogic({ recall, filter, setFilter, workingData }: StockDaysReportListLogicProps) {
  const { t } = useTranslation();

  const refresh = useCallback(() => {
    recall?.(filter.metadata);
  }, [recall, filter]);

  const OnChangeApplyFilters = useCallback(() => {
    refresh();
  }, [refresh]);

  const onResetFilter = useCallback(() => {
    setFilter(initFilter);
    recall?.(filterInit);
  }, [recall, setFilter]);

  const buttons: ButtonDetailsType[] = useMemo(
    () => [
      {
        id: 'resetFilters',
        onClick: onResetFilter,
        title: t('Reset'),
        disabled: workingData,
        loading: workingData
      },
      {
        id: 'dispatchDetailAddOrder',
        onClick: OnChangeApplyFilters,
        title: `${t('Refresh')}`,
        disabled: workingData,
        loading: workingData
      }
    ],
    [OnChangeApplyFilters, workingData, t, onResetFilter]
  );

  return {
    buttons
  };
}
