import { CustomError } from 'utils';
import { API_URL } from '../../../../../config';
import { GET_PRICE_LIST_B2B } from '../../../../../const';
import { PaymentMethodItem, ProductOodoResponse } from '../context/BudgetContext/types';

// TODO: esto deberia hacerse con libby tmb
export const getOodoPrice = async (sku: string, paymentMethod: Omit<PaymentMethodItem, 'code' | 'name'> | undefined, tierId?: number, advanceSale?: boolean): Promise<ProductOodoResponse | undefined> => {
  console.log('getOodoPrice:paymentMethod', paymentMethod);
  //@ts-ignore TODO: currency no es opcional pero mientras hacemos pruebas va asi
  const { odoo_currency_id: currency_id } = paymentMethod?.Currency?.odooCurrency[0];

  const body = {
    currency_id: +currency_id,
    sku,
    advanceSale,
    payment_methods: {
      payment_methods_id: paymentMethod?.paymentMethod?.OdooPaymentMethod[0]?.odoo_payment_method_id,
      dias: paymentMethod?.paymentMethod.PaymentMethod.days || 0,
      qty_deferred_payment: paymentMethod?.paymentDays,
      qty_delivery_days: paymentMethod?.deliverDays
    },
    tier_id: tierId,
    channel: 'offline'
  };
  const response = await fetch(`${API_URL}/${GET_PRICE_LIST_B2B}`, {
    method: 'POST',
    body: JSON.stringify(body),
    headers: { 'Content-Type': 'application/json' }
  });
  const json = await response.json();

  if (json?.result?.status === 400) {
    throw new CustomError(json.result.message);
  }
  const price = json?.result?.message?.[0];
  return price as ProductOodoResponse | undefined;
};
