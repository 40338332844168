import { useMemo } from 'react';
import { OptionsLibbyFetch, useLibbyCall } from '../../../hooks';
import { useOrderToRows } from '../../../models/orders/rows';
import { OrderLiquidation, OrderLiquidationGroup } from '../../../interfaces/business';
import { ORDER_DOCUMENT_TYPE } from '../../../const';
import { columnsLiquidation } from '../../../models/orders/columns';
import { LibbyObject, RowsType } from '../../../types';

interface UseBillingTableLogicProps {
  libby: LibbyObject;
  paramsFetch?: OptionsLibbyFetch;
  columnCheckEnabled?: boolean;
  actions?: boolean;
  group_id: string;
}

export const useLiquidationTableLogic = ({ libby, group_id }: UseBillingTableLogicProps) => {
  const getCommonRows = useOrderToRows('');

  const { data: dataDetails, working: workingDetails } = useLibbyCall(libby, {
    daoName: 'ster_order_liquidation_group',
    methodName: 'fetchById',
    params: [group_id]
  });

  const { data, working: workingData } = useLibbyCall(libby, {
    daoName: 'ster_order_liquidation',
    methodName: 'getGroupByOrderLiquidationId',
    aspect: 'list_orders_liquidations',
    params: [group_id]
  });

  const row: RowsType[] = useMemo(() => {
    const dataOrder = data as OrderLiquidation[];

    return dataOrder.map((orderLiquidation: OrderLiquidation) => {
      const { documents } = orderLiquidation.order;

      return {
        ...getCommonRows(orderLiquidation.order),

        ...orderLiquidation,

        so_number: documents.filter((item) => item.type.order_document_type_id === ORDER_DOCUMENT_TYPE.PEDIDO)[0].documentNumber
      };
    });
  }, [data, getCommonRows]);

  const columns = useMemo(() => columnsLiquidation, []);

  const liquidationGroupDetails = useMemo(() => {
    const dataOrder = dataDetails as OrderLiquidationGroup[];

    return dataOrder[0]?.details;
  }, [dataDetails]);

  const working = useMemo(() => workingData || workingDetails, [workingData, workingDetails]);

  return {
    row,
    columns,
    working,
    liquidationGroupDetails
  };
};
