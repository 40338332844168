import React, { useMemo } from 'react';
import { DatabaseConnector } from '@phinxlab/libby-rest-web';
import { GeneralSelect } from '../../../components/GeneralSelect';
import { AnyObject, LibbyObject } from '../../../types/types';
import { useLibbyCall } from '../../../hooks';
import { Account } from '../../../interfaces/business';

type SelectDispatchProps = {
  inputProps: AnyObject;
  optionAll?: boolean;
  [k: string]: any;
  libby: LibbyObject;
  valueInit: Account;
  label?: string;
  sort?: boolean | 'asc' | 'desc';
  methodName?: string;
};

export const SelectAccountRaw = ({ libby, inputProps = {}, optionAll = false, valueInit, label = 'Account', sort, methodName, ...styles }: SelectDispatchProps) => {
  const { data: dataRaw, working = true } = useLibbyCall(libby, {
    daoName: 'admin_account',
    methodName: methodName || 'getAccountLogisticsOperator'
  });

  const data = useMemo(
    () =>
      dataRaw.map(({ account_id, name, last_name }: AnyObject) => ({
        id: account_id.toString(),
        value: `${name} ${last_name || ''}`
      })),
    [dataRaw]
  );

  return <GeneralSelect working={working} label={label} optionAll={optionAll} data={data} inputProps={inputProps} {...styles} sort={sort} />;
};

export const SelectAccount = DatabaseConnector(SelectAccountRaw)('admin_account');
