import React, { useCallback, useMemo } from 'react';
import { Collapse, Grid } from '@material-ui/core';
import { isMoment } from 'moment';
import { Company } from '../../../../interfaces/business/marketplace/Company';
import { Courier, Marketplace } from '../../../../interfaces/business';
import { Order_source_type } from '../../../../interfaces/business/orders/Order_source_type';
import { useTranslation } from '../../../../services/translation';
import { endDateFormat, startDateFormat } from '../../../../utils';
import { FilterCommonProps, RowsImput } from '../../types';
import { FilterBarSelection } from '../../../../routes/Reporting/routes/ReportingList/FilterBar';
import { Filter } from '../../../../types';
import { useRepotingSalesFilterRows } from './useRepotingSalesFilterRows';

export interface FilterSelections {
  companies: Company[];
  marketplaces: Marketplace[];
  couriers: Courier[];
  canals: Order_source_type[];
  from: Date;
  to: Date;
}

export const ReportingSalesFilter = ({ expanded = false, filter, working = false, onFilter }: FilterCommonProps<FilterBarSelection>) => {
  const { t } = useTranslation();

  const prepareToChange = useCallback(
    <T, K>(value: T, name: string, setAditional?: Partial<K>, trasformDate?: 'end' | 'start') => {
      if (value === null) return;
      let valueToSave: T | Date = value;
      if (isMoment(value)) {
        valueToSave = trasformDate === 'end' ? endDateFormat(value).toDate() : startDateFormat(value).toDate();
      }
      const newFilter = {
        ...filter.metadata,
        [name]: valueToSave,
        ...(setAditional || {})
      };
      onFilter({ ...filter, metadata: newFilter });
    },
    [filter, onFilter]
  );

  const filterMarketplaces: Filter = useMemo(
    () => ({
      company:
        filter.metadata.company?.map((item) => ({
          path: 'company.company_id',
          value: item.id
        })) || []
    }),
    [filter.metadata.company]
  );

  const rows: RowsImput[] = useRepotingSalesFilterRows({ filter: filter.metadata, working, filterMarketplaces, t, prepareToChange });

  return (
    <Grid container direction="row" justify="flex-end" alignItems="flex-end" spacing={1}>
      <Grid item xs={12}>
        <Collapse in={expanded} timeout="auto">
          {rows.map(({ imputs, id_row, ...rest_one }) => (
            <Grid key={`row-filter-${id_row}`} {...rest_one}>
              {imputs
                .filter((item) => !item.hidden)
                .map(({ component, xs, sm, md, id, ...rest_two }) => {
                  const Comp = component;
                  return (
                    <Grid key={id} item xs={xs} sm={sm} md={md}>
                      <Comp {...rest_two} />
                    </Grid>
                  );
                })}
            </Grid>
          ))}
        </Collapse>
      </Grid>
    </Grid>
  );
};
