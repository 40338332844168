import React, { useMemo, useState } from 'react';
import { AnyObject } from '../../types/types';
import { useTranslation, useLanguage } from '../../services/translation';
import { GoalDate } from '../../interfaces/business/goal/GoalDate';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import moment, { Moment } from 'moment';
import MomentUtils from '@date-io/moment';
import { orderBy } from 'lodash';

type SelectGoalTypeProps = {
  inputProps: AnyObject;
  optionAll?: boolean;
  [k: string]: any;
  disabled?: boolean;
  sort?: boolean | 'asc' | 'desc';
  label?: string;
  data: GoalDate[];
  working: boolean;
};

export const SelectGoalMonth = ({ inputProps = {}, label = 'Month & year', data, working }: SelectGoalTypeProps) => {
  const { t } = useTranslation();
  const { getLanguage } = useLanguage();
  const language = getLanguage();
  const [value, setValues] = useState<Moment | null>(null);

  const minDate = useMemo(() => {
    return data.length ? orderBy(data, 'start_date', ['asc'])[0].start_date : '';
  }, [data]);

  const maxDate = useMemo(() => {
    return data.length ? orderBy(data, 'start_date', ['desc'])[0].start_date : '';
  }, [data]);

  return (
    <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils} locale={language}>
      <DatePicker
        disabled={working || inputProps.disabled}
        value={value}
        autoOk
        onChange={(date) => {
          inputProps.onChange(date);
          setValues(moment(date));
        }}
        views={['year', 'month']}
        fullWidth
        label={t(label)}
        minDate={minDate}
        maxDate={maxDate}
        clearable
        onAccept={(date) => {
          setValues(date ? moment(date) : null);
        }}
        invalidDateMessage=""
        maxDateMessage=""
      />
    </MuiPickersUtilsProvider>
  );
};
