import { Moment } from 'moment';
import { Filter } from '../../../types';
import { endDateFormatUtc, startDateFormatUtc } from '../../../utils';

type MakeFilterProps = {
  order_liquidation_group_id?: number;
  marketplace?: string;
  start_date?: Moment | null;
  end_date?: Moment | null;
};

export const makeFilter = ({ order_liquidation_group_id, marketplace, start_date, end_date }: MakeFilterProps) => {
  const filter: Filter = {};

  if (marketplace) {
    filter.marketplace = [
      {
        path: 'marketplace.marketplace_id',
        value: marketplace
      }
    ];
  }

  if (order_liquidation_group_id) {
    filter.order_liquidation_group_id = [
      {
        path: 'order_liquidation_group_id',
        value: order_liquidation_group_id,
        method: 'includes'
      }
    ];
  }

  if (start_date) {
    filter.start_date = [
      {
        path: 'start_date',
        value: startDateFormatUtc(start_date),
        method: 'higherOrEquals'
      }
    ];
  }
  if (end_date) {
    filter.end_date = [
      {
        path: 'end_date',
        value: endDateFormatUtc(end_date),
        method: 'lowerOrEquals'
      }
    ];
  }

  return filter;
};
