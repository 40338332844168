import React, { useEffect, useState } from 'react';
import { DatabaseConnector } from '@phinxlab/libby-rest-web';
import { AnyObject, LibbyObject } from '../../types/types';
import { GeneralSelect } from '../../components/GeneralSelect';
import { useOrderState } from '../Orders/routes/OrdersList/hook/useOrderState';
import { MENU_ACTIONS, ORDER_STATE } from '../../const';
import { useMenuAction } from 'hooks/useMenuActions';

type SelectImportTypeProps = {
  title: string;
  inputProps: AnyObject;
  optionAll?: boolean;
  [k: string]: any;
  libby: LibbyObject;
  listStateInit: number[];
  sort?: boolean | 'asc' | 'desc';
};

type DataItem = {
  id: string;
  value: string;
};

export type OrderStateType = {
  order_state_id: string | number;
  name: string;
};

export const SelectOrderStateRaw = ({ libby, inputProps = {}, optionAll = false, title, listStateInit, sort, ...restProps }: SelectImportTypeProps) => {
  const { data, working } = useOrderState(libby, listStateInit);
  const [stateOptions, setStateOptions] = useState([]);
  const { validateAction } = useMenuAction();

  useEffect(() => {
    if (data) {
      setStateOptions(data.filter((item: { id: string; value: string }) => item.id !== ORDER_STATE.DRAFT.toString() && item.id !== ORDER_STATE.DRAFT_REVISION.toString() && item.id !== ORDER_STATE.BUDGET.toString()));
    }

    if (data && validateAction(MENU_ACTIONS.SLCT_ORDER_STATE_FILTER)) {
      setStateOptions(data.filter((item: DataItem) => item.id === ORDER_STATE.READY_FOR_DELIVERY.toString() || item.id === ORDER_STATE.OUT_FOR_DELIVERY.toString() || item.id === ORDER_STATE.DELIVERED.toString()));
    }
  }, [data, validateAction]);

  return <GeneralSelect working={working} label={title} optionAll={optionAll} data={stateOptions} inputProps={inputProps} {...restProps} sort={sort} />;
};

export const SelectOrderState = DatabaseConnector(SelectOrderStateRaw)('order_state');
