import React, { useState, useEffect, useCallback } from 'react';
import moment, { Moment } from 'moment';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import 'moment/locale/es';
import SearchIcon from '@material-ui/icons/Search';
import { makeFilter } from '../utils/makeFilter';
import { useTranslation } from '../../../../../services/translation';
import { InboxOrderState } from '../../../../../const/InboxOrderState';
import { AnyObject } from '../../../../../types/types';
import { SelectMarketPlace } from '../../../../components/SelectMarketPlace';
import { DateClose } from '../../../../../components/DateClose';
import { FilterBar } from '../../../../../components/FilterBar';
import useDebounce from '../../../../../hooks/useDebounce';
import { gridBreakpoints, gridBreakpointsFields } from '../../../../../const/gridBreakpoints';
import { replaceNonNumericCharacters } from '../../../../../functions';
import { SelectOrderStateInbox } from '../../../../components/SelectOrderStateInbox';

const orderStates = Object.entries(InboxOrderState).map(([id, value]) => ({ id: Number(id), value }));

type SearchFiltersProps = {
  onFilterChange: (makeFilter: object) => void;
  initFetch: AnyObject;
};

const initialStartDate = null;
const initialEndDate = null;

const SearchFiltersRaw = ({ onFilterChange, initFetch }: SearchFiltersProps) => {
  const { t } = useTranslation();
  const [symbolsArr] = useState(['e', 'E', '+', '-', '.', ',', '``']);

  const [startDate, setStartDate] = useState<Moment | null>(initFetch.startDate && initFetch.startDate.length > 0 ? moment(initFetch.startDate[0].value) : initialStartDate);
  const [endDate, setEndDate] = useState<Moment | null>(initFetch.endDate && initFetch.endDate.length > 0 ? moment(initFetch.endDate[0].value) : initialEndDate);
  const [status, setStatus] = useState<string>(initFetch.status && initFetch.status.length > 0 ? initFetch.status[0].value : '0');
  const [ownerId, setOwnerId] = useState<string>(initFetch.ownerId && initFetch.ownerId.length > 0 ? initFetch.ownerId[0].value : '');
  const [marketplace, setMarketplace] = useState<string>(initFetch.marketplace && initFetch.marketplace.length > 0 ? initFetch.marketplace[0].value : '0');
  const [inboxOrderStateId, setInboxOrderStateId] = useState<string>(initFetch.inboxOrderStateId && initFetch.inboxOrderStateId.length > 0 ? initFetch.inboxOrderStateId[0].value : '');
  const [reference, setReference] = useState<string>(initFetch.reference && initFetch.reference.length > 0 ? initFetch.reference[0].value : '');

  const referenceDebounced = useDebounce(reference, 500);
  const ownerIdDebounced = useDebounce(ownerId, 500);
  const inboxOrderStateIdDebounced = useDebounce(inboxOrderStateId, 500);

  useEffect(() => {
    onFilterChange(
      makeFilter({
        startDate,
        endDate,
        status,
        ownerId: ownerIdDebounced,
        marketplace,
        inboxOrderStateId: inboxOrderStateIdDebounced,
        reference: referenceDebounced
      })
    );
  }, [onFilterChange, startDate, endDate, status, ownerIdDebounced, marketplace, inboxOrderStateIdDebounced, referenceDebounced]);

  const handleChangeMarketplace = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setMarketplace(event.target.value !== '' ? event.target.value : '0');
  }, []);

  const handleChangeStatus = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setStatus(event.target.value !== '' ? event.target.value : '0');
  }, []);

  const handleChangeOwnerId = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setOwnerId(event.target.value !== '' ? event.target.value : '');
  }, []);

  const handleReset = useCallback(() => {
    setStartDate(initialStartDate);
    setEndDate(initialEndDate);
    setStatus('0');
    setOwnerId('');
    setMarketplace('0');
    setInboxOrderStateId('');
    setReference('');
    onFilterChange(
      makeFilter({
        startDate: initialStartDate,
        endDate: initialEndDate,
        status: '0',
        ownerId: '',
        marketplace: '0',
        inboxOrderStateId: '',
        reference: ''
      })
    );
  }, [onFilterChange]);

  return (
    <FilterBar
      defaultFilter={
        <Grid item {...gridBreakpoints}>
          <TextField
            id="standard-search"
            placeholder={t('Search by ID')}
            value={inboxOrderStateId}
            type="text"
            fullWidth
            onChange={(e) => setInboxOrderStateId(replaceNonNumericCharacters(e.target.value))}
            InputLabelProps={{ shrink: true }}
            onKeyDown={(e) => symbolsArr.includes(e.key) && e.preventDefault()}
            InputProps={{
              endAdornment: <SearchIcon />
            }}
          />
        </Grid>
      }
      handleReset={handleReset}
    >
      <Grid item {...gridBreakpointsFields}>
        <SelectOrderStateInbox data={orderStates} optionAll title={t('Status')} inputProps={{ value: status, onChange: handleChangeStatus }} />
      </Grid>
      <Grid item {...gridBreakpointsFields}>
        <SelectMarketPlace inputProps={{ value: marketplace, onChange: handleChangeMarketplace }} optionAll sort="asc" />
      </Grid>
      <Grid item {...gridBreakpointsFields}>
        <TextField fullWidth label={t('Owner ID')} type="search" value={ownerId} onChange={handleChangeOwnerId} InputLabelProps={{ shrink: true }} />
      </Grid>
      <Grid item {...gridBreakpointsFields}>
        <TextField fullWidth type="search" label={t('Reference')} value={reference} onChange={(e) => setReference(e.target.value)} InputLabelProps={{ shrink: true }} />
      </Grid>
      <Grid item {...gridBreakpointsFields}>
        <DateClose name="Start Date" value={startDate} onChange={setStartDate} />
      </Grid>
      <Grid item {...gridBreakpointsFields}>
        <DateClose name="End Date" value={endDate} minValue={startDate} onChange={setEndDate} />
      </Grid>
    </FilterBar>
  );
};

export const SearchFilters = React.memo(SearchFiltersRaw);
