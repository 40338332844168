import React, { useEffect, useState } from 'react';
import { FormConfig } from 'react-final-form-hooks';
import { ValidationErrors } from 'final-form';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography, Grid } from '@material-ui/core';
import { Moment } from 'moment';
import { SnackbarProvider } from 'notistack';
import { ThemeProvider, makeStyles } from '@material-ui/styles';
import { gridBreakpointsFields } from '../../../../../const';
import { useTranslation } from '../../../../../services/translation';
import { LibbyObject } from '../../../../../types';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

const useStyles = makeStyles({
  title: {
    fontFamily: 'Oswald'
  },
  errorTitle: {
    marginBottom: '10px'
  },
  accordion: {
    marginBottom: '7px'
  },
  periods: {
    color: '#FF5179'
  },
  error: {
    fontSize: '12px'
  },
  inputsContainer: {
    marginTop: '15px'
  },
  messageContainer: {
    height: 'auto'
  },
  ordersContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    gap: '2px'
  }
});

interface FormInitialValues {
  marketplace: string;
  startDate: Moment | null;
  endDate: Moment | null;
  reference: string;
}

interface LiquidationDialogErrorTypes extends FormConfig<any> {
  open: boolean;
  onCancel: any;
  moveBack: Function;
  closeErrorModal: Function;
  onConfirm: Function;
  errormsg: string;
  title: string;
  content: React.ReactNode;
  labelContent: string;
  cancelText: string;
  confirmText: string;
  customTheme: string | null;
  inputType: string;
  formInitialValues: FormInitialValues;
  formPreviousValues: FormInitialValues;
  validate?: (values: any) => ValidationErrors | Promise<ValidationErrors> | undefined;
  libby: LibbyObject;
}

export const LiquidationDialogError = ({ open = false, closeErrorModal = () => {}, title, cancelText, customTheme = null, errormsg }: LiquidationDialogErrorTypes) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [errorMessage, setErrorMessage] = useState<{ [key: string]: string[] }>({});
  /*   const { enqueueSnackbar } = useSnackbar(); */

  useEffect(() => {
    const groupOrders = (error: string) => {
      const ordersInfo = error.split(', Order: ');
      const groupedOrders: { [key: string]: string[] } = {};

      for (let i = 1; i < ordersInfo.length; i++) {
        const [order, period] = ordersInfo[i].split(', Grupo: ');

        if (!groupedOrders[period]) {
          groupedOrders[period] = [];
        }

        groupedOrders[period].push(order);
      }

      setErrorMessage(groupedOrders);
    };
    if (errormsg && typeof errormsg === 'string' && errormsg !== 'No orders to process') {
      groupOrders(errormsg);
    }

    return () => {
      setErrorMessage({});
    };
  }, [errormsg]);
  const dialog = (
    <>
      <Dialog open={open} aria-labelledby="form-dialog-title" fullWidth maxWidth="md">
        <DialogTitle id="form-dialog-title">
          <Typography className={classes.title} variant="h3">
            {title}
          </Typography>
        </DialogTitle>

        <DialogContent className={classes.messageContainer}>
          <Box item {...gridBreakpointsFields} style={{ marginBottom: '10px' }}>
            {errormsg && errormsg === 'No orders to process' ? (
              <Typography className={classes.title} variant="h4">
                {t(errormsg)}
              </Typography>
            ) : Object.keys(errorMessage).length > 0 ? (
              <Box>
                <Typography className={classes.errorTitle} variant="h4">
                  {t('The following orders were are already liquidated')}
                </Typography>
                {Object.entries(errorMessage).map(([period, orders]) => (
                  <Accordion className={classes.accordion} key={period}>
                    <AccordionSummary expandIcon={<ArrowDropDownIcon />} aria-controls="panel2-content" id="panel2-header">
                      <Typography className={classes.periods} variant="h4">{`${period}`}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Grid container spacing={2}>
                        {orders.map((order) => (
                          <Grid item xs={12} sm={6} md={4} lg={4} key={order}>
                            <Typography>{`Order: ${order}`}</Typography>
                          </Grid>
                        ))}
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                ))}
              </Box>
            ) : (
              <Typography className={classes.periods} variant="h4">
                {JSON.stringify(errormsg)}
              </Typography>
            )}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              closeErrorModal();
            }}
          >
            {cancelText}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );

  return !customTheme ? (
    dialog
  ) : (
    <ThemeProvider theme={customTheme}>
      <SnackbarProvider maxSnack={3}>{dialog}</SnackbarProvider>
    </ThemeProvider>
  );
};
