import { useMemo } from 'react';
import moment, { Moment } from 'moment';
import { Order_reporting_goal_sales, Order_reporting_goal_sales_all, Goal_total } from '../../../../../interfaces/business';
import { FilterBarSelectionReportingObjectiveSales } from '../type';
import { format } from '../../../../../util';
import { useTranslation } from '../../../../../services/translation';
import { useReportingObjectiveSalesLibbyCall } from '../../../../../business/orders/order/ReportingObjectSales';
import { GOAL_TYPE_VALUE } from '../../../../../const';

type useReportingObjectiveSaleCategoryLogicProps = {
  filterInit: FilterBarSelectionReportingObjectiveSales;
  filter: FilterBarSelectionReportingObjectiveSales;
};

export const useReportingObjectiveSaleCategoryLogic = ({ filterInit, filter }: useReportingObjectiveSaleCategoryLogicProps) => {
  const { t } = useTranslation();

  const { data, working, recall } = useReportingObjectiveSalesLibbyCall<Order_reporting_goal_sales_all>({
    methodName: 'getByDateOrderReportingObjectiveSales',
    params: [filterInit],
    noAutoCall: true
  });

  const dataFormatPayload = [
    {
      name: 'reached',
      format: (value: any) => format(value, 'ARS', t)
    },
    {
      name: 'objective',
      format: (value: any) => format(value, 'ARS', t)
    }
  ];

  const statisticsQuantityCategory = useMemo(
    () =>
      data?.goal_total.length
        ? data?.goal_total
            .filter((item) => item.goal_type_value_id === GOAL_TYPE_VALUE.QUANTITY.toString())
            .reduce((result: any[], element: Goal_total) => {
              if (element.name) {
                const reached = data?.goal_product_unit.find((item) => item.goal_id === element.goal_id.toString())?.total || 0;
                const percentage = (reached / element.total) * 100;

                result.push({
                  name: element.name,
                  objective: element.total,
                  reached,
                  'percentage reached': `${isFinite(percentage) ? percentage.toFixed(2) : 0}%`
                });
              }
              return result;
            }, [])
        : [],
    [data]
  );

  const statisticsProductCanalWithTotal = useMemo(() => {
    return {
      objective: data?.goal_total.filter((item) => item.goal_type_value_id === GOAL_TYPE_VALUE.QUANTITY.toString()).reduce((dataTotal: number, element: Goal_total) => dataTotal + element.total, 0) || 0,
      reached: data?.goal_product_unit.reduce((dataTotal: number, element: Order_reporting_goal_sales) => dataTotal + element.total, 0) || 0
    };
  }, [data]);

  const statisticsReportingObjectiveSaleCanal = useMemo(
    () =>
      data?.goal_total.length
        ? data?.goal_total
            .filter((item) => item.goal_type_value_id === GOAL_TYPE_VALUE.MONETARY.toString())
            .reduce((result: any[], element: Goal_total) => {
              if (element.name) {
                const reached = data?.goal_marketplace_monetary.find((item) => item.goal_id === element.goal_id.toString())?.total || 0;
                const percentage = (reached / element.total) * 100;

                result.push({
                  name: element.name,
                  objective: element.total,
                  reached,
                  'percentage reached': `${isFinite(percentage) ? percentage.toFixed(2) : 0}%`
                });
              }
              return result;
            }, [])
        : [],
    [data]
  );

  const statisticsProductsMonetaryAllTotal = useMemo(() => {
    return {
      objective: data?.goal_total?.filter((item) => item.goal_type_value_id === GOAL_TYPE_VALUE.MONETARY.toString()).reduce((dataTotal: number, element: Goal_total) => dataTotal + element.total, 0) || 0,
      reached: data?.goal_marketplace_monetary?.reduce((dataTotal: number, element: Order_reporting_goal_sales) => dataTotal + element.total, 0) || 0
    };
  }, [data]);

  const dataFormatPayloadCanal = [
    {
      name: 'reached',
      format: (value: string) => format(value, 'ARS', t)
    },
    {
      name: 'objective',
      format: (value: string) => format(value, 'ARS', t)
    }
  ];

  const dailySalesRequired = useMemo(() => {
    const today = moment();
    return filter?.to && statisticsProductsMonetaryAllTotal && filter?.to > today ? (statisticsProductsMonetaryAllTotal.objective - statisticsProductsMonetaryAllTotal.reached) / filter?.to?.diff(today, 'days') : 0;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statisticsProductsMonetaryAllTotal]);

  const aditionalColumns = useMemo(() => {
    const months: { id: string; value: string }[] = [];

    data?.goal_total.map((goal) => {
      goal.periods.map((period) => {
        const month = moment(period.start_date).locale('en');
        const object = {
          id: month.format('MMM-YYYY').toLowerCase(),
          value: month.format('MMMM')
        };

        if (!months.find((item) => item.id === object.id)) months.push(object);
      });
    });

    return months;
  }, [data]);

  const dataBillingTable = useMemo(() => {
    const today = moment();
    const from = filter?.from as Moment;
    const to = filter?.to as Moment;
    const dateUsed = today >= from ? today : from;

    const objectives = data?.goal_total.filter((item) => item.goal_type_value_id === GOAL_TYPE_VALUE.MONETARY.toString());
    if (!objectives?.length) return [];

    const objetivoTotal = objectives.reduce((total, item) => total + item.total, 0);

    const tableData =
      from && to && dateUsed >= from && objetivoTotal
        ? objectives
            .map((goal) => {
              const market = data?.goal_marketplace_monetary.find((item) => +item.goal_id === +goal.goal_id) || { total: 0 };
              const projection = Number(((market.total / (Math.abs(from.diff(dateUsed, 'days')) + 1)) * to.diff(dateUsed, 'days') + market.total).toFixed(2));

              const object: any = {
                name: goal.name,
                goal: goal.total,
                shareGoal: Number(((goal.total / objetivoTotal) * 100).toFixed(2)),
                real: market.total,
                percentageComplied: Number(((market.total / goal.total) * 100).toFixed(2)) || 0,
                difference: market.total - goal.total,
                projection,
                percentageCompliedProjection: Number(((projection / goal.total) * 100).toFixed(2)) || 0
              };

              goal.periods.map((period) => {
                const month = moment(period.start_date).locale('en').format('MMM-YYYY').toLowerCase();
                object[month] = period.value;
              });

              return object;
            })
            .sort((a, b) => b.percentageComplied - a.percentageComplied)
        : [];

    if (tableData.length) {
      const goal = tableData.reduce((total, item) => total + item.goal, 0);
      const real = tableData.reduce((total, item) => total + item.real, 0);
      const projection = tableData.reduce((total, item) => total + item.projection, 0);

      const objectTotal: any = {
        name: '',
        goal,
        shareGoal: tableData.reduce((total, item) => total + item.shareGoal, 0),
        real,
        percentageComplied: Number(((real / goal) * 100).toFixed(2)),
        difference: tableData.reduce((total, item) => total + item.difference, 0),
        projection,
        percentageCompliedProjection: Number(((projection / goal) * 100).toFixed(2))
      };

      aditionalColumns.map((month) => (objectTotal[month.id] = tableData.reduce((total, item) => total + item[month.id], 0)));
      tableData.push(objectTotal);
    }

    return tableData;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [aditionalColumns, data?.goal_marketplace_monetary]);

  const dataUnitsTable = useMemo(() => {
    const today = moment();
    const from = filter?.from as Moment;
    const to = filter?.to as Moment;
    const dateUsed = today >= from ? today : from;

    const objectives = data?.goal_total.filter((item) => item.goal_type_value_id === GOAL_TYPE_VALUE.QUANTITY.toString());
    if (!objectives?.length) return [];

    const objetivoTotal = objectives.reduce((total, item) => total + item.total, 0);

    const tableData =
      from && to && dateUsed >= from && objetivoTotal
        ? objectives
            .map((goal) => {
              const product = data?.goal_product_unit.find((item) => +item.goal_id === +goal.goal_id) || { total: 0 };
              const projection = Number(((product.total / (Math.abs(from.diff(dateUsed, 'days')) + 1)) * to.diff(dateUsed, 'days') + product.total).toFixed(2));

              const object: any = {
                name: goal.name,
                goal: goal.total,
                shareGoal: Number(((goal.total / objetivoTotal) * 100).toFixed(2)),
                real: product.total,
                percentageComplied: Number(((product.total / goal.total) * 100).toFixed(2)) || 0,
                difference: product.total - goal.total,
                projection,
                percentageCompliedProjection: Number(((projection / goal.total) * 100).toFixed(2)) || 0
              };

              goal.periods.map((period) => {
                const month = moment(period.start_date).locale('en').format('MMM-YYYY').toLowerCase();
                object[month] = period.value;
              });

              return object;
            })
            .sort((a, b) => b.percentageComplied - a.percentageComplied)
        : [];

    if (tableData.length) {
      const goal = tableData.reduce((total, item) => total + item.goal, 0);
      const real = tableData.reduce((total, item) => total + item.real, 0);
      const projection = tableData.reduce((total, item) => total + item.projection, 0);

      const objectTotal: any = {
        name: '',
        goal,
        shareGoal: Math.round(tableData.reduce((total, item) => total + item.shareGoal, 0)),
        real,
        percentageComplied: Number(((real / goal) * 100).toFixed(2)),
        difference: tableData.reduce((total, item) => total + item.difference, 0),
        projection,
        percentageCompliedProjection: Number(((projection / goal) * 100).toFixed(2))
      };

      aditionalColumns.map((month) => (objectTotal[month.id] = tableData.reduce((total, item) => total + item[month.id], 0)));
      tableData.push(objectTotal);
    }

    return tableData;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [aditionalColumns, data?.goal_product_unit]);

  return {
    working,
    data,
    recall,
    dataFormatPayload,
    statisticsQuantityCategory,
    statisticsProductCanalWithTotal,
    dataFormatPayloadCanal,
    statisticsReportingObjectiveSaleCanal,
    statisticsProductsMonetaryAllTotal,
    dailySalesRequired,
    dataBillingTable,
    aditionalColumns,
    dataUnitsTable
  };
};
