import React, { useMemo, useEffect, useState, useCallback } from 'react';
import { DatabaseConnector } from '@phinxlab/libby-rest-web';
import { Box, Grid, Switch } from '@material-ui/core';
import moment from 'moment';
import { useHistory } from 'react-router';
import InfoTable, { Column } from '../../../components/InfoTable';
import LoadingData from '../../../components/LoadingData';
import { useTranslation } from '../../../../services/translation';
import { useGlobalContext, useLibbyCall, useOrdersParamsRefresh } from '../../../../hooks';
import { LibbyObject } from '../../../../types/types';
import { ChartPie } from '../../../../chart/ChartPie';
import { format } from '../../../../util';
import { FilterBarSelection } from './FilterBar';
import { FilterReportingOrderByState, SCREENS } from '../../../../components/FilterManager';
import { Filter } from '../../../../interfaces/business';
import { useFilterLibbyCall } from '../../../../business/Filter';
import { userInfoType } from '../../../../types';
import { useOrderTableLogic } from '../../../Orders/routes/OrdersList/hook/useOrderTableLogic';
import { makeFilter } from '../../../Reports/ReportingLogistic/routes/ReportingLogisticList/utils/makeFilter';
import { ids } from '../utils';
import { ScreenAligned } from '../../../../components/ScreenAligned/ScreenAligned';
import { ButtonComponent } from '../../../../components/Button';
import { ButtonDetailsType } from '../../../../types/Button';
import { useGetOrderStateTurn, useRefreshHook } from '../hooks';

export type ReportingListProps = { libby: LibbyObject };

type AggregateRow = {
  [k: string]: any;
};

type TypeColumn = {
  name: string;
  total: number;
  count: number;
  columns: Array<Column>;
};
type TypeColumnMarketplaceState = {
  name: string;
  [key: string]: number | string | Array<Column>;
  columns: Array<Column>;
};

interface OrderStateAgregation {
  countofstate_name: string;
  state_name: string;
  sumofamount: string;
}

const filterInit: FilterBarSelection = {
  marketplaces: [],
  courier: [],
  state: [],
  turn: [],
  from: moment().subtract(1, 'months').toDate(),
  to: moment().toDate()
};

const initFilter: Filter<FilterBarSelection> = {
  name: '',
  private: false,
  preset: false,
  account: { username: '', account_id: '-1' },
  screen: { screen_id: SCREENS.REPORTING_ORDER_BY_STATE.toString(), name: '' },
  last_update: new Date(),
  metadata: filterInit,
  campaign: false
};

const ReportingListRaw = ({ libby }: ReportingListProps) => {
  const { t } = useTranslation();

  // Build the columns, depends on t function for translations

  const libbyResponse = useLibbyCall(libby, {
    daoName: 'order_state_marketplace',
    methodName: 'getStatesByMarketplace',
    params: [filterInit]
  });
  const columns = useMemo<Array<Column>>(
    () => [
      {
        id: 'name',
        label: 'State',
        render: (value: TypeColumn) => (value.name === 'Total' ? <b>{t(value.name)}</b> : t(value.name))
      },
      {
        id: 'count',
        label: 'Count',
        render: (value: TypeColumn) => (value.name === 'Total' ? <b>{format(value.count, 'Integer')}</b> : format(value.count, 'Integer'))
      },
      {
        id: 'total',
        label: 'Total',
        render: (value: TypeColumn) => (value.name === 'Total' ? <b>{format(value.total, 'ARS')}</b> : format(value.total, 'ARS'))
      }
    ],
    [t]
  );
  const marketPlaceStateColumns = useMemo<Array<Column>>(() => {
    let parsedColumns: Column[] = [];
    if (libbyResponse?.data?.columns?.length) {
      parsedColumns = libbyResponse.data.columns.map((column: string) => {
        const formattedColumnName = column
          .split('_')
          .map((word, index) => (index === 0 ? word.charAt(0).toUpperCase() + word.slice(1) : word))
          .join(' ');
        return {
          id: column,
          label: formattedColumnName
            .split('_')
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' '),
          render: (value: TypeColumnMarketplaceState) => (value.name === 'Total' ? <b>{format(value[column] || 0, 'Integer')}</b> : format(value[column] || 0, 'Integer'))
        };
      });
    }
    const defaultColumn = [
      {
        id: 'name',
        label: 'Marketplace',
        render: (value: TypeColumnMarketplaceState) => (value.name === 'Total' ? <b>{t(value.name)}</b> : t(value.name))
      }
    ];
    const allColumns = [...defaultColumn, ...parsedColumns];
    return allColumns;
  }, [t, libbyResponse.data.columns]);
  const history = useHistory();
  const path = 'orders';

  const { userInfo }: { userInfo: userInfoType } = useGlobalContext();

  const {
    data: dataGroup,
    working,
    recall
  } = useLibbyCall(libby, {
    daoName: 'ster_order_table',
    methodName: 'getByState',
    params: [filterInit]
  });

  const { data: filtersByUser = [], working: wFilterByUser } = useFilterLibbyCall<Filter<FilterBarSelection>[]>({
    methodName: 'getByUserFilter',
    params: [userInfo?.id || '', SCREENS.REPORTING_ORDER_BY_STATE]
  });

  const [filter, setFilter] = useState<Filter<FilterBarSelection>>(initFilter);

  const detailsRedirection = useCallback((order_id: string) => history.push(`orders/detail/${order_id}`), [history]);

  const filterOrders = makeFilter({
    state: filter?.metadata?.state!,
    turn: filter.metadata.turn,
    marketplaces: filter.metadata.marketplaces,
    source: filter.metadata.courier,
    from: filter.metadata.from,
    to: filter.metadata.to
  });

  const { fetchOrderStateTurn, ordersPendingTurn } = useGetOrderStateTurn(filterOrders);

  const rowPendingOfTurn = useMemo(() => {
    const result = ordersPendingTurn.reduce(
      (acc, obj: OrderStateAgregation) => {
        acc.countofstate_name += parseInt(obj.countofstate_name, 10);
        acc.sumofamount += parseFloat(obj.sumofamount);
        return acc;
      },
      { countofstate_name: 0, state_name: 'Pending of turn', sumofamount: 0.0 }
    );

    result.sumofamount = +result.sumofamount.toFixed(2);
    return result;
  }, [ordersPendingTurn]);

  const { onChangeApplyFilters, time, handleChange, stateRefresh, ordersRefresh } = useRefreshHook({ recallGetByState: recall, recallGetStatesByMarketplace: libbyResponse.recall }, fetchOrderStateTurn, filter);

  const { paramsFetch, direction } = useOrdersParamsRefresh({
    orderInit: 'order_id',
    daoName: 'ster_order',
    directionInit: 'desc',
    aspect: 'list_order_so_order',
    init: filterOrders,
    ordersRefresh
  });

  const {
    rows,
    columns: colOrders,
    fetchMore,
    working: wOrders
  } = useOrderTableLogic({
    libby,
    paramsFetch,
    detailsRedirection,
    actions: true,
    path
  });
  // FIXME CHINO-SDK IS NOT TAKING THE ALIAS, REMOVE THIS WHEN IS OK
  const statistics = useMemo(() => {
    let dataGroupClone = [...dataGroup];
    if (filter.metadata.turn && filter.metadata.turn.length > 0 && ordersRefresh) {
      dataGroupClone = dataGroupClone.map((dataItem: OrderStateAgregation) => {
        const orderTurnFound = ordersPendingTurn.find((orderTurn: OrderStateAgregation) => {
          return orderTurn.state_name === dataItem.state_name;
        });

        if (orderTurnFound) {
          return {
            ...dataItem,
            sumofamount: (+dataItem.sumofamount - +orderTurnFound.sumofamount).toFixed(2),
            countofstate_name: (+dataItem.countofstate_name - +orderTurnFound.countofstate_name).toFixed(2)
          };
        }
        return dataItem;
      });
      dataGroupClone = [...dataGroupClone, rowPendingOfTurn];
    }

    return dataGroupClone.reduce((result: any[], element: AggregateRow) => {
      if (element.state_name) {
        result.push({
          count: parseInt(element.countofstate_name, 10),
          total: parseFloat(element.sumofamount),
          name: element.state_name
        });
      }
      return result;
    }, []);
  }, [dataGroup, filter.metadata.turn, ordersPendingTurn, ordersRefresh, rowPendingOfTurn]);

  const withTotal = useMemo(() => {
    const newArray: Array<any> = [...statistics];
    newArray.push(
      newArray.reduce(
        (transport: AggregateRow, element: AggregateRow) => {
          transport.count += element.count;
          transport.total += element.total;
          return transport;
        },
        { name: 'Total', count: 0, total: 0 }
      )
    );
    return newArray;
  }, [statistics]);

  useEffect(() => {
    if (!wFilterByUser && filtersByUser.length > 0) setFilter(filtersByUser[0]);
  }, [wFilterByUser, filtersByUser]);

  const buttons: ButtonDetailsType[] = useMemo(
    () => [
      {
        id: 'stateOrderFilters',
        onClick: onChangeApplyFilters,
        title: `${t('Refresh')} ${stateRefresh ? `${time} ${t('Seconds').toLowerCase()})` : ''}`,
        disabled: working || wOrders,
        loading: working || wOrders,
        show: true
      }
    ],
    [onChangeApplyFilters, stateRefresh, t, time, wOrders, working]
  );

  return (
    <ScreenAligned
      title="Orders by state"
      additionalTitle={
        <>
          <Grid container>
            <Box display="flex" justifyContent="center" alignItems="center">
              <Switch checked={stateRefresh} onChange={handleChange} name="checkedA" color="primary" inputProps={{ 'aria-label': 'secondary checkbox' }} />
            </Box>
            {buttons.map((dataButton) => (
              <ButtonComponent
                key={dataButton.id}
                title={dataButton.title}
                className={dataButton.className}
                variant={dataButton.variant}
                color={dataButton.color}
                onClick={dataButton.onClick}
                type={dataButton.type}
                disabled={dataButton.disabled}
                loading={dataButton.loading}
              />
            ))}
          </Grid>
        </>
      }
    >
      <Grid container direction="row" justify="center" alignItems="center">
        <FilterReportingOrderByState initialFilter={initFilter} filter={filter} onFilter={setFilter} initExpanded working={working} withSaved />
        <ChartPie data={statistics} type="Pie" key="state_name" value="count" height={500} format="Integer" xs={6} />
        <ChartPie data={statistics} type="Pie" key="state_name" value="total" height={500} format="ARS" xs={6} />
        <InfoTable
          onRowClick={(row) => {
            if (row.name === 'Pending of turn') {
              return;
            }
            const rowState = { value: row.name, id: ids[row.name] };
            setFilter({ ...initFilter, metadata: { ...filterInit, state: [rowState] } });
          }}
          columns={columns}
          rows={withTotal}
          onBottomScroll={() => {}}
          onSortChange={() => {}}
          orderBy="order_id"
          rowIdKey="name"
          height="auto"
          xs={12}
        />
        <LoadingData working={working} cant={withTotal.length} />
        <Box marginTop="3rem" width="100%">
          <InfoTable columns={colOrders} rows={rows} onBottomScroll={fetchMore} direction={direction} onSortChange={() => {}} orderBy="order_id" rowIdKey="name" xs={12} />
          <LoadingData label={`${t('Loaded orders')}`} working={wOrders} cant={rows.length} />
        </Box>

        <InfoTable
          onRowClick={(row) => {
            if (row.name === 'Pending of turn') {
              return;
            }
            const rowState = { value: row.name, id: ids[row.name] };
            setFilter({ ...initFilter, metadata: { ...filterInit, state: [rowState] } });
          }}
          columns={marketPlaceStateColumns}
          rows={libbyResponse.data.rows}
          onBottomScroll={() => {}}
          onSortChange={() => {}}
          orderBy="order_id"
          rowIdKey="name"
          height="auto"
          xs={12}
        />
      </Grid>
    </ScreenAligned>
  );
};

// El dao a usar debe de heredar de LibbyFetchDAO para que funcione
export const ReportingList = DatabaseConnector(ReportingListRaw)('ster_order', 'ster_order_table', 'order_state_marketplace');
