export const columns = [
  {
    id: 'order_id',
    label: 'Order id',
    orderById: 'order_id',
    style: {
      whiteSpace: 'nowrap'
    }
  },
  {
    id: 'owner_id',
    label: 'Owner id',
    style: {
      with: '300px',
      whiteSpace: 'nowrap'
    },
    noSort: true
  },
  {
    id: 'so',
    label: 'Number so',
    orderById: 'so',
    style: {
      minWidth: '100px',
      whiteSpace: 'nowrap'
    }
  },

  {
    id: 'marketplace_name',
    label: 'Marketplace',
    style: {
      width: '200px'
    },
    orderById: 'marketplace_name'
  },
  {
    id: 'buyer_last_name',
    label: 'Lastname',
    style: {
      minWidth: '150px'
    },
    noSort: true
  },
  {
    id: 'buyer_first_name',
    label: 'Name',
    style: {
      minWidth: '150px'
    },
    noSort: true
  },
  {
    id: 'buyer_phone_number',
    label: 'Telefono',
    style: {
      whiteSpace: 'nowrap'
    },
    noSort: true
  },
  {
    id: 'buyer_document',
    label: 'Document',
    style: {
      minWidth: '150px'
    },
    noSort: true
  },
  {
    id: 'shipment_street',
    label: 'Street',
    style: {
      minWidth: '150px'
    },
    noSort: true
  },
  {
    id: 'shipment_street_number',
    label: 'Number',
    style: {
      minWidth: '100px'
    },
    noSort: true
  },
  {
    id: 'shipment_floor',
    label: 'Floor',
    style: {
      minWidth: '70px'
    },
    noSort: true
  },
  {
    id: 'shipment_department',
    label: 'DPTO',
    style: {
      minWidth: '70px'
    },
    noSort: true
  },
  {
    id: 'shipment_zip',
    label: 'Codigo postal',
    style: {
      minWidth: '150px'
    },
    noSort: true
  },
  {
    id: 'city_name',
    label: 'City',
    style: {
      minWidth: '150px'
    },
    noSort: true
  },
  {
    id: 'city_state_name',
    label: 'Provincia',
    style: {
      minWidth: '150px'
    },
    noSort: true
  },
  {
    id: 'order_state_name',
    label: 'State',
    style: {
      minWidth: '150px',
      textTransform: 'capitalize'
    },
    orderById: 'order_state_name'
  },
  {
    id: 'update_at',
    label: 'Ultima actualizacion',
    style: {
      minWidth: '150px',
      whiteSpace: 'nowrap'
    },
    orderBy: 'update_at'
  },
  {
    id: 'shipment_tracking',
    label: 'Tracking',
    style: {
      minWidth: '150px'
    },
    noSort: 'true'
  },
  {
    id: 'shipment_state_name',
    label: 'Estado de seguimiento',
    style: {
      minWidth: '150px',
      whiteSpace: 'nowrap'
    },
    noSort: true
  },
  {
    id: 'andreani_detail',
    label: 'Andreani',
    style: {
      minWidth: '100px',
      whiteSpace: 'nowrap'
    }
  },
  {
    id: 'detail',
    label: 'Detalle',
    style: {
      minWidth: '100px',
      whiteSpace: 'nowrap'
    }
  }
];
