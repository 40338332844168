import { useState } from 'react';
import { IAdvanceSale } from '../types/IAdvanceSale';
import { BudgetInitialValues } from '../types/BudgetContextValue';

export const useAdvanceSale = (initialValues: BudgetInitialValues) => {
  const [advanceSale, setAdvanceSale] = useState<IAdvanceSale>(
    initialValues.advanceSale || {
      isAdvanceSale: false,
      advanceSaleDate: undefined
    }
  );

  return {
    advanceSale,
    setAdvanceSale
  };
};
