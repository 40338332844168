export type RowIds = {
  [k: string]: string;
};

export const ids: RowIds = {
  created: '1',
  selled: '2',
  onqueue: '3',
  'waiting for preparation': '4',
  prepared: '5',
  'ready for delivery': '6',
  'out for delivery': '8',
  delivered: '9',
  cancelled: '10',
  production: '11',
  paid: '12',
  'ready to resend': '14',
  returned: '15',
  'tracker informed': '16',
  'bill informed': '17',
  collected: '18',
  'waiting for external delivery': '19',
  'External delivery ready': '20',
  'Waiting for approval': '21',
  'waiting to cancel': '22',
  'returned compled': '23',
  'Returning to cancel': '24',
  'Ready for preparation': '25',
  'Waiting for return': '26',
  'Cancellation for Fraud': '27',
  Retained: '28',
  Draft: '29',
  'Draft Revision': '30',
  Budget: '31'
};
