import React, { useCallback, useEffect, useState } from 'react';
import { Autocomplete } from '@material-ui/lab';
import { TextField } from '@material-ui/core';
import { useTranslation } from '../../services/translation';
import { useAvailableMLPeriodsDAO } from '../../business';
import { AnyObject } from '../../types';
import { AvailableMLPeriod } from '../../interfaces/business';
import { GeneralSelect } from '../GeneralSelect';
import { DataGenerateSelectType } from '../../utils/generateSelect';

interface SelectMLPeriodProps {
  multiple?: boolean;
  inputProps: AnyObject;
  optionAll?: boolean;

  [k: string]: any;

  disabled?: boolean;
  sort?: boolean | 'asc' | 'desc';
  label?: string;
  filter: {
    marketplace: string;
    year: string;
  };
}

export const SelectMLPeriod = ({ multiple = false, inputProps = {}, optionAll = false, disabled = false, sort, label, filter, ...styles }: SelectMLPeriodProps) => {
  const availableMLPeriodsDAO = useAvailableMLPeriodsDAO();
  const { t } = useTranslation();
  const [options, setOptions] = useState([]);
  const [working, setWorking] = useState(false);

  useEffect(() => {
    (async () => {
      if (!filter.year || !filter.marketplace) return;
      try {
        setWorking(true);
        const data = await availableMLPeriodsDAO.query().equals('marketplace', filter.marketplace).equals('year', filter.year).run();
        const allOptions = data?.length
          ? data.map(({ expiration_date, period: { date_to, date_from } }: AvailableMLPeriod) => ({
              id: `${date_from},${date_to},${expiration_date}`,
              value: `${t('Desde')}: ${date_from} ${t('Hasta')}: ${date_to}`
            }))
          : [];
        setOptions(allOptions);
      } catch (e) {
        console.log('e', e);
      } finally {
        setWorking(false);
      }
    })();
  }, [availableMLPeriodsDAO, filter.year, filter.marketplace, t, setWorking]);

  const onChangeAutoComplete = useCallback(
    (event, newValue: DataGenerateSelectType[]) => {
      inputProps.onChange(
        newValue.map((period: DataGenerateSelectType) => ({
          value: period.id,
          path: ''
        }))
      );
    },
    [inputProps]
  );

  return (
    <>
      {multiple ? (
        <Autocomplete
          disabled={disabled}
          style={{ display: 'inline-block', width: '100%' }}
          multiple
          id="tags-outlined"
          options={options}
          getOptionLabel={(option: { id: string; value: string }) => option.value}
          filterSelectedOptions
          onChange={onChangeAutoComplete}
          renderInput={(params) => <TextField {...params} variant="standard" label={t('ML Periods')} placeholder="" />}
        />
      ) : (
        <GeneralSelect disabled={disabled} working={working} label="ML Periods" optionAll={optionAll} data={options} inputProps={inputProps} {...styles} sort={sort} />
      )}
    </>
  );
};
