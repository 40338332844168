import { Filter } from '../../../../../types/Filter';

type MakeFilterProps = {
  goal_id?: string;
};

export const makeFilter = ({ goal_id }: MakeFilterProps) => {
  const filter: Filter = {};

  if (goal_id) {
    filter.goal_id = [
      {
        path: 'goal.goal_id',
        value: goal_id
      }
    ];
  }

  return filter;
};
