import { Non_working_day } from '../../../../../../interfaces/business';
import { formatDate } from '../../../../../../utils';
import { Column } from '../../../../../components/InfoTable';

export const columnsReportingLogisticSla: Array<Column> = [
  {
    id: 'actions',
    label: '',
    noSort: true,
    width: 30
  },
  {
    id: 'non_working_day_id',
    label: 'Non-working day id',
    width: 200
  },
  {
    id: 'day_date',
    label: 'Date',
    width: 200,
    render: ({ day_date }: Non_working_day) => formatDate(day_date, false, false)
  },
  {
    id: 'description',
    label: 'Description',
    style: {
      whiteSpace: 'nowrap'
    }
  }
];
