import { LibbyFetchDAO } from '../LibbyFetchDAO';

export class NonWorkingDayDAO extends LibbyFetchDAO {
  constructor() {
    super('ster_non_working_day', 'non_working_day_id');
  }

  getAll() {
    return this.query().limit(0, 10000).run();
  }

  getNonWorkingDayByID(id: string) {
    return this.query().equals('non_working_day_id', id).run();
  }
}
