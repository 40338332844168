import React, { useMemo } from 'react';
import { DatabaseConnector } from '@phinxlab/libby-rest-web';
import { AnyObject, LibbyObject } from '../../types/types';
import { GeneralSelect } from '../../components/GeneralSelect';
import { useLibbyFetch } from '../../hooks';
import { Order_document_type } from '../../interfaces/business';
import { DataGenerateSelectType } from '../../utils/generateSelect';
import { useTranslation } from '../../services/translation';

type SelectImportTypeProps = {
  title: string;
  inputProps: AnyObject;
  optionAll?: boolean;
  [k: string]: any;
  libby: LibbyObject;
  listStateInit: number[];
};

export const SelectDocumentTypeRaw = ({ libby, inputProps = {}, optionAll = false, title, listStateInit, ...restProps }: SelectImportTypeProps) => {
  const { data, working } = useLibbyFetch(libby, {
    daoName: 'ster_order_document_type'
  });

  const { t } = useTranslation();

  const documentTypes = useMemo(() => {
    const dataAllDocuments = data as Order_document_type[];

    const copyDataAllDocuments = dataAllDocuments.reduce(
      (allDocumentsType: DataGenerateSelectType[], { order_document_type_id, name }: Order_document_type) => {
        const copyAllDocumentsType = [...allDocumentsType];
        copyAllDocumentsType.push({
          id: parseInt(order_document_type_id, 10),
          value: t(name)
        });
        return copyAllDocumentsType;
      },
      [{ id: 0, value: t('No document type') }]
    );

    return copyDataAllDocuments;
  }, [data, t]);
  return <GeneralSelect working={working} label={title} optionAll={optionAll} data={documentTypes} inputProps={inputProps} {...restProps} />;
};

export const SelectDocumentType = DatabaseConnector(SelectDocumentTypeRaw)('ster_order_document_type');
