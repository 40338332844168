import { LibbyFetchDAO } from './LibbyFetchDAO';

export class ImportationStatusDAO extends LibbyFetchDAO {
  constructor() {
    super('public_importation_status', 'importation_status_id');
  }

  getAll() {
    return this.query().limit(0, 10000).run();
  }
}
