export const pickControlProductsColumns = [
  {
    id: 'so_number',
    label: 'SO',
    noSort: true
  },
  {
    id: 'order_id',
    label: 'Order ID',
    noSort: true
  },
  {
    id: 'cart_box_ix',
    label: 'Cart Box ID',
    noSort: true
  },
  {
    id: 'SKU',
    label: 'SKU',
    noSort: true
  },
  {
    id: 'product_name',
    label: 'Product',
    noSort: true
  },
  {
    id: 'serial_number',
    label: 'Serial Number',
    noSort: true
  },
  {
    id: 'actions',
    label: 'Actions',
    noSort: true
  }
];
