import { Justified_orders } from '../../../interfaces/business';
import { makeBusinessEntity } from '../../../lib/libby/generator/makeBusinessEntity';

const DAO_NAME = 'ster_reporting_logistic_justify';

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch }
} = makeBusinessEntity<Justified_orders>(DAO_NAME);

// hooks
export const useReportingLogisticJustifyById = fetchById;
export const useReportingLogisticJustifyLibbyCall = libbyCall;
export const useReportingLogisticJustifyDAO = libbyDAO;
export const useReportingLogisticJustifyLibbyFetch = libbyFetch;
