import {
  EntitySelectDefaultProps,
  withAccordionSelector,
  WithAccordionSelectorPropsDefaultProps,
  withEntityAutocomplete,
  WithEntityAutocompleteOptions,
  withRenderPropCall,
  withRenderPropFetchById,
  withSelectedChips,
  WithSelectedChipsPropsDefaultProps,
  withSimpleSelect
} from '../hoc';

export interface BussinessHOCsOptions<T> {
  withEntityAutocomplete: WithEntityAutocompleteOptions;
  withSimpleSelect: EntitySelectDefaultProps;
  withAccordionSelector: WithAccordionSelectorPropsDefaultProps<T>;
  withSelectedChips: WithSelectedChipsPropsDefaultProps<T>;
}

export const getBusinessHOCsDefaultOptions = <T>(): BussinessHOCsOptions<T> => ({
  withEntityAutocomplete: {
    optionLabelKey: 'descripcion'
  },
  withSimpleSelect: {
    labelKey: 'descripcion',
    valueKey: 'id',
    title: '',
    placeholder: 'Seleccioná',
    name: 'id'
  },
  withAccordionSelector: {
    idKey: 'id' as keyof T,
    labelKey: 'descripcion' as keyof T
  },
  withSelectedChips: {
    idKey: 'id' as keyof T,
    labelKey: 'descripcion' as keyof T
  }
});

export const makeBusinessHOCs = <T>(daoName: string, options: BussinessHOCsOptions<T> = getBusinessHOCsDefaultOptions<T>()) => {
  const entityAutocomplete = withEntityAutocomplete<T>(daoName, options.withEntityAutocomplete);
  const renderPropCall = withRenderPropCall(daoName);
  const renderPropFetchById = withRenderPropFetchById<T>(daoName);
  const simpleSelect = withSimpleSelect(daoName, options.withSimpleSelect);
  const accordionSelector = withAccordionSelector<T>(daoName, options.withAccordionSelector);
  const selectedChips = withSelectedChips<T>(daoName, options.withSelectedChips);

  return {
    entityAutocomplete,
    renderPropCall,
    renderPropFetchById,
    simpleSelect,
    accordionSelector,
    selectedChips
  };
};
