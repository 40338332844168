export enum COURIER_ID {
  FRAVEGA_ENVIOS = 1,
  NOVA_FLEX = 2,
  ANDREANI = 3,
  ENVIO_PACK = 4,
  CORREO_COMPRAS = 5,
  MERCADO_ENVIOS = 6.1,
  MERCADO_ENVIOS_FLEX = 6.2,
  MERCADO_ENVIOS_FULL = 6.3,
  TRANSPORTE = 7,
  CORREO_ARGENTINO = 8.1,
  CORREO_ARGENTINO_EXPRESS = 8.2,
  RETIRO_EN_SUCURSAL = 9,
  RETIRO_EN_PLANTA = 10,
  ENVIO_EXPRESS = 11,
  INTERNAL = 12,
  EXTERNAL = 13,
  SIN_ENVIO = 14,
  TALAVERA_1 = 19,
  TALAVERA_2,
  TALAVERA_3
}
