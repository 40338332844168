import { TableRow } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React, { ReactNode } from 'react';
import { RowsType } from '../../utils/tables';

const useStyles = makeStyles({
  rowNotPadding: {
    height: '30px',
    color: 'red',
    '& td.MuiTableCell-root': {
      color: (value: any) => value.rowBackground || 'none'
    }
  },
  rowPadding: {
    '& td.MuiTableCell-root': {
      color: (value: any) => value.rowBackground || 'none'
    }
  }
});

type InfoTableRowType = {
  key: string;
  onClick?: (row: RowsType) => void;
  onDoubleClick?: (row: RowsType) => void;
  padding?: boolean;
  row: RowsType;
  rowBackground?: string;
  children: ReactNode;
};

export const InfoTableRow = ({ key, onClick, onDoubleClick, padding, children, row, rowBackground }: InfoTableRowType) => {
  const classes = useStyles({ rowBackground });
  return (
    <TableRow hover role="checkbox" tabIndex={-1} key={key} onClick={onClick && (() => onClick(row))} onDoubleClick={onDoubleClick && (() => onDoubleClick(row))} className={!padding ? classes.rowNotPadding : classes.rowPadding}>
      {children}
    </TableRow>
  );
};
