import { Box, Card, Grid, Theme, Typography, useMediaQuery, GridSize, GridJustification } from '@material-ui/core';
import React from 'react';
import { useTranslation } from '../../services/translation';

export interface KpiSubData {
  title: string;
  value: string | number;
  icon?: any;
  xs?: GridSize;
  sm?: GridSize;
  md?: GridSize;
  lg?: GridSize;
  xl?: GridSize;
}

export interface KpiData {
  name?: string;
  data: KpiSubData[];
}

export interface KpiComponentProps {
  allKpi: KpiData[];
  justify?: GridJustification;
}

export default function KpiComponent({ allKpi, justify = 'space-between' }: KpiComponentProps) {
  const { t } = useTranslation();

  const isMd = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));

  return (
    <>
      {allKpi.map((kpi) => (
        <Box my={2} display="flex" width="100%" flexDirection="column">
          <Grid container xs={12} justify="space-between" direction="column">
            {kpi.name && (
              <Grid item xs={12}>
                <Typography variant="h5">{t(kpi.name)}</Typography>
              </Grid>
            )}
            <Grid container xs={12} justify={justify}>
              {kpi.data.map((data: KpiSubData) => (
                <Grid item xs={data?.xs || 12} sm={data?.sm || 12} md={data?.md || 3} lg={data?.lg || 3} xl={data?.xl || 4}>
                  <Box my={isMd ? 2 : 1} mx={isMd ? 2 : 1}>
                    <Card>
                      <Box display="flex" flexDirection="row" p={isMd ? 3 : 1} bgcolor="#fff1f4" alignItems="center">
                        {data.icon && (
                          <Box borderRadius="50%" borderColor="primary.main" border={1} mr={isMd ? 2 : 1}>
                            {data.icon}
                          </Box>
                        )}
                        <Box display="flex" flexDirection="column">
                          <Box height="100%" width="100%" mb={1}>
                            <Typography variant={isMd ? 'subtitle2' : 'body2'} color="primary">
                              {t(data.title).toUpperCase()}
                            </Typography>
                          </Box>
                          <Box height="100%" width="100%">
                            <Typography variant={isMd ? 'h2' : 'h4'} color="primary">
                              {data.value}
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                    </Card>
                  </Box>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Box>
      ))}
    </>
  );
}
