import { Order_reporting_ml_all } from '../../../interfaces/business';
import { makeBusinessEntity } from '../../../lib/libby/generator/makeBusinessEntity';

const DAO_NAME = 'ster_reporting_ml';

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch }
} = makeBusinessEntity<Order_reporting_ml_all>(DAO_NAME);

// hooks
export const useReportingMlFetchById = fetchById;
export const useReportingMlLibbyCall = libbyCall;
export const useReportingMlDAO = libbyDAO;
export const useReportingMlLibbyFetch = libbyFetch;
