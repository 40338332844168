import { Box } from '@material-ui/core';
import React from 'react';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import { Order_reporting_delivery_control_reporting } from '../../../../../../interfaces/business';
import { formatDate } from '../../../../../../utils';
import { minutesToString } from '../../../../../../utils/frecuendyHours';
import { Column } from '../../../../../components/InfoTable';

export const reportingLogisticJustifyColumns: Array<Column> = [
  {
    id: 'actions',
    label: '',
    noSort: true
  },
  {
    id: 'order_id',
    label: 'Order id',
    style: {
      whiteSpace: 'nowrap'
    },
    noSort: true
  },
  {
    id: 'marketplace',
    label: 'Marketplace',
    style: {
      whiteSpace: 'nowrap'
    },
    noSort: true
  },
  {
    id: 'so',
    label: 'So',
    noSort: true
  },
  {
    id: 'day',
    label: 'Date - waiting for preparation',
    noSort: true,
    render: ({ t_waiting_preparation }: Order_reporting_delivery_control_reporting) => <Box>{formatDate(t_waiting_preparation ?? '', false, false)}</Box>
  },
  {
    id: 'hour',
    label: 'Hour - waiting for preparation',
    noSort: true,
    render: ({ t_waiting_preparation }: Order_reporting_delivery_control_reporting) => <Box>{formatDate(t_waiting_preparation ?? '', true, false, 'HH:mm')}</Box>
  },
  {
    id: 'date',
    label: 'Date - out for delivery',
    noSort: true,
    render: ({ t_out_for_delivery }: Order_reporting_delivery_control_reporting) => <Box>{formatDate(t_out_for_delivery ?? '', false, false)}</Box>
  },
  {
    id: 'hour',
    label: 'Hour - out for delivery',
    noSort: true,
    render: ({ t_out_for_delivery }: Order_reporting_delivery_control_reporting) => <Box>{formatDate(t_out_for_delivery ?? '', true, false, 'HH:mm')}</Box>
  },
  {
    id: 'date',
    label: 'Date - delivered',
    noSort: true,
    render: ({ t_delivered }: Order_reporting_delivery_control_reporting) => <Box>{t_delivered ? formatDate(t_delivered ?? '', false, false) : null}</Box>
  },
  {
    id: 'hour',
    label: 'Hour - delivered',
    noSort: true,
    render: ({ t_delivered }: Order_reporting_delivery_control_reporting) => <Box>{t_delivered ? formatDate(t_delivered ?? '', true, false, 'HH:mm') : null}</Box>
  },
  {
    id: 'delta_operative',
    label: 'Total hours (Operative)',
    noSort: true,
    render: ({ delta_operative }: Order_reporting_delivery_control_reporting) => <Box>{minutesToString(Number(delta_operative))}</Box>
  },
  {
    id: 'effectiveness_operative',
    label: 'Effectiveness (Operative)',
    noSort: true,
    render: ({ effectiveness_operative }: Order_reporting_delivery_control_reporting) => <Box>{!effectiveness_operative ? 'Ok' : minutesToString(effectiveness_operative)}</Box>
  },
  {
    id: 'delta_delivered',
    label: 'Total hours (Delivered)',
    noSort: true,
    render: ({ delta_delivered }: Order_reporting_delivery_control_reporting) => <Box>{delta_delivered ? minutesToString(Number(delta_delivered)) : null}</Box>
  },
  {
    id: 'effectiveness_delivered',
    label: 'Effectiveness (Delivered)',
    noSort: true,
    render: ({ effectiveness_delivered }: Order_reporting_delivery_control_reporting) => <Box>{!effectiveness_delivered ? 'Ok' : minutesToString(effectiveness_delivered)}</Box>
  },
  {
    id: 'justification_order_id',
    label: 'Justified',
    noSort: true,
    render: ({ justification_order_id }: any) => <Box>{justification_order_id && <CheckCircleOutlineIcon style={{ color: '#FF5079' }} />}</Box>
  }
];
