import moment from 'moment';
import { useEffect, useMemo, useCallback, useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useReportingDeliveryControlLibbyFetch } from '../../../../../../business/orders/order/ReportingDeliveryControl';
import { useReportingDeliveryControlGroupLibbyCall } from '../../../../../../business/orders/order/ReportingDeliveryControlGroup';
import { MENU, MENU_ACTIONS, ORDER_SOURCE_TYPE } from '../../../../../../const';
import { useBreadcrumbs, useFilerParamsId } from '../../../../../../hooks';
import { Order_reporting_delivery_control_group, Order_reporting_delivery_control_reporting } from '../../../../../../interfaces/business';
import { useTranslation } from '../../../../../../services/translation';
import { RowsType } from '../../../../../../types';
import { ButtonDetailsType } from '../../../../../../types/Button';
import { makeFilter } from '../utils/makeFilter';
import { KpiData } from '../../../../../components/Kpi';
import { useInterval } from '../../../../../../hooks/useIntervalPage';
import { useMenuAction } from '../../../../../../hooks/useMenuActions';

const allOrderSourceType = [
  {
    id: 1,
    name: 'Online',
    source_type: [ORDER_SOURCE_TYPE.LICITACIONES, ORDER_SOURCE_TYPE.ONLINE]
  },
  {
    id: 2,
    name: 'Offline',
    source_type: [ORDER_SOURCE_TYPE.OFFLINE]
  }
];

interface RowGroup {
  id: number;
  title: string;
  data: RowsType[];
}

type AllQuantity = {
  quantity_operative: number;
  quantity_effectiveness_operative: number;
  quantity_delivered: number;
  quantity_effectiveness_delivered: number;
};

export const useReportingLogisticList = () => {
  const history = useHistory();
  const [stateRefresh, setStateRefresh] = useState(false);
  const match = useRouteMatch();
  const title = useBreadcrumbs('Delivery control');
  const { t } = useTranslation();
  const { orderBy, direction, paramsFetch, handleRequestSort, setFilter, filter } = useFilerParamsId({
    orderInit: 'order_id',
    daoName: 'ster_reporting_delivery_control',
    init: makeFilter({ startDate: moment().subtract(1, 'months').startOf('month'), endDate: moment().subtract(1, 'months').endOf('month'), effectiveness: 1 })
  });
  const [refreshFilter, setRefreshFilter] = useState<any>({
    startDate: [{ path: 'start_date', value: moment().subtract(1, 'months').startOf('month').format('YYYY-MM-DD HH:mm:ss') }],
    endDate: [{ path: 'end_date', value: moment().subtract(1, 'months').endOf('month').format('YYYY-MM-DD HH:mm:ss') }],
    effectiveness: 1
  });

  const { data: allData, fetchMore, working } = useReportingDeliveryControlLibbyFetch(paramsFetch);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setStateRefresh(event.target.checked);
  };

  const {
    data: allDataReportingDeliveryControlGroup = [],
    working: workingReportingDeliveryControlGroup,
    recall
  } = useReportingDeliveryControlGroupLibbyCall({ noAutoCall: true, methodName: 'getAllReportingDeliveryControlGroupByDate' });

  const refresh = useCallback(() => {
    setFilter(refreshFilter);
  }, [refreshFilter, setFilter]);

  const delay = useMemo(() => (stateRefresh ? 60000 : null), [stateRefresh]);

  const { time, resetTime } = useInterval(refresh, delay);

  const OnChangeApplyFilters = useCallback(() => {
    if (stateRefresh) {
      resetTime();
    } else {
      refresh();
    }
  }, [refresh, stateRefresh, resetTime]);

  const rows: RowsType[] = useMemo(() => {
    const dataOrder = allData as Order_reporting_delivery_control_reporting[];

    return dataOrder.length
      ? dataOrder.map((dataReporting: Order_reporting_delivery_control_reporting) => ({
          id: dataReporting.order_id,
          ...dataReporting
        }))
      : [];
  }, [allData]);

  const { validateMenuActions, validateAction } = useMenuAction(MENU.REPORTING_LOGISTIC);

  const buttons: ButtonDetailsType[] = useMemo(() => {
    const buttonsList: ButtonDetailsType[] = [
      {
        id: 'dispatchDetailAddOrder',
        onClick: OnChangeApplyFilters,
        title: `${t('Refresh')} ${stateRefresh ? `${time} ${t('Seconds').toLowerCase()})` : ''}`,
        disabled: workingReportingDeliveryControlGroup || working,
        loading: stateRefresh ? workingReportingDeliveryControlGroup || working : false,
        show: true
      },
      {
        id: 'non-working-days',
        onClick: () =>
          history.push({
            pathname: `${match.path}/non-working-day`,
            state: {
              goBack: title
            }
          }),
        title: t('Non-working days'),
        variant: 'contained',
        show: validateMenuActions(MENU_ACTIONS.SETTINGS.toString())
      },
      {
        id: 'updatedAddOrder',
        onClick: () =>
          history.push({
            pathname: `${match.path}/sla`,
            state: {
              goBack: title
            }
          }),
        title: t('Settings SLA'),
        variant: 'contained',
        show: validateMenuActions(MENU_ACTIONS.SETTINGS.toString())
      },
      {
        id: 'justify',
        onClick: () =>
          history.push({
            pathname: `${match.path}/justify`,
            state: {
              goBack: title
            }
          }),
        title: t('Justify'),
        disabled: !validateAction(MENU_ACTIONS.JUSTIFY),
        variant: 'contained',
        show: validateMenuActions(MENU_ACTIONS.SETTINGS.toString())
      },
      {
        id: 'deliver',
        onClick: () =>
          history.push({
            pathname: `${match.path}/deliver`,
            state: {
              goBack: title
            }
          }),
        title: t('Deliver'),
        variant: 'contained',
        show: validateAction(MENU_ACTIONS.JUSTIFY)
      }
    ];

    return buttonsList.filter((button) => button.show);
  }, [OnChangeApplyFilters, t, stateRefresh, time, workingReportingDeliveryControlGroup, working, validateMenuActions, validateAction, history, match.path, title]);

  useEffect(() => {
    recall?.({
      from: filter.startDate?.[0]?.value,
      to: filter.endDate?.[0]?.value,
      search: filter.search?.[0]?.value,
      effectiveness: filter.effectiveness?.[0]?.value,
      company: filter.company?.[0]?.value,
      marketplace: filter.marketplace,
      courier: filter.courier,
      canal: filter.canal
    });
  }, [filter, recall]);

  const rowsGroup = useMemo(() => {
    const dataOrderGroup = allDataReportingDeliveryControlGroup as Order_reporting_delivery_control_group[];
    const dataOrderAll = dataOrderGroup?.[0]?.reporting_group || [];
    return allOrderSourceType.reduce((allMarketplaceGroupSourceType: RowGroup[], orderSourceType) => {
      const copyAllMarketplaceGroupSourceType = [...allMarketplaceGroupSourceType];

      const allDataGroupSourceType = dataOrderAll?.length ? dataOrderAll?.filter(({ order_source_type_id }) => orderSourceType.source_type.includes(parseInt(order_source_type_id, 10))) : [];
      const allTotalOrderByGroupSourceTypeOperative = allDataGroupSourceType.reduce((quantityDataOrderGroup, dataOrderSourceType) => parseInt(dataOrderSourceType.quantity_operative, 10) + quantityDataOrderGroup, 0);
      const allTotalOrderByGroupSourceTypeDelivered = allDataGroupSourceType.reduce((quantityDataOrderGroup, dataOrderSourceType) => parseInt(dataOrderSourceType.quantity_delivered, 10) + quantityDataOrderGroup, 0);

      const data = allDataGroupSourceType.map((orderGroup) => {
        let quantity_effectiveness_operative = 0;
        let quantity_effectiveness_delivery = 0;
        if (orderGroup?.justification_order_operative) {
          quantity_effectiveness_operative = parseInt(orderGroup.quantity_effectiveness_operative, 10) + parseInt(orderGroup?.justification_order_operative, 10);
        }
        if (orderGroup?.justification_order_delivered) {
          quantity_effectiveness_delivery = parseInt(orderGroup.quantity_effectiveness_delivered, 10) + parseInt(orderGroup?.justification_order_delivered, 10);
        }

        return {
          id: orderGroup.marketplace_id,
          ...orderGroup,
          quantity_effectiveness_operative: quantity_effectiveness_operative || orderGroup.quantity_effectiveness_operative,
          quantity_effectiveness_delivered: quantity_effectiveness_delivery || orderGroup.quantity_effectiveness_delivered,
          quantity_percentage_operative: (parseInt(orderGroup.quantity_operative, 10) * 100) / allTotalOrderByGroupSourceTypeOperative,
          effectiveness_operative: (quantity_effectiveness_operative || parseInt(orderGroup.quantity_effectiveness_operative, 10) * 100) / parseInt(orderGroup.quantity_operative, 10) || 0,
          quantity_percentage_delivered: (parseInt(orderGroup.quantity_delivered, 10) * 100) / allTotalOrderByGroupSourceTypeDelivered,
          effectiveness_delivered: (quantity_effectiveness_delivery || parseInt(orderGroup.quantity_effectiveness_delivered, 10) * 100) / parseInt(orderGroup.quantity_delivered, 10) || 0
        };
      });

      copyAllMarketplaceGroupSourceType.push({
        id: orderSourceType.id,
        title: orderSourceType.name,
        data
      });

      return copyAllMarketplaceGroupSourceType;
    }, []);
  }, [allDataReportingDeliveryControlGroup]);

  const allQuantityOrderSource = useMemo(() => {
    return rowsGroup.length
      ? rowsGroup.reduce((quantity: any, rowGroup: RowGroup) => {
          const copyQuantity = [...quantity];
          const quantityByData = rowGroup.data.reduce(
            (quantityData: AllQuantity, data: RowsType) => ({
              quantity_operative: quantityData.quantity_operative + parseInt(data.quantity_operative, 10),
              quantity_effectiveness_operative: quantityData.quantity_effectiveness_operative + parseInt(data.quantity_effectiveness_operative, 10),
              quantity_delivered: quantityData.quantity_delivered + parseInt(data.quantity_delivered, 10),
              quantity_effectiveness_delivered: quantityData.quantity_effectiveness_delivered + parseInt(data.quantity_effectiveness_delivered, 10)
            }),
            {
              quantity_operative: 0,
              quantity_effectiveness_operative: 0,
              quantity_delivered: 0,
              quantity_effectiveness_delivered: 0
            }
          );

          copyQuantity.push({
            id: rowGroup.id,
            quantity: quantityByData
          });
          return copyQuantity;
        }, [])
      : [];
  }, [rowsGroup]);

  const quantityAllData = useMemo(() => {
    const dataOrderGroup = allDataReportingDeliveryControlGroup as Order_reporting_delivery_control_group[];

    return {
      quantity: dataOrderGroup[0]?.reporting_group?.reduce((prev, currentValue) => prev + Number(currentValue.quantity_operative), 0) || 0,
      quantity_day: dataOrderGroup[0]?.workdays || 0
    };
  }, [allDataReportingDeliveryControlGroup]);

  const allKpi: KpiData[] = useMemo(() => {
    const {
      quantity: {
        quantity_effectiveness_operative: quantityOperativeEffectivenessOnline,
        quantity_operative: quantityOperativeOnline,
        quantity_effectiveness_delivered: quantityDeliveredEffectivenessOnline,
        quantity_delivered: quantityDeliveredOnline
      }
    } = allQuantityOrderSource.find(({ id }) => id === ORDER_SOURCE_TYPE.ONLINE);
    const {
      quantity: {
        quantity_effectiveness_operative: quantityOperativeEffectivenessOffline,
        quantity_operative: quantityOperativeOffline,
        quantity_effectiveness_delivered: quantityDeliveredEffectivenessOffline,
        quantity_delivered: quantityDeliveredOffline
      }
    } = allQuantityOrderSource.find(({ id }) => id === ORDER_SOURCE_TYPE.OFFLINE);

    return [
      {
        data: [
          {
            title: 'Working days',
            value: quantityAllData.quantity_day
          },
          {
            title: 'Average orders per day',
            value: `${quantityAllData.quantity_day > 0 ? Math.ceil(quantityAllData.quantity / quantityAllData.quantity_day) : 0}`
          },
          {
            title: 'Number of online orders',
            value: quantityOperativeOnline
          },
          {
            title: 'Number of offline orders',
            value: quantityOperativeOffline
          },
          {
            title: 'Effectiveness online (Operative)',
            value: `${quantityOperativeOnline > 0 ? Math.min(Math.max((quantityOperativeEffectivenessOnline * 100) / quantityOperativeOnline, 0), 100).toFixed(2) : 0}%`
          },
          {
            title: 'Effectiveness offline (Operative)',
            value: `${quantityOperativeOffline > 0 ? Math.min(Math.max((quantityOperativeEffectivenessOffline * 100) / quantityOperativeOffline, 0), 100).toFixed(2) : 0}%`
          },
          {
            title: 'Effectiveness online (Delivered)',
            value: `${quantityDeliveredOnline > 0 ? Math.min(Math.max((quantityDeliveredEffectivenessOnline * 100) / quantityDeliveredOnline, 0), 100).toFixed(2) : 0}%`
          },
          {
            title: 'Effectiveness offline (Delivered)',
            value: `${quantityDeliveredOffline > 0 ? Math.min(Math.max((quantityDeliveredEffectivenessOffline * 100) / quantityDeliveredOffline, 0), 100).toFixed(2) : 0}%`
          }
        ]
      }
    ];
  }, [allQuantityOrderSource, quantityAllData]);

  return {
    stateRefresh,
    handleChange,
    title,
    buttons,
    working,
    direction,
    orderBy,
    handleRequestSort,
    setRefreshFilter,
    setFilter,
    refreshFilter,
    rows,
    fetchMore,
    workingReportingDeliveryControlGroup,
    rowsGroup,
    allKpi,
    quantityAllData
  };
};
