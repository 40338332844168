import React, { MouseEventHandler } from 'react';
import Dialog from '@material-ui/core/Dialog';
import CloseIcon from '@material-ui/icons/Close';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import { Button, Grid, IconButton } from '@material-ui/core';
import { ThemeProvider, makeStyles } from '@material-ui/styles';
import DialogContentText from '@material-ui/core/DialogContentText';
import { useTranslation } from '../../../../../services/translation';
import { Collect_item_product } from '../../../../../interfaces/business';
import { makeMapLabelValues } from '../../../../utils/mappers';
import { greyText } from '../../../../../theme/colors';

const useStyles = makeStyles(() => ({
  modalTitle: {
    '& h2.MuiTypography-h6': {
      textTransform: 'uppercase',
      fontSize: '16px !important',
      fontWeight: 600
    }
  },
  modalContent: {
    fontSize: 16
  },
  buttonText: {
    '& button': {
      fontSize: 16
    }
  },
  gridItem: {
    '& h5': {
      color: greyText,
      fontSize: 12
    },
    '& p': {
      fontSize: 16
    }
  },
  root: {
    '& .MuiPaper-root': {
      borderRadius: '8px'
    }
  }
}));

type SerialNumberDialogProps = {
  title: React.ReactNode;
  open: boolean;
  onCancel: MouseEventHandler;
  onConfirm: MouseEventHandler;
  customTheme: { [k: string]: any } | null;
  collectItem: Collect_item_product;
  goToCollect: (collect_id: string) => void;
};

const dummyFunc = () => {};

export const SerialNumberDialog = ({ title, open = false, onCancel = dummyFunc, onConfirm = dummyFunc, customTheme = null, collectItem, goToCollect }: SerialNumberDialogProps) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const basicInformationMap = [
    {
      key: 'dispatch_id',
      label: 'Dispatch ID',
      formatter: (value: any, data: Collect_item_product) => data?.collect_item?.collect?.dispatch?.dispatch_id || '-'
    },
    {
      key: 'collect_id',
      label: 'Collect ID',
      formatter: (value: any, data: Collect_item_product) => data?.collect_item?.collect?.collect_id || '-'
    },
    {
      key: 'cart_id',
      label: 'Cart ID',
      formatter: (value: any, data: Collect_item_product) => data?.collect_item?.collect?.cart?.cart_id || '-'
    },
    {
      key: 'order_id',
      label: 'Order ID',
      formatter: (value: any, data: Collect_item_product) => data?.collect_item?.dispatch_item?.order?.order_id || '-'
    },
    {
      key: 'so_number',
      label: 'SO Number',
      formatter: (value: any, data: Collect_item_product) => data?.collect_item?.dispatch_item?.order?.so_number || '-'
    },
    {
      key: 'sku',
      label: 'SKU',
      formatter: (value: any, data: Collect_item_product) => data?.order_item?.sku || '-'
    },
    {
      key: 'name',
      label: 'Product',
      formatter: (value: any, data: Collect_item_product) => data?.order_item?.name || '-'
    },
    {
      key: 'serial_number',
      label: 'Serial Number',
      formatter: (value: string) => value || '-'
    }
  ];

  const mapperLabelValues = makeMapLabelValues(t, 'column', false, {
    lg: 6,
    md: 6,
    sm: 12,
    style: { padding: '10px' }
  });

  const basicMapper = mapperLabelValues(basicInformationMap);

  const dialog = (
    <Dialog open={open} onClose={onCancel} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" className={classes.root} maxWidth="md">
      <DialogTitle className={classes.modalTitle} id="alert-dialog-title">
        <Grid container justify="space-between" alignItems="center">
          {title}
          {onCancel && (
            <>
              <IconButton aria-label="close" onClick={onCancel}>
                <CloseIcon />
              </IconButton>
            </>
          )}
        </Grid>
      </DialogTitle>
      <DialogContent>
        <DialogContentText className={classes.modalContent} id="alert-dialog-description">
          <Grid container justify="space-between" alignItems="flex-start" wrap="nowrap">
            <Grid className={classes.gridItem} container justify="space-between" spacing={1}>
              {collectItem && basicMapper(collectItem)}
            </Grid>
          </Grid>
          <Grid container justify="flex-end">
            <Button
              variant="contained"
              color="primary"
              onClick={(e) => {
                goToCollect(collectItem?.collect_item?.collect?.collect_id);
                onConfirm(e);
              }}
            >
              {t('Go to Collect')}
            </Button>
          </Grid>
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );

  return !customTheme ? dialog : <ThemeProvider theme={customTheme}>{dialog}</ThemeProvider>;
};
