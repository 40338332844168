import { Goal } from '../../../../../interfaces/business';
import { Column } from '../../../../components/InfoTable';

export const columnsReportingGoalSaleSettingProducts: Array<Column> = [
  {
    id: 'actions',
    label: '',
    noSort: true,
    width: 30
  },
  {
    id: 'name',
    label: 'Name',
    style: {
      whiteSpace: 'nowrap'
    }
  },
  {
    id: 'goal_type_value_name',
    label: 'Type value',
    orderById: 'goal_type_value.name',
    render: ({ goal_type_value: { name } }: Goal, item: any, t: (str: string) => string) => t(name)
  },
  {
    id: 'type',
    label: 'Type',
    orderById: 'goal_type.name',
    render: ({ goal_type: { name } }: Goal, item: any, t: (str: string) => string) => t(name)
  }
  /* {
    id: 'Quarter',
    label: 'Quarter',
    render: ({ start_date }: Goal) => `${moment(start_date).quarter()}-${moment(start_date).format('YYYY')}`,
    orderById: 'start_date'
  } */
];
