import React, { useCallback } from 'react';
import MenuItem from '@material-ui/core/MenuItem';

type LanguageMenuItemProps = {
  value: string;
  label: string;
  onClick: (value: string) => void;
  active?: boolean;
};

export const LanguageMenuItem = React.forwardRef<HTMLLIElement, LanguageMenuItemProps>(({ value, label, onClick, active = false }: LanguageMenuItemProps, ref) => {
  const onMenuClick = useCallback(() => onClick(value), [onClick, value]);
  return (
    <MenuItem ref={ref} selected={active} onClick={onMenuClick}>
      {label}
    </MenuItem>
  );
});

LanguageMenuItem.displayName = 'LanguageMenuItem';
