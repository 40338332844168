import React, { useMemo, useCallback } from 'react';
import SearchIcon from '@material-ui/icons/Search';
import { DatabaseConnector } from '@phinxlab/libby-rest-web';
import { TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { GeneralSelect } from '../../components/GeneralSelect';
import { AnyObject, LibbyObject } from '../../types/types';
import { useLibbyCall } from '../../hooks';
import { budgetAccountProps } from '../Budget/interfaces';
import { DataGenerateSelectType } from '../../utils/generateSelect';

type SelectBusinessTypeProps = {
  optionAll?: boolean;
  inputProps: AnyObject;
  [k: string]: any;
  libby: LibbyObject;
  sort?: boolean | 'asc' | 'desc';
  disabled?: boolean;
  multiple: boolean;
};

export const SelectBusinessRaw = ({ multiple = false, disabled, libby, inputProps = {}, optionAll = false, sort, ...styles }: SelectBusinessTypeProps) => {
  const { data: dataRaw, working = true } = useLibbyCall(libby, {
    daoName: 'admin_account',
    methodName: 'getAccountWithSalesRoles'
  });

  const data = useMemo(
    () =>
      dataRaw.map(({ account_id, name, last_name }: budgetAccountProps) => ({
        id: account_id,
        value: `${name} ${last_name}`
      })),
    [dataRaw]
  );

  const onChangeAutoComplete = useCallback(
    (event, newValue: DataGenerateSelectType[]) => {
      inputProps.onChange(
        newValue.map((business: DataGenerateSelectType) => ({
          value: business.id,
          path: ''
        }))
      );
    },
    [inputProps]
  );

  return (
    <>
      {multiple ? (
        <Autocomplete
          disabled={disabled}
          style={{ display: 'inline-block', width: '100%' }}
          multiple
          options={data}
          id="tags-outlined"
          getOptionLabel={(option: { id: string; value: string }) => option.value}
          renderOption={(item) => {
            return (
              <div>
                <div>{item.value}</div>
                <span>ID: {item.id}</span>
              </div>
            );
          }}
          filterSelectedOptions
          onChange={onChangeAutoComplete}
          popupIcon={<SearchIcon />}
          renderInput={(params) => <TextField {...params} label="Comercial" variant="outlined" placeholder="" />}
        />
      ) : (
        <GeneralSelect disabled={disabled} working={working} label="Comercial" data={data} inputProps={inputProps} optionAll={optionAll} {...styles} />
      )}
    </>
  );
};

export const SelectBusiness = DatabaseConnector(SelectBusinessRaw)('admin_account');
