import React from 'react';
import { DatabaseConnector } from '@phinxlab/libby-rest-web';
import { useParams, useLocation } from 'react-router-dom';
import { LibbyObject } from '../../../../types/types';
import { useLibbyFetchById, useBreadcrumbs } from '../../../../hooks';
import { ProductDetailDataRaw } from './TabsViews/ProductDetailData';
import { FeaturesDetail } from './TabsViews/FeaturesDetail';
import { ScreenAligned } from '../../../../components/ScreenAligned/ScreenAligned';
import { TitleBreadcrumbs } from '../../../../interfaces';
import TabsComponent from './components/TabsComponent';
import { useMemo } from 'react';
import { useTranslation } from 'services/translation';
import { ImagesDetail } from './TabsViews/ImagesDetail';
import { UserProductPermissions } from '../../../../types/types';
import { useLibbyFetch } from '../../../../hooks';
import { AnyObject } from '../../../../types/types';

type ProductDetailProps = {
  libby: LibbyObject;
  title: TitleBreadcrumbs[];
  userPermissions: UserProductPermissions;
};

export type Tab = {
  title: string;
  component: React.ReactNode;
};

export type FeatureGroupsLibbyProps = {
  working: boolean;
  features: any[];
  addCreate: (data: object) => void;
  reFetch: () => void;
  updateData: (dataUpdate: AnyObject, id: string) => void;
  removeData: (id: string, idName: string) => void;
};

const ProductDetailRaw = ({ libby, title, userPermissions }: ProductDetailProps) => {
  const { id } = useParams<{ id: string }>();
  const { t } = useTranslation();
  const { state } = useLocation<{ goBack: TitleBreadcrumbs[] }>();
  const { working, data } = useLibbyFetchById(libby, {
    daoName: 'ster_product',
    id
  });

  const featuresFilter = useMemo(
    () => ({
      features: [
        {
          path: 'product.product_id',
          value: id,
          method: 'equals'
        }
      ]
    }),
    [id]
  );

  const {
    working: feat_working,
    data: feat_group_data,
    addCreate: addCreateFeature,
    reFetch: reFetchFeature,
    updateData: updateDataFeature,
    removeData: removeFeature
  } = useLibbyFetch(libby, { daoName: 'ster_product_feature_group', filter: featuresFilter });

  const featureGroupsLibbyFeats: FeatureGroupsLibbyProps = useMemo(
    () => ({ working: feat_working, features: feat_group_data, addCreate: addCreateFeature, reFetch: reFetchFeature, updateData: updateDataFeature, removeData: removeFeature }),
    [feat_working, feat_group_data, addCreateFeature, reFetchFeature, updateDataFeature, removeFeature]
  );
  const titleShow = useBreadcrumbs('Product', state && state?.goBack?.length ? state.goBack : title, `#${id}`);

  const tabs: Tab[] = useMemo(() => {
    return [
      {
        title: t('Product Information'),
        component: <ProductDetailDataRaw data={data} libby={libby} userPermissions={userPermissions} />
      },
      {
        title: t('Features'),
        component: <FeaturesDetail libby={libby} featuresLibbyValues={featureGroupsLibbyFeats} id={id} />
      },
      {
        title: t('Images'),
        component: <ImagesDetail />
      }
    ];
  }, [data, t, userPermissions, libby, featureGroupsLibbyFeats, id]);

  return (
    <ScreenAligned title={titleShow} working={working} showData={!!data}>
      <TabsComponent tabs={tabs} />
    </ScreenAligned>
  );
};

export const ProductDetail = DatabaseConnector(ProductDetailRaw)('ster_product', 'ster_product_feature_group', 'ster_product_feature', 'ster_product_copy_features', 'ster_product_bulk_features');
