import React, { FC, useCallback, useMemo, useState } from 'react';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { Box, Divider, IconButton, InputAdornment, makeStyles, Paper, Table, TableBody, TableCell, TableHead, TableRow, TextField, Tooltip } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Theme, useTheme } from '@material-ui/core/styles';
import { Add } from '@material-ui/icons';
import { useSnackbar } from 'notistack';
import InfoTable, { Column } from '../../../../../components/InfoTable';
import { PaymentMethodItem, ProductItem, ProductItemRow } from '../../context/BudgetContext/types';
import { useBudgetContext } from '../../context';
import { AddProductModal, ProductOption } from './components';
import { LeftAndRight } from '../../../../../../components/LeftAndRight';
import { useTranslation } from '../../../../../../services/translation';
import { format } from '../../../../../../util';
import { getMargenPercentage } from '../../utils';
import { getListPercentage } from '../../utils/getListPercentage';
import { getUnitPrice } from '../../utils/getUnitPrice';
import { getPercentage } from '../../utils/getPercentage';

const useStyles = makeStyles({
  title: {
    fontFamily: '"Oswald"',
    verticalAlign: 'baseline',
    textTransform: 'uppercase',
    fontWeight: 200,
    fontSize: '20px',
    color: '#4B4B4B',
    textAlign: 'start'
  }
});

interface Row extends ProductItemRow {
  product: ProductItem;
  productIndex: number;
  id: string;
  rowIndex: number;
  subtotal: number;
}

let maxPercentageId: NodeJS.Timeout;

export interface ProductTableProps {}

export const ProductTable: FC<ProductTableProps> = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const theme = useTheme<Theme>();
  const matches = useMediaQuery(theme.breakpoints.up(430));
  const { enqueueSnackbar } = useSnackbar();

  const { products, removeProductRow, addProductRow, editProductRow, paymentMethods, selectedClient, setValidatingProduct } = useBudgetContext();

  const [openModal, setOpenModal] = useState(false);

  const onQuantityEdit = useCallback(
    (row: Row, quantity: number) => {
      const newRow: ProductItemRow = {
        quantity: quantity < 0 ? 0 : quantity,
        paymentMethod: row.paymentMethod,
        priceUnit: row.priceUnit,
        percentage: row.percentage,
        priceList: row.priceList,
        netIncome: row.netIncome,
        discountPercentage: row.percentage,
        discountPercentageMAX: getMargenPercentage()
      };

      editProductRow(row.product.product, row.rowIndex, newRow);
    },
    [editProductRow]
  );

  const onPercentageEdit = useCallback(
    async (row: Row, _percentage: number) => {
      const percentage = _percentage > (await getListPercentage(row.priceList, row.netIncome)) ? await getListPercentage(row.priceList, row.netIncome) : _percentage;

      if (percentage === (await getListPercentage(row.priceList, row.netIncome))) {
        enqueueSnackbar(t('OverMAXPercentage'), { variant: 'warning' });
      }
      const newRow: ProductItemRow = {
        quantity: row.quantity,
        paymentMethod: row.paymentMethod,
        // @ts-ignore
        priceUnit: await getUnitPrice(row.priceList, percentage),
        percentage,
        priceList: row.priceList,
        netIncome: row.netIncome,
        discountPercentage: percentage,
        discountPercentageMAX: await getListPercentage(row.priceList, row.netIncome)
      };

      editProductRow(row.product.product, row.rowIndex, newRow);
    },
    [editProductRow, t, enqueueSnackbar]
  );

  const onPriceUnitEdit = useCallback(
    async (row: Row, _priceUnit: number) => {
      setValidatingProduct(true);
      let priceUnit = _priceUnit;
      let percentage = await getPercentage(row.priceList, priceUnit);
      if (percentage > (await getListPercentage(row.priceList, row.netIncome))) {
        percentage = await getListPercentage(row.priceList, row.netIncome);
      }
      if (maxPercentageId) {
        clearTimeout(maxPercentageId);
      }
      // esto agrega un debounce al cambio de precio
      maxPercentageId = setTimeout(async () => {
        if (percentage > (await getListPercentage(row.priceList, row.netIncome))) {
          enqueueSnackbar(t('OverMAXPercentage'), { variant: 'warning' });
          priceUnit = await getUnitPrice(row.priceList, percentage);
        }
        const newRow: ProductItemRow = {
          quantity: row.quantity,
          paymentMethod: row.paymentMethod,
          priceUnit: await getUnitPrice(row.priceList, percentage),
          percentage,
          priceList: row.priceList,
          netIncome: row.netIncome,
          discountPercentage: percentage,
          discountPercentageMAX: await getListPercentage(row.priceList, row.netIncome)
        };
        editProductRow(row.product.product, row.rowIndex, newRow);
        setValidatingProduct(false);
      }, 2000);

      const newRow: ProductItemRow = {
        quantity: row.quantity,
        paymentMethod: row.paymentMethod,
        priceUnit,
        percentage,
        priceList: row.priceList,
        netIncome: row.netIncome,
        discountPercentage: percentage,
        discountPercentageMAX: await getListPercentage(row.priceList, row.netIncome)
      };
      editProductRow(row.product.product, row.rowIndex, newRow);
    },
    [editProductRow, t, enqueueSnackbar, setValidatingProduct]
  );

  const rows = useMemo<Row[]>(() => {
    const _rows: Row[] = [];
    products.forEach((product, productIndex) => {
      product.rows.forEach((row, rowIndex) => {
        _rows.push({
          ...row,
          product,
          productIndex,
          id: (_rows.length + 1).toString(),
          rowIndex,
          subtotal: row.priceUnit * row.quantity
        });
      });
    });
    return _rows;
  }, [products]);

  const quantityRender = useCallback(
    (row: Row) => (
      <TextField
        variant="standard"
        type="number"
        value={row.quantity}
        onChange={(event) => {
          onQuantityEdit(row, +event.target.value);
        }}
        InputLabelProps={{ shrink: true }}
        InputProps={{
          style: { width: '70%' }
        }}
      />
    ),
    [onQuantityEdit]
  );

  const percentageRender = useCallback(
    (row: Row) => (
      <Tooltip title={row.percentage === row.discountPercentageMAX ? t('MAXPercentage') : t('percentageExplained')}>
        <TextField
          variant="standard"
          type="number"
          value={parseFloat(row.percentage.toFixed(2))}
          onChange={(event) => {
            onPercentageEdit(row, +event.target.value);
          }}
          InputLabelProps={{ shrink: true }}
          InputProps={{
            endAdornment: <InputAdornment position="end">%</InputAdornment>,
            style: { width: '70%', color: row.percentage > 0 ? 'orangered' : undefined }
          }}
        />
      </Tooltip>
    ),
    [onPercentageEdit, t]
  );

  const priceRender = useCallback(
    (row: Row) => (
      <Tooltip title={row.percentage > getMargenPercentage() ? t('OverMAXPercentage') : ''}>
        <TextField
          required
          variant="standard"
          type="number"
          value={parseFloat(row.priceUnit.toFixed(2))}
          onChange={(event) => {
            onPriceUnitEdit(row, +event.target.value);
          }}
          InputLabelProps={{ shrink: true }}
          InputProps={{
            style: { width: '70%', color: row.percentage > 0 ? 'orangered' : undefined }
          }}
        />
      </Tooltip>
    ),
    [onPriceUnitEdit, t]
  );

  const columns = useMemo<Array<Column>>(() => {
    return [
      {
        id: 'sku',
        label: 'SKU',
        noSort: true,
        render: (row: Row) => row.product.product.sku
      },
      {
        id: 'name',
        label: 'Name',
        noSort: true,
        render: (row: Row) => row.product.product.name
      },
      {
        id: 'quantity',
        label: 'Quantity',
        noSort: true,
        render: (row: Row) => quantityRender(row)
      },
      {
        id: 'paymentMethod',
        label: 'Method',
        noSort: true,
        render: (row: Row) => {
          const method = paymentMethods.find((item) => item.code === row.paymentMethod.code);
          return `${method?.name} (${method?.paymentMethod.PaymentMethod.days})`;
        }
      },
      {
        id: 'priceList',
        label: 'List Price',
        noSort: true,
        render: (row: Row) => format(parseFloat(row.priceList.toFixed(2)), paymentMethods[0]?.Currency?.currency_symbol || 'ARS')
      },
      {
        id: 'percentage',
        label: matches ? '% discount' : '%',
        noSort: true,
        render: (row: Row) => percentageRender(row)
      },
      {
        id: 'priceUnit',
        label: 'Unit Price',
        noSort: true,
        render: (row: Row) => priceRender(row)
      },
      {
        id: 'subtotal',
        label: 'Subtotal',
        noSort: true,
        render: (row: Row) => format(row.subtotal, paymentMethods[0]?.Currency?.currency_symbol || 'ARS'),
        style: { minWidth: 150 }
      },
      {
        id: 'actions',
        label: '',
        noSort: true,
        render: (row: Row) => (
          <Button color="primary" type="button" onClick={() => removeProductRow(row.product.product, row.rowIndex)}>
            <DeleteIcon />
          </Button>
        )
      }
    ];
  }, [removeProductRow, paymentMethods, priceRender, quantityRender, percentageRender, matches]);

  const onConfirm = useCallback(
    (product: ProductOption, method: PaymentMethodItem) => {
      const row: ProductItemRow = {
        quantity: 0,
        paymentMethod: method,
        priceList: product?.odoo?.price.price || 0,
        percentage: 0,
        priceUnit: product?.odoo?.price.price || 0,
        netIncome: product?.odoo?.price.net_income || 0,
        discountPercentage: 0,
        discountPercentageMAX: getMargenPercentage()
      };

      addProductRow(product, row);

      setOpenModal(false);
    },
    [addProductRow]
  );

  const onAddProduct = useCallback(() => {
    if (Object.keys(selectedClient).length !== 0 && typeof selectedClient.tier?.id !== 'boolean') {
      setOpenModal(true);
    } else {
      enqueueSnackbar(t(typeof selectedClient.tier?.id !== 'boolean' ? 'The $$$$ field is required' : 'The selected client does not have a configured tier').replace('$$$$', t('Client').toLowerCase()), { variant: 'error' });
    }
  }, [enqueueSnackbar, selectedClient, t]);

  return (
    <div style={{ marginTop: 16 }}>
      <LeftAndRight
        left={
          <Typography variant="h1" className={classes.title}>
            {t('Products')}
          </Typography>
        }
        right={
          matches ? (
            <div style={{ textAlign: 'right', padding: 8 }}>
              <Button type="button" variant="contained" color="primary" onClick={onAddProduct}>
                {t('Add')}
              </Button>
            </div>
          ) : (
            <IconButton onClick={onAddProduct}>
              <Add style={{ width: '40px', height: '40px' }} color="primary" />
            </IconButton>
          )
        }
        rightProps={{ style: { display: 'flex', flexDirection: 'column', alignItems: 'flex-end' } }}
        leftProps={{ style: { display: 'flex', flexDirection: 'column', justifyContent: 'center' } }}
      />

      {!matches ? (
        <Table style={{ boxShadow: '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)', borderRadius: '4px' }}>
          <TableHead>
            <TableRow>
              <TableCell>{t('Products')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <Paper style={{ minHeight: '40px', backgroundColor: 'white', maxWidth: 'calc(100%-40px)' }}>
              {rows.map((row) => {
                const method = paymentMethods.find((item) => item.code === row.paymentMethod.code);

                return (
                  <TableRow>
                    <Divider />
                    <Box m={3}>
                      <Box display="flex">
                        <Box>
                          <Typography>
                            <strong>{row.product.product.name}</strong>
                          </Typography>
                        </Box>

                        <Box display="flex" justifyContent="center" alignItems="center">
                          <Button color="primary" type="button" onClick={() => removeProductRow(row.product.product, row.rowIndex)} style={{ padding: 0 }}>
                            <DeleteIcon />
                          </Button>
                        </Box>
                      </Box>

                      <Box mt={2}>
                        <Box display="flex" justifyContent="space-between">
                          <Box width="30%">
                            <Typography>
                              <strong>SKU</strong>
                            </Typography>
                            <Typography>{row.product.product.sku}</Typography>
                          </Box>
                          <Box width="40%">
                            <Typography>{t('List Price')}</Typography>
                            <Typography>{format(parseFloat(row.priceList.toFixed(2)), paymentMethods[0]?.Currency?.currency_symbol || 'ARS')}</Typography>
                          </Box>
                          <Box width="30%">
                            <Typography>
                              <strong>{t('Method')}</strong>
                            </Typography>
                            <Typography>{`${method?.name} (${method?.days})`}</Typography>
                          </Box>
                        </Box>

                        <Box display="flex" justifyContent="space-between" mt={2}>
                          <Box width="30%">
                            <Typography>
                              <strong>{t('Quantity')}</strong>
                            </Typography>
                            {quantityRender(row)}
                          </Box>
                          <Box width="40%">
                            <Typography>
                              <strong>{t('L. Price')}</strong>
                            </Typography>
                            {priceRender(row)}
                          </Box>
                          <Box width="30%">
                            <Typography>
                              <strong>{t('Discount')}</strong>
                            </Typography>
                            {percentageRender(row)}
                          </Box>
                        </Box>

                        <Box mt={2}>
                          <h3 style={{ color: '#FF5179' }}>{format(row.subtotal, paymentMethods[0]?.Currency?.currency_symbol || 'ARS')}</h3>
                        </Box>
                      </Box>
                    </Box>
                  </TableRow>
                );
              })}
            </Paper>
          </TableBody>
        </Table>
      ) : (
        <InfoTable columns={columns} rows={rows} rowIdKey="id" height="auto" tableContainerStyles={{ minHeight: '140px' }} />
      )}
      <AddProductModal open={openModal} onCancel={() => setOpenModal(false)} onConfirm={onConfirm} />
    </div>
  );
};
