export const DispatchState: { [k: string]: string } = {
  1: 'Pending',
  2: 'Sent',
  3: 'Cancelled',
  4: 'Ready',
  6: 'Preparing'
};

export enum DISPATCH_STATE {
  PENDING = '1',
  SENT = '2',
  CANCELLED = '3',
  READY = '4',
  PREPARING = '6'
}
