import React, { useState } from 'react';
import { Box, Collapse, IconButton, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { isArray } from 'lodash';
// import { RowsType } from '../../utils/tables';
import { useTranslation } from '../../services/translation';

const useStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset'
    }
  },
  rowNotPadding: {
    height: '30px',
    color: 'red',
    '& td.MuiTableCell-root': {
      color: (value: any) => value.rowBackground || 'none'
    }
  },
  rowPadding: {
    '& td.MuiTableCell-root': {
      color: (value: any) => value.rowBackground || 'none'
    }
  },
  colapseTable: {
    marginLeft: '20px'
  }
});

type InfoTableRowType = {
  key: string;
  onClick?: (row: any) => void;
  onColapseRowClick?: (row: any, rowColapse: any) => void;
  onDoubleClick?: (row: any) => void;
  padding?: boolean;
  row: any;
  rowBackground?: string;
  columns: Array<any>;
  primaryCollapsible?: string;
};

export const InfoCollapsibleTableRow = ({ key, onClick, onColapseRowClick, onDoubleClick, padding, row, rowBackground, columns, primaryCollapsible }: InfoTableRowType) => {
  const { t } = useTranslation();
  const classes = useStyles({ rowBackground });
  const [open, setOpen] = useState(false);

  const collapseColums: Array<string> = columns.find((item) => isArray(item));

  const values: Array<any> = [Object.values(row), Object.keys(row)];

  return (
    <>
      <TableRow hover role="checkbox" tabIndex={-1} key={key} onClick={onClick && (() => onClick(row))} onDoubleClick={onDoubleClick && (() => onDoubleClick(row))} className={!padding ? classes.rowNotPadding : classes.rowPadding}>
        {primaryCollapsible && (
          <TableCell>
            <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
        )}
        {values[0].map(
          (rowValues: any, index: number) => typeof rowValues !== 'object' && <TableCell align={values[1][index] === 'amount_objective' || values[1][index] === 'amount_sale' ? 'right' : 'center'}>{t(rowValues)}</TableCell>
        )}
      </TableRow>
      {primaryCollapsible && open && (
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box margin={1}>
                <Table size="small" aria-label="purchases">
                  {collapseColums && (
                    <TableHead>
                      <TableRow>
                        {collapseColums.map((column: string) => (
                          <TableCell align="center">{t(column)}</TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                  )}
                  <TableBody>
                    {row[primaryCollapsible].map((rowColapse: any) => (
                      <TableRow key="" onClick={onColapseRowClick && (() => onColapseRowClick(row, rowColapse))}>
                        {Object.values(rowColapse).map((item: any, index: number) => (
                          <TableCell align={Object.keys(rowColapse)[index] === 'amount_objective' || Object.keys(rowColapse)[index] === 'amount_sale' ? 'right' : 'center'}>{item}</TableCell>
                        ))}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      )}
    </>
  );
};
