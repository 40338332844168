export const columns = [
  {
    id: 'courier_id',
    label: 'ID',
    orderById: 'courier_id'
  },
  {
    id: 'name',
    label: 'Name'
  },
  {
    id: 'edit',
    label: 'Edit',
    maxWidth: 200,
    noSort: true
  }
];
