import React, { useCallback, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { Button, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import AssignmentIcon from '@material-ui/icons/Assignment';
import EditIcon from '@material-ui/icons/Edit';
import { ZipFormDialog } from '../../components/CityFormDialog';
import { getValidate, formInitialValues } from '../../utils/model';
import { MakeCell } from '../../../../components/MakeCell';
import CustomModal from '../../../../../services/customFormDialog';
import { useTranslation } from '../../../../../services/translation';
import { AnyObject, LibbyObject } from '../../../../../types/types';
import { formatDecimal } from '../../../../../utils';

const CityModal = CustomModal(ZipFormDialog);

const useStyles = makeStyles({
  buttonsCell: {
    display: 'flex',
    flexWrap: 'nowrap'
  }
});

export const useMakeCityTable = (dataFiltered: AnyObject, libby: LibbyObject, reFetch: Function) => {
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const classes = useStyles();

  const pushCitiesDetail = useCallback((city_id: number) => history.push(`cities/detail/${city_id}`), [history]);

  const handleOpenCityModal = useCallback(
    async (currentCity?: AnyObject) => {
      const paramsModal = {
        confirmText: t(currentCity ? 'Update' : 'Create'),
        cancelText: t('Cancel'),
        title: t(currentCity ? 'Update Location' : 'Create Location'),
        validate: getValidate(['state_id', 'name', 'zip', 'list', 'matches']),
        formInitialValues: currentCity
          ? {
              ...currentCity,
              state_id: String(currentCity.state.state_id),
              matches: currentCity.matches ?? ''
            }
          : formInitialValues
      };

      try {
        const { state_id, zip, list, name, matches, enabled }: AnyObject = (await CityModal.show(paramsModal)) as AnyObject;
        const formattedMatch =
          typeof matches !== 'string'
            ? matches
            : matches.split(',').reduce((accumulator: string, currentValue: string, index: number, array: Array<string>) => `${accumulator}${currentValue.trim()}${index === array.length - 1 ? '}' : ','}`, '{');

        if (zip) {
          try {
            await libby.ster_city.save({
              ...(currentCity && { ...currentCity }),
              state: { state_id },
              zip,
              list: Array.isArray(list) ? list : list?.split(','),
              name,
              matches: formattedMatch,
              enabled
            });
            reFetch();
            enqueueSnackbar(t(currentCity ? 'Location updated' : 'Location created'), { variant: 'success' });
          } catch (e: any) {
            enqueueSnackbar(t('Something is wrong'), { variant: 'error' });
          }
        }
      } catch (e: any) {
        // modal close
      }
    },
    [enqueueSnackbar, libby.ster_city, t, reFetch]
  );

  const columns = useMemo(
    () => [
      {
        id: 'city_id',
        label: 'ID'
      },
      {
        id: 'city',
        label: 'Location',
        orderById: 'name'
      },
      {
        id: 'state',
        label: 'Province',
        orderById: 'state.name'
      },
      {
        id: 'zip',
        label: 'Zip'
      },
      {
        id: 'list',
        label: 'Zip List'
      },
      {
        id: 'matches',
        label: 'Matches'
      },

      {
        id: 'detail',
        label: 'Detail',
        noSort: true
      }
    ],
    []
  );

  const rows = useMemo(
    () =>
      dataFiltered.map((city: AnyObject) => ({
        id: formatDecimal(city.city_id),
        city_id: formatDecimal(city.city_id),
        city: city.name,
        state: city.state.name,
        zip: city.zip,
        list: city?.list?.join(', '),
        matches: city?.matches?.join(', '),
        detail: (
          <div className={classes.buttonsCell}>
            <MakeCell
              label=""
              icon={AssignmentIcon}
              onClick={(e) => {
                e.stopPropagation();
                pushCitiesDetail(city.city_id);
              }}
            />
            <MakeCell
              label=""
              icon={EditIcon}
              onClick={(e) => {
                e.stopPropagation();
                handleOpenCityModal(city);
              }}
            />
          </div>
        )
      })),
    [dataFiltered, pushCitiesDetail, handleOpenCityModal, classes]
  );

  const CityButtons = (
    <>
      <Grid item>
        <Button type="button" onClick={() => handleOpenCityModal()} href="" variant="contained" color="primary">
          {t('Create Location')}
        </Button>
      </Grid>
    </>
  );

  return {
    columns,
    rows,
    CityButtons
  };
};
