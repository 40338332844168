import { useCallback, useMemo, useState } from 'react';
import moment from 'moment';
import { Order_reporting_goal_sales, Order_reporting_goal_sales_all, Seles_execution, Weeks_information, Seles_execution_by_weeks, Objective_period } from '../../../../../interfaces/business';
import { FilterBarSelectionReportingObjectiveSales } from '../type';
import { format } from '../../../../../util';
import { useTranslation } from '../../../../../services/translation';
import { useReportingObjectiveSalesLibbyCall } from '../../../../../business/orders/order/ReportingObjectSales';
import { useObjectivePeriodLibbyCall } from '../../../../../business/ObjectivePeriod';
import { UpdateObjectiveForm } from '../components/UpdateObjectiveForm';
import CustomModal from '../../../../../services/customFormDialog';
import { useGlobalContext } from '../../../../../hooks';
import { RowsType, userInfoType } from '../../../../../types';

type useReportingObjectiveSaleCategoryLogicProps = {
  filterInit: FilterBarSelectionReportingObjectiveSales;
  filter?: FilterBarSelectionReportingObjectiveSales;
};

type TotalGol = { objective: number; reached: number };

const initTotalGoal = {
  objective: 0,
  reached: 0
};

const UpdateObjectiveModal = CustomModal(UpdateObjectiveForm);

export const useReportingObjectiveSaleCategoryLogic = ({ filterInit }: useReportingObjectiveSaleCategoryLogicProps) => {
  const { t } = useTranslation();
  const [filterSales, setFilterSales] = useState({ week: 0, day: '', category: '' });

  const {
    userInfo
  }: {
    userInfo: userInfoType;
  } = useGlobalContext();

  const { data, working, recall } = useReportingObjectiveSalesLibbyCall<Order_reporting_goal_sales_all>({
    methodName: 'getByDateOrderReportingObjectiveOfflineSales',
    params: [filterInit]
  });

  const { data: dataPeriods, working: workingPeriods = true } = useObjectivePeriodLibbyCall<Objective_period>({
    methodName: 'getAll'
  });

  const statisticsProductsCategory = useMemo(
    () =>
      data?.goal_product_monetary
        ? data?.goal_product_monetary.reduce((result: any[], element: Order_reporting_goal_sales) => {
            if (element.name) {
              result.push({
                name: element.name,
                objective: element.value_goal,
                reached: element.total,
                'percentage reached': `${((element.total * 100) / element.value_goal).toFixed(2)}%`
              });
            }
            return result;
          }, [])
        : [],
    [data]
  );

  const dataFormatPayload = [
    {
      name: 'reached',
      format: (value: any) => format(value, 'ARS', t)
    },
    {
      name: 'objective',
      format: (value: any) => format(value, 'ARS', t)
    }
  ];

  const statisticsQuantityCategory = useMemo(
    () =>
      data?.goal_product_unit.length
        ? data?.goal_product_unit.reduce((result: any[], element: Order_reporting_goal_sales) => {
            if (element.name) {
              result.push({
                name: element.name,
                objective: element.value_goal,
                reached: element.total,
                'percentage reached': `${((element.total * 100) / element.value_goal).toFixed(2)}%`
              });
            }
            return result;
          }, [])
        : [],
    [data]
  );
  const statisticsProductCanalWithTotal = useMemo(
    () =>
      data?.goal_product_unit.length
        ? data?.goal_product_unit.reduce((dataTotal: TotalGol, element: Order_reporting_goal_sales) => {
            return {
              objective: dataTotal.objective + element.value_goal,
              reached: dataTotal.reached + element.total
            };
          }, initTotalGoal)
        : initTotalGoal,
    [data]
  );

  const statisticsReportingObjectiveSaleCanal = useMemo(
    () =>
      data?.goal_marketplace_monetary && data?.goal_marketplace_monetary.length
        ? data?.goal_marketplace_monetary.reduce((result: any[], element: Order_reporting_goal_sales) => {
            if (element.name) {
              result.push({
                name: element.name,
                objective: element.value_goal,
                reached: element.total,
                'percentage reached': `${((element.total * 100) / element.value_goal).toFixed(2)}%`
              });
            }
            return result;
          }, [])
        : [],
    [data]
  );

  const statisticsProductsMonetaryAllTotal = useMemo(() => {
    return statisticsProductsCategory?.reduce(
      (total, statisticReportingObjectiveSaleCanal) => {
        return {
          objective: total.objective + statisticReportingObjectiveSaleCanal.objective,
          reached: total.reached + statisticReportingObjectiveSaleCanal.reached
        };
      },
      {
        objective: 0,
        reached: 0
      }
    );
  }, [statisticsProductsCategory]);

  const statisticsReportingObjectiveSaleUser = useMemo(
    () =>
      data?.goal_user_monetary && data?.goal_user_monetary.length
        ? data?.goal_user_monetary.reduce((result: any[], element: Order_reporting_goal_sales) => {
            if (element.name) {
              result.push({
                name: element.name,
                objective: element.value_goal,
                reached: Number(element.total.toFixed(2)),
                'percentage reached': `${((element.total * 100) / element.value_goal).toFixed(2)}%`,
                users: element.users
              });
            }
            return result;
          }, [])
        : [],
    [data]
  );

  const statisticsUsersMonetary = useMemo(() => {
    const dataStatisticsCategory = statisticsReportingObjectiveSaleUser?.filter((item) => item.users.filter((itemUser: any) => itemUser.toString() === userInfo?.id?.toString()).length)[0];

    return {
      objective: dataStatisticsCategory ? dataStatisticsCategory.objective : 0,
      reached: dataStatisticsCategory ? dataStatisticsCategory.reached : 0
    };
  }, [userInfo, statisticsReportingObjectiveSaleUser]);

  const dataFormatPayloadCanal = [
    {
      name: 'reached',
      format: (value: string) => format(value, 'ARS', t)
    },
    {
      name: 'objective',
      format: (value: string) => format(value, 'ARS', t)
    }
  ];

  const handleOnOpenUpdateObjective = useCallback(async () => {
    try {
      await UpdateObjectiveModal.show({
        confirmText: t('Update'),
        cancelText: t('Cancel'),
        title: t('Update Objective')
      });
    } catch (error) {
      // nothing
    }
  }, [t]);

  const rows: RowsType[] = useMemo(() => {
    let dataSales = data?.sales_execution as Seles_execution[];
    if ((filterSales.week !== 0 || filterSales.day !== '') && filterSales.category !== '') {
      dataSales = data?.sales_execution_by_weeks?.filter(
        (item) => (item.week.toString() === filterSales.week.toString() || item.week === filterSales.day) && item.product_category === filterSales.category
      ) as Seles_execution_by_weeks[];
    }

    return dataSales ? dataSales.map((item) => ({ id: '', ...item })) : [];
  }, [data, filterSales]);

  const rowsWeeks: Weeks_information[] = useMemo(() => {
    return data?.weeks_information as Weeks_information[] | [];
  }, [data]);

  const DateFilter = useMemo(() => {
    const months = dataPeriods?.months.map((year) => {
      const monthsYear = year.months.map((month) => {
        const monthName = moment(`${year.year}-${month}-01`).format('MMMM');

        return { monthNumber: month, monthName: monthName.charAt(0).toUpperCase() + monthName.slice(1) };
      });

      return { year: year.year, months: monthsYear };
    });

    const weeks = dataPeriods?.weeks.map((week) => {
      const monthName = moment(`${week.year}-${week.month}-01`).format('MMMM');

      return { ...week, monthName: monthName.charAt(0).toUpperCase() + monthName.slice(1) };
    });

    return {
      years: dataPeriods?.years,
      months,
      weeks,
      users: dataPeriods?.users
    };
  }, [dataPeriods]);

  return {
    working,
    data,
    recall,
    statisticsProductsCategory,
    dataFormatPayload,
    statisticsQuantityCategory,
    statisticsProductCanalWithTotal,
    dataFormatPayloadCanal,
    statisticsReportingObjectiveSaleCanal,
    handleOnOpenUpdateObjective,
    statisticsProductsMonetaryAllTotal,
    statisticsReportingObjectiveSaleUser,
    statisticsUsersMonetary,
    salesExecutions: rows,
    weeksInformation: rowsWeeks,
    setFilterSales,
    DateFilter,
    DateFilterWorking: workingPeriods
  };
};
