import React, { useMemo } from 'react';
import { Grid } from '@material-ui/core';
import { DatabaseConnector } from '@phinxlab/libby-rest-web';
import { makeStyles } from '@material-ui/styles';
import { SummaryCard } from './SummaryCard';
import { LibbyObject } from '../../../../../types';
import { useLibbyFetch } from '../../../../../hooks';

const useStyles = makeStyles(() => ({
  container: {
    '@media (max-width: 550px)': {
      flexDirection: 'column'
    }
  }
}));
const paramsFetch = {
  daoName: 'ster_order_total_time',
  orderBy: 'alertstate'
};

type SummaryBoardProps = {
  libby: LibbyObject;
  setFilter: (makeFilter: object) => void;
};

export const SummaryBoardRaw = ({ libby, setFilter }: SummaryBoardProps) => {
  const classes = useStyles();
  const { data, working } = useLibbyFetch(libby, paramsFetch);
  const dataFormatted = useMemo(
    () =>
      data.reduce(
        (accum, { alertstate, quantity }) => ({
          ...accum,
          [alertstate]: parseInt(quantity, 10),

          total: accum.total + parseInt(quantity, 10)
        }),
        { total: 0 }
      ),
    [data]
  );

  return (
    <Grid container justify="flex-start" wrap="wrap" className={classes.container}>
      <SummaryCard working={working} type="OK" quantity={dataFormatted.OK} total={dataFormatted.total} setFilter={setFilter} />
      <SummaryCard working={working} type="Warning" quantity={dataFormatted.Warning} total={dataFormatted.total} setFilter={setFilter} />
      <SummaryCard working={working} type="Critical" quantity={dataFormatted.Critical} total={dataFormatted.total} setFilter={setFilter} />
    </Grid>
  );
};
export const SummaryBoard = DatabaseConnector(SummaryBoardRaw)('ster_order_total_time');
