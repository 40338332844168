import React, { useMemo, useCallback } from 'react';
import { DatabaseConnector } from '@phinxlab/libby-rest-web';
import { Autocomplete } from '@material-ui/lab';
import { TextField } from '@material-ui/core';
import { orderBy } from 'lodash';
import { JsxFragment } from 'typescript';
import { AnyObject, LibbyObject } from '../../types/types';
import { OptionsLibbyFetch, useLibbyFetch } from '../../hooks';
import { useTranslation } from '../../services/translation';
import { AllMarketplace } from '../../models/allMarketplace';
import { subFilter } from '../../types';
import { Marketplace } from '../../interfaces/business';

type SelectMarketPlaceTypeProps = {
  onChange: (value: subFilter[]) => void;
  libby: LibbyObject;
  allMarketplace: any;
  order?: boolean | 'asc' | 'desc';
  inputProps: JsxFragment | undefined;
};

const paramsFetch: OptionsLibbyFetch = {
  daoName: 'ster_marketplace'
};

export const SelectAllMarketPlaceRaw = ({ onChange, libby, allMarketplace, order = 'asc', inputProps }: SelectMarketPlaceTypeProps) => {
  const { data: dataRaw }: { data: AnyObject; working: boolean } = useLibbyFetch(libby, paramsFetch);

  const data = useMemo(
    () =>
      orderBy(
        dataRaw.map(({ marketplace_id, name }: Marketplace) => ({
          id: marketplace_id,
          name
        })),
        ['name'],
        [order]
      ),
    [dataRaw, order]
  );
  const { t } = useTranslation();

  const onChangeAutoComplete = useCallback(
    (event, newValue: AllMarketplace[]) => {
      onChange(
        newValue.map((marketplace: AllMarketplace) => ({
          value: marketplace.id,
          path: '',
          name: marketplace.name
        }))
      );
    },
    [onChange]
  );

  const autocompleteValue = useMemo(
    () =>
      allMarketplace?.length
        ? allMarketplace.map((marketplace: any) => ({
            id: marketplace.value,
            name: dataRaw.find(({ marketplace_id }: Marketplace) => marketplace_id === marketplace.value).name
          }))
        : [],
    [allMarketplace, dataRaw]
  );
  // const orderData = orderBy(data, ['name'], [order])

  const getOptionLabel = useCallback((option: any) => option.name, []);

  return (
    <Autocomplete
      value={autocompleteValue}
      style={{ display: 'inline-block', width: '100%' }}
      multiple
      id="tags-outlined"
      options={data}
      getOptionLabel={getOptionLabel}
      filterSelectedOptions
      onChange={onChangeAutoComplete}
      getOptionSelected={(option: any, _value: any) => option.id === _value.id}
      renderInput={(params) => {
        const { InputProps } = params;
        InputProps.startAdornment = inputProps;
        return <TextField {...params} label={t('All marketplace')} placeholder="" />;
      }}
    />
  );
};

export const SelectAllMarketPlace = DatabaseConnector(SelectAllMarketPlaceRaw)('ster_marketplace');
