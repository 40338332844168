import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Button } from '@material-ui/core';
import { InformationSection } from '../../../../../components/InformationSection';
import { useTranslation } from '../../../../../services/translation';
import { AnyObject } from '../../../../../types/types';

type ButtonContainerProps = {
  status: number;
  loading: boolean;
  handleOnSaveImport: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
  handleOnProcessImport: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
  handleOnRejectOrder: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
  handleOnAcceptOrder: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
  someFileWithoutTempAttachments: boolean;
  someFileReadyToSave: boolean;
  loadingSaveImport: boolean;
};

const useStyles = makeStyles(() => ({
  buttonContainer: {
    marginTop: 15,
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    '& > button': {
      marginRight: 10
    }
  }
}));

// TODO: refactorizar esto
export const ButtonContainer = ({
  status,
  loading,
  handleOnSaveImport,
  handleOnProcessImport,
  someFileWithoutTempAttachments,
  someFileReadyToSave,
  handleOnRejectOrder,
  handleOnAcceptOrder,
  loadingSaveImport
}: ButtonContainerProps) => {
  const { t } = useTranslation();
  const classes: AnyObject = useStyles();
  let ButtonReturn = <></>;

  if (status === 1) {
    ButtonReturn = someFileWithoutTempAttachments ? (
      <>
        <Button variant="contained" color="primary" type="submit" href="" disabled={loading || !someFileReadyToSave || loadingSaveImport} onClick={handleOnSaveImport}>
          {t('Save Files')}
        </Button>
      </>
    ) : (
      <Button variant="contained" color="primary" type="button" disabled={loading || loadingSaveImport} href="" onClick={handleOnProcessImport}>
        {t('Process Import')}
      </Button>
    );
  } else if (status === 4) {
    ButtonReturn = (
      <div className={classes.buttonContainer}>
        <Button variant="contained" color="primary" type="submit" href="" disabled={loading} onClick={handleOnAcceptOrder}>
          {t('Accept')}
        </Button>

        <Button variant="contained" type="submit" href="" disabled={loading} onClick={handleOnRejectOrder}>
          {t('Cancel')}
        </Button>
      </div>
    );
  }

  return (
    <InformationSection title="">
      <div className={classes.buttonContainer}>{ButtonReturn}</div>
    </InformationSection>
  );
};
