import React from 'react';
import { StorePickupTable } from './Components/StorePickupTable';
import { ScreenAligned } from '../../../components/ScreenAligned/ScreenAligned';
import { AnyObject } from '../../../types';
import { TitleBreadcrumbs } from '../../../interfaces';

type HeadCellsType = {
  id: string;
  label: string;
  translate?: boolean;
  render?: (row: any, item: any, t: (str: string) => string) => React.ReactNode;
  [k: string]: any;
};

type RowsType = {
  id: string;
  [k: string]: any;
};

export interface OrderListProps {
  detailUrl: string;
  setFilter: (makeFilter: object) => void;
  filter: AnyObject;
  working: boolean;
  rows: Array<RowsType>;
  columns: Array<HeadCellsType>;
  fetchMore: () => void;
  reFetch?: () => void;
  onSortChange?: (orderBy: string, direction: 'asc' | 'desc') => void;
  orderBy?: string;
  direction?: 'asc' | 'desc';
  title: TitleBreadcrumbs[];
  allButtonsAction?: React.ReactElement;
  onRowClick?: (row: RowsType) => void;
  canalOffLine?: boolean;
  resetFilter: () => void;
}

export const StorePickupList = ({ setFilter, resetFilter, rows, columns, working, filter, fetchMore, reFetch, onSortChange, orderBy, direction, title, allButtonsAction, onRowClick }: OrderListProps) => (
  <ScreenAligned title={title} additionalTitle={allButtonsAction}>
    <StorePickupTable
      columns={columns}
      rows={rows}
      working={working}
      filter={filter}
      setFilter={setFilter}
      fetchMore={fetchMore}
      onSortChange={onSortChange}
      orderBy={orderBy}
      direction={direction}
      onRowClick={onRowClick}
      reFetch={reFetch}
      resetFilter={resetFilter}
    />
  </ScreenAligned>
);
