import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
  titleContainer: {
    marginTop: 32,
    marginBottom: 32,
    gap: '32px'
  },
  titleContainerDownMd: {
    marginBottom: 32,
    gap: '32px'
  },
  divTitleIcon: {
    flexDirection: 'column'
  },
  divTitle: {
    marginTop: '32px'
  },
  title: {
    fontFamily: '"Oswald"',
    verticalAlign: 'baseline',
    textTransform: 'uppercase',
    fontWeight: 600,
    fontSize: '24px',
    color: '#4B4B4B'
  },
  bread: {
    fontFamily: '"Oswald"',
    verticalAlign: 'baseline',
    textTransform: 'uppercase',
    fontWeight: 100,
    fontSize: '11px',
    color: '#4B4B4B'
  },
  titleMobile: {
    fontFamily: '"Oswald"',
    verticalAlign: 'baseline',
    textTransform: 'uppercase',
    fontWeight: 600,
    fontSize: '20px',
    color: '#4B4B4B'
  },
  titleBackMobile: {
    fontFamily: '"Oswald"',
    verticalAlign: 'baseline',
    textTransform: 'uppercase',
    fontWeight: 600,
    color: 'black',
    fontSize: '17px'
  },
  arrowIcon: {
    color: '#FF5179'
  }
}));

export const breadcrumbsStyles = makeStyles({
  root: {
    lineHeight: '28.8px',
    fontWeight: 600,
    fontSize: '24px',
    '& .MuiBreadcrumbs-separator': {
      color: '#4B4B4B',
      fontWeight: 100,
      fontSize: '17px'
    },
    '& .MuiBreadcrumbs-ol': {
      alignItems: 'flex-start'
    },
    '& .MuiBreadcrumbs-li': {
      '& .MuiGrid-root': {
        '& > a': {
          textDecoration: 'none'
        },
        '& > a > h1': {
          color: '#4B4B4B',
          verticalAlign: 'baseline',
          textTransform: 'uppercase'
        }
      }
    }
  }
});
