import React from 'react';
import { Screen } from '../../components/Screen';
import { LoginForm } from './components/LoginForm';
import { Alerts } from '../../components/Alerts';

export const Login = () => (
  <Screen maxWidth="xs">
    <Alerts />
    <LoginForm />
  </Screen>
);
