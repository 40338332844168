import { makeValidator, ValidatorRule, ValidatorType } from '../../../../../utils';

export interface SterConfigCommentData {
  comment: string;
}

const validations: ValidatorRule[] = [
  { key: 'first_name', validators: [ValidatorType.REQUIRED] },
  { key: 'document', validators: [ValidatorType.REQUIRED] },
  { key: 'document_type', validators: [ValidatorType.REQUIRED] },
  { key: 'phone_number', validators: [ValidatorType.REQUIRED] },
  { key: 'email', validators: [ValidatorType.REQUIRED] },
  { key: 'billingAddressState', validators: [ValidatorType.REQUIRED] },
  { key: 'billingAddressCity', validators: [ValidatorType.REQUIRED] },
  { key: 'billingAddressStreet', validators: [ValidatorType.REQUIRED] },
  { key: 'billingAddressStreetNumber', validators: [ValidatorType.REQUIRED] },
  { key: 'billingAddressZip', validators: [ValidatorType.REQUIRED] },
  { key: 'shipment_type', validators: [ValidatorType.REQUIRED] },
  { key: 'payment_method', validators: [ValidatorType.REQUIRED] }
];

export const validate = makeValidator(validations);
