import { useLibbyFetch } from '../../../../../hooks';
import { LibbyObject } from '../../../../../types';

export const useGetCities = ({ libby }: LibbyObject) => {
  const { data: cities, working } = useLibbyFetch(libby, {
    daoName: 'ster_city',
    limit: 30000
  });
  return {
    cities,
    working
  };
};

export const useGetStates = ({ libby }: LibbyObject) => {
  const { data: states, working } = useLibbyFetch(libby, {
    daoName: 'ster_state',
    orderBy: 'name'
  });

  return {
    states,
    working
  };
};
