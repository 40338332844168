import React, { useMemo } from 'react';
import { Grid } from '@material-ui/core';
import { StateAreaChart } from './StateAreaChart';
import { ReportingGroupByMarketplaceCourier } from './ReportingGroupByMarketplaceCourier';
import { ReportAreaTime } from '../../../../../types/ReportAreaTime';

type IStateAreaCharts = {
  setFilter: (makeFilter: object) => void;
  data: ReportAreaTime[];
  working: boolean;
};

type chartInitialValuesType = {
  Critical: number;
  Warning: number;
  OK: number;
};

const chartInitialValues: chartInitialValuesType = {
  Critical: 0,
  Warning: 0,
  OK: 0
};

type chartPropsType = {
  Liquidation: chartInitialValuesType;
  Logistics: chartInitialValuesType;
  Sale: chartInitialValuesType;
};

const chartProps: chartPropsType = {
  Liquidation: { ...chartInitialValues },
  Logistics: { ...chartInitialValues },
  Sale: { ...chartInitialValues }
};

export interface StateAreaChartProps {
  title: string;
  critical: number;
  warning: number;
  ok: number;
  search: string;
  children?: React.ReactNode;
}

export const StateAreaCharts = ({ data, working, setFilter }: IStateAreaCharts) => {
  const chartValues = useMemo(
    () =>
      data.reduce((accum: chartPropsType, current: ReportAreaTime) => {
        if (current.StateMeta?.match(/^(Liquidation|Logistics|Sale)$/) && current.alertstate?.match(/^(Critical|Warning|OK)$/)) {
          const stateMeta = current.StateMeta as keyof typeof chartProps;
          const alertState = current.alertstate as keyof typeof chartInitialValues;
          accum[stateMeta][alertState] = parseInt(current.quantity, 10);
        }
        return accum;
      }, chartProps),
    [data]
  );

  const stateAreaCharRows: StateAreaChartProps[] = [
    {
      title: 'Sales',
      critical: chartValues.Sale.Critical,
      warning: chartValues.Sale.Warning,
      ok: chartValues.Sale.OK,
      search: 'Sale'
    },
    {
      title: 'Logistics',
      critical: chartValues.Logistics.Critical,
      warning: chartValues.Logistics.Warning,
      ok: chartValues.Logistics.OK,
      search: 'Logistics',
      children: <ReportingGroupByMarketplaceCourier setFilter={setFilter} />
    },
    {
      title: 'Liquidation',
      critical: chartValues.Liquidation.Critical,
      warning: chartValues.Liquidation.Warning,
      ok: chartValues.Liquidation.OK,
      search: 'Liquidation'
    }
  ];
  return (
    <Grid container justify="flex-start">
      {stateAreaCharRows.map((row) => (
        <StateAreaChart working={working} title={row.title} critical={row.critical} warning={row.warning} ok={row.ok} setFilter={setFilter} search={row.search}>
          {row?.children}
        </StateAreaChart>
      ))}
    </Grid>
  );
};
