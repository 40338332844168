import React from 'react';
import { FormConfig } from 'react-final-form-hooks';
import Dialog from '@material-ui/core/Dialog';
import { ThemeProvider } from '@material-ui/styles';
import { DialogContentText } from '@material-ui/core';
import { useTranslation } from 'services/translation';
import { InformationSection } from 'components/InformationSection';
import { FraudSettingsForm } from './FraudSettingsForm';
import { MarketplaceFraud } from 'interfaces/business';

interface ExportDialogType extends FormConfig<any> {
  open: boolean;
  onCancel: any;
  customTheme: string | null;
  title: string;
  render?: Function;
  maxWidth: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false;
  marketplace_fraud: MarketplaceFraud;
  onConfirm: any;
}
const dummyFunc = () => {};
export const FraudDialogComponent = ({ open = false, onCancel = dummyFunc, customTheme = null, title, maxWidth = 'xs', marketplace_fraud, onConfirm }: ExportDialogType) => {
  const { t } = useTranslation();

  const dialog = (
    <Dialog open={open} onClose={onCancel} fullWidth maxWidth={maxWidth} aria-labelledby="form-dialog-title" aria-describedby="alert-dialog-description">
      <InformationSection title={t(title)} noPaddingChildren>
        <DialogContentText>
          <FraudSettingsForm marketplace_fraud={marketplace_fraud} onConfirm={onConfirm} />
        </DialogContentText>
      </InformationSection>
    </Dialog>
  );

  return !customTheme ? dialog : <ThemeProvider theme={customTheme}>{dialog}</ThemeProvider>;
};
