import { makeValidator, ValidatorRule, ValidatorType } from '../../../../../utils';

export const formReportingGoalInitialValues = {
  name: '',
  goal_type: '',
  goal_type_value: ''
};

const validations: ValidatorRule[] = [
  { key: 'name', validators: [ValidatorType.REQUIRED] },
  { key: 'goal_type', validators: [ValidatorType.REQUIRED] },
  { key: 'goal_type_value', validators: [ValidatorType.REQUIRED] }
];

export const validateReportingGoalEdit = makeValidator(validations);
