import { useCallback, useState } from 'react';
import { useTranslation } from '../../../../../../../services/translation';
import { IBillingAddress, IProvince, IAddress } from '../types';
import { BudgetInitialValues } from '../types/BudgetContextValue';

export const useBillingAddress = (initialValues: BudgetInitialValues) => {
  const { t } = useTranslation();
  const [billingAddress, setBillingAddress] = useState<IBillingAddress>(initialValues.billingAddress);

  const selectBillingAddress = useCallback((address: any) => {
    // @ts-ignore
    setBillingAddress((prev) => {
      const { billingAddresses } = prev;
      return {
        ...prev,
        selectedBillingAddress: address,
        selectedBillingCity: billingAddresses[address]?.city?.name,
        selectedBillingState: billingAddresses[address]?.city?.state,
        selectedBillingStreet: billingAddresses[address]?.street,
        selectedBillingStreetNumber: billingAddresses[address]?.street_number,
        selectedBillingZipCode: billingAddresses[address]?.zip,
        selectedBillingFloor: billingAddresses[address]?.floor,
        selectedBillingDepartment: billingAddresses[address]?.department
      };
    });
  }, []);

  const pushBillingAddressOptions = useCallback(
    (province: IProvince, address: IAddress, provinces: any) => {
      setBillingAddress((prev: any) => {
        const newOption = {
          billingAddressState: provinces?.find((item: any) => item.state_id === province.state_id),
          id: prev.billingAddressOptions.length,
          value: `${t('Street')}: ${address.street};
            ${t('Street Number')}: ${address.street_number};
            ${t('City')}: ${address.city.name};
            ${t('Province')}: ${province.name};
            ${t('Zip Code')}: ${address.zip};
            ${t('Floor')}: ${address.floor};
            ${t('Department')}: ${address.department};`
        };

        const newBillingAdress = {
          city: {
            city_id: address.city.city_id,
            name: address.city.name,
            zip: +address.zip,
            enabled: true,
            state: {
              name: province.name,
              state_id: province.state_id
            }
          },
          department: address.department,
          floor: address.floor,
          street: address.street,
          street_number: +address.street_number,
          zip: +address.zip
        };

        return {
          ...prev,
          billingAddresses: [...prev.billingAddresses, newBillingAdress],
          selectedBillingAddress: newOption.id,
          billingAddressOptions: [...prev.billingAddressOptions, newOption]
        };
      });
      // @ts-ignore
      setBillingAddress((prev) => {
        const { billingAddresses } = prev;
        const lastIndex = billingAddresses.length - 1;
        return {
          ...prev,
          selectedBillingAddress: lastIndex,
          selectedBillingCity: billingAddresses[lastIndex]?.city?.name,
          selectedBillingState: billingAddresses[lastIndex]?.city?.state,
          selectedBillingStreet: billingAddresses[lastIndex]?.street,
          selectedBillingStreetNumber: billingAddresses[lastIndex]?.street_number,
          selectedBillingZipCode: billingAddresses[lastIndex]?.zip,
          selectedBillingFloor: billingAddresses[lastIndex]?.floor,
          selectedBillingDepartment: billingAddresses[lastIndex]?.department
        };
      });
    },
    [t]
  );

  const addBillingAddressOptions = useCallback<any>(
    (newClient: any, provinces: any[]) => {
      setBillingAddress((prev) => {
        return {
          ...prev,
          billingAddresses: newClient?.data?.billing_address,
          billingAddressOptions: newClient?.data?.billing_address?.map((item: any, index: any) => ({
            billingAddressState: provinces?.find((province: any) => province.state_id === item.city?.state?.state_id?.toString()),
            id: index,
            value: `${t('Street')}: ${item.street};
                      ${t('Street Number')}: ${item.street_number};
                      ${t('City')}: ${item.city?.name};
                      ${t('Province')}: 
                      ${provinces?.find((province: any) => province.state_id === item.city?.state?.state_id?.toString()).name};
                      ${t('Zip Code')}: ${item.zip}; 
                      ${t('Floor')}: ${item.floor}; 
                      ${t('Department')}: ${item.department};`
          }))
        };
      });

      if (newClient?.data) selectBillingAddress(newClient?.data?.billing_address?.length - 1);
    },
    [t, setBillingAddress, selectBillingAddress]
  );

  const editBillingAddress = useCallback((address) => {
    setBillingAddress((prev) => ({
      ...prev,
      selectedBillingCity: address.editedBillingCity,
      selectedBillingState: address.editedBillingState,
      selectedBillingStreet: address.editedBillingStreet,
      selectedBillingStreetNumber: address.editedBillingStreetNumber,
      selectedBillingZipCode: address.editedBillingZipCode,
      selectedBillingFloor: address.editedBillingFloor,
      selectedBillingDepartment: address.editedBillingDepartment
    }));
  }, []);

  return {
    addBillingAddressOptions,
    pushBillingAddressOptions,
    selectBillingAddress,
    billingAddress,
    editBillingAddress
  };
};
