import { useState } from 'react';

interface IUseTableSortConfig {
  direction: directionType;
  onSortChange: IHandleRequestSort;
  orderBy: string;
}

interface IHandleRequestSort {
  (newOrderBy: string, newDirection: directionType): void;
}

export type directionType = 'asc' | 'desc';

export const useTableSortConfig = (initialOrderBy: string): IUseTableSortConfig => {
  const [direction, setDirection] = useState<directionType>('asc');
  const [orderBy, setOrderBy] = useState(initialOrderBy);

  const handleRequestSort: IHandleRequestSort = (newOrderBy, newDirection) => {
    setOrderBy(newOrderBy);
    setDirection(newDirection);
  };

  return {
    direction,
    onSortChange: handleRequestSort,
    orderBy
  };
};
