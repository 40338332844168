import React, { ReactNode } from 'react';
import SearchIcon from '@material-ui/icons/Search';
import 'moment/locale/es';

import { Grid, TextField, GridProps } from '@material-ui/core';
import moment from 'moment';
import { FilterBar } from '../../../../../components/FilterBar';
import { gridBreakpoints } from '../../../../../const';
import { useTranslation } from '../../../../../services/translation';
import { useInitFilter, useInitFilterDebounced } from '../../../../OrderReport/routes/OrderReportList/hook';

import { SearchFiltersProps } from '../../../../../types';
import { makeFilter } from '../../../utils/makeFilter';
import { DateClose } from '../../../../../components/DateClose';
import { SelectMessageState, SelectMessageSource, SelectMessageReason } from '../../../Components';

type SearchFilterItemProps = {
  children: ReactNode;
  allGrid?: boolean;
};

const gridBreakpointsAll: GridProps = {
  xs: 12,
  sm: 6,
  md: 9,
  lg: 4
};

const SearchFilterItem = ({ children = null, allGrid = false }: SearchFilterItemProps) => {
  const gridBreakpointsInit = allGrid ? gridBreakpointsAll : gridBreakpoints;
  return (
    <Grid item {...gridBreakpointsInit}>
      {children}
    </Grid>
  );
};

const SearchFiltersRaw = ({ onFilterChange, initFetch, resetFilter }: SearchFiltersProps) => {
  const { t } = useTranslation();

  const { search, handleChange } = useInitFilterDebounced({
    initFetch: initFetch.search,
    init: '',
    onFilterChange,
    id: 'search',
    makeFilter
  });

  const { search: messageReason, handleChangeFilter: handleChangeMessageReason } = useInitFilter({
    initFetch: initFetch.message_reason,
    init: '0',
    onFilterChange,
    id: 'message_reason',
    makeFilter
  });

  const { search: messageSource, handleChangeFilter: handleChangeMessageSource } = useInitFilter({
    initFetch: initFetch.message_source,
    init: '0',
    onFilterChange,
    id: 'message_source',
    makeFilter
  });
  const { search: messageState, handleChangeFilter: handleChangeMessageState } = useInitFilter({
    initFetch: initFetch.message_state,
    init: '0',
    onFilterChange,
    id: 'message_state',
    makeFilter
  });

  const { search: startDate, handleChangeFilterString: setStartDate } = useInitFilter({
    initFetch: initFetch.startDate,
    onFilterChange,
    init: null,
    id: 'startDate',
    date: true,
    makeFilter
  });

  const { search: endDate, handleChangeFilterString: setEndDate } = useInitFilter({
    initFetch: initFetch.endDate,
    onFilterChange,
    id: 'endDate',
    date: true,
    init: null,
    makeFilter
  });

  const { search: sourceId, handleChange: handleChangeSourceId } = useInitFilterDebounced({
    initFetch: initFetch.source_id,
    init: '',
    onFilterChange,
    id: 'source_id',
    makeFilter
  });

  return (
    <FilterBar
      defaultFilter={
        <SearchFilterItem>
          <TextField
            id="standard-search"
            placeholder={t('Search')}
            type="search"
            value={search}
            onChange={handleChange}
            fullWidth
            InputLabelProps={{
              shrink: true
            }}
            InputProps={{
              endAdornment: <SearchIcon />
            }}
          />
        </SearchFilterItem>
      }
      handleReset={resetFilter}
      initFetch={initFetch}
    >
      <SearchFilterItem>
        <TextField
          id="source-search"
          label={t('Source ID')}
          value={sourceId}
          type="text"
          onChange={handleChangeSourceId}
          fullWidth
          InputLabelProps={{
            shrink: true
          }}
        />
      </SearchFilterItem>
      <SearchFilterItem>
        <SelectMessageSource
          inputProps={{
            value: messageSource,
            onChange: handleChangeMessageSource
          }}
        />
      </SearchFilterItem>
      <SearchFilterItem>
        <SelectMessageReason
          inputProps={{
            value: messageReason,
            onChange: handleChangeMessageReason
          }}
        />
      </SearchFilterItem>

      <SearchFilterItem>
        <SelectMessageState
          inputProps={{
            value: messageState,
            onChange: handleChangeMessageState
          }}
        />
      </SearchFilterItem>

      <SearchFilterItem>
        <DateClose name="Start Date" value={moment.isMoment(startDate) ? startDate : null} onChange={setStartDate} />
      </SearchFilterItem>
      <SearchFilterItem>
        <DateClose name="End Date" value={moment.isMoment(endDate) ? endDate : null} minValue={moment.isMoment(startDate) ? startDate : null} onChange={setEndDate} />
      </SearchFilterItem>
    </FilterBar>
  );
};

export const SearchFilters = React.memo(SearchFiltersRaw);
