import { AnyObject } from '../../../types/types';

type MakeFilterProps = {
  search?: string;
  from?: string | null;
  name?: string | null;
  subject?: string | null;
  id?: string | null;
  active?: boolean | null;
};
export const makeFilter = ({ search, from, name, subject, id, active }: MakeFilterProps) => {
  const filter: AnyObject = {};

  if (search) {
    const valueSearch = search.split(' ').reduce((accumulator: AnyObject, item, index) => {
      accumulator[index] = [
        {
          path: 'name',
          value: item,
          method: 'includes'
        },
        {
          path: 'message_from.message_from_id',
          value: item,
          method: 'includes'
        },
        {
          path: 'message_subject',
          value: item,
          method: 'includes'
        },
        {
          path: 'message_template_id',
          value: item,
          method: 'includes'
        }
      ];
      return accumulator;
    }, {});
    filter.search = [...valueSearch[0]];
  }

  if (name) {
    filter.name = [
      {
        path: 'name',
        value: name,
        method: 'includes'
      }
    ];
  }
  if (from && from !== '0') {
    filter.from = [
      {
        path: 'message_from.message_from_id',
        value: from
      }
    ];
  }
  if (subject) {
    filter.subject = [
      {
        path: 'message_subject',
        value: subject,
        method: 'includes'
      }
    ];
  }
  if (id) {
    filter.id = [
      {
        path: 'message_template_id',
        value: id,
        method: 'includes'
      }
    ];
  }
  if (active) {
    filter.active = [
      {
        path: 'active',
        value: active
      }
    ];
  }
  return filter;
};
