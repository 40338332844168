import { makeBusinessHOCs, BussinessHOCsOptions, getBusinessHOCsDefaultOptions } from './makeBusinessHOCs';
import { makeBusinessHooks } from './makeBusinessHooks';

export interface BusinessEntityOptions<T> {
  hoc: BussinessHOCsOptions<T>;
}

export const getBusinessEntityDefaultOptions = <T>(): BusinessEntityOptions<T> => ({
  hoc: getBusinessHOCsDefaultOptions<T>()
});

export interface PartialBusinessEntityOptions<T> {
  hoc?: Partial<BussinessHOCsOptions<T>>;
}

export const makeBusinessEntity = <T>(daoName: string, options: PartialBusinessEntityOptions<T> = getBusinessEntityDefaultOptions<T>()) => {
  const defaultOptions = getBusinessEntityDefaultOptions<T>();
  const mergedOptions: BusinessEntityOptions<T> = {
    ...defaultOptions,
    ...options,
    hoc: { ...defaultOptions.hoc, ...options.hoc }
  };
  const hoc = makeBusinessHOCs<T>(daoName, mergedOptions.hoc);
  const hook = makeBusinessHooks<T>(daoName);
  return {
    hoc,
    hook
  };
};
