import React, { useCallback, useMemo } from 'react';
import { makeStyles } from '@material-ui/styles';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { useSnackbar } from 'notistack';
import { useLibby } from '@phinxlab/libby-rest-web';
import { useLibbyFetch } from '../../../../../hooks';
import DeleteModal from '../../../../../services/confirmDialog';
import CustomModal from '../../../../../services/customFormDialog';
import { useTranslation } from '../../../../../services/translation';
import { MakeCell } from '../../../../components/MakeCell';
import { AnyObject } from '../../../../../types/types';
import { formatDecimal } from '../../../../../utils';
import { ClientFormDialog } from '../components/ClientFormDialog';

const ClientModal = CustomModal(ClientFormDialog);

const useStyles = makeStyles({
  buttonsCell: {
    display: 'flex',
    flexWrap: 'nowrap'
  }
});

export const useMakeClientTable = (paramsFetch: any) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const { libby } = useLibby(['ster_client']);
  const { data, working, reFetch, fetchMore } = useLibbyFetch(libby, paramsFetch);

  const handleOpenClientModal = useCallback(
    async (currentClient?: AnyObject) => {
      const paramsModal = {
        confirmText: t(currentClient ? 'Update' : 'Create'),
        cancelText: t('Cancel'),
        title: t(currentClient ? 'Update document' : 'Create document'),
        formInitialValues: currentClient
          ? {
              ...currentClient,
              client_document_id: String(currentClient.client_document_id)
            }
          : {
              client_document_id: '',
              document: ''
            }
      };

      try {
        const { document }: AnyObject = (await ClientModal.show(paramsModal)) as AnyObject;

        if (document) {
          try {
            await libby.ster_client.save({
              ...(currentClient && { ...currentClient }),
              document
            });
            reFetch();
            enqueueSnackbar(t(currentClient ? 'Document updated' : 'Document created'), { variant: 'success' });
          } catch (e) {
            enqueueSnackbar(t('Something is wrong'), { variant: 'error' });
          }
        }
      } catch (e) {
        // modal close
      }
    },
    [enqueueSnackbar, libby, t, reFetch]
  );

  const deleteDocumentModal = useCallback(
    async (client_document_id, document) => {
      const confirm = await DeleteModal.show({
        title: t('Delete document'),
        content: t('Do you want to delete the document $$$$?').replace('$$$$', document),
        confirmText: t('Yes'),
        cancelText: t('No')
      });
      if (confirm) {
        try {
          await libby.ster_client.remove({
            client_document_id
          });
          reFetch();
          enqueueSnackbar(t('Document Deleted'), { variant: 'success' });
        } catch (e) {
          if (e) {
            enqueueSnackbar(t('Something is wrong'), { variant: 'error' });
          }
        }
      }
    },
    [t, libby, enqueueSnackbar, reFetch]
  );

  const columns = useMemo(
    () => [
      {
        id: 'client_document_id',
        label: 'ID'
      },
      {
        id: 'document',
        label: 'Document'
      },
      {
        id: 'edit',
        label: 'Edit',
        noSort: true
      },
      {
        id: 'delete',
        label: 'Delete',
        noSort: true
      }
    ],
    []
  );

  const rows = useMemo(() => {
    if (!data) {
      return null;
    }

    return (data as AnyObject[]).map((client: AnyObject) => ({
      id: client.client_document_id,
      client_document_id: formatDecimal(client.client_document_id),
      document: client.document,
      edit: (
        <div className={classes.buttonsCell}>
          <MakeCell
            label=""
            icon={EditIcon}
            onClick={(e) => {
              e.stopPropagation();
              handleOpenClientModal({ client_document_id: client.client_document_id, document: client.document });
            }}
          />
        </div>
      ),
      delete: (
        <div className={classes.buttonsCell}>
          <MakeCell
            label=""
            icon={DeleteIcon}
            onClick={(e) => {
              e.stopPropagation();
              deleteDocumentModal(client.client_document_id, client.document);
            }}
          />
        </div>
      )
    }));
  }, [classes, data, deleteDocumentModal, handleOpenClientModal]);

  return {
    columns,
    rows,
    working,
    fetchMore,
    handleOpenClientModal
  };
};
