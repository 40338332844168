import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

export const useInterval = (callback: () => void, delay: number | null) => {
  const savedCallback = useRef(() => {});
  const interval = useRef<any>(null);

  const [enabledReset, setEnabledReset] = useState(false);

  const seconds = useMemo(() => {
    const milliseconds = typeof delay === 'number' ? delay : 0;
    return milliseconds / 1000;
  }, [delay]);
  const [time, setTime] = useState<number>(seconds);

  useEffect(() => {
    setTime(seconds);
  }, [seconds]);

  useEffect(() => {
    if (delay === null) {
      return () => {};
    }
    const intervalTimeSeconds = setInterval(() => {
      setTime((copyTime) => copyTime - 1);
    }, 1000);
    return () => clearInterval(intervalTimeSeconds);
  }, [delay]);

  useEffect(() => {
    if (delay !== null) {
      savedCallback.current = callback;
    }
  }, [callback, delay]);

  const resetSeconds = useCallback(() => {
    setTime(seconds);
  }, [seconds]);

  const refreshCallback = useCallback(() => {
    resetSeconds();
    if (delay !== null) {
      savedCallback.current();
    }
  }, [resetSeconds, delay]);

  useEffect(() => {
    if (delay === null) {
      return () => {};
    }
    interval.current = setInterval(() => {
      refreshCallback();
    }, delay);
    if (enabledReset) {
      setEnabledReset(false);
    }
    return () => {
      return clearInterval(interval.current);
    };
  }, [delay, enabledReset, refreshCallback]);

  const resetTime = useCallback(() => {
    setEnabledReset(true);
    refreshCallback();
  }, [refreshCallback]);

  return { time, resetTime };
};
