import React from 'react';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableCell, { TableCellProps } from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/styles';
import { Button, Grid, TableFooter, TableHead, TableRow } from '@material-ui/core';
import { CSVLink } from 'react-csv';
import { useTranslation } from '../../services/translation';
import { AnyObject } from '../../types/types';
// import { EnhancedTableHead } from '../../components/EnhancedTableHead/EnhancedTableHead';
import LoadingData from './LoadingData';
import { InfoCollapsibleTableRow } from './InfoCollapsibleTableRow';
import { ActionsFooterTable, OnSortChange, RowsType } from '../../utils/tables';
import { DataFilterDownloadInterface, DataFilterDownloadInterfaceSpanish } from '../../interfaces/business/general/DataFilterDownload';

export type InfoTableGridSize = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
const useStyles = makeStyles({
  root: {
    width: '100%',
    marginTop: '15',
    background: (value: AnyObject) => (value.rootBackground ? '#ffffff' : 'none')
  },
  tableContainer: {
    maxHeight: (value: AnyObject) => value.height,
    height: (value: AnyObject) => value.height
  },
  search: {
    display: 'flex',
    justifyContent: 'center'
  },
  caption: {
    height: '15px'
  },
  cell: {
    padding: '10px 16px',
    textAlign: 'left'
  },
  cellNotPadding: {
    padding: '0px 16px'
  },
  loading: {
    width: '100%',
    height: '100%'
  },
  rowNotPadding: {
    height: '30px'
  },
  rootDownloadCSV: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: '10px'
  },
  csvLink: {
    textDecoration: 'none',
    color: 'white'
  },
  footerAction: {
    bottom: 0,
    position: 'sticky',
    background: 'white'
  }
});

export type Column = {
  id: string;
  minWidth?: number;
  label: string;
  align?: TableCellProps['align'];
  // TODO: fix this, adding render to the type make fails in a lot of places
  // render?: (row: any, column?: Column, t?: (str: string) => string) => any,
} & AnyObject;

export interface InfoTableProps {
  onBottomScroll?: () => void;
  columns: Array<any>;
  rows: Array<any>;
  onRowClick?: (row: RowsType) => void;
  onColapseRowClick?: (row: any, rowColapse: any) => void;
  onDoubleClick?: (row: AnyObject) => void;
  rowIdKey?: string;
  onSortChange?: OnSortChange;
  onSortChangeInFront?: (orderBy: string) => void;
  orderBy?: string;
  direction?: 'asc' | 'desc';
  height?: number | 'auto';
  xs?: InfoTableGridSize;
  sm?: InfoTableGridSize;
  md?: InfoTableGridSize;
  lg?: InfoTableGridSize;
  xl?: InfoTableGridSize;
  working?: boolean;
  padding?: boolean;
  orderInFront?: boolean;
  RowComponent?: React.ElementType;
  rootBackground?: boolean;
  elevation?: boolean;
  rowComponentShow?: boolean;
  download?: boolean;
  data?: DataFilterDownloadInterface[] | DataFilterDownloadInterfaceSpanish[];
  actionsFooter?: ActionsFooterTable[];
  primaryCollapsible?: string;
}

const InfoCollapsibleTable = ({
  columns,
  rows,
  onBottomScroll,
  onRowClick,
  onColapseRowClick,
  onDoubleClick,
  height = 450,
  xs = 12,
  sm,
  md,
  lg,
  xl,
  working,
  padding = true,
  rootBackground = true,
  elevation = true,
  download,
  data,
  actionsFooter,
  primaryCollapsible
}: InfoTableProps) => {
  const { t } = useTranslation();
  const classes = useStyles({ height, rootBackground });
  const handleScroll = (e: any) => {
    const bottom = e.target.scrollHeight - e.target.scrollTop <= e.target.clientHeight + 200;
    if (bottom) {
      onBottomScroll?.();
    }
  };

  return (
    <Grid
      item
      {...{
        xs,
        sm,
        md,
        lg,
        xl
      }}
    >
      {download && (
        <Grid className={classes.rootDownloadCSV}>
          <Button variant="contained" color="primary">
            <CSVLink className={classes.csvLink} data={data || ''}>
              {t('Download')}
            </CSVLink>
          </Button>
        </Grid>
      )}
      <Paper className={classes.root} elevation={elevation ? 1 : 0}>
        <TableContainer className={classes.tableContainer} onScroll={handleScroll}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                <TableCell />
                {columns.map((column) => typeof column === 'string' && <TableCell align="center">{t(column)}</TableCell>)}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows?.map((row: any) => (
                <InfoCollapsibleTableRow key="" onClick={onRowClick} onColapseRowClick={onColapseRowClick} onDoubleClick={onDoubleClick} padding={padding} row={row} columns={columns} primaryCollapsible={primaryCollapsible} />
              ))}
            </TableBody>

            {!!actionsFooter?.length && (
              <TableFooter className={classes.footerAction}>
                <TableCell align="right" colSpan={rows.length}>
                  {actionsFooter.map(({ icon, id }: ActionsFooterTable) => (
                    <React.Fragment key={id}>{icon}</React.Fragment>
                  ))}
                </TableCell>
              </TableFooter>
            )}
          </Table>
          {working && (
            <Grid container justify="center" alignItems="center" className={classes.loading}>
              <LoadingData working={working} />
            </Grid>
          )}
        </TableContainer>
      </Paper>
    </Grid>
  );
};

export default React.memo(InfoCollapsibleTable);
