import { LibbyFetchDAO } from './LibbyFetchDAO';

export class SterConfigDAO extends LibbyFetchDAO {
  constructor() {
    super('ster_ster_config', 'ster_config_id');
  }

  getAll() {
    return this.query().limit(0, 10000).run();
  }

  getSterConfigId() {
    return this.query().equals('ster_config_id', 3).run();
  }

  getSterConfigFailure() {
    return this.query().equals('lastState', 'failure').run();
  }
}
