import React, { useState, useEffect } from 'react';
import { DatabaseConnector } from '@phinxlab/libby-rest-web';
import { GeneralSelect } from '../../../components/GeneralSelect';
import { AnyObject, LibbyObject } from '../../../types/types';
import { useLibbyCall } from '../../../hooks';
import { Dispatch } from '../../../interfaces/business';

type SelectDispatchProps = {
  inputProps: AnyObject;
  optionAll?: boolean;
  [k: string]: any;
  libby: LibbyObject;
  valueInit: Dispatch;
  disabled: boolean;
  inCollect: boolean;
};

export const SelectDispatchRaw = ({ libby, inputProps = {}, optionAll = false, valueInit, disabled, inCollect = false, ...styles }: SelectDispatchProps) => {
  const [dataSelect, setDataSelect] = useState<Array<{ id: number; value: any; name?: string }>>(
    inputProps.value && valueInit?.name
      ? [
          {
            id: inputProps.value,
            value: valueInit.name
          }
        ]
      : []
  );

  const { data: dataRaw, working = true } = useLibbyCall(libby, {
    daoName: 'ster_dispatch_details',
    methodName: inCollect ? 'getStateInCollect' : 'getState'
  });

  useEffect(() => {
    setDataSelect((prev) => {
      const copyDataSelect = [...prev];
      dataRaw.forEach(({ dispatch_id, name }: Dispatch) => {
        copyDataSelect.push({
          id: parseInt(dispatch_id, 10),
          value: name
        });
      });

      return copyDataSelect;
    });
  }, [dataRaw]);

  return <GeneralSelect working={working} label="Dispatch" optionAll={optionAll} data={dataSelect} inputProps={inputProps} disabled={disabled} {...styles} />;
};

export const SelectDispatch = DatabaseConnector(SelectDispatchRaw)('ster_dispatch_details');
