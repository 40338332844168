import React from 'react';
import { Box } from '@material-ui/core';
import { OrderStateColors } from '../../../../../const/OrderState';
import { OrderType } from '../../../../../types/types';
import { formatDate } from '../../../../../utils';

export const columns = [
  {
    id: 'order_log_id',
    label: 'Log ID'
  },
  {
    id: 'order',
    label: 'Order Number',
    render: ({ order: { order_id } }: OrderType) => <Box>{order_id}</Box>
  },
  {
    id: 'order_state_name_lower',
    label: 'State',
    render: ({ order_state_name, order_state_id }: { order_state_name: string; order_state_id: number }, item: any, t: (str: string) => string) => (
      <Box color={OrderStateColors[order_state_id ?? '']}>{order_state_name && t(order_state_name)}</Box>
    )
  },
  {
    id: 'updated_by',
    label: 'Updated by',
    render: ({ updated_by }: { updated_by: string }) => <Box>{updated_by}</Box>
  },
  {
    id: 'updated_at',
    label: 'Change date',
    render: ({ updated_at }: OrderType) => <Box>{formatDate(updated_at, true, false)}</Box>
  },
  {
    id: 'created_at',
    label: 'Origin date',
    render: ({ created_at }: OrderType) => <Box>{formatDate(created_at, true, false)}</Box>
  }
];
