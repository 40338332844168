import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import { ThemeProvider, makeStyles } from '@material-ui/styles';

const dummyFunc = () => {};

interface FormDialogType {
  open: boolean;
  onCancel: () => void;
  customTheme: string | null;
  message: string;
  isDownSm: boolean;
  isMobile: boolean;
}

export const MessageVisualizer = ({ open = false, onCancel = dummyFunc, customTheme = null, message, isDownSm, isMobile }: FormDialogType) => {
  const useStyles = makeStyles(() => ({
    root: {
      '& .MuiDialog-paperWidthSm': {
        maxWidth: 'fit-content'
      }
    },
    textField: {
      marginBottom: '40px'
    },
    title: {
      overflow: 'visible',
      '& > h3': {
        fontFamily: '"Oswald"'
      }
    },
    iframe: {
      width: isDownSm ? (isMobile ? '100%' : '460px') : '600px',
      height: '650px'
    }
  }));
  const classes = useStyles();

  const dialog = (
    <Dialog className={classes.root} open={open} onClose={onCancel} aria-labelledby="form-dialog-title">
      <iframe className={classes.iframe} srcDoc={message} title="Message Template" />
    </Dialog>
  );

  return !customTheme ? dialog : <ThemeProvider theme={customTheme}>{dialog}</ThemeProvider>;
};
