import { OrdersSoOrderDAO } from './Order/OrdersSoOrderDAO';

export class OrderB2BVwDAO extends OrdersSoOrderDAO {
  constructor() {
    super('ster_vw_order_b2b', 'order_id');
  }

  getAll() {
    return this.query().limit(0, 10).run();
  }
}
