import React from 'react';
import { useRouteMatch, useHistory } from 'react-router-dom';
import { SearchFilters } from './components/SearchFilters';
import InfoTable from '../../../components/InfoTable';
import { ScreenAligned } from '../../../../components/ScreenAligned/ScreenAligned';
import { useTranslation } from '../../../../services/translation';
import LoadingData from '../../../components/LoadingData';
import { MakeFilterProps } from '../../utils/makeFilter';
import { LibbyObject, HeadCellsType, Filter } from '../../../../types';
import { RowsType } from '../../../../utils/tables';
import { TitleBreadcrumbs } from '../../../../interfaces';

type DispatchesListProps = {
  rows: Array<RowsType>;
  columns: Array<HeadCellsType>;
  fetchMore: () => void;
  onSortChange?: (orderBy: string, direction: 'asc' | 'desc') => void;
  orderBy?: string;
  direction?: 'asc' | 'desc';
  onFilterChange: (makeFilter: MakeFilterProps) => void;
  working: boolean;
  title: TitleBreadcrumbs[];
  additionalTitle: React.ReactElement | null;
  filter: Filter;
  isReadOnly: boolean;
  resetFilter: () => void;
};

export const DispatchesList = ({ rows, columns, fetchMore, onSortChange, orderBy, direction, onFilterChange, working, title, additionalTitle, filter, isReadOnly, resetFilter }: DispatchesListProps) => {
  const { t } = useTranslation();
  const { path } = useRouteMatch();
  const history = useHistory();
  const goToDetails = (row: LibbyObject) => {
    history.push({
      pathname: `${path}/detail/${row.dispatch_id}`,
      state: {
        goBack: title
      }
    });
  };

  return (
    <ScreenAligned title={title} additionalTitle={additionalTitle}>
      <SearchFilters initFetch={filter} onFilterChange={onFilterChange} isReadOnly={isReadOnly} resetFilter={resetFilter} />
      <InfoTable columns={columns} rows={rows} onBottomScroll={fetchMore} rowIdKey="dispatch_id" onRowClick={goToDetails} onSortChange={onSortChange} orderBy={orderBy} direction={direction} />
      <LoadingData label={`${t('Loaded registers')}`} working={working} cant={rows.length} />
    </ScreenAligned>
  );
};
