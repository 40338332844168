import React, { useEffect, useState } from 'react';
import SearchIcon from '@material-ui/icons/Search';
import { Grid, TextField } from '@material-ui/core';
import moment, { Moment } from 'moment';
import { AnyObject } from '../../../types';
import { useTranslation } from '../../../services/translation';
import { makeFilter } from '../utils';
import { FilterBar } from '../../../components/FilterBar';
import { gridBreakpoints, gridBreakpointsFields } from '../../../const';
// import { useInitFilterDebounced } from '../../OrderReport/routes/OrderReportList/hook';
import { DateClose } from '../../../components/DateClose';
import useDebounce from '../../../hooks/useDebounce';

type SearchFiltersProps = {
  onFilterChange: (makeFilter: object) => void;
  filter: AnyObject;
};

const initialStartDate = null;
const initialEndDate = null;

const SearchFiltersRaw = ({ onFilterChange, filter }: SearchFiltersProps) => {
  const { t } = useTranslation();

  const [search, setSearch] = useState<string>(filter.search && filter.search.length > 0 ? filter.search[0].value : '');
  const [marketplace, setMarketplace] = useState<string>(filter.importId && filter.importId.length > 0 ? filter.importId[0].value : '');
  const [updatedBy, setUpdatedBy] = useState<string>(filter.importId && filter.importId.length > 0 ? filter.importId[0].value : '');
  const [sku, setSku] = useState<string>(filter.importId && filter.importId.length > 0 ? filter.importId[0].value : '');
  const [startDate, setStartDate] = useState<Moment | null>(filter.startDate && filter.startDate.length > 0 ? moment(filter.startDate[0].value) : initialStartDate);
  const [endDate, setEndDate] = useState<Moment | null>(filter.endDate && filter.endDate.length > 0 ? moment(filter.endDate[0].value) : initialEndDate);

  const searchDebounced = useDebounce(search, 500);
  const marketplaceDebounced = useDebounce(marketplace, 500);
  const updatedByDebounced = useDebounce(updatedBy, 500);
  const skuDebounced = useDebounce(sku, 500);

  useEffect(() => {
    onFilterChange(
      makeFilter({
        startDate,
        endDate,
        search: searchDebounced,
        marketplace: marketplaceDebounced,
        sku: skuDebounced,
        updated_by: updatedByDebounced
      })
    );
  }, [onFilterChange, startDate, endDate, skuDebounced, marketplaceDebounced, updatedByDebounced, searchDebounced]);

  const handleReset = () => {
    setUpdatedBy('');
    setSku('');
    setSearch('');
    setMarketplace('');
    setStartDate(initialStartDate);
    setEndDate(initialEndDate);
  };

  return (
    <FilterBar
      defaultFilter={
        <Grid item {...gridBreakpoints}>
          <TextField
            id="standard-search"
            placeholder={t('Search')}
            type="search"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            fullWidth
            InputLabelProps={{
              shrink: true
            }}
            InputProps={{
              endAdornment: <SearchIcon />
            }}
          />
        </Grid>
      }
      handleReset={handleReset}
    >
      <Grid item {...gridBreakpointsFields}>
        <TextField
          id="sku"
          label={t('Sku')}
          type="search"
          value={sku}
          onChange={(e) => setSku(e.target.value)}
          fullWidth
          InputLabelProps={{
            shrink: true
          }}
        />
      </Grid>
      <Grid item {...gridBreakpointsFields}>
        <TextField
          id="marketplace"
          label={t('Marketplace')}
          type="search"
          value={marketplace}
          onChange={(e) => setMarketplace(e.target.value)}
          fullWidth
          InputLabelProps={{
            shrink: true
          }}
        />
      </Grid>
      <Grid item {...gridBreakpointsFields}>
        <TextField
          id="updated_by"
          label={t('Updated By')}
          type="search"
          value={updatedBy}
          onChange={(e) => setUpdatedBy(e.target.value)}
          fullWidth
          InputLabelProps={{
            shrink: true
          }}
        />
      </Grid>
      <Grid item {...gridBreakpointsFields}>
        <DateClose name="Start Date" value={startDate} onChange={setStartDate} />
      </Grid>
      <Grid item {...gridBreakpointsFields}>
        <DateClose name="End Date" value={endDate} minValue={startDate} onChange={setEndDate} />
      </Grid>
    </FilterBar>
  );
};

export const SearchFilters = React.memo(SearchFiltersRaw);
