import React, { ReactElement, useCallback, useRef, useState } from 'react';

import { Backdrop, Box, Button, CircularProgress, Dialog, DialogContent, IconButton, InputAdornment, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@material-ui/core';
import Publish from '@material-ui/icons/Publish';
import Papa from 'papaparse';
import { CalendarToday, Close, Done, GetApp } from '@material-ui/icons';
import { DatePicker } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import moment from 'moment';
import { useVerifyOrdersDAO } from '../../../../../business/orders/order/VerifyOrders';
import { ButtonComponent } from '../../../../../components/Button';

export default function VerifyOrder() {
  const [open, setOpen] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const verifyOrdersDAO = useVerifyOrdersDAO();
  const [fromDate, setFromDate] = useState<String | undefined>(moment().subtract(1, 'month').format('YYYY-M-D HH:mm:ss'));
  const [toDate, setToDate] = useState<String | undefined>(moment().format('YYYY-M-D HH:mm:ss'));
  const [orders, setOrders] = useState<ReactElement[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [totalOrders, setTotalOrders] = useState<{ countOrders: number; countOrdersNotIn: number }>({ countOrders: 0, countOrdersNotIn: 0 });
  const [ordersToWrite, setOrdersToWrite] = useState<String[][]>([]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setFromDate(moment().subtract(1, 'month').format('YYYY-M-D HH:mm:ss'));
    setToDate(moment().format('YYYY-M-D HH:mm:ss'));
    setOrders([]);
  };

  const handleFileUpload = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.files) {
        setLoading(true);
        Papa.parse(event.target.files[event.target.files.length - 1], {
          async complete(result: Papa.ParseResult<string>) {
            const { data } = result;
            const idsFromCsv: String[] = data.map((id) => id[0]);
            const ordersResult: String[] = await verifyOrdersDAO.save({ ids: idsFromCsv, fromDate, toDate });
            const ordersToSet: ReactElement[] = [];
            let countOrders: number = 0;
            let countOrdersNotIn: number = 0;
            const writeOrders: String[][] = [];

            idsFromCsv.forEach((order) => {
              if (ordersResult.find((orderR) => orderR === order)) {
                countOrders += 1;
                ordersToSet.unshift(
                  <TableRow style={{ border: '1px solid #DFDFDF' }}>
                    <TableCell>{order}</TableCell>
                    <TableCell style={{ textAlign: 'end' }}>
                      <Done style={{ color: '#40EC5C' }} />
                    </TableCell>
                  </TableRow>
                );
              } else {
                countOrders += 1;
                countOrdersNotIn += 1;
                writeOrders.push([order]);
                ordersToSet.push(
                  <TableRow style={{ border: '1px solid #DFDFDF' }}>
                    <TableCell>{order}</TableCell>
                    <TableCell style={{ textAlign: 'end' }}>
                      <Close style={{ color: '#F03636' }} />
                    </TableCell>
                  </TableRow>
                );
              }
            });

            setOrdersToWrite(writeOrders);
            setTotalOrders({ countOrders, countOrdersNotIn });
            setOrders(ordersToSet);
            setLoading(false);
          }
        });
      } else console.log(event.target.files);
    },
    [verifyOrdersDAO, fromDate, toDate]
  );

  const Input = useCallback(() => {
    return <input ref={inputRef} style={{ display: 'none' }} type="file" onChange={handleFileUpload} />;
  }, [handleFileUpload]);

  const downloadCSV = () => {
    const csv = Papa.unparse(ordersToWrite);
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    const url = URL.createObjectURL(blob);
    link.setAttribute('href', url);
    link.setAttribute('download', `ster-ordenes-no-registradas-${moment(new Date()).format('DD/MM/YYYY-HH_mm_ss')}.csv`);
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <>
      <ButtonComponent title="Verificar" key="verifyOrder" onClick={handleClickOpen} variant="contained" />
      <Dialog open={open} onClose={handleClose} aria-labelledby="verify-orders-dialog" PaperProps={{ style: { minWidth: '500px' } }} style={{ textAlign: 'center' }} scroll="body">
        <DialogContent style={{ padding: '30px' }}>
          <h1 style={{ whiteSpace: 'nowrap' }}>Verificar ordenes existentes</h1>

          <Box pt={4} pb={4} display="flex" justifyContent="space-around" gridGap={8} border="none">
            <DatePicker
              label="Fecha desde"
              value={fromDate}
              onChange={(date: MaterialUiPickersDate) => {
                setFromDate(date?.format('YYYY-M-D HH:mm:ss'));
              }}
              inputVariant="outlined"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton>
                      <CalendarToday />
                    </IconButton>
                  </InputAdornment>
                )
              }}
              color="primary"
            />

            <DatePicker
              label="Fecha hasta"
              value={toDate}
              onChange={(date: MaterialUiPickersDate) => {
                setToDate(date?.format('YYYY-M-D HH:mm:ss'));
              }}
              inputVariant="outlined"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton>
                      <CalendarToday />
                    </IconButton>
                  </InputAdornment>
                )
              }}
              color="primary"
            />
          </Box>

          {!!orders.length && !loading && (
            <>
              <TableContainer component={Paper} style={{ maxHeight: 'calc(100vh - 30vh)', boxShadow: 'none', marginBottom: '30px' }}>
                <Table aria-label="Orders">
                  <TableHead style={{ backgroundColor: '#FF5179' }}>
                    <TableRow>
                      <TableCell style={{ color: 'white' }}>Owner ID</TableCell>
                      <TableCell style={{ color: 'white', width: '50px' }}>Registrada</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {orders}

                    <TableCell style={{ border: '1px solid #DFDFDF', width: '50%', textAlign: 'center' }}>
                      <Typography variant="h5">Total importado</Typography>
                      <h1>{totalOrders.countOrders}</h1>
                    </TableCell>
                    <TableCell style={{ border: '1px solid #DFDFDF', textAlign: 'center' }}>
                      <Typography variant="h5">Total no registrado</Typography>
                      <h1>{totalOrders.countOrdersNotIn}</h1>
                    </TableCell>
                  </TableBody>
                </Table>
              </TableContainer>
              <Button variant="contained" color="primary" startIcon={<GetApp />} fullWidth onClick={downloadCSV}>
                Descargar ordenes no registradas
              </Button>
            </>
          )}

          {!orders?.length && (
            <Button variant="contained" color="primary" startIcon={<Publish />} fullWidth onClick={() => inputRef.current?.click()}>
              Cargar archivo CSV
            </Button>
          )}

          <Input />
          <Backdrop style={{ color: '#FF5179', zIndex: 999 }} open={loading}>
            <CircularProgress color="inherit" />
          </Backdrop>
        </DialogContent>
      </Dialog>
    </>
  );
}
