import React, { ReactNode } from 'react';
import { Grid, GridSpacing, Button, Collapse, useMediaQuery, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import FilterList from '@material-ui/icons/FilterList';
import { useTranslation } from '../../services/translation';
import Pallet from '../../theme/palette';
import { Filter } from '../../types';
import { gridBreakpoints } from '../../const';

const useStyles = makeStyles({
  containerForm: {
    maxWidth: 'inherit',
    margin: '15px 0 50px 0'
  },
  verticalMargin: {
    marginBottom: '1rem'
  },
  filterButton: {
    backgroundColor: Pallet.background.gray
  },
  filterIcon: {
    marginRight: 4
  },
  buttonBox: {
    '& button': {
      marginLeft: 15
    },
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%'
  },
  buttonBoxMobile: {
    '& button': {
      marginLeft: 15
    },
    marginTop: '20px',
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%'
  }
});

interface FilterBarType {
  children?: ReactNode;
  spacing?: GridSpacing;
  defaultFilter?: ReactNode;
  handleReset?: () => void;
  initFetch?: Filter;
}

export const FilterBar = ({ children, spacing = 4, defaultFilter, handleReset, initFetch }: FilterBarType) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [expanded, setExpanded] = React.useState(initFetch ? !!Object.entries(initFetch).length : false);
  const isDownSm = useMediaQuery<Theme>((theme) => theme.breakpoints.down('sm'));

  return (
    <Grid container className={classes.containerForm}>
      <Grid container justify="space-between" alignItems="center" className={classes.verticalMargin}>
        {defaultFilter}
        {!!children && (
          <Grid {...gridBreakpoints} className={isDownSm ? classes.buttonBoxMobile : classes.buttonBox} item>
            {!!handleReset && expanded && (
              <Button type="reset" onClick={handleReset}>
                {t('Clear Filters')}
              </Button>
            )}
            <Button onClick={() => setExpanded(!expanded)} variant="contained" className={expanded ? classes.filterButton : undefined}>
              <FilterList className={classes.filterIcon} />
              {t('filters')}
            </Button>
          </Grid>
        )}
      </Grid>
      <Grid container>
        <Grid item xs={12}>
          <Collapse in={expanded} timeout="auto">
            <Grid container spacing={spacing}>
              {children}
            </Grid>
          </Collapse>
        </Grid>
      </Grid>
    </Grid>
  );
};
