import { LibbyFetchDAO } from '../LibbyFetchDAO';

export class GoalPeriodUpdateDAO extends LibbyFetchDAO {
  constructor() {
    super('ster_goal_period_update', 'goal_period_id');
  }

  getAllGoal() {
    return this.query('list_all_goal').limit(0, 10000).run();
  }
}
