import { Box } from '@material-ui/core';
import React from 'react';
import { formatDate } from '../../../../../../utils';
import { Column } from '../../../../../components/InfoTable';

export const reportingLogisticJustifyLogsColumns: Array<Column> = [
  {
    id: 'operation',
    label: 'Operation',
    style: {
      whiteSpace: 'nowrap'
    }
  },
  {
    id: 'justification',
    label: 'Justification'
  },
  {
    id: 'updated_by',
    label: 'Updated by'
  },
  {
    id: 'updated_at',
    label: 'Change date',
    style: {
      whiteSpace: 'nowrap'
    },
    render: ({ updated_at }: { updated_at: Date }) => <Box>{formatDate(updated_at, true, false)}</Box>
  }
];
