import React, { useMemo } from 'react';
import { Theme, useMediaQuery } from '@material-ui/core';
import { ComposedChart, Bar, Cell, Legend, ResponsiveContainer, XAxis, YAxis, Tooltip, Line } from 'recharts';
import { CHART_COLORS, CHART_LIGHT_COLORS } from '../../chart/types';
import LoadingData from '../../routes/components/LoadingData';
import { useTranslation } from '../../services/translation';
import { format } from '../../util/format';
import { CustomTooltip } from './CustomTooltip';
import { CustomizedCursor } from './CustomizedCursor';
import { RenderCusomizedLegend } from './RenderCusomizedLegend';
import { ChartEmpty } from '../ChartEmpty';
import { useStyles } from '../../theme/styles/useStyleChart';
import { AllBar, DataFormatPayload } from './interfaces/CustomToolTip';

interface ComposedChartProps {
  working: boolean;
  dataChart: any;
  onChange?: (payload: any) => void;
  bar: string[];
  dataKeyXAxis: string;
  dataFormatPayload?: DataFormatPayload[];
  dataIgnorePayload?: string[];
}

export const ComposedChartComponent = ({ working, dataChart, onChange, bar = ['test'], dataKeyXAxis, dataFormatPayload, dataIgnorePayload }: ComposedChartProps) => {
  const { t } = useTranslation();

  const isDownXs = useMediaQuery<Theme>((theme) => theme.breakpoints.down('xs')); // 400

  const isDownMd = useMediaQuery<Theme>((theme) => theme.breakpoints.down('md'));
  const className = useStyles();

  const dataAllBar = useMemo(
    () =>
      bar.reduce((allBar: AllBar[], bartNew: string, index: number) => {
        const allBarNew = [...allBar];
        allBarNew.push({
          dataKey: bartNew,
          colorAux: index === 0 ? CHART_COLORS : CHART_LIGHT_COLORS
        });

        return allBarNew;
      }, []),
    [bar]
  );

  return (
    <>
      {!working ? (
        dataChart.length ? (
          <ResponsiveContainer width="100%" height="100%" minHeight={isDownXs ? 400 : isDownMd ? 400 : 200}>
            <ComposedChart
              data={dataChart}
              margin={{
                right: 30,
                left: 20
              }}
            >
              <YAxis allowDecimals tickFormatter={(label) => format(label, 'Integer', t)} />
              <XAxis dataKey={dataKeyXAxis} />
              <Tooltip content={<CustomTooltip dataFormatPayload={dataFormatPayload} dataIgnorePayload={dataIgnorePayload} barColor={dataAllBar} />} cursor={<CustomizedCursor onChange={onChange} />} />
              <Legend
                payload={dataChart.map((item: any, index: number) => ({
                  id: item.name,
                  type: 'circle',
                  value: `${item.name} `,
                  color: item?.color ?? CHART_COLORS[index % CHART_COLORS.length]
                }))}
                content={<RenderCusomizedLegend />}
              />
              {[dataAllBar[1]].map(({ dataKey, colorAux }: AllBar) => (
                <Bar dataKey={dataKey} className={className.cursor} fill={dataChart[0]?.color ?? colorAux[1]} barSize={20}>
                  {dataChart.map((data: any, index: number) => {
                    delete data.users;
                    return (
                      <Cell
                        fill={data?.color ?? colorAux[index % colorAux.length]}
                        className={className.cursor}
                        onClick={() => {
                          if (onChange) {
                            onChange(data);
                          }
                        }}
                      />
                    );
                  })}
                </Bar>
              ))}
              {[dataAllBar[0]].map(({ dataKey, colorAux }: AllBar) => (
                <Line dataKey={dataKey} className={className.cursor} fill={dataChart[0]?.color ?? colorAux[1]} />
              ))}
            </ComposedChart>
          </ResponsiveContainer>
        ) : (
          <ChartEmpty />
        )
      ) : (
        <LoadingData working={working} />
      )}
    </>
  );
};
