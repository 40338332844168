import { useEffect, useState } from 'react';
import { useLibbyFetch } from '../../../../../../../lib/libby';

export const useProvinces = () => {
  const [provinces, setProvinces] = useState<[{ state_id: string; name: string }] | []>([]);
  const [selectedProvince, setSelectedProvinces] = useState<{ state_id: string; name: string } | {}>({});
  const { data: dataProvinces, working: fetchingProvinces } = useLibbyFetch<[{ id: string; value: string }] | []>({
    daoName: 'ster_state',
    orderBy: 'name'
  });

  useEffect(() => {
    if (!fetchingProvinces) {
      // @ts-ignore
      setProvinces(dataProvinces);
    }
  }, [dataProvinces, fetchingProvinces]);

  return {
    provinces,
    fetchingProvinces,
    selectedProvince,
    setSelectedProvinces
  };
};
