import React, { useCallback, useEffect } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { ThemeProvider } from '@material-ui/styles';
import Button from '@material-ui/core/Button';
import TextField, { TextFieldProps } from '@material-ui/core/TextField';
import { useField, useForm, FormConfig } from 'react-final-form-hooks';
import Typography from '@material-ui/core/Typography';
import { ValidationErrors } from 'final-form';
import { makeGetErrorAndHelperText } from '../../utils/materialHelpers';
import { useTranslation } from '../../services/translation';
import { ValidatePassword } from '../ValidatePassword';

const dummyFunc = () => {};

export interface FormDialogType extends FormConfig<any> {
  open: boolean;
  onCancel: any;
  onConfirm: (dataInput: object) => void;
  title: string;
  content: React.ReactNode;
  labelContent: string;
  cancelText: string;
  confirmText: string;
  customTheme: string | null;
  inputType: string;
  inputName: string;
  validate: (values: any) => ValidationErrors | Promise<ValidationErrors> | undefined;
  formInitialValues: { [name: string]: string };
  select: React.ReactType<TextFieldProps>;
}

export const FormDialog = ({
  open = false,
  onCancel = dummyFunc,
  onConfirm = dummyFunc,
  title = 'Confirmation Dialog',
  content = 'Are you sure of perform this action?',
  labelContent = 'enter value',
  cancelText = 'Cancel',
  confirmText = 'confirmText',
  customTheme = null,
  inputType = 'password',
  inputName = 'defaultName',
  validate,
  formInitialValues = {},
  select: Select
}: FormDialogType) => {
  const { t } = useTranslation();
  const getErrorAndHelperText = makeGetErrorAndHelperText(t);

  const onSubmit = useCallback(
    async (values) => {
      onConfirm({ [inputName]: values[inputName] });
    },
    [inputName, onConfirm]
  );

  const { form, handleSubmit, submitError } = useForm({
    initialValues: formInitialValues,
    onSubmit,
    validate
  });

  const genericInput = useField(inputName, form);

  useEffect(() => {
    if (!open) {
      // execute after submit form
      setTimeout(() => {
        form.reset();
        form.resetFieldState(inputName);
      }, 0);
    }
  }, [open, form, inputName]);

  const dialog = (
    <Dialog open={open} onClose={onCancel} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">{title}</DialogTitle>
      <form noValidate onSubmit={handleSubmit}>
        <DialogContent>
          {!!content && <DialogContentText>{content}</DialogContentText>}
          {inputType === 'text' ? (
            <TextField autoFocus margin="dense" {...genericInput.input} label={labelContent} type="text" {...getErrorAndHelperText(genericInput)} fullWidth />
          ) : inputType === 'select' ? (
            <Select
              inputProps={{
                ...genericInput.input,
                ...getErrorAndHelperText(genericInput)
              }}
            />
          ) : (
            inputType === 'password' && <ValidatePassword genericInput={genericInput} value={genericInput.input.value} />
          )}

          {submitError && (
            <Typography style={{ display: 'block' }} variant="overline" color="error">
              {t(submitError)}
            </Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={onCancel}>{cancelText}</Button>
          <Button color="primary" type="submit">
            {confirmText}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );

  return !customTheme ? dialog : <ThemeProvider theme={customTheme}>{dialog}</ThemeProvider>;
};
