import React from 'react';
import { Button, Grid, Link, Paper, Typography } from '@material-ui/core';
import { useTranslation } from '../../../../../../services/translation';
import { AnyObject } from '../../../../../../types';
import { KpiComponent } from '../KpiComponent';
import { FinancialsTypes } from '../../types';

export const FinancialsPaper = ({ values, classes }: { values: FinancialsTypes; classes: AnyObject }) => {
  const { t } = useTranslation();

  const { url, ...kpi } = values;

  return (
    <Grid item xs={12} sm={12} md={10} lg={4}>
      <Paper className={classes.paper}>
        <Grid container xs={12} justify="space-between" alignItems="center">
          <Typography variant="h4" className={classes.title}>
            {t('Financials')}
          </Typography>
        </Grid>
        {kpi && (
          <Grid container xs={12}>
            <Grid container xs={12} justify="space-between" alignItems="center">
              <Typography variant="h5">{t('Balances')}</Typography>
              {url && (
                <Button color="primary">
                  <Link target="_blank" href={url}>
                    {t('see more')}
                  </Link>
                </Button>
              )}
            </Grid>
            {Object.entries(kpi).map(([key, value]: [string, string | number]) => (
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <KpiComponent name={key} value={value} />
              </Grid>
            ))}
          </Grid>
        )}
      </Paper>
    </Grid>
  );
};
