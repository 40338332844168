import { useCallback, useState } from 'react';
import { Grid, TextField, CircularProgress, Box } from '@material-ui/core';
import { Button } from '@material-ui/core';
import { useField, useForm } from 'react-final-form-hooks';
import { DatabaseConnector } from '@phinxlab/libby-rest-web';
import { useStyles } from '../components/styles';
import { AnyObject, LibbyObject } from 'types';
import { useTranslation } from 'services/translation';
import { validate } from '../models/model';
import { useFormConfig } from '../config/useFormConfig';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import { FormFieldProductTypes } from '../config/types';
import { SelectCategoryProduct } from 'routes/components/SelectCategoryProduct';
import { SelectBrandProduct } from 'routes/components/SelectBrandProduct';
import { SelectConfirmation } from 'components/SelectConfirmation';
import { useSnackbar } from 'notistack';
import { UserProductPermissions } from 'types';

type productForm = {
  name: string;
  sku: string;
  description: string;
  category: number;
  brand: number;
  width: number;
  height: number;
  weight: number;
  depth: number;
  price: number;
  n_serie: string;
  vat: number;
};

interface OrderDetailDataInterface {
  data: AnyObject;
  libby: LibbyObject;
  userPermissions: UserProductPermissions;
}

export const ProductDetailDataRaw = ({ data, libby, userPermissions }: OrderDetailDataInterface) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [enabledForm, setEnabledForm] = useState<boolean>(false);
  const formInitialValues = {
    name: data.name,
    sku: data.sku,
    description: data.description,
    category: data.category.category_id,
    brand: data.brand.brand_id,
    width: data.attributes?.physical?.width || '',
    height: data.attributes?.physical?.height || '',
    weight: data.attributes?.physical?.weight || '',
    depth: data.attributes?.physical?.depth || '',
    price: data.price,
    n_serie: data.come_serial_number ? 'yes' : 'no',
    vat: data.vat
  };
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();

  const onSubmit = useCallback(
    async ({ name, sku, description, category, brand, width, height, weight, depth, price, n_serie, vat }: productForm) => {
      try {
        setLoading(true);
        const attributes = {
          physical: {
            width: width || 0,
            height: height || 0,
            depth: depth || 0,
            weight: weight || 0
          }
        };
        const newDataToEditProduct = {
          ...data,
          name,
          description,
          attributes: attributes,
          price,
          sku,
          come_serial_number: n_serie === 'yes' ? true : false,
          category: { ...data.category, category_id: Number(category) },
          brand: { ...data.brand, brand_id: Number(brand) },
          vat
        };
        Promise.resolve(await libby.ster_product.save({ ...newDataToEditProduct }));
        enqueueSnackbar(t('Product $$$$ edited successfully').replace('$$$$', `${data.product_id}`), { variant: 'success' });
      } catch (err) {
        enqueueSnackbar(t('Something went wrong'), { variant: 'error' });
      } finally {
        setLoading(false);
        setEnabledForm(false);
      }
    },
    [enqueueSnackbar, setLoading, setEnabledForm, libby, data, t]
  );

  const { form, handleSubmit } = useForm({
    initialValues: formInitialValues,
    onSubmit,
    validate
  });

  const name = useField('name', form);
  const sku = useField('sku', form);
  const description = useField('description', form);
  const category = useField('category', form);
  const brand = useField('brand', form);
  const width = useField('width', form);
  const height = useField('height', form);
  const weight = useField('weight', form);
  const depth = useField('depth', form);
  const price = useField('price', form);
  const n_serie = useField('n_serie', form);
  const vat = useField('vat', form);

  const { formFieldsProduct, formFieldsAttributes /* errorForm */ }: any = useFormConfig({
    props: {
      name,
      sku,
      description,
      category,
      brand,
      width,
      height,
      weight,
      depth,
      price,
      n_serie,
      vat
    },
    classes,
    userPermissions,
    type: 'edit'
  });

  return (
    <>
      <Grid className={classes.containInfo}>
        <Box sx={{ display: 'flex', width: '100%', justifyContent: 'flex-end', marginRight: '20px' }}>
          <Button color="primary" variant="contained" type="button" disabled={userPermissions.full_permission || userPermissions.edit_permission || enabledForm ? false : true} onClick={() => setEnabledForm(true)}>
            {t('Edit')}
          </Button>
        </Box>
        <form onSubmit={handleSubmit}>
          <DialogContent>
            <DialogContentText className={classes.modalContent} id="alert-dialog-description">
              {t('Product information')}
            </DialogContentText>
            <Grid xs={12}>
              {formFieldsProduct &&
                formFieldsProduct.map(({ multiline, field, errorField, label, disabled, ...props }: FormFieldProductTypes) => (
                  <TextField
                    variant="outlined"
                    key={props.name}
                    margin="normal"
                    multiline
                    disabled={!enabledForm || disabled}
                    minRows={!!multiline ? 4 : 1}
                    InputLabelProps={{ shrink: true }}
                    value={field.input.value}
                    onChange={(e) => {
                      field.input.onChange(e.target.value);
                    }}
                    onBlur={field.input.onBlur}
                    className={props.class}
                    label={t(label)}
                    {...errorField}
                  />
                ))}
              <Grid container spacing={2} direction="row" style={{ marginTop: 2 }}>
                <Grid item xs={6}>
                  <SelectCategoryProduct
                    sort="asc"
                    inputProps={{ value: category.input.value, onChange: category.input.onChange }}
                    canalOffLine={false}
                    variant={'outlined'}
                    disabled={enabledForm ? (userPermissions.full_permission ? false : !userPermissions.edit_permission) : true}
                  />
                </Grid>
                <Grid item xs={6}>
                  <SelectBrandProduct
                    sort="asc"
                    inputProps={{ value: brand.input.value, onChange: brand.input.onChange }}
                    canalOffLine={false}
                    variant={'outlined'}
                    disabled={enabledForm ? (userPermissions.full_permission ? false : !userPermissions.edit_permission) : true}
                  />
                </Grid>
                <Grid item xs={6}>
                  <SelectConfirmation
                    label={t('N° Serie')}
                    inputProps={{ value: n_serie.input.value, onChange: n_serie.input.onChange }}
                    variant={'outlined'}
                    disabled={enabledForm ? (userPermissions.full_permission ? false : !userPermissions.edit_permission) : true}
                  />
                </Grid>
              </Grid>
              <DialogContentText className={classes.modalContent} id="alert-dialog-description">
                {t('Product Attributes')}
              </DialogContentText>
              <Grid xs={12}>
                {formFieldsAttributes &&
                  formFieldsAttributes.map(({ multiline, field, errorField, label, disabled, ...props }: FormFieldProductTypes) => (
                    <TextField
                      variant="outlined"
                      key={props.name}
                      margin="normal"
                      multiline
                      disabled={!enabledForm || disabled}
                      minRows={!!multiline ? 4 : 1}
                      InputLabelProps={{ shrink: true }}
                      value={field.input.value}
                      onChange={(e) => {
                        field.input.onChange(e.target.value);
                      }}
                      onBlur={field.input.onBlur}
                      className={props.class}
                      label={t(label)}
                      {...errorField}
                    />
                  ))}
              </Grid>
            </Grid>
          </DialogContent>
          {enabledForm && (
            <DialogActions className={classes.buttonText}>
              <Button onClick={() => setEnabledForm(false)} color="primary" variant="outlined" disabled={!enabledForm}>
                Cancelar
              </Button>
              <Button color="primary" type="submit" autoFocus variant="contained" disabled={loading} style={{ minWidth: '100px' }}>
                {loading ? <CircularProgress size={24} /> : 'Guardar'}
              </Button>
            </DialogActions>
          )}
        </form>
      </Grid>
    </>
  );
};

export const OrderDetailData = DatabaseConnector(ProductDetailDataRaw)('ster_product');
