import { useState, useEffect, useCallback } from 'react';
import { AnyObject, LibbyObject } from '../../../types';
import { useLibbyFetchById } from '../../../hooks/useLibbyFetchById';
import { Collect } from '../../../interfaces/business';

interface useCollectStartedProp {
  libby: LibbyObject;
  id: string;
  updateData: (Collect: Collect, id: string) => void;
}
const skuExceptions = ['desc_esp_105', 'desc_esp_21', 'DES_PLATAF', 'DESC_21', 'DESC_105', 'BNA_COSTO_FINANCIERO'];

export const useCollectStarted = ({ libby, id, updateData }: useCollectStartedProp) => {
  const { working, data, recall } = useLibbyFetchById(libby, {
    daoName: 'ster_dispatch_collect_details',
    id,
    aspect: 'list_details'
  });

  const [dataCollect, setDataCollect] = useState<any>();

  // TODO refactor the sku filter with a new feature
  useEffect(() => {
    setDataCollect({
      ...data,
      items: data?.items?.map((item: AnyObject) => ({
        ...item,
        dispatch_item: {
          ...item.dispatch_item,
          order: { ...item.dispatch_item.order, items: item.dispatch_item.order.items.filter((orderItem: AnyObject) => !skuExceptions.includes(orderItem.sku)) }
        }
      }))
    });
  }, [data]);

  const updateDataCollect = useCallback(
    (newDataCollect) => {
      setDataCollect(newDataCollect);
      updateData(newDataCollect, 'collect_id');
    },
    [updateData]
  );

  return {
    working,
    data: dataCollect,
    recall,
    updateData: updateDataCollect
  };
};
