import axios from 'axios';
import { Dispatch } from '../../../interfaces/business';
import { API_URL } from '../../../config';

interface Response {
  success: boolean;
  error?: string;
  data?: string;
}

export const getDispatchManifest = (dispatch_id: string) => `${API_URL}/api/custom/public/dispatch/dispatch_manifest/${dispatch_id}?download=true`;

export const printDispatchManifest = async (dispatch: Dispatch): Promise<Response> => {
  try {
    const urlRefer = await axios({
      url: getDispatchManifest(dispatch.dispatch_id),
      method: 'PUT',
      data: dispatch
    });
    return {
      success: true,
      data: urlRefer.data.data
    };
  } catch (err: any) {
    return { success: false, error: err.message.toString() };
  }
};
