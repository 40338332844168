import React, { useMemo } from 'react';
import { GeneralSelect } from '../../../components/GeneralSelect';
import { AnyObject } from '../../../types';
import { makeFilter } from '../utils/makeFilter';
import { Collect_state } from '../../../interfaces/business';
import { useLibbyCall, useLibbyFetch } from '../../../lib/libby';

type SelectWithCollectProps = {
  inputProps: AnyObject;
  optionAll?: boolean;
  [k: string]: any;
  // libby: LibbyObject;
  // valueInit: Cart;
  // disabled: boolean;
  // collect_id: string;
  assigned_id?: string;
  collect_state_id?: (string | number | boolean)[];
  idData: string;
  nameData: string;
  daoName: string;
  label: string;
  sort?: boolean | 'asc' | 'desc';
  sortBy?: string;
};

export const SelectWithCollectRaw = ({ inputProps = {}, optionAll = false, assigned_id, collect_state_id, idData, nameData, daoName, label, sort, sortBy, ...styles }: SelectWithCollectProps) => {
  const { data: dataCollectStateArray } = useLibbyCall({
    daoName: 'ster_dispatch_collect_state',
    methodName: 'getAll'
  });
  //@ts-ignore
  const dataCollectStateString = useMemo(() => dataCollectStateArray?.map((dataCollectState: Collect_state) => dataCollectState.collect_state_id), [dataCollectStateArray]);

  const collect_state = useMemo(() => (collect_state_id?.length ? collect_state_id : dataCollectStateString), [collect_state_id, dataCollectStateString]);

  const filter = useMemo(() => makeFilter({ assigned_id, collect_state_id: collect_state }), [assigned_id, collect_state]);

  const paramsFetch = useMemo(
    () => ({
      daoName,
      filter,
      limit: 10000
    }),
    [filter, daoName]
  );
  const { data: dataRaw, working } = useLibbyFetch({ ...paramsFetch, aspect: 'list_delete_data_collect' });

  const dataSend = useMemo(
    () =>
      dataRaw?.map((data: any) => ({
        id: data[idData],
        value: data[nameData].toLowerCase()
      })),
    [dataRaw, idData, nameData]
  );

  return <GeneralSelect working={working} label={label} optionAll={optionAll} data={dataSend} inputProps={inputProps} {...styles} sort={sort} sortBy={sortBy} />;
};

export const SelectWithCollect = SelectWithCollectRaw;
