import React, { useCallback, useEffect } from 'react';
import { useField, useForm } from 'react-final-form-hooks';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { ThemeProvider } from '@material-ui/styles';
import { FormProps } from '../../../../../types';
import { useTranslation } from '../../../../../services/translation';
import { makeGetErrorAndHelperText, textFieldProps } from '../../../../../utils';
import { ResetForm } from '../../../../../components/ResetForm';
import { useModalFormStyles } from '../../../../../theme';
import { SelectGoalType } from '../../../../../components/SelectGoalType';
import { SelectGoalTypeValue } from '../../../../../components/SelectGoalTypeValue';
import { GOAL_TYPE, GOAL_TYPE_VALUE } from '../../../../../const';
import { SelectMarketplaceGoal } from '../../../../../components/SelectMarketplaceGoal';
import { SelectCategoryGoal } from '../../../../../components/SelectCategoryGoal';

export const EditGoalForm = ({
  open = false,
  onCancel = () => {},
  onConfirm = () => {},
  title = 'Confirmation Dialog',
  cancelText = 'Cancel',
  confirmText = 'confirmText',
  customTheme = null,
  validate,
  formInitialValues = {}
}: FormProps) => {
  const { t } = useTranslation();
  const styles = useModalFormStyles();
  const getErrorAndHelperText = makeGetErrorAndHelperText(t);

  const onSubmit = useCallback(
    async ({ goal_type, name, goal_type_value, goal_marketplaces, goal_categories }) => {
      onConfirm({
        goal_type,
        goal_type_value,
        name,
        goal_marketplaces,
        goal_categories
      });
    },
    [onConfirm]
  );

  const { form, handleSubmit, submitError } = useForm({
    initialValues: formInitialValues,
    onSubmit,
    validate: (values: any) => {
      const validationErrors = validate && validate(values);
      const manualValidationErrors: any = {};

      if (values.goal_type === GOAL_TYPE.PRODUCT.toString()) {
        if (!values.goal_categories.length) manualValidationErrors.goal_categories = t('Please fill out this field');
      } else if (values.goal_type === GOAL_TYPE.MARKETPLACE.toString()) {
        if (!values.goal_marketplaces.length) manualValidationErrors.goal_marketplaces = t('Please fill out this field');
      }

      return { ...(validationErrors || {}), ...manualValidationErrors };
    }
  });

  const name = useField('name', form);
  const goal_type = useField('goal_type', form);
  const goal_type_value = useField('goal_type_value', form);
  const goal_marketplaces = useField('goal_marketplaces', form);
  const goal_categories = useField('goal_categories', form);

  useEffect(() => {
    if (goal_type_value.input.value !== GOAL_TYPE_VALUE.MONETARY.toString() && goal_type.input.value === GOAL_TYPE.MARKETPLACE.toString()) {
      form.change('goal_type_value', GOAL_TYPE_VALUE.MONETARY.toString());
    }
  }, [form, goal_type_value.input.value, goal_type.input.value, goal_categories.input, formInitialValues.goal_categories]);

  const dialog = (
    <Dialog open={open} onClose={onCancel} fullWidth aria-labelledby="form-dialog-title">
      <DialogContent className={styles.title}>
        <Typography variant="h3">{title}</Typography>
      </DialogContent>
      <form noValidate onSubmit={handleSubmit}>
        <DialogContent className={styles.content}>
          <TextField
            {...textFieldProps(`${t('Name')}`)}
            onChange={name.input.onChange}
            value={name.input.value}
            variant="standard"
            multiline
            InputLabelProps={{
              shrink: true,
              ...getErrorAndHelperText(name)
            }}
          />

          <SelectGoalType
            inputProps={{
              onChange: goal_type.input.onChange,
              value: goal_type.input.value || '',
              ...getErrorAndHelperText(goal_type)
            }}
            disabled
          />

          <SelectGoalTypeValue
            disabled
            inputProps={{
              onChange: goal_type_value.input.onChange,
              value: goal_type_value.input.value || '',
              ...getErrorAndHelperText(goal_type_value)
            }}
          />

          {goal_type.input.value === GOAL_TYPE.MARKETPLACE.toString() && (
            <SelectMarketplaceGoal
              inputProps={{
                onChange: goal_marketplaces.input.onChange,
                value: goal_marketplaces.input.value || '',
                error: { ...getErrorAndHelperText(goal_marketplaces) }
              }}
              label={t('Marketplace')}
              editForm
            />
          )}

          {goal_type.input.value === GOAL_TYPE.PRODUCT.toString() && (
            <SelectCategoryGoal
              inputProps={{
                onChange: goal_categories.input.onChange,
                value: goal_categories.input.value || '',
                error: { ...getErrorAndHelperText(goal_categories) }
              }}
              label={t('Categories')}
              editForm
            />
          )}

          {submitError && (
            <Typography style={{ display: 'block' }} variant="overline" color="error">
              {t(submitError)}
            </Typography>
          )}
        </DialogContent>
        <DialogActions>
          <ResetForm form={form} open={open} />
          <Button
            onClick={() => {
              onCancel();
            }}
          >
            {cancelText}
          </Button>
          <Button color="primary" type="submit">
            {confirmText}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );

  return !customTheme ? dialog : <ThemeProvider theme={customTheme}>{dialog}</ThemeProvider>;
};
