import React from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import { AlertList } from './routes/AlertList';

export const Alert = () => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route exact path={`${path}`}>
        <AlertList />
      </Route>
    </Switch>
  );
};
