import { GoalTypeValue } from '../../interfaces/business';
import { makeBusinessEntity } from '../../lib/libby/generator/makeBusinessEntity';

const DAO_NAME = 'ster_goal_type_value';

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch }
} = makeBusinessEntity<GoalTypeValue>(DAO_NAME);

// hooks
export const useGoalTypeValueFetchById = fetchById;
export const useGoalTypeValueLibbyCall = libbyCall;
export const useGoalTypeValueDAO = libbyDAO;
export const useGoalTypeValueLibbyFetch = libbyFetch;

// hooks
export const useGoalTypeValueUpdateFetchById = fetchById;
export const useGoalTypeValueUpdateLibbyCall = libbyCall;
export const useGoalTypeValueUpdateDAO = libbyDAO;
export const useGoalTypeValueUpdateLibbyFetch = libbyFetch;
