import React, { MouseEventHandler } from 'react';
import { Button, PropTypes } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { useTranslation } from '../../services/translation';
import { LoadingTable } from '../LoadingTable';

export const stylesButton = makeStyles(() => ({
  marginButton: {
    margin: '7px 7px'
  }
}));

export const ButtonComponent = ({
  className,
  variant = 'outlined',
  color = 'primary',
  onClick,
  title,
  type = 'button',
  disabled = false,
  loading = false,
  classNameContainer
}: {
  className?: string;
  variant?: 'text' | 'outlined' | 'contained';
  color?: PropTypes.Color;
  onClick: MouseEventHandler;
  title: string;
  type?: 'submit' | 'reset' | 'button';
  disabled?: boolean;
  loading?: boolean;
  classNameContainer?: string;
}) => {
  const { t } = useTranslation();
  const classes = stylesButton();
  return (
    <div className={`${classes.marginButton} ${classNameContainer}`}>
      <Button className={className} variant={variant} color={color} onClick={onClick} type={type} disabled={disabled}>
        {loading ? <LoadingTable /> : t(title)}
      </Button>
    </div>
  );
};
