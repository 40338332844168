import { Category } from '../interfaces/business/product/Category';
import { makeBusinessEntity, PartialBusinessEntityOptions } from '../lib/libby/generator/makeBusinessEntity';

import { businessOptions } from './businessOptions';

const DAO_NAME = 'ster_category';

const defaultOptions: PartialBusinessEntityOptions<any> = {
  hoc: {
    withEntityAutocomplete: {
      optionLabelKey: 'name'
    },
    withAccordionSelector: {
      idKey: 'category_id',
      labelKey: 'name'
    },
    withSelectedChips: {
      idKey: 'category_id',
      labelKey: 'name'
    }
  }
};

const optionsToUse = !businessOptions.Category
  ? defaultOptions
  : {
      ...defaultOptions,
      ...businessOptions.Category,
      hoc: { ...defaultOptions.hoc, ...businessOptions.Category.hoc }
    };

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch },
  hoc: { entityAutocomplete, renderPropCall, renderPropFetchById, simpleSelect, accordionSelector, selectedChips }
} = makeBusinessEntity<Category>(DAO_NAME, optionsToUse);

// hooks
export const useCategoryFetchById = fetchById;
export const useCategoryLibbyCall = libbyCall;
export const useCategoryDAO = libbyDAO;
export const useCategoryLibbyFetch = libbyFetch;

// components/hoc
export const CategoryAutocomplete = entityAutocomplete;
export const CategoryCall = renderPropCall;
export const CategoryById = renderPropFetchById;
export const CategorySelect = simpleSelect;
export const CategoryAccordionSelector = accordionSelector;
export const CategorySelectedChips = selectedChips;
