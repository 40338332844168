import { Box, Grid, TextField, makeStyles } from '@material-ui/core';
import React, { useState } from 'react';
import SearchIcon from '@material-ui/icons/Search';
import { useLocation, useParams } from 'react-router-dom';
import { ScreenAligned } from '../../../../components/ScreenAligned/ScreenAligned';
import { gridBreakpointsFields } from '../../../../const';
import { useLiquidationTableLogic } from '../../hook/useLiquidationTableLogic';
import { useTranslation } from '../../../../services/translation';
import { LibbyObject } from '../../../../types';
import InfoTable from '../../../components/InfoTable';
import LoadingData from '../../../components/LoadingData';
import { useBreadcrumbs } from '../../../../hooks';
import { TitleBreadcrumbs } from '../../../../interfaces';
import { InformationSection } from '../../../../components/InformationSection';
import { makeMapLabelValues } from '../../../../utils';
import { format } from '../../../../util';
import { greyText } from '../../../../theme/colors';

interface LiquidationGroupProps {
  libby: LibbyObject;
  title: TitleBreadcrumbs[];
}

const useStyles = makeStyles({
  gridItem: {
    '& h5': {
      color: greyText,
      fontSize: 12
    },
    '& p': {
      fontSize: 16
    }
  },
  editButton: {
    position: 'relative',
    top: '-5px'
  }
});

export const LiquidationsListGroup = ({ libby, title }: LiquidationGroupProps) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const stringValue = (value: number) => (value ? `-${format(value, 'ARS', t)}` : format(value, 'ARS', t));

  const basicInformationMap = [
    {
      key: 'total',
      label: 'Total',
      formatter: (value: any) => format(value, 'ARS', t)
    },
    {
      key: 'cancellations',
      label: 'Cancellations',
      formatter: (value: any) => stringValue(value)
    },
    {
      key: 'penalties',
      label: 'Penalties',
      formatter: (value: any) => stringValue(value)
    },
    {
      key: 'refund',
      label: 'Refunds',
      formatter: (value: any) => format(value, 'ARS', t)
    },
    {
      key: 'commissions_accumulated',
      label: 'Commissions',
      formatter: (value: any) => stringValue(value)
    },
    {
      key: 'shipping_accumulated',
      label: 'Shipping',
      formatter: (value: any) => format(value, 'ARS', t)
    },
    {
      key: 'discount_accumulated',
      label: 'Discount',
      formatter: (value: any) => stringValue(value)
    },
    {
      key: 'cost_interest_accumulated',
      label: 'Interest',
      formatter: (value: any) => stringValue(value)
    },
    {
      key: 'bonus_charge_accumulated',
      label: 'Bonus Charger',
      formatter: (value: any) => stringValue(value)
    },
    {
      key: 'return_charge_accumulated',
      label: 'Return Charge',
      formatter: (value: any) => stringValue(value)
    },
    {
      key: 'shipping_charge_accumulated',
      label: 'Shipping Charge',
      formatter: (value: any) => stringValue(value)
    }
  ];

  const mapperLabelValues = makeMapLabelValues(t, 'row', false, {
    lg: 2,
    md: 2,
    sm: 6,
    style: { padding: '10px' }
  });
  const basicMapper = mapperLabelValues(basicInformationMap);

  const [search, setSearch] = useState('');
  const { id } = useParams<{ id: string }>();
  const symbolsArr = ['e', 'E', '+', '-', '.', ',', '`+'];

  const { state } = useLocation<{ goBack: TitleBreadcrumbs[] }>();
  const { row, columns, working, liquidationGroupDetails } = useLiquidationTableLogic({
    libby,
    group_id: id
  });

  const titleShow = useBreadcrumbs('Order', state && state?.goBack?.length ? state.goBack : title, `#${id}`);

  const result = !search ? row : row.filter((r) => r.id.includes(search));

  return (
    <ScreenAligned title={titleShow}>
      <InformationSection title="" whiteBackground minimumPadding detailHeader>
        <Grid container justify="space-between" alignItems="flex-start" wrap="nowrap">
          <Grid className={classes.gridItem} container justifyContent="flex-start" spacing={1}>
            {working ? <LoadingData working={working} /> : <>{liquidationGroupDetails && Object.entries(liquidationGroupDetails).length > 0 && basicMapper(liquidationGroupDetails)}</>}
          </Grid>
        </Grid>
      </InformationSection>

      <Box mt={3}>
        <Grid item {...gridBreakpointsFields}>
          <TextField
            id="standard-search"
            placeholder={t('ID Order')}
            type="text"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            fullWidth
            InputLabelProps={{
              shrink: true
            }}
            InputProps={{
              endAdornment: <SearchIcon />
            }}
            onKeyDown={(e) => symbolsArr.includes(e.key) && e.preventDefault()}
          />
        </Grid>
        <InfoTable columns={columns} rows={result} onSortChange={() => {}} direction="asc" rowIdKey="name" />
        <LoadingData label={`${t('Loaded liquidations')}`} working={working} cant={row?.length} />
      </Box>
    </ScreenAligned>
  );
};
