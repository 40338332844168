import React from 'react';
import { DatabaseConnector } from '@phinxlab/libby-rest-web';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import { LibbyObject } from '../../types';
import { ReportingTransportList } from './routes';

interface ReportingTransportTypes {
  libby: LibbyObject;
}

const ReportingTransportRaw = ({ libby }: ReportingTransportTypes) => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${path}`}>
        <ReportingTransportList title="Orders for policies" libby={libby} />
      </Route>
    </Switch>
  );
};

export const ReportingTransport = DatabaseConnector(ReportingTransportRaw)('ster_reporting_by_transport');
