import React, { useCallback, useEffect, useRef, useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { ThemeProvider } from '@material-ui/styles';
import Button from '@material-ui/core/Button';
import { Grid, TextField } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { useField, useForm } from 'react-final-form-hooks';
import { useTranslation } from '../../services/translation';
import { useStyles } from './style/style';
import { SelectProvince } from '../SelectProvince';
import { AutocompleteCity } from '../AutocompleteCity';
import { useFormConfig } from './config/useFormConfig';
import { FormFieldsBuyerTypes } from '../../types/FormConfig';
import { EdirOrderFormType } from './types/types';
import { ButtonsCopyReset } from './utils/ButtonsCopyReset';
import { AccordionBuyerAddress } from './components/Accordion';

export const EditOrderDialog = ({ titleEditOrder, cancelText, confirmText, customTheme = null, open = false, validate, onCancel = () => {}, onConfirm = () => {}, formInitialValues, reset, handleGetCity }: EdirOrderFormType) => {
  const [cancel, setCancel] = useState(false);
  const [verificateZipBuyer, setVerificateZipBuyer] = useState(true);
  const [verificateZip, setVerificateZip] = useState(true);

  const onSubmit = useCallback(
    async ({
      first_name,
      last_name,
      document,
      email,
      phone_number,
      buyerDepartment,
      buyerFloor,
      buyerStreet,
      buyerStreetNumber,
      buyerExtraAddress,
      buyerZip,
      buyerCity,
      city,
      street,
      street_number,
      zip,
      floor,
      department,
      extra_address,
      province,
      buyerProvince
    }) => {
      onConfirm({
        first_name,
        last_name,
        document,
        email,
        phone_number,
        buyerDepartment,
        buyerFloor,
        buyerStreet,
        buyerStreetNumber,
        buyerExtraAddress,
        buyerZip,
        buyerCity,
        city,
        street,
        street_number,
        zip,
        floor,
        department,
        extra_address,
        province,
        buyerProvince
      });
    },
    [onConfirm]
  );

  const { form, handleSubmit } = useForm({
    initialValues: formInitialValues,
    onSubmit,
    validate
  });

  useEffect(() => {
    form.reset();
  }, [reset, form, cancel]);

  const first_name = useField('first_name', form);
  const last_name = useField('last_name', form);
  const document = useField('document', form);
  const email = useField('email', form);
  const phone_number = useField('phone_number', form);
  const buyerDepartment = useField('buyerDepartment', form);
  const buyerFloor = useField('buyerFloor', form);
  const buyerProvince = useField('buyerProvince', form);
  const buyerStreet = useField('buyerStreet', form);
  const buyerStreetNumber = useField('buyerStreetNumber', form);
  const buyerExtraAddress = useField('buyerExtraAddress', form);
  const buyerZip = useField('buyerZip', form);
  const city = useField('city', form);
  const province = useField('province', form);
  const buyerCity = useField('buyerCity', form);
  const street = useField('street', form);
  const street_number = useField('street_number', form);
  const zip = useField('zip', form);
  const floor = useField('floor', form);
  const department = useField('department', form);
  const extra_address = useField('extra_address', form);

  const classes = useStyles();

  const { t } = useTranslation();

  const { formFieldsBuyer, formFieldsBuyerAddress, formFieldsShipment, errorBuyerCity, errorCity, errorForm, errorBuyerAddress, errorProvince, errorBuyerProvince }: any = useFormConfig({
    props: {
      first_name,
      last_name,
      document,
      email,
      phone_number,
      buyerDepartment,
      buyerFloor,
      buyerProvince,
      buyerStreet,
      buyerStreetNumber,
      buyerExtraAddress,
      buyerZip,
      city,
      province,
      buyerCity,
      street,
      street_number,
      zip,
      floor,
      department,
      extra_address
    },
    classes
  });

  const cityInputRef = useRef();

  const { handleCancelCopy, handleCopyAddress, copiedCity, setCopiedCity } = ButtonsCopyReset({
    buyerDepartment,
    buyerFloor,
    buyerStreet,
    buyerStreetNumber,
    buyerExtraAddress,
    buyerZip,
    buyerCity,
    buyerProvince,
    street,
    street_number,
    zip,
    floor,
    department,
    extra_address,
    city,
    province,
    formInitialValues,
    cityInputRef
  });

  const zipValidation = useCallback(async () => {
    if (!city.input.value) return;
    const [cityData] = await handleGetCity(city.input.value, province.input.value);
    const isValid = cityData?.list?.find((zipInner: number) => zipInner.toString() === zip.input.value.toString());
    setVerificateZip(!!isValid);
  }, [zip.input.value, city.input.value, province.input.value, handleGetCity]);

  const buyerZipValidation = useCallback(async () => {
    if (!buyerCity.input.value) return;
    const [cityData] = await handleGetCity(buyerCity.input.value, buyerProvince.input.value);
    const isValid = cityData?.list?.find((zipInner: number) => zipInner.toString() === buyerZip.input.value.toString());
    setVerificateZipBuyer(!!isValid);
  }, [buyerZip.input.value, buyerCity.input.value, buyerProvince.input.value, handleGetCity]);

  useEffect(() => {
    zipValidation();
  }, [zip.input.value, city.input.value, zipValidation]);

  useEffect(() => {
    buyerZipValidation();
  }, [buyerZip.input.value, buyerCity.input.value, buyerZipValidation]);

  const dialog = (
    <Dialog open={open} onClose={onCancel} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" className={classes.root}>
      <DialogTitle className={classes.modalTitle} id="alert-dialog-title">
        <Typography className={classes.title} variant="h3">
          {t(titleEditOrder || '')}
        </Typography>
      </DialogTitle>
      <form onSubmit={handleSubmit}>
        <DialogContent>
          <DialogContentText className={classes.modalContent} id="alert-dialog-description">
            {t('Buyer information')}
          </DialogContentText>
          <Grid xs={12}>
            {formFieldsBuyer &&
              formFieldsBuyer.map(({ field, errorField, label, ...props }: FormFieldsBuyerTypes) => (
                <TextField
                  key={props.name}
                  margin="normal"
                  InputLabelProps={{ shrink: true }}
                  value={field.input.value}
                  onChange={(e) => {
                    field.input.onChange(e.target.value);
                  }}
                  onBlur={field.input.onBlur}
                  className={props.class}
                  label={t(label)}
                  {...errorField}
                />
              ))}
          </Grid>
          <AccordionBuyerAddress
            classes={classes}
            errorBuyerAddress={errorBuyerAddress}
            verificateZipBuyer={verificateZipBuyer}
            buyerProvince={buyerProvince}
            buyerCity={buyerCity}
            errorBuyerProvince={errorBuyerProvince}
            errorBuyerCity={errorBuyerCity}
            formFieldsBuyerAddress={formFieldsBuyerAddress}
          />
          <Grid xs={12}>
            <DialogContentText className={classes.modalContent} id="alert-dialog-description">
              {t('Shipment address')}
            </DialogContentText>
            <Grid className={classes.buttonsShipmentDiv}>
              <Button color="primary" onClick={handleCancelCopy} className={classes.buttonCopy}>
                {t('Reset values')}
              </Button>
              <Button color="primary" onClick={handleCopyAddress} className={classes.buttonCopy}>
                {t('Copy buyer Address')}
              </Button>
            </Grid>
          </Grid>
          <Grid xs={12}>
            <SelectProvince
              name="province"
              inputProps={{
                onChange: province.input.onChange,
                value: province.input.value,
                classes: classes.textFieldSelect,
                error: errorProvince,
                onBlur: province.input.onBlur
              }}
            />
            <AutocompleteCity
              name="city"
              inputProps={{
                province: province.input.value,
                onChange: city.input.onChange,
                value: city.input.value,
                error: errorCity,
                onBlur: city.input.onBlur
              }}
              id="shipment-address"
              cityInputRef={cityInputRef}
              copiedCity={copiedCity}
              setCopiedCity={setCopiedCity}
            />
            {formFieldsShipment.map(({ field, errorField, label, customeValid, ...props }: FormFieldsBuyerTypes) => (
              <>
                <TextField
                  key={props.name}
                  margin="normal"
                  InputLabelProps={{ shrink: true }}
                  value={field.input.value}
                  onChange={field.input.onChange}
                  className={props.class}
                  label={t(label)}
                  onBlur={field.input.onBlur}
                  {...errorField}
                />
                {customeValid ? (
                  verificateZip ? (
                    ''
                  ) : (
                    <Typography className={classes.error} color="primary">
                      {`${t('The value do not match the city zip codes')} *`}
                    </Typography>
                  )
                ) : (
                  ''
                )}
              </>
            ))}
          </Grid>
          {errorForm[0] || !verificateZip || !verificateZipBuyer || errorBuyerAddress ? <Typography className={classes.errorForm}>{`${t('There are some errors on the fields, please fix them')} *`}</Typography> : ''}
        </DialogContent>
        <DialogActions className={classes.buttonText}>
          <Button
            onClick={() => {
              onCancel();
              setCancel(!cancel);
            }}
            color="primary"
          >
            {cancelText}
          </Button>
          {!verificateZip || !verificateZipBuyer || errorForm[0] || errorBuyerAddress ? (
            <Button disabled color="primary" type="submit" autoFocus>
              {confirmText}
            </Button>
          ) : (
            <Button color="primary" type="submit" autoFocus>
              {confirmText}
            </Button>
          )}
        </DialogActions>
      </form>
    </Dialog>
  );
  return !customTheme ? dialog : <ThemeProvider theme={customTheme}>{dialog}</ThemeProvider>;
};
