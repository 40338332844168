import React, { useMemo } from 'react';
import { Box, Theme, Tooltip, Typography, useMediaQuery } from '@material-ui/core';
import { Column } from '../../../components/InfoTable';
import { useTranslation } from '../../../../services/translation';
import { format } from '../../../../util';
import { useStyles } from './styles';

type TypeColumn = {
  name: string;
  total: number;
  count: number;
  id: string;
  tooltip_count?: number;
  tooltip_total?: number;
};

const emptyName = ['Discounts', 'Shipping cost', 'Sub total'];
const textBold = [...emptyName, 'Total'];

export default function ResportingSalesListConst() {
  const classes = useStyles();
  const isMd = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));

  const handleTooltipComponents = (value: any, formatInner: any) => {
    return (
      <>
        {' '}
        <Tooltip placement="top-end" title={`Odoo: ${value}` || ''}>
          <p>{formatInner}</p>
        </Tooltip>{' '}
      </>
    );
  };

  const { t } = useTranslation();

  const avgCommonColumn = useMemo(
    () => ({
      id: 'avg',
      label: 'AVG',
      render: (value: TypeColumn) => {
        const avg = format(value.total / value.count, 'ARS', t);
        return textBold.includes(value.name) ? <b>{avg}</b> : avg;
      },
      noSort: true
    }),
    [t]
  );

  const columns = useMemo<Array<Column>>(
    () => [
      {
        id: 'name',
        label: 'Marketplace',
        style: {
          whiteSpace: 'nowrap',
          width: '150px'
        },
        render: (value: TypeColumn) => (
          <Box fontWeight={textBold.includes(value.name) ? 'bold' : 'regular'}>
            <Typography noWrap variant="body1" color="textSecondary">
              {t(value.name)}
            </Typography>
          </Box>
        ),
        noSort: true
      },
      {
        id: 'count',
        label: 'Count',
        // render: (value: TypeColumn) => (textBold.includes(value.name) ?
        // <b>{format(value.count, 'Integer')}</b> : <Tooltip placement="top-end" title={value.tooltip_count?.toString()|| ''}>{<p>{format(value.count, 'Integer')}</p>}</Tooltip>),
        render: (value: TypeColumn) =>
          textBold.includes(value.name) ? (
            <b>{format(value.count, 'Integer')}</b>
          ) : value.tooltip_count ? (
            handleTooltipComponents(format(value.tooltip_count, 'Integer'), format(value.count, 'Integer'))
          ) : (
            format(value.count, 'Integer')
          ),
        align: 'right',
        noSort: true
      },
      avgCommonColumn,
      {
        id: 'total',
        label: 'Total',
        // render: (value: TypeColumn) => (textBold.includes(value.name) ?
        // <b>{format(value.total, 'Currency', t)}</b> : <Tooltip placement="top-end" title={value.tooltip_total?.toString()|| ''}>{<p>{format(value.total, 'Currency', t)}</p>}</Tooltip>),
        render: (value: TypeColumn) =>
          textBold.includes(value.name) ? <b>{format(value.total, 'ARS', t)}</b> : value.tooltip_total ? handleTooltipComponents(format(value.tooltip_total, 'ARS', t), format(value.total, 'ARS', t)) : format(value.total, 'ARS', t),
        align: 'right'
      }
    ],
    // eslint-disable-next-line
    [t, avgCommonColumn]
  );

  const columnsProducts: Array<Column> = [
    {
      id: 'name',
      label: 'Products',
      style: {
        whiteSpace: 'nowrap'
      },
      render: (value: TypeColumn, item: TypeColumn, tr: (str: string) => string) => (
        <Tooltip title={`${tr(value.name)}`}>
          <Box maxWidth={isMd ? 400 : 100} minWidth={100}>
            <Typography noWrap variant="body1" color="textSecondary" align={textBold.includes(value.name) ? 'right' : 'left'} className={textBold.includes(value.name) ? classes.bold : ''}>
              {tr(value.name)}
            </Typography>
          </Box>
        </Tooltip>
      ),
      noSort: true
    },
    {
      id: 'count',
      label: 'Count',
      render: (value: TypeColumn) => {
        const text = emptyName.includes(value.name) ? '' : format(value.count, 'Integer');
        return textBold.includes(value.name) ? <b>{text}</b> : text;
      },
      align: 'right',
      noSort: true
    },
    avgCommonColumn,
    {
      id: 'total',
      label: 'Total',
      render: (value: TypeColumn, item: TypeColumn, tr: (str: string) => string) => (textBold.includes(value.name) ? <b>{format(value.total, 'ARS', tr)}</b> : format(value.total, 'ARS', tr)),
      align: 'right'
    }
  ];

  const columnsProductsCategory: Array<Column> = [
    {
      id: 'name',
      label: 'Category',
      style: {
        whiteSpace: 'nowrap'
      },
      render: (value: TypeColumn, item: TypeColumn, tr: (str: string) => string) => (
        <Tooltip title={`${tr(value.name)}`}>
          <Box maxWidth={isMd ? 400 : 100} minWidth={100}>
            <Typography noWrap variant="body1" color="textSecondary" align={textBold.includes(value.name) ? 'right' : 'left'} className={textBold.includes(value.name) ? classes.bold : ''}>
              {tr(value.name)}
            </Typography>
          </Box>
        </Tooltip>
      ),
      noSort: true
    },
    {
      id: 'count',
      label: 'Count',
      render: (value: TypeColumn) => {
        const text = emptyName.includes(value.name) ? '' : format(value.count, 'Integer');
        return textBold.includes(value.name) ? <b>{text}</b> : text;
      },
      align: 'right',
      noSort: true
    },
    avgCommonColumn,
    {
      id: 'total',
      label: 'Total',
      render: (value: TypeColumn, item: TypeColumn, tr: (str: string) => string) => (textBold.includes(value.name) ? <b>{format(value.total, 'ARS', tr)}</b> : format(value.total, 'ARS', tr)),
      align: 'right'
    }
  ];

  const columnsProductsBrand: Column[] = [
    {
      id: 'name',
      label: 'Brand',
      style: {
        whiteSpace: 'nowrap'
      },
      render: (value: TypeColumn, item: TypeColumn, tr: (str: string) => string) => (
        <Tooltip title={`${tr(value.name)}`}>
          <Box maxWidth={isMd ? 400 : 100} minWidth={100}>
            <Typography noWrap variant="body1" color="textSecondary" align={textBold.includes(value.name) ? 'right' : 'left'} className={textBold.includes(value.name) ? classes.bold : ''}>
              {tr(value.name)}
            </Typography>
          </Box>
        </Tooltip>
      ),
      noSort: true
    },
    {
      id: 'count',
      label: 'Count',
      render: (value: TypeColumn) => {
        const text = emptyName.includes(value.name) ? '' : format(value.count, 'Integer');
        return textBold.includes(value.name) ? <b>{text}</b> : text;
      },
      align: 'right',
      noSort: true
    },
    avgCommonColumn,
    {
      id: 'total',
      label: 'Total',
      render: (value: TypeColumn, item: TypeColumn, tr: (str: string) => string) => (textBold.includes(value.name) ? <b>{format(value.total, 'ARS', tr)}</b> : format(value.total, 'ARS', tr)),
      align: 'right'
    }
  ];

  return {
    columns,
    columnsProducts,
    columnsProductsCategory,
    columnsProductsBrand
  };
}
