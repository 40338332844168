import React, { useCallback } from 'react';
import { Moment } from 'moment';
import { useField, useForm } from 'react-final-form-hooks';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { ThemeProvider } from '@material-ui/styles';
import { makeGetErrorAndHelperText, textFieldProps } from '../../../utils';
import { useModalFormStyles } from '../../../theme';
import { useTranslation } from '../../../services/translation';
import { FormProps } from '../../../types';
import { ResetForm } from '../../../components/ResetForm';

export type CreateDispatchType = {
  courier: number;
  courier_service: number;
  dispatch_zone: number;
  observation: string;
  delivery_date: Moment;
  name: string;
};

export type MessageRecipientFormConfirmType = {
  email: string;
};

export const MessageRecipientForm = ({
  open = false,
  onCancel = () => {},
  onConfirm = () => {},
  title = 'Confirmation Dialog',
  cancelText = 'Cancel',
  confirmText = 'confirmText',
  customTheme = null,
  validate,
  formInitialValues = {}
}: FormProps) => {
  const { t } = useTranslation();
  const styles = useModalFormStyles();
  const getErrorAndHelperText = makeGetErrorAndHelperText(t);

  const onSubmit = useCallback(
    async ({ email }) => {
      const result: MessageRecipientFormConfirmType = {
        email
      };
      onConfirm(result);
    },
    [onConfirm]
  );

  const { form, handleSubmit, submitError } = useForm({
    initialValues: formInitialValues,
    onSubmit,
    validate
  });

  const email = useField('email', form);

  const dialog = (
    <Dialog open={open} onClose={onCancel} fullWidth aria-labelledby="form-dialog-title">
      <DialogContent className={styles.title}>
        <Typography variant="h3">{t(title)}</Typography>
      </DialogContent>
      <form noValidate onSubmit={handleSubmit}>
        <DialogContent className={styles.content}>
          <TextField
            {...textFieldProps(`${t('Email')}`)}
            onChange={email.input.onChange}
            value={email.input.value}
            variant="standard"
            multiline
            InputLabelProps={{
              shrink: true,
              ...getErrorAndHelperText(email)
            }}
          />

          {submitError && (
            <Typography style={{ display: 'block' }} variant="overline" color="error">
              {t(submitError)}
            </Typography>
          )}
        </DialogContent>
        <DialogActions>
          <ResetForm form={form} open={open} />
          <Button
            onClick={() => {
              onCancel();
            }}
          >
            {t(cancelText)}
          </Button>
          <Button color="primary" type="submit">
            {t(confirmText)}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );

  return !customTheme ? dialog : <ThemeProvider theme={customTheme}>{dialog}</ThemeProvider>;
};
