import { makeValidator, ValidatorType, ValidatorRule } from '../../../../../utils';

export const featureGroupInitialValuesForm = {
  name: '',
  searchable: false,
  order: ''
};

const validations: ValidatorRule[] = [
  { key: 'name', validators: [ValidatorType.REQUIRED] },
  { key: 'order', validators: [ValidatorType.REQUIRED, ValidatorType.NUMBER] }
];

export const validate = makeValidator(validations);

export const getValidate = (keys: Array<string>) => makeValidator(keys.length > 0 ? validations.filter((val) => keys.includes(val.key)) : validations);
