import React from 'react';
import { Box, capitalize } from '@material-ui/core';

export const CommonProdutColumns = {
  actions: {
    id: 'actions',
    label: '',
    noSort: true
  },
  productId: {
    id: 'product_id',
    label: 'ID Producto',
    style: {
      whiteSpace: 'nowrap',
      minWidth: '100px'
    }
  },
  name: {
    id: 'name',
    label: 'Name',
    style: {
      whiteSpace: 'nowrap',
      minWidth: '100px'
    }
  },
  description: {
    id: 'description',
    label: 'Description',
    style: {
      whiteSpace: 'nowrap',
      minWidth: '100px'
    }
  },
  sku: {
    id: 'sku',
    label: 'SKU',
    style: {
      whiteSpace: 'nowrap',
      minWidth: '100px'
    }
  },
  category: {
    id: 'category',
    label: 'Category',
    style: {
      whiteSpace: 'nowrap',
      minWidth: '100px'
    }
  },
  brand: {
    id: 'brand',
    label: 'Brand',
    style: {
      whiteSpace: 'nowrap',
      minWidth: '100px'
    }
  },
  attributes: {
    id: 'attributes',
    label: 'Attributes',
    style: {
      whiteSpace: 'nowrap',
      minWidth: '100px'
    }
  },
  price: {
    id: 'price',
    label: 'Price',
    style: {
      whiteSpace: 'nowrap',
      minWidth: '100px'
    }
  },
  productType: {
    id: 'productType',
    label: 'Tipo',
    style: {
      whiteSpace: 'nowrap',
      minWidth: '100px'
    }
  },
  serial_number: {
    id: 'come_serial_number',
    label: 'N° Serie',
    style: {
      whiteSpace: 'nowrap',
      minWidth: '100px'
    },
    render: ({ come_serial_number }: any, item: any, t: (str: string) => string) => (
      <Box color={come_serial_number ? '#04B208' : '#B20404'} whiteSpace="nowrap">
        {t(capitalize(come_serial_number ? 'Yes' : 'No'))}
      </Box>
    )
  },
  active: {
    id: 'active',
    label: 'Active',
    style: {
      whiteSpace: 'nowrap',
      minWidth: '100px'
    },
    render: ({ active }: any, item: any, t: (str: string) => string) => (
      <Box color={active ? '#04B208' : '#B20404'} whiteSpace="nowrap">
        {t(capitalize(active ? 'Active' : 'Inactive'))}
      </Box>
    )
  },
  edit: {
    id: 'edit',
    label: 'Edit',
    noSort: true
  },
  manage: {
    id: 'manage',
    label: 'Manage',
    noSort: true
  }
};

export const FeaturesGroupColumns = {
  feature_group_id: {
    id: 'feature_group_id',
    label: 'Group ID',
    noSort: true,
    style: {
      whiteSpace: 'nowrap',
      minWidth: '60px'
    }
  },
  name: {
    id: 'name',
    label: 'Name',
    style: {
      whiteSpace: 'nowrap',
      minWidth: '100px'
    }
  },
  searchable: {
    id: 'searchable',
    label: 'Searchable',
    style: {
      whiteSpace: 'nowrap',
      minWidth: '60px'
    }
  },
  product_id: {
    id: 'product_id',
    label: 'Product ID',
    style: {
      whiteSpace: 'nowrap',
      minWidth: '60px'
    }
  },
  order: {
    id: 'order',
    label: 'Order',
    style: {
      whiteSpace: 'nowrap',
      minWidth: '60px'
    }
  },
  action: {
    id: 'action',
    label: 'Action',
    noSort: true
  }
};
export const FeaturesColumns = {
  feature_group_id: {
    id: 'feature_group_id',
    label: 'Group ID',
    noSort: true,
    style: {
      whiteSpace: 'nowrap',
      minWidth: '60px'
    }
  },
  name: {
    id: 'name',
    label: 'Name',
    style: {
      whiteSpace: 'nowrap',
      minWidth: '100px'
    }
  },
  value: {
    id: 'value',
    label: 'Value',
    style: {
      whiteSpace: 'nowrap',
      minWidth: '100px'
    }
  },
  searchable: {
    id: 'searchable',
    label: 'Searchable',
    style: {
      whiteSpace: 'nowrap',
      minWidth: '60px'
    }
  },
  order: {
    id: 'order',
    label: 'Order',
    style: {
      whiteSpace: 'nowrap',
      minWidth: '60px'
    }
  },
  action: {
    id: 'action',
    label: 'Action',
    noSort: true
  }
};
