import { AnyObject } from '../../../../../types/types';

type MakeFilterProps = {
  documentSearch?: string;
};

export const makeFilter = ({ documentSearch }: MakeFilterProps) => {
  let filter: AnyObject = {};

  if (documentSearch) {
    filter = documentSearch.split(' ').reduce((accumulator: AnyObject, item, index) => {
      accumulator[index] = [
        {
          path: 'document',
          value: item,
          method: 'includes'
        }
      ];

      const numberSearch = parseInt(item, 10);
      if (numberSearch) {
        accumulator[index].push({
          path: 'document',
          value: numberSearch
        });
      }
      return accumulator;
    }, {});
  }
  console.log('filter!!!', filter);

  return filter;
};
