import { LibbyFetchDAO } from '../LibbyFetchDAO';

export class MarketplaceChargebackDAO extends LibbyFetchDAO {
  constructor() {
    super('ster_marketplace_chargeback', 'chargeback_marketplace_id');
  }

  getAll() {
    return this.query().limit(0, 10000).run();
  }

  getMarketplaceSlaByID(id: string) {
    return this.query().equals('chargeback_marketplace_id', id).run();
  }
}
