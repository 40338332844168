import { Box } from '@material-ui/core';
import React from 'react';
import { Alert } from '../../../../interfaces/business';
import { formatDate } from '../../../../utils';
import { convertMinsToHrsMins, getHourLocal } from '../../../../utils/frecuendyHours';
import { RecipentsShow } from '../../../Messages/Components';

export const columnsAlertForm = [
  {
    id: 'options',
    label: '',
    width: '40px',
    style: {
      minWidth: '30px'
    },
    noSort: true
  },
  {
    id: 'id',
    label: 'Alert ID',
    width: '60px',
    style: {
      minWidth: '80px'
    },
    noSort: true
  },
  {
    id: 'name',
    label: 'Name',
    width: '180px',
    style: {
      minWidth: '180px'
    }
  },
  {
    id: 'view_name',
    label: 'View name',
    width: '180px',
    style: {
      minWidth: '180px'
    }
  },
  {
    id: 'email_title',
    label: 'Title',
    width: '180px',
    style: {
      minWidth: '180px'
    }
  },
  {
    id: 'email_to',
    label: 'To',
    width: '180px',
    style: {
      minWidth: '180px'
    },
    render: ({ email_to }: Alert) => {
      return <RecipentsShow message_to={email_to} message_id="" />;
    }
  },
  {
    id: 'frequency',
    label: 'Frequency',
    width: '180px',
    style: {
      minWidth: '180px'
    },
    render: ({ frequency }: Alert) => convertMinsToHrsMins(frequency)
  },
  {
    id: 'hours',
    label: 'Hour',
    width: '180px',
    style: {
      minWidth: '180px'
    },
    render: ({ hour }: Alert) => (typeof hour === 'string' ? `${getHourLocal(hour)}` : '')
  },
  {
    id: 'last_execution',
    label: 'Last execution',
    width: '180px',
    style: {
      minWidth: '180px'
    },
    render: ({ last_execution }: { last_execution: Date }) => <Box>{formatDate(last_execution ?? '', true, false)}</Box>
  },
  {
    id: 'status_send',
    label: 'Status sent',
    width: '180px',
    style: {
      minWidth: '180px'
    },
    render: ({ status_send }: Alert, item: any, t: (str: string) => string) => (
      <Box whiteSpace="nowrap" textAlign="center">
        {t(status_send ? 'Sent data' : 'No data to send')}
      </Box>
    )
  },
  {
    id: 'success',
    label: 'Success',
    width: '180px',
    style: {
      minWidth: '180px'
    },
    render: ({ success }: Alert, item: any, t: (str: string) => string) => (
      <Box whiteSpace="nowrap" textAlign="center">
        {t(success ? 'Sent' : 'Not sent')}
      </Box>
    )
  }
];
