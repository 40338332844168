import { LibbyFetchDAO } from '../LibbyFetchDAO';

export class OrderB2BDAO extends LibbyFetchDAO {
  constructor() {
    super('ster_order_b2b', 'order_b2b_id');
  }

  getAll() {
    return this.query().limit(0, 10000).run();
  }
}
