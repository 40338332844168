import React from 'react';
import ReactDOM from 'react-dom';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/styles';
import { GridFullHeight } from '../GridFullHeight';
import { usePortal } from '../../hooks';
import { AnyObject } from '../../types/types';

const useStyles = makeStyles((theme: AnyObject) => ({
  wrapper: {
    position: 'absolute',
    top: theme.mixins.toolbar.minHeight,
    bottom: 0,
    left: 0,
    right: 0,
    overflowY: 'scroll',
    zIndex: 1000
  },
  backdrop: {
    top: theme.mixins.toolbar.minHeight
  }
}));

export const LoadingModal = () => {
  const portal = usePortal('portals');
  const classes = useStyles();
  return ReactDOM.createPortal(
    <div className={classes.wrapper}>
      <Backdrop open className={classes.backdrop} />
      <GridFullHeight>
        <Grid item>
          <CircularProgress size={40} />
        </Grid>
      </GridFullHeight>
    </div>,
    portal
  );
};
