import { ORDER_STATE } from '../../../../../const';
import { ACCOUNT_ROLE } from '../../../../../const/accountRole';
import { useRolesUser } from '../../../../../hooks';

export const useOrderStateEnabled = () => {
  const rolAdminLogistics = useRolesUser({
    rolesEnabled: [ACCOUNT_ROLE.ADMINISTRATOR, ACCOUNT_ROLE.LOGISTICS]
  });

  return rolAdminLogistics ? ORDER_STATE.WAITING_FOR_PREPARATION.toString() : ORDER_STATE.READY_FOR_DELIVERY.toString();
};
