import React from 'react';
import { ScreenAligned } from '../../../components/ScreenAligned/ScreenAligned';
import { useTranslation } from '../../../services/translation';
import { LibbyObject } from '../../../types';
import InfoTable from '../../components/InfoTable';
import LoadingData from '../../components/LoadingData';
import { ReportingByTransportLogic } from '../hooks';
import { SearchFilters } from '../components';

interface ReportingTransportListTypes {
  title: string;
  libby: LibbyObject;
}

export const ReportingTransportList = ({ title, libby }: ReportingTransportListTypes) => {
  const { t } = useTranslation();

  const { columns, rows, setFilter, filter, orderBy, direction, handleRequestSort, working, fetchMore } = ReportingByTransportLogic(libby);

  return (
    <ScreenAligned title={title}>
      <SearchFilters onFilterChange={setFilter} filter={filter} />
      <InfoTable columns={columns} rows={rows} onSortChange={handleRequestSort} orderBy={orderBy} direction={direction} onBottomScroll={fetchMore} />
      <LoadingData label={`${t('Number of')} ${t('Orders').toLocaleLowerCase()}`} working={working} cant={rows.length} />
    </ScreenAligned>
  );
};
