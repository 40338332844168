import { useCallback, useMemo, useState } from 'react';
import axios from 'axios';
import { API_URL } from 'config';
import { GET_ORDER_STATE_TURN } from 'const';
import { TokenManager } from 'platform/libby/TokenManager';
import { Filter } from 'types';

interface OrderStateAgregation {
  countofstate_name: string;
  state_name: string;
  sumofamount: string;
}

export const useGetOrderStateTurn = (filterOrders: Filter) => {
  const [ordersPendingTurn, setOrdersPendingTurn] = useState<OrderStateAgregation[]>([]);

  const filtersParam = useMemo(() => {
    const paramList: string[] = [];
    const filterObj = { ...filterOrders };
    const keys = Object.keys(filterObj);

    keys.forEach((key) => {
      if (['from', 'to'].includes(key)) {
        filterObj[key] = filterObj[key].map(({ value }) => ({ path: key, value }));
      }

      const param = filterObj[key].reduce((acc, item, index, array) => {
        const valuePart = index === 0 ? `${item.path}=${item.value}` : `${item.value}`;
        return acc + (index < array.length - 1 ? `${valuePart},` : valuePart);
      }, '');

      paramList.push(param);
    });

    return paramList.join('&');
  }, [filterOrders]);

  const fetchOrderStateTurn = useCallback(async () => {
    const baseUrl = `${API_URL}/${GET_ORDER_STATE_TURN}`;
    let queryParams = `${filtersParam}`;

    const response = await axios.get(`${baseUrl}?${queryParams}`, {
      headers: {
        'x-chino-token': await TokenManager.create().retrieve()
      }
    });

    setOrdersPendingTurn(response.data);
  }, [filtersParam]);

  return {
    fetchOrderStateTurn,
    ordersPendingTurn
  };
};
