import React from 'react';
import { useTranslation } from '../../../services/translation';
import { ScreenAligned } from '../../../components/ScreenAligned/ScreenAligned';
import { useRowsAlertForm } from './hooks/useRowsAlertForm';
import InfoTable from '../../components/InfoTable';
import { columnsAlertForm } from './utils/ColumnsAlertForm';

import LoadingData from '../../components/LoadingData';
import { useAlert } from '../hooks/AlertLogic';
import { SearchFilters } from '../components/SearchFilter';

export const AlertList = () => {
  const { t } = useTranslation();
  const { alert, openAlertEdit, openAlertEditHour, handleRequestSort, orderBy, direction, working, setFilter, filter } = useAlert();
  const { rows } = useRowsAlertForm({ alert, openAlertEdit, openAlertEditHour });
  return (
    <>
      <ScreenAligned title={t('Alerts')}>
        <SearchFilters onFilterChange={setFilter} filter={filter} />
        <InfoTable columns={columnsAlertForm} rows={rows} onSortChange={handleRequestSort} orderBy={orderBy} direction={direction} />
        <LoadingData label={`${t('Number of')} ${t('Alert').toLocaleLowerCase()}`} working={working} cant={rows.length} />
      </ScreenAligned>
    </>
  );
};
