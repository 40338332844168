import React, { FC, ReactNode, createContext, useContext, useMemo } from 'react';
import { usePaymentMethods, useClients, useProducts, useProvinces, useBillingAddress, useShippingAddress, useTransport, useSaveBudget, useAdvanceSale, useCleanBudget } from './hooks';
import { BudgetContextValue, BudgetInitialValues } from './types/BudgetContextValue';

export const BudgetContext = createContext<BudgetContextValue>({
  editBillingAddress: () => {},
  billingAddress: {
    billingAddressOptions: [],
    billingAddresses: [],
    selectBillingAddress: '',
    selectedBillingAddress: '',
    selectedBillingCity: '',
    selectedBillingState: {
      state_id: '',
      value: ''
    },
    selectedBillingStreet: '',
    selectedBillingStreetNumber: '',
    selectedBillingZipCode: '',
    selectedBillingFloor: '',
    selectedBillingDepartment: ''
  },
  selectedClient: {},
  shippingAddress: {
    shippingAddressOptions: [],
    shippingAddresses: [],
    selectShippingAddress: '',
    selectedShippingAddress: '',
    selectedShippingCity: '',
    selectedShippingCityId: '',
    selectedShippingState: {
      state_id: '',
      value: ''
    },
    selectedShippingStreet: '',
    selectedShippingStreetNumber: '',
    selectedShippingZipCode: '',
    selectedShippingFloor: '',
    selectedShippingDepartment: '',
    selectedShippingComments: ''
  },
  transport: null,
  sellType: null,
  paymentMethods: [],
  products: [],
  addProductRow: () => {},
  editProductRow: () => {},
  removeProductRow: () => {},
  addPaymentMethod: () => {},
  editPaymentMethod: () => {},
  removePaymentMethod: () => {},
  provinces: [],
  selectedProvince: {},
  setSelectedProvinces: () => {},
  addBillingAddressOptions: () => {},
  pushBillingAddressOptions: () => {},
  selectBillingAddress: () => {},
  fetchingProvinces: true,
  addShippingAddressOptions: () => {},
  pushShippingAddressOptions: () => {},
  selectShippingAddress: () => {},
  selectedTransport: {
    id: '',
    value: ''
  },
  setSelectedTransport: () => {},
  setSelectedClient: () => {},
  transportationCompanyOptions: [],
  selectedTransportCompany: '',
  setSelectedTransportCompany: () => {},
  fetchingTransportationCompany: true,
  setTransportationCompanyOptions: () => {},
  recallTransportation: () => {},
  editShippingAddress: () => {},
  saving: false,
  onSaveNew: () => {},
  methodsProducts: [],
  advanceSale: {
    isAdvanceSale: false,
    advanceSaleDate: undefined
  },
  setAdvanceSale: () => {},
  // @ts-ignore
  cleanBudget: () => 'promise',
  validatingProduct: false,
  setValidatingProduct: () => {}
});

export interface BudgetContextProviderProps {
  children: ReactNode | ReactNode[];
  initialValues: BudgetInitialValues;
}

export const BudgetContextProvider: FC<BudgetContextProviderProps> = ({ children, initialValues }: BudgetContextProviderProps) => {
  const { products, addProductRow, editProductRow, removeProductRow, cleanProducts, validatingProduct, setValidatingProduct } = useProducts(initialValues);
  const { paymentMethods, addPaymentMethod, editPaymentMethod, removePaymentMethod, cleanPaymentMethod } = usePaymentMethods(initialValues);
  const { provinces, selectedProvince, setSelectedProvinces, fetchingProvinces } = useProvinces();
  const { selectedClient, setSelectedClient } = useClients(initialValues);
  const { addBillingAddressOptions, pushBillingAddressOptions, editBillingAddress, billingAddress, selectBillingAddress } = useBillingAddress(initialValues);
  const { editShippingAddress, addShippingAddressOptions, pushShippingAddressOptions, selectShippingAddress, shippingAddress } = useShippingAddress(initialValues);
  const { recallTransportation, setTransportationCompanyOptions, selectedTransport, setSelectedTransport, transportationCompanyOptions, fetchingTransportationCompany, selectedTransportCompany, setSelectedTransportCompany } =
    useTransport(initialValues);
  const { advanceSale, setAdvanceSale } = useAdvanceSale(initialValues);
  const { saving, onSaveNew, methodsProducts } = useSaveBudget(products, paymentMethods, selectedClient as any, shippingAddress, billingAddress, selectedTransport, advanceSale, selectedTransportCompany);
  const { cleanBudget } = useCleanBudget(cleanProducts, cleanPaymentMethod, setSelectedClient, setSelectedTransport, setSelectedTransportCompany, setTransportationCompanyOptions, setAdvanceSale);
  const value = useMemo<BudgetContextValue>(() => {
    return {
      transportationCompanyOptions,
      transport: null,
      sellType: null,
      setSelectedClient,
      fetchingProvinces,
      paymentMethods,
      products,
      addProductRow,
      editProductRow,
      removeProductRow,
      addPaymentMethod,
      editPaymentMethod,
      removePaymentMethod,
      selectedClient,
      provinces,
      selectedProvince,
      setSelectedProvinces,
      addBillingAddressOptions,
      pushBillingAddressOptions,
      shippingAddress,
      editBillingAddress,
      billingAddress,
      selectBillingAddress,
      editShippingAddress,
      addShippingAddressOptions,
      pushShippingAddressOptions,
      selectShippingAddress,
      selectedTransport,
      setSelectedTransport,
      selectedTransportCompany,
      setSelectedTransportCompany,
      fetchingTransportationCompany,
      setTransportationCompanyOptions,
      recallTransportation,
      saving,
      onSaveNew,
      methodsProducts,
      advanceSale,
      setAdvanceSale,
      cleanBudget,
      validatingProduct,
      setValidatingProduct
    };
  }, [
    transportationCompanyOptions,
    editPaymentMethod,
    setSelectedClient,
    selectBillingAddress,
    fetchingProvinces,
    addBillingAddressOptions,
    pushBillingAddressOptions,
    provinces,
    setSelectedProvinces,
    editBillingAddress,
    billingAddress,
    shippingAddress,
    selectedProvince,
    paymentMethods,
    products,
    addProductRow,
    editProductRow,
    removeProductRow,
    addPaymentMethod,
    removePaymentMethod,
    selectedClient,
    editShippingAddress,
    addShippingAddressOptions,
    pushShippingAddressOptions,
    selectShippingAddress,
    selectedTransport,
    setSelectedTransport,
    selectedTransportCompany,
    setSelectedTransportCompany,
    fetchingTransportationCompany,
    setTransportationCompanyOptions,
    recallTransportation,
    saving,
    onSaveNew,
    methodsProducts,
    advanceSale,
    setAdvanceSale,
    cleanBudget,
    validatingProduct,
    setValidatingProduct
  ]);
  return <BudgetContext.Provider value={value}>{children}</BudgetContext.Provider>;
};

export const useBudgetContext = () => useContext<BudgetContextValue>(BudgetContext);
