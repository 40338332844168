import React, { useCallback, useMemo } from 'react';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { useSnackbar } from 'notistack';
import { useFilerParamsId, useLibbyFetch } from '../../../hooks';
import { LibbyObject, RowsType } from '../../../types';
import CustomModal from '../../../services/customFormDialog';
import { BrandForm } from '../components/BrandForm';
import { useTranslation } from '../../../services/translation';
import { formInitialValues, validate, columns } from '../utils';
import { MakeCell } from '../../components/MakeCell';
import { ButtonDetailsType } from 'types/Button';
import { BrandBulkLoadForm } from '../routes/BrandList/components/BrandBulkLoadForm';
import { ModalTitle } from 'routes/Collection/common';
import ConfirmDialog from 'components/ConfirmDialog';
import Box from '@material-ui/core/Box';

type BrandFormType = {
  id?: string;
  name: string;
};
type BulkLoadFormResult = {
  message: string;
};

interface useCourierLogicProps {
  libby: LibbyObject;
}

const BrandFormModal = CustomModal(BrandForm);
const BrandBulkLoadFormModal = CustomModal(BrandBulkLoadForm);
const ConfirmModal = CustomModal(ConfirmDialog);

export const useBrandLogic = ({ libby }: useCourierLogicProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const { orderBy, direction, paramsFetch, handleRequestSort, setFilter, filter } = useFilerParamsId({
    orderInit: 'brand_id',
    daoName: 'ster_brand'
  });

  const { data, working, fetchMore, reFetch } = useLibbyFetch(libby, paramsFetch);
  // FIX ALL EDITING AND CREATING CATEGORIES, COULDN'T FINISH IT BECAUSE OF DATABASE CONSTRAINT ERROR NOT ALLOWING NULL brand_id
  const openBrandForm = useCallback(
    async (event: any, currentBrand?: RowsType) => {
      try {
        const formData = (await BrandFormModal.show({
          confirmText: t(currentBrand ? 'Update' : 'Create'),
          cancelText: t('Cancel'),
          title: `${t(currentBrand ? 'Update' : 'Create')} ${t('Brand')}`,
          validate,
          formInitialValues: currentBrand ? { name: currentBrand.name } : formInitialValues
        })) as BrandFormType;
        if (!formData) return;
        if (currentBrand?.brand_id) {
          await libby.ster_brand.save({ ...formData, brand_id: currentBrand.brand_id });
        } else {
          await libby.ster_brand.save(formData);
        }
        reFetch();
        enqueueSnackbar(t('Brand saved'), { variant: 'success' });
      } catch (error: any) {
        if (error !== false) {
          enqueueSnackbar(t('Something is wrong'), { variant: 'error' });
        }
      }
    },
    [t, libby, enqueueSnackbar, reFetch]
  );

  const handleOpenBrandBulkLoad = useCallback(async () => {
    try {
      const featuresBulkLoadResult = (await BrandBulkLoadFormModal.show({
        confirmText: t('Send'),
        cancelText: t('Cancel'),
        title: t('Features Bulk Load'),
        libby
      })) as BulkLoadFormResult;
      if (featuresBulkLoadResult) {
        reFetch();
        enqueueSnackbar(t(featuresBulkLoadResult.message), { variant: 'success' });
      }
    } catch (err) {
      //nothing
    }
  }, [t, enqueueSnackbar, libby, reFetch]);

  const handleOpenDeleteBrand = useCallback(
    async (e: MouseEvent, row: any) => {
      try {
        const isDeleted = await ConfirmModal.show({
          title: <ModalTitle title="Warning" error={true} />,
          content: `${t('Are you sure you want to delete the $$$$ Brand?').replace('$$$$', row.name)}`,
          confirmText: t('Accept'),
          oneButton: true
        });
        if (isDeleted) {
          await libby.ster_brand.remove({ ...row, brand_id: row.brand_id });
          reFetch();
          enqueueSnackbar(t('Brand deleted successfully'), { variant: 'success' });
        }
      } catch (err) {
        console.log(err);
      }
    },
    [t, enqueueSnackbar, reFetch, libby]
  );

  const rows: RowsType[] = useMemo(() => {
    const dataBrand = data as any[];

    return dataBrand.map((brand: any) => ({
      ...brand,
      id: brand.brand_id,
      actions: (
        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
          <MakeCell label="" icon={EditIcon} onClick={(e) => openBrandForm(e, brand)} />
          <MakeCell label="" icon={DeleteIcon} onClick={(e) => handleOpenDeleteBrand(e, brand)} />
        </Box>
      )
    }));
  }, [data, openBrandForm, handleOpenDeleteBrand]);

  const buttons: ButtonDetailsType[] = useMemo(
    () => [
      {
        id: 'CreateBrand',
        onClick: (e) => openBrandForm(e),
        title: t('Create Brand'),
        variant: 'contained'
      },
      {
        id: 'BulkLoad',
        onClick: handleOpenBrandBulkLoad,
        title: t('Bulk'),
        variant: 'contained'
      }
    ],
    [t, openBrandForm, handleOpenBrandBulkLoad]
  );

  return {
    rows,
    columns,
    working,
    fetchMore,
    handleRequestSort,
    orderBy,
    direction,
    setFilter,
    filter,
    buttons
  };
};
