import React, { useCallback, useMemo, MouseEvent } from 'react';
import LaunchIcon from '@material-ui/icons/Launch';
import { useSnackbar } from 'notistack';
import { LibbyObject } from '../../../../../types/types';
import { MakeCell } from '../../../../components/MakeCell';
import CustomModal from '../../../../../services/customFormDialog';
import { useLibbyFetch, OptionsLibbyFetch } from '../../../../../hooks/useLibbyFetch';
import { ItemsTableSearch } from '../../../../components/ItemsTableSearch';
import { DocumentsTableSearch } from '../../../../components/DocumentsTableSearch';
import { RowsType } from '../../../../../types';
import { columnsOrderB2B } from '../../../../../models/orders/columns';
import { useOrderToRows } from '../../../../../models/orders/rows';
import { ExportDialogComponent } from '../../../../../components/ExportDialog';
import { Order } from '../../../../../interfaces/business';
import { MenuActions, MenuItemComponentType } from '../../../../../components/MenuActions';
import { useStateOrder } from './useStateOrder';
import { AllOrderCancel, DelivereOrders, MENU, MENU_ACTIONS, OrderState, ORDER_STATE } from '../../../../../const';
import { useTranslation } from '../../../../../services/translation';
import { useCheckAll } from '../../../../../hooks';
import { B2BOrderColumns } from '../../../../../models/orders/B2BOrderColumns';
import { ButtonDetailsType } from '../../../../../types/Button';
import { ButtonComponent } from '../../../../../components/Button';
import { reducerOrderId, searchState } from '../utils/reduceOrderId';
import { subFilterValue } from '../../../../../utils/subFilterValue';
import { useMenuAction } from '../../../../../hooks/useMenuActions';
import { CommonOrderWithDeliveryColumns } from '../../../../../models/orders/CommonOrderWithDeliveryColumns';
import { useOrderLiberateDAO } from '../../../../../business/orders/order';
import confirmDialog from '../../../../../services/confirmDialog';

const SearchDialogModal = CustomModal(ExportDialogComponent);

export const handleOpenItemsModal = async (e: MouseEvent, items: string) => {
  e.stopPropagation();
  try {
    await SearchDialogModal.show({
      title: 'Items',
      id: 'order_item_id',
      properties: ['name', 'sku'],
      label: 'Item/Sku',
      data: items,
      maxWidth: 'md',
      render: () => <ItemsTableSearch order_id={items} />
    });
  } catch (error: any) {
    // nothing
  }
};

const handleOpenDocumentsModal = async (e: MouseEvent, order_id?: string | number) => {
  e.stopPropagation();
  try {
    await SearchDialogModal.show({
      title: 'Documents',
      id: 'order_document_id',
      properties: ['documentNumber', 'type.name'],
      label: 'Document',
      data: order_id,
      maxWidth: 'md',
      render: () => <DocumentsTableSearch orderId={order_id} />
    });
  } catch (error: any) {
    // nothing
  }
};

interface UseOrderTableLogicProps {
  libby: LibbyObject;
  paramsFetch: OptionsLibbyFetch;
  detailsRedirection: (order_id: string) => void;
  onCopyBudget: (order_id: string) => void;
  columnCheckEnabled?: boolean;
  actions?: boolean;
  path: string;
  deliveryDate?: boolean;
}

export const useOrderB2BTableLogic = ({ libby, paramsFetch, detailsRedirection, onCopyBudget, columnCheckEnabled = false, actions = false, path, deliveryDate = false }: UseOrderTableLogicProps) => {
  const { data, working, fetchMore, updateData, searchData, reFetch } = useLibbyFetch(libby, paramsFetch);
  const { enqueueSnackbar } = useSnackbar();

  const { t } = useTranslation();

  const OrderLiberateDAO = useOrderLiberateDAO();

  const { onChangeUpdateState, onChangeUpdateStateAll, onChangeUpdateStateAllWithRefer } = useStateOrder({
    libby
  });

  const {
    checked: { all },
    columnAll,
    handleCheckId,
    resetCheck
  } = useCheckAll(data, 'order_id');

  const updateDataField = useCallback(
    (order: Order) => {
      const searchOrder = searchData(order, 'order_id');
      const dataOrderAll = data as Order[];

      if (searchOrder > -1) {
        const orderUpdate: Order = { ...dataOrderAll[searchOrder] };
        updateData({ ...orderUpdate, ...order }, 'order_id');
      }
      resetCheck();
    },
    [updateData, data, searchData, resetCheck]
  );

  const getCommonRows = useOrderToRows(path);

  const optainPreviusStateId = useCallback(
    async (e: any, order: Order) => {
      e.stopPropagation();

      const dataUpdate: Order = await OrderLiberateDAO.save(order);

      updateDataField({
        ...dataUpdate
      });

      enqueueSnackbar(t('Order $$$ liberated').replace('$$$', order.order_id), {
        variant: 'success'
      });
    },
    [OrderLiberateDAO, t, updateDataField, enqueueSnackbar]
  );

  const menuArray = useCallback(
    (dataOrder: Order): MenuItemComponentType[] => {
      const state = dataOrder.state.order_state_id;
      const menu: MenuItemComponentType[] = [
        {
          title: 'Details',
          onClick: (e: MouseEvent) => {
            e.stopPropagation();
            detailsRedirection(dataOrder.order_id);
          },
          disabled: false
        },
        {
          title: 'Copy',
          onClick: (e: MouseEvent) => {
            e.stopPropagation();
            onCopyBudget(dataOrder.order_id);
          },
          disabled: false
        },
        {
          title: 'Cancel',
          onClick: (e: MouseEvent) => {
            e.stopPropagation();
            onChangeUpdateState({
              updateDataField,
              data: dataOrder,
              title: t('Cancel order'),
              content: `${t('Are you sure you want to cancel the order $$$$?').replace('$$$$', dataOrder.order_id)}`,
              state_id: ORDER_STATE.CANCELLED.toString()
            });
          },
          disabled: AllOrderCancel.includes(parseInt(state, 10)) || state === ORDER_STATE.BUDGET.toString(),
          color: 'error'
        },
        {
          title: t('Fraud'),
          onClick: (e: MouseEvent) => {
            e.stopPropagation();
            onChangeUpdateState({
              updateDataField,
              data: dataOrder,
              title: t('Cancel order'),
              content: `${t('Are you sure you want to cancel the order $$$$ for fraud?').replace('$$$$', dataOrder.order_id)}`,
              state_id: ORDER_STATE.CANCELLATION_FOR_FRAUD.toString()
            });
          },
          disabled: AllOrderCancel.includes(parseInt(state, 10)),
          color: 'error'
        },
        {
          title: t('Retain'),
          onClick: (e: MouseEvent) => {
            e.stopPropagation();
            onChangeUpdateState({
              updateDataField,
              data: dataOrder,
              title: t('Retain order'),
              content: `${t('Are you sure you want to retain the order $$$$?').replace('$$$$', dataOrder.order_id)}`,
              state_id: ORDER_STATE.RETAIN.toString(),
              typeUpdateState: 'Retained'
            });
          },
          disabled: [...DelivereOrders, ...AllOrderCancel].includes(parseInt(state, 10))
        },
        {
          title: t('Liberate'),
          onClick: async (e: MouseEvent) => {
            e.stopPropagation();
            const confirm = await confirmDialog.show({
              title: t('Liberate order'),
              content: `${t('Are you sure you want to liberate the order $$$$?').replace('$$$$', dataOrder.order_id)}`,
              confirmText: `${t('Yes')}!`,
              cancelText: t('No')
            });
            if (confirm) {
              optainPreviusStateId(e, dataOrder);
            }
          },
          disabled: state !== ORDER_STATE.RETAIN.toString()
        }
      ];
      return menu.filter((menuItems) => menuItems.disabled === false);
    },
    [onChangeUpdateState, updateDataField, detailsRedirection, t, optainPreviusStateId, onCopyBudget]
  );

  const rows: RowsType[] = useMemo(() => {
    const dataOrder = data as Order[];

    const status = subFilterValue(paramsFetch?.filter?.orderStatus);
    const dataUpdateWithState = status ? dataOrder.filter((order: Order) => status.includes(order.state.order_state_id)) : dataOrder;

    return dataUpdateWithState.map((order: Order) => {
      const { number_documents, number_items, order_id } = order;
      return {
        currency: order.currency.currency_symbol,
        select_order_id: handleCheckId(order_id),
        complete_name: order.complete_name!,
        actions: <MenuActions menuArray={menuArray(order)} />,
        ...getCommonRows(order),
        delivery_date: order.delivery_date,
        documents: <MakeCell label={String(number_documents ?? 0)} icon={LaunchIcon} onClick={(e) => handleOpenDocumentsModal(e, order_id || '')} />,
        items: <MakeCell label={String(number_items ?? 0)} icon={LaunchIcon} onClick={(e) => handleOpenItemsModal(e, order_id || '')} />
      };
    });
  }, [data, getCommonRows, menuArray, handleCheckId, paramsFetch]);

  const columns = useMemo(() => {
    let columnsOrderAll = [...columnsOrderB2B];

    if (actions) {
      columnsOrderAll = [B2BOrderColumns.actions, ...columnsOrderB2B];
    }
    if (columnCheckEnabled) {
      columnsOrderAll = [columnAll, ...columnsOrderB2B];
    }
    if (deliveryDate) {
      const positionCreatedAt = columnsOrderAll.findIndex(({ id }: { id: string }) => id === B2BOrderColumns.created_at.id);

      if (positionCreatedAt) columnsOrderAll.splice(positionCreatedAt + 1, 0, CommonOrderWithDeliveryColumns.delivery_date);
      else {
        columnsOrderAll.push(CommonOrderWithDeliveryColumns.delivery_date);
      }
    }
    return columnsOrderAll;
  }, [actions, columnCheckEnabled, columnAll, deliveryDate]);

  const { validateMenuActions, validateAction } = useMenuAction(MENU.CANAL_OFF_LINE);

  const buttonActions = useMemo((): ButtonDetailsType[] => {
    const allOrders = all as Order[];
    const ordersWaitingForApproval = searchState(allOrders, ORDER_STATE.WAITING_FOR_APPROVAL.toString());

    const ordersReadyForPreparation = searchState(allOrders, ORDER_STATE.READY_FOR_PREPARATION.toString());

    const ordersReadyForDeliver = searchState(allOrders, ORDER_STATE.OUT_FOR_DELIVERY.toString());

    const buttons: ButtonDetailsType[] = [
      {
        id: 'modifyStateOrderdeliver',
        onClick: async () => {
          const updateState = await onChangeUpdateStateAll({
            updateDataField,
            data: ordersReadyForDeliver,
            title: t('Change the status of orders'),
            content: `${t('Confirm delivery of selected orders')}`,
            state_id: ORDER_STATE.DELIVERED.toString(),
            typeUpdateState: 'delivered'
          });
          if (updateState) {
            onChangeUpdateStateAllWithRefer({
              updateDataField,
              data: ordersReadyForDeliver,
              title: t('The status was changed to Delivered. Do you want to upload receipts?'),
              state_id: ORDER_STATE.DELIVERED.toString(),
              typeUpdateState: 'delivered'
            });
          }
        },
        title: 'Deliver',
        disabled: !ordersReadyForDeliver.length,
        show: validateMenuActions(MENU_ACTIONS.DELIVERED.toString())
      },
      {
        id: 'modifyStateOrderWaitingForPrepartion',
        onClick: () => {
          const ordersReadyForPreparationId = ordersReadyForPreparation.reduce(reducerOrderId, []);
          onChangeUpdateStateAll({
            updateDataField,
            data: ordersReadyForPreparation,
            title: t('Change the status of orders'),
            content: `${t('Are you sure you want to move the following orders ($$$$) to the $$$ state?.').replace('$$$$', ordersReadyForPreparationId.join(',')).replace('$$$', t(OrderState[ORDER_STATE.WAITING_FOR_PREPARATION]))}`,
            state_id: ORDER_STATE.WAITING_FOR_PREPARATION.toString(),
            typeUpdateState: 'waiting for preparation'
          });
        },
        title: 'Prepare',
        disabled: !ordersReadyForPreparation.length,
        show: validateMenuActions(MENU_ACTIONS.PREPARE.toString())
      },
      {
        id: 'modifyStateOrderReadyForDelivery',
        onClick: () => {
          const ordersWaitingForApprovalId = ordersWaitingForApproval.reduce(reducerOrderId, []);
          onChangeUpdateStateAll({
            updateDataField,
            data: ordersWaitingForApproval,
            title: t('Change the status of orders'),
            content: `${t('Are you sure you want to move the following orders ($$$$) to the ready for delivery state?.').replace('$$$$', ordersWaitingForApprovalId.join(','))}`,
            state_id: ORDER_STATE.READY_FOR_DELIVERY.toString(),
            typeUpdateState: 'ready for delivery'
          });
        },
        title: 'Ready for delivery',
        disabled: !ordersWaitingForApproval.length,
        show: validateAction(MENU_ACTIONS.BUDGET_READY_FOR_DELIVERY)
      }
    ];
    return buttons.filter((button) => button.show);
  }, [all, onChangeUpdateStateAll, t, updateDataField, validateAction, validateMenuActions, onChangeUpdateStateAllWithRefer]);

  const allButtonsActions = (
    <>
      {buttonActions.map((dataButton) => (
        <ButtonComponent key={dataButton.id} title={dataButton.title} onClick={dataButton.onClick} className={dataButton.className} disabled={dataButton.disabled} />
      ))}
    </>
  );

  return {
    working,
    rows,
    columns,
    fetchMore,
    data,
    updateData,
    updateDataField,
    allButtonsActions,
    reFetch
  };
};
