import { useLibbyCall, LibbyCallOptions, LibbyCallReturn } from './useLibbyCall';

export type EntityLibbyCallOptions = Omit<LibbyCallOptions, 'daoName'>;

export const makeEntityLibbyCall =
  (daoName: string) =>
  <T>(options: EntityLibbyCallOptions): LibbyCallReturn<T> =>
    useLibbyCall<T>({
      daoName,
      ...options
    });
