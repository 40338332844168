import { Box } from '@material-ui/core';
import React from 'react';
import { Order_reporting_delivery_control_reporting_group } from '../../../../../../interfaces/business';
import { Column } from '../../../../../components/InfoTable';

export const columnsReportingDeliveryControlGroup: Array<Column> = [
  {
    id: 'sla_operative',
    label: 'SLA (Operative)',
    noSort: true
  },
  {
    id: 'sla_delivered',
    label: 'SLA (Delivered)',
    noSort: true
  },
  {
    id: 'marketplace',
    label: 'Marketplace',
    style: {
      whiteSpace: 'nowrap'
    },
    noSort: true
  },
  {
    id: 'quantity_operative',
    label: 'Quantity (Operative)',
    noSort: true
  },
  {
    id: 'quantity_operative',
    label: '% Quantity (Operative)',
    noSort: true,
    render: ({ quantity_percentage_operative }: any) => <Box>{quantity_percentage_operative >= 1 ? '100' : (quantity_percentage_operative * 100).toFixed(2)}%</Box>
  },
  {
    id: 'quantity_delivered',
    label: 'Quantity (Delivered)',
    noSort: true
  },
  {
    id: 'quantity_delivered',
    label: '% Quantity (Delivered)',
    noSort: true,
    render: ({ quantity_percentage_delivered }: any) => <Box>{quantity_percentage_delivered >= 1 ? '100' : (quantity_percentage_delivered * 100).toFixed(2)}%</Box>
  },
  {
    id: 'total_hour_percentage_operative',
    label: 'Average time in hours (Operative)',
    noSort: true,
    render: ({ total_hour_percentage_operative }: Order_reporting_delivery_control_reporting_group) => <Box>{total_hour_percentage_operative}</Box>
  },
  {
    id: 'effectiveness_operative',
    label: 'Effectiveness (Operative)',
    noSort: true,
    render: ({ effectiveness_operative }: any) => <Box>{effectiveness_operative >= 1 ? '100' : (effectiveness_operative * 100).toFixed(2)}%</Box>
  },
  {
    id: 'total_hour_percentage_delivered',
    label: 'Average time in hours (Delivered)',
    noSort: true,
    render: ({ total_hour_percentage_delivered }: Order_reporting_delivery_control_reporting_group) => <Box>{total_hour_percentage_delivered}</Box>
  },
  {
    id: 'effectiveness_delivered',
    label: 'Effectiveness (Delivered)',
    noSort: true,
    render: ({ effectiveness_delivered }: any) => <Box>{effectiveness_delivered >= 1 ? '100' : (effectiveness_delivered * 100).toFixed(2)}%</Box>
  }
];
