export enum MENU_ACTIONS {
  DELIVERED = 1,
  PREPARE,
  SETTING,
  SETTINGS,
  EDIT_ORDER,
  EDIT_BUDGET,
  FILTER_OFFLINE_ORDERS,
  BUDGET_READY_FOR_DELIVERY,
  ORDER_READY_FOR_DELIVERY,
  SLCT_COURIER_FILTER_TRANSPORT,
  JUSTIFY,
  SLCT_ORDER_STATE_FILTER,
  SLCT_COURIER_FILTER_TALAVERA_DRIVER,
  FULL_PERMISSION_PRODUCT,
  HIGH_PERMISSION_PRODUCT,
  EDIT_PERMISSION_PRODUCT,
  DELETE_DISPATCH_ORDER,
  UPDATE_DISPATCH_ORDER_STATE
}
