import React from 'react';
import { Button, Grid, Link, Paper, Typography } from '@material-ui/core';
import { useTranslation } from '../../../../../../services/translation';
import { AnyObject } from '../../../../../../types';
import { KpiComponent } from '../KpiComponent';
import { CostTypes } from '../../types';

export const CostPaper = ({ values, classes }: { values: CostTypes; classes: AnyObject }) => {
  const { t } = useTranslation();

  const { url: urlWorkforce, ...kpiWorkforce } = values?.workforce;
  const { url: urlStructureCost, ...kpiStructureCost } = values?.structure_cost;

  return (
    <Grid item xs={12} sm={12} md={10} lg={4}>
      <Paper className={classes.paper}>
        <Grid container xs={12} justify="space-between" alignItems="center">
          <Typography variant="h4" className={classes.title}>
            {t('Company Cost')}
          </Typography>
        </Grid>
        {kpiWorkforce && (
          <Grid container xs={12}>
            <Grid container xs={12} justify="space-between" alignItems="center">
              <Typography variant="h5">{t('Workforce')}</Typography>
              {urlWorkforce && (
                <Button color="primary">
                  <Link target="_blank" href={urlWorkforce}>
                    {t('see more')}
                  </Link>
                </Button>
              )}
            </Grid>
            {Object.entries(kpiWorkforce).map(([key, value]: [string, string | number]) => (
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <KpiComponent name={key} value={value} />
              </Grid>
            ))}
          </Grid>
        )}
        {kpiStructureCost && (
          <Grid container xs={12}>
            <Grid container xs={12} justify="space-between" alignItems="center">
              <Typography variant="h5">{t('Structure Cost')}</Typography>
              {urlStructureCost && (
                <Button color="primary">
                  <Link target="_blank" href={urlStructureCost}>
                    {t('see more')}
                  </Link>
                </Button>
              )}
            </Grid>
            {Object.entries(kpiStructureCost).map(([key, value]: [string, string | number]) => (
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <KpiComponent name={key} value={value} />
              </Grid>
            ))}
          </Grid>
        )}
      </Paper>
    </Grid>
  );
};
