import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { DatabaseConnector } from '@phinxlab/libby-rest-web';
import { AccountCreateForm } from './components/AccountCreateForm';
import { useAddAccountForm } from './hooks/useAddAccountForm';

import { LibbyObject } from '../../../../types/types';
import { ScreenAligned } from '../../../../components/ScreenAligned/ScreenAligned';
import { TitleBreadcrumbs } from '../../../../interfaces';
import { useBreadcrumbs } from '../../../../hooks';
import { Account } from '../../../../interfaces/business';

type AddAccountProps = {
  title: TitleBreadcrumbs[];
  libby: LibbyObject;
  addCreate: (data: object) => void;
};

const AddAccountRaw = ({ libby, addCreate, title }: AddAccountProps) => {
  const history = useHistory();

  const [accountCreated, setAccountCreated] = useState<Account | null>(null);

  // when libby finish saving the account, push /acounts
  useEffect(() => {
    if (!libby.working && !!accountCreated) {
      history.push(`/accounts/${accountCreated && accountCreated.account_id}`);
    }
  }, [history, libby.working, accountCreated]);

  const { account, onCancelClick, handleSubmit, onSearchChange, name, last_name, username, password, email, account_role, submitError, duplicateAccount, isSearching, errors, duplicateAccountEmailData, loadingAccountEmailData } =
    useAddAccountForm({
      libby,
      addCreate
    });

  const titleShow = useBreadcrumbs('Create Account', title);

  return (
    <ScreenAligned title={titleShow}>
      <AccountCreateForm
        libby={libby}
        account={account}
        duplicateAccountEmailData={duplicateAccountEmailData}
        setAccountCreated={setAccountCreated}
        handleSubmit={handleSubmit}
        onSearchChange={onSearchChange}
        username={username}
        password={password}
        email={email}
        name={name}
        last_name={last_name}
        account_role={account_role}
        submitError={submitError}
        duplicateAccount={duplicateAccount}
        isSearching={isSearching}
        onCancelClick={onCancelClick}
        disabledSave={!!Object.keys(errors).length || !!duplicateAccountEmailData}
        loadingAccountEmailData={loadingAccountEmailData}
      />
    </ScreenAligned>
  );
};

export const AddAccount = DatabaseConnector(AddAccountRaw)('admin_account');
