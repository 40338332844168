export const columns = [
  {
    id: 'id',
    label: 'Category id',
    orderById: 'id'
  },
  {
    id: 'name',
    label: 'Name'
  },
  {
    id: 'actions',
    label: 'Actions',
    maxWidth: 200,
    noSort: true
  }
];
