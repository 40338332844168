import { Moment } from 'moment';

export const startDateFormat = (date: Moment) =>
  date.set({
    second: 0,
    millisecond: 0,
    minute: 0,
    hour: 0
  });

export const startDateFormatUtc = (date: Moment) => startDateFormat(date).utc().toISOString();

export const endDateFormat = (date: Moment) => date.set({ hour: 23, second: 59, minute: 59 });

export const endDateFormatUtc = (date: Moment) => endDateFormat(date).utc().toISOString();
