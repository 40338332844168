import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles(() => ({
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  root: {
    display: 'flex',
    marginBottom: '20px'
  },
  textError: {
    color: 'rgba(0, 0, 0, 0.38)'
  },
  textSuccess: {
    color: 'rgb(86, 181, 97)',
    fontWeight: 'bold'
  },
  validateBar: {
    width: '100%',
    height: '10px',
    marginTop: '10px',
    borderRadius: '4px',
    backgroundColor: '#d9e0e6'
  },
  validateBarInner: {
    height: '100%',
    borderRadius: '4px'
  },
  textValidate: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '10px'
  }
}));
