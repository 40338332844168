import { useMemo } from 'react';
import { ActiveStateType } from '../FraudSettingsForm';
import { ValidatorRule, ValidatorType, makeValidator } from 'utils';

export const useFraudSettingsFormLogic = ({ active }: { active: ActiveStateType }) => {
  const validate = useMemo(() => {
    const initialValidations: ValidatorRule[] = [];
    if (active.orders_in_range) initialValidations.push({ key: 'from', validators: [ValidatorType.REQUIRED] }, { key: 'to', validators: [ValidatorType.REQUIRED] });
    if (active.different_address) initialValidations.push({ key: 'rows_ammount', validators: [ValidatorType.NUMBER] });
    if (active.same_address) initialValidations.push({ key: 'documents_amount', validators: [ValidatorType.NUMBER] });
    if (active.multiple_ot) initialValidations.push({ key: 'multiple_ot_amount', validators: [ValidatorType.NUMBER] });
    if (active.multiple_quantity) initialValidations.push({ key: 'multiple_quantity', validators: [ValidatorType.NUMBER] });
    if (active.ot_price) initialValidations.push({ key: 'ot_price', validators: [ValidatorType.NUMBER] });
    return makeValidator(initialValidations);
  }, [active]);
  return {
    validate
  };
};
