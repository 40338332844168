import { useCallback } from 'react';
import { useSnackbar } from 'notistack';
import moment from 'moment';
import { useFilerParamsId } from '../../../hooks';
import { useTranslation } from '../../../services/translation';
import { useAlertUpdatedDAO, useAlertLibbyFetch } from '../../../business';
import { Alert } from '../../../interfaces/business';
import { MessageRecipientForm as EmailToForm } from '../../Messages/Components/MessageEmailForm';
import CustomModal from '../../../services/customFormDialog';
import { EmailHourForm } from '../components/MessageEmailHourForm';
import { convertFormatToHours, convertFormatToHoursTimeSystem, getHourLocal } from '../../../utils/frecuendyHours';

const EmailToDialogModal = CustomModal(EmailToForm);

const EmailHourDialogModal = CustomModal(EmailHourForm);

export const useAlert = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const { orderBy, direction, paramsFetch, handleRequestSort, setFilter, filter } = useFilerParamsId({
    orderInit: 'alert_id',
    daoName: 'ster_alert'
  });
  const { data: alerts, working, updateData } = useAlertLibbyFetch(paramsFetch);

  const alertDAO = useAlertUpdatedDAO();

  const openAlertEdit = useCallback(
    async (alert: Alert) => {
      const { email_to, alert_id } = alert;
      const formInitialValuesEdit = {
        email: email_to
      };

      try {
        const confirmEdit: any = await EmailToDialogModal.show({
          title: 'Edit email to',
          confirmText: 'Save',
          formInitialValues: formInitialValuesEdit
        });
        if (confirmEdit) {
          const { email } = confirmEdit;
          const newAlert: Alert = await alertDAO.save({
            ...alert,
            email_to: email
          });
          updateData(newAlert, 'alert_id');
          enqueueSnackbar(`${t('The email for the saved alert $$$$').replace('$$$$', alert_id)}`, { variant: 'success' });
        }
      } catch (e: any) {
        if (e)
          enqueueSnackbar(`${t('Error updating email to')}: ${e.message}`, {
            variant: 'error'
          });
      }
    },
    [t, alertDAO, enqueueSnackbar, updateData]
  );

  const openAlertEditHour = useCallback(
    async (alert: Alert) => {
      const { alert_id, hour } = alert;

      const oldHour = getHourLocal(hour);
      const oldHourFormatLocal = typeof oldHour === 'string' ? parseInt(oldHour, 10) : oldHour;
      const formInitialValuesEdit = {
        hour: convertFormatToHours(oldHourFormatLocal),
        hourAmPm: convertFormatToHoursTimeSystem(oldHourFormatLocal, true)
      };
      try {
        const confirmEdit: any = await EmailHourDialogModal.show({
          title: 'Edit hour',
          confirmText: 'Save',
          formInitialValues: formInitialValuesEdit
        });
        if (confirmEdit) {
          const { hour: hourEdit } = confirmEdit;

          const newAlert: Alert = await alertDAO.save({
            ...alert,
            hour: hourEdit !== null ? moment.utc(new Date(`${moment().format('YYYY-MM-DD')} ${hourEdit}:00:00.000`)).format('HH:mm:ss') : hourEdit
          });
          updateData(newAlert, 'alert_id');
          enqueueSnackbar(`${t('Updated $$$$ alert time successfully').replace('$$$$', alert_id)}`, { variant: 'success' });
        }
      } catch (e: any) {
        if (e)
          enqueueSnackbar(`${t('Error updating email to')}: ${e.message}`, {
            variant: 'error'
          });
      }
    },
    [t, alertDAO, enqueueSnackbar, updateData]
  );

  return {
    alert: alerts as Alert[],
    working,
    openAlertEdit,
    openAlertEditHour,
    handleRequestSort,
    orderBy,
    direction,
    setFilter,
    filter
  };
};
