import React, { useCallback, useMemo } from 'react';
import { Autocomplete } from '@material-ui/lab';
import { TextField } from '@material-ui/core';
import { useYearWeekLibbyFetch } from '../../business';
import { Week, YearWeek } from '../../interfaces/business';
import { DataGenerateSelectType } from '../../utils/generateSelect';
import { GeneralSelect } from '../GeneralSelect';
import { AnyObject } from '../../types';
import { useTranslation } from '../../services/translation';

type SelectYearWeekProps = {
  multiple?: boolean;
  inputProps: AnyObject;
  optionAll?: boolean;
  [k: string]: any;
  disabled?: boolean;
  sort?: boolean | 'asc' | 'desc';
  label?: string;
  year?: string;
};

export const SelectYearWeek = ({ multiple = false, inputProps = {}, optionAll = false, disabled = false, sort, label, year, ...styles }: SelectYearWeekProps) => {
  const { t } = useTranslation();
  const { data: yearWeeks, working } = useYearWeekLibbyFetch();

  const data = useMemo(() => {
    const fullYear = year || new Date().getFullYear();
    const dataYearWeek = yearWeeks as YearWeek[];

    const weeks = (dataYearWeek?.length && dataYearWeek.find((yearWeek) => yearWeek.year.toString() === fullYear.toString())?.weeks) || [];

    return weeks.map(({ number, start, end }: Week) => {
      return {
        id: `${start}.${end}`,
        value: `${t('Week')} ${Number(number)}, ${t('From')} ${start} ${t('To')} ${end}`
      };
    });
  }, [yearWeeks, t, year]);

  const onChangeAutoComplete = useCallback(
    (event, newValue: DataGenerateSelectType[]) => {
      inputProps.onChange(
        newValue.map((value: DataGenerateSelectType) => ({
          value: value.id,
          path: ''
        }))
      );
    },
    [inputProps]
  );

  return (
    <>
      {multiple ? (
        <Autocomplete
          disabled={disabled}
          style={{ display: 'inline-block', width: '100%' }}
          multiple
          id="tags-outlined"
          options={data || []}
          getOptionLabel={(option: { id: string; value: string }) => option.value}
          filterSelectedOptions
          onChange={onChangeAutoComplete}
          renderInput={(params) => <TextField {...params} variant="standard" label={t('Week')} placeholder="" />}
        />
      ) : (
        <GeneralSelect disabled={disabled} working={working} label="Week" optionAll={optionAll} data={data || []} inputProps={inputProps} {...styles} sort={false} />
      )}
    </>
  );
};
