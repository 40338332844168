import { ORDER_STATE } from '../../../../const';
import { InitialValuesFilter } from '../../../../routes/ReportB2BStock/hooks';
import { LibbyFetchDAO } from '../LibbyFetchDAO';

export class OrderReportB2BStockDAO extends LibbyFetchDAO {
  constructor() {
    super('ster_report_b2b_stock', 'order_id');
  }

  getAll() {
    return this.query().limit(0, 10000).run();
  }

  getByDate(filters: InitialValuesFilter) {
    const start = filters.startDate;
    const end = filters.endDate;
    return this.query()
      .groupStart()
      .equals('start_date', `${start} 00:00:00.000000`)
      .equals('end_date', `${end} 23:59:59.999999`)
      .equals('state', [ORDER_STATE.DRAFT, ORDER_STATE.DRAFT_REVISION, ORDER_STATE.BUDGET])
      .groupEnd()
      .run();
  }
}
