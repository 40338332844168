import React, { useMemo } from 'react';
import { DatabaseConnector } from '@phinxlab/libby-rest-web';
import { GeneralSelect } from '../../components/GeneralSelect';
import { Marketplace } from '../../interfaces/business';
import { useMarketplaceSlaUnassignedLibbyCall } from '../../business/marketplace';
import { InputProps } from '../../types/types';

type SelectMarketPlaceSlaUnassignedTypeProps = {
  inputProps: InputProps;
  optionAll?: boolean;
  [k: string]: any;
  sort?: boolean | 'asc' | 'desc';
};

export const SelectMarketPlaceSlaUnassignedRaw = ({ inputProps, optionAll = false, sort }: SelectMarketPlaceSlaUnassignedTypeProps) => {
  const { data: dataMarketplaceSlaUnassigned = [], working = true } = useMarketplaceSlaUnassignedLibbyCall({
    methodName: 'getAll'
  });

  const data = useMemo(() => {
    const dataMarketplaceSlaUnassignedType = dataMarketplaceSlaUnassigned as Marketplace[];
    const initData = inputProps.value.length
      ? [
          {
            id: inputProps.value,
            value: inputProps.name
          }
        ]
      : [];
    return dataMarketplaceSlaUnassignedType.reduce((allDataMarketplaceSlaUnassigned: any, dataMarketplaceSlaUnassignedValue: any) => {
      const copyAllDataMarketplaceSlaUnassigned = [...allDataMarketplaceSlaUnassigned];
      copyAllDataMarketplaceSlaUnassigned.push({
        id: dataMarketplaceSlaUnassignedValue.marketplace_id,
        value: dataMarketplaceSlaUnassignedValue.name
      });
      return copyAllDataMarketplaceSlaUnassigned;
    }, initData);
  }, [dataMarketplaceSlaUnassigned, inputProps]);

  return <GeneralSelect sort={sort} working={working} label="Marketplace" optionAll={optionAll} data={data} inputProps={inputProps} />;
};

export const SelectMarketPlaceSlaUnassigned = DatabaseConnector(SelectMarketPlaceSlaUnassignedRaw)('ster_order_source');
