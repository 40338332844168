import React, { useCallback, useMemo } from 'react';
import { Switch, Route, useRouteMatch, useHistory } from 'react-router-dom';
import { DatabaseConnector } from '@phinxlab/libby-rest-web';
import { ProductDetail } from './routes/ProductDetail';
import { ProductList } from './routes/ProductList';
import { useProductsTableLogic } from './routes/ProductList/hook/useProductsTableLogic';
import { LibbyObject } from '../../types/types';
import { useBreadcrumbs, useFilerParamsId, useQueryParams } from '../../hooks';
import { makeFilter } from './routes/ProductList/utils/makeFilter';
import { ProductCreate } from './routes/ProductCreate';
import { useGlobalContext } from '../../hooks';
import { userInfoType } from 'types';
import { UserProductPermissions } from '../../types/types';
import { authorizeAction } from 'utils/validateAuthorizeAction';
import { MENU_ACTIONS } from 'const';

export const ProductsRouter = ({ libby }: LibbyObject) => {
  const { path } = useRouteMatch();
  const {
    userInfo
  }: {
    userInfo: userInfoType;
  } = useGlobalContext();
  const history = useHistory();

  const detailsRedirection = useCallback((product_id: string) => history.push(`products/detail/${product_id}`), [history]);

  const validatePoductSearchhQueryParams = useQueryParams('productSearch');
  const validateCategoriesNameQueryParams = useQueryParams('category');
  const validateBrandQueryParams = useQueryParams('brand');

  const { paramsFetch, orderBy, filter, setFilter, resetFilter, direction, handleRequestSort } = useFilerParamsId({
    orderInit: 'product_id',
    daoName: 'ster_product',
    directionInit: 'desc',
    initAuxiliary: makeFilter({
      productSearch: validatePoductSearchhQueryParams || '',
      category: validateCategoriesNameQueryParams || '',
      brand: validateBrandQueryParams || ''
    })
  });
  const userProductPermissions: UserProductPermissions = useMemo(
    () => ({
      full_permission: authorizeAction(userInfo, MENU_ACTIONS.FULL_PERMISSION_PRODUCT),
      high_permission: authorizeAction(userInfo, MENU_ACTIONS.HIGH_PERMISSION_PRODUCT),
      edit_permission: authorizeAction(userInfo, MENU_ACTIONS.EDIT_PERMISSION_PRODUCT)
    }),
    [userInfo]
  );

  const { rows, columns, working, fetchMore, reFetch } = useProductsTableLogic({
    libby,
    paramsFetch,
    detailsRedirection,
    actions: true,
    path,
    userPermissions: userProductPermissions
  });

  const title = useBreadcrumbs('Products');

  /* Uncomment and pass it to ProductList as onRowClick prop if needed
  const onRowClick = (row: LibbyObject) => {
    history.push(`${path}/detail/${row.product_id}`);
  }; */

  return (
    <Switch>
      <Route path={`${path}/create`}>
        <ProductCreate libby={libby} title={title} userPermissions={userProductPermissions} />
      </Route>
      <Route path={`${path}/detail/:id`}>
        <ProductDetail title={title} userPermissions={userProductPermissions} libby={libby} />
      </Route>
      <Route path={`${path}`}>
        <ProductList
          title={title}
          pathUrl={`${path}`}
          setFilter={setFilter}
          rows={rows}
          columns={columns}
          working={working}
          filter={filter}
          fetchMore={fetchMore}
          onSortChange={handleRequestSort}
          orderBy={orderBy}
          direction={direction}
          reFetch={reFetch}
          resetFilter={resetFilter}
          userPermissions={userProductPermissions}
        />
      </Route>
    </Switch>
  );
};

export const Products = DatabaseConnector(ProductsRouter)('ster_product', 'ster_product_feature_group', 'ster_product_feature', 'ster_product_copy_features', 'ster_product_bulk_features');
