import { LibbyFetchDAO } from './LibbyFetchDAO';

export class YearWeekDAO extends LibbyFetchDAO {
  constructor() {
    super('ster_year_week', 'year_week_id');
  }

  getAll() {
    return (
      this.query()
        // .orderBy('name', 'asc')
        .limit(0, 10000)
        .run()
    );
  }
}
