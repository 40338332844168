import { LibbyFetchDAO } from '../LibbyFetchDAO';

export class GoalMarketplaceDAO extends LibbyFetchDAO {
  constructor() {
    super('ster_goal_marketplace', 'goal_marketplace_id');
  }

  getAllGoalMarketplace() {
    return this.query().limit(0, 10000).run();
  }
}
