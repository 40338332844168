import React, { MouseEvent, useCallback, useMemo, useState } from 'react';
import { useSnackbar } from 'notistack';
import AssignmentIcon from '@material-ui/icons/Assignment';
import { columns } from '../utils/columns';
import { useLibbyFetch } from '../../../hooks/useLibbyFetch';
import { LibbyObject, Order } from '../../../types';
import { makeFilter } from '../utils/makeFilter';
import { RowsType } from '../../../utils/tables';
import { formatDate } from '../../../utils';
import { MakeCell } from '../../components/MakeCell';
import { useBreadcrumbs } from '../../../hooks';
import { useTranslation } from '../../../services/translation';
import { MenuActions, MenuItemComponentType } from '../../../components/MenuActions';
import confirmDialog from '../../../services/confirmDialog';
import { ORDER_SHIPMENT_STATE, OrderShipmentState } from '../../../const';

type useReportingPendingAndreaniIncomeLogicProps = {
  libby: LibbyObject;
};

interface OrderRaw {
  order_id: string;
}

export const useReportingPendingAndreaniIncomeLogic = ({ libby }: useReportingPendingAndreaniIncomeLogicProps) => {
  const { enqueueSnackbar } = useSnackbar();

  const [filter, setFilter] = useState(
    makeFilter({
      startDate: null,
      endDate: null
    })
  );
  const { t } = useTranslation();

  const title = useBreadcrumbs('Report pending andreani income');

  const [orderBy, setOrderBy] = useState('order_id');
  const [direction, setDirection] = useState<'asc' | 'desc'>('asc');

  const paramsFetch = useMemo(
    () => ({
      daoName: 'ster_reporting_pending_andreani_income',
      filter,
      orderBy,
      direction
    }),
    [filter, orderBy, direction]
  );

  const { data, working, fetchMore, removeData } = useLibbyFetch(libby, paramsFetch);

  const handleRequestSort = useCallback((newOrderBy: string, newDirection: 'asc' | 'desc') => {
    setOrderBy(newOrderBy);
    setDirection(newDirection);
  }, []);

  const updateOrder = useCallback(
    async (order: Order) => {
      const confirm = await confirmDialog.show({
        title: t('Updated order as delivered'),
        content: t('Are you sure of perform this action?'),
        confirmText: `${t('Save')}`,
        cancelText: t('Cancel')
      });
      if (confirm) {
        try {
          await libby.ster_order_table.save({ ...order });
          await removeData(order.order_id?.toString() || '', 'order_id');
          enqueueSnackbar(t('Saved changes'), { variant: 'success' });
        } catch (e) {
          enqueueSnackbar(t('Something is wrong'), { variant: 'error' });
        }
      }
    },
    [t, enqueueSnackbar, libby, removeData]
  );

  const setOrderAsDelivered = useCallback(
    async (orderRaw: OrderRaw) => {
      const [order] = await libby.ster_order_table.findByPk(orderRaw.order_id);
      if (!order.shipment) return;
      const updatedOrder = { ...order };
      updatedOrder.shipment = { ...order.shipment, state: { order_shipment_state_id: ORDER_SHIPMENT_STATE.DELIVERED.toString(), name: OrderShipmentState[ORDER_SHIPMENT_STATE.DELIVERED] } };
      await updateOrder(updatedOrder);
    },
    [updateOrder, libby]
  );

  const menuArray = useCallback(
    (order: OrderRaw) => {
      const menu: MenuItemComponentType[] = [
        {
          title: 'Delivered',
          onClick: (e: MouseEvent) => {
            e.stopPropagation();
            setOrderAsDelivered(order);
          },
          disabled: false
        }
      ];
      return menu;
    },
    [setOrderAsDelivered]
  );

  const rows: RowsType[] = useMemo(
    () =>
      data.map((order: any) => ({
        id: order.order_id,
        order_id: order.order_id,
        owner_id: order.owner_id,
        so: order.so,
        courier: order.courier,
        courier_state: t(order.courier_state),
        dispatch_date: formatDate(order?.dispatch_date, true, false),
        detail: (
          <MakeCell
            label=""
            icon={AssignmentIcon}
            path={`orders/detail/${order?.order_id}`}
            params={{
              goBack: title
            }}
            onClick={(e) => {
              e.stopPropagation();
            }}
          />
        ),
        options: <MenuActions menuArray={menuArray(order as OrderRaw)} />
      })),
    [data, title, t, menuArray]
  );

  return {
    working,
    data,
    columns,
    fetchMore,
    handleRequestSort,
    orderBy,
    direction,
    filter,
    setFilter,
    rows,
    title
  };
};
