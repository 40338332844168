import { Company_marketplace } from '../interfaces/business/marketplace/CompanyMarketplace';
import { makeBusinessEntity, PartialBusinessEntityOptions } from '../lib/libby/generator/makeBusinessEntity';

import { businessOptions } from './businessOptions';

const DAO_NAME = 'ster_company_marketplace';

const defaultOptions: PartialBusinessEntityOptions<any> = {
  hoc: {
    withEntityAutocomplete: {
      optionLabelKey: 'marketplace.name'
    },
    withAccordionSelector: {
      idKey: 'marketplace.marketplace_id',
      labelKey: 'marketplace.name'
    },
    withSelectedChips: {
      idKey: 'marketplace.marketplace_id',
      labelKey: 'marketplace.name'
    }
  }
};

const optionsToUse = !businessOptions.Company
  ? defaultOptions
  : {
      ...defaultOptions,
      ...businessOptions.Company,
      hoc: { ...defaultOptions.hoc, ...businessOptions.Company.hoc }
    };

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch },
  hoc: { entityAutocomplete, renderPropCall, renderPropFetchById, simpleSelect, accordionSelector, selectedChips }
} = makeBusinessEntity<Company_marketplace>(DAO_NAME, optionsToUse);

// hooks
export const useCompanyMarketplaceFetchById = fetchById;
export const useCompanyMarketplaceLibbyCall = libbyCall;
export const useCompanyMarketplaceDAO = libbyDAO;
export const useCompanyMarketplaceLibbyFetch = libbyFetch;

// components/hoc
export const CompanyMarketplaceAutocomplete = entityAutocomplete;
export const CompanyMarketplaceCall = renderPropCall;
export const CompanyMarketplaceById = renderPropFetchById;
export const CompanyMarketplaceSelect = simpleSelect;
export const CompanyMarketplaceAccordionSelector = accordionSelector;
export const CompanyMarketplaceSelectedChips = selectedChips;
