// eslint-disable-next-line max-classes-per-file
import React, { Component, ComponentType } from 'react';
import { render } from 'react-dom';

type AnyProps = {
  [k: string]: any;
};

export type AsServiceOptions = {
  resolveClose: string;
  activeProp: string;
  resolveErrorClose: string;
  resolveProp: string;
  rejectProp: string;
  forceResolveOnReject: boolean;
  rejectValue: any | null;
  resolveValue?: any;
};

const asService =
  ({ activeProp, resolveProp, rejectProp, resolveClose, forceResolveOnReject = false, rejectValue = null, resolveValue, resolveErrorClose }: AsServiceOptions) =>
  (Comp: ComponentType<any>) => {
    type AsServiceProps = {
      createProps: AnyProps;
      setRef?: (service: AsService) => void;
    };

    type AsServiceState = {
      isOpen: boolean;
      isMovingBack: boolean;
      innerProps: AnyProps;
    };

    class ServiceInstanceHandler {
      get ref(): AsService {
        return this._ref;
      }

      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      private _ref: AsService = new AsService({ createProps: {} });

      constructor(props: any) {
        this.init(props);
      }

      init(props: AnyProps = {}) {
        const containerElement = document.createElement('div');
        document.body.appendChild(containerElement);
        return render(
          <AsService
            createProps={props}
            setRef={(service) => {
              this._ref = service;
            }}
          />,
          containerElement
        );
      }
    }

    let resolve: any;
    let reject: any;
    class AsService extends Component<AsServiceProps, AsServiceState> {
      static create(props = {}): AsService {
        const instance = new ServiceInstanceHandler(props);
        return instance.ref;
      }

      constructor(props: AsServiceProps) {
        super(props);

        this.state = {
          isOpen: false,
          isMovingBack: false,
          innerProps: {}
        };

        this.handleCancel = this.handleCancel.bind(this);
        this.handleConfirm = this.handleConfirm.bind(this);
        this.show = this.show.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleCloseError = this.handleCloseError.bind(this);
      }

      componentDidMount(): void {
        const { setRef } = this.props;
        if (setRef) {
          setRef(this);
        }
      }

      handleClose() {
        this.setState({ isOpen: false, isMovingBack: true });
        if (forceResolveOnReject) {
          resolve(rejectValue);
        } else {
          reject({ closed: false, movingBack: true });
        }
      }

      handleCloseError() {
        this.setState({ isOpen: false });
      }

      handleCancel() {
        this.setState({ isOpen: false, isMovingBack: false });
        if (forceResolveOnReject) {
          resolve(rejectValue);
        } else {
          reject(rejectValue);
        }
      }

      handleConfirm(...params: any[]) {
        this.setState({ isOpen: false });
        if (resolveValue !== undefined) {
          resolve(resolveValue);
        } else {
          resolve(...params);
        }
      }

      show(props = {}) {
        const { createProps } = this.props;
        const innerProps = { ...createProps, ...props };
        this.setState({ isOpen: true, innerProps });
        return new Promise((res, rej) => {
          resolve = res;
          reject = rej;
        });
      }

      render() {
        const { isOpen, innerProps } = this.state;
        const compProps = {
          [activeProp]: isOpen,
          [resolveClose]: this.handleClose,
          [resolveProp]: this.handleConfirm,
          [rejectProp]: this.handleCancel,
          [resolveErrorClose]: this.handleCloseError,
          ...innerProps
        };
        return <Comp {...compProps} />;
      }
    }

    return AsService;
  };
//@ts-ignore
export default asService;
