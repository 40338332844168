import React, { useCallback, useState } from 'react';
import { IconButton, Menu, MenuItem, Typography } from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { makeStyles } from '@material-ui/styles';
import { useTranslation } from '../../../services/translation';
import { Message_template } from '../../../interfaces/business/message';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column'
  },
  anchor: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: '0px 10px',
    width: '90%'
  }
});

interface RowsOptionsType {
  template: Message_template;
  deleteMessageTemplate: (templates: Message_template) => Promise<void>;
  editMessageTemplate: (template: Message_template) => Promise<void>;
}

export const RowsOptions = ({ template, deleteMessageTemplate, editMessageTemplate }: RowsOptionsType) => {
  const [anchorElMenu, setAnchorElMenu] = useState(null);
  const { t } = useTranslation();
  const classes = useStyles();

  const handleClick = useCallback(
    (event) => {
      setAnchorElMenu(event.currentTarget);
    },
    [setAnchorElMenu]
  );

  const handleClose = useCallback(() => {
    setAnchorElMenu(null);
  }, [setAnchorElMenu]);

  const open = Boolean(anchorElMenu);
  const idPopover = open ? 'simple-menu' : undefined;
  return (
    <>
      <IconButton
        onClick={(e) => {
          e.stopPropagation();
          handleClick(e);
        }}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id={idPopover}
        anchorEl={anchorElMenu}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        keepMounted
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        open={open}
        onClose={handleClose}
      >
        <MenuItem
          onClick={() => {
            editMessageTemplate(template);
            setAnchorElMenu(null);
          }}
          className={classes.anchor}
        >
          <Typography variant="subtitle2" color="secondary">
            {t('Edit')}
          </Typography>
        </MenuItem>
        {!template.message_config.length && !template.message_config_alert.length && (
          <MenuItem
            onClick={() => {
              deleteMessageTemplate(template);
              setAnchorElMenu(null);
            }}
            className={classes.anchor}
          >
            <Typography variant="subtitle2" color="primary">
              {t('Delete')}
            </Typography>
          </MenuItem>
        )}
      </Menu>
    </>
  );
};
