import { makeBusinessEntity } from '../../../lib/libby/generator/makeBusinessEntity';

const DAO_NAME = 'ster_order_comment';

interface OrderB2BComment {
  order_b2b_comment_id: number;
}

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch }
} = makeBusinessEntity<OrderB2BComment>(DAO_NAME);

// hooks
export const useOrderB2BCommentFetchById = fetchById;
export const useOrderB2BCommentLibbyCall = libbyCall;
export const useOrderB2BCommentDAO = libbyDAO;
export const useOrderB2BCommentLibbyFetch = libbyFetch;
