import React, { ReactNode } from 'react';
import { Grid, GridProps, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { AnyObject } from '../../types/types';

const useStyles = makeStyles((theme: AnyObject) => ({
  containerForm: {
    padding: '15px',
    border: '1 solid black',
    borderColor: 'black'
  },
  noPaddingContainer: {
    padding: 0,
    margin: 0
  },
  whiteBackground: {
    borderRadius: 8,
    backgroundColor: '#ffffff'
  },
  primaryTitle: {
    textAlign: 'left',
    marginBottom: 15,
    lineHeight: 1,
    color: theme.palette.primary.main
  },
  detailHeaderTitle: {
    textAlign: 'left',
    marginBottom: 15,
    color: '#999999'
  },
  detail: {
    paddingTop: 5,
    paddingLeft: 50,
    paddingRight: 50
  },
  minimumPadding: {
    paddingTop: 0,
    paddingLeft: 5,
    paddingRight: 5
  },
  detailTopOnly: {
    paddingTop: 5
  },
  noPaddingChildren: {
    padding: 0
  }
}));

type InformationSectionProps = {
  title: NonNullable<ReactNode>;
  children: NonNullable<ReactNode>;
  noPadding?: boolean;
  minimumPadding?: boolean;
  whiteBackground?: boolean;
  detailHeader?: boolean;
  containerProps?: GridProps;
  noPaddingChildren?: boolean;
};

export const InformationSection = ({ title, children, noPadding = false, minimumPadding = false, whiteBackground = false, detailHeader = false, containerProps, noPaddingChildren = false }: InformationSectionProps) => {
  const classes = useStyles();

  return (
    <Grid item className={`${classes.containerForm} ${noPadding && classes.noPaddingContainer} ${whiteBackground && classes.whiteBackground}`} {...containerProps}>
      <Typography className={`${detailHeader ? classes.detailHeaderTitle : classes.primaryTitle}`} variant="h3">
        {title}
      </Typography>
      <div className={`${noPadding ? classes.detailTopOnly : classes.detail} ${minimumPadding && classes.minimumPadding}  ${noPaddingChildren && classes.noPaddingChildren} || `}>{children}</div>
    </Grid>
  );
};
