import { translate } from '../../../utils';

export const columns = [
  {
    id: 'so_id',
    label: 'ID',
    orderById: 'so_id'
  },
  {
    id: 'so_name',
    label: 'So Name',
    style: {
      whiteSpace: 'nowrap'
    },
    orderById: 'so_name'
  },
  {
    id: 'sales_channel_name',
    label: 'Sales Channel Name',
    style: {
      whiteSpace: 'nowrap'
    },
    orderById: 'sales_channel_name'
  },
  {
    id: 'client_name',
    label: 'Client Name',
    style: {
      whiteSpace: 'nowrap'
    },
    orderById: 'client_name'
  },
  {
    id: 'client_phone',
    label: 'Client Phone',
    style: {
      whiteSpace: 'nowrap'
    },
    orderById: 'client_phone'
  },
  {
    id: 'client_movil',
    label: 'Client Mobile',
    style: {
      whiteSpace: 'nowrap'
    },
    orderById: 'client_movil'
  },
  {
    id: 'client_mail',
    label: 'Client Email',
    style: {
      whiteSpace: 'nowrap'
    },
    orderById: 'client_mail'
  },
  {
    id: 'client_address',
    label: 'Client Address',
    style: {
      whiteSpace: 'nowrap'
    },
    orderById: 'client_address'
  },
  {
    id: 'invoice_date',
    label: 'Invoice Date',
    style: {
      whiteSpace: 'nowrap'
    },
    orderById: 'invoice_date'
  },
  {
    id: 'invoice_number',
    label: 'Invoice Number',
    style: {
      whiteSpace: 'nowrap'
    },
    orderById: 'invoice_number'
  },
  {
    id: 'shipment_date',
    label: 'Shipment Date',
    style: {
      whiteSpace: 'nowrap'
    },
    orderById: 'shipment_date'
  },
  {
    id: 'shipment_tracking',
    label: 'Shipment Tracking',
    style: {
      whiteSpace: 'nowrap'
    },
    orderById: 'shipment_tracking'
  },
  {
    id: 'sales_channel_number',
    label: 'Sales Channel Number',
    style: {
      whiteSpace: 'nowrap'
    },
    orderById: 'sales_channel_number'
  },
  {
    id: 'sales_team',
    label: 'Sales Team',
    style: {
      whiteSpace: 'nowrap'
    },
    orderById: 'sales_team'
  },
  {
    id: 'id_ml',
    label: 'ID ML',
    style: {
      whiteSpace: 'nowrap'
    },
    orderById: 'id_ml'
  },
  {
    id: 'state',
    label: 'State',
    style: {
      whiteSpace: 'nowrap'
    },
    orderById: 'state',
    render: (value: { state: string }) => `${translate(value.state)}`
  },
  {
    id: 'credit_note_number',
    label: 'Credit Note Number',
    style: {
      whiteSpace: 'nowrap'
    },
    orderById: 'credit_note_number'
  },
  {
    id: 'details',
    label: 'Details',
    noSort: true
  }
];

export const columnsDetails = [
  {
    id: 'sku',
    label: 'SKU',
    noSort: true
  },
  {
    id: 'quantity',
    label: 'Quantity',
    noSort: true
  },
  {
    id: 'description',
    label: 'Description',
    noSort: true
  },
  {
    id: 'serial_numbers',
    label: 'Serial Numbers',
    noSort: true,
    render: (value: { serial_numbers: string[] }) => value.serial_numbers.join(',')
  },
  {
    id: 'delivery_product',
    label: 'Product',
    noSort: true,
    render: (value: { delivery_product: boolean }) => (value.delivery_product ? `${translate('Delivery')}` : `${translate('Sale')}`)
  }
];
