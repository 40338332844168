import React, { useRef, useCallback } from 'react';
import { Grid } from '@material-ui/core';
import { Filter } from '../../../../types';

import { RowsType } from '../../../../utils/tables';
import { columnsOrderReport } from '../../../../models/orders/columns';

import { StateAreaCharts } from './components/StateAreaCharts';
import { SummaryBoard } from './components/SummaryBoard';
import { SectionTitle } from '../../../../components/SectionTitle/index.ts';
import { ScreenAligned } from '../../../../components/ScreenAligned/ScreenAligned';
import { OrderTable } from './components/OrderReportTable';

import { TitleBreadcrumbs } from '../../../../interfaces';
import { ReportAreaTime } from '../../../../types/ReportAreaTime';
import { DataFilterDownloadInterface, DataFilterDownloadInterfaceSpanish } from '../../../../interfaces/business/general/DataFilterDownload';

export type OrderReportListProps = {
  title: TitleBreadcrumbs[];
  rows: Array<RowsType>;
  filter: Filter;
  working: boolean;
  setFilter: (makeFilter: object) => void;
  searchFilter: (value: object) => void;
  fetchMore: () => void;
  orderBy?: string;
  direction?: 'asc' | 'desc';
  handleRequestSort: (orderBy: string, direction: 'asc' | 'desc') => void;
  pathDetails: (id: string) => string;
  data_area_time: ReportAreaTime[];
  working_area_time: boolean;
  download: boolean;
  data: DataFilterDownloadInterface[] | DataFilterDownloadInterfaceSpanish[];
};

export const OrderReportList = ({ title, rows, filter, working, searchFilter, handleRequestSort, setFilter, fetchMore, orderBy, direction, pathDetails, data_area_time, working_area_time, download, data }: OrderReportListProps) => {
  const detailsRef = useRef<null | HTMLDivElement>(null);

  const searchFilterRef = useCallback(
    (value: object) => {
      searchFilter(value);
      if (detailsRef?.current) {
        detailsRef?.current?.scrollIntoView({ behavior: 'smooth' });
      }
    },
    [searchFilter]
  );

  return (
    <ScreenAligned title={title}>
      <Grid container direction="row" justify="center" alignItems="center">
        <SectionTitle title="Status according to time since the order was created" separation>
          <SummaryBoard setFilter={searchFilterRef} />
        </SectionTitle>
        <SectionTitle title="State according to time in each area" separation>
          <StateAreaCharts setFilter={searchFilterRef} data={data_area_time} working={working_area_time} />
        </SectionTitle>
        <SectionTitle title="Detail by orders">
          <div ref={detailsRef}>
            <OrderTable
              title={title}
              columns={columnsOrderReport}
              rows={rows}
              filter={filter}
              working={!!working}
              onSortChange={handleRequestSort}
              setFilter={setFilter}
              fetchMore={fetchMore}
              direction={direction}
              orderBy={orderBy}
              pathDetails={pathDetails}
              download={download}
              data={data}
            />
          </div>
        </SectionTitle>
      </Grid>
    </ScreenAligned>
  );
};
