import React from 'react';
import SearchIcon from '@material-ui/icons/Search';
import { Grid, TextField } from '@material-ui/core';
import moment from 'moment';
import { FilterBar } from '../../../../../../components/FilterBar';
import { gridBreakpoints, gridBreakpointsFields } from '../../../../../../const/gridBreakpoints';
import { useInitFilterDebounced, useInitFilter } from '../../../../../OrderReport/routes/OrderReportList/hook';
import { makeFilter } from '../utils/makeFilter';
import { Filter } from '../../../../../../types';
import { useTranslation } from '../../../../../../services/translation';
import { DateClose } from '../../../../../../components/DateClose';

type SearchFiltersProps = {
  onFilterChange: (makeFilter: object) => void;
  filter: Filter;
};

const SearchFiltersRaw = ({ onFilterChange, filter }: SearchFiltersProps) => {
  const { t } = useTranslation();

  const { search, handleChange } = useInitFilterDebounced({
    initFetch: filter.search,
    init: '',
    onFilterChange,
    id: 'search',
    makeFilter
  });

  const { search: dayDate, handleChangeFilterString: setDayDate } = useInitFilter({
    initFetch: filter.dayDate,
    onFilterChange,
    init: null,
    id: 'dayDate',
    date: true,
    makeFilter
  });

  return (
    <FilterBar
      defaultFilter={
        <Grid item {...gridBreakpoints}>
          <TextField
            id="standard-search"
            placeholder={t('Search')}
            type="search"
            value={search}
            onChange={handleChange}
            fullWidth
            InputLabelProps={{
              shrink: true
            }}
            InputProps={{
              endAdornment: <SearchIcon />
            }}
          />
        </Grid>
      }
    >
      <Grid item {...gridBreakpointsFields}>
        <DateClose name="Date" value={moment.isMoment(dayDate) ? dayDate : null} onChange={setDayDate} />
      </Grid>
    </FilterBar>
  );
};

export const SearchFilters = React.memo(SearchFiltersRaw);
