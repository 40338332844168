import { Order_reporting_goal_sales_all } from '../../../interfaces/business';
import { makeBusinessEntity } from '../../../lib/libby/generator/makeBusinessEntity';

const DAO_NAME = 'ster_reporting_objective_sales';

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch }
} = makeBusinessEntity<Order_reporting_goal_sales_all>(DAO_NAME);

// hooks
export const useReportingObjectiveSalesFetchById = fetchById;
export const useReportingObjectiveSalesLibbyCall = libbyCall;
export const useReportingObjectiveSalesDAO = libbyDAO;
export const useReportingObjectiveSalesLibbyFetch = libbyFetch;
