import React from 'react';
import { CircularProgress } from '@material-ui/core';
import Modal from '@material-ui/core/Modal';
import { makeStyles } from '@material-ui/styles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles({
  modalTitle: {
    textTransform: 'uppercase',
    fontSize: '24px',
    fontWeight: 700,
    paddingBottom: '16px'
  },
  modalContent: {
    fontSize: '16px',
    paddingBottom: '24px'
  },
  buttonText: {
    fontSize: '16px'
  },
  box: {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    backgroundColor: '#fff',
    border: '1px solid #e0e0e0',
    boxShadow: '0px 3px 5px -1px rgb(0 0 0 / 20%), 0px 6px 10px 0px rgb(0 0 0 / 14%), 0px 1px 18px 0px rgb(0 0 0 / 12%)',
    borderRadius: '4px',
    padding: '16px'
  },
  progressContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: '24px'
  }
});

interface DialogLoadingProps {
  title: React.ReactNode;
  content?: React.ReactNode;
}

const DialogLoading: React.FC<DialogLoadingProps> = ({ title, content }: DialogLoadingProps) => {
  const classes = useStyles();

  const handleBackdropClick = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
  };

  const handleContentClick = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
  };

  const dialog = (
    <Modal open={true} BackdropProps={{ onClick: handleBackdropClick }}>
      <Box className={classes.box} onClick={handleContentClick}>
        <Typography className={classes.modalTitle}>{title}</Typography>
        {content && <Typography className={classes.modalContent}>{content}</Typography>}
        <Box className={classes.progressContainer}>
          <CircularProgress />
        </Box>
      </Box>
    </Modal>
  );

  return dialog;
};

export { DialogLoading };
