import React from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import { OrderQRDialog } from '../Orders/routes/OrderQRDialog';

export const OrderDeliveryConfirmation = () => {
  const match = useRouteMatch();
  return (
    <Switch>
      <Route path={`${match.path}/:id`}>
        <OrderQRDialog />
      </Route>
    </Switch>
  );
};
