import { Button, Grid } from '@material-ui/core';
import React from 'react';
import { ScreenAligned } from '../../../components/ScreenAligned/ScreenAligned';
import InfoTable from '../../components/InfoTable';
import { useReportB2BStock } from '../hooks';
import { columns } from '../utils/columns';
import { SearchFilters } from '../components/SearchFilter';
import { useTranslation } from '../../../services/translation';

interface orderRow {
  id: string;
  sku: string;
  quantity: number;
  name: string;
}

export const ReportingB2BStockList = () => {
  const { rows, working, initFilter, onFilterChange, onRefresh } = useReportB2BStock();
  const { t } = useTranslation();
  const rowsToShow: orderRow[] = [];

  if (rows)
    rows.forEach((row) => {
      const order: orderRow | undefined = rowsToShow.find((orderToShow) => row.sku === orderToShow.sku);
      if (order) order.quantity = +order.quantity + +row.quantity;
      else rowsToShow.push(row);
    });

  return (
    <ScreenAligned
      title="Report B2B Stock"
      additionalTitle={
        <Button color="primary" variant="contained" onClick={onRefresh}>
          {t('Refresh')}
        </Button>
      }
    >
      <SearchFilters onFilterChange={onFilterChange} filter={initFilter} />
      <Grid container direction="row" justify="center" alignItems="center">
        <InfoTable columns={columns} rows={rowsToShow} onBottomScroll={() => {}} onSortChange={() => {}} direction="asc" rowIdKey="name" height="auto" md={12} sm={12} xs={12} working={working} />
      </Grid>
    </ScreenAligned>
  );
};
