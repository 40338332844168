import { Column } from '../../components/InfoTable';

export const columns: Column[] = [
  {
    id: 'sku',
    label: 'Sku',
    noSort: true
  },
  {
    id: 'quantity',
    label: 'Quantity',
    noSort: true
  },
  {
    id: 'name',
    label: 'Name',
    noSort: true
  }
];
