import { makeBusinessEntity } from '../../lib/libby/generator/makeBusinessEntity';
import { Marketplace_sla } from '../../interfaces/business';

const DAO_NAME = 'ster_marketplace_sla';

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch }
} = makeBusinessEntity<Marketplace_sla>(DAO_NAME);

// hooks
export const useMarketplaceSlaFetchById = fetchById;
export const useMarketplaceSlaLibbyCall = libbyCall;
export const useMarketplaceSlaDAO = libbyDAO;
export const useMarketplaceSlaLibbyFetch = libbyFetch;
