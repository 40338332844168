import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { Theme, makeStyles } from '@material-ui/core/styles';
import { MobileCollectDetailItem } from './MobileCollectDetailItem';
import { useTranslation } from '../../../../../../services/translation';
import { AnyObject } from '../../../../../../types';
import { Collect_item_product } from '../../../../../../interfaces/business/dispatch/Collect_item_product';
import { Order_item } from '../../../../../../interfaces/business/orders/Order_item';
import { reducerQuantity } from '../../../../utils/quantity';

const useStyles = makeStyles((theme: Theme) => ({
  orderBox: {
    marginTop: 5,
    marginBottom: 15
  },
  orderName: {
    fontSize: 14,
    fontWeight: 700,
    marginBottom: 5
  },
  orderPercentage: {
    color: theme.palette.grey[500],
    marginLeft: 10
  },
  productsTabItem: {
    padding: '5px 0 10px',
    borderBottom: `solid 1px ${theme.palette.grey[400]}`
  },
  orderTitle: {
    display: 'flex',
    justifyContent: 'space-between'
  }
}));

type MobileCollectDetailsOrdersProps = {
  item: AnyObject;
  itemProductAddOrRemove: (type: boolean, data: Collect_item_product) => void;
  assignedUser: string;
};

type dataOrderType = {
  completedProducts: Collect_item_product[];
  complete: boolean;
  data: Order_item;
};

export const MobileCollectDetailsOrders = ({ item, itemProductAddOrRemove, assignedUser }: MobileCollectDetailsOrdersProps) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const orderNumber = item.order_id;

  const cartBoxName = item?.cart_box || '';
  const products = item.order_items || [];

  const dataOrders = products.reduce((accumulatorOrder: dataOrderType[], data: Order_item) => {
    const product = item.items_product.filter((item_product: Collect_item_product) => item_product.order_item.order_item_id === data.order_item_id);

    const copyAccumulatorOrder = [...accumulatorOrder];

    copyAccumulatorOrder.push({
      completedProducts: product,
      complete: product.length === [data].reduce(reducerQuantity, 0),
      data
    });
    return copyAccumulatorOrder;
  }, []);

  return (
    <Grid item className={classes.orderBox}>
      <Grid item className={classes.orderTitle}>
        <Typography variant="subtitle1" color="initial" className={classes.orderName}>
          {`${t('Order')} #${orderNumber}`}
          <span className={classes.orderPercentage}>{`${dataOrders.filter(({ complete }: dataOrderType) => complete === true).length}/${item.order_items.length}`}</span>
        </Typography>
        <Typography variant="subtitle1" color="initial" className={classes.orderPercentage}>
          {`${t('Box')}${' '}#${cartBoxName}`}
        </Typography>
      </Grid>
      <Grid item>
        {dataOrders.map((data: dataOrderType) => (
          <MobileCollectDetailItem
            assignedUser={assignedUser}
            data={{
              name: data.data.name,
              sku: data.data.sku,
              url: data.data.product?.url
            }}
            itemProductAddOrRemove={itemProductAddOrRemove}
            completedProducts={data.completedProducts}
            collectItem={{
              collect_item_id: item.id,
              cartBox: {
                name: item.cart_box
              },
              collectItemState: item.collectItemState,
              dispatch_item: item.dispatch_item,
              items_product: []
            }}
            dataProduct={data}
            complete={data.complete}
          />
        ))}
      </Grid>
    </Grid>
  );
};
