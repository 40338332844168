import React, { useEffect } from 'react';
import { Backdrop, CircularProgress } from '@material-ui/core';
import { usePickControlLogic } from './hook';
import { LibbyObject } from '../../../../types';
import { makeFilter } from '../../utils/makeFilter';
import { useFilerParamsId } from '../../../../hooks';
import InfoTable from '../../../components/InfoTable';
import { TitleBreadcrumbs } from '../../../../interfaces';
import LoadingData from '../../../components/LoadingData';
import { CollectState, DISPATCH_STATE } from '../../../../const';
import { useTranslation } from '../../../../services/translation';
import { ScreenAligned } from '../../../../components/ScreenAligned/ScreenAligned';
import { ButtonComponent } from '../../../../components/Button';
import { ButtonDetailsType } from '../../../../types/Button';

interface PickControlListProps {
  libby: LibbyObject;
  title: TitleBreadcrumbs[];
}

export const PickControlList = ({ libby, title }: PickControlListProps) => {
  const { t } = useTranslation();

  const { paramsFetch, orderBy, direction, handleRequestSort } = useFilerParamsId({
    orderInit: 'collect_id',
    daoName: 'ster_dispatch_collect_details',
    directionInit: 'desc',
    initAuxiliary: makeFilter({
      collectStates: [CollectState.COLLECTING, CollectState.COMPLETE],
      dispatchStates: [DISPATCH_STATE.PENDING, DISPATCH_STATE.READY, DISPATCH_STATE.PREPARING]
    })
  });

  const { rows, columns, working, fetchMore, reFetch, buttons, onRowClick, serchingSerialNumber } = usePickControlLogic({
    libby,
    paramsFetch
  });

  useEffect(() => {
    if (reFetch) reFetch();
  }, [reFetch]);

  return (
    <ScreenAligned
      title={title}
      additionalTitle={
        <>
          {buttons.map((dataButton: ButtonDetailsType) => (
            <ButtonComponent
              key={dataButton.id}
              title={dataButton.title}
              className={dataButton.className}
              variant={dataButton.variant}
              color={dataButton.color}
              onClick={dataButton.onClick}
              type={dataButton.type}
              disabled={dataButton.disabled}
              loading={dataButton.loading}
            />
          ))}
        </>
      }
    >
      <InfoTable columns={columns} rows={rows} onBottomScroll={fetchMore} rowIdKey="collect_id" onRowClick={onRowClick} onSortChange={handleRequestSort} orderBy={orderBy} direction={direction} />

      <LoadingData label={`${t('Loaded registers')}`} working={working} cant={rows.length} />

      <Backdrop open={serchingSerialNumber} style={{ zIndex: 9999 }}>
        <CircularProgress color="secondary" />
      </Backdrop>
    </ScreenAligned>
  );
};
