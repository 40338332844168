import { LibbyFetchDAO } from '../LibbyFetchDAO';

export class ShippingTagsCustomDAO extends LibbyFetchDAO {
  constructor() {
    super('ster_shipping_tags_custom', 'stock_id');
  }

  getAll() {
    return this.query().limit(0, 10000).run();
  }

  getAllShippingTagsByOrderId(id: number) {
    if (!id) {
      return null;
    }
    return this.query().equals('order_id', id).run();
  }
}
