import React, { useMemo } from 'react';
import { DatabaseConnector } from '@phinxlab/libby-rest-web';
import { useParams } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { InformationSection } from '../../../../components/InformationSection';
import { ERPOrderDetailData } from './components/ERPOrderDetailData';
import { columnsDetails } from '../../utils';
import InfoTable from '../../../components/InfoTable';
import { LibbyObject } from '../../../../types/types';
import { useTranslation } from '../../../../services/translation';
import { useLibbyFetchById, useBreadcrumbs } from '../../../../hooks';
import { ScreenAligned } from '../../../../components/ScreenAligned/ScreenAligned';
import { Items } from '../../types/ERPOrder';
import { TitleBreadcrumbs } from '../../../../interfaces';

const useStyles = makeStyles({
  containInfo: {
    paddingTop: '15px'
  }
});
type OrderDetailProps = {
  libby: LibbyObject;
  title: TitleBreadcrumbs[];
};

const ERPOrderDetailsRaw = ({ libby, title }: OrderDetailProps) => {
  const { id } = useParams<{ id: string }>();
  const { t } = useTranslation();
  const classes = useStyles();
  const { working, data } = useLibbyFetchById(libby, {
    daoName: 'ster_erp_order',
    id
  });

  const rows = useMemo(
    () =>
      data?.items.length
        ? data.items.map((erpOrder: Items) => ({
            sku: erpOrder?.sku,
            quantity: erpOrder?.quantity,
            description: erpOrder?.description,
            serial_numbers: erpOrder?.serial_numbers,
            delivery_product: erpOrder?.delivery_product
          }))
        : [],
    [data]
  );
  const titleShow = useBreadcrumbs('ERP Order', title, `#${id}`);
  return (
    <ScreenAligned
      title={titleShow} // "ERP Order Detail"
      working={working}
    >
      <ERPOrderDetailData data={data} working={working} />
      <Grid className={classes.containInfo}>
        {data?.items.length && (
          <InformationSection title={t('Items')} noPadding>
            <InfoTable columns={columnsDetails} rows={rows} rowIdKey="sku" />
          </InformationSection>
        )}
      </Grid>
    </ScreenAligned>
  );
};

export const ERPOrderDetails = DatabaseConnector(ERPOrderDetailsRaw)('ster_erp_order');
