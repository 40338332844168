import React, { useMemo, useState, useCallback } from 'react';
import { useSnackbar } from 'notistack';
import { Column } from '../../../components/InfoTable';
import { useTranslation } from '../../../../services/translation';
import { LibbyObject } from '../../../../types/types';
import { format } from '../../../../util';
import { FilterBarSelection } from '../../../Reporting/routes/ReportingList/FilterBar';
import { useInterval } from '../../../../hooks/useIntervalPage';
import { ButtonDetailsType } from '../../../../types/Button';
import { KpiData, KpiSubData } from '../../../components/Kpi';
import { orderBy } from 'lodash';

export type ReportingSalesListProps = { vatEnabled?: boolean; libby: LibbyObject; typeReporting: 'Sales' | 'Orders'; daoNameHistory: string; daoName: string; columnsAux: Array<Column>; columnsProductAux: Array<Column> };

type TypeColumn = {
  name: string;
  total: number;
  count: number;
  id: string;
  tooltip_count?: number;
  tooltip_total?: number;
};

type odooRows = {
  id_marketplace: string;
  number: number;
  total: number;
};

type sterOrderType = {
  color: string;
  count: number;
  id: string;
  key: string;
  name: string;
  total: number;
  vat: string;
};

type ReportingSalesListLogicTypes = {
  recall: ((...newParams: any[]) => Promise<any>) | undefined;
  filter: any;
  setFilter: any;
  workingData: boolean;
  vatEnabled?: boolean;
  statisticsWithTotal: any;
  withOrder: any[];
  dataReportingSales: any;
  setDirection: any;
  setDirectionProduct: any;
};

type AggregateRow = {
  [k: string]: any;
};

export default function ReportingSalesListLogic({ recall, filter, setFilter, workingData, vatEnabled, statisticsWithTotal, withOrder, dataReportingSales, setDirection, setDirectionProduct }: ReportingSalesListLogicTypes) {
  const [stateRefresh, setState] = React.useState(false);
  const { t } = useTranslation();
  const [finalRows, setFinalRows] = useState<any[]>([]);

  const delay = useMemo(() => (stateRefresh ? 60000 : null), [stateRefresh]);

  const { enqueueSnackbar } = useSnackbar();

  const refresh = useCallback(() => {
    recall?.(filter.metadata);
  }, [recall, filter]);

  const { time, resetTime } = useInterval(refresh, delay);

  const onChangeMarketplace = useCallback(
    (dataMarketplace: TypeColumn) => {
      const newFilter: FilterBarSelection = {
        ...filter.metadata,
        marketplaces: [
          {
            id: dataMarketplace.id,
            value: dataMarketplace.name
          }
        ]
      };
      setFilter({ ...filter, metadata: newFilter });
      recall?.(newFilter);
    },
    [filter, recall, setFilter]
  );

  const onChangeCompany = useCallback(
    (dataCompany: TypeColumn) => {
      const newFilter: FilterBarSelection = {
        ...filter.metadata,
        company: [
          {
            id: dataCompany.id,
            value: dataCompany.name
          }
        ]
      };
      setFilter({ ...filter, metadata: newFilter });
      recall?.(newFilter);
    },
    [filter, recall, setFilter]
  );

  const onChangeOrderSourceType = useCallback(
    (dataOrderSource: TypeColumn) => {
      const newFilter: FilterBarSelection = {
        ...filter.metadata,
        canal: [
          {
            id: dataOrderSource.id,
            value: dataOrderSource.name
          }
        ]
      };
      setFilter({ ...filter, metadata: newFilter });
      recall?.(newFilter);
    },
    [filter, recall, setFilter]
  );
  const OnChangeApplyFilters = useCallback(() => {
    if (stateRefresh) {
      resetTime();
    } else {
      refresh();
    }
  }, [refresh, stateRefresh, resetTime]);

  const buttons: ButtonDetailsType[] = useMemo(
    () => [
      {
        id: 'dispatchDetailAddOrder',
        onClick: OnChangeApplyFilters,
        title: `${t('Refresh')} ${stateRefresh ? `(${time} ${t('Seconds').toLowerCase()})` : ''}`,
        disabled: workingData,
        loading: stateRefresh ? workingData : false
      }
    ],
    [time, OnChangeApplyFilters, workingData, stateRefresh, t]
  );

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setState(event.target.checked);
  };

  const allKpi: KpiData[] = useMemo(() => {
    const size = vatEnabled ? 3 : 6;
    const dataKpi: KpiSubData[] = [
      {
        title: 'Amount sales',
        value: statisticsWithTotal.count,
        xs: 12,
        sm: 12,
        md: size,
        lg: size,
        xl: size
      },
      {
        title: 'Total sales',
        value: format(statisticsWithTotal.total, 'ARS', t),
        xs: 12,
        sm: 12,
        md: size,
        lg: size,
        xl: size
      }
    ];
    if (vatEnabled) {
      dataKpi.push({
        title: 'Total vat',
        value: format(statisticsWithTotal.vat, 'ARS', t),
        xs: 12,
        sm: 12,
        md: size,
        lg: size,
        xl: size
      });
      dataKpi.push({
        title: 'Total without taxation',
        value: format(statisticsWithTotal.total - parseFloat(statisticsWithTotal.vat), 'ARS', t),
        xs: 12,
        sm: 12,
        md: size,
        lg: size,
        xl: size
      });
    }
    return [
      {
        data: dataKpi
      }
    ];
  }, [statisticsWithTotal, t, vatEnabled]);

  const handleOdooDiffSter = useCallback(async () => {
    const conditional = withOrder.length !== 1 ? filter.metadata.courier.length === 0 && dataReportingSales.odoo[0] : false;
    if (conditional) {
      const diffTotals: odooRows[] = [];
      let hasDiff = false;
      await withOrder.forEach((row: sterOrderType) => {
        if (row.name === 'Total') return;
        const sterOrder = dataReportingSales.odoo.find((item: odooRows) => row.id === item.id_marketplace);
        if (!sterOrder) {
          hasDiff = true;
          diffTotals.push({
            id_marketplace: row.id,
            number: 0,
            total: 0
          });
        } else if (sterOrder?.total !== row?.total) {
          if (sterOrder.total > row.total) {
            const sub = sterOrder.total - row.total;
            if (sub > 100000) {
              diffTotals.push(sterOrder);
              hasDiff = true;
            }
          } else {
            const sub = row.total - sterOrder.total;
            if (sub > 100000) {
              diffTotals.push(sterOrder);
              hasDiff = true;
            }
          }
        }
      });
      if (hasDiff)
        enqueueSnackbar(t('There are some differences between odoo and ster orders. Please review them.'), {
          variant: 'error'
        });
      const newWitOrder = await withOrder.map((market) => {
        const singleMarket = market;
        const diff = diffTotals.find((item) => item.id_marketplace === market.id);
        if (diff) {
          singleMarket.color_row = 'red';
          singleMarket.tooltip_total = diff.total;
          singleMarket.tooltip_count = diff.number;
          return singleMarket;
        }
        return market;
      });

      setFinalRows(newWitOrder);
    } else {
      setFinalRows(withOrder);
    }
  }, [dataReportingSales, enqueueSnackbar, withOrder, filter.metadata.courier.length, t]);

  const handleRequestSort = (newOrderBy: string, newDirection: 'asc' | 'desc') => {
    setDirection(newDirection);
  };

  const handleRequestProductSort = (newOrderBy: string, newDirection: 'asc' | 'desc') => {
    setDirectionProduct(newDirection);
  };

  const statisticsProductsBrand: any[] = useMemo(() => {
    if (!dataReportingSales?.brand?.length) return [];
    let data: AggregateRow[] = [];

    dataReportingSales.brand?.forEach((brand: any) => {
      data.push({
        count: parseInt(brand.count_of_items_name, 10),
        total: parseFloat(brand.sum_of_amount),
        name: brand.brand_name
      });
    });

    data = orderBy(data, ['total', 'count'], 'desc');

    data.push(
      data.reduce(
        (transport: AggregateRow, element: AggregateRow) => {
          transport.count += element.count;
          transport.total += element.total;
          return transport;
        },
        { name: 'Total', count: 0, total: 0 }
      )
    );

    return data;
  }, [dataReportingSales.brand]);

  return {
    allKpi,
    handleChange,
    buttons,
    onChangeMarketplace,
    onChangeCompany,
    onChangeOrderSourceType,
    stateRefresh,
    handleOdooDiffSter,
    finalRows,
    handleRequestSort,
    handleRequestProductSort,
    statisticsProductsBrand
  };
}
