import React from 'react';
import { ScreenAligned } from '../../../components/ScreenAligned/ScreenAligned';
import { LibbyObject } from '../../../types';
import InfoTable from '../../components/InfoTable';
import { useStockLogic } from '../hook/useStockLogic';
import { stockColumns } from '../utils';
import { SearchFilters } from '../components';
import LoadingData from '../../components/LoadingData';
import { useTranslation } from '../../../services/translation';
import { Button } from '@material-ui/core';
import { useHistory, useRouteMatch } from 'react-router';
import { useProductStockSyncErrorLibbyFetch } from '../../../business/product/ProductStockSyncError';
import { useFilerParamsId } from '../../../hooks';
import Box from '@material-ui/core/Box';

interface StockListTypes {
  title: string;
  libby: LibbyObject;
}

export const StockList = ({ title, libby }: StockListTypes) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { data: stockSyncErrors } = useProductStockSyncErrorLibbyFetch();
  const match = useRouteMatch();

  const { paramsFetch, orderBy, filter, setFilter, resetFilter, direction, handleRequestSort } = useFilerParamsId({
    orderInit: 'odoo_stock',
    daoName: 'ster_product_stock_odoo',
    directionInit: 'desc'
  });

  const { rows, working, fetchMore } = useStockLogic({ libby, paramsFetch });

  return (
    <ScreenAligned
      title={title}
      additionalTitle={
        <Box>
          <Button variant="contained" style={{ marginRight: 2 }} color="primary" onClick={() => history.push('/product-stock-sync-error')}>
            {t('Errors')} ({stockSyncErrors?.length})
          </Button>
          <Button variant="contained" color="primary" onClick={() => history.push(match.path + '/stock-process-sync')}>
            {t('Sync')}
          </Button>
        </Box>
      }
    >
      <SearchFilters onFilterChange={setFilter} filter={filter} resetFilter={resetFilter} />
      <InfoTable columns={stockColumns} rows={rows} onBottomScroll={fetchMore} onSortChange={handleRequestSort} direction={direction} orderBy={orderBy} />
      <LoadingData label={`${t('Loaded stock')}`} working={working} cant={rows.length} />
    </ScreenAligned>
  );
};
