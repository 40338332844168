import React, { useCallback, useEffect, useState, useMemo } from 'react';
import moment, { Moment } from 'moment';
import SearchIcon from '@material-ui/icons/Search';
import 'moment/locale/es';
import { Grid, TextField } from '@material-ui/core';
import { DateClose } from '../../../../../components/DateClose';
import { FilterBar } from '../../../../../components/FilterBar';
import { gridBreakpoints, gridBreakpointsFields } from '../../../../../const/gridBreakpoints';
import useDebounce from '../../../../../hooks/useDebounce';
import { useTranslation } from '../../../../../services/translation';
import { AnyObject } from '../../../../../types/types';
import { replaceNonNumericCharacters } from '../../../../../functions';
import { makeFilterCollectOrder } from '../utils/makeFilterCollectOrder';

const initialStartDate = null;
const initialEndDate = null;

type SearchFiltersProps = {
  onFilterChange: (makeFilterCollectOrder: object) => void;
  initFetch: AnyObject;
};

const SearchFiltersRaw = ({ onFilterChange, initFetch }: SearchFiltersProps) => {
  const { t } = useTranslation();
  const [symbolsArr] = useState(['e', 'E', '+', '-', '.', ',', '`+']);
  const [search, setSearch] = useState<string>(initFetch?.['0']?.[0]?.value || '');
  const searchDebounced = useDebounce(search, 500);
  const [orderId, setOrderId] = useState<string>(initFetch?.order_id?.length > 0 ? initFetch.order_id[0].value : '');
  const orderIdDebounced = useDebounce(orderId, 500);

  const [startDate, setStartDate] = useState<Moment | null>(initFetch?.startDate?.length > 0 ? moment(initFetch.startDate[0].value) : initialStartDate);
  const [endDate, setEndDate] = useState<Moment | null>(initFetch?.endDate?.length > 0 ? moment(initFetch.endDate[0].value) : initialEndDate);

  useEffect(() => {
    onFilterChange(
      makeFilterCollectOrder({
        search: searchDebounced,
        startDate,
        endDate,
        orderId: orderIdDebounced
      })
    );
  }, [onFilterChange, searchDebounced, startDate, endDate, orderIdDebounced]);

  const handleReset = useCallback(() => {
    setOrderId('');
    setSearch('');
    setStartDate(initialStartDate);
    setEndDate(initialEndDate);
    onFilterChange({ ...initFetch });
  }, [onFilterChange, initFetch]);

  const filters = useMemo(
    () => [
      {
        id: 'collect-order-generic-search',
        icon: (
          <TextField
            id="generic-search"
            label={t('ID Order')}
            value={orderId}
            type="text"
            onChange={(event) => setOrderId(replaceNonNumericCharacters(event.target.value))}
            fullWidth
            onKeyDown={(e) => symbolsArr.includes(e.key) && e.preventDefault()}
            InputLabelProps={{
              shrink: true
            }}
          />
        )
      },
      {
        id: 'collect-order-start_date',
        icon: <DateClose name="Start Date" value={startDate} onChange={setStartDate} />
      },
      {
        id: 'collect-order-end_date',
        icon: <DateClose name="End Date" value={endDate} minValue={startDate} onChange={setEndDate} />
      }
    ],
    [orderId, startDate, endDate, t, symbolsArr]
  );
  return (
    <FilterBar
      defaultFilter={
        <Grid item {...gridBreakpoints}>
          <TextField
            id="search"
            placeholder={t('Search')}
            type="search"
            value={search}
            onChange={(event) => setSearch(event.target.value)}
            fullWidth
            InputLabelProps={{
              shrink: true
            }}
            InputProps={{
              endAdornment: <SearchIcon />
            }}
          />
        </Grid>
      }
      handleReset={handleReset}
    >
      {filters.map((filter) => (
        <Grid item {...gridBreakpointsFields} key={filter.id}>
          {filter.icon}
        </Grid>
      ))}
    </FilterBar>
  );
};

export const SearchFilters = React.memo(SearchFiltersRaw);
