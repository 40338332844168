import { Order_reporting_dashboard } from '../../../interfaces/business';
import { makeBusinessEntity } from '../../../lib/libby/generator/makeBusinessEntity';

const DAO_NAME = 'ster_reporting_dashboard';

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch }
} = makeBusinessEntity<Order_reporting_dashboard>(DAO_NAME);

// hooks
export const useReportingDashboardFetchById = fetchById;
export const useReportingDashboardLibbyCall = libbyCall;
export const useReportingDashboardDAO = libbyDAO;
export const useReportingDashboardLibbyFetch = libbyFetch;
