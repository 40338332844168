import { DatabaseConnector } from '@phinxlab/libby-rest-web';
import React from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import { LibbyObject } from '../../types';
import { MessageConfigList } from './routes/MessageConfigList';

export const MessageConfigRaw = ({ libby }: LibbyObject) => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={`${path}`}>
        <MessageConfigList libby={libby} />
      </Route>
    </Switch>
  );
};

export const MessageConfig = DatabaseConnector(MessageConfigRaw)('ster_message_config', 'ster_message_config_update', 'order_state', 'ster_marketplace', 'ster_message_template', 'ster_order_document_type');
