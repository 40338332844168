import React from 'react';
import { Box, Button, Grid, Link, Paper, Typography } from '@material-ui/core';
import { useTranslation } from '../../../../../../services/translation';
import { AnyObject, RowsType } from '../../../../../../types';
import { KpiComponent } from '../KpiComponent';
import InfoTable from '../../../../../components/InfoTable';
import { StockTypes } from '../../types';
import { columnCategoryStock } from '../../ReportingDashboardListConst';

interface StockPaperInterface {
  values: StockTypes;
  classes: AnyObject;
  rowsCategoryStock: Array<RowsType>;
}

export const StockPaper = ({ values, classes, rowsCategoryStock }: StockPaperInterface) => {
  const { t } = useTranslation();

  const { url: urlSegment, ...kpiSegment } = values?.by_segment;

  return (
    <Grid item xs={12} sm={12} md={10} lg={8}>
      <Paper className={classes.paper}>
        <Grid container xs={12} justify="space-between" alignItems="center">
          <Typography variant="h4" className={classes.title}>
            {t('Stock')}
          </Typography>
        </Grid>
        <Grid container xs={12} sm={12} md={12} lg={12} spacing={1}>
          <Grid item xs={12} sm={12} md={12} lg={6}>
            <KpiComponent name="general" value={values.general.value} title="Overall Valued" url={values.general.url} />
            <KpiComponent name="transit" value={values.transit.value} title="Valued in Transit (FOB)" url={values.transit.url} />

            {kpiSegment && (
              <Grid container xs={12}>
                <Grid container xs={12} justify="space-between" alignItems="center">
                  <Typography variant="h5">{t('Valued by segmentation')}</Typography>
                  {urlSegment && (
                    <Button color="primary">
                      <Link target="_blank" href={urlSegment}>
                        {t('see more')}
                      </Link>
                    </Button>
                  )}
                </Grid>
                {Object.entries(kpiSegment).map(([key, value]: [string, string | number]) => (
                  <KpiComponent name={key} value={value} />
                ))}
              </Grid>
            )}
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={6}>
            <Box mb={2}>
              <Grid container xs={12} justify="space-between" alignItems="center">
                <Typography variant="h5">{t('Valued by Category')}</Typography>
                {values.category.url && (
                  <Button color="primary">
                    <Link target="_blank" href={values.category.url}>
                      {t('see more')}
                    </Link>
                  </Button>
                )}
              </Grid>
            </Box>
            <InfoTable padding={false} height="auto" columns={columnCategoryStock} rows={rowsCategoryStock} />
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
};
