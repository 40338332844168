import { ThemeProvider } from '@material-ui/styles';
import { Dialog, DialogActions, DialogContent, Button, Grid, Typography, ListItemText, ListItem, TableBody, TableCell, Table, useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { useModalFormStyles } from '../../../../../theme';
import { StyledTableRowProductDescription } from './styles';
import Box from '@material-ui/core/Box';
import { useTranslation } from 'services/translation';

type FeaturesPreviewProps = {
  open: boolean;
  onCancel: () => void;
  title: string;
  cancelText: string;
  customTheme: null;
  features: any;
};

interface ValueDetailsShowProps {
  bold?: boolean;
  value: string;
}

const ValueDetailsShow = ({ bold = false, value }: ValueDetailsShowProps) => (
  <TableCell component="td">
    <Typography variant={bold ? 'subtitle2' : 'body2'}>
      <Box component="text" color="textPrimary" fontWeight={bold ? 'bold' : 'normal'}>
        {value}
      </Box>
    </Typography>
  </TableCell>
);

export const FeaturesPreview = ({ open = false, onCancel = () => {}, title = 'Confirmation Dialog', cancelText = 'Cancel', customTheme = null, features }: FeaturesPreviewProps) => {
  const styles = useModalFormStyles();
  const theme = useTheme();
  const isSm = useMediaQuery(theme.breakpoints.down('sm'));
  const { t } = useTranslation();

  const dialog = (
    <Dialog open={open} onClose={onCancel} fullWidth aria-labelledby="form-dialog-title">
      <DialogContent className={styles.title}>
        <Typography variant="h3">{title}</Typography>
      </DialogContent>
      <DialogContent className={styles.content}>
        <Grid item>
          <Box pt={4}>
            <ListItem disableGutters dense divider={!isSm}>
              <Typography variant="h5" style={{ marginBottom: 16 }}>
                <Box component="text" color="#FF5179" fontWeight="bold">
                  {'Technical Specifications'}
                </Box>
              </Typography>
            </ListItem>
            {features?.length ? (
              features.map((feature: any, index: number) => (
                <ListItem disableGutters dense divider={!isSm} key={feature.name}>
                  <Grid container justifyContent="space-between">
                    <Grid item xs={!isSm ? 4 : 12}>
                      <Box pb={!isSm ? 0 : 2} pt={!isSm || index === 0 ? 0 : 1}>
                        <ListItemText
                          primary={
                            <Typography variant={!isSm ? 'h6' : 'body1'}>
                              <Box component="text" color="textPrimary" fontWeight="bold" py={index === 0 ? 0 : 1}>
                                {feature.name}
                              </Box>
                            </Typography>
                          }
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={!isSm ? 8 : 12}>
                      <Table aria-label="customized table">
                        <TableBody>
                          {feature.features?.map(({ name, value }: { name: string; value: string }) => (
                            <StyledTableRowProductDescription key={`${name}-${value}`}>
                              <ValueDetailsShow value={name} />
                              <ValueDetailsShow value={value} bold />
                            </StyledTableRowProductDescription>
                          )) || (
                            <StyledTableRowProductDescription key={t('No features for this group')}>
                              <ValueDetailsShow value={t('No features for this group')} />
                            </StyledTableRowProductDescription>
                          )}
                        </TableBody>
                      </Table>
                    </Grid>
                  </Grid>
                </ListItem>
              ))
            ) : (
              <Box p={2}>
                <Typography variant="subtitle1" align="center">
                  {t('There are no specifications for this product yet.')}
                </Typography>
              </Box>
            )}
          </Box>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            onCancel();
          }}
        >
          {cancelText}
        </Button>
      </DialogActions>
    </Dialog>
  );

  return !customTheme ? dialog : <ThemeProvider theme={customTheme}>{dialog}</ThemeProvider>;
};
