import { makeValidator, ValidatorType, ValidatorRule } from '../../../../../utils';

export const validations: ValidatorRule[] = [
  { key: 'name', validators: [ValidatorType.REQUIRED] },
  { key: 'sku', validators: [ValidatorType.REQUIRED] },
  { key: 'description', validators: [ValidatorType.REQUIRED] },
  { key: 'category', validators: [ValidatorType.REQUIRED] },
  { key: 'brand', validators: [ValidatorType.REQUIRED] },
  { key: 'price', validators: [ValidatorType.NUMBER, ValidatorType.REQUIRED] },
  { key: 'vat', validators: [ValidatorType.NUMBER, ValidatorType.REQUIRED] }
];

export const validate = makeValidator(validations);

export const getValidate = (keys: Array<string>) => makeValidator(keys.length > 0 ? validations.filter((val) => keys.includes(val.key)) : validations);
