import { LibbyFetchDAO } from './LibbyFetchDAO';

export class OrderSourceDAO extends LibbyFetchDAO {
  constructor() {
    super('ster_order_source', 'order_source_id');
  }

  getAll() {
    return this.query().limit(0, 10000).run();
  }

  getAllByOrderSourceType(order_source_type_ids: string[]) {
    let ls = this.query();
    ls = ls.groupStart();
    let first: boolean = true;
    order_source_type_ids.forEach((id) => {
      if (!first) ls = ls.or();
      ls = ls.equals('order_source_type.order_source_type_id', id);
      first = false;
    });
    ls = ls.groupEnd();

    return ls.run();
  }
}
