import React from 'react';
import { Box } from '@material-ui/core';
import { formatDate } from '../../../../../utils';
import { RowsType } from '../../../types';

interface RowsMessageType extends RowsType {
  created_at: Date;
}

export const columnsComments = [
  {
    id: 'created_at',
    label: 'Created',
    render: ({ created_at }: RowsMessageType) => <Box>{formatDate(created_at, true, false)}</Box>,
    noSort: true
  },
  {
    id: 'order.order_id',
    label: 'Order ID',
    render: (row: any) => {
      return row.order.order_id;
    },
    noSort: true
  },
  {
    id: 'createdByAccount.username',
    label: 'Business',
    render: (row: any) => {
      return row.createdByAccount.username;
    },
    noSort: true
  },
  {
    id: 'text',
    label: 'Comment',
    noSort: true
  }
];
