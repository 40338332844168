import React, { ReactNode, useMemo } from 'react';
import cx from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import { MenuItem, FormControl, Select, Typography, SelectProps, FormHelperText } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import _ from 'lodash';
import { AnyObject } from '../../types';
import typography from '../../theme/typography';
import { grey, primary } from '../../theme/colors';
import LoadingData from '../../routes/components/LoadingData';

export interface SimpleSelectProps {
  title?: string;
  content?: any;
  value: string | number | string[] | number[];
  name?: string;
  handleChange?: (value: React.ChangeEvent<{ value: unknown }>) => void;
  onChange?: (value: React.ChangeEvent<{ value: unknown }>) => void;
  valueKey?: any;
  labelKey?: string;
  placeholder: string;
  customStyleTitle?: any;
  disableUnderline?: SelectProps['disableUnderline'];
  disabled?: any;
  variant?: SelectProps['variant'];
  error?: boolean;
  helperText?: string;
  loading?: boolean;
  onBlur?: <T>(event?: React.FocusEvent<T>) => void;
  onFocus?: <T>(event?: React.FocusEvent<T>) => void;
  multiple?: boolean;
  customClassNameFormControl?: string;
  [x: string]: any;
  requiredField?: boolean;
}

const useStyles = makeStyles({
  formControl: {
    margin: 1,
    width: '100%',
    '& .MuiOutlinedInput-input': {
      padding: '7px 1em'
    },
    '& .MuiOutlinedInput-root': {
      border: 'none'
    }
  },
  title: {
    color: grey[700],
    fontWeight: 'normal',
    fontSize: '14px',
    marginBottom: '10px',
    fontFamily: typography.fontFamily
  },
  select: {
    fontFamily: typography.fontFamily,
    color: grey[500],
    fontSize: '13px',
    maxWidth: '100%',
    display: 'block',
    whiteSpace: 'nowrap'
  },
  placeHolder: {
    fontFamily: typography.fontFamily,
    color: grey[500],
    fontSize: '13px',
    maxWidth: '100%',
    overflow: 'hidden',
    display: 'block',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  }
});

export const SimpleSelect = ({
  title,
  content,
  handleChange,
  onChange,
  value,
  name = '',
  valueKey = '',
  labelKey = '',
  placeholder,
  customStyleTitle,
  disabled,
  disableUnderline = false,
  variant = 'outlined',
  error,
  helperText,
  loading,
  onBlur,
  onFocus,
  multiple,
  customClassNameFormControl,
  requiredField = false
}: SimpleSelectProps) => {
  const classes = useStyles();

  const renderValue = useMemo(() => {
    if (!multiple) {
      return undefined;
    }
    return (values: unknown) => {
      if (!values || !(values as any[]).length) return placeholder;
      return (values as any[])
        .map((item: any) => {
          const option = content?.find((con: any) => `${_.get(con, valueKey)}` === `${item}`);
          return !option ? '-' : _.get(option, labelKey);
        })
        .join(', ') as ReactNode;
    };
  }, [content, labelKey, multiple, placeholder, valueKey]);

  const notMatchWithOptions = () => {
    if (valueKey.includes('.')) {
      return false;
    }
    const notMatch = content.every((option: any) => {
      return option[valueKey] !== value;
    });
    if (value === undefined || value === null || notMatch) {
      return true;
    }
    return false;
  };

  return (
    <div>
      {title && (
        <Typography className={classes.title} style={customStyleTitle}>
          {title?.charAt(0).toUpperCase() + title?.toLowerCase().slice(1)}
          {requiredField && <span style={{ color: '#ff0000' }}> *</span>}
        </Typography>
      )}

      {false ? (
        <LoadingData working={loading} />
      ) : (
        <FormControl variant={variant} className={cx(customClassNameFormControl, classes.formControl)} error={error}>
          <Select
            name={name}
            value={notMatchWithOptions() ? '' : value}
            displayEmpty
            onChange={onChange || handleChange}
            disableUnderline={disableUnderline}
            disabled={disabled}
            IconComponent={ExpandMoreIcon}
            onBlur={onBlur}
            onFocus={onFocus}
            multiple={multiple}
            renderValue={renderValue}
          >
            <MenuItem style={{ backgroundColor: primary.white, opacity: 1 }} value="">
              <Typography className={classes.placeHolder}>{placeholder?.charAt(0).toUpperCase() + placeholder?.toLowerCase().slice(1)}</Typography>
            </MenuItem>
            {content?.map((item: AnyObject) => {
              const theValue = _.get(item, valueKey);
              return (
                <MenuItem
                  key={theValue}
                  style={{
                    backgroundColor: primary.white,
                    opacity: 1,
                    textDecoration: item.needToDisable ? 'line-through' : 'none'
                  }}
                  value={theValue}
                  disabled={!!item.needToDisable}
                >
                  <Typography className={classes.select}>
                    {!!item.icon && item.icon}
                    {_.get(item, labelKey)}
                  </Typography>
                </MenuItem>
              );
            })}
          </Select>
          {!!helperText && <FormHelperText>{helperText}</FormHelperText>}
        </FormControl>
      )}
    </div>
  );
};
