import React, { FC, useMemo, useState, useEffect } from 'react';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { Grid, makeStyles, Tooltip } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { useHistory } from 'react-router-dom';
import { useBudgetContext } from '../../context';
import { format } from '../../../../../../util';
import { useTranslation } from '../../../../../../services/translation';
import { LoadingScreen } from '../../../../../../components/LoadingScreen';
import { PreviewNewBudgetDialog } from '../../../../../../components/PreviewNewBudget';
import { getMargenPercentage } from '../../utils';

const useStyles = makeStyles({
  total: {
    fontFamily: '"Oswald"',
    verticalAlign: 'baseline',
    textTransform: 'uppercase',
    fontWeight: 200,
    fontSize: '26px',
    color: '#FF5179',
    textAlign: 'start',
    marginBottom: 16
  }
});

export interface FooterProps {}

export const Footer: FC<FooterProps> = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [unableToSave, setUnableToSave] = useState<boolean>(false);
  const [showBudgetDialog, setShowBudgetDialog] = useState(false);
  const [isCleaned, setIsCleaned] = useState(false);

  const { products, saving, onSaveNew, methodsProducts, cleanBudget, validatingProduct, paymentMethods } = useBudgetContext();

  const total = useMemo(
    () =>
      products.reduce<number>((acum, product) => {
        const parcial = product.rows.reduce<number>((acum2, row) => acum2 + row.quantity * row.priceUnit, 0);
        return acum + parcial;
      }, 0),
    [products]
  );

  useEffect(() => {
    if (isCleaned && !products.length) {
      history.push('/budget');
    }
  }, [isCleaned, products, history]);

  const onError = () => {
    setShowBudgetDialog(false);
    setUnableToSave(true);
    setTimeout(() => setUnableToSave(false), 5000);
  };

  const validateProducts = () => {
    for (const product of products[0]?.rows ?? []) {
      if (product?.discountPercentage > getMargenPercentage()) {
        return true;
      }
    }
    return validatingProduct;
  };

  const hanldeConfirmModal = async (listOrderIds: (string | undefined)[] | undefined) => {
    listOrderIds?.forEach((orderId: string | undefined) => {
      enqueueSnackbar(t('The order $$$$ successfully saved').replace('$$$$', orderId as string), {
        variant: 'success'
      });
    });
    const cleaned = await cleanBudget();
    if (cleaned === 'cleaned') setIsCleaned(true);
  };

  const handleCancel = async () => {
    const cleaned = await cleanBudget();
    if (cleaned === 'cleaned') setIsCleaned(true);
  };

  const onFulfilled = (): void => {
    setShowBudgetDialog(true);
  };

  return (
    <>
      <div style={{ justifyContent: 'end', display: 'flex', padding: 8 }}>
        {paymentMethods.length && paymentMethods[0].Currency ? <Typography className={classes.total}>Total: {format(total, paymentMethods[0]?.Currency?.currency_symbol)}</Typography> : null}
      </div>
      <Grid container justify="center" spacing={4}>
        <Grid item>
          <Button onClick={() => handleCancel()}>{t('Cancel')}</Button>
        </Grid>
        <Tooltip title={validateProducts() ? 'la orden tiene productos con un descuento superior al permitido' : ''}>
          <Grid item>
            <Button variant="contained" color="primary" onClick={() => onSaveNew(onError, onFulfilled)} disabled={validateProducts() || unableToSave}>
              {t('Save')}
            </Button>
          </Grid>
        </Tooltip>
      </Grid>
      {saving && <LoadingScreen />}
      {showBudgetDialog && <PreviewNewBudgetDialog open title="Budget Detail" methodsProducts={methodsProducts} editUnitPrice={false} onConfirm={hanldeConfirmModal} />}
    </>
  );
};
