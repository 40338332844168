import { LibbyFetchDAO } from '../LibbyFetchDAO';

export class MessageConfigAlertUpdateDAO extends LibbyFetchDAO {
  constructor() {
    super('ster_message_config_alert_update', 'message_config_alert_id');
  }

  getAll() {
    return this.query().limit(0, 10000).run();
  }

  getConfigAlertByID(id: string) {
    return this.query().equals('message_config_alert_id', id).run();
  }
}
