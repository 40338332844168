import { LibbyFetchDAO } from './LibbyFetchDAO';

export class StockProductsDAO extends LibbyFetchDAO {
  constructor() {
    super('ster_product_stock_odoo', 'sku');
  }

  getProducts() {
    return this.query().limit(10000).run();
  }
}
