import React, { useCallback } from 'react';

import { COURIER_SERVICE } from '../../../../../const';
import { paymentMethodsId } from '../../../const';

interface UseValidationFormProps {
  setCustomErrors: React.Dispatch<React.SetStateAction<{ name: string; error: boolean; text: string }[]>>;
}

export const useValidationForm = ({ setCustomErrors }: UseValidationFormProps) => {
  const validationForm = useCallback(
    (values) => {
      let valid = true;
      const newArray: Array<{ name: string; error: boolean; text: string }> = [];
      if (values.shipment_type === COURIER_SERVICE.ANDREANI.toString() || values.shipment_type === COURIER_SERVICE.TRANSPORTE.toString()) {
        if (values.shipment_type === COURIER_SERVICE.TRANSPORTE.toString()) {
          if (values.transportation_company === '') {
            newArray.push({ name: 'transportationCompany', error: true, text: 'The $$$$ field is required' });
            valid = false;
          }
        }

        if (values.shipmentAddressState === '') {
          newArray.push({ name: 'state', error: true, text: 'The $$$$ field is required' });
          valid = false;
        }
        if (values.shipmentAddressCity === '') {
          newArray.push({ name: 'city', error: true, text: 'The $$$$ field is required' });
          valid = false;
        }
        if (values.shipmentAddressStreet === '') {
          newArray.push({ name: 'street', error: true, text: 'The $$$$ field is required' });
          valid = false;
        }
        if (values.shipmentAddressStreetNumber === '') {
          newArray.push({ name: 'streetNumber', error: true, text: 'The $$$$ field is required' });
          valid = false;
        }
        if (values.shipmentAddressZip === '') {
          newArray.push({ name: 'zip', error: true, text: 'The $$$$ field is required' });
          valid = false;
        }
      }

      if (values.payment_method === paymentMethodsId.CHEQUEPERSONALIZDO || values.payment_method === paymentMethodsId.ECHEQUEPERSONALIZDO) {
        if (values.custom_term === '') {
          newArray.push({ name: 'customTerm', error: true, text: 'The $$$$ field is required' });
          valid = false;
        }
      }

      if (values.advance_sale && !values.advance_sale_date) {
        newArray.push({ name: 'advanceSaleDate', error: true, text: 'The $$$$ field is required' });
        valid = false;
      }

      setCustomErrors(newArray);
      return valid;
    },
    [setCustomErrors]
  );

  return { validationForm };
};
