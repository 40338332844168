import { LibbyFetchDAO } from '../LibbyFetchDAO';

export class TransportationCompanySearchDAO extends LibbyFetchDAO {
  constructor() {
    super('ster_transportation_company_search', 'transportation_company_id');
  }

  getAll() {
    return this.query().limit(0, 10000).run();
  }

  getTransportationByName(name: string) {
    return this.query().equals('name', name).run();
  }
}
