import { DatabaseConnector } from '@phinxlab/libby-rest-web';
import React from 'react';
import { LibbyObject } from '../../types';
import { StockList } from './routes/StockList';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import { StockProcessList } from './routes/StockProcessList';

interface StockRawType {
  libby: LibbyObject;
}

const StockRaw = ({ libby }: StockRawType) => {
  const match = useRouteMatch();
  return (
    <Switch>
      <Route exact path={`${match.path}`}>
        <StockList title="Stock" libby={libby} />
      </Route>
      <Route exact path={`${match.path}/stock-process-sync`}>
        <StockProcessList title="Stock Process Sync" libby={libby} />
      </Route>
    </Switch>
  );
};

export const Stock = DatabaseConnector(StockRaw)('ster_product_stock', 'ster_product_stock_odoo', 'ster_product_stock_odoo_update', 'ster_ster_config');
