import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles(() => ({
  modalTitle: {
    '& h2.MuiTypography-h6': {
      fontSize: '24px !important',
      fontWeight: 600
    }
  },
  buttonsShipmentDiv: {
    display: 'flex',
    justifyContent: 'space-evenly',
    width: '100%'
  },
  buttonsShipmentDivDownXs: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    width: '100%'
  },
  modalContent: {
    fontSize: 16,
    marginTop: '12px'
  },
  buttonCopy: {
    fontSize: '12px',
    width: '50%'
  },
  buttonCopyDownXs: {
    fontSize: '12px',
    width: '100%'
  },
  halfWidth: {
    width: '50%'
  },
  buttonText: {
    '& button': {
      fontSize: 16
    }
  },
  textField: {
    '& label': {
      color: '#999999'
    },
    '& input': {
      color: 'black'
    },
    '& .MuiFormHelperText-root': {
      color: '#FF5179'
    },
    width: '100%'
  },
  textFieldSelect: {
    '& label': {
      color: '#999999'
    },
    '& input': {
      color: 'black'
    },
    '& .MuiFormHelperText-root': {
      color: '#FF5179'
    },
    width: '100%',
    marginBottom: '18px'
  },
  halfTextField: {
    '& label': {
      color: '#999999'
    },
    '& input': {
      color: 'black'
    },
    '& .MuiFormHelperText-root': {
      color: '#FF5179'
    },
    width: '50%'
  },
  halfTextFieldLeft: {
    '& label': {
      color: '#999999'
    },
    '& input': {
      color: 'black'
    },
    '& .MuiFormHelperText-root': {
      color: '#FF5179'
    },
    width: '50%',
    paddingRight: '10px'
  },
  paddingRightField: {
    paddingRight: '15px',
    width: '50%',
    '& label': {
      color: '#999999'
    },
    '& input': {
      color: 'black'
    }
  },
  root: {
    '& .MuiPaper-root': {
      borderRadius: '8px'
    },
    '& .MuiDialog-paperWidthSm': {
      maxWidth: '600px !important'
    }
  },
  rootAccordion: {
    '& .MuiAccordion-root:before': {
      opacity: '0 !important',
      display: 'none !important'
    }
  },
  accordion: {
    '& .MuiAccordionSummary-root': {
      padding: '0px !important'
    },
    boxShadow: 'none !important'
  },
  rootDetailsAccordion: {
    padding: '0px !important'
  },
  title: {
    fontFamily: 'Oswald'
  },
  headingAccordion: {
    fontSize: '15px',
    color: '#66788A'
  },
  headingAccordionError: {
    fontSize: '15px',
    color: '#FF5179'
  },
  errorForm: {
    color: '#FF5179',
    display: 'flex',
    justifyContent: 'flex-end'
  },
  error: {
    fontSize: '12px'
  }
}));
