import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, makeStyles } from '@material-ui/core';
import { AnyObject } from 'types';
import { FilterBarSelection } from 'routes/Reporting/routes/ReportingList/FilterBar';
import { useTranslation } from 'services/translation';

const useStyles = makeStyles({
  root: {
    width: '100%',
    marginTop: 15,
    background: (value: AnyObject) => (value.rootBackground ? '#ffffff' : 'none')
  },
  stickyHeader: {
    position: 'sticky',
    top: 0,
    background: 'white',
    zIndex: 3,
    borderBottom: '2px solid #ff5179'
  },
  cellMonth: {
    position: 'sticky',
    top: 0,
    zIndex: 2,
    background: 'white',
    borderLeft: '2px solid #ff5179',
    borderBottom: '2px solid #ff5179'
  },
  leftBorderCell: {
    position: 'sticky',
    top: 58,
    zIndex: 1,
    background: 'white',
    borderLeft: '2px solid #ff5179'
  },
  rightBorderCell: {
    position: 'sticky',
    top: 58,
    zIndex: 1,
    background: 'white',
    borderRight: '2px solid #ff5179'
  },
  tableContainer: {
    maxHeight: (value: AnyObject) => value.height,
    height: (value: AnyObject) => value.height
  }
});

const ChargebackMainTable = ({ data, months, filters }: { data: any; months: any[]; filters: FilterBarSelection }) => {
  const classes = useStyles({ height: 450, rootBackground: true });
  const { t } = useTranslation();
  return (
    <Paper className={classes.root}>
      <TableContainer className={classes.tableContainer} component={Paper}>
        <Table style={{ borderCollapse: 'separate' }}>
          <TableHead>
            <TableRow>
              <TableCell rowSpan={2} className={classes.stickyHeader}>
                {t('Marketplace')}
              </TableCell>
              {months.map((month) => (
                <TableCell colSpan={3} align="center" key={month.value} className={classes.cellMonth}>
                  {month.name}
                </TableCell>
              ))}
            </TableRow>
            <TableRow>
              {months.map((month) => (
                <React.Fragment key={month.value}>
                  <TableCell className={classes.leftBorderCell} align="center">
                    {t('Chargeback')}
                  </TableCell>
                  <TableCell className={classes.leftBorderCell} align="center">
                    {filters.view === 'monetary' ? t('Money earned') : t('Units sold')}
                  </TableCell>
                  <TableCell className={classes.leftBorderCell} align="center">
                    {t('Index')}
                  </TableCell>
                </React.Fragment>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.map((marketplace: any) => (
              <TableRow key={marketplace.marketplace_id}>
                <TableCell>{marketplace.name}</TableCell>
                {months.map((month, index) => {
                  const monthlyData = marketplace.monthly_data ? marketplace.monthly_data.find((m: any) => m.month === month.value) : null;

                  const chargeback = monthlyData?.chargeback_amount || 0;
                  const total_amount = filters.view === 'monetary' ? monthlyData?.total_amount || 0 : monthlyData?.total_orders || 0;
                  const chargeback_index = total_amount !== 0 ? `${((chargeback / monthlyData?.total_amount || 0) * 100).toString().slice(0, 4)}%` : '0%';

                  return (
                    <React.Fragment key={index}>
                      <TableCell align="center">${chargeback}</TableCell>
                      <TableCell align="center">{filters.view === 'monetary' ? `$${total_amount}` : total_amount}</TableCell>
                      <TableCell align="center">{chargeback_index}</TableCell>
                    </React.Fragment>
                  );
                })}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default ChargebackMainTable;
