export const ChipStyle = {
  marginRight: '10px',
  marginBottom: '10px',
  backgroundColor: '#FF5179',
  color: 'white',
  boxShadow: '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)',
  border: 'none',
  '& MuiChip-deleteIcon': {
    backgroundColor: 'black'
  }
};

export const RefreshButton = {
  width: '116px',
  height: '37px',
  marginRight: '5px'
};
