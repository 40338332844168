import { GoalUser } from '../../interfaces/business';
import { makeBusinessEntity } from '../../lib/libby/generator/makeBusinessEntity';

const DAO_NAME = 'ster_goal_user';

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch }
} = makeBusinessEntity<GoalUser>(DAO_NAME);

// hooks
export const useGoalUserFetchById = fetchById;
export const useGoalUserLibbyCall = libbyCall;
export const useGoalUserDAO = libbyDAO;
export const useGoalUserLibbyFetch = libbyFetch;

// hooks
export const useGoalUserUpdateFetchById = fetchById;
export const useGoalUserUpdateLibbyCall = libbyCall;
export const useGoalUserUpdateDAO = libbyDAO;
export const useGoalUserUpdateLibbyFetch = libbyFetch;
