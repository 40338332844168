import React, { ReactElement, useMemo } from 'react';
import { Message_template } from '../../../../interfaces/business/message';
import { RowMessage } from '../../components/RowMessage';
import { RowsOptions } from '../../components/RowOptions';

interface TableRowsType {
  templates: Message_template[];
  deleteMessageTemplate: (templates: Message_template) => Promise<void>;
  editMessageTemplate: (templates: Message_template) => Promise<void>;
  showMessageDialog: (message: string, isDownSm: boolean, isMobile: boolean) => void;
  isDownSm: boolean;
  isMobile: boolean;
}

interface dataRowType {
  actions: ReactElement<any, any>;
  id: string;
  name: string;
  message: ReactElement<any, any>;
  message_from: string;
  message_subject: string;
}

export const TableRows = ({ templates, showMessageDialog, editMessageTemplate, deleteMessageTemplate, isDownSm, isMobile }: TableRowsType) => {
  const rows = useMemo(() => {
    const dataRows: dataRowType[] = templates.reduce((allDataRows: dataRowType[], template: Message_template) => {
      const copyAllDataRows = [...allDataRows];
      const { message_template_id, name, message_from, message, message_subject } = template;
      copyAllDataRows.push({
        actions: <RowsOptions template={template} deleteMessageTemplate={deleteMessageTemplate} editMessageTemplate={editMessageTemplate} />,
        id: message_template_id,
        name,
        message: <RowMessage showMessageDialog={showMessageDialog} message={message} isDownSm={isDownSm} isMobile={isMobile} />,
        message_from: message_from?.email ?? '',
        message_subject
      });
      return copyAllDataRows;
    }, []);
    return dataRows;
  }, [templates, deleteMessageTemplate, editMessageTemplate, showMessageDialog, isDownSm, isMobile]);
  return { rows };
};
