import { Dispatch, SetStateAction, useCallback } from 'react';

export const useCleanBudget = (
  cleanProducts: () => Promise<any>,
  cleanPaymentMethod: () => void,
  setSelectedClient: Dispatch<SetStateAction<any>>,
  setSelectedTransport: Dispatch<SetStateAction<any>>,
  setSelectedTransportCompany: Dispatch<SetStateAction<any>>,
  setTransportationCompanyOptions: Dispatch<SetStateAction<any>>,
  setAdvanceSale: Dispatch<SetStateAction<any>>
) => {
  const cleanBudget = useCallback(async () => {
    await cleanProducts();
    cleanPaymentMethod();
    setSelectedClient({});
    setSelectedTransport({
      id: '',
      value: ''
    });
    setSelectedTransportCompany('');
    setTransportationCompanyOptions([]);
    setAdvanceSale({
      isAdvanceSale: false,
      advanceSaleDate: undefined
    });
    return 'cleaned';
  }, [cleanProducts, cleanPaymentMethod, setSelectedClient, setSelectedTransport, setSelectedTransportCompany, setTransportationCompanyOptions, setAdvanceSale]);

  return {
    cleanBudget
  };
};
