import { useCallback, useState } from 'react';
import { PaymentMethodData, PaymentMethodItem } from '../types';
import { BudgetContextValue, BudgetInitialValues } from '../types/BudgetContextValue';
import { useSnackbar } from 'notistack';

export const usePaymentMethods = (initialValues: BudgetInitialValues) => {
  const { enqueueSnackbar } = useSnackbar();
  const [paymentMethods, setPaymentMethods] = useState<PaymentMethodData>(initialValues.paymentMethods);

  const addPaymentMethod = useCallback<BudgetContextValue['addPaymentMethod']>(
    (method) => {
      if (method.plazo === 'Personalizado' || !paymentMethods.find((item) => item.id === method.id)) {
        setPaymentMethods([...paymentMethods, method]);
      }
    },
    [paymentMethods, setPaymentMethods]
  );

  const validateDays = useCallback(
    (newMethod: PaymentMethodItem, prev: PaymentMethodData): boolean => {
      if (newMethod.plazo === 'Personalizado' && prev.find((item) => item.id === newMethod.id)) {
        const filterPayments = prev.filter((payment) => payment.id === newMethod.id);
        if (filterPayments.length > 1) {
          for (let filterPayment of filterPayments) {
            if (filterPayment.paymentMethod.PaymentMethod.days === newMethod.paymentMethod.PaymentMethod.days && newMethod.code !== filterPayment.code) {
              enqueueSnackbar('no crear cheques con dias iguales', {
                variant: 'error'
              });
              return true;
            }
          }
          return false;
        } else return false;
      } else return false;
    },
    [enqueueSnackbar]
  );

  const editPaymentMethod = useCallback<BudgetContextValue['removePaymentMethod']>(
    (method) => {
      setPaymentMethods((prev) => {
        const index = prev.findIndex((item) => item.code === method.code);
        //VALIDA QUE NO EXISTA OTROS TIPO PERSONALIZADO con los mismos dias
        if (validateDays(method, prev)) {
          method.paymentMethod.PaymentMethod.days = 0;
        }
        return prev.map((item, _index) => (_index !== index ? item : method));
      });
    },
    [validateDays]
  );

  const removePaymentMethod = useCallback<BudgetContextValue['removePaymentMethod']>((method) => {
    setPaymentMethods((prev) => {
      const index = prev.findIndex((item) => item.code === method.code);
      return prev.filter((item, _index) => _index !== index);
    });
  }, []);

  const cleanPaymentMethod = useCallback(() => {
    setPaymentMethods([]);
  }, [setPaymentMethods]);

  return { paymentMethods, addPaymentMethod, editPaymentMethod, removePaymentMethod, cleanPaymentMethod };
};
