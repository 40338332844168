import React from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import { DatabaseConnector } from '@phinxlab/libby-rest-web';
import { LibbyObject } from '../../types';
import { useMessagesLogic } from './hooks/useMessagesLogic';
import { MessagesEmailSend } from './routes/MessagesEmailSend';

interface MessagesPros {
  libby: LibbyObject;
}

export const MessageRaw = ({ libby }: MessagesPros) => {
  const { path } = useRouteMatch();

  const { working, rows, columns, fetchMore, orderBy, filter, setFilter, resetFilter, direction, handleRequestSort } = useMessagesLogic({
    libby
  });

  return (
    <Switch>
      <Route exact path={[`${path}`]}>
        <MessagesEmailSend
          rows={rows}
          title="Messages list"
          columns={columns}
          fetchMore={fetchMore}
          working={working}
          orderBy={orderBy}
          filter={filter}
          setFilter={setFilter}
          resetFilter={resetFilter}
          direction={direction}
          handleRequestSort={handleRequestSort}
        />
      </Route>
    </Switch>
  );
};

export const Message = DatabaseConnector(MessageRaw)('ster_message', 'ster_message_updated');
