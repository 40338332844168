import { useCallback, useEffect, useState } from 'react';
import axios from 'axios';
import { API_URL } from '../../../config';
import { GET_EMBED_URL } from '../../../const';
import { TokenManager } from '../../../platform/libby/TokenManager';

export const useQueryEmbedUrl = (username: string, dashboardId: string) => {
  const [url, setUrl] = useState<string>('');
  const [loading, setLoading] = useState(false);

  const fetchEmbedUrl = useCallback(async (_username: string, _dashboardId: string) => {
    setLoading(true);
    try {
      const path = `${API_URL}/${GET_EMBED_URL}?username=${_username}&dashboardId=${_dashboardId}`;
      const response = await axios.get(path, {
        headers: {
          'x-chino-token': await TokenManager.create().retrieve()
        }
      });

      setUrl(response.data.url);
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchEmbedUrl(username, dashboardId);
  }, [dashboardId, fetchEmbedUrl, username]);

  return {
    url,
    loading,
    fetchEmbedUrl
  };
};
