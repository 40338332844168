import React from 'react';
import { Link } from 'react-router-dom';

export const OrderLiquidationGroupColumns = {
  orderLiquidationGroupId: {
    id: 'order_liquidation_group_id',
    label: 'ID Liquidation Group',
    style: {
      whiteSpace: 'nowrap',
      minWidth: '150px'
    }
  },
  reference: {
    id: 'reference',
    label: 'Reference',
    style: {
      whiteSpace: 'nowrap'
    },
    noSort: true
  },
  startDate: {
    id: 'start_date',
    label: 'Start Date',
    style: {
      whiteSpace: 'nowrap'
    },
    noSort: true
  },
  endDate: {
    id: 'end_date',
    label: 'End Date',
    style: {
      whiteSpace: 'nowrap'
    },
    noSort: true
  },
  marketplace: {
    id: 'marketplace',
    label: 'Marketplace',
    style: {
      whiteSpace: 'nowrap'
    },
    orderById: 'marketplace'
  },
  username: {
    id: 'username',
    label: 'Username',
    style: {
      whiteSpace: 'nowrap'
    },
    orderById: 'account'
  },
  liquidation_date: {
    id: 'liquidation_date',
    label: 'Liquidation Date',
    style: {
      whiteSpace: 'nowrap',
      minWidth: '150px'
    },
    noSort: true
  },
  detail: {
    id: 'detail',
    label: 'Detail',
    noSort: true
  },
  files: {
    id: 'files',
    label: 'Files',
    render: (rows: any, item: any, t: (str: string) => string) => (
      <Link target="_blank" to={{ pathname: `${rows.files}` }}>
        {t('Download')}
      </Link>
    ),
    noSort: true
  }
};
