import { AnyObject } from '../types/types';

export enum ORDER_STATE {
  CREATED = 1,
  SOLD = 2,
  ON_QUEUE = 3,
  WAITING_FOR_PREPARATION = 4,
  PREPARED = 5,
  READY_FOR_DELIVERY = 6,
  OUT_FOR_DELIVERY = 8,
  DELIVERED = 9,
  CANCELLED = 10,
  PRODUCTION = 11,
  PAID = 12,
  UPLOAD_TO_COLLECTIONS = 13,
  READY_TO_RESEND = 14,
  RETURNED = 15,
  TRACKER_INFORMED = 16,
  BILL_INFORMED = 17,
  COLLECTED = 18,
  WAITING_FOR_EXTERNAL_DELIVERY = 19,
  EXTERNAL_DELIVERY_READY = 20,
  WAITING_FOR_APPROVAL = 21,
  WAITING_TO_CANCEL = 22,
  RETURNED_COMPLETED = 23,
  RETURNING_TO_CANCEL = 24,
  READY_FOR_PREPARATION = 25,
  WAITING_FOR_RETURN = 26,
  CANCELLATION_FOR_FRAUD = 27,
  RETAIN = 28,
  DRAFT = 29,
  DRAFT_REVISION = 30,
  BUDGET = 31,
  BUDGET_TO_BE_CONFIRMED = 32,
  FRAUD = 33,
  CHARGEBACK = 999
}

export const STATES_AFTER_OUT_FOR_DELIVERY = [ORDER_STATE.OUT_FOR_DELIVERY, ORDER_STATE.DELIVERED, ORDER_STATE.COLLECTED, ORDER_STATE.PAID, ORDER_STATE.BILL_INFORMED, ORDER_STATE.TRACKER_INFORMED];

export const OrderState: AnyObject = {
  1: 'Created',
  2: 'Sold',
  3: 'On queue',
  4: 'Waiting for preparation',
  5: 'Prepared',
  6: 'Ready for delivery',
  8: 'Out for delivery',
  9: 'Delivered',
  10: 'Cancelled',
  11: 'Production',
  12: 'Paid',
  13: 'Upload to collections',
  14: 'Ready to resend',
  15: 'Returned',
  16: 'Tracker Informed',
  17: 'Bill Informed',
  18: 'Collected',
  19: 'Waiting for external delivery',
  20: 'External delivery ready',
  21: 'Waiting for approval',
  22: 'Waiting to cancel',
  23: 'Returned completed',
  24: 'Returning to cancel',
  25: 'Ready for preparation',
  26: 'Waiting for return',
  27: 'Cancellation for Fraud',
  28: 'Retained',
  29: 'Draft',
  30: 'Draft Revision',
  31: 'Budget'
};

export const OrderStateColors: AnyObject = {
  1: 'purple',
  2: 'blue',
  3: 'grey',
  4: 'orange',
  5: 'black',
  6: 'Lilac',
  8: 'Plum',
  9: 'green',
  10: 'red',
  11: 'violet',
  33: 'red'
};
