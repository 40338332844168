import { formatDate, translate } from '../../../../utils';
import { Courier_service, Order_shipment, Order_shipment_address, Order_shipment_receiver, Order_shipment_state } from '../../../../interfaces/business';
import { LINK_TRACKING_BY_COURIER, COURIER } from '../../../../const';
import { buyerDirection } from './buyerDirection';

const receiver = (value: Order_shipment_receiver) => {
  let receiverName = '';

  if (value?.first_name || value?.last_name) {
    receiverName += `${value?.first_name} ${value?.last_name || ''} |`;
  }

  if (value?.document_type?.name || value?.document) {
    receiverName += `${value?.document_type?.name} ${value?.document} |`;
  }

  if (value?.email) {
    receiverName += `${value?.email} |`;
  }

  if (value?.comments) {
    receiverName += value?.comments;
  }

  return receiverName;
};

export const shipmentMap = [
  {
    key: 'type',
    label: 'Type',
    formatter: (value: any) => translate(value)
  },
  {
    key: 'service',
    label: 'Service',
    formatter: (service: Courier_service) => translate(service?.name)
  },
  {
    key: 'courier',
    label: 'Courier',
    formatter: (value: any, data: Order_shipment) => translate(data?.service?.courier?.name)
  },

  {
    key: 'address',
    label: 'Shipment Address',
    formatter: (value: any) => buyerDirection(value?.street, value?.street_number, value?.city?.name, value?.floor, value?.department, value?.extra_address, value?.comments, value?.city?.state?.name)
  },
  {
    key: 'address',
    label: 'Zip',
    formatter: (value: Order_shipment_address) => value.zip || 'N/A'
  },
  {
    key: 'comments',
    label: 'Comments',
    formatter: (value: any, data: any) => data?.address?.comments || '-'
  },
  {
    key: 'receiver',
    label: 'Receiver',
    formatter: (value: Order_shipment_receiver) => receiver(value)
  },
  {
    key: 'state',
    label: 'State',
    formatter: (state: Order_shipment_state) => translate(state?.name)
  },
  {
    key: 'tracking',
    label: 'Tracking',
    link: (tracking: string, { service }: Order_shipment) => {
      const couriersWithURLThatDoesntIncludeTrackingID: string[] = [COURIER.FAST_MAIL];
      const courier_id = service?.courier?.courier_id;
      const link = LINK_TRACKING_BY_COURIER[courier_id];
      const isURLOnly = couriersWithURLThatDoesntIncludeTrackingID.includes(courier_id);
      return link ? (isURLOnly ? link : `${link}/${tracking}`) : null;
    },
    formatter: (value: any) => value || ''
  },
  {
    key: 'updated_at',
    label: 'Last Update',
    formatter: (value: any) => formatDate(value, true, false)
  },
  {
    key: 'transportation_company',
    label: 'Carrier',
    formatter: (value: any) => (value ? `${value?.name}, ${value?.street} ${value?.street_number}, ${value?.phone_number}` : '-')
  }
];
