import React from 'react';
import { useHistory } from 'react-router-dom';
import { ScreenAligned } from '../../../../components/ScreenAligned/ScreenAligned';
import InfoTable from '../../../components/InfoTable';
import LoadingData from '../../../components/LoadingData';
import { useTranslation } from '../../../../services/translation';
import { LibbyObject } from '../../../../types/types';
import { TitleBreadcrumbs } from '../../../../interfaces';
import { columns } from '../../utils/columns';
import { RowsType } from '../../../../utils/tables';
import { SearchFilters } from './components/SearchFilters';

type ReportingPendingAndreaniListProps = {
  working: any;
  filter: LibbyObject;
  setFilter: (makeFilter: object) => void;
  fetchMore: () => void;
  orderBy?: string;
  direction?: 'asc' | 'desc';
  onSortChange?: (orderBy: string, direction: 'asc' | 'desc') => void;
  title: TitleBreadcrumbs[];
  rows: Array<RowsType>;
};

export const ReportingPendingAndreaniList = ({ working, setFilter, filter, fetchMore, onSortChange, orderBy, direction, title, rows }: ReportingPendingAndreaniListProps) => {
  const { t } = useTranslation();
  const history = useHistory();

  const goToDetails = (row: LibbyObject) => {
    history.push({
      pathname: `/orders/detail/${row.order_id}`,
      state: {
        goBack: title
      }
    });
  };

  return (
    <ScreenAligned title={title}>
      <SearchFilters onFilterChange={setFilter} filter={filter} />
      <InfoTable columns={columns} rows={rows} onBottomScroll={fetchMore} rowIdKey="order_id" onSortChange={onSortChange} onRowClick={goToDetails} orderBy={orderBy} direction={direction} />
      <LoadingData label={`${t('Loaded registers')}`} working={working} cant={rows.length} />
    </ScreenAligned>
  );
};
