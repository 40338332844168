import React, { useCallback, useMemo } from 'react';
import { DatabaseConnector } from '@phinxlab/libby-rest-web';
import { TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { AnyObject, LibbyObject } from '../../types/types';
import { GeneralSelect } from '../../components/GeneralSelect';
import { useLibbyFetch } from '../../hooks/useLibbyFetch';
import { makeFilter } from '../Orders/routes/OrdersList/utils/makeFilter';
import { useTranslation } from '../../services/translation';
import { DataGenerateSelectType } from '../../utils/generateSelect';
import { MENU_ACTIONS } from '../../const';
import { COURIER_ID } from '../../const/CourierId';
import { useMenuAction } from 'hooks/useMenuActions';

type SelectImportTypeProps = {
  multiple: boolean;
  typeOrderCourierSearch: string;
  inputProps: AnyObject;
  optionAll?: boolean;
  [k: string]: any;
  libby: LibbyObject;
  disabled?: boolean;
  sort?: boolean | 'asc' | 'desc';
};

type Item = {
  id: string;
  value: string;
};

export const SelectOrderCourierRaw = ({ multiple = false, typeOrderCourierSearch, libby, inputProps = {}, optionAll = false, disabled = false, sort, ...styles }: SelectImportTypeProps) => {
  const { t } = useTranslation();
  const { validateAction } = useMenuAction();
  const filter = useMemo(() => {
    const typeOrderCourierMarketplaceSearch = typeOrderCourierSearch;
    return makeFilter({ typeOrderCourierMarketplaceSearch });
  }, [typeOrderCourierSearch]);

  const paramsFetch = useMemo(
    () => ({
      daoName: 'courier_order',
      filter
    }),
    [filter]
  );

  const { data: dataRaw, working }: { data: AnyObject; working: boolean } = useLibbyFetch(libby, paramsFetch);

  const data = useMemo(
    () =>
      dataRaw.map(({ courier_id, name }: AnyObject) => ({
        id: courier_id,
        value: name
      })),
    [dataRaw]
  );

  const onChangeAutoComplete = useCallback(
    (event, newValue: DataGenerateSelectType[]) => {
      inputProps.onChange(
        newValue.map((marketplace: DataGenerateSelectType) => ({
          value: marketplace.id,
          path: ''
        }))
      );
    },
    [inputProps]
  );

  return (
    <>
      {multiple ? (
        <Autocomplete
          disabled={disabled}
          style={{ display: 'inline-block', width: '100%' }}
          multiple
          id="tags-outlined"
          options={data}
          getOptionLabel={(option: Item) => option.value}
          filterSelectedOptions
          onChange={onChangeAutoComplete}
          renderInput={(params) => <TextField {...params} variant="outlined" label={t('All the courier')} placeholder="" />}
        />
      ) : (
        <GeneralSelect
          disabled={disabled}
          working={working}
          label="Courier"
          optionAll={optionAll}
          data={
            validateAction(MENU_ACTIONS.SLCT_COURIER_FILTER_TRANSPORT)
              ? data.filter((item: Item) => item.id === COURIER_ID.TRANSPORTE.toString())
              : validateAction(MENU_ACTIONS.SLCT_COURIER_FILTER_TALAVERA_DRIVER)
              ? data.filter((item: Item) => item.id === COURIER_ID.TALAVERA_1.toString() || item.id === COURIER_ID.TALAVERA_2.toString() || item.id === COURIER_ID.TALAVERA_3.toString())
              : data
          }
          inputProps={inputProps}
          {...styles}
          sort={sort}
        />
      )}
    </>
  );
};

export const SelectOrderCourier = DatabaseConnector(SelectOrderCourierRaw)('courier_order');
