import React from 'react';
import { useParams } from 'react-router-dom';
import { BudgetDetailsProps } from '../../types';
import { BudgetForm } from '../BudgetNew/components';
import { useEditOrder } from '../BudgetNew/hook/useEditOrder';

export const BudgetCopy = ({ title, libby }: BudgetDetailsProps) => {
  const params = useParams<{ id: string }>();
  const { formValues, formItems, previousOrderData } = useEditOrder({ libby, orderId: params.id });

  return <BudgetForm libby={libby} title={title} budgetEditProps={{ formValues, formItems, previousOrderData }} />;
};
