import { Autocomplete } from '@material-ui/lab';
import { TextField } from '@material-ui/core';
import React, { useCallback, useMemo } from 'react';
import { GeneralSelect } from '../GeneralSelect';
import { useTranslation } from '../../services/translation';
import { AnyObject } from '../../types';
import { DataGenerateSelectType } from '../../utils/generateSelect';

type TextFieldVariant = 'filled' | 'standard' | 'outlined';
interface SelectConfirmationProps {
  multiple?: boolean;
  inputProps: AnyObject;
  optionAll?: boolean;
  variant?: TextFieldVariant;
  onChange?: (value: any) => void;

  [k: string]: any;

  disabled?: boolean;
  sort?: boolean | 'asc' | 'desc';
  label?: string;
}

const optionsData = [
  { id: 'yes', value: 'Yes' },
  { id: 'no', value: 'No' }
];

export const SelectConfirmation = ({ multiple = false, inputProps = {}, optionAll = false, disabled = false, sort, label, variant = 'standard', onChange, ...styles }: SelectConfirmationProps) => {
  const { t } = useTranslation();

  const onChangeAutoComplete = useCallback(
    (event, newValue: DataGenerateSelectType[]) => {
      inputProps.onChange(
        newValue.map((option: DataGenerateSelectType) => ({
          value: option.id,
          path: ''
        }))
      );
    },
    [inputProps]
  );

  const options = useMemo(() => optionsData.map((option) => ({ ...option, value: t(option.value) })), [t]);

  return (
    <>
      {multiple ? (
        <Autocomplete
          disabled={disabled}
          style={{ display: 'inline-block', width: '100%' }}
          multiple
          id="tags-outlined"
          options={options}
          getOptionLabel={(option: { id: string; value: string }) => option.value}
          filterSelectedOptions
          onChange={onChangeAutoComplete}
          renderInput={(params) => <TextField {...params} variant="standard" label={t(label || '')} placeholder="" />}
        />
      ) : (
        <GeneralSelect disabled={disabled} working={false} label={t(label || '')} optionAll={optionAll} data={options} inputProps={onChange ? { ...inputProps, onChange } : inputProps} {...styles} sort={sort} variant={variant} />
      )}
    </>
  );
};
