import { useCallback } from 'react';
import axios from 'axios';
import { API_URL } from 'config';
import { GET_BILLINGS } from 'const';
import { TokenManager } from 'platform/libby/TokenManager';

interface IBillingQuery {
  marketplace_id: string;
  marketplace_type: string | undefined;
  expiration_date: string;
  limit: number;
  offset: number;
}

export const useBillings = (setFetchLoading: React.Dispatch<React.SetStateAction<number>>, setLoadingPercentage: React.Dispatch<React.SetStateAction<number>>) => {
  /* const [billings, setBillings] = useState<any[]>([]); */

  const fetchBillings = useCallback(async (billingQuery: IBillingQuery) => {
    const baseUrl = `${API_URL}/${GET_BILLINGS}`;
    const { marketplace_id, marketplace_type, expiration_date, limit, offset } = billingQuery;
    let queryParams = `marketplace_id=${marketplace_id}&group=${marketplace_type}&expiration_date=${expiration_date}&limit=${limit}&offset=${offset}`;

    const response = await axios.get(`${baseUrl}?${queryParams}`, {
      headers: {
        'x-chino-token': await TokenManager.create().retrieve()
      }
    });

    return response.data.billings;
  }, []);

  const fetchAllBillings = useCallback(
    async (marketplace_id: string, marketplace_type: string | undefined, expiration_date: string) => {
      setFetchLoading(0);
      setLoadingPercentage(0);
      const limit = 100;
      let offset = 0;
      let moreDataAvailable = true;
      let allBillings: any[] = [];

      while (moreDataAvailable) {
        try {
          const billingsResp = await fetchBillings({
            marketplace_id,
            marketplace_type,
            expiration_date,
            limit,
            offset
          });

          allBillings = [...allBillings, ...billingsResp];
          const ordersRequestLength = billingsResp.length;
          const allOrdersBeingLoaded = allBillings.length;
          setFetchLoading(() => allOrdersBeingLoaded);
          setLoadingPercentage((prevState) => (prevState + ordersRequestLength / 10 >= 100 ? (prevState + 1 >= 100 ? 99 : prevState + 1) : prevState + ordersRequestLength / 10));

          if (billingsResp.length < limit) {
            moreDataAvailable = false;
            setLoadingPercentage(() => 99);
          } else {
            offset += limit;
          }
        } catch (e) {
          console.error('error getting billing', e);
          moreDataAvailable = false;
        }
      }
      setLoadingPercentage(() => 100);
      return allBillings;
    },
    [fetchBillings, setFetchLoading, setLoadingPercentage]
  );

  return {
    fetchAllBillings
  };
};
