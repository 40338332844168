import { makeValidator, ValidatorRule, ValidatorType } from '../../../../../utils';

export const formReportingGoalPeriodInitialValues = {
  value: '',
  start_date: '',
  end_date: '',
  month_year: ''
};

const validations: ValidatorRule[] = [{ key: 'value', validators: [ValidatorType.REQUIRED] }];

export const validateReportingGoalPeriodAdd = makeValidator(validations);
