import { Moment } from 'moment';
import { AnyObject } from '../../../../../../types/types';
import { endDateFormatUtc, startDateFormatUtc } from '../../../../../../utils';

export interface Marketplace {
  value: string;
  path: string;
  method: string;
}

export type MakeFilterProps = {
  search?: string;
  startDate?: Moment | null;
  endDate?: Moment | null;
  marketplaces?: Marketplace[];
};

export const makeFilter = ({ search, startDate, endDate, marketplaces }: MakeFilterProps) => {
  let filter: AnyObject = {};

  if (search) {
    filter = search.split(' ').reduce((accumulator: AnyObject, item, index) => {
      accumulator[index] = [
        {
          path: 'order_id',
          value: search,
          method: 'includes'
        },
        {
          path: 'so_number',
          value: item,
          method: 'includes'
        },
        {
          path: 'owner_id',
          value: item,
          method: 'includes'
        }
      ];

      return accumulator;
    }, {});
  }

  filter.marketplaces = marketplaces;

  if (startDate) {
    filter.startDate = [
      {
        path: 'updated_at',
        value: startDateFormatUtc(startDate),
        method: 'higherOrEquals'
      }
    ];
  }

  if (endDate) {
    filter.endDate = [
      {
        path: 'updated_at',
        value: endDateFormatUtc(endDate),
        method: 'lowerOrEquals'
      }
    ];
  }
  return filter;
};
