import { useTranslation } from '../../../../../services/translation';
import { formatDate, translate } from '../../../../../utils';
import { makeMapLabelValues } from '../../../../utils/mappers';

const basicInformationMap = [
  {
    key: 'inbox_order_id',
    label: 'ID',
    formatter: (value: any) => value || ''
  },
  {
    key: 'updated_at',
    label: 'Last Update',
    formatter: (value: any) => formatDate(value, true, false)
  },
  {
    key: 'marketplace',
    label: 'Marketplace',
    formatter: (value: any) => value?.name
  },
  {
    key: 'owner_id',
    label: 'Owner ID',
    formatter: (value: any) => value || ''
  },
  {
    key: 'state',
    label: 'State',
    formatter: ({ name }: { name: string }) => translate(name)
  },
  {
    key: 'reference',
    label: 'Reference',
    formatter: (value: string) => translate(value || '')
  }
];

const errorMap = [
  {
    key: 'situation',
    label: 'Error',
    formatter: (value: any) => value.exception
  }
];

export const useInboxOrderMappers = () => {
  const { t } = useTranslation();

  const mapperLabelValues = makeMapLabelValues(t);
  const basicMapper = mapperLabelValues(basicInformationMap);
  const errorMapper = mapperLabelValues(errorMap);

  return { basicMapper, errorMapper };
};
