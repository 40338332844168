import React from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import { MENU, MENU_ACTIONS } from '../../const';
import { useMenuAction } from '../../hooks/useMenuActions';
import { ReportsPurchases } from './routes/ReportsPurchases';
import { ReportsSales } from './routes/ReportsSales';
import { ReportsStock } from './routes/ReportsStock';
import { Footer } from '../../components/Footer';

export const ReportsQuicksight = () => {
  const match = useRouteMatch();

  const { validateMenuActions } = useMenuAction(MENU.REPORTING_LOGISTIC);

  return (
    <Switch>
      {validateMenuActions(MENU_ACTIONS.SETTINGS.toString()) && (
        <>
          <Route exact path={`${match.path}/purchases`}>
            <ReportsPurchases />
          </Route>
          <Route exact path={`${match.path}/sales`}>
            <ReportsSales />
          </Route>
          <Route exact path={`${match.path}/stock`}>
            <ReportsStock />
          </Route>
          <Footer />
        </>
      )}
    </Switch>
  );
};
