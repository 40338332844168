import React, { useMemo } from 'react';
import { Link, useHistory, useRouteMatch } from 'react-router-dom';
import AssignmentIcon from '@material-ui/icons/Assignment';
import { makeStyles } from '@material-ui/styles';
import { createStyles, Theme } from '@material-ui/core/styles';
import { ScreenAligned } from '../../../../components/ScreenAligned/ScreenAligned';
import { columns } from '../../utils/columns';
import InfoTable from '../../../components/InfoTable';
import LoadingData from '../../../components/LoadingData';
import { useTranslation } from '../../../../services/translation';
import { ERPOrder } from '../../types/ERPOrder';
import { SearchFilters } from './components/SearchFilters';
import { AnyObject, LibbyObject } from '../../../../types/types';
import { formatDate } from '../../../../utils';
import { TitleBreadcrumbs } from '../../../../interfaces';
import { RowsType } from '../../../../utils/tables';

type ERPOrderListProps = {
  data: AnyObject[];
  working: any;
  filter: LibbyObject;
  setFilter: (makeFilter: object) => void;
  fetchMore: () => void;
  orderBy?: string;
  direction?: 'asc' | 'desc';
  onSortChange?: (orderBy: string, direction: 'asc' | 'desc') => void;
  title: TitleBreadcrumbs[];
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    linkDetails: {
      textDecoration: 'none',
      color: theme.palette.secondary.main
    }
  })
);

export const ERPOrderList = ({ data, working, setFilter, filter, fetchMore, onSortChange, orderBy, direction, title }: ERPOrderListProps) => {
  const { t } = useTranslation();
  const { path } = useRouteMatch();
  const history = useHistory();
  const classes = useStyles();

  const dataERPOrder = data as ERPOrder[];

  const rows: RowsType[] = useMemo(
    () =>
      dataERPOrder.reduce((result: RowsType[], erpOrder: ERPOrder) => {
        result.push({
          id: erpOrder.so_id.toString(),
          so_id: erpOrder.so_id,
          sales_channel_number: erpOrder.sales_channel_number,
          sales_channel_name: erpOrder.sales_channel_name,
          sales_team: erpOrder.sales_team,
          invoice_date: formatDate(erpOrder.invoice_date, false),
          invoice_number: erpOrder.invoice_number,
          so_name: erpOrder.so_name,
          client_name: erpOrder.client_name,
          client_phone: erpOrder.client_phone,
          client_movil: erpOrder.client_movil,
          client_mail: erpOrder.client_mail,
          client_address: erpOrder.client_address,
          shipment_date: formatDate(erpOrder.shipment_date, false),
          shipment_tracking: erpOrder.shipment_tracking,
          id_ml: erpOrder.id_ml,
          state: erpOrder.state,
          credit_note_number: erpOrder.credit_note_number,
          details: (
            <Link className={classes.linkDetails} to={`${path}/details/${erpOrder.so_id}`}>
              <AssignmentIcon />
            </Link>
          )
        });
        return result;
      }, []),
    [dataERPOrder, path, classes]
  );

  const goToDetails = (row: LibbyObject) => {
    history.push(`${path}/detail/${row.so_id}`);
  };

  return (
    <ScreenAligned title={title}>
      <SearchFilters onFilterChange={setFilter} filter={filter} />
      <InfoTable columns={columns} rows={rows} onBottomScroll={fetchMore} rowIdKey="so_id" onSortChange={onSortChange} onRowClick={goToDetails} orderBy={orderBy} direction={direction} />
      <LoadingData label={`${t('Loaded registers')}`} working={working} cant={rows.length} />
    </ScreenAligned>
  );
};
