import React, { useCallback } from 'react';
import IconButton from '@material-ui/core/IconButton';
import AccountCircle from '@material-ui/icons/AccountCircle';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import { makeStyles } from '@material-ui/styles';
import { CircularProgress } from '@material-ui/core';
import { useTranslation } from '../../services/translation';
import { LibbyObject, userInfoType } from '../../types';
import { useGlobalContext } from '../../hooks';
import { useAccountModals } from '../../routes/Accounts/routes/AccountsList/hooks/useAccountModals';
import { makeFilter } from '../../routes/Accounts/routes/AccountsList/utils/makeFilter';

const useStyles = makeStyles(() => ({
  menuButton: {
    paddingRight: 0
  },
  progress: {
    marginLeft: '20px'
  }
}));

export type AccountButtonProps = {
  onLogoutClick: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
  color?: 'inherit' | 'primary' | 'secondary' | 'default';
  libby: LibbyObject;
};

export const AccountButton = ({ onLogoutClick, color = 'inherit', libby }: AccountButtonProps) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleMenu = useCallback(
    (event) => {
      setAnchorEl(event.currentTarget);
    },
    [setAnchorEl]
  );

  const { userInfo }: { userInfo: userInfoType } = useGlobalContext();

  const reFetch = useCallback(() => {}, []);

  const { createAccountEditModal, loading } = useAccountModals({
    libby,
    reFetch
  });

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, [setAnchorEl]);

  const changePassword = useCallback(async () => {
    const [data] = await libby.admin_account.fetch({
      filter: makeFilter({ account_id: userInfo && userInfo.id }),
      daoName: 'admin_account'
    });
    if (data) {
      createAccountEditModal(data, 'password', 'password', handleClose);
    }
  }, [createAccountEditModal, libby.admin_account, userInfo, handleClose]);

  const onLogout = useCallback(
    (event) => {
      onLogoutClick(event);
      handleClose();
    },
    [onLogoutClick, handleClose]
  );
  return (
    <div>
      <IconButton aria-label="account of current user" aria-controls="menu-appbar" aria-haspopup="true" onClick={handleMenu} color={color} className={classes.menuButton}>
        <AccountCircle fontSize="large" />
      </IconButton>
      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        open={!!anchorEl}
        onClose={handleClose}
      >
        <MenuItem onClick={onLogout}>{t('Logout')}</MenuItem>
        <MenuItem onClick={changePassword}>
          {t('Change Password')}
          {loading && <CircularProgress className={classes.progress} size={20} />}
        </MenuItem>
      </Menu>
    </div>
  );
};
