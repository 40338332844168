import { FieldRenderProps } from 'react-final-form-hooks';
import { createContext } from 'react';
import { AnyObject } from '../../../../../types';
import { BudgetEditProps } from '../../../types';
import { BudgetClient } from '../../../interfaces';
import { TransportationCompany } from '../../../../../interfaces/business';

export interface PaymentMethodsType {
  id: string;
  plazo: string;
  name: string;
}

export interface BudgetFormContextType extends AnyObject {
  customErrors: Array<{ name: string; error: boolean; text: string }>;
  payment_method: FieldRenderProps<any, string> | any;
  custom_term: FieldRenderProps<any, string> | any;
  first_name: FieldRenderProps<any, string> | any;
  last_name: FieldRenderProps<any, string> | any;
  document_type: FieldRenderProps<any, string> | any;
  phone_number: FieldRenderProps<any, string> | any;
  email: FieldRenderProps<any, string> | any;
  document: FieldRenderProps<any, string> | any;
  billingAddressState: FieldRenderProps<any, string> | any;
  billingAddressCity: FieldRenderProps<any, string> | any;
  billingAddressStreet: FieldRenderProps<any, string> | any;
  billingAddressStreetNumber: FieldRenderProps<any, string> | any;
  billingAddressFloor: FieldRenderProps<any, string> | any;
  billingAddressDepartment: FieldRenderProps<any, string> | any;
  billingAddressZip: FieldRenderProps<any, string> | any;
  shipmentAddressStreet: FieldRenderProps<any, string> | any;
  shipmentAddressStreetNumber: FieldRenderProps<any, string> | any;
  shipmentAddressZip: FieldRenderProps<any, string> | any;
  shipmentAddressFloor: FieldRenderProps<any, string> | any;
  shipmentAddressDepartment: FieldRenderProps<any, string> | any;
  shipmentAddressComments: FieldRenderProps<any, string> | any;
  shipmentAddressState: FieldRenderProps<any, string> | any;
  shipmentAddressCity: FieldRenderProps<any, string> | any;
  shipment_type: FieldRenderProps<any, string> | any;
  paymentMethodsGroup: Array<PaymentMethodsType>;
  budgetEditProps?: BudgetEditProps;
  loadingSearch: boolean | undefined;
  clientSearch: string;
  setClientSearch: (v: string) => void;
  handleClient: (selectedClient: BudgetClient) => void;
  budgetUsers: any;
  client: BudgetClient | undefined | null;
  billingAddressesOptions: Array<{ id: string; value: string }>;
  billingAddress: string;
  handleChangeAddress: (event: any, type: string) => void;
  stateOptions: Array<{ id: string; value: any }>;
  fetchingStates: boolean | undefined;
  addressRequired: boolean;
  setTransportationSelect: (v: string) => void;
  transportation_company: FieldRenderProps<any, string> | any;
  setTransportationSearch: (v: string) => void;
  fetchingTransportationCompany: boolean | undefined;
  transportationCompanyOptions: any;
  carrierData: TransportationCompany | undefined;
  shipmentAddress: string;
  shippingAddressesOptions: Array<{ id: string; value: string }>;
}

export const budgetFormContextInitialValue: BudgetFormContextType = {
  customErrors: [],
  payment_method: {},
  custom_term: {},
  paymentMethodsGroup: [],
  setClientSearch: () => {},
  loadingSearch: undefined,
  handleClient: () => {},
  clientSearch: '',
  budgetUsers: [],
  first_name: {},
  last_name: {},
  email: {},
  phone_number: {},
  document_type: {},
  document: {},
  client: null,
  billingAddressesOptions: [],
  billingAddress: '',
  handleChangeAddress: () => {},
  stateOptions: [],
  fetchingStates: undefined,
  billingAddressState: {},
  billingAddressCity: {},
  billingAddressDepartment: {},
  billingAddressFloor: {},
  billingAddressStreet: {},
  billingAddressStreetNumber: {},
  billingAddressZip: {},
  shipment_type: {},
  addressRequired: false,
  setTransportationSelect: () => {},
  transportation_company: {},
  setTransportationSearch: () => {},
  fetchingTransportationCompany: undefined,
  transportationCompanyOptions: [],
  carrierData: undefined,
  shipmentAddress: '',
  shippingAddressesOptions: [],
  shipmentAddressComments: {},
  shipmentAddressDepartment: {},
  shipmentAddressFloor: {},
  shipmentAddressStreet: {},
  shipmentAddressStreetNumber: {},
  shipmentAddressZip: {},
  shipmentAddressState: {},
  shipmentAddressCity: {}
};
export const BudgetFormContext = createContext<BudgetFormContextType>(budgetFormContextInitialValue);
