import React, { useCallback, useEffect } from 'react';
import { Moment } from 'moment';
import { useField, useForm } from 'react-final-form-hooks';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { ThemeProvider } from '@material-ui/styles';
import { FormProps } from '../../../../../types';
import { useTranslation } from '../../../../../services/translation';
import { makeGetErrorAndHelperText, textFieldProps } from '../../../../../utils';
import { ResetForm } from '../../../../../components/ResetForm';
import { useModalFormStyles } from '../../../../../theme';
import { SelectGoalType } from '../../../../../components/SelectGoalType';
import { SelectGoalTypeValue } from '../../../../../components/SelectGoalTypeValue';
import { GOAL_TYPE, GOAL_TYPE_VALUE } from '../../../../../const';

export type CreateGoalType = {
  value: number;
  goal_type: string;
  start_date: Moment;
  end_date: Moment;
  name: string;
  goal_type_value: string;
};
export interface AddGoalFormProps extends FormProps {
  duplicate: boolean;
}
export const EditGoalForm = ({
  open = false,
  onCancel = () => {},
  onConfirm = () => {},
  title = 'Confirmation Dialog',
  cancelText = 'Cancel',
  confirmText = 'confirmText',
  customTheme = null,
  validate,
  formInitialValues = {},
  duplicate
}: AddGoalFormProps) => {
  const { t } = useTranslation();
  const styles = useModalFormStyles();
  const getErrorAndHelperText = makeGetErrorAndHelperText(t);

  const onSubmit = useCallback(
    async ({ goal_type, name, goal_type_value }) => {
      onConfirm({
        goal_type,
        goal_type_value,

        name
      });
    },
    [onConfirm]
  );

  const { form, handleSubmit, submitError } = useForm({
    initialValues: formInitialValues,
    onSubmit,
    validate
  });

  const name = useField('name', form);

  const goal_type = useField('goal_type', form);
  const goal_type_value = useField('goal_type_value', form);

  useEffect(() => {
    if (goal_type_value.input.value !== GOAL_TYPE_VALUE.MONETARY.toString() && goal_type.input.value === GOAL_TYPE.MARKETPLACE.toString()) {
      form.change('goal_type_value', GOAL_TYPE_VALUE.MONETARY.toString());
    }
  }, [form, goal_type_value.input.value, goal_type.input.value]);

  const dialog = (
    <Dialog open={open} onClose={onCancel} fullWidth aria-labelledby="form-dialog-title">
      <DialogContent className={styles.title}>
        <Typography variant="h3">{title}</Typography>
      </DialogContent>
      <form noValidate onSubmit={handleSubmit}>
        <DialogContent className={styles.content}>
          {!duplicate && (
            <TextField
              {...textFieldProps(`${t('Name')}`)}
              onChange={name.input.onChange}
              value={name.input.value}
              variant="standard"
              multiline
              InputLabelProps={{
                shrink: true,
                ...getErrorAndHelperText(name)
              }}
            />
          )}
          {!duplicate && (
            <SelectGoalType
              inputProps={{
                onChange: goal_type.input.onChange,
                value: goal_type.input.value || '',
                ...getErrorAndHelperText(goal_type)
              }}
            />
          )}
          {!duplicate && (
            <SelectGoalTypeValue
              disabled={!goal_type.input.value.length || goal_type.input.value === GOAL_TYPE.MARKETPLACE.toString()}
              inputProps={{
                onChange: goal_type_value.input.onChange,
                value: goal_type_value.input.value || '',
                ...getErrorAndHelperText(goal_type_value)
              }}
            />
          )}

          {submitError && (
            <Typography style={{ display: 'block' }} variant="overline" color="error">
              {t(submitError)}
            </Typography>
          )}
        </DialogContent>
        <DialogActions>
          <ResetForm form={form} open={open} />
          <Button
            onClick={() => {
              onCancel();
            }}
          >
            {cancelText}
          </Button>
          <Button color="primary" type="submit">
            {confirmText}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );

  return !customTheme ? dialog : <ThemeProvider theme={customTheme}>{dialog}</ThemeProvider>;
};
