import { LibbyFetchDAO } from '../LibbyFetchDAO';

export class GoalUpdateDAO extends LibbyFetchDAO {
  constructor() {
    super('ster_goal_update', 'goal_id');
  }

  getAllGoal() {
    return this.query('list_all_goal').limit(0, 10000).run();
  }
}
