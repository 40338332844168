import React, { useCallback, useState, useRef, useEffect } from 'react';
import { Grid, Typography, IconButton, TextFieldProps, FormHelperText } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { makeStyles } from '@material-ui/styles';
import PublishIcon from '@material-ui/icons/Publish';
import { AnyObject } from '../../../../../types/types';
import { useTranslation } from '../../../../../services/translation';
import { getExtension } from 'routes/Liquidations/utils';
import EditModal from '../../../../../services/formDialog';
import { SelectDelimiter, formInitialValues, validate, inputName } from '../../../../../components/SelectDelimiter';

let fileReader: AnyObject;
interface onUpdateFilesType {
  importationText: string;
  delimiter: string;
}
type FileInputProps = {
  onUpdateFiles: ({ importationText, delimiter }: onUpdateFilesType) => void;
  [k: string]: any;
  originalFilename?: string;
  error: boolean;
  product: string | number;
};

const useStyles = makeStyles({
  containerInput: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    height: '50px',
    marginTop: '10px'
  },
  label: {
    display: 'flex',
    flexWrap: 'nowrap',
    alignItems: 'center',
    paddingRight: 10
  },
  labelText: {
    paddingRight: 10
  },
  icon: {
    minWidth: 50,
    minHeight: 50
  },
  fileInput: {
    display: 'none'
  }
});

export const FileInput = ({ originalFilename, onUpdateFiles, error, product }: FileInputProps) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  useEffect(() => {
    return () => onUpdateFiles({ importationText: '', delimiter: '' });
  }, [onUpdateFiles]);

  const fileRef = useRef<HTMLInputElement | null>(null);
  const [userFilename, setUserFilename] = useState('');

  const handleFileRead = useCallback(
    async (event) => {
      const paramsModal = {
        inputType: 'select',
        inputName,
        confirmText: t('Yes'),
        cancelText: t('No'),
        title: t('CSV Delimiter'),
        content: t('What delimiter does the uploaded file use?'),
        labelContent: t('Delimiter'),
        select: ({ inputProps }: { inputProps: TextFieldProps }) => <SelectDelimiter inputProps={inputProps} />,
        formInitialValues,
        validate
      };
      // eslint-disable-next-line no-shadow
      let isError = false;
      try {
        let delimiter = '';
        try {
          const { select_name } = (await EditModal.show(paramsModal)) as {
            select_name: string;
          };
          delimiter = +select_name === 1 ? ';' : ',';
        } catch (e) {
          isError = true;
          enqueueSnackbar(`${t('Delimiter Required')}`, { variant: 'error' });
        }
        if (!isError) {
          onUpdateFiles({ importationText: fileReader.result, delimiter });
          setUserFilename(event.target.fileName || '');
        }
      } catch (e: any) {
        enqueueSnackbar(`${t('Cannot upload file, reason:')} ${e.message}`, {
          variant: 'error'
        });
      }
    },
    [enqueueSnackbar, onUpdateFiles, t]
  );

  const handleFileChosen = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const target = e.target as HTMLInputElement;
      const file: File = (target.files as FileList)[0];
      fileReader = new FileReader();
      fileReader.fileName = file && file.name;

      const extension = getExtension(fileReader.fileName);
      if (extension === 'csv') {
        fileReader.onloadend = handleFileRead;
      } else {
        enqueueSnackbar(`${t('Cannot upload file, reason:')} ${t('The file extension must not be different from .csv')}`, {
          variant: 'error'
        });
      }

      if (file) {
        fileReader.readAsText(file);
      }
    },
    [enqueueSnackbar, handleFileRead, t]
  );

  // eslint-disable-next-line no-nested-ternary
  const userFileHtml = userFilename ? ` (${userFilename})` : originalFilename ? `(${originalFilename})` : '';

  return (
    <Grid>
      <Grid item xs={12} className={classes.containerInput}>
        <label htmlFor={'Features_Carga_Masiva.csv'} className={classes.label}>
          <IconButton component="span">
            <PublishIcon />
            <input type="file" ref={fileRef} id={'Features_Carga_Masiva.csv'} className={classes.fileInput} onChange={handleFileChosen} />
          </IconButton>
          <Typography className={classes.labelText} variant="h5">
            {`Producto_${product}_carga_masiva.csv ${userFileHtml}`}
          </Typography>
        </label>
      </Grid>
      {error ? <FormHelperText error>{t('Please fill out this field.')}</FormHelperText> : null}
    </Grid>
  );
};
