import { userInfoType } from '../types';
import { useGlobalContext } from './useGlobalContext';

type useRolesUserType = {
  rolesEnabled: string[];
};

export const useRolesUser = ({ rolesEnabled }: useRolesUserType) => {
  const {
    userInfo
  }: {
    userInfo: userInfoType;
  } = useGlobalContext();
  return rolesEnabled.findIndex((rol: string) => userInfo?.groups?.[0].id === rol) > -1;
};
