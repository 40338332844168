export const columnsMessageConfigForm = [
  {
    id: 'options',
    label: '',
    width: '40px',
    style: {
      minWidth: '30px'
    },
    noSort: true
  },
  {
    id: 'id',
    label: 'Message Alert Settings ID',
    width: '60px',
    style: {
      minWidth: '80px'
    },
    noSort: true
  },
  {
    id: 'alert_id',
    label: 'Alert ID',
    width: '60px',
    style: {
      minWidth: '80px'
    },
    orderById: 'alert.alert_id'
  },
  {
    id: 'alert',
    label: 'Alert',
    width: '180px',
    style: {
      minWidth: '180px'
    },
    orderById: 'alert.name'
  },
  {
    id: 'template',
    label: 'Template',
    width: '180px',
    style: {
      minWidth: '180px'
    },
    orderById: 'template.name'
  }
];
