import { DataGenerateAutocompleteType } from '../../../../utils/generateAutocomplete';
import { LibbyFetchDAO } from '../LibbyFetchDAO';

export class CompanyMarketplaceDAO extends LibbyFetchDAO {
  constructor() {
    super('ster_company_marketplace', 'company_marketplace_id');
  }

  getAll() {
    return this.query().limit(0, 10000).run();
  }

  getAllCompanyById(company_id: DataGenerateAutocompleteType[]) {
    let ls = this.query();
    ls = ls.groupStart();
    let first: boolean = true;
    company_id.forEach(({ id }: DataGenerateAutocompleteType) => {
      if (!first) ls = ls.or();
      ls = ls.equals('company.company_id', id);
      first = false;
    });
    ls = ls.groupEnd();
    return ls.run();
  }
}
