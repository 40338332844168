import React from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import { DatabaseConnector } from '@phinxlab/libby-rest-web';
import { InboxOrdersList } from './routes/InboxOrdersList';
import { InboxOrderDetail } from './routes/InboxOrderDetail';
import { useOrderInboxTableLogic } from '../../hooks/userOrderInbox';
import { LibbyObject } from '../../types/types';
import { useBreadcrumbs } from '../../hooks';

export const InboxOrdersRouter = ({ libby }: LibbyObject) => {
  const match = useRouteMatch();

  const { data, working, fetchMore, reFetch, direction, orderBy, filter, handleRequestSort, setFilter } = useOrderInboxTableLogic(libby);

  const onChangeFilter = (value: object) => {
    if (JSON.stringify(filter) !== JSON.stringify(value)) {
      setFilter(value);
    }
  };
  const title = useBreadcrumbs('Orders Entry');
  return (
    <Switch>
      <Route path={`${match.path}/detail/:id`}>
        <InboxOrderDetail reFetch={reFetch} title={title} />
      </Route>
      <Route path={`${match.path}`}>
        <InboxOrdersList title={title} data={data} working={working} filter={filter} setFilter={onChangeFilter} fetchMore={fetchMore} onSortChange={handleRequestSort} orderBy={orderBy} direction={direction} />
      </Route>
    </Switch>
  );
};

export const InboxOrders = DatabaseConnector(InboxOrdersRouter)('ster_inbox_order');
