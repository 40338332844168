import moment from 'moment';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useInterval } from '../../../../hooks/useIntervalPage';

export const useRefreshHook = (recall: any, fetchOrderStateTurn: any, filter: any) => {
  const [stateRefresh, setStateRefresh] = useState(false);
  const [ordersRefresh, setOrdersRefresh] = useState(false);
  const [refreshFilter, setRefreshFilter] = useState<any>({
    startDate: [{ path: 'start_date', value: moment().subtract(1, 'months').startOf('month').format('YYYY-MM-DD HH:mm:ss') }],
    endDate: [{ path: 'end_date', value: moment().subtract(1, 'months').endOf('month').format('YYYY-MM-DD HH:mm:ss') }]
  });

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setStateRefresh(event.target.checked);
  };

  const refresh = useCallback(() => {
    if (filter.metadata.turn && filter.metadata.turn?.length > 0) {
      fetchOrderStateTurn?.();
    }
    recall.recallGetByState?.(filter.metadata);
    recall.recallGetStatesByMarketplace?.(filter.metadata);
  }, [recall, fetchOrderStateTurn, filter]);

  const delay = useMemo(() => (stateRefresh ? 60000 : null), [stateRefresh]);

  const { time, resetTime } = useInterval(refresh, delay);

  const onChangeApplyFilters = useCallback(() => {
    if (stateRefresh && !ordersRefresh) {
      resetTime();
    } else {
      refresh();
      setOrdersRefresh(true);
    }
  }, [stateRefresh, ordersRefresh, resetTime, refresh]);

  useEffect(() => {
    setOrdersRefresh(false);
  }, [filter, recall]);

  return { handleChange, setRefreshFilter, time, onChangeApplyFilters, stateRefresh, refreshFilter, ordersRefresh };
};
