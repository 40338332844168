import { LibbyFetchDAO } from '../LibbyFetchDAO';

export class OperationDAO extends LibbyFetchDAO {
  constructor() {
    super('operation', 'operationId');
  }

  getAll() {
    return this.query().limit(0, 10000).run();
  }
}
