import { Filter } from '../../../types';

type MakeFilterProps = {
  collectId?: string;
  cartBoxId?: string;
  dispatchStates?: string[];
  collectStates?: string[];
};

export const makeFilter = ({ collectId, cartBoxId, dispatchStates, collectStates }: MakeFilterProps) => {
  const filter: Filter = {};

  if (collectId) {
    filter.collectId = [
      {
        path: 'collect_id',
        value: collectId
      }
    ];
  }

  if (cartBoxId) {
    filter.cartBoxId = [
      {
        path: 'items.cartBox.cart_box_id',
        value: cartBoxId
      }
    ];
  }

  if (dispatchStates?.length) {
    filter.dispatchStates = dispatchStates.map((dispatchState) => ({
      path: 'dispatch.dispatch_state.dispatch_state_id',
      value: dispatchState
    }));
  }

  if (collectStates) {
    filter.collectStates = collectStates.map((collectState) => ({
      path: 'collectState.collect_state_id',
      value: collectState
    }));
  }

  return filter;
};
