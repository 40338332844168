import { Filter } from '../interfaces/business';
import { makeBusinessEntity, PartialBusinessEntityOptions } from '../lib/libby/generator/makeBusinessEntity';

import { businessOptions } from './businessOptions';

const DAO_NAME = 'filter';

const defaultOptions: PartialBusinessEntityOptions<any> = {
  hoc: {
    withEntityAutocomplete: {
      optionLabelKey: 'name'
    },
    withAccordionSelector: {
      idKey: 'filter_id',
      labelKey: 'name'
    },
    withSelectedChips: {
      idKey: 'filter_id',
      labelKey: 'name'
    }
  }
};

const optionsToUse = !businessOptions.Filter
  ? defaultOptions
  : {
      ...defaultOptions,
      ...businessOptions.Filter,
      hoc: { ...defaultOptions.hoc, ...businessOptions.Filter.hoc }
    };

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch },
  hoc: { entityAutocomplete, renderPropCall, renderPropFetchById, simpleSelect, accordionSelector, selectedChips }
} = makeBusinessEntity<Filter>(DAO_NAME, optionsToUse);

// hooks
export const useFilterFetchById = fetchById;
export const useFilterLibbyCall = libbyCall;
export const useFilterDAO = libbyDAO;
export const useFilterLibbyFetch = libbyFetch;

// components/hoc
export const FilterAutocomplete = entityAutocomplete;
export const FilterCall = renderPropCall;
export const FilterById = renderPropFetchById;
export const FilterSelect = simpleSelect;
export const FilterAccordionSelector = accordionSelector;
export const FilterSelectedChips = selectedChips;
