import React from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/styles';
import { Typography, useMediaQuery, Theme } from '@material-ui/core';
import { lightGrey } from '../../theme/colors';
import Logo from '../../assets/images/logo_phinx.png';
import { AnyObject } from '../../types/types';
import { useTranslation } from '../../services/translation';

const useStyles = makeStyles((theme: AnyObject) => ({
  rootContainer: {
    backgroundColor: '#333',
    width: '100%'
  },
  preFooter: {
    backgroundColor: '#f5f5f5',
    width: '100%',
    height: '2.5rem',
    textAlign: 'center'
  },
  rootFooter: {
    height: '80px',
    padding: '20px',
    backgroundColor: '#333333'
  },
  rootFooterMobile: {
    backgroundColor: '#333333',
    height: '80px',
    padding: '5px',
    marginBottom: '1px'
  },
  firstText: {
    color: theme.palette.secondary.contrastText
  },
  secondText: {
    color: lightGrey
  },
  containerSecondaryText: {
    textAlign: 'end',
    paddingRight: 15
  },
  textWithLogoContainer: {
    color: theme.palette.secondary.contrastText,
    display: 'flex',
    alignItems: 'center',
    padding: '5px'
  },
  logo: {
    height: 40
  },
  tinnyText: {
    color: '#717170'
  }
}));

export const Footer = () => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery<Theme>((theme) => theme.breakpoints.down(540));

  const classes = useStyles();
  return (
    <Grid container className={classes.rootContainer}>
      <Grid item xs={12} className={classes.preFooter}>
        <h3 className={classes.tinnyText}> {t('Made in')} PHINX</h3>
      </Grid>
      <Grid container className={isMobile ? classes.rootFooterMobile : classes.rootFooter} justify="center" alignItems="center">
        <Grid item xs={4} className={classes.textWithLogoContainer}>
          <img src={Logo} alt="logo" className={classes.logo} />
        </Grid>
        <Grid item xs={8} className={classes.containerSecondaryText}>
          <Typography className={classes.firstText}>Buenos Aires</Typography>
          <Typography className={classes.secondText}>© 2020 Copyright. All rights reserved</Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};
