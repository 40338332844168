import React, { useMemo } from 'react';
import { Button, Grid, Link, Paper, Typography } from '@material-ui/core';
import { useTranslation } from '../../../../../../services/translation';
import { AnyObject } from '../../../../../../types';
import { KpiComponent } from '../KpiComponent';
import { MarginsTypes } from '../../types';

export const MarginsPaper = ({ values, classes }: { values: MarginsTypes; classes: AnyObject }) => {
  const { t } = useTranslation();

  const { url, ...kpi } = values;

  const { margin_gross, margin_net } = useMemo(() => {
    const margin_gross_filter = Object.fromEntries(Object.entries(kpi).filter(([key]) => key.includes('gross')));
    const margin_net_filter = Object.fromEntries(Object.entries(kpi).filter(([key]) => key.includes('net')));
    return {
      margin_gross: margin_gross_filter,
      margin_net: margin_net_filter
    };
  }, [kpi]);

  return (
    <Grid item xs={12} sm={12} md={10} lg={4}>
      <Paper className={classes.paper}>
        <Grid container xs={12} justify="space-between" alignItems="center">
          <Typography variant="h4" className={classes.title}>
            {t('Margins')}
          </Typography>
        </Grid>
        {margin_gross && (
          <Grid container xs={12}>
            <Grid container xs={12} justify="space-between" alignItems="center">
              <Typography variant="h5">{t('Gross Margin')}</Typography>
              {url && (
                <Button color="primary">
                  <Link target="_blank" href={url}>
                    {t('see more')}
                  </Link>
                </Button>
              )}
            </Grid>
            {Object.entries(margin_gross).map(([key, value]: [string, string | number]) => (
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <KpiComponent name={key.split('_')[2]} value={value} />
              </Grid>
            ))}
          </Grid>
        )}
        {margin_net && (
          <Grid container xs={12}>
            <Grid container xs={12} justify="space-between" alignItems="center">
              <Typography variant="h5">{t('Net Margin')}</Typography>
              {url && (
                <Button color="primary">
                  <Link target="_blank" href={url}>
                    {t('see more')}
                  </Link>
                </Button>
              )}
            </Grid>
            {Object.entries(margin_net).map(([key, value]: [string, string | number]) => (
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <KpiComponent name={key.split('_')[2]} value={value} />
              </Grid>
            ))}
          </Grid>
        )}
      </Paper>
    </Grid>
  );
};
