import { useMemo, useCallback, useState } from 'react';
import { Column } from '../../../../components/InfoTable';
import { useTranslation } from '../../../../../services/translation';
import { LibbyObject, AnyObject } from '../../../../../types/types';
import { ButtonDetailsType } from '../../../../../types/Button';
import { filterInit } from '../ChargebackReportList';
import { useHistory } from 'react-router';
import moment from 'moment';
import { useLibbyCall } from 'hooks';
import { chargebackCityColumns, chargebackStreetColumns, chargebackProductColumns } from 'models/chargeback_report/chargebackReportColumns';
import { orderBy } from 'lodash';
import { KpiData, KpiSubData } from 'routes/components/Kpi';
import { format } from '../../../../../util';

export type ReportingSalesListProps = { vatEnabled?: boolean; libby: LibbyObject; typeReporting: 'Sales' | 'Orders'; daoNameHistory: string; daoName: string; columnsAux: Array<Column>; columnsProductAux: Array<Column> };

type ChargebackReportListLogicProps = {
  filter: any;
  setFilter: any;
  path: string;
  libby: LibbyObject;
};

export default function ChargebackReportListLogic({ filter, path, libby }: ChargebackReportListLogicProps) {
  const { t } = useTranslation();
  const history = useHistory();

  const [cityTableDirection, setCityTableDirection] = useState<'asc' | 'desc'>('desc');
  const [cityTableOrderBy, setCityTableOrderBy] = useState('quantity_total');

  const [streetTableDirection, setStreetTableDirection] = useState<'asc' | 'desc'>('desc');
  const [streetTableOrderBy, setStreetTableOrderBy] = useState('quantity_total');

  const [productTableDirection, setProductTableDirection] = useState<'asc' | 'desc'>('desc');
  const [productTableOrderBy, setProductTableOrderBy] = useState('quantity_total');

  const handleRequestCityTableSort = (newOrderBy: string, newDirection: 'asc' | 'desc') => {
    setCityTableOrderBy(newOrderBy);
    setCityTableDirection(newDirection);
  };
  const handleRequestStreetTableSort = (newOrderBy: string, newDirection: 'asc' | 'desc') => {
    setStreetTableOrderBy(newOrderBy);
    setStreetTableDirection(newDirection);
  };
  const handleRequestProductTableSort = (newOrderBy: string, newDirection: 'asc' | 'desc') => {
    setProductTableOrderBy(newOrderBy);
    setProductTableDirection(newDirection);
  };

  const {
    data: mainTableData,
    recall: recallMainTable,
    working: workingMainData
  } = useLibbyCall(libby, {
    daoName: 'ster_chargeback_report',
    methodName: 'getByDate',
    params: [filterInit]
  });

  const {
    data: cityTableData,
    recall: recallCityTable,
    working: workingCityData
  } = useLibbyCall(libby, {
    daoName: 'ster_chargeback_report_city',
    methodName: 'getByDate',
    params: [filterInit]
  });

  const {
    data: streetTableData,
    recall: recallStreetTable,
    working: workingStreetData
  } = useLibbyCall(libby, {
    daoName: 'ster_chargeback_report_street',
    methodName: 'getByDate',
    params: [filterInit]
  });

  const {
    data: productTableData,
    recall: recallProductTable,
    working: workingProductData
  } = useLibbyCall(libby, {
    daoName: 'ster_chargeback_report_product',
    methodName: 'getByDate',
    params: [filterInit]
  });
  const parsedMainTableData = useMemo(() => {
    if (!mainTableData?.data?.length) return [];

    return mainTableData.data.map((row: any) => {
      // Parse monthly_data JSON strings into objects
      const monthlyDataArray = row.monthly_data ? row.monthly_data.map((month: any) => JSON.parse(month)) : [];

      // Calculate the sums of the desired properties
      const totalMonthsData = monthlyDataArray.reduce(
        (acc: any, month: any) => ({
          chargeback_amount: acc.chargeback_amount + (month.chargeback_amount || 0),
          total_amount: acc.total_amount + (month.total_amount || 0),
          total_orders: acc.total_orders + (month.total_orders || 0)
        }),
        { chargeback_amount: 0, total_amount: 0, total_orders: 0 }
      );

      // Return the new row object with parsed monthly_data and the sums
      monthlyDataArray.push({ ...totalMonthsData, month: 'Total' });
      return {
        ...row,
        monthly_data: monthlyDataArray,
        totalMonthsData
      };
    });
  }, [mainTableData]);

  const parsedCityTableData = useMemo(() => {
    const parsedData = cityTableData.map((city: any) => ({
      ...city,
      chargeback_amount_negative: parseFloat(city.chargeback_amount_negative || 0),
      chargeback_amount_positive: parseFloat(city.chargeback_amount_positive || 0),
      chargeback_amount_total: parseFloat(city.chargeback_amount_total || 0),
      quantity_negative: parseInt(city.quantity_negative || 0, 10),
      quantity_positive: parseInt(city.quantity_positive || 0, 10),
      quantity_total: parseInt(city.quantity_total || 0, 10)
    }));
    const orderedData = orderBy(parsedData, [cityTableOrderBy], [cityTableDirection]);

    const totalObject = orderedData.reduce(
      (acc, row) => ({
        chargeback_amount_negative: acc.chargeback_amount_negative + parseFloat(row.chargeback_amount_negative || 0),
        chargeback_amount_positive: acc.chargeback_amount_positive + parseFloat(row.chargeback_amount_positive || 0),
        chargeback_amount_total: acc.chargeback_amount_total + parseFloat(row.chargeback_amount_total || 0),
        quantity_negative: acc.quantity_negative + parseInt(row.quantity_negative || 0, 10),
        quantity_positive: acc.quantity_positive + parseInt(row.quantity_positive || 0, 10),
        quantity_total: acc.quantity_total + parseInt(row.quantity_total || 0, 10)
      }),
      {
        chargeback_amount_negative: 0,
        chargeback_amount_positive: 0,
        chargeback_amount_total: 0,
        quantity_negative: 0,
        quantity_positive: 0,
        quantity_total: 0
      }
    );

    const totalRow = {
      ...totalObject,
      name: 'TOTAL'
    };

    return [...orderedData, totalRow];
  }, [cityTableData, cityTableDirection, cityTableOrderBy]);

  const parsedStreetTableData = useMemo(() => {
    const parseData = streetTableData.map((street: any) => ({
      ...street,
      chargeback_amount_negative: parseFloat(street.chargeback_amount_negative || 0),
      chargeback_amount_positive: parseFloat(street.chargeback_amount_positive || 0),
      chargeback_amount_total: parseFloat(street.chargeback_amount_total || 0),
      quantity_negative: parseInt(street.quantity_negative || 0, 10),
      quantity_positive: parseInt(street.quantity_positive || 0, 10),
      quantity_total: parseInt(street.quantity_total || 0, 10)
    }));
    const orderedData = orderBy(parseData, [streetTableOrderBy], [streetTableDirection]);

    const totalObject = orderedData.reduce(
      (acc, row) => ({
        chargeback_amount_negative: acc.chargeback_amount_negative + parseFloat(row.chargeback_amount_negative || 0),
        chargeback_amount_positive: acc.chargeback_amount_positive + parseFloat(row.chargeback_amount_positive || 0),
        chargeback_amount_total: acc.chargeback_amount_total + parseFloat(row.chargeback_amount_total || 0),
        quantity_negative: acc.quantity_negative + parseInt(row.quantity_negative || 0, 10),
        quantity_positive: acc.quantity_positive + parseInt(row.quantity_positive || 0, 10),
        quantity_total: acc.quantity_total + parseInt(row.quantity_total || 0, 10)
      }),
      {
        chargeback_amount_negative: 0,
        chargeback_amount_positive: 0,
        chargeback_amount_total: 0,
        quantity_negative: 0,
        quantity_positive: 0,
        quantity_total: 0
      }
    );

    const totalRow = {
      ...totalObject,
      address: 'TOTAL ADDRESS',
      name: 'TOTAL'
    };

    return [...orderedData, totalRow];
  }, [streetTableData, streetTableDirection, streetTableOrderBy]);

  const parsedProductTableData = useMemo(() => {
    const parseData = productTableData.map((product: any) => ({
      ...product,
      chargeback_amount_negative: parseFloat(product.chargeback_amount_negative || 0),
      chargeback_amount_positive: parseFloat(product.chargeback_amount_positive || 0),
      chargeback_amount_total: parseFloat(product.chargeback_amount_total || 0),
      quantity_negative: parseInt(product.quantity_negative || 0, 10),
      quantity_positive: parseInt(product.quantity_positive || 0, 10),
      quantity_total: parseInt(product.quantity_total || 0, 10)
    }));
    const orderedData = orderBy(parseData, [productTableOrderBy], [productTableDirection]);

    const totalObject = orderedData.reduce(
      (acc, row) => ({
        chargeback_amount_negative: acc.chargeback_amount_negative + parseFloat(row.chargeback_amount_negative || 0),
        chargeback_amount_positive: acc.chargeback_amount_positive + parseFloat(row.chargeback_amount_positive || 0),
        chargeback_amount_total: acc.chargeback_amount_total + parseFloat(row.chargeback_amount_total || 0),
        quantity_negative: acc.quantity_negative + parseInt(row.quantity_negative || 0, 10),
        quantity_positive: acc.quantity_positive + parseInt(row.quantity_positive || 0, 10),
        quantity_total: acc.quantity_total + parseInt(row.quantity_total || 0, 10)
      }),
      {
        chargeback_amount_negative: 0,
        chargeback_amount_positive: 0,
        chargeback_amount_total: 0,
        quantity_negative: 0,
        quantity_positive: 0,
        quantity_total: 0
      }
    );

    const totalRow = {
      ...totalObject,
      sku: 'TOTAL',
      name: 'TOTAL Products'
    };

    return [...orderedData, totalRow];
  }, [productTableData, productTableDirection, productTableOrderBy]);
  const allKpi: KpiData[] = useMemo(() => {
    const size = 4;
    const dataKpi: KpiSubData[] = mainTableData?.kpi
      ? [
          {
            title: t('Positive Chargeback Amount'),
            value: format(mainTableData.kpi.chargeback_amount_positive || 0, 'ARS', t),
            xs: 6,
            sm: 12,
            md: size,
            lg: size,
            xl: size
          },
          {
            title: t('Positive Chargeback Percentage'),
            value: `${Math.round(mainTableData.kpi.positive_chargeback_percentage)}%`,
            xs: 6,
            sm: 12,
            md: size,
            lg: size,
            xl: size
          },
          {
            title: t('Total Positive Chargeback'),
            value: `${mainTableData.kpi.positive_quantity}`,
            xs: 6,
            sm: 12,
            md: size,
            lg: size,
            xl: size
          }
        ]
      : [];
    return [
      {
        data: dataKpi
      }
    ];
  }, [mainTableData, t]);
  const refresh = useCallback(() => {
    recallMainTable?.(filter.metadata);
    recallCityTable?.(filter.metadata);
    recallStreetTable?.(filter.metadata);
    recallProductTable?.(filter.metadata);
  }, [recallMainTable, recallCityTable, recallStreetTable, recallProductTable, filter]);

  const OnChangeApplyFilters = useCallback(() => {
    refresh();
  }, [refresh]);

  const workingData = useMemo(() => {
    if (typeof workingMainData === 'boolean' && typeof workingCityData === 'boolean' && typeof workingStreetData === 'boolean' && typeof workingProductData === 'boolean') {
      return workingMainData && workingCityData && workingStreetData && workingProductData;
    } else return false;
  }, [workingMainData, workingCityData, workingStreetData, workingProductData]);

  const monthsChargeback = useMemo(() => {
    const availableMonths: AnyObject = {
      '01': t('January'),
      '02': t('February'),
      '03': t('March'),
      '04': t('April'),
      '05': t('May'),
      '06': t('June'),
      '07': t('July'),
      '08': t('August'),
      '09': t('September'),
      '10': t('October'),
      '11': t('November'),
      '12': t('December')
    };
    const fromDateYear = moment(filter.metadata.from).format('YYYY');
    const fromDateMonth = moment(filter.metadata.from).format('M');
    const toDateYear = moment(filter.metadata.to).format('YYYY');
    const toDateMonth = moment(filter.metadata.to).format('M');
    const selectedMonths: AnyObject[] = [];
    const higherMonth = fromDateYear < toDateYear ? '12' : fromDateMonth >= toDateMonth ? fromDateMonth : toDateMonth;
    const lowestMonth = fromDateYear === toDateYear ? parseInt(fromDateMonth) : 1;
    for (let i = lowestMonth; i <= parseInt(higherMonth, 10); i++) selectedMonths.push({ name: availableMonths[i <= 9 ? `0${i}` : i], value: i <= 9 ? `0${i}` : i.toString() });
    selectedMonths.push({ name: 'Total', value: 'Total' });
    return selectedMonths;
  }, [t, filter.metadata]);

  const buttons: ButtonDetailsType[] = useMemo(
    () => [
      {
        id: 'setMarketplaces',
        onClick: () => history.push(`${path}/marketplaces`),
        title: t('Marketplaces'),
        disabled: false,
        loading: false
      },
      {
        id: 'dispatchDetailAddOrder',
        onClick: OnChangeApplyFilters,
        title: `${t('Refresh')}`,
        disabled: workingMainData,
        loading: workingMainData
      }
    ],
    [OnChangeApplyFilters, t, history, path, workingMainData]
  );

  return {
    buttons,
    monthsChargeback,
    workingData,
    mainTableData: parsedMainTableData,
    cityColumns: chargebackCityColumns,
    streetColumns: chargebackStreetColumns,
    productColumns: chargebackProductColumns,
    cityRows: parsedCityTableData,
    streetRows: parsedStreetTableData,
    productRows: parsedProductTableData,
    handleRequestCityTableSort,
    handleRequestStreetTableSort,
    handleRequestProductTableSort,
    cityTableDirection,
    cityTableOrderBy,
    streetTableDirection,
    streetTableOrderBy,
    productTableDirection,
    productTableOrderBy,
    allKpi
  };
}
