import React, { useCallback, useMemo, MouseEvent } from 'react';
import { useSnackbar } from 'notistack';
import { useLocation } from 'react-router-dom';
import { useTranslation } from '../../../../../../services/translation';
import { useBreadcrumbs, useFilerParamsId } from '../../../../../../hooks';
import { Marketplace_sla } from '../../../../../../interfaces/business';
import { RowsType } from '../../../../../../utils/tables';
import CustomModal from '../../../../../../services/customFormDialog';
import { formReportingLogisticSlaInitialValues, validateReportingLogisticSlaAdd } from '../utils/modelReportingLogisticSlaAdd';
import { MenuActions, MenuItemComponentType } from '../../../../../../components/MenuActions';
import ConfirmDialog from '../../../../../../components/ConfirmDialog';
import { AddSlaForm, CreateMarketplaceType } from '../components/AddSlaForm';
import { ButtonDetailsType } from '../../../../../../types/Button';
import { TitleBreadcrumbs } from '../../../../../../interfaces';
import { useMarketplaceSlaLibbyFetch, useMarketplaceSlaUpdateDAO } from '../../../../../../business/marketplace';

const AddSlaModal = CustomModal(AddSlaForm);

const ConfirmModal = CustomModal(ConfirmDialog);
export const useReportingLogisticSlaLogic = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const { orderBy, direction, paramsFetch, handleRequestSort, setFilter, filter } = useFilerParamsId({
    orderInit: 'marketplace.name',
    daoName: 'ster_marketplace_sla'
  });

  const { data: allData, fetchMore, removeData, working, addCreate, updateData } = useMarketplaceSlaLibbyFetch(paramsFetch);

  const { state } = useLocation<{ goBack: TitleBreadcrumbs[] }>();

  const title = useBreadcrumbs('Settings SLA');
  const titleShow = useBreadcrumbs('Settings SLA', state && state?.goBack?.length ? state.goBack : title);
  const marketplaceSlaDAO = useMarketplaceSlaUpdateDAO();

  const handleOnOpenAddSla = useCallback(
    async (marketplaceSla?: Marketplace_sla) => {
      try {
        const marketplaceSlaDataForm = (await AddSlaModal.show({
          confirmText: t(marketplaceSla ? 'Update' : 'Add'),
          cancelText: t('Cancel'),
          title: t(!marketplaceSla ? 'Add SLA' : 'Update SLA'),
          validate: validateReportingLogisticSlaAdd,
          formInitialValues: marketplaceSla
            ? {
                marketplace: marketplaceSla.marketplace.marketplace_id,
                sla_operative: marketplaceSla.sla_operative,
                sla_delivered: marketplaceSla.sla_delivered,
                marketplace_name: marketplaceSla.marketplace.name
              }
            : formReportingLogisticSlaInitialValues
        })) as CreateMarketplaceType;

        if (marketplaceSlaDataForm) {
          const newMarketplaceSla = await marketplaceSlaDAO.save({
            ...marketplaceSla,
            marketplace_sla_id: marketplaceSla?.marketplace_sla_id,
            marketplace: {
              marketplace_id: marketplaceSlaDataForm.marketplace
            },
            sla_operative: marketplaceSlaDataForm.sla_operative,
            sla_delivered: marketplaceSlaDataForm.sla_delivered
          });
          const message = marketplaceSla ? 'modified' : 'aggregated';
          if (!marketplaceSla) {
            addCreate(newMarketplaceSla);
          } else {
            updateData(newMarketplaceSla, 'marketplace_sla_id');
          }
          enqueueSnackbar(`${t(`Marketplace $$$$ successfully ${message}`).replace('$$$$', newMarketplaceSla.marketplace_sla_id)}`, { variant: 'success' });
        }
      } catch (error: any) {
        // nothing
      }
    },
    [t, marketplaceSlaDAO, updateData, enqueueSnackbar, addCreate]
  );

  const handleCancelModal = useCallback(
    async (marketplace_sla: Marketplace_sla) => {
      try {
        const cancel = await ConfirmModal.show({
          title: t('Delete marketplace sla'),
          content: `${t('You are about to delete $$$$ marketplace sla, do you want to continue?')}`.replace('$$$$', String(marketplace_sla.marketplace.name)),
          confirmText: t('Confirm'),
          cancelText: t('Cancel')
        });
        if (cancel) {
          await marketplaceSlaDAO.remove({
            ...marketplace_sla
          });
          removeData(marketplace_sla.marketplace_sla_id, 'marketplace_sla_id');
          enqueueSnackbar(t('Marketplace sla deleted'), { variant: 'success' });
        }
      } catch (error: any) {
        if (error) {
          enqueueSnackbar(`${t('Failed to delete')}: ${error}`, {
            variant: 'info'
          });
        }
      }
    },
    [enqueueSnackbar, marketplaceSlaDAO, removeData, t]
  );

  const menuArray = useCallback(
    (marketplaceSla: Marketplace_sla): MenuItemComponentType[] => {
      const menu: MenuItemComponentType[] = [
        {
          title: 'Edit',
          onClick: (e: MouseEvent) => {
            e.stopPropagation();
            handleOnOpenAddSla(marketplaceSla);
          },
          disabled: false
        },
        {
          title: 'Delete',
          onClick: (e: MouseEvent) => {
            e.stopPropagation();
            handleCancelModal(marketplaceSla);
          },
          disabled: false,
          color: 'error'
        }
      ];
      return menu.filter((menuItems) => menuItems.disabled === false);
    },
    [handleOnOpenAddSla, handleCancelModal]
  );

  const rows: RowsType[] = useMemo(() => {
    const dataOrder = allData as Marketplace_sla[];
    return dataOrder.map((marketplaceSla: Marketplace_sla) => {
      const {
        marketplace_sla_id,
        sla_operative,
        sla_delivered,
        marketplace: { name: marketplace_name }
      } = marketplaceSla;
      return {
        id: marketplace_sla_id,
        ...marketplaceSla,
        sla_operative,
        sla_delivered,
        marketplace: marketplace_name,
        actions: <MenuActions menuArray={menuArray(marketplaceSla)} />
      };
    });
  }, [allData, menuArray]);

  const buttons: ButtonDetailsType[] = useMemo(
    () => [
      {
        id: 'addSla',
        onClick: () => handleOnOpenAddSla(),
        title: t('Add SLA'),
        variant: 'contained'
      }
    ],
    [handleOnOpenAddSla, t]
  );

  return {
    working,
    direction,
    orderBy,
    handleRequestSort,
    setFilter,
    filter,
    rows,
    fetchMore,
    buttons,
    titleShow,
    handleCancelModal
  };
};
