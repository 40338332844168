import { useMemo } from 'react';
import moment from 'moment';
import { RowsType } from '../../../types';
import { columnsLiquidationCompare } from '../../../models/orders/columns';
import { formatMoney } from '../../../utils';
import { OrderLiquidationPreview } from '../../../interfaces/business';
import { Column } from '../../components/InfoTable';
import { OrderState } from '../../../const';

interface UseComparativeTableLogicProps {
  ordersProcessed?: Array<OrderLiquidationPreview>;
  unprocessedOrders?: Array<{ owner_id: string; created_at: Date; order_state_id: string; reason: string }>;
  details?: {
    total: number;
  };
}

export const useComparativeTableLogic = (data: UseComparativeTableLogicProps, t: any) => {
  const rowsProcessed: RowsType[] | any[] | undefined = useMemo(() => {
    if (!data) return [];
    return data?.ordersProcessed?.map((orderLiquidation: OrderLiquidationPreview) => {
      return {
        id: orderLiquidation?.owner_id,
        ...orderLiquidation,
        total: formatMoney(orderLiquidation.total),
        total_db: formatMoney(orderLiquidation.total_db)
      };
    });
  }, [data]);

  const columnsProcessed = useMemo(() => {
    let columnsColorized: any[] = [];
    if (data?.ordersProcessed?.some((orderLiquidation: OrderLiquidationPreview) => orderLiquidation.total !== orderLiquidation.total_db)) {
      columnsColorized = columnsLiquidationCompare.map((orderColumn) => {
        if (orderColumn.id.includes('total')) {
          return {
            ...orderColumn,
            style: {
              ...orderColumn.style,
              backgroundColor: '#bb0909',
              color: 'white'
            }
          };
        }
        return orderColumn;
      });
    } else {
      columnsColorized = columnsLiquidationCompare;
    }
    return columnsColorized;
  }, [data]);

  const rowsUnprocessed: RowsType[] | any[] | undefined = useMemo(() => {
    if (!data) return [];
    return data?.unprocessedOrders?.map((orderLiquidation: { owner_id: string; created_at: Date; order_state_id: string; reason: string }) => {
      return {
        id: orderLiquidation.owner_id,
        owner_id: orderLiquidation.owner_id,
        created_at: orderLiquidation.created_at ? moment(orderLiquidation.created_at).format('DD/MM/YYYY') : '',
        order_state_id: t(OrderState[Number(orderLiquidation.order_state_id)]),
        reason: orderLiquidation.reason
      };
    });
  }, [data, t]);

  const columnsUnprocessed: Column[] = [
    {
      id: 'owner_id',
      label: 'Owner ID'
    },
    {
      id: 'created_at',
      label: 'Created at'
    },
    {
      id: 'reason',
      label: 'Reason'
    }
  ];

  const marketExpenses: number = useMemo(() => {
    let total = 0;
    if (!data?.ordersProcessed) return total;

    data?.ordersProcessed?.forEach((orderLiquidation) => {
      total += orderLiquidation.commissions + orderLiquidation.discount + orderLiquidation.cost_interest + orderLiquidation.bonus_charger + orderLiquidation.return_charge + orderLiquidation.shipping_charge;
    });

    return total;
  }, [data]);

  const shippingTotal: number = useMemo(() => {
    return data?.ordersProcessed?.reduce((prev, acc) => prev + Number(acc.shipping), 0) || 0;
  }, [data]);

  const generalTotal: number = useMemo(() => {
    return data?.details ? data.details.total + marketExpenses + shippingTotal : 0;
  }, [data, marketExpenses, shippingTotal]);

  return {
    rowsProcessed,
    columnsProcessed,
    rowsUnprocessed,
    columnsUnprocessed,
    marketExpenses,
    generalTotal,
    shippingTotal
  };
};
