import React, { useMemo } from 'react';
import AssignmentIcon from '@material-ui/icons/Assignment';
import Moment from 'moment';
import { LibbyObject } from '../../../types/types';
import { MakeCell } from '../../components/MakeCell';
import { useLibbyFetch, OptionsLibbyFetch } from '../../../hooks/useLibbyFetch';
import { columnsLiquidationGroup } from '../../../models/orders/columns';
import { RowsType } from '../../../types';
import { formatDate } from '../../../utils';

interface UseBillingTableLogicProps {
  libby: LibbyObject;
  paramsFetch: OptionsLibbyFetch;
  columnCheckEnabled?: boolean;
  actions?: boolean;
  path?: string;
}

export const useBillingTableLogic = ({ libby, paramsFetch, path }: UseBillingTableLogicProps) => {
  const { data, working, fetchMore, updateData, reFetch } = useLibbyFetch(libby, paramsFetch);

  const rows: RowsType[] = useMemo(
    () =>
      data.map((liq) => ({
        detail: <MakeCell label="" icon={AssignmentIcon} path={`${path}/detail/${liq?.order_liquidation_group_id}`} />,
        id: liq.order_liquidation_group_id,
        order_liquidation_group_id: liq.order_liquidation_group_id,
        reference: !liq.reference ? '--------' : liq.reference,
        start_date: formatDate(liq.start_date, true, false),
        end_date: formatDate(liq.end_date),
        marketplace: liq.marketplace?.name,
        username: liq.account?.username,
        liquidation_date: Moment(liq.liquidation_date).format('DD-MM-YYYY HH:mm:ss'),
        files: liq.files,
        liquidations: liq.liquidations
      })),
    [data, path]
  );

  const columns = useMemo(() => columnsLiquidationGroup, []);

  return {
    working,
    rows,
    columns,
    fetchMore,
    data,
    updateData,
    reFetch
  };
};
