import React, { useCallback } from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Theme, useTheme } from '@material-ui/core/styles';
import { DatabaseConnector } from '@phinxlab/libby-rest-web';
import { InboxOrderProblemButton } from 'components/InboxOrderProblemButton';
import { Navbar } from '../../Navbar';
import { MenuButton } from '../../MenuButton';
import { AccountButton } from '../../AccountButton';
import { useTranslation } from '../../../services/translation';
import { LanguageButton } from '../../LanguageButton';
import { useGlobalContext } from '../../../hooks';
import { AnyObject, LibbyObject } from '../../../types/types';
import { ProcessConfigAlert } from '../../ProcessConfigAlert';
import { authorizeMenu } from '../../../utils';
import { MENU } from '../../../const';

const useStyles = makeStyles((theme: AnyObject) => ({
  title: {
    fontFamily: '"Oswald"',
    cursor: 'default',
    color: theme.palette.common.black
  },
  contentTitle: {
    display: 'flex',
    alignItems: 'center'
  },
  divider: {
    height: 48,
    width: 1,
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.contrastText
  },
  loginIcon: {
    color: theme.palette.primary.contrastText
  },
  logo: {
    paddingBottom: 0,
    paddingTop: 0
  },
  actionButton: {
    marginRight: 0
  },
  menuItem: {
    padding: 0
  }
}));

type AppNavBarProps = {
  libby: LibbyObject;
};

const AppNavBarRaw = ({ libby }: AppNavBarProps) => {
  const { isLogged, userInfo } = useGlobalContext();
  const { t } = useTranslation();
  const theme = useTheme<Theme>();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));
  const classes = useStyles();
  // Title
  const title = (
    <div className={classes.contentTitle}>
      <h2 role="presentation" className={classes.title}>
        {t('STER')}
      </h2>
    </div>
  );
  // Account button
  const onLogoutClick = useCallback(() => {
    libby.session.logout();
  }, [libby]);
  const authButton = !!isLogged && <AccountButton onLogoutClick={onLogoutClick} color="primary" libby={libby} />;
  const processConfigAlert = !!isLogged && <ProcessConfigAlert />;
  const inboxOrderProblemButton = !!isLogged && authorizeMenu(userInfo, MENU.INBOX) && <InboxOrderProblemButton />;
  const right = (
    <Grid spacing={1} container alignItems="center" className={matches ? classes.actionButton : ''}>
      <Grid item className={classes.menuItem}>
        {processConfigAlert}
      </Grid>
      <Grid item className={classes.menuItem}>
        {inboxOrderProblemButton}
      </Grid>
      <Grid item className={classes.menuItem}>
        <LanguageButton color="primary" />
      </Grid>
      <Grid item className={classes.menuItem}>
        {authButton}
      </Grid>
    </Grid>
  );
  return <Navbar left={isLogged && <MenuButton color="primary" />} title={title} right={right} />;
};

export const AppNavBar = DatabaseConnector(AppNavBarRaw)();
