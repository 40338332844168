import React, { useCallback, useEffect, useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import { ThemeProvider, makeStyles } from '@material-ui/styles';
import { Box, Button, CircularProgress, DialogActions, DialogTitle, TextField, Typography } from '@material-ui/core';
import { useTranslation } from '../../../../../../services/translation';
import { Justified_orders, Order_reporting_delivery_control_reporting } from '../../../../../../interfaces/business';
import { LibbyObject } from '../../../../../../types';
import { SelectJustifiedOrdersTypes } from './SelectJustifiedOrdersTypes';

const useStyles = makeStyles({
  root: {
    '& .MuiDialog-paperWidthSm': {
      width: '100%',
      maxWidth: '600px'
    },
    '& .MuiDialogContent-root': {
      padding: '0px 24px 8px 24px'
    }
  },
  textField: {
    marginBottom: '20px'
  },
  title: {
    overflow: 'visible',
    '& > h3': {
      fontFamily: '"Oswald"'
    }
  },
  rootEdit: {
    display: 'flex',
    justifyContent: 'space-around',
    width: '100%'
  },
  rootCancelEdit: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%'
  }
});

interface ShowDetailsTypes {
  open: boolean;
  onCancel: () => void;
  title: string;
  customTheme: string | null;
  data: Order_reporting_delivery_control_reporting;
  cancelText: string;
  deleteText: string;
  updateText: string;
  confirmText: string;
  libby: LibbyObject;
  userId: string;
  reFetch: () => void;
}

const dummyFunc = () => {};

export const ReportingLogisticJustifyModal = ({ open = false, onCancel = dummyFunc, title, customTheme = null, data, confirmText, updateText, deleteText, cancelText, libby, userId, reFetch }: ShowDetailsTypes) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [update, setUpdate] = useState(false);
  const [working, setWorking] = useState(false);
  const [workingJustId, setWorkingJustId] = useState(false);
  const [dataJustification, setDataJustification] = useState<Justified_orders | undefined>();
  const [searchValue, setSearchValue] = useState('');
  const [editValue, setEditValue] = useState(data?.justification);
  const [justifiedType, setJustifiedType] = useState(data?.justified_orders_types_id || '');

  const getJustification = useCallback(async () => {
    setEditValue(data?.justification);
    setSearchValue('');
    if (!libby) {
      return;
    }
    if (data?.justification_order_id) {
      setWorkingJustId(true);
      const res = await libby.ster_reporting_logistic_justify.query().equals('justified_orders_id', data?.justification_order_id).run();
      setWorkingJustId(false);
      setDataJustification(res[0]);
    }
  }, [libby, data]);

  useEffect(() => {
    getJustification();
  }, [getJustification]);

  const handleAfterAction = () => {
    reFetch();
    onCancel();
    setUpdate(false);
  };

  const handleJustifiedtypeSelect = useCallback(
    (e: any) => {
      setJustifiedType(e.target.value);
    },
    [setJustifiedType]
  );

  const dialog = (
    <>
      {data?.justification_order_id ? (
        <Dialog className={classes.root} open={open} onClose={onCancel} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">{`${t('Justification of order id')} ${data.order_id} : `}</DialogTitle>
          <Box style={{ padding: '20px' }}>
            <SelectJustifiedOrdersTypes
              inputProps={{
                onChange: handleJustifiedtypeSelect,
                value: justifiedType || ''
              }}
            />
          </Box>
          <Box style={{ padding: '20px' }}>
            {update ? (
              <TextField
                id="generic-justify"
                placeholder={t('Enter the justification here')}
                type="text"
                name="justify"
                value={editValue}
                onChange={(e: any) => setEditValue(e.target.value)}
                fullWidth
                style={{ width: '100%', padding: '0px 0px 20px 0px' }}
                InputLabelProps={{
                  shrink: true
                }}
              />
            ) : (
              <Typography>{editValue}</Typography>
            )}
          </Box>
          <DialogActions>
            {(working || workingJustId) && <CircularProgress size={25} />}
            <Button
              onClick={() => {
                onCancel();
              }}
            >
              {cancelText}
            </Button>
            <Button
              disabled={update ? editValue === dataJustification?.justification || workingJustId || working : false}
              onClick={async () => {
                if (!update) {
                  setUpdate(!update);
                } else {
                  setWorking(true);
                  try {
                    await libby.ster_reporting_logistic_justify.save({
                      order_id: parseInt(dataJustification?.order_id.order_id || '0', 10),
                      created_by: dataJustification?.created_by.account_id,
                      created_at: dataJustification?.created_at,
                      updated_by: parseInt(userId, 10),
                      justified_orders_id: dataJustification?.justified_orders_id,
                      justification: editValue,
                      justified_orders_types_id: parseInt(justifiedType, 10)
                    });
                    setWorking(false);
                    handleAfterAction();
                  } catch (e: any) {
                    setWorking(false);
                    onCancel();
                  }
                }
              }}
              color="primary"
              type="submit"
            >
              {updateText}
            </Button>
            <Button
              disabled={workingJustId || working}
              onClick={async () => {
                setWorking(true);
                try {
                  await libby.ster_reporting_logistic_justify.save({
                    order_id: parseInt(dataJustification?.order_id.order_id || '0', 10),
                    created_by: dataJustification?.created_by.account_id,
                    created_at: dataJustification?.created_at,
                    updated_by: parseInt(userId, 10),
                    justified_orders_id: dataJustification?.justified_orders_id,
                    justification: dataJustification?.justification,
                    justified_orders_types_id: parseInt(justifiedType, 10)
                  });
                  await libby.ster_reporting_logistic_justify.remove({
                    justified_orders_id: data?.justification_order_id
                  });
                  setWorking(false);
                  handleAfterAction();
                } catch (e: any) {
                  setWorking(false);
                  onCancel();
                }
              }}
              color="primary"
              type="submit"
            >
              {deleteText}
            </Button>
          </DialogActions>
        </Dialog>
      ) : (
        <Dialog className={classes.root} open={open} onClose={onCancel} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">{title}</DialogTitle>
          <Box style={{ padding: '20px' }}>
            <SelectJustifiedOrdersTypes
              inputProps={{
                onChange: handleJustifiedtypeSelect,
                value: justifiedType || ''
              }}
            />
          </Box>
          <Box style={{ padding: '20px' }}>
            <TextField
              id="generic-justify"
              placeholder={t('Enter the justification here')}
              type="text"
              name="justify"
              value={searchValue}
              onChange={(e: any) => setSearchValue(e.target.value)}
              fullWidth
              style={{ width: '100%', padding: '0px 0px 20px 0px' }}
              InputLabelProps={{
                shrink: true
              }}
            />
          </Box>
          <DialogActions>
            {working && <CircularProgress size={25} />}
            <Button
              onClick={() => {
                onCancel();
              }}
            >
              {cancelText}
            </Button>
            <Button
              onClick={async () => {
                setWorking(true);
                try {
                  await libby.ster_reporting_logistic_justify.save({
                    order_id: parseInt(data?.order_id, 10),
                    justification: searchValue,
                    created_by: parseInt(userId, 10),
                    updated_by: parseInt(userId, 10),
                    justified_orders_types_id: parseInt(justifiedType, 10)
                  });
                  setWorking(false);
                  handleAfterAction();
                } catch (e: any) {
                  setWorking(false);
                  onCancel();
                }
              }}
              color="primary"
              type="submit"
            >
              {confirmText}
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );

  return !customTheme ? dialog : <ThemeProvider theme={customTheme}>{dialog}</ThemeProvider>;
};
