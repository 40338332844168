import { Column } from 'routes/components/InfoTable';
import { MarketplaceChargeback } from 'interfaces/business';

export const columnsReportChargebackMarketplace: Array<Column> = [
  {
    id: 'actions',
    label: '',
    noSort: true,
    width: 30
  },
  {
    id: 'chargeback_marketplace_id',
    label: 'Marketplace chargeback id',
    style: {
      whiteSpace: 'nowrap'
    },
    orderById: 'chargeback_marketplace_id'
  },
  {
    id: 'marketplace',
    label: 'Marketplace',
    style: {
      whiteSpace: 'nowrap'
    },
    orderById: 'marketplace.name'
  },
  {
    id: 'active',
    label: 'Active',
    render: (marketplace: MarketplaceChargeback) => <div>{marketplace.active ? 'Sí' : 'No'}</div>,
    noSort: true
  }
];
