import React, { useCallback, useEffect, useState } from 'react';
import { Grid, Typography, Box } from '@material-ui/core';
import moment, { Moment } from 'moment';
import { orderBy } from 'lodash';
import { useTranslation } from '../../../../../services/translation';
import { FilterBar } from '../../../../../components/FilterBar';
import { gridBreakpoints } from '../../../../../const/gridBreakpoints';
import { DataGenerateAutocompleteType } from '../../../../../utils/generateAutocomplete';
import { FilterBarSelectionReportingObjectiveSales } from '../type';
import { SelectGoal } from '../../../../../components/SelectGoal';
import { useGoalListDateLibbyCall } from '../../../../../business/goal';
import { SelectGoalQuarter } from '../../../../../components/SelectGoalQuarter';
import { SelectGoalMonth } from '../../../../../components/SelectGoalMonth';
import { GoalDate } from '../../../../../interfaces/business/goal/GoalDate';
import { SelectConfirmation } from '../../../../../components/SelectConfirmation';

type SearchFiltersProps = {
  onFilterChange: (makeFilter: FilterBarSelectionReportingObjectiveSales) => void;
  initFetch: FilterBarSelectionReportingObjectiveSales;
};

const SearchFiltersRaw = ({ onFilterChange, initFetch }: SearchFiltersProps) => {
  const { t } = useTranslation();
  const [dataQuarters, setDataQuarters] = useState<GoalDate[]>([]);
  const [dataMonths, setDataMonths] = useState<GoalDate[]>([]);
  const [inputQuarter, setInputQuarter] = useState<boolean>(false);
  const [inputMonth, setInputMonth] = useState<boolean>(false);

  const { data: dataAllQuarters, working: workingQuarters } = useGoalListDateLibbyCall({
    methodName: 'getAllListDate',
    params: [{ quarter: true }]
  });

  const { data: dataAllMonths, working: workingMonths } = useGoalListDateLibbyCall({
    methodName: 'getAllListDate',
    params: [{ quarter: false }]
  });

  const setDataDates = useCallback(() => {
    const dataQuartersTyped = dataAllQuarters as GoalDate[];
    const dataMonthsTyped = dataAllMonths as GoalDate[];

    if (dataQuartersTyped) setDataQuarters(dataQuartersTyped);
    if (dataMonthsTyped) setDataMonths(dataMonthsTyped);
  }, [dataAllMonths, dataAllQuarters]);

  useEffect(() => {
    setDataDates();
  }, [setDataDates]);

  const handleChangeCanal = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, value: DataGenerateAutocompleteType[]) => {
      const newFilter: FilterBarSelectionReportingObjectiveSales = {
        ...initFetch,
        canal: value
      };
      onFilterChange(newFilter);
    },
    [initFetch, onFilterChange]
  );

  const handleChangeCategory = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, value: DataGenerateAutocompleteType[]) => {
      const newFilter: FilterBarSelectionReportingObjectiveSales = {
        ...initFetch,
        category: value
      };
      onFilterChange(newFilter);
    },
    [initFetch, onFilterChange]
  );

  const handleChangeQuarter = useCallback(
    (values: { id: string; value: string }[]) => {
      const dataAllDateWithType = dataAllQuarters as GoalDate[];
      const dataSelectDate = orderBy(
        dataAllDateWithType?.filter((dataDate: GoalDate) => values.some((value) => value.id === dataDate.goal_date_id)),
        'start_date'
      );

      if (dataSelectDate.length) {
        setInputQuarter(true);
        const newFilter: FilterBarSelectionReportingObjectiveSales = {
          ...initFetch,
          from: moment(dataSelectDate[0].start_date),
          to: moment(dataSelectDate[dataSelectDate.length - 1].end_date),
          category: [],
          canal: []
        };
        onFilterChange(newFilter);
      } else {
        setInputQuarter(false);
      }
    },
    [dataAllQuarters, initFetch, onFilterChange]
  );

  const handleChangeMonth = useCallback(
    (values: Moment) => {
      if (values) {
        setInputMonth(true);
        const newFilter: FilterBarSelectionReportingObjectiveSales = {
          ...initFetch,
          from: moment(values).startOf('month'),
          to: moment(values).endOf('month'),
          category: [],
          canal: []
        };
        onFilterChange(newFilter);
      } else {
        setInputMonth(false);
      }
    },
    [initFetch, onFilterChange]
  );

  const handleChangeEightyPercent = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target;

      if (value) {
        const newFilter: FilterBarSelectionReportingObjectiveSales = {
          ...initFetch,
          eightyPercent: value
        };
        onFilterChange(newFilter);
      }
    },
    [initFetch, onFilterChange]
  );

  return (
    <FilterBar
      defaultFilter={
        <Grid item {...gridBreakpoints}>
          <Typography variant="h4">{t('Target date')}</Typography>
          <Box flexDirection="column" display="flex" my={1}>
            <Box>
              <Typography variant="h5">
                {t('Start Date')} : {initFetch?.from && moment(initFetch.from).format('YYYY-M-D')}
              </Typography>
            </Box>
            <Box>
              <Typography variant="h5">
                {t('End Date')}: {initFetch?.to && moment(initFetch.to).format('YYYY-M-D')}
              </Typography>
            </Box>
          </Box>
        </Grid>
      }
    >
      <Grid item {...gridBreakpoints}>
        <SelectGoal
          multiple
          disabled={!!initFetch?.category?.length}
          from={initFetch.from}
          to={initFetch.to}
          label="marketplace"
          marketplace
          inputProps={{
            value: initFetch.canal,
            onChange: handleChangeCanal
          }}
        />
      </Grid>
      <Grid item {...gridBreakpoints}>
        <SelectGoal
          multiple
          from={initFetch.from}
          to={initFetch.to}
          label="product"
          disabled={!!initFetch?.canal?.length}
          inputProps={{
            value: initFetch.category,
            onChange: handleChangeCategory
          }}
        />
      </Grid>
      <Grid item {...gridBreakpoints}>
        <SelectGoalQuarter
          inputProps={{
            value: initFetch?.goal_date,
            onChange: handleChangeQuarter,
            disabled: inputMonth
          }}
          data={dataQuarters}
          working={workingQuarters}
        />
      </Grid>
      <Grid item {...gridBreakpoints}>
        <SelectGoalMonth
          inputProps={{
            value: initFetch?.goal_date,
            onChange: handleChangeMonth,
            disabled: inputQuarter
          }}
          data={dataMonths}
          working={workingMonths}
        />
      </Grid>
      <Grid item {...gridBreakpoints}>
        <SelectConfirmation label={t('%80')} inputProps={{ value: initFetch.eightyPercent, onChange: handleChangeEightyPercent }} />
      </Grid>
    </FilterBar>
  );
};

export const SearchFilters = React.memo(SearchFiltersRaw);
