import { Moment } from 'moment';
import { AnyObject } from '../../../types/types';
import { endDateFormatUtc, startDateFormatUtc } from '../../../utils';

type MakeFilterProps = {
  search?: string;
  sku?: string | null;
  marketplace?: string | null;
  updated_by?: string | null;
  startDate?: Moment | null;
  endDate?: Moment | null;
};

export const makeFilter = ({ search, sku, marketplace, updated_by, startDate, endDate }: MakeFilterProps) => {
  const filter: AnyObject = {};

  if (search) {
    const valueSearch = search.split(' ').reduce((accumulator: AnyObject, item, index) => {
      accumulator[index] = [
        {
          path: 'marketplace',
          value: item,
          method: 'includes'
        },
        {
          path: 'sku',
          value: item,
          method: 'includes'
        },
        {
          path: 'updated_by',
          value: item,
          method: 'includes'
        },
        {
          path: 'updated_at',
          value: item,
          method: 'includes'
        }
      ];
      return accumulator;
    }, {});
    filter.search = [...valueSearch[0]];
  }

  if (marketplace) {
    filter.marketplace = [
      {
        path: 'marketplace.name',
        value: marketplace,
        method: 'includes'
      }
    ];
  }
  if (sku) {
    filter.sku = [
      {
        path: 'sku',
        value: sku,
        method: 'includes'
      }
    ];
  }
  if (updated_by) {
    filter.updated_by = [
      {
        path: 'updated_by.username',
        value: updated_by,
        method: 'includes'
      }
    ];
  }

  if (startDate) {
    filter.startDate = [
      {
        path: 'updated_at',
        value: startDateFormatUtc(startDate),
        method: 'higherOrEquals'
      }
    ];
  }
  if (endDate) {
    filter.endDate = [
      {
        path: 'updated_at',
        value: endDateFormatUtc(endDate),
        method: 'lowerOrEquals'
      }
    ];
  }
  return filter;
};
