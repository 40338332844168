import React, { useCallback } from 'react';
import { Switch, Route, useRouteMatch, useHistory } from 'react-router-dom';
import { DatabaseConnector } from '@phinxlab/libby-rest-web';
import { BudgetList } from './routes/BudgetList';
import { useBreadcrumbs, useFilerParamsId } from '../../hooks';
import { makeFilter } from './utils';
import { useOrderB2BTableLogic } from '../Orders/routes/OrdersList/hook/useOrderTableLogicB2B'; // TODO:CHANGE IMPLEMENTATIO TO BUDGET
import { LibbyObject } from '../../types';
import { BudgetNew } from './routes/BudgetNew';
import { useTranslation } from '../../services/translation';
import { OrderStateLogs } from '../Orders/routes/OrderStateLogs';
import { OrderDetail } from '../Orders/routes/OrderDetail';
import { ORDER_SOURCE_CANAL_OF_LINE_ENABLED } from '../../const/canalOffLine';
import { BudgetEdit } from './routes/BudgetEdit';
import { BudgetCommentList } from './routes/BudgetComment';
import { BudgetCopy } from './routes/BudgetCopy/BudgetCopy';
import { BudgetNewV2 } from './routes/BudgetNewV2';

interface BudgetTypes {
  libby: LibbyObject;
}
const filterInit = makeFilter({ canalOffLine: ORDER_SOURCE_CANAL_OF_LINE_ENABLED });

export const BudgetRouter = ({ libby }: BudgetTypes) => {
  const { t } = useTranslation();
  const { path } = useRouteMatch();
  const history = useHistory();

  const title = useBreadcrumbs(t('Budget'));
  const match = useRouteMatch();

  const detailsRedirection = useCallback((order_id: string) => history.push(`orders/detail/${order_id}`), [history]);

  const onCopyBudget = useCallback((order_id: string) => history.push(`${path}/copy-v2/${order_id}`), [history, path]);

  const { filter, orderBy, direction, paramsFetch, setFilter, handleRequestSort, resetFilter } = useFilerParamsId({
    orderInit: 'order_id',
    daoName: 'ster_vw_order_b2b',
    aspect: 'list_order_so_order',
    init: filterInit
  });

  const { rows, columns, working, fetchMore, reFetch } = useOrderB2BTableLogic({
    libby,
    paramsFetch,
    detailsRedirection,
    onCopyBudget,
    actions: false,
    columnCheckEnabled: false,
    path: match.path,
    deliveryDate: true
  });

  const onRowClick = (row: LibbyObject) => {
    history.push(`${path}/detail/${row.order_id}`);
  };

  const onNewBudget = () => {
    history.push(`${path}/new-budget-v2`);
  };

  return (
    <Switch>
      <Route path={`${path}/new-budget`}>
        <BudgetNew libby={libby} title={title} />
      </Route>
      <Route path={`${path}/new-budget-v2`}>
        <BudgetNewV2 title={title} />
      </Route>
      <Route path={`${path}/logs/:id`}>
        <OrderStateLogs title={title} />
      </Route>
      <Route path={`${path}/detail/:id`}>
        <OrderDetail title={title} />
      </Route>
      <Route path={`${path}/edit/:id`}>
        <BudgetEdit libby={libby} title={title} />
      </Route>
      <Route path={`${path}/copy/:id`}>
        <BudgetCopy libby={libby} title={title} />
      </Route>
      <Route path={`${path}/copy-v2/:id`}>
        <BudgetNewV2 title={title} />
      </Route>
      <Route path={`${path}/comments/:id`}>
        <BudgetCommentList libby={libby} title={title} />
      </Route>
      <Route path={`${path}`}>
        <BudgetList
          title={title}
          detailUrl={`${path}/detail`}
          setFilter={setFilter}
          rows={rows}
          columns={columns}
          working={working}
          filter={filter}
          fetchMore={fetchMore}
          onSortChange={handleRequestSort}
          orderBy={orderBy}
          direction={direction}
          onRowClick={onRowClick}
          reFetch={reFetch}
          resetFilter={resetFilter}
          onNewBudget={onNewBudget}
          canalOffLine
          libby={libby}
        />
      </Route>
    </Switch>
  );
};

export const Budget = DatabaseConnector(BudgetRouter)(
  'ster_product',
  'ster_order_source',
  'order_state',
  'ster_order_document_type',
  'courier_order',
  'ster_order_table',
  'ster_order_shipment',
  'ster_order_so_with_delivery_date',
  'ster_order_b2b',
  'ster_order_comment',
  'ster_vw_order_b2b',
  'admin_account',
  'ster_state',
  'ster_city',
  'ster_order_buyer',
  'ster_order_item',
  'courier_service',
  'ster_list_date',
  'ster_reporting_objective_sales',
  'ster_transportation_company',
  'ster_transportation_company_search',
  'ster_order_cancelled',
  'ster_buyer_odoo',
  'operation',
  'operationcomposition',
  'operationorder'
);
