import { useCallback, useEffect, useState } from 'react';
import { usePrevious } from './usePrevious';
import { AnyObject, LibbyObject } from '../types/types';

/*
  This hook is used in combination with LibbyFetchDAO to do calls with libby with basic
  fetch more pagination
  params:
  - libby: Libby object provided by DatabaseConnector
  - Options object:
    - filter: Filter object to be passed to the dao (see LibbyFetchDAO)
              WARNING the hook react in filter changes. Use memo or ref hooks
              to memoize filter value and avoid unwanted api calls
    - orderBy: property for the order by (accept libby property, example: name.first)
    - daoName: name of the dao to use (must be connected with the libby HOC)
    - limit: limit to use for the calls (optional, default 40)
  returns an object with:
  - data: all the data fetched (incrementally by fetchMore)
  - working: boolean, can be used like a loading state
  - fetchMore: function to call to fetch the next batch of data
  - reFetch : function to reset the hook (offset = 0, data = 0)
              WARNING this function is automatically called when filter option change
  Example of use
  const { data: establishments, working, fetchMore } = useLibbyFetch(libby, {
    orderBy: 'nombre',
    daoName: 'establishment',
    filter, // see LibbyFetchDAO for an example
    limit: 20,
  })
 */

export type OptionsLibbyFetch = {
  filter?: AnyObject;
  orderBy?: string;
  daoName: string;
  limit?: number;
  checkDuplication?: boolean;
  direction?: 'asc' | 'desc';
  aspect?: string;
  enabled?: boolean;
};

export const useLibbyFetch = (libby: LibbyObject, { filter, orderBy, direction = 'asc', daoName = '', aspect = '', limit: initialLimit = 40, checkDuplication = true, enabled = true }: OptionsLibbyFetch) => {
  const prevFilter = usePrevious(filter);
  const [initialFetch, setInitialFetch] = useState(enabled);
  const [mergedData, setMergedData] = useState<AnyObject[]>([]);
  const [limit] = useState(initialLimit);
  const [offset, setOffset] = useState(0);
  const [dataError, setDataError] = useState<string | undefined>();

  const fetch = useCallback(async () => {
    if (!daoName) {
      console.log('daoName required!');
    } else if (!libby[daoName]) {
      console.log('DAO not found, be sure that DatabaseConnector HOC has the daoName in its config');
    } else {
      try {
        if (aspect) {
          libby[daoName].aspect(aspect);
        }
        const data = await libby[daoName].fetch({
          filter,
          orderBy,
          limit,
          offset,
          direction
        });

        if (!!libby[daoName].errors?._default?.rest) {
          const { message } = JSON.parse(libby[daoName].errors._default?.rest?.data);
          setDataError(message);
        }
        // TODO: think how to filter duplicated in a better way
        setMergedData((prev: AnyObject[]) => {
          // this is to avoid duplications
          const copy: AnyObject[] = [...prev];
          const { pk } = libby[daoName];
          if (data && data.length) {
            data.forEach((item: AnyObject) => {
              if (!checkDuplication || !copy.find((el) => el[pk] === item[pk])) {
                copy.push(item);
              }
            });
          }
          return copy;
        });
        setOffset(offset + limit);
      } catch (error) {
        const message = error instanceof Error ? error.message : '';
        setDataError(message);
      }
    }
  }, [checkDuplication, daoName, filter, libby, limit, offset, orderBy, direction, aspect]);
  const reFetch = useCallback(() => {
    setMergedData([]);
    setOffset(0);
    setInitialFetch(false);
    setDataError(undefined);
  }, []);
  useEffect(() => {
    if (enabled) {
      reFetch();
    }
  }, [orderBy, direction, reFetch, enabled]);
  useEffect(() => {
    if (prevFilter !== filter && enabled) {
      reFetch();
    }
  }, [filter, prevFilter, reFetch, enabled]);
  useEffect(() => {
    if (!initialFetch && enabled) {
      setInitialFetch(true);
      fetch();
    }
  }, [fetch, initialFetch, enabled]);

  const [debounce, setDebounce] = useState<NodeJS.Timeout | null>(null);
  const fetchMore = useCallback(() => {
    if (debounce) {
      clearTimeout(debounce);
    }
    setDebounce(
      setTimeout(() => {
        fetch();
      }, 100)
    );
  }, [debounce, fetch]);

  const addCreate = useCallback((data: object) => {
    setMergedData((prev) => {
      const copy: AnyObject[] = [...prev];
      copy.push(data);
      return copy;
    });
  }, []);

  const searchData = useCallback(
    (dataUpdate: AnyObject, id: string) => {
      const result = mergedData.findIndex((value) => value[id] === dataUpdate[id]);
      return result;
    },
    [mergedData]
  );

  const updateData = useCallback(
    (dataUpdate: AnyObject, id: string) => {
      setMergedData((prev) => {
        const copy: AnyObject[] = [...prev];
        if (dataUpdate[id]) {
          const result = searchData(dataUpdate, id);
          copy[result] = dataUpdate;
        }
        return copy;
      });
    },
    [setMergedData, searchData]
  );

  const removeData = useCallback(
    (id: string, idName: string) => {
      setMergedData((prev) => {
        const copy: AnyObject[] = [...prev];
        const result = copy.findIndex((value) => value[idName] === id);
        copy.splice(result, 1);
        return copy;
      });
    },
    [setMergedData]
  );

  return {
    data: mergedData,
    working: libby.working,
    fetchMore,
    reFetch,
    addCreate,
    setMergedData,
    updateData,
    removeData,
    searchData,
    error: dataError
  };
};
