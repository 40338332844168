import { useCallback, useState } from 'react';
import { useSnackbar } from 'notistack';
import { useLibby } from '@phinxlab/libby-rest-web';
import { CustomError } from 'utils';
import { BUDGET_SHIPMENT_TYPE } from '../../../../../../../const';
import { MethosProductsType, OrderItemType } from '../../../../BudgetNew/hook/useSaveOrderLogic';
import { useLibbyCall } from '../../../../../../../lib/libby';
import { useTranslation } from '../../../../../../../services/translation';
import { IBillingAddress, IBudgetClient, IShippingAddress, ITransportCompany, PaymentMethodItem, PaymentMethodData, ProductData, ProductInfo, ProductItemRow } from '../types';
import { ISelectedTransport } from '../types/ISelectedTransport';
import { IAdvanceSale } from '../types/IAdvanceSale';
import { AnyObject } from '../../../../../../../types';
import { getMargenPercentage } from '../../../utils';

export interface SaveOrderParams {
  orderBuyerId?: number;
  billingAddressCity: string;
  billingAddressState: string;
  last_name: string;
  first_name: string;
  email: string;
  phone_number: string;
  billingAddressStreet: string;
  billingAddressStreetNumber: string;
  billingAddressFloor: string;
  billingAddressDepartment: string;
  billingAddressZip: string;
  document: string;
  document_type: string;
  shipment_type: string;
  shipmentAddressCity: string;
  shipmentAddressCityId: string;
  shipmentAddressState: string;
  shipmentAddressStreet: string;
  shipmentAddressStreetNumber: string;
  shipmentAddressDepartment: string;
  shipmentAddressComments: string;
  shipmentAddressZip: string;
  shipmentAddressFloor: string;
  transportation_company?: any;
  advance_sale_date?: any;
  methodsProducts: MethosProductsType[];
  advance_sale?: boolean;
}

interface IBudgetResponse {
  ordersCreated: IOrdersCreated[];
  success: boolean;
}

interface IOrdersCreated {
  order_id: string;
  owner_id: string;
}

interface MethodGroupProduct extends ProductItemRow {
  product: ProductInfo;
}

interface MethodGroup {
  paymentMethod: PaymentMethodItem;
  products: MethodGroupProduct[];
}

export const useSaveBudget = (
  products: ProductData,
  paymentMethods: PaymentMethodData,
  selectedClient: IBudgetClient,
  shippingAddress: IShippingAddress,
  billingAddress: IBillingAddress,
  selectedTransport: ISelectedTransport,
  advanceSale: IAdvanceSale,
  selectedTransportCompany: ITransportCompany
) => {
  const [saving, setSaving] = useState(false);
  const [methodsProducts, setMethodsProducts] = useState<MethosProductsType[]>([]);
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const { libby } = useLibby(['ster_city']);
  const { recall: saveBudget } = useLibbyCall({ daoName: 'budget', methodName: 'save', noAutoCall: true });

  const validateFields = useCallback((): void => {
    const { selectedBillingAddress } = billingAddress;

    if (advanceSale.isAdvanceSale && !advanceSale.advanceSaleDate) throw new CustomError(t('Select the agreed delivery date'));

    if (selectedBillingAddress === '' || +selectedBillingAddress < 0) {
      throw new CustomError(t('Enter billing address'));
    }
    if (!selectedTransport?.id || selectedTransport?.id.trim().length === 0) {
      throw new CustomError(t('Select the shipping method'));
    }

    // hacemos validaciones de que los campos esten completos
    if (!selectedClient || !Object.keys(selectedClient).length) {
      throw new CustomError(t('Select a client'));
    }

    if (!selectedClient?.tier?.id) {
      throw new CustomError(t('Missing Tier'));
    }

    // chequeamos si existen metodos de pagos personalizados con el mismo valor de days
    const paymentGroup: AnyObject = {};
    for (const paymentMethod of paymentMethods) {
      if (!paymentGroup[`${paymentMethod.id}_${paymentMethod.paymentMethod.PaymentMethod.days}`]) {
        paymentGroup[`${paymentMethod.id}_${paymentMethod.paymentMethod.PaymentMethod.days}`] = true;
      } else {
        // consegui un repetido
        throw new CustomError(t('There are equal payment methods'));
      }
    }

    // chequeamos si alguno de los productos tiene la cantidad en 0
    for (const product of products) {
      for (const row of product.rows)
        if (!row.quantity) {
          throw new CustomError(t('There cannot be products with quantity 0'));
        }
    }
  }, [billingAddress, paymentMethods, products, selectedClient, selectedTransport, t, advanceSale.advanceSaleDate, advanceSale.isAdvanceSale]);

  const onSaveNew = useCallback(
    async (onError: Function, onFulfilled?: Function) => {
      try {
        validateFields();

        // agrupamos por metodo de pagos
        const groupedByMethod = products.reduce<{ [k: string]: MethodGroup }>((acum, item) => {
          for (let i = 0; i < item.rows.length; i++) {
            const row = item.rows[i];
            if (!acum[row.paymentMethod.code]) {
              acum[row.paymentMethod.code] = {
                paymentMethod: row.paymentMethod,
                products: []
              };
            }
            acum[row.paymentMethod.code].products.push({
              ...row,
              product: item.product
            });
          }
          return acum;
        }, {});

        setSaving(true);

        // ahora armamos el methodproduct
        const methodsProductsParam: MethosProductsType[] = Object.values(groupedByMethod).map<MethosProductsType>((item) => {
          const method = paymentMethods.find((_item) => item.paymentMethod.code === _item.code);
          if (!method) {
            throw new Error('Payment method not found');
          }
          return {
            payment_method_id: method.id,
            paid_in_advance: method.paidInAdvance,
            currency_id: method.Currency?.currency_id,
            payment_method_name: method.name,
            payment_method_quantity: 0,
            custom: method.paymentMethod.PaymentMethod.days,
            qty_deferred_payment: method.paymentDays,
            qty_delivery_days: method.deliverDays,
            product_list: item.products.map<OrderItemType>((product) => {
              return {
                // order_item_id: string | null; // TODO esto es para el edit
                order_item_id: null,
                owner_item_id: `budget - ${product.product.sku} - ${new Date().getTime()}`, // TODO: de donde saco esto?
                name: product.product.name,
                sku: product.product.sku,
                quantity: product.quantity,
                unit_price: product.priceUnit.toString(),
                subtotal: (product.quantity * product.priceUnit).toString(),
                discountPercentage: product.discountPercentage,
                discountPercentageMAX: getMargenPercentage(),
                // marketplace_product?: Marketplace_product; // TODO: esto parece estar roto de antes
                product: {
                  ...product.product,
                  // TODO esto no esta en los types pero parece ser que es necesario para que funcione
                  company: product.product.odoo?.price.company
                }
                // products_c?: Product_component[]; // TODO: esto parece ser que no se usa en la creacion
              };
            })
          };
        });
        const param: SaveOrderParams = {
          orderBuyerId: undefined,
          billingAddressCity: billingAddress.selectedBillingCity,
          billingAddressState: billingAddress.selectedBillingState.state_id,
          last_name: selectedClient.last_name,
          first_name: selectedClient.first_name,
          email: selectedClient.email,
          phone_number: selectedClient.phone_number,
          billingAddressStreet: billingAddress.selectedBillingStreet,
          billingAddressStreetNumber: billingAddress.selectedBillingStreetNumber,
          billingAddressFloor: billingAddress.selectedBillingFloor,
          billingAddressDepartment: billingAddress.selectedBillingDepartment,
          billingAddressZip: billingAddress.selectedBillingZipCode,
          document: selectedClient.document,
          document_type: selectedClient.document_type.document_type_id,
          shipment_type: selectedTransport.id,
          shipmentAddressCity: shippingAddress.selectedShippingAddress,
          shipmentAddressState: shippingAddress.selectedShippingState?.state_id,
          shipmentAddressCityId: shippingAddress.selectedShippingCityId,
          shipmentAddressStreet: shippingAddress.selectedShippingStreet,
          shipmentAddressStreetNumber: shippingAddress.selectedShippingStreetNumber,
          shipmentAddressDepartment: shippingAddress.selectedShippingDepartment,
          shipmentAddressComments: shippingAddress.selectedShippingComments,
          shipmentAddressZip: shippingAddress.selectedShippingZipCode,
          shipmentAddressFloor: shippingAddress.selectedShippingFloor,
          transportation_company: selectedTransportCompany?.transportation_company_id,
          advance_sale_date: advanceSale.advanceSaleDate?.format('DD-MM-YYYY'),
          methodsProducts: methodsProductsParam,
          advance_sale: advanceSale.isAdvanceSale
        };

        const [city] = await libby?.ster_city
          .query()
          .equals('name', param.billingAddressCity || 'default')
          .equals('state.state_id', param.billingAddressState)
          .run();

        // buyer
        const orderBuyer = {
          // ...previousOrderData?.buyer,
          // order_buyer_id: previousOrderData?.buyer?.order_buyer_id || null,
          order_buyer_id: param.orderBuyerId, // TODO checkear si existe ya el orderbuyer
          last_name: param.last_name,
          first_name: param.first_name,
          email: param.email,
          phone_number: param.phone_number,
          street: param.billingAddressStreet === '' || param.billingAddressStreet === 'null' ? null : param.billingAddressStreet,
          street_number: param.billingAddressStreetNumber === '' || param.billingAddressStreetNumber === 'null' ? null : param.billingAddressStreetNumber,
          floor: param.billingAddressFloor === '' || param.billingAddressFloor === 'null' ? null : param.billingAddressFloor,
          department: param.billingAddressDepartment === '' || param.billingAddressDepartment === 'null' ? null : param.billingAddressDepartment,
          zip: param.billingAddressZip === '' || param.billingAddressZip === 'null' ? null : param.billingAddressZip,
          extra_address: '',
          document: param.document,
          comments: '',
          document_type: { document_type_id: param.document_type },
          city
        };

        // shipmentAddress
        const [cityShipment] = await libby?.ster_city
          .query()
          .equals('city_id', param.shipment_type !== BUDGET_SHIPMENT_TYPE.RETIRO_EN_PLANTA.toString() ? param.shipmentAddressCityId : 0)
          .run();
        const orderShipmentAddress = {
          // ...previousOrderData?.shipment?.address,
          // order_shipment_address_id: previousOrderData?.shipment?.address?.order_shipment_address_id || null,
          // order_shipment_address_id: null,
          street:
            param.shipment_type !== BUDGET_SHIPMENT_TYPE.RETIRO_EN_PLANTA.toString() && param.shipmentAddressStreet && param.shipmentAddressStreet !== '' && param.shipmentAddressStreet !== 'null'
              ? param.shipmentAddressStreet
              : null,
          street_number:
            param.shipment_type !== BUDGET_SHIPMENT_TYPE.RETIRO_EN_PLANTA.toString() && param.shipmentAddressStreetNumber && param.shipmentAddressStreetNumber !== '' && param.shipmentAddressStreetNumber !== 'null'
              ? Number(param.shipmentAddressStreetNumber)
              : null,
          floor:
            param.shipment_type !== BUDGET_SHIPMENT_TYPE.RETIRO_EN_PLANTA.toString() && param.shipmentAddressFloor && param.shipmentAddressFloor !== '' && param.shipmentAddressFloor !== 'null' ? param.shipmentAddressFloor : null,
          department:
            param.shipment_type !== BUDGET_SHIPMENT_TYPE.RETIRO_EN_PLANTA.toString() && param.shipmentAddressDepartment && param.shipmentAddressDepartment !== '' && param.shipmentAddressDepartment !== 'null'
              ? param.shipmentAddressDepartment
              : null,
          extra_address: null,
          comments:
            param.shipment_type !== BUDGET_SHIPMENT_TYPE.RETIRO_EN_PLANTA.toString() && param.shipmentAddressComments && param.shipmentAddressComments !== '' && param.shipmentAddressComments !== 'null'
              ? param.shipmentAddressComments
              : null,
          zip: param.shipment_type !== BUDGET_SHIPMENT_TYPE.RETIRO_EN_PLANTA.toString() && param.shipmentAddressZip && param.shipmentAddressZip !== '' && param.shipmentAddressZip !== 'null' ? Number(param.shipmentAddressZip) : null,
          city: cityShipment
        };

        // shipmentReceiver
        const orderShipmentReceiver = {
          // ...previousOrderData?.shipment?.receiver,
          // order_shipment_receiver_id: previousOrderData?.shipment?.receiver?.order_shipment_receiver_id || null,
          order_shipment_receiver_id: null,
          first_name: orderBuyer.first_name,
          last_name: orderBuyer.last_name,
          email: orderBuyer.email,
          phone_number: orderBuyer.phone_number,
          document: orderBuyer.document,
          extra_address: null,
          comments: null,
          zip: param.shipment_type !== BUDGET_SHIPMENT_TYPE.RETIRO_EN_PLANTA.toString() && param.shipmentAddressZip ? Number(param.shipmentAddressZip) : null,
          city: cityShipment,
          document_type: orderBuyer.document_type
        };

        const body = {
          buyer: orderBuyer,
          shipmentAddress: orderShipmentAddress,
          shipmentReceiver: orderShipmentReceiver,
          methodsProducts: param.methodsProducts,
          shipment_type: param.shipment_type,
          transportation_company: param.transportation_company,
          advance_sale_date: param.advance_sale_date,
          tierId: selectedClient.tier.id,
          hasDocument: selectedClient.hasDocument,
          advance_sale: advanceSale.isAdvanceSale
        };

        const resultBudget = (await saveBudget(body)) as IBudgetResponse;
        const methodsProductsWithOrderId = param.methodsProducts.map((methodProduct: MethosProductsType, index: number) => {
          return {
            ...methodProduct,
            order_id: resultBudget.ordersCreated[index].order_id
          };
        });

        setMethodsProducts(methodsProductsWithOrderId);
        onFulfilled?.();
        setSaving(false);
      } catch (e) {
        console.log(e);
        const message = e instanceof CustomError ? e.message : t('Something is Wrong With Order Creation');
        enqueueSnackbar(message, { variant: 'error' });
        onError();
        setSaving(false);
      }
    },
    [
      saveBudget,
      libby,
      setSaving,
      enqueueSnackbar,
      t,
      products,
      paymentMethods,
      selectedClient,
      shippingAddress,
      billingAddress,
      selectedTransport,
      advanceSale.advanceSaleDate,
      selectedTransportCompany,
      validateFields,
      advanceSale.isAdvanceSale
    ]
  );

  return { saving, onSaveNew, methodsProducts };
};
