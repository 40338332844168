import React, { RefObject, useEffect, useImperativeHandle, useMemo } from 'react';
import { DatabaseConnector } from '@phinxlab/libby-rest-web';
import { TextFieldProps } from '@material-ui/core';
import { AnyObject, LibbyObject } from '../../types/types';
import { GeneralSelect } from '../../components/GeneralSelect';
import { useLibbyCall } from '../../hooks';

type SelectImportTypeProps = {
  inputProps: TextFieldProps;
  optionAll?: boolean;
  typeCourierSearch?: string;
  sort?: boolean | 'asc' | 'desc';
  external?: boolean;
};
interface SelectImportRawTypeProps extends SelectImportTypeProps {
  libby: LibbyObject;
  dataRef: RefObject<unknown>;
}

export const SelectOrderShipmentRaw = ({ typeCourierSearch, libby, inputProps = {}, optionAll = false, dataRef, sort, external = undefined }: SelectImportRawTypeProps) => {
  const {
    data: dataRaw,
    working = true,
    recall
  } = useLibbyCall(libby, {
    daoName: 'courier_service',
    methodName: 'getAllCourierService',
    noAutoCall: true
  });
  const disabled = useMemo(() => typeof typeCourierSearch !== 'undefined' && (typeCourierSearch === '0' || !typeCourierSearch.length), [typeCourierSearch]);

  useEffect(() => {
    if (!disabled) recall?.({ typeCourierSearch, external });
  }, [recall, typeCourierSearch, external, disabled]);

  const data = useMemo(
    () =>
      dataRaw.map(({ courier_service_id, name }: AnyObject) => ({
        id: courier_service_id,
        value: name
      })),
    [dataRaw]
  );

  useImperativeHandle(
    dataRef,
    () => ({
      data
    }),
    [data]
  );

  return <GeneralSelect disabled={disabled} data={data} working={working} label="Service" optionAll={optionAll} inputProps={inputProps} sort={sort} />;
};

const SelectOrderShipmentWithoutRef = DatabaseConnector(SelectOrderShipmentRaw)('courier_service');

export const SelectOrderShipment = React.forwardRef((props: SelectImportTypeProps, ref) => <SelectOrderShipmentWithoutRef {...props} dataRef={ref} />);
