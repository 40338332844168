import React, { useContext } from 'react';
import { CircularProgress, Grid, MenuItem, Paper, TextField, Typography } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import SearchIcon from '@material-ui/icons/Search';
import { GeneralSelect } from '../../../../../components/GeneralSelect';
import { SHIPMENT_TYPE } from '../../../const';
import { COURIER_SERVICE } from '../../../../../const';
import { AnyObject } from '../../../../../types';
import { makeGetErrorAndHelperText, textFieldProps } from '../../../../../utils/materialHelpers';
import { AutocompleteCity } from '../../../../../components/AutocompleteCity';
import { useStyles } from '../const';
import { useTranslation } from '../../../../../services/translation';
import { BudgetFormContext } from '../hook/BudgetFormContext';

export const Shipment = () => {
  const {
    shipment_type,
    addressRequired,
    setTransportationSelect,
    transportation_company,
    setTransportationSearch,
    fetchingTransportationCompany,
    transportationCompanyOptions,
    carrierData,
    shipmentAddress,
    handleChangeAddress,
    shippingAddressesOptions,
    stateOptions,
    fetchingStates,
    shipmentAddressCity,
    customErrors,
    shipmentAddressStreet,
    shipmentAddressStreetNumber,
    shipmentAddressZip,
    shipmentAddressFloor,
    shipmentAddressDepartment,
    shipmentAddressComments,
    shipmentAddressState
  } = useContext(BudgetFormContext);
  const { t } = useTranslation();
  const classes = useStyles();

  const getErrorAndHelperText = makeGetErrorAndHelperText(t);

  return (
    <Grid item xs={12}>
      <Paper className={classes.paper}>
        <Grid item container>
          <Grid xs={12}>
            <Typography variant="h1" className={classes.title}>{`${t('Shipment')}`}</Typography>
          </Grid>
          <Grid item xs={3} style={{ margin: '8px 8px 10px 8px' }}>
            <GeneralSelect
              label={`${t('Shipment Type')}`}
              optionAll={false}
              data={SHIPMENT_TYPE}
              working={false}
              inputProps={{
                onChange: shipment_type.input.onChange,
                value: shipment_type.input.value || '',
                ...getErrorAndHelperText(shipment_type),
                required: true
              }}
            />
          </Grid>
          {addressRequired ? (
            <>
              {shipment_type.input.value === COURIER_SERVICE.TRANSPORTE.toString() && (
                <>
                  <Grid xs={12}>
                    <Typography variant="h1" className={classes.title}>{`${t('Carrier')}`}</Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Autocomplete
                      onChange={(e, value: { id: string; value: string } | any) => {
                        setTransportationSelect(value?.id || '');
                        transportation_company.input.onChange({ target: value });
                      }}
                      onInputChange={(event, value) => {
                        setTransportationSearch(value);
                      }}
                      options={fetchingTransportationCompany ? [...transportationCompanyOptions] : transportationCompanyOptions}
                      getOptionLabel={(item: AnyObject) => `${item.id}`}
                      renderOption={(item) =>
                        fetchingTransportationCompany ? (
                          <div className={classes.loadingAutocomplete}>
                            <CircularProgress size={24} />
                          </div>
                        ) : (
                          <div>{item.id}</div>
                        )
                      }
                      popupIcon={<SearchIcon />}
                      style={{ width: 450, margin: '8px' }}
                      renderInput={(params) =>
                        fetchingTransportationCompany ? (
                          <TextField {...params} label="Buscar Transportista" variant="standard" disabled>
                            <CircularProgress size={24} />
                          </TextField>
                        ) : (
                          <TextField {...params} label="Buscar Transportista" variant="standard" />
                        )
                      }
                    />
                  </Grid>
                  <Grid container xs={12} style={{ marginLeft: '10px', marginBottom: '10px', marginTop: '10px', justifyContent: 'flex-start' }}>
                    <Grid item xs={2} direction="row" style={{ display: 'flex', justifyContent: 'flex-start' }}>
                      <Typography variant="h5">{`${t('Name')}:`}</Typography>
                      <Typography variant="body1" style={{ marginLeft: '5px' }}>{`${carrierData?.name || '_ _ _ _ _'}`}</Typography>
                    </Grid>
                    <Grid item xs={2} direction="row" style={{ display: 'flex', justifyContent: 'flex-start' }}>
                      <Typography variant="h5">{`${t('Province')}:`}</Typography>
                      <Typography variant="body1" style={{ marginLeft: '5px' }}>{`${typeof carrierData?.city?.state === 'object' ? carrierData?.city.state?.name : '_ _ _ _ _'}`}</Typography>
                    </Grid>
                    <Grid item xs={2} direction="row" style={{ display: 'flex', justifyContent: 'flex-start' }}>
                      <Typography variant="h5">{`${t('City')}:`}</Typography>
                      <Typography variant="body1" style={{ marginLeft: '5px' }}>{`${typeof carrierData?.city === 'object' && carrierData?.city?.name ? carrierData?.city?.name : '_ _ _ _ _'}`}</Typography>
                    </Grid>
                    <Grid item xs={2} direction="row" style={{ display: 'flex', justifyContent: 'flex-start' }}>
                      <Typography variant="h5">{`${t('Street')}:`}</Typography>
                      <Typography variant="body1" style={{ marginLeft: '5px' }}>{`${carrierData?.street || '_ _ _ _ _'}`}</Typography>
                    </Grid>
                    <Grid item xs={2} direction="row" style={{ display: 'flex', justifyContent: 'flex-start' }}>
                      <Typography variant="h5">{`${t('Street Number')}:`}</Typography>
                      <Typography variant="body1" style={{ marginLeft: '5px' }}>{`${carrierData?.street_number || '_ _ _ _ _'}`}</Typography>
                    </Grid>
                    <Grid item xs={2} direction="row" style={{ display: 'flex', justifyContent: 'flex-start' }}>
                      <Typography variant="h5">{`${t('Floor')}:`}</Typography>
                      <Typography variant="body1" style={{ marginLeft: '5px' }}>{`${carrierData?.floor || '_ _ _ _ _'}`}</Typography>
                    </Grid>
                    <Grid item xs={2} direction="row" style={{ display: 'flex', justifyContent: 'flex-start', marginTop: '5px' }}>
                      <Typography variant="h5">{`${t('Department')}:`}</Typography>
                      <Typography variant="body1" style={{ marginLeft: '5px' }}>{`${carrierData?.department || '_ _ _ _ _'}`}</Typography>
                    </Grid>
                    <Grid item xs={2} direction="row" style={{ display: 'flex', justifyContent: 'flex-start', marginTop: '5px' }}>
                      <Typography variant="h5">{`${t('Zip')}:`}</Typography>
                      <Typography variant="body1" style={{ marginLeft: '5px' }}>{`${carrierData?.zip || '_ _ _ _ _'}`}</Typography>
                    </Grid>
                    <Grid item xs={2} direction="row" style={{ display: 'flex', justifyContent: 'flex-start', marginTop: '5px' }}>
                      <Typography variant="h5">{`${t('Phone Number')}:`}</Typography>
                      <Typography variant="body1" style={{ marginLeft: '5px' }}>{`${carrierData?.phone_number || '_ _ _ _ _'}`}</Typography>
                    </Grid>
                  </Grid>
                </>
              )}
              <Grid xs={12}>
                <Typography variant="h1" className={classes.title}>{`${t('Shipment Address')}`}</Typography>
              </Grid>
              <Grid item xs={3}>
                <TextField
                  {...textFieldProps(`${t('Old Shipment Addresses')}`)}
                  select
                  variant="standard"
                  value={shipmentAddress}
                  onChange={(e: any) => handleChangeAddress(e, 'Shipment')}
                  InputLabelProps={{ shrink: true }}
                  style={{ width: 450, margin: '8px' }}
                  disabled={shippingAddressesOptions?.length === 0}
                >
                  {shippingAddressesOptions.map((item) => (
                    <MenuItem key={item.id} value={item.value}>
                      {item.value}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={12} className={classes.containerInputs}>
                <Grid item xs={3} style={{ margin: '8px', maxWidth: '45ch' }}>
                  <GeneralSelect
                    label={`${t('Province')}`}
                    optionAll={false}
                    data={stateOptions}
                    working={!!fetchingStates}
                    inputProps={{
                      onChange: shipmentAddressState.input.onChange,
                      value: shipmentAddressState.input.value,
                      ...getErrorAndHelperText(shipmentAddressState),
                      required: true,
                      style: { textAlign: 'left' },
                      error: customErrors.find((item) => item.name === 'state')?.error || false,
                      helperText: t(customErrors.find((item) => item.name === 'state')?.text || '').replace('$$$$', t('Province'))
                    }}
                  />
                </Grid>
                <AutocompleteCity
                  name="city"
                  inputProps={{
                    province: shipmentAddressState.input.value,
                    onChange: shipmentAddressCity.input.onChange,
                    value: shipmentAddressCity.input.value === '' ? null : shipmentAddressCity.input.value,
                    classes: classes.provinceCityInputShipment,
                    error: {
                      error: customErrors.find((item) => item.name === 'city')?.error || false,
                      helperText: t(customErrors.find((item) => item.name === 'city')?.text || '').replace('$$$$', t('City'))
                    },
                    onBlur: shipmentAddressCity.input.onBlur,
                    InputLabelProps: { shrink: true }
                  }}
                />
                <TextField
                  {...textFieldProps(`${t('Street')}`)}
                  {...getErrorAndHelperText(shipmentAddressStreet)}
                  required
                  variant="standard"
                  value={shipmentAddressStreet.input.value}
                  onChange={shipmentAddressStreet.input.onChange}
                  InputLabelProps={{ shrink: true }}
                  className={classes.inputsShipment}
                  error={customErrors.find((item) => item.name === 'street')?.error || false}
                  helperText={t(customErrors.find((item) => item.name === 'street')?.text || '').replace('$$$$', t('Street'))}
                />
                <TextField
                  {...textFieldProps(`${t('Street Number')}`)}
                  {...getErrorAndHelperText(shipmentAddressStreetNumber)}
                  required
                  variant="standard"
                  value={shipmentAddressStreetNumber.input.value}
                  onChange={shipmentAddressStreetNumber.input.onChange}
                  InputLabelProps={{ shrink: true }}
                  className={classes.inputsShipment}
                  type="number"
                  error={customErrors.find((item) => item.name === 'streetNumber')?.error || false}
                  helperText={t(customErrors.find((item) => item.name === 'streetNumber')?.text || '').replace('$$$$', t('Street Number'))}
                />
              </Grid>
              <Grid item xs={12} className={classes.containerInputs}>
                <TextField
                  {...textFieldProps(`${t('Zip')}`)}
                  {...getErrorAndHelperText(shipmentAddressZip)}
                  required
                  variant="standard"
                  value={shipmentAddressZip.input.value}
                  onChange={shipmentAddressZip.input.onChange}
                  InputLabelProps={{ shrink: true }}
                  className={classes.inputsShipment}
                  type="number"
                  error={customErrors.find((item) => item.name === 'zip')?.error || false}
                  helperText={t(customErrors.find((item) => item.name === 'zip')?.text || '').replace('$$$$', t('Zip'))}
                />
                <TextField
                  {...textFieldProps(`${t('Floor')}`)}
                  {...getErrorAndHelperText(shipmentAddressFloor)}
                  variant="standard"
                  value={shipmentAddressFloor.input.value}
                  onChange={shipmentAddressFloor.input.onChange}
                  InputLabelProps={{ shrink: true }}
                  className={classes.inputsShipment}
                />
                <TextField
                  {...textFieldProps(`${t('Department')}`)}
                  {...getErrorAndHelperText(shipmentAddressDepartment)}
                  variant="standard"
                  value={shipmentAddressDepartment.input.value}
                  onChange={shipmentAddressDepartment.input.onChange}
                  InputLabelProps={{ shrink: true }}
                  className={classes.inputsShipment}
                />
                <TextField
                  {...textFieldProps(`${t('Comments')}`)}
                  {...getErrorAndHelperText(shipmentAddressComments)}
                  variant="standard"
                  value={shipmentAddressComments.input.value}
                  onChange={shipmentAddressComments.input.onChange}
                  InputLabelProps={{ shrink: true }}
                  className={classes.inputsShipment}
                />
              </Grid>
            </>
          ) : null}
        </Grid>
      </Paper>
    </Grid>
  );
};
