import { makeValidator, ValidatorType, ValidatorRule } from '../../../../utils';

export const formInitialValues = {
  name: '',
  state_id: ''
};

const validations: ValidatorRule[] = [
  { key: 'name', validators: [ValidatorType.REQUIRED] },
  { key: 'state_id', validators: [ValidatorType.REQUIRED] }
];

export const validate = makeValidator(validations);

export const getValidate = (keys: Array<string>) => makeValidator(keys.length > 0 ? validations.filter((val) => keys.includes(val.key)) : validations);
