import React, { useCallback } from 'react';
import { Box, Typography } from '@material-ui/core';
import { useTranslation } from '../../../services/translation';
import ConfirmDialog from '../../../components/ConfirmDialog';
import CustomModal from '../../../services/customFormDialog';

interface RecipentsShowType {
  message_to: string;
  message_id: string;
}

const ConfirmModal = CustomModal(ConfirmDialog);

export const RecipentsShow = ({ message_to, message_id }: RecipentsShowType) => {
  const recipentArray = message_to?.length ? message_to.split(',') : [];

  const { t } = useTranslation();

  const showRecipents = useCallback(
    async (recipents) => {
      await ConfirmModal.show({
        title: t('Recipents'),
        content: recipents.map((recipentInner: string) => <p>{recipentInner}</p>),
        confirmText: t('Close'),
        oneButton: true
      });
    },
    [t]
  );

  return (
    <Box flexDirection="row" display="flex" alignItems="center">
      <Box width="100%">
        {recipentArray.map((recipent: string, index: number) => {
          if (index === 2) {
            return (
              <Box display="flex" width="100%" justifyContent="flex-end">
                <Typography onClick={() => showRecipents(recipentArray)} style={{ cursor: 'pointer', width: 'fit-content' }} color="primary" variant="body2" key={`${recipent}-${message_id}`}>
                  {`${t('see more')}...`}
                </Typography>
              </Box>
            );
          }
          if (index < 2) {
            return (
              <Typography variant="body2" key={`${recipent}-${message_id}`}>
                {recipent}
              </Typography>
            );
          }
          return <></>;
        })}
      </Box>
    </Box>
  );
};
