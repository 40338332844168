import { makeBusinessEntity } from '../lib/libby/generator/makeBusinessEntity';
import { SterConfigLog } from '../interfaces/business';

const DAO_NAME = 'ster_config_logs';

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch }
} = makeBusinessEntity<SterConfigLog>(DAO_NAME);

// hooks
export const useSterConfigLogFetchById = fetchById;
export const useSterConfigLogLibbyCall = libbyCall;
export const useSterConfigLogDAO = libbyDAO;
export const useSterConfigLogLibbyFetch = libbyFetch;
