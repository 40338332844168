import { LibbyFetchDAO } from './LibbyFetchDAO';

export class OrdersSoDAO extends LibbyFetchDAO {
  constructor() {
    super('ster_order_so', 'order_id');
  }

  getAll() {
    return this.query().limit(0, 10000).run();
  }

  fetchAllById(idAll: number[]) {
    let query = this.aspect('list_order_so_print').query();

    idAll.forEach((id) => {
      query = query.or();
      query.equals('order_id', id);
    });
    query = query.run();
    return query;
  }
}
