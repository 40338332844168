import { Filter } from '../../../../../types';

type MakeFilterProps = {
  ster_config_id?: string;
};

export const makeFilter = ({ ster_config_id }: MakeFilterProps) => {
  const filter: Filter = {};

  if (typeof ster_config_id === 'string') {
    filter.active = [
      {
        path: 'ster_config_id',
        value: ster_config_id
      }
    ];
  }

  return filter;
};
