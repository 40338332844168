import React from 'react';
import { IconButton } from '@material-ui/core';
import VisibilityIcon from '@material-ui/icons/Visibility';

interface RowsMessageType {
  message: string;
  showMessageDialog: (message: string, isDownSm: boolean, isMobile: boolean) => void;
  isDownSm: boolean;
  isMobile: boolean;
}

export const RowMessage = ({ message, showMessageDialog, isDownSm, isMobile }: RowsMessageType) => (
  <IconButton onClick={() => showMessageDialog(message, isDownSm, isMobile)}>
    <VisibilityIcon />
  </IconButton>
);
