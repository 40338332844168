import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useField, useForm } from 'react-final-form-hooks';
import { Grid, Paper, Button } from '@material-ui/core';
import axios from 'axios';
import { cloneDeep } from 'lodash';
import { useSnackbar } from 'notistack';
import { validate } from '../utils';
import { ProductSearch } from './ProductSearch';
import { BudgetClient } from '../../../interfaces';
import { useLibbyCall, useLibbyFetch } from '../../../../../hooks';
import confirmDialog from '../../../../../services/confirmDialog';
import { useTranslation } from '../../../../../services/translation';
import { BudgetDetailsProps, BudgetEditProps, RowsType } from '../../../types';
import { Product, TransportationCompany } from '../../../../../interfaces/business';
import { ScreenAligned } from '../../../../../components/ScreenAligned/ScreenAligned';
import { BUYER_ODOO_BILLING_INFO, COURIER_SERVICE, GET_PRICE_LIST_B2B } from '../../../../../const';
import { useSaveOrderLogic, OrderItemType, UseSaveOrderLogicProps } from '../hook/useSaveOrderLogic';
import { initFormBudget, useStyles } from '../const';
import { AnyObject } from '../../../../../types';
import { API_URL } from '../../../../../config';
import { formatMoney } from '../../../../../utils';
import { AdvanceSale } from './AdvanceSale';
import { PaymentMethod } from './PaymentMethod';
import { usePaymentMethodOdooLibbyFetch } from '../../../../../business';
import { BudgetFormContext, BudgetFormContextType, PaymentMethodsType } from '../hook/BudgetFormContext';
import { ClientInformation } from './ClientInformation';
import { BillingAddress } from './BillingAddress';
import { Shipment } from './Shipment';
import { useValidationForm } from '../hook/useValidationForm';
import { PreviewNewBudgetDialog } from '../../../../../components/PreviewNewBudget';
import customFormDialog from '../../../../../services/customFormDialog';
import LoadingData from '../../../../components/LoadingData';

const PreviewNewBudgetDialogModal = customFormDialog(PreviewNewBudgetDialog);

export const BudgetForm = ({ title, libby, budgetEditProps }: BudgetDetailsProps & { budgetEditProps?: BudgetEditProps }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [client, setClient] = useState<BudgetClient | undefined | null>();
  const [clientSearch, setClientSearch] = useState<string>('');
  const [transportationSearch, setTransportationSearch] = useState<string>('');
  const history = useHistory();
  const [addressRequired, setAddressRequired] = useState<boolean>(false);
  const [loadItems, setLoadItems] = useState<boolean>(false);
  const [reloadPrice, setReloadPrice] = useState<boolean>(false);
  const [customErrors, setCustomErrors] = useState<Array<{ name: string; error: boolean; text: string }>>([]);
  const [billingAddress, setBillingAddress] = useState<string>('');
  const [shipmentAddress, setShipmentAddress] = useState<string>('');
  const [carrierData, setCarrierData] = useState<TransportationCompany>();
  const [transportationSelect, setTransportationSelect] = useState<string | undefined>('');
  const { data: paymentMethodOdoo } = usePaymentMethodOdooLibbyFetch();
  const { validationForm } = useValidationForm({ setCustomErrors });
  const { enqueueSnackbar } = useSnackbar();

  const paymentMethodsGroup = useMemo(
    () => paymentMethodOdoo?.map((paymentMethod) => ({ id: paymentMethod.paymentMethodOdooId || '', plazo: paymentMethod.plazoPago, name: paymentMethod.group, percentil: 0 })) || [],
    [paymentMethodOdoo]
  );

  const isCopy = useLocation().pathname.includes('copy');

  const { data: states, working: fetchingStates } = useLibbyFetch(libby, {
    daoName: 'ster_state',
    orderBy: 'name'
  });

  const {
    data: transportationCompany,
    working: fetchingTransportationCompany,
    recall: recalltransportation
  } = useLibbyCall(libby, {
    daoName: 'ster_transportation_company_search',
    methodName: 'getTransportationByName',
    params: [],
    noAutoCall: true
  });

  const {
    data: budgetUsers,
    working: loadingSearch,
    recall
  } = useLibbyCall(libby, {
    daoName: 'ster_buyer_odoo',
    methodName: 'getBuyerByNameId',
    params: [],
    noAutoCall: true
  });

  const { addItem, orderItems, removeItem, onSave, saving, editItem, setOrderItems, methodsProducts, setMethodsProducts } = useSaveOrderLogic({
    libby,
    previousOrderData: budgetEditProps?.previousOrderData,
    isCopy
  } as UseSaveOrderLogicProps);

  const getClient = useCallback(
    async (buyer: string = '') => {
      if (buyer?.length < 4) return;
      if (/^(?=.*[A-Za-z])(?=.*\d).+$/.test(buyer)) return;
      if (!Number.isNaN(+buyer)) {
        if (buyer?.length >= 8) {
          await recall?.(buyer);
        }
      } else {
        await recall?.(buyer);
      }
    },
    [recall]
  );

  const getTransportation = useCallback(
    async (transportation: string = '') => {
      await recalltransportation?.(transportation);
    },
    [recalltransportation]
  );

  useEffect(() => {
    if (clientSearch) getClient(clientSearch);
  }, [getClient, clientSearch]);

  useEffect(() => {
    if (transportationSearch && transportationSelect !== transportationSearch) getTransportation(transportationSearch);
  }, [getTransportation, transportationSearch, transportationSelect]);

  const onSubmit = useCallback(
    async (param) => {
      if (validationForm(param)) await onSave(param);
    },
    [onSave, validationForm]
  );

  const { form, handleSubmit } = useForm({
    initialValues: initFormBudget,
    onSubmit,
    validate
  });

  const first_name = useField('first_name', form);
  const last_name = useField('last_name', form);
  const document = useField('document', form);
  const document_type = useField('document_type', form);
  const phone_number = useField('phone_number', form);
  const email = useField('email', form);

  const billingAddressState = useField('billingAddressState', form);
  const billingAddressCity = useField('billingAddressCity', form);
  const billingAddressStreet = useField('billingAddressStreet', form);
  const billingAddressStreetNumber = useField('billingAddressStreetNumber', form);
  const billingAddressFloor = useField('billingAddressFloor', form);
  const billingAddressDepartment = useField('billingAddressDepartment', form);
  const billingAddressZip = useField('billingAddressZip', form);

  const shipment_type = useField('shipment_type', form);
  const shipmentAddressState = useField('shipmentAddressState', form);
  const shipmentAddressCity = useField('shipmentAddressCity', form);
  const shipmentAddressStreet = useField('shipmentAddressStreet', form);
  const shipmentAddressStreetNumber = useField('shipmentAddressStreetNumber', form);
  const shipmentAddressZip = useField('shipmentAddressZip', form);
  const shipmentAddressFloor = useField('shipmentAddressFloor', form);
  const shipmentAddressDepartment = useField('shipmentAddressDepartment', form);
  const shipmentAddressComments = useField('shipmentAddressComments', form);

  const payment_method = useField('payment_method', form);
  const custom_term = useField('custom_term', form);
  const transportation_company = useField('transportation_company', form);

  const advance_sale = useField('advance_sale', form);
  const advance_sale_date = useField('advance_sale_date', form);

  const updateForm = useCallback(
    (values) => {
      if (values.document !== document.input.value) {
        const updateValues = {
          ...values,
          payment_method: paymentMethodsGroup?.find((item) => item.id?.toString() === values.payment_method.toString()) ? [paymentMethodsGroup.find((item) => item.id?.toString() === values.payment_method.toString())] : []
        };
        form.reset(updateValues);
      }
    },
    [document.input.value, form, paymentMethodsGroup]
  );

  useEffect(() => {
    if (budgetEditProps?.formValues) {
      updateForm(budgetEditProps?.formValues);
    }
  }, [budgetEditProps, updateForm]);

  const onBudgetBack = async () => {
    const confirm = await confirmDialog.show({
      title: t('Leaving budget form'),
      content: `${t('Are you sure you wanna go back?')}`,
      confirmText: `${t('Accept')}`,
      cancelText: t('Cancel')
    });
    if (confirm) {
      history.goBack();
    }
  };

  const updateItemsForm = useCallback(
    (values) => {
      values.then((valuePromise: any) => {
        if (JSON.stringify(valuePromise) !== JSON.stringify(orderItems) && !loadItems) {
          setLoadItems(true);
          setOrderItems(valuePromise as OrderItemType[]);
        }
      });
    },
    [loadItems, orderItems, setOrderItems]
  );

  useEffect(() => {
    if (budgetEditProps?.formItems) {
      updateItemsForm(budgetEditProps?.formItems);
    }
  }, [budgetEditProps, updateItemsForm]);

  const stateOptions = useMemo(() => states.map((s: any) => ({ id: s.state_id, value: s.name })), [states]);
  const transportationCompanyOptions = useMemo(() => transportationCompany.map((item: any) => ({ value: item.transportation_company_id, id: item.name })), [transportationCompany]);

  useEffect(() => {
    if (shipment_type.input.value === COURIER_SERVICE.RETIRO_EN_PLANTA.toString()) setAddressRequired(false);
    else if (shipment_type.input.value === COURIER_SERVICE.TRANSPORTE.toString() || shipment_type.input.value === COURIER_SERVICE.ANDREANI.toString()) setAddressRequired(true);
  }, [shipment_type.input.value]);

  const orderItemsRow = useMemo<RowsType[]>(() => orderItems.map((orderItem, index) => ({ ...orderItem, id: orderItem?.order_item_id || index.toString() })), [orderItems]);

  const orderItemsSubTotal = useMemo<number>(() => {
    let subtotal = 0;
    orderItems.map((orderItem) => {
      subtotal += +orderItem.subtotal;
      return orderItem;
    });
    return subtotal;
  }, [orderItems]);

  const billingAddressesOptions = useMemo(
    () =>
      client?.billing_address?.map((item, index) => ({
        id: index.toString(),
        value: `${t('Province')}: ${stateOptions?.find((stateItem) => stateItem.id === item.city?.state?.state_id?.toString())?.value}; ${t('City')}: ${item.city?.name}; ${t('Street')}: ${item.street}; ${t('Street Number')}: ${
          item.street_number
        };${t('Zip Code')}: ${item.zip}; ${t('Floor')}: ${item.floor}; ${t('Department')}: ${item.department};`
      })) || [],
    [client, stateOptions, t]
  );

  const shippingAddressesOptions = useMemo(
    () =>
      client?.shipment_address?.map((item, index) => ({
        id: index.toString(),
        value: `${t('Province')}:
         ${stateOptions?.find((stateItem) => stateItem.id === item.city?.state?.state_id?.toString())?.value};
         ${t('City')}: ${item.city?.name};
         ${t('Street')}: ${item.street};
         ${t('Street Number')}:${item.street_number};
         ${t('Zip Code')}: ${item.zip};
         ${t('Floor')}: ${item.floor || ''};
         ${t('Department')}: ${item.department || ''};
         ${t('Comments')}: ${item.comments || ''};`
      })) || [],
    [client, stateOptions, t]
  );

  const handleChangeAddress = useCallback(
    (event: any, type: string) => {
      const information = event.target.value.split(';').map((item: any) => item.split(':')[1]?.trim());
      if (type === 'Billing') {
        billingAddressState.input.onChange(stateOptions.find((item) => item.value === information[0])?.id);
        billingAddressCity.input.onChange(information[1]);
        billingAddressStreet.input.onChange(information[2]);
        billingAddressStreetNumber.input.onChange(Number(information[3]));
        billingAddressZip.input.onChange(information[4]);
        billingAddressFloor.input.onChange(information[5]);
        billingAddressDepartment.input.onChange(information[6]);

        setBillingAddress(event.target.value);
      } else if (type === 'Shipment') {
        shipmentAddressState.input.onChange(stateOptions.find((item) => item.value === information[0])?.id);
        shipmentAddressCity.input.onChange(information[1]);
        shipmentAddressStreet.input.onChange(information[2]);
        shipmentAddressStreetNumber.input.onChange(information[3] ? Number(information[3]) : '');
        shipmentAddressZip.input.onChange(information[4]);
        shipmentAddressFloor.input.onChange(information[5]);
        shipmentAddressDepartment.input.onChange(information[6]);
        shipmentAddressComments.input.onChange(information[7]);

        setShipmentAddress(event.target.value);
      }
    },
    [
      billingAddressCity.input,
      billingAddressState.input,
      billingAddressStreet.input,
      billingAddressStreetNumber.input,
      billingAddressZip.input,
      billingAddressFloor.input,
      billingAddressDepartment.input,
      shipmentAddressCity.input,
      shipmentAddressState.input,
      shipmentAddressStreet.input,
      shipmentAddressStreetNumber.input,
      shipmentAddressZip.input,
      shipmentAddressFloor.input,
      shipmentAddressDepartment.input,
      shipmentAddressComments,
      stateOptions
    ]
  );

  const handleChangePaymentMethod = useCallback((event: any) => payment_method.input.onChange(budgetEditProps?.previousOrderData && !isCopy ? [event.target] : event.target), [payment_method.input, budgetEditProps, isCopy]);

  useEffect(() => {
    if (client) {
      const formValues = {
        first_name: client?.first_name,
        last_name: '',
        document: client?.document,
        document_type: client?.document_type?.document_type_id,
        phone_number: client?.phone_number,
        email: client?.email,
        billingAddressState: '',
        billingAddressCity: '',
        billingAddressStreet: '',
        billingAddressStreetNumber: '',
        billingAddressFloor: '',
        billingAddressDepartment: '',
        billingAddressZip: '',
        shipmentAddressState: '',
        shipmentAddressCity: '',
        shipmentAddressStreet: '',
        shipmentAddressStreetNumber: '',
        shipmentAddressZip: '',
        shipmentAddressFloor: '',
        shipmentAddressDepartment: '',
        shipmentAddressComments: '',
        payment_method: '',
        custom_term: '',
        qty_deferred_payment: client?.qty_deferred_payment || 0,
        qty_delivery_days: client?.qty_delivery_days || 0,
        transportation_company: client?.transportation_company_id
      };

      updateForm(formValues);

      if (billingAddressesOptions?.length && billingAddressState.input.value === '') handleChangeAddress({ target: billingAddressesOptions[billingAddressesOptions?.length - 1] }, 'Billing');
      if (shippingAddressesOptions?.length && shipmentAddressState.input.value === '') handleChangeAddress({ target: shippingAddressesOptions[shippingAddressesOptions?.length - 1] }, 'Shipment');
      if (client?.transportation_company_id !== null && !carrierData) {
        setCarrierData(transportationCompany.find((item: AnyObject) => item.transportation_company_id === client?.transportation_company_id) as TransportationCompany);
      }
    } else if (client === null) {
      form.reset(initFormBudget);
    }
  }, [billingAddressesOptions, shippingAddressesOptions, client, form, handleChangeAddress, updateForm, billingAddressState.input.value, shipmentAddressState.input.value, transportationCompany, carrierData]);

  useEffect(() => {
    if (!transportation_company.input.value) return;
    setCarrierData(transportationCompany.find((item: AnyObject) => item.transportation_company_id.toString() === transportation_company.input.value.toString()) as TransportationCompany);
  }, [transportationCompany, transportation_company.input.value]);

  const handleClient = useCallback(async (selectedClient: BudgetClient) => {
    const response = await axios({
      method: 'GET',
      url: `${API_URL}/${BUYER_ODOO_BILLING_INFO.replace(':document', selectedClient?.document || '')}`
    });

    setClient({
      ...selectedClient,
      ...response.data
    });
  }, []);

  const budgetFormContextValue = useMemo<BudgetFormContextType>(
    () => ({
      payment_method,
      custom_term,
      customErrors,
      paymentMethodsGroup,
      budgetEditProps,
      loadingSearch,
      setClientSearch,
      handleClient,
      clientSearch,
      budgetUsers,
      document_type,
      email,
      phone_number,
      last_name,
      first_name,
      document,
      client,
      billingAddressesOptions,
      billingAddress,
      handleChangeAddress,
      stateOptions,
      fetchingStates,
      billingAddressState,
      billingAddressCity,
      billingAddressStreetNumber,
      billingAddressZip,
      billingAddressStreet,
      billingAddressFloor,
      billingAddressDepartment,
      shipment_type,
      addressRequired,
      setTransportationSelect,
      transportation_company,
      setTransportationSearch,
      fetchingTransportationCompany,
      transportationCompanyOptions,
      carrierData,
      shipmentAddress,
      shippingAddressesOptions,
      shipmentAddressStreetNumber,
      shipmentAddressZip,
      shipmentAddressStreet,
      shipmentAddressFloor,
      shipmentAddressDepartment,
      shipmentAddressComments,
      shipmentAddressState,
      shipmentAddressCity
    }),
    [
      payment_method,
      custom_term,
      customErrors,
      paymentMethodsGroup,
      budgetEditProps,
      loadingSearch,
      setClientSearch,
      handleClient,
      clientSearch,
      budgetUsers,
      document_type,
      email,
      phone_number,
      last_name,
      first_name,
      document,
      client,
      billingAddressesOptions,
      billingAddress,
      handleChangeAddress,
      stateOptions,
      fetchingStates,
      billingAddressState,
      billingAddressCity,
      billingAddressStreetNumber,
      billingAddressZip,
      billingAddressStreet,
      billingAddressFloor,
      billingAddressDepartment,
      shipment_type,
      addressRequired,
      setTransportationSelect,
      transportation_company,
      setTransportationSearch,
      fetchingTransportationCompany,
      transportationCompanyOptions,
      carrierData,
      shipmentAddress,
      shippingAddressesOptions,
      shipmentAddressStreetNumber,
      shipmentAddressZip,
      shipmentAddressStreet,
      shipmentAddressFloor,
      shipmentAddressDepartment,
      shipmentAddressComments,
      shipmentAddressState,
      shipmentAddressCity
    ]
  );

  const getItemsByPaymentMethod: (orderItem: OrderItemType, percentil: number, prices: AnyObject | undefined) => OrderItemType = (orderItem: OrderItemType, percentil: number, prices: AnyObject | undefined) => {
    const newQuantity = Math.round((orderItem.quantity * percentil) / 100);
    const newOrderItem: OrderItemType = {
      ...orderItem,
      quantity: newQuantity,
      unit_price: prices?.price?.toString() || orderItem.unit_price,
      subtotal: ((newQuantity || 1) * Number(prices?.price || orderItem.unit_price)).toString()
    };
    return newOrderItem;
  };

  const reloadPrices = useCallback(
    async (methods = []) => {
      setMethodsProducts([]);
      orderItems.map(async (item: OrderItemType) => {
        if (item.quantity < methods?.length) {
          enqueueSnackbar(t('Quantity od each product should be more or equal than Payments Methods'), { variant: 'error' });
        } else {
          const body = {
            sku: item.sku,
            lists: [168, 170, 171],
            payment_methods: payment_method?.input?.value && payment_method?.input?.value?.map((paymentMethods: PaymentMethodsType) => paymentMethods.id),
            channel: 'offline'
          };
          let prices: AnyObject[] = [];
          try {
            setReloadPrice(true);
            const response = await fetch(`${API_URL}/${GET_PRICE_LIST_B2B}`, {
              method: 'POST',
              body: JSON.stringify(body),
              headers: { 'Content-Type': 'application/json' }
            });
            const json = await response.json();
            prices = json?.result?.message?.prices || [];
          } catch {
            enqueueSnackbar(t('Something wrong with Odoo'), { variant: 'error' });
          } finally {
            setReloadPrice(false);
          }
          methods?.map((paymentMethod: AnyObject) => {
            setMethodsProducts((prev: Array<any>) => {
              const paymentMethodIndex = prev.findIndex((orderItem: AnyObject) => orderItem.payment_method_id === paymentMethod.id);
              const newState = cloneDeep(prev);
              if (paymentMethodIndex >= 0) {
                newState[paymentMethodIndex] = {
                  ...newState[paymentMethodIndex],
                  payment_method_id: paymentMethod.id,
                  payment_method_name: `${paymentMethod.name} ${paymentMethod.plazo}`,
                  payment_method_quantity: paymentMethod.percentil,
                  ...(paymentMethod.custom && !Number.isNaN(+paymentMethod.custom) && { custom: paymentMethod.custom }),
                  product_list: [
                    ...newState[paymentMethodIndex].product_list,
                    getItemsByPaymentMethod(
                      item,
                      paymentMethod.percentil,
                      prices?.find((priceItem: AnyObject) => Number(priceItem.payment_method) === Number(paymentMethod.id))
                    )
                  ]
                };
              } else {
                const methodsProductsObj = {
                  payment_method_id: paymentMethod.id,
                  payment_method_name: `${paymentMethod.name} ${paymentMethod.plazo}`,
                  payment_method_quantity: paymentMethod.percentil,
                  ...(paymentMethod.custom && !Number.isNaN(+paymentMethod.custom) && { custom: paymentMethod.custom }),
                  product_list: [
                    getItemsByPaymentMethod(
                      item,
                      paymentMethod.percentil,
                      prices?.find((priceItem: AnyObject) => Number(priceItem.payment_method) === Number(paymentMethod.id))
                    )
                  ]
                };
                newState.push(methodsProductsObj);
              }
              return newState;
            });
            return paymentMethod;
          });
        }
      });
    },
    [payment_method, orderItems, enqueueSnackbar, t, setMethodsProducts]
  );

  return (
    <BudgetFormContext.Provider value={budgetFormContextValue}>
      <ScreenAligned title={title} additionalTitle={null} showData={!!budgetUsers}>
        <form className={classes.root} noValidate autoComplete="off" onSubmit={handleSubmit}>
          <ClientInformation />

          <BillingAddress />

          <AdvanceSale dateField={advance_sale_date} checkField={advance_sale} customErrors={customErrors} />

          <Shipment />

          <ProductSearch onProductAdded={(e: any) => addItem(e as Product)} items={orderItemsRow} removeItem={removeItem} editItem={editItem} isEdit={!!budgetEditProps?.previousOrderData && !isCopy} />

          <PaymentMethod handleChangePaymentMethod={handleChangePaymentMethod} reloadPrices={reloadPrices} isEdit={!!budgetEditProps?.previousOrderData && !isCopy} />

          <Grid item xs={12}>
            <Grid container spacing={3} justify="flex-end">
              <Grid item xs={4}>
                {budgetEditProps?.previousOrderData && !isCopy && (
                  <Paper className={classes.paper}>
                    <Grid container spacing={3} justify="space-between">
                      <Grid item xs={4}>
                        Subtotal:
                      </Grid>
                      <Grid item xs={4}>
                        {formatMoney(`${orderItemsSubTotal}`)}
                      </Grid>
                    </Grid>
                  </Paper>
                )}
              </Grid>
            </Grid>
            <LoadingData working={reloadPrice || saving} />
            <Grid item xs={12} style={{ marginTop: 23 }}>
              <div className={classes.container}>
                <Button color="primary" type="button" onClick={() => onBudgetBack()} style={{ marginRight: 23 }}>
                  {t('Cancel')}
                </Button>
                {(!budgetEditProps?.previousOrderData || isCopy) && (
                  <Button
                    color="primary"
                    type="button"
                    onClick={() => {
                      PreviewNewBudgetDialogModal.show({
                        title: 'Budget Preview',
                        methodsProducts,
                        setMethodsProducts
                      });
                    }}
                    style={{ marginRight: 23 }}
                    disabled={reloadPrice || methodsProducts?.length === 0}
                  >
                    {t('Preview')}
                  </Button>
                )}
                <Button color="primary" type="submit" variant="contained" disabled={saving || !orderItems?.length || (budgetEditProps?.previousOrderData && !isCopy ? false : methodsProducts?.length === 0) || reloadPrice}>
                  {saving ? `${t('Processing')}...` : isCopy ? t('Copy') : t('Save')}
                </Button>
              </div>
            </Grid>
          </Grid>
        </form>
      </ScreenAligned>
    </BudgetFormContext.Provider>
  );
};
