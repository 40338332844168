import { makeBusinessEntity } from '../../../lib/libby/generator/makeBusinessEntity';
import { AvailableMLPeriod } from '../../../interfaces/business';

const DAO_NAME = 'available_ml_periods';

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch }
} = makeBusinessEntity<AvailableMLPeriod>(DAO_NAME);

// hooks
export const useAvailableMLPeriodsLiquidationFetchById = fetchById;
export const useAvailableMLPeriodsLibbyCall = libbyCall;
export const useAvailableMLPeriodsDAO = libbyDAO;
export const useAvailableMLPeriodsLibbyFetch = libbyFetch;
