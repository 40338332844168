import { Order } from '../../../interfaces/business';
import { makeBusinessEntity } from '../../../lib/libby/generator/makeBusinessEntity';

const DAO_NAME = 'ster_order_liberate';

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch }
} = makeBusinessEntity<Order>(DAO_NAME);

// hooks
export const useOrderLiberateFetchById = fetchById;
export const useOrderLiberateLibbyCall = libbyCall;
export const useOrderLiberateDAO = libbyDAO;
export const useOrderLiberateLibbyFetch = libbyFetch;
