import React, { useCallback, useEffect } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { ThemeProvider, makeStyles } from '@material-ui/styles';
import Button from '@material-ui/core/Button';
import { useForm, FormConfig, useField } from 'react-final-form-hooks';
import Typography from '@material-ui/core/Typography';
import { ValidationErrors } from 'final-form';
import { useTranslation } from '../../../services/translation';
import { makeGetErrorAndHelperText } from '../../../utils/materialHelpers';
import { MessageConfigForm } from './MessageConfigForm';
import { onConfirmType } from '../types/types';

const useStyles = makeStyles({
  root: {
    '& .MuiDialog-paperWidthSm': {
      width: '500px',
      maxWidth: '450px'
    }
  },
  title: {
    overflow: 'visible',
    '& > h3': {
      fontFamily: '"Oswald"'
    }
  }
});

const dummyFunc = () => {};

interface FormDialogType extends FormConfig<any> {
  open: boolean;
  onCancel: any;
  onConfirm: Function;
  title: string;
  cancelText: string;
  confirmText: string;
  customTheme: string | null;
  validate: (values: any) => ValidationErrors | Promise<ValidationErrors> | undefined;
  formInitialValues: { [name: string]: string };
  isValidNewMessage: boolean;
}

export const FormDialog = ({
  open = false,
  onCancel = dummyFunc,
  onConfirm = dummyFunc,
  title = 'Confirmation Dialog',
  cancelText = 'Cancel',
  confirmText = 'Save',
  customTheme = null,
  validate,
  formInitialValues = {},
  isValidNewMessage
}: FormDialogType) => {
  const { t } = useTranslation();
  const getErrorAndHelperText = makeGetErrorAndHelperText(t);
  const classes = useStyles();

  const onSubmit = useCallback(
    async ({ orderState, marketplace, template, documentType, send_to, send_to_client }: onConfirmType) => {
      onConfirm({
        orderState,
        marketplace,
        template,
        documentType,
        sendTo: send_to,
        sendToClient: send_to_client
      });
    },
    [onConfirm]
  );

  const { form, handleSubmit, submitError } = useForm({
    initialValues: formInitialValues,
    onSubmit,
    validate
  });

  const marketplace = useField('marketplace', form);
  const orderState = useField('orderState', form);
  const template = useField('template', form);
  const documentType = useField('documentType', form);
  const send_to_client = useField('send_to_client', form);
  const send_to = useField('send_to', form);

  const handleReset = useCallback(() => {
    form.reset();
    form.resetFieldState('marketplace');
    form.resetFieldState('orderState');
    form.resetFieldState('template');
    form.resetFieldState('documentType');
    form.resetFieldState('send_to_client');
    form.resetFieldState('send_to');
  }, [form]);

  useEffect(() => {
    handleReset();
  }, [isValidNewMessage, handleReset]);

  const errorMarketplace = getErrorAndHelperText(marketplace);
  const errorOrderState = getErrorAndHelperText(orderState);
  const errorTemplate = getErrorAndHelperText(template);
  const errorDocumentType = getErrorAndHelperText(documentType);

  const dialog = (
    <Dialog className={classes.root} open={open} onClose={onCancel} aria-labelledby="form-dialog-title">
      <DialogContent className={classes.title}>
        <Typography variant="h3">{t(title)}</Typography>
      </DialogContent>
      <form noValidate onSubmit={handleSubmit}>
        <DialogContent>
          <MessageConfigForm
            marketplace={marketplace}
            orderState={orderState}
            template={template}
            documentType={documentType}
            send_to_client={send_to_client}
            send_to={send_to}
            errorMarketplace={errorMarketplace}
            errorOrderState={errorOrderState}
            errorTemplate={errorTemplate}
            errorDocumentType={errorDocumentType}
          />
          {submitError && (
            <Typography style={{ display: 'block' }} variant="overline" color="error">
              {t(submitError)}
            </Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            onClick={() => {
              onCancel();
              handleReset();
            }}
          >
            {cancelText}
          </Button>
          <Button color="primary" type="submit">
            {confirmText}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );

  return !customTheme ? dialog : <ThemeProvider theme={customTheme}>{dialog}</ThemeProvider>;
};
