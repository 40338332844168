import React, { useState, useEffect, useCallback } from 'react';
import SearchIcon from '@material-ui/icons/Search';
import { Chip, Grid, TextField, Button, CircularProgress } from '@material-ui/core';
import moment, { Moment } from 'moment';
import { DatabaseConnector } from '@phinxlab/libby-rest-web';
import { Promise } from 'bluebird';
import { useSnackbar } from 'notistack';
import { makeFilter } from '../utils';
import { FilterBar } from '../../../../../../components/FilterBar';
import { DateClose } from '../../../../../../components/DateClose';
import { gridBreakpoints } from '../../../../../../const/gridBreakpoints';
import { useTranslation } from '../../../../../../services/translation';
import { SelectAllMarketPlace } from '../../../../../components/AllMarketplace';
import { Marketplace } from '../interfaces';
import { ChipStyle, RefreshButton } from './styles';
import { ScreenAligned } from '../../../../../../components/ScreenAligned/ScreenAligned';
import InfoTable from '../../../../../components/InfoTable';
import { columns } from '../utils/columns';
import LoadingData from '../../../../../components/LoadingData';
import { useDebounceState } from '../../../../../../hooks';
import { useReportingDeliverOrders } from '../hook';
import { LibbyObject } from '../../../../../../types';
import { ORDER_STATE } from '../../../../../../const';

type ReportingDeliverOrdersListType = {
  libby: LibbyObject;
};

const ReportingDeliverOrdersListRaw = ({ libby }: ReportingDeliverOrdersListType) => {
  const { title, working, rows, fetchMore, orderBy, direction, handleRequestSort: onSortChange, filter, setFilter, ordersToUpdate, reFetch } = useReportingDeliverOrders();

  const { t } = useTranslation();

  const initialDate = null;

  const [marketplace, setMarketplace] = useState<Marketplace[]>([]);

  const [search, setSearch] = useState<string>('');

  const [startDate, setStartDate] = useState<Moment | null>(filter?.startDate?.length > 0 ? moment(filter.startDate[0].value) : initialDate);

  const [endDate, setEndDate] = useState<Moment | null>(filter?.endDate?.length > 0 ? moment(filter.endDate[0].value) : initialDate);

  const { enqueueSnackbar } = useSnackbar();

  const [filters, setFilters] = useDebounceState(
    {
      search,
      endDate,
      startDate,
      marketplaces: marketplace
    },
    500
  );

  useEffect(() => {
    setFilter(makeFilter({ ...filters }));
  }, [setFilter, filters]);

  const handleReset = useCallback(() => {
    setSearch('');
    setStartDate(initialDate);
    setEndDate(initialDate);
    setMarketplace([]);
    setFilter(makeFilter({}));
  }, [setFilter]);

  const handleApplyFilters = () =>
    setFilter(
      makeFilter({
        search,
        endDate,
        startDate,
        marketplaces: marketplace
      })
    );

  const handleChangeSearch = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setSearch(event.target.value);
    setFilters({
      ...filters,
      search: event.target.value
    });
  };

  const handleDeleteMarketplace = (name: string) => {
    setMarketplace(marketplace.filter((market) => market.value !== name));
  };

  const handleChangeSelect = (market: any) => !marketplace.find((m) => m.value === market[0].name) && setMarketplace([...marketplace, { value: market[0].name, path: 'marketplace', method: 'includes' }]);

  const handleUpdateOrders = async () => {
    await Promise.map(ordersToUpdate, (order_id: string) =>
      libby.ster_order_table.aspect('order_state').save({
        order_id,
        state: {
          order_state_id: ORDER_STATE.DELIVERED
        }
      })
    );

    reFetch();
    enqueueSnackbar(ordersToUpdate.length > 1 ? 'Ordenes actualizadas' : 'Orden actualizada', {
      variant: 'success'
    });
  };

  return (
    <ScreenAligned
      title={title}
      additionalTitle={
        <>
          <Button disabled={!ordersToUpdate.length} variant="outlined" color="primary" style={RefreshButton} onClick={handleUpdateOrders}>
            {t('Deliver')}
          </Button>

          <Button variant="contained" color="primary" onClick={handleApplyFilters} style={RefreshButton} disabled={working}>
            {working ? <CircularProgress color="primary" size={20} /> : t('Refresh')}
          </Button>
        </>
      }
    >
      <FilterBar
        defaultFilter={
          <Grid item {...gridBreakpoints}>
            <TextField
              id="standard-search"
              placeholder={t('Search')}
              type="search"
              value={search}
              onChange={handleChangeSearch}
              fullWidth
              InputLabelProps={{
                shrink: true
              }}
              InputProps={{
                endAdornment: <SearchIcon />
              }}
            />
          </Grid>
        }
        handleReset={handleReset}
      >
        <Grid item {...gridBreakpoints}>
          <DateClose name={t('Last update since')} value={startDate} onChange={setStartDate} />
        </Grid>

        <Grid item {...gridBreakpoints}>
          <DateClose name={t('Last update until')} value={endDate} onChange={setEndDate} />
        </Grid>

        <Grid item {...gridBreakpoints}>
          <SelectAllMarketPlace
            onChange={handleChangeSelect}
            inputProps={marketplace.map((market: any) => (
              <Chip key={market.value} variant="outlined" label={market.value} onDelete={() => handleDeleteMarketplace(market.value)} style={ChipStyle} />
            ))}
          />
        </Grid>
      </FilterBar>

      <InfoTable columns={columns} rows={rows} onBottomScroll={fetchMore} orderBy={orderBy} direction={direction} onSortChange={onSortChange} />

      <LoadingData label={`${t('Loaded registers')}`} working={working} cant={rows.length} />
    </ScreenAligned>
  );
};

export const ReportingDeliverOrdersList = DatabaseConnector(ReportingDeliverOrdersListRaw)('admin_account', 'ster_order_table');
