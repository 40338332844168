import React from 'react';
import { DatabaseConnector } from '@phinxlab/libby-rest-web';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { LibbyObject } from '../../types';
import { CouriersList } from './routes';
import { useCourierLogic, useCourierMode } from './hook';
import { Courier } from '../../interfaces/business';

const CouriersRaw = ({ libby }: { libby: LibbyObject }) => {
  const match = useRouteMatch();
  const { data, working, fetchMore, handleRequestSort, orderBy, direction, onCreateCourier } = useCourierLogic({
    libby
  });
  const { title, screenProps, rows, columns } = useCourierMode({
    data: data as Courier[],
    onCreateCourier
  });
  return (
    <Switch>
      <Route path={`${match.path}`}>
        <CouriersList title={title} rows={rows} columns={columns} working={working} fetchMore={fetchMore} onSortChange={handleRequestSort} orderBy={orderBy} direction={direction} additionalTitle={screenProps.aditionalTitle} />
      </Route>
    </Switch>
  );
};

export const Couriers = DatabaseConnector(CouriersRaw)('courier_order', 'ster_product');
