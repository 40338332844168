import React, { useCallback } from 'react';
import { Box, FormControlLabel, Switch, TextField, Typography, Accordion, AccordionSummary, AccordionDetails } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { makeStyles } from '@material-ui/styles';
import { FormApi } from 'final-form';
import { textFieldProps } from '../../../utils/materialHelpers';
import { useTranslation } from '../../../services/translation';
import { MarketplacesForm } from '../../../interfaces/business';
import { isNumber } from 'lodash';

const useStyles = makeStyles({
  error: {
    fontSize: '12px'
  },
  inputs: {
    marginTop: '5px',
    width: '48%'
  },
  switches: {
    width: '100%'
  }
});

interface AccordionMarketProps {
  title: string;
  form: FormApi;
  values: MarketplacesForm;
  odooStock: number;
  index: number;
}

export const AccordionMarket = ({ title, form, values, odooStock, index }: AccordionMarketProps) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const handleChangeCheckSync = useCallback(() => {
    if (!values.stock_sync) form.change(`marketplaces[${index}].stock`, odooStock);
    form.change(`marketplaces[${index}].stock_sync`, !values.stock_sync);
  }, [form, index, values.stock_sync, odooStock]);

  const handleChangeCheckPublishedStock = useCallback(() => {
    if (values.published_stock_active) form.change(`marketplaces[${index}].published_stock`, null);
    form.change(`marketplaces[${index}].published_stock_active`, !values.published_stock_active);
  }, [form, index, values.published_stock_active]);

  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography variant="body1">{`${t(title)}`}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Box display="flex" flexDirection="column" width="100%">
          <Box display="flex" justifyContent="space-between">
            <TextField
              {...textFieldProps(`${t('Stock')}`)}
              onChange={(e) => form.change(`marketplaces[${index}].stock`, Number(e.target.value))}
              value={values.stock}
              required={values.stock !== null}
              variant="standard"
              type="number"
              InputLabelProps={{
                shrink: true
              }}
              InputProps={{ inputProps: { min: 0 } }}
              className={classes.inputs}
              disabled={!!values.stock_sync}
              onKeyDown={(event) => {
                if (event?.key === '-' || event?.key === '+' || event?.key === '.') {
                  event.preventDefault();
                }
              }}
            />
            <Box width="48%">
              <FormControlLabel
                value="Sync"
                control={<Switch color="primary" checked={values.stock_sync} onChange={handleChangeCheckSync} value={values.stock_sync} />}
                label={t('Sync with Odoo')}
                labelPlacement="top"
                className={classes.switches}
              />
            </Box>
          </Box>
          <Box display="flex" justifyContent="space-between">
            <TextField
              {...textFieldProps(`${t('Stock to Publish')}`)}
              onChange={(e) => form.change(`marketplaces[${index}].published_stock`, Number(e.target.value))}
              value={isNumber(values.published_stock) ? values.published_stock : values.stock}
              required={values.published_stock_active}
              variant="standard"
              type="number"
              InputLabelProps={{
                shrink: true
              }}
              InputProps={{ inputProps: { min: 0 } }}
              className={classes.inputs}
              disabled={!values.published_stock_active}
              onKeyDown={(event) => {
                if (event?.key === '-' || event?.key === '+' || event?.key === '.') {
                  event.preventDefault();
                }
              }}
            />

            <Box width="48%">
              <FormControlLabel
                value="Custom Stock in Publication"
                control={<Switch color="primary" checked={values.published_stock_active} onChange={handleChangeCheckPublishedStock} value={values.published_stock_active} />}
                label={t('Custom Stock in Publication')}
                labelPlacement="top"
                className={classes.switches}
              />
            </Box>
          </Box>
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};
