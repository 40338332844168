import React, { ReactNode } from 'react';
import { TextField } from '@material-ui/core';
import { Theme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/styles';
import Grid, { GridProps } from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import { LabelValueTypography } from './LabelValueTypography';

export type GridDirection = 'row' | 'row-reverse' | 'column' | 'column-reverse';

const useStyles = makeStyles((theme: Theme) => ({
  title: {
    textAlign: 'left',
    marginBottom: 15,
    whiteSpace: 'nowrap'
  },
  description: {
    overflowWrap: 'anywhere'
  },
  child: {
    '& div': {
      width: '100%'
    }
  },
  disabled: {
    color: theme.palette.secondary.main
  }
}));

type LabelValueProps = {
  label: string;
  direction?: GridDirection;
  children: NonNullable<ReactNode>;
  container?: boolean;
  stylesChildrenContainer?: GridProps;
  link?: string | undefined;
};

export const LabelValue = ({ label, direction = 'row', children, container = true, stylesChildrenContainer, link }: LabelValueProps) => {
  const classes = useStyles();
  const show = <LabelValueTypography name={`${children}`} className={classes.description} />;
  return (
    <Grid
      {...(container && {
        container: true,
        direction,
        ...(direction === 'row' && {
          wrap: 'nowrap'
        })
      })}
      {...stylesChildrenContainer}
      className={classes.child}
    >
      {direction === 'column' ? (
        <TextField
          InputProps={{
            disableUnderline: true,
            readOnly: true,
            disabled: true,
            classes
          }}
          label={`${label}`}
          value={`${children}`}
        />
      ) : (
        <>
          <LabelValueTypography name={`${label}:`} className={classes.title} label />
          <Box ml={container ? 0.5 : 0}>
            {link?.length ? (
              <a href={link} target="_blank" rel="noopener noreferrer">
                {show}
              </a>
            ) : (
              show
            )}
          </Box>
        </>
      )}
    </Grid>
  );
};
