import React from 'react';
import { useTranslation } from '../../../../../services/translation';
import LoadingData from '../../../../components/LoadingData';
import { AnyObject } from '../../../../../types/types';
import InfoTable from '../../../../components/InfoTable';
import { Typography } from '@material-ui/core';
import { Box } from '@material-ui/core';
import { ButtonDetailsType } from 'types/Button';
import { ButtonComponent } from 'components/Button';

type RowsType = {
  id: string;
  [k: string]: any;
};

type HeadCellsType = {
  id: string;
  label: string;
  translate?: boolean;
  render?: (row: any, item: any, t: (str: string) => string) => React.ReactNode;
  [k: string]: any;
};

type OrderTableProps = {
  setFilter?: (makeFilter: object) => void;
  filter?: AnyObject;
  working?: boolean;
  rows: Array<RowsType>;
  columns: Array<HeadCellsType>;
  fetchMore?: () => void;
  reFetch?: () => void;
  onSortChange?: (orderBy: string, direction: 'asc' | 'desc') => void;
  orderBy?: string;
  direction?: 'asc' | 'desc';
  onRowClick?: (row: RowsType) => void;
  canalOffLine?: boolean;
  resetFilter?: () => void;
  buttons: ButtonDetailsType[];
};

export const FeaturesTable = ({ columns, rows, working, fetchMore, onSortChange, orderBy, direction, onRowClick = () => {}, buttons }: OrderTableProps) => {
  const { t } = useTranslation();
  /*   columns = columns.filter((column) => column.id !== 'business');
  useEffect(() => {
    if (reFetch) reFetch();
  }, [reFetch]); */

  return (
    <>
      <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', textAlign: 'center' }}>
        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 10 }}>
          {buttons.map((dataButton: ButtonDetailsType) => (
            <ButtonComponent
              key={dataButton.id}
              title={dataButton.title}
              className={dataButton.className}
              variant={dataButton.variant}
              color={dataButton.color}
              onClick={dataButton.onClick}
              type={dataButton.type}
              disabled={dataButton.disabled}
              loading={dataButton.loading}
            />
          ))}
          <Typography variant="h4" style={{ fontWeight: 'bold', textAlign: 'center', whiteSpace: 'nowrap' }}>
            {t('Feature Table')}
          </Typography>
        </Box>
        <InfoTable columns={columns} rows={rows} onBottomScroll={fetchMore} rowIdKey="order_id" onRowClick={onRowClick} onSortChange={onSortChange} orderBy={orderBy} direction={direction} />

        <LoadingData label={`${t('Loaded registers')}`} working={working} cant={rows.length} />
      </Box>
    </>
  );
};
