import { LibbyFetchDAO } from './LibbyFetchDAO';
import Moment from 'moment';
import { FilterBarSelection } from 'routes/Reporting/routes/ReportingList/FilterBar';

export class ChargebackReportCityDAO extends LibbyFetchDAO {
  constructor() {
    super('ster_chargeback_report_city', 'city_id');
  }

  getAll() {
    return this.query().limit(0, 40).run();
  }

  async getByDate(filter: FilterBarSelection) {
    let ls = this.query();
    // FIXME QUERY BUILDER HAVE TO DECIDE IF HAVE TO AUTOMATICALLY INSERT AN AND
    let needsAnd: boolean = false;

    if (filter.from && filter.to) {
      if (needsAnd) ls = ls.and();
      ls = ls.groupStart().higherOrEquals('created_at', Moment(filter.from).format('YYYY-M-D HH:mm:ss')).lowerOrEquals('created_at', Moment(filter.to).format('YYYY-M-D HH:mm:ss')).groupEnd();
      needsAnd = true;
    }
    /*     ls.limit(0, 40).run(); */
    ls.limit(0, 1000).run();
    return ls;
  }
}
