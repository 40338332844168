const novatech = {
  company_id: 1,
  order_source_id: 14,
  name: 'Novatech',
  id_lista: 22
};

const leanval = {
  company_id: 2,
  order_source_id: 18,
  name: 'Leanval',
  id_lista: 95
};

const athuel = {
  company_id: 3,
  order_source_id: 20,
  name: 'Athuel',
  id_lista: 96
};

export const companyMarketplace = [novatech, leanval, athuel];
