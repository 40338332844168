import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Box } from '@material-ui/core';
import { useTranslation } from '../../../../../services/translation';
import { useStyles as useStylesAccount } from '../styles';
import { Account } from '../../../../../interfaces/business';

const useStyles = makeStyles(() => ({
  messageError: {
    display: 'block'
  }
}));

type AccountErrorMessagesProps = {
  duplicateAccount: boolean;
  submitError: string;
  account: Account | null;
};

export const AccountErrorMessages = ({ account, duplicateAccount, submitError }: AccountErrorMessagesProps) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const classesAccountInput = useStylesAccount();

  return (
    <div>
      {duplicateAccount && (
        <>
          <Typography className={classes.messageError} color="error">
            {t('This user name is already created')}
          </Typography>
          <Box flexDirection="row" display="flex">
            <Typography variant="h6" color="textPrimary" className={classesAccountInput.errorTitle}>
              {`${t('Associated email')}: `}
            </Typography>
            <Box ml={1}>
              <Typography variant="h6" color="primary" className={classesAccountInput.errorTitle}>
                {account?.email}
              </Typography>
            </Box>
          </Box>
        </>
      )}
      {submitError && (
        <Typography className={classes.messageError} color="error">
          {t(submitError)}
        </Typography>
      )}
    </div>
  );
};
