import { GoalPeriod } from '../../interfaces/business/goal/GoalPeriod';
import { makeBusinessEntity } from '../../lib/libby/generator/makeBusinessEntity';

const DAO_NAME = 'ster_goal_period_update';

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch }
} = makeBusinessEntity<GoalPeriod>(DAO_NAME);

// hooks

export const useGoalPeriodUpdateFetchById = fetchById;
export const useGoalPeriodUpdateLibbyCall = libbyCall;
export const useGoalPeriodUpdateDAO = libbyDAO;
export const useGoalPeriodUpdateLibbyFetch = libbyFetch;
