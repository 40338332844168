import { capitalize } from 'lodash';
import { OrderType } from '../../../types';

export const columns = [
  {
    id: 'order_id',
    label: 'Order ID',
    style: {
      minWidth: '115px'
    },
    orderById: 'order_id'
  },
  {
    id: 'so_number',
    label: 'SO Number',
    style: {
      minWidth: '125px'
    },
    noSort: true
  },
  {
    id: 'state',
    label: 'State',
    style: {
      minWidth: '140px'
    },
    noSort: true,
    render: ({ state }: OrderType, item: any, t: (str: string) => string) => t(capitalize(state ?? ''))
  },
  {
    id: 'updated_at',
    label: 'Last change',
    style: {
      minWidth: '150px'
    },
    orderById: 'updated_at'
  },
  {
    id: 'created_at',
    label: 'Created at',
    style: {
      minWidth: '140px'
    },
    orderById: 'created_at'
  },
  {
    id: 'document_date',
    label: 'Document Date',
    style: {
      minWidth: '160px'
    },
    orderById: 'od.created_at'
  },
  {
    id: 'document_number',
    label: 'Order Documents',
    style: {
      minWidth: '150px'
    },
    noSort: true
  },
  {
    id: 'name',
    label: 'Buyer Name',
    style: {
      minWidth: '150px'
    },
    noSort: true
  },
  {
    id: 'buyer_document',
    label: 'Buyer Document',
    style: {
      minWidth: '150px'
    },
    noSort: true
  },
  {
    id: 'sku',
    label: 'Skus',
    style: {
      minWidth: '180px'
    },
    noSort: true
  },
  {
    id: 'description',
    label: 'Product Name',
    style: {
      minWidth: '400px'
    },
    noSort: true
  },
  {
    id: 'total',
    label: 'Total Price',
    style: {
      minWidth: '110px'
    },
    noSort: 'total'
  },
  {
    id: 'marketplace',
    label: 'Marketplace',
    style: {
      minWidth: '140px'
    },
    noSort: true
  }
];
