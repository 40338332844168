import { Orderb2b } from '../../../interfaces/business/orders/Order_b2b';
import { makeBusinessEntity } from '../../../lib/libby/generator/makeBusinessEntity';

const DAO_NAME = 'ster_order_b2b';

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch }
} = makeBusinessEntity<Orderb2b>(DAO_NAME);

// hooks
export const useOrderB2BFetchById = fetchById;
export const useOrderB2BLibbyCall = libbyCall;
export const useOrderB2BDAO = libbyDAO;
export const useOrderB2BLibbyFetch = libbyFetch;
