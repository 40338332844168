import React, { useCallback, useMemo } from 'react';
import { Autocomplete } from '@material-ui/lab';
import { TextField } from '@material-ui/core';
import { useGoalRangeTypeLibbyFetch } from '../../business';
import { GoalRangeType } from '../../interfaces/business';
import { DataGenerateSelectType } from '../../utils/generateSelect';
import { AnyObject } from '../../types';
import { GeneralSelect } from '../GeneralSelect';
import { useTranslation } from '../../services/translation';

type SelectGoalRangeTypeProps = {
  multiple?: boolean;
  inputProps: AnyObject;
  optionAll?: boolean;
  [k: string]: any;
  disabled?: boolean;
  sort?: boolean | 'asc' | 'desc';
  label?: string;
  filterGoalRangeType?: number[];
};

export const SelectGoalRangeType = ({ multiple = false, inputProps = {}, optionAll = false, disabled = false, sort, label, filterGoalRangeType, ...styles }: SelectGoalRangeTypeProps) => {
  const { t } = useTranslation();
  const { data: goalRangeTypes, working } = useGoalRangeTypeLibbyFetch();

  const data = useMemo(() => {
    const dataRangeTypes = goalRangeTypes as GoalRangeType[];

    return dataRangeTypes?.length
      ? dataRangeTypes
          .map(({ goal_range_type_id, name }: GoalRangeType) => ({
            id: goal_range_type_id,
            value: name
          }))
          .filter((item) => (filterGoalRangeType ? filterGoalRangeType.includes(+item.id) : item))
      : [];
  }, [goalRangeTypes, filterGoalRangeType]);

  const onChangeAutoComplete = useCallback(
    (event, newValue: DataGenerateSelectType[]) => {
      inputProps.onChange(
        newValue.map((value: DataGenerateSelectType) => ({
          value: value.id,
          path: ''
        }))
      );
    },
    [inputProps]
  );

  return (
    <>
      {multiple ? (
        <Autocomplete
          disabled={disabled}
          style={{ display: 'inline-block', width: '100%' }}
          multiple
          id="tags-outlined"
          options={data}
          getOptionLabel={(option: { id: string; value: string }) => option.value}
          filterSelectedOptions
          onChange={onChangeAutoComplete}
          renderInput={(params) => <TextField {...params} variant="standard" label={t('Range type')} placeholder="" />}
        />
      ) : (
        <GeneralSelect disabled={disabled} working={working} label="Range type" optionAll={optionAll} data={data} inputProps={inputProps} {...styles} sort={sort} />
      )}
    </>
  );
};
