import { makeBusinessEntity } from '../../lib/libby/generator/makeBusinessEntity';
import { GoalRangeType } from '../../interfaces/business';

const DAO_NAME = 'ster_goal_range_type';

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch }
} = makeBusinessEntity<GoalRangeType>(DAO_NAME);

// hooks
export const useGoalRangeTypeFetchById = fetchById;
export const useGoalRangeTypeLibbyCall = libbyCall;
export const useGoalRangeTypeDAO = libbyDAO;
export const useGoalRangeTypeLibbyFetch = libbyFetch;
