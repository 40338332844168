import React, { useCallback, useMemo, MouseEvent } from 'react';
import { useSnackbar } from 'notistack';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import { useTranslation } from '../../../../../services/translation';
import { useGoalLibbyFetch, useGoalUpdateDAO } from '../../../../../business/goal';
import { useBreadcrumbs, useFilerParamsId } from '../../../../../hooks';
import { Goal } from '../../../../../interfaces/business';
import { RowsType } from '../../../../../utils/tables';
import CustomModal from '../../../../../services/customFormDialog';

import { formReportingGoalInitialValues, validateReportingGoalAdd } from '../utils/modelReportingGoalSettingAdd';
import { MenuActions, MenuItemComponentType } from '../../../../../components/MenuActions';
import ConfirmDialog from '../../../../../components/ConfirmDialog';
import { AddGoalForm, CreateGoalType } from '../components/AddGoalForm';
import { ButtonDetailsType } from '../../../../../types/Button';
import { TitleBreadcrumbs } from '../../../../../interfaces';
import { validateReportingGoalEdit } from '../utils/modelReportingGoalSettingEdit';
import { EditGoalForm } from '../components/EditGoalForm';

const AddGoalModal = CustomModal(AddGoalForm);
const EditGoalModal = CustomModal(EditGoalForm);
const ConfirmModal = CustomModal(ConfirmDialog);
export const useReportingGoalSaleSettingLogic = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const match = useRouteMatch();

  const { orderBy, direction, paramsFetch, handleRequestSort, setFilter, filter } = useFilerParamsId({
    orderInit: 'name',
    daoName: 'ster_goal',
    directionInit: 'asc'
  });

  const { data: allData, working, updateData, removeData, addCreate, fetchMore } = useGoalLibbyFetch(paramsFetch);

  const { state } = useLocation<{ goBack: TitleBreadcrumbs[] }>();

  const title = useBreadcrumbs('Settings sales by goals');
  const titleShow = useBreadcrumbs('Settings sales by goals', state && state?.goBack?.length ? state.goBack : title);
  const goalDAO = useGoalUpdateDAO();

  const handleOnOpenAddGoal = useCallback(async () => {
    try {
      const goalDataForm = (await AddGoalModal.show({
        confirmText: t('Add'),
        cancelText: t('Cancel'),
        title: t('Add goal'),
        validate: validateReportingGoalAdd,
        formInitialValues: formReportingGoalInitialValues
      })) as CreateGoalType;

      if (goalDataForm) {
        const newGoal: Goal = await goalDAO.aspect('list_all_goal').save({
          goal_id: undefined,
          goal_type_value: { goal_type_value_id: goalDataForm.goal_type_value },
          name: goalDataForm.name,
          goal_type: { goal_type_id: goalDataForm.goal_type },
          goal_period: [
            {
              start_date: goalDataForm.start_date,
              end_date: goalDataForm.end_date,
              value: goalDataForm.value,
              goal_range_type_id: Number(goalDataForm.goal_range_type)
            }
          ],
          goal_marketplaces: goalDataForm.goal_marketplaces,
          goal_categories: goalDataForm.goal_categories
        });

        addCreate(newGoal);
        enqueueSnackbar(`${t('Goal $$$$ successfully aggregated').replace('$$$$', newGoal.goal_id)}`, { variant: 'success' });
      }
    } catch (error) {
      // nothing
    }
  }, [addCreate, enqueueSnackbar, goalDAO, t]);

  const handleOnOpenEditGoal = useCallback(
    async (dataGoal: Goal) => {
      try {
        const goalDataForm = (await EditGoalModal.show({
          confirmText: t('Update'),
          cancelText: t('Cancel'),
          title: t('Update goal'),
          validate: validateReportingGoalEdit,
          formInitialValues: {
            goal_type: dataGoal.goal_type.goal_type_id,
            name: dataGoal.name,
            goal_type_value: dataGoal.goal_type_value.goal_type_value_id,
            goal_marketplaces: dataGoal.goal_marketplace ? dataGoal?.goal_marketplace.map((marketplace) => ({ id: marketplace.marketplace.marketplace_id.toString(), value: marketplace.marketplace.name })) : '',
            goal_categories: dataGoal?.categories ? dataGoal?.categories.map((category) => ({ id: category.category_id.toString(), value: category.name })) : ''
          }
        })) as CreateGoalType;

        if (goalDataForm) {
          const updatedGoal: Goal = await goalDAO.aspect('list_all_goal').save({
            ...dataGoal,
            goal_id: dataGoal?.goal_id,
            goal_type_value: { goal_type_value_id: goalDataForm.goal_type_value },
            name: goalDataForm.name,
            goal_type: { goal_type_id: goalDataForm.goal_type },
            goal_marketplaces: goalDataForm.goal_marketplaces,
            goal_categories: goalDataForm.goal_categories
          });

          updateData(updatedGoal, 'goal_id');

          enqueueSnackbar(`${t('Goal $$$$ successfully modified').replace('$$$$', updatedGoal.goal_id)}`, { variant: 'success' });
        }
      } catch (error) {
        // nothing
      }
    },
    [t, goalDAO, enqueueSnackbar, updateData]
  );

  const handleCancelModal = useCallback(
    async (goal: Goal) => {
      try {
        const cancel = await ConfirmModal.show({
          title: t('Delete goal'),
          content: `${t('You are about to delete $$$$ goal, do you want to continue?')}`.replace('$$$$', String(goal.name)),
          confirmText: t('Confirm'),
          cancelText: t('Cancel')
        });
        if (cancel) {
          await goalDAO.remove({
            ...goal
          });
          removeData(goal.goal_id, 'goal_id');
          enqueueSnackbar(t('Goal deleted'), { variant: 'success' });
        }
      } catch (error) {
        if (error) {
          enqueueSnackbar(`${t('Failed to delete')}: ${error}`, {
            variant: 'info'
          });
        }
      }
    },
    [enqueueSnackbar, goalDAO, removeData, t]
  );

  const menuArray = useCallback(
    (dataGoal: Goal): MenuItemComponentType[] => {
      const menu: MenuItemComponentType[] = [
        {
          title: 'Edit',
          onClick: (e: MouseEvent) => {
            e.stopPropagation();
            handleOnOpenEditGoal(dataGoal);
          },
          disabled: !dataGoal.active
        },
        {
          title: 'Period',
          onClick: (e: MouseEvent) => {
            e.stopPropagation();

            history.push({
              pathname: `${match.path}/period/${dataGoal.goal_id}`,
              state: {
                goBack: titleShow
              }
            });
          },
          disabled: false
        },
        {
          title: 'Delete',
          onClick: (e: MouseEvent) => {
            e.stopPropagation();
            handleCancelModal(dataGoal);
          },
          disabled: !dataGoal.active,
          color: 'error'
        }
      ];
      return menu.filter((menuItems) => menuItems.disabled === false);
    },
    [handleCancelModal, handleOnOpenEditGoal, match.path, history, titleShow]
  );

  const rowsGoal: RowsType[] = useMemo(() => {
    const dataOrder = allData as Goal[];
    return dataOrder.map((goal: Goal) => {
      const {
        goal_id,

        goal_type: { name: goal_type_name },
        goal_type_value: { name: goal_type_value_name }
      } = goal;
      return {
        id: goal_id,
        ...goal,
        type: goal_type_name,
        goal_type_value_name,
        actions: <MenuActions menuArray={menuArray(goal)} />
      };
    });
  }, [allData, menuArray]);

  const buttons: ButtonDetailsType[] = useMemo(
    () => [
      {
        id: 'addGoal',
        onClick: () => handleOnOpenAddGoal(),
        title: t('Add goal'),
        variant: 'contained'
      }
    ],
    [handleOnOpenAddGoal, t]
  );

  return {
    working,
    direction,
    orderBy,
    handleRequestSort,
    setFilter,
    filter,
    rowsGoal,
    fetchMore,
    buttons,
    titleShow
  };
};
