import { LibbyFetchDAO } from './LibbyFetchDAO';

export class SterProcessMonitorAlertDAO extends LibbyFetchDAO {
  constructor() {
    super('ster_process_monitor_alert', 'ster_config_id');
  }

  getAll() {
    return this.query().limit(0, 10000).run();
  }
}
