import React, { useMemo, useState } from 'react';
import { AnyObject } from '../../types/types';
import { useTranslation } from '../../services/translation';
import { GoalDate } from '../../interfaces/business/goal/GoalDate';
import { Autocomplete } from '@material-ui/lab';
import { TextField } from '@material-ui/core';

type SelectGoalTypeProps = {
  inputProps: AnyObject;
  optionAll?: boolean;
  [k: string]: any;
  disabled?: boolean;
  sort?: boolean | 'asc' | 'desc';
  label?: string;
  data: GoalDate[];
  working: boolean;
};

export const SelectGoalQuarter = ({ inputProps = {}, label = 'Quarter', data, working }: SelectGoalTypeProps) => {
  const { t } = useTranslation();
  const [selectedValues, setSelectedValues] = useState<{ id: string; value: string }[]>([]);

  const dataFormatted = useMemo(() => {
    return data?.length
      ? data.map(({ name, goal_date_id }) => ({
          id: goal_date_id,
          value: t(name)
        }))
      : [];
  }, [data, t]);

  const isQuarterSelectable = (option: { id: string; value: string }) => {
    let validOption = false;
    if (!selectedValues.length) return true;

    selectedValues.forEach((selectedValue: any) => {
      const { value } = selectedValue;
      const [optionQuarterNumber, optionYear] = option.value.split('-').map(Number);
      const [selectedQuarterNumber, selectedYear] = value.split('-').map(Number);

      if (
        (selectedYear === optionYear && selectedQuarterNumber === optionQuarterNumber + 1) ||
        (selectedYear === optionYear && selectedQuarterNumber === optionQuarterNumber - 1) ||
        (selectedYear === optionYear - 1 && optionQuarterNumber === 1 && selectedQuarterNumber == 4) ||
        (selectedYear === optionYear + 1 && optionQuarterNumber === 4 && selectedQuarterNumber == 1)
      ) {
        validOption = true;
      }
    });

    return validOption;
  };

  return (
    <Autocomplete
      disabled={working || inputProps.disabled}
      style={{ display: 'inline-block', width: '100%' }}
      multiple
      options={dataFormatted}
      getOptionLabel={(option: { id: string; value: string }) => option.value}
      filterSelectedOptions
      getOptionSelected={(option: any, _value: any) => option.id === _value.id}
      value={selectedValues || []}
      onChange={(e, newValue) => {
        inputProps.onChange(newValue);
        setSelectedValues(newValue);
      }}
      getOptionDisabled={(option: any) => !isQuarterSelectable(option)}
      renderInput={(params) => <TextField {...params} variant="standard" label={t(label)} placeholder="" />}
    />
  );
};
