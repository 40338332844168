import React, { useMemo } from 'react';
import { useTranslation } from '../../services/translation';
import { AnyObject } from '../../types';
import { MenuItem, TextField } from '@material-ui/core';

type TextFieldVariant = 'filled' | 'standard' | 'outlined';
type MaxCustomOptions = [string, string];

interface SelectTwoOptionsProps {
  inputProps: AnyObject;
  optionAll?: boolean;
  variant?: TextFieldVariant;
  [k: string]: any;
  disabled?: boolean;
  sort?: boolean | 'asc' | 'desc';
  label: string;
  customOptions?: MaxCustomOptions;
  booleanValue?: boolean;
}

export const SelectTwoOptions = ({ inputProps = {}, optionAll = false, disabled = false, sort, label, variant = 'standard', customOptions, booleanValue, ...styles }: SelectTwoOptionsProps) => {
  const { t } = useTranslation();

  const optionsData = useMemo(
    () => [
      { id: 1, value: 'Yes' },
      { id: 2, value: 'No' }
    ],
    []
  );

  const options = useMemo(() => optionsData.map((option, index) => ({ ...option, value: t(customOptions ? customOptions[index] : option.value) })), [optionsData, t, customOptions]);

  const formatValue = booleanValue ? (inputProps.value === '0' ? inputProps.value : inputProps.value === true ? 1 : 2) : inputProps.value;

  return (
    <TextField
      variant={variant}
      onChange={inputProps.onChange}
      value={formatValue}
      disabled={disabled}
      label={t(label)}
      InputLabelProps={{
        shrink: true
      }}
      fullWidth
      select
      {...styles}
    >
      {optionAll && <MenuItem value="0">{t('All')}</MenuItem>}
      {options?.map(({ id, value }) => (
        <MenuItem value={String(id)} key={id}>
          {t(value)}
        </MenuItem>
      ))}
    </TextField>
  );
};
