import React, { useCallback, useEffect } from 'react';
import { useField, useForm, FormConfig } from 'react-final-form-hooks';
import { ValidationErrors } from 'final-form';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { Checkbox, FormControlLabel } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/styles';
import { SelectState } from './SelectState';
import { useTranslation } from '../../../../services/translation';
import { AnyObject } from '../../../../types/types';
import { makeGetErrorAndHelperText, textFieldProps } from '../../../../utils/materialHelpers';
import { replaceNonNumericCharactersComma, replaceNonNumericCharacters, replaceQuotationMarks } from '../../../../utils';

const dummyFunc = () => {};

interface ZipFormDialogType extends FormConfig<any> {
  open: boolean;
  onCancel: any;
  onConfirm: Function;
  title: string;
  content: React.ReactNode;
  labelContent: string;
  cancelText: string;
  confirmText: string;
  customTheme: string | null;
  inputType: string;
  validate: (values: any) => ValidationErrors | Promise<ValidationErrors> | undefined;
  formInitialValues: AnyObject;
}

export const ZipFormDialog = ({
  open = false,
  onCancel = dummyFunc,
  onConfirm = dummyFunc,
  title = 'Confirmation Dialog',
  cancelText = 'Cancel',
  confirmText = 'confirmText',
  customTheme = null,
  validate,
  formInitialValues = {}
}: ZipFormDialogType) => {
  const { t } = useTranslation();
  const getErrorAndHelperText = makeGetErrorAndHelperText(t);

  const onSubmit = useCallback(
    async ({ state_id, zip, list, name, matches, enabled }) => {
      onConfirm({
        state_id,
        zip,
        list,
        name,
        matches,
        enabled
      });
    },
    [onConfirm]
  );

  const { form, handleSubmit, submitError } = useForm({
    initialValues: formInitialValues,
    onSubmit,
    validate
  });

  const selectState = useField('state_id', form);
  const selectCity = useField('name', form);
  const inputZip = useField('zip', form);
  const inputZipList = useField('list', form);
  const inputMatches = useField('matches', form);
  const checkEnabled = useField('enabled', form);

  useEffect(() => {
    if (!open) {
      // execute after submit form
      setTimeout(() => form.reset(), 0);
    }
  }, [open, form]);

  const dialog = (
    <Dialog open={open} onClose={onCancel} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">{title}</DialogTitle>
      <form noValidate onSubmit={handleSubmit}>
        <DialogContent>
          {formInitialValues.city_id && <TextField autoFocus {...textFieldProps(t('Location ID'))} value={formInitialValues.city_id} disabled />}
          {open && (
            <SelectState
              inputProps={{
                ...selectState.input,
                ...getErrorAndHelperText(selectState)
              }}
            />
          )}
          <TextField
            autoFocus
            {...textFieldProps(t('Location'))}
            {...selectCity.input}
            {...getErrorAndHelperText(selectCity)}
            onChange={(event) => {
              const eventMatch = event;
              const arrayMatch = inputMatches.input.value.toString().split(',');
              event.target.value = replaceQuotationMarks(event.target.value).replace(/,/, '');
              arrayMatch[0] = event.target.value;
              selectCity.input.onChange(event);
              eventMatch.target.value = arrayMatch.toString();
              inputMatches.input.onChange(eventMatch);
              return event;
            }}
            type="text"
          />
          <TextField
            {...textFieldProps(t('Zip'))}
            {...inputZip.input}
            {...getErrorAndHelperText(inputZip)}
            onChange={(event) => {
              const { value } = event.target;
              event.target.value = replaceNonNumericCharacters(value);
              inputZip.input.onChange(event);
              return event;
            }}
            type="text"
          />
          <TextField
            {...textFieldProps(t('Zip List'))}
            {...inputZipList.input}
            {...getErrorAndHelperText(inputZipList)}
            onChange={(event) => {
              const { value } = event.target;
              event.target.value = replaceNonNumericCharactersComma(value);
              inputZipList.input.onChange(event);
              return event;
            }}
            type="text"
          />
          <TextField
            {...textFieldProps(`${t('Matches')} (${t('comma delimited')})`)}
            {...inputMatches.input}
            {...getErrorAndHelperText(inputMatches)}
            type="text"
            multiline
            disabled={selectCity.input.value.length === 0}
            placeholder={`${t('Example')}:\nadrogué,adrogue `}
            onChange={(event) => {
              const { value } = event.target;
              event.target.value = replaceQuotationMarks(value);
              inputMatches.input.onChange(event);
              const arrayMatches = value.split(',');
              if (arrayMatches.length && arrayMatches[0].toUpperCase() !== selectCity.input.value.toUpperCase()) {
                event.target.value = inputMatches.input.value;
              }
              inputMatches.input.onChange(event);
              return event;
            }}
          />

          <FormControlLabel control={<Checkbox color="primary" id={checkEnabled.input.name} checked={checkEnabled.input.value} {...checkEnabled.input} />} label={t('Enabled')} />

          {submitError && (
            <Typography style={{ display: 'block' }} variant="overline" color="error">
              {t(submitError)}
            </Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={onCancel}>{cancelText}</Button>
          <Button color="primary" type="submit">
            {confirmText}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );

  return !customTheme ? dialog : <ThemeProvider theme={customTheme}>{dialog}</ThemeProvider>;
};
