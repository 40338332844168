export const ORDER_DOCUMENT_TYPE = {
  PEDIDO: '1',
  ORDER_DE_VENTA: '2',
  REMITO: '3',
  INVOICE: '4',
  RECIBO: '5',
  PRODUCCION: '6',
  ETIQUETA: '7',
  B2B: '8',
  PRESUPUESTO: '10'
};
