import React, { FC, useCallback } from 'react';
import { Checkbox, FormControlLabel, Grid, Typography } from '@material-ui/core';
import { DatePicker } from '@material-ui/pickers';
import { DateRange } from '@material-ui/icons';
import { useStyles } from '../const';
import { useTranslation } from '../../../../../services/translation';
import { useBudgetContext } from '../../BudgetNewV2/context';
import { IAdvanceSale } from '../../BudgetNewV2/context/BudgetContext/types/IAdvanceSale';

export const AdvanceSale: FC<any> = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { advanceSale, setAdvanceSale } = useBudgetContext();

  const { isAdvanceSale, advanceSaleDate } = advanceSale;

  const handleAdvanceSale = useCallback(
    (e) => {
      setAdvanceSale((prev: IAdvanceSale) => ({
        ...prev,
        isAdvanceSale: e.target.checked
      }));
    },
    [setAdvanceSale]
  );

  const handleAdvanceSaleDate = useCallback(
    (e) => {
      setAdvanceSale((prev: IAdvanceSale) => ({
        ...prev,
        advanceSaleDate: e
      }));
    },
    [setAdvanceSale]
  );

  return (
    <Grid item container style={{ marginBottom: '10px', marginTop: '30px' }}>
      <Grid xs={12}>
        <Typography variant="h1" className={classes.title}>{`${t('Sale Type')}`}</Typography>
      </Grid>
      <Grid container style={{ maxWidth: '500px', marginTop: '10px' }} xs={11}>
        <FormControlLabel control={<Checkbox checked={isAdvanceSale} onChange={handleAdvanceSale} />} label={t('Is Advance Sale?')} />
      </Grid>
      <Grid container xs={11}>
        <DatePicker
          value={advanceSaleDate || null}
          onChange={(e) => {
            handleAdvanceSaleDate(e);
          }}
          fullWidth
          style={{ maxWidth: '180px' }}
          id="advance_sale_date"
          label={t('agreed delivery date')}
          InputLabelProps={{
            shrink: true
          }}
          InputProps={{
            endAdornment: <DateRange color="primary" />
          }}
          format="DD-MM-YYYY"
          disablePast
        />
      </Grid>
    </Grid>
  );
};
