import React, { useMemo, useCallback } from 'react';
import { DatabaseConnector } from '@phinxlab/libby-rest-web';
import { Autocomplete } from '@material-ui/lab';
import { TextField } from '@material-ui/core';
import { LibbyObject } from '../../types/types';
import { useLibbyCall } from '../../hooks';
import { useTranslation } from '../../services/translation';
import { AllMarketplace } from '../../models/allMarketplace';
import { subFilter } from '../../types';
import { Dispatch_state } from '../../interfaces/business';
import { DispatchState } from '../../const';

type SelectDispatchAllStatusTypeProps = {
  onChange: (value: subFilter[]) => void;
  libby: LibbyObject;
  allState: any;
  onChangeEmpty: () => void;
};

export const SelectDispatchAllStatusRaw = ({ onChange, libby, allState, onChangeEmpty }: SelectDispatchAllStatusTypeProps) => {
  const { data: dataRaw } = useLibbyCall(libby, {
    daoName: 'ster_dispatch_state',
    methodName: 'getAllInquiry'
  });
  const { t } = useTranslation();

  const data = useMemo(
    () =>
      dataRaw.map(({ dispatch_state_id, name }: Dispatch_state) => ({
        id: dispatch_state_id,
        name: t(name)
      })),
    [dataRaw, t]
  );

  const onChangeAutoComplete = useCallback(
    (event, newValue: AllMarketplace[]) => {
      if (!newValue.length) {
        onChangeEmpty();
      } else {
        onChange(
          newValue.map((marketplace: AllMarketplace) => ({
            value: marketplace.id,
            path: ''
          }))
        );
      }
    },
    [onChange, onChangeEmpty]
  );

  const autocompleteValue = useMemo(
    () =>
      allState?.length
        ? allState.map((filter: subFilter) => ({
            id: filter.value,
            name: t(DispatchState[filter.value.toString()])
          }))
        : [],
    [allState, t]
  );

  const getOptionLabel = useCallback((option: any) => t(option.name), [t]);

  return (
    <Autocomplete
      value={autocompleteValue}
      style={{ display: 'inline-block', width: '100%' }}
      multiple
      id="tags-outlined"
      options={data}
      fullWidth
      getOptionLabel={getOptionLabel}
      filterSelectedOptions
      onChange={onChangeAutoComplete}
      getOptionSelected={(option: any, _value: any) => option.id === _value.id}
      renderInput={(params) => <TextField {...params} label={t('All the status')} placeholder="" />}
    />
  );
};

export const SelectDispatchAllStatus = DatabaseConnector(SelectDispatchAllStatusRaw)('ster_dispatch_state');
