import { GoalType } from '../../interfaces/business';
import { makeBusinessEntity } from '../../lib/libby/generator/makeBusinessEntity';

const DAO_NAME = 'ster_goal_type';

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch }
} = makeBusinessEntity<GoalType>(DAO_NAME);

// hooks
export const useGoalTypeFetchById = fetchById;
export const useGoalTypeLibbyCall = libbyCall;
export const useGoalTypeDAO = libbyDAO;
export const useGoalTypeLibbyFetch = libbyFetch;

// hooks
export const useGoalTypeUpdateFetchById = fetchById;
export const useGoalTypeUpdateLibbyCall = libbyCall;
export const useGoalTypeUpdateDAO = libbyDAO;
export const useGoalTypeUpdateLibbyFetch = libbyFetch;
