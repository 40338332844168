import React, { useCallback, useMemo, useState } from 'react';
import { Grid, Typography, Box } from '@material-ui/core';
import moment from 'moment';
import { useTranslation } from '../../../../../services/translation';
import { FilterBar } from '../../../../../components/FilterBar';
import { gridBreakpoints } from '../../../../../const/gridBreakpoints';
import { FilterBarSelectionReportingObjectiveSales } from '../type';
import { GeneralSelect } from '../../../../../components/GeneralSelect';

type DataFilter = {
  years?: Array<number>;
  months?: Array<{
    year?: number;
    months?: Array<{ monthNumber: number; monthName: string }>;
  }>;
  weeks?: Array<{ year?: number; month?: number; week?: number; monthName?: string; start?: number; end?: number }>;
  users?: Array<{ account_id: number; name: string; last_name: string }>;
};

type SearchFiltersProps = {
  onFilterChange: (makeFilter: object) => void;
  initFetch: FilterBarSelectionReportingObjectiveSales;
  dateFilter?: DataFilter;
  dateFilterWorking: boolean;
};

interface filterDate {
  year?: string;
  month?: string;
  week?: string;
}

const SearchFiltersRaw = ({ onFilterChange, initFetch, dateFilter, dateFilterWorking }: SearchFiltersProps) => {
  const { t } = useTranslation();
  // const [date, setDate] = useState(moment());
  const [filterDate, setFilterDate] = useState<filterDate>({ year: moment().format('yyyy'), month: `${moment().format('yyyy')}-${moment().format('M')}`, week: '0' });

  const dataYears = useMemo(() => dateFilter?.years?.map((year) => ({ id: year.toString(), value: year.toString() })) || [], [dateFilter]);

  const dataMonths = useMemo(() => {
    const options: Array<{ id: string; value: string }> = [];

    dateFilter?.months?.map(
      (year) => year?.year?.toString() === filterDate.year && year?.months?.map((month) => options.push({ id: `${year?.year?.toString()}-${month.monthNumber}`, value: `${year?.year?.toString()} ${month.monthName}` }))
    );

    return options;
  }, [dateFilter, filterDate.year]);

  const dataWeeks = useMemo(() => {
    const options: Array<{ id: string; value: string }> = [];

    dateFilter?.weeks?.map(
      (week) =>
        week.year?.toString() === filterDate.year &&
        week.month?.toString() === filterDate.month?.split('-')[1] &&
        options.push({ id: `${week?.year?.toString()}-${week.month}-${week.week}`, value: `${week.year} Mes: ${week.monthName}, Semana n° ${week.week}` })
    );
    return options;
  }, [dateFilter, filterDate.month, filterDate.year]);

  const dataUsers = useMemo(() => {
    return (
      dateFilter?.users?.map((user) => ({
        id: user.account_id,
        value: `${user.name} ${user.last_name}` || ''
      })) || []
    );
  }, [dateFilter]);

  const handleChangeUser = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const initCopy = { ...initFetch };
      delete initCopy.account_id;

      const newFilter: FilterBarSelectionReportingObjectiveSales = {
        ...initCopy,
        ...((e.target.value !== '0' && { account_id: e.target.value }) || null)
      };

      onFilterChange(newFilter);
    },
    [onFilterChange, initFetch]
  );

  const handleChangeDateTest = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { name, value } = e.target;

      switch (name) {
        case 'year': {
          setFilterDate({ ...filterDate, year: value });
          break;
        }
        case 'month': {
          setFilterDate({ ...filterDate, month: value, week: '0' });

          const newFilter: FilterBarSelectionReportingObjectiveSales = {
            ...initFetch,
            from: moment(`${value}-1`).startOf('month').format('YYYY-MM-DD'),
            to: moment(`${value}-1`).endOf('month').format('YYYY-MM-DD')
          };

          onFilterChange(newFilter);
          break;
        }
        case 'week': {
          setFilterDate({ ...filterDate, week: value });
          if (value === '0') {
            const newFilter: FilterBarSelectionReportingObjectiveSales = {
              ...initFetch,
              from: moment(`${filterDate.month}-1`).startOf('month').format('YYYY-MM-DD'),
              to: moment(`${filterDate.month}-1`).endOf('month').format('YYYY-MM-DD')
            };

            onFilterChange(newFilter);
          } else {
            const dateParts = value.split('-');
            const week = dateFilter?.weeks?.find((item) => item.year?.toString() === dateParts[0] && item.month?.toString() === dateParts[1] && item.week?.toString() === dateParts[2]);

            const newFilter: FilterBarSelectionReportingObjectiveSales = {
              ...initFetch,
              from: `${week?.year}-${week?.month}-${week?.start}`,
              to: `${week?.year}-${week?.month}-${week?.end}`
            };

            onFilterChange(newFilter);
          }
          break;
        }
        default: {
          break;
        }
      }
    },
    [dateFilter, filterDate, initFetch, onFilterChange]
  );

  return (
    <FilterBar
      defaultFilter={
        <Grid item {...gridBreakpoints}>
          <Typography variant="h4">{t('Target date')}</Typography>
          <Box flexDirection="column" display="flex" my={1}>
            <Box>
              <Typography variant="h5">
                {t('Start Date')} : {initFetch?.from && moment(initFetch.from).format('YYYY-M-D')}
              </Typography>
            </Box>
            <Box>
              <Typography variant="h5">
                {t('End Date')}: {initFetch?.to && moment(initFetch.to).format('YYYY-M-D')}
              </Typography>
            </Box>
          </Box>
        </Grid>
      }
    >
      <Grid item {...gridBreakpoints}>
        <GeneralSelect working={dateFilterWorking} label={t('Year')} optionAll={false} data={dataYears} inputProps={{ value: filterDate.year, onChange: handleChangeDateTest, name: 'year' }} sort={false} />
      </Grid>
      <Grid item {...gridBreakpoints}>
        <GeneralSelect working={dateFilterWorking} label={t('Month')} optionAll={false} data={dataMonths} inputProps={{ value: filterDate.month, onChange: handleChangeDateTest, name: 'month' }} sort={false} />
      </Grid>
      <Grid item {...gridBreakpoints}>
        <GeneralSelect working={dateFilterWorking} label={t('Week')} optionAll data={dataWeeks} inputProps={{ value: filterDate.week, onChange: handleChangeDateTest, name: 'week' }} sort={false} />
      </Grid>
      <Grid item {...gridBreakpoints}>
        <GeneralSelect working={dateFilterWorking} label={t('Account')} optionAll data={dataUsers} inputProps={{ value: initFetch?.account_id || '0', onChange: handleChangeUser }} sort={false} />
      </Grid>
    </FilterBar>
  );
};

export const SearchFilters = React.memo(SearchFiltersRaw);
