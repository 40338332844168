import React, { FC, useCallback, useEffect, useState } from 'react';
import { Button, CircularProgress, Grid, TextField, Typography } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import SearchIcon from '@material-ui/icons/Search';
import axios from 'axios';
import { useTranslation } from '../../../../../../../../services/translation';
import { useStyles } from '../../../../../BudgetNew/const';
import { useBudgetContext } from '../../../../context';
import { useLibbyCall } from '../../../../../../../../lib/libby';
import { BUYER_ODOO_BILLING_INFO, GET_CLIENT_DOCUMENT } from '../../../../../../../../const';
import { API_URL } from '../../../../../../../../config';
import { IBudgetClient } from '../../../../context/BudgetContext/types';
import { ModalMissingTier } from './Modals';

export interface ClientProps {}

export const Client: FC<ClientProps> = () => {
  const [clientSearch, setClientSearch] = useState('');
  const { setSelectedClient, selectedClient } = useBudgetContext();
  const [budgetUser, setBudgetUser] = useState<IBudgetClient[]>([]);
  const [showDialogMissingTier, setShowDialogMissingTier] = useState(false);

  const {
    data: dataBudgetUsers,
    working: loadingSearch,
    recall
  } = useLibbyCall({
    daoName: 'ster_buyer_odoo',
    methodName: 'getBuyerByNameId',
    params: [],
    noAutoCall: true
  });

  const getClient = useCallback(
    async (buyer: string = '') => {
      if (buyer?.length < 4) return;
      if (/^(?=.*[A-Za-z])(?=.*\d).+$/.test(buyer)) return;
      if (!Number.isNaN(+buyer)) {
        if (buyer?.length >= 8) {
          await recall?.(buyer);
        }
      } else {
        await recall?.(buyer);
      }
    },
    [recall]
  );

  const handleClient = useCallback(
    async (client: any) => {
      const response = await axios({
        method: 'GET',
        url: `${API_URL}/${BUYER_ODOO_BILLING_INFO.replace(':document', client?.document || '')}`
      });

      const respDocument = await axios({
        method: 'GET',
        url: `${API_URL}/${GET_CLIENT_DOCUMENT.replace(':document', client?.document || '')}`
      });
      const hasDocument = respDocument?.data?.hasDocument;

      if (!client?.tier?.id) {
        setShowDialogMissingTier(true);
      }
      setSelectedClient({ ...client, ...response, hasDocument });
    },
    [setSelectedClient]
  );

  const handleClean = useCallback(() => {
    setSelectedClient([]);
  }, [setSelectedClient]);

  const { t } = useTranslation();

  const classes = useStyles();

  useEffect(() => {
    if (clientSearch) getClient(clientSearch);
  }, [getClient, clientSearch]);

  useEffect(() => {
    // @ts-ignore
    if (dataBudgetUsers?.length) {
      // @ts-ignore
      setBudgetUser(dataBudgetUsers);
    }
  }, [dataBudgetUsers]);

  return (
    <Grid container style={{ marginBottom: '10px' }}>
      {!selectedClient.data && (
        <Grid xs={12}>
          <Typography variant="h1" className={classes.title}>{`${t('Client')}`}</Typography>
        </Grid>
      )}
      <Grid item xs={!selectedClient.data ? 12 : 8}>
        <Autocomplete<IBudgetClient>
          id="client-search"
          value={Object.keys(selectedClient).length ? selectedClient : undefined}
          onChange={(event, value) => (value ? handleClient(value) : handleClean())}
          onInputChange={(event, value) => {
            setClientSearch(value);
          }}
          options={loadingSearch ? [{ ...budgetUser[0], last_name: clientSearch }] : budgetUser}
          getOptionLabel={(item: IBudgetClient) => `${item.first_name} ${item.last_name} ${item.document}`}
          renderOption={(item: IBudgetClient) =>
            loadingSearch ? (
              <div className={classes.loadingAutocomplete}>
                <CircularProgress size={24} />
              </div>
            ) : (
              <div>
                <div>{`${item?.first_name} ${item.last_name}`}</div>
                <span>DNI: {item.document}</span>
              </div>
            )
          }
          popupIcon={<SearchIcon />}
          style={{ width: '100%' }}
          renderInput={(params) => <TextField {...params} label={selectedClient.data ? t('Client') : null} variant="standard" />}
        />
      </Grid>
      {selectedClient?.data && selectedClient?.tier?.name && (
        <Grid container xs={4} alignItems="center" justify="center">
          <Button style={{ padding: '1px', width: '90%', maxWidth: '150px' }} type="button" variant="outlined" color="primary">
            {selectedClient.tier.name}
          </Button>
        </Grid>
      )}
      <ModalMissingTier client={selectedClient} open={showDialogMissingTier} onClose={() => setShowDialogMissingTier(false)} />
    </Grid>
  );
};
