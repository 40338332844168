import { LibbyFetchDAO } from '../LibbyFetchDAO';

export class FeatureGroupDAO extends LibbyFetchDAO {
  constructor() {
    super('ster_product_feature_group', 'feature_group_id');
  }

  getAll() {
    return this.query().limit(0, 10000).run();
  }
}
