import { Box, Typography } from '@material-ui/core';
import React from 'react';
import BarChartIcon from '@material-ui/icons/BarChart';
import { useTranslation } from '../../services/translation';

export const ChartEmpty = () => {
  const { t } = useTranslation();
  return (
    <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
      <BarChartIcon fontSize="large" color="primary" />
      <Typography noWrap variant="body1" color="textSecondary">
        {t('No data for the chart')}
      </Typography>
    </Box>
  );
};
