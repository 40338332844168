import React, { useCallback } from 'react';
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { useSnackbar } from 'notistack';
import { AnyObject } from '../../../../../types/types';
import { useTranslation } from '../../../../../services/translation';
import { getExtension } from '../utils/getExtension';

let fileReader: AnyObject;
type FileInputProps = {
  uploadFile: (filename: string, base64Content: string, extension: string) => void;
  [k: string]: any;
};

const useStyles = makeStyles({
  containerInput: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    height: '50px'
  },
  label: {
    display: 'flex',
    flexWrap: 'nowrap',
    alignItems: 'center',
    paddingRight: 10
  },
  labelText: {
    paddingRight: 10
  },
  icon: {
    minWidth: 50,
    minHeight: 50
  },
  fileInput: {
    display: 'none'
  },
  Space: {
    marginRight: 12
  }
});

export const FileInput = ({ uploadFile }: FileInputProps) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const handleFileRead = useCallback(
    async (event) => {
      try {
        const base64ToSave = event.target.result.split(',');
        const extension = getExtension(fileReader.fileName);
        const arrayTitle = fileReader.fileName.split('.');
        arrayTitle.pop();
        const base64Content = base64ToSave[1];

        uploadFile(arrayTitle.join('.'), base64Content, extension);
        enqueueSnackbar(t('Uploaded file successfully'), { variant: 'success' });
      } catch (e: any) {
        enqueueSnackbar(`${t('Cannot upload file, reason:')} ${e.message}`, {
          variant: 'error'
        });
      }
    },
    [uploadFile, enqueueSnackbar, t]
  );

  const handleFileChosen = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const target = e.target as HTMLInputElement;
      const file: File = (target.files as FileList)[0];
      fileReader = new FileReader();
      fileReader.fileName = file && file.name;

      fileReader.onload = handleFileRead;

      if (file) fileReader.readAsDataURL(file);
    },
    [handleFileRead]
  );

  return (
    <Button variant="contained" color="primary" component="label" className={classes.Space}>
      <input hidden type="file" className={classes.Space} onChange={handleFileChosen} />
      {t('Attach')}
    </Button>
  );
};
