import { makeBusinessEntity } from '../../lib/libby/generator/makeBusinessEntity';
import { MarketplaceFraud } from 'interfaces/business';

const DAO_NAME = 'ster_marketplace_fraud';

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch }
} = makeBusinessEntity<MarketplaceFraud>(DAO_NAME);

// hooks
export const useMarketplaceFraudFetchById = fetchById;
export const useMarketplaceFraudLibbyCall = libbyCall;
export const useMarketplaceFraudDAO = libbyDAO;
export const useMarketplaceFraudLibbyFetch = libbyFetch;
