import { Column } from '../../../../components/InfoTable';

export const columnsReportingGoalSaleSettingFieldAdd: Array<Column> = [
  {
    id: 'name',
    label: 'Name',
    style: {
      whiteSpace: 'nowrap'
    }
  }
];

export const columnsReportingGoalSaleSettingFieldProductsAdd: Array<Column> = [
  {
    id: 'sku',
    label: 'Sku'
  }
];

export const columnsReportingGoalSaleSettingFieldUserAdd: Array<Column> = [
  {
    id: 'account_id',
    label: 'Account ID',
    style: {
      whiteSpace: 'nowrap'
    }
  },
  {
    id: 'username',
    label: 'User Name',
    style: {
      whiteSpace: 'nowrap'
    }
  }
];
