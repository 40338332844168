import React, { useCallback, useEffect, useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Typography } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { useTranslation } from '../../../../../../../../../services/translation';
import { useBudgetContext } from '../../../../../context';
import { textFieldProps } from '../../../../../../../../../utils';
import { useStyles } from '../../../../../const';
import { AutocompleteCityEdit } from '../../../../../../../../../components/AutocompleteCityEdit';
import { IAddress, IProvince } from '../../../../../context/BudgetContext/types';

interface IModalShippingAddressEdit {
  open: boolean;
  onClose: () => void;
}

export const ModalShippingAddressEdit = ({ open = false, onClose }: IModalShippingAddressEdit) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const { provinces, setSelectedProvinces, selectedProvince, pushShippingAddressOptions, shippingAddress, editShippingAddress } = useBudgetContext();

  const [editedShippingCity, setEditedShippingCity] = useState(shippingAddress.selectedShippingCity);
  const [shippingCityId, setShippingCityId] = useState<string>();
  const [editedShippingState, setEditedShippingState] = useState(shippingAddress.selectedShippingState);
  const [editedShippingStreet, setEditedShippingStreet] = useState(shippingAddress?.selectedShippingStreet);
  const [editedShippingStreetNumber, setEditedShippingStreetNumber] = useState(shippingAddress?.selectedShippingStreetNumber);
  const [editedShippingZipCode, setEditedShippingZipCode] = useState(shippingAddress?.selectedShippingZipCode);
  const [editedShippingFloor, setEditedShippingFloor] = useState(shippingAddress?.selectedShippingFloor);
  const [editedShippingDepartment, setEditedShippingDepartment] = useState(shippingAddress?.selectedShippingDepartment);

  const handleEditing = useCallback(() => {
    const province = selectedProvince as IProvince;
    const address: IAddress = {
      street: editedShippingStreet,
      street_number: editedShippingStreetNumber,
      city: { city_id: shippingCityId, name: editedShippingCity, state: province.name },
      zip: editedShippingZipCode,
      floor: editedShippingZipCode,
      department: editedShippingDepartment
    };
    editShippingAddress({
      editedShippingCity,
      editedShippingState,
      editedShippingStreet,
      editedShippingStreetNumber,
      editedShippingZipCode,
      editedShippingFloor,
      editedShippingDepartment
    });
    pushShippingAddressOptions(province, address, provinces);
    onClose();
  }, [
    editShippingAddress,
    editedShippingCity,
    editedShippingDepartment,
    editedShippingFloor,
    editedShippingState,
    editedShippingStreet,
    editedShippingStreetNumber,
    editedShippingZipCode,
    onClose,
    provinces,
    pushShippingAddressOptions,
    selectedProvince,
    shippingCityId
  ]);

  useEffect(() => {
    setEditedShippingCity(shippingAddress?.selectedShippingCity);
    setEditedShippingState(shippingAddress?.selectedShippingState);
    setEditedShippingStreet(shippingAddress?.selectedShippingStreet);
    setEditedShippingStreetNumber(shippingAddress?.selectedShippingStreetNumber);
    setEditedShippingZipCode(shippingAddress?.selectedShippingZipCode);
    setEditedShippingFloor(shippingAddress?.selectedShippingFloor);
    setEditedShippingDepartment(shippingAddress?.selectedShippingDepartment);
  }, [shippingAddress]);

  return (
    <Dialog open={open} aria-labelledby="form-dialog-title" fullWidth maxWidth="md">
      <DialogTitle id="form-dialog-title">
        <Typography variant="h1" className={classes.title}>{`${t('Add')}`}</Typography>
      </DialogTitle>
      <DialogContent>
        <div style={{ margin: '10px' }}>
          <Autocomplete
            id="province-search"
            options={provinces}
            // @ts-ignore
            getOptionLabel={(option) => option?.name}
            onChange={(e, value) => setSelectedProvinces(value)}
            // @ts-ignore
            value={selectedProvince}
            filterSelectedOptions
            renderInput={(params) => <TextField {...params} className={classes.textFieldAutocomplete} placeholder={t('Province')} />}
          />
        </div>
        <div style={{ margin: '10px' }}>
          <AutocompleteCityEdit
            className={classes.inputs}
            inputProps={{
              // @ts-ignore
              // province: parseInt(editedShippingState?.state_id),
              province: selectedProvince?.state_id,
              // @ts-ignore
              onChange: (city: { id: string; value: stirng }) => {
                setShippingCityId(city.id);
                setEditedShippingCity(city.value);
              },
              InputLabelProps: { shrink: true },
              required: true,
              error: {
                error: provinces.find((item: any) => item.name === 'city')?.error || false,
                helperText: t(provinces.find((item: any) => item.name === 'city')?.text || '').replace('$$$$', t('City'))
              }
            }}
          />
        </div>
        <TextField
          {...textFieldProps(`${t('Street')}`)}
          required
          variant="standard"
          value={editedShippingStreet}
          onChange={(event: any) => {
            setEditedShippingStreet(event.target.value);
          }}
          InputLabelProps={{ shrink: true }}
          className={classes.inputs}
        />
        <TextField
          {...textFieldProps(`${t('Street Number')}`)}
          required
          variant="standard"
          value={editedShippingStreetNumber}
          onChange={(event: any) => setEditedShippingStreetNumber(event.target.value)}
          InputLabelProps={{ shrink: true }}
          type="number"
          className={classes.inputs}
        />
        <TextField
          {...textFieldProps(`${t('Floor')}`)}
          required
          variant="standard"
          value={editedShippingFloor}
          onChange={(event: any) => setEditedShippingFloor(event.target.value)}
          InputLabelProps={{ shrink: true }}
          className={classes.inputs}
        />
        <TextField
          {...textFieldProps(`${t('Department')}`)}
          required
          variant="standard"
          value={editedShippingDepartment}
          onChange={(event: any) => setEditedShippingDepartment(event.target.value)}
          InputLabelProps={{ shrink: true }}
          className={classes.inputs}
        />
        <TextField
          {...textFieldProps(`${t('Zip')}`)}
          required
          variant="standard"
          value={editedShippingZipCode}
          onChange={(event: any) => setEditedShippingZipCode(event.target.value)}
          InputLabelProps={{ shrink: true }}
          type="number"
          className={classes.inputs}
        />
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={() => handleEditing()} autoFocus>
          {`${t('Add')}`}
        </Button>
        <Button color="secondary" onClick={() => onClose()} autoFocus>
          {`${t('Cancel')}`}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
