import { GoalProduct } from '../../interfaces/business';
import { makeBusinessEntity } from '../../lib/libby/generator/makeBusinessEntity';

const DAO_NAME = 'ster_goal_product';

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch }
} = makeBusinessEntity<GoalProduct>(DAO_NAME);

// hooks
export const useGoalProductFetchById = fetchById;
export const useGoalProductLibbyCall = libbyCall;
export const useGoalProductDAO = libbyDAO;
export const useGoalProductLibbyFetch = libbyFetch;

// hooks
export const useGoalProductUpdateFetchById = fetchById;
export const useGoalProductUpdateLibbyCall = libbyCall;
export const useGoalProductUpdateDAO = libbyDAO;
export const useGoalProductUpdateLibbyFetch = libbyFetch;
