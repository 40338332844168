import { useCallback, useEffect, useRef, useState } from 'react';
import { useLibby } from '@phinxlab/libby-rest-web';
import { AnyObject } from '../../../types';
import { usePrevious } from '../../../hooks';

// La diferencia entre fixedFilter y filters, es que el primero no reacciona a cambios automaticamente
export type LibbyFetchOptions = {
  filter?: AnyObject;
  fixedFilter?: AnyObject;
  orderBy?: string;
  daoName: string;
  limit?: number;
  checkDuplication?: boolean;
  direction?: 'asc' | 'desc';
  aspect?: string;
};

export interface LibbyFetchReturn<T> {
  data?: T[];
  working: boolean;
  fetchMore: () => void;
  reFetch: () => void;
  addCreate: (data: any) => void;
  setMergedData: (prev: T[]) => void;
  updateData: (dataUpdate: T, id: string) => void;
  setFixedFilters: (newFixedFilter: AnyObject) => void;
  removeData: (id: string, idName: string) => void;
  error?: string;
}

export const useLibbyFetch = <T>({ filter, fixedFilter = {}, orderBy, direction = 'asc', daoName, limit: initialLimit = 40, checkDuplication = true, aspect }: LibbyFetchOptions): LibbyFetchReturn<T> => {
  const { libby } = useLibby([daoName]);
  const fixedFilterRef = useRef<AnyObject>(fixedFilter);
  const prevFilter = usePrevious(filter);
  const [endReached, setEndReached] = useState(false);
  const [initialFetch, setInitialFetch] = useState(false);
  const [mergedData, setMergedData] = useState<T[]>([]);
  const [limit] = useState(initialLimit);
  const [offset, setOffset] = useState(0);
  const [dataError, setDataError] = useState<string | undefined>();

  const fetch = useCallback(async () => {
    if (!daoName) {
      // eslint-disable-next-line
      console.log('daoName required!');
    } else if (!libby[daoName]) {
      // eslint-disable-next-line
      console.log('DAO not found, be sure that DatabaseConnector HOC has the daoName in its config');
    } else if (!endReached) {
      try {
        if (aspect) {
          console.log('colocando es aspecto:', aspect);
          libby[daoName].aspect(aspect);
        }
        const joinedFilters = filter ? { ...fixedFilterRef.current, ...filter } : fixedFilterRef.current;
        const data = await libby[daoName].fetch({
          filter: joinedFilters,
          orderBy,
          limit,
          offset,
          direction
        });

        if (!!libby[daoName].errors?._default?.rest) {
          const { message } = JSON.parse(libby[daoName].errors._default?.rest?.data);
          setDataError(message);
        }

        // TODO: think how to filter duplicated in a better way
        setMergedData((prev: T[]) => {
          // this is to avoid duplications
          const copy: T[] = [...prev];
          const { pk } = libby[daoName];
          if (data && data.length) {
            data.forEach((item: T) => {
              if (!checkDuplication || !copy.find((el) => el[pk as keyof typeof item] === item[pk as keyof typeof item])) {
                copy.push(item);
              }
            });
          }
          return copy;
        });
        setOffset(offset + limit);
        if (!data?.length) {
          setEndReached(true);
        }
      } catch (error) {
        const message = error instanceof Error ? error.message : '';
        setDataError(message);
      }
    }
  }, [checkDuplication, daoName, filter, libby, limit, offset, orderBy, direction, endReached, aspect]);

  const reFetch = useCallback(() => {
    setMergedData([]);
    setOffset(0);
    setEndReached(false);
    setInitialFetch(false);
    setDataError(undefined);
  }, []);
  useEffect(() => {
    reFetch();
  }, [orderBy, direction, reFetch]);
  useEffect(() => {
    if (prevFilter !== filter) {
      reFetch();
    }
  }, [filter, prevFilter, reFetch]);
  useEffect(() => {
    if (!initialFetch) {
      setInitialFetch(true);
      fetch();
    }
  }, [fetch, initialFetch]);

  const [debounce, setDebounce] = useState<NodeJS.Timeout | null>(null);
  const fetchMore = useCallback(() => {
    if (debounce) {
      clearTimeout(debounce);
    }
    setDebounce(
      setTimeout(() => {
        fetch();
      }, 100)
    );
  }, [debounce, fetch]);

  const addCreate = useCallback((data: any) => {
    setMergedData((prev) => {
      const copy: T[] = [...prev];
      copy.push(data);
      return copy;
    });
  }, []);

  const updateData = useCallback(
    (dataUpdate: T, id: string) => {
      setMergedData((prev) => {
        const copy: T[] = [...prev];
        if (dataUpdate[id as keyof typeof dataUpdate]) {
          const result = copy.findIndex((value) => value[id as keyof typeof dataUpdate] === dataUpdate[id as keyof typeof dataUpdate]);
          copy[result] = dataUpdate;
        }
        return copy;
      });
    },
    [setMergedData]
  );

  const removeData = useCallback(
    (id: string, idName: string) => {
      setMergedData((prev) => {
        const copy = [...prev] as AnyObject[];
        const result = copy.findIndex((value) => value[idName] === id);
        copy.splice(result, 1);
        return copy as T[];
      });
    },
    [setMergedData]
  );

  const setFixedFilters = useCallback(
    (newFixedFilters) => {
      fixedFilterRef.current = newFixedFilters;
      if (reFetch) reFetch();
    },
    [reFetch]
  );

  return {
    data: mergedData,
    working: libby.working,
    fetchMore,
    reFetch,
    addCreate,
    setMergedData,
    updateData,
    removeData,
    setFixedFilters,
    error: dataError
  };
};
