import React, { useEffect, useMemo } from 'react';
import { TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { Moment } from 'moment';
import { DatabaseConnector } from '@phinxlab/libby-rest-web';
import { orderBy, toLower } from 'lodash';
import { Category } from '../../interfaces/business/product/Category';
import { AnyObject, LibbyObject } from '../../types/types';
import { useLibbyCall } from '../../hooks';
import { useTranslation } from '../../services/translation';
import { CATEGORY_PRODUCT } from '../../const';

type SelectCategoryGoalProps = {
  libby: LibbyObject;
  from?: Moment;
  inputProps: AnyObject;
  [k: string]: any;
  disabled?: boolean;
  label: string;
  editForm?: boolean;
};

export const SelectCategoryGoalRaw = ({ libby, inputProps = {}, disabled = false, label, from, editForm = false }: SelectCategoryGoalProps) => {
  const { t } = useTranslation();
  const {
    data: dataAll,
    working,
    recall
  } = useLibbyCall(libby, {
    daoName: !editForm ? 'ster_goal_field_enabled' : 'ster_category',
    methodName: !editForm ? 'getAllGoalFieldEnabledByType' : 'getAllCategory',
    ...(!editForm && { params: [{ type: 'Category', start_date: `${from?.date(1).format('YYYY-MM-DD')} 00:00:00` }] }),
    noAutoCall: !editForm
  });

  useEffect(() => {
    if (from) {
      recall?.({ type: 'Category', start_date: `${from?.date(1).format('YYYY-MM-DD')} 00:00:00` });
    }
  }, [from, recall]);

  const data = useMemo(() => {
    const dataCategories = dataAll as Category[];

    return dataCategories?.length ? dataCategories?.map((category) => ({ id: category.category_id.toString(), value: category.name })).filter((item) => item.id !== CATEGORY_PRODUCT.DEFAULT.toString()) : [];
  }, [dataAll]);

  return (
    <Autocomplete
      disabled={disabled || working || !data.length}
      style={{ display: 'inline-block', width: '100%' }}
      multiple
      options={orderBy(data, [(item) => toLower(item.value)], 'asc')}
      getOptionLabel={(option: { id: string; value: string }) => option.value}
      filterSelectedOptions
      getOptionSelected={(option: any, _value: any) => option.id === _value.id}
      value={inputProps.value || []}
      onChange={(e, newValue) => inputProps.onChange(newValue)}
      renderInput={(params) => <TextField {...params} variant="standard" label={label} placeholder="" error={inputProps.error?.error} helperText={inputProps.error?.error ? `${t(inputProps.error?.helperText || '')}` : ''} />}
    />
  );
};

export const SelectCategoryGoal = DatabaseConnector(SelectCategoryGoalRaw)('ster_goal_field_enabled', 'ster_category');
