import React, { useCallback, useMemo, useState } from 'react';
import { IconButton } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import { useSnackbar } from 'notistack';
import { OptionsLibbyFetch, useLibbyFetch } from '../../../hooks';
import { LibbyObject, RowsType } from '../../../types';
import customModal from '../../../services/customFormDialog';
import { StockEditNewDialog } from '../components';
import { useTranslation } from '../../../services/translation';
import { formEditStock, ProductStockNew } from '../../../interfaces/business/product';

const StockDialog = customModal(StockEditNewDialog);

interface useStockLogicProps {
  libby: LibbyObject;
  paramsFetch: OptionsLibbyFetch;
}

export const useStockLogic = ({ libby, paramsFetch }: useStockLogicProps) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);

  const { data, working, fetchMore, updateData } = useLibbyFetch(libby, paramsFetch);

  const handleEditStock = useCallback(
    async (dataInner: ProductStockNew) => {
      try {
        const formInitialValues: formEditStock = {
          base: dataInner.base,
          min: dataInner.min,
          marketplaces: dataInner.marketplaces.map((marketplace) => ({
            marketplace_id: marketplace.marketplace_id,
            marketplace_name: marketplace.marketplace_name,
            stock: marketplace.stock,
            published_stock: marketplace.published_stock,
            published_stock_active: marketplace.published_stock_active,
            stock_sync: marketplace.sync
          }))
        };

        const confirm: any = await StockDialog.show({
          dataInner,
          formInitialValues: formInitialValues,
          title: t('Edit Stock'),
          confirmText: t('Save'),
          cancelText: t('Cancel')
        });

        if (confirm) {
          try {
            const { base, min, marketplaces } = confirm;
            setLoading(true);
            const newProductStock: ProductStockNew = await libby.ster_product_stock_odoo_update.save({
              name: dataInner.name,
              sku: dataInner.sku,
              odoo_stock: dataInner.odoo_stock,
              ...(base && { base }),
              ...(min && { min }),
              marketplaces
            });
            if (newProductStock) {
              updateData(newProductStock, 'sku');
              enqueueSnackbar(`${dataInner.name} ${t('Stock Saved')}`, { variant: 'success' });
            } else {
              enqueueSnackbar(t('Something went wrong'), { variant: 'error' });
            }
          } catch (error) {
            // noting
          } finally {
            setLoading(false);
          }
        }
      } catch (error) {
        // noting
      }
    },
    [enqueueSnackbar, libby.ster_product_stock_odoo_update, t, updateData]
  );

  const rows: RowsType[] = useMemo(() => {
    const dataProducts = data as ProductStockNew[];

    return dataProducts.map((product) => {
      return {
        ...product,
        id: product.sku,
        edit: (
          <IconButton onClick={() => handleEditStock(product)} aria-label="delete">
            <EditIcon />
          </IconButton>
        )
      };
    });
  }, [data, handleEditStock]);

  return { rows, working: loading || working, fetchMore };
};
