import { useCallback, useMemo } from 'react';
import { useSnackbar } from 'notistack';
import { MessageTemplateFormDialog } from '../components/MessageTemplateFormDialog';
import CustomModal from '../../../services/customFormDialog';
import { formInitialValues, validate } from '../model/model';
import { useTranslation } from '../../../services/translation';
import { LibbyObject } from '../../../types';
import { useFilerParamsId, useLibbyFetch } from '../../../hooks';
import confirmDialog from '../../../services/confirmDialog';
import { Message_template } from '../../../interfaces/business/message';
import { MessageVisualizer } from '../components/MessageVisualizer';

const TemplateForm = CustomModal(MessageTemplateFormDialog);
const ShowMessage = CustomModal(MessageVisualizer);

interface TemplateLogic {
  libby: LibbyObject;
}

export const useMessageTemplatesLogic = ({ libby }: TemplateLogic) => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const { orderBy, direction, handleRequestSort, paramsFetch, setFilter, filter } = useFilerParamsId({
    orderInit: 'message_template_id',
    directionInit: 'desc',
    daoName: 'ster_message_template',
    aspect: 'message_template_list'
  });

  const { data, addCreate, updateData, working } = useLibbyFetch(libby, paramsFetch);

  const templates = useMemo(() => data.filter((template) => template.active), [data]);

  const showMessageDialog = useCallback((message: string, isDownSm: boolean, isMobile: boolean) => {
    ShowMessage.show({
      message,
      isDownSm,
      isMobile
    });
  }, []);

  const createTemplateDialog = useCallback(async () => {
    try {
      const confirm: any = await TemplateForm.show({
        title: t('Create Message Template'),
        confirmText: `${t('Save')}`,
        cancelText: t('Cancel'),
        formInitialValues,
        validate
      });

      if (confirm) {
        const { message, name, from, message_subject } = confirm;
        const newMessageConfigSaved = await libby.ster_message_template_update.aspect('message_template_list').save({
          name,
          message,
          message_from: {
            message_from_id: from
          },
          message_subject
        });

        addCreate(newMessageConfigSaved);
        enqueueSnackbar(t('Message template saved'), { variant: 'success' });
      }
    } catch (e) {
      // nothing
      if (e)
        enqueueSnackbar(`${t('Error creating message configuration')}`, {
          variant: 'error'
        });
    }
  }, [t, addCreate, enqueueSnackbar, libby.ster_message_template_update]);

  const deleteMessageTemplate = useCallback(
    async (template: Message_template) => {
      const confirm = await confirmDialog.show({
        title: t('Delete Message Template'),
        confirmText: `${t('Delete')}`,
        cancelText: t('Cancel')
      });
      if (confirm) {
        try {
          const newMessageTemplate: Message_template = await libby.ster_message_template.aspect('message_template_list').save({
            ...template,
            active: false
          });
          updateData(newMessageTemplate, 'message_template_id');
          enqueueSnackbar(t('Message configuration deleted'), { variant: 'success' });
        } catch (e) {
          enqueueSnackbar(`${t('Error deleting message configuration')}`, {
            variant: 'error'
          });
        }
      }
    },
    [enqueueSnackbar, updateData, t, libby]
  );

  const editMessageTemplate = useCallback(
    async ({ message_template_id, name, message, message_from, message_subject }: Message_template) => {
      const formInitialValuesEdit = {
        message_template_id,
        name,
        message,
        from: message_from?.message_from_id ?? '0',
        message_subject
      };

      const confirmEdit: any = await TemplateForm.show({
        title: 'Edit Message Template',
        confirmText: 'Save',
        formInitialValues: formInitialValuesEdit,
        validate
      });
      if (confirmEdit) {
        const { message: message_form, name: name_form, from, message_subject: message_subject_form } = confirmEdit;
        try {
          const newMessageTemplate: Message_template = await libby.ster_message_template_update.aspect('message_template_list').save({
            message_template_id,
            name: name_form,
            message: message_form,
            message_from: {
              message_from_id: from
            },
            active: true,
            message_subject: message_subject_form
          });
          updateData(newMessageTemplate, 'message_template_id');
          enqueueSnackbar(t('Message configuration saved'), { variant: 'success' });
        } catch (e) {
          enqueueSnackbar(`${t('Error deleting message configuration')}`, {
            variant: 'error'
          });
        }
      }
    },
    [libby, enqueueSnackbar, t, updateData]
  );

  return {
    createTemplateDialog,
    deleteMessageTemplate,
    editMessageTemplate,
    showMessageDialog,
    handleRequestSort,
    setFilter,
    templates: templates as Message_template[],
    orderBy,
    direction,
    working,
    filter
  };
};
