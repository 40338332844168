import React, { useMemo } from 'react';
import { DatabaseConnector } from '@phinxlab/libby-rest-web';
import { GeneralSelect } from '../../../components/GeneralSelect';
import { useLibbyCall } from '../../../hooks';
import { Message_reason } from '../../../interfaces/business/message';
import { SelectTypeProps } from '../../../interfaces/select';

export const SelectMessageReasonRaw = ({ libby, inputProps, ...styles }: SelectTypeProps) => {
  const { data: dataRaw, working = true } = useLibbyCall(libby, {
    daoName: 'ster_message_reason',
    methodName: 'getAll'
  });

  const data = useMemo(
    () =>
      dataRaw.map(({ message_reason_id, name }: Message_reason) => ({
        id: message_reason_id,
        value: name
      })),
    [dataRaw]
  );

  return <GeneralSelect working={working} label="Reason" optionAll data={data} inputProps={inputProps} {...styles} />;
};

export const SelectMessageReason = React.memo(DatabaseConnector(SelectMessageReasonRaw)('ster_message_reason'));
