/* eslint-disable */
import React, { useMemo, useState, useCallback } from 'react';
import { DatabaseConnector } from '@phinxlab/libby-rest-web';
import { useHistory } from 'react-router-dom';
import LaunchIcon from '@material-ui/icons/Launch';
import { useSnackbar } from 'notistack';
import { Box, Checkbox } from '@material-ui/core';
import { ScreenAligned } from '../../../../../components/ScreenAligned/ScreenAligned';
import { useTranslation } from '../../../../../services/translation';
import { MakeCell, MakeCellChildren } from '../../../../components/MakeCell';
import { MakeFilterProps } from '../../../utils/makeFilter';
import { ExportDialogComponent } from '../../../../../components/ExportDialog';
import { ItemsTableSearch } from '../../../../components/ItemsTableSearch';
import { AnyObject, LibbyObject } from '../../../../../types';
import { capitalize, formatDate, formatMoney } from '../../../../../utils';
import { NavbarDispatch } from './NavbarDispatch';
import { SearchFilters } from './SearchFilters';
import InfoTable, { Column } from '../../../../components/InfoTable';
import CustomModal from '../../../../../services/customFormDialog';
import LoadingData from '../../../../components/LoadingData';
import { OrderStateColors, Priority, PriorityColors } from '../../../../../const';
import { RowsType } from '../../../../../utils/tables';
import _ from 'lodash';
import { Collect, Dispatch } from '../../../../../interfaces/business';
import { LoadingTable } from '../../../../../components/LoadingTable';

type DispatchOrderListTableProps = {
  libby: LibbyObject;
  courierService: string;
  data: AnyObject;
  working: boolean;
  filter: AnyObject;
  onFilterChange: (makeFilter: MakeFilterProps) => void;
  fetchMore: () => void;
  reFetch: () => void;
  orderBy?: string;
  direction?: 'asc' | 'desc';
  onSortChange?: (orderBy: string, direction: 'asc' | 'desc') => void;
  addItem: (item?: AnyObject) => void;
  columns?: Array<Column>;
  rows: RowsType[];
};

const SearchDialogModal = CustomModal(ExportDialogComponent);

export const columnsAddOrder = [
  {
    id: 'id',
    label: 'Order ID',
    width: '180px',
    style: {
      minWidth: '180px'
    },
    orderById: 'order_id'
  },
  {
    id: 'so_number',
    label: 'SO Number',
    width: '180px',
    style: {
      minWidth: '180px'
    },
    orderById: 'so_number'
  },
  {
    id: 'buyer',
    label: 'Buyer',
    width: '180px',
    style: {
      minWidth: '180px'
    },
    orderById: 'buyer'
  },
  {
    id: 'total',
    label: 'Total Purchase',
    width: '180px',
    style: {
      minWidth: '180px'
    },
    orderById: 'total'
  },
  {
    id: 'items',
    label: 'Items',
    width: '80px',
    style: {
      minWidth: '80px'
    },
    orderById: 'number_items'
  },
  {
    id: 'state',
    label: 'State',
    width: '160px',
    style: {
      minWidth: '140px'
    },
    orderById: 'state'
  },
  {
    id: 'created_at',
    label: 'Purchase Date',
    width: '180px',
    style: {
      minWidth: '180px'
    },
    orderById: 'created_at'
  }
];

export const columnsPriority = {
  id: 'priority',
  label: 'Priority',
  width: '160px',
  style: {
    minWidth: '120px'
  },
  render: ({ priority }: any, item: any, t: (str: string) => string) => <Box color={PriorityColors[priority]}>{t(Priority[priority])}</Box>
};

export const DispatchOrderListTableRaw = ({ libby, courierService, data, filter, working, fetchMore, orderBy, direction, addItem, onSortChange, onFilterChange, columns, rows, reFetch }: DispatchOrderListTableProps) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const [checked, setChecked] = useState({
    all: [] as AnyObject[]
  });

  const handleOpenItemsModal = async (e: AnyObject, items: string) => {
    e.stopPropagation();
    try {
      await SearchDialogModal.show({
        title: 'Items',
        id: 'order_item_id',
        properties: ['name', 'sku'],
        label: 'Item/Sku',
        data: items,
        maxWidth: 'md',
        render: () => <ItemsTableSearch order_id={items} />
      });
    } catch (error: any) {
      // nothing
    }
  };

  const handleCheckChieldElement = useCallback((event: AnyObject) => {
    setChecked((prev) => {
      const copy = { ...prev };

      const all = [...prev.all];

      const search = all.findIndex((dataOrder: any) => (dataOrder.dispatch_item_id ? dataOrder.dispatch_item_id === event.dispatch_item_id : dataOrder.order_id === event.order_id));

      if (search === -1) {
        all.push({
          ...event
        });
      } else {
        all.splice(search, 1);
      }
      copy.all = all;
      return copy;
    });
  }, []);

  const resetCheck = useCallback(() => {
    setChecked({ all: [] });
  }, [setChecked]);

  const handleCheckChildElementAll = useCallback(() => {
    setChecked((prev) => {
      let copy = { ...prev };
      const all = [...prev.all];
      if (all.length !== data.length) {
        data.forEach((value: any) => {
          if (all.findIndex((valueCopy) => (valueCopy.dispatch_item_id ? valueCopy.dispatch_item_id === value.dispatch_item_id : valueCopy.order_id === value.order_id)) === -1) {
            all.push({
              ...value
            });
          }
        });
      } else {
        all.length = 0;
      }
      copy = Object.assign(copy, { all });
      return copy;
    });
  }, [data]);

  // TODO: Refactorizar
  const columnsDispatch = [
    {
      id: 'select_order_id',
      label: '',
      width: '60px',
      style: {
        minWidth: '50px'
      },
      icon: () => (
        <MakeCellChildren label="">
          <Checkbox checked={checked.all.length === data.length && data.length > 0} onChange={handleCheckChildElementAll} inputProps={{ 'aria-labelledby': 'transfer-list-item-all-label' }} color="primary" />
        </MakeCellChildren>
      ),
      noSort: true
    },
    ...[...columnsAddOrder, columnsPriority]
  ];

  // TODO: Refactorizar
  const rowsDispatch: AnyObject[] = useMemo(
    () =>
      data.map((order: AnyObject, index: any) => {
        let idItem: string;
        if (order.dispatch_item_id) {
          idItem = order.dispatch_item_id;
          order = order.order;
        } else {
          idItem = order.order_id;
        }
        const { order_id, so_number, buyer, total, number_items, state, order_state_id, created_at, priority } = order;

        return {
          select_order_id: (
            <MakeCellChildren label="">
              <Checkbox
                checked={checked.all.findIndex((value: any) => (value?.dispatch_item_id ? value.dispatch_item_id === idItem : value.order_id === idItem)) !== -1}
                onChange={() => handleCheckChieldElement(data[index])}
                inputProps={{
                  'aria-labelledby': `transfer-list-item-${order_id}-label`
                }}
                color="primary"
              />
            </MakeCellChildren>
          ),
          id: order_id,
          so_number,
          buyer: buyer?.trim(),
          total: (
            <Box textAlign="right" fontWeight="fontWeightBold">
              {formatMoney(total)}
            </Box>
          ),
          items: <MakeCell label={String(number_items ?? 0)} icon={LaunchIcon} onClick={(e) => handleOpenItemsModal(e, order_id || '')} />,
          state: (
            <Box color={OrderStateColors[order_state_id || '']} whiteSpace="nowrap">
              {t(capitalize(state || ''))}
            </Box>
          ),
          created_at: `${created_at ? formatDate(created_at, true, false) : 'Invalid Date'}`,
          priority
        };
      }),
    [data, checked.all, handleCheckChieldElement, t]
  );

  const dataShow = useMemo(() => {
    if (orderBy === 'priority' && direction) {
      const newData = _.orderBy(rowsDispatch, (o: any) => Number(o[orderBy]), [direction]);
      return newData;
    }
    return rowsDispatch;
  }, [rowsDispatch, orderBy, direction]);

  const addItemSelect = useCallback(() => {
    addItem(checked.all);
    if (reFetch) reFetch();
  }, [addItem, checked, reFetch]);

  return (
    <>
      <NavbarDispatch libby={libby} checked={checked} resetCheck={resetCheck} addOrdersHandle={addItemSelect} />
      <ScreenAligned title="">
        <SearchFilters onFilterChange={onFilterChange} initFetch={filter} courierService={courierService} />
        {working && <LoadingTable />}
        <InfoTable columns={columns || columnsDispatch} rows={rows || dataShow} onBottomScroll={fetchMore} rowIdKey="dispatch_id" onSortChange={onSortChange} orderBy={orderBy} direction={direction} working={working} />
        <LoadingData label={`${t('Loaded registers')}`} working={working} cant={rows?.length || rowsDispatch?.length} />
      </ScreenAligned>
    </>
  );
};

export const DispatchOrderListTable = DatabaseConnector(DispatchOrderListTableRaw)('ster_dispatch_item');
