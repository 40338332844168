import { LibbyFetchDAO } from './LibbyFetchDAO';

export class DispatchZoneEnabledDAO extends LibbyFetchDAO {
  constructor() {
    super('ster_dispatch_zone_enabled', 'dispatch_zone_id');
  }

  getAll() {
    return this.query().limit(0, 10000).run();
  }

  getAllDispatchZoneEnableWithCourier({ typeCourierSearch }: { typeCourierSearch?: number }) {
    let ls = this.query();
    let needsAnd: boolean = false;
    if (typeCourierSearch) {
      if (needsAnd) ls = ls.and();
      ls = ls.groupStart();
      ls = ls.equals('courier.courier_id', typeCourierSearch);
      ls = ls.groupEnd();
      needsAnd = true;
    }
    return ls.run();
  }
}
