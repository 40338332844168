import { DISPATCH_ITEM_STATE, ORDER_STATE, DISPATCH_STATE } from '../../../const';
import { Dispatch, Dispatch_item } from '../../../interfaces/business';
import { LibbyObject } from '../../../types';
import { filterDispatchItemsByState } from './filter';
import { printDispatchManifest } from './printDispatchManifest';

interface manifestDispatch {
  dispatch: Dispatch;
}

interface manifestDispatch {
  dispatch: Dispatch;
}

interface Response {
  success: boolean;
  error?: string;
  data?: manifestDispatch;
}

export const sendDispatch = async (data: Dispatch, libby: LibbyObject): Promise<Response> => {
  const { items } = data;

  try {
    if (
      filterDispatchItemsByState({
        dispatchItems: items,
        orderState: ORDER_STATE.READY_FOR_DELIVERY.toString()
      }).length !== items.length
    ) {
      return {
        success: false,
        error: 'You cannot send a dispatch with orders in another state that is not ready for delivery'
      };
    }

    const dispatchItemsSent = items.filter(({ dispatch_item_state: { dispatch_item_state_id } }: Dispatch_item) => dispatch_item_state_id === DISPATCH_ITEM_STATE.SENT);
    if (!dispatchItemsSent.length) {
      return { error: 'the dispatch is not ready to be sent', success: false };
    }

    const dataSave = await libby.ster_dispatch_send.save({
      ...data,
      dispatch_state: { dispatch_state_id: DISPATCH_STATE.SENT }
    });
    if (!dataSave?.success && dataSave?.success !== undefined) {
      return { success: false, error: dataSave.message };
    }
    const { success, error } = await printDispatchManifest(data);

    if (success) {
      return {
        success,
        data: { dispatch: dataSave }
      };
    }
    return { success: false, error };
  } catch (err: any) {
    return { success: false, error: err.toString() };
  }
};
