import React from 'react';
import { DatabaseConnector } from '@phinxlab/libby-rest-web';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import { LiquidationsList, LiquidationsListGroup } from './routes';
import { LibbyObject } from '../../types/types';
import { useBillingTableLogic, useFilerParamsId } from './hook';
import { useBreadcrumbs } from '../../hooks';

const LiquidationsRouter = ({ libby }: LibbyObject) => {
  const { path } = useRouteMatch();

  const { paramsFetch, filter, setFilter, resetFilter } = useFilerParamsId({
    orderInit: 'order_liquidation_group_id',
    daoName: 'ster_order_liquidation_group',
    directionInit: 'desc'
  });

  const { rows, columns, working, fetchMore, reFetch } = useBillingTableLogic({
    libby,
    paramsFetch,
    actions: true,
    path
  });

  const title = useBreadcrumbs('Liquidations');

  return (
    <Switch>
      <Route path={`${path}/detail/:id`}>
        <LiquidationsListGroup libby={libby} title={title} />
      </Route>
      <Route path={`${path}`}>
        <LiquidationsList columns={columns} filter={filter} setFilter={setFilter} resetFilter={resetFilter} working={working} reFetch={reFetch} fetchMore={fetchMore} rows={rows} libby={libby} title={title} />
      </Route>
    </Switch>
  );
};

export const Liquidations = DatabaseConnector(LiquidationsRouter)('ster_order_liquidation', 'ster_new_order_liquidation', 'ster_order_liquidation_group', 'ster_new_order_liquidation_confirm');
