import React, { MouseEventHandler, useCallback, useRef, KeyboardEvent } from 'react';
import QrReader from 'react-qr-reader';
import CloseIcon from '@material-ui/icons/Close';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import { IconButton, Dialog, Typography, Grid, TextField } from '@material-ui/core';
import { ThemeProvider, makeStyles } from '@material-ui/styles';
import DialogContentText from '@material-ui/core/DialogContentText';
import { useTranslation } from '../../../../../services/translation';
import { Order } from '../../../../../interfaces';

const useStyles = makeStyles(() => ({
  modalTitle: {
    '& h2.MuiTypography-h6': {
      textTransform: 'uppercase',
      fontSize: '24px !important',
      fontWeight: 700
    }
  },
  modalContent: {
    fontSize: 16
  },
  textField: {
    marginTop: '5px',
    width: '100%'
  },
  root: {
    '& .MuiPaper-root': {
      borderRadius: '8px'
    }
  }
}));

type SendDispatchItemDialogProps = {
  open: boolean;
  onCancel: MouseEventHandler;
  onConfirm: any;
  customTheme: { [k: string]: any } | null;
  dispatchZone: string;
  order: Order;
};

const dummyFunc = () => {};

export const SendDispatchItemDialog = ({ open = false, onCancel = dummyFunc, onConfirm = dummyFunc, customTheme = null, dispatchZone, order }: SendDispatchItemDialogProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const productInput = useRef<HTMLInputElement>(null);

  const handleScanQR = async (scanData: any) => {
    if (scanData && scanData !== '') {
      onConfirm(scanData);
    }
  };

  const validateButton = (event: KeyboardEvent<HTMLInputElement>) => event.key === 'Enter' && event.currentTarget.value.length;

  const onPickUpProduct = useCallback((event: KeyboardEvent<HTMLInputElement>) => validateButton(event) && onConfirm(event.currentTarget.value), [onConfirm]);

  const dialog = (
    <Dialog open={open} onClose={onCancel} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" className={classes.root}>
      <DialogTitle className={classes.modalTitle} id="alert-dialog-title">
        <Grid container justify="space-between" alignItems="center">
          {t('Transport packages')}
          {onCancel && (
            <>
              <IconButton aria-label="close" onClick={onCancel}>
                <CloseIcon />
              </IconButton>
            </>
          )}
        </Grid>
      </DialogTitle>
      <DialogContent>
        <DialogContentText className={classes.modalContent} id="alert-dialog-description">
          <Typography style={{ marginBottom: '5px' }} variant="body1" color="textPrimary" component="div">
            {order?.items?.length < 2
              ? t('Please, leave the product of the order ($$$) in the dispatch zone ($$$$).').replace('$$$', order?.order_id).replace('$$$$', dispatchZone)
              : t('Please, leave the products of the order ($$$) in the dispatch zone ($$$$).').replace('$$$', order?.order_id).replace('$$$$', dispatchZone)}
          </Typography>

          <QrReader onError={(err: any) => console.error(err)} onScan={handleScanQR} style={{ width: '100%' }} />
          <TextField
            label={t('Code')}
            className={classes.textField}
            variant="standard"
            InputProps={{
              inputRef: productInput,
              onKeyDown: (event: KeyboardEvent<HTMLInputElement>) => {
                onPickUpProduct(event);
              }
            }}
          />
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
  return !customTheme ? dialog : <ThemeProvider theme={customTheme}>{dialog}</ThemeProvider>;
};
