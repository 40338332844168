import { AnyObject } from '../types/types';

export const OrderSources: AnyObject = {
  1: 'Source 1',
  2: 'Source 2',
  3: 'Source 3',
  4: 'Source 4',
  5: 'Source 5',
  6: 'Source 6',
  8: 'Source 8',
  9: 'Source 9',
  10: 'Source 10'
};

export enum ORDER_DOCUMENT_SOURCE {
  ERP = '1',
  MARKETPLACE = '2',
  CLIENT = '3',
  COURIER = '4',
  STER = '5'
}
