import { LibbyFetchDAO } from './LibbyFetchDAO';

export class BuyerOdooDAO extends LibbyFetchDAO {
  constructor() {
    super('ster_buyer_odoo', 'client');
  }

  getAll() {
    return this.query().limit(0, 10000).run();
  }

  getBuyerByNameId(client: string) {
    return this.query().equals('client', client).run();
  }
}
