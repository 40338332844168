import { LibbyFetchDAO } from './LibbyFetchDAO';

export class DispatchCollectCloseDAO extends LibbyFetchDAO {
  constructor() {
    super('ster_dispatch_collect_close', 'collect_id');
  }

  getAll() {
    return this.query().limit(0, 10000).run();
  }
}
