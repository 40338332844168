import React, { useEffect, useState } from 'react';
import SearchIcon from '@material-ui/icons/Search';
import { Grid, TextField } from '@material-ui/core';
import { AnyObject } from '../../../types';
import { useTranslation } from '../../../services/translation';
import { makeFilter } from '../utils';
import { FilterBar } from '../../../components/FilterBar';
import { gridBreakpoints, gridBreakpointsFields } from '../../../const';
import useDebounce from '../../../hooks/useDebounce';
import { SelectMessageFrom } from './SelectMessageFrom';

type SearchFiltersProps = {
  onFilterChange: (makeFilter: object) => void;
  filter: AnyObject;
};

const SearchFiltersRaw = ({ onFilterChange, filter }: SearchFiltersProps) => {
  const { t } = useTranslation();

  const [search, setSearch] = useState<string>(filter.search && filter.search.length > 0 ? filter.search[0].value : '');
  const [name, setName] = useState<string>(filter.importId && filter.importId.length > 0 ? filter.importId[0].value : '');
  const [subject, setSubject] = useState<string>(filter.importId && filter.importId.length > 0 ? filter.importId[0].value : '');
  const [from, setFrom] = useState<string>(filter.importId && filter.importId.length > 0 ? filter.importId[0].value : '0');
  const [id, setId] = useState<string>(filter.importId && filter.importId.length > 0 ? filter.importId[0].value : '');
  const searchDebounced = useDebounce(search, 500);
  const nameDebounced = useDebounce(name, 500);
  const subjectDebounced = useDebounce(subject, 500);
  const IdDebounced = useDebounce(id, 500);

  useEffect(() => {
    onFilterChange(
      makeFilter({
        search: searchDebounced,
        name: nameDebounced,
        from,
        subject: subjectDebounced,
        id: IdDebounced
      })
    );
  }, [onFilterChange, IdDebounced, from, nameDebounced, subjectDebounced, searchDebounced]);
  const handleReset = () => {
    setSubject('');
    setFrom('');
    setSearch('');
    setName('');
    setId('');
    onFilterChange(makeFilter({}));
  };
  const handleChangeFrom = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFrom(event.target.value);
  };

  return (
    <FilterBar
      defaultFilter={
        <Grid item {...gridBreakpoints}>
          <TextField
            id="standard-search"
            placeholder={t('Search')}
            type="search"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            fullWidth
            InputLabelProps={{
              shrink: true
            }}
            InputProps={{
              endAdornment: <SearchIcon />
            }}
          />
        </Grid>
      }
      handleReset={handleReset}
    >
      <Grid item {...gridBreakpointsFields}>
        <SelectMessageFrom optionAll sort="asc" title="Message From" inputProps={{ value: from, onChange: handleChangeFrom }} />
      </Grid>
      <Grid item {...gridBreakpointsFields}>
        <TextField
          id="name"
          label={t('Name')}
          type="search"
          value={name}
          onChange={(e) => setName(e.target.value)}
          fullWidth
          InputLabelProps={{
            shrink: true
          }}
        />
      </Grid>
      <Grid item {...gridBreakpointsFields}>
        <TextField
          id="subject"
          label={t('Subject')}
          type="search"
          value={subject}
          onChange={(e) => setSubject(e.target.value)}
          fullWidth
          InputLabelProps={{
            shrink: true
          }}
        />
      </Grid>
      <Grid item {...gridBreakpointsFields}>
        <TextField
          id="id"
          label={t('Id')}
          type="search"
          value={id}
          onChange={(e) => setId(e.target.value)}
          fullWidth
          InputLabelProps={{
            shrink: true
          }}
        />
      </Grid>
    </FilterBar>
  );
};

export const SearchFilters = React.memo(SearchFiltersRaw);
