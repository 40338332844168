import { Alert } from '../../../interfaces/business';
import { makeBusinessEntity } from '../../../lib/libby/generator/makeBusinessEntity';

const DAO_NAME = 'ster_alert_updated';

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch }
} = makeBusinessEntity<Alert>(DAO_NAME);

// hooks
export const useAlertUpdatedFetchById = fetchById;
export const useAlertUpdatedLibbyCall = libbyCall;
export const useAlertUpdatedDAO = libbyDAO;
export const useAlertUpdatedLibbyFetch = libbyFetch;
