import React from 'react';
import { DatabaseConnector } from '@phinxlab/libby-rest-web';
import { useLibbyFetch, OptionsLibbyFetch } from '../../hooks/useLibbyFetch';
import { AccountProviderContext } from './AccountProviderContext';
import { AnyObject } from '../../types/types';

type AccountProviderProps = {
  libby: object;
  children: React.ReactNode;
};

const paramsFetch: OptionsLibbyFetch = {
  daoName: 'admin_account_role'
};

const AccountProviderRaw = ({ libby, children }: AccountProviderProps) => {
  const { data: accountTypes, working }: { data: AnyObject; working: boolean } = useLibbyFetch(libby, paramsFetch);

  return (
    <AccountProviderContext.Provider
      value={{
        accountTypes,
        working
      }}
    >
      {children}
    </AccountProviderContext.Provider>
  );
};

export const AccountProvider = DatabaseConnector(AccountProviderRaw)('admin_account_role');
