import { useCallback, useState } from 'react';
import { useTranslation } from '../../../../../../../services/translation';
import { IAddress, IProvince, IShippingAddress } from '../types';
import { BudgetInitialValues } from '../types/BudgetContextValue';

export const useShippingAddress = (initialValues: BudgetInitialValues) => {
  const { t } = useTranslation();
  const [shippingAddress, setShippingAddress] = useState<IShippingAddress>(initialValues.shippingAddress);

  const selectShippingAddress = useCallback((address: any) => {
    // @ts-ignore
    setShippingAddress((prev) => {
      const { shippingAddresses } = prev;
      return {
        ...prev,
        selectedShippingAddress: address,
        selectedShippingCity: shippingAddresses[address]?.city?.name,
        selectedShippingState: shippingAddresses[address]?.city?.state,
        selectedShippingCityId: shippingAddresses[address]?.city?.city_id,
        selectedShippingStreet: shippingAddresses[address]?.street,
        selectedShippingStreetNumber: shippingAddresses[address]?.street_number,
        selectedShippingZipCode: shippingAddresses[address]?.zip,
        selectedShippingFloor: shippingAddresses[address]?.floor,
        selectedShippingDepartment: shippingAddresses[address]?.department,
        selectedShippingComments: shippingAddresses[address]?.comments
      };
    });
  }, []);

  const pushShippingAddressOptions = useCallback(
    (province: IProvince, address: IAddress, provinces: any) => {
      setShippingAddress((prev: any) => {
        const newOption = {
          selectedShippingState: provinces?.find((item: any) => item.state_id === province.state_id),
          id: prev.shippingAddressOptions.length,
          value: `${t('Street')}: ${address.street};
            ${t('Street Number')}: ${address.street_number};
            ${t('City')}: ${address.city.name};
            ${t('Province')}: ${province.name};
            ${t('Zip Code')}: ${address.zip};
            ${t('Floor')}: ${address.floor};
            ${t('Department')}: ${address.department};
            ${t('Comments')}: ${address.comments || ''};`
        };

        const newShippingAdress = {
          city: {
            city_id: address.city.city_id,
            name: address.city.name,
            zip: +address.zip,
            enabled: true,
            state: {
              name: province.name,
              state_id: province.state_id
            }
          },
          department: address.department,
          floor: address.floor,
          street: address.street,
          street_number: +address.street_number,
          zip: +address.zip
        };

        return {
          ...prev,
          shippingAddresses: [...prev.shippingAddresses, newShippingAdress],
          selectedShippingAddress: newOption.id,
          shippingAddressOptions: [...prev.shippingAddressOptions, newOption]
        };
      });
      // @ts-ignore
      setShippingAddress((prev) => {
        const { shippingAddresses } = prev;
        const lastIndex = shippingAddresses.length - 1;
        return {
          ...prev,
          selectedShippingAddress: lastIndex,
          selectedShippingCity: shippingAddresses[lastIndex]?.city?.name,
          selectedShippingState: shippingAddresses[lastIndex]?.city?.state,
          selectedShippingCityId: shippingAddresses[lastIndex]?.city?.city_id,
          selectedShippingStreet: shippingAddresses[lastIndex]?.street,
          selectedShippingStreetNumber: shippingAddresses[lastIndex]?.street_number,
          selectedShippingZipCode: shippingAddresses[lastIndex]?.zip,
          selectedShippingFloor: shippingAddresses[lastIndex]?.floor,
          selectedShippingDepartment: shippingAddresses[lastIndex]?.department,
          selectedShippingComments: shippingAddresses[lastIndex]?.comments
        };
      });
    },
    [t]
  );

  const addShippingAddressOptions = useCallback<any>(
    (newClient: any, provinces: any[]) => {
      setShippingAddress((prev) => {
        return {
          ...prev,
          shippingAddresses: newClient?.data?.shipment_address,
          shippingAddressOptions: newClient?.data?.shipment_address?.map((item: any, index: any) => ({
            id: index,
            value: `${t('Street')}: ${item.street};
                      ${t('Street Number')}: ${item.street_number};
                      ${t('City')}: ${item.city?.name};
                      ${t('Province')}: 
                      ${provinces?.find((province: any) => province.state_id === item.city?.state?.state_id?.toString()).name};
                      ${t('Zip Code')}: ${item.zip}; 
                      ${t('Floor')}: ${item.floor}; 
                      ${t('Department')}: ${item.department};
                      ${t('Comments')}: ${item.comments || ''};`
          }))
        };
      });
      if (newClient?.data) selectShippingAddress(newClient?.data?.shipment_address?.length - 1);
    },
    [t, setShippingAddress, selectShippingAddress]
  );

  const editShippingAddress = useCallback((address) => {
    setShippingAddress((prev) => ({
      ...prev,
      selectedShippingCity: address.editedShippingCity,
      selectedShippingState: address.editedShippingState,
      selectedShippingStreet: address.editedShippingStreet,
      selectedShippingStreetNumber: address.editedShippingStreetNumber,
      selectedShippingZipCode: address.editedShippingZipCode,
      selectedShippingFloor: address.editedShippingFloor,
      selectedShippingDepartment: address.editedShippingDepartment
    }));
  }, []);

  return {
    pushShippingAddressOptions,
    addShippingAddressOptions,
    selectShippingAddress,
    shippingAddress,
    editShippingAddress
  };
};
