import React from 'react';
import { Typography } from '@material-ui/core';
import { AnyObject } from '../../../types';
import { cartBox } from '../types/Collect';
import { useTranslation } from '../../../services/translation';

type LabelProductProps = {
  data: {
    product: AnyObject;
    collectItem: {
      cartBox: cartBox;
      collectItemState: {
        collect_item_state_id: string;
        name: string;
      };
    };
  };
  labelStyle: string;
  skuAndSerialBoxStyle: string;
  skuStyle: string;
};

export const LabelProduct = ({ data, labelStyle, skuAndSerialBoxStyle, skuStyle }: LabelProductProps) => {
  const { t } = useTranslation();

  return (
    <label htmlFor={`productSerial-${data?.product?.product_id || ''}`} className={labelStyle}>
      <Typography variant="h6" color="initial">
        {`${t('Pick up the product')} '${data.product.name}'`}
      </Typography>
      <div className={skuAndSerialBoxStyle}>
        <Typography variant="body2" color="initial" className={skuStyle}>
          {data?.product?.sku || `${t('Without Info')}`}
        </Typography>
      </div>
    </label>
  );
};
