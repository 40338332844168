import React from 'react';
import { SearchFilters } from './components';
import { RowsType } from '../../../../utils/tables';
import InfoTable from '../../../components/InfoTable';
import LoadingData from '../../../components/LoadingData';
import { AnyObject, HeadCellsType } from '../../../../types';
import { useTranslation } from '../../../../services/translation';
import { ScreenAligned } from '../../../../components/ScreenAligned/ScreenAligned';

type CarriersListProps = {
  rows: Array<RowsType>;
  columns: Array<HeadCellsType>;
  fetchMore: () => void;
  onSortChange?: (orderBy: string, direction: 'asc' | 'desc') => void;
  orderBy?: string;
  direction?: 'asc' | 'desc';
  working: boolean;
  title: string;
  additionalTitle?: React.ReactElement;
  setFilter: (makeFilter: object) => void;
  filter: AnyObject;
};

export const CarriersList = ({ title, columns, rows, working, fetchMore, onSortChange, orderBy, direction, additionalTitle, setFilter, filter }: CarriersListProps) => {
  const { t } = useTranslation();
  return (
    <ScreenAligned title={title} additionalTitle={additionalTitle}>
      <SearchFilters onFilterChange={setFilter} filter={filter} />
      <InfoTable rows={rows} columns={columns} working={working} onBottomScroll={fetchMore} rowIdKey="transportation_company_id" onSortChange={onSortChange} orderBy={orderBy} direction={direction} />
      <LoadingData label={`${t('Loaded registers')}`} working={working} cant={rows.length} />
    </ScreenAligned>
  );
};
