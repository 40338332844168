export enum COURIER_SERVICE {
  FRAVEGA_ENVIOS = 1,
  ANDREANI = 2,
  NOVA_FLEX = 3,
  ENVIO_PACK = 4,
  CORREO_COMPRAS = 5,
  MERCADO_ENVIOS = 8,
  MERCADO_ENVIOS_FLEX = 9,
  MERCADO_ENVIOS_FULL = 10,
  TRANSPORTE = 11,
  CORREO_ARGENTINO = 12,
  RETIRO_EN_SUCURSAL = 13,
  RETIRO_EN_PLANTA = 14,
  ENVIO_EXPRESS = 15,
  INTERNAL = 16,
  EXTERNAL = 17,
  CORREO_ARGENTINO_EXPRESS = 18,
  SIN_ENVIO = 19,
  TALAVERA_1 = 25,
  TALAVERA_2,
  TALAVERA_3
}
