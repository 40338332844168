import { LibbyFetchDAO } from './LibbyFetchDAO';

export class DispatchCartBoxDAO extends LibbyFetchDAO {
  constructor() {
    super('ster_dispatch_cart_box', 'cart_box_id');
  }

  getAll() {
    return this.query().limit(0, 10000).run();
  }

  getCart(cart_id: string) {
    return this.query().equals('cart_id', cart_id).run();
  }
}
