import React, { useCallback } from 'react';
import { Autocomplete } from '@material-ui/lab';
import { TextField } from '@material-ui/core';
import { GeneralSelect } from '../GeneralSelect';
import { AnyObject } from '../../types';
import { DataGenerateSelectType } from '../../utils/generateSelect';
import { useTranslation } from '../../services/translation';

const data = [
  { id: '2020', value: '2020' },
  { id: '2021', value: '2021' },
  { id: '2022', value: '2022' },
  { id: '2023', value: '2023' },
  { id: '2024', value: '2024' }
];

type SelectYearProps = {
  multiple?: boolean;
  inputProps: AnyObject;
  optionAll?: boolean;
  [k: string]: any;
  disabled?: boolean;
  sort?: boolean | 'asc' | 'desc';
  label?: string;
};

export const SelectYear = ({ multiple = false, inputProps = {}, optionAll = false, disabled = false, sort, label, ...styles }: SelectYearProps) => {
  const { t } = useTranslation();

  const onChangeAutoComplete = useCallback(
    (event, newValue: DataGenerateSelectType[]) => {
      inputProps.onChange(
        newValue.map((value: DataGenerateSelectType) => ({
          value: value.id,
          path: ''
        }))
      );
    },
    [inputProps]
  );

  return (
    <>
      {multiple ? (
        <Autocomplete
          disabled={disabled}
          style={{ display: 'inline-block', width: '100%' }}
          multiple
          id="tags-outlined"
          options={data || []}
          getOptionLabel={(option: { id: string; value: string }) => option.value}
          filterSelectedOptions
          onChange={onChangeAutoComplete}
          renderInput={(params) => <TextField {...params} variant="standard" label={t('Year')} placeholder="" />}
        />
      ) : (
        <GeneralSelect disabled={disabled} working={false} label="Year" optionAll={optionAll} data={data || []} inputProps={inputProps} {...styles} sort={false} />
      )}
    </>
  );
};
