import { makeValidator, ValidatorRule, ValidatorType } from '../../../../../utils';

export const formReportingGoalPeriodInitialValues = {
  value: '',
  start_date: '',
  end_date: ''
};

const validations: ValidatorRule[] = [
  { key: 'value', validators: [ValidatorType.REQUIRED] },
  { key: 'trimester_date', validators: [ValidatorType.REQUIRED] },
  { key: 'year', validators: [ValidatorType.REQUIRED] }
];

export const validateReportingGoalPeriodAdd = makeValidator(validations);
