import { CommonOrderColumns } from './CommonOrderColumns';
import { ReportOrderColumns } from './ReportOrderColumns';
import { B2BOrderColumns } from './B2BOrderColumns';
import { OrderLiquidationColumns } from './OrderLiquidationColums';
import { OrderLiquidationGroupColumns } from './OrderLiquidationGroup';
import { ReportObjetiveSalesColumns, ReportObjetiveSalesWeeksInformationColumns } from './ReportObjetiveSalesColumns';
import { OrderLiquidationCompareColumns } from './OrderLiquidationCompareColumns';

export const columnsOrder = [
  CommonOrderColumns.orderId,
  CommonOrderColumns.soNumber,
  CommonOrderColumns.orderState,
  CommonOrderColumns.ownerId,
  CommonOrderColumns.marketplace,
  CommonOrderColumns.buyer,
  CommonOrderColumns.document,
  CommonOrderColumns.phoneNumber,
  CommonOrderColumns.documents,
  CommonOrderColumns.items,
  CommonOrderColumns.courier,
  CommonOrderColumns.service,
  CommonOrderColumns.total,
  CommonOrderColumns.updatedBy,
  CommonOrderColumns.date,
  CommonOrderColumns.created_at,
  CommonOrderColumns.detail,
  CommonOrderColumns.logs,
  CommonOrderColumns.message
];
export const columnsOrdersOffline = [
  CommonOrderColumns.orderId,
  CommonOrderColumns.soNumber,
  CommonOrderColumns.advance_sale,
  CommonOrderColumns.paid_in_advance,
  CommonOrderColumns.orderState,
  CommonOrderColumns.ownerId,
  CommonOrderColumns.marketplace,
  CommonOrderColumns.buyer,
  CommonOrderColumns.document,
  CommonOrderColumns.phoneNumber,
  CommonOrderColumns.documents,
  CommonOrderColumns.items,
  CommonOrderColumns.courier,
  CommonOrderColumns.service,
  CommonOrderColumns.total,
  CommonOrderColumns.updatedBy,
  CommonOrderColumns.date,
  CommonOrderColumns.created_at,
  CommonOrderColumns.detail,
  CommonOrderColumns.logs,
  CommonOrderColumns.message
];

export const columnsOrderB2B = [
  B2BOrderColumns.actions,
  B2BOrderColumns.orderId,
  B2BOrderColumns.soNumber,
  B2BOrderColumns.orderState,
  B2BOrderColumns.complete_name,
  B2BOrderColumns.buyer,
  B2BOrderColumns.document,
  B2BOrderColumns.marketplace,
  CommonOrderColumns.documents,
  B2BOrderColumns.items,
  B2BOrderColumns.total,
  B2BOrderColumns.currency,
  B2BOrderColumns.updatedBy,
  B2BOrderColumns.created_at,
  B2BOrderColumns.detail,
  B2BOrderColumns.logs,
  B2BOrderColumns.message
];

export const columnsOrderReport = [
  CommonOrderColumns.orderId,
  CommonOrderColumns.soNumber,
  ReportOrderColumns.state,
  ReportOrderColumns.business,
  ReportOrderColumns.StateMeta,
  ReportOrderColumns.daysMetaState,
  ReportOrderColumns.totalTime,
  ReportOrderColumns.marketplace,
  ReportOrderColumns.buyer,
  ReportOrderColumns.totalPrice,
  ReportOrderColumns.created_at,
  CommonOrderColumns.courier,
  CommonOrderColumns.detail
];

export const columnsTagmanager = [
  CommonOrderColumns.orderId,
  CommonOrderColumns.soNumber,
  CommonOrderColumns.buyer,
  CommonOrderColumns.orderState,
  CommonOrderColumns.marketplace,
  CommonOrderColumns.phoneNumber,
  CommonOrderColumns.zipCode,
  CommonOrderColumns.courier,
  CommonOrderColumns.service,
  CommonOrderColumns.domicile,
  CommonOrderColumns.date,
  CommonOrderColumns.created_at,
  CommonOrderColumns.statePrinting,
  CommonOrderColumns.returnedReason
];

export const columnsLiquidation = [
  OrderLiquidationColumns.orderId,
  OrderLiquidationColumns.soNumber,
  OrderLiquidationColumns.ownerId,
  OrderLiquidationColumns.total,
  OrderLiquidationColumns.commissions,
  OrderLiquidationColumns.shipping,
  OrderLiquidationColumns.discount,
  OrderLiquidationColumns.cost_interest,
  OrderLiquidationColumns.bonus_charger,
  OrderLiquidationColumns.return_charge,
  OrderLiquidationColumns.shipping_charge
];

export const columnsLiquidationCompare = [
  OrderLiquidationCompareColumns.orderId,
  OrderLiquidationCompareColumns.soNumber,
  OrderLiquidationCompareColumns.ownerId,
  OrderLiquidationCompareColumns.total,
  OrderLiquidationCompareColumns.total_db,
  OrderLiquidationCompareColumns.commissions,
  OrderLiquidationCompareColumns.shipping,
  OrderLiquidationCompareColumns.discount,
  OrderLiquidationCompareColumns.cost_interest,
  OrderLiquidationCompareColumns.bonus_charger,
  OrderLiquidationCompareColumns.return_charge,
  OrderLiquidationCompareColumns.shipping_charge
];

export const columnsLiquidationGroup = [
  OrderLiquidationGroupColumns.orderLiquidationGroupId,
  OrderLiquidationGroupColumns.reference,
  OrderLiquidationGroupColumns.startDate,
  OrderLiquidationGroupColumns.endDate,
  OrderLiquidationGroupColumns.marketplace,
  OrderLiquidationGroupColumns.username,
  OrderLiquidationGroupColumns.liquidation_date,
  OrderLiquidationGroupColumns.detail,
  OrderLiquidationGroupColumns.files
];

export const columnsReportObjetiveSales = [ReportObjetiveSalesColumns.product_category, ReportObjetiveSalesColumns.sku, ReportObjetiveSalesColumns.description, ReportObjetiveSalesColumns.quantity, ReportObjetiveSalesColumns.amount];

export const columnsReportObjetiveSalesWeeks = [
  ReportObjetiveSalesWeeksInformationColumns.name,
  ReportObjetiveSalesWeeksInformationColumns.quantity_objective,
  ReportObjetiveSalesWeeksInformationColumns.quantity_sale,
  ReportObjetiveSalesWeeksInformationColumns.amount_objective,
  ReportObjetiveSalesWeeksInformationColumns.amount_sale
];
