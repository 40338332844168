import { LibbyFetchDAO } from '../LibbyFetchDAO';

export class GoalTypeUpdateDAO extends LibbyFetchDAO {
  constructor() {
    super('ster_goal_type_update', 'goal_type_id');
  }

  getAllGoalType() {
    return this.query().limit(0, 10000).run();
  }
}
