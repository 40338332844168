import React, { useMemo, MouseEvent, useCallback } from 'react';
import { LibbyObject } from '../../../../../types/types';
import CustomModal from '../../../../../services/customFormDialog';
import { useLibbyFetch, OptionsLibbyFetch } from '../../../../../hooks/useLibbyFetch';
import { ItemsTableSearch } from '../../../../components/ItemsTableSearch';
import { columnsProduct } from '../../../../../models/products/columns';
import { ExportDialogComponent } from '../../../../../components/ExportDialog';
import { MakeCell } from 'routes/components/MakeCell';
import EditIcon from '@material-ui/icons/Edit';
import ArchiveIcon from '@material-ui/icons/Archive';
import UnarchiveIcon from '@material-ui/icons/Unarchive';
import ConfirmDialog from 'components/ConfirmDialog';
import { useTranslation } from 'services/translation';
import { AnyObject } from '../../../../../types/types';
import { useSnackbar } from 'notistack';
import { UserProductPermissions } from '../../../../../types/types';

const SearchDialogModal = CustomModal(ExportDialogComponent);
const ConfirmModal = CustomModal(ConfirmDialog);

export const handleOpenItemsModal = async (e: MouseEvent, items: string) => {
  e.stopPropagation();
  try {
    await SearchDialogModal.show({
      title: 'Items',
      id: 'order_item_id',
      properties: ['name', 'sku'],
      label: 'Item/Sku',
      data: items,
      maxWidth: 'md',
      render: () => <ItemsTableSearch order_id={items} />
    });
  } catch (error: any) {
    // nothing
  }
};

interface UseProductsTableLogicProps {
  libby: LibbyObject;
  paramsFetch: OptionsLibbyFetch;
  detailsRedirection: (order_id: string) => void;
  columnCheckEnabled?: boolean;
  actions?: boolean;
  path: string;
  deliveryDate?: boolean;
  isCanalOffLine?: boolean;
  userPermissions: UserProductPermissions;
}

export const useProductsTableLogic = ({ libby, paramsFetch, path, userPermissions }: UseProductsTableLogicProps) => {
  const { data, working, fetchMore, updateData, reFetch } = useLibbyFetch(libby, paramsFetch);
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const handleOpenManageModal = useCallback(
    async (action: string, rowData: AnyObject) => {
      try {
        const validation = await ConfirmModal.show({
          title: t('Managing'),
          content: t(action),
          confirmText: t('Yes'),
          cancelText: t('Cancel')
        });
        if (validation) {
          await libby.ster_product.save({ ...rowData, active: !rowData.active });
          enqueueSnackbar(t(rowData.active ? 'Product archived successfully' : 'Product unarchived successfully'), { variant: 'success' });
          reFetch();
        }
      } catch (err) {
        return false;
      }
    },
    [enqueueSnackbar, libby.ster_product, reFetch, t]
  );

  const rows: any[] = useMemo(() => {
    const dataOrder = data as any[];
    const parsedData = dataOrder.map((product) => {
      const physical = product.attributes?.physical;
      const attributes = physical
        ? `Width: ${physical?.width ? physical?.width : 'S/N'}, Height: ${physical?.height ? physical?.height : 'S/N'}, Depth: ${physical?.depth ? physical?.depth : 'S/N'}, Weight: ${physical?.weight ? physical?.weight : 'S/N'}`
        : 'Sin atributos';
      return {
        ...product,
        brand: product.brand?.name,
        category: product.category?.name,
        attributes: attributes,
        productType: product.productType?.name,
        edit: (
          <MakeCell
            label=""
            icon={EditIcon}
            path={`${path}/detail/${product.product_id}`}
            onClick={(e: any) => {
              e.stopPropagation();
            }}
          />
        ),
        manage: (
          <MakeCell
            label=""
            icon={product.active ? ArchiveIcon : UnarchiveIcon}
            onClick={() => handleOpenManageModal(product.active ? t('Are you sure you want to archive $$$$?').replace('$$$$', product.name) : t('Are you sure you want to unarchive $$$$?').replace('$$$$', product.name), product)}
          />
        )
      };
    });
    return parsedData;
  }, [data, path, t, handleOpenManageModal]);

  const columns = useMemo(() => {
    let columnsOrderAll = columnsProduct;
    if (userPermissions.full_permission || userPermissions.edit_permission) return columnsOrderAll;
    else return columnsOrderAll.filter((col) => col.id !== 'manage');
  }, [userPermissions]);

  return {
    working,
    rows,
    columns,
    fetchMore,
    data,
    updateData,
    reFetch
  };
};
