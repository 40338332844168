import React, { useCallback, MouseEvent, useMemo } from 'react';
import { DatabaseConnector } from '@phinxlab/libby-rest-web';
import { Grid, Menu, MenuItem, IconButton, Typography } from '@material-ui/core';
import { MoreVert } from '@material-ui/icons';
import { createStyles } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/styles';
import { useTranslation } from '../../../services/translation';
import { Collect } from '../../../interfaces/business';
import { LoadingTable } from '../../../components/LoadingTable';
import { CollectState } from '../../../const';
import { MenuItemComponentType } from '../../../components/MenuActions';

type ColumnActionsCollectProps = {
  collect: Collect;
  handleCancelCollect: (k?: Collect) => void;
  goToDetails: (row: Collect) => void;
};

const useStyles = makeStyles(() =>
  createStyles({
    anchor: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      margin: '0px 10px',
      width: '90%'
    }
  })
);

export const ColumnActionsCollectRaw = ({ collect, handleCancelCollect, goToDetails }: ColumnActionsCollectProps) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClose = useCallback(
    (e) => {
      e.stopPropagation();
      setAnchorEl(null);
    },
    [setAnchorEl]
  );

  const handleMenu = useCallback(
    (event) => {
      event.stopPropagation();
      setAnchorEl(event.currentTarget);
    },
    [setAnchorEl]
  );

  const MenuItemComponent = ({ onClick, title, disabled, loading, color = 'initial' }: MenuItemComponentType) => (
    <MenuItem onClick={onClick} disabled={!disabled && loading} className={classes.anchor}>
      <Typography variant="subtitle2" color={color}>
        {t(title)}
      </Typography>
      {loading && <LoadingTable size={18} />}
    </MenuItem>
  );

  const menuArray: MenuItemComponentType[] = useMemo(() => {
    const menu: MenuItemComponentType[] = [
      {
        title: 'Details',
        onClick: (e: MouseEvent) => {
          e.stopPropagation();
          goToDetails(collect);
        },
        disabled: false
      },
      {
        title: 'Cancel',
        onClick: (e: MouseEvent) => {
          e.stopPropagation();
          handleClose(e);
          handleCancelCollect(collect);
        },
        disabled: collect.collectState.collect_state_id === CollectState.COMPLETE || collect.collectState.collect_state_id === CollectState.CANCELLED,
        color: 'error'
      }
    ];

    return menu.filter((menuItems) => !menuItems.disabled);
  }, [collect, handleCancelCollect, goToDetails, handleClose]);

  return (
    <Grid container wrap="nowrap" justify="space-between" alignItems="center">
      <IconButton>
        <MoreVert
          type="button"
          onClick={(e) => {
            e.stopPropagation();
            handleMenu(e);
          }}
        />
      </IconButton>
      <Grid>
        <Menu
          id="menu-appbar-language"
          anchorEl={anchorEl}
          getContentAnchorEl={null}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left'
          }}
          keepMounted
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'left'
          }}
          open={!!anchorEl}
          onClose={handleClose}
        >
          {menuArray.map((item: MenuItemComponentType) => (
            <MenuItemComponent title={item.title} onClick={item.onClick} disabled={item.disabled} loading={item.loading} color={item.color} />
          ))}
        </Menu>
      </Grid>
    </Grid>
  );
};

export const ColumnActionsCollect = DatabaseConnector(ColumnActionsCollectRaw)('ster_dispatch_collect', 'ster_dispatch_collect_close');
