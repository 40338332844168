import { makeBusinessEntity } from '../../lib/libby/generator/makeBusinessEntity';
import { YearWeek } from '../../interfaces/business';

const DAO_NAME = 'ster_year_week';

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch }
} = makeBusinessEntity<YearWeek>(DAO_NAME);

// hooks
export const useYearWeekFetchById = fetchById;
export const useYearWeekLibbyCall = libbyCall;
export const useYearWeekDAO = libbyDAO;
export const useYearWeekLibbyFetch = libbyFetch;
