import Moment from 'moment';
import { FilterBarSelection } from '../../../../routes/Reporting/routes/ReportingList/FilterBar';
import { DataGenerateAutocompleteType } from '../../../../utils/generateAutocomplete';
import { LibbyFetchDAO } from '../LibbyFetchDAO';

export class OrderReportingOrdersHistoryByMarketplaceDAO extends LibbyFetchDAO {
  constructor() {
    super('ster_order_reporting_orders_history_marketplace', 'order_id');
  }

  getAll() {
    return this.query().limit(0, 10000).run();
  }

  async getByDate(filter: FilterBarSelection = { marketplaces: [], courier: [] }) {
    let ls = this.query();
    // FIXME QUERY BUILDER HAVE TO DECIDE IF HAVE TO AUTOMATICALLY INSERT AN AND
    let needsAnd: boolean = false;

    if (filter.marketplaces.length > 0) {
      if (needsAnd) ls = ls.and();
      ls = ls.groupStart();
      let first: boolean = true;
      filter.marketplaces.forEach(({ id }: DataGenerateAutocompleteType) => {
        if (!first) ls = ls.or();
        ls = ls.equals('source.marketplace.marketplace_id', id);
        first = false;
      });
      ls = ls.groupEnd();
      needsAnd = true;
    }
    if (filter.from && filter.to) {
      if (needsAnd) ls = ls.and();
      ls = ls.groupStart().higherOrEquals('created_at', Moment(filter.from).format('YYYY-M-D HH:mm:ss')).lowerOrEquals('created_at', Moment(filter.to).format('YYYY-M-D HH:mm:ss')).groupEnd();
    }

    if (filter.company && filter.company.length) {
      if (needsAnd) ls = ls.and();
      ls = ls.groupStart();
      let first: boolean = true;
      filter.company.forEach(({ id }: DataGenerateAutocompleteType) => {
        if (!first) ls = ls.or();
        ls = ls.equals('source.marketplace.company.company_id', id);
        first = false;
      });
      ls = ls.groupEnd();
      needsAnd = true;
    }

    if (filter?.canal && filter?.canal?.length) {
      if (needsAnd) ls = ls.and();
      ls = ls.groupStart();
      let first: boolean = true;
      filter?.canal.forEach(({ id }: DataGenerateAutocompleteType) => {
        if (!first) ls = ls.or();
        ls = ls.equals('source.order_source_type.order_source_type_id', id);
        first = false;
      });
      ls = ls.groupEnd();
    }
    ls.run();

    return ls;
  }
}
