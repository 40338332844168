import React, { useEffect, useState, useCallback } from 'react';
import SearchIcon from '@material-ui/icons/Search';
import { Grid, TextField } from '@material-ui/core';
import 'moment/locale/es';
import moment from 'moment';
import { DateClose } from '../../../../../components/DateClose';
import { SelectOrderShipment, SelectDispatchAllStatus } from '../../../../components';
import { useTranslation } from '../../../../../services/translation';
import { gridBreakpoints, gridBreakpointsFields } from '../../../../../const/gridBreakpoints';
import { FilterBar } from '../../../../../components/FilterBar';
import { useInitFilter, useInitFilterDebounced, useInitFilterArray } from '../../../../OrderReport/routes/OrderReportList/hook';
import { Filter } from '../../../../../types';
import { makeFilter } from '../../../utils/makeFilter';
import { DISPATCH_STATE } from '../../../../../const';

type SearchFiltersProps = {
  onFilterChange: (makeFilter: object) => void;
  initFetch: Filter;
  isReadOnly: boolean;
  resetFilter: () => void;
};

const SearchFiltersRaw = ({ onFilterChange, initFetch, isReadOnly, resetFilter }: SearchFiltersProps) => {
  const { t } = useTranslation();
  const [stateIsReadOnlyInit, setStateIsReadOnlyInit] = useState(isReadOnly);
  const { search, handleChange } = useInitFilterDebounced({
    initFetch: initFetch.search,
    init: '',
    onFilterChange,
    id: 'search',
    makeFilter
  });

  const { search: courierService, handleChangeFilter: handleChangeCourier } = useInitFilter({
    initFetch: initFetch.courierService,
    init: '0',
    onFilterChange,
    id: 'courierService',
    makeFilter
  });

  const { search: deliveryDate, handleChangeFilterString: setDeliveryDate } = useInitFilter({
    initFetch: initFetch.deliveryDate,
    onFilterChange,
    init: null,
    id: 'deliveryDate',
    date: true,
    makeFilter
  });

  const { search: state, handleChangeFilter: handleChangeState } = useInitFilterArray({
    initFetch: initFetch.status,
    onFilterChange,
    id: 'status',
    makeFilter
  });

  const { handleChangeFilter: handleChangeStateEmpty } = useInitFilterArray({
    initFetch: initFetch.status,
    onFilterChange,
    id: 'statusInit',
    makeFilter
  });

  const resetState = useCallback(() => {
    handleChangeStateEmpty(
      isReadOnly
        ? [
            { path: '', value: DISPATCH_STATE.SENT },
            { path: '', value: DISPATCH_STATE.CANCELLED }
          ]
        : [
            { path: '', value: DISPATCH_STATE.PENDING },
            { path: '', value: DISPATCH_STATE.PREPARING },
            { path: '', value: DISPATCH_STATE.READY }
          ]
    );
  }, [handleChangeStateEmpty, isReadOnly]);

  useEffect(() => {
    if (isReadOnly !== stateIsReadOnlyInit) {
      resetFilter();
      setStateIsReadOnlyInit(isReadOnly);
    }
  }, [isReadOnly, stateIsReadOnlyInit, resetFilter]);

  const filterArray = [];
  filterArray.push(
    <Grid item {...gridBreakpoints}>
      <SelectOrderShipment
        inputProps={{
          value: courierService || '',
          onChange: handleChangeCourier
        }}
        optionAll
        sort="asc"
        external
      />
    </Grid>
  );

  filterArray.push(
    <Grid item {...gridBreakpointsFields}>
      <TextField
        id="standard-search"
        placeholder={`${t('Search')} ${t('Number so').toLowerCase()}`}
        type="search"
        value={search}
        onChange={handleChange}
        fullWidth
        InputLabelProps={{
          shrink: true
        }}
        InputProps={{
          endAdornment: <SearchIcon />
        }}
      />
    </Grid>
  );

  return (
    <FilterBar handleReset={resetFilter} defaultFilter={filterArray[isReadOnly ? 1 : 0]}>
      <Grid item xs={12} md={6} lg={5}>
        <DateClose name={t('Delivery date')} value={moment.isMoment(deliveryDate) ? deliveryDate : null} onChange={setDeliveryDate} disableFuture={false} />
      </Grid>
      {isReadOnly && filterArray[0]}
      <Grid item xs={12} md={6} lg={5}>
        <SelectDispatchAllStatus allState={state} onChange={handleChangeState} onChangeEmpty={resetState} />
      </Grid>
    </FilterBar>
  );
};

export const SearchFilters = React.memo(SearchFiltersRaw);
