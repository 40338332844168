import React, { useCallback, useEffect } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { ThemeProvider } from '@material-ui/styles';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { useField, useForm, FormConfig } from 'react-final-form-hooks';
import Typography from '@material-ui/core/Typography';
import { ValidationErrors } from 'final-form';
import { makeGetErrorAndHelperText, textFieldProps } from '../../../../utils/materialHelpers';
import { useTranslation } from '../../../../services/translation';
import { AnyObject } from '../../../../types/types';
import { replaceQuotationMarks } from '../../../../utils';

const dummyFunc = () => {};

interface ZipFormDialogType extends FormConfig<any> {
  open: boolean;
  onCancel: any;
  onConfirm: Function;
  title: string;
  content: React.ReactNode;
  labelContent: string;
  cancelText: string;
  confirmText: string;
  customTheme: string | null;
  inputType: string;
  validate: (values: any) => ValidationErrors | Promise<ValidationErrors> | undefined;
  formInitialValues: AnyObject;
}

export const ZipFormDialog = ({
  open = false,
  onCancel = dummyFunc,
  onConfirm = dummyFunc,
  title = 'Confirmation Dialog',
  cancelText = 'Cancel',
  confirmText = 'confirmText',
  customTheme = null,
  validate,
  formInitialValues = {}
}: ZipFormDialogType) => {
  const { t } = useTranslation();
  const getErrorAndHelperText = makeGetErrorAndHelperText(t);
  const onSubmit = useCallback(
    async (data) => {
      onConfirm({
        ...data
      });
    },
    [onConfirm]
  );

  const { form, handleSubmit, submitError } = useForm({
    initialValues: formInitialValues,
    onSubmit,
    validate
  });

  const editNameState = useField('name', form);
  const inputMatches = useField('matches', form);

  useEffect(() => {
    if (!open) {
      // execute after submit form
      setTimeout(() => form.reset(), 0);
    }
  }, [open, form]);

  const dialog = (
    <Dialog open={open} onClose={onCancel} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">{title}</DialogTitle>
      <form noValidate onSubmit={handleSubmit}>
        <DialogContent>
          {formInitialValues.state_id && <TextField autoFocus {...textFieldProps(t('Province ID'))} value={formInitialValues.state_id} disabled />}
          <TextField
            autoFocus
            {...textFieldProps(t('Province Name'))}
            {...editNameState.input}
            {...getErrorAndHelperText(editNameState)}
            onChange={(event) => {
              const arrayMatch = inputMatches.input.value.toString().split(',');
              event.target.value = replaceQuotationMarks(event.target.value).replace(/,/, '');
              arrayMatch[0] = event.target.value;
              editNameState.input.onChange(event);
              event.target.value = arrayMatch.toString();
              inputMatches.input.onChange(event);
              return event;
            }}
            type="text"
          />

          <TextField
            {...textFieldProps(`${t('Matches')} (${t('comma delimited')})`)}
            {...inputMatches.input}
            {...getErrorAndHelperText(inputMatches)}
            type="text"
            multiline
            placeholder={`${t('Example')}:\nadrogué,adrogue `}
            onChange={(event) => {
              const { value } = event.target;
              event.target.value = replaceQuotationMarks(value);
              inputMatches.input.onChange(event);
              const arrayMatches = value.split(',');
              if (arrayMatches.length && arrayMatches[0].toUpperCase() !== editNameState.input.value.toUpperCase()) {
                event.target.value = inputMatches.input.value;
              }
              inputMatches.input.onChange(event);
              return event;
            }}
          />
          {submitError && (
            <Typography style={{ display: 'block' }} variant="overline" color="error">
              {t(submitError)}
            </Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={onCancel}>{cancelText}</Button>
          <Button color="primary" type="submit">
            {confirmText}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );

  return !customTheme ? dialog : <ThemeProvider theme={customTheme}>{dialog}</ThemeProvider>;
};
