import { isArray, isNumber } from 'lodash';
import { MARKETPLACE, FULLJAUS_STOCK } from '../../../const';
import { ProductStockNew } from '../../../interfaces/business';

export const stockColumns = [
  {
    id: 'sku',
    label: 'Sku',
    orderById: 'sku'
  },
  {
    id: 'name',
    label: 'Products Name',
    orderById: 'name'
  },
  {
    id: 'odoo_stock',
    label: 'Odoo',
    orderById: 'odoo_stock'
  },
  {
    id: 'stock_enova',
    label: 'Enova',
    orderById: 'stock_enova',
    render: (row: ProductStockNew) => {
      const value = row.marketplaces.find((item) => item.marketplace_id.toString() === MARKETPLACE.ENOVA)?.stock;
      return isNumber(value) ? value : 'N/A';
    }
  },
  {
    id: 'stock_novastore',
    label: 'Novastore',
    orderById: 'stock_novastore',
    render: (row: ProductStockNew) => {
      const value = row.marketplaces.find((item) => item.marketplace_id.toString() === MARKETPLACE.NOVATECH_STORE)?.stock;
      return isNumber(value) ? value : 'N/A';
    }
  },
  {
    id: 'stock_fulljaus',
    label: 'Fulljaus',
    orderById: 'stock_fulljaus',
    render: (row: ProductStockNew) => {
      const value = row.marketplaces.find((item) => {
        if (isArray(item.marketplace_id)) {
          const fulljaus_number = FULLJAUS_STOCK.map((market) => +market);
          for (let i = 0; i < item.marketplace_id.length; i++) {
            return fulljaus_number.includes(item.marketplace_id[i]);
          }
        }
      })?.stock;
      return isNumber(value) ? value : 'N/A';
    }
  },
  {
    id: 'stock_fravega',
    label: 'Fravega',
    orderById: 'stock_fravega',
    render: (row: ProductStockNew) => {
      const value = row.marketplaces.find((item) => item.marketplace_id.toString() === MARKETPLACE.FRAVEGA)?.stock;
      return isNumber(value) ? value : 'N/A';
    }
  },
  {
    id: 'stock_megatone',
    label: 'Megatone',
    orderById: 'stock_megatone',
    render: (row: ProductStockNew) => {
      const value = row.marketplaces.find((item) => item.marketplace_id.toString() === MARKETPLACE.MEGATONE)?.stock;
      return isNumber(value) ? value : 'N/A';
    }
  },
  {
    id: 'stock_tienda_oficial_ml',
    label: 'Tienda Oficial ML',
    orderById: 'stock_tienda_oficial_ml',
    render: (row: ProductStockNew) => {
      const value = row.marketplaces.find((item) => item.marketplace_id.toString() === MARKETPLACE.TIENDA_OFICIAL_ML)?.stock;
      return isNumber(value) ? value : 'N/A';
    }
  },
  {
    id: 'stock_novatech_meli',
    label: 'Novatech Meli',
    orderById: 'stock_novatech_meli',
    render: (row: ProductStockNew) => {
      const value = row.marketplaces.find((item) => item.marketplace_id.toString() === MARKETPLACE.NOVATECH_MELI)?.stock;
      return isNumber(value) ? value : 'N/A';
    }
  },
  {
    id: 'stock_urbantech_meli',
    label: 'Urbantech Meli',
    orderById: 'stock_urbantech_meli',
    render: (row: ProductStockNew) => {
      const value = row.marketplaces.find((item) => item.marketplace_id.toString() === MARKETPLACE.URBANTECH_MELI)?.stock;
      return isNumber(value) ? value : 'N/A';
    }
  },
  {
    id: 'edit',
    label: 'Edit',
    noSort: true
  }
];
