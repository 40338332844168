import React, { useState, useEffect } from 'react';
import Quagga from '@ericblade/quagga2';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(() => ({
  viewport: {
    position: 'relative',
    width: '100%',
    height: '45vh',
    overflow: 'hidden',
    textAlign: 'center',
    '& canvas': {
      maxWidth: '100%',
      width: '100%',
      '& drawing': {
        position: 'absolute',
        left: 0,
        top: 0
      },
      '& drawingBuffer': {
        position: 'absolute',
        left: 0,
        top: 0
      }
    },
    '& video': {
      maxWidth: '100%',
      width: '100%'
    }
  },
  framework: {
    zIndex: 1,
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    border: 'solid rgba(0, 0, 0, 0.3)'
  }
}));

type CollectProductProps = {
  setCode: any;
};

export const BarCodeScanner = ({ setCode }: CollectProductProps) => {
  const classes = useStyles();
  const [size, setSize] = useState({
    width: 0,
    height: 0
  });

  const _onDetected = (result: any) => {
    const code = result;

    Quagga.stop();
    return setCode(code.codeResult.code);
  };

  const _onProcessed = (result: any) => {
    const drawingCtx = Quagga.canvas.ctx.overlay;
    const drawingCanvas = Quagga.canvas.dom.overlay;

    if (result) {
      if (result.boxes) {
        drawingCtx.clearRect(0, 0, parseInt(drawingCanvas.getAttribute('width') || '0', 10), parseInt(drawingCanvas.getAttribute('height') || '0', 10));
        result.boxes
          .filter((box: any) => {
            return box !== result.box;
          })
          .forEach((box: any) => {
            Quagga.ImageDebug.drawPath(box, { x: 0, y: 1 }, drawingCtx, {
              color: 'green',
              lineWidth: 2
            });
          });
      }

      if (result.box) {
        Quagga.ImageDebug.drawPath(result.box, { x: 0, y: 1 }, drawingCtx, {
          color: '#00F',
          lineWidth: 2
        });
      }

      if (result.box) {
        Quagga.ImageDebug.drawPath(result.line, { x: 'x', y: 'y' }, drawingCtx, { color: 'red', lineWidth: 3 });
      }
    }
  };

  useEffect(() => {
    Quagga.init(
      {
        inputStream: {
          type: 'LiveStream',
          constraints: {
            width: { min: 800, max: 1280 },
            height: { min: 600, max: 720 },
            aspectRatio: { min: 4 / 3, max: 16 / 9 },
            facingMode: 'environment'
          },
          area: {
            top: '40%',
            right: '10%',
            left: '10%',
            bottom: '40%'
          }
        },
        locator: {
          patchSize: 'medium',
          halfSample: true
        },
        numOfWorkers: 8,
        // decoder: {
        //   readers: ['code_39_reader', 'ean_reader', 'ean_8_reader', 'code_128_reader', 'code_39_vin_reader', 'codabar_reader', 'upc_reader', 'upc_e_reader', 'i2of5_reader']
        // },
        locate: true
      },
      // eslint-disable-next-line consistent-return
      (err) => {
        if (err) return console.error(err);

        Quagga.start();
      }
    );
    Quagga.onDetected(_onDetected);
    Quagga.onProcessed(_onProcessed);

    const dataCanvas = document.getElementById('interactive');

    setSize({
      width: dataCanvas?.clientWidth || 0,
      height: dataCanvas?.clientHeight || 0
    });
    return () => {
      Quagga.stop();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div id="interactive" className={`${classes.viewport} viewport`}>
      <div className={classes.framework} style={{ borderWidth: `${(size.height / 100) * 40}px ${(size.width / 100) * 10}px` }} />
    </div>
  );
};
