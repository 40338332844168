import { Moment } from 'moment';
import { LibbyFetchDAO } from '../LibbyFetchDAO';

export class GoalDAO extends LibbyFetchDAO {
  constructor() {
    super('ster_goal', 'goal_id');
  }

  getAllGoal() {
    return this.query('list_all_goal').limit(0, 10000).run();
  }

  getAllGoalByDate({ from, to, typeValue }: { from: Moment; to: Moment; typeValue: number }) {
    return this.query('list_all_goal')
      .equals('goal_type_value', typeValue)
      .higherOrEquals('goal_period.start_date', from.format('YYYY-MM-DD HH:mm:ss'))
      .lowerOrEquals('goal_period.end_date', to.format('YYYY-MM-DD HH:mm:ss'))
      .limit(0, 10000)
      .run();
  }

  async getByDateGoal() {
    const ls = this.query();
    return ls.run();
  }
}
