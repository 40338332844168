import React, { useMemo } from 'react';
import { Button, Box, Card, Grid, Typography, useMediaQuery, Theme } from '@material-ui/core';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import { makeStyles } from '@material-ui/styles';
import moment from 'moment';
import { useReportingObjectiveSaleCategoryLogic } from '../../../ReportingObjectOfflineSales/routes/ReportingObjectSalesList/hook';
import { FilterBarSelectionReportingObjectiveSales } from '../../../ReportingObjectOfflineSales/routes/ReportingObjectSalesList/type';
import { ScreenAligned } from '../../../../components/ScreenAligned/ScreenAligned';
import { useTranslation } from '../../../../services/translation';
import { BudgetListProps } from '../../interfaces';
import { BudgetTable } from './components';
import { format } from '../../../../util';
import { GOAL_RANGE_TYPE, ORDER_SOURCE_TYPE } from '../../../../const';

const useStyles = makeStyles(() => ({
  search: {
    width: '80%'
  },
  parent: {
    overflowX: 'hidden'
  },
  container: {
    // display:'flow-root'
    position: 'relative',
    marginRight: '118px',
    left: '50%',
    top: '50%',
    // transform: 'translate(45%, 30%)',
    textAlign: 'center'
  },
  btn: {
    border: 'none',
    margin: 20,
    width: 120,
    height: 35,
    borderRadius: 6,
    textTransform: 'uppercase',
    boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
    cursor: 'pointer',
    color: '#fff',
    backgroundSize: '200%',
    transition: '0.4s',
    '&:hover': {
      backgroundPosition: 'right'
    }
  },
  btn1: {
    backgroundImage: 'linear-gradient(45deg, #FFC312, #EE5A24, #00A8FF)'
  },
  btn2: {
    backgroundImage: 'linear-gradient(45deg, #e74c3c, #8e44ad, #f1c40f)'
  },
  displaceBtn: {
    marginRight: '305px'
  }
}));

const filterInit: FilterBarSelectionReportingObjectiveSales = {
  canal: [],
  category: [],
  sale_type: ORDER_SOURCE_TYPE.OFFLINE.toString(),
  goal_range_type: GOAL_RANGE_TYPE.WEEKLY.toString(),
  from: moment().startOf('month').format('YYYY-MM-DD'),
  to: moment().endOf('month').format('YYYY-MM-DD')
};

export const BudgetList = ({
  setFilter,
  resetFilter,
  rows,
  columns,
  working,
  filter,
  libby,
  fetchMore,
  reFetch,
  onSortChange,
  orderBy,
  direction,
  title,
  allButtonsActions,
  onRowClick,
  canalOffLine = false,
  onNewBudget
}: BudgetListProps) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const isMd = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));

  const { statisticsUsersMonetary } = useReportingObjectiveSaleCategoryLogic({ filterInit });

  const allQuantityTotal = useMemo(() => {
    return [
      {
        name: 'Sales objective',
        data: [
          {
            title: 'Total reached of the goal',
            value: format(statisticsUsersMonetary.reached, 'ARS', t),
            icon: <AttachMoneyIcon style={{ fontSize: 50 }} color="primary" />
          },
          {
            title: 'Percentage of goal reached',
            value: `${statisticsUsersMonetary.objective > 0 ? ((statisticsUsersMonetary.reached * 100) / statisticsUsersMonetary.objective).toFixed(2) : 0}%`
          },
          {
            title: 'Total goal',
            value: format(statisticsUsersMonetary.objective, 'ARS', t),
            icon: <AttachMoneyIcon style={{ fontSize: 50 }} color="primary" />
          }
        ]
      }
    ];
  }, [t, statisticsUsersMonetary]);

  return (
    <div className={classes.parent}>
      <ScreenAligned title={title} additionalTitle={allButtonsActions}>
        <div className={classes.container}>
          <Button color="primary" type="submit" variant="contained" onClick={onNewBudget}>
            {t('Budget')}
          </Button>
        </div>
        <BudgetTable
          canalOffLine={canalOffLine}
          columns={columns}
          rows={rows}
          working={working}
          filter={filter}
          setFilter={setFilter}
          fetchMore={fetchMore}
          onSortChange={onSortChange}
          orderBy={orderBy}
          direction={direction}
          onRowClick={onRowClick}
          reFetch={reFetch}
          libby={libby}
          resetFilter={resetFilter}
        />
        {allQuantityTotal.map((quantityTotal) => (
          <Box my={2} display="flex" width="100%" flexDirection="column">
            <Grid container xs={12} justify="space-between" direction="column">
              <Grid item xs={12}>
                <Typography variant="h5">{t(quantityTotal.name)}</Typography>
              </Grid>
              <Grid container xs={12} justify="space-between">
                {quantityTotal.data.map((dataTotal) => (
                  <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                    <Box my={isMd ? 2 : 1} mx={isMd ? 2 : 1}>
                      <Card>
                        <Box display="flex" flexDirection="row" p={isMd ? 3 : 1} bgcolor="#fff1f4" alignItems="center">
                          {dataTotal.icon && (
                            <Box borderRadius="50%" borderColor="primary.main" border={1} mr={isMd ? 2 : 1}>
                              {dataTotal.icon}
                            </Box>
                          )}
                          <Box display="flex" flexDirection="column">
                            <Box height="100%" width="100%" mb={1}>
                              <Typography variant={isMd ? 'subtitle2' : 'body2'} color="primary">
                                {t(dataTotal.title).toUpperCase()}
                              </Typography>
                            </Box>
                            <Box height="100%" width="100%">
                              <Typography variant={isMd ? 'h2' : 'h4'} color="primary">
                                {dataTotal.value}
                              </Typography>
                            </Box>
                          </Box>
                        </Box>
                      </Card>
                    </Box>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Box>
        ))}
      </ScreenAligned>
    </div>
  );
};
