import React, { useMemo } from 'react';
import { Grid } from '@material-ui/core';
import { gridBreakpointsFields } from '../../../../../const';
import { GeneralSelect } from '../../../../../components/GeneralSelect';
import LoadingData from '../../../../components/LoadingData';
import { useQueryDashboards, useQueryEmbedUrl } from '../../../hooks';
import { QuicksightFrame } from '../../../components';
import { Screen } from '../../../../../components/Screen';
import { ScreenTitle } from '../../../../../components/ScreenTitle';

export const ReportsPurchases = () => {
  const { listDashboards, value, setValue } = useQueryDashboards('purchases');
  const { url, fetchEmbedUrl } = useQueryEmbedUrl('mdellano@novatech.com.ar', listDashboards[0]?.dashboardId);
  const options = useMemo(
    () =>
      listDashboards.map((dashboard: any) => ({
        id: dashboard.dashboardId,
        value: dashboard.name
      })),
    [listDashboards]
  );

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const id = event.target.value;
    setValue(id);
    fetchEmbedUrl('mdellano@novatech.com.ar', id);
  };

  return (
    <>
      <Screen hideFooter>
        <Grid container wrap="nowrap" alignItems="center" justify="flex-start">
          <ScreenTitle title="Purchases" />
        </Grid>
        <Grid container spacing={4} direction="column">
          <Grid item {...gridBreakpointsFields} style={{ marginBottom: '50px' }}>
            {value && (
              <GeneralSelect
                working={false}
                label="Reports"
                optionAll={false}
                data={options}
                inputProps={{
                  value,
                  onChange: handleChange
                }}
                sort
              />
            )}
          </Grid>
        </Grid>
      </Screen>
      {url ? <QuicksightFrame url={url} /> : <LoadingData working={!url} />}
    </>
  );
};
