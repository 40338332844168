import React, { useEffect, useCallback, useMemo } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { DatabaseConnector } from '@phinxlab/libby-rest-web';
import _ from 'lodash';
import { LibbyObject } from '../../../../types/types';
import { Collect } from '../../types/Collect';
import { useCollectsAddOrder, useCartEnabled } from '../../hook';
import { ScreenAlignedWithFilterAndLoader } from '../../../../components/ScreenAligned/ScreenAlignedWithFilterAndLoader';
import { SearchFilters } from './Compontents/SearchFilters';
import { NavbarDispatch } from '../../../Dispatches/routes/DispatchOrderList/components/NavbarDispatch';
import { TitleBreadcrumbs } from '../../../../interfaces';
import { CollectAddMobile } from './Compontents/CollectAddMobile';

type CollectOrderProps = {
  libby: LibbyObject;
  updateData: (Collect: Collect, id: string) => void;
};

export const CollectOrderRaw = ({ libby, updateData }: CollectOrderProps) => {
  const { id, dispatchId }: { id: string; dispatchId: string } = useParams();
  const { state } = useLocation<{ goBack: TitleBreadcrumbs[] }>();

  const { working, addItemSelect, checked, resetCheck, columns, onChangeFilter, handleRequestSort, filter, orderBy, direction, rows, data } = useCollectsAddOrder({
    libby,
    id,
    dispatchId,
    handleUpdateCollectList: updateData
  });

  const {
    data: dataCart,
    recallCart,
    alertCartEnabled
  } = useCartEnabled({
    libby,
    id: data?.cart?.cart_id || '-1'
  });

  useEffect(() => {
    if (data?.cart?.cart_id) recallCart(data?.cart?.cart_id);
  }, [recallCart, data]);

  const validateEnabledCart = useCallback(() => {
    if (dataCart?.length >= checked.all.length || dataCart[0].cart.capacity === -1) {
      addItemSelect(state?.goBack);
    } else {
      alertCartEnabled();
    }
  }, [addItemSelect, alertCartEnabled, dataCart, checked, state]);

  const onRowClick = (row: LibbyObject) => {
    row.select_order_id_change();
  };

  const dataShow = useMemo(() => {
    if (orderBy === 'priority' && direction) {
      const newData = _.orderBy(rows, (o: any) => Number(o[orderBy]), [direction]);
      return newData;
    }
    return rows;
  }, [rows, orderBy, direction]);

  return (
    <>
      <NavbarDispatch libby={libby} checked={checked} resetCheck={resetCheck} addOrdersHandle={validateEnabledCart} buttonEnabled={!dataCart?.length} />
      <ScreenAlignedWithFilterAndLoader
        rows={dataShow}
        columns={columns}
        working={working}
        fetchMore={() => {}}
        rowId="order"
        onSortChange={handleRequestSort}
        direction={direction}
        orderBy={orderBy}
        loaderMsg="Order loaded to add in the collection"
        RowComponent={CollectAddMobile}
        onRowClick={onRowClick}
      >
        <SearchFilters initFetch={filter} onFilterChange={onChangeFilter} />
      </ScreenAlignedWithFilterAndLoader>
    </>
  );
};

export const CollectOrder = DatabaseConnector(CollectOrderRaw)('ster_dispatch_collect_details', 'ster_dispatch_collect_item', 'ster_dispatch_collect_add_order', 'ster_dispatch_cart_box');
