import { useCallback } from 'react';
import { useSnackbar } from 'notistack';
import { LibbyObject } from '../../../../../types';
import CustomModal from '../../../../../services/customFormDialog';
import { useTranslation } from '../../../../../services/translation';
import { formInitialValues, validate } from '../utils';
import { CommentForm } from '../components/CommentForm';

interface IuseOrderCommentLogic {
  libby: LibbyObject;
  id: string;
  recall: () => void;
}

interface Comment {
  order_id: number;
  name: string;
}

const CreateCommentModal = CustomModal(CommentForm);

export const useOrderCommentLogic = ({ libby, id, recall }: IuseOrderCommentLogic) => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const onCreateComment = useCallback(async () => {
    try {
      const formData = (await CreateCommentModal.show({
        confirmText: t('Create'),
        cancelText: t('Cancel'),
        title: t('New Comment'),
        validate,
        formInitialValues
      })) as Comment;
      if (!formData) return;
      await libby.ster_order_comment.save({
        ...formData,
        order: {
          order_id: +id
        }
      });

      enqueueSnackbar(t('Comment saved'), { variant: 'success' });
      recall();
    } catch (error: any) {
      if (error !== false) {
        enqueueSnackbar(t('Something is wrong'), { variant: 'error' });
      }
    }
  }, [t, libby.ster_order_comment, enqueueSnackbar, recall, id]);

  return {
    onCreateComment
  };
};
