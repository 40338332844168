import { LibbyFetchDAO } from '../LibbyFetchDAO';

export class MessageTypeDAO extends LibbyFetchDAO {
  constructor() {
    super('ster_message_type', 'message_type_id');
  }

  getAll() {
    return this.query().limit(0, 10000).run();
  }
}
