import React from 'react';
import { Box, Modal } from '@material-ui/core';
import { useLocation, useParams } from 'react-router-dom';
import { LibbyObject } from '../../../../types';
import { useBreadcrumbs, useFilerParamsId } from '../../../../hooks';
import { TitleBreadcrumbs } from '../../../../interfaces';
import { ScreenAligned } from '../../../../components/ScreenAligned/ScreenAligned';
import { usePickControlDetailLogic } from './hook';
import { makeFilter } from '../../utils/makeFilter';
import { CollectState, DISPATCH_STATE } from '../../../../const';
import InfoTable from '../../../components/InfoTable';
import LoadingData from '../../../components/LoadingData';
import { useTranslation } from '../../../../services/translation';
import { OrderDetailData } from '../../../Collection/routes/CollectDetail/components/OrderDetailData';
import { useStylesButtonDispatch } from '../../../Dispatches/routes/DispatchDetail';

interface PickControlDetailProps {
  libby: LibbyObject;
  title: TitleBreadcrumbs[];
}

export const PickControlDetail = ({ libby, title }: PickControlDetailProps) => {
  const { t } = useTranslation();
  const { id, type } = useParams<{ id: string; type?: string }>();
  const { state } = useLocation<{ goBack: TitleBreadcrumbs[] }>();
  const classes = useStylesButtonDispatch();

  const { paramsFetch: paramsFetchCollect } = useFilerParamsId({
    orderInit: 'collect_id',
    daoName: 'ster_dispatch_collect_details',
    directionInit: 'desc',
    initAuxiliary: makeFilter({
      collectId: id
    })
  });

  const { paramsFetch: paramsFetchCart } = useFilerParamsId({
    orderInit: 'collect_id',
    daoName: 'ster_dispatch_collect_details',
    directionInit: 'desc',
    initAuxiliary: makeFilter({
      cartBoxId: id,
      collectStates: [CollectState.COMPLETE],
      dispatchStates: [DISPATCH_STATE.PREPARING]
    })
  });

  const { collect, rows, columns, working, isCart, tagView, setTagView, showModalTagManagerView, setShowModalTagManagerView, loadingDocuments } = usePickControlDetailLogic({
    libby,
    paramsFetchCollect,
    paramsFetchCart
  });

  const titleShow = useBreadcrumbs((type && isCart && 'Cart') || 'Collect', state && state?.goBack?.length ? state.goBack : title, `#${id}`);

  return (
    <ScreenAligned title={titleShow}>
      <Box my={3}>
        <OrderDetailData working={working} data={collect} readOnlyData showCollectId={!!type && isCart} showCartId={!type && !isCart} />
      </Box>

      <InfoTable columns={columns} rows={rows} rowIdKey="id" />

      <LoadingData label={`${t('Loaded registers')}`} working={working || loadingDocuments} cant={rows.length} />
      <Modal
        onClose={() => {
          setShowModalTagManagerView(false);
          setTagView(<></>);
        }}
        open={showModalTagManagerView}
      >
        <div className={classes.modal}>{tagView}</div>
      </Modal>
    </ScreenAligned>
  );
};
