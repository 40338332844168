import { LibbyFetchDAO } from '../LibbyFetchDAO';

export class MessageTemplateUpdateDAO extends LibbyFetchDAO {
  constructor() {
    super('ster_message_template_update', 'message_template_id');
  }

  getAll() {
    return this.query().limit(0, 10000).run();
  }

  getTemplateByID(id: string) {
    return this.query().equals('message_template_id', id).run();
  }
}
