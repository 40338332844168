import React from 'react';
import { ScreenAligned } from '../../../../components/ScreenAligned/ScreenAligned';
import { TitleBreadcrumbs } from '../../../../interfaces';
import { useTranslation } from '../../../../services/translation';
import { HeadCellsType } from '../../../../types';
import { RowsType } from '../../../../utils/tables';
import InfoTable from '../../../components/InfoTable';
import LoadingData from '../../../components/LoadingData';

type CourierListProps = {
  rows: Array<RowsType>;
  columns: Array<HeadCellsType>;
  fetchMore: () => void;
  onSortChange?: (orderBy: string, direction: 'asc' | 'desc') => void;
  orderBy?: string;
  direction?: 'asc' | 'desc';
  working: boolean;
  title: TitleBreadcrumbs[];
  additionalTitle?: React.ReactElement;
};

export const CouriersList = ({ title, columns, rows, working, fetchMore, onSortChange, orderBy, direction, additionalTitle }: CourierListProps) => {
  const { t } = useTranslation();
  return (
    <ScreenAligned title={title} additionalTitle={additionalTitle}>
      <InfoTable rows={rows} columns={columns} working={working} onBottomScroll={fetchMore} rowIdKey="courier_id" onSortChange={onSortChange} orderBy={orderBy} direction={direction} />
      <LoadingData label={`${t('Loaded registers')}`} working={working} cant={rows.length} />
    </ScreenAligned>
  );
};
