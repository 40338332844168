import { LibbyFetchDAO } from '../LibbyFetchDAO';

export class OrderSourceTypeDAO extends LibbyFetchDAO {
  constructor() {
    super('ster_order_source_type', 'order_source_type_id');
  }

  getAll() {
    return this.query().limit(0, 10000).run();
  }
}
