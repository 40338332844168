import React, { useEffect, useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, TextField, Typography } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/styles';
import { PaginatedTable } from '../../../../../components/PaginatedTable';
import { useModalFormStyles } from '../../../../../theme';
import { useTranslation } from '../../../../../services/translation';
import { Product } from '../../../../../interfaces/business';
import { RowsType } from '../../../types';

interface ProductSelectionProps {
  products: RowsType[];
  open?: boolean;
  customTheme?: any;
  onRowClick?: (product: Product) => void;
  onCancel: () => void;
}

export const ProductSelection = ({ products = [], open = false, customTheme = null, onRowClick = () => {}, onCancel = () => {} }: ProductSelectionProps) => {
  const { t } = useTranslation();
  const styles = useModalFormStyles();

  const [productForm, setProductForm] = useState(products);

  const onAddProduct = (row: any) => {
    const finalProduct = productForm?.find((product) => product.product_id === row.product_id);
    const finalRow = { ...row, price: finalProduct?.price || row.price, quantity: finalProduct?.quantity || 1 };
    onRowClick(finalRow as Product);
    onCancel();
  };

  const handleChange = (event: any, type: string) => {
    event.stopPropagation();
    const { name, value } = event.target;
    setProductForm((prevData) => {
      const newData = prevData.map((item) => (item.product_id === name ? { ...item, [type]: value } : item));
      return newData;
    });
  };

  useEffect(() => {
    if (products?.length > 0) setProductForm(products);
  }, [products]);

  const columns = [
    {
      id: 'sku',
      label: 'SKU'
    },
    {
      id: 'name',
      label: 'Name'
    },
    {
      id: 'price',
      label: 'Price'
    },
    {
      id: 'quantity',
      label: 'Quantity',
      render: (row: any) => {
        return <TextField onChange={(e) => handleChange(e, 'quantity')} defaultValue={1} color="primary" name={row.product_id} type="number" />;
      }
    },
    {
      id: 'stock',
      label: 'Stock'
    },
    {
      id: 'company_name',
      label: 'Company'
    },
    {
      id: 'actions',
      label: 'Add',
      render: (row: any) => {
        return (
          <Button
            variant="contained"
            onClick={(e) => {
              e.stopPropagation();
              onAddProduct({ ...row });
            }}
            color="primary"
          >
            {t('Add')}
          </Button>
        );
      }
    }
  ];

  const dialog = (
    <Dialog open={open} onClose={onCancel} fullWidth aria-labelledby="form-dialog-title" maxWidth={false}>
      <DialogContent className={styles.title}>
        <Typography variant="h3">Select Product</Typography>
      </DialogContent>
      <DialogContent className={styles.content}>
        <PaginatedTable headCells={columns} rows={products} rowIdKey="sku" />
      </DialogContent>
      <DialogActions />
    </Dialog>
  );

  return !customTheme ? dialog : <ThemeProvider theme={customTheme}>{dialog}</ThemeProvider>;
};
