import { useCallback, useEffect, useState } from 'react';
import axios from 'axios';
import { API_URL } from '../../../config';
import { GET_DASHBOARDS } from '../../../const';
import { TokenManager } from '../../../platform/libby/TokenManager';

interface IDashboard {
  dashboardId: string;
  name: string;
}

export const useQueryDashboards = (type: string) => {
  const [listDashboards, setListDashboards] = useState<IDashboard[]>([]);
  const [value, setValue] = useState<string>();

  const fetchDashboards = useCallback(async () => {
    return axios.get(`${API_URL}/${GET_DASHBOARDS}/${type}`, {
      headers: {
        'x-chino-token': await TokenManager.create().retrieve()
      }
    });
  }, [type]);

  useEffect(() => {
    const fecth = async () => {
      const response = await fetchDashboards();
      setListDashboards(response.data);
      setValue(response.data[0].dashboardId);
    };
    fecth();
  }, [fetchDashboards]);

  return {
    listDashboards,
    value,
    setValue
  };
};
