import { Box, Button, Collapse, Divider, useTheme } from '@material-ui/core';
import { KeyboardArrowRight } from '@material-ui/icons';
import React, { useState } from 'react';
import { MethosProductsType } from 'routes/Budget/routes/BudgetNew/hook/useSaveOrderLogic';

interface OrderRowMobileType {
  method: MethosProductsType;
  subtotal: number;
}

const styles = {
  OrderButton: {
    width: '100%',
    boxShadow: 'rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px'
  },
  ButtonContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%'
  },
  FirstRowContent: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  SecondRowContent: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '10px'
  }
};

export default function OrderRowMobile({ method, subtotal }: OrderRowMobileType) {
  const [open, setOpen] = useState<boolean>(false);
  const color = useTheme().palette.primary;

  return (
    <Box style={{ padding: '10px' }}>
      <Button style={styles.OrderButton} onClick={() => setOpen(!open)}>
        <Box style={styles.ButtonContainer}>
          <Box style={{ display: 'flex', textTransform: 'capitalize', whiteSpace: 'nowrap' }}>
            <strong>{method.order_id}</strong>
            <KeyboardArrowRight style={{ color: color.main, transition: 'transform 0.3s ease-in-out', transform: open ? 'rotate(90deg)' : 'rotate(0deg)' }} />
            <span>{method.payment_method_name}</span>
          </Box>
          <Box style={{ color: color.main }}>
            <span>
              <strong>ARS ${subtotal}</strong>
            </span>
          </Box>
        </Box>
      </Button>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <Box>
          {method.product_list.map((product, index) => (
            <>
              {index ? <Divider style={{ marginLeft: '5px', marginRight: '5px' }} /> : ''}
              <Box style={{ padding: '20px' }}>
                <strong>{product.name}</strong>
                <Box mt={2} style={styles.FirstRowContent}>
                  <Box>
                    <Box>
                      <strong>SKU</strong>
                    </Box>
                    <Box>{product.sku}</Box>
                  </Box>
                  <Box>
                    <Box>
                      <strong>Cantidad</strong>
                    </Box>
                    <Box>{product.quantity}</Box>
                  </Box>
                  <Box>
                    <Box>
                      <strong>Precio Unitario</strong>
                    </Box>
                    <Box color={color.main}>ARS ${product.unit_price}</Box>
                  </Box>
                </Box>

                <Box style={styles.SecondRowContent}>
                  <Box>
                    <Box>
                      <strong>Stock</strong>
                    </Box>
                    <Box>{product.product?.stock || 0}</Box>
                  </Box>
                  <Box>
                    <Box>
                      <strong>Subtotal</strong>
                    </Box>
                    <Box color={color.main}>
                      <strong>ARS ${product.subtotal}</strong>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </>
          ))}
        </Box>
      </Collapse>
    </Box>
  );
}
