import React, { useState, useEffect, useRef, DragEvent } from 'react';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/styles';
import { Typography } from '@material-ui/core';
import PublishIcon from '@material-ui/icons/Publish';
import ClearIcon from '@material-ui/icons/Clear';
import IconButton from '@material-ui/core/IconButton';
import Chip from '@material-ui/core/Chip';
import { useTranslation } from 'services/translation';
const useStyles = makeStyles(() => ({
  imageContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    flexDirection: 'row',
    width: '100%',
    borderRadius: '10px',
    borderColor: '#5b9bfb',
    minHeight: '300px'
  },
  singleImageContainer: {
    width: '200px',
    height: '200px',
    margin: '5px',
    border: '1px solid',
    borderRadius: '10px',
    borderColor: 'gray',
    overflow: 'hidden',
    cursor: 'grab', // Set initial cursor to 'grab'
    '&:active, &:focus': {
      cursor: 'grabbing' // Change cursor to 'grabbing' during drag operation
    }
  },
  singleImageContainerSelected: {
    width: '200px',
    height: '200px',
    margin: '5px',
    border: '1px solid',
    borderRadius: '10px',
    borderColor: 'gray',
    overflow: 'hidden',
    cursor: 'pointer',
    boxShadow: '0px 4px 10px #5b9bfb'
  },
  img: {
    width: '100%',
    height: '100%',
    objectFit: 'cover'
  },
  uploadContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '200px',
    height: '200px',
    margin: '5px',
    border: '1px dashed',
    borderRadius: '10px',
    borderWidth: '3px',
    borderColor: '#5b9bfb',
    transition: 'background-color 0.3s ease',
    '&:hover': {
      backgroundColor: '#dbe9ff'
    },
    cursor: 'pointer'
  },
  selectText: {
    fontSize: '18px',
    color: '#5b9bfb'
  },
  clearIcon: {
    color: 'black',
    backgroundColor: '#f0f0f0',
    borderRadius: '20px'
  },
  deleteIconButton: { position: 'absolute', top: 0, right: 0, zIndex: 1 },
  mainImageChip: { position: 'absolute', bottom: '20px', left: '50%', transform: 'translateX(-50%)', zIndex: 1 }
}));

interface ImagePreviewProps {
  fileList: FileList | File[];
  handleClearImages: () => void;
}

const ImagePreview: React.FC<ImagePreviewProps> = ({ fileList, handleClearImages }) => {
  const [previewUrls, setPreviewUrls] = useState<string[]>([]);
  const classes = useStyles();
  const inputRef = useRef<HTMLInputElement>(null);
  const [dragItemIndex, setDragItemIndex] = useState<number | undefined>();
  const [dragOverItemIndex, setDragOverItemIndex] = useState<number | undefined>();
  const { t } = useTranslation();

  const handleAddFiles = (event: any) => {
    if (event.target.files.length) {
      const files: FileList = event.target.files;
      const urls: string[] = Array.from(files).map((file: File) => URL.createObjectURL(file));
      setPreviewUrls((prevState) => [...prevState, ...urls]);
    }
  };

  const handleDeleteImage = (index: number) => {
    const previewCopy = [...previewUrls];
    URL.revokeObjectURL(previewUrls[index]);
    previewCopy.splice(index, 1);
    if (previewUrls.length === 1) handleClearImages();
    setPreviewUrls(previewCopy);
  };

  const handleDragStart = (index: number) => {
    setDragItemIndex(index);
  };

  const handleDragOver = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
  };

  const handleDrop = () => {
    if (dragItemIndex !== undefined && dragOverItemIndex !== undefined) {
      const updatedPreviewUrls = [...previewUrls];
      const dragItem = updatedPreviewUrls.splice(dragItemIndex, 1);
      updatedPreviewUrls.splice(dragOverItemIndex, 0, dragItem[0]);
      setPreviewUrls(updatedPreviewUrls);
    }
  };

  const handleDragEnter = (index: number) => {
    setDragOverItemIndex(index);
  };

  const handleDragLeave = () => {
    setDragOverItemIndex(undefined);
  };

  const handleDragEnd = () => {
    setDragItemIndex(undefined);
    setDragOverItemIndex(undefined);
  };

  useEffect(() => {
    if (fileList) {
      const urls = Array.from(fileList).map((file) => URL.createObjectURL(file));
      setPreviewUrls(urls);
    }
    return () => {
      previewUrls.forEach((url) => URL.revokeObjectURL(url));
    };
  }, [fileList, previewUrls]);

  return (
    <Box className={classes.imageContainer}>
      <Box onClick={() => inputRef.current?.click()} className={classes.uploadContainer}>
        <PublishIcon style={{ color: '#5b9bfb' }} />
        <Typography>Seleccionar</Typography>
      </Box>
      {previewUrls.map((url, index) => (
        <Box
          key={index}
          className={dragOverItemIndex !== index ? classes.singleImageContainer : classes.singleImageContainerSelected}
          draggable
          onDragStart={() => handleDragStart(index)}
          onDragOver={handleDragOver}
          onDrop={handleDrop}
          onDragEnter={() => handleDragEnter(index)}
          onDragLeave={handleDragLeave}
          onDragEnd={handleDragEnd}
          style={{ position: 'relative' }}
        >
          <IconButton className={classes.deleteIconButton}>
            <ClearIcon className={classes.clearIcon} onClick={() => handleDeleteImage(index)} />
          </IconButton>
          <img className={classes.img} src={url} alt={`Preview ${index}`} />
          {index === 0 && <Chip color="primary" label={t('Main Image')} className={classes.mainImageChip} />}
        </Box>
      ))}
      <input type="file" multiple onChange={handleAddFiles} hidden accept="image/png, image/jpeg" ref={inputRef} />
    </Box>
  );
};

export default ImagePreview;
