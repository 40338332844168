import React, { useMemo, useState } from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import { DatabaseConnector } from '@phinxlab/libby-rest-web';
import { ERPOrderList } from './routes/ERPOrderList/ERPOrderList';
import { ERPOrderDetails } from './routes/ERPOrderDetails/ERPOrderDetails';
import { useLibbyFetch } from '../../hooks/useLibbyFetch';
import { LibbyObject } from '../../types/types';
import { useBreadcrumbs } from '../../hooks';

type ERPOrderProps = {
  libby: LibbyObject;
};

const ERPOrderRouter = ({ libby }: ERPOrderProps) => {
  const match = useRouteMatch();
  const [direction, setDirection] = useState<'asc' | 'desc'>('asc');
  const [orderBy, setOrderBy] = useState('so_id');
  const [filter, setFilter] = useState({});

  const paramsFetch = useMemo(
    () => ({
      daoName: 'ster_erp_order',
      filter,
      orderBy,
      direction
    }),
    [filter, orderBy, direction]
  );

  const { working, data, fetchMore } = useLibbyFetch(libby, paramsFetch);

  const handleRequestSort = (newOrderBy: string, newDirection: 'asc' | 'desc') => {
    setOrderBy(newOrderBy);
    setDirection(newDirection);
  };
  const title = useBreadcrumbs('ERP Orders');
  return (
    <Switch>
      <Route path={`${match.path}/detail/:id`}>
        <ERPOrderDetails title={title} />
      </Route>
      <Route path={`${match.path}`}>
        <ERPOrderList title={title} working={working} data={data} filter={filter} setFilter={setFilter} fetchMore={fetchMore} onSortChange={handleRequestSort} orderBy={orderBy} direction={direction} />
      </Route>
    </Switch>
  );
};

export const ERPOrder = DatabaseConnector(ERPOrderRouter)('ster_erp_order');
