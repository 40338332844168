import React from 'react';
import { Grid } from '@material-ui/core';
import { OrderCancelReason } from '../../models/orders/OrderCancelReason';
import { SimpleSelect } from '../../components/Select';
import { useTranslation } from '../../services/translation';
import { useLibbyCall } from '../../lib/libby';

export interface OrderReasonSelectProps {
  reasonId: string;
  setReasonId: (value: any) => void;
}

export const OrderReasonSelect = ({ reasonId, setReasonId }: OrderReasonSelectProps) => {
  const { data = [], working } = useLibbyCall<OrderCancelReason[]>({
    daoName: 'order_cancel_reason',
    methodName: 'getAll',
    params: [[-1, 3]]
  });

  const { t } = useTranslation();

  return (
    <Grid container style={{ paddingBottom: '10px' }}>
      <Grid container style={{ padding: '24px' }}>
        <Grid item xs={12} style={{ paddingBottom: '20px' }}>
          {!working && (
            <SimpleSelect
              value={reasonId}
              handleChange={(e) => setReasonId(e.target.value as string)}
              content={data.map((item) => ({ ...item, name: t(item.name) }))}
              title={t('Select a reason')}
              placeholder={t('Select a reason')}
              labelKey="name"
              valueKey="order_cancel_reason_id"
            />
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};
