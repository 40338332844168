import React, { useMemo } from 'react';
import { Grid, Button, makeStyles } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import { useBreadcrumbs } from '../../../hooks';
import { Courier } from '../../../interfaces/business';
import { useTranslation } from '../../../services/translation';
import { MakeCell } from '../../components/MakeCell';
import { columns } from '../utils';

interface useCollectModeProps {
  data: Courier[];
  onCreateCourier: (courier?: Courier) => void;
}

const useStyles = makeStyles({
  buttonLargeWidth: {
    fontFamily: '"Oswald"',
    padding: '5px 22px',
    minWidth: 100
  }
});

export const useCourierMode = ({ data, onCreateCourier }: useCollectModeProps) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const screenProps = useMemo(
    () => ({
      title: 'Courier',
      aditionalTitle: (
        <Grid item>
          <Button className={classes.buttonLargeWidth} type="reset" variant="contained" onClick={() => onCreateCourier()} color="primary">
            {t('Create')}
          </Button>
        </Grid>
      )
    }),
    [classes.buttonLargeWidth, t, onCreateCourier]
  );
  const title = useBreadcrumbs(screenProps.title);

  const rows = useMemo(
    () =>
      data.map((courier: Courier) => ({
        courier_id: courier.courier_id,
        id: courier.courier_id,
        name: courier.name,
        edit: <MakeCell label="" icon={EditIcon} onClick={() => onCreateCourier(courier)} />
      })),
    [data, onCreateCourier]
  );

  return {
    rows,
    screenProps,
    title,
    columns
  };
};
