import Moment from 'moment';
import { FilterBarSelection } from '../../../../routes/Reporting/routes/ReportingList/FilterBar';
import { DataGenerateAutocompleteType } from '../../../../utils/generateAutocomplete';
import { LibbyFetchDAO } from '../LibbyFetchDAO';

export class OrderReportingSalesDAO extends LibbyFetchDAO {
  // eslint-disable-next-line @typescript-eslint/no-useless-constructor
  constructor(name: string, pk: string) {
    super(name, pk);
  }

  getAll() {
    return this.query().limit(0, 10000).run();
  }

  async getByDate(filter: FilterBarSelection) {
    let ls = this.query();
    // FIXME QUERY BUILDER HAVE TO DECIDE IF HAVE TO AUTOMATICALLY INSERT AN AND
    let needsAnd: boolean = false;
    if (filter.courier.length > 0) {
      if (needsAnd) ls = ls.and();
      ls = ls.groupStart();
      let first: boolean = true;
      filter.courier.forEach((courier) => {
        if (!first) ls = ls.or();
        ls = ls.equals('shipment.service.courier.courier_id', courier.id);
        first = false;
      });
      ls = ls.groupEnd();
      needsAnd = true;
    }

    if (filter.marketplaces.length > 0) {
      if (needsAnd) ls = ls.and();
      ls = ls.groupStart();
      let first: boolean = true;
      filter.marketplaces.forEach(({ id }: DataGenerateAutocompleteType) => {
        if (!first) ls = ls.or();
        ls = ls.equals('source.marketplace.marketplace_id', id);
        first = false;
      });
      ls = ls.groupEnd();
      needsAnd = true;
    }
    if (filter.company && filter.company.length) {
      if (needsAnd) ls = ls.and();
      ls = ls.groupStart();
      let first: boolean = true;
      filter.company.forEach(({ id }: DataGenerateAutocompleteType) => {
        if (!first) ls = ls.or();
        ls = ls.equals('source.marketplace.company.company_id', id);
        first = false;
      });
      ls = ls.groupEnd();
      needsAnd = true;
    }

    if (filter.from && filter.to) {
      if (needsAnd) ls = ls.and();
      ls = ls.groupStart().higherOrEquals('created_at', Moment(filter.from).format('YYYY-M-D HH:mm:ss')).lowerOrEquals('created_at', Moment(filter.to).format('YYYY-M-D HH:mm:ss')).groupEnd();
      needsAnd = true;
    }

    if (filter?.canal && filter?.canal?.length) {
      if (needsAnd) ls = ls.and();
      ls = ls.groupStart();
      let first: boolean = true;
      filter?.canal.forEach(({ id }: DataGenerateAutocompleteType) => {
        if (!first) ls = ls.or();
        ls = ls.equals('source.order_source_type.order_source_type_id', id);
        first = false;
      });
      ls = ls.groupEnd();
    }

    if (filter?.brand && filter?.brand?.length) {
      if (needsAnd) ls = ls.and();
      ls = ls.groupStart();
      let first: boolean = true;
      filter?.brand.forEach(({ id }: DataGenerateAutocompleteType) => {
        if (!first) ls = ls.or();
        ls = ls.equals('product.brand.brand_id', id);
        first = false;
      });
      ls = ls.groupEnd();
    }

    if (filter?.category && filter?.category?.length) {
      if (needsAnd) ls = ls.and();
      ls = ls.groupStart();
      let first: boolean = true;
      filter?.category.forEach(({ id }: DataGenerateAutocompleteType) => {
        if (!first) ls = ls.or();
        ls = ls.equals('product.category.category_id', id);
        first = false;
      });
      ls = ls.groupEnd();
    }

    ls.run();

    return ls;
  }
}
