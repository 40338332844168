import React from 'react';
import { capitalize } from '@material-ui/core';
import { TextFieldProps } from '@material-ui/core/TextField';
import { GeneralSelect, GeneralSelectProps } from '../../../../../components/GeneralSelect';
import { OrderPrintState } from '../../../../../const/OrderPrintingState';

type SelectOrderPrintStatusProps = {
  title: string;
  inputProps: TextFieldProps;
  [k: string]: any;
  sort?: boolean | 'asc' | 'desc';
};

export const SelectPrintOrderStatus = ({ inputProps = {}, title, sort, ...restProps }: SelectOrderPrintStatusProps) => {
  const data: GeneralSelectProps['data'] = Object.entries(OrderPrintState).map((item) => ({ id: +item[0], value: capitalize(item[1]) }));
  return <GeneralSelect label={title} optionAll data={data} inputProps={data.length === 0 && inputProps?.value !== '0' ? { ...inputProps, value: '0' } : inputProps} working={false} {...restProps} sort={sort} />;
};
