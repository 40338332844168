import React, { useCallback, useState } from 'react';
import { FormConfig } from 'react-final-form-hooks';
import Dialog from '@material-ui/core/Dialog';
import { ThemeProvider } from '@material-ui/styles';
import { Button, DialogActions, DialogContentText } from '@material-ui/core';
import { useTranslation } from '../../services/translation';
import { InformationSection } from '../InformationSection';
import { OrderReasonSelectProps } from '../../routes/components';

interface OrderReasonDialogType extends FormConfig<any> {
  open: boolean;
  onCancel: any;
  customTheme: string | null;
  title: string;
  render?: (props: OrderReasonSelectProps) => JSX.Element;
  maxWidth: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false;
  cancelText: string;
  confirmText: string;
  onConfirm: (reasonId: string | null) => void;
}
const dummyFunc = () => {};
export const OrderReasonDialogComponent = ({ open = false, onCancel = dummyFunc, render, customTheme = null, title, maxWidth = 'xs', cancelText = 'No', confirmText = 'Yes', onConfirm }: OrderReasonDialogType) => {
  const { t } = useTranslation();

  const [reasonId, setReasonId] = useState('');

  const onSubmit = useCallback(() => {
    onConfirm(reasonId);
  }, [onConfirm, reasonId]);

  const onCancelModal = useCallback(() => {
    setReasonId('');
    onCancel();
  }, [onCancel]);

  const Comp = render;

  const dialog = (
    <Dialog open={open} onClose={onCancel} fullWidth maxWidth={maxWidth} aria-labelledby="form-dialog-title" aria-describedby="alert-dialog-description">
      <InformationSection title={t(title)} noPaddingChildren>
        <DialogContentText>{Comp && <Comp reasonId={reasonId} setReasonId={setReasonId} />}</DialogContentText>
      </InformationSection>
      <DialogActions>
        <Button onClick={onCancelModal}>{cancelText}</Button>
        <Button color="primary" disabled={reasonId === ''} onClick={onSubmit}>
          {t(confirmText)}
        </Button>
      </DialogActions>
    </Dialog>
  );

  return !customTheme ? dialog : <ThemeProvider theme={customTheme}>{dialog}</ThemeProvider>;
};
