import React from 'react';
import SearchIcon from '@material-ui/icons/Search';
import 'moment/locale/es';
import { Grid, TextField } from '@material-ui/core';
import { makeFilter } from '../utils/makeFilter';
import { FilterBar } from '../../../../../components/FilterBar';
import { gridBreakpoints, gridBreakpointsFields } from '../../../../../const/gridBreakpoints';
import { useTranslation } from '../../../../../services/translation';
import { AnyObject } from '../../../../../types/types';
import { useInitFilter, useInitFilterDebounced } from '../../../../OrderReport/routes/OrderReportList/hook';
import { SelectCategoryProduct } from 'routes/components/SelectCategoryProduct';
import { SelectBrandProduct } from 'routes/components/SelectBrandProduct';

type SearchFiltersProps = {
  onFilterChange: (makeFilter: object) => void;
  initFetch: AnyObject;
  canalOffLine?: boolean;
  resetFilter: () => void;
};

const SearchFiltersRaw = ({ onFilterChange, initFetch, canalOffLine = false, resetFilter }: SearchFiltersProps) => {
  const { t } = useTranslation();
  const { search, handleChange } = useInitFilterDebounced({
    initFetch: initFetch.productSearch,
    init: '',
    onFilterChange,
    id: 'productSearch',
    makeFilter
  });

  const { search: marketplace, handleChangeFilter: handleChangeCategory } = useInitFilter({
    initFetch: initFetch.category,
    init: '0',
    onFilterChange,
    id: 'category',
    makeFilter
  });

  const { search: source, handleChangeFilter: handleChangeBrand } = useInitFilter({
    initFetch: initFetch.brand,
    init: '0',
    onFilterChange,
    id: 'brand',
    makeFilter
  });

  return (
    <FilterBar
      defaultFilter={
        <Grid item {...gridBreakpoints}>
          <TextField
            id="standard-search"
            placeholder={t('Search')}
            type="search"
            value={search}
            onChange={handleChange}
            fullWidth
            InputLabelProps={{
              shrink: true
            }}
            InputProps={{
              endAdornment: <SearchIcon />
            }}
          />
        </Grid>
      }
      handleReset={resetFilter}
    >
      <Grid item {...gridBreakpointsFields}>
        <SelectCategoryProduct sort="asc" inputProps={{ value: marketplace, onChange: handleChangeCategory }} canalOffLine={canalOffLine} optionAll />
      </Grid>
      <Grid item {...gridBreakpointsFields}>
        <SelectBrandProduct sort="asc" inputProps={{ value: source, onChange: handleChangeBrand }} canalOffLine={canalOffLine} optionAll />
      </Grid>
    </FilterBar>
  );
};

export const SearchFilters = React.memo(SearchFiltersRaw);
