import { Grid } from '@material-ui/core';
import React from 'react';
import { ButtonComponent } from '../../../../components/Button';
import { ScreenAligned } from '../../../../components/ScreenAligned/ScreenAligned';
import { useTranslation } from '../../../../services/translation';
import InfoTable from '../../../components/InfoTable';
import LoadingData from '../../../components/LoadingData';

import { useReportingGoalSaleSettingFieldsLogic } from './hooks/useReportingGoalSalesSettingFields';

export const ReportingObjectSaleSettingFields = () => {
  const { columns, rowsGoalFields, working, titleShow, buttonDetails } = useReportingGoalSaleSettingFieldsLogic();
  const { t } = useTranslation();
  return (
    <ScreenAligned
      title={titleShow}
      additionalTitle={
        <>
          <Grid container>
            {buttonDetails.map((dataButton) => (
              <ButtonComponent
                key={dataButton.id}
                title={dataButton.title}
                className={dataButton.className}
                variant={dataButton.variant}
                color={dataButton.color}
                onClick={dataButton.onClick}
                type={dataButton.type}
                disabled={dataButton.disabled}
                loading={dataButton.loading}
              />
            ))}
          </Grid>
        </>
      }
    >
      {!!rowsGoalFields?.length && !!columns?.length && (
        <Grid container direction="row" justify="center" alignItems="center">
          <InfoTable columns={columns} rows={rowsGoalFields} onBottomScroll={() => {}} onSortChange={() => {}} direction="asc" rowIdKey="goal_id" md={12} sm={12} xs={12} />
          {(working || !!rowsGoalFields.length) && <LoadingData label={`${t('Loaded goals')}`} working={working} cant={rowsGoalFields.length} />}
        </Grid>
      )}
    </ScreenAligned>
  );
};
