import { AnyObject } from '../../../../../types/types';

type MakeFilterProps = {
  account_id?: string;
  search?: string;
  role?: string;
  onlyEnabled?: boolean;
};
export const makeFilter = ({ account_id, search, role, onlyEnabled = false }: MakeFilterProps) => {
  let filter: {
    account_id?: AnyObject;
    enabled?: AnyObject;
    role?: string | AnyObject;
  } = {};

  if (search) {
    filter = search.split(' ').reduce((accumulator: AnyObject, item, index) => {
      // eslint-disable-next-line no-param-reassign
      accumulator[index] = [
        { path: 'username', value: search, method: 'includes' },
        { path: 'username', value: item, method: 'includes' },
        { path: 'account_id', value: item, method: 'includes' }
      ];
      return accumulator;
    }, {});
  }

  if (account_id) {
    filter.account_id = [{ path: 'account_id', value: account_id }];
  }

  if (role && role !== '0') {
    filter.role = [{ path: 'role.account_role_id', value: role }];
  }

  if (onlyEnabled) {
    filter.enabled = [
      {
        path: 'account_enabled',
        value: true,
        method: 'equals'
      }
    ];
  }
  return filter;
};
