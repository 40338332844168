import React from 'react';
import { Box } from '@material-ui/core';
import { formatDate } from '../../utils';
import { Order } from '../../interfaces/business';

export const CommonOrderWithDeliveryColumns = {
  delivery_date: {
    id: 'delivery_date',
    label: 'Delivery Date',
    style: {
      whiteSpace: 'nowrap',
      minWidth: '150px'
    },
    render: ({ delivery_date }: Order) => <Box>{delivery_date ? formatDate(delivery_date, true, false) : ''}</Box>,
    orderById: 'delivery_date'
  }
};
