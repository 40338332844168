import { RESTDAO } from '@phinxlab/libby-rest-web';

export class AdminGenerateReportDAO extends RESTDAO {
  constructor() {
    super('admin_generate_report', 'generate_report_id');
  }

  getAll() {
    return this.query().limit(0, 10000).run();
  }
}
