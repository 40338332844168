import { LibbyFetchDAO } from './LibbyFetchDAO';

type GetAllCourierServiceType = {
  typeCourierSearch?: string;
  external?: boolean;
};

export class LogisticsServiceDAO extends LibbyFetchDAO {
  constructor() {
    super('courier_service', 'courier_service_id');
  }

  getAll() {
    return this.query().limit(0, 10000).run();
  }

  getAllCourierService({ typeCourierSearch, external }: GetAllCourierServiceType) {
    let ls = this.query();
    let needsAnd: boolean = false;

    if (typeof external === 'boolean') {
      if (needsAnd) ls = ls.and();
      ls = ls.groupStart();
      ls = ls.notEquals('external', external);
      ls = ls.groupEnd();
      needsAnd = true;
    }
    if (typeCourierSearch) {
      if (needsAnd) ls = ls.and();
      ls = ls.groupStart();
      ls = ls.equals('courier.courier_id', typeCourierSearch);
      ls = ls.groupEnd();
      needsAnd = true;
    }
    return ls.run();
  }
}
