import { LibbyFetchDAO } from '../LibbyFetchDAO';

export class MarketPlaceSlaDAO extends LibbyFetchDAO {
  constructor() {
    super('ster_marketplace_sla', 'marketplace_sla_id');
  }

  getAll() {
    return this.query().limit(0, 10000).run();
  }

  getMarketplaceSlaByID(id: string) {
    return this.query().equals('marketplace_sla_id', id).run();
  }
}
