import React, { useCallback } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Typography } from '@material-ui/core';
import { useTranslation } from '../../../../../../../../../services/translation';
import { useBudgetContext } from '../../../../../context';
import { useStyles } from '../../../../../const';

interface IModalShippingAddressEdit {
  open: boolean;
  onClose: () => void;
}

export const ModalTransportEdit = ({ open = false, onClose }: IModalShippingAddressEdit) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const { selectedTransportCompany } = useBudgetContext();

  const handleEditing = useCallback(() => {
    onClose();
  }, [onClose]);

  return (
    <Dialog open={open} aria-labelledby="form-dialog-title" fullWidth maxWidth="md">
      <DialogTitle id="form-dialog-title">
        <Typography variant="h1" className={classes.title}>{`${t('Transport Details')}`}</Typography>
      </DialogTitle>
      <DialogContent>
        <Grid container xs={12} style={{ marginLeft: '10px', marginBottom: '10px', marginTop: '10px', justifyContent: 'flex-start' }}>
          <Grid item xs={12} direction="row" style={{ display: 'flex', justifyContent: 'flex-start' }}>
            <Typography variant="h5">{`${t('Name')}:`}</Typography>
            <Typography variant="body1" style={{ marginLeft: '5px' }}>{`${selectedTransportCompany?.name || '_ _ _ _ _'}`}</Typography>
          </Grid>
          <Grid item xs={12} direction="row" style={{ display: 'flex', justifyContent: 'flex-start' }}>
            <Typography variant="h5">{`${t('Province')}:`}</Typography>
            <Typography variant="body1" style={{ marginLeft: '5px' }}>{`${typeof selectedTransportCompany?.city?.state === 'object' ? selectedTransportCompany?.city.state?.name : '_ _ _ _ _'}`}</Typography>
          </Grid>
          <Grid item xs={12} direction="row" style={{ display: 'flex', justifyContent: 'flex-start' }}>
            <Typography variant="h5">{`${t('City')}:`}</Typography>
            <Typography variant="body1" style={{ marginLeft: '5px' }}>{`${
              typeof selectedTransportCompany?.city === 'object' && selectedTransportCompany?.city?.name ? selectedTransportCompany?.city?.name : '_ _ _ _ _'
            }`}</Typography>
          </Grid>
          <Grid item xs={12} direction="row" style={{ display: 'flex', justifyContent: 'flex-start' }}>
            <Typography variant="h5">{`${t('Street')}:`}</Typography>
            <Typography variant="body1" style={{ marginLeft: '5px' }}>{`${selectedTransportCompany?.street || '_ _ _ _ _'}`}</Typography>
          </Grid>
          <Grid item xs={12} direction="row" style={{ display: 'flex', justifyContent: 'flex-start' }}>
            <Typography variant="h5">{`${t('Street Number')}:`}</Typography>
            <Typography variant="body1" style={{ marginLeft: '5px' }}>{`${selectedTransportCompany?.street_number || '_ _ _ _ _'}`}</Typography>
          </Grid>
          <Grid item xs={12} direction="row" style={{ display: 'flex', justifyContent: 'flex-start' }}>
            <Typography variant="h5">{`${t('Floor')}:`}</Typography>
            <Typography variant="body1" style={{ marginLeft: '5px' }}>{`${selectedTransportCompany?.floor || '_ _ _ _ _'}`}</Typography>
          </Grid>
          <Grid item xs={12} direction="row" style={{ display: 'flex', justifyContent: 'flex-start', marginTop: '5px' }}>
            <Typography variant="h5">{`${t('Department')}:`}</Typography>
            <Typography variant="body1" style={{ marginLeft: '5px' }}>{`${selectedTransportCompany?.department || '_ _ _ _ _'}`}</Typography>
          </Grid>
          <Grid item xs={12} direction="row" style={{ display: 'flex', justifyContent: 'flex-start', marginTop: '5px' }}>
            <Typography variant="h5">{`${t('Zip')}:`}</Typography>
            <Typography variant="body1" style={{ marginLeft: '5px' }}>{`${selectedTransportCompany?.zip || '_ _ _ _ _'}`}</Typography>
          </Grid>
          <Grid item xs={12} direction="row" style={{ display: 'flex', justifyContent: 'flex-start', marginTop: '5px' }}>
            <Typography variant="h5">{`${t('Phone Number')}:`}</Typography>
            <Typography variant="body1" style={{ marginLeft: '5px' }}>{`${selectedTransportCompany?.phone_number || '_ _ _ _ _'}`}</Typography>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={() => handleEditing()} autoFocus>
          {`${t('Add')}`}
        </Button>
        <Button color="secondary" onClick={() => onClose()} autoFocus>
          {`${t('Cancel')}`}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
