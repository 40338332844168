import { MakeCell } from 'routes/components/MakeCell';
import AssignmentIcon from '@material-ui/icons/Assignment';
import { ProductErrorModalDialog } from '../components';
import CustomModal from '../../../services/customFormDialog';

const ProductErrorModal = CustomModal(ProductErrorModalDialog);

export const columns = [
  {
    id: 'sku',
    label: 'SKU',
    orderById: 'sku',
    style: {
      whiteSpace: 'nowrap'
    }
  },
  {
    id: 'name',
    label: 'Name',
    style: {
      whiteSpace: 'nowrap'
    },
    noSort: true
  },
  {
    id: 'last_sync',
    label: 'Last Sync',
    style: {
      whiteSpace: 'nowrap'
    }
  },
  {
    id: 'detail',
    label: 'Detail',
    style: {
      whiteSpace: 'nowrap'
    },
    noSort: true,
    render: (data: any) => {
      return (
        <MakeCell
          label=""
          color="primary"
          icon={AssignmentIcon}
          onClick={async () =>
            ProductErrorModal.show({
              title: data.name,
              data: data.errors
            })
          }
        />
      );
    }
  }
];
