import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { CompanyAutocomplete } from '../../../../business/Company';
import { CompanyMarketplaceAutocomplete } from '../../../../business/CompanyMarketplace';
import { CourierAutocomplete } from '../../../../business/Courier';
import { OrderSourceTypeAutocomplete } from '../../../../business/OrderSourceType';
import { Brand, Courier, Marketplace } from '../../../../interfaces/business';
import { Company } from '../../../../interfaces/business/marketplace/Company';
import { Company_marketplace } from '../../../../interfaces/business/marketplace/CompanyMarketplace';
import { Order_source_type } from '../../../../interfaces/business/orders/Order_source_type';
import { FilterBarSelection } from '../../../../routes/Reporting/routes/ReportingList/FilterBar';
import { Filter } from '../../../../types';
import { DataGenerateAutocompleteType } from '../../../../utils/generateAutocomplete';
import { RowsImput } from '../../types/Rowsimput';
import { DateInput } from '../DateInput';
import { BrandAutocomplete } from '../../../../business/product';
import { CategoryAutocomplete } from '../../../../business/Category';
import { Category } from '../../../../interfaces/business/product/Category';

export interface useRowsProps {
  filter: FilterBarSelection;
  working: boolean;
  filterMarketplaces: Filter;
  t: (str: string) => string;
  prepareToChange: <T, K>(value: T, name: string, setAditional?: Partial<K>, trasformDate?: 'end' | 'start') => void;
}

export const useRepotingSalesFilterRows = ({ filter, working, filterMarketplaces, t, prepareToChange }: useRowsProps): RowsImput[] => {
  const { pathname } = useLocation();

  return useMemo(
    () => [
      {
        id_row: 1,
        container: true,
        direction: 'row',
        justify: 'space-between',
        alignItems: 'flex-end',
        spacing: 2,
        imputs: [
          {
            id: 1,
            md: 3,
            sm: 6,
            xs: 12,
            value: filter.company?.map(({ id, value }) => ({ company_id: id, name: value })),
            multiple: true,
            autoCompleteProps: { disabled: working, getOptionSelected: (option: Company, _value: Company) => option.company_id === _value.company_id },
            textFieldProps: { label: t('All companies') },
            onChange: (value: Company[]) => {
              const transform = value.map(({ company_id, name }) => ({ id: company_id, value: name }));
              prepareToChange<DataGenerateAutocompleteType[] | Date, FilterBarSelection>(transform, 'company', { marketplaces: [] });
            },
            component: CompanyAutocomplete
          },
          {
            id: 2,
            md: 3,
            sm: 6,
            xs: 12,
            value: filter.marketplaces.map(({ id, value }, index) => ({ company_marketplace_product_id: index, company: {} as Company, marketplace: { marketplace_id: id, name: value } as Marketplace })),
            multiple: true,
            autoCompleteProps: { disabled: working, getOptionSelected: (option: Company_marketplace, _value: Company_marketplace) => option.marketplace.marketplace_id === _value.marketplace.marketplace_id },
            textFieldProps: { label: t('All marketplace') },
            filter: filterMarketplaces,
            direction: 'asc',
            orderBy: 'marketplace.name',
            onChange: (value: Company_marketplace[]) => {
              const transform = value.map(({ marketplace: { marketplace_id, name } }) => ({ id: marketplace_id, value: name }));
              prepareToChange<DataGenerateAutocompleteType[] | Date, FilterBarSelection>(transform, 'marketplaces');
            },
            component: CompanyMarketplaceAutocomplete
          },
          {
            id: 3,
            md: 3,
            sm: 6,
            xs: 12,
            value: filter.courier?.map(({ id, value }) => ({ courier_id: id, name: value })),
            multiple: true,
            autoCompleteProps: { disabled: working, getOptionSelected: (option: Courier, _value: Courier) => option.courier_id === _value.courier_id },
            textFieldProps: { label: t('All the courier') },
            onChange: (value: Courier[]) => {
              const transform = value.map(({ courier_id, name }) => ({ id: courier_id, value: name }));
              prepareToChange<DataGenerateAutocompleteType[] | Date, FilterBarSelection>(transform, 'courier');
            },
            component: CourierAutocomplete
          },
          {
            id: 4,
            md: 3,
            sm: 6,
            xs: 12,
            value: filter.canal?.map(({ id, value }) => ({ order_source_type_id: id, name: value })),
            multiple: true,
            autoCompleteProps: { disabled: working, getOptionSelected: (option: Order_source_type, _value: Order_source_type) => option.order_source_type_id === _value.order_source_type_id },
            textFieldProps: { label: t('All source types') },
            onChange: (value: Order_source_type[]) => {
              const transform = value.map(({ order_source_type_id, name }) => ({ id: order_source_type_id, value: name }));
              prepareToChange<DataGenerateAutocompleteType[] | Date, FilterBarSelection>(transform, 'canal');
            },
            component: OrderSourceTypeAutocomplete
          }
        ]
      },
      {
        id_row: 2,
        container: true,
        direction: 'row',
        alignItems: 'flex-end',
        spacing: 2,
        imputs: [
          {
            id: 4,
            md: 3,
            sm: 6,
            xs: 12,
            value: filter.category?.map(({ id, value }) => ({ category_id: id, name: value })),
            multiple: true,
            autoCompleteProps: { disabled: working, getOptionSelected: (option: Category, _value: Category) => option.category_id === _value.category_id },
            textFieldProps: { label: t('All Categories') },
            onChange: (value: Category[]) => {
              const transform = value.map(({ category_id, name }) => ({ id: category_id, value: name }));
              prepareToChange<DataGenerateAutocompleteType[] | Date, FilterBarSelection>(transform, 'category');
            },
            hidden: pathname.replace('/', '') === 'reporting-sale-taxation',
            component: CategoryAutocomplete
          },
          {
            id: 4,
            md: 3,
            sm: 6,
            xs: 12,
            value: filter.brand?.map(({ id, value }) => ({ brand_id: id, name: value })),
            multiple: true,
            autoCompleteProps: {
              disabled: working,
              getOptionSelected: (option: Brand, _value: Brand) => option.brand_id === _value.brand_id
            },
            textFieldProps: { label: t('All Brands') },
            onChange: (value: Brand[]) => {
              const transform = value.map(({ brand_id, name }) => ({ id: brand_id, value: name }));
              prepareToChange<DataGenerateAutocompleteType[] | Date, FilterBarSelection>(transform, 'brand');
            },
            hidden: pathname.replace('/', '') === 'reporting-sale-taxation',
            component: BrandAutocomplete
          },
          {
            id: 1,
            md: 3,
            sm: 6,
            xs: 6,
            value: filter.from,
            label: t('From'),
            disabled: working,
            onChange: (value: Date) => {
              prepareToChange<Date, FilterBarSelection>(value, 'from');
            },
            component: DateInput
          },
          {
            id: 2,
            md: 3,
            sm: 6,
            xs: 6,
            value: filter.to,
            label: t('To'),
            disabled: working,
            onChange: (value: Date) => {
              prepareToChange<Date, FilterBarSelection>(value, 'to', {}, 'end');
            },
            component: DateInput
          }
        ]
      }
    ],
    [working, filter, filterMarketplaces, t, prepareToChange, pathname]
  );
};
