import { DatabaseConnector } from '@phinxlab/libby-rest-web';
import React from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import { LibbyObject } from '../../types';
import { MessageConfigAlertList } from './routes/MessageConfigAlertList';

export const MessageConfigAlertRaw = ({ libby }: LibbyObject) => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={`${path}`}>
        <MessageConfigAlertList libby={libby} />
      </Route>
    </Switch>
  );
};

export const MessageConfigAlert = DatabaseConnector(MessageConfigAlertRaw)('ster_message_config_alert', 'ster_message_config_alert_update', 'order_state', 'ster_marketplace', 'ster_message_template', 'ster_alert');
