import { useState } from 'react';
import { IBudgetClient } from '../../../components/Header/components/Client/Types';
import { BudgetInitialValues } from '../types/BudgetContextValue';

export const useClients = (initialValues: BudgetInitialValues) => {
  const [selectedClient, setSelectedClient] = useState<IBudgetClient[]>(initialValues.selectedClient);

  return {
    selectedClient,
    setSelectedClient
  };
};
