import React from 'react';
import { SearchFilters } from './components';
import { RowsType } from '../../../../utils/tables';
import InfoTable from '../../../components/InfoTable';
import LoadingData from '../../../components/LoadingData';
import { AnyObject, HeadCellsType } from '../../../../types';
import { useTranslation } from '../../../../services/translation';
import { ScreenAligned } from '../../../../components/ScreenAligned/ScreenAligned';
import { ButtonDetailsType } from 'types/Button';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';

type CarriersListProps = {
  rows: Array<RowsType>;
  columns: Array<HeadCellsType>;
  fetchMore: () => void;
  onSortChange?: (orderBy: string, direction: 'asc' | 'desc') => void;
  orderBy?: string;
  direction?: 'asc' | 'desc';
  working: boolean;
  title: string;
  additionalTitle?: React.ReactElement;
  setFilter: (makeFilter: object) => void;
  filter: AnyObject;
  buttons?: ButtonDetailsType[];
};

export const BrandList = ({ title, columns, rows, working, fetchMore, onSortChange, orderBy, direction, setFilter, filter, buttons }: CarriersListProps) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <ScreenAligned
      title={title}
      additionalTitle={
        <div>
          {buttons
            ? buttons.map(({ title: buttonTittle, onClick, variant, id: idMap }) => (
                <Button key={idMap} onClick={onClick} variant={variant} color="primary" style={{ ...(isSmallScreen ? { marginBottom: '5px', width: '100%' } : { marginRight: '10px', width: 'auto' }) }}>
                  {buttonTittle}
                </Button>
              ))
            : null}
        </div>
      }
    >
      <SearchFilters onFilterChange={setFilter} filter={filter} />
      <InfoTable rows={rows} columns={columns} working={working} onBottomScroll={fetchMore} rowIdKey="transportation_company_id" onSortChange={onSortChange} orderBy={orderBy} direction={direction} />
      <LoadingData label={`${t('Loaded registers')}`} working={working} cant={rows.length} />
    </ScreenAligned>
  );
};
