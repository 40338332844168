import React, { useCallback, useMemo } from 'react';
import { useSnackbar } from 'notistack';
import EditIcon from '@material-ui/icons/Edit';
import { useTranslation } from '../../../../../services/translation';
import { AnyObject, LibbyObject } from '../../../../../types/types';
import { getValidate } from '../../utils/model';
import { formatDecimal } from '../../../../../utils';
import { MakeCell } from '../../../../components/MakeCell';
import { ZipFormDialog } from '../../components/StateFormDialog';
import EditModalRaw from '../../../../../services/customFormDialog';

const StateModal = EditModalRaw(ZipFormDialog);

const columns = [
  {
    id: 'state_id',
    label: 'Province ID',
    maxWidth: 200
  },
  {
    id: 'name',
    label: 'Name',
    maxWidth: 200,
    orderById: 'name'
  },
  {
    id: 'matches',
    label: 'Matches',
    maxWidth: 200,
    orderById: 'matches'
  },
  {
    id: 'edit',
    label: 'Edit',
    maxWidth: 200,
    noSort: true
  }
];

export const useMakeStateTable = (dataFiltered: AnyObject, libby: LibbyObject, reFetch: Function) => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const handleOpenStateModal = useCallback(
    async (currentState?: AnyObject) => {
      const matchesInit = [...currentState?.matches];
      const nameMatch = currentState?.name.toLowerCase();
      const searchMatches = matchesInit.findIndex((match: string) => match.toLowerCase() === nameMatch);

      if (searchMatches !== 0) {
        if (searchMatches > 0) {
          matchesInit.splice(searchMatches, 1, matchesInit[0]);
          matchesInit.splice(0, 1, nameMatch);
        } else {
          matchesInit.unshift(nameMatch);
        }
      }
      const paramsModal = {
        confirmText: t('Update'),
        cancelText: t('Cancel'),
        title: t('Edit Province'),
        validate: getValidate(['state_id', 'name', 'matches']),
        formInitialValues: {
          ...currentState,
          matches: matchesInit
        }
      };
      try {
        const { state_id, name, matches }: AnyObject = (await StateModal.show(paramsModal)) as AnyObject;
        if (state_id) {
          const formattedMatch =
            typeof matches !== 'string'
              ? matches
              : matches.split(',').reduce((accumulator: string, currentValue: string, index: number, array: Array<string>) => `${accumulator}${currentValue.trim()}${index === array.length - 1 ? '}' : ','}`, '{');

          try {
            await libby.ster_state.save({
              ...currentState,
              state: { state_id },
              name,
              matches: formattedMatch
            });
            reFetch();
            enqueueSnackbar(t('Province updated'), { variant: 'success' });
          } catch (e: any) {
            enqueueSnackbar(t('Something is wrong'), { variant: 'error' });
          }
        }
      } catch (e: any) {
        // nothing
      }
    },
    [enqueueSnackbar, libby.ster_state, t, reFetch]
  );

  const rows = useMemo(
    () =>
      dataFiltered.map((state: AnyObject) => ({
        id: formatDecimal(state.state_id),
        state_id: formatDecimal(state.state_id),
        name: state.name,
        matches: state?.matches?.join(', '),
        edit: <MakeCell label="" icon={EditIcon} onClick={() => handleOpenStateModal(state)} />
      })),
    [dataFiltered, handleOpenStateModal]
  );

  return {
    columns,
    rows
  };
};
