import { LibbyFetchDAO } from '../LibbyFetchDAO';

export class PaymentMethodCurrencyDAO extends LibbyFetchDAO {
  constructor() {
    super('paymentmethodcurrency', 'payment_method_currency_id');
  }

  getAll() {
    return this.query().limit(0, 10000).run();
  }

  getByCurrency(currency_id: number) {
    return this.query().equals('Currency.currency_id', currency_id).and().equals('enable', true).orderBy('PaymentMethod.PaymentType.payment_type_id', 'asc').orderBy('payment_method_currency_id', 'asc').run();
  }
}
