import React, { useCallback, useEffect, useMemo, useState } from 'react';
import moment, { Moment } from 'moment';
import { useField, useForm } from 'react-final-form-hooks';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { ThemeProvider } from '@material-ui/styles';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { FormProps, LibbyObject } from '../../../../../types';
import { DateClose } from '../../../../../components/DateClose';
import { useTranslation, useLanguage } from '../../../../../services/translation';
import { formatDate, makeGetErrorAndHelperText, replaceNonNumericCharacters, textFieldProps } from '../../../../../utils';
import { ResetForm } from '../../../../../components/ResetForm';
import { useModalFormStyles } from '../../../../../theme';
import { SelectTrimester } from '../../../../../components/SelectTrimester';
import { DataPeriodNotEnabled } from '../type';
import { Goal, Marketplace } from '../../../../../interfaces/business';
import { GOAL_RANGE_TYPE, GOAL_TYPE } from '../../../../../const';
import { Category } from '../../../../../interfaces/business/product/Category';

export type CreateGoalPeriodType = {
  value: number;
  start_date: Moment;
  end_date: Moment;
};
export interface AddGoalFormProps extends FormProps {
  editForm: boolean;
  allDataPeriodNotEnabled: DataPeriodNotEnabled[];
  goal?: Goal;
  libby: LibbyObject;
}

export const AddGoalPeriodForm = ({
  open = false,
  onCancel = () => {},
  onConfirm = () => {},
  title = 'Confirmation Dialog',
  cancelText = 'Cancel',
  confirmText = 'confirmText',
  customTheme = null,
  validate,
  formInitialValues = {},
  editForm,
  allDataPeriodNotEnabled,
  goal,
  libby
}: AddGoalFormProps) => {
  const { t } = useTranslation();
  const { getLanguage } = useLanguage();
  const language = getLanguage();
  const styles = useModalFormStyles();
  const getErrorAndHelperText = makeGetErrorAndHelperText(t);

  const [invalidType, setInvalidType] = useState<string[]>([]);

  const onSubmit = useCallback(
    async ({ value, year, trimester_date, month_year }) => {
      let start_date = '';
      let end_date = '';

      switch (goal?.goal_period[0].goal_range_type_id.toString()) {
        case GOAL_RANGE_TYPE.QUARTERLY.toString(): {
          const yearDate = parseInt(moment(year).format('YYYY'), 10);
          start_date = formatDate(moment().quarter(trimester_date).year(yearDate).startOf('quarter').toDate(), true, false, 'YYYY-MM-DD HH:mm:ss');
          end_date = formatDate(moment().quarter(trimester_date).year(yearDate).endOf('quarter').toDate(), true, false, 'YYYY-MM-DD HH:mm:ss');
          break;
        }
        case GOAL_RANGE_TYPE.MONTHLY.toString(): {
          start_date = formatDate(moment(month_year).startOf('month').toDate(), true, false, 'YYYY-MM-DD HH:mm:ss');
          end_date = formatDate(moment(month_year).endOf('month').toDate(), true, false, 'YYYY-MM-DD HH:mm:ss');
          break;
        }
        default: {
          break;
        }
      }

      onConfirm({
        end_date,
        start_date,
        value
      });
    },
    [goal, onConfirm]
  );

  const { form, handleSubmit, submitError } = useForm({
    initialValues: formInitialValues,
    onSubmit,
    validate: (values: any) => {
      const validationErrors = validate && validate(values);
      const manualValidationErrors: any = {};

      if (invalidType.length) {
        manualValidationErrors.month_year = `${t('The marketplaces ($$$$) are already registered for the selected period').replace('$$$$', invalidType.join(', '))}`;
      }
      if (goal?.goal_period[0].goal_range_type_id.toString() === GOAL_RANGE_TYPE.QUARTERLY.toString()) {
        if (!values.year) manualValidationErrors.year = t('Please fill out this field');
        if (!values.trimester_date) manualValidationErrors.trimester_date = t('Please fill out this field');
      } else if (goal?.goal_period[0].goal_range_type_id.toString() === GOAL_RANGE_TYPE.MONTHLY.toString()) {
        if (!values.month_year) manualValidationErrors.month_year = t('Please fill out this field');
      } else if (goal?.goal_period[0].goal_range_type_id.toString() === GOAL_RANGE_TYPE.WEEKLY.toString()) {
        if (!values.year_week) manualValidationErrors.year_week = t('Please fill out this field');
      }

      return { ...(validationErrors || {}), ...manualValidationErrors };
    }
  });

  const valueGoal = useField('value', form);
  const year = useField('year', form);
  const trimester_date = useField('trimester_date', form);
  const month_year = useField('month_year', form);

  const quarterDisabled = useMemo(() => {
    if (year.input.value) {
      const yearDate = moment(year.input.value).format('YYYY');
      return allDataPeriodNotEnabled.filter(({ year: yearDataPeriodNotEnabled }: DataPeriodNotEnabled) => yearDataPeriodNotEnabled === yearDate).map(({ quarter }: DataPeriodNotEnabled) => quarter);
    }
    return [];
  }, [allDataPeriodNotEnabled, year]);

  const validationGoalType = useCallback(async () => {
    if (!libby) {
      return;
    }
    if (month_year.input.value && !editForm) {
      const type = goal?.goal_type.goal_type_id.toString() === GOAL_TYPE.MARKETPLACE.toString() ? 'Marketplace' : 'Category';

      const res = await libby.ster_goal_field_enabled
        .query()
        .equals('type', type)
        .and()
        .equals('start_date', `${month_year.input.value?.date(1).format('YYYY-MM-DD')} 00:00:00`)
        .run();

      switch (type) {
        case 'Marketplace': {
          const validMarketplace = res.map((item: Marketplace) => item.marketplace_id);

          setInvalidType(goal?.goal_marketplace.filter((item) => !validMarketplace.includes(item.marketplace.marketplace_id)).map((item) => item.marketplace.name) || []);
          break;
        }
        case 'Category': {
          const validCategories = res.map((item: Category) => item.category_id);

          setInvalidType(goal?.categories?.filter((item) => !validCategories.includes(item.category_id)).map((item) => item.name) || []);
          break;
        }
        default: {
          break;
        }
      }
    }
  }, [editForm, goal, libby, month_year.input.value]);

  useEffect(() => {
    validationGoalType();
  }, [validationGoalType]);

  const dialog = (
    <Dialog open={open} onClose={onCancel} fullWidth aria-labelledby="form-dialog-title">
      <DialogContent className={styles.title}>
        <Typography variant="h3">{title}</Typography>
      </DialogContent>
      <form noValidate onSubmit={handleSubmit}>
        <DialogContent className={styles.content}>
          {goal?.goal_period[0].goal_range_type_id.toString() === GOAL_RANGE_TYPE.QUARTERLY.toString() && (
            <>
              <DateClose views={['year']} name="Start Date" value={year.input.value ? moment(year.input.value).utc() : null} onChange={year.input.onChange} disableFuture={false} {...getErrorAndHelperText(year)} />
              <SelectTrimester
                disabled={!year.input.value}
                quarterDisabled={quarterDisabled}
                inputProps={{
                  onChange: trimester_date.input.onChange,
                  value: trimester_date.input.value || '',
                  ...getErrorAndHelperText(trimester_date)
                }}
              />
            </>
          )}

          {goal?.goal_period[0].goal_range_type_id.toString() === GOAL_RANGE_TYPE.MONTHLY.toString() && (
            <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils} locale={language}>
              <DatePicker
                value={month_year.input.value ? moment(month_year.input.value) : null}
                autoOk
                onChange={month_year.input.onChange}
                views={['month', 'year']}
                fullWidth
                label={t('Month & year')}
                InputLabelProps={{
                  shrink: true
                }}
                {...getErrorAndHelperText(month_year)}
                disabled={editForm}
              />
            </MuiPickersUtilsProvider>
          )}

          <TextField
            {...textFieldProps(`${t('Value')} (${t(goal?.goal_type_value.name || '')})`)}
            {...valueGoal.input}
            {...getErrorAndHelperText(valueGoal)}
            variant="standard"
            onChange={(event) => {
              const { value } = event.target;
              event.target.value = replaceNonNumericCharacters(value);
              valueGoal.input.onChange(event);
              return event;
            }}
            type="text"
          />

          {submitError && (
            <Typography style={{ display: 'block' }} variant="overline" color="error">
              {t(submitError)}
            </Typography>
          )}
        </DialogContent>
        <DialogActions>
          <ResetForm form={form} open={open} />
          <Button
            onClick={() => {
              onCancel();
            }}
          >
            {cancelText}
          </Button>
          <Button color="primary" type="submit">
            {confirmText}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );

  return !customTheme ? dialog : <ThemeProvider theme={customTheme}>{dialog}</ThemeProvider>;
};
