import { State } from '../interfaces/business';
import { makeBusinessEntity, PartialBusinessEntityOptions } from '../lib/libby/generator/makeBusinessEntity';

import { businessOptions } from './businessOptions';

const DAO_NAME = 'order_state';

const defaultOptions: PartialBusinessEntityOptions<any> = {
  hoc: {
    withEntityAutocomplete: {
      optionLabelKey: 'name'
    },
    withAccordionSelector: {
      idKey: 'order_state_id',
      labelKey: 'name'
    },
    withSelectedChips: {
      idKey: 'order_state_id',
      labelKey: 'name'
    }
  }
};

const optionsToUse = !businessOptions.State
  ? defaultOptions
  : {
      ...defaultOptions,
      ...businessOptions.State,
      hoc: { ...defaultOptions.hoc, ...businessOptions.State.hoc }
    };

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch },
  hoc: { entityAutocomplete, renderPropCall, renderPropFetchById, simpleSelect, accordionSelector, selectedChips }
} = makeBusinessEntity<State>(DAO_NAME, optionsToUse);

// hooks
export const useStateFetchById = fetchById;
export const useStateLibbyCall = libbyCall;
export const useStateDAO = libbyDAO;
export const useStateLibbyFetch = libbyFetch;

// components/hoc
export const StateAutocomplete = entityAutocomplete;
export const StateCall = renderPropCall;
export const StateById = renderPropFetchById;
export const StateSelect = simpleSelect;
export const StateAccordionSelector = accordionSelector;
export const StateSelectedChips = selectedChips;
