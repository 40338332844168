import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import { columns } from './columns';
import { PaginatedTable } from '../../../../../components/PaginatedTable';
import { columnsBuyerLogs } from './columnsBuyerLog';
import { columnsShipmentAddressLogs } from './columnsShipmentAddressLog';

interface TabPanelInterface {
  children: any;
  value: number;
  index: number;
}

const TabPanel = (props: TabPanelInterface) => {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} id={`full-width-tabpanel-${index}`} aria-labelledby={`full-width-tab-${index}`} {...other}>
      {value === index && <Box>{children}</Box>}
    </div>
  );
};

const a11yProps = (index: number) => ({
  id: `full-width-tab-${index}`,
  'aria-controls': `full-width-tabpanel-${index}`
});

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    width: '100%'
  }
}));

interface StateLogsTabInterface {
  data: any;
  dataBuyer: any;
  dataShipment: any;
}

export const StateLogsTab = ({ data, dataBuyer, dataShipment }: StateLogsTabInterface) => {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  return (
    <div className={classes.root}>
      <AppBar position="static" color="default">
        <Tabs value={value} onChange={handleChange} indicatorColor="primary" textColor="primary" variant="fullWidth" aria-label="full width tabs">
          <Tab label="Order Logs" {...a11yProps(0)} />
          <Tab label="Buyer Logs" {...a11yProps(1)} />
          <Tab label="Shipment Logs" {...a11yProps(2)} />
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
        <PaginatedTable headCells={columns} rows={data} sortByDefaultBy="updated_at" rowIdKey="order_log_id" />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <PaginatedTable headCells={columnsBuyerLogs} rows={dataBuyer} sortByDefaultBy="updated_at" rowIdKey="order_log_id" />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <PaginatedTable headCells={columnsShipmentAddressLogs} rows={dataShipment} sortByDefaultBy="updated_at" rowIdKey="order_log_id" />
      </TabPanel>
    </div>
  );
};
