import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { Login } from '../Login';

export const Home = () => {
  return (
    <Switch>
      <Route path="/">
        <Login />
      </Route>
    </Switch>
  );
};
