import React from 'react';
import { Box, capitalize } from '@material-ui/core';
import { OrderStateColors } from '../../const';
import { useTranslation } from '../../services/translation';

export const StateRowsComponent = ({ order_state_id, name }: { order_state_id: string; name: string }) => {
  const { t } = useTranslation();
  return (
    <Box color={OrderStateColors[order_state_id || '']} whiteSpace="nowrap">
      {name && t(capitalize(name))}
    </Box>
  );
};
