import { LibbyFetchDAO } from './LibbyFetchDAO';

export class DispatchCartWithCollectDAO extends LibbyFetchDAO {
  constructor() {
    super('ster_dispatch_cart_with_collect', 'cart_id');
  }

  getAll() {
    return this.query().limit(0, 10000).run();
  }
}
