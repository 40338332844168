import { useCallback, useMemo } from 'react';
import { useSnackbar } from 'notistack';

import { useHistory, useLocation, useParams } from 'react-router-dom';
import { orderBy as orderByLodash } from 'lodash';
import { useTranslation } from '../../../../../services/translation';
import { useBreadcrumbs, useCheckAll, useFilerParamsId } from '../../../../../hooks';
import { GoalFieldsEnabled, GoalMarketplaceNoGoal, GoalProductEnabled, GoalProductNoGoal, Marketplace, GoalUserEnabled, GoalUserNoGoal, Account } from '../../../../../interfaces/business';

import { AnyObject } from '../../../../../types';
import { useGoalFetchById, useGoalUpdateDAO } from '../../../../../business/goal';
import { useGoalFieldsEnabledLibbyCall } from '../../../../../business/goal/GoalFieldsEnabled';
import { columnsReportingGoalSaleSettingFieldAdd, columnsReportingGoalSaleSettingFieldProductsAdd, columnsReportingGoalSaleSettingFieldUserAdd } from '../utils/columnsReportingGoalSaleSettingFieldsAdd';
import { GOAL_TYPE } from '../../../../../const';
import { TitleBreadcrumbs } from '../../../../../interfaces';
import { ButtonDetailsType } from '../../../../../types/Button';

export const useReportingGoalSaleSettingFieldsAddLogic = () => {
  const { goal_id } = useParams<{ goal_id: string }>();
  const { data } = useGoalFetchById(goal_id, 'list_all_goal');

  const goalType = useMemo(() => data?.goal_type.goal_type_id, [data]);
  const { state } = useLocation<{ goBack: TitleBreadcrumbs[] }>();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();

  const selectTitle = useMemo(() => (goalType ? (goalType === GOAL_TYPE.MARKETPLACE.toString() ? 'Fields marketplace' : 'Fields products') : 'Fields'), [goalType]);

  const title = useBreadcrumbs(selectTitle);

  const titleShow = useBreadcrumbs(selectTitle, state && state?.goBack?.length ? state.goBack : title);

  /* --------------------------------------------------------------------------*/
  const { orderBy, direction, handleRequestSort, setFilter, filter } = useFilerParamsId({
    orderInit: 'name',
    daoName: 'ster_list_date',
    aspect: 'list_product_not_in_goal'
  });

  const { data: dataFields, working: workingFields } = useGoalFieldsEnabledLibbyCall({
    methodName: 'getAllGoalFieldEnabled',
    params: [{ goal_id }]
  });

  const dataFieldsNotInGoal = useMemo(() => {
    const dataFieldsWithType = dataFields as GoalFieldsEnabled;
    return goalType === GOAL_TYPE.PRODUCT.toString() ? dataFieldsWithType?.product : goalType === GOAL_TYPE.MARKETPLACE.toString() ? dataFieldsWithType?.marketplace : GOAL_TYPE.USER.toString() ? dataFieldsWithType?.account : [];
  }, [dataFields, goalType]);

  const keyFields = useMemo(() => (goalType === GOAL_TYPE.PRODUCT.toString() ? 'sku' : goalType === GOAL_TYPE.MARKETPLACE.toString() ? 'marketplace_id' : goalType === GOAL_TYPE.USER.toString() ? 'account_id' : ''), [goalType]);

  const { checked, columnAll, handleCheckId, resetCheck } = useCheckAll(dataFieldsNotInGoal as AnyObject, keyFields);

  const columns = useMemo(() => {
    const dataType = data?.goal_type.goal_type_id;
    const columnFieldProduct = dataType === GOAL_TYPE.PRODUCT.toString() ? columnsReportingGoalSaleSettingFieldProductsAdd : [];
    return [columnAll, ...(dataType !== GOAL_TYPE.USER.toString() ? [...columnsReportingGoalSaleSettingFieldAdd, ...columnFieldProduct] : [...columnsReportingGoalSaleSettingFieldUserAdd])];
  }, [data, columnAll]);

  const goalUpdateDAO = useGoalUpdateDAO();

  const addItemSelect = useCallback(async () => {
    const newProducts = checked.all as GoalProductEnabled[];

    const newMarketplaces = checked.all as Marketplace[];

    const newUsers = checked.all as Account[];

    const goalProductOld: GoalProductNoGoal[] = data?.goal_product?.length ? [...data?.goal_product] : [];

    const goalMarketplaceOld: GoalMarketplaceNoGoal[] = data?.goal_marketplace?.length ? [...data?.goal_marketplace] : [];

    const goalUserOld: GoalUserNoGoal[] = data?.goal_user?.length ? [...data?.goal_user] : [];

    if (goalType === GOAL_TYPE.PRODUCT.toString()) {
      newProducts.forEach(({ sku }: GoalProductEnabled) => {
        goalProductOld.push({
          sku
        });
      });
    } else if (goalType === GOAL_TYPE.MARKETPLACE.toString()) {
      newMarketplaces.forEach((newMarketplace: Marketplace) => {
        goalMarketplaceOld.push({
          marketplace: { ...newMarketplace }
        });
      });
    } else if (goalType === GOAL_TYPE.USER.toString()) {
      newUsers.forEach((newUser: any) => {
        goalUserOld.push({
          account: { ...newUser }
        });
      });
    }

    await goalUpdateDAO.aspect('save_field').save({
      ...data,
      goal_product: goalProductOld,
      goal_marketplace: goalMarketplaceOld,
      goal_user: goalUserOld
    });
    enqueueSnackbar(t('Successfully added products'), { variant: 'success' });
    history.push({
      pathname: `/reporting-goal-sales/setting/fields/${goal_id}`,
      state: {
        goBack: state.goBack
      }
    });
  }, [history, enqueueSnackbar, state.goBack, t, checked, goalUpdateDAO, data, goal_id, goalType]);

  const rowsProducts = useMemo(() => {
    const dataFieldsWithType = dataFields as GoalFieldsEnabled;
    const { search } = filter;
    const valueSearch = search?.[0].value;
    const allRowsProducts =
      dataFieldsWithType?.product?.length && valueSearch
        ? dataFieldsWithType?.product.filter((rowsField) => rowsField?.sku.toLowerCase().includes(valueSearch.toLowerCase()) || rowsField?.name.toLowerCase().includes(valueSearch.toLowerCase()))
        : dataFieldsWithType?.product;

    const rows = allRowsProducts?.length
      ? allRowsProducts?.map((dataProduct: GoalProductEnabled) => ({
          select_order_id: handleCheckId(dataProduct.sku),
          id: dataProduct.sku,
          ...dataProduct
        }))
      : [];

    return orderByLodash(rows, [orderBy], [direction]);
  }, [dataFields, handleCheckId, orderBy, direction, filter]);

  const rowsMarketplace = useMemo(() => {
    const dataFieldsWithType = dataFields as GoalFieldsEnabled;
    const { search } = filter;
    const valueSearch = search?.[0].value;
    const allRowsMarketplace =
      dataFieldsWithType?.marketplace?.length && valueSearch ? dataFieldsWithType?.marketplace.filter((rowsField) => rowsField?.name.toLowerCase().includes(valueSearch.toLowerCase())) : dataFieldsWithType?.marketplace;

    const rows = allRowsMarketplace?.length
      ? allRowsMarketplace?.map((dataProduct: Marketplace) => ({
          select_order_id: handleCheckId(dataProduct.marketplace_id),
          id: dataProduct.marketplace_id,
          ...dataProduct
        }))
      : [];
    return orderByLodash(rows, [orderBy], [direction]);
  }, [dataFields, handleCheckId, direction, orderBy, filter]);

  const rowsUser = useMemo(() => {
    const dataFieldsWithType = dataFields as GoalFieldsEnabled;
    const { search } = filter;
    const valueSearch = search?.[0].value;
    const allRowsUser = dataFieldsWithType?.account?.length && valueSearch ? dataFieldsWithType?.account.filter((rowsField) => rowsField?.username.toLowerCase().includes(valueSearch.toLowerCase())) : dataFieldsWithType?.account;

    const rows = allRowsUser?.length
      ? allRowsUser?.map((dataUser: GoalUserEnabled) => ({
          select_order_id: handleCheckId(dataUser.account_id),
          id: dataUser.account_id,
          ...dataUser
        }))
      : [];
    return orderByLodash(rows, [orderBy], [direction]);
  }, [dataFields, handleCheckId, direction, orderBy, filter]);

  const rowsFields = useMemo(
    () => (!data?.goal_type.goal_type_id ? [] : data?.goal_type.goal_type_id === GOAL_TYPE.PRODUCT.toString() ? rowsProducts : data?.goal_type.goal_type_id === GOAL_TYPE.MARKETPLACE.toString() ? rowsMarketplace : rowsUser),
    [data, rowsProducts, rowsMarketplace, rowsUser]
  );

  const buttonDetails = useMemo((): ButtonDetailsType[] => {
    return [
      {
        id: 'addFieldGoalSales',
        onClick: addItemSelect,
        title: 'Add',
        type: 'submit',
        variant: 'contained',
        disabled: !checked.all.length
      }
    ];
  }, [addItemSelect, checked]);

  return { buttonDetails, selectTitle, rowsProducts: rowsFields, fetchMore: () => {}, working: workingFields, columns, checked, resetCheck, addItemSelect, orderBy, direction, handleRequestSort, setFilter, filter, titleShow };
};
