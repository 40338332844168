import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { userInfoType } from '../../../types';
import { ACCOUNT_ROLE } from '../../../const';
import { useGlobalContext } from '../../../hooks';

export const useInitFilterCollect = () => {
  const {
    userInfo
  }: {
    userInfo: userInfoType;
  } = useGlobalContext();

  const { dispatchId }: { id: string; dispatchId: string } = useParams();
  const rolAdminLogistics = userInfo?.groups?.[0].id === ACCOUNT_ROLE.ADMINISTRATOR || userInfo?.groups?.[0].id === ACCOUNT_ROLE.LOGISTICS;

  const dispatch = useMemo(
    () =>
      dispatchId
        ? [
            {
              path: 'dispatch.dispatch_id',
              value: dispatchId
            }
          ]
        : [],
    [dispatchId]
  );

  const assigned = useMemo(
    () =>
      !rolAdminLogistics && [
        {
          path: 'account.account_id',
          value: userInfo?.id
        }
      ],
    [userInfo, rolAdminLogistics]
  );

  const initFilter = useMemo(
    () => ({
      assigned,
      dispatch
    }),
    [assigned, dispatch]
  );

  return {
    initFilter,
    rolAdminLogistics,
    dispatchId,
    userId: userInfo?.id
  };
};
