import React from 'react';
import { Grid } from '@material-ui/core';
import moment from 'moment';
import { FilterBar } from '../../../components/FilterBar';
import { gridBreakpointsFields } from '../../../const';
import { DateClose } from '../../../components/DateClose';
import { InitialValuesFilter } from '../hooks';

type SearchFiltersProps = {
  onFilterChange: (param: object, type: string) => void;
  filter: InitialValuesFilter;
};

const SearchFiltersRaw = ({ onFilterChange, filter }: SearchFiltersProps) => {
  const { startDate, endDate } = filter;

  return (
    <FilterBar
      defaultFilter={
        <Grid container spacing={4}>
          <Grid item {...gridBreakpointsFields}>
            <DateClose name="Start Date" value={startDate} onChange={(e: any) => onFilterChange(e || moment(new Date().toJSON().slice(0, 10)), 'startDate')} />
          </Grid>
          <Grid item {...gridBreakpointsFields}>
            <DateClose name="End Date" value={endDate} onChange={(e: any) => onFilterChange(e, 'endDate')} />
          </Grid>
        </Grid>
      }
    />
  );
};

export const SearchFilters = React.memo(SearchFiltersRaw);
