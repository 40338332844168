import { Moment } from 'moment';
import { AnyObject } from '../../../types/types';
import { endDateFormatUtc, startDateFormatUtc } from '../../../utils';

type MakeFilterProps = {
  search?: string;
  startDate?: Moment | null;
  endDate?: Moment | null;
};

export const makeFilter = ({ search, startDate, endDate }: MakeFilterProps) => {
  let filter: AnyObject = {};

  if (search) {
    filter = search.split(' ').reduce((accumulator: AnyObject, item, index) => {
      accumulator[index] = [
        {
          path: 'order_id',
          value: search,
          method: 'includes'
        },
        {
          path: 'owner_id',
          value: item,
          method: 'includes'
        },
        {
          path: 'so',
          value: item,
          method: 'includes'
        },
        {
          path: 'courier',
          value: item,
          method: 'includes'
        },
        {
          path: 'courier_state',
          value: item,
          method: 'includes'
        }
      ];

      return accumulator;
    }, {});
  }

  if (startDate) {
    filter.startDate = [
      {
        path: 'dispatch_date',
        value: startDateFormatUtc(startDate),
        method: 'higherOrEquals'
      }
    ];
  }
  if (endDate) {
    filter.endDate = [
      {
        path: 'dispatch_date',
        value: endDateFormatUtc(endDate),
        method: 'lowerOrEquals'
      }
    ];
  }
  return filter;
};
