import React, { MouseEventHandler, useCallback } from 'react';
import { useField, useForm } from 'react-final-form-hooks';
import { Box, Button, Dialog, Grid, IconButton, Typography } from '@material-ui/core';
import { makeStyles, ThemeProvider } from '@material-ui/styles';
import CloseIcon from '@material-ui/icons/Close';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import { useTranslation } from '../../../services/translation';
import { validateConfirmBudgetForm } from '../utils';
import { SelectConfirmation } from '../../../components/SelectConfirmation';

interface ConfirmBudgetDialogProps {
  initialValuesForm: {
    confirmed: boolean;
  };
  onConfirm: (values: { confirmed: boolean }) => void;
  title: React.ReactNode;
  open: boolean;
  onCancel: MouseEventHandler;
  customTheme: { [k: string]: any } | null;
}

const useStyles = makeStyles(() => ({
  modalTitle: {
    '& h2.MuiTypography-h6': {
      textTransform: 'uppercase',
      fontSize: '16px !important',
      fontWeight: 600,
      color: '#000'
    }
  },
  modalContent: {
    fontSize: 16
  },
  textFieldSelect: {
    '& label': {
      color: '#999999'
    },
    '& input': {
      color: 'black'
    },
    '& .MuiFormHelperText-root': {
      color: '#FF5179'
    },
    width: '100%',
    marginBottom: '18px'
  },
  root: {
    '& .MuiPaper-root': {
      borderRadius: '8px'
    }
  }
}));

export const ConfirmBudgetDialog = ({ initialValuesForm, onConfirm, open, title, onCancel, customTheme }: ConfirmBudgetDialogProps) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const onSubmit = useCallback(
    (param) => {
      onConfirm({ confirmed: param.confirmed === 'yes' });
    },
    [onConfirm]
  );

  const { form, handleSubmit } = useForm({
    initialValues: initialValuesForm,
    onSubmit,
    validate: validateConfirmBudgetForm
  });

  const confirmed = useField('confirmed', form);

  const dialog = (
    <Dialog open={open} onClose={onCancel} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" className={classes.root}>
      <DialogTitle className={classes.modalTitle} id="alert-dialog-title">
        <Grid container justify="space-between" alignItems="center">
          {title}
          {onCancel && (
            <>
              <IconButton aria-label="close" onClick={onCancel}>
                <CloseIcon />
              </IconButton>
            </>
          )}
        </Grid>
      </DialogTitle>
      <DialogContent>
        <DialogContentText className={classes.modalContent} id="alert-dialog-description">
          <form onSubmit={handleSubmit}>
            <Box>
              <Typography style={{ color: '#000', textTransform: 'uppercase', fontWeight: 600, fontSize: '16px' }}>{t('Is the budget approved by the client?')}</Typography>
              <SelectConfirmation name="confirmed" inputProps={confirmed.input} />
            </Box>
            <Box display="flex" justifyContent="flex-end" marginTop="5px">
              <Button type="submit" variant="contained" color="primary" disabled={!confirmed.input.value}>
                {t('Save')}
              </Button>
            </Box>
          </form>
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );

  return !customTheme ? dialog : <ThemeProvider theme={customTheme}>{dialog}</ThemeProvider>;
};
