import { LibbyFetchDAO } from '../LibbyFetchDAO';

export class OrderBuyerLogsDAO extends LibbyFetchDAO {
  constructor() {
    super('ster_order_buyer_logs', 'order_buyer_log_id');
  }

  getAll() {
    return this.query().limit(0, 10000).run();
  }

  getAllByOrderId(id: number) {
    return this.query().equals('order.order_id', id).run();
  }
}
