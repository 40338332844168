import React, { ReactNode, useCallback, useEffect, useState, useMemo } from 'react';
import DeleteIcon from '@material-ui/icons/Delete';
import { Grid } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { AnyObject, LibbyObject } from '../../../types/types';
import { useLibbyFetchById } from '../../../hooks';
import { columnsAddOrder } from '../../Dispatches/routes/DispatchOrderList/components/DispatchOrderListTable';
import { useTranslation } from '../../../services/translation';
import { formatMoney } from '../../../utils';
import { LaunchIconComponent } from '../../../components/Icon/LaunchIcon';
import { MakeCell } from '../../components/MakeCell';
import DeleteModal from '../../../services/confirmDialog';
import { useCheckAll } from '../../../hooks/useCheckAll';
import { Collect_item, Collect, Collect_item_product } from '../../../interfaces/business';
import { Collect_Item_State, CollectItemState } from '../../../const/CollectItemState';
import { useCollectClose } from './useCollectClose';

import { useCreateUpdateCollectModal } from './useCreateUpdateCollectModal';

interface useCollectsDetailProp {
  libby: LibbyObject;
  id: string;
  handleUpdateCollectList: (collect: Collect, id: string) => void;
  dataGeneral?: Array<Collect>;
}

export type orderArrayItemsType = {
  id?: string;
  order_id?: string;
  order_state_id?: string;
  order_state_name?: string;
  document?: string;
  phone_number?: string;
  buyer?: string;
  itemsName?: string;
  itemsQuantity?: string;
  updated_by?: string;
  courier?: string;
  updated_at?: string;
  so_number?: string;
  Collect_item_id?: string;
  delete?: ReactNode;
};

const skuExceptions = ['desc_esp_105', 'desc_esp_21', 'DES_PLATAF', 'DESC_21', 'DESC_105', 'BNA_COSTO_FINANCIERO'];

export const useCollectsDetail = ({ libby, id, handleUpdateCollectList }: useCollectsDetailProp) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [msg, setMsg] = useState('');
  const [data, setData] = useState<Collect>();

  const updateInOpenCollectModal = useCallback(
    (collect: Collect, nameId: string): void => {
      const newCollect = {
        ...collect,
        items: data?.items || []
      };

      setData(newCollect);
      handleUpdateCollectList(collect, nameId);
    },
    [handleUpdateCollectList, data]
  );

  const { handleOnOpenCollectModal } = useCreateUpdateCollectModal({
    libby,
    updateData: updateInOpenCollectModal
  });

  const { working, data: dataDetails } = useLibbyFetchById(libby, {
    daoName: 'ster_dispatch_collect_details',
    id,
    aspect: 'list_details'
  });

  const { onCloseCollect, orderStateState } = useCollectClose({ updateData: setData, libby, data: dataDetails });

  const orderItems: Collect_item[] = useMemo(() => data?.items || [], [data]);

  const { checked, columnAll, handleCheckId, resetCheck } = useCheckAll(orderItems, 'collect_item_id');

  // TODO refactor the sku filter with a new feature
  useEffect(() => {
    if (dataDetails && Object.entries(dataDetails).length) {
      setData({
        ...dataDetails,
        items: dataDetails.items.map((item: AnyObject) => ({
          ...item,
          dispatch_item: {
            ...item.dispatch_item,
            order: { ...item.dispatch_item.order, items: item.dispatch_item.order.items.filter((orderItem: AnyObject) => !skuExceptions.includes(orderItem.sku)) }
          }
        }))
      });
    }
  }, [dataDetails]);

  const refreshDataGeneral = useCallback(
    (newDate) => {
      handleUpdateCollectList(newDate, 'collect_id');
    },
    [handleUpdateCollectList]
  );

  const removeItemUpdate = useCallback(
    (collect_item_id) => {
      setData((prev: Collect | undefined) => {
        if (prev) {
          const updateItems = prev.items.filter((itemCopy: Collect_item) => itemCopy.collect_item_id !== collect_item_id);

          const newPrevCollectRemoveItemUpdate = {
            ...prev,
            items: updateItems
          };
          refreshDataGeneral(newPrevCollectRemoveItemUpdate);
          return newPrevCollectRemoveItemUpdate;
        }
        return prev;
      });
    },
    [refreshDataGeneral]
  );

  const removeProduct = useCallback(
    async (collect_product_data) => {
      await libby.ster_dispatch_collect_item.aspect('collect_delete').save({
        collect_item_id: collect_product_data.collect_item.collect_item_id,
        collectItemState: {
          collect_item_state_id: CollectItemState.NOT_COLLECTED
        }
      });
      setData((prev: Collect | undefined) => {
        if (prev) {
          const items = [...prev.items];

          const searchItems = items.findIndex((itemCopy: Collect_item) => itemCopy.collect_item_id === collect_product_data.collect_item.collect_item_id);

          const items_product_delete = items[searchItems].items_product.filter((items_product: Collect_item_product) => items_product.order_item.order_item_id !== collect_product_data.order_item.order_item_id);

          items[searchItems].items_product = items_product_delete;
          items[searchItems].collectItemState = {
            collect_item_state_id: CollectItemState.NOT_COLLECTED,
            name: Collect_Item_State[CollectItemState.NOT_COLLECTED]
          };
          const newPrevCollectRemoveProduct = {
            ...prev,
            items
          };
          refreshDataGeneral(newPrevCollectRemoveProduct);
          return newPrevCollectRemoveProduct;
        }
        return prev;
      });
    },
    [libby, refreshDataGeneral]
  );

  const addProduct = useCallback(
    (collect_product_data) => {
      setData((prev: Collect | undefined) => {
        if (prev) {
          const items = [...prev.items];
          const searchItems = items.findIndex((itemCopy: Collect_item) => itemCopy.collect_item_id === collect_product_data.collect_item.collect_item_id);
          const products = items[searchItems].items_product;
          products.push(collect_product_data);
          items[searchItems].items_product = products;
          items[searchItems].collectItemState = collect_product_data.collect_item.collectItemState;

          const newPrevCollectAddProduct = {
            ...prev,
            collectState: collect_product_data.collect_item.collect.collectState,
            items
          };
          refreshDataGeneral(newPrevCollectAddProduct);
          return newPrevCollectAddProduct;
        }
        return prev;
      });
    },
    [refreshDataGeneral]
  );

  const itemProductAddOrRemove = useCallback(
    (add: boolean, collect_product_data) => {
      if (add) {
        addProduct(collect_product_data);
      } else {
        removeProduct(collect_product_data);
      }
    },
    [removeProduct, addProduct]
  );

  const columns = [
    {
      id: 'select_order_id',
      label: '',
      width: '60px',
      style: {
        minWidth: '50px'
      },
      noSort: true
    },
    ...columnsAddOrder
  ];

  const removeItem = useCallback(
    async (collect_item_id: string) => {
      try {
        await libby.ster_dispatch_collect_item.aspect('collect_delete').remove({
          collect_item_id
        });
        removeItemUpdate(collect_item_id);
      } catch (error: any) {
        enqueueSnackbar(`${t('Error deleting order')}: ${error}`, {
          variant: 'error'
        });
      }
    },
    [removeItemUpdate, libby, t, enqueueSnackbar]
  );

  const deleteItemInOrderItemsModal = useCallback(
    async (collect_item_id, order_id) => {
      setMsg(t('Removing order: $$$$').replace('$$$$', order_id));
      const confirm = await DeleteModal.show({
        title: t('Order Delete'),
        content: t('Do you want to delete the order of the collect $$$$?').replace('$$$$', order_id),
        confirmText: t('Yes'),
        cancelText: t('No')
      });
      if (confirm) {
        try {
          await removeItem(collect_item_id);

          enqueueSnackbar(t('Order Delete'), { variant: 'success' });
        } catch (e: any) {
          if (e) {
            enqueueSnackbar(t('Something is wrong'), { variant: 'error' });
          }
        }
      }
    },
    [enqueueSnackbar, t, removeItem]
  );

  const deleteItemsInOrdersItemsModal = useCallback(async () => {
    const orders = checked.all.map(({ dispatch_item }) => dispatch_item.order.order_id);
    const confirm = await DeleteModal.show({
      title: t('Delete orders'),
      content: t('You want to remove the following $$$$ orders from the collect?').replace('$$$$', orders.join()),
      confirmText: t('Yes'),
      cancelText: t('No')
    });
    if (confirm) {
      /* eslint-disable no-await-in-loop */
      for (let index = 0; index < checked.all.length; index += 1) {
        const {
          collect_item_id,
          dispatch_item: {
            order: { order_id }
          }
        } = checked.all[index];

        setMsg(t('Removing order: $$$$').replace('$$$$', order_id));
        try {
          await removeItem(collect_item_id);
          enqueueSnackbar(t('Order $$$$ removed').replace('$$$$', order_id), {
            variant: 'success'
          });
        } catch (e: any) {
          enqueueSnackbar(t('Something is wrong'), { variant: 'error' });
        }
      }
      /* eslint-enable no-await-in-loop */
      resetCheck();
    }
  }, [enqueueSnackbar, t, removeItem, checked, resetCheck]);

  const rows = useMemo(() => {
    const dataRow: any = [];
    orderItems.forEach(
      ({
        collect_item_id,
        dispatch_item: {
          dispatch_item_id,
          dispatch_item_state,
          order: { order_id, so_number, buyer, total, created_at, updated_by, number_items, items }
        },
        cartBox: { name },
        collectItemState,
        items_product
      }: Collect_item) =>
        dataRow.push({
          select_order_id: handleCheckId(collect_item_id),
          id: collect_item_id,
          order_id,
          so_number,
          total,
          buyer: `${buyer?.first_name} ${buyer?.last_name}`,
          items: <LaunchIconComponent order_id={order_id} number_items={number_items} />,
          created_at,
          updated_by: updated_by?.username,
          collect_item_state: Collect_Item_State[collectItemState?.collect_item_state_id],
          delete: (
            <Grid container justify="center">
              <MakeCell label="" icon={DeleteIcon} onClick={() => deleteItemInOrderItemsModal(collect_item_id, order_id)} />
            </Grid>
          ),
          order_items: items,
          cart_box: name,
          collectItemState,
          items_product,
          dispatch_item: {
            dispatch_item_id,
            dispatch_item_state
          }
        })
    );

    return dataRow;
  }, [orderItems, deleteItemInOrderItemsModal, handleCheckId]);

  const columnsDetail = [
    columnAll,
    {
      id: 'order_id',
      label: 'Order ID',
      style: {
        whiteSpace: 'nowrap'
      }
    },
    {
      id: 'so_number',
      label: 'Number so',
      style: {
        whiteSpace: 'nowrap'
      }
    },
    {
      id: 'buyer',
      label: 'Buyer',
      style: {
        whiteSpace: 'nowrap'
      }
    },
    {
      id: 'items',
      label: 'Items',
      style: {
        whiteSpace: 'nowrap'
      }
    },
    {
      id: 'total',
      label: 'Total Purchase',
      style: {
        minWidth: '80px'
      },
      render: (value: any) => formatMoney(value?.total) || '-'
    },
    {
      id: 'collect_item_state',
      label: 'State',
      style: {
        minWidth: '80px'
      },
      render: ({ collect_item_state }: any) => t(collect_item_state)
    },

    {
      id: 'delete',
      label: 'Actions',
      noSort: true,
      style: {
        whiteSpace: 'nowrap'
      }
    }
  ];

  return {
    working,
    columns,
    columnsDetails: columnsDetail,
    rows,
    data,
    deleteItemsInOrdersItemsModal,
    msg,
    checked,
    itemProductAddOrRemove,
    onCloseCollect,
    orderStateState,
    setData,
    handleOnOpenCollectModal
  };
};
