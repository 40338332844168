export const columns = [
  {
    id: 'actions',
    label: 'Actions',
    noSort: true
  },
  {
    id: 'name',
    label: 'Indicator',
    style: {
      minWidth: '125px'
    },
    render: ({ name }: { name: string }, item: any, t: (str: string) => string) => t(name ?? ''),
    noSort: true
  },
  {
    id: 'quantity_urbantech_meli',
    label: 'Urbantech Meli',
    style: {
      minWidth: '140px'
    },
    render: ({ quantity_urbantech_meli, working_urbantech_ml }: { quantity_urbantech_meli: number; working_urbantech_ml: boolean }, item: any, t: (str: string) => string) =>
      working_urbantech_ml ? t('Loading data') : quantity_urbantech_meli,
    noSort: true
  },
  {
    id: 'quantity_tienda_oficial_ml',
    label: 'Tienda Oficial ML',
    style: {
      minWidth: '140px'
    },
    render: ({ quantity_tienda_oficial_ml, working_tienda_oficial_ml }: { quantity_tienda_oficial_ml: number; working_tienda_oficial_ml: boolean }, item: any, t: (str: string) => string) =>
      working_tienda_oficial_ml ? t('Loading data') : quantity_tienda_oficial_ml,
    noSort: true
  },
  {
    id: 'quantity_novatech_meli',
    label: 'Novatech Meli',
    style: {
      minWidth: '140px'
    },
    render: ({ quantity_novatech_meli, working_novatech_ml }: { quantity_novatech_meli: number; working_novatech_ml: boolean }, item: any, t: (str: string) => string) =>
      working_novatech_ml ? t('Loading data') : quantity_novatech_meli,
    noSort: true
  }
];
