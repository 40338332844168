import React, { useCallback, useState } from 'react';
import { IconButton, Menu, MenuItem, Typography } from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { makeStyles } from '@material-ui/styles';
import { useTranslation } from '../../../services/translation';
import { MessageConfigType } from '../types/types';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column'
  },
  anchor: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: '0px 10px',
    width: '90%'
  }
});

interface RowsOptionsType {
  message_config: MessageConfigType;
  deleteMessageConfig: (message_config_id: string) => Promise<void>;
  openMessageConfigEdit: (message_config: MessageConfigType) => Promise<void>;
}

export const RowsOptions = ({ message_config, deleteMessageConfig, openMessageConfigEdit }: RowsOptionsType) => {
  const [anchorElMenu, setAnchorElMenu] = useState(null);
  const { t } = useTranslation();
  const classes = useStyles();

  const handleClick = useCallback(
    (event) => {
      setAnchorElMenu(event.currentTarget);
    },
    [setAnchorElMenu]
  );

  const handleClose = useCallback(() => {
    setAnchorElMenu(null);
  }, [setAnchorElMenu]);

  const open = Boolean(anchorElMenu);
  const idPopover = open ? 'simple-menu' : undefined;

  return (
    <>
      <IconButton
        onClick={(e) => {
          e.stopPropagation();
          handleClick(e);
        }}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id={idPopover}
        anchorEl={anchorElMenu}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        keepMounted
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        open={open}
        onClose={handleClose}
      >
        <MenuItem
          onClick={() => {
            openMessageConfigEdit(message_config);
            setAnchorElMenu(null);
          }}
          className={classes.anchor}
        >
          <Typography variant="subtitle2" color="secondary">
            {t('Edit')}
          </Typography>
        </MenuItem>
        <MenuItem
          onClick={() => {
            deleteMessageConfig(message_config.message_config_id);
            setAnchorElMenu(null);
          }}
          className={classes.anchor}
        >
          <Typography variant="subtitle2" color="primary">
            {t('Delete')}
          </Typography>
        </MenuItem>
      </Menu>
    </>
  );
};
