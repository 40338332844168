import React, { ReactNode } from 'react';
import { TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { useTranslation } from '../../services/translation';
import { InformationSection } from '../InformationSection';

interface SearchDialogContentType {
  label: string;
  title: string;
  children: NonNullable<ReactNode>;
  onChange: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
}

const useStyles = makeStyles(() => ({
  textWidth: {
    width: '100%'
  }
}));

export const SearchDialogContent = ({ title, children, onChange, label }: SearchDialogContentType) => {
  const { t } = useTranslation();
  const classes = useStyles();
  return (
    <>
      <InformationSection title={t(title)} noPadding>
        <TextField autoFocus label={t(label)} defaultValue="" onChange={onChange} variant="outlined" type="text" className={classes.textWidth} />
      </InformationSection>
      <InformationSection title="" noPadding>
        {children}
      </InformationSection>
    </>
  );
};
