import React from 'react';
import { Box, useMediaQuery } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import { URL_GENERATE_PDF } from '../../../../const';
import { useTranslation } from '../../../../services/translation';
import { typeDocumentsType } from '../../type';

export const pdfStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'column'
  },
  rootPDF: {
    width: '80%',
    height: '40rem',
    margin: '0.5rem auto',
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    }
  },
  rootPDFDisabled: {
    width: '0%',
    height: '0rem'
  },
  rootPDFx2: {
    width: '80%',
    height: '40rem',
    margin: '0.5rem auto',
    [theme.breakpoints.down('md')]: {
      width: '90%'
    }
  },
  rootPDFx2down: {
    width: '95%',
    height: '40rem',
    margin: '0.5rem auto'
  },
  loading: {
    margin: '0 auto',
    width: '50%'
  },
  iframeEmpty: {
    border: 'transparent'
  },
  container: {
    width: '100%',
    display: 'flex',
    flex: '1',
    '@media (max-width: 768px)': {
      flexDirection: 'column'
    }
  },
  tnPresent: {
    flex: '1',
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    '& iframe': {
      margin: 'none'
    },
    '@media (max-width: 768px)': {
      flexDirection: 'column'
    }
  },
  tnNoPresent: {
    display: 'flex',
    flex: '1',
    width: '100%'
  },
  buttonError: {
    marginLeft: '15px'
  },
  containerPdf: {
    width: '100%',
    display: 'flex',
    flex: '1',
    flexDirection: 'column'
  },
  containerButtons: {
    width: '100%',
    display: 'flex',
    justifyContent: 'end',
    marginBottom: '1%'
  }
}));

interface ViewTagManagerProps {
  typeDocuments: typeDocumentsType[];
  onLoad: (typeDocumentId: string) => void;
  idUseInfo: string;
  allOrderId: string;
  keyIframe: number;
}

export const ViewTagManager = ({ idUseInfo, typeDocuments, onLoad, allOrderId, keyIframe }: ViewTagManagerProps) => {
  const classesPdf = pdfStyles();
  const customeBreakpoint = useMediaQuery<Theme>((theme) => theme.breakpoints.down(1050));
  const { t } = useTranslation();

  return (
    <div className={typeDocuments.length > 0 ? classesPdf.tnPresent : classesPdf.tnNoPresent}>
      {typeDocuments.map((typeDocument: typeDocumentsType) => (
        <div className={classesPdf.tnNoPresent}>
          {typeDocument.working && (
            <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column" width="100%">
              <CircularProgress size={24} />
              <Box py={2}>{t('Cargando etiquetas')}</Box>
            </Box>
          )}
          <iframe
            key={keyIframe}
            title="dispatchPdf"
            src={`${URL_GENERATE_PDF}/${idUseInfo}?order_id[in]=${allOrderId}&type_download[equals]=${typeDocument.id}`}
            className={typeDocument.working ? classesPdf.rootPDFDisabled : typeDocuments.length > 0 ? (customeBreakpoint ? classesPdf.rootPDFx2down : classesPdf.rootPDFx2) : classesPdf.rootPDF}
            onLoad={() => onLoad(typeDocument.id)}
          />
        </div>
      ))}
    </div>
  );
};
