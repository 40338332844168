import { makeValidator, ValidatorType, ValidatorRule } from '../../../utils';

export const formInitialValues = {
  email: ''
};

const validations: ValidatorRule[] = [{ key: 'email', validators: [ValidatorType.REQUIRED] }];

export const validate = makeValidator(validations);

export const getValidate = (keys: Array<string>) => makeValidator(keys.length > 0 ? validations.filter((val) => keys.includes(val.key)) : validations);
