import React from 'react';
import { InboxOrderTable } from './components/InboxOrderTable';
import { AnyObject } from '../../../../types/types';
import { ScreenAligned } from '../../../../components/ScreenAligned/ScreenAligned';
import { TitleBreadcrumbs } from '../../../../interfaces';

export interface OrderListProps {
  filter: object;
  setFilter: (makeFilter: object) => void;
  data: AnyObject;
  working: boolean;
  fetchMore: () => void;
  onSortChange?: (orderBy: string, direction: 'asc' | 'desc') => void;
  orderBy?: string;
  direction?: 'asc' | 'desc';
  title: TitleBreadcrumbs[];
}

export const InboxOrdersList = ({ data, working, filter, setFilter, fetchMore, onSortChange, orderBy, direction, title }: OrderListProps) => (
  <ScreenAligned title={title}>
    <InboxOrderTable filter={filter} data={data} working={working} setFilter={setFilter} fetchMore={fetchMore} onSortChange={onSortChange} orderBy={orderBy} direction={direction} />
  </ScreenAligned>
);
