import { makeValidator, ValidatorRule, ValidatorType } from '../../../../../../utils';

export const formReportingLogisticNonWorkingDayInitialValues = {
  day_date: '',
  description: ''
};

const validations: ValidatorRule[] = [
  { key: 'day_date', validators: [ValidatorType.REQUIRED] },
  { key: 'description', validators: [ValidatorType.REQUIRED] }
];

export const validateReportingLogisticSlaAdd = makeValidator(validations);
