import React, { useCallback, useMemo } from 'react';
import EditIcon from '@material-ui/icons/Edit';
import { useSnackbar } from 'notistack';
import { useFilerParamsId, useLibbyFetch } from '../../../hooks';
import { LibbyObject, RowsType, TransportationCompany } from '../../../types';
import CustomModal from '../../../services/customFormDialog';
import { CarrierFormDialog } from '../components';
import { useTranslation } from '../../../services/translation';
import { formInitialValues, validate, columns } from '../utils';
import { MakeCell } from '../../components/MakeCell';

interface useCourierLogicProps {
  libby: LibbyObject;
}

const CarrierFormModal = CustomModal(CarrierFormDialog);

export const useCarriersLogic = ({ libby }: useCourierLogicProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const { orderBy, direction, paramsFetch, handleRequestSort, setFilter, filter } = useFilerParamsId({
    orderInit: 'transportation_company_id',
    daoName: 'ster_transportation_company'
  });
  const { data: states, working: fetchingStates } = useLibbyFetch(libby, {
    daoName: 'ster_state',
    orderBy: 'name'
  });
  const { data, working, fetchMore, updateData, addCreate } = useLibbyFetch(libby, paramsFetch);
  const handleGetCity = useCallback(
    async (city: string, province: string) => {
      const cityFetch = await libby?.ster_city.query().equals('name', city).equals('state.state_id', province).run();
      return cityFetch;
    },
    [libby]
  );

  const openCarrierForm = useCallback(
    async (currentCarrier?: TransportationCompany) => {
      try {
        const formData = (await CarrierFormModal.show({
          confirmText: t(currentCarrier ? 'Update' : 'Create'),
          cancelText: t('Cancel'),
          title: `${t(currentCarrier ? 'Update' : 'Create')} ${t('Carrier')}`,
          validate,
          formInitialValues: currentCarrier || formInitialValues,
          states,
          fetchingStates
        })) as TransportationCompany;
        if (!formData) return;
        const TransportistCity = typeof formData.state === 'string' && typeof formData.city === 'string' ? await handleGetCity(formData.city, formData.state) : [];
        const transportation_company = await libby.ster_transportation_company.save({
          ...(currentCarrier && { ...currentCarrier }),
          ...formData,
          city: TransportistCity[0]
        });
        if (currentCarrier?.transportation_company_id) {
          updateData(transportation_company, 'transportation_company_id');
        } else {
          addCreate(transportation_company);
        }
        enqueueSnackbar(t('Carrier saved'), { variant: 'success' });
      } catch (error: any) {
        if (error !== false) {
          enqueueSnackbar(t('Something is wrong'), { variant: 'error' });
        }
      }
    },
    [t, libby, enqueueSnackbar, updateData, addCreate, states, fetchingStates, handleGetCity]
  );

  const rows: RowsType[] = useMemo(() => {
    const dataCarrier = data as TransportationCompany[];

    return dataCarrier.map((carrier: TransportationCompany) => ({
      id: carrier.transportation_company_id.toString(),
      transportation_company_id: carrier.transportation_company_id,
      name: carrier.name,
      state: typeof carrier.city === 'object' ? carrier.city?.state_?.name : '',
      city: typeof carrier.city === 'object' ? carrier.city?.name : '',
      address: `${carrier.street} ${carrier.street_number}. ${carrier.floor ? `${carrier.floor} - ` : ''} ${carrier.department || ''}`,
      phone_number: carrier.phone_number,
      edit: <MakeCell label="" icon={EditIcon} onClick={() => openCarrierForm(carrier)} />
    }));
  }, [data, openCarrierForm]);

  return {
    rows,
    columns,
    working,
    fetchMore,
    handleRequestSort,
    orderBy,
    direction,
    setFilter,
    filter,
    openCarrierForm
  };
};
