import { LibbyFetchDAO } from '../LibbyFetchDAO';

export class FeatureDAO extends LibbyFetchDAO {
  constructor() {
    super('ster_product_feature', 'feature_id');
  }

  getAll() {
    return this.query().limit(0, 10000).run();
  }
}
