import moment from 'moment';
import { ALLOWED_DOMAINS } from '../config';
import { isEmail } from './helpers';

export const getErrorMessage = (error: { message: string }) => error.message;
export type ValidatorFunction = (value: string, values: { [key: string]: any }) => boolean;
export type Validator = {
  validator: ValidatorFunction;
  message: string;
};
export enum ValidatorType {
  REQUIRED = 'required',
  NUMBER = 'number',
  POSITIVE = 'positive',
  EMAIL = 'email',
  DATE = 'date',
  SELECT = 'select',
  LETTERS = 'letters',
  SPECIALS = 'specials',
  POSITIVEANDCERO = 'positive or cero',
  NOTDECIMAL = 'not decimal',
  ATLEASTEIGHT = 'more than 8',
  ONEUPPERCASE = 'have uppercase',
  ONELOWERCASE = 'have lowercase',
  ONENUMBER = 'have number',
  ONESPECIAL = 'have special characters',
  ALLOWEDDOMAIN = 'allowed domains',
  MAX_FIVE = 'cannot have more than 5 characters'
}
export type ValidatorRule = {
  key: string;
  validators: Array<ValidatorType | Validator>;
};
const errorMessages = {
  [ValidatorType.REQUIRED]: 'Please fill out this field.',
  [ValidatorType.NUMBER]: 'The value is not a valid number.',
  [ValidatorType.POSITIVE]: 'The value must be a positive number',
  [ValidatorType.EMAIL]: 'The value must be a valid email',
  [ValidatorType.DATE]: 'The value must be a valid date',
  [ValidatorType.SELECT]: 'Please select an item in the list.',
  [ValidatorType.LETTERS]: 'The value must be only letters and spaces.',
  [ValidatorType.SPECIALS]: 'The value cant have special caracters',
  [ValidatorType.POSITIVEANDCERO]: 'The value must be a positive number',
  [ValidatorType.NOTDECIMAL]: 'The value must be an integer number',
  [ValidatorType.ATLEASTEIGHT]: 'The password must be at least 8 characters',
  [ValidatorType.ONEUPPERCASE]: 'The password must have at least one upper case character',
  [ValidatorType.ONELOWERCASE]: 'The password must have at least one lower case character',
  [ValidatorType.ONENUMBER]: 'The password must have at least one number character',
  [ValidatorType.ONESPECIAL]: 'The password must have at least special character',
  [ValidatorType.ALLOWEDDOMAIN]: 'The email have an invalid domain',
  [ValidatorType.MAX_FIVE]: 'The value can not have more than 5 characters'
};
export const makeValidator = (rules: ValidatorRule[]) => (values: { [key: string]: any }) =>
  rules.reduce((errors, { key, validators }: ValidatorRule) => {
    const error = validators.find((validator) => {
      if (typeof validator === 'object') return validator.validator(values[key], values);
      switch (validator) {
        case ValidatorType.REQUIRED: {
          return Array.isArray(values[key]) ? !values[key].length : !values[key];
        }
        case ValidatorType.NUMBER: {
          return Number.isNaN(+values[key]);
        }
        case ValidatorType.POSITIVE: {
          return Number.isNaN(+values[key]) || +values[key] <= 0;
        }
        case ValidatorType.POSITIVEANDCERO: {
          return Number.isNaN(+values[key]) || +values[key] < 0;
        }
        case ValidatorType.NOTDECIMAL: {
          return +values[key] % 1 !== 0;
        }
        case ValidatorType.EMAIL: {
          return !isEmail(values[key]);
        }
        case ValidatorType.DATE: {
          return !moment(values[key]).isValid();
        }
        case ValidatorType.SELECT: {
          return +values[key] === 0;
        }
        case ValidatorType.LETTERS: {
          const letters = /^[a-zA-Z\s]*$/;
          return !values[key]?.match(letters);
        }
        case ValidatorType.SPECIALS: {
          const format = /[~`.@!#$%^&*+=\-[\]\\';,/{}()|\\":<>?]/;
          return values[key].match(format);
        }
        case ValidatorType.ATLEASTEIGHT: {
          return values[key].length < 8;
        }
        case ValidatorType.ONEUPPERCASE: {
          return !/[A-Z]/.test(values[key]);
        }
        case ValidatorType.ONELOWERCASE: {
          return !/[a-z]/.test(values[key]);
        }
        case ValidatorType.ONENUMBER: {
          return !/\d/.test(values[key]);
        }
        case ValidatorType.ONESPECIAL: {
          return !/[~`.@!#$%^&*+=\-[\]\\';,/{}()|\\":<>?]/.test(values[key]);
        }
        case ValidatorType.ALLOWEDDOMAIN: {
          const allowed_domains = ALLOWED_DOMAINS?.split(',') || [''];
          const include = allowed_domains.filter((domain) => values[key].includes(domain));
          return !include.length;
        }
        case ValidatorType.MAX_FIVE: {
          return values[key].toString().length > 5;
        }
        default:
          return false;
      }
    });
    if (error) {
      const message = typeof error === 'object' ? error.message : errorMessages[error] || error;
      return { ...errors, [key]: message };
    }
    return errors;
  }, {});
