import React from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import { ReportingList } from './routes';

export const Reporting = () => {
  const match = useRouteMatch();

  return (
    <Switch>
      <Route exact path={`${match.path}`}>
        <ReportingList />
      </Route>
    </Switch>
  );
};
