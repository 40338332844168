import moment from 'moment';
import { useMemo } from 'react';
import { useFilerParamsId, useLibbyFetch } from '../../../hooks';
import { LibbyObject } from '../../../types';

interface UseStockLogicTypes {
  libby: LibbyObject;
}

interface RowsType {
  id: string;
  quantity: number;
  marketplace: string;
  updated_by: string;
  updated_at: string;
}

export const useStockLogsLogic = ({ libby }: UseStockLogicTypes) => {
  const { orderBy, direction, paramsFetch, handleRequestSort, setFilter, filter } = useFilerParamsId({
    orderInit: 'updated_at',
    daoName: 'ster_product_stock_logs',
    directionInit: 'desc'
  });

  const { data, fetchMore, working } = useLibbyFetch(libby, paramsFetch);

  const rows: RowsType[] = useMemo(
    () =>
      data.map((value) => {
        const updateDate = moment(value.updated_at).format('DD-MM-YYYY  HH:mm');
        return {
          id: value.sku,
          quantity: value.quantity,
          marketplace: value.marketplace.name,
          updated_at: updateDate,
          updated_by: value.updated_by?.username
        };
      }),
    [data]
  );

  return {
    rows,
    data,
    orderBy,
    direction,
    handleRequestSort,
    fetchMore,
    setFilter,
    filter,
    working
  };
};
