import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import { Box, CircularProgress, Typography, LinearProgress, CircularProgressProps } from '@material-ui/core';
import { usePortal } from '../../hooks';
import { useTranslation } from 'services/translation';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    loading: {
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100%',
      height: '100vh',
      zIndex: 1500,
      overflowY: 'hidden',
      backgroundColor: 'rgba(255,255,255, 0.5)',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column'
    },
    infoContainer: {
      position: 'fixed',
      display: 'flex',
      width: '100%',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center'
    },
    label: {
      position: 'relative',
      top: -110,
      textAlign: 'center',
      fontSize: '18px',
      color: '#FF5179',
      [theme.breakpoints.down('xs')]: {
        width: '60%'
      },
      [theme.breakpoints.up('xl')]: {
        width: '100%'
      }
    },
    description: {
      position: 'relative',
      top: 115,
      backgroundColor: 'white',
      width: '725px',
      fontSize: '14px',
      color: '#FF5179',
      textAlign: 'center',
      fontWeight: 'normal',
      padding: 2,
      [theme.breakpoints.down('xs')]: {
        width: '80%'
      },
      [theme.breakpoints.between('sm', 'md')]: {
        width: '80%'
      },
      [theme.breakpoints.between('md', 'lg')]: {
        width: '650px'
      },
      [theme.breakpoints.between('lg', 'xl')]: {
        width: '725px'
      },
      [theme.breakpoints.up('xl')]: {
        width: '725px'
      }
    }
  })
);

export const LoadingScreen = ({ total_loaded, percentage }: { total_loaded?: number; percentage?: number }) => {
  const portal = usePortal('portals');
  const classes = useStyles();
  const { t } = useTranslation();

  useEffect(() => {
    document.body.classList.add('overflow-hidden');

    return () => {
      document.body.classList.remove('overflow-hidden');
    };
  }, []);

  return ReactDOM.createPortal(
    <Box className={classes.loading} maxWidth="md">
      {percentage ? <CircularProgressWithLabel value={percentage} /> : <CircularProgress />}
      {total_loaded !== undefined && total_loaded > 0 && (
        <Box className={classes.infoContainer}>
          <Typography className={classes.label}>
            {t('Total of orders being evaluated')}: {total_loaded}
          </Typography>
          {percentage !== undefined && <LinearProgress variant="determinate" value={percentage} className={classes.description} />}
          <Typography className={classes.description}>
            {t('WARNING')}:{' '}
            {percentage && percentage < 100
              ? t('Orders are being loaded for evaluation and may take some time, the number displayed during loading may differ from the number finally displayed in the preview')
              : t("Orders are being processed to evaluate whether they meet the requeriments. Please don't close this window while the process is loading.")}
          </Typography>
        </Box>
      )}
    </Box>,
    portal
  );
};

function CircularProgressWithLabel(props: CircularProgressProps & { value: number }) {
  return (
    <Box sx={{ position: 'relative', display: 'inline-flex' }}>
      <CircularProgress />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <Typography variant="caption" component="div">{`${Math.round(props.value)}%`}</Typography>
      </Box>
    </Box>
  );
}
