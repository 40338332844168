// Colors
import { primary } from '../colors';
import palette from '../palette';

type MuiFilledInputType = { [k: string]: any };

const MuiFilledInput: MuiFilledInputType = {
  root: {
    backgroundColor: palette.background.default,
    '&:hover': {
      backgroundColor: primary.light
    },
    '&$focused': {
      backgroundColor: primary.light
    }
  }
};

export default MuiFilledInput;
