import { Operation } from '../../../interfaces/business';
import { makeBusinessEntity } from '../../../lib/libby/generator/makeBusinessEntity';

const DAO_NAME = 'operation';

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch }
} = makeBusinessEntity<Operation>(DAO_NAME);

// hooks
export const useOperationFetchById = fetchById;
export const useOperationLibbyCall = libbyCall;
export const useOperationDAO = libbyDAO;
export const useOperationLibbyFetch = libbyFetch;
