import { Box, Typography } from '@material-ui/core';
import React from 'react';
import { LegendPayload, LegendProps, Surface, Symbols } from 'recharts';

export const RenderCusomizedLegend = ({ payload }: LegendProps) => (
  <Box flexWrap="wrap" display="flex" justifyContent="center">
    {payload &&
      payload.map((entry: LegendPayload) => (
        <Box flexDirection="rows" display="flex">
          <Box>
            <Surface width={20} height={20}>
              <Symbols cx={10} cy={10} type="circle" size={50} fill={entry.color} />
            </Surface>
          </Box>
          <Box width={120}>
            <Typography noWrap variant="body1" color="textSecondary">
              {entry.value}
            </Typography>
          </Box>
        </Box>
      ))}
  </Box>
);
