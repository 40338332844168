import { useCallback, useState, useReducer } from 'react';
import { useSnackbar } from 'notistack';
import { useHistory } from 'react-router-dom';
import { LibbyObject } from '../../../types';
import { CloseCollectDialog } from '../components';
import CustomModal from '../../../services/customFormDialog';
import { useTranslation } from '../../../services/translation';
import { OddoOrder, sendDataToOddo } from '../../Dispatches/utils/oddo';
import { CollectState, DISPATCH_ITEM_STATE, ORDER_STATE } from '../../../const';
import { Collect_item, Collect, Collect_item_product } from '../../../interfaces/business';

interface IOrderState {
  loading: boolean;
  count: number;
  totalCount: number;
}

enum ActionType {
  UpdateInit = 'UPDATE_INIT',
  UpdateProcess = 'UPDATE_PROCESS',
  UpdateSuccess = 'UPDATE_SUCCESS',
  UpdateFailure = 'UPDATE_FAILURE'
}

type Action = { type: ActionType.UpdateInit; payload: number } | { type: ActionType.UpdateProcess; payload: number } | { type: ActionType.UpdateSuccess } | { type: ActionType.UpdateFailure };

const orderStateReducer = (state: IOrderState, action: Action): IOrderState => {
  switch (action.type) {
    case ActionType.UpdateInit:
      return { ...state, loading: true, totalCount: action.payload };
    case ActionType.UpdateProcess:
      return { ...state, loading: true, count: action.payload };
    case ActionType.UpdateSuccess:
      return { ...state, loading: false };
    case ActionType.UpdateFailure:
      return { ...state, loading: false };
    default:
      return state;
  }
};

const initialState: IOrderState = {
  loading: false,
  count: 0,
  totalCount: 0
};

type useCollectCloseProps = {
  libby: LibbyObject;
  updateData: (collect: Collect) => void;
  data: Collect;
};

const CloseCollectModal = CustomModal(CloseCollectDialog);

export const useCollectClose = ({ libby, updateData, data }: useCollectCloseProps) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [closeCollect, setCloseCollect] = useState<boolean>(false);
  const [orderStateState, dispatch] = useReducer(orderStateReducer, initialState);

  const modifyStateGlobal = useCallback(
    (collect: Collect) => {
      const updateState = {
        ...data,
        collectState: collect.collectState
      };
      updateData(updateState);
    },
    [updateData, data]
  );

  const onCloseCollect = useCallback(
    async (collectDetails?: boolean) => {
      try {
        console.log('========================================================================================================================');
        console.log('Logs useCollectClose');

        const result = await CloseCollectModal.show({ collectDetails });
        if (result !== '990a4037-6650-49f8-bbea-a64eb455fd2e') return;

        const onCloseUpdate = await libby.ster_dispatch_collect_close.aspect('close_collect_with_items').save({
          collect_id: data.collect_id,
          items: data.items.map((collect_item: Collect_item) => ({
            ...collect_item,
            dispatch_item: {
              ...collect_item.dispatch_item,
              dispatch_item_state: {
                dispatch_item_state_id: DISPATCH_ITEM_STATE.PREPARING_PACKAGE
              }
            }
          })),
          collectState: { collect_state_id: CollectState.COMPLETE }
        });
        setCloseCollect(true);

        console.log('---------------------------------------onCloseUpdate---------------------------------------');
        console.log('body');
        console.log({
          collect_id: data.collect_id,
          items: data.items.map((collect_item: Collect_item) => ({
            ...collect_item,
            dispatch_item: {
              ...collect_item.dispatch_item,
              dispatch_item_state: {
                dispatch_item_state_id: DISPATCH_ITEM_STATE.PREPARING_PACKAGE
              }
            }
          })),
          collectState: { collect_state_id: CollectState.COMPLETE }
        });
        console.log(onCloseUpdate);
        console.log('---------------------------------------onCloseUpdate---------------------------------------');

        modifyStateGlobal(onCloseUpdate);
        if (onCloseUpdate) {
          const dataForOddo: OddoOrder[] = [];
          for (let i = 0; i < data?.items?.length; i++) {
            const collectItem = data?.items[i];
            const {
              dispatch_item: {
                order: { order_id, so_number: so, items }
              }
            } = collectItem;
            const serial_numbers = [];
            /* eslint-disable no-await-in-loop */
            for (let j = 0; j < items.length; j++) {
              const { sku, order_item_id } = items[j];
              const collectItemsProduct = await libby.ster_collect_item_product.getByOrderItemId(order_item_id);

              serial_numbers.push({
                SKU: sku,
                SN: collectItemsProduct.length ? collectItemsProduct.map((collectItemProduct: Collect_item_product) => collectItemProduct.serial_number) : []
              });
            }
            dataForOddo.push({
              order_id: Number(order_id),
              so: so || '',
              serial_numbers
            });
            console.log('----------------------dataForOddo----------------------');
            console.log({ orders: dataForOddo });

            console.log('----------------------dataForOddo----------------------');
          }

          await sendDataToOddo({ orders: dataForOddo });
          dispatch({ type: ActionType.UpdateInit, payload: data?.items.length });
          let countOrderUpdated = 0;
          for await (const item of data?.items) {
            await libby.ster_order_table.save({
              ...item.dispatch_item.order,
              state: {
                order_state_id: ORDER_STATE.READY_FOR_DELIVERY
              }
            });

            console.log('----------------------OrderState----------------------');
            console.log({
              ...item.dispatch_item.order,
              state: {
                order_state_id: ORDER_STATE.READY_FOR_DELIVERY
              }
            });
            console.log('----------------------OrderState----------------------');

            dispatch({ type: ActionType.UpdateProcess, payload: countOrderUpdated++ });
          }

          enqueueSnackbar(t('Collect Closed'), { variant: 'success' });
        }
      } catch (error: any) {
        console.log('----------------------Catch (Error)----------------------');
        console.log(error);
        console.log('----------------------Catch (Error)----------------------');
        dispatch({ type: ActionType.UpdateFailure });
        enqueueSnackbar(`Error ${error}`, { variant: 'error' });
      } finally {
        dispatch({ type: ActionType.UpdateSuccess });
        console.log('========================================================================================================================');
        history.push('/collect');
      }
    },
    [libby, data, modifyStateGlobal, enqueueSnackbar, t, history]
  );

  return {
    onCloseCollect,
    closeCollect,
    orderStateState
  };
};
