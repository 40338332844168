import { LibbyFetchDAO } from '../LibbyFetchDAO';

export class OrderLiquidationDAO extends LibbyFetchDAO {
  constructor() {
    super('ster_order_liquidation', 'order_liquidation_id');
  }

  getGroupByOrderLiquidationId(group_id: string) {
    return this.query().equals('order_liquidation_group_id', group_id).run();
  }
}
