import React, { useMemo, useCallback, useState, useEffect, ReactNode } from 'react';
import { useSnackbar } from 'notistack';
import { useHistory } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import LaunchIcon from '@material-ui/icons/Launch';
import { LibbyObject, AnyObject } from '../../../types/types';
import { useLibbyFetchById, useModalWarning } from '../../../hooks';
import { useTranslation } from '../../../services/translation';
import { MakeCell } from '../../components/MakeCell';
import DeleteModal from '../../../services/confirmDialog';
import { columnsDetail, columnsDetailInquiry } from '../utils/columns';
import { Dispatch, Dispatch_item } from '../../../interfaces/business';
import CustomModal from '../../../services/customFormDialog';
import { ORDER_STATE, DISPATCH_STATE, DISPATCH_ITEM_STATE, DISPATCH_ITEM_STATE_NAME, MENU_ACTIONS } from '../../../const';
import { ItemsTable } from '../../components/ItemsTable';
import { SearchDialog } from '../../../components/SearchDialog';
import { useCheckAll } from '../../../hooks/useCheckAll';
import { Dispatch_item_custom } from '../../../interfaces/business/dispatch/custom/Dispatch_item_custom';
import { useDispatchesPrintRefer } from './useDispatchPrintRefer';
import { sendDispatch } from '../utils/sendDispatch';
import { Order } from '../../../interfaces';
import { red } from '../../../theme/colors';
import { filterDispatchItemsByState } from '../utils/filter';
import { openInNewTab } from '../../../utils';
import { getDispatchManifest } from '../utils/printDispatchManifest';
import { SendDispatchItemDialog } from '../routes/DispatchDetail/components/SendDispatchItemDialog';
import { highlightState } from '../utils/highlightState';
import { useMenuAction } from 'hooks/useMenuActions';

const SearchDialogModal = CustomModal(SearchDialog);
const SendDispatchItemDModal = CustomModal(SendDispatchItemDialog);

interface useDispatchesDetailProp {
  libby: LibbyObject;
  id: string;
  reFetch: () => void;
  handleUpdateDispatchList?: (dispatch: Dispatch, id: string) => void;
  resetFilter?: () => void;
  showModalCancelledShow?: boolean;
}

export type rowsDispatch = {
  id: string;
  select_order_id: boolean | JSX.Element;
  order_id: string;
  buyer: string;
  so_number: string | undefined;
  state: string;
  order_state_id: string;
  items: JSX.Element;
  total: string;
  updated_by: string;
  dispatch_item_state: string;
  dispatch_item_state_id: string;
  collect: string | undefined;
  delete: JSX.Element;
};

export type orderArrayItemsType = {
  id?: string;
  order_id?: string;
  order_state_id?: string;
  order_state_name?: string;
  document?: string;
  phone_number?: string;
  buyer?: string;
  itemsName?: string;
  itemsQuantity?: string;
  updated_by?: string;
  courier?: string;
  updated_at?: string;
  so_number?: string;
  dispatch_item_id?: string;
  delete?: ReactNode;
};

const availableStates = [DISPATCH_STATE.PENDING, DISPATCH_STATE.PREPARING, DISPATCH_STATE.READY];

export const useDispatchesDetail = ({ libby, id, reFetch, handleUpdateDispatchList, resetFilter, showModalCancelledShow = false }: useDispatchesDetailProp) => {
  const { working, data, recall } = useLibbyFetchById(libby, {
    daoName: 'ster_dispatch_details',
    aspect: 'list_dispatch_details',
    id
  });
  const history = useHistory();
  const [readOnlyData, setReadOnlyData] = useState(false);
  const [newData, setNewData] = useState(data || {});
  const [msg, setMsg] = useState('');
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const [orderItems, setOrderItems] = useState<Dispatch_item_custom[]>([]);
  const { checked, columnAll, handleCheckId } = useCheckAll(orderItems, 'dispatch_item_id');
  const { showModal } = useModalWarning({
    title: 'Send order',
    confirmText: 'Ok'
  });
  const { validateAction } = useMenuAction();

  const checkIfDispatchIsReady = useCallback(
    async (updatedData: Dispatch) => {
      let nextDispatchState = '';
      if (!updatedData.items.length) {
        nextDispatchState = DISPATCH_STATE.PENDING;
      }
      const completeDispatchItems = updatedData.items.filter((item: Dispatch_item) => item.dispatch_item_state.dispatch_item_state_id === DISPATCH_ITEM_STATE.PREPARED);
      if (completeDispatchItems.length && completeDispatchItems.length === updatedData.items.length) {
        nextDispatchState = DISPATCH_STATE.READY;
      }
      if (!nextDispatchState) return;
      try {
        const dispatch = await libby.ster_dispatch_details_update.aspect('list_dispatch_details').save({
          ...updatedData,
          dispatch_state: { dispatch_state_id: nextDispatchState }
        });
        setNewData({ ...newData, ...dispatch, total_amount_dispatch: dispatch.items.reduce((previousValue: number, currentValue: any) => previousValue + Number(currentValue.order.amount), 0) });
        setOrderItems(dispatch.items || []);
      } catch (err: any) {
        enqueueSnackbar(err.toString(), { variant: 'error' });
      }
    },
    [libby.ster_dispatch_details_update, enqueueSnackbar, setNewData, newData]
  );

  const handleOpenItemsModal = async (e: AnyObject, items: AnyObject) => {
    e.stopPropagation();
    try {
      await SearchDialogModal.show({
        title: 'Items',
        id: 'order_item_id',
        properties: ['name', 'sku'],
        label: 'Item/Sku',
        data: items,
        renderItem: (dataItems: AnyObject) => <ItemsTable items={dataItems.data} />
      });
    } catch (error: any) {
      // nothing
    }
  };

  const validateStatusOrderCancelled = useCallback((dataDispatch: Dispatch): number => {
    return filterDispatchItemsByState({
      dispatchItems: dataDispatch.items,
      orderState: [ORDER_STATE.READY_FOR_DELIVERY.toString(), ORDER_STATE.WAITING_FOR_PREPARATION.toString(), ORDER_STATE.PREPARED.toString()]
    }).length;
  }, []);

  const showModalCancelled = useCallback(
    (countCancelled: number) => {
      showModal({
        newContent: t('Please, delete all orders that are not in the state ready for delivery, waiting for preparation or prepared').replace('$$$$', countCancelled.toString())
      });
    },
    [showModal, t]
  );

  useEffect(() => {
    if (data && Object.entries(data).length > 0 && Object.entries(newData).length === 0) {
      setNewData({ ...data, total_amount_dispatch: data?.items?.reduce((previousValue: number, currentValue: any) => previousValue + Number(currentValue.order.amount), 0) });
      if (showModalCancelledShow && data.dispatch_state.dispatch_state_id.toString() !== DISPATCH_STATE.SENT.toString()) {
        const countCancelled = validateStatusOrderCancelled(data);
        if (countCancelled !== data.items.length) {
          showModalCancelled(countCancelled);
        }
      }

      setOrderItems(data.items);
      setReadOnlyData(!availableStates.includes(data?.dispatch_state.dispatch_state_id));
    }
  }, [data, newData, t, validateStatusOrderCancelled, showModalCancelled, showModalCancelledShow]);

  const { printDispatchNote, searchPrintRefer } = useDispatchesPrintRefer({
    dispatch: newData
  });

  const removeItemUpdate = useCallback(
    (order_id) => {
      const dispatchItemsUpdated = orderItems.filter((item: Dispatch_item) => item.order?.order_id !== order_id);
      setOrderItems(dispatchItemsUpdated);
      return dispatchItemsUpdated;
    },
    [orderItems]
  );

  // HIDE Manifest
  const confirmPrintRefer = useCallback(async () => {
    const confirm = await DeleteModal.show({
      title: t('Print manifest'),
      content: t('Do you want to print the manifest?'),
      confirmText: t('Yes'),
      cancelText: t('No')
    });
    if (confirm) {
      openInNewTab(getDispatchManifest(id));
    }
  }, [t, id]);

  const toFinishDispatch = useCallback(async () => {
    const { data: saveDetailsUpdateState, success, error } = await sendDispatch(newData, libby);

    if (!success || !saveDetailsUpdateState) {
      enqueueSnackbar(t(error as string), {
        variant: 'error'
      });
      return;
    }
    setNewData({ ...saveDetailsUpdateState.dispatch, total_amount_dispatch: saveDetailsUpdateState?.dispatch.items.reduce((previousValue, currentValue) => previousValue + Number(currentValue.order.amount), 0) });
    setOrderItems(saveDetailsUpdateState?.dispatch.items || []);

    history.push(`/dispatches/inquiry/detail/${newData.dispatch_id}`);

    enqueueSnackbar(t('Dispatch successfully sent'), {
      variant: 'success'
    });
    if (resetFilter) resetFilter();
    reFetch();
    confirmPrintRefer();
  }, [newData, libby, confirmPrintRefer, t, enqueueSnackbar, history, resetFilter, reFetch]);

  const addItemInDispatch = useCallback(
    async (newOrderSave: Order[]) => {
      const dispatchData = { ...newData };
      const dispatchItems = [...dispatchData.items];

      newOrderSave.forEach((order: Order) => dispatchItems.push({ order: { ...order } }));

      try {
        const orderSkusValidation = (await libby.ster_validate_order_item.save({ orderIds: dispatchItems.map((dispatchItem: any) => dispatchItem.order.order_id) })).find((validation: any) => !validation.exists);

        if (orderSkusValidation) throw new Error(`Order ID #${orderSkusValidation.order_id} SKU (${orderSkusValidation.sku}) ${t('unregistered')}`);

        const addItems = await libby.ster_dispatch_details_update.aspect('list_dispatch_details').save({ ...dispatchData, items: dispatchItems });

        reFetch();
        if (handleUpdateDispatchList) {
          handleUpdateDispatchList(addItems, 'dispatch_id');
        }
        enqueueSnackbar(t('Orders Added'), { variant: 'success' });
        history.push(`/dispatches/detail/${id}`);
      } catch (error: any) {
        enqueueSnackbar(`${t('Error adding orders in dispatch')}: ${error}`, {
          variant: 'error'
        });
      }
    },
    [newData, libby.ster_dispatch_details_update, handleUpdateDispatchList, t, enqueueSnackbar, history, id, reFetch, libby.ster_validate_order_item]
  );

  const addItemInOrderItems = useCallback(
    async (orderId) => {
      try {
        const dipatchItem = orderItems?.find((item: Dispatch_item) => item.order?.order_id === orderId);
        if (dipatchItem?.dispatch_item_state?.dispatch_item_state_id === DISPATCH_ITEM_STATE.PREPARED) {
          const resp = await SendDispatchItemDModal.show({
            dispatchZone: data.dispatch_zone.name,
            order: dipatchItem.order
          });

          if (resp === newData.dispatch_zone.dispatch_zone_id) {
            const itemsDontUpdated = orderItems?.filter((item: Dispatch_item) => item.dispatch_item_id !== dipatchItem.dispatch_item_id);

            const itemUpdated = await libby.ster_dispatch_item.aspect('collect_item_update_state').save({
              ...dipatchItem,
              dispatch_item_state: {
                dispatch_item_state_id: DISPATCH_ITEM_STATE.SENT
              }
            });
            if (itemUpdated) itemsDontUpdated.push({ ...dipatchItem, dispatch_item_state: { dispatch_item_state_id: DISPATCH_ITEM_STATE.SENT, name: DISPATCH_ITEM_STATE_NAME[DISPATCH_ITEM_STATE.SENT] } });

            const dispatchUpdated: Dispatch = { ...newData, items: [...itemsDontUpdated] };
            setNewData(dispatchUpdated);
            setOrderItems(dispatchUpdated.items || []);
            checkIfDispatchIsReady(dispatchUpdated);
          } else {
            enqueueSnackbar('The scanned code is not correct', { variant: 'error' });
          }
        } else {
          enqueueSnackbar('The aggregated order does not have the status prepared', {
            variant: 'error'
          });
        }
      } catch (error: any) {
        enqueueSnackbar(`${t('An error occurred sending order $$$$').replace('$$$$', orderId)}: ${error}`, {
          variant: 'error'
        });
      }
      setMsg('');
    },
    [t, data, newData, orderItems, libby, checkIfDispatchIsReady, enqueueSnackbar]
  );

  const updateDataDetail = useCallback(
    (dataUpdate: AnyObject) => {
      setNewData((prev: AnyObject) => {
        const copy: AnyObject = { ...prev };
        if (copy?.dispatch_id?.length) {
          if (copy.courier_service.courier_service_id !== dataUpdate.courier_service.courier_service_id && copy.courier_service.courier.courier_id !== dataUpdate.courier_service.courier.courier_id) {
            copy.items = [];
            setOrderItems(copy.items);
          }
          return { ...copy, ...dataUpdate, total_amount_dispatch: 0 };
        }
        return copy;
      });
    },
    [setNewData]
  );

  const deleteItemInOrderItemsModal = useCallback(
    async (dispatch_item_id, order_id, dispatch_item_state_id) => {
      setMsg(t('Removing order: $$$$').replace('$$$$', order_id));
      const confirm = await DeleteModal.show({
        title: t('Order Delete'),
        content:
          dispatch_item_state_id !== DISPATCH_ITEM_STATE.ASSIGENED_TO_COLLECT && dispatch_item_state_id !== DISPATCH_ITEM_STATE.COLLECTING
            ? t('Do you want to delete the order of the dispatch $$$$?').replace('$$$$', order_id)
            : t('This order $$$ is already in $$$$ status, you are sure you want to remove it from dispatch?').replace('$$$', order_id).replace('$$$$', t(DISPATCH_ITEM_STATE_NAME[dispatch_item_state_id])),
        confirmText: t('Yes'),
        cancelText: t('No')
      });
      if (confirm) {
        try {
          if (dispatch_item_id) {
            await libby.ster_dispatch_item.aspect('remove_item').remove({ dispatch_item_id });
          }

          const updatedDispatchItems = removeItemUpdate(order_id);
          const updatedDispatch = { ...newData, items: updatedDispatchItems, total_amount_dispatch: updatedDispatchItems.reduce((previousValue, currentValue) => previousValue + Number(currentValue.order.amount), 0) };
          setNewData(updatedDispatch);
          setOrderItems(updatedDispatch.items || []);
          checkIfDispatchIsReady(updatedDispatch);
          enqueueSnackbar(t('Order Delete'), { variant: 'success' });
        } catch (e: any) {
          enqueueSnackbar(t('Something is wrong'), { variant: 'error' });
        }
      }
    },
    [enqueueSnackbar, libby.ster_dispatch_item, t, removeItemUpdate, checkIfDispatchIsReady, setNewData, newData]
  );

  const rows: rowsDispatch[] = useMemo(() => {
    const detailsData = newData as Dispatch;
    return orderItems.map(({ dispatch_item_id, order: { order_id, so_number, buyer, items, total, state, shipment }, dispatch_item_state, updated_by, created_by, collect_items }) => ({
      id: dispatch_item_id,
      select_order_id:
        (state?.order_state_id === ORDER_STATE.WAITING_FOR_PREPARATION.toString() || state?.order_state_id === ORDER_STATE.READY_FOR_DELIVERY.toString() || detailsData.dispatch_state.dispatch_state_id === DISPATCH_STATE.READY) &&
        handleCheckId(dispatch_item_id),
      order_id,
      province: shipment?.order_shipment_address?.city?.state?.name && highlightState(shipment?.order_shipment_address?.city?.state?.name),
      buyer: `${buyer?.first_name} ${buyer?.last_name}`,
      so_number,
      state: state?.name,
      order_state_id: state?.order_state_id,
      items: <MakeCell label={String(items?.length ?? '')} icon={LaunchIcon} onClick={(e) => handleOpenItemsModal(e, items ?? [])} />,
      total,
      updated_by: updated_by?.username ? updated_by.username : created_by?.username,
      dispatch_item_state: dispatch_item_state?.name,
      dispatch_item_state_id: dispatch_item_state?.dispatch_item_state_id,
      collect: collect_items?.[0]?.collect_id,
      delete: (
        <Grid container justify="center">
          <MakeCell label="" icon={DeleteIcon} onClick={() => deleteItemInOrderItemsModal(dispatch_item_id, order_id, dispatch_item_state?.dispatch_item_state_id.toString())} />
        </Grid>
      ),
      color_row: state?.order_state_id === ORDER_STATE.CANCELLED.toString() && red
    }));
  }, [orderItems, deleteItemInOrderItemsModal, handleCheckId, newData]);

  const columnsDetailsWithCheck = [columnAll, ...columnsDetail];

  if (validateAction(MENU_ACTIONS.DELETE_DISPATCH_ORDER)) {
    columnsDetailInquiry.push({
      id: 'delete',
      label: 'Actions',
      noSort: true,
      style: {
        whiteSpace: 'nowrap'
      }
    });
    columnsDetailsWithCheck.push({
      id: 'delete',
      label: 'Actions',
      noSort: true,
      style: {
        whiteSpace: 'nowrap'
      }
    });
  }

  return {
    orderItems,
    working,
    rows,
    addItemInOrderItems,
    deleteItemInOrderItemsModal,
    newData,
    columns: readOnlyData ? columnsDetailInquiry : columnsDetailsWithCheck,
    readOnlyData,
    updateDataDetail,
    msg,
    checked,
    recall,
    setNewData,
    setOrderItems,
    toFinishDispatch,
    checkIfDispatchIsReady,
    printDispatchNote,
    searchPrintRefer,
    addItemInDispatch
  };
};
