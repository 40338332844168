import React, { useMemo } from 'react';
import { DatabaseConnector } from '@phinxlab/libby-rest-web';
import { CircularProgress, FormControl, MenuItem, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { orderBy } from 'lodash';
import { AnyObject, LibbyObject } from '../../types/types';
import { useFilerParamsId, useLibbyFetch } from '../../hooks';
import { Message_template } from '../../interfaces/business/message';
import { DataGenerateSelectType } from '../../utils/generateSelect';
import { useTranslation } from '../../services/translation';

type SelectImportTypeProps = {
  title: string;
  inputProps: AnyObject;
  optionAll?: boolean;
  [k: string]: any;
  libby: LibbyObject;
  listStateInit: number[];
  disabled?: boolean;
  sort?: boolean | 'asc' | 'desc';
  sortBy?: string;
};

const useStyles = makeStyles({
  formControl: {
    width: '100%',
    '& > div > div > div': {
      flex: 1
    }
  },
  pText: {
    margin: '0px 4px',
    fontWeight: 'bold'
  }
});

export const SelectTemplateRaw = ({ disabled, libby, inputProps = {}, optionAll = false, title, sort = 'asc', sortBy = 'value' }: SelectImportTypeProps) => {
  const { paramsFetch } = useFilerParamsId({
    orderInit: 'name',
    daoName: 'ster_message_template',
    init: { active: [{ path: 'active', value: true }] }
  });
  const { data, working } = useLibbyFetch(libby, paramsFetch);
  const { t } = useTranslation();
  const classes = useStyles();

  const templates: DataGenerateSelectType[] = useMemo(() => {
    return Object(data).reduce((allTemplate: DataGenerateSelectType[], { message_template_id, name }: Message_template) => {
      const copyAllTemplate = [...allTemplate];
      copyAllTemplate.push({
        id: parseInt(message_template_id, 10),
        value: message_template_id,
        name: t(name)
      });
      return copyAllTemplate;
    }, []);
  }, [data, t]);

  const translatedLabel = t(title);

  return (
    <FormControl className={classes.formControl}>
      <TextField
        {...inputProps}
        label={working ? `${t('Loading')} ${translatedLabel} ...` : translatedLabel}
        InputLabelProps={{
          shrink: true
        }}
        placeholder={translatedLabel}
        fullWidth
        select
        disabled={!!disabled}
      >
        {working && !disabled ? (
          <MenuItem value="0">
            <CircularProgress size={24} />
          </MenuItem>
        ) : (
          optionAll && <MenuItem value="0">{t('All')}</MenuItem>
        )}

        {!working &&
          !sort &&
          templates.map(({ id, value, name }) => (
            <MenuItem value={String(value)} key={id}>
              {t(name || '')}
            </MenuItem>
          ))}
        {!working &&
          sort &&
          orderBy(
            templates.map((item) => ({ ...item, value: t(item.value) })),
            [sortBy],
            [sort]
          ).map(({ id, value, name }) => (
            <MenuItem value={String(value)} key={id}>
              {t(name || '')}
            </MenuItem>
          ))}
      </TextField>
    </FormControl>
  );
};

export const SelectTemplate = DatabaseConnector(SelectTemplateRaw)('ster_message_template');
