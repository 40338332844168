import React, { useEffect } from 'react';
import { DatabaseConnector } from '@phinxlab/libby-rest-web';
import { ACCOUNT_ROLE } from '../../const/accountRole';
import { useAlertModal } from './AlertModal';
import { useGlobalContext } from '../../hooks';
import { userInfoType, LibbyObject } from '../../types';

const AlertsRaw = ({ libby }: LibbyObject) => {
  const {
    userInfo
  }: {
    userInfo: userInfoType;
  } = useGlobalContext();
  const { validateAlert } = useAlertModal({ libby });

  useEffect(() => {
    if (userInfo?.groups?.[0]?.id === ACCOUNT_ROLE.ADMINISTRATOR) {
      validateAlert();
    }
  }, [userInfo, validateAlert]);
  return <></>;
};

export const Alerts = DatabaseConnector(AlertsRaw)('ster_ster_config');
