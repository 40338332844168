import { useCallback, useEffect, useState } from 'react';

interface UseContainerDimensionsType {
  current: HTMLInputElement | null;
}

export const useContainerDimensions = (myRef: UseContainerDimensionsType) => {
  const getDimensions = useCallback(
    () => ({
      width: myRef?.current?.offsetWidth ? myRef?.current?.offsetWidth : 0,
      height: myRef?.current?.offsetHeight ? myRef?.current?.offsetHeight : 0
    }),
    [myRef]
  );

  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

  useEffect(() => {
    const handleResize = () => {
      setDimensions(getDimensions());
    };

    if (myRef.current) {
      setDimensions(getDimensions());
    }

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [myRef, getDimensions]);

  return dimensions;
};
