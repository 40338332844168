import { Dispatch_zone } from '../../interfaces/business';
import { makeBusinessEntity } from '../../lib/libby/generator/makeBusinessEntity';

const DAO_NAME = 'ster_dispatch_zone_enabled';

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch }
} = makeBusinessEntity<Dispatch_zone>(DAO_NAME);

// hooks
export const useDispatchZoneEnabledFetchById = fetchById;
export const useDispatchZoneEnabledLibbyCall = libbyCall;
export const useDispatchZoneEnabledDAO = libbyDAO;
export const useDispatchZoneEnabledLibbyFetch = libbyFetch;
