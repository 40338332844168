import { LibbyFetchDAO } from '../LibbyFetchDAO';
import { ORDER_STATE, DISPATCH_STATE, DISPATCH_ITEM_STATE } from '../../../../const';

export class DispatchDetailsGlobalDAO extends LibbyFetchDAO {
  constructor(name: string) {
    super(name, 'dispatch_id');
  }

  getAll() {
    return this.query().limit(0, 10000).run();
  }

  getState() {
    return this.aspect('dispatch_select')
      .query()
      .equals('dispatch_state.dispatch_state_id', DISPATCH_STATE.PREPARING)
      .equals('items.dispatch_item_state.dispatch_item_state_id', DISPATCH_ITEM_STATE.ASSIGNED)
      .equals('items.order.state', ORDER_STATE.WAITING_FOR_PREPARATION)
      .limit(0, 10000)
      .run();
  }

  getStateInCollect() {
    return this.aspect('dispatch_select_state').query().notEquals('dispatch_state.dispatch_state_id', DISPATCH_STATE.PENDING).limit(0, 10000).run();
  }
}
