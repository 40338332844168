import { useEffect, useMemo, useState } from 'react';
import axios from 'axios';
import moment from 'moment';
import { v4 as uuid } from 'uuid';
import { useSnackbar } from 'notistack';
import { useHistory } from 'react-router-dom';
import { BudgetInitialValues } from '../types/BudgetContextValue';
import { useLibbyCall, useLibbyFetch } from '../../../../../../../lib/libby';
// import { usePaymentMethodOdooLibbyFetch } from '../../../../../../../business';
import { PaymentMethodItem, ProductInfo } from '../types';
import { Product } from '../../../../../../../interfaces/business';
import { getMargenPercentage, getOodoPrice } from '../../../utils';
import { API_URL } from '../../../../../../../config';
import { BUYER_ODOO_BILLING_INFO, COURIER_SERVICE } from '../../../../../../../const';
import { useTranslation } from '../../../../../../../services/translation';
import { IPaymentMethodCurrency } from '../types/IPaymentMethodCurrency';

export interface ProductOption extends ProductInfo {
  nameSKU: string;
}

const newInitialValues = {
  paymentMethods: [],
  products: [],
  billingAddress: {
    billingAddressOptions: [],
    billingAddresses: [],
    selectBillingAddress: '',
    selectedBillingAddress: '',
    selectedBillingCity: '',
    selectedBillingState: {
      state_id: '',
      value: ''
    },
    selectedBillingStreet: '',
    selectedBillingStreetNumber: '',
    selectedBillingZipCode: '',
    selectedBillingFloor: '',
    selectedBillingDepartment: ''
  },
  selectedClient: {},
  shippingAddress: {
    shippingAddressOptions: [],
    shippingAddresses: [],
    selectShippingAddress: '',
    selectedShippingAddress: '',
    selectedShippingCity: '',
    selectedShippingCityId: '',
    selectedShippingState: {
      state_id: '',
      value: ''
    },
    selectedShippingStreet: '',
    selectedShippingStreetNumber: '',
    selectedShippingZipCode: '',
    selectedShippingFloor: '',
    selectedShippingDepartment: '',
    selectedShippingComments: ''
  },
  transport: null,
  sellType: null,
  selectedTransport: {
    id: '',
    value: ''
  },
  selectedTransportCompany: '',
  advanceSale: {
    isAdvanceSale: false,
    advanceSaleDate: undefined
  }
};

export const useBudgetInitialValues = (id?: string) => {
  // TODO: esto esta repetido
  const { data: paymentMethodOdoo } = useLibbyFetch<IPaymentMethodCurrency>({ daoName: 'paymentmethodcurrency', aspect: '' });
  const paymentMethodsGroup = useMemo<Omit<PaymentMethodItem, 'code'>[]>(
    () =>
      paymentMethodOdoo?.map<Omit<PaymentMethodItem, 'code'>>((paymentMethod) => ({
        id: paymentMethod.OdooPaymentMethod[0].odoo_payment_method_id || '',
        plazo: paymentMethod.PaymentMethod.name,
        name: paymentMethod.PaymentMethod.PaymentType.name,
        paymentDays: 0,
        days: paymentMethod.PaymentMethod.name === 'Personalizado' ? '0' : paymentMethod.PaymentMethod.PaymentType.name,
        deliverDays: 0,
        //@ts-ignore
        paymentMethod
      })) || [],
    [paymentMethodOdoo]
  );
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const { data: products = [] } = useLibbyCall({ daoName: 'ster_product', methodName: 'getAll' });
  //@ts-ignore
  const options = useMemo(() => products.map<ProductOption>((product: Product) => ({ ...product, nameSKU: `${product.sku} - ${product.name}`, odoo: null })), [products]);

  const [initialValues, setInitialValues] = useState<BudgetInitialValues | undefined>(undefined);

  const { recall } = useLibbyCall<any[]>({
    daoName: 'ster_order_table',
    methodName: 'fetchById',
    params: [id],
    noAutoCall: true
  });

  const { recall: odooRecall } = useLibbyCall<any[]>({
    daoName: 'ster_buyer_odoo',
    methodName: 'getBuyerByNameId',
    noAutoCall: true
  });

  useEffect(() => {
    if (recall && !initialValues && paymentMethodsGroup.length && options.length) {
      if (!id) {
        setInitialValues(newInitialValues);
      } else {
        (async () => {
          try {
            const [data] = await recall();

            if (!data.orderB2Bs[0].tier_id) throw Error('order is too old');
            const dataOdoo = await odooRecall(data.buyer.first_name);
            if (!dataOdoo) throw Error('error in odoo, try later');
            const values: BudgetInitialValues = { ...newInitialValues };
            values.selectedClient = data.buyer;

            const response = await axios({
              method: 'GET',
              url: `${API_URL}/${BUYER_ODOO_BILLING_INFO.replace(':document', values.selectedClient?.document || '')}`
            });
            values.selectedClient = { ...values.selectedClient, ...response, ...dataOdoo[0] };

            // obtengo todos los ids de los paymentMethods
            const paymentMethodIds: any[] = [];
            data.orderB2Bs.forEach((item: any) => {
              if (!paymentMethodIds.includes(item.payment_method_id)) {
                paymentMethodIds.push(item);
              }
            });
            let method: Omit<PaymentMethodItem, 'code'> | undefined;
            let code: string = uuid();
            // ahora lo busco en el array de payments
            for (const paymentMethodId of paymentMethodIds) {
              method = paymentMethodsGroup.find((item) => item.id.toString() === paymentMethodId.payment_method_id.toString() && data.currency.currency_id === item.paymentMethod.Currency.currency_id);
              const orderB2b = data.orderB2Bs.find((order: any) => +order.payment_method_id === +paymentMethodId.payment_method_id);
              // @ts-ignore
              method = {
                ...method,
                // @ts-ignore
                Currency: {
                  ...method?.paymentMethod.Currency
                },
                //@ts-ignore
                paymentMethod: {
                  ...method?.paymentMethod,
                  PaymentMethod: {
                    ...method?.paymentMethod.PaymentMethod,
                    days: paymentMethodId.custom_term
                  }
                },
                paidInAdvance: orderB2b?.paidInAdvance || false,
                paymentDays: paymentMethodId.qty_deferred_payment,
                deliverDays: paymentMethodId.qty_delivery_days
              };
              code = uuid();
              if (method) {
                values.paymentMethods = [];
                values.paymentMethods.push({
                  ...method,
                  code,
                  paymentMethod: {
                    ...method.paymentMethod,
                    PaymentMethod: {
                      ...method.paymentMethod.PaymentMethod,
                      days: paymentMethodId.custom_term
                    }
                  },
                  days: paymentMethodId.custom_term || method.name || undefined,
                  paymentDays: paymentMethodId.qty_deferred_payment,
                  deliverDays: paymentMethodId.qty_delivery_days
                });
              }
            }

            // agrego producto por method
            values.products = [];
            for (const orderItem of data.items) {
              const product = options.find((option: any) => option.sku === orderItem.sku);

              const unit_price = data.current_rate !== null ? +orderItem.unit_price / +data.current_rate : +orderItem.unit_price / (+data.initial_rate === 0 ? 1 : +data.initial_rate) || 0;
              if (product) {
                const oodoPrice = await getOodoPrice(product.sku, method, data.orderB2Bs[0].tier_id, data.orderB2Bs[0].advanceSale);
                product.odoo = !oodoPrice
                  ? null
                  : {
                      price: {
                        company: oodoPrice.company,
                        list: 0,
                        payment_method: oodoPrice.payment_method,
                        price: oodoPrice.price,
                        net_income: +((oodoPrice.margen / 100) * oodoPrice.price).toFixed(2)
                      }
                    };
                values.products.push({
                  product,
                  rows: [
                    {
                      quantity: orderItem.quantity,
                      paymentMethod: { ...method, code } as PaymentMethodItem,
                      priceList: product.odoo?.price?.price || 0,
                      // @ts-ignore
                      percentage: 0,
                      priceUnit: oodoPrice?.price || 0,
                      netIncome: product.odoo?.price?.net_income || 0,
                      // @ts-ignore
                      discountPercentage: (100 * (product.odoo.price.price - unit_price)) / product.odoo.price.net_income,
                      discountPercentageMAX: getMargenPercentage()
                    }
                  ]
                });
              }
            }

            values.selectedTransport = {
              id: data.shipment.service.courier_service_id,
              value: data.shipment.service.name
            };

            if (values.selectedTransport.id === COURIER_SERVICE.TRANSPORTE.toString()) {
              values.selectedTransportCompany = data.shipment.transportation_company;
            }

            if (data.orderB2Bs.length) {
              const [b2b] = data.orderB2Bs;
              if (b2b.advanceSale) {
                values.advanceSale = {
                  isAdvanceSale: b2b.advanceSale,
                  advanceSaleDate: moment(b2b.advanceSaleDate)
                };
              }
            }

            setInitialValues(values);
          } catch (e: any) {
            enqueueSnackbar(t(e), {
              variant: 'error'
            });
            history.push('/budget');
          }
        })();
      }
    }
  }, [history, recall, enqueueSnackbar, t, odooRecall, initialValues, id, paymentMethodsGroup, options]);

  return initialValues;
};
