import React, { useCallback } from 'react';
import SearchIcon from '@material-ui/icons/Search';
import { Grid, TextField } from '@material-ui/core';
import { FilterBar } from '../../../../../components/FilterBar';
import { gridBreakpoints, gridBreakpointsFields } from '../../../../../const/gridBreakpoints';
import { useInitFilterDebounced, useInitFilterArray } from '../../../../OrderReport/routes/OrderReportList/hook';
import { makeFilter } from '../utils/makeFilter';
import { Filter } from '../../../../../types';
import { useTranslation } from '../../../../../services/translation';
import { SelectGoalType } from '../../../../../components/SelectGoalType';
import { SelectGoalTypeValue } from '../../../../../components/SelectGoalTypeValue';

type SearchFiltersProps = {
  onFilterChange: (makeFilter: object) => void;
  filter: Filter;
};

const SearchFiltersRaw = ({ onFilterChange, filter }: SearchFiltersProps) => {
  const { t } = useTranslation();

  const { search, handleChange } = useInitFilterDebounced({
    initFetch: filter.search,
    init: '',
    onFilterChange,
    id: 'search',
    makeFilter
  });

  const { search: goalType, handleChangeFilter: setGoalType } = useInitFilterArray({
    initFetch: filter.goal_types,
    onFilterChange,
    id: 'goal_types',
    makeFilter
  });
  const handleChangeGoalType = useCallback(
    (value: any) => {
      setGoalType(value);
    },
    [setGoalType]
  );

  const { search: goalTypeValue, handleChangeFilter: setGoalTypeValue } = useInitFilterArray({
    initFetch: filter.goal_types,
    onFilterChange,
    id: 'goal_types_value',
    makeFilter
  });

  const handleChangeGoalTypeValue = useCallback(
    (value: any) => {
      setGoalTypeValue(value);
    },
    [setGoalTypeValue]
  );

  return (
    <FilterBar
      defaultFilter={
        <Grid item {...gridBreakpoints}>
          <TextField
            id="standard-search"
            placeholder={t('Search')}
            type="search"
            value={search}
            onChange={handleChange}
            fullWidth
            InputLabelProps={{
              shrink: true
            }}
            InputProps={{
              endAdornment: <SearchIcon />
            }}
          />
        </Grid>
      }
    >
      <Grid item {...gridBreakpointsFields}>
        <SelectGoalTypeValue
          multiple
          inputProps={{
            onChange: handleChangeGoalTypeValue,
            value: goalTypeValue
          }}
        />
      </Grid>
      <Grid item {...gridBreakpointsFields}>
        <SelectGoalType
          multiple
          inputProps={{
            onChange: handleChangeGoalType,
            value: goalType
          }}
        />
      </Grid>
    </FilterBar>
  );
};

export const SearchFilters = React.memo(SearchFiltersRaw);
