import React from 'react';
import { Box } from '@material-ui/core';
import { BuyerLog, ShipmentAddressLog } from '../../../../../types/types';
import { formatDate } from '../../../../../utils';

export const columnsBuyerLogs = [
  {
    id: 'order_buyer_log_id',
    label: 'Log ID'
  },
  {
    id: 'order_id',
    label: 'Order Number',
    render: ({ order: { order_id } }: BuyerLog) => <Box>{order_id}</Box>
  },
  {
    id: 'first_name',
    label: 'First Name',
    render: ({ first_name }: BuyerLog) => <Box>{first_name}</Box>
  },
  {
    id: 'last_name',
    label: 'Last Name',
    render: ({ last_name }: BuyerLog) => <Box>{last_name}</Box>
  },
  {
    id: 'document',
    label: 'Document',
    render: ({ document }: BuyerLog) => <Box>{document}</Box>
  },
  {
    id: 'email',
    label: 'Email',
    render: ({ email }: BuyerLog) => <Box>{email}</Box>
  },
  {
    id: 'phone_number',
    label: 'Phone Number',
    render: ({ phone_number }: BuyerLog) => <Box>{phone_number}</Box>
  },
  {
    id: 'street',
    label: 'Street',
    render: ({ street }: ShipmentAddressLog) => <Box>{street}</Box>
  },
  {
    id: 'street_number',
    label: 'Street Number',
    render: ({ street_number }: ShipmentAddressLog) => <Box>{street_number}</Box>
  },
  {
    id: 'zip',
    label: 'Zip',
    render: ({ zip }: ShipmentAddressLog) => <Box>{zip}</Box>
  },
  {
    id: 'name',
    label: 'City',
    render: ({ city: { name } }: ShipmentAddressLog) => <Box>{name}</Box>
  },
  {
    id: 'floor',
    label: 'Floor',
    render: ({ floor }: ShipmentAddressLog) => <Box>{floor}</Box>
  },
  {
    id: 'department',
    label: 'Department',
    render: ({ department }: ShipmentAddressLog) => <Box>{department}</Box>
  },
  {
    id: 'extra_address',
    label: 'Extra Address',
    render: ({ extra_address }: ShipmentAddressLog) => <Box>{extra_address}</Box>
  },
  {
    id: 'updated_by',
    label: 'Updated by',
    render: ({ updated_by }: BuyerLog) => <Box>{updated_by && updated_by.username}</Box>
  },
  {
    id: 'updated_at',
    label: 'Change date',
    render: ({ updated_at }: BuyerLog) => <Box>{formatDate(updated_at, true, false)}</Box>
  }
];
