import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Button, CircularProgress, Grid, IconButton, MenuItem, TextField, Theme, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import SearchIcon from '@material-ui/icons/Search';
import { Add } from '@material-ui/icons';
import { useTranslation } from '../../../../../../../../services/translation';
import { useStyles } from '../../../../../BudgetNew/const';
import { SHIPMENT_TYPE } from '../../../../../../const';
import { useBudgetContext } from '../../../../context';
import { AnyObject } from '../../../../../../../../types';
import { COURIER_SERVICE } from '../../../../../../../../const';
import { ModalShippingAddressEdit } from './Modals';
import { ModalTransportEdit } from './Modals/ModalTransportEdit';

export interface AddressProps {}

export const ShippingAddress: FC<AddressProps> = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const {
    selectedClient,
    setSelectedTransport,
    addShippingAddressOptions,
    provinces,
    selectedProvince,
    setSelectedProvinces,
    selectedTransport,
    selectShippingAddress,
    shippingAddress,
    setSelectedTransportCompany,
    transportationCompanyOptions,
    fetchingTransportationCompany,
    recallTransportation,
    selectedTransportCompany
  } = useBudgetContext();

  const { shippingAddresses, selectedShippingAddress } = shippingAddress;
  const theme = useTheme<Theme>();
  const matches = useMediaQuery(theme.breakpoints.up(430));

  const [openEditModal, setOpenEditModal] = useState(false);
  const [openTransportModal, setOpenTransportModal] = useState(false);
  const [searchTransport, setSearchTransport] = useState<null | string>(null);

  const defaultSTransportCompany = useMemo(() => {
    const transportationCompanyId = selectedClient.data?.transportation_company_id;

    if (!searchTransport) {
      return transportationCompanyOptions.find((option: { transportation_company_id: number }) => {
        return option.transportation_company_id === transportationCompanyId;
      });
    }
    return undefined;
  }, [selectedClient.data, transportationCompanyOptions, searchTransport]);

  const handleSearchTransport = useCallback(
    async (value) => {
      setSearchTransport(value);
      await recallTransportation?.(value);
    },
    [recallTransportation]
  );

  useEffect(() => {
    if (defaultSTransportCompany && searchTransport === null) {
      setSelectedTransportCompany(defaultSTransportCompany);
    }
  }, [defaultSTransportCompany, setSelectedTransportCompany, searchTransport]);

  useEffect(() => {
    if (provinces.length) {
      addShippingAddressOptions(selectedClient, provinces);
    }
  }, [selectedClient, provinces, addShippingAddressOptions]);

  useEffect(() => {
    if (!selectedProvince?.state_id && shippingAddresses?.length) {
      setSelectedProvinces(provinces?.find((province: any) => province.state_id === shippingAddresses[shippingAddresses.length - 1].city?.state?.state_id?.toString()));
    }
  }, [provinces, selectedProvince, setSelectedProvinces, shippingAddresses]);

  return selectedClient.data ? (
    <Grid item container direction="column">
      {!selectedTransport.value && (
        <Grid xs={12}>
          <Typography variant="h1" className={classes.title}>{`${t('Shipping Address')}`}</Typography>
        </Grid>
      )}
      <Grid item xs={12} style={{ marginBottom: '10px' }}>
        <Autocomplete
          id="transport-search"
          options={SHIPMENT_TYPE}
          // @ts-ignore
          getOptionLabel={(option) => option?.value}
          style={{ maxWidth: '500px' }}
          onChange={(e, value) => {
            recallTransportation('');
            setSelectedTransport(value);
          }}
          // @ts-ignore
          value={selectedTransport}
          filterSelectedOptions
          disableClearable
          renderInput={(params) => <TextField {...params} label={selectedTransport.value ? t('Shipping ways') : null} className={classes.textFieldAutocomplete} placeholder={t('Shipping ways')} />}
        />
      </Grid>
      {selectedTransport.id !== COURIER_SERVICE.RETIRO_EN_PLANTA.toString() && selectedTransport.id !== '' && (
        <Grid container xs={12} style={{ marginBottom: '10px' }}>
          <Grid container xs={!selectedClient.data ? 12 : 8}>
            <TextField
              select
              variant="standard"
              value={selectedShippingAddress}
              label={selectedShippingAddress ? t('Shipping Address') : null}
              onChange={(event: any) => selectShippingAddress(event.target.value)}
              InputLabelProps={{ shrink: true }}
              style={{ width: '100%', maxWidth: '500px' }}
              disabled={shippingAddress.shippingAddressOptions?.length === 0}
            >
              {shippingAddress.shippingAddressOptions?.map((item: any) => (
                <MenuItem key={item.id} value={item.id}>
                  {item.value}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          {selectedClient.data &&
            (matches ? (
              <Grid container xs={4} justify="center" alignItems="flex-end">
                <Button style={{ padding: '1px', width: '90%', maxWidth: '150px' }} type="button" variant="contained" color="primary" onClick={() => setOpenEditModal(true)}>
                  {t('Add')}
                </Button>
              </Grid>
            ) : (
              <IconButton>
                <Add style={{ width: '40px', height: '40px' }} color="primary" onClick={() => setOpenEditModal(true)} />
              </IconButton>
            ))}
        </Grid>
      )}
      {selectedTransport.id === COURIER_SERVICE.TRANSPORTE.toString() && (
        <Grid container>
          {!selectedTransportCompany?.name && (
            <Grid xs={12}>
              <Typography variant="h1" className={classes.title}>{`${t('Carrier')}`}</Typography>
            </Grid>
          )}
          <Grid item xs={!selectedTransportCompany?.name ? 12 : 8}>
            <Autocomplete
              value={selectedTransportCompany}
              id="transport-company-search"
              onChange={(e, value: { id: string; value: string } | any) => {
                setSelectedTransportCompany(value);
              }}
              onInputChange={(event, value) => {
                handleSearchTransport(value);
              }}
              options={fetchingTransportationCompany ? [...transportationCompanyOptions] : transportationCompanyOptions}
              getOptionLabel={(item: AnyObject) => `${item.name}`}
              renderOption={(item) =>
                fetchingTransportationCompany ? (
                  <div className={classes.loadingAutocomplete}>
                    <CircularProgress size={24} />
                  </div>
                ) : (
                  <div>{item.name}</div>
                )
              }
              popupIcon={<SearchIcon />}
              style={{ width: '100%', maxWidth: '500px' }}
              renderInput={(params) =>
                fetchingTransportationCompany ? (
                  <TextField {...params} label="Buscar Transportista" variant="standard" disabled>
                    <CircularProgress size={24} />
                  </TextField>
                ) : (
                  <TextField {...params} label={selectedTransportCompany?.name ? 'Transportista' : null} variant="standard" />
                )
              }
            />
          </Grid>
          {selectedTransportCompany?.name && (
            <Grid container xs={4} justify="center" alignItems="center">
              <Button style={{ padding: '1px', width: '90%', maxWidth: '150px' }} type="button" variant="contained" color="primary" onClick={() => setOpenTransportModal(true)}>
                {t('Details')}
              </Button>
            </Grid>
          )}
        </Grid>
      )}
      <ModalShippingAddressEdit open={openEditModal} onClose={() => setOpenEditModal(false)} />
      <ModalTransportEdit open={openTransportModal} onClose={() => setOpenTransportModal(false)} />
    </Grid>
  ) : null;
};
