import React from 'react';
import { Box } from '@material-ui/core';
import { ShipmentAddressLog } from '../../../../../types/types';
import { formatDate } from '../../../../../utils';

export const columnsShipmentAddressLogs = [
  {
    id: 'order_shipment_address_log_id',
    label: 'Log ID'
  },
  {
    id: 'order_id',
    label: 'Order Number',
    render: ({ order: { order_id } }: ShipmentAddressLog) => <Box>{order_id}</Box>
  },
  {
    id: 'street',
    label: 'Street',
    render: ({ street }: ShipmentAddressLog) => <Box>{street}</Box>
  },
  {
    id: 'street_number',
    label: 'Street Number',
    render: ({ street_number }: ShipmentAddressLog) => <Box>{street_number}</Box>
  },
  {
    id: 'zip',
    label: 'Zip',
    render: ({ zip }: ShipmentAddressLog) => <Box>{zip}</Box>
  },
  {
    id: 'name',
    label: 'City',
    render: ({ city: { name } }: ShipmentAddressLog) => <Box>{name}</Box>
  },
  {
    id: 'floor',
    label: 'Floor',
    render: ({ floor }: ShipmentAddressLog) => <Box>{floor}</Box>
  },
  {
    id: 'department',
    label: 'Department',
    render: ({ department }: ShipmentAddressLog) => <Box>{department}</Box>
  },
  {
    id: 'extra_address',
    label: 'Extra Address',
    render: ({ extra_address }: ShipmentAddressLog) => <Box>{extra_address}</Box>
  },
  {
    id: 'updated_by',
    label: 'Updated by',
    render: ({ updated_by }: ShipmentAddressLog) => <Box>{updated_by && updated_by.username}</Box>
  },
  {
    id: 'updated_at',
    label: 'Change date',
    render: ({ updated_at }: ShipmentAddressLog) => <Box>{formatDate(updated_at, true, false)}</Box>
  }
];
