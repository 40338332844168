import { Goal } from '../../interfaces/business';
import { makeBusinessEntity } from '../../lib/libby/generator/makeBusinessEntity';

const DAO_NAME = 'ster_goal';

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch }
} = makeBusinessEntity<Goal>(DAO_NAME);

// hooks
export const useGoalFetchById = fetchById;
export const useGoalLibbyCall = libbyCall;
export const useGoalDAO = libbyDAO;
export const useGoalLibbyFetch = libbyFetch;
