import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import moment from 'moment';
import { useTranslation } from '../../../../../services/translation';
import { FilterBar } from '../../../../../components/FilterBar';
import { gridBreakpoints } from '../../../../../const/gridBreakpoints';
import { DateInput } from '../../../../../components/FilterManager/components/DateInput';

const SearchFiltersRaw = ({ date, onFilterChange }: { date: any; onFilterChange: any }) => {
  const { t } = useTranslation();
  return (
    <FilterBar
      defaultFilter={
        <Grid item {...gridBreakpoints}>
          <Typography variant="h4">
            {t('Reporting Date')}: {moment(date).format('DD/MM/YYYY')}
          </Typography>
        </Grid>
      }
    >
      <Grid item {...gridBreakpoints}>
        <DateInput onChange={onFilterChange} value={date} label={t('Date')} />
      </Grid>
    </FilterBar>
  );
};

export const SearchFilters = React.memo(SearchFiltersRaw);
