import { useEffect, useState, useCallback, useMemo } from 'react';
import { useSnackbar } from 'notistack';
import { AnyObject, LibbyObject } from '../../../../../types/types';
import { getExtension } from '../utils/getExtension';
import confirmDialog from '../../../../../services/confirmDialog';
import { useTranslation } from '../../../../../services/translation';

type useImportFormProps = {
  libby: LibbyObject;
  data: AnyObject;
  working: boolean;
};

export const useImportForm = ({ libby, data, working }: useImportFormProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const [currentImport, setCurrentImport] = useState<AnyObject>({});
  const [loading, setLoading] = useState(true);
  const [loadingSaveImport, setLoadingSaveImport] = useState(false);
  useEffect(() => {
    if (working === false && data) {
      setCurrentImport(data);
      setLoading(false);
    }
  }, [working, data]);

  const [filesStatus, someFileWithoutTempAttachments, someFileReadyToSave] = useMemo(() => {
    const { files: initialFiles = [] } = currentImport;

    const initialFilesWithoutTempAttachments = initialFiles.some(({ url }: { url: string }) => !url);

    const initialFilesReadyToSave = initialFiles.some(({ url, base64 }: { url: string; base64: string }, index: number) => {
      if ((!!base64 && !url) || (!url && !!data.files[index].url)) {
        return true;
      }

      return false;
    });

    return [initialFiles, initialFilesWithoutTempAttachments, initialFilesReadyToSave];
  }, [currentImport, data]);

  const handleUpdateTempAttachments = useCallback(
    (filename: string, base64Content: string, originalFilename: string, extension: string, delimiter?: string, action: 'delete' | 'add' = 'add') => {
      const newFilesStatus = filesStatus.map((file: AnyObject) => {
        const { filename: currentFilename, originalFilename: currentOriginalFilename } = file;

        return currentFilename === filename
          ? action === 'delete'
            ? {
                filename: currentFilename
              }
            : {
                filename: currentFilename,
                base64: base64Content,
                extension: getExtension(originalFilename, data.importation_type.importation_type_id),
                originalFilename: currentOriginalFilename,
                ...(delimiter && {
                  metadata: {
                    delimiter
                  }
                })
              }
          : file;
      });

      const newImport = { ...currentImport, files: newFilesStatus };
      setCurrentImport(newImport);
      return newFilesStatus;
    },
    [currentImport, filesStatus, data]
  );

  const handleOnSaveImport = useCallback(async () => {
    setLoadingSaveImport(true);
    try {
      const newImport = await libby.app_importation.save({
        ...currentImport,
        files: filesStatus
      });
      enqueueSnackbar(t('Saved files'), { variant: 'success' });
      setCurrentImport(newImport);
    } catch (e: any) {
      enqueueSnackbar(t('Something is wrong'), { variant: 'error' });
    }
    setLoadingSaveImport(false);
  }, [currentImport, filesStatus, libby.app_importation, enqueueSnackbar, t]);

  const changeStateImport = useCallback(
    async (state: number, newFiles?: Array<object>) => {
      setLoadingSaveImport(true);
      try {
        const newImport = await libby.app_importation.save({
          ...currentImport,
          files: newFiles || filesStatus,
          importation_status: {
            importation_status_id: state
          }
        });
        setCurrentImport(newImport);
        enqueueSnackbar(t('Updated status'), { variant: 'success' });
      } catch (e: any) {
        enqueueSnackbar(t('Something is wrong'), { variant: 'error' });
      }
      setLoadingSaveImport(false);
    },
    [currentImport, filesStatus, libby.app_importation, enqueueSnackbar, t]
  );

  const handleDeleteImport = useCallback(
    async (name: string) => {
      const confirm = await confirmDialog.show({
        title: t('Delete File'),
        content: t('Do you want to delete this file?'),
        confirmText: t('Yes'),
        cancelText: t('No')
      });
      if (confirm) {
        try {
          // delete status file
          const files = handleUpdateTempAttachments(name, '', '', '', '', 'delete');
          changeStateImport(currentImport.importation_status.importation_status_id, files);
          // delete database file
          enqueueSnackbar(t('File deleted successfully'), {
            variant: 'success'
          });
        } catch (e: any) {
          enqueueSnackbar(t('Failed to delete'), { variant: 'error' });
        }
      }
    },
    [handleUpdateTempAttachments, t, changeStateImport, enqueueSnackbar, currentImport]
  );

  const handleOnAcceptOrder = useCallback(async () => {
    const confirm = await confirmDialog.show({
      title: t('Accept Order'),
      content: t('Do you want to accept the order?'),
      confirmText: t('Yes'),
      cancelText: t('No')
    });
    if (confirm) {
      changeStateImport(5);
    }
  }, [changeStateImport, t]);

  const handleOnRejectOrder = useCallback(async () => {
    const confirm = await confirmDialog.show({
      title: t('Reject Order'),
      content: t('Do you want to reject the order?'),
      confirmText: t('Yes'),
      cancelText: t('No')
    });
    if (confirm) {
      changeStateImport(6);
    }
  }, [changeStateImport, t]);

  const handleOnProcessImport = useCallback(async () => {
    const confirm = await confirmDialog.show({
      title: t('Process Import'),
      content: t('Do you want to process the import?'),
      confirmText: t('Yes'),
      cancelText: t('No')
    });
    if (confirm) {
      changeStateImport(2);
    }
  }, [changeStateImport, t]);

  return {
    loadingSaveImport,
    currentImport,
    someFileWithoutTempAttachments,
    someFileReadyToSave,
    loading,
    handleDeleteImport,
    handleOnRejectOrder,
    handleOnAcceptOrder,
    handleOnSaveImport,
    handleUpdateTempAttachments,
    handleOnProcessImport
  };
};
