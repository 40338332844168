import { LibbyFetchDAO } from './LibbyFetchDAO';

export class DispatchCartDAO extends LibbyFetchDAO {
  constructor() {
    super('ster_dispatch_cart', 'cart_id');
  }

  getAll() {
    return this.query().orderBy('name', 'asc').limit(0, 10000).run();
  }
}
