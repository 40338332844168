import React, { useCallback, useMemo } from 'react';
import moment, { Moment } from 'moment';
import { useField, useForm } from 'react-final-form-hooks';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { ThemeProvider } from '@material-ui/styles';
import { FormProps } from '../../../../../types';
import { DateClose } from '../../../../../components/DateClose';
import { useTranslation } from '../../../../../services/translation';
import { formatDate, makeGetErrorAndHelperText, replaceNonNumericCharacters, textFieldProps } from '../../../../../utils';
import { ResetForm } from '../../../../../components/ResetForm';
import { useModalFormStyles } from '../../../../../theme';
import { SelectTrimester } from '../../../../../components/SelectTrimester';
import { DataPeriodNotEnabled } from '../type';

export type CreateGoalPeriodType = {
  value: number;
  start_date: Moment;
  end_date: Moment;
};
export interface AddGoalFormProps extends FormProps {
  duplicate: boolean;
  allDataPeriodNotEnabled: DataPeriodNotEnabled[];
}
export const AddGoalPeriodForm = ({
  open = false,
  onCancel = () => {},
  onConfirm = () => {},
  title = 'Confirmation Dialog',
  cancelText = 'Cancel',
  confirmText = 'confirmText',
  customTheme = null,
  validate,
  formInitialValues = {},
  duplicate,
  allDataPeriodNotEnabled
}: AddGoalFormProps) => {
  const { t } = useTranslation();
  const styles = useModalFormStyles();
  const getErrorAndHelperText = makeGetErrorAndHelperText(t);

  const onSubmit = useCallback(
    async ({ value, year, trimester_date }) => {
      const yearDate = parseInt(moment(year).format('YYYY'), 10);
      const start_date = formatDate(moment().quarter(trimester_date).year(yearDate).startOf('quarter').toDate(), true, false, 'YYYY-MM-DD HH:mm:ss');
      const end_date = formatDate(moment().quarter(trimester_date).year(yearDate).endOf('quarter').toDate(), true, false, 'YYYY-MM-DD HH:mm:ss');

      onConfirm({
        end_date,
        start_date,
        value
      });
    },
    [onConfirm]
  );

  const { form, handleSubmit, submitError } = useForm({
    initialValues: formInitialValues,
    onSubmit,
    validate
  });

  const valueGoal = useField('value', form);
  const year = useField('year', form);
  const trimester_date = useField('trimester_date', form);

  const disabled = useMemo(() => {
    return duplicate ? moment(year.input.value).format('YYYY') === moment(formInitialValues.year).format('YYYY') && trimester_date.input.value === formInitialValues.trimester_date : false;
  }, [duplicate, formInitialValues, year.input.value, trimester_date.input.value]);

  const quarterDisabled = useMemo(() => {
    if (year.input.value) {
      const yearDate = moment(year.input.value).format('YYYY');
      return allDataPeriodNotEnabled.filter(({ year: yearDataPeriodNotEnabled }: DataPeriodNotEnabled) => yearDataPeriodNotEnabled === yearDate).map(({ quarter }: DataPeriodNotEnabled) => quarter);
    }
    return [];
  }, [allDataPeriodNotEnabled, year]);

  const dialog = (
    <Dialog open={open} onClose={onCancel} fullWidth aria-labelledby="form-dialog-title">
      <DialogContent className={styles.title}>
        <Typography variant="h3">{title}</Typography>
      </DialogContent>
      <form noValidate onSubmit={handleSubmit}>
        <DialogContent className={styles.content}>
          <TextField
            {...textFieldProps(t('Value'))}
            {...valueGoal.input}
            {...getErrorAndHelperText(valueGoal)}
            variant="standard"
            onChange={(event) => {
              const { value } = event.target;
              event.target.value = replaceNonNumericCharacters(value);
              valueGoal.input.onChange(event);
              return event;
            }}
            type="text"
          />
          <DateClose views={['year']} name="Start Date" value={year.input.value ? moment(year.input.value).utc() : null} onChange={year.input.onChange} disableFuture={false} {...getErrorAndHelperText(year)} />

          <SelectTrimester
            disabled={!year.input.value}
            quarterDisabled={quarterDisabled}
            inputProps={{
              onChange: trimester_date.input.onChange,
              value: trimester_date.input.value || '',
              ...getErrorAndHelperText(trimester_date)
            }}
          />

          {submitError && (
            <Typography style={{ display: 'block' }} variant="overline" color="error">
              {t(submitError)}
            </Typography>
          )}
        </DialogContent>
        <DialogActions>
          <ResetForm form={form} open={open} />
          <Button
            onClick={() => {
              onCancel();
            }}
          >
            {cancelText}
          </Button>
          <Button color="primary" type="submit" disabled={disabled}>
            {confirmText}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );

  return !customTheme ? dialog : <ThemeProvider theme={customTheme}>{dialog}</ThemeProvider>;
};
