import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { RouteComponentProps } from 'react-router';

type PrivateRouteProps = {
  component: React.ElementType;
  authorize: () => boolean;
  redirect?: string;
  key: string;
  [k: string]: any;
};

export const PrivateRoute = ({ component: Component, authorize = () => false, redirect = '/orders', key, ...rest }: PrivateRouteProps) => {
  const isAuthorized = authorize();
  return <Route key={key} {...rest} render={(props: RouteComponentProps) => (isAuthorized ? <Component {...props} /> : <Redirect to={redirect} />)} />;
};
