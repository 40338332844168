export enum ORDER_SHIPMENT_STATE {
  NOT_SET = 1,
  SCHEDULED,
  PREPARATION,
  OUT_TO_DELIVERY,
  DELIVERED,
  RETURNED
}

export const OrderShipmentState = {
  [ORDER_SHIPMENT_STATE.DELIVERED]: 'Delivered'
};
