export type PaymentMethodType = {
  ID: string;
  'Plazos de pago': string;
  Empresa?: string;
  companyId?: string;
};

export type PaymentMethodGroupType = {
  title: string;
  options: PaymentMethodType[];
};

export enum paymentMethodsId {
  CHEQUEPERSONALIZDO = '127',
  ECHEQUEPERSONALIZDO = '140'
}

export const paymentMethods: PaymentMethodGroupType[] = [
  {
    title: 'Transferencia',
    options: [
      {
        ID: '113',
        'Plazos de pago': 'Inmediata'
      }
    ]
  },
  {
    title: 'Efectivo',
    options: [
      {
        ID: '114',
        'Plazos de pago': 'Efectivo'
      }
    ]
  },
  {
    title: 'Cheque',
    options: [
      {
        ID: '115',
        'Plazos de pago': '0 Días'
      },
      {
        ID: '116',
        'Plazos de pago': '7 Días'
      },
      {
        ID: '117',
        'Plazos de pago': '15 días'
      },
      {
        ID: '118',
        'Plazos de pago': '30 días'
      },
      {
        ID: '119',
        'Plazos de pago': '45 días'
      },
      {
        ID: '120',
        'Plazos de pago': '60 días'
      },
      {
        ID: '121',
        'Plazos de pago': '90 días'
      },
      {
        ID: '122',
        'Plazos de pago': '120 días'
      },
      {
        ID: '123',
        'Plazos de pago': '0/30 días'
      },
      {
        ID: '124',
        'Plazos de pago': '30/60 días'
      },
      {
        ID: '125',
        'Plazos de pago': '30/60/90 días'
      },
      {
        ID: '126',
        'Plazos de pago': '60/90 días'
      },
      {
        ID: '127',
        'Plazos de pago': 'Personalizado'
      }
    ]
  },
  {
    title: 'E-Check',
    options: [
      {
        ID: '128',
        'Plazos de pago': '0 días'
      },
      {
        ID: '129',
        'Plazos de pago': '7 días'
      },
      {
        ID: '130',
        'Plazos de pago': '15 días'
      },
      {
        ID: '131',
        'Plazos de pago': '30 días'
      },
      {
        ID: '132',
        'Plazos de pago': '45 días'
      },
      {
        ID: '133',
        'Plazos de pago': '60 días'
      },
      {
        ID: '134',
        'Plazos de pago': '90 días'
      },
      {
        ID: '135',
        'Plazos de pago': '120 días'
      },
      {
        ID: '136',
        'Plazos de pago': '0/30 días'
      },
      {
        ID: '137',
        'Plazos de pago': '30/60 días'
      },
      {
        ID: '138',
        'Plazos de pago': '30/60/90 días'
      },
      {
        ID: '139',
        'Plazos de pago': '60/90 días'
      },
      {
        ID: '140',
        'Plazos de pago': 'Personalizado'
      }
    ]
  }
];
