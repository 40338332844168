import { ScreenAligned } from 'components/ScreenAligned/ScreenAligned';
import { useBreadcrumbs } from 'hooks';
import { useTranslation } from 'services/translation';
import { CreateProductFormRaw } from './components/CreateProductForm';
import { LibbyObject } from 'types';
import { useLocation } from 'react-router-dom';
import { TitleBreadcrumbs } from 'interfaces';
import { UserProductPermissions } from 'types';

export const ProductCreate = ({ libby, title, userPermissions }: { libby: LibbyObject; title: TitleBreadcrumbs[]; userPermissions: UserProductPermissions }) => {
  const { t } = useTranslation();
  const { state } = useLocation<{ goBack: TitleBreadcrumbs[] }>();
  const titleShow = useBreadcrumbs(t('Create Product'), state && state?.goBack?.length ? state.goBack : title);
  return (
    <ScreenAligned title={titleShow} working={false} showData={true}>
      <CreateProductFormRaw libby={libby} userPermissions={userPermissions} />
    </ScreenAligned>
  );
};
