import React, { useCallback } from 'react';
import { Accordion as MuiAccordion, AccordionDetails, AccordionSummary as MuiAccordionSummary, Typography, List, ListItem, ListItemText, Theme } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { makeStyles, withStyles } from '@material-ui/styles';
import { AnyObject } from '../../types';

const AccordionSummary = withStyles((theme) => ({
  root: {
    flexDirection: 'row-reverse'
  },
  content: {
    paddingLeft: theme.spacing(1)
  }
}))(MuiAccordionSummary);

const Accordion = withStyles({
  root: {
    background: 'transparent',
    borderRadius: 0,
    boxShadow: 'none'
  }
})(MuiAccordion);

const useStyle = makeStyles<Theme>({
  list: {
    width: '100%'
  }
});

export interface AccordionSelectorProps<T> {
  title: string | JSX.Element;
  items: T[];
  labelKey?: keyof T;
  idKey?: keyof T;
  value: T[];
  expanded?: boolean;
  onChange?: (newValue: T[]) => void;
  onItemClick?: (item: T) => void;
}

export function AccordionSelector<T = AnyObject>({ title, expanded, items, labelKey, idKey, value, onChange, onItemClick }: AccordionSelectorProps<T>) {
  const classes = useStyle();
  const getFoundIndex = useCallback((item: T) => value.findIndex((_value: T) => (idKey ? _value[idKey] === item[idKey] : _value === item)), [idKey, value]);
  const onClick = useCallback(
    (item: T) => {
      if (onChange) {
        const foundIndex = getFoundIndex(item);
        if (foundIndex === -1) {
          onChange([...value, item]);
        } else {
          onChange(value.filter((_value: T, index: number) => index !== foundIndex));
        }
      }
    },
    [getFoundIndex, onChange, value]
  );
  return (
    <Accordion defaultExpanded={expanded}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>{typeof title !== 'string' ? title : <Typography>{title}</Typography>}</AccordionSummary>
      <AccordionDetails>
        <List className={classes.list}>
          {items.map((item) => (
            <ListItem button key={idKey ? `${item[idKey]}` : `${item}`} onClick={() => (onItemClick ? onItemClick(item) : onClick(item))} selected={getFoundIndex(item) !== -1}>
              <ListItemText primary={labelKey ? item[labelKey] : item} />
            </ListItem>
          ))}
        </List>
      </AccordionDetails>
    </Accordion>
  );
}
