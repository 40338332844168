import React from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import { SterConfigList } from './routes/SterConfigList';
import { SterConfigLogsList } from './routes/SterConfigLogList';
import { useBreadcrumbs } from '../../hooks';

export const SterConfig = () => {
  const { path } = useRouteMatch();
  const match = useRouteMatch();

  const title = useBreadcrumbs('Process monitor');

  return (
    <Switch>
      <Route path={`${path}/logs/:id`}>
        <SterConfigLogsList title={title} />
      </Route>
      <Route path={`${match.path}`}>
        <SterConfigList />
      </Route>
    </Switch>
  );
};
