import React from 'react';
import { capitalize } from '@material-ui/core';
import { TextFieldProps } from '@material-ui/core/TextField';
import { GeneralSelect, GeneralSelectProps } from '../../../../../components/GeneralSelect';
import { ReportingCreationAreaStatus } from '../../../../../const';

type SelectOrderPrintStatusProps = {
  inputProps: TextFieldProps;
  title: string;
  [k: string]: any;
  sort?: boolean | 'asc' | 'desc';
};

export const SelectCreationAreaStatus = ({ inputProps = {}, sort, title, ...restProps }: SelectOrderPrintStatusProps) => {
  const data: GeneralSelectProps['data'] = Object.entries(ReportingCreationAreaStatus).map((item) => ({ id: +item[0], value: item[0], name: capitalize(item[1]) }));

  return <GeneralSelect label={title} optionAll data={data} inputProps={data.length === 0 && inputProps?.value !== '0' ? { ...inputProps, value: '0' } : inputProps} working={false} {...restProps} sort={sort} />;
};
