import React from 'react';
import { Grid, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { SelectDocumentType } from '../../components/SelectDocumentType';
import { SelectMarketPlace } from '../../components/SelectMarketPlace';
import { SelectOrderState } from '../../components/SelectOrderState';
import { SelectTemplate } from '../../components/SelectTemplate';
import { MessageConfigFormTypes } from '../types/types';
import { useTranslation } from '../../../services/translation';
import { textFieldProps } from '../../../utils';
import { CheckBox } from '../../../components/CheckBok';

const useStyles = makeStyles({
  field: {
    marginTop: '20px'
  }
});

export const MessageConfigForm = ({ marketplace, orderState, template, documentType, send_to, send_to_client, errorMarketplace, errorOrderState, errorTemplate, errorDocumentType }: MessageConfigFormTypes) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <>
      <Grid className={classes.field}>
        <SelectMarketPlace inputProps={{ value: marketplace.input.value, onChange: marketplace.input.onChange, ...errorMarketplace }} />
      </Grid>
      <Grid className={classes.field}>
        <SelectOrderState inputProps={{ value: orderState.input.value, onChange: orderState.input.onChange, ...errorOrderState }} title="State" />
      </Grid>
      <Grid className={classes.field}>
        <SelectTemplate inputProps={{ value: template.input.value, onChange: template.input.onChange, ...errorTemplate }} title={t('Template')} />
      </Grid>
      <Grid className={classes.field}>
        <SelectDocumentType inputProps={{ value: documentType.input.value, onChange: documentType.input.onChange, ...errorDocumentType }} title={t('Document Type')} />
      </Grid>
      <TextField {...textFieldProps(`${t('Send to')}`)} onChange={send_to.input.onChange} value={send_to.input.value} variant="standard" multiline />
      <CheckBox
        validateCheck={send_to_client.input.value}
        directionLabel="row-reverse"
        label="Send to client"
        onChange={() => {
          send_to_client.input.onChange(!send_to_client.input.value);
        }}
      />
    </>
  );
};
