import { makeValidator, ValidatorType, ValidatorRule } from '../../../utils';

export const formInitialValues = {
  message: ''
};

const validations: ValidatorRule[] = [
  { key: 'message', validators: [ValidatorType.REQUIRED] },
  { key: 'from', validators: [ValidatorType.REQUIRED] },
  { key: 'name', validators: [ValidatorType.REQUIRED] },
  { key: 'message_subject', validators: [ValidatorType.REQUIRED] }
];

export const validate = makeValidator(validations);
