export const OrderLiquidationCompareColumns = {
  orderId: {
    id: 'order_id',
    label: 'ID Order',
    style: {
      whiteSpace: 'nowrap',
      minWidth: '150px'
    }
  },
  soNumber: {
    id: 'so_number',
    label: 'Number so',
    style: {
      whiteSpace: 'nowrap'
    },
    orderById: 'so_number'
  },
  ownerId: {
    id: 'owner_id',
    label: 'Owner ID',
    style: {
      whiteSpace: 'nowrap',
      minWidth: '150px'
    },
    orderById: 'owner_id'
  },
  total: {
    id: 'total',
    label: 'Total',
    style: {
      whiteSpace: 'nowrap',
      minWidth: '120px',
      alignItems: 'right',
      backgroundColor: 'rgb(86, 181, 97)'
    },
    orderById: 'total'
  },
  total_db: {
    id: 'total_db',
    label: 'Total In Order',
    style: {
      whiteSpace: 'nowrap',
      minWidth: '120px',
      alignItems: 'right',
      backgroundColor: 'rgb(86, 181, 97)'
    },
    orderById: 'total_db'
  },
  commissions: {
    id: 'commissions',
    label: 'Commissions',
    style: {
      whiteSpace: 'nowrap',
      minWidth: '120px'
    },
    orderById: 'commissions'
  },
  shipping: {
    id: 'shipping',
    label: 'Shipping',
    style: {
      whiteSpace: 'nowrap',
      minWidth: '120px'
    },
    orderById: 'shipping'
  },
  discount: {
    id: 'discount',
    label: 'Discount',
    style: {
      whiteSpace: 'nowrap',
      minWidth: '120px'
    },
    orderById: 'discount'
  },
  cost_interest: {
    id: 'cost_interest',
    label: 'Interest',
    style: {
      whiteSpace: 'nowrap',
      minWidth: '120px'
    },
    orderById: 'cost_interest'
  },
  bonus_charger: {
    id: 'bonus_charger',
    label: 'Bonus Charger',
    style: {
      whiteSpace: 'nowrap',
      minWidth: '120px'
    },
    orderById: 'bonus_charger'
  },
  return_charge: {
    id: 'return_charge',
    label: 'Return Charge',
    style: {
      whiteSpace: 'nowrap',
      minWidth: '120px'
    },
    orderById: 'return_charge'
  },
  shipping_charge: {
    id: 'shipping_charge',
    label: 'Shipping Charge',
    style: {
      whiteSpace: 'nowrap',
      minWidth: '120px'
    },
    orderById: 'shipping_charge'
  }
};
