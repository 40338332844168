import React, { useMemo, useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import { ThemeProvider, makeStyles } from '@material-ui/styles';
import { Box, DialogTitle, TextField } from '@material-ui/core';
import { useTranslation } from '../../../services/translation';
import InfoTable from '../../components/InfoTable';

const useStyles = makeStyles({
  root: {
    '& .MuiDialog-paperWidthSm': {
      width: '100%',
      maxWidth: '600px'
    },
    '& .MuiDialogContent-root': {
      padding: '0px 24px 8px 24px'
    }
  },
  textField: {
    marginBottom: '20px'
  },
  title: {
    overflow: 'visible',
    '& > h3': {
      fontFamily: '"Oswald"'
    }
  },
  rootEdit: {
    display: 'flex',
    justifyContent: 'space-around',
    width: '100%'
  },
  rootCancelEdit: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%'
  }
});

interface ShowDetailsTypes {
  open: boolean;
  onCancel: () => void;
  title: string;
  customTheme: string | null;
  rows: any;
  label: string;
  distinct: boolean;
  search: boolean;
}

const dummyFunc = () => {};

export const ShowDetails = ({ open = false, onCancel = dummyFunc, title, customTheme = null, rows, label, distinct, search }: ShowDetailsTypes) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [searchValue, setSearchValue] = useState('');

  const rowsTable = useMemo(() => {
    const valueUrban: any[] = rows?.data_urbantech_ml.map((item: any) => item.name);
    const valueNova: any[] = rows?.data_novatech_ml.map((item: any) => item.name);
    const valueEnova: any[] = rows?.data_tienda_oficial_ml.map((item: any) => item.name);
    const allValues = valueNova && valueEnova && valueUrban ? [...valueNova, ...valueEnova, ...valueUrban] : [];

    const uniqueValues: any[] = Array.from(new Set(allValues));

    const responseRows: any[] = uniqueValues?.map((name: string) => {
      const valueEnovaFind = rows?.data_tienda_oficial_ml.find((valueFind: any) => valueFind.name === name);
      const valueUrbanFind = rows?.data_urbantech_ml.find((valueFind: any) => valueFind.name === name);
      const valueNovaFind = rows?.data_novatech_ml.find((valueFind: any) => valueFind.name === name);
      if (distinct) {
        return {
          name,
          quantity_urbantech_meli: valueUrbanFind ? valueUrbanFind.count : '0',
          quantity_tienda_oficial_ml: valueEnovaFind ? valueEnovaFind.count : '0',
          quantity_novatech_meli: valueNovaFind ? valueNovaFind.count : '0'
        };
      }
      return {
        name,
        quantity_urbantech_meli: valueUrbanFind.count,
        quantity_tienda_oficial_ml: valueEnovaFind.count,
        quantity_novatech_meli: valueNovaFind.count
      };
    });
    const responseFilter = responseRows.filter((itemFilter) => itemFilter.name.includes(searchValue));

    return responseFilter;
  }, [rows, searchValue, distinct]);

  const columns = [
    {
      id: 'name',
      label,
      style: {
        minWidth: '125px'
      },
      render: ({ name }: { name: string }) => t(name ?? ''),
      noSort: true
    },
    {
      id: 'quantity_urbantech_meli',
      label: 'Urbantech Meli',
      style: {
        minWidth: '140px'
      },
      render: ({ quantity_urbantech_meli, working_urbantech_ml }: { quantity_urbantech_meli: number; working_urbantech_ml: boolean }) => (working_urbantech_ml ? t('Loading data') : quantity_urbantech_meli),
      noSort: true
    },
    {
      id: 'quantity_tienda_oficial_ml',
      label: 'Tienda Oficial ML',
      style: {
        minWidth: '140px'
      },
      render: ({ quantity_tienda_oficial_ml, working_tienda_oficial_ml }: { quantity_tienda_oficial_ml: number; working_tienda_oficial_ml: boolean }) => (working_tienda_oficial_ml ? t('Loading data') : quantity_tienda_oficial_ml),
      noSort: true
    },
    {
      id: 'quantity_novatech_meli',
      label: 'Novatech Meli',
      style: {
        minWidth: '140px'
      },
      render: ({ quantity_novatech_meli, working_novatech_ml }: { quantity_novatech_meli: number; working_novatech_ml: boolean }) => (working_novatech_ml ? t('Loading data') : quantity_novatech_meli),
      noSort: true
    }
  ];

  const dialog = (
    <Dialog className={classes.root} open={open} onClose={onCancel} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">{title}</DialogTitle>
      <Box style={{ padding: '20px' }}>
        {search && (
          <TextField
            id="generic-search"
            placeholder={t(`Search ${label}`)}
            type="search"
            name="search"
            value={searchValue}
            onChange={(e: any) => setSearchValue(e.target.value)}
            fullWidth
            style={{ width: '100%', padding: '0px 0px 20px 0px' }}
            InputLabelProps={{
              shrink: true
            }}
          />
        )}
        <InfoTable columns={columns} rows={rowsTable} />
      </Box>
    </Dialog>
  );

  return !customTheme ? dialog : <ThemeProvider theme={customTheme}>{dialog}</ThemeProvider>;
};
