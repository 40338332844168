import React, { useEffect, useState } from 'react';
import Link from '@material-ui/core/Link';
import { SnackbarProvider, useSnackbar } from 'notistack';
import { formatDate } from '../../utils';
import { AnyObject } from '../../types/types';
import { PaginatedTable } from '../../components/PaginatedTable';
import { PreviewPdfModal } from '../../components/PreviewPdfModal';
import { useGenerateOrderDocumentURLDAO } from 'business';

export const documentsColumns = [
  {
    id: 'documentNumber',
    label: 'Number'
  },
  {
    id: 'source',
    label: 'Source',
    render: (row: any, item: any, t: (str: string) => string) => t(row.source.name)
  },
  {
    id: 'type',
    label: 'Type',
    render: (row: any, item: any, t: (str: string) => string) => t(row.type.name)
  },
  {
    id: 'createdAt',
    label: 'Creation Date',
    render: (row: any) => formatDate(row.createdAt, true, false)
  },
  {
    id: 'url',
    label: 'Link',
    render: (row: any, item: any, t: (str: string) => string) => {
      const DownLoadFile = ({ fileURL }: { fileURL: string }) => {
        const { enqueueSnackbar } = useSnackbar();
        const [presignedUrl, setPresignedUrl] = useState('');
        const GenerateOrderDocumentUrlDAO = useGenerateOrderDocumentURLDAO();

        useEffect(() => {
          const generatePresignedUrl = async () => {
            try {
              const { singedURL } = await GenerateOrderDocumentUrlDAO.save({ fileURL });

              setPresignedUrl(singedURL);
            } catch (error) {
              enqueueSnackbar('Error generating download URL', {
                variant: 'error'
              });
            }
          };

          generatePresignedUrl();
        }, [fileURL, enqueueSnackbar, GenerateOrderDocumentUrlDAO]);

        return (
          <Link target="_blank" href={presignedUrl}>
            {t('Download')}
          </Link>
        );
      };

      return row.url.includes('amazon') && !row.url.includes('pdf') ? (
        <DownLoadFile fileURL={row.url} />
      ) : (
        <Link target="_blank" href={row.url}>
          {t('Go to file')}
        </Link>
      );
    }
  },
  {
    id: 'preview',
    label: 'Preview',
    render: (row: any) => <PreviewPdfModal url={row.url.includes('amazon') ? row.url : `${row.url}&report_type=pdf`} />
  }
];

export const DocumentsTable = React.memo(({ documents = [], columns = documentsColumns }: AnyObject) => (
  <SnackbarProvider>
    <PaginatedTable headCells={columns} rows={documents} rowIdKey="order_document_id" />
  </SnackbarProvider>
));
