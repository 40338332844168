import { LibbyFetchDAO } from '../LibbyFetchDAO';

export class GoalProductEnabledDAO extends LibbyFetchDAO {
  constructor() {
    super('ster_goal_product_enabled', 'goal_product_enabled_id');
  }

  getAllGoalProductEnabled() {
    return this.query().limit(0, 10000).run();
  }
}
