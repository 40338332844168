import { NewOrderLiquidation } from '../../../interfaces/business';
import { makeBusinessEntity } from '../../../lib/libby/generator/makeBusinessEntity';

const DAO_NAME = 'ster_new_order_liquidation';

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch }
} = makeBusinessEntity<NewOrderLiquidation>(DAO_NAME);

// hooks
export const useNewOrderLiquidationFetchById = fetchById;
export const useNewOrderLiquidationLibbyCall = libbyCall;
export const useNewOrderLiquidationDAO = libbyDAO;
export const useNewOrderLiquidationLibbyFetch = libbyFetch;
