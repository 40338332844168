import { LibbyFetchDAO } from './LibbyFetchDAO';

export class OrderItemWithProductDAO extends LibbyFetchDAO {
  constructor() {
    super('ster_order_item_product', 'order_item_id');
  }

  getAll() {
    return this.query().limit(0, 10000).run();
  }

  getAllByOrderId(id: number) {
    return this.query().equals('order_id', id).run();
  }
}
